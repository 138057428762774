import React from 'react';
import { ChangeLogComponent} from './ChangeLog.Component';
import { httpServices } from '../../../../services/httpServices';

export class ChangeLogContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            title : 'Change Log',
            history  : [],
            selectedEntity : '',
            changeLogEntities : {}
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidMount(){
        this.setState(
            {
                selectedEntity : this.props.ChangeLogData.selectedEntity,
                changeLogEntities : this.props.ChangeLogData.changelog_entities
            },
            ()=>{
                this.getChangeLogDetails(this.props.UserId);
            });
    }
    getChangeLogDetails = (userId) => {
        let apiEndpoint = 'api/user/listChangeHistory';
        let selectedEntity =this.state.selectedEntity;
        return httpServices.post(apiEndpoint,{id : userId,entity : selectedEntity})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            history : response.data.history,
                            showLoader : false,
                            permission : response.data.permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleOnChange = (evt, value) => {
        this.setState({
            selectedEntity : evt.target.value
        },
        function(){
            this.getChangeLogDetails(this.props.UserId)
        });
    };
    
    render(){
        return (
            <ChangeLogComponent 
                History = {this.state.history}
                ChangeLogEntities = {this.state.changeLogEntities}
                selectedEntity = {this.state.selectedEntity}
                HandleOnChange = {this.handleOnChange}
                UserDetails = {this.props.UserDetails}
            />
        )
    }
}
