import React from 'react';
import Grid from '@mui/material/Grid';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {CustomizedSnackbars} from '../../common/snackbars';
import {DialogBoxMedium} from '../../common/dialog.medium';
import EventIcon from "@mui/icons-material/Event";
import { IconButton, InputAdornment } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { formatName } from '../../common/common';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import FilterList from '@mui/icons-material/FilterList';
import { CustomDatePicker } from '../../common/CustomDatePicker';

const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});

const SideList = withStyles(styles)(p => {
    const years = [];
    for(var i = p.MaxYear; i >= p.StartYear; i--){
        years.push(i);
    }
    return (
        <div role="presentation" className={p.classes.drawerList}>
            <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="year">Year</InputLabel>
                        <Select
                            value={(typeof p.Year !== 'undefined') ? p.Year : null}
                            onChange={p.HandleOnYearChange}
                            inputProps={{
                                name: 'year',
                                id:   'year',
                            }}>
                            { years.map((y) =>
                                <MenuItem value={y}>{y}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
	    <Grid item xs={4}>
                <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
	     </ Grid>
	     <Grid item>
                <Button variant="outlined"  color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
            </Grid>
	    </Grid>
        </div>
    );
});

export const HolidayComponent =  withStyles(styles)(props => {
    const { classes, ToggleDrawer } = props;
    const customButton = (props) => {
        return (<Button variant="contained" color="primary" size='small'>Add</Button>);
    }

    return(
        <React.Fragment>
        <CustomMaterialTable
        title ={"Holidays"}
        columns={[
            {
                title: 'Date',
                field: 'holiday_date',
            },
            {
                title: 'Holiday',
                field: 'holiday_name',
            },
            {
                title: 'Description',
                field: 'holiday_desc',
            },
            {
                title: 'Restricted Holiday',
                field: 'restricted_holiday',
            },
            {
                title: 'Created By',
                field: 'holiday_createdby',
                render: rowData => ( formatName(rowData.cby_first_name, null, rowData.cby_last_name, props.NameFormat))
            },
            {
                title: 'Created On',
                field: 'holiday_cdate',
            },
            {
                title: 'Modified By',
                field: 'holiday_modifiedby',
                render: rowData => ( formatName(rowData.mby_first_name, null, rowData.mby_last_name, props.NameFormat))
            },
            {
                title: 'Modified On',
                field: 'holiday_mdate',
            },
        ]}
        data={props.Holidays}
        options={{
            search: false,
                actionsColumnIndex: -1,
                paging: false,
                sorting: false,
                headerStyle: { position: 'sticky', top: 0 },
                maxBodyHeight: '1000px',
        }}
        actions={[
            {
                icon: customButton,
                tooltip: 'Add Holiday',
                isFreeAction: true,
                onClick: (event, newData) => {
                    props.AddHoliday();
                },
                hidden: (props.EditPermission) ? false : true,
            },
            rowData => ({
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => {
                    props.EditHoliday(rowData.holiday_id);
                },
                hidden : (props.EditPermission === false) ? true : ((rowData.hide_action !== 'undefined' && rowData.hide_action ) ? true : false),
            }),
            rowData =>(
                {
                    icon: 'delete',
                    tooltip: 'Delete',
                    onClick: (event, rowData) => {
                        props.DeleteHoliday(rowData.holiday_id);
                    },
                    hidden : (props.EditPermission === false) ? true : ((rowData.hide_action !== 'undefined' && rowData.hide_action ) ? true : false),
                }),
        ]}
        components={{
            Toolbar: props => (
                <div style={{ position: 'relative', paddingRight:'50px' }}>
                <CustomMTableToolbar {...props} />
                <Tooltip title="Filter" aria-label="Filter">
                <IconButton onClick={ToggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                </Tooltip>
                </div>
            )
        }}
        />
        <Drawer
            anchor="right"
            open={props.Right}
            onOpen={props.ToggleDrawer('right', true)}
            onClose={props.ToggleDrawer('right', false)}>
            <SideList
                Year ={props.Year}
                MaxYear = {props.MaxYear}
                StartYear = {props.StartYear}
                Right={'right'}
                HandleOnYearChange ={props.HandleOnYearChange}
                SubmitFilter={props.HandleSubmitFilter}
                ResetFilter={props.ResetFilter}
            />
        </Drawer>
        
        {props.Open &&
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.Close}
                Title = {props.Action === 'add' ? "Add Holiday" : "Edit Holiday"}
                OnSubmit = {(props.Action === 'edit' && parseInt(props.EditId) !== 0) ? ()=>props.OnSubmit(props.EditId) : ()=>props.OnSubmit()}
                Content = {<DialogContent FormProps={props}/>}
            />
        }
        {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
        }
        {props.DeleteConfirm &&
            <DialogBoxMedium
                Open = {props.DeleteConfirm}
                Cancel = {props.Close}
                Title = {'Confirm Delete'}
                ButtonName = {'Delete'}
                OnSubmit = {props.HandleDelete}
                Content = {<DialogDeleteHoliday FormProps={props} />}
            />
        }
        </React.Fragment>
    )

});

const DialogDeleteHoliday = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                <Typography variant="subtitle2" gutterBottom>
                    Do you want to delete this holiday?
                </Typography>
            </Box>
        </div>
    )
}

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleDateChange} = props;
    return (
        <form className="form-fullwidth"  >
            <Grid container spacing={3} >
                <Grid item xs={12} md={6}>
                    <FormControl>
                         <CustomDatePicker
                            name = "date"
                            id = "date"
                            selectedDate = {(typeof FormFields.date !== 'undefined') ? FormFields.date : null }
                            handleDateChange = {HandleDateChange}
                            error = {props.Errors.date}
                            required = {true}
                            label = "Date"
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="name"
                            name="name"
                            label="Name"
                            type="text"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormFields.name !== 'undefined' && FormFields.name ) ? FormFields.name : null}
                            onChange={props.HandleOnChange}
                            error={props.Errors.name && true}
                            helperText={props.Errors.name}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            multiline={true}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormFields.description !== 'undefined') ? FormFields.description : ''}
                            onChange={props.HandleOnChange}
                            error={props.Errors.description && true}
                            helperText={props.Errors.description}
                        />
                    </ FormControl>
                </Grid>
                <Grid item  xs={12} md={6}>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked = {(typeof FormFields.restricted_holiday !== 'undefined' && ( FormFields.restricted_holiday === 1 || FormFields.restricted_holiday === true )) ? FormFields.restricted_holiday : false}
                                    value = {(typeof FormFields.restricted_holiday !== 'undefined' && (FormFields.restricted_holiday === 1 || FormFields.restricted_holiday === true) ) ? FormFields.restricted_holiday : false}
                                    id = "restricted_holiday"
                                    name = "restricted_holiday"
                                    onChange = {props.HandleOnChange}
                                    color="primary"
                                />
                            }
                            label="Restricted Holiday"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
