import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { CustomizedSnackbars } from '../../../common/snackbars';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

export const GroupsComponent = (props) => {
    return (
        <>
            <React.Fragment>
	            <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"User Groups"}
                        columns={[
                            {title: 'Group Name',field: 'name'},
                        ]}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Assign Group',
                                isFreeAction: true,
                                onClick: (event, newData) => {props.HandleClickOpen()},
                                hidden: props.EditPermission ? false : true,
                            },
                            rowData => ({
                                icon: DeleteOutline,
                                tooltip: 'Delete',
                                onClick: (event, rowData) => { props.OnDelete(rowData.group_id)},
                                hidden: props.EditPermission ? false : true,
                            }),
                        ]}
                        data={props.Groups}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	            </div>
            </React.Fragment>
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={props.Type==='add'? 'Assign User Group':'Delete User Group'}
                OnSubmit={props.Type==='add' ? props.onSubmit : props.onDeleteSubmit}
                ButtonName={ props.Type==='add'?'Save':'Yes'}
                ErrorMessage={props.errorMessage}
                Content = {props.Type==='add'?
                    <DialogContent
                        FormData = {props.FormData}
                        GroupList = {props.GroupList}
                        HandleOnChange = {props.HandleOnChange}
                        Errors = {props.Errors}
                    />:'Do you want to delete this group?'
                }
            />
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </>
    )
};
const DialogContent = (p) => {
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="group"  error={p.Errors.group && true}>Group</InputLabel>
                        <Select
                            value={typeof p.FormData != 'undefined' && p.FormData.group}
                            onChange={p.HandleOnChange}
                            inputProps={{
                                name: 'group',
                                id: 'group',
                            }}>
                            { typeof p.GroupList != 'undefined' && p.GroupList.map((o, i) =>
                                <MenuItem value={o.id}>{o.name}</MenuItem>
                            )}
                        </Select>
                        <small className='error'>{p.Errors && p.Errors.group}</small>
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
