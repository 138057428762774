import React from 'react';
import {CustomMaterialTable} from '../../common/CustomMaterialTable';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { DialogBoxMedium } from "../../common/dialog.medium";

import { formatName } from '../../common/common';
import { CustomizedSnackbars } from '../../common/snackbars';
import { GroupEditContainer } from './GroupEdit.Container';
 

const columns = [
    { title: 'Name', field: 'name', removable: false, defaultSort: 'asc', },
    { title: 'Created By', field: 'created_by', removable: false,render: rowData => ( formatName(rowData.first_name, null, rowData.last_name)) },
    { title: 'Created On', field: 'created_on', removable: false, customSort:(a,b) => sort(new Date(a.created_on),new Date(b.created_on))},
];

function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
const GroupListStyle = styled(React.Fragment)(({theme}) => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
}));

const customButton = (props) => {
    return (<Button variant="contained" color="primary" size='small'>Add Group</Button>);
}


export const GroupListComponent = (props) => {
    const {  EditPermission,DeletePermission } = props;
    return (
        <GroupListStyle>
            <CustomMaterialTable
                title= "Groups"
                data = {props.Groups}
                columns={columns}
                actions={[
                    {
                        icon: customButton,
                        tooltip: 'Add Group',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.AddGroup() },
                        hidden: (EditPermission) ? false : true, 
                    },{
                        icon: Edit,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => { props.HandleEditClick(rowData.id)},
                        hidden : (EditPermission ) ? false : true, 
                    },{
                        icon: DeleteOutline,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => { props.HandleDeleteClick(rowData.id)},
                        hidden : (DeletePermission ) ? false : true, 
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize:10,
                    pageSizeOptions: [10,20,30],
                    thirdSortClick: false,
                    tableLayout: "fixed",
                    search:true
                }}
                onRowClick={(event, rowData) => props.HandleViewDetails(rowData.id)}
            />
              

            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {(props.Open && props.PopupType === 'edit')&& <GroupEditContainer
                Open = {props.Open}
                EditId = {props.EditId}
                UpdateParentOnSuccess = {props.UpdateParentOnSuccess}
                OnClose={props.OnClose}
            />}
            {(props.PopupType === 'delete' &&  props.Open) &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Confirm Delete' }
                OnSubmit = { props.DeleteClick} ButtonName={'Yes'}
                Content = {"Do you really want to delete this group?"}
            />}
        </GroupListStyle>
    );
};
