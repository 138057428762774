import React from "react";
import * as _cs from '../common/common';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import Paper from '@mui/material/Paper';
import AnnouncementIcon from '@mui/icons-material/AnnouncementOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import EmailIcon from '@mui/icons-material/MailOutlined';

export const PersonalInfoComponent = (props) => {
    let emergencyInfo = props.Emergency;
    let address = props.OtherAddress;
    let hrEmail = props.HREmail;
    let phone = props.Phone;
    let email = props.Email;
    return (
        <>
            {hrEmail &&
            <Grid item xs={12} md={12}>
                <Alert severity="info"><b>Please contact {hrEmail} for any changes in the contact information</b></Alert>
            </Grid>
            }
            <Grid container spacing={3} style={{ marginTop: hrEmail != false ? '1px' : '0 !important' }}>
                <Grid item xs={12} sm={6}>
                    <Paper className={'contact-group'}>
                        <div className={'contact-group_head'}>
                            <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                                <PhoneIcon className={'title-icon'} fontSize="small" />
                                Phone
                            </Typography>
                        </div>
                        <ul className={'contact-list'}>
                            <li>
                                <div className={'contact-detils'}>
                                    <div className={'contact-type primary'}>Primary (Personal)</div>
                                    <div className={'contact-title'}>{phone}</div>
                                    <div className={'contact-content'}></div>
                                </div>
                            </li>
                        </ul>
                    </Paper>
                </Grid>

                {Object.keys(email).length ?
                    <Grid item xs={12} sm={6}>
                        <Paper className={'contact-group'}>
                            <div className={'contact-group_head'}>
                                <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                                    <EmailIcon className={'title-icon'} fontSize="small" />
                                    Email
                                </Typography>
                            </div>
                            <ul className={'contact-list'}>
                                {Object.keys(email).length && Object.keys(email).map((a, index) =>
                                <>
                                {(email[a].type == '2') ?
                                <li key={index}>
                                    <div className={'contact-detils'}>
                                        <div className={'contact-type personal'}>Personal</div>
                                        <div className={'contact-title'}>{email[a].email}</div>
                                        <div className={'contact-content'}></div>
                                    </div>
                                </li>
                                :
                                <></>
                                }
                                </>
                                )}
                            </ul>
                        </Paper>
                    </Grid>
                    : <></>
                }
                {Object.keys(address).length ?
                    <Grid item xs={12} sm={6}>
                        <Paper className={'contact-group'}>
                            <div className={'contact-group_head'}>
                                <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                                    <LocationIcon className={'title-icon'} fontSize="small" />
                                    Address
                                </Typography>
                            </div>
                            <ul className={'contact-list'}>

                                {Object.keys(address).length && Object.keys(address).map((a, index) =>
                                    <li key={index}>
                                        <div className={'contact-detils'}>
                                            <div className={'contact-type'}>{address[a].title}</div>
                                            <div className={'contact-title'}>{address[a].street}</div>
                                            <div className={'contact-content'}>
                                                {address[a].city}, {address[a].county ? address[a].county + ', ' : ''} {address[a].short_code ? address[a].short_code : address[a].state} {address[a].zipcode}
                                                <div>{address[a].country}</div>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Paper>
                    </Grid>
                    :
                    <></>
                }
                {Object.keys(emergencyInfo).length ?
                    <Grid item xs={12} sm={6}>
                        <Paper className={'contact-group'}>
                            <div className={'contact-group_head'}>
                                <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                                    <AnnouncementIcon className={'title-icon'} fontSize="small" />
                                    Emergency Contact
                                </Typography>
                            </div>
                            <ul className={'contact-list'}>
                                {Object.keys(emergencyInfo).length && Object.keys(emergencyInfo).map((a, index) =>
                                    <li key={index}>
                                        <div className={'contact-detils'}>
                                            {emergencyInfo[a].type === 'Primary' && <div className={'contact-type primary'}>Primary</div>}
                                            <div className={'contact-title'}>{emergencyInfo[a].contact_name} <div className={'title-sub'}>{emergencyInfo[a].relationship}</div></div>
                                            <div className={'contact-content'}>
                                                {emergencyInfo[a].contact_phone && <span className={'contact-content_phone'}>{_cs.formatPhone(emergencyInfo[a].contact_phone)}</span>}
                                                {emergencyInfo[a].contact_email && <span className={'contact-content_email'}>{emergencyInfo[a].contact_email}</span>}
                                            </div>
                                            <div className={'contact-content'}>
                                                {emergencyInfo[a].notes && <span>{emergencyInfo[a].notes}</span>}
                                            </div>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </Paper>
                    </Grid>
                    : <></>
                }
            </Grid>
        </>
    );
};
