import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';


import {DialogBoxMedium} from '../common/dialog.medium';

export const StatesComponent= (props) => { 
        return (
            <>
               <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">States</Typography>
                    {(props.EditPermission) &&
                    <Tooltip title="Add State" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} >
                            <AddIcon onClick={props.OnAddStateClick}/>
                        </Fab>
                    </Tooltip>
                    }
                </div>
                <div className={'section-content roles-table'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>State Name</TableCell>
                                <TableCell>Short Code</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.States.map((ry,i) =>    
                            <TableRow hover>
                                <TableCell>{ry.name}</TableCell>   
                                <TableCell>{ry.short_code}</TableCell>
                                <TableCell>{ry.c_name}</TableCell>
                                <TableCell align="right">
                                    {(parseInt(ry.default) !== 1 && props.EditPermission) &&
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                            <EditIcon fontSize="small" onClick={props.EditStateClick(ry.id)} />
                                        </IconButton>
                                    </Tooltip>                                  
                                    }
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                </div>
                
                {props.PopupType === 'add' &&
                <DialogBoxMedium 
                    Open= { props.Open }  
                    Cancel = { props.OnClose }
                    Title = { props.IsEditable ? 'Edit State' : 'Add State' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogContent FormProps = { props }/> }
                />
                }
              
            </>
        )
}

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.name && true }
                            helperText = { Errors.name }
                        />
                        <TextField variant="standard"
                            required
                            id = "short_code"
                            name = "short_code"
                            label = "Short Code"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.short_code : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.short_code && true }
                            helperText = { Errors.short_code }
                        />
                       </ FormControl>
                       <FormControl variant="standard">
                       <InputLabel htmlFor="country">Country</InputLabel>
                        <Select
                            required
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.country_id : '' }           
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'country_id',
                                id: 'country_id',
                            }}>
                            {props.FormProps.Countries.map((jt, i) =>
                                <MenuItem value={jt.id} key={'sub-type-'+i}>{jt.name}</MenuItem>
                            )}
                        </Select>
                       {Errors.country_id && <FormHelperText>{Errors.country_id}</FormHelperText> }
                    </ FormControl>
                </Grid>               
            </Grid>
        </form>
    )
}
