import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import { CustomizedSnackbars } from '../../common/snackbars';
import Paper from '@mui/material/Paper';

const API_URL = process.env.REACT_APP_API_URL;
export const ImportExpenseComponent = (props) => {
    return (
        <React.Fragment>
            {!props.permisionError &&
                <form className="form-fullwidth" onSubmit={props.handleSubmit} encType="multipart/form-data">
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Import Expense
                        </Typography>
                    </div>
                    <Paper> 
                        <div className={'p1'}>
                            <Grid container spacing={3} className={'d-flex'}>
                                <Grid item xs={12} md={2}>
                                    <InputLabel htmlFor="expense_type" required >Upload Expense</InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <input type="file" name="file" id="file" onChange={props.onFileChange} />
                                    <Button variant="contained" size="small" color="primary" type="submit" >
                                        <span className={'MuiButton-startIcon MuiButton-iconSizeSmall'}><SaveIcon /></span>
                                        {'Import'}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <img src={API_URL + 'img/Expense_Voucher_Template_New.png'} alt="image" height="100px" width="100px" />
                                    <p><a href={API_URL + 'downloads/Expense_Voucher_New_Template_New.xlsx'}>Click here to</a> download the latest Expense Sheet template.</p>
                                </Grid>
                            </Grid>
                                        </div>
                        </Paper>
                </form>}
            <CustomizedSnackbars
                open={props.dialogOpen}
                variant={props.dialogVarient}
                message={props.dialogMessage}
                handleClose={props.snakbarClose}
            />

            <p className='error' align='center'>{props.permisionError}</p>
        </React.Fragment>
    )
};
