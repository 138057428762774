import React from 'react';
import { RolesComponent } from './Roles.Component';
import { httpServices } from '../../../../../services/httpServices';
import { CustomProgressBar } from '../../../../common/CustomProgressBar';

export class RolesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            showLoader: true,
        }
    }
    componentDidMount() {
        this.getRoleDetails(this.props.UserId);
    }
    getRoleDetails = (userId) => {
        let apiEndpoint = 'api/user/listroles';
        return httpServices.post(apiEndpoint, { id: userId })
            .then(response => {
                if (response.data) {
                        this.setState({
                            roles: response.data.roles,
                            showLoader: false,
                        });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    render() {
        return (
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                <RolesComponent
                    Roles={this.state.roles}
                />
            </>
        )
    }
}
