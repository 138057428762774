import React from 'react';
import {UtilizationReportComponent} from './UtilizationReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as validations from '../../common/validate';

const API_URL = process.env.REACT_APP_API_URL;
const filterFields = {
    'year': new Date().getFullYear(),
    'component': 0,
    'staff': 0,
}
const filters = {
}

export class UtilizationReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            cap_components : [],
            history_info: [],
            open: false,
            filterFields: filterFields,
            filters: filters,
            name_format:'',
            loader: true,
            errors: {}
        }
        this.exportReports = this.exportReports.bind(this);
    }

    componentDidMount = () => {
        this.getUtilization();
        window.scrollTo(0, 0);
    };
    getUtilization = () => {
        this.setState({
            loader : true,
            });
        let data = this.state.filterFields;
        return httpServices.post('api/report/utilization', data)
            .then(response => {
                if (response.data && !response.data.errors) {
                    this.setState({
                        cap_components: response.data.cap_components,
                        filters: response.data.filters,
                        permission : response.data.permission,
                        name_format: response.data.name_format,
                        loader: false,
                    });
                } else if (response.data.errors) {
                    this.setState({
                        loader: false,
                        errors: response.data.errors
                    });
                }
                else {
                    this.setState({ loader: false ,
                                    permission : response.data.permission });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    };
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
        let url = API_URL+'api/report/utilization-export/'+this.state.filterFields.year+"/"+this.state.filterFields.component+"/"+this.state.filterFields.staff;
        window.location.assign(url);
    }
}

    validateRequest() {
        let validationData = [
            { name: 'year', value: this.state.filterFields.year, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'component', value: this.state.filterFields.component, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'staff', value: this.state.filterFields.staff, type: 'alphanumeric', otherOptions: { required: false } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    showHistory = (staff_id, component_id) => {
        let year = this.state.filterFields.year;
        let data = {staff_id : staff_id, component_id : component_id, year: year}
        return httpServices.post('api/report/utilization-history', data)
            .then(response => {
                if (response.data) {
                    this.setState({
                        history_info : response.data,
                        open : true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onClose = () =>{
        this.setState({
            open: false
        });
    }
    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        if(name === 'year') {
            this.getStaffs(evt.target.value);
        }
        this.setState({
            filterFields: f
        });
    }
    getStaffs = (year) => {
        if (year) {
            return httpServices.post('api/report/utilization', {year: year})
                .then(response => {
                    if (response.data.filters) {
                        this.setState({
                            filters: response.data.filters,
                            name_format : response.data.name_format
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
            });
        }
    }
    clearFilter = () => {
        let filterFields = {
            'year': new Date().getFullYear(),
            'component': 0,
            'staff': 0,
        }
        this.setState({
            filterFields: filterFields
        }, function(){
                this.getUtilization();
        });

    }
    render(){
        return(<>

                <CustomProgressBar
                   loaderOpen={this.state.loader}
                />
            {(this.state.permission) ?
                <UtilizationReportComponent
                    showHistory = {this.showHistory.bind(this)}
                    ExportReports = {this.exportReports}
                    onClose = {this.onClose.bind(this)}
                    Errors = {this.state.errors}
                    HandleFilterChange = {this.handleFilterChange.bind(this)}
                    GetUtilization = {this.getUtilization.bind(this)}
                    ClearFilter = {this.clearFilter.bind(this)}
                    Errors = {this.state.errors}
                    {...this.state}
                />
                :
                <ErrorPage/>}
            </>
            )
    }
}
