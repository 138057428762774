import React from 'react';
import { ClientListComponent} from './ClientList.Component';
import { httpServices } from '../../../services/httpServices';
import Cookies from 'universal-cookie'
import { history } from '../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

let filterValues = {
    status : 1,
    type : 0
};
const cookies = new Cookies();

export class ClientListContainer extends React.Component{
    constructor(props) {
        let filters = filterValues;
        let filterCookie = cookies.get('filter');
        if (filterCookie) {
            if (filterCookie.client) {
                filters = filterCookie.client;
            } else {
                filterCookie.client = filterValues;
            }
        }
        else {
            filterCookie = {'client' : filterValues}
        }
        super(props);
        this.state = {
            permission : false,
            filterInputs : [],
            filterValues : filters,
            showLoader : true,
            projects :[],
            right: false,
            popupType : '',
            dialogOpen : false,
            snackbarOpen : false,
            snackbarMsg : '',
            editId : 0,
            editPermission : false,
            selfEditPermission : false,
            dataLoaded : false,
            loggedUser : 0,
            client : [],
            status : [],
            clientTypes : [],
            open : false,
            errors : [],
            filterCookie: filterCookie,
        };
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.getClientList = this.getClientList.bind(this);
        this.addClient = this.addClient.bind(this);
        this.updateParentOnSuccess = this.updateParentOnSuccess.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);

    }
   
    componentDidMount() {
        this.getClientList();
    }

     toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

   addClient = () => {
        this.setState({
            popupType: "add",
            open: true,
            editId : 0
        });
    };

    getClientList = () => {
        let filterCookie = this.state.filterCookie;
        filterCookie.client = this.state.filterValues;
        cookies.set('filter', filterCookie, { path: '/' });
        this.setState({
            filterCookie : filterCookie,
        });
        const data = this.state.filterValues;
        this.setState({
            right: false,
            showLoader : true,
        });
        let apiEndpoint = 'api/client/list';
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            showLoader : false,
                            clients : response.data.clients,
                            status : response.data.status,
                            clientTypes : response.data.client_types,
                            filterInputs : response.data.filters,
                            permission : response.data.permission,
                            selfEditPermission : response.data.self_edit,
                            editPermission : response.data.edit_permission,
                            loggedUser : response.data.logged_user,
                            dataLoaded : true,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('clients/view/' + id);
        }
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterValues;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterValues: f
        });
    }

    handleEditClick = (id) => {
        this.setState({
            popupType : 'edit',
            open : true,
            editId : id,
        });
    }
    
    resetFilter = () => {
        let f = {
            status : 1,
            type : 0
        };
        filterValues = f;
        this.setState({
            filterValues: f
        },() => {this.getClientList();});
    }

    updateParentOnSuccess = (status) => {
        this.setState({open : status});
        this.getClientList();
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <ClientListComponent
                        Clients = {this.state.clients}
                        Status = {this.state.status}
                        ClientTypes = {this.state.clientTypes}
                        AddClient = {this.addClient}
                        HandleEditClick = {this.handleEditClick}
                        Open = {this.state.open}
                        PopupType = {this.state.popupType}
                        HandleViewDetails ={this.handleViewDetails}
                        EditId = {this.state.editId}
                        UpdateParentOnSuccess = {this.updateParentOnSuccess}
                        FilterValues = {this.state.filterValues}
                        FilterInputs={this.state.filterInputs}
                        toggleDrawer={this.toggleDrawer}
                        Right={this.state.right}
                        HandleFilterChange={this.handleFilterChange.bind(this)}
                        SubmitFilter={this.getClientList}
                        ResetFilter = {this.resetFilter}
                        EditPermission = {this.state.editPermission}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                :<></>
            }
            </>
        )
    }
}
