import React  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { formatName } from '../../common/common';


const API_URL = process.env.REACT_APP_API_URL;
export const RequestList  = props => {
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const handleChangePage = (event, newPage) => {
      setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    return (
        <React.Fragment>
                <TableContainer component={Paper}>
                <Table  aria-label="Timesheet List" className={'list-table_basic steps-table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {(props.Tab !== 'my') && (props.Requests.length !== 0) &&
                            <TableCell>Staff Name</TableCell>
                            }
                            <TableCell>Location</TableCell> 
                            <TableCell>Date</TableCell> 
                            <TableCell>Description</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Saved Hours</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>Status</TableCell>
                            {(props.Tab !== 'my') && (props.Requests.length !== 0) &&
                            <TableCell>Submitted by</TableCell>
                            }
                            <TableCell>Submitted on</TableCell>
                            <TableCell>Reviewed by</TableCell>
                            <TableCell>Reviewed on</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                         {(props.Requests) && (props.Requests.length === 0) &&
                            <TableRow >
                                <TableCell colSpan="5">No Work Location Change Requests found.</TableCell>
                            </TableRow>
                        }
                        
                        {props.Requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                        <TableRow hover>
                            <TableCell><a className={'item-link'} onClick={()=>props.HandleViewClick(row.id)}>{row.id}</a></TableCell>
                            {(props.Tab !== 'my') &&
                            <TableCell>{formatName(row.cby_first_name, null, row.cby_last_name)}</TableCell>
                            }
                            <TableCell>{row.location}</TableCell>
                            {(row.start_date !== row.end_date) ?
                             <TableCell  width = '200px'>{row.start_date}-{row.end_date}</TableCell>
                                    :
                            <TableCell>{row.start_date}</TableCell>
                            }
                            {(row.description.length > 20) ? 
                            <TableCell>{row.description.substring(0, 20)}...</TableCell>
                            :
                            <TableCell>{row.description.substring(0, 20)}</TableCell>
                            }
                            <TableCell>{row.hours}</TableCell>
                            <TableCell>{row.employee_total_hours}</TableCell>
                            <TableCell>{row.client_project}</TableCell>                           
                            <TableCell>{row.request_status_name}</TableCell>
                             {(props.Tab !== 'my') &&
                            <TableCell>{formatName(row.cby_first_name, null, row.cby_last_name)}</TableCell>
                            }

                            <TableCell>{row.submitted_date}</TableCell>
                            <TableCell>{row.rev_name}</TableCell>
                            <TableCell>{row.reviewed_on}</TableCell>
                            <TableCell align="right" width = '200px'>
                             {(props.Tab === 'my') && (props.Logged_user === parseInt(row.user_ids) && parseInt(row.status) !== 4 && props.LocationEditPermission) &&
                             <Tooltip title="Edit" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                    <EditIcon fontSize="small" onClick={()=>props.HandleEditClick(row.id)}/>
                                    </IconButton>
                                </Tooltip>
                             }
                                <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                    <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(row.id)}/>
                                    </IconButton>
                                </Tooltip>
                            {(row.attachement_id != null) &&
                                <IconButton   className={'action-btn'} edge="end" aria-label="Attachment">
                                        <a href = {API_URL+'api/request/timeoff/download?id='+ row.attachement_id}>  <AttachmentIcon  fontSize="small"/> </a>
                                    </IconButton>
                             }
                            </TableCell>

                        </TableRow>
                        )}
                    </TableBody>
                </Table>
              </TableContainer>
         <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={props.Requests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

        </React.Fragment>
    )
}

