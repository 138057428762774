import { createSlice } from '@reduxjs/toolkit';
import { httpServices } from '../../../services/httpServices';

export const systemConfig = createSlice({
  name: 'config',
  initialState: {
    configurations: {}
  },
  reducers: {
    setSystemConfigs: (state, action) => {
      state.configurations = action.payload;
    
    }
  }
})

export const setSystemConfigurations = () => (dispatch) => {
  httpServices.get('api/system/get-configurations')
  .then(response => {
      if (response.data) {
        dispatch(setSystemConfigs(response.data))
      }
  })
  .catch(function (error) {
    console.log("Error: "+error);
  });   
  }

// Action creators are generated for each case reducer function
export const { setSystemConfigs } = systemConfig.actions
export default systemConfig.reducer