import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import FormHelperText from '@mui/material/FormHelperText';
const API_URL = process.env.REACT_APP_API_URL;
const BackDrop = styled(Backdrop) (({ theme }) => ({
    '& .backdrop': {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const customButton = (props) => { 
    return (<form id="export_form" action={API_URL + 'api/report/daily-hours-summary-export'} method="post">
        <input type="hidden" id="exp_employee_id" name="exp_employee_id" /> 
        <input type="hidden" id="exp_project_id" name="exp_project_id" /> 
        <input type="hidden" id="exp_client_id" name="exp_client_id" /> 
        <input type="hidden" id="exp_start_date" name="exp_start_date" /> 
        <input type="hidden" id="exp_end_date" name="exp_end_date" /> 
        <Button variant="contained" color="primary" size='small'>Export</Button></form>);
}
export const DailyHoursSummaryReportComponent = (props) => {
    let projects = (typeof props.projects !== 'undefined') ? props.projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof props.filterFields.project_id !== 'undefined') ? [props.filterFields.project_id] : []));
    }
    const gridStyle = {
        margin: '0px'
    };
    const errorStyle = {
        color: 'red'
    };
    const titleStyle = {
        fontSize: 'large',
        paddingBottom: '20px',
        fontWeight: 500
    };
    const buttonAlignmentStyle = {
        justifyContent: 'flex-end'
    };
    const columns = [
        { title: 'Date', field: 'timeslice_date', render: rowData =><>{rowData.issummaryrow ? <b>{rowData.timeslice_date}</b> : <>{rowData.timeslice_date}</>}</>},
        { title: 'Working', field: 'WH', render: rowData =><>{rowData.issummaryrow ? <b>{rowData.WH}</b> : <>{rowData.WH}</>}</>},
        { title: 'Holiday', field: 'HO', render: rowData =><>{rowData.issummaryrow ? <b>{rowData.HO}</b> : <>{rowData.HO}</>}</> },
        { title: 'Time Off', field: 'TO', render: rowData =><>{rowData.issummaryrow ? <b>{rowData.TO}</b> : <>{rowData.TO}</>}</> },
        { title: 'Non Billable', field: 'NB', render: rowData =><>{rowData.issummaryrow ? <b>{rowData.NB}</b> : <>{rowData.NB}</>}</> },
        { title: 'Break Time', field: 'BR',render: rowData =><>{rowData.issummaryrow ? <b>{rowData.BR}</b> : <>{rowData.BR}</>}</>},
        { title: 'Hours', field: 'timeslice_hours',render: rowData =><>{rowData.issummaryrow ? <b>{rowData.timeslice_hours}</b> : <>{rowData.timeslice_hours}</>}</>},  
    ];    
   
    let dataArr = (typeof props.summaryData !== 'undefined' && props.summaryData.length > 0) ?  props.summaryData : [];
    if(typeof props.totalData !== 'undefined')
    { 
        let lastItem = dataArr.slice(-1)[0]; 
        if(typeof lastItem !== 'undefined' && typeof lastItem.issummaryrow === 'undefined')
        { 
            dataArr.push ({'timeslice_date' :'Total', 
            'WH' : (props.totalData.WH && props.totalData.WH != '') ? parseFloat(props.totalData.WH).toFixed(2) : '', 
            'HO' : (props.totalData.HO && props.totalData.HO != '') ? parseFloat(props.totalData.HO).toFixed(2) : '', 
            'TO' : (props.totalData.TO && props.totalData.TO != '') ? parseFloat(props.totalData.TO).toFixed(2) : '', 
            'NB' : (props.totalData.NB && props.totalData.NB != '') ? parseFloat(props.totalData.NB).toFixed(2) : '', 
            'BR' : (props.totalData.BR && props.totalData.BR != '') ? parseFloat(props.totalData.BR).toFixed(2) : '', 
            'timeslice_hours': (props.totalData.timeslice_hours && props.totalData.timeslice_hours != '') ? parseFloat(props.totalData.timeslice_hours).toFixed(2) : '', 
            'issummaryrow' : true});
        }         
    }
    return (
        <>
        <Paper className={'mb-1'}>
        <div class="p1">
            <div className={'d-flex justify-between'}>
                <Grid container spacing={3} style={gridStyle}>
                <div className = {'section-title'} style={titleStyle}>Daily Hours Summary</div>
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                            
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={3}>
                                    <FormControl error={props.Errors.emp_status && true} variant="standard"> 
                                        <InputLabel htmlFor="emp_status">Staff Status</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'emp_status',
                                                id: 'emp_status',
                                            }}
                                            value={(typeof props.filterFields.emp_status !== 'undefined') ? props.filterFields.emp_status : 0}
                                            onChange={props.HandleFilterChange}
                                        > 
                                        <MenuItem key={0} value={0}>--All--</MenuItem>
                                        {_R.type(props.employeeStatus) !== 'Undefined' && props.employeeStatus.map((emp_status, i) =>
                                            <MenuItem key={emp_status.user_status_id} value={emp_status.user_status_id}>{emp_status.user_status_name}</MenuItem>
                                        )}
                                        </Select>
                                        <FormHelperText >{props.Errors.emp_status}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl error={props.Errors.employee_id && true} variant="standard">
                                        <InputLabel htmlFor="employee_id" required>Staff</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'employee_id',
                                                id: 'employee_id',
                                            }}
                                            value={(typeof props.filterFields.employee_id !== 'undefined') ? props.filterFields.employee_id : 0}
                                            onChange={props.HandleFilterChange}
                                            helperText={props.Errors.employee_id}
                                        > 
                                        <MenuItem key={0} value={0}>--Select--</MenuItem>
                                        {_R.type(props.employees) !== 'Undefined' && props.employees.map((emp, i) =>
                                            <MenuItem key={emp.user_id} value={emp.user_id}>{emp.first_name+' '+emp.last_name}</MenuItem>
                                        )}
                                        </Select>
                                        <FormHelperText >{props.Errors.employee_id}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl error={props.Errors.client_id && true} variant="standard">
                                        <InputLabel htmlFor="client_id">Client</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: 'client_id',
                                                id: 'client_id',
                                            }}
                                            value={(typeof props.filterFields.client_id !== 'undefined') ? props.filterFields.client_id : 0}
                                            onChange={props.HandleFilterChange}
                                        > 
                                        <MenuItem key={0} value={0}>--All--</MenuItem>
                                        {_R.type(props.clients) !== 'Undefined' && props.clients.map((client, i) =>
                                            <MenuItem key={client.client_id} value={client.client_id}>{client.client_name}</MenuItem>
                                        )}
                                        </Select>
                                        <FormHelperText >{props.Errors.client_id}</FormHelperText>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                <InputLabel htmlFor="project_id" className="MuiInputLabel-shrink">Project</InputLabel>
                                    <FormControl>
                                        <TreeSelectComponent
                                            dropdown_mode={'true'}
                                            data={typeof props.projects ? props.projects : []}
                                            HandleTreeChange={props.HandleTreeChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={2}>
                                    <FormControl>
                                        <TextField variant="standard"
                                            id = "start_date"
                                            name = "start_date"
                                            label = "Start Date"
                                            value = {props.filterFields.start_date}
                                            type = "date"
                                            required
                                            onChange = {props.HandleFilterChange}
                                            error={props.Errors.start_date && true}
                                            helperText={props.Errors.start_date}
                                            InputLabelProps = {{
                                                shrink: true,
                                            }}
                                        />
                                    </ FormControl>
                                </Grid>
                                <Grid item md={2}>
                                    <FormControl>
                                        <TextField variant="standard"
                                            id = "end_date"
                                            name = "end_date"
                                            label = "End Date"
                                            value = {props.filterFields.end_date}
                                            type = "date"
                                            required
                                            onChange = {props.HandleFilterChange}
                                            error={props.Errors.end_date && true}
                                            helperText={props.Errors.end_date}
                                            InputLabelProps = {{
                                                shrink: true,
                                            }}
                                        />
                                    </ FormControl>
                                </Grid>                                
                                <Grid className={'d-flex'} item xs={6} md={6} style={buttonAlignmentStyle}>
                                    <Button variant="contained" size="small" color="primary" className={'mr-1'} onClick={props.FilterData}> Filter</Button>
                                    <Button color = "primary" size = "small" variant = "outlined" onClick = {props.ClearFilter}>Reset</Button>
                                </Grid>
                            </Grid>
                        </form>
                        
                    </Grid>
                </Grid>

            </div>
            </div>
        </Paper>

        {props.showList && <CustomMaterialTable
            title= "Daily Hours Summary"
            columns={columns}
            data={dataArr}
            options={{
                search:false,
                actionsColumnIndex: -1,
                paging: false,
                maxBodyHeight: '500px',
            }}
            actions={[
                {
                    icon: customButton,
                    tooltip: 'Export',
                    isFreeAction: true,
                    onClick: (event, newData) => { props.ExportReports() },
                    hidden: (dataArr.length > 0) ? false : true, 
                }
            ]}
        />
        }
        <BackDrop className={'backdrop'} open={props.loader}>
           <CircularProgress color="inherit" />
         </BackDrop>
        </>
    )
};
