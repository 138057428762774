import {setSystemConfigurations} from '../store/reducers/systemConfig';
import { useSelector, useDispatch} from 'react-redux';

export const GetConfig = (configElement) => {
    const dispatch = useDispatch();
    let configValue = useSelector(state => state.systemConfig.configurations);
    if (configValue[configElement]) {
        return configValue[configElement];
    }
    else {
        dispatch(setSystemConfigurations());
    }   
};
export default GetConfig;