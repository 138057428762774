import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Autocomplete from '@mui/material/Autocomplete';
import { formatName } from '../../common/common';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import FormHelperText from '@mui/material/FormHelperText';

const StyledPaper = styled(Paper) (({ theme }) => ({
    '& .backdrop': {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    '& .head': {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    '& .root': {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const API_URL = process.env.REACT_APP_API_URL;
export const TimesheetDelinquencyReportComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    let Users =(_R.type(props.ResultSet.result) !== 'Undefined' && _R.type(props.ResultSet.result.usersDataList !== 'Undefined')) ? props.ResultSet.result.usersDataList : [];
    let Headings =(_R.type(props.ResultSet.result) !== 'Undefined' && _R.type(props.ResultSet.result.headings !== 'Undefined')) ? props.ResultSet.result.headings :[];
    let Data =(_R.type(props.ResultSet.result) !== 'Undefined' && _R.type(props.ResultSet.result.data !== 'Undefined')) ? props.ResultSet.result.data : [];
    let ColorCode = (_R.type(props.ResultSet.result) !== 'Undefined' && _R.type(props.ResultSet.result.color_code !== 'Undefined')) ? props.ResultSet.result.color_code : [];
    return (
        <React.Fragment>
        <StyledPaper>
            <Grid container spacing={3} style={gridStyle}>
                    <Grid item>
                        <h6 class="MuiTypography-root section-title MuiTypography-h6">Timesheet Delinquency Report</h6>
                    </Grid>
                    {(props.ErrorMessage) &&
                        <div className={'root'}>
                            <Alert severity="error"> {props.ErrorMessage} </Alert>
                        </div>
                    }
                    <Grid item xs={12}>
                        <form className="form-fullwidth" action={API_URL+'api/report/timesheet-delinquency-report'} method="post" id="delinquency">
                            <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={2}>
                                    <FormControl>
                                        <CustomDatePicker
                                            id = "start_date"
                                            name = "start_date"
                                            label = "Start Date"
                                            required = {true}
                                            className = {'mt-0'}
                                            selectedDate = {props.FormFields.start_date}
                                            handleDateChange = {props.HandleDateChange}
                                            error={props.Errors.start_date}
                                            helperText = { props.Errors.start_date }
                                            maxDate = {(typeof props.FormFields.end_date !== 'undefined') ? props.FormFields.end_date : null}
                                        />
                                    </ FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                    <FormControl>
                                        <CustomDatePicker
                                            id = "end_date"
                                            name = "end_date"
                                            label = "End Date"
                                            required = {true}
                                            className = {'mt-0'}
                                            selectedDate = {props.FormFields.end_date}
                                            handleDateChange = {props.HandleDateChange}
                                            error={props.Errors.end_date}
                                            helperText = { props.Errors.end_date }
                                            maxDate = {(typeof props.Filters.currentDate !== 'undefined') ? props.Filters.currentDate : null}
                                            minDate = {(typeof props.FormFields.start_date !== 'undefined') ? props.FormFields.start_date : null}
                                        />
                                     </ FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                <FormControl error={props.Errors.staff_status && true} variant="standard">
                                            <InputLabel htmlFor="staff_status">Staff Status</InputLabel>
                                            <Select
                                                value={props.FormFields.staff_status ? props.FormFields.staff_status : 0}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'staff_status',
                                                    id: 'staff_status',
                                                }}>
                                                <MenuItem value="0">All</MenuItem>
                                                {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.staffStatusList) !== 'Undefined' && props.Filters.staffStatusList.map((staff_status, i) =>
                                                    <MenuItem key={'staff_status-'+i} value={staff_status.user_status_id}>{staff_status.user_status_name}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText >{props.Errors.staff_status}</FormHelperText>
                                </ FormControl>
                                </Grid>
                                <Grid item  md={3}>
                                <FormControl error={props.Errors.name_format && true} variant="standard">
                                    <InputLabel htmlFor="name_format">Report Name Format</InputLabel>
                                    <Select
                                        value={ typeof props.FormFields.name_format !== 'undefined' ? props.FormFields.name_format  :''}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                        name: 'name_format',
                                        id: 'name_format',
                                        }}>
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.nameFormats) !== 'Undefined' && props.Filters.nameFormats.map((k, i) =>
                                        <MenuItem value={k.key}>{k.value}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText >{props.Errors.name_format}</FormHelperText>
                                </ FormControl>
                            </Grid>
                             <input type='hidden' name='excluded_resources' value={props.FormFields.excluded_resources}/>
                              <Grid item md={5}>
                                <FormControl>
                                <Autocomplete
                                    value = {(typeof props.FormFields.excluded_staff !== 'undefined' && props.FormFields.excluded_staff) ? props.FormFields.excluded_staff  : []}
                                    onChange={props.handleRecipientChange}
                                    multiple
                                    id="exculded_resources"
                                    options={ (typeof props.Filters.staff_list !== 'undefined') ? props.Filters.staff_list  : []}
                                    getOptionLabel={(option) => formatName(option.first_name, null, option.last_name)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Exclude Resources"
                                    placeholder="Exclude Resources"
                                    />
                                    )}
                                />
                                 {typeof props.FormFields.excluded_staff !== 'undefined' && props.FormFields.excluded_staff.length > 0 &&
                                 <div>{props.FormFields.excluded_staff.length} resource excluded</div>
                                 }
                                 </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={2} className = {'filter-action'}>
                                <div className="filter-column">
                                    <div className="d-flex align-center justify-end">
                                    <Button variant="contained" size="small" color="primary" onClick={props.OnSubmit}> Submit </Button>
                                    <Button className={'ml-1'} variant="contained" size="small" color="primary"  onClick={props.HandleExportDelinquencyReport}> Export <input type="hidden" name="action" value={"export_btn"} /> </Button>
                                </div>
                                </div>
                            </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
        </StyledPaper>
        {(_R.type(props.ResultSet.result) !== 'Undefined')&&
        <Paper>
            <div className={'mb-1'}>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="simple table" border="1" className={'list-table_basic'}>
                        {(Users !== 'Undefined' && Users.length !== 0)?
                        <>
                        <TableHead className={'thead'}>
                        <TableRow>
                        {(Headings).map((head, j) => (
                            <TableCell > {head} </TableCell>
                        ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(Data).map((data, i) => 
                                <TableRow>
                                {Object.keys(Data[data]).map((k, j)=>
                                    (j==0)?
                                    <TableCell colSpan={2} bgColor={k}> {Data[data][k]} </TableCell>
                                    :
                                    <TableCell> {Data[data][k]} </TableCell>
                                )}
                                </TableRow>
                            )}
                            {Object.keys(Users).map((user, m) =>
                                <TableRow>
                                    {Object.keys(Users[user]).map((n, g) =>
                                    <TableCell bgColor={( Users[user][n] =='N/A') ? 'white' : ColorCode[Users[user][n]]}> {Users[user][n]} </TableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                        </>
                        :
                        <TableRow>
                            <TableCell> No records found  </TableCell>
                        </TableRow>}
                        </Table>
                </TableContainer>
            </div>
        </Paper>
        }
          <Backdrop className={'backdrop'} open={props.LoaderOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    )
}
