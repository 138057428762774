import React from 'react';
import {AddNoteComponent } from './AddNote.Component';
import {httpServices}  from '../../../../services/httpServices';

const formFields = {
    'id': 0,
    'staff_id':null,
    'note' : null,
    'category' : null,	
    'created_by':null,
    'created_on':null,
    'modified_by':null,
    'modified_on':null,
}
export class AddNoteContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            formFields:formFields,
            errors: {},
            title : 'Staff Notes',
            note  : [],
            isEdit:false,
            isDelete: false,
            updated:false,
            close:false,
            Open :true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
        this.props.OnCloseNote();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    submit =() => {
        if (this.handleValidation()) {
            this.saveNote();
        }
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.note) {
            errors["note"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    saveNote = () => {
        let data = this.state.formFields;
        const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        const category = this.props.Category;
        const Compen_component_id = this.props.Compen_component_id;
        data.staff_id = staffID;
        data.user_id = userID;
        data.category = category;
        data.Compen_component_id = Compen_component_id;   
        if(this.handleValidation()){
            httpServices.post('api/staff/note/save-notes', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({ note: response.data.note });
                            this.props.OnCloseNote();
                            this.clearForm();
                            alert("Successfully saved");
                        }
                    } else {
                        this.setState({ errors: response.data.errors });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                    alert("Something went wrong. Please try again later");
                });
        }
    }
    render(){
        return(
            <AddNoteComponent
            Open={this.props.OpenNote}	
            Close={this.onClose.bind(this)}
            HandleOnChange={this.handleOnChange}
            SaveNote={this.submit.bind(this)}
            Note={this.props.note}
            FormFields={this.state.formFields}
            Errors={this.state.errors}
            />
        )
    }
}
