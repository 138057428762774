import React from 'react';
import Badge from '@mui/material/Badge';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import * as _R from 'ramda';
import { formatName } from '../../common/common';

export const DashboardComponent = (props) => {
    let request_data = _R.type(props.RequestData) !== 'Undefined' ? props.RequestData : [];
    let request_config = _R.type(request_data.request_config_details) !== 'Undefined' ? request_data.request_config_details : [];
    return (
        <React.Fragment>
        <div className={'st-row'}>
            {request_data.my_action_items &&
                <div className={'st-aside'}>
                    <div className={'section-title'}>My Action Items</div>
                    <div className={'box-notification-list'}>
                        {request_config.map((config, index) =>
                            _R.gt(config.review_count, 0) &&
                            <div className={'list-item'}>
                                <div className={'item-header'}>
                                    <div>
                                    <div className={'title'}>
                                        <a onClick={()=>props.ViewList(config.code, 'review')} className='link'>{config.name} Requests</a> 
                                        &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={config.review_count} ></Badge>
                                    </div>
                                    <div className={'desc'}>Waiting for your review
                                    </div>
                                    </div>
                                    <div className="action">
                                        <span className={'table-info'}>
                                            <Tooltip title="Display in dashboard limited to oldest 5 records">
                                            <InfoIcon color="disabled" align="right"/>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                                <div className={'item-content'}>
                                    <div className={'item-list-container'}>        
                                        {config.review.map((row, rindex) =>
                                            <div className={'item'}>
                                                <a onClick={()=>props.ViewRequest(row.id, config.code)} className='link'>
                                                    <div className={'details'}>
                                                        <div className={'title'}>{row.request_users}{formatName(row.user_first_name, null, row.user_last_name)}</div>
                                                        <div className={'desc'}>
                                                            {config.code === 'overtime' ?
                                                                <span className='date'>Week: {row.week}</span>
                                                                : (config.code === 'additionalpto') ?
                                                                <span className='date'>Year: {row.year}</span>
                                                                : <span className='date'>Date: {row.request_date}</span>
                                                            }
                                                            {config.code === 'timeoff' ?
                                                                <span className='info'>{row.task_name} request submitted on {row.created_on}</span>
                                                                : <span className='info'>Submitted on {row.created_on}</span>
                                                            }
                                                            </div>
                                                    </div>
                                                    <div className={'action'}>View</div>
                                                </a>
                                            </div>
                                        )}   
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        }

        <div className={'st-col-main'}>
            <div className={'section-title'}>My Requests</div>
            <div className={'box-notification-list'}>
                {request_config.map((config, index) =>
                    _R.type(config.my_request) !== 'Undefined' &&
                    <div className={'list-item'}>
                        <div className={'item-header'}>
                            <div>
                            <div className={'title'}><a onClick={()=>props.ViewList(config.code, 'my')} className='link'>{config.name} Requests</a></div>
                            <div className={'desc'}></div>
                            </div>
                            <div className="action">
                                <span className={'table-info'}>
                                    <Tooltip title="Display in dashboard limited to oldest 5 records">
                                    <InfoIcon color="disabled" align="right"/>
                                    </Tooltip>
                                </span>
                            </div>
                        </div>
                        <div className={'item-content'}>
                            <div className={'item-list-container'}>
                                {config.my_request.map((row, index) =>
                                    <div className={'item'}>
                                        <div className={'details'}>
                                            <div className={'title'}><a onClick={()=>props.ViewRequest(row.id, config.code)} className='link'>
                                                {config.code === 'overtime' ? row.week : config.code === 'additionalpto' ? row.year : row.request_date}
                                            </a></div>
                                            <div className={'desc'}><a onClick={()=>props.ViewRequest(row.id, config.code)} className='link'>Submitted on {row.created_on}</a></div>
                                        </div>
                                        <div><span className={"value text-"+row.request_status_name.toLowerCase()}>{row.request_status_name}</span></div>
                                    </div>
                                )}
                                {config.my_request.length ===  0 &&
                                    <div className={'request-title'}> No {config.name} requests found</div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
</React.Fragment>    
)
};
