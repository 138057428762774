import React from 'react';

import { httpServices }  from '../../../services/httpServices';
import { PtoConfigComponent }  from './PtoConfig.Component.js';

let formFields = {
    id : 0,
    config : null,
    description : null,
    value : null,
    from_year : null,
    to_year : null,
    overrideId : 0
}
export class ConfigContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            config : [],
            timesheet_status : [],
            editPermission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant: ''
        }
        this.addConfigOverrideClick = this.addConfigOverrideClick.bind(this);
        this.editConfigClick = this.editConfigClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.editOverrideClick = this.editOverrideClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => {
        this.getallConfig();
    };

    getallConfig = () => {
        let id = this.props.timeoffId
        let apiEndpoint = 'api/settings/timeoff/getallconfig';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        config : response.data.config_details,
                        editPermission : response.data.edit_permission,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addConfigOverrideClick = (id) => {
        let isEdit = false
        this.setState({
            popupType : 'add',
            open : true,
        });
        this.editClick(id,isEdit);
    };

    editConfigClick = (id) => {
        let isEdit = true
        this.setState({
            popupType: 'edit',
            open: true,
        });
        this.editClick(id, isEdit);
    };
    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    handleSubmit = (event) => {
        const type = 'config';
        if (this.handleValidation(type)) {
            let data = this.state.formFields;
            data.timeoffTypeId = this.props.timeoffId;
            if (data) {
                 httpServices.post('api/settings/timeoff/editconfig', { data })
                    .then(response => {
                        if (response.data.permission) {
                            this.getallConfig();
                            let successMsg = ''
                            if (response.data.valid) {
                                if (data.id) {
                                    successMsg = 'Config updated successfully';
                                } else {
                                    successMsg = 'Config added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                        }                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    
    onAdd = (event) => {
        const type = 'override';
        if(this.handleValidation(type)) {
        let timeofftypeId = this.props.timeoffId
        let data = this.state.formFields;
        if (data) {
            httpServices.post('api/settings/timeoff/editoverride', { data, timeofftypeId })
                .then(response => {
                    if (response.data.permission) {
                        if (response.data.valid) {
                            if(response.data.status_flag) {
                            this.getallConfig();
                            let successMsg = ''
                            if (data.overrideId) {
                                successMsg = 'Override updated successfully';
                            } else {
                                successMsg = 'Override added successfully';
                            }
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: successMsg,
                                snackbarVariant: 'success',
                                open: false,
                                popupType: '',
                                formFields: {},
                                errors: {},
                            });
                            } else {
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: 'Duplicate entry not allowed',
                                    snackbarVariant: 'error'
                                });
                            }
                        }
                        else {
                            this.setState({
                                errors: response.data.errors,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log("error" + error);
                });
        }
        }
    };
    
    editOverrideClick = (id) => {
        let apiEndpoint = 'api/settings/timeoff/getoverride';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data) {
                    if (response.data.override_details) {
                        let formFields = {
                            overrideId: response.data.override_details.id,
                            config: response.data.override_details.config,
                            description: response.data.override_details.description,
                            value: response.data.override_details.value,
                            from_year: response.data.override_details.from_year,
                            to_year: response.data.override_details.to_year,
                        }
                        this.setState({
                            formFields: formFields,
                            open: true,
                            popupType: 'editoverride'
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };

    
    handleValidation(type) {
        let formIsValid = true;
        let errors = {};
        if (type === 'override') {
            if (this.state.formFields.from_year == null) {
                errors["from_year"] = "Cannot be empty";
                formIsValid = false;
            }
            if (this.state.formFields.to_year && parseInt(this.state.formFields.to_year) < parseInt(this.state.formFields.from_year)) {
                errors['to_year'] = "Year to cannot be less than Year from";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.value) {
           errors["value"] = "Cannot be empty";
           formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id, isEdit) => {
        let apiEndpoint = 'api/settings/timeoff/getconfig';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data) {
                    if (response.data.configuration_data) {
                        let formFields
                        if (isEdit) {
                            formFields = {
                                id: response.data.configuration_data.id,
                                config: response.data.configuration_data.config,
                                description: response.data.configuration_data.description,
                                value: response.data.configuration_data.value,
                                yearly_override: response.data.configuration_data.yearly_override,
                                staff_override: response.data.configuration_data.staff_override,
                            }
                        } else {
                            formFields = {
                                id: response.data.configuration_data.id,
                                config: response.data.configuration_data.config,
                            }
                        }
                       this.setState({
                           formFields : formFields,
                           open : true,
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <PtoConfigComponent
                Config = {this.state.config}
                TimesheetStatus = {this.state.timesheet_status}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddPConfigOverrideClick = {this.addConfigOverrideClick}
                EditConfigClick = {this.editConfigClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                EditOverrideClick = {this.editOverrideClick}
                OnSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                OnAdd = {this.onAdd}
                SnackbarVariant = {this.state.snackbarVariant}
            />
        )
    }
}
