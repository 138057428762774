import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {CityComponent}  from './City.Component';
import { ErrorPage } from '../common/error';

const formFields = {
    'id':0,
    'name': '',
    'state_id': 0,
};

export class CityContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            states : [],
            cities : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
        };
        this.editCityClick = this.editCityClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddCity = this.onAddCity.bind(this);
        this.getStateNameById = this.getStateNameById.bind(this);
    }
    componentDidMount = () => {
        this.getAllCities();
        this.getAllStates();
    };
    getAllStates = () => {
        let apiEndpoint = 'api/settings/getAllStates';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    if (response.data.states && response.data.states.length) {
                        this.setState({states : response.data.states});
                    } 
                }
            })          
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getAllCities = () => {
        let apiEndpoint = 'api/settings/getAllCities';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data && response.data.permission) {
                    let editPermission = false;
                    if (response.data.edit_permission) {
                        editPermission = true;
                    }
                    if (response.data.cities) {
                        this.setState({
                            cities : response.data.cities,
                            editPermission : editPermission,
                        });
                    } 
                } else {
                    this.setState({hasPermission : false});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    editCityClick = (id) => () => {
        let apiEndpoint = 'api/settings/get-city-details-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data && response.data.city_details) {
                        this.setState({
                            formFields : response.data.city_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getStateNameById = (id) => {
        if(this.state.states){
            let st_name = '';
            let states = this.state.states;
            states.forEach(function(item){
                if(item.id === id){
                    st_name = item.name;
                }
            });
            return st_name;
        }
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddCity = () => {
        let formFields = {
            'id':0,
            'name': '',
            'state_id':0,
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };
    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/settings/saveCity', { data })
                    .then(response => {
                        if (response.data.status) {
                            let added_state_name = this.getStateNameById(data.state_id);
                            if (data.id) {
                                // Update DOM
                                const index = this.state.cities.findIndex(ct => ct.id === data.id);
                                data['state_name'] = added_state_name;
                                let cities = this.state.cities;
                                cities[index] = data;
                                this.setState({
                                    states: cities,
                                    open: false
                                });
                                alert("City details updated successfully");
                            } else {
                                let cities = this.state.cities;
                                data['id'] = response.data.id;
                                data['state_name'] = added_state_name;
                                cities.push(data);
                                this.setState({
                                    cities: cities,
                                    open: false,
                                });
                                alert("City details saved successfully");
                            }
                        } else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                            else {
                                alert("Save failed. Duplicate city name not allowed");
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.state_id) {
            errors["state_id"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    
    render(){
        return (
            (this.state.hasPermission)
            ?
            <CityComponent
                States = {this.state.states} 
                Cities = {this.state.cities}
                EditCityClick = {this.editCityClick}
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddCityClick = {this.onAddCity}                
                PopupType = {this.state.popupType}
                EditPermission = {this.state.editPermission}
            />
            : <ErrorPage Message ={'You have no permission to view cities'}/>
        )
    }
}
