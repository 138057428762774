import React from 'react'
import Root from './root'
import './assets/styles/index.scss';
import ReactDOM from 'react-dom/client'

// create a root
const root = ReactDOM.createRoot(document.getElementById('root'));

//render app to root
root.render(<Root />);
