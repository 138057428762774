import React from 'react';
import { ErrorPage } from '../../common/error';
import {httpServices}  from '../../../services/httpServices';
import { ExpenseSettingsComponent }  from './Settings.Component.js';
import { CustomProgressBar } from '../../common/CustomProgressBar';

export class ExpenseSettingsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            expenseCodeView : false,
            expenseProjectView : false,
            mileageView : false,
            expenseLimitView : false,
            permission : false,
            showLoader : true,
            dataLoaded : false,
        }
    }

    componentDidMount = () => {
        let apiEndpoint = 'api/settings/expenses';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        expenseCodeView : response.data.expense_code_view_permission,
                        expenseProjectView : response.data.expense_project_view_permission,
                        mileageView : response.data.mileage_view_permission,
                        expenseLimitView : response.data.expense_limit_view_permission,
                        permission : true,
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
                else
                {
                   this.setState({
                        permission : false,
                       showLoader : false,
                       dataLoaded : true,
                    });
                }
            })
    };

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission)
                        ?
                        <ExpenseSettingsComponent
                            ExpenseCodeView = {this.state.expenseCodeView}
                            ExpenseProjectView = {this.state.expenseProjectView}
                            MileageView = {this.state.mileageView}
                            ExpenseLimitView = {this.state.expenseLimitView}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
