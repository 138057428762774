import React from 'react';
import {history} from './../../helpers/history';
import {httpServices}  from '../../services/httpServices';
import {ReportComponent}  from './ReportComponent';
import { ErrorPage } from '../common/error';
import MixpanelManager from  '../../services/Analytics';

export class Reports extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reports : [],
            hasPermission : true,
        };
        this.assignLink = this.assignLink.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount = () => {
        this.getAllReports();
        window.scrollTo(0, 0);
    };
    getAllReports = () => {
        let apiEndpoint = 'api/report/get-all-reports';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.reports && response.data.reports.length) {
                    this.setState({reports : response.data.reports});
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    assignLink = (link) => () => {
        if (link) {
            history.push(link); 
            this.MixpanelManager.trackEvent('Reports', {'module' : 'Reports', 'feature' : link, 'action' : 'clicked'});
        }
    };

    render(){
        return (
            (this.state.hasPermission)
            ?
            <ReportComponent
                Reports = {this.state.reports}
                assignLink = {this.assignLink}
            />
            :
            <ErrorPage/>
        )
    }
}
