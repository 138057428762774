import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const PreviewComponent = (props) => {
  return (
    <ClickAwayListener onClickAway={props.FilterView('right', false)}>
      <Stack open={props.FileOpen}>
        <Avatar variant="square"
          src={props.FileUrl}
          sx={{ width: "600px", height: "500px", position: "absolute", bottom: "0px", right: "0px" }}
        />
      </Stack>
    </ClickAwayListener>
  );
}
