import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {httpServices}  from '../../../services/httpServices'
import { ChangeHistory } from '../../requests/Common/ChangeHistory';
import TableContainer from '@mui/material/TableContainer';
import * as _R from 'ramda';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { TimeSliceReviewNotes } from './TimeSliceReviewNotes';

export class TimeSliceView extends React.Component {
    constructor(props) {
        super(props);
          this.state ={
            current : '',
            changeLogs : '',
            comments : '',
            loader : true,
        }
    }
     componentDidMount() {
        let apiEndpoint = 'api/timesheet/details';
        httpServices.post(apiEndpoint, {'id' : this.props.id})
            .then(response => {
                    let inputs = response.data;
                    this.setState({
                            current : inputs.current,
                            changeLogs : inputs.changelogs,
                            comments : inputs.comments,
                            loader : false,
                    });
            })            
    }
     render(){
    return (
         (this.state.loader)
            ?
            <CustomProgressBar
                loaderOpen={this.state.loader}
            />:
          <>
        <Paper className={'mb-1'}>
               <TableContainer>
            <Table stickyHeader aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell><strong>Date</strong><div>{this.state.current.timeslice_date}</div></TableCell>
                        <TableCell><strong>Client</strong><div>{this.state.current.client_name}</div></TableCell>
                        <TableCell colSpan={2}><strong>Project</strong><div>{this.state.current.project_name}</div></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><strong>Task</strong><div>{this.state.current.task_name}</div></TableCell>
                        <TableCell><strong>Task Type</strong><div>{this.state.current.tasktype}</div></TableCell>
                        <TableCell><strong>Hours</strong><div>{this.state.current.timeslice_hours}</div></TableCell>
                        <TableCell><strong>Location</strong><div>{this.state.current.location_name}</div></TableCell>
                    </TableRow>
                     <TableRow>
                        <TableCell colSpan={4}><strong>Description</strong><div>{this.state.current.timeslice_log}</div></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><strong>Status</strong><div>{this.state.current.timesheet_status_name}</div></TableCell>
                        <TableCell><strong>Created On</strong><div>{this.state.current.timeslice_cdate}</div></TableCell>
                        <TableCell><strong>Modified On</strong><div>{this.state.current.timeslice_mdate}</div></TableCell>
                        <TableCell><strong>Modified By</strong><div>{this.state.current.modifiedby}</div></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
        <Paper className={'mb-1'}>
        <ChangeHistory History = {_R.type(this.state.changeLogs) !== 'Undefined' ? this.state.changeLogs :[]} />
        </Paper>
        <TimeSliceReviewNotes Comments = {_R.type(this.state.comments) !== 'Undefined' ? this.state.comments :[]} />      
        </>
  );
    }
}

