import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormHelperText from '@mui/material/FormHelperText';
import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Multiselect } from 'multiselect-react-dropdown';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as _ts from '../../common/treeSelect';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import { StatusChangeLog } from '../../timesheet/details/StatusChangeLog';
import {DialogBoxMedium} from '../../common/dialog.medium';
import { formatName } from '../../common/common';

const API_URL = process.env.REACT_APP_API_URL;
const BackDrop = styled(Backdrop) (({ theme }) => ({
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const InvoiceReportComponent = (props) => {
    let Open = props.open;
    //Tabs
    const [tabValue, setTabValue] =  React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (btn) => {
        props.ValidateExport(btn)
        setAnchorEl(null);
    };
    const gridStyle = {
        margin: '15px 0px'
    };
    const mstyle={ chips: { background: "#f4f4f4", color: 'black' }}
    let dataArr = (typeof props.data !== 'undefined' && props.data.length > 0) ?  props.data : [];
    let clients = (_R.type(props.filters) !== 'Undefined' && _R.type(props.filters.clients) !== 'Undefined') ? props.filters.clients : [];
    let projects = ( _R.type(props.filters) !== 'Undefined' && _R.type(props.filters.projects) !== 'Undefined') ? props.filters.projects : [];

    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.filterFields) !== 'Undefined') ? props.filterFields.projects : []));
    }
    let tasks = (_R.type(props.filters) !== 'Undefined' && _R.type(props.filters.tasks) !== 'Undefined') ? props.filters.tasks : [];
    if (tasks.length) {
        _ts.findTaskKey(tasks, ((_R.type(props.filterFields) !== 'Undefined') ? props.filterFields.tasks : []));
    }
    let staffs = (_R.type(props.filters) !== 'Undefined' && _R.type(props.filters.staffs) !== 'Undefined') ? props.filters.staffs : [];
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Timesheet Report for Invoice</h6>
                </Grid>
                <Grid item>
                    <form className="form-fullwidth" action={API_URL+'api/report/invoice-report'} method="post" id="invoiceForm">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={2}>
                                <FormControl variant="standard">
                                    <InputLabel required htmlFor="report_type">Report Type</InputLabel>
                                    <Select
                                        required
                                        value={props.filterFields.report_type}
                                        inputProps={{
                                            name: 'report_type',
                                            id: 'report_type',
                                        }}
                                        onChange={props.HandleFilterChange}
                                    >
                                    {_R.type(props.filters) === 'Object' && _R.type(props.filters.report_types) !== 'Undefined' && props.filters.report_types.map((rtype, i) =>
                                        <MenuItem key={'report-type-'+i} value={rtype.id}>{rtype.value}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={2}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="name_format">Report Name Format</InputLabel>
                                    <Select
                                        value={props.filterFields.name_format}
                                        onChange={props.HandleFilterChange}
                                        inputProps={{
                                        name: 'name_format',
                                        id: 'name_format',
                                        }}>
                                        {_R.type(props.filters) === 'Object' && _R.type(props.filters.name_formats) !== 'Undefined' && props.filters.name_formats.map((k, i) =>
                                        <MenuItem value={k.key}>{k.value}</MenuItem>
                                        )}
                                    </Select>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.filterFields.start_date}
                                        id="start_date"
                                        name="start_date"
                                        label="Start Date"
                                        type="date"
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.errors.start_date && true}
                                        helperText={props.errors.start_date}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.filterFields.end_date}
                                        id="end_date"
                                        name="end_date"
                                        required
                                        label="End Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.errors.end_date && true}
                                        helperText={props.errors.end_date}
                                    />
                                </ FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div class="filter-column">
                                    <div class="filter-column-title">Client &amp; Project</div>
                                    <div class="filter-column-content">
                                        <input type='hidden' name='client' value={props.filterFields.client}/>
                                        <FormControl>
                                            <Multiselect
                                                options={clients}
                                                displayValue="client_name"
                                                placeholder="Search Client"
                                                selectedValues={props.selectedClientList}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption = {true}
                                                closeIcon = "cancel"
                                            />

                                        </FormControl>

                                        <FormControl>
                                            <input type='hidden' name='projects' value={props.filterFields.projects}/>
                                            <TreeSelectComponent
                                                data = {projects}
                                                placeholderText = { "Search Projects"}
                                                type = 'project'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                            <small className='error'>{props.errors.projects && props.errors.projects}</small>
                                        </FormControl>

                                        <input type='hidden' name='tasks' value={props.filterFields.tasks}/>
                                        <FormControl>
                                            <TreeSelectComponent
                                                data = {tasks}
                                                placeholderText = { "Search Tasks"}
                                                type = 'tasks'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div class="filter-column">
                                    <div class="filter-column-title">Staff</div>
                                    <div class="filter-column-content">
                                        {(( props.permission_type === 'all' || props.permission_organization_wise === true) && props.filterFields.report_type === 'emp-wise') &&
                                            <FormControl variant="standard">
                                                <InputLabel htmlFor="organization" error={props.errors.organization && true}>Organization</InputLabel>
                                                <Select
                                                    value={props.filterFields.organization}
                                                    onChange={props.HandleFilterChange}
                                                    inputProps={{
                                                        name: 'organization',
                                                        id: 'organization',
                                                    }}>
                                                    <MenuItem value="0">Select</MenuItem>
                                                    {_R.type(props.filters) === 'Object' && _R.type(props.filters.organizations) !== 'Undefined' && props.filters.organizations.map((org, i) =>
                                                        <MenuItem key={'org-'+i} value={org.client_id}>{org.client_name}</MenuItem>
                                                    )}
                                                </Select>
                                               <FormHelperText className="error">{props.errors.organization}</FormHelperText>
                                            </ FormControl>
                                        }
                                        {(props.permission_type === 'all' || (props.permission_organization_wise && props.filterFields.report_type === 'emp-wise' && props.filterFields.organization > 0) || (props.permission_type === 'self' && props.filterFields.report_type === 'project-wise' && props.project_manager)) &&
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="staff_status">Staff Status</InputLabel>
                                            <Select
                                                value={props.filterFields.staff_status}
                                                onChange={props.HandleFilterChange}
                                                inputProps={{
                                                    name: 'staff_status',
                                                    id: 'staff_status',
                                                }}>
                                                <MenuItem value="0">All</MenuItem>
                                                {_R.type(props.filters) === 'Object' && _R.type(props.filters.staff_statuses) !== 'Undefined' && props.filters.staff_statuses.map((staff_status, i) =>
                                                    <MenuItem key={'staff-status-'+i} value={staff_status.user_status_id}>{staff_status.user_status_name}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText className="error">{props.errors.staff_status}</FormHelperText>
                                            </ FormControl>
                                        }
                                        <input type='hidden' name='staff' value={props.filterFields.staff}/>
                                        <FormControl>
                                            <Multiselect
                                                options={staffs}
                                                displayValue="full_name"
                                                placeholder="Search Staff"
                                                selectedValues={props.selectedStaffList}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption
                                                closeIcon = "cancel"
                                            />
                                            <FormHelperText className="error">{props.errors.staff}</FormHelperText>
                                        </ FormControl>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={(typeof props.filterFields.nbillable !== 'undefined') ? props.filterFields.nbillable : false}  
                                                value={(typeof props.filterFields.nbillable !== 'undefined') ? props.filterFields.nbillable : null}
                                                id = "nbillable" 
                                                name ="nbillable"
                                                onChange={props.HandleFilterChange}
                                            />
                                        }
                                        label="Include Non-billable hours"
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(typeof props.filterFields.detailed !== 'undefined') ? props.filterFields.detailed : false}
                                                value={(typeof props.filterFields.detailed !== 'undefined') ? props.filterFields.detailed : null}
                                                id = "detailed"
                                                name ="detailed"
                                                onChange={props.HandleFilterChange}
                                            />
                                        }
                                        label="Detailed report"
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(typeof props.filterFields.remove_footer !== 'undefined') ? props.filterFields.remove_footer : false}
                                                value={(typeof props.filterFields.remove_footer !== 'undefined') ? props.filterFields.remove_footer : null}
                                                id = "remove_footer"
                                                name ="remove_footer"
                                                onChange={props.HandleFilterChange}
                                            />
                                        }
                                        label="Remove footer text from PDF"
                                    />
                                </div>
                            </Grid>


                            <Grid item xs={12} class='filter-action'>
                                <div class="filter-column">
                                    <div class="d-flex align-center justify-end">
                                        <input type = 'hidden' id='btn_type' name='btn_type' value={props.filterFields.btn_type}/>
                                        <Button className = {'mr-1'} variant = "contained" size = "small" color = "primary" onClick = {props.ValidateFilter}> Filter</Button>
                                    </div>
                                    <div class = "d-flex align-center justify-end">
                                        <Button aria-controls = "simple-menu" aria-haspopup = "true" className = {'mr-1'} variant = "contained" size = "small" color = "primary" onClick = {handleClick}>Export</Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                        <MenuItem onClick={() => {handleClose('export_pdf')}}>PDF</MenuItem>
                                        <MenuItem onClick={() => {handleClose('export_excel')}}>Excel</MenuItem>
                                        </Menu>
                                    </div>
                                    <div class = "d-flex align-center justify-end">
                                        <Button className = {'mr-1'} color = "primary" size = "small" variant = "outlined" onClick = {props.ClearFilter}> Reset</Button>
                                    </div>
                                </div>
                            </Grid> 
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper>


        {(dataArr.length === 0 && props.post)  &&
            <Grid container style={gridStyle}>
                <Table size="small" aria-label="Report List" className={'list-table_basic'}>
                    <TableRow>
                        <TableCell colspan="6"></TableCell>
                        <TableCell>No search result</TableCell>
                    </TableRow>
                </Table>
            </Grid>
        }

        {dataArr.map((timesheet, i) =>
            <Paper className={'mb-1'}>
                <Grid item xs={12}>
                    <div class="timeSheet-list_header d-flex justify-between align-stretch">
                        <div class="col-main d-flex justify-between align-stretch d-felx_grow1">
                            <div class="col-item d-felx_grow1">
                                <div>
                                    <span>Staff Name</span>
                                    {props.filterFields.name_format.includes("LEGAL") ?
                                        <label>{formatName(timesheet.legal_first_name, timesheet.legal_middle_name, timesheet.legal_last_name, props.filterFields.name_format)}</label>
                                        :
                                        <label>{formatName(timesheet.user_first_name,null,timesheet.user_last_name,props.filterFields.name_format)}</label>
                                    }
                                </div>
                            </div>
                            <div class="col-item d-felx_grow1">
                                <div>
                                    <span>Start Date</span>
                                    <label>{timesheet.sdate}</label>
                                </div>
                            </div>
                        <div class="col-item d-felx_grow1">
                            <div>
                                <span>End Date</span>
                                <label>{timesheet.edate}</label>
                            </div>
                        </div>
                        <div class="col-item d-felx_grow1">
                            <div class="widget_status">
                                <span>Status</span>
                                <span class="value">
                                    <span class={"statusSqr "+timesheet.status_name.toLowerCase()}></span>
                                    <span> {timesheet.status_name} </span>
                                </span>
                            </div>
                        </div>
                        {_R.equals(parseInt(timesheet.status), 2) &&
                            <div class="col-item d-felx_grow1">
                                <div class="widget_status">
                                    <span>{timesheet.status_name} By</span>
                                    {props.filterFields.name_format.includes("LEGAL") ?
                                        <span class="value"><label>{formatName(timesheet.sby_legal_first_name,timesheet.sby_legal_middle_name,timesheet.sby_legal_last_name,props.filterFields.name_format)}</label></span>
                                        :
                                        <span class="value"><label>{formatName(timesheet.sby_first_name,null,timesheet.sby_last_name,props.filterFields.name_format)}</label></span>
                                    }
                                </div>
                            </div>
                        }
                        {((_R.equals(parseInt(timesheet.multiple_review), 0) ||( _R.equals(parseInt(timesheet.multiple_review), 1) && _R.isEmpty(timesheet.project_review))) && (_R.equals(parseInt(timesheet.status), 3) || _R.equals(parseInt(timesheet.status), 4))) &&
                            <div class="col-item d-felx_grow1">
                                <div class="widget_status">
                                    <span>{timesheet.status_name} By</span>
                                    {props.filterFields.name_format.includes("LEGAL") ?
                                        <span class="value"><label>{formatName(timesheet.rby_legal_first_name,timesheet.rby_legal_middle_name,timesheet.rby_legal_last_name,props.filterFields.name_format)}</label></span>
                                        :
                                        <span class="value"><label>{formatName(timesheet.rby_first_name,null,timesheet.rby_last_name,props.filterFields.name_format)}</label></span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Grid>

            <div className={'d-flex justify-between'}>
                <Grid container spacing={3} style={gridStyle}>
                    {timesheet.summary.length !== 0 &&
                        <TableContainer component={Paper}>
                            <Table aria-label="Report List" className={'list-table_basic'}>
                                <TableHead className={'thead'}>
                                    <TableRow>
                                        {Object.keys(timesheet.header).map((hname, i) =>
                                            <>
                                                {_R.equals(hname, 'dates') ?
                                                    timesheet.header.dates.map((d, i) =>
                                                        <TableCell><p>{d.day}</p><p> {d.day_name}</p></TableCell>
                                                    )
                                                :
                                                    <TableCell>{timesheet.header[i]}</TableCell>
                                                }
                                            </>
                                        )}
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                {<TableBody>
                                    {Object.keys(timesheet.summary).map((key, i) => (
                                        <TableRow key={'timesheet-'+i}>
                                            <TableCell component="th" scope="row">
                                                {timesheet.summary[key].client_name}
                                            </TableCell>
                                            <TableCell>{timesheet.summary[key].project_name}</TableCell>
                                            <TableCell>{timesheet.summary[key].task_name}</TableCell>
                                            {Object.values(timesheet.summary[key].hours).map((hour, i) =>
                                                <TableCell align="right">{hour ? parseFloat(hour).toFixed(2) : ''}</TableCell>
                                            )}
                                            <TableCell align="right"><strong>{timesheet.summary[key].row_total ? parseFloat(timesheet.summary[key].row_total).toFixed(2) : ''}</strong></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow key='total'>
                                        <TableCell component="th" scope="row" colSpan={3} align='right'><strong>Total</strong></TableCell>
                                        {
                                            Object.values(timesheet.col_total).map((hour, i) => (
                                                <TableCell align="right"><strong>{hour ? parseFloat(hour).toFixed(2): ''}</strong></TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    }
                    {timesheet.summary.length === 0 &&
                        <Grid container style={gridStyle}>
                            <Table size="small" aria-label="Report List" className={'list-table_basic'}>
                                <TableRow>
                                    <TableCell colspan="6"></TableCell>
                                    <TableCell>No records found</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    }
                </Grid>
            </div>


            {timesheet.summary.length !== 0 &&
                <>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={'heading'}>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div className={'tab-navContainer d-flex justify-between'}>
                                    <Tabs
                                        value={tabValue}
                                        onChange={tabChange}
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="Timesheet Summary Tabs"
                                    >
                                        <Tab label="Review Summary"/>
                                        <Tab label="Timesheet Details"/>
                                        <Tab label="Activity Log"/>
                                        <Tab label="Status Change Log"/>
                                    </Tabs>
                                </div>
                                <div className={'tab-panelContainer'}>
                                    <TabPanel value={tabValue} index={0}>
                                        {(!_R.isEmpty(timesheet.project_review) && timesheet.multiple_review === 1) &&
                                            <TableContainer component={Paper}>
                                                <Table  aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Client</TableCell>
                                                            { (timesheet.report_type === 'project-wise') ?
                                                                <>
                                                                { timesheet.project_review[0]['classification'] == 1 ?
                                                                    <TableCell> Contract/Order #</TableCell>
                                                                :
                                                                    <TableCell> Subcontract/Order #</TableCell>
                                                                }
                                                                </>
                                                            :
                                                                <TableCell align="right">Contract/Order/Subcontract #</TableCell>
                                                            }
                                                            <TableCell>Project</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Reviewers</TableCell>
                                                            <TableCell>Reviewed By</TableCell>
                                                            <TableCell>Reviewed On</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(timesheet.project_review).map((review, i) => (
                                                            <TableRow key={'project-review-'+i}>
                                                                <TableCell component="th" scope="row">
                                                                    {review.client_name}
                                                                </TableCell>
                                                                <TableCell>{review.contract_number}</TableCell>
                                                                <TableCell>{review.project_name}</TableCell>
                                                                <TableCell>{review.status_label}</TableCell>
                                                                {!_R.isEmpty(review.reviewers) ?
                                                                    <TableCell>{review.reviewers}</TableCell>
                                                                :
                                                                    <TableCell>{timesheet.supervisors}</TableCell>
                                                                }
                                                                <TableCell>{review.reviewer}</TableCell>
                                                                <TableCell>{review.reviewed_on}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                        {(_R.isEmpty(timesheet.project_review) || timesheet.multiple_review === 0) &&
                                            <Table size="small" aria-label="Report List" className={'list-table_basic'}>
                                                <TableRow>
                                                    <TableCell colspan="6"></TableCell>
                                                    <TableCell>No records found</TableCell>
                                                </TableRow>
                                            </Table>
                                        }
                                    </TabPanel>
                
                                    <TabPanel value={tabValue} index={1}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>{timesheet.clients_count < 2 ?  'Project' : 'Client / Project'}</TableCell>
                                                        <TableCell>Task</TableCell>
                                                        <TableCell>Charge Code</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Hours</TableCell>
                                                        <TableCell>Last Modified</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(timesheet.timeslices).map((timeslice, i) => (
                                                        <TableRow key={'details-'+i}>
                                                            <TableCell component="th" scope="row">
                                                                {timeslice.timeslice_date}
                                                            </TableCell>
                                                            <TableCell>{timesheet.clients_count < 2 ? timeslice.project_path : timeslice.client_code+' / '+timeslice.project_path}</TableCell>
                                                            <TableCell>{timeslice.task_name+ ' ['+timeslice.location_name+'] '}</TableCell>
                                                            <TableCell>{timeslice.chargecode}</TableCell>
                                                            <TableCell>{timeslice.timeslice_log}</TableCell>
                                                            <TableCell>{timeslice.timeslice_hours}</TableCell>
                                                            <TableCell>{timeslice.timeslice_mdate}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow key={'details-total'}>
                                                        <TableCell colspan='5' align="right"><strong>Total Hours</strong></TableCell>
                                                        <TableCell colspan='2'><strong>{timesheet.timeslice_total.toFixed(2)}</strong></TableCell>
                                                    </TableRow>

                                                    {(timesheet.task_hours).map((task_hour, i) => (
                                                        <TableRow key={'task_hour-'+i}>
                                                            <TableCell colspan='5' align="right"><strong>Total {task_hour.name} Hours</strong></TableCell>
                                                            <TableCell colspan='2'><strong>{task_hour.hours}</strong></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>

                                    <TabPanel value={tabValue} index={2}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Activity</TableCell>
                                                        <TableCell>From</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(timesheet.log).map((log, i) => (
                                                        <TableRow key={'log-'+i}>
                                                            <TableCell component="th" scope="row">
                                                                {log.created_on}
                                                            </TableCell>
                                                            <TableCell>{log.summary}</TableCell>
                                                            <TableCell>{log.ipaddress}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>

                                    <TabPanel value={tabValue} index={3}>
                                        <StatusChangeLog
                                            StatusChangeLog={timesheet.history}
                                        />
                                    </TabPanel>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
        </Paper>
    )}
    <DialogBoxMedium
        Open = {Open}
        Cancel = {props.OnClose}
        Title = {"Timesheet"}
        OnSubmit = {props.ConfirmTimesheetValidation}
        Content = {<DialogContent Timesheets = {props.warning_timesheets}/>}
        ButtonName = 'Yes'
    />

    <BackDrop className={'backdrop'} open={props.loaderOpen}>
        <CircularProgress color="inherit" />
    </BackDrop>

    </>
)
};
const DialogContent = (p) => {
    return (
        <>
            <p>The following pay period/s are not approved. Do you wish to continue?</p>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Staff</TableCell>
                                <TableCell>Pay period</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                    <TableBody>
                        {(p.Timesheets).map((timesheet, i) => (
                            <TableRow key={'wtimesheet-'+i}>
                                <TableCell component="th" scope="row">
                                    {formatName(timesheet.user_first_name,null,timesheet.user_last_name)}
                                </TableCell>
                                <TableCell>{timesheet.sdate} - {timesheet.edate}</TableCell>
                                <TableCell>{timesheet.status_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
