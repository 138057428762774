import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {DialogBoxMedium} from '../../../common/dialog.medium';
import { CustomDatePicker } from '../../../common/CustomDatePicker';

export const DisabilityDetailsComponent = (props) => {
    const { disabilityDetails, onAdd, onUpdate, onDeleteSubmit, EditPermission, DeletePermission } = props;
    let data = (_R.type(disabilityDetails) !== 'undefined') ? disabilityDetails : {};
    let Edit = EditPermission;
    let Delete = DeletePermission;
    return (  
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Disability</Typography>
                {_R.equals(Edit, true) &&
                <Tooltip title="Add Disability Details" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={onAdd}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                }
            </div>   
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Disability Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof data != 'undefined' && data.length
                            ? data.map( (d, index ) =>
                        <TableRow hover>
                            <TableCell> {d.start_date} </TableCell>
                            <TableCell> {d.end_date} </TableCell>
                            <TableCell> {d.title} </TableCell>
                            <TableCell align="right">
                            {_R.equals(Edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={onUpdate(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            {_R.equals(Delete, true) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <DeleteIcon fontSize="small" onClick={onDeleteSubmit(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            </TableCell>
                        </TableRow>
                        )
                        :<></> }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium 
                Open= { props.open }  
                Cancel = { props.onClose }
                Title = { props.IsEditable ? 'Edit Disability Details' : 'Add Disability Details' }
                OnSubmit = { props.OnSubmit }
                Content = { <DialogContent FormProps = { props }/> }
            />
        </>
    )
};

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, FormInputs, HandleOnChange, HandleDateChange} = props;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker 
                            name = "start_date"
                            id = "start_date"
                            selectedDate = {FormFields.start_date}
                            handleDateChange = {HandleDateChange}
                            error = {props.Errors.start_date}
                            required = {true}
                            label = "Start Date"
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            name = "end_date"
                            id = "end_date"
                            selectedDate = {FormFields.end_date}
                            handleDateChange = {HandleDateChange}
                            error = {props.Errors.end_date}
                            label = "End Date"
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error = {props.Errors.disability_type} htmlFor="disability_type">Disability Type</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && FormFields.disability_type}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'disability_type',
                                id: 'disability_type',
                            }}>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.disability_types) !== 'Undefined' && FormInputs.disability_types.map((t, i) =>            
                                <MenuItem value={t.id} key={'sub-type-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                        {props.Errors.disability_type && <FormHelperText className ='error'> {props.Errors.disability_type} </FormHelperText> }
                    </ FormControl>
                </Grid>   
            </Grid>
        </form>
    )
}
