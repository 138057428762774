import React from 'react';
import { NoteComponent } from './Note.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';
import { ErrorPage } from '../../../common/error';
import MixpanelManager from  '../../../../services/Analytics';

const formFields = {
    'id': 0,
    'staff_id':null,
    'note' : null,
    'created_by':null,
    'created_on':null,
    'modified_by':null,
    'modified_on':null,
}
export class NoteDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            formFields:formFields,
            errors: {},
            title : 'Staff Notes',
            Open:false,
            isEdit:false,
            isDelete: false,
            updated:false,
            beforeUpdateNote: [],
        }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.MixpanelManager = new MixpanelManager();

    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    onAddComponent = () => {
        this.setState({Open: true,
        isEdit: false,
        });
    }
    onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
    }

    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    submit =() => {
        if (this.handleValidation()) {
            this.saveNote();
        }
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.note) {
            errors["note"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
 
     saveNote = () => {
        let data = Object.assign({},this.state.formFields);
        const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        data.staff_id = staffID;
        data.user_id = userID;
        if(this.handleValidation()){
            httpServices.post('api/staff/note/save-notes', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            if (this.state.beforeUpdateNote.note !== data.note && data.category == 'Service Period') {
                                this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-note-edit', 'action' : 'service-period-note-update'});
                            }
                            this.setState({note:response.data.note});
                            this.setState({Open:false});
                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
          })
       .catch(function(error) {
            console.log("Error: "+error);
            alert("Something went wrong. Please try again later");
        });
        }
    }
    onUpdate = (id) => {
        return httpServices.post('api/staff/note/get-notes', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        Open: true,
                        id: id,
                        isEdit: true,
                        formFields: response.data.selected_note[0],
                        beforeUpdateNote: Object.assign({},response.data.selected_note[0]),
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onDelete = (index, rowID, category) => {
        return httpServices.post('api/staff/note/delete-notes', {'id': rowID, 'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data.valid) {
                    if (category == 'Service Period') {
                        this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-note-delete', 'action' : 'service-period-note-delete'});
                    }
                    this.setState({note:response.data.note});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    render(){
        return(
            (this.props.Permission)
            ?
            <NoteComponent
               Tiitle={this.state.title}
               Note={_R.type(this.state.note) !== 'Undefined' ? this.state.note :(_R.type(this.props.NoteData) !== 'Undefined') ? this.props.NoteData.note: []}
               Open={this.state.Open}
               Close={this.onClose.bind(this)}
               HandleOnChange={this.handleOnChange}
               AddComponent={this.onAddComponent.bind(this)}
               SaveNote={this.submit.bind(this)}
               Update={this.onUpdate.bind(this)}
               FormFields={this.state.formFields} 
               IsEdit={this.state.isEdit} 
               IsDelete={this.state.isDelete}
               Errors={this.state.errors}
               OnDelete={this.onDelete.bind(this)}
               delete_permission={this.props.NoteData ? this.props.NoteData.delete_permission : undefined }
               edit_permission={this.props.NoteData ? this.props.NoteData.edit_permission  : undefined} 
            />
            :
            <ErrorPage/>
        )
    }
}
