import React from 'react';
import { EmailNotificationDetailsComponent } from './EmailNotificationDetails.Component';
import { httpServices } from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import { formatName } from '../common/common';

const formFields = {
    'id': 0,
    'name': null,
    'code': null,
    'description': null,
    'status': null,
    'external_email' : null
};

export class EmailNotificationDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            email_notification: '',
            event_group_names : '',
            event_user_names : '',
            title : 'Email Notifications',
            formFields: formFields,
            dialogOpen: false,
            dialogGroupOpen : false,
            errors: {},
            all_users : [],
            assigned_users : {},
            assigned_groups : {},
            all_groups : [],
            hasPermission : true,
            snackbarOpen: false,
            snackbarType: null,
            message: null,
            id : 0,
            dialogExternalOpen : false,
            external_emails : [],
            email_exists : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddGroup = this.onAddGroup.bind(this);
        this.popupClose = this.popupClose.bind(this);
        this.handleResourceChange = this.handleResourceChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.onAddUser = this.onAddUser.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onGroupSubmit = this.onGroupSubmit.bind(this);
        this.onAddExternalEmail = this.onAddExternalEmail.bind(this);
        this.onExternalEmailSubmit = this.onExternalEmailSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.deleteExternalEmail = this.deleteExternalEmail.bind(this);
    }
    componentDidMount = () => {
        let id = this.props.match.params.id;
        this.getEvent(id);
    };
    getEvent = (id) => {
        let apiEndpoint = 'api/system/emailnotification/view';
        return httpServices.post(apiEndpoint, {id : id})
            .then(response => {
                if (response.data.permission) {
                        this.setState({
                            email_notification : response.data.event,
                            event_group_names : response.data.event_group_names,
                            event_user_names : response.data.event_user_names,
                            id : id,
                            external_emails : response.data.external_emails,
                        });
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    
    handleResourceChange = (selected, eventId) => {
        // Update resource dual box
        let selectedResources = selected;
        this.setState({
            assigned_users : selectedResources,
        });
    }

    handleSubmit = (event) => {
            let data = {};
            data.eventId = this.state.id;
            data.assigned_users = JSON.stringify(this.state.assigned_users);
            if (data) {
                httpServices.post('api/system/emailnotification/updateNotificationUser', { data })
                    .then(response => {
                        if (response.data) {
                                this.setState({
                                    dialogOpen: false,
                                    snackbarOpen: true,
                                    snackbarType: "success",
                                    message: "Email Notification users updated successfully",
                                });
                                this.getEvent(this.state.id);
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
    };
    
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }

    onAddUser = () => {
        return httpServices.post('api/system/emailnotification/assignuser', {'id': this.state.id})
            .then(response => { 
                if (response.data.permission) {
                    let assigned_users = response.data.assign_user_ids;
                    let all_users = response.data.employee;
                    let temp_user_array = [];
                    all_users.forEach(function(obj,idx) {
                        obj.value = obj.user_id;
                        obj.label = formatName(obj.first_name,null,obj.last_name);
                        temp_user_array.push(obj);
                    });                   
                        this.setState({
                            assigned_users: assigned_users,
                            all_users: temp_user_array,
                            dialogOpen: true,
                        });
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    onAddGroup = () => {
        return httpServices.post('api/system/emailnotification/assigngroup', {'id': this.state.id})
            .then(response => { 
                if (response.data.permission) {
                    let assigned_groups = response.data.assign_group_ids;
                    let all_groups = response.data.groups;
                    let temp_group_array = [];
                    all_groups.forEach(function(obj,idx) {
                        obj.value = obj.id;
                        obj.label = obj.name;
                        temp_group_array.push(obj);
                    });                   
                        this.setState({
                            assigned_groups: assigned_groups,
                            all_groups: temp_group_array,
                            dialogGroupOpen: true,
                        });
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onGroupSubmit = () => {
        let data = {};
        data.eventId = this.state.id;
        data.assigned_groups = JSON.stringify(this.state.assigned_groups);
        if (data) {
            httpServices.post('api/system/emailnotification/updateNotificationGroup', { data })
                .then(response => {
                    if (response.data) {
                            this.setState({
                                dialogGroupOpen: false,
                                snackbarOpen: true,
                                snackbarType: "success",
                                message: "Email Notification groups updated successfully",
                            });
                            this.getEvent(this.state.id);
                    }
                })
                .catch(function (error) {
                    console.log("error" + error);
                });
        }
    }

    handleGroupChange = (selected) => {
         // Update resource dual box
         let selectedGroups = selected;
         this.setState({
             assigned_groups : selectedGroups,
         });
    }

    popupClose = () => {
        this.setState({
            dialogOpen : false,
            dialogGroupOpen : false,
            dialogExternalOpen : false,
        });
    }

    onAddExternalEmail = () => {
        let fields = this.state.formFields;
        fields.external_email = null;
        this.setState({
            dialogExternalOpen: true,
            formFields : fields,
        });
    }

    handleOnChange = (evt, value) => {
        const fields = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        fields[name] = val;
        this.setState({
            formFields: fields,
        });
        this.validateExternalEmail(val);
    };

    onExternalEmailSubmit = () => {
        if (this.handleValidation()) {
        this.setState({
            dialogExternalOpen: false,
        });
        return httpServices.post('api/system/emailnotification/save-external-email',{'notification_id' : this.state.id,'external_email': this.state.formFields.external_email})
         .then(response => { 
             if (response.data.permission) {
                if (response.data.valid) {
                    this.setState({
                        external_emails : response.data.external_emails,
                        snackbarOpen: true,
                        snackbarType: "success",
                        message: "External email recipient added successfully",
                    }); 
                }
                else {
                    this.setState({
                        snackbarOpen: true,
                        snackbarType: "error",
                        message: "External email recipient add failed",
                    }); 
                }
             } })
        }
    }

    deleteExternalEmail = (external_email_id) => {
        return httpServices.post('api/system/emailnotification/delete-external-email',{'notification_id' : this.state.id,'external_email_id': external_email_id})
         .then(response => { 
             if (response.data.permission) {
                    if (response.data.valid) {
                        this.setState({
                            external_emails : response.data.external_emails,
                            snackbarOpen: true,
                            snackbarType: "success",
                            message: "External email recipient deleted successfully",
                        }); 
                    }
                    else {
                        this.setState({
                            snackbarOpen: true,
                            snackbarType: "error",
                            message: "External email recipient delete failed",
                        }); 
                    }
             } })
    }

    validateExternalEmail = (external_email) => {
        return httpServices.post('api/system/emailnotification/check-external-email-exist',{'notification_id' : this.state.id,'external_email': external_email})
         .then(response => { 
             if (response.data) {
                    this.setState({email_exists : response.data.email_exists});
             } })
    }
    
    handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        let user_email = this.state.formFields.external_email;
        if (user_email) {
            let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let ok = reg.exec(user_email);
            if (!ok) {
                errors["external_email"] = "Email isn't valid";
                formIsValid = false;
            }
        }
        if (this.state.email_exists && !(errors["external_email"])) {
             errors["external_email"] = "Email already exists";
             formIsValid = false;
        }
        if (user_email == null){
            errors["external_email"] = "Cannot be null";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    render() {
        return(
            (this.state.hasPermission)
            ?
            <EmailNotificationDetailsComponent
               EmailNotifications = {this.state.email_notification}
               EventUserNames = {this.state.event_user_names}
               EventGroupNames = {this.state.event_group_names}
               OnSubmit={this.handleSubmit}
               HandleResourceChange={this.handleResourceChange}
               OnAddGroup={this.onAddGroup}               
               PopupClose={this.popupClose}
               OnAddUser={this.onAddUser} 
               DialogOpen = {this.state.dialogOpen}
               DialogGroupOpen = {this.state.dialogGroupOpen}
               AssignedUsers = {this.state.assigned_users}
               AssignedGroups = {this.state.assigned_groups}
               EventId = {this.state.id}
               AllUsers = {this.state.all_users}
               HandleGroupChange = {this.handleGroupChange}
               AllGroups = {this.state.all_groups}
               SnackbarOpen = {this.state.snackbarOpen}
               SnackbarType = {this.state.snackbarType}
               SnackbarClose = {this.snackbarClose}
               OnGroupSubmit = {this.onGroupSubmit}
               Message = {this.state.message}
               OnAddExternalEmail = {this.onAddExternalEmail}
               DialogExternalOpen = {this.state.dialogExternalOpen}
               ExternalEmail = {this.state.external_emails}
               OnExternalEmailSubmit = {this.onExternalEmailSubmit}
               HandleOnChange = {this.handleOnChange}
               FormFields = {this.state.formFields}
               DeleteExternalEmail = {this.deleteExternalEmail}
               Errors = {this.state.errors}
            />
            :
            <ErrorPage/>
        )
    }
}
