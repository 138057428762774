import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import { formatAndSortNames } from '../../common/common';

const styles = theme => ({
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    }
});
export const OvertimeFilterComponent = (props) => {
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'user_first_name', middle_name: 'user_middle_name', last_name: 'user_last_name'};
    let employee = typeof props.EmployeeList !== 'undefined' ? props.EmployeeList : [];
    if (employee && employee.length) {
        employee = formatAndSortNames(employee, [format, columnsToBeFormated], 'ASC');
    }
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                        <FormControl>
                            <CustomDatePicker
                                id = "startDate"
                                name = "startDate"
                                selectedDate = {props.FormFields.startDate}
                                handleDateChange = {props.handleStartDateChange}
                                label = {'Start Date'}
                            />
                        </ FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl>
                        <CustomDatePicker
                                id = "endDate"
                                name = "endDate"
                                selectedDate = {props.FormFields.endDate}
                                handleDateChange = {props.handleEndDateChange}
                                label = {'End Date'}                               
                            />
                    <span class = "error">{props.errors.endDate }</span>
                    </ FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Request Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'requestStatus',
                            id: 'requestStatus',
                        }}
                        value = {!_R.isNil(props.FormFields.requestStatus) && props.FormFields.requestStatus }
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.RequestStatus) !== 'undefined' && props.RequestStatus.map((t, i) =>
                            <MenuItem value={t.request_status_id} key={'c-'+i}>{t.request_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                {
                props.tab === 'all' &&
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'employeeStatus',
                            id: 'employeeStatus',
                        }}
                        value = {!_R.isNil(props.FormFields.employeeStatus) && props.FormFields.employeeStatus}
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.EmployeeStatus) !== 'undefined' && props.EmployeeStatus.map((n, i) =>
                            <MenuItem value={n.user_status_id} key={'n-'+i}>{n.user_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
                {
                props.tab !== 'my' && 
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Name</InputLabel>
                        <Select
                            inputProps={{
                            name: 'employeeName',
                            id: 'employeeName',
                        }}
                        value = {!_R.isNil(props.FormFields.employeeName) && props.FormFields.employeeName}
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {employee.map((n, i) =>
                            <MenuItem value={n.user_id} key={'n-'+i}>{n.formatted_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
                <div className={'d-flex justify-end mb-1'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(typeof props.SaveFilter !== 'undefined') ? props.SaveFilter : false}
                                value={(typeof props.SaveFilter !== 'undefined') ? props.SaveFilter : false}
                                id="saveFilter"
                                name="saveFilter"
                                onChange={props.handleOnChange}
                                color={'primary'}
                            />
                        }
                        label="Save Filter"
                    />
                </div>
                <Button variant = "contained" color = "primary" className = {'filterSubmitButton'} onClick = {()=> props.handleOnSubmit(props.tab)}> Submit </Button>
                <Button variant = "outlined" color = "primary"  className = {'ml-1 filterSubmitButton'}  onClick = {()=> props.ResetFilter(props.tab)} >Reset</Button>
        </form>
    )
}
