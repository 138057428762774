import React from 'react';
import {SearchInOutComponent} from './SearchInOut.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';

const API_URL = process.env.REACT_APP_API_URL;
const timesheetFields = {
    'employee_status' :'',
    'staff' :'' ,
    'start_date' : '',
    'end_date' : '',
}

const timesheetInputs = {
    employee_status : [
        { id : 0, value : "0", title : "All" },
        { id : 1, value : "1", title : "Active"},
        { id : 2, value : "2", title : "Inactive"},
        { id : 3, value : "3", title : "Relieved"}

    ],
    staff_list : [
    ],
    timeinout_list : [
    ],
}

export class SearchInOutContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            timesheetFields: timesheetFields,
            timesheetInputs: timesheetInputs,
            dialogOpen : false,
            popupType : '',
            selectedDate :'',
            random_number : 0,
            hasPermission : true,
            user : 0,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.timesheetFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        
        if(field === 'employee_status'){
             this.getStaffByStatus(evt.target.value);
             ff['staff']=0;
        }
         
        this.setState({
            timesheetFields: ff
        });
        
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }


    };
    componentDidMount() {
        httpServices.post('api/timesheet/searchinandout')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        timesheetFields : {
                            employee_status : inputs.employee_status,
                            staff : inputs.employee,
                            start_date : inputs.start_date,
                            end_date :inputs.end_date
                        },
                        user : inputs.employee,

                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            this.getStaffByStatus(1);
    }

    getStaffByStatus(status_id){
        httpServices.post('api/timesheet/getStaffList',{ 'status_id' : status_id })
            .then(response => {
                if (response.data) {
                    let staff_list = response.data.users;
                    this.setState({
                        timesheetInputs:{
                            employee_status:this.state.timesheetInputs.employee_status,
                            staff_list:staff_list,
                        },
                    }); 
                }
            })
    }
    validateForm()
    {
        let formIsValid = true;
        let errors = {};
        if (this.state.timesheetFields.staff === 0) {
            errors["staff"] = "Select a staff";
            formIsValid = false;
        }
        if (!this.state.timesheetFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.timesheetFields.end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;

    }
    OnSearchClick = (action) => () =>{
        let id = this.state.timesheetFields.staff;
        let start_date = this.state.timesheetFields.start_date;
        let end_date = this.state.timesheetFields.end_date;
        let data = {user_id : id, start_date : start_date, end_date : end_date, action : action }
        if(this.validateForm()){
            httpServices.post('api/timesheet/searchinandout', data)
                .then(response => {
                    if (response.data) {
                         this.setState({
                            timesheetInputs:{
                                employee_status:this.state.timesheetInputs.employee_status,
                                staff_list:this.state.timesheetInputs.staff_list,
                                timeinout_list:response.data,
                         }
                        }); 
                }
            });
        }
    }
    editTimeInOutClick = (date) => ()=>{
        let rand = Math.random();
        this.setState({
            selectedDate : date,
            dialogOpen : true,
            popupType : 'timein_out',
            random_number : rand,
        });
    }
    onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    }
    exportTimeInOut = () => {
       let url = API_URL+'api/timesheet/export-time-in-out/'+this.state.timesheetFields.start_date+"/"+this.state.timesheetFields.end_date+"/"+this.state.timesheetFields.staff;
        window.location.assign(url);

    }
    render(){
       return(
           (this.state.hasPermission)
            ?
           <SearchInOutComponent
                timesheetInputs = { this.state.timesheetInputs }
                HandleOnChange = { this.HandleOnChange }
                timesheetFields = { this.state.timesheetFields }
                OnSearchClick = { this.OnSearchClick.bind(this) }
                editTimeInOutClick ={this.editTimeInOutClick}
                PopupType = {this.state.popupType}
                selectedDate = {this.state.selectedDate}
                DialogOpen = {this.state.dialogOpen}
                OnClose = {this.onClose.bind(this)}
                Errors = { this.state.errors }
                randomNumber = {this.state.random_number}
                TimeInOutSaveSuccess = {this.OnSearchClick('search')}
                User = { this.state.user }
                ExportTimeInOut = { this.exportTimeInOut }
            />
           :<ErrorPage/>
       )
    }
}
