import React from 'react';
import { WorkHoursEffectivenessReportComponent } from './WorkHoursEffectivenessReport.Component';
import { httpServices }  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';

const formFields = {
    startDate : '',
    startDateMax : '',
    endDate : '',
    minEndDate : '',
    staffStatus : '1',
}

const formInputs = {
    staffStatuses : [],
}

export class WorkHoursEffectivenessReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formInputs: formInputs,
            formFields: formFields,
            errors : {},
            hasPermission : true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    componentDidMount() {
        this.index();
    }
    index = () => {
        httpServices.get('api/report/workhoursEffectivenessReport',{})
            .then(response => {
                if (response.data && response.data.permission) {
                    let formFields = this.state.formFields;
                    formFields.startDate = response.data.start_date;
                    formFields.endDate = response.data.end_date;
                    formFields.minEndDate = response.data.start_date;
                    formFields.startDateMax = response.data.end_date;
                    let formInputs = this.state.formInputs;
                    formInputs.staffStatuses = response.data.user_statuses;
                    this.setState({
                        formInputs : formInputs,
                        formFields : formFields,
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
    }
    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let value = evt.target.value;
        if (field === 'startDate') {
            ff['minEndDate'] = value;
        }
        if (field === 'endDate') {
            ff['startDateMax'] = value;
        }
        ff[field] = value;
        this.setState({
            formFields: ff
        });
    }

    render(){
        return(
            (this.state.hasPermission)
            ?
            <WorkHoursEffectivenessReportComponent 
                FormInputs = { this.state.formInputs }
                FormFields = { this.state.formFields }
                HandleOnChange = { this.handleOnChange }
                Errors = {this.state.errors}
            />
            :<ErrorPage/>
        )
    }
}
