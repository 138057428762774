import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DialogBoxMedium} from '../../common/dialog.medium';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as R from 'ramda';
import { Comment } from '../Common/Comment';
import { ChangeHistory } from '../Common/ChangeHistory';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import { CustomizedSnackbars } from '../../common/snackbars';
import { AddAdditionalpto } from './AdditionalptoAdd.Container';
import { formatName } from '../../common/common';
import Tooltip from '@mui/material/Tooltip';


export const AdditionalptoDetails = (props) => {
return(
    <div className="section-main section-main-header-fixed">
        <div className={'header-fixed'} >
            <div className="section-header">
                <div className="col-left">
                    <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                    <div className="section-title-wrap">
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Additional PTO Details
                        </Typography>
                        <Typography>ID: {props.RequestDetails.id}</Typography>
                    </div>
                </div>
                <div className="actions">
                    { props.SelfEdit &&
                        <Button variant="contained" size="small"  color="primary" onClick={()=>props.ConfirmRequest('edit')}>Edit</Button>
                    }
                    { props.is_cancel ?
                        <Button variant="outlined" color="primary" size="small" onClick={()=>props.ConfirmRequest('cancel')} >Cancel</Button>
                        :<></>
                }
                { (props.is_reviewer && props.RequestDetails.status === 1 ) ?
                    <>
                        <Button variant="contained" size="small" color="primary" onClick={()=>props.ConfirmRequest('approve')}>Approve</Button>
                        <Button variant="contained" size="small" color="secondary" onClick={()=>props.ConfirmRequest('reject')} >Reject</Button>
                    </>
                    :<></>
                }
                { props.can_comment ?
                        <Button variant="outlined" size="small"  onClick={()=>props.ConfirmRequest('comment')} >Comment</Button>
                        :<></>
                    }
                </div>
            </div>
        </div>
        <div className="section-body"> 
            <Paper>
                <div className="timesheet_list-summary">
                    <div className="p-1rem summary box-details">
                        <ul className="list-standard list-ul-shrink">
                            <li className="list-item">
                                <label>Staff Name :</label>
                                <span className="value">{formatName(props.RequestDetails.user_first_name,null,props.RequestDetails.user_last_name)}</span>
                            </li>
                            <li className="list-item">
                                <label>Year :</label>
                                <span className="value">{props.RequestDetails.year}</span>
                            </li>
                            <li className="list-item">
                                <label>Duration :</label>
                                <span className="value">{props.RequestDetails.hours.toFixed(2)} Hours</span>
                            </li>
                            <li className="list-item">
                                <label>Status :</label>
                                <span className="value">{props.RequestDetails.request_status_name}</span>
                            </li>
                        </ul>
                        <ul className="list-standard list-ul-grow list-ul-border-l">
                            <li className="list-item list-item-block">
                                <label>Reason</label>
                                <span className="value">
                                {props.RequestDetails.description} 
                                </span>
                            </li>
                            {(props.RequestDetails.recipients)?
                                <li className="list-item list-item-block">
                                    <label>Email Recipients</label>
                                    <span className="value">
                                        {props.RequestDetails.recipients} 
                                    </span>
                                </li>
                                :<></>
                            }
                            <li className="list-item list-item-bottom-info">
                                <div>
                                    <i><strong>Submitted by: </strong></i>
                                    <i> {formatName(props.RequestDetails.cby_first_name,null,props.RequestDetails.cby_last_name)} </i>
                                    <i>on {props.RequestDetails.created_on} </i>
                                    {(props.RequestDetails.updated_on != null)?
                                    <>
                                     &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                    <i><strong>Modified by: </strong></i>
                                    <i> {formatName(props.RequestDetails.mby_first_name,null,props.RequestDetails.mby_last_name)} </i>
                                    <i>on {props.RequestDetails.updated_on} </i>
                                    </>
                                    :<> </>
                                    }
                                    {(props.RequestDetails.reviewed_on !== null)?<>
                                     &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                    <i><strong>Reviewed by: </strong></i>
                                    <i> {formatName(props.RequestDetails.reviewer_first_name,null,props.RequestDetails.reviewer_last_name)} </i>
                                    <i>on {props.RequestDetails.reviewed_on} </i>
                                    </>
                                    :<> </>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Paper>
            <Comment Comments={R.type(props.RequestDetails.comment ) !== 'Undefined' ? props.RequestDetails.comment : []} />
            <Paper>
                <Accordion>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                        PTO Information
                    </AccordionSummary>
                    <AccordionDetails>
                    <Table size="small" aria-label="a dense table">
                        {props.PtoDetails.map((value, index) => 
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row"> {value['label']} </TableCell>
                                    <TableCell component="th" scope="row"> {value['hours']} Hours </TableCell>
                                    <TableCell component="th" scope="row"> {value['days']} Days </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                    </AccordionDetails>
                </Accordion>
            </Paper>
            <ChangeHistory History = {R.type(props.RequestDetails.changelog ) !== 'Undefined' ? props.RequestDetails.changelog : []} />
        </div>
        {(props.action!=='edit') ?
            <DialogBoxMedium 
                Open= { props.open }  
                Cancel = { props.Close }
                Title = { props.action!=='comment' ? props.action +' Request' :'add comment' }
                OnSubmit = { ()=>props.SendRequest(props.action) }
                Content = { <DialogContent FormProps = { props }/> }
            />
            :
            (props.open) ?
                <AddAdditionalpto 
                    Open = {props.open}
                    EditId = {props.RequestDetails.id} 
                    UpdateParent = { props.UpdateParent}
                    Title = {"EDIT"}
                /> 
            :<></>
        }
        <CustomizedSnackbars
            open={ props.SnackbarOpen }
            variant={ props.message.message_type }
            message={ props.message.message ? props.message.message : '' }
            handleClose={ props.SnackbarClose }
        />
    </div>
)};
const DialogContent = (p) => {
    const { FormProps : props } = p;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
		    {( props.action === 'approve' || props.action === 'reject' || props.action === 'cancel') ?
            <Grid item xs={12} md={12}>
                <Typography variant="subtitle2" gutterBottom>
                    Do you want to {props.action} Request(s)?
                </Typography>
            </Grid>
            :<></>
            }
            <Grid container>
            { ( props.action === 'approve') ? 
                <>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id = "hours"
                            name = "hours"
                            label = "Hours"
                            variant = 'standard'
                            type = "hours" value = {props.FormFields.hours}
                            onChange = {props.HandleOnChange}
                            placeholder ="[Format: nn.nn or HH:MM ]" error={props.Errors.hours && true}
                            required helperText = { typeof(props.Errors) !== 'undefined' &&  props.Errors.hours}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <FormControlLabel
                            value="Y"
                            control={<Checkbox checked={props.FormFields.carry_forward} onChange={props.HandleOnChange} name="carry_forward" />}
                            label="Carry forward the negative hours to the following year"
                            labelPlacement="start"
                        />
                    </FormControl>
                </Grid>
                </>
                :
                <></>
            }
            <Grid item xs={12} md={12}>
                <Box className={'mt-1'}>
                    <FormControl>
                        <TextField
                            id = "comment"
                            name = "comment"
                            label = "Comment"
                            required = {(props.action !== 'cancel')?true:false}
                            type = "text"
                            multiline={true}
                            variant="outlined"
                            value = {props.FormFields.comment}
                            onChange = {props.HandleOnChange}
                            error = { props.Errors.comment && true }
                            helperText = { typeof(props.Errors) !== 'undefined' && props.Errors.comment }
                        />
                    </ FormControl>
                </Box>
            </Grid>
        </Grid>                        
    </form> 
)};
