import React from 'react';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import { formatName } from '../../common/common';

export const ChangehistoryComponent = (props) => {
    return (
        <>
            <React.Fragment>
                <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Change History"}
                        columns={[
                            { title: 'Date and Time', field: 'created_on' },
                            { title: 'Modified by', render: rowData => <>{formatName(rowData.cby_firstName,null,rowData.cby_lastName)}</> },
                            { title: 'Entity', field: 'entity_name' },
                            { title: 'Field', field: 'display_name' },
                            { title: 'Previous Value', field: 'prev' },
                            { title: 'Current Value', field: 'new' },
                        ]}
                        data={props.History}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}

                    />
                </div>
            </React.Fragment>
        </>
    )
};