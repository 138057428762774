import React from 'react';
import { EmailNotificationComponent } from './EmailNotification.Component';
import { httpServices } from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import {history} from './../../helpers/history';
import { CustomProgressBar } from '../common/CustomProgressBar';


const formFields = {
    'id': 0,
    'name': null,
    'code': null,
    'description': null,
    'status': null,
};

export class EmailNotification extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            email_notification: [],
            title : 'Email Notifications',
            formFields: formFields,
            open: false,
            errors: {},
            hasPermission : true,
            snackbarOpen: false,
            snackbarType: null,
            message: null,
            buttonLoader : false,
            showLoader : true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.viewNotificationDetails = this.viewNotificationDetails.bind(this);
    }
    componentDidMount = () => {
        this.getAllNotifications();
    };
    getAllNotifications = () => {
        let apiEndpoint = 'api/system/emailnotification/list';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    if (response.data.list && response.data.list.length) {
                        this.setState({
                            email_notification : response.data.list,
                            showLoader : false,
                        });
                    }
                } else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
            this.setState({ 
                formFields: ff
            });        
    };

    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (data) {
                this.setState({
                    buttonLoader: true,
                });
                httpServices.post('api/system/emailnotification/edit', { data })
                    .then(response => {
                        if (response.data.valid) {
                            this.getAllNotifications();
                            this.setState({
                                Open: false,
                                snackbarOpen: true,
                                snackbarType: "success",
                                message: "Email Notifications updated successfully",
                                buttonLoader: false,
                            });
                        } else {
                            this.setState({
                                errors : response.data.errors,
                                buttonLoader: false,
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    clearForm = () => {
        const ff = {
             'id':0,
             'name': null,
             'code': null,
             'description': null,
             'status': null,
             'subject' : null
          };
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    
    onClose = () =>{
        this.clearForm();
        this.setState({
            Open: false
        });
    };

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }
    
    viewNotificationDetails  = (Id) => {
        history.push('notificationview/' + Id);
    }

    onUpdate = (id) => {
        return httpServices.post('api/system/emailnotification/edit', {'id': id})
            .then(response => { 
                if (response.data.permission) {
                    let data = response.data.event;
                    let statuses = response.data.status;
                    if (data) {
                        const inputs = {
                            'id': data.id,
                            'name': data.name,
                            'code': data.code,
                            'description': data.description,
                            'user_statuses': statuses,
                            'user_status' : data.status,
                            'category': data.category,
                            'subject' : data.subject,
                            'loggedin_user' : data.loggedin_user,
                        };
                        this.setState({
                            formFields: inputs,
                            Open: true,
                            id: data.id,
                        });
                    }
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    } 

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.description || this.state.formFields.description.trim() === '') {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.subject  || this.state.formFields.subject.trim() === '') {
            errors["subject"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <EmailNotificationComponent
               Title={this.state.title}
               EmailNotifications = {this.state.email_notification}
               FormFields={this.state.formFields}
               OnSubmit={this.handleSubmit} 
               HandleOnChange={this.handleOnChange}
               ViewNotificationDetails = {this.viewNotificationDetails}
               Open= {this.state.Open} 
               OnClose={this.onClose.bind(this)}
               OnUpdate={this.onUpdate.bind(this)} 
               Errors={this.state.errors}
               ButtonLoader = {this.state.buttonLoader}
               SnackbarOpen = {this.state.snackbarOpen}
               SnackbarType = {this.state.snackbarType}
               SnackbarClose = {this.snackbarClose.bind(this)}
               Message = {this.state.message}
            />
            :
            <ErrorPage/>}
            </>
        )
    }
}