import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CustomDropZone } from '../../common/customDropZone';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import { CustomDatePicker } from '../../common/CustomDatePicker';

export const ExpenseForm = (props) => {
    function makeItems(data) {
        const items = [];
        for (let row of data) {
            items.push(<ListSubheader key={row.id}>{row.name}</ListSubheader>);
            for (let prj of row.projects) {
                if (parseInt(prj.paytype) === 1) {
                    items.push(
                        <MenuItem value={prj.id}> {prj.name}</MenuItem>
                    );
                }
                else {
                    items.push(
                        <MenuItem value={prj.id}> {prj.code + ': ' + prj.name}</MenuItem>
                    );
                }
            }
        }
        return items;
    }

    const FormProps = props;
    let maxDate = new Date();
    if(typeof FormProps.relievedDate !== 'undefined' && FormProps.relievedDate){
        if(moment().isAfter(FormProps.relievedDate)){
            maxDate = moment(FormProps.relievedDate);
        }
    }
    return (
        <div className={'p1'}>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <div className={'ts-input datepicker'}>
                                    <FormControl>
                                         <span className={'expense-add-label'}>Date *</span>
	    					 <CustomDatePicker
 			                               name = "expense_date"
                        				id = "expense_date"
                                			selectedDate = {FormProps.formFields.expense_date}
                                			handleDateChange = {FormProps.HandleExpenseDateChange}
                                			datepickerType = {'keyboard'}
                                			error = {FormProps.errors.expense_date}
                                			maxDate = {maxDate}
	    						minDate={FormProps.joinedDate}
                                			required = {true}
                                			renderInput = {''}
	    						disabled = { (FormProps.Current==='submit' || FormProps.Current==='review') ? true : false}
                            				/>
                                                </ FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className={'ts-input select'}>
                                                <FormControl>
                                                    <span className={'expense-add-label'}>Expense Type *</span>
                                                    <Select
                                                        value={(typeof FormProps.formFields.expense_type !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.expense_type : 0}
                                                        onChange={FormProps.handleOnChange}
                                                        inputProps={{
                                                            name: 'expense_type',
                                                            id: 'expense_type',
                                                        }}
                                                        disabled = { (FormProps.Current==='review') ? true : false}
                                                        className={'input-item'}
                                                    >
                                                        <MenuItem value={0}>Select</MenuItem>
                                                        {props.expenseTypes.map((row, i) =>
                                                            <MenuItem value={row.id}>{row.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                    <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.expense_type && FormProps.errors.expense_type}</small>
                                                </ FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className={'ts-input select'}>
                                                <FormControl>
                                                    <span className={'expense-add-label'}>Expense Category * </span>
                                                    <Select
                                                        value={(typeof FormProps.formFields.expense_category !== 'undefined' && !FormProps.dialogOpen) ? (FormProps.formFields.expense_category !== null ? FormProps.formFields.expense_category : '0') :  '0'}
                                                        onChange={FormProps.handleOnChange}
                                                        className={'input-item'}
                                                        inputProps={{
                                                            id: 'expense_category',
                                                            name: 'expense_category',
                                                        }}>
                                                        <MenuItem value={0}>Select</MenuItem>
                                                        {props.expenseCategories.map((row, j) =>
                                                            <MenuItem value={row.id}>{row.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                    <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.expense_category && FormProps.errors.expense_category}</small>
                                                </ FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className={'ts-input select'}>
                                                <FormControl>
                                                    <span className={'expense-add-label'}>Expense Code {(FormProps.Current==='review') ? '*' : ''}</span>
                                                    <Select
                                                        value={(typeof FormProps.formFields.expense_code !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.expense_code : '0'}
                                                        onChange={FormProps.handleOnChange}
                                                        className={'input-item'}
                                                        inputProps={{
                                                            name: 'expense_code',
                                                            id: 'expense_code',
                                                        }}>
                                                        <MenuItem value={0}>Select</MenuItem>
                                                        {props.expenseCodes.map((row, l) =>
                                                            <MenuItem value={row.id}>{row.code + ': ' + row.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                    <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.expense_code && FormProps.errors.expense_code}</small>
                                                </ FormControl>
                                                </div>
                                            </Grid>
                                            
                                            <Grid item xs={12} md={9}> 
                                                <div className={'ts-input select'}>
                                                <FormControl>
                                                    <span className={'expense-add-label'}>Project {(FormProps.Current==='review') ? '*' : ''} </span>
                                                    <Select
                                                        value={(typeof FormProps.formFields.project !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.project : '0'}
                                                        onChange={FormProps.handleOnChange}
                                                        fullwidth= "true"
                                                        className={'input-item'}
                                                        inputProps={{
                                                            name: 'project',
                                                            id: 'project',
                                                        }}>
                                                        <MenuItem value={0}>Select</MenuItem>
                                                        {makeItems(props.projectCodes)}

                                                    </Select>
                                                    <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.project && FormProps.errors.project}</small>
                                                </ FormControl>
                                                </div>
                                            </Grid>{(FormProps.formFields.expense_type === 1) &&
                                                <Grid item xs={12} md={3}>
                                                    <div className={'ts-input text'}>
                                                    <FormControl>
                                                        <span className={'expense-add-label'}>Amount *</span>
                                                        <TextField
                                                            id="amount"
                                                            name="amount"
                                                            placeholder="Amount" 
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled = {(FormProps.Current==='review') ? true : false}
                                                            InputProps={{
                                                                startAdornment:<InputAdornment position="start">{props.currencySymbol}</InputAdornment>,
                                                            }}
                                                            multiline={true}
                                                            type="number"
                                                            className={'input-item tex-right'}
                                                            value={(typeof FormProps.formFields.amount !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.amount : null}
                                                            onChange={FormProps.handleOnChange}
                                                            error={typeof FormProps.errors !== 'undefined' && FormProps.errors.amount && FormProps.errors.amount}
                                                        />
                                                        <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.amount && FormProps.errors.amount}</small>
                                                    </ FormControl>
                                                    </div>
                                                </Grid>
                                            }
                                            {(FormProps.formFields.expense_type === 2) && 
                                            <>
                                            <Grid item xs={12} md={3}>
                                                <div className={'ts-input text'}>
                                                <FormControl>
                                                    <span className={'expense-add-label'}>Mileage *</span>
                                                    <TextField
                                                        id="mileage"
                                                        name="mileage"
                                                        placeholder="Mileage"
                                                        className={'input-item'}
                                                        disabled = {(FormProps.Current==='review') ? true : false}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        multiline={true}
                                                        type="number"
                                                        value={(typeof FormProps.formFields.mileage !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.mileage : null}
                                                        error={typeof FormProps.errors !== 'undefined' && FormProps.errors.mileage}
                                                        onChange={FormProps.handleOnChange}
                                                    />
                                                    <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.mileage && FormProps.errors.mileage}</small>
                                                </ FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className={'ts-input text expense-milage-info'}>
                                                    <div>
                                                        <span>Amount : {(FormProps.formFields.amount) ? props.currencySymbol + FormProps.formFields.amount : null}</span>
                                                    </div>
                                                    <small>Rate per mile : {props.currencySymbol+FormProps.mileageRate}</small>
                                                </div>
                                            </Grid>
                                            </>
                                            }
                                            <Grid item xs={12} >
                                                <div className={'ts-input textarea'}>
                                                <span className={'expense-add-label'}>Description *</span>
                                                <TextField
                                                    id="desc"
                                                    name="desc"
                                                    type="text"
                                                    multiline
                                                    variant="outlined"
                                                    autoFocus={props.focus}
                                                    className={'input-item'}
                                                    value={(typeof FormProps.formFields.desc !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.desc : ''}
                                                    fullWidth
                                                    onChange={props.handleOnChange}
                                                    error={typeof FormProps.errors !== 'undefined' && FormProps.errors.desc && FormProps.errors.desc}
                                                />
                                                <small className='error'>{typeof FormProps.errors !== 'undefined' && FormProps.errors.desc && FormProps.errors.desc}</small>
                                                </div>
                                            </Grid>
                                            <inputType
                                                id={'id'}
                                                name={'id'}
                                                placeholder="Id"
                                                type="hidden"
                                                className={'te-input'}
                                                value={(typeof FormProps.formFields.id !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.id : 0}
                                            />
                                            <inputType
                                                id={'attached_files'}
                                                name={'attached_files'}
                                                placeholder="attached_files"
                                                type="hidden"
                                                className={'te-input'}
                                                onChange={FormProps.handleOnChange}
                                                value={(typeof FormProps.formFields.attached_files !== 'undefined' && !FormProps.dialogOpen) ? FormProps.formFields.attached_files : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomDropZone 
                                            Open={props.Open}
                                            cancel={props.cancel} 
                                            attachFiles={props.attachFiles} 
                                            removeItem={props.removeItem}
                                            uploadTitle={props.uploadTitle}
                                            OriginalFileNames={props.OriginalFileNames}
                                            LoginUser={props.LoginUser}
                                            attachedFileNames={props.attachedFileNames}
                                            fileUploadCallback={props.fileUploadCallback}
                                            handleAttachmentSelection={props.handleAttachmentSelection}
                                            selectedFile={props.selectedFile}
                                            recentFiles={props.recentFiles}
                                            getRecentFiles={props.getRecentFiles}
                                            Filetype={props.Filetype}
                                        />
                                    {typeof FormProps.formFields.amount !== 'undefined' && parseFloat(FormProps.formFields.amount) >= parseFloat(props.expenseMinAmount) && !(parseFloat(FormProps.formFields.mileage)) && (typeof props.attachedFileNames !== 'undefined' && props.attachedFileNames.length === 0) && <Grid item xs={12} md={12}>
                                        <ul>
                                            <li className='error'>{"Receipts are required for all expenses "+props.currencySymbol+props.expenseMinAmount+" and above."}</li>
                                            <li className='error'>Please attach scanned/digital copy of your receipt/bill/invoice.</li>
                                            <li className='error'>{"The file should be in JPG/PNG/PDF file format and the file size should be less than "+FormProps.formFields.uploadSize+"B."}</li>
                                            <li className='error'>{" Please attach any email approvals available along with the supporting documents."}</li>
                                        </ul>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </div>
    )
}

