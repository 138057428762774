import 'date-fns';
import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CustomDatePicker } from '../../common/CustomDatePicker';

import { DialogBoxLarge } from '../../common/dialog.large';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { formatName } from '../../common/common';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';

// TBD
const approvalMethods = [
    {name : 'PM', value : 'PM'},
    {name : 'COR', value : 'COR'},
    { name: 'No Request Approval Required', value: 'no_request_approval_required'},
];
export const ProjectAddComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxLarge
                Open={props.Open}
                Cancel={props.OnClose}
                ButtonName={"Save"}
                OnSubmit={props.Save}
                Title={props.Title}
                Loading ={props.ButtonLoader}
                Content={
                    <DialogAddProject FormProps={props} />
                }
            />
            {(props.PopupType === 'sub_project_edate_confirmation' && props.ValidationPopupOpen) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                Title={"Confirmation"}
                ButtonName={"Yes"}
                DisplayNoButton={true}
                OnNoClick={() => props.SubProjectStatusChangeConfirmation(0)}
                OnSubmit={() => props.SubProjectStatusChangeConfirmation(1)}
                HideCancel={true}
                Content={
                    <DialogWithConfirmMessage msg={props.ValidationPopupMsg} />
                }
            />
            }
            {(props.PopupType === 'sub_project_status_confirmation' && props.ValidationPopupOpen) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                ButtonName={"Yes"}
                DisplayNoButton={true}
                OnNoClick={() => props.SubProjectStatusChangeConfirmation(0)}
                OnSubmit={() => props.SubProjectStatusChangeConfirmation(1)}
                Title={"Confirmation"}
                HideCancel={true}
                Content={
                    <DialogWithConfirmMessage msg={props.ValidationPopupMsg} />
                }
            />
            }
            {(props.PopupType === 'active_task_edate_confirmation' && props.ValidationPopupOpen) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                ButtonName={"Yes"}
                DisplayNoButton={true}
                OnNoClick = {() => props.TaskEndDateConfirmation(0)} 
                OnSubmit={() => props.TaskEndDateConfirmation(1)}
                Title={"Confirmation"}
                Content={
                    <DialogWithConfirmMessage msg={"Do you want to update the end date of all active tasks?"} />
                }
            />
            }
            {(props.PopupType === 'dialog_confirm_dates' && props.ValidationPopupOpen) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                ButtonName={"Yes"}
                OnSubmit={props.ConfirmDatesSubmit}
                Title={"Confirmation"}
                Content={
                    <DialogConfirmDates 
                        msg = {props.ValidationPopupMsg} 
                        FormValues = {props.FormValues}
                        Errors = {props.Errors}
                        HandleOnChange = {props.HandleOnChange}  
                        DateConfirmationError = {props.DateConfirmationError}
                    />
                }
            />
            } 
            {(props.PopupType === 'end_date_confirmation_for_all_subprojects' && props.ValidationPopupOpen) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                ButtonName={"Ok"}
                OnSubmit={props.ConfirmationClose}
                Title={"End Date"}
                Content={
                    <DialogSetEndDateSubProjects 
                        SubProjects = {props.SubProjects} 
                        FormValues = {props.FormValues}
                        HandleDateChange = {props.handleDateChange}
                        HandleEndDateConfirmationForClosed = {props.HandleEndDateConfirmationForClosed}
                    />
                }
            />
            }
            {((props.AdditionalResources != undefined && props.AdditionalResources.length > 0) || (props.AdditionalLcats != undefined && props.AdditionalLcats.length > 0)) &&
            <DialogBoxMedium
                Open = {props.ValidationPopupOpen}
                Cancel = {props.ConfirmationClose}
                ButtonName={"Ok"}
                OnSubmit={props.SaveProject}
                Title={"Copy Labor categories/ Resources to parent project"}
                Content={
                    <CopySubprojectDataToParent 
                        AdditionalResources = {props.AdditionalResources} 
                        AdditionalLcats = {props.AdditionalLcats}
                    />
                }
            />
            }
        </React.Fragment>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}

const DialogConfirmDates = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                    <span>{props.msg}</span>
                </Grid>
                {(typeof props.DateConfirmationError !== 'undefined' && props.DateConfirmationError !== '') &&
                <Grid item xs={12} md={12}>
                    <span className={'error'}>{props.DateConfirmationError}</span>
                </Grid>
                }
                <Grid item xs={12} md={6}>
                    <FormControl>
                    <TextField
                        variant="standard"
                        id="confirmStartDate"
                        name="confirmStartDate"
                        label="Start Date"
                        type="date"
                        required
                        value={(typeof props.FormValues.confirmStartDate !== 'undefined') ? props.FormValues.confirmStartDate : null}
                        onChange={props.HandleOnChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            max : (typeof props.FormValues.confirmEndDate !== 'undefined') ? props.FormValues.confirmEndDate : null,
                        }}
                        error={(typeof props.Errors.confirmStartDate !== 'undefined') && true}
                        helperText={(typeof props.Errors.confirmStartDate !== 'undefined') && props.Errors.confirmStartDate}
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                    <TextField
                        variant="standard"
                        id="confirmEndDate"
                        name="confirmEndDate"
                        label="End Date"
                        type="date"
                        value={(typeof props.FormValues.confirmEndDate !== 'undefined') ? props.FormValues.confirmEndDate : null}
                        onChange={props.HandleOnChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min : (typeof props.FormValues.confirmStartDate !== 'undefined') ? props.FormValues.confirmStartDate : null,
                        }}
                        error={(typeof props.Errors.confirmEndDate !== 'undefined') && true}
                        helperText={(typeof props.Errors.confirmEndDate !== 'undefined') && props.Errors.confirmEndDate}
                    />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const CopySubprojectDataToParent = (props) => {
    return (
        <Grid>
            The following resources, labor categories are not present in parent project. Copy this to parent and proceed?
            {(typeof(props.AdditionalResources) !== 'undefined' && (props.AdditionalResources.length > 0)) && 
                <h4>Resources</h4>
            }
            {(typeof(props.AdditionalResources) !== 'undefined' && (props.AdditionalResources.length > 0)) && props.AdditionalResources.map((resource, i) =>
                    <p>{resource}</p>
            )}
            {(typeof(props.AdditionalLcats) !== 'undefined' && (props.AdditionalLcats.length > 0)) && 
                <h4>Labor Categories</h4>
            }
            {(typeof(props.AdditionalLcats) !== 'undefined' && (props.AdditionalLcats.length > 0)) && props.AdditionalLcats.map((lcat, i) =>
                    <p>{lcat}</p>
            )}
        </Grid>
    )
}
const DialogSetEndDateSubProjects = (props) => {
    let subProjects = (typeof props.SubProjects !== 'undefined') ? props.SubProjects : [];
    return (
        <>
        { (subProjects && subProjects.length) ?
        <form className="form-fullwidth">
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                    <span>Confirm project and its sub-projects end date.</span>
                </Grid>
                <Grid item xs={12} md={4}>
                    <span>{props.FormValues.name}</span>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomDatePicker
                        name = "endDate"
                        id = "endDate"
                        selectedDate = {props.FormValues.endDate}
                        handleDateChange = {props.HandleDateChange}
                        label = "End Date"
                        minDate = {(typeof props.FormValues.startDate !== 'undefined') ? props.FormValues.startDate : null}
                    />
                </Grid>
                {subProjects.map((sb, key) =>
                <>
                {(sb.project_status === 1 || sb.project_status === 2) &&
                <>    
                    <Grid item xs={12} md={4}>
                        <span>{sb.project_name}</span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomDatePicker
                            name={sb.project_id}
                            id={sb.project_id}
                            selectedDate={(sb.project_edate === null) ? props.FormValues.endDate : sb.project_edate}
                            handleDateChange={props.HandleEndDateConfirmationForClosed}
                            label="End Date"
                            minDate={(typeof props.FormValues.startDate !== 'undefined') ? props.FormValues.startDate : null}
                        />
                    </Grid>
                </>
                }
                </>
                )}
            </Grid>
        </form>
        :
        <>
            <form className="form-fullwidth">
            <Grid container spacing={3} >
            <Grid item xs={12} md={12}>
                <span>Confirm project end date. Status of all  tasks will be updated to closed.</span>
            </Grid>
            <Grid item xs={12} md={12}>
                <CustomDatePicker
                    name = "endDate"
                    id = "endDate"
                    selectedDate = {props.FormValues.endDate}
                    handleDateChange={props.HandleDateChange}
                    label = "End Date"
                    clearable = {true}
                    minDate = {(typeof props.FormValues.startDate !== 'undefined') ? props.FormValues.startDate : null}
                />
            </Grid>
            </Grid>
            </form>
        </>
        }
        </>
    )
}

const DialogAddProject = (p) => {
    const { FormProps: props } = p;
    let projects = (typeof props.FormInputs.parentProjects !== 'undefined') ? props.FormInputs.parentProjects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof props.FormValues.projectParent !== 'undefined') ? [props.FormValues.projectParent].map(Number) : []));
    }
    if (typeof props.PostValidationErr !== 'undefined' && props.PostValidationErr !== '') {
        let element = document.getElementById('errorMsg');
        if (element) {
            element.scrollIntoView(true);
        }
    }
    return (
        <>
        {props.Permission ?
        <form className="form-fullwidth" onSubmit={props.OnSubmit} >
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} id="errorMsg">
                {(typeof props.PostValidationErr !== 'undefined' && props.PostValidationErr !== '') &&
                    <span className={'error'}>{props.PostValidationErr}</span>
                }
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            variant="standard"
                            id="name"
                            name="name"
                            label="Project Name"
                            required
                            type="text"
                            value={(typeof props.FormValues.name !== 'undefined') ? props.FormValues.name : ''}
                            onChange={props.HandleOnChange}
                            error={(typeof props.Errors.name !== 'undefined') && true}
                            helperText={props.Errors.name}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            required
                            type="text"
                            multiline={true}
                            variant="outlined"
                            value={(typeof props.FormValues.description !== 'undefined') ? props.FormValues.description : ''}
                            onChange={props.HandleOnChange}
                            error={( typeof props.Errors.description !== 'undefined') && true}
                            helperText={props.Errors.description}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl>
                        <TextField
                            id="chargeCode"
                            name="chargeCode"
                            label="Charge Code"
                            required
                            type="text"
                            variant="standard"
                            value={(typeof props.FormValues.chargeCode !== 'undefined') ? props.FormValues.chargeCode : ''}
                            onChange={props.HandleOnChange}
                            error={(typeof props.Errors.chargeCode !== 'undefined') && true}
                            helperText={props.Errors.chargeCode}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl  variant='standard'>
                        <InputLabel htmlFor="client" required >Client</InputLabel>
                        <Select
                            value={(typeof props.FormValues.client !== 'undefined') ? props.FormValues.client : ''}
                            onChange={props.HandleOnChange}
                            disabled = {(props.ProjectParent && props.ProjectParent > 0) ? true : false}
                            inputProps={{
                                name: 'client',
                                id: 'client',
                            }}>
                            {typeof props.FormInputs.clients !== 'undefined' && props.FormInputs.clients.map((cl, key) =>
                                <MenuItem value={cl.client_id} >{cl.client_name}</MenuItem>
                            )}
                        </Select>
                        {( typeof props.Errors.client !== 'undefined') &&
                        <FormHelperText className='error'>{props.Errors.client}</FormHelperText>
                        }
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl>
                        <TreeSelectComponent
                            dropdown_mode={'true'}
                            data={typeof projects ? projects : []}
                            disabled = {(props.ProjectParent && props.ProjectParent > 0) ? true : false}
                            placeholderText={"Parent Project"}
                            type='project'
                            HandleTreeChange={props.HandleTreeChange}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl  variant='standard'>
                        <InputLabel htmlFor="projectManager" required >Project Manager</InputLabel>
                        <Select
                            value={(typeof props.FormValues.projectManager !== 'undefined') ? props.FormValues.projectManager : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'projectManager',
                                id: 'projectManager',
                            }}>
                            {typeof props.FormInputs.users !== 'undefined' && props.FormInputs.users.map((usr, key) =>
                                <MenuItem value={usr.user_id} >{formatName(usr.first_name,null,usr.last_name, props.NameFormat)}</MenuItem>
                            )}
                        </Select>
                        {( typeof props.Errors.projectManager !== 'undefined') &&
                        <FormHelperText className='error'>{props.Errors.projectManager}</FormHelperText>
                        }
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl>
                        <CustomDatePicker
                            name = "startDate"
                            id = "startDate"
                            selectedDate = {props.FormValues.startDate}
                            handleDateChange = {props.handleDateChange}
                            error = {props.Errors.startDate}
                            required = {true}
                            label = "Start Date"
                            maxDate = {(typeof props.FormValues.endDate !== 'undefined') ? props.FormValues.endDate : null}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl>
                        <CustomDatePicker
                            name = "endDate"
                            id = "endDate"
                            selectedDate = {props.FormValues.endDate}
                            handleDateChange = {props.handleDateChange}
                            error = {props.Errors.endDate}
                            label = "End Date"
                            clearable = {true}
                            minDate = {(typeof props.FormValues.startDate !== 'undefined') ? props.FormValues.startDate : null}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant='standard'>
                        <InputLabel htmlFor="projectStatus" required >Status</InputLabel>
                        <Select
                            value={(typeof props.FormValues.projectStatus !== 'undefined') ? props.FormValues.projectStatus : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'projectStatus',
                                id: 'projectStatus',
                            }}>
                            {typeof props.FormInputs.pstatuses !== 'undefined' && props.FormInputs.pstatuses.map((pst, key) =>
                                <MenuItem value={pst.project_status_id} >{pst.project_status_name}</MenuItem>
                            )}
                        </Select>
                        {( typeof props.Errors.projectStatus !== 'undefined') &&
                        <FormHelperText className='error'>{props.Errors.projectStatus}</FormHelperText>
                        }
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl  variant='standard'>
                        <InputLabel htmlFor="cor">COR</InputLabel>
                        <Select
                            value={(typeof props.FormValues.cor !== 'undefined') ? props.FormValues.cor : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'cor',
                                id: 'cor',
                            }}>
                            {typeof props.FormInputs.cors !== 'undefined' && props.FormInputs.cors.map((cor, key) =>
                                <MenuItem value={cor.user_id} >{formatName(cor.first_name, null, cor.last_name)}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField
                            id="minTimeInc"
                            name="minTimeInc"
                            label="Minimum Timeslice Increment"
                            type="text"
                            variant="standard"
                            value={(typeof props.FormValues.minTimeInc !== 'undefined') ? props.FormValues.minTimeInc : ''}
                            onChange={props.HandleOnChange}
                            error={(typeof props.Errors.minTimeInc !== 'undefined') && true}
                            helperText={props.Errors.minTimeInc}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl  variant='standard'>
                        <InputLabel htmlFor="payType">Pay Type</InputLabel>
                        <Select
                            value={(typeof props.FormValues.payType !== 'undefined') ? props.FormValues.payType : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'payType',
                                id: 'payType',
                            }}>
                            {typeof props.FormInputs.pay_types !== 'undefined' && props.FormInputs.pay_types.map((ptype, key) =>
                                <MenuItem value={ptype.id} >{ptype.name}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'prelim_review_flag',
                                    id: 'prelim_review_flag',
                                }}
                                value={(props.FormValues && typeof props.FormValues.prelim_review_flag !== 'undefined') ? props.FormValues.prelim_review_flag : false}
                                checked={(props.FormValues && typeof props.FormValues.prelim_review_flag !== 'undefined') ? props.FormValues.prelim_review_flag : false}
                                color="primary"
                            />
                        }
                        label="Timesheet Preliminary Review"
                    />
                </Grid>
		{(typeof props.FormInputs.system_timesheet_location != 'undefined' && props.FormInputs.system_timesheet_location) ?
		<>
		<Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'timesheet_location',
                                    id: 'timesheet_location',
                                }}
                                value={(props.FormValues && typeof props.FormValues.timesheet_location !== 'undefined') ? props.FormValues.timesheet_location : false}
                                checked={(props.FormValues && typeof props.FormValues.timesheet_location !== 'undefined') ? props.FormValues.timesheet_location : false}                    
				color="primary"
                            />
                        }
                        label="Location"
                    />
                </Grid>
                {(typeof props.FormValues.timesheet_location !== 'undefined' && props.FormValues.timesheet_location) ?
		<>
		<Grid item xs={12} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'lcr_required',
                                    id: 'lcr_required',
                                }}
                                value={(props.FormValues && typeof props.FormValues.lcr_required !== 'undefined') ? props.FormValues.lcr_required : false}
                                checked={(props.FormValues && typeof props.FormValues.lcr_required !== 'undefined') ? props.FormValues.lcr_required : false}
                                color="primary"
                            />
                        }
                        label="LCR Required"
                    />
                </Grid>
                {(typeof props.FormValues.lcr_required !== 'undefined' && props.FormValues.lcr_required) ?
                <Grid item xs={12} md={3}>
                    <FormControl  variant='standard'>
                        <InputLabel htmlFor="approvalMethod"> Request Approval Method </InputLabel>
                        <Select
                            value={(typeof props.FormValues.approvalMethod !== 'undefined') ? props.FormValues.approvalMethod : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'approvalMethod',
                                id: 'approvalMethod',
                            }}>
                            {typeof approvalMethods !== 'undefined' && approvalMethods.map((apm, key) =>
                                <MenuItem value={apm.value} >{apm.name}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>:<></>
                }
		</>
		:<></>
		}
		</> 
		:<></>}
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'notifyPm',
                                    id: 'notifyPm',
                                }}
                                value={(props.FormValues && typeof props.FormValues.notifyPm !== 'undefined') ? props.FormValues.notifyPm : false}
                                checked={(props.FormValues && typeof props.FormValues.notifyPm !== 'undefined') ? props.FormValues.notifyPm : false}
                                color="primary"
                            />
                        }
                        label="Notify Project Manager"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    name: 'reqLabourCategory',
                                    id: 'reqLabourCategory',
                                }}
                                onChange={props.HandleOnChange}
                                value={(props.FormValues && typeof props.FormValues.reqLabourCategory !== 'undefined') ? props.FormValues.reqLabourCategory : false}
                                checked={(props.FormValues && typeof props.FormValues.reqLabourCategory !== 'undefined') ? props.FormValues.reqLabourCategory : false}
                                color="primary"
                            />
                        }
                        label="Require Labor Category"
                    />
                </Grid>
                {props.ShowInheritResources &&
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    name: 'inheritResource',
                                    id: 'inheritResource',
                                }}
                                onChange={props.HandleOnChange}
                                value={(props.FormValues && typeof props.FormValues.inheritResource !== 'undefined') ? props.FormValues.inheritResource : false}
                                checked={(props.FormValues && typeof props.FormValues.inheritResource !== 'undefined') ? props.FormValues.inheritResource : false}
                                color="primary"
                            />
                        }
                        label="Inherit Resources"
                    />
                </Grid>
                }
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    name: 'reqClientTimesheet',
                                    id: 'reqClientTimesheet',
                                }}
                                onChange={props.HandleOnChange}
                                value={(props.FormValues && typeof props.FormValues.reqClientTimesheet !== 'undefined') ? props.FormValues.reqClientTimesheet : false}
                                checked={(props.FormValues && typeof props.FormValues.reqClientTimesheet !== 'undefined') ? props.FormValues.reqClientTimesheet : false}
                                color="primary"
                            />
                        }
                        label="Require Client Timesheet System"
                    />
                </Grid>
                {props.ShowInheritReviewers &&
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{
                                    name: 'inheritReviewers',
                                    id: 'inheritReviewers',
                                }}
                                onChange={props.HandleOnChange}
                                value={(props.FormValues && typeof props.FormValues.inheritReviewers !== 'undefined') ? props.FormValues.inheritReviewers : false}
                                checked={(props.FormValues && typeof props.FormValues.inheritReviewers !== 'undefined') ? props.FormValues.inheritReviewers : false}
                                color="primary"
                            />
                        }
                        label="Inherit Reviewers"
                    />
                </Grid>
                }
            </Grid>
        </form>
        :
        <><span className={'error'}>{props.PermissionErrMsg}</span></>
        }
        </>
    )
}



