import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import parse from 'html-react-parser';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HistoryIcon from '@mui/icons-material/History'

import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../common/dialog.medium';
import { HoursByProject } from '../details/HoursByProject';
import { TimeSheetView } from '../details/TimeSheetView';
import { StatusChangeLog } from '../details/StatusChangeLog';
import { CustomizedSnackbars } from '../../common/snackbars';
import { TimeOffRequests } from '../details/TimeOffRequests';
import { LocationChangeRequest } from '../details/LocationChangeRequest';
import { ProjectReviewStatus } from '../details/ProjectReviewStatus';
import { LateSubmission } from '../details/LateSubmission';
import { LateSubmissionHistory } from '../details/LateSubmissionHistory';
import { TimeOffLog } from '../details/TimeOffLog';
import { YellowButton } from '../../common/YellowButton';
import { formatName } from '../../common/common';
import { ClearanceConfirmation } from '../details/ClearanceConfirmation';
import Tooltip from '@mui/material/Tooltip';

function nl2br(str){
 return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

const DialogReject = (props) => {
    return (
        <div>
            {/* Display the projects for rejection, if multiple_review is enabled*/}
            {(props.formData.multiple_review === 1) &&
                <>
                    <p className='error'>{props.errors.projects}</p>
                    <div>
                        <div>Select the project(s) you want to reject</div>
                        <ul className={'list-standard checkbox-list'}>
                            {props.formData.review_projects.map((item) => 
                                <li className="list-item">
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={_R.includes(item.project_id, props.formFields.projects) ? true : false}
                                                    value={item.project_id}
                                                    id="project"
                                                    name="project"
                                                    color="primary"
                                                    onChange={props.HandleOnChange}
                                                />
                                            }
                                            label={item.project_path+' ('+item.review_type+')'}
                                        />
                                    </FormControl>
                                </li>
                            )}
                            {props.formData.user_approved_projects.map((item) =>
                                <li className="list-item">
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={_R.includes(item.project_id, props.formFields.projects) ? true : false}
                                                    value={item.project_id}
                                                    id="project"
                                                    name="project"
                                                    color="primary"
                                                    onChange={props.HandleOnChange}
                                                />
                                            }
                                            label={item.project_path+' ('+item.review_type+')'}
                                        />
                                    </FormControl>
                                </li>                                       
                            )}
                        </ul>
                    </div>
                </>
            }
            {/* Common section for Reject */}
            <Box className={'mt-1'}>
                <TextField
                    required
                    id="reason"
                    name="reason"
                    label="Reason"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.errors.reason && true}
                    helperText = {typeof props.errors.reason !== 'undefined' ? props.errors.reason : ''}
                />
                <p><i>* Note : Rejecting the timesheet changes the status to <strong>Rejected</strong>. Use this option if the timesheet needs to be modified by the staff.</i></p>
            </Box>

        </div>
    )
}
const DialogUnApproveValidation = (props) => {
    return (
        <div>{props.Message}</div>
    )
}
const DialogUnApprove = (props) => {
    return (
        <div>
            {/* Display the projects for un-approve, if multiple_review is enabled*/}
            {(props.formData.multiple_review === 1) &&
                <>
                    <p className='error'>{props.errors.projects}</p>
                    <div>
                        <div>Select the project(s) you want to Un-approve</div>
                        <ul className={'list-standard checkbox-list'}>
                            {props.formData.user_approved_projects.map((item) =>
                                <li className="list-item">
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={_R.includes(item.project_id, props.formFields.projects) ? true : false}
                                                    value={item.project_id}
                                                    id="project"
                                                    name="project"
                                                    color="primary"
                                                    onChange={props.HandleOnChange}
                                                />
                                            }
                                            label={item.project_path+' ('+item.review_type+')'}
                                        />
                                    </FormControl>
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            }

            {/* Common section for Un-Approve */}
            <Box className={'mt-1'}>
                <TextField
                    id="reason"
                    name="reason"
                    label="Comment"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error = {props.errors.reason && true}
                    helperText = {typeof props.errors.reason !== 'undefined' ? props.errors.reason : ''}
                />
                <p><i>* Note: This action changes the timesheet status to <strong>Submitted</strong>. Use this option if you want to revert approval. Timesheet will appear again in your review list.</i></p>
             </Box>
        </div>
    )
}
const DialogApproveValidation = (props) => {
    return (
        <div>{props.Message}</div>
    )
}
const DialogApprove = (props) => {
    let timesheet = props.formData.timesheet;
    return (
        <div>
            {(typeof props.formData !== 'undefined' && typeof props.formData.late_submission_review !== 'undefined' && props.formData.late_submission_review) &&
            <div className={'waiver-alert full-width'} style={{'border':'1px solid #000','border-radius' : '5px' }}>
                <Grid container spacing={1} className={'p-1rem'}>
                    <Grid item xs={12} md={12}>
                        <div className={'d-flex'}>
                            <div>
                                <ReportProblemIcon fontSize={'small'} className={'error'}/>
                            </div>
                            <div className={'ml10'}>
                                <strong>  A delay in submission of timesheet is noticed</strong>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} className={'mt-1'}>
                        <strong>Delayed by {(typeof props.formData !== 'undefined' && typeof props.formData.timesheet_submission_delay !== 'undefined') ? props.formData.timesheet_submission_delay : ''}</strong>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <span><strong>Reason for late submission:</strong> {(typeof props.formData !== 'undefined' && typeof props.formData.late_submission_reason !== 'undefined' && props.formData.late_submission_reason) ? parse(nl2br(props.formData.late_submission_reason)) : ''}</span>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <p className='error'>{props.errors.waiver_status}</p>
                        <FormControl>
                            <RadioGroup aria-label="name_option" name="waiver_status" value={(typeof props.formFields !== 'undefined' && props.formFields.waiver_status) ? props.formFields.waiver_status : null} onChange={props.HandleOnChange}>
                                { (typeof props.formData !== 'undefined' && typeof props.formData.waiver_statuses !== 'undefined') && props.formData.waiver_statuses.map((wstatus) => 
                                <FormControlLabel value={wstatus.id} control={<Radio />} label={wstatus.status_name} />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={'mt-1'}>
                            <TextField
                                id = "justification"
                                name = "justification"
                                label = "Justification"
                                required
                                type = "text"
                                multiline = {true}
                                variant = "outlined"
                                rows = {3}
                                fullWidth
                                value = {typeof props.formFields.justification !== 'undefined' ? props.formFields.justification : ''}
                                onChange = {props.HandleOnChange}
                                error = {props.errors.justification_error && true}
                                helperText = {props.errors.justification_error}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            }
            {/* Display the projects for approve, if multiple_review is enabled*/}
            {(props.formData.multiple_review === 1) &&
                <>
                    <p className='error'>{props.errors.projects}</p>
                    {(typeof props.formData.review_projects !== 'undefined' && props.formData.review_projects.length > 0) &&
                    <div>
                        <div>Select the project(s) you want to approve</div>
                        <ul className={'list-standard checkbox-list'}>
                            {props.formData.review_projects.map((item) => 
                                <li className="list-item">
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={_R.includes(item.project_id, props.formFields.projects) ? true : false}
                                                    value={item.project_id}
                                                    id="project"
                                                    name="project"
                                                    color="primary"
                                                    onChange={props.HandleOnChange}
                                                />
                                            }
                                            label={item.project_path+' ('+item.review_type+')'}
                                        />
                                    </FormControl>
                                </li>
                            )}
                        </ul>
                    </div>
                    }
                </>
            }
            {!(typeof props.formData !== 'undefined' && typeof props.formData.late_submission_review !== 'undefined' && props.formData.late_submission_review && typeof props.formData.supervisor_review !== 'undefined' && !props.formData.supervisor_review) &&
            <>
            <Box className={'mt-1'}>
                {/* Common section for Reject */}
                {(props.validation !== undefined && props.validation.message !== undefined && props.validation.message != '') && <p>
                    {props.validation.message}
                </p>}
                <p>
                    I hereby certify that the staff member was present and working during the period {timesheet.payperiod_sdate} to {timesheet.payperiod_edate} as indicated by this report. 
                </p>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FormControl required error={props.errors.agree && true} component="fieldset">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(typeof props.formFields.agree !== 'undefined') ? props.formFields.agree : false}
                                        value={(typeof props.formFields.agree !== 'undefined') ? props.formFields.agree : null}
                                        id="agree"
                                        name="agree"
                                        onChange={props.HandleOnChange}
                                    />
                                }
                                label="I agree"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {(typeof props.formFields.agree !== 'undefined' && props.formFields.agree) &&
                            <TextField
                                required
                                id="initials"
                                name="initials"
                                label="Initials"
                                value={props.formFields.initials}
                                variant="outlined"
                                style={{width:'100%'}}
                                onChange={props.HandleOnChange}
                                error={props.errors.initials && true}
                                helperText = {typeof props.errors.initials !== 'undefined' ? props.errors.initials : ''}
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box className={'mt-1'}>
                <TextField
                    id="reason"
                    name="reason"
                    label="Comment"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.errors.reason && true}
                    helperText={typeof props.errors.reason !== 'undefined' ? props.errors.reason : ''}
                />
            </Box>
            </>
            }
        </div>
    )
}
const DialogSubmit = (props) => {
    return (
        <>
        {(props.lateSubmission) && 
            <>
                <div className={'pb-1'}>
                    <b>
                        {"We noticed a delay in timesheet submission. Please provide a reason for the late submission. Your specified reason will be used to justify any waivers for this late submission"}
                    </b>
                </div>
                <div className={'pb-1'}>
                    <TextField
                        id="late_submission_reason"
                        name="late_submission_reason"
                        label="Reason for Late Submission"
                        required
                        type="text"
                        multiline={true}
                        variant="outlined"
                        rows={4}
                        fullWidth
                        onChange={props.HandleOnChange}
                        error={props.errors.late_submission_reason && true}
                    />
                </div>                
            </>
        }
        {!props.validation.valid &&
            <>
                <p>Timesheet validation errors</p>
                <ul>
                    {Object.values(props.validation.messages).map((msg) => 
                        <li>{ parse(msg) }</li>
                    )}
                </ul>
            </>
        }
        <TextField
            id="reason"
            name="reason"
            label="Reason For Submission"
            multiline
            variant="outlined"
            fullWidth
            onChange={props.HandleOnChange}
            required
            error={props.errors.reason && true}
            helperText = {typeof props.errors.reason !== 'undefined' ? props.errors.reason : ''}
        />
        <p>Do you want to Submit timesheet of {formatName(props.formData.timesheet.user_first_name,null,props.formData.timesheet.user_last_name)}  ?</p>
        </>
    )
}

export const ReviewComponent = (props) => {
    //Tabs
    let tabIndex = 'hours_by_project';
    const [tabValue, setTabValue] =  React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    let fwsWarning = '';
    let fwsVariance = '';
    if (props.formData.fws_var_info && typeof props.formData.fws_var_info === 'object') {
        if (props.formData.fws_var_info.fws_warning && props.formData.fws_var_info.fws_warning !== '') {
            fwsWarning = props.formData.fws_var_info.fws_warning;
        } else if (props.formData.fws_var_info.fws_variance) {
            fwsVariance = props.formData.fws_var_info.fws_variance;
        }
    }
    return (
        <div>
            <div className={'section-main section-main-header-fixed'}>
                {!_R.isEmpty(props.formData) &&
                    <div className={'header-fixed'} >
                        <div className={'section-header'}>
                            <div className={'col-left'}>
                                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                                <Typography variant="h6" component="h6" className={'section-title'}>
                                    Review Timesheet for {props.formData.timesheet.payperiod_sdate} - {props.formData.timesheet.payperiod_edate}
                                </Typography>
                            </div>
                            <div className={'actions'}>
                                {props.ShowWaiverStatusUpdateBtn && 
                                    <Button variant="contained" color="primary" onClick={props.UpdateWaiverStatusClick} >Update Waiver Status</Button>
                                }
                                {props.formData.show_approve_button &&
                                    <Button variant="contained" color="primary" onClick={props.ApprovebtnClick} disabled={props.disableApproveBtn}>Approve</Button>
                                }
                                {props.formData.show_reject_button &&
                                    <Button variant="contained" color="secondary" onClick={props.ReviewbtnClick('reject')}>Reject</Button>
                                }
                                {props.formData.show_unapprove_button &&
                                    <YellowButton  buttonName='Un-Approve' onClick={props.ReviewbtnClick('un-approve')} />
                                }
                                {props.formData.show_submit_button &&
                                    <Button variant="outlined" color="primary" onClick={props.SubmitbtnClick}>Submit on Behalf of {formatName(props.formData.timesheet.user_first_name,null,props.formData.timesheet.user_last_name).toUpperCase()}</Button>
                                }
                            </div>
                            <div className={'show-on-scroll'}>
                                <div className={'employee bordered'}>
                                    {!_R.isEmpty(props.formData) && formatName(props.formData.timesheet.user_first_name,null,props.formData.timesheet.user_last_name).toUpperCase()}  
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={'section-body'}>
                    <Paper className={'mb-1'}>
                        {!_R.isEmpty(props.formData) &&
                            <div className={'p-1rem box-details'}>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Status</label>
                                        <span className={'value'}>{props.formData.timesheet.status_name}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Submitted by</label>
                                        <span className={'value'}>{formatName(props.formData.timesheet.sby_first_name,null,props.formData.timesheet.sby_last_name)}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Submitted on</label>
                                        <span className={'value'}>{props.formData.timesheet.submitted_time}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Last Reviewed by</label>
                                        <span className={'value'}>{formatName(props.formData.timesheet.rby_first_name,null,props.formData.timesheet.rby_last_name)}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Last Reviewed on</label>
                                        <span className={'value'}>{props.formData.timesheet.reviewed_time}</span>
                                    </li>
                                </ul>
                                <ul className={'list-standard list-item-right'}>
                                    {(_R.type(props.formData.total_hours) !== 'Undefined' && props.formData.total_hours.length > 0) && props.formData.total_hours.map((t_hrs,index)=>
                                        <>{(parseInt(t_hrs.task_type) !== 0 && t_hrs.code === "WH") ?
                                            props.TimesheetLocation ?
                                            <>
                                                {(_R.type(props.formData.ts_summary) !== 'Undefined' && typeof props.formData.ts_summary.location_hours !== 'undefined' && props.formData.ts_summary.location_hours.length > 0) && props.formData.ts_summary.location_hours.map((l_hrs) =>
                                                    <li className={'list-item list-items-onsite-hours'}>
                                                        <label>Hours ({l_hrs.location})</label>
                                                        <span className={'value right'}>{parseFloat(l_hrs.location_hours).toFixed(2)}</span>
                                                    </li>
                                                )}
                                            </>
                                            :
                                            <></>
                                            : <>
                                                <li className={'list-item list-items-onsite-hours'}>
                                                    <label>{t_hrs.name}</label>
                                                    <span className={'value right'}>{t_hrs.hours}</span>
                                                </li>
                                            </>}
                                        </>
                                    )}
                                    <li className={'list-item list-items-hours'}>
                                        <label>Total Hours</label>
                                        <span className={'value right'}>{_R.type(props.formData.ts_summary) !== 'Undefined' ? props.formData.ts_summary.hours_saved : ''}</span>
                                    </li>
                                    <li className={'list-item list-items-min-hours'}>
                                        <label>Min. Hours Required</label>
                                        <span className={'value right'}>{_R.type(props.formData.min_hours) !== 'Undefined' ? parseFloat(props.formData.min_hours).toFixed(2) : ''}</span>
                                    </li>
                                    <>
                                    {(fwsVariance !== '') &&
                                    <li className={'list-item list-items-min-hours'}>
                                        <label>Standard Hours Variance </label>
                                        <span className={'value right'}>{fwsVariance}</span>
                                    </li>
                                    }
                                    </>
                                    {(typeof props.formData.late_submission_details !== 'undefined' && props.formData.late_submission_details) &&
                                    <>
                                    <li className={'list-item'}>
                                        <label>Delayed By</label>
                                        <span className={'value'}>{(typeof props.formData.late_submission_details.delayed_by !== 'undefined') ? props.formData.late_submission_details.delayed_by : ""}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Reason For Late Submission</label>
                                        <span className={'value'}>{(typeof props.formData.late_submission_details.late_submission_reason !== 'undefined' && props.formData.late_submission_details.late_submission_reason) ? parse(nl2br(props.formData.late_submission_details.late_submission_reason)) : ""}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Late Submission Review Comment</label>
                                        <span className={'value'}>{(typeof props.formData.late_submission_details.waiver_justification !== 'undefined' && props.formData.late_submission_details.waiver_justification) ? parse(nl2br(props.formData.late_submission_details.waiver_justification)) : ""}</span>
                                    </li>
                                    </>
                                    }
                                </ul>
                            </div>
                        }
                        { (typeof props.formData.late_submission_status !== 'undefined' && props.formData.late_submission_status) && <div className={'p-1rem d-flex justify-end'}>
                            <Button color="primary" startIcon={<HistoryIcon />} size="small" onClick={handleScroll}>Late Submission History</Button></div>
                        }
                        <>
                        {(fwsWarning !== '') &&
                        <div className={'fws-warning'}>{fwsWarning}</div>
                        }
                        </>
                    </Paper>
                    <div className={'timesheet_list'}>
                        <TimeSheetView
                            UserTimeIn = {props.formData.user_timein}
                            UserTimeInReq = {props.formData.user_timein_required}
                            UserTimeInDetails = {props.formData.timeinout_rows}
                            TimeinTotal = {props.formData.timein_total}
                            TotalHours = {props.formData.total_hours}
                            TsSummary = {props.formData.ts_summary}
                            MinHours = {props.formData.min_hours}
                            DayOff = {props.formData.day_off ? props.formData.day_off.split(',') : []}
                            FwsVarianceInfo = {props.formData.fws_var_info ? props.formData.fws_var_info : {}}
                            ViewType = {props.ViewType}
                            listReviewNotes = {props.listReviewNotes}
                            Timesheet = {props.formData.timesheet}
                            ReviewNotes = {props.review_comments}
                            DialogOpen = {props.dialogOpen}
                            OnClose = {props.OnClose}
                            PopupType = {props.popup}
                            ReviewNote = {props.reviewNote}
                            HandleReviewNoteChange = {props.HandleReviewNoteChange}
                            AddReviewNote = {props.AddReviewNote}
                            ShowReviewButton = {typeof props.formData.show_approve_button !== 'undefined' ? props.formData.show_approve_button : true}
                            MultipleReview = {props.formData.multiple_review}
                            ProjectsByReviewer = {props.formData.projects_by_reviewer}
                            HandleNonConfirmityChange = {props.HandleNonConfirmityChange}
                            ReviewNoteError = {props.reviewNoteError}
                            TimesheetId = {props.TimesheetId}
                            RequestedPage = {'review-details'}
                            ForceRerender = {props.RerenderSavedTimesheet}
                            NonConfirmity = {props.nconfirmity}
                        />
                    </div>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="primary"
                                aria-label="Timesheet List View Tabs"
                            >
                                <Tab label="Hours By Project" value="hours_by_project" />
                                <Tab label="Status Change Log" value="status_change_log" />
                                <Tab label="Time Off Request" value="timeoff_request" />
                                {(props.formData.multiple_review === 1) &&
                                    <Tab label="Project Review Status" value="project_review_status" />
                                }
                                {(typeof props.formData.late_submission_details !== 'undefined' && props.formData.late_submission_details) &&
                                    <Tab label="Late Submission Status" value="late_submission_status" />
                                }
                                {(typeof props.formData.late_submission_status !== 'undefined' && props.formData.late_submission_status) &&
                                    <Tab label="Late Submission History" value="late_submission_history" />
                                }
                                {props.TimesheetLocation && 
                                    <Tab label="Work Location Change Request" value="location_change_request" />
                                }
                                <Tab label="Time Off Log" value="timeoff_log" />
                                {(props.formData.user_clearence_level) &&
                                    <Tab label="Clearance Confirmation" value="ClearanceConfirmation"/>
                                }
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            {(tabValue === 'hours_by_project') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <HoursByProject
                                        HoursByProject={props.formData.chargecode_summary}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'status_change_log') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <StatusChangeLog
                                        StatusChangeLog={props.formData.history}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'timeoff_request') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <TimeOffRequests
                                        TimeOffRequests={props.formData.time_off}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'project_review_status') && (props.formData.multiple_review === 1) &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <ProjectReviewStatus
                                        ProjectReviewStatus={props.formData.project_review}
                                        MultipleReview={props.formData.multiple_review}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'late_submission_status') && (typeof props.formData.late_submission_details !== 'undefined' && props.formData.late_submission_details) &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <LateSubmission
                                        LateSubmissionDetails={props.formData.late_submission_details}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'late_submission_history') && (typeof props.formData.late_submission_status !== 'undefined' && props.formData.late_submission_status) &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <LateSubmissionHistory
                                        LateSubmissionHistory={props.formData.late_submission_history}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'location_change_request') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <LocationChangeRequest
                                        OffsiteRequest={props.formData.offsites}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'timeoff_log') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <TimeOffLog
                                        TimeOffLog={props.formData.timeofflog}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'ClearanceConfirmation') && (props.formData.user_clearence_level) &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <ClearanceConfirmation
                                        ClearanceConfirmation = {props.formData.selectedClearanceOptions}                                    
                                    /> 
                                </TabPanel>                                
                            }
                        </div>
                    </Paper>
                </div>
            </div>
           {(props.popup === 'reject') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SubmitReviewTimesheet}
                    Loading = {props.Loading}
                    ButtonName={props.dialogButton}
                    Title='Reject Timesheet'
                    Content={
                        <DialogReject
                            {...props}
                        />
                    }
                />
            }
            {(props.popup === 'unapprove-validation') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.OnClose}
                    ButtonName = 'OK'
                    Title='Timesheet UnApprove failed'
                    Content={
                        <DialogUnApproveValidation
                            Message = {props.validation.message}
                        />
                    }
                />
            }
            {(props.popup === 'un-approve') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SubmitReviewTimesheet}
                    ButtonName={props.dialogButton}
                    Loading = {props.Loading}
                    Title='Un-Approve Timesheet'
                    Content={
                        <DialogUnApprove
                            {...props}
                        />
                    }
                />
            }
            {(props.popup === 'approve-validation') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.OnClose}
                    ButtonName = 'OK'
                    Title='Timesheet Approve failed'
                    Content={
                        <DialogApproveValidation
                            Message = {props.validation.message}
                        />
                    }
                />
            }
            {(props.popup === 'approve') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SubmitReviewTimesheet}
                    ButtonName = 'Approve'
                    Disabled={props.buttonDisabled}
                    Loading = {props.Loading}
                    Title={(typeof props.formData !== 'undefined' && typeof props.formData.late_submission_review !== 'undefined' && props.formData.late_submission_review && typeof props.formData.supervisor_review !== 'undefined' && !props.formData.supervisor_review)? 'Approve Waiver' :'Approve Timesheet'}
                    Content={
                        <DialogApprove
                            {...props}
                        />
                    }
                />
            }
            {(props.popup === 'submit') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SubmitReviewTimesheet}
                    ButtonName = 'Submit Anyway'
                    Title='Reason for Submission'
                    Content={
                        <DialogSubmit
                            {...props}
                        />
                    }
                />
            }
            {(props.popup === 'update_waiver_status') &&
                <DialogBoxMedium
                    Open={props.dialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.UpdateWaiverStatus}
                    Loading = {props.Loading}
                    ButtonName={props.dialogButton}
                    Title='Update Waiver Status'
                    Content={
                        <DialogUpdateWaiverStatus
                            {...props}
                        />
                    }
                />
            }

              <CustomizedSnackbars
                open = {props.snackbarOpen}
                variant= 'success'
                message = {props.formData.message}
                handleClose = {props.SnackbarClose}
            />

        </div>
    );
    function handleScroll() {
        window.scrollTo(0, document.body.offsetHeight);
        const index = 'late_submission_history';
        setTabValue(index);
    }
}
const DialogUpdateWaiverStatus = (props) => {
    return (
        <div className={'waiver-alert full-width'} style={{'border':'1px solid lightgrey','border-radius' : '5px' }}>
            <Grid container spacing={1} className={'p-1rem'}>
                <Grid item xs={12} md={6} >
                    <FormControl>
                        <RadioGroup aria-label="name_option" name="waiver_status" value={(typeof props.WaiverDetails !== 'undefined' && props.WaiverDetails.waiver_status) ? props.WaiverDetails.waiver_status : null} onChange={props.HandleWaiverChange}>
                                { (typeof props.formData !== 'undefined' && typeof props.formData.waiver_statuses !== 'undefined') && props.formData.waiver_statuses.map((wstatus) =>
                            <FormControlLabel value={wstatus.id} control={<Radio />} label={wstatus.status_name} />
                            )}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box className={'mt-1'}>
                        <TextField
                            id="waiver_justification"
                            name="waiver_justification"
                            label="Justification"
                            required
                            type="text"
                            multiline={true}
                            variant="outlined"
                            rows={3}
                            fullWidth
                            value={(typeof props.WaiverDetails !== 'undefined' && props.WaiverDetails.waiver_justification) ? props.WaiverDetails.waiver_justification : ''}
                            onChange={props.HandleWaiverChange}
                            error={props.errors.justification_error && true}
                        />
                    </Box>
                </Grid>
            </Grid> 
        </div>
    )
}

