import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { StaffBasic } from './view/StaffBasic.Component.js';
import { PersonalDetails } from './view/personal';
import { ContactDetails } from './view/contact';
import { EmploymentDetails } from './view/employment';
import { ProjectDetails } from './view/project';
import { Compensation } from './view/compensation';
import { BenefitDetails } from './view/benefit';
import { TimeoffDetails } from './view/timeoff';
import { AppraisalDetails } from './view/appraisal';
import { FamilyDetails } from './view/family';
import { TimesheetDetails } from './view/timesheet';
import { ConfigDetails } from './view/config';
import { NoteDetails } from './view/note';
import { HistoryDetails } from './view/history';
import { SharepointFiles } from './view/files';
import { GetSelectedTab } from '../../redux/middleware/tabMiddleware';
import { PreviewComponent } from '../common/Preview'; 


import * as _R from 'ramda'

const StaffViewStyle = styled(React.Fragment)(({ theme }) => ({
    '& .paper': {
        color: theme.palette.text.secondary,
        minHeight:'calc(100vh - 100px)',
        boxShadow:'none'
    },
    '& .gridRoot':{
        minHeight:'calc(100vh - 100px)'  
    },
    '& .tab':{
        backgroundColor:'#eee',
        display:'block'
    },
    '& .bgWhiteLeft':{
        width:'100%',height:'100%',
        backgroundColor:'#fff',
        borderRadius:'4px',
        borderRight:'1px solid #eee',
        borderBottomRightRadius:0,
        padding: 6*2,
    },
    '& .bgWhiteContent':{
        backgroundColor:'#fff',
        padding: 8*3,
        display:'block',
        position:'relative'
    },
}));

export const StaffView = (props) => {
    const { anchorEl, staffDetails, staffBasicDetails, Permission } = props;
    const staff_id = props.staffID;
    const user_id =  props.userID;
    const selectedStaffTab =  GetSelectedTab("staff-view");
    return (
        <StaffViewStyle>
            <Paper className = {'paper'} style={{'width': '100%', 'flexGrow': '1' }}>
                <Grid container justify="space-between" alignItems="stretch" spacing={0} className={'gridRoot'}>
                    { typeof(staffDetails) != 'undefined'
                    ? <Grid  item xs={12} md={3}>
                            <Typography component="div" className={'bgWhiteLeft profile-view'} >
                                <StaffBasic anchorEl={anchorEl} staffDetails={staffBasicDetails} handlePhotoClick = {props.handlePhotoClick} fileOpen = {props.fileOpen} fileUrl = {props.fileUrl} filterView = {props.filterView}/>
                            </Typography>
                        </Grid>
                    :<></>}
                    <Grid  item xs={12} md={9}>
                        <Tabs 
                            value={selectedStaffTab} 
                            onChange={props.handleChange} 
                            variant="scrollable"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="Staff Tabs"
                            scrollButtons="auto"
                            className={'tab-main'}
                        >
                            <Tab className = {'tab'} label="Personal"/>
                            <Tab className = {'tab'} label="Contact"/>
                            <Tab className = {'tab'} label="Work"/>
                            <Tab className = {'tab'} label="Projects"/>
                            <Tab className = {'tab'} label="Compensation"/>
                            <Tab className = {'tab'} label="Benefits"/>
                            <Tab className = {'tab'} label="Timeoff"/>
                            <Tab className = {'tab'} label="Appraisal"/>
                            <Tab className = {'tab'} label="Family"/>
                            <Tab className = {'tab'} label="Timesheet"/>
                            <Tab className = {'tab'} label="Config"/>
                            <Tab className = {'tab'} label="Notes"/>
                            <Tab className = {'tab'} label="Files"/>
                            <Tab className = {'tab'} label="Change History"/>
                        </Tabs>
                        { typeof(staffDetails) != 'undefined'
                        ? <div className={`bgWhiteContent tabContainer`}>
                                {selectedStaffTab === 0 && <PersonalDetails staffDetails={staffBasicDetails}  updateChange={props.updateChange} />}
                                {selectedStaffTab === 1 && <ContactDetails staffDetails={staffDetails} StaffID={staff_id} UserID={user_id} />}
                                {(selectedStaffTab === 2 && _R.type(staffDetails) !== 'Undefined' && 'work' in staffDetails) &&
                                    <EmploymentDetails WorkData={_R.type(staffDetails) !== 'Undefined' && 'work' in staffDetails ? staffDetails.work : undefined} StaffID={staff_id} UserID={user_id} updateChange={props.updateChange} />
                                }



                                {/*props.value === 2 && <EmploymentDetails 
                                                        staffDetails={staffDetails} 
                                                        handleClickOpen={handleClickOpen} 
                                                        handleClose={handleClose} 
                                                        open={open}
                                                        spInputs={props.spInputs}
                                                        HandleOnChange={props.HandleOnChange}
                                                        spData={props.spData}
                                                        updateServicePeriod={props.updateServicePeriod}
                            /> */}
                                {selectedStaffTab === 3 && <ProjectDetails ProjectData={_R.type(staffDetails) !== 'Undefined' && 'project' in staffDetails ? staffDetails['project'] : undefined} StaffID={staff_id} UserID={user_id} />}
                                {(selectedStaffTab === 4 && _R.type(staffDetails) !== 'Undefined' && 'salary' in staffDetails) &&
                                    <Compensation StaffID={staff_id} UserID={user_id} DateJoined={_R.type(staffDetails) !== 'Undefined' ? staffDetails.date_joined : ''} CompensationData={_R.type(staffDetails) !== 'Undefined' && 'salary' in staffDetails ? staffDetails.salary : undefined} />
                                }
                                {
                                    selectedStaffTab === 5
                                    && _R.type(staffDetails) !== 'Undefined' && 'benefits' in staffDetails &&
                                    <BenefitDetails Benefits={_R.type(staffDetails) !== 'Undefined' && 'benefits' in staffDetails ? staffDetails.benefits : undefined} StaffID={staff_id} />
                                }
                                {(selectedStaffTab === 6 && _R.type(staffDetails) !== 'Undefined' && 'timeoff' in staffDetails) &&
                                    <TimeoffDetails StaffID={staff_id} UserID={user_id} TimeoffData={_R.type(staffDetails) !== 'Undefined' && 'timeoff' in staffDetails ? staffDetails.timeoff : undefined} />
                                }
                                {selectedStaffTab === 7 && _R.type(staffDetails) !== 'Undefined' && 'appraisal' in staffDetails &&
                                    <AppraisalDetails AppraisalData={_R.type(staffDetails) !== 'Undefined' && 'appraisal' in staffDetails ? staffDetails.appraisal : undefined} StaffID={staff_id} />
                                }
                                {(selectedStaffTab === 8 && _R.type(staffDetails) !== 'Undefined' && 'family' in staffDetails) &&
                                    <FamilyDetails FamilyData={_R.type(staffDetails) !== 'Undefined' && 'family' in staffDetails ? staffDetails.family : undefined} StaffID={staff_id} />
                                }
                                {selectedStaffTab === 9 && <TimesheetDetails TimesheetData={_R.type(staffDetails) !== 'Undefined' && 'timesheet' in staffDetails ? staffDetails.timesheet : undefined} StaffID={staff_id} UserID={user_id} Permission={props.Permission} />}
                                {(selectedStaffTab === 10 && _R.type(staffDetails) !== 'Undefined' && 'config' in staffDetails) &&
                                    <ConfigDetails StaffID={staff_id} UserID={user_id} ConfigData={_R.type(staffDetails) !== 'Undefined' && 'config' in staffDetails ? staffDetails.config : undefined} DateJoined={_R.type(staffBasicDetails) !== 'Undefined' ? staffBasicDetails.date_joined : null} RelievedDate={_R.type(staffBasicDetails) !== 'Undefined' ? staffBasicDetails.relieved_date : null} />
                                }
                                {selectedStaffTab === 11 && _R.type(staffDetails) !== 'Undefined' &&
                                    <NoteDetails NoteData={_R.type(staffDetails) !== 'Undefined' ? staffDetails.note : undefined} StaffID={staff_id} Permission ={Permission} />
                                }
                                {selectedStaffTab === 12 && _R.type(staffDetails) !== 'Undefined' && 'files' in staffDetails &&
                                    <SharepointFiles FilesData={_R.type(staffDetails) !== 'Undefined' && 'files' in staffDetails ? staffDetails.files : undefined} StaffID={staff_id} />
                                }
                                {selectedStaffTab === 13 && _R.type(staffDetails) !== 'Undefined' && 'history' in staffDetails &&
                                    <HistoryDetails HistoryData={_R.type(staffDetails) !== 'Undefined' && 'history' in staffDetails ? staffDetails.history : undefined} StaffID={staff_id} />
                                }
                        </div>
                        :<></>}
                    </Grid>
                </Grid>
            </Paper>
            {props.fileOpen &&
                <PreviewComponent
                    FileOpen={props.fileOpen}
                    FilterView = {props.filterView}
                    FileUrl={props.fileUrl}
                    Variant="persistent"
                    Anchor="right"
                    classes={'drawerheight'}
                />
            }
        </StaffViewStyle>
    );
};