import React from 'react';
import { AccountsComponent} from './Account.Component';
import { httpServices } from '../../../../services/httpServices';
import { CustomProgressBar } from '../../../common/CustomProgressBar';

export class AccountContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            title : 'Accounts',
            accounts  : [],
            formData : {},
            formInputs : [],
            open : false,
            errors : {},
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            showLoader : true,
            dataLoaded : false,
            buttonLoader : false,
            editPermission : false,
            openPassword : false,
            passwordPolicyText : '',
        }
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.handleClickReset = this.handleClickReset.bind(this);
        this.onResetSubmit = this.onResetSubmit.bind(this);
    }

    componentDidMount(){
        this.listAccounts(this.props.UserId);
    }
    
    listAccounts = (userId) => {
        this.setState({
            showLoader : true,
        });
        let apiEndpoint = 'api/user/list-accounts';
        let selectedEntity =this.state.selectedEntity;
        return httpServices.post(apiEndpoint,{user_id : userId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            accounts : response.data.accounts,
                            user_status : response.data.user_status,
                            showLoader : false,
                            permission : response.data.permission,
                            editPermission : response.data.edit_permission,
                            dataLoaded : true,
                            passwordPolicyText : response.data.password_policy_text,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onClose = () => {
        this.setState({
            open: false,
            openPassword : false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formData;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formData: ff
        });
    };
    handleClickOpen = (id) => {
        const { UserId } = this.props;
        let apiEndpoint = 'api/user/get-account';
        return httpServices.post(apiEndpoint, {'user_id':UserId, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            formInputs: response.data,
                            formData: response.data.accounts,
                            isDelete: false,
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            formInputs : response.data,
                            isEditable: false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formData;
        var val = evt.target.value;
        ff[field] = val;
        if(field==='account_provider'){
            let provider = this.state.formInputs.authProvider.find((item) =>item.id==val);
            ff['change_password'] = parseInt(provider.change_password); 
        }
        this.setState({
            formData: ff
        });
    };
    
    onSubmit = ()  => {
        if (this.handleValidation()) {
            this.setState({buttonLoader:true});
            const userID = this.props.UserId;
            const data = this.state.formData;
            const id = this.state.id;
            data.user_id = userID;
            let apiEndpoint = 'api/user/save-account';
            if (id) {
                data.id = id;
                data.action = 'update';
            }
            else {
                data.action = 'add';
            }
            if (data) {
                return httpServices.post(apiEndpoint, data)
                    .then(response => {
                        if (response.data.success) {
                            this.setState({
                                buttonLoader: false,
                                open: false,
                                snackbarOpen: true,
                                snackbarVarient: 'success',
                                snackbarMsg: response.data.success_message,
                            });
                            this.clearForm();
                            this.listAccounts(this.props.UserId);
                        } else if (response.data.errors) {
                            this.setState({
                                buttonLoader: false,
                                errors: response.data.errors,
                            });
                        }
			else if (response.data.failed_message) {
			    this.setState({
                                buttonLoader: false,
                                open: true,
                                snackbarOpen: true,
                                snackbarVarient: 'error',
                                snackbarMsg: response.data.failed_message,
                            });
			}
                        else {
                            let errors = {};
                            errors["new_pswd"] = this.state.passwordPolicyText;
                            this.setState({
                                buttonLoader: false,
                                errors: errors,
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let formData = this.state.formData;
        if (!formData.account_provider) {
            errors["account_provider"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!formData.account_username || formData.account_username.trim()=='') {
            errors["account_username"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!formData.account_status) {
            errors["account_status"] = "Cannot be empty";
            formIsValid = false;
        }
        if(!this.state.isEditable && formData.change_password && parseInt(formData.change_password) || (this.state.openPassword)){
           if (!formData.new_pswd) {
                errors["new_pswd"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!formData.confm_pswd) {
                errors['confm_pswd'] = "Cannot be empty";
                formIsValid = false;
            }
            if (formData.new_pswd !== formData.confm_pswd) {
                errors['confm_pswd'] = "Password Mismatch!";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    };

    onDeleteSubmit = () => {
        this.setState({buttonLoader:true});
        let apiEndpoint = 'api/user/delete-account';
        return httpServices.post(apiEndpoint, {
            'id': this.state.id,
        })
            .then(response => {
                this.setState({
                    open:false,
                    buttonLoader : false,
                    snackbarOpen : true,
                    snackbarVarient : 'success',
                    snackbarMsg : response.data.success_message,
                });
                this.listAccounts(this.props.UserId);
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onDelete = (id) => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    onChangePassword = () =>
    {
        this.setState({openPassword : true});
    }
    handleClickReset = (id) => {
        const { UserId } = this.props;
        let apiEndpoint = 'api/user/get-account';
        return httpServices.post(apiEndpoint, {'user_id': UserId, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            openPassword: true,
                            formInputs: response.data,
                            formData: response.data.accounts,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onResetSubmit = ()  => {
        if (this.handleValidation()) {
            this.setState({buttonLoader:true});
            const userID = this.props.UserId;
            const data = this.state.formData;
            const id = this.state.id;
            data.user_id = userID;
            let apiEndpoint = 'api/user/save-account';
            if (id) {
                data.id = id;
                data.action = 'reset';
            }
            if (data) {
                return httpServices.post(apiEndpoint, data )
                    .then(response => {
                        if (response.data.success) {
                            this.setState({
                                buttonLoader:false,
                                openPassword :false,
                                snackbarOpen : true,
                                snackbarVarient : 'success',
                                snackbarMsg : response.data.success_message,
                            });
                            this.clearForm();
                            this.listAccounts(this.props.UserId);
                        } else if (response.data.errors) {
                            this.setState({
                                buttonLoader: false,
                                errors: response.data.errors,
                            });
                        }
                        else
                        {   
                            let errors = {};
                            errors["new_pswd"] = this.state.passwordPolicyText;                           
                            this.setState({
                                buttonLoader:false,
                                errors: errors,                                
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    
    render(){
        return (
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                <AccountsComponent 
                    Accounts = {this.state.accounts}
                    UserStatus= {this.state.user_status}
                    HandleClickOpen = {this.handleClickOpen}
                    OnClose = {this.onClose}
                    FormData = {this.state.formData}
                    FormInputs = {this.state.formInputs}
                    Open = {this.state.open}
                    Errors = {this.state.errors}
                    HandleOnChange = {this.handleOnChange}
                    OnDelete = {this.onDelete}
                    isDelete = {this.state.isDelete}
                    isEditable = {this.state.isEditable}
                    onDeleteSubmit = {this.onDeleteSubmit}
                    onSubmit = {this.onSubmit}
                    SnackbarOpen={this.state.snackbarOpen}
                    SnackbarVarient={this.state.snackbarVarient}
                    SnackbarMsg={this.state.snackbarMsg}
                    SnackbarClose={this.snackbarClose}
                    ButtonLoader={this.state.buttonLoader}
                    EditPermission = {this.state.editPermission}
                    OnChangePassword = {this.onChangePassword}
                    OpenPassword = {this.state.openPassword}
                    HandleClickReset = {this.handleClickReset}
                    onResetSubmit = {this.onResetSubmit}
                />
            </>
        )
    }
}
