import React from 'react';
import {CustomMaterialTable, CustomMTableToolbar} from '../common/CustomMaterialTable';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';
import SaveAlt from '@mui/icons-material/SaveAlt';
import { formatName } from '../common/common';
import { AddProject } from './AddProject/AddProject.Container.js';
import { CustomizedSnackbars } from '../common/snackbars';
function sort (date1, date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
const API_URL = process.env.REACT_APP_API_URL;
const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});
const customButton = (props) => {
    return (<Button variant="contained" color="primary" size='small'>Add Project</Button>);
}
const SideList = withStyles(styles)(p => {
    return (<div role="presentation" className={p.classes.drawerList}>
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
	    <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl className={p.classes.drawerFormControl}  variant="standard">
            <InputLabel htmlFor="status">Project Status</InputLabel>
            <Select
                inputProps={{
                    name: 'status',
                    id: 'project_status',
                }}
                value = {!_R.isNil(p.FilterValues.status) && p.FilterValues.status }
                onChange = {p.HandleFilterChange}
            >
                <MenuItem value={0} key={'st-0'}>All</MenuItem>
                {_R.type(p.FilterInputs.project_status !== 'Undefined') && p.FilterInputs.project_status.map((s, i) =>
                    <MenuItem value={s.project_status_id} key={'st-'+i}>{s.project_status_name}</MenuItem>
                )}
            </Select>
	    </FormControl>
	    </Grid>
	    <Grid item xs={9}>
	    <FormControl className={p.classes.drawerFormControl}  variant="standard">
	    <InputLabel htmlFor="client">Client</InputLabel>
	    <Select
                inputProps={{
                    name: 'client',
                    id: 'client',
                }}
                value = {!_R.isNil(p.FilterValues.client) && p.FilterValues.client}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value={0} key={'st-0'}>All</MenuItem>
	    {_R.type(p.FilterInputs.client_list !== 'Undefined') && p.FilterInputs.client_list.map((s, i) =>
                    <MenuItem value={s.client_id} key={'st-'+i}>{s.client_name}</MenuItem>
            )}
            </Select>
            </FormControl>
	    </Grid>
	    </Grid>
	    <Grid container spacing={2}>
	    <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
	    <Button variant="outlined"  color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
	    </Grid>
    </div>
    );
});

export const ProjectsComponent = withStyles(styles)(props => {
    const { classes, toggleDrawer } = props;
    const columns = [
        { title: 'Project Name', field: 'project_name', removable: false,
            cellStyle :(rowDataArray,rowData) =>{
                let paddingLeft=15 * (parseInt(rowData.nlevel));
                return({paddingLeft:paddingLeft});
            }
        },
        { title: 'Charge Code', field: 'project_code', removable: false, },
        { title: 'Project Manager', field: 'project_manager', removable: false, render: rowData => ( formatName(rowData.manager_first_name, null, rowData.manager_last_name, props.NameFormat))},
        { title: 'Start Date', field: 'project_sdate', removable: false, customSort:(a, b) => sort (new Date(a.project_sdate), new Date(b.project_sdate))},
        { title: 'End Date', field: 'project_edate', removable: false, customSort:(a, b) => sort(new Date(a.project_edate), new Date(b.project_edate)), render: rowData => ( (rowData.project_edate !== null) ? rowData.project_edate :'Never') },
        { title: 'Status', field: 'project_status_name', removable: false, },
        { title: 'Last Modified', field: 'project_mdate', removable: false, customSort:(a, b) => sort(new Date(a.project_mdate), new Date(b.project_mdate))},
    ];
    return (
        <React.Fragment>
            <CustomMaterialTable
                title= "Projects"
                data = {props.Projects}
                columns={columns}
                actions={[
                    {
                        icon: customButton,
                        tooltip: 'Add Project',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.AddProjectClick() },
                        hidden: (props.EditPermission) ? false : true, 
                    },
                    {
                        icon: SaveAlt,
                        tooltip: 'Export',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.ExportProject() },
                    },
                    rowData => ({
                        icon: Edit,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => { props.AddProjectClick(rowData.project_id)},
                        hidden : (props.EditPermission || (props.SelfEditPermission && (props.LoggedUser === rowData.project_manager || (rowData.reviewers && rowData.reviewers !== "" && rowData.reviewers.split(",").indexOf(props.LoggedUser) !== -1)))) ? false : true, 
                    }),
                    {icon: DeleteOutline, tooltip: 'Delete',hidden : props.DeletePermission ? false:true },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: true,
                }}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                components={{
                    Toolbar: props => (
                        <div style={{ position: 'relative', paddingRight:'50px' }}>
                                <CustomMTableToolbar {...props} />
                            <Tooltip title="Filter" aria-label="Filter">
                                <IconButton onClick={toggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                            </Tooltip>
                        </div>
                        )
                    }}
                onRowClick={(event, rowData) => props.HandleViewDetails(rowData.project_id)}
            />
            <Drawer
                anchor="right"
                open={props.Right}
                onOpen={props.toggleDrawer('right', true)}
                onClose={props.toggleDrawer('right', false)}>
                <SideList
                    Right={'right'}
                    FilterValues = {props.FilterValues}
                    FilterInputs={props.FilterInputs}
                    HandleFilterChange={props.HandleFilterChange}
                    SubmitFilter={props.SubmitFilter}
                    ResetFilter={props.ResetFilter}
                />
            </Drawer>
            {/*Form for submitting filter values for export project list*/}
            <form action={API_URL+'api/project'} method={"post"} id={"exportProject"}>
                <input type="hidden" name="action" value={"export"} />
                <input type="hidden" name="status" value={props.FilterValues.status} />
                <input type="hidden" name="client" value={props.FilterValues.client} />
            </form>
            {(props.PopupType === 'add_project' && props.DialogOpen) ?
            <AddProject
                Open = {props.DialogOpen}
                UpdateParentOnAddSuccess = {props.UpdateParentOnAddSuccess}
                //DisplaySuccess = {props.DisplaySuccess}
                EditId = {props.EditId}
                Title = {props.EditId ? "Edit Project" : "Add Project"}
            />
            :<></>
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>
    );
});
