import React from 'react';
import { httpServices } from '../../../../services/httpServices';
import { ServicePeriodComponent } from './ServicePeriod.Component';
import * as _R from 'ramda';


export class ServicePeriodContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            permission : false,
            servicePeriods:[],
            open: false,
            isEditable: false,
            isDelete: false,
            formFields: {},
            sp_inputs: [],
            errors: {},
            errorMessage: false,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            showLoader : true,
            dataLoaded : false,
            buttonLoader : false,
            editPermission : false,
            totalExperience :'',
        }
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount(){
        this.listServicePeriods(this.props.UserId);
    }
    listServicePeriods = (UserId) => {
        let apiEndpoint = 'api/user/list-service-periods';
        return httpServices.post(apiEndpoint,{user_id : UserId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            servicePeriods : response.data.service_periods,
                            totalExperience : response.data.total_experience,
                            showLoader : false,
                            dataLoaded : true,
                            permission : response.data.permission,
                            editPermission : response.data.edit_permission
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleClickOpen = (id) => {
        const { UserId } = this.props;
        let apiEndpoint = 'api/user/get-service-period';
        return httpServices.post(apiEndpoint, {'user_id':UserId, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            sp_inputs: response.data,
                            formFields: response.data.service_period,
                            isDelete: false,
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            sp_inputs: response.data,
                            isEditable: false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        var val = evt.target.value;
        if (field === 'include_holidays') {
            val = (val === 'Yes') ? 'No' : 'Yes';
        }
        ff[field] = val;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };

    onSubmit = ()  => {
        if (this.handleValidation()) {
            this.setState({ buttonLoader : true });
            const userID = this.props.UserId;
            const data = this.state.formFields;
            const id = this.state.id;
            data.user_id = userID;
            let apiEndpoint = '';
            if (id) {
                data.id = id;
                apiEndpoint = 'api/user/edit-service-period';
            }
            else {
                apiEndpoint = 'api/user/add-service-period';
            }
            if (data) {
                return httpServices.post(apiEndpoint, {'data' : data})
                    .then(response => {
                        if (response.data.valid) {
                            this.setState({
                                buttonLoader : false,
                                open:false,
                                snackbarOpen : true,
                                snackbarVarient : 'success',
                                snackbarMsg : response.data.msg,
                            });
                            this.clearForm();
                            this.listServicePeriods(this.props.UserId);
                        }
                        else {
                            let input_errors = response.data.input_errors;
                            if (Object.keys(input_errors).length) {
                                this.setState({
                                    errors:response.data.input_errors,
                                    buttonLoader : false,
                                });
                           }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                    buttonLoader : false,
                                });
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let daily_minimum = this.state.formFields.daily_minimum;
        let weekly_limit = this.state.formFields.weekly_limit;

        if (!this.state.formFields.staff_type) {
            errors["staff_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (daily_minimum) {
            let reg = /^[0-9]+$/;
            let ok = reg.exec(daily_minimum);
            if (!ok) {
                errors["daily_minimum"] = "Daily Minimum isn't valid";
                formIsValid = false;
            }
        }
        if (weekly_limit) {
            let reg = /^[0-9]+$/;
            let ok = reg.exec(weekly_limit);
            if (!ok) {
                errors["weekly_limit"] = "Weekly Limit isn't valid";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onDelete = (id) => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const {UserId} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/user/delete-service-period';
        return httpServices.post(apiEndpoint, {
            'user_id': UserId,
            'id': id,
        })
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        open:false,
                        snackbarOpen : true,
                        snackbarVarient : 'success',
                        snackbarMsg : response.data.msg,
                    },()=>{ this.listServicePeriods(this.props.UserId);});
                }
                else {
                    this.setState({
                        open:false,
                        snackbarOpen : true,
                        snackbarVarient : 'error',
                        snackbarMsg : response.data.msg,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };


    render(){
        return(
                <ServicePeriodComponent
                    ServicePeriods={this.state.servicePeriods}
                    HandleClickOpen = {this.handleClickOpen}
                    Open = {this.state.open}
                    isEditable = {this.state.isEditable}
                    isDelete = {this.state.isDelete}
                    formFields = {this.state.formFields}
                    sp_inputs = {this.state.sp_inputs}
                    errors = {this.state.errors}
                    errorMessage = {this.state.errorMessage}
                    OnClose = {this.onClose}
                    HandleOnChange = {this.HandleOnChange}
                    OnSubmit = {this.onSubmit}
                    OnDelete = {this.onDelete}
                    OnDeleteSubmit = {this.onDeleteSubmit}
                    SnackbarOpen={this.state.snackbarOpen}
                    SnackbarVarient={this.state.snackbarVarient}
                    SnackbarMsg={this.state.snackbarMsg}
                    SnackbarClose={this.snackbarClose}
                    EditPermission = {this.state.editPermission}
                    ButtonLoader = {this.state.buttonLoader}
                    TotalExperience = {this.state.totalExperience}
                />
        )
    }
}

