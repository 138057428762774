import React from 'react';
import {httpServices}  from '../../../services/httpServices';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { TimeSheetDetails } from './TimeSheetDetails';
import { ErrorPage } from '../../common/error';

export class TimeSheetDetailsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            formData : {},
            hasPermission : false,
            openDetails : false,
            id:0,
            viewType: '',
            timesliceData:[],
            dialogOpen : false,
            comments:[],
            history:[],
            loader: true,
            dataLoaded :false
        }
        this.onDetailsClick = this.onDetailsClick.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(){
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.getTimesheetDetails(id);
    }

    getTimesheetDetails = (id) => {
        return httpServices.post('api/report/getTimesheet', {id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            formData : response.data,
                            viewType : response.data.view_type,
                            hasPermission : response.data.permission,
                            openDetails : true,
                            loader : false,
                            dataLoaded : true
                        });
                    }
                    else {
                        this.setState({
                            loader : false,
                            dataLoaded : true
                        });
                    }
               }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onDetailsClick=(id)=> {
       this.setState({openDetails:true});
         return httpServices.post('api/report/getTimesliceDetails', {id : id})
            .then(response => {
                if (response.data) {
                        this.setState({
                            timesliceData : response.data,
                            dialogOpen : true,
                            comments :response.comments,
                            history : response.history,
                            loader : false,
                            });
               }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };

     onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    }

    render(){
        return(
            <>
            {(this.state.loader ) &&
                <CustomProgressBar
                   loaderOpen={this.state.loader}
                />
            }
            {this.state.dataLoaded ?
                <>
                {this.state.hasPermission ?
                    <TimeSheetDetails
                        FormData = {this.state.formData}
                        TimesheetId = {this.state.id}
                        ViewType = {this.state.viewType}
                        OnDetailsClick = {this.onDetailsClick}
                        TimesliceData = {this.state.timesliceData}
                        DialogOpen = {this.state.dialogOpen}
                        OnClose = {this.onClose}
                        Comments = {this.state.comments}
                        History = {this.state.history}
                    />
                    : <ErrorPage />
                }
                </>
                : <></>
            }
            </>
        )
    }
}
