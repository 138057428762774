import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import * as _R from 'ramda';
import FormHelperText from '@mui/material/FormHelperText';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import { formatName } from '../../../common/common';
import { CustomDatePicker } from '../../../common/CustomDatePicker';
import moment from 'moment';

const DialogContent = (p) =>{
    const {FormProps:props} = p;
    const {FormFields, FormInputs, HandleOnChange, HandleDateChange} = props;
    return (
        <form className="form-fullwidth" onSubmit={props.HandleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="component" required  error={props.Errors.component && true}>Component</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && FormFields.component}
                            onChange={props.HandleComponentOnChange}
                            inputProps={{
                                name: 'component',
                                id: 'component',
                                readOnly: FormFields.disabled,
                            }}>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.base_components) !== 'Undefined' && FormInputs.base_components.map((b, i) =>
                                <MenuItem value={b.id} key={'component-'+i}>{b.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.component}</FormHelperText>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "start_date"
                            name = "start_date"
                            label = "Start Date"
                            required = {true}
                            className = {'mt-0'}
                            selectedDate = {FormFields.start_date}
                            handleDateChange = {HandleDateChange}
                            error = {props.Errors.start_date}
                            minDate = {(typeof props.DateJoined !== 'undefined') ?  props.DateJoined : null}
                            maxDate = {(FormFields.end_date) ? FormFields.end_date :  (props.RelievedDate ? props.RelievedDate : null) }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "end_date"
                            name = "end_date"
                            label = "End Date"
                            className = {'mt-0'}
                            selectedDate = {FormFields.end_date}
                            handleDateChange = {HandleDateChange}
                            error = {props.Errors.end_date}
                            minDate = { (typeof FormFields.start_date !== 'undefined') ? FormFields.start_date : null}
                            maxDate = { (typeof props.RelievedDate !== 'undefined') ? props.RelievedDate : null}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="unit" error={props.Errors.unit && true}>Unit</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.unit)}
                            onChange={HandleOnChange}
                            required
                            inputProps={{
                                name: 'unit',
                                id: 'unit',
                            }}>
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.units) !== 'Undefined' && FormInputs.units.map((t, i) =>
                                <MenuItem value={t.id} key={'unit-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.unit}</FormHelperText>
                    </ FormControl>
                </Grid>
                 <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="amount"
                            name="amount"
                            label="Amount"
                            type="text"
                            fullWidth
                            value={FormFields.amount}
                            onChange={HandleOnChange}
                            error={props.Errors.amount && true}
                            helperText={props.Errors.amount}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="payroll_count"
                            name="payroll_count"
                            label="Payroll Count"
                            type="text"
                            fullWidth
                            value={FormFields.payroll_count}
                            onChange={HandleOnChange}
                            InputLabelProps={{ shrink: true }}
                            error={props.Errors.payroll_count && true}
                            helperText={props.Errors.payroll_count}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="based_on" error={props.Errors.based_on && true}>Based On</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.based_on)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'based_on',
                                id: 'based_on',
                            }}>
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.base_components) !== 'Undefined' && FormInputs.base_components.map((b, i) =>
                                <MenuItem value={b.id} key={'based-on-'+i}>{b.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.based_on}</FormHelperText>
                    </ FormControl>
                </Grid>
                {_R.type(FormFields.based_on) !== 'Null' &&
                <>
                <Grid item xs={12} md={6}>
                    <FormControl>
                       <TextField variant="standard"
                            id="lower_limit"
                            name="lower_limit"
                            label="Lower Limit"
                            type="text"
                            fullWidth
                            value={FormFields.lower_limit}
                            onChange={HandleOnChange}
                            error={props.Errors.lower_limit && true}
                            helperText={props.Errors.lower_limit}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="upper_limit"
                            name="upper_limit"
                            label="Upper Limit"
                            type="text"
                            fullWidth
                            value={FormFields.upper_limit}
                            onChange={HandleOnChange}
                            error={props.Errors.upper_limit && true}
                            helperText={props.Errors.upper_limit}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ FormControl>
                </Grid>
                </> }
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="pay_recurrence" error={props.Errors.pay_recurrence && true}>Pay Recurrence</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.pay_recurrence)}
                            onChange={HandleOnChange}
                            required
                            inputProps={{
                                name: 'pay_recurrence',
                                id: 'pay_recurrence',
                            }}>
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.frequency) !== 'Undefined' && FormInputs.frequency.map((t, i) =>
                                <MenuItem value={t.id} key={'pay_recurrence-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                         <FormHelperText className='error'>{props.Errors.pay_recurrence}</FormHelperText>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="frequency" error={props.Errors.pay_frequency  && true} >Pay Frequency</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.pay_frequency)}
                            onChange={HandleOnChange}
                            error={props.Errors.pay_frequency && true}
                            helperText={props.Errors.pay_frequency}
                            required
                            inputProps={{
                                name: 'pay_frequency',
                                id: 'pay_frequency',
                            }}>
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.frequency) !== 'Undefined' && FormInputs.frequency.map((t, i) =>
                                <MenuItem value={t.id} key={'pay_frequency-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                         <FormHelperText className='error'>{props.Errors.pay_frequency}</FormHelperText>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="cap_unit" error={props.Errors.cap_unit && true} >Cap Unit</InputLabel>
                        <Select
                            value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.cap_unit)}
                            onChange={HandleOnChange}
                            required
                            inputProps={{
                                name: 'cap_unit',
                                id: 'cap_unit',
                            }}>
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.units) !== 'Undefined' && FormInputs.units.map((t, i) =>
                                <MenuItem value={t.id} key={'unit-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.cap_unit}</FormHelperText>
                    </ FormControl>
                </Grid>
                 <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="cap_figure"
                            name="cap_figure"
                            label="Cap Figure"
                            type="text"
                            fullWidth
                            value={FormFields.cap_figure}
                            onChange={HandleOnChange}
                            error={props.Errors.cap_figure && true}
                            helperText={props.Errors.cap_figure}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ FormControl>
                </Grid>
	         <Grid item xs={12} md={15}>
                    <FormControl>
                        <TextField variant="standard"
                            id="description"
                            name="description"
                            label="Notes"
                            type="text"
                            fullWidth
                            value={FormFields.description}
                            onChange={HandleOnChange}
                            error={props.Errors.note && true}
                            helperText={props.Errors.note}
                            InputLabelProps={{ shrink: true }}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            onChange={props.HandleCheckBoxChange}
                            value={"true"}
                            //checked={true}
                            color="primary"
                        />
                        }
                        label="Do you want to update the component in active compensation"
                    />
                </Grid>
                {props.PreferredAmountFlag &&
                <>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="preferred_amount"
                            name="preferred_amount"
                            label="Preferred Amount"
                            type="text"
                            fullWidth
                            value={props.PreferredAmount}
                            onChange={HandleOnChange}
                            InputLabelProps={{ shrink: true }}
                            error={props.Errors.preferred_amount && true}
                            helperText={props.Errors.preferred_amount}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Tooltip title="Reload Preferred Amount" aria-label="refresh">
                        <Button color="primary" size="small" tabIndex="-1" onClick={props.GetPreferredAmount}>
                            <CachedIcon fontSize="inherit" />&nbsp;Reload Preferred Amount
                        </Button>
                    </Tooltip>
                </Grid>
                </>
                }
            </Grid>
        </form>
    )
}

export const ConfigComponent = (props) => {
    const { Components:dataArr } = props;
    let table_size = 10; // Default
    if (dataArr) {
        if (Array.isArray(dataArr)) {
            let data_arr_length = dataArr.length ;
            if (data_arr_length < 10) {
                table_size = data_arr_length < 3 ? 2 : data_arr_length;
            }
        }
    }
    
    return (
        <React.Fragment>
	     <div className={'mtable-basic'}>
            <CustomMaterialTable
                title="Compensation Config"
                columns={[
                    { title: 'Title', field: 'title' },
                ]}
                data={dataArr}
                options={{
                    actionsColumnIndex: -1,
                    toolbar:true,
                    pageSize:table_size,
                    pageSizeOptions: [10,20,30],
                    search: false,
                    columnsButton:true,
                }}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add Component',
                        isFreeAction: true,
                        onClick: (event) => { props.HandleAdd(false) },
                        hidden: (props.Edit) ? false : true,
                    },
                    {
                        icon: 'add',
                        tooltip: 'Add Component',
                        onClick: (event,rowData) => { props.HandleAdd(rowData.id) },
                        hidden: (props.Edit) ? false : true,
                    },
                ]}

                detailPanel={[
                    {
                        render: ({ rowData }) => {
                            if (rowData.childs.length) {
                                return (
                                    <div class="staff_tabel-detail-panel">
                                        <table cellspacing="0">
                                            {rowData.childs.map((r, i) =>
                                                <tr>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Start Date:</label><span class="value">{r.start_date}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>End Date:</label><span class="value">{r.end_date}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Based On:</label><span class="value">{r.based_on}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Lower Limit:</label><span class="value">{r.lower_limit}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Upper Limit:</label><span class="value">{r.upper_limit}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Unit:</label><span class="value">{r.unit}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Amount:</label>
                                                        <span class="value">{(!_R.isNil(r.unit) && _R.toLower(r.unit) === 'fixed') ? r.figure : ((!_R.isNil(r.unit) && !_R.empty(r.unit)) ? r.figure + '%' : r.figure)}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Cap Unit:</label><span class="value">{r.cap_unit_title}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Cap Amount:</label>
                                                        <span class="value">{(!_R.isNil(r.cap_unit_title) && _R.toLower(r.cap_unit_title) === 'fixed') ? r.cap_figure : ((!_R.isNil(r.cap_unit_title) && !_R.empty(r.cap_unit_title)) ? r.cap_figure + '%' : r.cap_figure)}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Notes:</label><span class="value">{r.description}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Created By :</label><span class="value">{formatName(r.conf_first_name, null, r.conf_last_name)}</span>
                                                    </td>
                                                    <td class="staff_tabel-detail-item">
                                                        <label>Created On :</label><span class="value">{r.config_created_on}</span>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <span class="value">
                                                            <IconButton aria-label="edit">
                                                                <EditIcon onClick={() => props.HandleUpdate(r.config_id)} />
                                                            </IconButton>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="value">
                                                            <IconButton aria-label="delete">
                                                                <DeleteIcon onClick={() => props.HandleDelete(r.config_id, 0, r.id, r.start_date)} />
                                                            </IconButton>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                    </div>
                                )
                            }
                        }
                    }
                ]}
            />
	 </div>    
        <DialogBoxMedium
            Open={props.Open? props.Open:props.message?props.message:false}
            Cancel={props.message ? props.OnCloseDelete:props.HandleClose}
            ButtonName={props.message?"Ok":"Save"}
            Title={props.message?'Delete Component':props.IsEditable?'Edit Component':'Add Component'}
            OnSubmit={props.message?props.ConfirmDelete:props.HandleSubmit}
            Content={props.Open?<DialogContent FormProps={props}/>:props.message? "Do you want to delete the config? " :""}
        />
        </React.Fragment>
    )
};
