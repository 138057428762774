import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ErrorPage } from '../../common/error';
import { GetSelectedTab } from '../../../redux/middleware/tabMiddleware';
import { OvertimeFilterComponent } from './OvertimeFilterComponent';
import { MyRequestOvertime } from './MyRequestOvertime.Component';
import { AddOvertime } from './OvertimeAdd.Container';
import { CustomizedSnackbars } from '../../common/snackbars';
import format from 'date-fns/format';
import {GetConfig} from '../../../redux/middleware/configMiddleware';


export const OvertimeComponent = (props) => {
    const selectedOvertimeTab =  GetSelectedTab("overtime");
    let myTabIndex = (props.RequestView) ? 0 : null;
    let reviewTabIndex = (props.RequestView) ? 1 : null;
    let allTabIndex = null;
    if (props.RequestView && props.AllRequestView) {
        allTabIndex = 2;
    } else if(props.AllRequestView) {
        allTabIndex = 0;
    }
    let tabIndex = (selectedOvertimeTab === 'my' && props.RequestView) ? myTabIndex : (selectedOvertimeTab === 'review' && props.RequestView) ? reviewTabIndex : allTabIndex;

    const nameFormat =  GetConfig('name_format');
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }

    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Overtime Requests
                        </Typography>
                    </div>
                    <div className={'st-timeEntry_payperiod-title center'}>
                        <span className={'label'}> Period</span>
                        {format(props.PeriodStartDate, 'MM/dd/yy')} - {format(props.PeriodEndDate, 'MM/dd/yy')}
                    </div>
                    <div className={'action'}>
                        {(props.UserType === true && props.ListViewPermission === true) && 
                            <Button variant="contained" color="primary" size="small" onClick={props.HandleOnClick}>New Request</Button>
                        }
                            <Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                    >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Filter</div>
                                    <OvertimeFilterComponent 
                                                FormFields = {props.FormFields}
                                                NameFormat = {nameFormat}
                                                EmployeeList = {props.EmployeeList}
                                                SaveFilter = {props.SaveFilter}
                                                EmployeeStatus = {props.EmployeeStatus}
                                                RequestStatus = {props.RequestStatus}
                                                handleOnSubmit = {props.FilterSubmit} 
                                                handleOnChange = {props.HandleOnChange} 
                                                handleStartDateChange={props.handleStartDateChange}
                                                handleEndDateChange={props.handleEndDateChange}
                                                tab = {parseInt(tabIndex) === 0 ? (props.RequestView ? 'my' : 'all' ): parseInt(tabIndex) === 1 ? 'review' : 'all'}
                                                ResetFilter = {props.ResetFilter}
                                                errors = {props.errors}
                                                />
                                </div>
                            </Drawer>
                        </div>
                </div>
                <div className={'section-body'}>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabIndex}
                                onChange={props.HandleChange}
                                indicatorColor="primary"
                                aria-label="Overtime requests tabs">
                                {props.RequestView ? <Tab label="My Requests" onClick={()=>props.GetIndex('my')}/> : <></> }
                                {props.RequestView ? <Tab label={parseInt(props.ReviewRequestsCount) !== 0 ? <Badge color="secondary"  badgeContent={props.ReviewRequestsCount} >Review </Badge> : "Review"} onClick={()=>props.GetIndex('review')}/> : <></> }
                                {props.AllRequestView ? <Tab label="All Requests" onClick={()=>props.GetIndex('all')}/> : <></>}
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabIndex} index={myTabIndex}>
                            {(props.UserType === false && props.TabName === 'my') &&
                                 <ErrorPage Message ={props.NotEligibleMessage} />
                            }
                            {(props.ListViewPermission && props.RequestView) ?
                                <MyRequestOvertime
                                    Requests = {props.Requests}   
                                    HandleEditClick = {props.HandleEditClick}
                                    HandleViewClick = {props.HandleViewClick}
                                    Tab = {"my"}
                                    Logged_user = {props.Logged_user}
                                />
                                :
                                <></>}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={reviewTabIndex}>
                            {(props.ListViewPermission && props.RequestView) ?
                                <MyRequestOvertime
                                    Requests = {props.Requests}
                                    Tab = {'review'}
                                    HandleViewClick = {props.HandleViewClick}
                                    Logged_user = {props.Logged_user}
                                />
                                 :
                                <></>}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={allTabIndex}>
                            {(props.ListViewPermission && props.AllRequestView) ?
                                <MyRequestOvertime
                                    Requests = {props.Requests}
                                    HandleViewClick = {props.HandleViewClick}
                                    Tab = {'all'}
                                    HandleEditClick = {props.HandleEditClick}
                                    Logged_user = {props.Logged_user}
                                />
                                :
                                <></>}
                            </TabPanel>
                        </div>
                    </Paper>
                </div>
            </div>
            {props.Open ? 
            <AddOvertime
                Open = {props.Open}
                EditId = {props.EditId}
                Recipients = {props.Recipients}
                Approvers = {props.Approvers}
                Custom_recipients = {props.Custom_recipients}
                UpdateParent = { props.UpdateParent}
                UpdateParentOnAddSuccess = {props.UpdateParentOnAddSuccess}
                Doj = {props.Doj}
                RelievedDate={props.RelievedDate}
            />
                :<></>}
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>    
    )
};
