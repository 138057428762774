import React from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { formatName } from '../../../common/common';
import { CustomDatePicker } from '../../../common/CustomDatePicker';


const DialogAddContent = (props) => {
    const { FormFields, FormInputs, HandleOnChange, HandleDateChange } = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="reviewer_id" error={props.Errors.reviewer_id && true} required>Supervisor</InputLabel>
                            <Select
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.reviewer_id}
                                    onChange={HandleOnChange}
                                    required
                                    error={props.Errors.reviewer_id && true}
                                    inputProps={{
                                        name: 'reviewer_id',
                                        id: 'reviewer_id',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.users) !== 'Undefined' && FormInputs.users.map((t, i) =>
                                        <MenuItem value={t.user_id} key={'reviewer-'+i}>{formatName(t.first_name,null,t.last_name)}</MenuItem>
                                    )}
                                </Select>
                                <small className='error'>{props.Errors.reviewer_id && props.Errors.reviewer_id}</small>
                        </ FormControl>
                    </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker variant="standard"
                            id="start_date"
                            name="start_date"
                            selectedDate={FormFields.start_date}
                            handleDateChange={HandleDateChange}
                            minDate={props.DOJ}
                            shouldDisableDate={(date) => {
                                if (moment(date).isSame(props.DOJ,'date')){
                                    return false;
                                }
                                let day = date.format('D');
                                if (props.Payperiod==='semi-monthly') {
                                    if (day === '1' || day === '16'){
                                        return false;
                                    }
                                } else {
                                    if (day === '1') {
                                        return false;
                                    }
                                }
                                return true;
                            }}
                            label={'Start Date'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker variant="standard"
                            id="end_date"
                            name="end_date"
                            selectedDate={FormFields.end_date ? FormFields.end_date : null}
                            error={(typeof props.Errors.end_date !== 'undefined') ? props.Errors.end_date : ''}
                            handleDateChange={HandleDateChange}
                            label={'End Date'}
	    		    clearable = {true}
                        />
                    </FormControl>
                </Grid>
                </Grid>
            </form>
    )
}
const DialogEditContent = (props) => {
    const { FormFields, HandleOnChange, HandleDateChange } = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                disabled
                                required
                                id="supervisor"
                                name="supervisor"
                                label="Supervisor"
                                type="text"
                                fullWidth
                                value={formatName(FormFields.rby_first_name,null,FormFields.rby_last_name)}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="priority"
                                name="priority"
                                label="Priority"
                                type="number"
                                fullWidth
                                onChange={HandleOnChange}
                                value={FormFields.priority}
                                error={props.Errors.priority && true}
                            />
                            <small className='error'>{props.Errors.priority && props.Errors.priority}</small> 
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <CustomDatePicker
                                id="start_date"
                                name="start_date"
                                selectedDate={FormFields.start_date}
                                handleDateChange={HandleDateChange}
                                minDate={props.DOJ}
                                shouldDisableDate={(date) => {
                                    if (moment(date).isSame(props.DOJ,'date')){
                                        return false;
                                    }
                                    let day = date.format('D');
                                    if (props.Payperiod==='semi-monthly') {
                                        if (day === '1' || day === '16'){
                                            return false;
                                        }
                                    } else {
                                        if (day === '1') {
                                            return false;
                                        }
                                    }
                                    return true;
                                }}
                                label={'Start Date'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <CustomDatePicker
                                id="end_date"
                                name="end_date"
                                selectedDate={FormFields.end_date ? FormFields.end_date : null}
                                handleDateChange={HandleDateChange}
                                error={(typeof props.Errors.end_date !== 'undefined') ? props.Errors.end_date : ''}
                                label={'End Date'}
	    			clearable = {true}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

const formFields = {
    'reviewer_id': null,
    'reviewer_name': null,
    'priority': null,
    'start_date': null,
    'end_date': null 
};

export class SupervisorList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dialog_open: false,
            IsEditable: false,
            IsDelete: false,
            formFields: formFields,
            formInputs: [],
            supervisors: [],
            errors: {},
            errorMessage: false,
        };
        this.addSupervisor= this.addSupervisor.bind(this);
        this.editSupervisor = this.editSupervisor.bind(this);
        this.deleteSupervisor = this.deleteSupervisor.bind(this);
        this.onClose = this.onClose.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.updateSupervisor = this.updateSupervisor.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount = () => {
        const {Supervisors} = this.props;
        this.setState({supervisors: Supervisors});
    };
    addSupervisor = (action, id = 0) => () =>  {
        const {UserID} = this.props;
        let editable = (id) ? true : false;
        let apiEndpoint = 'api/staff/work/supervisors';
        return httpServices.post(apiEndpoint, {
                        'id':id,
                        'userID':UserID,
                        'action' : action
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        dialog_open:true,
                        formFields: {},
                        formInputs: response.data,
                        IsEditable: editable,
                        IsDelete: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    editSupervisor = (id) => () => {
        const {UserID} = this.props;
        let apiEndpoint = 'api/staff/work/get-supervisor';
        return httpServices.post(apiEndpoint, {
                        'id':id,
                        'user_id':UserID,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        dialog_open:true,
                        formFields: response.data.reviewer,
                        //formInputs: response.data,
                        IsEditable: true,
                        IsDelete: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    deleteSupervisor = (id) => () => {
        let data = this.state.formFields;
        data.id = id;
        this.setState({
            dialog_open:true,
            IsDelete: true,
            formFields: data,
        });
    }
    onClose = () =>{
        this.setState({
            dialog_open: false
        });
        this.clearForm();
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    clearForm = () => {
        const ff = this.state.formFields;
        if (_R.length(ff) > 0) {
            for (let key in ff) {
                ff[key] = null;
            }
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    updateSupervisor = (action) => () =>  {
        window.event.preventDefault();
        const {UserID, StaffID} = this.props;
        const data = this.state.formFields;
        data.user_id = UserID;
        data.staff_id = StaffID;
        data.action = action;
        if (data) {
            if (this.handleValidation(action)) {
                let apiEndpoint = 'api/staff/work/update-supervisor';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.input_errors) {
                            if (!response.data.error) {
                                this.setState({
                                    dialog_open:false,
                                    supervisors: response.data.supervisors,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                        else {
                            this.setState({
                                errors: response.data.input_errors,
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    handleValidation(action) {
        let formIsValid = true;
        let errors = {};
        if (action === 'add') {
            if (!this.state.formFields.reviewer_id) {
                errors["reviewer_id"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        if (action === 'edit') {
            let priority = this.state.formFields.priority;
            if (!priority) {
                errors["priority"] = "Cannot be empty";
                formIsValid = false;
            }
            else {
                let reg = /^[0-9]+$/;
                let ok = reg.exec(priority);
                if (!ok) {
                    errors["priority"] = "Priority isn't valid";
                    formIsValid = false;
                }
            }
        }
        let start_date = this.state.formFields.start_date;
        let end_date = this.state.formFields.end_date;
        if (start_date && end_date && end_date < start_date ) {
            errors["end_date"] = "End date should be greater than start date.";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };

    handleDateChange = (value, name) => {
        const form_field = this.state.formFields;
        form_field[name] = value;
        this.setState({
            formFields: form_field
        });
    };

    render(){
        const { supervisors, formInputs, formFields} = this.state;
        const {Edit, Delete} = this.props;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Supervisors</Typography>
                {_R.equals(Edit, true) &&
                    <Tooltip title="Add Supervisor" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.addSupervisor('add')}>
                        <AddIcon />
                    </Fab>
                    </Tooltip>
                }
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Priority</TableCell>
                            <TableCell>Supervisor</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            {_R.or(Edit, Delete) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { _R.type(supervisors) !== 'Undefined' && supervisors.map((s, i) =>
                        <TableRow hover>
                            <TableCell>{s.priority}</TableCell>
                            <TableCell>{formatName(s.first_name, null,s.last_name)}</TableCell>
                            <TableCell>{s.start_date}</TableCell>
                            <TableCell>{s.end_date}</TableCell>
                            {_R.or(Edit, Delete) &&
                                <TableCell align="right">
                                {_R.equals(Edit, true) &&
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                            <EditIcon fontSize="small" onClick={this.editSupervisor(s.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {_R.equals(Delete, true) &&
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                            <DeleteIcon fontSize="small" onClick={this.deleteSupervisor(s.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                </TableCell>
                            }
                        </TableRow>
                        )}
                        { _R.isEmpty(supervisors) &&
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium
                    Open={this.state.dialog_open}
                    Cancel={this.onClose}
                    Title={this.state.IsDelete ?  'Delete supervisor' : (this.state.IsEditable ? 'Edit Supervisor' : 'Add Supervisor')}
                    OnSubmit={this.state.IsDelete ? this.updateSupervisor('delete') : (this.state.IsEditable ? this.updateSupervisor('edit') : this.updateSupervisor('add'))}
                    ButtonName={this.state.IsDelete ? 'Ok' : 'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        this.state.IsDelete ?
                        'Do you want to delete this supervisor?'
                        :
                        (this.state.IsEditable?
                        <DialogEditContent
                            FormInputs={formInputs}
                            FormFields={formFields}
                            OnSubmit={this.updateSupervisor('edit')}
                            HandleOnChange = {this.HandleOnChange}
                            HandleDateChange={this.handleDateChange}
                            Errors = {this.state.errors}
                            Payperiod = {this.props.StaffDetails.pay_period}
                            DOJ={this.props.StaffDetails.date_joined}
                        />
                        :
                        <DialogAddContent
                            FormInputs={formInputs}
                            FormFields={formFields}
                            OnSubmit={this.updateSupervisor('add')}
                            HandleOnChange = {this.HandleOnChange}
                            HandleDateChange={this.handleDateChange}
                            Errors = {this.state.errors}
                            Payperiod={this.props.StaffDetails.pay_period}
                            DOJ={this.props.StaffDetails.date_joined}
                        />)
                    }
            />
            </>
        )
    }
}
