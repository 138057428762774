import React from 'react';
import { ChangeLogComponent} from './ChangeLog.Component';
import { httpServices } from '../../../../services/httpServices';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Switch from '@mui/material/Switch';
import { CustomizedSnackbars } from '../../../common/snackbars';
import Tooltip from '@mui/material/Tooltip';

export class PreferencesContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            userDetails : props.UserDetails,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    handleOnChange = (event) => {
        let userDetails = this.state.userDetails;
        let name = event.target.name;
        let value = event.target.checked ? 1:0;
        userDetails[name] = value;
        this.setState({userDetails: userDetails});
        let apiEndpoint = 'api/user/edit-preferences';
        return httpServices.post(apiEndpoint, {
            'user_id': userDetails['user_id'],
            'preference': name,
            'value' : value
            })
            .then(response => {
                if(response.data.success){
                this.setState({snackbarOpen : true,
                                snackbarVarient : 'success',
                                snackbarMsg : response.data.msg,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    render(){
        return (
        <React.Fragment>
            <div className={"mt-1"}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="td" scope="row">Email Notification</TableCell>
                            <TableCell component="td" scope="row"> 
                                <Tooltip title = { parseInt(this.state.userDetails.email_notification) ? "Yes":"No" }>
                                <Switch checked={parseInt(this.state.userDetails.email_notification)}
                                    onChange={this.handleOnChange}
                                    color="primary"
                                    name="email_notification"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="td" scope="row">Receive email for own actions</TableCell>
                            <TableCell component="td" scope="row">
                                <Tooltip title = { parseInt(this.state.userDetails.email_own_action) ? "Yes":"No" }>
                                 <Switch checked={parseInt(this.state.userDetails.email_own_action)}
                                    onChange={this.handleOnChange}
                                    color="primary"
                                    name="email_own_action"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="td" scope="row">Secondary email notification</TableCell>
                            <TableCell component="td" scope="row">
                                <Tooltip title = { parseInt(this.state.userDetails.secondary_email_notification) ? "Yes":"No" }>
                                     <Switch checked={parseInt(this.state.userDetails.secondary_email_notification)}
                                        onChange={this.handleOnChange}
                                        color="primary"
                                        name="secondary_email_notification"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            {this.state.snackbarOpen &&
            <CustomizedSnackbars
                open = {this.state.snackbarOpen}
                variant= {this.state.snackbarVarient}
                message = {this.state.snackbarMsg}
                handleClose = {this.state.snackbarClose}
            />
            }
        </React.Fragment>
            
        )
    }
}
