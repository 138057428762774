import React from 'react';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import {TimesheetDelinquencyReportComponent} from './TimesheetDelinquencyReportComponent';
import moment from 'moment';
import * as validations from '../../common/validate';

const filters = {
}
export class TimesheetDelinquencyReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            formFields: {},
            hasPermission : true,
            filters: filters,
            errors : {},
            resultSet : [],
            loaderOpen : false,
            errorMessage : '',
        }
        this.handleRecipientChange = this.handleRecipientChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleExportDelinquencyReport = this.handleExportDelinquencyReport.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount() {
        httpServices.get('api/report/timesheet-delinquency-report')
            .then(response => {
                let filters = this.state.filters;
                let formFields = this.state.formFields;
                if (response.data && response.data.permission){
                    filters.nameFormats = response.data.name_formats;
                    formFields.name_format = response.data.name_format;
                    filters.staffStatusList =  response.data.staff_statuses;
                    let currentDateArr =  response.data.current_date.split('-');
                    let currentDateObj = new Date(currentDateArr[0], currentDateArr[1]-1, currentDateArr[2]);
                    filters.currentDate = currentDateObj;
                    formFields.start_date = response.data.latest_sdate;
                    formFields.end_date = response.data.latest_edate;
                    this.setState ({
                        hasPermission : response.data.permission,
                        filters: filters,
                        formFields: formFields,
                    }, function() {
                        this.getStaffByStatus(0, this.state.formFields.start_date, this.state.formFields.end_date, 0);
                    });
                } else {
                    this.setState({
                        hasPermission: false,
                    });
                }
            });
        window.scrollTo(0, 0);    
    }
    handleRecipientChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') {
                //ff['county'] = 'new_'+value;
            } else if (typeof value === 'object' && value) {
                    ff['excluded_staff'] = value;
                    ff['excluded_resources'] = JSON.stringify(Object.values(this.state.formFields.excluded_staff));
            }
        } else {
            //ff['county'] = '';
        }
        this.setState({
            formFields: ff
        });
    };
    getStaffByStatus(status_id,start_date,end_date,staff_type){
        httpServices.post('api/report/getStaffList',{ 'status_id' : status_id,'start_date' : start_date,'end_date' : end_date, 'staff_type' : staff_type})
            .then(response => {
                if (response.data) {
                    let staff_list = response.data;
                    let RepInputs = this.state.filters;
                    RepInputs['staff_list'] = staff_list;
                    this.setState({
                        filters : RepInputs
                    });
                }
            })
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let errors = {};
        if (field === 'staff_status') {
            this.getStaffByStatus(evt.target.value, ff['start_date'], ff['end_date'], 0);        
        }
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff,
            errors : errors
        });
    }
    
    handleValidation() {
        let formIsValid = true;
        let validationData = [
            { name: 'start_date', value: this.state.formFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.formFields.end_date, type: 'date', otherOptions: { required: true } },
            { name: 'staff_status', value: this.state.formFields.staff_status, type: 'text', otherOptions: { required: false } },
            { name: 'name_format', value: this.state.formFields.name_format, type: 'text', otherOptions: { required: false } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        if (isValid) {
            let errors = {};
            let dateObject = moment(this.state.formFields.start_date);
            let selectdDay = parseInt(dateObject.format('DD'));
            if (selectdDay !== 1 && selectdDay !== 16) {
                errors['start_date'] = "Please select payperiod start date";
                formIsValid = false;
            }
            let dateOBJ = moment(this.state.formFields.end_date);
            let selectedDay = parseInt(dateOBJ.format('DD'));
            let endDateOfSelectedMonth = parseInt(dateOBJ.endOf('month').format('DD'));
            if (selectedDay !== 15 && selectedDay !== endDateOfSelectedMonth) {
                errors['end_date'] = "Please select payperiod end date";
                formIsValid = false;
            }
            this.setState({
                errors: errors
            });
        } else {
            formIsValid = false;
            this.setState({
                errors: validateErrors
            });
        }
        return formIsValid;
    }

    onSubmit =()=> {
        if(this.handleValidation()) {
            this.setState({loaderOpen : true});
            httpServices.post('api/report/timesheet-delinquency-report', {'start_date' :this.state.formFields.start_date, 'end_date' : this.state.formFields.end_date, 'staff_status': this.state.formFields.staff_status, 'name_format' : this.state.formFields.name_format, 'excluded_resources' : this.state.formFields.excluded_resources})
            .then(response => {
                if (response.data && response.data.permission && !response.data.errors){
                    this.setState ({
                        hasPermission : response.data.permission,
                        loaderOpen : false
                    });
                    if( response.data.valid) {
                        this.setState ({
                            resultSet : response.data,
                            errorMessage : false,
                        });
                    } else if (response.data.errors) {
                        this.setState ({
                            errors : response.data.errors,
                            loaderOpen : false,
                        });
                    }
                    else {
                        this.setState({resultSet : [], 
                            errorMessage :"Pay period limit exceeds. Maximum 24 intervals are allowed."});
                    }
                } else {
                    this.setState({
                        hasPermission: false,
                        loaderOpen : false
                    });
                }
            });
        }
    }

    handleDateChange = (date, field) => {
        const f = this.state.formFields;
        let errors = {};
        f[field] = date;
        if (field === 'start_date') {
            let dateOBJ = moment(this.state.formFields.start_date);
            let selectedDay = parseInt(dateOBJ.format('DD'));
            if (selectedDay !== 1 && selectedDay !== 16) {
                errors['start_date'] = "Please select payperiod start date";
            }
        }
        if (field === 'end_date') {
            let dateOBJ = moment(this.state.formFields.end_date);
            let selectedDay = parseInt(dateOBJ.format('DD'));
            let endDateOfSelectedMonth = parseInt(dateOBJ.endOf('month').format('DD'));
            if (selectedDay !== 15 && selectedDay !== endDateOfSelectedMonth) {
                errors['end_date'] = "Please select payperiod end date";
            }
        }
        this.setState({ formFields: f,
                errors : errors
            }, function(){
                this.getStaffByStatus(this.state.formFields.staff_status, this.state.formFields.start_date, this.state.formFields.end_date, 0);
            });
        };
    handleExportDelinquencyReport = () => {
        if (this.handleValidation()) {
            httpServices.post('api/report/timesheet-delinquency-report', { 'start_date': this.state.formFields.start_date, 'end_date': this.state.formFields.end_date, 'staff_status': this.state.formFields.staff_status, 'name_format': this.state.formFields.name_format, 'excluded_resources': this.state.formFields.excluded_resources, 'exportBtn': true }).then(response => {
                if (response.data.payperiod_count <= 24) {
                    if (response.data.users_count == 0) {
                        this.setState({
                            resultSet: [],
                            errorMessage: "No records found"
                        });
                    }
                    else {
                        this.setState({ errorMessage: false });
                        document.getElementById("delinquency").submit();
                    }
                }
                else {
                    this.setState({
                        resultSet: [],
                        errorMessage: "Pay period limit exceeds. Maximum 24 intervals are allowed."
                    });
                }
            });
        }
    }
    render(){
       return(
           (this.state.hasPermission)
            ?
            <TimesheetDelinquencyReportComponent 
                FormFields = { this.state.formFields }
                Filters = { this.state.filters }
                HandleOnChange = { this.HandleOnChange }
                handleRecipientChange = {this.handleRecipientChange}
                Errors = {this.state.errors}
                OnSubmit = {this.onSubmit}
                ResultSet = {this.state.resultSet}
                LoaderOpen = {this.state.loaderOpen}
                ErrorMessage = {this.state.errorMessage}
                HandleExportDelinquencyReport={this.handleExportDelinquencyReport}
                HandleDateChange = {this.handleDateChange}
           />
           :<ErrorPage/>
       )
    }
}

