import React from 'react';
import {BonusReportComponent} from './BonusReportComponent'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import { getDate,endOfMonth } from 'date-fns';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as validations from '../../common/validate';

const ReportFields = {
    'on_date' : '',
    'max_date': '',
}


export class BonusReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            hasPermission : true,
            disableButton : true,
            loader: true,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.exportReports  =  this.exportReports.bind(this);
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
        let dateObj = new Date(evt.target.value);
        let errors = {};
        let endDateOfSelectedMonth = getDate(endOfMonth(dateObj));
        ff[field] = evt.target.value;
        if (dateObj.getDate() === '15' || dateObj.getDate() === endDateOfSelectedMonth) {
            this.setState({
                ReportFields: ff,
                errors : {},
                disableButton:false,
            });
        }
        else
        {
            errors['on_date'] = "Please select payperiod end date";
            this.setState({
                errors : errors,
                disableButton:true,
            });
        }
    };  

    componentDidMount() {
        httpServices.post('api/report/consolidated-bonus-report')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        ReportFields : {
                            on_date : inputs.report_day,
                            max_date : inputs.report_day,
                        },
                        disableButton : false,
                        loader: false,
                     });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader: false,
                    });
                }
            })
    }    

    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'on_date', value: this.state.ReportFields.on_date, type: 'date', otherOptions: { required: true } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }
    render(){
       return( (this.state.loader )?
            <CustomProgressBar
                loaderOpen={this.state.loader}
            />
            :
            (this.state.hasPermission)?
           <BonusReportComponent
                Errors = { this.state.errors }
                HandleOnChange = { this.HandleOnChange }
                ReportFields = { this.state.ReportFields }
                DisableButton = { this.state.disableButton }
                ExportReports = { this.exportReports }
            />
            :<ErrorPage/>
            )
         }
     }
     
