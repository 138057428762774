import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { ucfirst} from 'bellajs';
import * as _R from 'ramda';  // used 0.04kb

import { formatName } from '../../../common/common';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { ServicePeriodEdit } from ".";
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';
import MixpanelManager from  '../../../../services/Analytics';

// dialog content
const DialogContent = (p) => {   
    return (
        <>
        {p.note &&
        	<div class="notes">
            <div class="note-item">
                <div class="note-text">
                    <p>{p.note.note}</p>

                </div>
                <div class="notes-footer">
                    <div class="notes-details">
                        <div class="item">Created by: {formatName(p.note.cby_first_name, null, p.note.cby_last_name)}, On: {p.note.created_on}</div>
                        {(p.note.modified_by) &&
                            <div class="item">Modified by: {formatName(p.note.mby_first_name, null, p.note.mby_last_name)}, On: {p.note.modified_on}</div>}
                    </div>
                </div>
            </div>
            </div>
        }
        </>
    )
}

class ServicePeriodListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isEditable: false,
            isDelete: false,
            formFields: [],
            sp_inputs: [],
            servicePeriods: this.props.ServicePeriods,
            edit: this.props.Edit,
            deletePermission:this.props.Delete,
            TotalExperience: this.props.TotalExperience,
            errors: {},
            errorMessage: false,
            noteOpen : false,
            note : '',
            beforeUpdateData: [],
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onNoteClose = this.onNoteClose.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount = () => {
        const {spData} = this.props;
        this.setState({formFields: spData});
    };
    handleClickOpen = (id) => () => {
        const { UserID } = this.props;
        let apiEndpoint = StaffConstants.API_SERVICE_PERIOD;
        return httpServices.post(apiEndpoint, {'userID':UserID, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            sp_inputs: response.data,
                            formFields: response.data.service_period,
                            beforeUpdateData: Object.assign({},response.data.service_period),
                            isDelete: false,
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            sp_inputs: response.data,
                            isEditable: false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleNoteOpen = (id) => () => {
        let apiEndpoint = 'api/staff/work/getNote';
        return httpServices.post(apiEndpoint, {'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            noteOpen:true,
                            note : response.data.note

                        });
                    }                   
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const userID = this.props.UserID;
            const staffID = this.props.StaffID;
            let data = Object.assign({},this.state.formFields);
            const id = this.state.id;
            data.user_id = userID;
            data.staff_id = staffID;
            let tab = StaffConstants.STAFF_TABS[2];
            let module = 'service_periods';
            let apiEndpoint = '';
            if (id) {
                data.id = id;
                apiEndpoint = 'api/staff/work/edit-service-period';
            }
            else {
                apiEndpoint = 'api/staff/work/add-service-period';
            }
            if (data) {
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                if (this.state.beforeUpdateData.notes !== data.notes) {
                                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-edit', 'action' : 'service-period-note-edit'});
                                }
                                this.setState({
                                    open:false,
                                    servicePeriods:response.data.service_periods,
                                    TotalExperience:response.data.total_experience
                                },()=>this.clearForm());
                            }
                            else {
                                let input_errors = response.data.input_errors;
                                if (Object.keys(input_errors).length) {
                                    this.setState({
                                        errors:response.data.input_errors,
                                    });
                                }
                                else {
                                    this.setState({
                                        errorMessage: response.data.msg,
                                    });
                                }
                            }
                        }
                        else {
                            this.setState({ errors : response.data.errors });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let daily_minimum = this.state.formFields.daily_minimum;
        let break_time = this.state.formFields.break_time;
        let dailyMaximum = this.state.formFields.daily_max_limit;
        let weekly_limit = this.state.formFields.weekly_limit;
        if (!this.state.formFields.staff_type) {
            errors["staff_type"] = "Cannot be empty";
            formIsValid = false;
        }
        else if (this.state.formFields.staff_type == 1 && !this.state.formFields.employment_type) {
            errors['employment_type'] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (typeof this.state.formFields.timesheet_required === 'undefined' || this.state.formFields.timesheet_required === '') {
            errors["timesheet_required"] = "Cannot be empty";
            formIsValid = false;
        }
        if (daily_minimum) {
            let reg = /^[0-9.]*$/;
            let ok = reg.exec(daily_minimum);
            if (!ok) {
                errors["daily_minimum"] = "Daily Minimum isn't valid";
                formIsValid = false;
            }
        }
        if (break_time) {
            let reg = /^[0-9.]*$/;
            let ok = reg.exec(break_time);
            if (!ok) {
                errors["break_time"] = "Break time isn't valid";
                formIsValid = false;
            }
        }
        if (dailyMaximum) {
            let maxLimit = 24;
            if (dailyMaximum > maxLimit) {
                errors["daily_max_limit"] = "Daily Maximum isn't valid";
                formIsValid = false;
            }
        }
        if (weekly_limit) {
            let reg = /^[0-9]+$/;
            let ok = reg.exec(weekly_limit);
            if (!ok) {
                errors["weekly_limit"] = "Weekly Limit isn't valid";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        var val = evt.target.value;
        if (field === 'include_holidays') {
            val = (val === 'Yes') ? 'No' : 'Yes';
        }
        ff[field] = val;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/work/delete-service-period';
        return httpServices.post(apiEndpoint, {
            'user_id': UserID,
            'staff_id': StaffID,
            'id': id,
        })
            .then(response => {
                this.setState({
                    open:false,
                });
                if (response.data.valid) {
                    let tab = StaffConstants.STAFF_TABS[2];
                    let module = 'service_periods';
                    this.setState({
                        servicePeriods:response.data.service_periods,
                        TotalExperience:response.data.total_experience
                    });
                }
                else {
                    alert(response.data.msg);

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    onNoteClose = () => {
        this.setState({
            noteOpen: false
        });console.log(this.state.noteOpen);
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    render(){
        const { servicePeriods, edit, deletePermission, TotalExperience } = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Service Period</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Service Period" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.handleClickOpen()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Staff Type</TableCell>
                            <TableCell>Employment Type</TableCell>
                            <TableCell>Organization</TableCell>
                            <TableCell>Pay Type</TableCell>
                            <TableCell>Overtime</TableCell>
                            <TableCell>Timesheet Required</TableCell>
                            <TableCell>Daily Min.</TableCell>
                            <TableCell>Daily Max.</TableCell>
                            <TableCell>Weekly Limit</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Break Time</TableCell>
                            {_R.or(edit, deletePermission) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof servicePeriods != 'undefined' && servicePeriods.map((sp) =>
                        <TableRow hover>
                            <TableCell>{sp.start_date} - {sp.end_date}</TableCell>
                            <TableCell>{sp.type}</TableCell>
                            <TableCell>{sp.emp_type}</TableCell>
                            <TableCell>{sp.client_name}</TableCell>
			    <TableCell>{sp.category != null? ucfirst(sp.category) : ''}</TableCell> 
                            <TableCell>{ucfirst(sp.overtime)}</TableCell>
                            <TableCell>{ucfirst(sp.timesheet_required)}</TableCell>
                            <TableCell>{sp.daily_minimum}</TableCell>
                            <TableCell>{sp.daily_max_limit}</TableCell>
                            <TableCell>{sp.weekly_limit} <span className={'sub'}>{(sp.weekly_limit !== null && sp.weekly_limit !== '0') ? (sp.include_holidays === 'Yes' ? '(Include Holidays)' : '(Exclude Holidays)') : <></>}</span></TableCell>
                            <TableCell>{sp.duration}</TableCell>
                            <TableCell>{sp.break_time}</TableCell>
                            {_R.or(edit, deletePermission) &&
                            <TableCell align="right">
                                <Tooltip title="Note" aria-label="comment">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Notes" onClick={this.handleNoteOpen(sp.id)}>
                                        <CommentIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                                {_R.equals(edit, true) &&
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.handleClickOpen(sp.id)}>
                                            <EditIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {_R.equals(deletePermission, true) &&
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onDelete(sp.id)}>
                                            <DeleteIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(servicePeriods) &&
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    <TableRow className = "text-bold">
                        <TableCell colSpan = {9} align="center"> Total : {TotalExperience}</TableCell>   
                        <TableCell colSpan = {2}> </TableCell>   
                    </TableRow>
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium
            Open={this.state.open}
            Cancel={this.onClose}
            Title={this.state.isDelete ?  'Delete Service Period' : (this.state.isEditable ? 'Edit Service Period' : 'Add Service Period')}
            OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
            ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
            ErrorMessage={this.state.errorMessage}
            Content={
                this.state.isDelete ?
                'Do you want to delete this Service Period?'
                :
                <ServicePeriodEdit 
                    OnSubmit={this.onSubmit}
                    spInputs={this.state.sp_inputs} 
                    HandleOnChange={this.HandleOnChange} 
                    spData={this.state.formFields}
                    Errors = {this.state.errors}
                />
            }
            />
            <DialogBoxMedium
                Open={this.state.noteOpen}
                HideCancel = {true}
                OnSubmit={this.onNoteClose}
                Title={'Note'}
                ButtonName={'Ok'}
                Content={<DialogContent note={this.state.note} />}
            />
            </>
        )
    }
}
export { ServicePeriodListContainer as ServicePeriodList };
