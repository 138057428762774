import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import UserIcon from '@mui/icons-material/PersonOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreIcon from '@mui/icons-material/MoreVert';
import TsLogoTitle from './TsLogoTitle';
import {TsMainNav} from './TsMainNav';
import Avatar from '@mui/material/Avatar';
import { setMenuItems } from '../../redux/store/reducers/menuItems';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import Icon from '@mui/material/Icon';
import Badge from '@mui/material/Badge';
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { httpServices } from '../../services/httpServices';
import cookie from 'react-cookies';
import * as _R from 'ramda';
import { history } from '../../helpers/history';
import { logout } from '../../modules/common/logout';
import { styled } from '@mui/material/styles';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

class AppBarMain extends Component{
    state = {
        auth: true,
        anchorEl: null,
        helpMenuAnchor: null,
        mobileMoreAnchorEl: null,
        left: false,
        info:[],
        company:[],
        notificationAnchor:null,
        announcementAnchor : null,
        notifications:[],
        announcements:[],
        unreadNotification : true,
        unreadAnnouncements : false,
        mainNavOptions : {},
        thumb_url : ''
    };
    componentDidMount() {
        httpServices.get('api/user/appinfo')
        .then(response => {
            if (response.data) {
                this.setState({info: response.data});
                this.setState({company:response.data.company_info});
                let app_title = response.data.company_info.short_name ? response.data.company_info.short_name + ' STEPS' : 'STEPS';
                document.title = app_title;
            }
        })
        .catch(function(error) {
        });
        this.getNotifications();
        this.getAnnouncements();
        this.getMenuItems();
        this.getPhoto();
    } 
    componentDidUpdate(prevProps) {
        if (Object.keys(prevProps.menuItems).length !== Object.keys(this.props.menuItems).length) {
            this.setState({
                mainNavOptions: this.props.menuItems,
            });
        }
    }
    handleProfileMenuOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handleHelpMenuOpen = event => {
        this.setState({
            helpMenuAnchor: event.currentTarget
        });
    };
    handleNotificationOpen = event => {
        this.getNotifications();
        this.setState({
            notificationAnchor: event.currentTarget
        });
    };
    handleAnnouncementOpen = event => {
        this.getAnnouncements();
        this.setState({
            announcementAnchor: event.currentTarget
        });
    };
    handleMenuOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            helpMenuAnchor: null,
            notificationAnchor : null,
            announcementAnchor : null
        });
        this.handleMobileMenuClose();
    };
    handleMyProfile = () => {
        this.handleMenuClose();
        history.push(BASE_PATH+'/profile');
    };
    handleMobileMenuOpen = event => {
        this.setState({
            mobileMoreAnchorEl: event.currentTarget
        });
    };
    handleMobileMenuClose = () => {
        this.setState({
            mobileMoreAnchorEl: null
        });
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };
    drawerAction = (side,open)  => {
        this.toggleDrawer(side, open)();
    }
    handleMenuLogout = event => {
        logout();
    };

    getNotification  = (id)=> () => {
        this.handleMenuClose();
        history.push(BASE_PATH+'/profile/messages/view/'+id);
    };
    getAnnouncement  = (id)=> () => {
        this.handleMenuClose();
        history.push(BASE_PATH+'/system/announcements/view/'+id);
    };
    getNotifications = () => {
        httpServices.get('api/dashboard/getNotifications')
            .then(response => {
                if (response.data) {
                    this.setState({
                        notifications:response.data.messages,
                        unreadNotification : response.data.unread_notifications
                    });
                }
            })
            .catch(function(error) {
            });
    };
    getPhoto = () => {
        httpServices.get('api/dashboard/getPhoto')
        .then(response => {
            if (response.data) {
                this.setState({
                    thumb_url:response.data.thumb_url,
                });
            }
        })
        .catch(function(error) {
        });
    };
    getAnnouncements = () => {
        httpServices.get('api/dashboard/getAnnouncements')
            .then(response => {
                if (response.data) {
                    this.setState({
                        announcements:response.data.announcements,
                        unreadAnnouncements : response.data.unread_announcements
                    });
                }
            })
            .catch(function(error) {
            });
    };
    getMenuItems = () => {
        const { dispatch } = this.props;
        dispatch(setMenuItems());
    }
    viewNotifications = () =>{
        this.handleMenuClose();
        history.push(BASE_PATH+'/profile/messages');
    };
    render(){
        const { anchorEl, helpMenuAnchor, mobileMoreAnchorEl, notificationAnchor, announcementAnchor} = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isHelpMenuOpen = Boolean(helpMenuAnchor);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const isNotificationOpen = Boolean(notificationAnchor);
        const isAnnouncementOpen = Boolean(announcementAnchor);
        
        const renderMenu = ( 
            <Menu 
                anchorEl = {anchorEl}
                anchorOrigin = {{ vertical: 'top',horizontal: 'right'}}
                transformOrigin = {{vertical: 'top', horizontal: 'right'}}
                open = {isMenuOpen}
                onClose = {this.handleMenuClose} 
                className={'appBar-main_dropmenu'}
                >
                
                {/*
                <MenuItem onClick = {this.handleMenuClose} >My account< /MenuItem>
                */}
                <MenuItem className={'nav-menu_usersnippet'}  onClick = {this.handleMenuClose} >Logged In as <span className={'name'}>{decodeURIComponent(cookie.load('tv2_username'))}</span></MenuItem>
                <MenuItem onClick = {this.handleMyProfile} >My Profile</MenuItem>
                <MenuItem onClick = {this.handleMenuLogout} >Logout</MenuItem>
            </Menu>
        );
        const helpMenu = ( 
            <Menu 
                id="help-menu"
                keepMounted
                anchorEl  = {helpMenuAnchor}
                anchorOrigin = {{ vertical: 'top',horizontal: 'right'}}
                transformOrigin = {{vertical: 'top', horizontal: 'right'}}
                open = {isHelpMenuOpen}
                onClose = {this.handleMenuClose} 
                className={'appBar-main_dropmenu'}
                >
                <MenuItem className={'nav-menu_userguide'} onClick = {this.handleMenuClose} ><Link to={BASE_PATH+'/downloads/Ascella_STEPS_User_Guide.pdf'} target="_blank">User Guide</Link></MenuItem>
                <MenuItem className={'nav-menu_help'} ><a href={"mailto:"+this.state.company.support_email} target="_blank">Report Bugs/Issues to <span>{(this.state.company.support_email)}</span></a></MenuItem> 
                <MenuItem className={'nav-menu_version'} >Version: {(_R.type(this.state.info.app_info) !== 'Undefined') ? this.state.info.app_info.version : ''}</MenuItem>
            </Menu>
        );
        const renderMobileMenu = ( 
            <Menu 
                anchorEl = { mobileMoreAnchorEl}
                anchorOrigin = {{vertical: 'top',horizontal: 'right'}}
                transformOrigin = {{vertical: 'top',horizontal: 'right'}}
                open = {isMobileMenuOpen}
                onClose = {this.handleMenuClose} 
                >
                {/*
                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton color="inherit">
                        <NotificationsIcon />
                    </IconButton>
                </MenuItem>
                */}
                <MenuItem>
                    <IconButton color="inherit">
                        <HelpOutlineIcon />
                    </IconButton>
                </MenuItem>
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <UserIcon />
                    </IconButton>
                </MenuItem>
                {/*
                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton color="inherit">
                        <SettingsIcon />
                    </IconButton>
                </MenuItem>
                */}
            </Menu>
        );
        const notificationMenu = ( 
            <Menu 
                id="notification-menu"
                keepMounted
                anchorEl  = {notificationAnchor} getContentAnchorEl={null}
                anchorOrigin = {{ vertical: 'bottom',horizontal: 'right'}}
                transformOrigin = {{vertical: 'top', horizontal: 'right'}}
                open = {isNotificationOpen}
                onClose = {this.handleMenuClose} 
                className={'appBar-main_dropmenu notification-menu'}
            >
            <>{ this.state.notifications && this.state.notifications.map((row) =>
                <MenuItem className={'nav-menu_notification'} onClick = {this.getNotification(row.msg_id)} >
                    <Icon color="inherit"><MailOutlineIcon /></Icon>
                    <span className={'notification_text'}>{(parseInt(row.msg_status)===1)?row.msg_subject:<b>{row.msg_subject}</b>}</span>
                </MenuItem>
            )}</>
            <MenuItem><Button  variant="outlined" style ={{ marginLeft : 'auto' }} size="small" onClick = {this.viewNotifications}> view more </Button></MenuItem>
            </Menu>
        );
        
        const announcementMenu = (
            <Menu
                id="announcement-menu"
                keepMounted
                anchorEl  = {announcementAnchor} getContentAnchorEl={null}
                anchorOrigin = {{ vertical: 'bottom',horizontal: 'right'}}
                transformOrigin = {{vertical: 'top', horizontal: 'right'}}
                open = {isAnnouncementOpen}
                onClose = {this.handleMenuClose}
                className={'appBar-main_dropmenu notification-menu'}
            >
            <>{ this.state.announcements && this.state.announcements.map((row) =>
                <MenuItem className={'nav-menu_notification'} onClick = {this.getAnnouncement(row.id)} >
                    <Icon color="inherit"><EmojiFlagsIcon /></Icon>
                    <span className={'notification_text'}>{(row.read)?row.title:<b>{row.title}</b>}</span>
                </MenuItem>)
            }</>
            </Menu>
        );
        return(
            
            <React.Fragment>
                <StyledAppBar color="default" className={'appBarMain'}>
                <div className={'steps-bar'}>
                    <div className={'steps-logo'}>
                        <div className={'helper'}>
                            <img src={BASE_PATH+"/steps-logo.svg"} alt="" />
                        </div>
                    </div>
                    </div>
                    <Toolbar className={'appBarMain__toolbar'}>
                        <IconButton className={'menuButton sectionMobile'} onClick={this.toggleDrawer('left', true)} color="inherit" aria-label="Open drawer">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={'branding'}>
                            <TsLogoTitle 
                                title={(_R.type(this.state.info.company_info) !== 'Undefined') ? this.state.info.company_info.short_name : ''}
                            /> 
                        </Typography>
                        <div className={'mainNavSection'}><TsMainNav path={this.props.path} drawerAction={this.drawerAction.bind(this)} mainNavOptions={ this.state.mainNavOptions } /></div>
                        <div className={'sectionDesktop'}>
                            <IconButton 
                                aria-owns={isAnnouncementOpen ? 'announcement-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleAnnouncementOpen}
                                color="inherit"
                                tabindex={"-1"} >
                                <Badge color="secondary" variant="dot" overlap="circular" invisible={this.state.unreadAnnouncements ? false:true}><VolumeUpIcon /></Badge>
                            </IconButton>
                            <IconButton
                                aria-owns={isNotificationOpen ? 'notification-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleNotificationOpen}
                                color="inherit"
                                tabindex={"-1"} >
                                <Badge color="secondary" variant="dot" overlap="circular" invisible={this.state.unreadNotification ? false:true}><MailOutlineIcon /></Badge>
                            </IconButton>
                            <IconButton
                                aria-owns={isHelpMenuOpen ? 'help-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleHelpMenuOpen}
                                color="inherit"
                                tabindex={"-1"}
                            >
                                <HelpOutlineIcon />
                            </IconButton>
                            <IconButton
                                aria-owns={isMenuOpen ? 'user-details' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                                tabindex={"-1"}
                                >
                                {this.state.thumb_url !== '' ?
                                    <Avatar className={'staff_avatar'} src={this.state.thumb_url}></Avatar>
                                    :
                                    <UserIcon />
                                }
                            </IconButton>
                            {/*
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                            */}
                        </div>
                        <div className={'sectionMobile'}>
                            <IconButton tabindex={"-1"} className={'moreButton'} aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </StyledAppBar>
                <Paper><div className={'toolbar' + ' appBarHelper'} /></Paper>
                {renderMenu}{notificationMenu}{announcementMenu}
                {helpMenu}
                {renderMobileMenu}
                <Drawer className={'sectionMobile'} open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                    <div className={'toolbar appBarMain__toolbar'}>
                        <TsLogoTitle />
                    </div>
                    <Divider />
                    <TsMainNav  path={this.props.path} drawerAction={this.drawerAction.bind(this)} mainNavOptions={ this.state.mainNavOptions } />
                </Drawer>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const menuItems =  state.menuItems.menu ? state.menuItems.menu : {};
    return {
        menuItems: menuItems
    }
}

const connectedAppBarMain = connect(mapStateToProps)(AppBarMain);
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    '& .sectionDesktop': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    '& .sectionMobile': {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    '& .menuButton': {
        marginLeft: -12,
        marginRight: 20,
    },
    '& .moreButton': {
        marginRight: -12,
        marginLeft: 20,
    },
    '& .grow': {
        flexGrow: 1,
    },
    '& .mainNavSection': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexGrow: 1,
        },
    },
    '& .toolbar': theme.mixins.toolbar,
})); 
export { connectedAppBarMain as AppBarMain};
