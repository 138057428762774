import React from 'react';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CustomizedSnackbars } from '../../common/snackbars';
import Badge from '@mui/material/Badge';
import parse from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import { DialogBoxMedium } from '../../common/dialog.medium';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';


const StyledDiv = styled('div')(({ theme }) => ({
    width: 500,
    '& > * + *': {
        marginTop: theme.spacing(3),
    },
}));
export const PreliminaryReviewComponent = (props) => {
    let projects = (_R.type(props.Projects) !== 'Undefined') ? props.Projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.FormFields.project_id) !== 'Undefined') ? [props.FormFields.project_id] : []));
    }
    return (
        <>
            <Paper className={'mb-1'}>
                <Grid container class="p1">
                    <Grid item>
                        <form className="form-fullwidth" >
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6} className='justify-between'>
                                    <InputLabel htmlFor="project_id" className="MuiInputLabel-shrink" required error={props.Errors.project_id && true} >Project</InputLabel>
                                    <FormControl error={props.Errors.project_id && true}>
                                        <TreeSelectComponent
                                            dropdown_mode={'true'}
                                            data={typeof projects ? projects : []}
                                            placeholderText={"Select Project"}
                                            type='project'
                                            HandleTreeChange={props.HandleTreeChange}
                                        />
                                        <input type="hidden" value={props.FormFields.project_id} name="project_id" />
                                        <FormHelperText >{props.Errors.project_id}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div class="filter-column">
                                        <div class="filter-column-content">
                                            <FormControl error={_R.type(props.Errors.staff) !== 'Undefined' ? true : false} variant="standard">
                                                <InputLabel required htmlFor="project">Staff</InputLabel>
                                                <Select
                                                    required
                                                    value={props.FormFields.staff}
                                                    inputProps={{
                                                        name: 'staff',
                                                        id: 'staff',
                                                    }}
                                                    multiple
                                                    onChange={props.HandleOnChange}
                                                >
                                                    {_R.type(props.ProjectResources) !== 'Undefined' && props.ProjectResources.map((rtype, i) =>
                                                        <MenuItem key={'staff-' + i} value={rtype.user_id}>{rtype.resource_fullname}</MenuItem>
                                                    )}
                                                </Select>
                                                {_R.type(props.Errors.staff) !== 'Undefined' && <FormHelperText>{props.Errors.staff}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <div class="filter-column">
                                        <div class="filter-column-content">
                                            <FormControl  variant="standard">
                                                <InputLabel required htmlFor="project">Lock Status</InputLabel>
                                                <Select
                                                    required
                                                    value={props.FormFields.lock_status}
                                                    inputProps={{
                                                        name: 'lock_status',
                                                        id: 'lock_status',
                                                    }}                                                    
                                                    onChange={props.HandleOnChange}
                                                >
                                                    <MenuItem key={'unlock'} value={0}>Unlocked</MenuItem>
                                                    <MenuItem key={'unlock'} value={1}>Locked</MenuItem>
                                                    <MenuItem value='All'>All</MenuItem>
                                                   
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3} >
                                    <FormControl>
                                        <CustomDatePicker
                                            name="startDate"
                                            id="startDate"
                                            selectedDate={props.FormFields.startDate}
                                            handleDateChange={props.HandleStartDateChange}
                                            error={props.Errors.startDate}
                                            required={true}
                                            label="Start Date"
                                        />
                                    </ FormControl>
                                </Grid>
                                {props.Week != null &&
                                    <Grid item xs={12} md={3} >
                                        <StyledDiv>
                                            <div>
                                                <Alert severity="info"> {props.Week} </Alert> </div>
                                        </StyledDiv>
                                    </Grid>
                                }
                                <Grid item xs={12} class='filter-action'>
                                    <div class="filter-column">
                                        <div class="d-flex align-center justify-end">
                                            <Button variant="contained" size="small" color="primary" onClick={props.HandleSubmit}>Show</Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Paper>

            {props.SubmitClick &&
                <Paper>
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding
                            : '1rem'
                    }}>
                        <div className={'col-left'}>
                            {props.Week &&
                                <Typography variant="h6" component="h6" className={'section-title'}>
                                    Timesheet Preliminary Review  for {props.Week}
                                </Typography>
                            }
                        </div>
                        <div className={'actions'}>
                            {props.LockPermission && props.FormFields.lock_status == 0 &&
                                <Button variant="contained" color="primary" onClick={() => props.HandleLockClick("lock")} >Lock</Button>
                            }
                            {props.UnlockPermission && props.FormFields.lock_status == 1 &&
                                <>&nbsp;<Button variant="contained" color="primary" onClick={() => props.HandleLockClick("unlock")}>Unlock</Button></>
                            }
                        </div>
                    </div>
                    <div className={'timeSheet-list_body'}>

                        <Table className={'list-table_basic'} size="small">
                            <TableHead className={'thead'}>
                                <TableRow>
                                    <TableCell width="50px"></TableCell>
                                    <TableCell width="50px">Date</TableCell>
                                    <TableCell width="20%"><div>Charge code</div>
                                        <div>Client / Project </div></TableCell>
                                    <TableCell width="20%">Task</TableCell>
                                    <TableCell width="60%">Description</TableCell>
                                    <TableCell>Hours</TableCell>
                                    <TableCell className={'action'}>Last modified</TableCell>
                                    <TableCell className={'action'}>Lock status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={'tbody'}>
                                {_R.type(props.TimesheetDetails) !== 'Undefined' && Object.keys(props.TimesheetDetails).map((row, row_index) =>
                                    <>
                                        <TableRow>
                                            <TableCell>
                                            {props.FormFields.lock_status !== 'All' && 
                                                <Checkbox
                                                    onChange={props.HandleSelectionChange}
                                                    value={props.TimesheetDetails[row].id}
                                                    color="primary"
                                                    checked={_R.includes(props.TimesheetDetails[row].id, props.SelectedTimesliceCbyIds) ? true : false}
                                                    id={"staff_checkbox"}
                                                    name={"staff_checkbox"}
                                                />
                                            }
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>Staff Name : </b>{props.TimesheetDetails[row].name}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>

                                        </TableRow>
                                        {_R.type(props.TimesheetDetails[row].timeslice) !== 'Undefined' && Object.keys(props.TimesheetDetails[row].timeslice).map((row_key, ts_index) =>
                                            <>
                                                <TableRow>
                                                    <TableCell>
                                                    {props.FormFields.lock_status !== 'All' &&
                                                        <Checkbox
                                                            className={'action_icon'}
                                                            onChange={props.HandleSelectionChange}
                                                            checked={_R.includes(row_key + "_" + props.TimesheetDetails[row].id, props.SelectedTimeslices) ? true : false}
                                                            color="primary"
                                                            id={"timeslice_checkbox"}
                                                            name={"timeslice_checkbox"}
                                                            value={row_key + "_" + props.TimesheetDetails[row].id}
                                                        />
                                                    }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={'rowboarder-right vtop th-date'}>
                                                        <span className={'timesheet-list_row-date'}>
                                                            {props.TimesheetDetails[row].timeslice[row_key].ts_date.dayName ? props.TimesheetDetails[row].timeslice[row_key].ts_date.dayName : ''}
                                                            <span className={'dm'}>{props.TimesheetDetails[row].timeslice[row_key].ts_date.monthName ? props.TimesheetDetails[row].timeslice[row_key].ts_date.monthName : ''}
                                                                <span className={'dd'}>{props.TimesheetDetails[row].timeslice[row_key].ts_date.dateValue ? props.TimesheetDetails[row].timeslice[row_key].ts_date.dateValue : ''}</span>
                                                            </span>
                                                            {props.TimesheetDetails[row].timeslice[row_key].ts_date.dateYear ? props.TimesheetDetails[row].timeslice[row_key].ts_date.dateYear : ''}
                                                        </span>
                                                    </TableCell>
                                                    {_R.type(props.TimesheetDetails[row].timeslice[row_key].each_timeslice) !== 'Undefined' &&  _R.type(props.TimesheetDetails[row].timeslice[row_key].each_timeslice[0]) !== 'Undefined' ?
                                                        <>
                                                            <TableCell>
                                                                <div className={'te-charge-code'}>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].chargecode}</div>
                                                                <div> {Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].project_path}</div>
                                                            </TableCell>
                                                            <TableCell>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].task_name}</TableCell>
                                                            <TableCell width="30%">
                                                                <div className={'timesheet-list_row-description'}>
                                                                    {parse(Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].timeslice_log)}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell><span className={'timesheet-list_row-time col-time'}>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].timeslice_hours}</span>
                                                                <div>
                                                                    <Tooltip title={Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].tasktype_name} aria-label="tasktype">
                                                                        <span className={'tasktype-badge ' + Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].code.toLowerCase()}>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].code}</span>
                                                                    </Tooltip>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={'td-action'}>
                                                                <div className="action">
                                                                    <>
                                                                        <div className={'timesheet-list_row-description'}><span>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].modifiedDateValue}</span><span>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].modifiedTimeValue}</span></div>

                                                                    </>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>{Object.values(props.TimesheetDetails[row].timeslice[row_key].each_timeslice)[0].lock_status ?
                                                                <div className='timesheet-list_row-locked'>
                                                                    <Tooltip title={'Timeentry is locked'}>
                                                                        <LockIcon></LockIcon>
                                                                    </Tooltip>
                                                                </div> : ''}</TableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>{props.TimesheetDetails[row].timeslice[row_key]['lock_status'] ?
                                                                <div className='timesheet-list_row-locked'>
                                                                    <Tooltip title={'Timeentry is locked'}>
                                                                        <LockIcon></LockIcon>
                                                                    </Tooltip>
                                                                </div> : ''}</TableCell>
                                                        </>
                                                    }


                                                   
                                                </TableRow>
                                                {_R.type(props.TimesheetDetails[row].timeslice[row_key].each_timeslice) !== 'Undefined' && Object.keys(props.TimesheetDetails[row].timeslice[row_key].each_timeslice).map((each_day, ts_index) =>
                                                    <>
                                                        {each_day != 0 &&
                                                            <TableRow>
                                                                <TableCell>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row" className={'rowboarder-right vtop th-date'}>

                                                                </TableCell>
                                                                {/* } */}
                                                                <TableCell>
                                                                    <div className={'te-charge-code'}>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].chargecode}</div>
                                                                    <div> {props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].project_path}</div>
                                                                </TableCell>
                                                                <TableCell>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].task_name}

                                                                </TableCell>
                                                                <TableCell width="30%">
                                                                    <div className={'timesheet-list_row-description'}>
                                                                        {parse(props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].timeslice_log)}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell><span className={'timesheet-list_row-time col-time'}>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].timeslice_hours}</span>
                                                                    <div>
                                                                        <Tooltip title={props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].tasktype_name} aria-label="tasktype">
                                                                            <span className={'tasktype-badge ' + props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].code.toLowerCase()}>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].code}</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={'td-action'}>
                                                                    <div className="action">
                                                                        <>
                                                                            <div className={'timesheet-list_row-description'}><span>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].modifiedDateValue}</span><span>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].modifiedTimeValue}</span></div>

                                                                        </>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{props.TimesheetDetails[row].timeslice[row_key].each_timeslice[each_day].lock_status ? 
                                                                <div className='timesheet-list_row-locked'>
                                                                    <Tooltip title={'Timeentry is locked'}>
                                                                    <LockIcon></LockIcon> 
                                                                    </Tooltip>
                                                                </div> : ''}</TableCell>
                                                            </TableRow>
                                                        }

                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Table>

                    </div>
                </Paper>
            }

            <DialogBoxMedium
                Open={props.DialogConfirmOpen}
                Cancel={props.OnClose}
                Title={`Confirm ${props.LockAction}`}
                ButtonName={'Yes'}
                OnSubmit={props.HandleLockSubmit}
                Content={<DialogConfirm msg={`Do you want to ${props.LockAction}  the time entries ?`} />}
            />
            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant={"success"}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
             <DialogBoxMedium
                Open={props.DialogWarningOpen}
                HideCancel={true}
                Title={`Error Message`}
                ButtonName={'Ok'}
                OnSubmit={props.OnClose}
                Content={<DialogConfirm msg={`Time entries not selected`} />}
            />
        </>
    )
};
const DialogConfirm = (p) => {
    return (
        <div>
            <Box className={'mt-1'}>
                <Typography variant="subtitle2" gutterBottom>
                    {p.msg}
                </Typography>
            </Box>
        </div>
    )
}
