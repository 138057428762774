import React from 'react';
import Grid from '@mui/material/Grid';
import {EligibilityCriteriaGroupsView}  from './EligibilityCriteriaGroups.Container';

export class EligibilityCriteria extends React.Component{
    render(){     
        return (
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                <Grid  item xs={12} md={12}>
            <EligibilityCriteriaGroupsView
            />
            </Grid>
            </Grid>
        )
    }
}
