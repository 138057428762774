import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';

export const TimeInOutList = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
        <Table className={'list-table_basic'} size="small" aria-label="a dense table" >
          <TableHead className={'thead'}>
            <TableRow >
               <TableCell> Date </TableCell>
               <TableCell> Time in </TableCell>
               <TableCell> Time out </TableCell>
               <TableCell> Hours </TableCell>
               <TableCell> Location </TableCell>
               <TableCell> Last modified </TableCell>
               <TableCell> Actions </TableCell>
             </TableRow>
           </TableHead>
      <TableBody className={'tbody'}>
          {_R.type(props.time_inout_details) !== 'Undefined' && Object.keys(props.time_inout_details).map((item,k) =>
          <>
          {item !== "grandTotal" ?
          <>
             {Object.keys(props.time_inout_details[item]).map((k) =>
             <>
                 <TableRow>
            {parseInt(k) === 0 ? <TableCell component="th" scope="row" rowspan={Object.keys(props.time_inout_details[item]).length-1}>{item}</TableCell> :<></>}
                {k !== 'total' ?
                <> 
                   <TableCell >{props.time_inout_details[item][k].time_in_ampm} </TableCell>
                   <TableCell component="th" scope="row" >{props.time_inout_details[item][k].time_out_ampm} </TableCell>
                   <TableCell component="th" scope="row" >{props.time_inout_details[item][k].diff_hours} </TableCell>
                   <TableCell component="th" scope="row" >{props.time_inout_details[item][k].location_name} </TableCell>
                   <TableCell component="th" scope="row" >{props.time_inout_details[item][k].modified_on} </TableCell>
                   <TableCell>
                   <Tooltip title="Edit" aria-label="edit">
                      <IconButton   className={'action-btn'} edge="end" aria-label="Edit">
                         <EditIcon onClick={() => props.EditTimeInout(props.time_inout_details[item][k].db_date)} fontSize="small" />
                       </IconButton>
                    </Tooltip>
                    </TableCell>
                </>
                :<></>
                }
                </TableRow>
             </> 
             )}
          <TableRow>
                <TableCell colspan={2} align="left"></TableCell>
                <TableCell><strong>Day total</strong></TableCell>
                <TableCell colspan={4}><strong>{props.time_inout_details[item]['total']} Hours </strong></TableCell>
          </TableRow>
        </>:<></>}
        </>)}
           <TableRow>
             <TableCell colspan={2}></TableCell>
            <TableCell><strong> Grand total</strong></TableCell><TableCell colspan={4}><strong> {props.time_inout_details['grandTotal']} Hours </strong></TableCell></TableRow> 
            </TableBody>
        </Table>
    </div>
  );
}

