import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { formatName } from '../../common/common';

export const TimeSliceReviewNotes = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        (typeof props.Button === 'undefined' || props.Button !== "review_button") ?
        <>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            >
            <Typography variant="subtitle1" gutterBottom>Notes</Typography>
            </AccordionSummary>
         <AccordionDetails>
                {props.Comments.length > 0 ?
                    <ul className="list-standard list-ul-shrink">
                        {(props.Comments).map((item) =>
                            <>
                            <li className="list-item">
                                <Typography variant="body1" gutterBottom>{item.note}</Typography>
                            </li>
                            <li className="list-item">
                                <Typography  variant="body2" display="block"><i>Posted by {formatName(item.user_first_name,null,item.user_last_name)} On {item.comment_ctime}</i></Typography>
                            </li>
                            </>
                        )}
                    </ul>
                    :
                    <Typography variant="body2" gutterBottom align='center'>No notes for this timeslice</Typography>

                }
            </AccordionDetails>
        </Accordion>
        </>
        :
        <>
          {props.Comments.length > 0 ?
                    <ul className="list-standard list-ul-shrink">
                        {(props.Comments).map((item) =>
                            <>
                            <li className="list-item">
                                <Typography variant="body1" gutterBottom>{item.note}</Typography>
                            </li>
                            <li className="list-item">
                                <Typography  variant="body2" display="block"><i>Posted by {formatName(item.user_first_name,null,item.user_last_name)} On {item.comment_ctime}</i></Typography>
                            </li>
                            </>
                        )}
                    </ul>
                    :
                    <Typography variant="body2" gutterBottom align='center'>No notes for this timeslice</Typography>

                }
        </>
    );
}

