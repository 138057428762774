import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const EditServicePeriodStyle = styled(React.Fragment)(({ theme }) => ({
    '& .formControl': {
        padding: theme.spacing(1),
        minWidth: 120,
    },
    formControl2Col:{
        padding: theme.spacing(1),
        width: '50%',
    }
}));

export const ServicePeriodEdit = (props) => {
    return (
        <EditServicePeriodStyle>
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <InputLabel htmlFor="staff_type" error={props.Errors.staff_type && true} required >Staff Type</InputLabel>
                <Select
                    value={typeof props.spData != 'undefined' && props.spData.staff_type}
                    onChange={props.HandleOnChange}
                    error={props.Errors.staff_type && true}
                    inputProps={{
                        name: 'staff_type',
                        id: 'staff_type',
                    }}>
                { typeof props.spInputs != 'undefined' && props.spInputs.staff_types.map((t, i) => 
                    <MenuItem value={t.id}>{t.type}</MenuItem>

                )}
                </Select>
                <small className='error'>{props.Errors.staff_type && props.Errors.staff_type}</small>
            </ FormControl>
                </Grid>

            <Grid item xs={12} md={6}>
                <FormControl variant="standard">
                    <InputLabel htmlFor="employment_type" error={props.Errors.employment_type && true} required = { (typeof props.spData.staff_type !== 'undefined' && props.spData.staff_type === 1) ? true : false} >Employment Type</InputLabel>
                    <Select
                        value={typeof props.spData != 'undefined' && props.spData.employment_type}
                        onChange={props.HandleOnChange}
                        error={props.Errors.employment_type && true}
                        inputProps={{
                            name: 'employment_type',
                            id: 'employment_type',
                        }}>
                    { typeof props.spInputs != 'undefined' && props.spInputs.employment_types.map((t, i) =>
                        <MenuItem value={t.id}>{t.title}</MenuItem>

                    )}
                    </Select>
                    <small className='error'>{props.Errors.employment_type && props.Errors.employment_type}</small>
                </ FormControl>
            </Grid>
                <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <InputLabel htmlFor="organization">Organization</InputLabel>
                <Select
                    value={typeof props.spData != 'undefined' && props.spData.organization}
                    onChange={props.HandleOnChange}
                    inputProps={{
                        name: 'organization',
                        id: 'organization',
                    }}>
                    { typeof props.spInputs != 'undefined' && props.spInputs.organizations.map((o, i) =>
                        <MenuItem value={o.client_id}>{o.client_name}</MenuItem>
                    )}
                </Select>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl>
                <TextField variant="standard"
                    id="start_date"
                    name="start_date"
                    label="Start Date"
                    type="date"
                    className="no-margin"
                    value={typeof props.spData != 'undefined' && props.spData.start_date}
                    required
                    onChange={props.HandleOnChange}
                    error={props.Errors.start_date && true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                <small className='error'>{props.Errors.start_date && props.Errors.start_date}</small>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl>
                <TextField variant="standard"
                    id="end_date"
                    name="end_date"
                    label="End Date"
                    type="date"
                    className="no-margin"
                    value={typeof props.spData != 'undefined' && props.spData.end_date}
                    onChange={props.HandleOnChange} 
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
                <small className='error'>{props.Errors.end_date && props.Errors.end_date}</small>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <InputLabel htmlFor="category">Category</InputLabel>
                <Select
                    value={typeof props.spData != 'undefined' && props.spData.category}
                    onChange={props.HandleOnChange}
                    inputProps={{
                        name: 'category',
                        id: 'category',
                    }}>
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    <MenuItem value={'salary'}>Salary</MenuItem>
                    <MenuItem value={'hourly'}>Hourly</MenuItem>
                </Select>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <InputLabel htmlFor="overtime">Overtime</InputLabel>
                <Select
                    value={typeof props.spData != 'undefined' && props.spData.overtime}
                    onChange={props.HandleOnChange}
                    inputProps={{
                        name: 'overtime',
                        id: 'overtime',
                    }}>
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    <MenuItem value={'exempt'}>Exempt</MenuItem>
                    <MenuItem value={'non-exempt'}>Non-exempt</MenuItem>
                </Select>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl variant="standard">
                    <InputLabel htmlFor="timesheet_required" error={props.Errors.timesheet_required && true} required>Timesheet Required</InputLabel>
                    <Select
                        value={typeof props.spData != 'undefined' && props.spData.timesheet_required}
                        onChange={props.HandleOnChange}
                        inputProps={{
                            name: 'timesheet_required',
                            id: 'timesheet_required',
                        }}>
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                    <small className='error'>{props.Errors.timesheet_required && props.Errors.timesheet_required}</small>
                </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl>
                <TextField variant="standard"
                    id="daily_minimum"
                    name="daily_minimum"
                    label="Daily Minimum"
                    error={props.Errors.daily_minimum && true}
                    type="text"
                    value={typeof props.spData != 'undefined' && props.spData.daily_minimum}
                    onChange={props.HandleOnChange}
                />
                <small className='error'>{props.Errors.daily_minimum && props.Errors.daily_minimum}</small>
            </ FormControl>
            </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="daily_max_limit"
                            name="daily_max_limit"
                            label="Daily Maximum"
                            error={props.Errors.daily_max_limit && true}
                            type="text"
                            value={typeof props.spData != 'undefined' && props.spData.daily_max_limit}
                            onChange={props.HandleOnChange}
                        />
                        <small className='error'>{props.Errors.daily_max_limit && props.Errors.daily_max_limit}</small>
                    </ FormControl>
                </Grid>
            <Grid item xs={12} md={6}>
            <FormControl>
                <TextField variant="standard"
                    id="weekly_limit"
                    name="weekly_limit"
                    label="Weekly Limit"
                    error={props.Errors.weekly_limit && true}
                    type="text"
                    value={typeof props.spData != 'undefined' && props.spData.weekly_limit}
                    onChange={props.HandleOnChange}
                />
                <small className='error'>{props.Errors.weekly_limit && props.Errors.weekly_limit}</small>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl>
                <TextField variant="standard"
                    id="break_time"
                    name="break_time"
                    label="Break Time"
                    error={props.Errors.break_time && true}
                    type="text"
                    value={typeof props.spData != 'undefined' && props.spData.break_time}
                    onChange={props.HandleOnChange}
                />
                <small className='error'>{props.Errors.break_time && props.Errors.break_time}</small>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <TextField
                    id="notes"
                    name="notes"
                    label="Notes"
                    error={props.Errors.notes && true}
                    type="text"
                    multiline
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={typeof props.spData != 'undefined' && props.spData.notes}
                    onChange={props.HandleOnChange}
                />
                <small className='error'>{props.Errors.notes && props.Errors.notes}</small>
            </ FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl variant="standard">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={typeof props.spData != 'undefined' && props.spData.include_holidays === 'Yes' ? true : false}
                            name="include_holidays"
                            value={typeof props.spData != 'undefined' && props.spData.include_holidays} 
                            onChange={props.HandleOnChange}
                            color="primary"
                        />
                    } 
                    label="Include Holidays" 
                />
            </ FormControl>
            </Grid>           
            </Grid>
            </form>
        </EditServicePeriodStyle>
    );
};
