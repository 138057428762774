import React from 'react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DialogBoxMedium} from '../common/dialog.medium';
import { CustomMaterialTable } from '../common/CustomMaterialTable';

export const JobTitleComponent= (props) => {   
    let dataArr = typeof props.JobTitle !== 'undefined' ? props.JobTitle : [] ;
    const columns = [
        { title: 'Job Title', field: 'title', defaultSort: 'asc', },
        { title: 'Level', field: 'name', }
    ];
    return (
        <>
            <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Job Titles</Typography>
                    {props.EditPermission && <Tooltip title="Add Job Title" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} >
                            <AddIcon onClick={props.OnAddJobTitleClick}/>
                        </Fab>
                    </Tooltip>}
                </div>
                {(props.HasPermission) ? <div className={'section-content roles-table'}>
                    <CustomMaterialTable
                        columns={columns}
                        data={dataArr}
                        title = " "
                        options={{
                            search: true,
                            actionsColumnIndex: -1,                            
                            toolbar:true,
                            pageSizeOptions: [10,20,30],
                            pageSize:10,
                            grouping: true
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                hidden : props.EditPermission ? false : true,
                                onClick: (event, rowData) => {
                                    props.EditJobTitleClick(rowData.id);
                                },
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                hidden : props.DeletePermission ? false : true,
                                onClick: (event, rowData) => {
                                    props.DeleteJobTitleClick(rowData.id);
                                },
                            }

                        ]}
                    />
                </div>
                :
                <div><p className='error'>You are not authorized to view the Job Titles</p></div>}
            </div>
            {props.PopupType === 'add' &&
            <DialogBoxMedium 
                Open= { props.Open }  
                Cancel = { props.OnClose }
                Title = { props.IsEditable ? 'Edit Job Title' : 'Add  Job Title' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogContent FormProps = { props }/> }
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                OnSubmit={props.DeleteJobTitle}
                Title='Confirm Delete'
                ButtonName='Ok'   
                Content={
                    <DialogWithConfirmMessage msg="Do you really want to delete this Job Title ?" />
                }
            />
            }
        </>
    )
}

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange } = props;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                
                <FormControl>
                        <InputLabel htmlFor = "level" error = {props.Errors.level && true} required >Level</InputLabel>
                        <Select                        
                            value={_R.type(FormFields) !== 'Undefined' && FormFields.level}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'level',
                                id: 'level',
                            }}>
                            {props.ListFields.map((jt, i) =>         
                                <MenuItem value={jt.id} key={'sub-type-'+i}>{jt.name}</MenuItem>
                            )}
                        </Select>
                        <small className = 'error'>{props.Errors.level && props.Errors.level}</small>
                    </ FormControl>
                    <FormControl>
                        <TextField
                            required
                            id = "title"
                            name = "title"
                            label = "Job Title"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.title : '' }
                            onChange = { HandleOnChange }
                            error = { props.Errors.title && true }
                            helperText = { props.Errors.title }
                        />
                    </ FormControl>
                </Grid>               
            </Grid>
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
