import React from 'react';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { AccountEdit} from './AccountEdit.Component';
import { formatName } from '../../../common/common';

export const UserConfigComponent = (props) => {
    return (        
        <>
	        <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title="User Configurations"
                        columns={[
                            {title: 'Parameter',field: 'config'},
                            {title: 'Value',field: 'value'},
                            {title: 'Created By',field: 'created_by',render: rowData => ( formatName(rowData.cby_first_name, null, rowData.cby_last_name)) },
                            {title: 'Created On',field: 'created_on'},
                            {title: 'Modified By',field: 'modified_by',render: rowData => ( formatName(rowData.mby_first_name, null, rowData.mby_last_name))},
                            {title: 'Modified On',field: 'modified_on'},
                        ]}
                        actions={[
                            {
                            icon: 'add',
                            tooltip: 'Add Configuration',
                            isFreeAction: true,
                            onClick: (event, newData) => {props.HandleClickOpen()},
                            hidden: props.EditPermission ? false : true,
                        },
                        rowData => ({
                            icon: Edit,
                            tooltip: 'Edit',
                            onClick: (event, rowData) => { props.HandleClickOpen(rowData.id)},
                            hidden: props.EditPermission ? false : true,
                        }),
                        rowData => ({
                            icon: DeleteOutline, 
                            tooltip: 'Delete Configuration', 
                            onClick: (event, rowData) => { props.OnDelete(rowData.id)},
                            hidden: props.EditPermission ? false : true,
                        }),
                        ]}
                        data={props.UserConfig}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	        </div>
            { props.Open &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={props.isDelete ?  'Delete User Configuration' : (props.isEditable ? 'Edit User Configuration' : 'Add User Configuration')}
                OnSubmit={props.isDelete ? props.onDeleteSubmit : props.onSubmit}
                ButtonName={props.isDelete ? 'Ok' : 'Save'}
                ErrorMessage={props.errorMessage}
                Loading = {props.ButtonLoader}
                Content={
                    props.isDelete ?
                    'Do you want to delete this user configuration?'
                    :
                    <UserConfigEdit 
                        OnSubmit={props.onSubmit}
                        FormInputs={props.FormInputs} 
                        HandleOnChange={props.HandleOnChange} 
                        FormData={props.FormData}
                        Errors = {props.Errors}
                />
            }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }


        </>
    )
};
const UserConfigEdit = (p)=>{console.log(p);
    return(
        <form className="form-fullwidth" onSubmit={p.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                    <InputLabel htmlFor="account_provider" error={p.Errors.parameter && true} required >Parameter</InputLabel>
                    <Select
                        value={typeof p.FormData != 'undefined' && p.FormData.config}
                        onChange={p.HandleOnChange}
                        error={p.Errors.config && true}
                        inputProps={{
                            name: 'config',
                            id: 'config',
                        }}
                    >
                    { typeof p.FormInputs != 'undefined' && p.FormInputs.config_component.map((t, i) => 
                        <MenuItem value={t}>{t}</MenuItem>

                    )}
                    </Select>
                    <small className='error'>{p.Errors && p.Errors.config}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="value"
                            name="value"
                            label="Value"
                            error={p.Errors.value && true}
                            type="text"
                            value={typeof p.FormData != 'undefined' && p.FormData.value}
                            onChange={p.HandleOnChange}
                            helperText = {p.Errors.value}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
