import React from 'react';
import moment from 'moment';
import {DashboardComponent} from './Dashboard.Component';
import { httpServices } from '../../services/httpServices';
import { history } from '../../helpers/history';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';
import Cookies from 'universal-cookie';
import MixpanelManager from  '../../services/Analytics';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { setSelectedTabIndex } from '../../redux/store/reducers/tabs';
import { connect } from "react-redux";
import { flushSync } from 'react-dom';


const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const cookies = new Cookies();
 class DashboardContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            permission : false,
            showLoader : true,
            holidays : [],
            events : [],
            myActions : [],
            week_timeoff : [],
            previousPayperiod : {},
            timeEntryPermission : false,
            dataLoaded : false,
            workLocationDays : [],
            date_joined: '',
            relieved_date:'',
            timeentryPeriods : []
        }
        this.handleDateChange = this.handleDateChange.bind(this);
        this.getDashboardDetails = this.getDashboardDetails.bind(this);
        this.viewLink = this.viewLink.bind(this);
        this.viewTimeEntry = this.viewTimeEntry.bind(this);
        this.viewExpense = this.viewExpense.bind(this);
        this.reviewRequest = this.reviewRequest.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }

    componentDidMount = () => {
        this.MixpanelManager.trackEvent('Dashboard',{'module':'Dashboard','feature':'menu','action':'clicked'});
        this.getDashboardDetails(); 
    };

    getDashboardDetails = () => {
        let apiEndpoint = 'api/dashboard';
        return httpServices.post(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        permission : response.data.permission, 
                        myActions : response.data.my_actions,
                        week_timeoff : response.data.timeoff_requests_week,
                        previousPayperiod : response.data.prev_payperiod,
                        date_joined :response.data.user_date_joined,
                        relieved_date : response.data.relieved_date,
                        showLoader : false,
                        dataLoaded : true,
                        timeentryPeriods : response.data.timeentry_periods
                    });
                }else {
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleDateChange = (start,end) => { 
        let apiEndpoint = 'api/dashboard/get-calender-events';
        let data ={
            'start':start.getFullYear()+'-'+(start.getMonth()+1)+'-'+start.getDate(),
            'end':end.getFullYear()+'-'+(end.getMonth()+1)+'-'+end.getDate()
        };
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data.permission) {
                    flushSync(() => {
                        this.setState({
                            events : response.data.events,
                            holidays : response.data.holidays,
                            timeEntryPermission : response.data.timeentry_permission,
                            entryDates : response.data.entry_dates,
                            workLocationDays: response.data.work_location_days
                        });
                    });
                    response.data.events.forEach((event) => {
                        let type = event.title.trim();
                        let title = type.replaceAll(' ', '_');
                        let displayName = title.replaceAll('&', '_');
                        if (typeof event.id !== 'undefined') {
                            tippy('.event' + displayName, { content: 'Click to view ' + event.title + ' Request' });

                        }
                    })
                    tippy('.day-tooltip', { content: 'Add new time entry', });
                    tippy('.day-entry-tooltip', { content: 'Click to enter time' });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    viewLink  = (link)=> () => {
        this.MixpanelManager.trackEvent('Dashboard',{'module':'Dashboard','feature':'My Action Items','action':'Clicked on '+link});
        history.push({
            pathname:BASE_PATH+link,
                state:{
                    fromDashboard:true
                }
        });
    };
    
    viewTimeEntry = (date)=> {
        let timeEntryDate = moment(date);
        let doj = moment(this.state.date_joined);
        let relieved_date = false;
        if(this.state.relieved_date) {
            relieved_date = moment(this.state.relieved_date);
        }
        let view_timeentry_date = false;
        this.state.timeentryPeriods.forEach(period => {
            if (timeEntryDate.isBetween(period.start_date, period.end_date, 'date', '[]')) {
                view_timeentry_date = true;
            }
        });
        if (view_timeentry_date && this.state.timeEntryPermission) {
            this.MixpanelManager.trackEvent('Dashboard',{'module':'Dashboard',
                'feature':'calendar','action':'Clicked on dashboard calendar'});
            history.push({
                pathname:BASE_PATH+'/timesheet/time-entry',
                    state:{
                        date: timeEntryDate.format('YYYY-MM-DD')
                    }
            });
        }
    };
    
    viewExpense  = (id)=> () => {
        this.MixpanelManager.trackEvent('Dashboard',{'module':'Dashboard','feature':'My Action Items',
            'action':'Clicked on Expense review'});
        history.push(BASE_PATH+'/expenses/review/' + id );
    };
    
     reviewRequest = (request) => {
         let obj = { page: request, selected: 'review' }
         this.props.setTabIndexValue(obj);
         this.MixpanelManager.trackEvent('Dashboard', {
             'module': 'Dashboard', 'feature': 'My Action Items',
             'action': 'Clicked on ' + request + ' request review'
         });
         let dashboardFilter = {
            'timeoffRequestsStartDate' : this.state.myActions.timeoff_requests_start_date,
            'timeoffRequestsEndDate' : this.state.myActions.timeoff_requests_end_date,
            'overtimeRequestsStartDate' : this.state.myActions.overtime_requests_start_date,
            'overtimeRequestsEndDate' : this.state.myActions.overtime_requests_end_date,
            'offsiteRequestsStartDate' : this.state.myActions.offsite_requests_start_date,
            'offsiteRequestsEndDate' : this.state.myActions.offsite_requests_end_date,
        }    
            //this.state.myActions    
        history.push({
            pathname:BASE_PATH+'/requests/'+request,
                state:{
                    fromDashboard:true,
                    dashboardFilter: dashboardFilter
                }
        });
    };

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission)?
                        <DashboardComponent
                            handleDateChange = {this.handleDateChange}
                            events = { this.state.events }
                            myActions = {this.state.myActions}
                            weekTimeoff = {this.state.week_timeoff}
                            viewLink = {this.viewLink}
                            viewTimeEntry = {this.viewTimeEntry}
                            holidays = {this.state.holidays}
                            expenses = { this.state.expenses}
                            previousPayperiod = {this.state.previousPayperiod}
                            viewExpense = {this.viewExpense}
                            timeEntryPermission={this.state.timeEntryPermission}
                            reviewRequest={this.reviewRequest}
                            entryDates = {this.state.entryDates}
                            WorkLocationDays = {this.state.workLocationDays}
                            TimeentryPeriods = {this.state.timeentryPeriods}
                        />
                        : <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTabIndexValue: (obj) => dispatch(setSelectedTabIndex(obj)),
    }
}
const connectedDashboardContainer = connect(null,mapDispatchToProps)(DashboardContainer);
export { connectedDashboardContainer as DashboardContainer };