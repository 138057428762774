import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactHtmlParser from 'html-react-parser';
import { formatName } from '../../common/common';

import * as _R from 'ramda';

export const ClearanceConfirmation = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{ minHeight: '200px' }}>
      {props.ClearanceConfirmation ?
      <> 
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Clearance Options</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Modified By</TableCell>
              <TableCell>Modified On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {props.ClearanceConfirmation &&
            <>
            <TableRow>               
              <TableCell component="th" scope="row"> 
                  {props.ClearanceConfirmation && props.ClearanceConfirmation.clearance_level_option_names.map((item, index) => (
                      <React.Fragment key={index}>
                        {ReactHtmlParser(item)}
                      </React.Fragment>
                  ))}
              </TableCell>
              <TableCell component="th" scope="row"> {formatName(props.ClearanceConfirmation.created_first_name, null, props.ClearanceConfirmation.created_last_name)}</TableCell>
              <TableCell component="th" scope="row"> {props.ClearanceConfirmation.created_on}</TableCell>
              <TableCell component="th" scope="row"> {formatName(props.ClearanceConfirmation.modified_first_name, null, props.ClearanceConfirmation.modified_last_name)}</TableCell>
              <TableCell component="th" scope="row"> {props.ClearanceConfirmation.modified_on}</TableCell>
            </TableRow>
            </>
          }
          </TableBody>
        </Table>
      </>        
      :
      <>
        <Table size="small" aria-label="a dense table">
            <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row" colSpan="8">No Clearance Level Options for this period</TableCell>
                </TableRow>
            </TableBody>
        </Table>
      </>          
    }        
    </div>
  );
}