import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { BasicForm } from './BasicForm';
import { TBDForm } from './TBDForm';
import { StaffDetailsForm } from './StaffDetailsForm';
import { PreferencesForm } from './PreferencesForm';
import { LoginInformationForm } from'./LoginInformationForm';
import { Compensation } from'./CompensationComponents';
import { httpServices }  from '../../../services/httpServices';
import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomProgressBar } from "../../common/CustomProgressBar";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function getSteps(userType, includeCompensation) {
    if (parseInt(userType) === 1) {
        if( includeCompensation) {
            return ['Basic Details', 'Employment', 'Preferences', 'Compensation Components', 'Login Information'];
        }
        else {
            return ['Basic Details', 'Employment', 'Preferences', 'Login Information'];
        }
    }
    else if (parseInt(userType) === 2) {
        return ['Basic Details', 'Preferences', 'Login Information'];
    }
    else
    {
        return [];
    }
}

export const UserAddComponent = (props) => {
    const includeCompensation = parseInt(props.FormInputs.component_count) && ( typeof props.FormFields.staff_type == 'undefined' || props.FormFields.staff_type == 1);
    const steps = getSteps(props.UserTypeSelected, includeCompensation);
    const gridStyle = {
        margin: "15px 0px",
    };
    const handleNext = () => {
        let valid = props.HandleValidation(props.ActiveStep);
        if (valid) {
            let usr_type = parseInt(props.UserTypeSelected);
            if (((usr_type === 1) && ((!includeCompensation && props.ActiveStep === 3) || props.ActiveStep === 4)) || (props.ActiveStep === 2 && (usr_type === 2))) {
                props.HandleSubmit();
            }
            else {
                props.SetActiveStep(props.ActiveStep + 1);
            }
            if (props.ActiveStep === 2 && usr_type === 1) {
                props.GetSystemComponents();
            }
        }
    };
    const handleTbdSubmit = () => {
        let valid = props.HandleValidation(0);
        if (valid) {
            props.HandleSubmit();
        }
    }
    const isStepFailed = (step) => {
        if (props.TabValue) {
            if (props.TabValue.includes(step)) {
                return true;
            }
        }
    };
    const handleBack = () => {
        props.SetActiveStep(props.ActiveStep - 1);
    };
    const HandleFilterChange = (event, value) => {
        props.ClearErrors();
        props.HandleFilterChange(event, value);
        props.SetActiveStep(0);
    }    

        

    let userType = parseInt(props.UserTypeSelected);
    let showBasic = (userType === 2 && props.ActiveStep === 0) || (userType === 1 && props.ActiveStep === 0);
    let showPreferences = (userType === 2 && props.ActiveStep === 1) || (userType === 1 && props.ActiveStep === 2);
    let showCompensation = (userType === 1 && props.ActiveStep === 3 && includeCompensation);
    let showLogin = ((userType === 2 && props.ActiveStep === 2) || userType === 1 && ((!includeCompensation && props.ActiveStep === 3) || props.ActiveStep === 4));

    return (
        <>  
        <>
              <CustomProgressBar
                    loaderOpen={props.ShowLoader}
                />         
        </>
        <form className="form-fullwidth">
                    <Grid container spacing="3" style={gridStyle}>
                        <Grid item md={2}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="user_type">
                                    User Type
                                </InputLabel>
                                <Select
                                    value={
                                        typeof props.FilterFields.user_type !==
                                        "undefined"
                                            ? props.FilterFields.user_type
                                            : 1
                                    }
                                    onChange={HandleFilterChange}
                                    inputProps={{
                                        name: "user_type",
                                        id: "user_type",
                                    }}
                                >
                                    {typeof props.FilterInputs.user_types !=
                                        "undefined" &&
                                        props.FilterInputs.user_types !== "" &&
                                        props.FilterInputs.user_types.map(
                                            (t, i) => (
                                                (t.type != "TBD") ?
                                                <MenuItem
                                                    value={t.id}
                                                    key={"stst-" + i}
                                                >
                                                    {t.type}
                                                </MenuItem>
                                                :
                                                <></>
                                            )
                                        )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>

            <Grid container>
                <Grid md={10} lg={12}>
                    <Paper>
                     {(userType !== 3) &&
                         <>
                        <div className={'form-header'}>
                            <div className={'stepper-wrapper'}>
                                <Stepper activeStep={props.ActiveStep} alternativeLabel>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepFailed(index)) {
                                            labelProps.error = true;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>
                        </div>
                        <div className={'form-body'}>
                            <form className={"form-fullwidth"}>
                                {showBasic && (
                                    <BasicForm
                                        FormInputs = {props.FormInputs}
                                        UserTypeSelected = {props.UserTypeSelected}
                                        FormFields = {props.FormFields}
                                        HandleOnChange = {props.HandleOnChange}
                                        Errors = {props.Errors}
                                    />
                                )}

                                {parseInt(props.UserTypeSelected) === 1 && props.ActiveStep === 1 && (
                                    <StaffDetailsForm
                                        FormInputs = {props.FormInputs}
                                        StaffInputs = {props.StaffInputs}
                                        FormFields = {props.FormFields}
                                        HandleOnChange = {props.HandleOnChange}
                                        HandleTreeChange = {props.HandleTreeChange}
                                        Errors = {props.Errors}
                                    />
                                )
                                }

                                {showPreferences && (
                                    <PreferencesForm
                                        FormInputs = {props.FormInputs}
                                        StaffInputs = {props.StaffInputs}
                                        FormFields = {props.FormFields}
                                        HandleOnChange = {props.HandleOnChange}
                                    />
                                )}

                                {showCompensation && (
                                    <Compensation
                                        SystemComponents = {props.SystemComponents}
                                        HandleComponentCheckBoxClick = {props.HandleComponentCheckBoxClick}
                                        HandleSelectAllClick = {props.HandleSelectAllClick}
                                        SelectAllComponentsFlag = {props.SelectAllComponentsFlag}
                                        SelectedComponentsLength = {props.SelectedComponentsLength}
                                    />
                                )}

                                {showLogin && (
                                    <LoginInformationForm
                                        FormInputs = {props.FormInputs}
                                        StaffInputs = {props.StaffInputs}
                                        FormFields = {props.FormFields}
                                        HandleOnChange = {props.HandleOnChange}
                                        ValidatePassword = {props.ValidatePassword}
                                        ShowPassword = {props.ShowPassword}
                                        Errors = {props.Errors}
                                    />
                                )}
                            </form>
                        </div>
                        <div className={'form-footer'}>
                            <Button disabled={props.ActiveStep === 0} onClick={handleBack}>
                                Back
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {props.ActiveStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div></>
                     }
                   {(userType === 3) &&
                         <>
                            <div className={'form-body'}>
                            <form className={"form-fullwidth"}>
                                    <TBDForm
                                        FormInputs = {props.FormInputs}
                                        UserTypeSelected = {props.UserTypeSelected}
                                        FormFields = {props.FormFields}
                                        HandleOnChange = {props.HandleOnChange}
                                        HandleTbdSubmit = {handleTbdSubmit}
                                        Errors = {props.Errors}
                                    />
                            </form>
                        </div>
                           </>
                   }

                    </Paper>
                </Grid>
            </Grid>
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = 'User added successfully'
                handleClose = {props.SnackbarClose}
            />
        </>
    );
}
