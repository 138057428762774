export const StaffConstants = {
    FETCH_STAFF: 'FETCH_STAFF',
    FETCH_STAFF_SUCCESS: 'FETCH_STAFF_SUCCESS',
    FETCH_STAFF_FAILURE: 'FETCH_STAFF_FAILURE',

    FETCH_STAFF_BY_ID: 'FETCH_STAFF_BY_ID',
    FETCH_STAFF_DETAILS_BY_MODULE : 'FETCH_STAFF_DETAILS_BY_MODULE',
    FETCH_SERVICE_PERIOD: 'FETCH_SERVICE_PERIOD',
    ENTITY_GET_FAILED: 'ENTITY_GET_FAILED',
    GET_ENTITY: 'GET_ENTITY',
    ENTITY_UPDATE: 'ENTITY_UPDATE',
    ENTITY_UPDATE_FAILURE: 'ENTITY_UPDATE_FAILURE',
    UPDATE_STAFF_BY_MODULE: 'UPDATE_STAFF_BY_MODULE',
    STAFF_NO_PERMISSION: 'STAFF_NO_PERMISSION',

    // Entity/Module API Actions
    CONTACT: 'api/staff/contact/index',
    WORK: 'api/staff/work/index',
    PROJECT: 'api/staff/projectDetails',
    SALARY: 'api/compensation/index',
    BENEFITS: 'api/staff/benefit',
    //APPRAISAL: 'api/staff/appraisalDetails',
    APPRAISAL: 'api/staff/appraisal',
    HISTORY: 'api/staff/changehistory',
    FAMILY: 'api/staff/family/index',
    TIMESHEET: 'api/staff/timesheetDetails',
    TIMEOFF: 'api/timeoff/index',
    CONFIG: 'api/staff/config/index',
    NOTE: 'api/staff/note/noteDetails',
    FILES: 'api/staff/files/filesDetails',
    WORKPOLICY: 'api/staff/workpolicy/index',

    // Api Actions
    API_STAFF_LIST: "api/staff/index",
    API_STAFF_VIEW : 'api/staff/view',
    STAFF_VIEW_PAGE: 'staff/view',
    STAFF_INDEX_PAGE: '/staff',
    API_SERVICE_PERIOD: 'api/staff/work/service-period',
    API_GET_SERVICE_PERIOD: 'api/staff/work/get-service-period',
    API_SAVE_SERVICE_PERIOD: 'api/staff/work/save-service-period',
    ROLE_DETAILS_PAGE : 'roledetails',

    STAFF_TABS : {
        '0' : 'PERSONAL',
        '1' : 'CONTACT',
        '2' : 'WORK',
        '3' : 'PROJECT',
        '4' : 'SALARY',
        '5' : 'BENEFITS',
        '6' : 'TIMEOFF',
        '7' : 'APPRAISAL',
        '8' : 'FAMILY',
        '9' : 'TIMESHEET',
        '10' : 'CONFIG',
        '11' : 'NOTE',
        '12' : 'FILES',
    },

    spInputs : {
        'staff_type' : '',
        'organization': null,
        'start_date': null,
        'end_date': null,
        'category': 'hourly',
        'overtime': null,
        'daily_max_limit':null,
        'daily_minimum': null,
        'weekly_limit': null,
        'include_holidays': null
    }
};
