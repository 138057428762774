import React from 'react';
import {LocationViewComponent} from './LocationView.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as _R from 'ramda';

const formFields = {
    'comment': '',
};

export class LocationViewDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hasPermission : true,
            formFields : formFields,
            requests : '',
            is_reviewer : false,
            show_cor : false,
            id : 0,
            open : false,
            errors : {},
            snackbar : false,
            valid: false,
            success_message:null,
            error_message :null,
            action : null,
            commentsHistory :{},
            changeLog : {},
            timesheet : {},
            showLoader : true,
            selfEdit : false,
            dialogOpen : false,
            popup : '',
            snackbarMsg : '',
            snackbarMsgType : 'success',
            loading : false,
            height : 0,
        }
       this.requestAction = this.requestAction.bind(this);
       this.addCommentClick = this.addCommentClick.bind(this);
       this.onClose = this.onClose.bind(this);
       this.handleOnChange = this.handleOnChange.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.snackbarClose = this.snackbarClose.bind(this);
       this.editClick = this.editClick.bind(this);
       this.updateParentOnEditSuccess = this.updateParentOnEditSuccess.bind(this);
    }

    componentDidMount = () => {
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id,
        });
        this.getViewData(id);
        const height = document.getElementById('fixed-header-id').clientHeight;
         this.setState({
            height : height,
        });
     }
        handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    editClick = () => {
        this.setState({
            popup : 'edit',
            dialogOpen : true,
        });
    }

     addCommentClick = (action) => {
        const ff = this.state.formFields;
        ff['comment'] = '';
        this.setState({
            open : true,
            action : action,
            formFields : ff,
        });
    }
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    
    snackbarClose = () => {
        this.setState({
            snackbar : false,
            valid: false,
        });
    }


    getViewData = (id) => {
        let apiEndpoint = 'api/location/view';
        return httpServices.post(apiEndpoint,{'id': id})
            .then(response => {
             if (response.data && response.data.permission) {
                         this.setState ({
                            requests : response.data,
                            commentsHistory : response.data.history,
                            is_reviewer : response.data.is_reviewer,
                            show_cor : response.data.show_cor,
                            changeLog : response.data.change_history,
                            timesheet : response.data.timesheet,
                            showLoader : false,
                            selfEdit : response.data.self_edit,
                           });
             }
            else 
                {
                  this.setState({
                        hasPermission : false,
                        showLoader : false,
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    requestAction = (id, action, comment = '') =>{
        let apiEndpoint = 'api/location/review';
        return httpServices.post(apiEndpoint,{'request_id': id, 'action': action, 'comment':comment})
            .then(response => {
                if (response.data.success) {
                         this.setState ({
                             open :false,
                             snackbar:true,
                             valid: true,
                             loading : false,
                             success_message: response.data.success,
                           });
                     this.getViewData(id);
                }
                else {
                    let snackbar = true;
                    if (response.data.errors) {
                        snackbar = false;
                    }
                    let popup_open = response.data.errors && response.data.errors.comment ? true : false;
                    this.setState({
                        flag : true,
                        open : popup_open,
                        snackbar : snackbar,
                        valid : false,
                        error_message : response.data.error_message,
                        loading : false,
                        errors: response.data.errors,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    
   handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            this.setState({loading : true});
            if (data) {
                this.requestAction(this.state.id, this.state.action, this.state.formFields.comment)
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.action !== 'cancel' && this.state.action !== 'approve') {
            if (!this.state.formFields.comment || _R.length(this.state.formFields.comment.trim()) === 0) {
                errors["comment"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    updateParentOnEditSuccess = (status, attachmentMsg = '') => {
        if ( status ) {
            let msg = 'Request Updated Successfully';
            if (attachmentMsg != '') {
                msg = msg+". "+attachmentMsg;
            }
            this.setState({
                snackbar:true,
                valid: true,
                success_message: msg,
                dialogOpen : false,
                popup : '',
            }, function () {
                this.getViewData(this.state.id);
            });
        } else {
            this.setState({popup : ''});
        }
    }


    render(){
        return(
            <>
            <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
            {(this.state.hasPermission)
            ?
            <LocationViewComponent
              Request = {this.state.requests}
              Height = {this.state.height}
              ID = {this.state.id}
              IsReviewer = {this.state.is_reviewer}
              ShowCor = {this.state.show_cor}
              Open = {this.state.open}
              Comment = {this.state.comment}
              RequestAction = {this.requestAction}
              AddCommentClick = {this.addCommentClick}
              HandleSubmit = {this.handleSubmit}
              OnClose = {this.onClose}
              FormFields = {this.state.formFields}
              HandleOnChange = {this.handleOnChange}
              Errors = {this.state.errors}
              Valid = {this.state.valid}
              ErrorMessage = {this.state.error_message}
              SuccessMessage = {this.state.success_message}
              SnackbarClose = {this.snackbarClose}
              Snackbar = {this.state.snackbar}
              Action = {this.state.action}
              CommentsHistory = {this.state.commentsHistory}
              ChangeLog = {this.state.changeLog}
              Timesheet = {this.state.timesheet}
              SelfEdit = {this.state.selfEdit}
              EditClick = {this.editClick}
              Popup = {this.state.popup}
              DialogOpen = {this.state.dialogOpen}
              UpdateParentOnEditSuccess = {this.updateParentOnEditSuccess}
              Loading = {this.state.loading}
            />
            :
            <ErrorPage Message={'You have no permission to view WLC request details'} />
            }
            </>
        )
    }
}
