import React from 'react';
import { withStyles } from '@mui/styles';
import {CustomMaterialTable, CustomMTableToolbar} from '../common/CustomMaterialTable';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { ExportCsv } from "@material-table/exporters";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    }
});

//Filters
const SideList = withStyles(styles)(p => {
    return (<div role="presentation" className={p.classes.drawerList}>
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
	    <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl className={p.classes.drawerFormControl} variant="standard">
            <InputLabel htmlFor="age-simple">Status</InputLabel>
            <Select
                inputProps={{
                    name: 'status',
                    id: 'project_status',
                }}
                value = {!_R.isNil(p.FilterFields.status) && p.FilterFields.status }
                onChange = {p.HandleFilterChange}
            >
                <MenuItem value={0} key={'st-0'}>All</MenuItem>
                {_R.type(p.Statuses !== 'Undefind') && p.Statuses.map((s, i) =>
                    <MenuItem value={s.user_status_id} key={'st-'+i}>{s.user_status_name}</MenuItem>
                )}
            </Select>
	    </FormControl>
	    </Grid>
	    <Grid item xs={9}>
	    <FormControl className={p.classes.drawerFormControl} variant="standard">
	    <InputLabel htmlFor="age-simple">Staff Type</InputLabel>
	    <Select
                inputProps={{
                    name: 'staff_types',
                    id: 'staff_types',
                }}
                value = {!_R.isNil(p.FilterFields.staff_types) && p.FilterFields.staff_types}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value={0} key={'st-0'}>All</MenuItem>
	    {_R.type(p.StaffTypes !== 'Undefind') && p.StaffTypes.map((s, i) =>
                    <MenuItem value={s.id} key={'st-'+i}>{s.type}</MenuItem>
            )}
            </Select>
            </FormControl>
	    </Grid>
        <Grid item xs={9}>
        <FormControl className={p.classes.drawerFormControl} variant="standard">
        <InputLabel htmlFor="age-simple">Staff Name Format</InputLabel>
        <Select
                inputProps={{
                    name: 'name_format',
                    id: 'name_format',
                }}
                value = {!_R.isNil(p.FilterFields.name_format) && p.FilterFields.name_format}
                onChange = {p.HandleFilterChange}
            >
        {_R.type(p.NameFormats !== 'Undefind') && p.NameFormats.map((s, i) =>
                    <MenuItem value={s.key}>{s.value}</MenuItem>
            )}
            </Select>
            </FormControl>
        </Grid>
	    </Grid>
        <Button variant="contained" color="primary" onClick={p.HandleFilter} className={p.classes.filterSubmitButton}>Filter</Button>
    </div>
    );
});

function sort(a,b) {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
}
export const StaffList  = withStyles(styles)(props => {
    const columns = [
        { title: 'Full Name', field: 'full_name', defaultSort: 'asc', removable: false, },
        props.DisplayStatus ? { title: 'Employee Code', field: 'employee_id', customSort:(a,b) => sort(parseInt(a.employee_id),parseInt(b.employee_id))} : { title: 'Staff ID', field: 'staff_id', customSort:(a,b) => sort(parseInt(a.staff_id),parseInt(b.staff_id))},
        { title: 'Email', field: 'user_email', hidden: true},
        { title: 'Date Joined', field: 'date_joined',customSort:(a,b) => sort(new Date(a.date_joined),new Date(b.date_joined))},
        { title: 'Staff Type', field: 'staff_type' },
        { title: 'Category', field: 'emp_category' },
        { title: 'Status', field: 'status_text'},
        { title: 'Relieved Date', field: 'relieved_date', hidden: true, customSort:(a,b) => sort(new Date(a.relieved_date),new Date(b.relieved_date))},
        { title: 'Title', field: 'designation_title'},
        { title: 'Job Title', field: 'job_title', hidden: true},
        { title: 'Project Role', field: 'project_title', hidden: true},
        { title: 'Overtime', field: 'overtime', hidden: true},
        { title: 'Main Project', field: 'main_project', hidden: true},
        { title: 'Supervisor', field: 'supervisor', hidden: true },
        { title: 'Leadership Program', field: 'leadership_program', hidden: true},
        { title: 'Expert', field: 'expert', hidden: true},
    ];

    const { classes, tableData, toggleDrawer } = props;
    let dataArr = [];
    if (typeof tableData !== 'undefined') {
        dataArr = Object.values(tableData);
    }
    let statusArr = (_R.type(props.Statuses) !== 'Undefined')?Object.values(props.Statuses):[];
    let staff_typesArr = (_R.type(props.staff_types) !== 'Undefined')?Object.values(props.staff_types):[];	
    let NameFormat = (_R.type(props.NameFormat) !== 'Undefined')?Object.values(props.NameFormat):[];
    return (
        <React.Fragment>
                <CustomMaterialTable
                    title= "Staff List"
                    icons={tableIcons}
                    columns={columns}
                    data={dataArr}
                    actions={[
                        {icon: tableIcons.Edit, tooltip: 'Edit',},
                    ]}
                    options={{
                        exportMenu: [ {
                            label: 'Export',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Stafflist')
                          }],
                        actionsColumnIndex: -1,
                        columnsButton:true,
                        toolbar:true,
                        exportButton: true,
                        thirdSortClick: false,
                        search : true,
                    }} 
                detailPanel={[
                    {
                        render: ({ rowData }) => {
                            return (
                                <div class="staff_tabel-detail-panel">
                                    {props.DisplayStatus && <div class="staff_tabel-detail-item"><label>Staff ID:</label><span class="value">{rowData.staff_id}</span></div>}
                                    <div class="staff_tabel-detail-item"><label>Email:</label><span class="value">{rowData.user_email}</span></div>
                                    <div class="staff_tabel-detail-item"><label>Client Email Address:</label><span class="value">{rowData.client_email_address}</span></div>
                                    <div class="staff_tabel-detail-item"><label>Start Date of Current Title:</label><span class="value">{rowData.desg_start_date}</span></div>
                                    {rowData.relieved_date && <div class="staff_tabel-detail-item"><label>Relieved Date:</label><span class="value">{rowData.relieved_date}</span></div>}
                                </div>
                            )
                        }
                    }
                ]}
                    components={{
                        Toolbar: props => (
                            <div style={{ position: 'relative', paddingRight:'50px' }}>
                                <CustomMTableToolbar {...props} />
                                <Tooltip title="Filter" aria-label="Filter">
                                    <IconButton onClick={toggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                                </Tooltip>
                            </div>
                        )
                    }}
                    onRowClick={(event, rowData) => props.handleViewDetail(rowData.user_id, rowData.staff_id)}
                />
                  <Drawer 
                        anchor="right" 
                        open={props.Right} 
                        onOpen={props.toggleDrawer('right', true)}
                        onClose={props.toggleDrawer('right', false)}>
                        <SideList Right={'right'} Statuses={statusArr}  StaffTypes={staff_typesArr} NameFormats={NameFormat} FilterFields={props.FilterFields} HandleFilterChange={props.HandleFilterChange} HandleFilter={props.HandleFilter} />
                </Drawer> 
            </React.Fragment>
   )
});
