import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText'

const DialogContent = (p) => {
    const {FormProps} = p;
    return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                   <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="bank_name"
                                name="bank_name"
                                label="Bank Name"
                                type="text"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.bank_name && true}
                                value={FormProps.FormFields.bank_name}
                                helperText={FormProps.Errors.bank_name}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="pay_allocation_percent"
                                name="pay_allocation_percent"
                                label="Pay Allocation Percent"
                                type="text"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.pay_allocation_percent && true}
                                value={FormProps.FormFields.pay_allocation_percent}
                                helperText={FormProps.Errors.pay_allocation_percent}
                            />
                        </ FormControl>
                    </Grid>
                   <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="routing_num"
                                name="routing_num"
                                label="Routing Number"
                                type="text"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.routing_num && true}
                                value={FormProps.FormFields.routing_num}
                                helperText={FormProps.Errors.routing_num}
                            />
                        </ FormControl>
                    </Grid>
                   <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="account_num"
                                name="account_num"
                                label="Account Number"
                                type="text"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.account_num && true}
                                value={FormProps.FormFields.account_num}
                                helperText={FormProps.Errors.account_num}
                            />
                        </ FormControl>
                    </Grid>

                   <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="account_type" required error={FormProps.Errors.account_type && true} >Account Type</InputLabel>
                            <Select
                                value={_R.type(FormProps.FormFields) !== 'Undefined' && FormProps.FormFields.account_type}
                                onChange={FormProps.HandleOnChange}
                                inputProps={{
                                    name: 'account_type',
                                    id: 'account_type',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(FormProps.AccountType) !== 'Undefined' && FormProps.AccountType.map((t, i) =>
                                   <MenuItem value={t.id} key={'type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {FormProps.Errors.account_type && <FormHelperText className = 'error'>{FormProps.Errors.account_type}</FormHelperText>}
                        </ FormControl>
                    </Grid> 
                </Grid>
            </form>
      )
};

export const BankInfoDetails = (props) => {
    const {BankInfo} = props;
    return (
        <>
             <React.Fragment>
                <div  className={'mtable-basic'}>
                <CustomMaterialTable
                    title= "Bank Information"
                    columns={[
                            { title: 'Bank Name', field: 'bank_name' },
                            { title: 'Net Pay Allocation', field: 'pay_allocation_percent' },
                            { title: 'Routing #', field: 'routing_num' },
                            { title: 'Account#', field: 'account_num' },
                            { title: 'Type', field: 'acc_type' },
                    ]}
                    data={BankInfo}
                    options={{
                        search:false,
                        paging:false,
                        thirdSortClick:false,
                        actionsColumnIndex: -1,
                        toolbar:true,
                    }}
                    actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Bank Details',
                                isFreeAction: true,
                                onClick: (event, newData) => {
                                    props.AddBankDetails();
                                },
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.Update(rowData.id);
                                },
                            },
                        ]}
                        editable={{
                        onRowDelete: rowData =>
                            new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                const index = BankInfo.indexOf(rowData);
                                props.OnDelete(index, rowData.id);
                            }
                            resolve()
                            }, 500)
                        }),
                    }}
                />
            </div>
              <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {'Add Bank Details'}
                    OnSubmit={props.SaveBankDetails}
                    Content={<DialogContent FormProps={props}/>}
                    />
            </React.Fragment>
        </>
    )
};
