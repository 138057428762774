import React from 'react';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';

import { ExpenseCodeContainer }  from './ExpenseCode.Container.js';
import { ExpenseProjectContainer }  from './ExpenseProject.Container.js';

import { MileageContainer } from './Mileage.Container.js';
import { ExpenseLimitContainer }  from './ExpenseLimit.Container.js';

export const ExpenseSettingsComponent = (props) => {
    //Tabs
    const [tabValue, setTabValue] =  React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const projectTabId = props.ExpenseCodeView ? 1 : 0;
    const mileageTabId = props.ExpenseProjectView ? projectTabId+1: projectTabId;
    const limitTabId = props.MileageView ? mileageTabId+1: mileageTabId;
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-body'}>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                indicatorColor="primary"
                                aria-label="Expense Settings Tabs"
                            >
                                { props.ExpenseCodeView &&
                                    <Tab label="Expense Codes"/> }
                                { props.ExpenseProjectView &&
                                    <Tab label="Expense Projects"/> }
                                { props.MileageView &&
                                    <Tab label="Mileage"/> }
                                { props.ExpenseLimitView &&
                                    <Tab label="Expense Limit"/> }
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            {props.ExpenseCodeView &&
                            <TabPanel value={tabValue} index={0}>
                                {tabValue === 0 &&
                                <ExpenseCodeContainer/>
                                }
                            </TabPanel>
                            }
                            {props.ExpenseProjectView &&
                            <TabPanel value={tabValue} index={projectTabId}>
                            {tabValue === projectTabId &&
                                <ExpenseProjectContainer/>
                                }
                            </TabPanel>
                            }
                            {props.MileageView &&
                            <TabPanel value={tabValue} index={mileageTabId}>
                                {tabValue === mileageTabId &&
                                    <MileageContainer/>
                                }
                            </TabPanel>
                            }
                            {props.ExpenseLimitView &&
                            <TabPanel value={tabValue} index={limitTabId}>
                                {tabValue === limitTabId &&
                                    <ExpenseLimitContainer />
                                }
                            </TabPanel>
                            }
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>    
    )
};

