import React from 'react';

import { httpServices } from '../../../../services/httpServices';
import { PhotoComponent } from "./Photo.Component";
import MixpanelManager from  '../../../../services/Analytics';

const formFields = {
    'id':0,
};

export class PhotoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photos: [],
            setasdefault: true,
            dialogOpen: false,
            dialogWarningOpen: false,
            dialogDeleteWarning: false,
            file: null,
            errors: {},
            snackOpen: false,
            snackVarient: '',
            snackMessage: '',
            fileOpen: '',
            defaultId: 0,
            buttonLoader: false,
            deleteId: 0
        };
        this.handleDefaultClick = this.handleDefaultClick.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.snakbarClose = this.snakbarClose.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSetDefaultPhoto = this.handleSetDefaultPhoto.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }

    onAdd = () => {
        if (this.state.photos.length < 1 && this.props.photos) {
            this.setState({ photos: this.props.photos });
        }
        let formFields = {
            'id': 0,
            'disability_type': null,
            'start_date': null,
            'end_date': null,
        };
        this.setState({
            dialogOpen: true,
            formFields: formFields,
        });
    };

    onClose = () => {
        this.setState({
            dialogOpen: false,
            file: null,
            errors: {},
            dialogWarningOpen: false,
            dialogDeleteWarning: false,
        });
    };
    handleDefaultClick = (event) => {
        let value = event.target.checked;
        this.setState({
            setasdefault: value
        })
    }
    snakbarClose = (id) => {
        this.setState({
            snackOpen: false
        });
    }
    handleOnChange = (evt) => {
        let sfile = evt.target.files[0];
        this.setState({ file: sfile });
    };
    handleViewClick = (url) => {
        this.setState({
            fileOpen: true,
            fileUrl: url
        });
    };
    filterView = (anchor, open) => (event) => {
        this.setState({ fileOpen: open });
    };
    handleOptionChange = (val) => {
        this.setState({
            dialogWarningOpen: true,
            defaultId: val
        })
    }
    handleSetDefaultPhoto = () => {
        httpServices.post('api/staff/personal/updateStaffDefaultPhoto', { 'id': this.state.defaultId, 'staffId': this.props.staffId })
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        photos: response.data.photos,
                        dialogWarningOpen: false,
                    }, () => { this.props.updateChange() });
                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-photo', 'action' : 'update-default-photo'});
                }
            })
            .catch(function (error) {
                console.log("error" + error);
            });
    }
    getPhotos = () => {
        httpServices.post('api/staff/personal/getStaffPhotos', { 'staffId': this.props.staffId })
            .then(response => {
                if (response.data) {
                    this.setState({
                        photos: response.data.photos,
                    })
                }
            })
            .catch(function (error) {
                console.log("error" + error);
            });
    };
    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let file = this.state.file;
            let defaultPhoto = this.state.setasdefault;
            let postData = {
                file: file,
                staffId: this.props.staffId,
                defaultPhoto: defaultPhoto
            };
            if (postData) {
                this.setState({
                    buttonLoader: true,
                })
                httpServices.post('api/staff/personal/savePhotoDetails', postData)
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.success) {
                                this.setState({
                                    dialogOpen: false,
                                    snackOpen: true,
                                    snackVarient: "success",
                                    snackMessage: "Photo added successfully",
                                    file: null,
                                    buttonLoader: false,
                                }, () => { this.props.updateChange() });
                                this.getPhotos();
                                this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-photo', 'action' : 'save-staff-photo'});
                            } else {
                                this.setState({
                                    snackOpen: true,
                                    snackVarient: "error",
                                    snackMessage: response.data.error,
                                    buttonLoader: false,
                                })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleDelete = (val) => {
        this.setState({
            dialogDeleteWarning: true,
            deleteId: val
        })
    }
    onDeleteSubmit = () => {
        httpServices.post('api/staff/personal/deletephotos', { 'staff_id': this.props.staffId, 'id': this.state.deleteId })
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        dialogOpen: false,
                        snackOpen: true,
                        snackVarient: "success",
                        snackMessage: "Photo deleted successfully",
                        dialogDeleteWarning: false
                    })
                    this.getPhotos();
                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-photo', 'action' : 'delete-staff-photo'});
                }
            })
            .catch(function (error) {
                console.log("error" + error);
            });
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.file) {
            errors["file"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        return (
            <PhotoComponent
                Photos={this.state.photos.length > 0 ? this.state.photos : this.props.photos}
                DialogOpen={this.state.dialogOpen}
                DialogWarningOpen={this.state.dialogWarningOpen}
                onAdd={this.onAdd}
                FormFields={this.state.formFields}
                errors={this.state.errors}
                HandleOnChange={this.handleOnChange}
                onClose={this.onClose}
                FormInputs={this.props.formInputs}
                OnSubmit={this.handleSubmit}
                staffId={this.props.staffId}
                EditPermission={this.props.Edit}
                snakbarClose={this.snakbarClose}
                snackMessage={this.state.snackMessage}
                snackOpen={this.state.snackOpen}
                snackVarient={this.state.snackVarient}
                HandleViewClick={this.handleViewClick}
                FilterView={this.filterView}
                FileOpen={this.state.fileOpen}
                FileUrl={this.state.fileUrl}
                HandleOptionChange={this.handleOptionChange}
                HandleSetDefaultPhoto={this.handleSetDefaultPhoto}
                OnDeleteSubmit={this.onDeleteSubmit}
                ButtonLoader={this.state.buttonLoader}
                DeleteId={this.state.deleteId}
                HandleDelete={this.handleDelete}
                DialogDeleteWarning={this.state.dialogDeleteWarning}
                HandleDefaultClick={this.handleDefaultClick}
                setasdefault={this.state.setasdefault}
            />
        )
    }
}
