import React,  { Component } from 'react';
import { withStyles } from '@mui/styles';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import *as _R from 'ramda';
import { StaffConstants} from '../../../../constants/';
import { httpServices } from '../../../../services/httpServices';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { formatName } from '../../../common/common';

const styles = theme => ({
    test:{
        color:'red'
    },
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterSubmitButton:{
        marginTop:'20px'
    }
});
const columns = [
    { title: 'Project Name', field: 'project_name' },
    { title: 'Charge Code', field: 'project_code', removable: false},
    { title: 'Client', field: 'client_name'},
    { title: 'Project Manager', field: 'project_manager_name', removable: false, render: rowData => ( formatName(rowData.manager_first_name, null, rowData.manager_last_name))},
    { title: 'Labor Category', field: 'project_labor_category' },
    { 
        title: 'Status', 
        field: 'project_status_name', 
    }
];
const formFields = {
    'project_status': '1',
};
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

// This class component is depends Data property
export class ProjectsContainer extends Component{
    // This is for future purpose
    constructor(props) {
        super(props);
        this.state = {
            ProjectData : [],
            selectedData : {},
        };
    }

    componentDidMount() {
        this.getProjectList();
    } 


    getProjectList = () => {
        const {UserId} = this.props;
        let apiEndpoint = 'api/user/get-project-list';
        return httpServices.post(apiEndpoint, { 'id': UserId })
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedData : response.data,
                        right :false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            }); 
    }

    render(){
        return(
        <ProjectComponent 
            ProjectData = {(_R.not(_R.isEmpty(this.state.selectedData))) ? this.state.selectedData : this.props.ProjectData}
        />
        )
    }
}

const ProjectComponent = withStyles(styles)(props => {
    const dataArr = (_R.type(props.ProjectData) !== 'Undefined' && _R.type(props.ProjectData.projects) !== 'Undefined') ? props.ProjectData.projects :[];
    return (
        <>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title= "Project List"
                    icons={tableIcons}
                    columns={columns}
                    data={dataArr}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                    options={{
                        search:false,
                        pageSize:10,
                        pageSizeOptions: [10,20,30],
                    }}
                />
            </div>
        </>
    )
});
