export const validateFormElements = (formElements) => {
    let valid = true;
    let errors = {};
    formElements.forEach((element) => {
        let type = element.type;
        let value = element.value;
        let elemName = element.name;
        if (element.otherOptions.required && (!value || value === '')) {
            valid = false;
            errors[elemName] = 'Cannot be empty';
            return { valid: valid, errors: errors }
        }
        switch (type) {
            case 'alphanumeric':
                if (element.otherOptions.required && value.trim() === '') {
                    valid = false;
                    errors[elemName] = 'Cannot be empty';
                    return { valid: valid, errors: errors }
                }
                let regex = /^[a-zA-Z0-9 ]*$/
                let validInput = regex.exec(value)
                if (!validInput) {
                    valid = false;
                    errors[elemName] = "Invalid input"
                }
                break;

            case 'text':
                if (element.otherOptions.required && value.trim() === '') {
                    valid = false;
                    errors[elemName] = 'Cannot be empty';
                    return { valid: valid, errors: errors }
                }
                let reg = /^(?=[a-zA-Z0-9~@#&'-^*()_/[\]{}|\\,.?: - ]*$)(?!.*[<>])/;
                let validData = reg.exec(value);

                if (!validData) {
                    valid = false;
                    errors[elemName] = "Unsupported special character"
                } else if (validData) {
                    let regex = /[a-zA-Z0-9 ]/;
                    let validInput = regex.test(value);
                    if (!validInput) {
                        valid = false;
                        errors[elemName] = "Should contain atleast one character"
                    }
                }
                break;

            case 'textarea':
                if (element.otherOptions.required && value.trim() === '') {
                    valid = false;
                    errors[elemName] = 'Cannot be empty';
                    return { valid: valid, errors: errors }
                }
                if (value) {
                    let regValue = /<([a-zA-Z ])*>/;
                    let validValue = regValue.test(value);
                    if (validValue) {
                        valid = false;
                        errors[elemName] = "HTML tags not supported"
                    } else {
                        let regex = /[a-zA-Z0-9 ]/;
                        let validInput = regex.test(value);
                        if (!validInput) {
                            valid = false;
                            errors[elemName] = "Should contain atleast one character"
                        }
                    }
                }
                break;

            case 'hours':
                let val = value;
                let minimumInc = 0.25;
                let res = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
                let reg2 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
                let reg3 = new RegExp("^(\\.)[0-9]{1,2}");
                if (reg3.test(val)) {
                    val = '0' + val;
                }
                if (reg2.test(val)) {
                    let hm = val.split(':');
                    let fr = hm[1] / 60;
                    val = parseFloat(hm[0]) + parseFloat(fr.toFixed(2));
                }
                if (!(res.test(val) || reg3.test(val))) {
                    valid = false;
                    errors["hours"] = 'Hours is invalid';
                }
                else {
                    let min = minimumInc * 100;
                    let frp = val % 1;
                    frp = frp.toFixed(2) * 100;
                    if (!(frp % min === 0)) {
                        valid = false;
                        errors["hours"] = 'Hours should be entered as a multiple of ' + minimumInc + ' hours';
                    }

                }
                break;

            case 'integer':
                let regPattern = /^[0-9]*$/
                let validNumber = regPattern.exec(value);
                if (!validNumber) {
                    valid = false;
                    errors[elemName] = "Invalid number";
                }
                break;

            case 'email':
                let regexValue = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                let validEmail = regexValue.exec(value);
                if (!validEmail) {
                    valid = false;
                    errors[elemName] = "Invalid email";
                }
                break;

            case 'mobilenumber':
                let mobilePattern = /^(\+\d{1,2}[\s.-]?)\(?\d{3}\)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{4}$/;
                let validMobile = mobilePattern.exec(value);
                if (!validMobile) {
                    errors[elemName] = "Invalid mobile number";
                }
                break;

        }
    })
    return { valid: valid, errors: errors }

};
