import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import { formatName } from '../../common/common';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const ReviewFilterComponent = (props) => {
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear; i >=1970; i--){
        years.push(i);
    }
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Year</InputLabel>
                        <Select
                            inputProps={{
                            name: 'year',
                            id: 'year',
                        }}
                        value = {(!_R.isNil(props.FormFields.year) && props.FormFields.year)? props.FormFields.year: currentYear }
                        onChange = {props.HandleOnChange}
                        >
                        <MenuItem value ="0">All</MenuItem>
                        { years.map((y) =>
                            <MenuItem value={y}>{y}</MenuItem>
                        )}
                        </Select>
                    </ FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Month</InputLabel>
                        <Select
                            inputProps={{
                            name: 'month',
                            id: 'month',
                        }}
                        value = {props.FormFields.month }
                        onChange = {props.HandleOnChange}
                        >
                        <MenuItem value ="0">All</MenuItem>
                        { months.map((m) =>
                            <MenuItem value={m}>{m}</MenuItem>
                        )}
                        </Select>
                    </ FormControl>
                </Grid>
              </Grid>
            <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl variant="standard">
            <InputLabel htmlFor="age-simple">Status</InputLabel>
            <Select
                inputProps={{
                    name: 'voucherStatus',
                    id: 'voucherStatus',
                }}
               value = {props.FormFields.voucherStatus}
               onChange = {props.HandleOnChange}
            >
            <MenuItem value="0">All</MenuItem>
            <MenuItem value="-1">To be Reviewed</MenuItem>
            {typeof(props.VoucherStatus) !== 'undefined' && (props.VoucherStatus != null)  && props.VoucherStatus.map((t, i) =>
                    <MenuItem value={t.id} key={'c-'+i}>{t.description}</MenuItem>
            )} 
            </Select>
        </FormControl>
        </Grid>
        </Grid>
                <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Staff Name</InputLabel>
                        <Select
                            inputProps={{
                            name: 'employee',
                            id: 'employee',
                        }}
                        value = {!_R.isNil(props.FormFields.employee) && props.FormFields.employee}
                        onChange = {props.HandleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
           {typeof(props.StaffNameList) !== 'undefined' && (props.StaffNameList != null)  && props.StaffNameList.map((n, i) =>
                           <MenuItem value={n.user_id} key={'n-'+i}>{formatName(n.user_first_name, null, n.user_last_name)}</MenuItem>
                        )} 
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>

        <Grid container spacing={2}>
            <Grid item xs={9}>
            <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker 
                    clearable
                    autoOk
                    format="MM/dd/yyyy"
                    views={["year", "month", "day"]}
                    variant="inline"
	            placeholder="mm/dd/yyyy"
                    id="paymentDate"
                    name="paymentDate"
                    type="text"
                    label="Payment Date"
                    onChange={props.handleDateChange}
                    value = {(typeof props.FormFields.paymentDateObj !== 'undefined') ? props.FormFields.paymentDateObj: null}
                    InputLabelProps = {{
                        shrink: true,
                    }} 
                />
            </LocalizationProvider>

            </FormControl>
            </Grid>
            </Grid>
        <Button variant="contained" color="primary" onClick={props.HandleOnSubmit}> Submit </Button>
        </form>
    )
}
