import React from 'react';
import { FilesComponent } from './Files.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';
import { ErrorPage } from '../../../common/error';

const formFields = {
    'id': 0,
    'staff_id':null,
    'sharepoint_url' : null,
    'description' : null,
    'created_by':null,
    'created_on':null,
    'modified_by':null,
    'modified_on':null,
}
export class SharepointFiles extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            formFields:formFields,
            errors: {},
            title: 'SharePoint Files',
            files:[],
            Open:false,
            isEdit:false,
            isDelete: false,
            updated:false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    onAddFiles = () => {
        this.setState({Open: true,
                        isEdit: false});
    }
     onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
    }

    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    submit =() => {
        if (this.handleValidation()) {
            this.saveFiles();
        }
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.sharepoint_url) {
            errors["sharepoint_url"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    saveFiles = () => {
        let data = this.state.formFields;
        const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        data.staff_id = staffID;
        data.user_id = userID;
        if(this.handleValidation()){
            httpServices.post('api/staff/files/save-files-url', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({files:response.data.files});
                            this.setState({Open:false});
                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
          })
       .catch(function(error) {
            console.log("Error: "+error);
            alert("Something went wrong. Please try again later");
        });
        }
    }
    onUpdate = (id) => {
        return httpServices.post('api/staff/files/get-files-details', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        Open: true,
                        id: id,
                        isEdit: true,
                        formFields: response.data.selected_files[0],
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    render(){
        return(
            (this.props.FilesData.permission)
            ?
            <FilesComponent
            files ={this.state.files.length>0 ? this.state.files:(_R.type(this.props.FilesData) !== 'Undefined')? this.props.FilesData.files:undefined}
            title ={this.state.title}
            IsEdit={this.state.isEdit}
            AddFiles={this.onAddFiles.bind(this)}
            Open={this.state.Open}
            Close={this.onClose.bind(this)}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields}
            SaveFiles={this.submit.bind(this)}
            Update={this.onUpdate.bind(this)}
            Errors={this.state.errors}
            />
            :
            <ErrorPage/>
        )
    }
}
