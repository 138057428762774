import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DialogBoxMedium } from '../../common/dialog.medium';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';


const AddLocation = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                        <InputLabel htmlFor="Location" required >Work Location</InputLabel>
                            <Select
                                value={0}
                                disabled={true}
                                inputProps={{
                                    name: 'project_location',
                                    id: 'project_location',
                                }}>                               
                                <MenuItem value={0} >{(typeof props.FormValues.location_name !== 'undefined') ? props.FormValues.location_name : ''}</MenuItem>                               
                            </Select>                           
                        </ FormControl>
                    </Grid>                    
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(typeof props.FormValues.default !== 'undefined') && props.FormValues.default === 1 ? true : false}
                                        onChange={props.HandleOnChange}
                                        color="primary"
                                        inputProps={{
                                            name: 'default',
                                            id: 'default',
                                        }}
                                    />
                                }
                                label="Set as default"
                            />
                        </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked = {(typeof props.FormValues.timesheet !== 'undefined') && props.FormValues.timesheet === 1 ? true : false}
                                    onChange={props.HandleOnChange}
                                    color="primary"
                                    inputProps={{
                                        name: 'timesheet',
                                        id: 'timesheet',
                                    }}
                                />
                            }
                            label="Timesheet"
                        />
                        {(typeof props.Errors !== 'undefined') && props.Errors &&
                                <FormHelperText className='error'>{props.Errors.timesheet}</FormHelperText>
                        }
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

export const WorkLocationComponent = (props) => {
    return (        
        <>
        <React.Fragment>
	        <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title=""
                        columns={[
                            { title: 'Location Name', field: 'location'},
                            { title: 'Timesheet', field: 'timesheet', render: rowData => <>{rowData.timesheet === 1 ? "Enabled" : "Disabled"} </> },
                            { title: 'Default', field: 'default', render: rowData => <>{rowData.default === 1 ? "Yes" : "No"} </> },
                            { title: 'Created By', field: 'created_by_username' },
                            { title: 'Created On', field: 'created_on' },
                            { title: 'Modified By', field: 'modified_by_username' },
                            { title: 'Modified On', field: 'modified_on' },
                            { title: 'Type', field: 'type'},
                            { title: 'Status', field: 'status'}


                        ]}
                        data={props.ProjectLocations}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                        }}
                        components={{
                            Toolbar: () => null,
                        }}
                        actions={[                            
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'Edit',
                                hidden: props.EditPermission  ? false : true,
                                onClick: (event, rowData) => {
                                    props.EditWorkLocation(rowData.location_id, rowData.location, rowData.default, rowData.timesheet);
                                },
                            }),
                            rowData => ({
                                icon: 'delete',
                                tooltip: 'Delete Location',
                                hidden: props.EditPermission && rowData.default !== 1 ? false : true,
                                onClick: (event, rowData) => {
                                    props.DeleteWorkLocation(rowData.location_id);
                                },
                            }),
                        ]}
                                            
                    />
	        </div>
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    ButtonName={props.Action === 'edit' ?  'Save' : 'Confirm'}
                    OnSubmit={props.OnConfirm}
                    Title={props.Action === 'edit' ? 'Edit Project Location':'Confirmation'}
                    Content={props.Action === 'edit' ? 
                        <AddLocation 
                            WorkLocations = {props.WorkLocations}
                            FormValues = {props.FormValues}
                            HandleOnChange = {props.HandleOnChange}
                            Errors = {props.Errors}
                        /> 
                        : props.ConfirmationMessage
                    }
                />
        </React.Fragment>
        </>
    )
};
