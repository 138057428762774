import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Disability } from './Disability.Container';
import { Beneficiary } from './Beneficiary.Container';
import {DialogBoxMedium} from '../../../common/dialog.medium';

export const BenefitComponent = (props) => {
    const { Data } = props;
    const {edit_permission:Edit, delete_permission:Delete} = Data;
    const dataArr = (_R.type(props.InsuranceData) !== 'Undefined') ? props.InsuranceData:[];
    const familyArr = (_R.type(Data) !== 'Undefined' && 'insured_family' in Data) ? Data.insured_family:[];
    const familyNames = (_R.type(Data) !== 'Undefined' && 'family_names' in Data) ? Data.family_names:[];
    const basicDetails = (_R.type(Data) !== 'Undefined' && 'benefit_config' in Data) ? Data.benefit_config:[];
    const disability_benefits = (_R.type(props.DisabilityData) !== 'Undefined') ? props.DisabilityData:[];
    const disability_types = (_R.type(Data) !== 'Undefined' && 'disability_types' in Data) ? Data.disability_types:[];
    const disability_ctc_components = (_R.type(Data) !== 'Undefined' && 'disability_ctc_components' in Data) ? Data.disability_ctc_components:[];
    const disability_deduction_components = (_R.type(Data) !== 'Undefined' && 'disability_deduction_components' in Data) ? Data.disability_deduction_components:[];
    const StaffID = (_R.type(props.StaffID) !== 'Undefined' && 'StaffID' in props) ? props.StaffID:0;
    const beneficiary_relationships = (_R.type(Data) !== 'Undefined' && 'beneficiary_relationships' in Data) ? Data.beneficiary_relationships:[];
    const beneficiary_details = (_R.type(Data) !== 'Undefined' && 'beneficiary_details' in Data) ? Data.beneficiary_details:[];
    const beneficiary_types = (_R.type(Data) !== 'Undefined' && 'beneficiary_types' in Data) ? Data.beneficiary_types:[];
    let table_size = 10; // Default
    let data_arr_length = dataArr.length ;
    if (data_arr_length < 10) {
        table_size = data_arr_length < 3 ? 2 : data_arr_length;
    }
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3} style={{position:"relative"}}>
                <Grid item xs={12} sm={6}>
                    <div className={'profile-view__basics_default'} style={{'marginBottom': '2rem'}}>
                            <Typography component="div" variant="button" color="primary" display="block" gutterBottom>Benefits Details</Typography>
                            <IconButton aria-label="delete" style={{'right':'0', 'top':'0', 'position':'absolute'}}>
                                <EditIcon onClick={()=>props.OnEditBasic()}/>
                            </IconButton>
                            <div className={'profile-view__basics_default-item item-role'}><label>FSA Effective Date</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['fsa_date']}</div>
                            <div className={'profile-view__basics_default-item item-role'}><label>HSA Effective Date</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['hsa_date']}</div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Commuter Benefits Effective Date</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['commuter_start_date']}</div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Disability Type</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['disability']}</div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Safeharbor Contribution</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['enable_safeharbor_contrib_name']}</div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Insurance Status</label> {!_R.isNil(basicDetails) && Object.keys(basicDetails) && basicDetails['insurance_status']}</div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title="Insurance"
                        columns={[
                            {
                                title: 'Insurance Type',
                                field: 'insurance_type',
                            },
                            {
                                title: 'Plan Type',
                                field: 'plan_type',
                            },
                            {
                                title: 'Start Date',
                                field: 'start_date',
                            },
                            {
                                title: 'End Date',
                                field: 'end_date',
                            },
                            {
                                title: 'Paycheck Start Date',
                                field: 'paycheck_start_date',
                            },
                            {
                                title: 'Paycheck End Date',
                                field: 'paycheck_end_date',
                            },
                            {
                                title: 'Coverage',
                                field: 'coverage',
                            },
                            
                            {
                                title:'Monthly Premium Employee',
                                field:'monthly_premium_employee',
                                
                            },
                            {
                                title:'Monthly Premium Family',
                                field:'monthly_premium_family',
                                
                            },
                            {
                                title:'Employer Contribution Employee(%)',
                                field:'employer_contrib_employee',
                                hidden:true,
                            },
                            {
                                title:'Employer Contribution Family(%)',
                                field:'employer_contrib_family',
                                hidden:true,
                            },
                            {
                                title:'Employee Contribution Employee(%)',
                                field:'employee_contrib_employee',
                                hidden:true,
                            },
                            {
                                title:'Employee Contribution Family(%)',
                                field:'employee_contrib_family',
                                hidden:true,
                            },
                            {
                                title:'Deduction Component',
                                field:'deduction_component',
                                hidden:true,
                            },
                            {
                                title:'CTC Component',
                                field:'ctc_component',
                                hidden:true,
                            },
                            
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                            columnsButton:true,
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Insurance Details',
                                isFreeAction: true,
                                onClick: (event) => { props.OnAdd() },
                                hidden: (Edit) ? false : true,
                            },
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                onClick: (event, rowData) => {
                                    props.OnAddFamily(rowData.id);
                                },
                                hidden: (Edit) ? false : true,
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                                hidden: (Edit) ? false : true,
                            },
                        ]}
                        editable={{
                            isDeletable: rowData => (Delete) ? true : false,
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                    {
                                        let idx = dataArr.findIndex(x => x.id === rowData.id);
                                        props.OnDelete(idx, rowData.id);
                                    }
                                    resolve()
                                    }, 500)
                            }),
                        }}
                        detailPanel={[
                            {
                                render: ({ rowData }) => {
                                    let insurance_id = rowData.id;
                                    if (familyArr.length) {
                                        const result = familyArr.filter(i => i.insurance_id === insurance_id);
                                        return (
                                            result.map((r, i) =>
                                                <div class="staff_tabel-detail-panel" key={i}>
                                                    <div class="staff_tabel-detail-item"><label>Name:</label><span class="value">{r.name}</span></div>
                                                    <div class="staff_tabel-detail-item"><label>Relation:</label><span class="value">{r.relation}</span></div>
                                                    <div style={{ 'right': 0, 'position': 'absolute' }}>
                                                        <span class="value">
                                                            <IconButton aria-label="delete">
                                                                <DeleteIcon onClick={() => props.HandleFamilyDelete(r.id, familyArr)} />
                                                            </IconButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                }
                            }
                        ]}
                     />
                </Grid>
            </Grid>
            <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={props.IsEditBasic?'Edit Basic Details':(props.FamilyAdd?'Add New Family':(props.IsEditable?'Edit Insurance Details':'Add Insurance Details'))}
                    OnSubmit={props.IsEditBasic?props.OnUpdateBasic:(props.FamilyAdd?props.OnFamilySubmit:props.OnSubmit)}
                    Content={
                        props.IsEditBasic
                        ?<DialogContent3 FormProps={props} BasicDetails={basicDetails}/>
                        :
                        (props.FamilyAdd
                            ?<DialogContent2 Family={familyNames} FamilyID={props.FamilyID} HandleOnChange={props.HandleOnChange} Errors={props.Errors}/> 
                            :<DialogContent FormProps={props}/>)
                        }
           />
            <Disability 
                staff_join_date = {Data.staff_join_date}
                disability_benefits = {disability_benefits} 
                disability_type = {disability_types}
                disability_ctc_components = {disability_ctc_components}
                disability_deduction_components = {disability_deduction_components}
                StaffID = {StaffID} />
            <Beneficiary
                beneficiary_relationships = {beneficiary_relationships}
                beneficiary_details = {beneficiary_details}
                beneficiary_types = {beneficiary_types}
                StaffID = {StaffID} />
        </React.Fragment>
        </>
    )
};


const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange} = props;
    const {insurance_types, plan_types, coverage,deduction_components,ctc_components} = props.Data;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
               <div className = "error"> {props.Errors.insurance_overlap}</div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.start_date && true}
                                helperText={props.Errors.start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.end_date}
                                onChange={HandleOnChange}
                                error={props.Errors.end_date && true}
                                helperText={props.Errors.end_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="paycheck_start_date"
                                name="paycheck_start_date"
                                label="Paycheck Start Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.paycheck_start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.paycheck_start_date && true}
                                helperText={props.Errors.paycheck_start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="paycheck_end_date"
                                name="paycheck_end_date"
                                label="Paycheck End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.paycheck_end_date}
                                onChange={HandleOnChange}
                                error={props.Errors.paycheck_end_date && true}
                                helperText={props.Errors.paycheck_end_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="insurance_type" required  error={props.Errors.insurance_type && true}>Insurance Type</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.insurance_type}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'insurance_type',
                                    id: 'insurance_type',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(insurance_types) !== 'Undefined' && insurance_types.map((t, i) =>
                                    <MenuItem value={t.id} key={'i-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.insurance_type && <FormHelperText className='error'>{props.Errors.insurance_type}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="plan_type" error={props.Errors.plan_type && true}>Plan Type</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.plan_type}
                                onChange={HandleOnChange}
                                required
                                inputProps={{
                                    name: 'plan_type',
                                    id: 'plan_type',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(plan_types) !== 'Undefined' && plan_types.map((t, i) =>
                                    <MenuItem value={t.id} key={'plan-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.plan_type && <FormHelperText>{props.Errors.plan_type}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="coverage" error={props.Errors.coverage && true}>Coverage</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.coverage}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'coverage',
                                    id: 'coverage',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(coverage) !== 'Undefined' && coverage.map((t, i) =>
                                    <MenuItem value={t.id} key={'coverage-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.coverage && <FormHelperText>{props.Errors.coverage}</FormHelperText> }
                        </ FormControl>
                    </Grid>
        <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="monthly_prem_employee"
                                name="monthly_prem_employee"
                                label="Monthly Premium  Employee"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.monthly_prem_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
        <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="monthly_prem_family"
                                name="monthly_prem_family"
                                label="Monthly Premium  Family"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.monthly_prem_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>


        <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employer_contrib_employee"
                                name="employer_contrib_employee"
                                label="Employer Contribution Employee Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employer_contrib_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
         <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employer_contrib_family"
                                name="employer_contrib_family"
                                label="Employer Contribution Family Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employer_contrib_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
 <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employee_contrib_employee"
                                name="employee_contrib_employee"
                                label="Employee Contribution Employee Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employee_contrib_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
 <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employee_contrib_family"
                                name="employee_contrib_family"
                                label="Employee Contribution Family Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employee_contrib_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
         <Grid item xs={12} md={6}>
        
                        <FormControl variant="standard">
                            <InputLabel htmlFor="deduction_components"   error={props.Errors.deduction_components && true}>Deduction Component</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.deduction_components}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'deduction_components',
                                    id: 'deduction_components',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(deduction_components) !== 'Undefined' && deduction_components.map((t, i) =>
                                    <MenuItem value={t.id} key={'deduction-components-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.deduction_components && <FormHelperText>{props.Errors.deduction_components}</FormHelperText> }
                        </ FormControl>
                    </Grid>

        <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="ctc_components"   error={props.Errors.ctc_components && true}>CTC Component</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.ctc_components}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'ctc_components',
                                    id: 'ctc_components',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(ctc_components) !== 'Undefined' && ctc_components.map((t, i) =>
                                    <MenuItem value={t.id} key={'ctc_components-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.ctc_components && <FormHelperText>{props.Errors.ctc_components}</FormHelperText> }
                        </ FormControl>
                    </Grid>


                    
                </Grid> 

            </form>
    )
}

const DialogContent2 = (p) => {
    const { Family } = p;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="family_id" required error={p.Errors.family_id && true} >Name</InputLabel>
                        <Select
                            value={p.FamilyID != null&&p.FamilyID}
                            onChange={p.HandleOnChange}
                            required
                            inputProps={{
                                name: 'family_id',
                                id: 'family_id',
                            }}
                        >
                        <MenuItem value={null} key={'family-id-0'}>Select</MenuItem>
                        {_R.type(Family) !== 'Undefined' && Family.map((t, i) =>
                            <MenuItem value={t.id} key={'family-0'+i}>{t.name}</MenuItem>
                        )}
                        </Select>
                        {p.Errors.family_id && <FormHelperText className='error'>{p.Errors.family_id}</FormHelperText> }
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogContent3 = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange2} = props;
    const {disability_types} = props.Data;
    let fsaDate, hsaDate, disability, safeharbour;
    if (!_R.isNil(p.BasicDetails) && p.BasicDetails.length>0){
        fsaDate = p.BasicDetails['fsa_effective_date'];
        hsaDate = p.BasicDetails['hsa_effective_date'];
        disability = (p.BasicDetails['disability_id'])?parseInt(p.BasicDetails['disability_id']):null;
        safeharbour = p.BasicDetails['enable_safeharbor_contrib'];
    }
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="fsa_effective_date"
                                name="fsa_effective_date"
                                label="FSA Effective Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.fsa_effective_date?FormFields.fsa_effective_date:fsaDate}
                                onChange={HandleOnChange2}
                                error={props.Errors.fsa_effective_date && true}
                                helperText={props.Errors.fsa_effective_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="hsa_effective_date"
                                name="hsa_effective_date"
                                label="HSA Effective Date"
                                type="date"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.hsa_effective_date?FormFields.hsa_effective_date:hsaDate}
                                onChange={HandleOnChange2}
                                error={props.Errors.hsa_effective_date && true}
                                helperText={props.Errors.hsa_effective_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="disability_type">Disability Type</InputLabel>
                            <Select
                                value={(_R.type(FormFields) !== 'Undefined' && FormFields.disability_type)?FormFields.disability_type:disability}
                                onChange={HandleOnChange2}
                                inputProps={{
                                    name: 'disability_type',
                                    id: 'disability_type',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(disability_types) !== 'Undefined' && disability_types.map((t, i) =>
                                    <MenuItem value={t.id} key={'d-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                             <InputLabel htmlFor="safeharbor_contrib">Enable Safeharbor Contribution</InputLabel>
                            <Select
                                value={FormFields.safeharbor_contrib?FormFields.safeharbor_contrib:safeharbour}
                                onChange={HandleOnChange2}
                                inputProps={{
                                    name: 'safeharbor_contrib',
                                    id: 'safeharbor_contrib',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={"1"} key={'i-type-1'}>Yes</MenuItem>
                                <MenuItem value={"0"} key={'i-type-1'}>No</MenuItem>
                            </Select>
                        </ FormControl>
                    </Grid>
                 </Grid>
            </form>
    )
}
