import React from 'react';
import { httpServices } from '../../../services/httpServices';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { DialogBoxMedium } from "../../common/dialog.medium";

export class GroupEditContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            errors : [],
            formFields : [],
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount(){
        if(this.props.EditId){
            this.getDetails(this.props.EditId);
        }
    }
    getDetails = (id) => {        
        let apiEndpoint = 'api/user/get-group-details';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            formFields : response.data.details,
                            permission : response.data.permission,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff,
        });
    };

    handleSubmit = (id) => {
        if(this.handleValidation()) {
            return httpServices
                .post("api/user/save-group", {
                    id: id, 
                    name: this.state.formFields.name,                    
                })
                .then((response) => {
                    if (response.data.success) {
                        this.props.UpdateParentOnSuccess(response.data);
                    } else {
                        this.setState({ errors: response.data.errors });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };
     handleValidation() {
        let formIsValid = true;
        let errors = {};
         if (typeof this.state.formFields.name === "undefined" || this.state.formFields.name.trim() === '') {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
   render(){
       return(
           <>
            <DialogBoxMedium
                Open={this.props.Open}
                Cancel={this.props.OnClose}
                Title={this.props.EditId ?  "Edit Group":"Add Group" }
                OnSubmit={ ()=>this.handleSubmit(this.props.EditId)}
                Content={<DialogEdit
                            FormFields={this.state.formFields}
                            HandleOnChange={this.HandleOnChange}
                            Errors={this.state.errors}
                        />}
           />
           </>

       )
   }
};
const DialogEdit = (props) => {
    return (
        <>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="name"
                                name="name"
                                label="Group Name"
                                required
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={
                                    typeof props.FormFields.name !==
                                    "undefined"
                                        ? props.FormFields.name
                                        : ""
                                }
                                onChange={props.HandleOnChange}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
