import React from 'react';
import {FamilyComponent} from './Family.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb
import { ErrorPage } from '../../../common/error';

const formFields = {
    'staff_id': null,
    'family_type': '',
    'name': null,
    'dob': null,
    'sort_order': 0,
    'ssn' :null,
    'gender':null,
    'idcard_type': '',
}

export class  FamilyDetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            errors: {},
            family: [],
            open:false,
            isDelete: false,
            isEdit: false,
            updated: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.family_type) {
            errors["family_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.idcard_value) {
            errors['idcard_value'] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.idcard_type) {
            errors['idcard_type'] = "Cannot be empty";
             formIsValid = false;
        }
        if (!this.state.formFields.gender) {
            errors['gender'] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    submit =() => {
        let len = this.state.family.length;
        let updated = this.state.updated;
        if (len === 0 && !updated) {
            this.setState({family: this.props.FamilyData.familyInfo, updated: true});
        }
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if(data){
                if(typeof data.id === 'undefined') {
                    console.log(data);
                // Check family duplicate entry
                httpServices.post('api/staff/family/check-family-duplicate-entry', { name : data.name })
                    .then(response => {
                        let confirm_submit = true;
                        if(response.data.is_duplicate_name){
                            confirm_submit = window.confirm("The entered name is already in your family member list. Do you want to save it as another member ?")
                        }
                        if(confirm_submit){
                            this.saveFamily();
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                        alert("Something went wrong. Please try again later");
                    });
                }
                else
                {
                  this.saveFamily();
                }
            }
        }
    }

    saveFamily = () => {
        let data = this.state.formFields;
        const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        const id = (this.state.id) ? this.state.id : 0;
        data.staff_id = staffID;
        data.user_id = userID;
        httpServices.post('api/staff/family/save-family', data)
            .then(response => {
                if (!response.data.errors) {
                    if (response.data.valid) {
                        if (!id ) {
                            this.setState({family : response.data.familyInfo });
                        }
                        else {
                            //Update Data
                            this.setState({family:response.data.familyInfo}); 
                        }
                        // Sort family data by sort_order
                        this.state.family.sort(function(a,b){
                            return a.sort_order - b.sort_order;
                        });
                        this.setState({open:false});
                        this.clearForm();
                    }
                }
                else {
                    this.setState({errors : response.data.errors});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
                alert("Something went wrong. Please try again later");
            });
    }

    onAddComponent = () => {
        return httpServices.post('api/staff/family/get-family', {})
            .then(response => {
                if (response.data) {
                    this.setState({
                        open: true,
                        isEdit: false,
                        family_type_opts: response.data.family_type_opts,
                        staff_gender_opt: response.data.staff_gender_opt,
                        id: 0,
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            payperiod_id: null,
            errors:{}
        });
        this.clearForm();
    }

    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };

    onUpdate = (id) => {
        return httpServices.post('api/staff/family/get-family', {'id': id})
            .then(response => {
                if (response.data) {

                    this.setState({
                        family_type_opts: response.data.family_type_opts,
                        staff_gender_opt: response.data.staff_gender_opt,
                        open: true, 
                        id: id,
                        isEdit: true,
                        formFields: response.data.selected_family,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onDelete = (index, rowID) => {
        let len = this.state.family.length;
        let updated = this.state.updated;
        if (len === 0 && !updated) {
            this.setState({family: this.props.FamilyData.familyInfo, updated: true});
        }
        return httpServices.post('api/staff/family/delete-family', {'id': rowID, 'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data.valid) {
                    this.setState({family: response.data.familyInfo});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    render(){
        return(
            (this.props.FamilyData.permission) ?
            <FamilyComponent
            Family={this.state.family.length>0?this.state.family: _R.type(this.props.FamilyData) !== 'Undefined' ? this.props.FamilyData.familyInfo: undefined}
            SaveFamily={this.submit.bind(this)}
            FamilyTypeOpts = {this.state.family_type_opts}
            StaffGenderOpts = {this.props.FamilyData.staff_gender_opt}
            IdCardTypes = {this.props.FamilyData.idcard_type}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields} 
            Open={this.state.open}
            Close={this.onClose.bind(this)}
            AddComponent={this.onAddComponent.bind(this)}
            OnDelete={this.onDelete.bind(this)}
            HandleUpdate={this.onUpdate.bind(this)}
            Errors={this.state.errors}
            IsDelete={this.state.isDelete}
            IsEdit = {this.state.isEdit}
            Edit = {this.props.FamilyData.edit_permission}
            Delete = {this.props.FamilyData.delete_permission}
            />
            :
            <ErrorPage/>
        )
    }
}
