import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ReviewFilterComponent } from './ReviewFilter.Component';
import { CustomizedSnackbars } from '../../common/snackbars';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { formatName } from '../../common/common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PaymentComponent } from './Payment.Component';
import Checkbox from '@mui/material/Checkbox';
import PaymentIcon from '@mui/icons-material/Payment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const API_URL = process.env.REACT_APP_API_URL;

export const ReviewComponent = (props) => {
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const handleChangePage = (event, newPage) => {
      setPage(newPage);
      }
     const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
     };
    
    return (
        <React.Fragment> 
            {!props.permisionError && <div className={'section-main'}>
              <div className={'section-header'}>
                <div className={'col-left'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Expense Vouchers
                        </Typography>
                </div>
                <div className={'action'}>
                 {(props.VoucherList.length >0 && props.FormFields.paymentDate !== null) ?
                    <>
                        <Button variant="contained" color="primary" size="small" onClick={event=>props.HandleExportClick(event)} endIcon={<KeyboardArrowDownIcon />} >Export as IIF</Button>
                        <Menu id="lock-menu" anchorEl={props.AnchorEl} open={Boolean(props.AnchorEl)} onClose={props.HandleExportClose} >
                            <MenuItem onClick={()=>props.HandleExportIIF('quickbooks_desktop_pro_2018')}> QuickBooks Desktop Pro 2018 </MenuItem>
                            <MenuItem onClick={()=>props.HandleExportIIF('quickbooks_pro_plus_2023')}> QuickBooks Pro Plus 2023 </MenuItem>
                        </Menu>
                    </>
                 : <></>
                 }
                 {(props.selectedVouchers.length > 0) && <Button variant="contained" size="small" color="primary" onClick={()=>props.handlePayButton()}>Pay</Button>}
                     <Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                    >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)} >
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Filter</div>
                                        <ReviewFilterComponent VoucherStatus={props.VoucherStatus} StaffNameList={props.StaffNameList} HandleOnSubmit={props.FilterSubmit} HandleOnChange={props.HandleOnChange} handleDateChange={props.handleDateChange} FormFields ={props.FormFields}/> 
                                </div>
                            </Drawer> 

                    </div>
                   </div>
                <div className={'section-body'}>
                    <Paper>
                    <TableContainer component={Paper}>
                <Table  aria-label="Timesheet List" className={'list-table_basic steps-table'}>
                    {(props.VoucherList.length === 0) ?
                        <TableHead> <TableRow> <TableCell> No records found </TableCell> </TableRow> </TableHead>
                    :
                    <>
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell>Staff Name</TableCell>
                            <TableCell>Voucher ID</TableCell> 
                            <TableCell>Expense Period</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell className={'text-right'}>Submitted Amount</TableCell>
                            <TableCell className={'text-right'}>Actual Payment</TableCell>
                            <TableCell>Payment Scheduled On</TableCell>
                            <TableCell>Submitted On</TableCell>
                            <TableCell>Reviewed By</TableCell>
                            <TableCell>Reviewed On</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                    {Object.keys(props.VoucherList).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => 
                    <>
                    <TableRow>
                            <TableCell> 
                                {(props.VoucherList[row].status_name === 'Approved') && <Checkbox
                                    onChange={props.handleSelection}
                                    value={props.VoucherList[row].id }
                                    color="primary"
                                    />}
                            </TableCell>
                            <TableCell>{formatName(props.VoucherList[row].user_first_name,null,props.VoucherList[row].user_last_name)} </TableCell>
                            <TableCell>{props.VoucherList[row].id }</TableCell>
                            <TableCell>{props.VoucherList[row].period}</TableCell>
                            <TableCell className={props.VoucherList[row].colour_code}>{props.VoucherList[row].status_name}</TableCell>
                            <TableCell className={'text-right'}>{props.VoucherList[row].total_amount ? props.VoucherList[row].total_amount : "$0.00"}</TableCell>
                            <TableCell className={'text-right'}>{props.VoucherList[row].actual_payment}</TableCell>
                            <TableCell>{props.VoucherList[row].payment_scheduled}</TableCell>
                            <TableCell>{props.VoucherList[row].submitted_on !== "0" ? props.VoucherList[row].submitted_on :''}</TableCell>
                            <TableCell>{formatName(props.VoucherList[row].rby_first_name,null,props.VoucherList[row].rby_last_name)}</TableCell>
                            <TableCell>{props.VoucherList[row].reviewed_on}</TableCell>
                            <TableCell>         
                            {(props.VoucherList[row].status === 6 || props.VoucherList[row].status === 1 || props.VoucherList[row].status === 4) ?
                                    <Tooltip title="View" aria-label="view">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="View">
                                            <VisibilityIcon fontSize="small" onClick={()=>props.GetExpenseDetails(props.VoucherList[row].id)}/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    ((props.VoucherList[row].status === 2 || props.VoucherList[row].status === 5) ?
                                        <Tooltip title="Review" aria-label="Review">
                                            <IconButton  className={'action-btn'} edge="end" aria-label="Review">
                                                <VisibilityIcon fontSize="small" onClick={()=>props.GetExpenseDetails(props.VoucherList[row].id)}/>
                                            </IconButton>
                                        </Tooltip>

                                        :
                                            ((props.VoucherList[row].status === 3 && props.IsFinanceTeam) ?
                                                <Tooltip title="Pay" aria-label="Pay">
                                                    <IconButton  className={'action-btn'} edge="end" aria-label="Pay">
                                                        <PaymentIcon fontSize="small" onClick={()=>props.GetExpenseDetails(props.VoucherList[row].id)}/>
                                                    </IconButton>
                                                </Tooltip>
                                            : 
                                                <Tooltip title="View" aria-label="view">
                                                    <IconButton  className={'action-btn'} edge="end" aria-label="View">
                                                        <VisibilityIcon fontSize="small" onClick={()=>props.GetExpenseDetails(props.VoucherList[row].id)}/>
                                                    </IconButton>
                                                </Tooltip>
                                            ))
                            }                                          
                            </TableCell>
                        </TableRow>
                        {/* <TableCell align="right">
                             <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(props.Requests[row].id)}/>
                                    </IconButton>
                             </Tooltip>
                        </TableCell>*/}
                    </>
                    )}
                        <TableRow><TableCell> </TableCell>
                        <TableCell colspan='4' align='right'> <b>Total Amount: </b></TableCell> 
                        <TableCell className={'text-right'}> <b>{props.SubmittedAmount}</b></TableCell>
                        <TableCell className={'text-right'}> <b>{props.ActualPayment}</b> </TableCell>
                        </TableRow>
                    </TableBody>
                    </>
                    }
                </Table>
              </TableContainer>
            </Paper>
            </div>
        </div>}
        <p className='error' align="center">{props.permisionError}</p>
        {!props.permisionError && <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={props.VoucherList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />} 
           <PaymentComponent
                formFields={props.FormFields}
                paymentOpen={props.paymentOpen}
                handleCancel={props.handleCancel}
                handlePayment={props.handlePayment}
                handleOnChange={props.HandleOnChange}
                handleDateChange = {props.handlePaymentDateChange}
                errors={props.Errors}
                ButtonLoader ={props.ButtonLoader}
            />
            <CustomizedSnackbars
                open={props.snackOpen}
                variant={props.snackVarient}
                message={props.snackMessage}
                handleClose={props.snakbarClose}
            />
            {/*Form for submitting filter values for iif export */}
            <form action={API_URL+'api/expenses/review-list'} method={"post"} id={"reviewList"}>
                <input type="hidden" name="export_version" id ="export_version"/>
                <input type="hidden" name="action" value={"export_iif"} />
                <input type="hidden" name="voucherStatus" value={props.FormFields.voucherStatus} />
                <input type="hidden" name="year" value={props.FormFields.year} />
                <input type="hidden" name="month" value={props.FormFields.month} />
                <input type="hidden" name="employee" value={props.FormFields.employee} />
                <input type="hidden" name="paymentDate" value={props.FormFields.paymentDate} />
            </form>
        </React.Fragment>
    )
};
