import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { formatName } from '../../common/common';

export const Comment = (props) => {   
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    let notes = (props.Comments.length > 0) ?  props.Comments : [];
    let private_arr = [];
    let note_arr = [];
    let allNotes = [];
     for (let key in notes) {
        if (parseInt(notes[key]['private']) === 1) {
            private_arr.push(notes[key]);
        }
        else {
            note_arr.push(notes[key]);
        }
    }
    allNotes = private_arr.concat(note_arr);
    return (
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            >
            <Typography variant="subtitle1" gutterBottom>Notes</Typography>
            </AccordionSummary>
         <AccordionDetails>
                {(notes.length > 0  && (props.Page === "submit")) 
                    ?
                    <ul className="list-standard list-ul-shrink">
                        {(note_arr).map((item) =>
                            <>
                            <li className="list-item">
                                <Typography variant="body1" gutterBottom>{item.comment.split('\n').map(str =><ul><li>{str}</li></ul>)}</Typography>
                            </li>
                            <li className="list-item">
                                <Typography  variant="body2" display="block"><i>Posted by {formatName(item.cby_first_name,null,item.cby_last_name)} On {item.created_on}</i></Typography>
                            </li>
                            </>
                        )}
                    </ul>
                    :
                    (props.Page === "review" && allNotes.length > 0) 
                        ?
                       <ul className="list-standard list-ul-shrink">
                        {(allNotes).map((item) =>
                            <>
                            <li className="list-item">
                                <Typography variant="body1" gutterBottom>{item.comment.split('\n').map(str =><ul><li>{str}</li></ul>)}</Typography>
                            </li>
                            <li className="list-item">
                                <Typography  variant="body2" display="block"><i>Posted by {formatName(item.cby_first_name,null,item.cby_last_name)} On {item.created_on}</i></Typography>
                            </li>
                            </>
                        )}
                    </ul>
                    :
                    <Typography variant="body2" gutterBottom align='center'>No notes for this expense</Typography>

                }
            </AccordionDetails>
        </Accordion>
    );
}


