import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {DialogBoxMedium} from '../../common/dialog.medium';
import { formatName } from '../../common/common';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';


// dialog content
const DialogContent = (p) => {
    const StyledPaper = styled(Paper) (({ Paper }) => ({
        '& .root': {
            width: '100%',
        },
        '& .container': {
            maxHeight: 440,
        },
    }));

    const {FormProps:props} = p;
    return (
        <>
        <div className='d-flex justify-between'>
            <p>Staff: <strong>{props.staff}</strong></p>
            <p>Component: <strong>{props.component}</strong></p>
        </div>

        <StyledPaper className={'root'}>
               <TableContainer className={'container'}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Pay Check Date</TableCell>
                        <TableCell>Actual Utilized Amount</TableCell>
                        <TableCell> Estimated Utilized Amount</TableCell>
                        <TableCell>Payroll Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.history.map(row => (
                    <TableRow key={row.pay_date}>
                        <TableCell component="th" scope="row">
                            {row.pay_date}
                        </TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>{row.estimated_amount}</TableCell>
                        <TableCell>{row.payroll_status}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell><strong>Total</strong></TableCell>
                    <TableCell><strong>{props.total_actual}</strong></TableCell>
                    <TableCell><strong>{props.total_estimated}</strong></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </StyledPaper>
        </>
    )
}


export const UtilizationReportComponent = (props) => {
    
    const gridStyle = {
        margin: '0px'
    };
    const columns = [
        {
            title: 'Staff',
            field: 'staff_name',
            render: rowData => <>{formatName(rowData.first_name,null,rowData.last_name,props.name_format)} </>,
            cellStyle: {
                color: '#0062ad'
            },
        },
        { title: 'Component', field: 'title'},
        { title: 'Cap Amount', field: 'cap_amount' },
        { title: 'Actual Utilized Amount', field: 'utilized_amount' },
        { title: 'Estimated Utilized Amount', field: 'estimated_utilized_amount' },
        { title: 'Balance Amount', field: 'balance_amount'},
    ];

    const {showHistory} = props;
    let dataArr = (typeof props.cap_components !== 'undefined' && props.cap_components.length > 0) ?  props.cap_components : [];

    return (
        <>
        <Paper className={'mb-1'}>
            <div className = {'p1'}>
                <Typography variant="h6" component="h6" className={'section-title'}>Utilization Report</Typography>
                    <div className={'d-flex justify-between'}>
                        <Grid container spacing={3} style={gridStyle}>
                            <Grid item xs={12} sm={12}>
                                <form className="form-fullwidth">
                                    <Grid container spacing={10}>
                                        <Grid item xs={4} md={2}>
                                            <FormControl error={_R.type(props.Errors.year) !== 'Undefined'?true:false}  variant="standard">
                                                <InputLabel htmlFor="year">Year</InputLabel>
                                                <Select
                                                    value={props.filterFields.year}
                                                    inputProps={{
                                                        name: 'year',
                                                        id: 'year',
                                                    }}
                                                    onChange={props.HandleFilterChange}
                                                >
                                                {_R.type(props.filters) === 'Object' && _R.type(props.filters.years) !== 'Undefined' && props.filters.years.map((year, i) =>
                                                    <MenuItem key={'year-'+i} value={year}>{year}</MenuItem>
                                                )}
                                                </Select>
                                                {_R.type(props.Errors.year) !== 'Undefined' && <FormHelperText>{props.Errors.year}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <FormControl error={props.Errors.component && true}  variant="standard">
                                                <InputLabel htmlFor="component">Component</InputLabel>
                                                <Select
                                                    value={props.filterFields.component}
                                                    onChange={props.HandleFilterChange}
                                                    inputProps={{
                                                        name: 'component',
                                                        id: 'component',
                                                    }}>
                                                    <MenuItem value="0">All</MenuItem>
                                                        {_R.type(props.filters) === 'Object' && _R.type(props.filters.components) !== 'Undefined' && props.filters.components.map((comp, i) =>
                                                                <MenuItem key={'components-'+i} value={comp.component_id}>{comp.title}</MenuItem>
                                                        )}

                                                </Select>
                                                <FormHelperText >{props.Errors.component}</FormHelperText>
                                       </ FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <FormControl error={props.Errors.staff && true}  variant="standard">
                                                <InputLabel htmlFor="staff">Staff</InputLabel>
                                                <Select
                                                    value={props.filterFields.staff}
                                                    onChange={props.HandleFilterChange}
                                                    inputProps={{
                                                        name: 'staff',
                                                        id: 'staff',
                                                    }}>
                                                    <MenuItem value="0">All</MenuItem>
                                                        {_R.type(props.filters) === 'Object' && _R.type(props.filters.staffs) !== 'Undefined' && props.filters.staffs.map((staff, i) =>
                                                                <MenuItem key={'staff-'+i} value={staff.staff_id}>{formatName(staff.first_name,null,staff.last_name,props.name_format)}</MenuItem>
                                                        )}
                                                </Select>
                                                <FormHelperText >{props.Errors.staff}</FormHelperText>
                                            </ FormControl>
                                        </Grid>

                                        <Grid className={'d-flex align-center'} item xs={4} md={2}>
                                            <Button className = {'mr-1'} variant = "contained" size = "small" color = "primary" onClick = {props.GetUtilization}> Filter</Button>
                                            <Button className = {'mr-1'} variant = "contained" size = "small" color = "primary" onClick = {props.ExportReports}> Export</Button>
                                            <Button className = {'mr-1'} variant = "outlined" color = "primary" size = "small" onClick = {props.ClearFilter}> Reset</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>

                        <div>
                        </div>
                    </div>
            </div>
        </Paper>

        <CustomMaterialTable
            title= "Utilization Tracking"
            columns={columns}
            data={dataArr}
            options={{
                search:false,
                actionsColumnIndex: -1,
                pageSize:10,
                pageSizeOptions: [10,20,30]
            }}
            actions={[
                {
                    icon: TrackChangesIcon,
                    tooltip: 'Utilization Tracking history',
                    onClick: (event, rowData) => {
                        showHistory(rowData.staff_id, rowData.component_id);
                    }
                }
            ]}
        />
        <DialogBoxMedium 
            Open={props.open} 
            Cancel={props.onClose}
            Title='Utilization History'
            ButtonName='OK'
            OnSubmit={props.onClose}
            Content={<DialogContent FormProps={props.history_info}/>}
        />
        </>
    )
};
