import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { TreeSelectComponent } from '../../../common/TreeSelectComponent';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';
import * as _R from 'ramda';

export class BasicDetailsEdit extends React.Component{
    render(){
      const filter = createFilterOptions();
      let projects = ( _R.type(this.props.staffInputs) !== 'Undefined' && _R.type(this.props.staffInputs.projects) !== 'Undefined') ? this.props.staffInputs.projects : [];
        return (
            <>
            <form className="form-fullwidth" onSubmit={this.props.OnSubmit}>
                <Grid container spacing={3}>
                    { (this.props.staffData.display_employee_code)?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employee_id"
                                name="employee_id"
                                label="Employee Code"
                                type="text"
                                error = { this.props.Errors.employee_id && true}
                                value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.employee_id) ? this.props.staffData.employee_id : null}
                                onChange={this.props.HandleOnChange}
                                helperText={ this.props.Errors.employee_id }
                            />
                        </ FormControl>
                    </Grid>
                    :<></>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="date_joined"
                                name="date_joined"
                                label="Date Joined"
                                type="date"
                                required
                                error = { this.props.Errors.date_joined && true}
                                value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.date_joined) ? this.props.staffData.date_joined : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={ this.props.Errors.date_joined }
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="pay_period">Pay Period</InputLabel>
                            <Select
                                value={typeof this.props.staffData != 'undefined' && this.props.staffData.pay_period}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'pay_period',
                                    id: 'pay_period',
                                }}>
                                { typeof this.props.staffInputs != 'undefined' && this.props.staffInputs.pay_periods.map((t, i) => 
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                             <Tooltip title="Format: HH:MM" placement="top-start" arrow>
                                <TextField variant="standard"
                                    id="entry_time"
                                    name="entry_time"
                                    label="Working Time Start"
                                    type="time"
                                    defaultValue="HH:MM" 
                                    value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.entry_time) ? this.props.staffData.entry_time : null}
                                    onChange={this.props.HandleOnChange}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                      helperText={ this.props.Errors.entry_time }
                                />
                                </Tooltip>                        
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>                            
                            <Tooltip title="Format: HH:MM" placement="top-start" arrow>
                                <TextField variant="standard"
                                    id="exit_time"
                                    name="exit_time"
                                    label="Working Time End"
                                    type="time"
                                    defaultValue="HH:MM" 
                                    value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.exit_time) ? this.props.staffData.exit_time : null}
                                    onChange={this.props.HandleOnChange}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                      helperText={ this.props.Errors.exit_time }
                                />
                                </Tooltip>                            
                        </ FormControl>
                    </Grid>
                    <Grid className={'project-column-grid'} item xs={12} md={6}>
                        <FormControl variant="standard">
                            <div class="project-column-title" > Main Project </div>
                             <input type='hidden' name='projects'/>
                             <TreeSelectComponent
                             data = {projects}
                             placeholderText = { "Search Projects"}
                             type = 'project'
                             dropdown_mode = 'true'
                             HandleTreeChange = {this.props.HandleTreeChange}
                                            />
                       </FormControl>

                    </Grid>
                    <Grid className={'project-column-grid'} item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="ts_review_method">Timesheet Review Method</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.timesheet_multiple_review) ? this.props.staffData.timesheet_multiple_review : 0 }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'timesheet_multiple_review',
                                    id: 'timesheet_multiple_review',
                                }}>
                                { typeof this.props.staffInputs != 'undefined' && this.props.staffInputs.ts_review_method.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="relieved_date"
                                name="relieved_date"
                                label="Relieved Date"
                                type="date"
                                error = { this.props.Errors.relieved_date && true}
                                value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.relieved_date) ? this.props.staffData.relieved_date : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={ this.props.Errors.relieved_date }
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="expense_eligibility">Expense Eligibility</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && typeof this.props.staffData.expense_eligibility != 'undefined') ? this.props.staffData.expense_eligibility : null }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'expense_eligibility',
                                    id: 'expense_eligibility',
                                }}>
                                { typeof this.props.staffInputs.expense_eligibility != 'undefined' && this.props.staffInputs.expense_eligibility.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    { parseInt(this.props.staffData.expense_eligibility) ? 
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="expense_limit_exempt">Expense Limit Exempt</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && typeof this.props.staffData.expense_limit_exempt != 'undefined') ? this.props.staffData.expense_limit_exempt : null }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'expense_limit_exempt',
                                    id: 'expense_limit_exempt',
                                }}>
                                { typeof this.props.staffInputs.expense_limit_exempt != 'undefined' && this.props.staffInputs.expense_limit_exempt.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    : <></>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="time_inout">Time In/Out</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.time_inout) ? this.props.staffData.time_inout : 0 }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'time_inout',
                                    id: 'time_inout',
                                }}>
                                { typeof this.props.staffInputs.time_inout != 'undefined' && this.props.staffInputs.time_inout.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    { parseInt(this.props.staffData.time_inout)?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="time_inout_required">Time In/Out Required</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.time_inout_required) ? this.props.staffData.time_inout_required : null }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'time_inout_required',
                                    id: 'time_inout_required',
                                }}>
                                { typeof this.props.staffInputs.time_inout != 'undefined' && this.props.staffInputs.time_inout.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    :<></>
                    }
                    { parseInt(this.props.staffData.time_inout)?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="time_inout_edate"
                                name="time_inout_edate"
                                label="Time In/Out Effective Date"
                                type="date"
                                value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.time_inout_edate) ? this.props.staffData.time_inout_edate : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                            />
                        </ FormControl>
                    </Grid>
                    :<></>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="timeoff_eligibility">Timeoff Eligibility Start Date</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.timeoff_eligibility) ? this.props.staffData.timeoff_eligibility : null }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'timeoff_eligibility',
                                    id: 'timeoff_eligibility',
                                }}>
                                { typeof this.props.staffInputs.timeoff_eligibility != 'undefined' && this.props.staffInputs.timeoff_eligibility.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    {(this.props.staffData.timeoff_eligibility == 'custom_start_date') && <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="doj_override_date"
                                name="doj_override_date"
                                label="Joining Date Override"
                                type="date"
                                error = { this.props.Errors.doj_override_date && true}
                                value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.doj_override_date) ? this.props.staffData.doj_override_date : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={ this.props.Errors.doj_override_date }
                            />
                        </ FormControl>
                    </Grid>}
                    {(this.props.staffData.timeoff_eligibility == 'custom_start_date' || this.props.staffData.timeoff_eligibility == 'joining_date') && <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="include_timeoff_eligibility_service_period">Timeoff Eligibility Service Perid</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.include_timeoff_eligibility_service_period) ? this.props.staffData.include_timeoff_eligibility_service_period : null }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'include_timeoff_eligibility_service_period',
                                    id: 'include_timeoff_eligibility_service_period',
                                }}>
                                { typeof this.props.staffInputs.include_timeoff_eligibility_service_period != 'undefined' && this.props.staffInputs.include_timeoff_eligibility_service_period.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>}
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="owners_relatives">Owner or Relative</InputLabel>
                            <Select
                                value={(typeof this.props.staffData != 'undefined' && this.props.staffData.owners_relatives) ? this.props.staffData.owners_relatives : 0 }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'owners_relatives',
                                    id: 'owners_relatives',
                                }}>
                                { typeof this.props.staffInputs.owner_or_relative != 'undefined' && this.props.staffInputs.owner_or_relative.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    { (typeof this.props.staffData!== 'undefined' && parseInt(this.props.staffData.owners_relatives) == 1) ?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                                <TextField variant="standard"
                                    id="relationship_to_owner"
                                    name="relationship_to_owner"
                                    label="Relationship With Owner"
                                    type="text"
                                    value={this.props.staffData.relationship_to_owner}
                                    onChange={this.props.HandleOnChange}
                                    helperText={ this.props.Errors.relationship_to_owner }
                                />
                        </ FormControl>
                    </Grid>
                    : <></>
                    }
                    { (typeof this.props.staffData!== 'undefined' && parseInt(this.props.staffData.user_status)==3) ?
                    <>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="attrition_type">Attrition Type</InputLabel>
                            <Select
                                value={(typeof this.props.staffData.attrition_type !== 'undefined' && this.props.staffData.attrition_type!=='') ? this.props.staffData.attrition_type : null }
                                onChange={this.props.HandleOnChange}
                                 error = { this.props.Errors.attrition_type && true}
                                inputProps={{
                                    name: 'attrition_type',
                                    id: 'attrition_type',
                                }}>
                                { typeof this.props.attrition_type_list != 'undefined' && this.props.attrition_type_list.map((t, i) =>
                                    <MenuItem value={t.id}>{t.title}</MenuItem>
                                )}
                            </Select>
                             <FormHelperText>{ this.props.Errors.attrition_type}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="attrition_reason"
                                name="attrition_reason"
                                label="Attrition Reason"
                                multiline
                                rows="4"
                                error = { this.props.Errors.attrition_reason && true}
                                defaultValue={(typeof this.props.staffData!= 'undefined' && this.props.staffData.attrition_reason) ? this.props.staffData.attrition_reason : null}
                                onChange={this.props.HandleOnChange}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={ this.props.Errors.attrition_reason }
                            />
                        </ FormControl>
                    </Grid>
                    </> 
                    :<></>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="offices">Office Location</InputLabel>
                            <Select
                                value={(typeof this.props.staffData.office_location !== 'undefined' && this.props.staffData.office_location!=='') ? this.props.staffData.office_location : null }
                                onChange={this.props.HandleOnChange}
                                 error = { this.props.Errors.office && true}
                                inputProps={{
                                    name: 'office_location',
                                    id: 'office_location',
                                }}>
                                <MenuItem value={0}>Select</MenuItem>
                                { typeof this.props.locations != 'undefined' && this.props.locations.map((t, i) =>
                                    <MenuItem value={t.location_id}>{t.location}</MenuItem>
                                )}
                            </Select>
                             <FormHelperText>{ this.props.Errors.office_location}</FormHelperText>
                        </ FormControl>
                    </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="leadership_program">Leadership Program</InputLabel>
                                <Select
                                    value={(typeof this.props.staffData != 'undefined' && this.props.staffData.leadership_program) ? this.props.staffData.leadership_program : 0}
                                    onChange={this.props.HandleOnChange}
                                    inputProps={{
                                        name: 'leadership_program',
                                        id: 'leadership_program',
                                    }}>
                                    {typeof this.props.staffInputs.leadership_program != 'undefined' && this.props.staffInputs.leadership_program.map((t, i) =>
                                        <MenuItem value={t.value}>{t.title}</MenuItem>
                                    )}
                                </Select>
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="expert">Expert</InputLabel>
                                <Select
                                    value={(typeof this.props.staffData != 'undefined' && this.props.staffData.expert) ? this.props.staffData.expert : 0}
                                    onChange={this.props.HandleOnChange}
                                    inputProps={{
                                        name: 'expert',
                                        id: 'expert',
                                    }}>
                                    {typeof this.props.staffInputs.expert != 'undefined' && this.props.staffInputs.expert.map((t, i) =>
                                        <MenuItem value={t.value}>{t.title}</MenuItem>
                                    )}
                                </Select>
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="clearance">Clearance Level</InputLabel>
                                <Select
                                    value={(typeof this.props.staffData.clearance_level_id !== 'undefined' && this.props.staffData.clearance_level_id !== '') ? this.props.staffData.clearance_level_id : null}
                                    onChange={this.props.HandleOnChange}
                                    error={this.props.Errors.clearance_level_id && true}
                                    inputProps={{
                                        name: 'clearance_level_id',
                                        id: 'clearance_level_id',
                                    }}>
                                    <MenuItem value={0}>Select</MenuItem>
                                    {typeof this.props.ClearanceLevels != 'undefined' && this.props.ClearanceLevels.map((t, i) =>
                                        <MenuItem value={t.id}>{t.name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>{this.props.Errors.clearance_level_id}</FormHelperText>
                            </ FormControl>
                        </Grid>
                </Grid>
            </form>
            </>
        )
    }
};
