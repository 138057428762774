import React from 'react';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
export const PaymentComponent = props => {
    return (
        <React.Fragment>

            <DialogBoxMedium
                Open={props.paymentOpen}
                Cancel={props.handleCancel}
                OnSubmit={props.handlePayment}
                handleOnChange={props.handleOnChange}
                Title={'Pay Expense Voucher'}
                Content={
                    <PayExpenseComponent FormProps={props} />
                }
                Loading = {props.ButtonLoader}
            />
        </React.Fragment>
    )
}
const PayExpenseComponent = (props) => {
    const FormProps = props.FormProps;
    return (
        <React.Fragment>
            <div className={'section-main'}>

                <p>Do you want to change expense voucher status to Paid?</p>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="expense_date" required >Paycheck Date</InputLabel>
                        <FormControl>
                            <CustomDatePicker
                                name = "payment_date"
                                id = "payment_date"
                                selectedDate = {FormProps.formFields.payment_date}
                                handleDateChange = {FormProps.handleDateChange}
                                datepickerType = {'keyboard'}
                                error = {FormProps.errors.payment_date}
                                helperText={FormProps.errors.payment_date}
                                minDate = {props.minDate}
                                required = {true}
                                label = {''}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="payment_mode" >Payment Mode</InputLabel>
                        <FormControl>


                            <Select
                                value={(typeof FormProps.formFields.payment_mode !== 'undefined') ? FormProps.formFields.payment_mode : 2}
                                onChange={FormProps.handleOnChange}
                                inputProps={{
                                    name: 'payment_mode',
                                    id: 'payment_mode',
                                }}>
                                <MenuItem value={1}>Paper Check</MenuItem>
                                <MenuItem value={2}>Payroll</MenuItem>
                            </Select>
                        </ FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            id="paid_note"
                            name="paid_note"
                            label="Add Note"
                            multiline
                            variant="outlined"
                            value={(typeof FormProps.formFields.paid_note !== 'undefined') ? FormProps.formFields.paid_note : null}
                            fullWidth
                            onChange={FormProps.handleOnChange}
                            error={FormProps.errors.note && true}
                            helperText={FormProps.errors.note}
                        />
                    </Grid>
                </Grid>

            </div>
        </React.Fragment>
    )
}

