import React from 'react';
import { ChangehistoryComponent } from './changeHistory.Component.js';
import * as _R from 'ramda';
import { httpServices } from '../../../services/httpServices';

export class ChangehistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Change History',
            history: [],
        }
    }
    componentDidMount = () => {
        this.getChangehistoryDetails();
    };
    getChangehistoryDetails = () => {
        let id = this.props.timeoffId;
        let apiEndpoint = 'api/settings/timeoff/getChangeHistory';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data.permission) {
                    console.log("permission true")
                    this.setState({
                        history: response.data.history,
                    });
                }
            })
    }
    render() {
        return (
            <ChangehistoryComponent
                Tiitle={this.state.title}
                History={this.state.history.length > 0 ? this.state.history : undefined}
            />
        )
    }
}
