import React from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'react-tagsinput/react-tagsinput.css';
import TablePagination from '@mui/material/TablePagination';
import * as _R from 'ramda';
import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import { formatAndSortNames, nl2br } from '../../common/common';
import {CustomMaterialTable} from '../../common/CustomMaterialTable';

const filterStyles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});
function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
export const ProjectTasksComponent = withStyles(filterStyles) (props => {
    const tasks = (typeof props.Tasks !== 'undefined') ? props.Tasks : [];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'first_name', middle_name: 'middle_name', last_name: 'last_name'};
    return (
        <>
            <React.Fragment>

            <CustomMaterialTable
                        title=" "
                        columns={[
                            {
                                title: (
                                <Checkbox
                                onChange={props.HandleCheckboxChange}
                                checked={props.SelectAllTasks}
                                inputProps={{
                                    name: 'select_all_tasks',
                                    id: 'select_all_tasks',
                                }}
                                color="primary"
                                />
                                ),
                                render: (rowData) => (
                                    <div>
                                        <Checkbox
                                        onChange={(e) => props.HandleCheckboxChange(e, rowData.task_id)}
                                        inputProps={{
                                            name: 'task_row_select',
                                            id: 'task_row_select',
                                        }}
                                        checked={(props.SelectedTasks.includes(rowData.task_id)) ? true : false}
                                        color="primary"
                                        />
                                    </div>
                                )
                            },
                            {
                                title: 'Title',
                                field: 'task_name',
                            },
                            {
                                title: 'Task Type',
                                field: 'name',
                            },
                            {
                                title: 'Start Date',
                                field: 'task_sdate',
                                customSort:(a,b) => sort(new Date(a.task_sdate),new Date(b.task_sdate))
                            },
                            {
                                title: 'End Date',
                                field: 'task_edate',
                                customSort:(a,b) => sort(new Date(a.task_edate),new Date(b.task_edate)),
                                render: rowData => <>{(typeof rowData.task_edate === 'undefined' || rowData.task_edate === '' || !rowData.task_edate) ? "Never" : rowData.task_edate}</>
                            },
                            {
                                title: 'Status',
                                field: 'task_status_name',
                            },
                        ]}
                        data={tasks}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            paging: false,
                            rowStyle: rowData => ({
                                color: (typeof rowData.isDataChanged !== 'undefined' && rowData.isDataChanged) ? '#DC143C' : '',
                            })
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: 'No task found',
                            },
                        }}
                        detailPanel={[
                            {
                                render: ({rowData})  => {
                                    return (
                                        <div className={'project-task-detail-panel'}>
                                            <form className="form-fullwidth">
                                                <Grid container spacing={3} >
                                                    <Grid item xs={12} md={6}>
                                                        <div className={'profile-view__basics_default'}>
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Description</label>
                                                                <span className={'value'}>{parse(nl2br(rowData.task_description))}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <div className={'task-resource-box'}>
                                                            <div className={'header d-flex align-center'}>
                                                                <label>Assigned Resources</label>
                                                            </div>
                                                            {(typeof rowData.users !== 'undefined' && rowData.users && rowData.users.length > 0) &&
                                                                <div className={'value'}>
                                                                    <ul>
                                                                        {formatAndSortNames(rowData.users, [format, columnsToBeFormated], props.NameSortOrder).map((name, key) =>
                                                                            <li>{name.formatted_name}</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            }
                                                            {(typeof rowData.users !== 'undefined' && rowData.users && rowData.users.length < 1) &&
                                                                <div className={'info'}>
                                                                    No Resources Found
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                    )
                                }
                            }
                        ]}
                     />
            {<TablePagination
                rowsPerPageOptions={[15, 30, 50]}
                component="div"
                count={tasks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

            </React.Fragment>
        </>
    );
});