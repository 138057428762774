import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Paper from "@mui/material/Paper";
import { ClientEditContainer } from './ClientEdit.Container';
import Tooltip from '@mui/material/Tooltip';

export const ClientViewComponent = (props) => {
      const gridStyle = {
        display: "block",
        padding: "24px",
        position: "relative",
        background: "#fff",
    };
    let data = typeof props.Client !== "undefined" ? props.Client : "";
    return (
        <>
        <div className="section-main">
        <div className="section-header">
            <div className="col-left">
                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                <div className="section-title-wrap">
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        {data.client_name}
                    </Typography>
                    <Typography></Typography>
                </div>
            </div>
            <div className="actions">
                   {props.EditPermission &&
                    <Button variant="contained" size="small"  color="primary" onClick={() => props.HandleEditClick(data.client_id)}>Edit</Button>
                   }
             </div>
        </div>
        <div className="section-body">
             <Paper className={"mb-1"} style={gridStyle}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Name</label>
                                <span className={"value"}>
                                    {data.client_name}
                                </span>{" "}
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Type</label>
                                <span className={"value"}>
                                    {data.type_name}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Created By</label>
                                <span className={"value"}>
                                    {data.createdby}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Modified By</label>
                                <span className={"value"}>
                                    {data.modifiedby}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Description</label>
                                <span className={"value"}>
                                    {data.client_description}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Status</label>
                                <span className={"value"}>
                                    {data.client_status_name}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Created Date</label>
                                <span className={"value"}>
                                    {data.client_cdate}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Modified Date</label>
                                <span className={"value"}>
                                    {data.client_mdate}
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
                <div className={"listSubHeader"}>
                    <Typography
                        component="h2"
                        className={"section-title"}
                        variant="button"
                        display="block"
                        gutterBottom
                        color="primary"
                    >
                        Projects
                    </Typography>
                </div>
             <Paper>
             <div className={"section-content"}>
             <CustomMaterialTable
                title= ""
                data = {props.Projects}
                columns={[
                    {
                      'title' : 'Code',
                      'field' : 'project_code'
                    },
                    {
                      'title' : 'Project',
                      'field' : 'project_name'
                    },
                    {
                      'title' : 'Description',
                      'field' : 'project_description'
                    },

                ]}
                actions={[
                    {
                        icon: VisibilityIcon,
                        tooltip: 'View',
                        onClick: (event, rowData) => { props.HandleViewDetails(rowData.project_id)},
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search:true,
                    pageSize:10,
                    pageSizeOptions: [10,20,30],
                    thirdSortClick: false,
                }}
                /></div>
            </Paper>
            </div></div>
            <ClientEditContainer
                Open = {props.Open}
                PopupType = {props.PopupType}
                EditId = {props.ClientId}
                Status = {props.ClientStatus}
                ClientTypes = {props.ClientTypes}
                UpdateParentOnSuccess = {props.UpdateParentOnSuccess}
            />

        </>
    );
};


