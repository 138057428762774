import React from 'react';
import Grid from '@mui/material/Grid';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import {CustomizedSnackbars} from '../../common/snackbars';
import {DialogBoxMedium} from '../../common/dialog.medium';
import Button from '@mui/material/Button';
import { formatName } from '../../common/common';
import { withStyles } from '@mui/styles';
import { CKEditor } from 'ckeditor4-react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHtmlParser from 'html-react-parser';
import TextField from '@mui/material/TextField';

const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});

export const ClearanceComponent =  withStyles(styles)(props => {
    const customButton = (props) => {
        return (<Button variant="contained" color="primary" size='small'>Add</Button>);
    }

    return(
        <React.Fragment>
        <CustomMaterialTable
        title ={"Clearance Options"}
        columns={[
            {
                title: 'Clearance Option',
                field: 'option_name',
                render: rowData => ( ReactHtmlParser(rowData.option_name))
            },
            {
                title: 'Email',
                field: 'email_notification',
            },
            {
                title: 'Clearance Group',
                field: 'group',
            },
            {
                title: 'Created By',
                field: 'created_by',
                render: rowData => ( formatName(rowData.cby_first_name, null, rowData.cby_last_name, props.NameFormat))
            },
            {
                title: 'Created On',
                field: 'created_on',
            },
            {
                title: 'Modified By',
                field: 'modified_by',
                render: rowData => ( formatName(rowData.mby_first_name, null, rowData.mby_last_name, props.NameFormat))
            },
            {
                title: 'Modified On',
                field: 'modified_on',
            },
        ]}
        data={props.ClearanceOptions}
        options={{
            search: false,
                actionsColumnIndex: -1,
                paging: false,
                sorting: false,
                headerStyle: { position: 'sticky', top: 0 },
                maxBodyHeight: '1000px',
        }}
        actions={[
            {
                icon: customButton,
                tooltip: 'Add Clearance Option',
                isFreeAction: true,
                onClick: (event, newData) => {
                    props.AddClearanceOption();
                },
                hidden: (props.EditPermission) ? false : true,
            },
            rowData => ({
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => {
                    props.EditClearanceOption(rowData.id);
                },
                hidden : (props.EditPermission === false) ? true : ((rowData.hide_action !== 'undefined' && rowData.hide_action ) ? true : false),
            }),
        ]}
        />
        {props.Open &&
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.Close}
                Title = {props.Action === 'add' ? "Add Clearance Option" : "Edit Clearance Option"}
                OnSubmit = {(props.Action === 'edit' && parseInt(props.EditId) !== 0) ? ()=>props.OnSubmit(props.EditId) : ()=>props.OnSubmit()}
                Content = {<DialogContent FormProps={props}/>}
            />
        }
        {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
        }
        </React.Fragment>
    )
});

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, Errors} = props;
    return (
        <form className="form-fullwidth"  >
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                <CKEditor
                    initData={( FormFields.message && typeof FormFields.message != 'Undefined') ? FormFields.message : ''}
                    name="option_name"
                    debug={true}
                    config={{
                        toolbar: [
                            [ 'Bold', 'Italic' ],
                            [ 'Source' ],
                            [ 'Link' ]
                        ],
                    }}
                    onChange={props.HandleOnHtmlChange}
                />
                <small className='error'>{ Errors.option_name !== 'undefined' && Errors.option_name }</small>
                </Grid>
                <Grid item xs={12} md={12}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked = {(typeof FormFields.email !== 'undefined' && ( FormFields.email === 1 || FormFields.email === true )) ? FormFields.email : false}
                                value = {(typeof FormFields.email !== 'undefined' && (FormFields.email === 1 || FormFields.email === true) ) ? FormFields.email : false}
                                id = "email"
                                name = "email"
                                onChange = {props.HandleOnChange}
                                color="primary"
                            />
                        }
                        label="Email Notification"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="clearanceGroup"
                            name="clearanceGroup"
                            label="Clearance Group"
                            type="text"
                            value={props.FormFields.clearanceGroup}
                            onChange={props.HandleOnChange}
                            error={props.Errors.name && true}
                            helperText={props.Errors.name}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}