import React from 'react';
import {ReviewListComponent} from './ReviewList.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import { history } from './../../../helpers/history';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const filterFields = {
    'year' :  '0',
    'pay_period' : '0',
    'timesheet_status' : '-1',
    'user_status' : '0',
    'user' : '0'
}

export class ReviewListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            list : [],
            hasPermission : true,
            filterInputs : [],
            filterFields : filterFields,
            right : false,
            multipleReview : '',
            loader : true,
            tabIndex : 0,
            prelimReviewPermission : false,
            timesheetReviewPermission : false
        }
        this.handleTabChange = this.handleTabChange.bind(this);
    }
    componentDidMount = () => { 
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;
        if (fromDashboard) {
            let filterFields = this.state.filterFields;
            filterFields['year'] = '0';
            filterFields['pay_period'] = '0';
            filterFields['timesheet_status'] = '-1';
            filterFields['user_status'] = '0';
            filterFields['user'] = '0';
            this.setState({
                filterFields: filterFields
            });
        }
        this.getReviewList();
    };

    getReviewList = () => {
        this.setState({               
            loader : true,
        });        
        let data = this.state.filterFields;
        return httpServices.post('api/timesheet/reviewlist', data)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        list : response.data.timesheets,
                        filterInputs : response.data.filter_inputs,
                        multipleReview : response.data.multiple_review,
                        loader : false,
                        prelimReviewPermission : response.data.prelimReviewPermission,
                        timesheetReviewPermission : response.data.timesheet_review_permission,
                        tabIndex : response.data.timesheet_review_permission ? 0 : 1
                    });
                    this.loadUsers();
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader :false,
                    });
                }
            })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    handleTabChange = (tab) => {
        this.setState({
            tabIndex : tab           
        });
    }
    viewReviewPage  = (id) =>{
        history.push('review/' + id);
    }
    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
        if (name === 'user_status') {
            this.loadUsers();
            f['user'] = '0';
            this.setState({
                filterFields: f
            });
        }
        if (name === 'year') {
            this.loadUsers();
            this.loadPayPeriods();
        }
        if (name === 'pay_period') {
            this.loadUsers();
        }
    }

    handleFilter = () => {
        const data = this.state.filterFields;
        if (data) {
            //this.props.fetchStaffByFilters(data);
            this.getReviewList();
            this.setState({right: false});
        }
    }
    loadUsers = () => {
        let f = this.state.filterFields;
        let user_status = f.user_status;
        let year = f.year;
        let pay_period = f.pay_period;

        return httpServices.post('api/timesheet/get-users-by-status', {status_id : user_status, year : year, pay_period : pay_period})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.filterInputs;
                    let users = response.data;
                    Object.assign(finputs, users);
                    this.setState({
                        filterInputs : finputs,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    loadPayPeriods = () => {
        let f = this.state.filterFields;
        let year = f.year;

        return httpServices.post('api/timesheet/payperiods', {year : year})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.filterInputs;
                    let payperiods = response.data;
                    Object.assign(finputs, payperiods);
                    this.setState({
                        filterInputs : finputs,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });


    }
    resetFilter = () => {
        let ff = this.state.filterFields; 
        ff['year'] = '0';
        ff['pay_period'] = '0';
        ff['timesheet_status'] = '-1';
        ff['user_status'] = '0';
        ff['user'] = '0';
        this.setState({
            filterFields: ff,
            right: false
        },()=>{
            this.getReviewList();}
        );
    }
    render(){
	
        return(
            (this.state.loader)
            ?
            <CustomProgressBar  
                loaderOpen={this.state.loader}
            />:
                (this.state.hasPermission)
                ?
                <ReviewListComponent
                    HandleSubmitPage = {this.viewReviewPage.bind(this)}
                    ToggleDrawer = {this.toggleDrawer.bind(this)}
                    HandleFilterChange={this.handleFilterChange.bind(this)}
                    HandleFilter = {this.handleFilter.bind(this)}
                    ResetFilter ={this.resetFilter.bind(this)}
                    HandleTabChange = {this.handleTabChange}
                    {...this.state}
                />
                :<ErrorPage/>
        )
    }
}
