import React from 'react';
import { ConfigComponent } from './Configuration.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

export class  SystemConfiguration extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            configs : [],
            formValues : {},
            open : false,
            hasPermission : true,
            showLoader : true,
            buttonLoader: false,
            snackbarOpen : false,
            snackbarMsg : '',
            errors : {},
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getConfigurations = this.getConfigurations.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount = () => {
        this.getConfigurations();
    };
    getConfigurations = () => {
        let apiEndpoint = 'api/system/list-configuration';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        configs : response.data.configurations,
                        showLoader: false,
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                        showLoader: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formValues;
        ff[field] = evt.target.value;
        this.setState({ 
            formValues: ff
        });        
    };
    handleSubmit = (event) => {
        this.setState({
            buttonLoader: true,
        });
        let data = this.state.formValues;
        if (data) {
            httpServices.post('api/system/edit-config', { data })
                .then(response => {
                    if (response.data.status && response.data.permission) {
                        this.setState({
                            configs : response.data.configurations,
                            open : false,
                            formValues : {},
                            buttonLoader: false,
                            snackbarOpen : true,
                            snackbarMsg : 'Configuration updated successfully',
                        });
                    } else {
                        this.setState({
                            hasPermission : response.data.permission,
                            buttonLoader: false,
                            errors : response.data.errors,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };
    onClose = () =>{
        this.setState({
            open: false,
            formValues:  {},
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onUpdateClick = (id) => {
        return httpServices.post('api/system/get-system-config-by-id', {'id': id})
            .then(response => { 
                if (response.data.permission) {
                    let data = response.data.system_config;
                    if (data){
                        this.setState({
                            formValues: data,
                            open: true,
                            errors : {},
                        });
                    }
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
 
    render(){
        return(
            (this.state.hasPermission)
            ?
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            <ConfigComponent
                Configs = {this.state.configs}
                FormValues = {this.state.formValues}
                OnSubmit = {this.handleSubmit} 
                HandleOnChange = {this.handleOnChange}
                Open = {this.state.open} 
                OnClose = {this.onClose}
                OnUpdateClick = {this.onUpdateClick}
                ButtonLoader = {this.state.buttonLoader}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarClose = {this.snackbarClose}
                Errors = {this.state.errors}
            />
            </>
            :
            <ErrorPage/>
        )
    }
}
