import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import * as _ts from '../../common/treeSelect';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ProjectCodeFilterComponent } from './ProjectCodeFilter.Component';
import Drawer from '@mui/material/Drawer';
import { CustomDatePicker } from '../../common/CustomDatePicker';

export const ExpenseProjectComponent = (props) => {
    const projects = typeof props.ExpenseProjects !== 'undefined' ? props.ExpenseProjects : [];
    return (
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                <div>
                    {props.EditPermission &&
                        <Tooltip title="Add Expense Project" aria-label="add">
                            <Button variant="contained" color="primary" size="small" onClick={props.AddExpenseProjectClick}>Add</Button>
                        </Tooltip>
                    }
                    <IconButton>
                        <FilterListIcon
                            onClick={props.FilterView('right', true)}
                            color="action" />
                    </IconButton>
                </div>
                <Drawer 
                anchor="right"
                open = {props.FilterViewState}
                onOpen = {props.FilterView('right', true)}
                onClose = {props.FilterView('right', false)}
                >
                    <div className={'tree-view-drawer'}>
                        <div className={'drawer-title'}>Filter</div>
                        <ProjectCodeFilterComponent
                            SelectedPayType={props.SelectedPayType}
                            HandleOnSubmit={props.FilterSubmit}
                            HandleOnChange={props.HandleFilterChange} />
                    </div>
                </Drawer>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="Timesheet List" size='small' className={'list-table_basic steps-table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Expense Pay Type</TableCell>
                            <TableCell>Project Code</TableCell>
                            <TableCell>Project Name</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            {props.EditPermission &&
                                <TableCell>Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(projects.length === 0) &&
                            <TableRow >
                                <TableCell>No expense projects found.</TableCell>
                            </TableRow>
                        }
                        {projects.map((item, index) =>
                            <TableRow hover>
                                <TableCell>{(parseInt(item.paytype) === 1) && 'Direct'}{(parseInt(item.paytype) === 2) && 'Indirect'}</TableCell>
                                <TableCell>{item.code}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.start_date}</TableCell>
                                <TableCell>{item.end_date}</TableCell>
                                {props.EditPermission &&
                                    <TableCell>
                                        <Tooltip title="Edit" aria-label="edit">
                                            <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                <EditIcon fontSize="small" onClick={() => props.EditClick(item.id)} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="View" aria-label="Delete">
                                            <IconButton className={'action-btn'} edge="end" aria-label="Delete">
                                                <DeleteOutlinedIcon fontSize="small" onClick={() => props.HandleDeleteClick(item.id)} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                }
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {(props.PopupType === 'add' || props.PopupType === 'edit') &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={props.PopupType === 'add' ? 'Add Expense Project' : 'Edit Expense Project'}
                    OnSubmit={props.HandleSubmit}
                    Content={<DialogAdd FormProps={props} />}
                />
            }
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarVarient}
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            }
            {props.PopupType === 'delete' &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteExpProject}
                    Title='Confirm Delete'
                    ButtonName='Ok'
                    Content={
                        <DialogWithConfirmMessage msg="Do you want to delete this Expense Project" />
                    }
                />
            }

        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields, HandleOnChange, Errors, HandleTimeEntryDateChange, HandleTreeChange, Filters} = props.FormProps;
    var all_projects = Filters.projects;
    let projects = (_R.type(all_projects) !== 'Undefined') ? all_projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(FormFields) !== 'Undefined' && FormFields.projects) ? FormFields.projects : []));
    } 
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                        <span className={'expense-add-label'}>Expense Pay Type *</span>
                        <Select
                            value={(typeof FormFields.pay_type !== 'undefined') ? FormFields.pay_type : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'pay_type',
                                id: 'pay_type'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Direct</MenuItem>
                            <MenuItem value={2}>Indirect</MenuItem>
                        </Select>
                        <small className='error'>{Errors.pay_type && Errors.pay_type}</small>
                    </ FormControl>
                </Grid>

                {(parseInt(FormFields.pay_type) === 1) && <Grid item xs={12} md={12}>
                    <FormControl>
                        <input type='hidden' name='projects' value={(typeof FormFields.projects !== 'undefined') ? FormFields.projects : null} />
                        <TreeSelectComponent
                            data={projects}
                            dropdown_mode={"1"}
                            placeholderText={"Search Projects"}
                            type='project'
                            HandleTreeChange={HandleTreeChange}
                        />
                        <small className='error'>{Errors.projects && Errors.projects}</small>
                    </ FormControl>
                </Grid>}
                {(parseInt(FormFields.pay_type) === 2) && <Grid item xs={12} md={12}>
                    <div className={'ts-input text'}>
                        <span className={'expense-add-label'}>Project Code *</span>
                        <TextField
                            id="code"
                            name="code"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline={true}
                            type="text"
                            className={'input-item tex-right'}
                            value={(typeof FormFields.code !== 'undefined' ? FormFields.code : '')}
                            onChange={HandleOnChange}
                            error={Errors.code && Errors.code}
                        />
                        <small className='error'>{Errors.code && Errors.code}</small>
                    </div>
                </Grid>}
                {(parseInt(FormFields.pay_type) === 2) && <Grid item xs={12} md={12}>
                    <div className={'ts-input text'}>
                        <span className={'expense-add-label'}>Project Name *</span>
                        <TextField
                            id="name"
                            name="name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline={true}
                            type="text"
                            className={'input-item tex-right'}
                            value={(typeof FormFields.name !== 'undefined' ? FormFields.name : '')}
                            onChange={HandleOnChange}
                            error={Errors.name && Errors.name}
                        />
                        <small className='error'>{Errors.name && Errors.name}</small>
                    </div>
                </Grid>}
                {(parseInt(FormFields.pay_type) === 2) && <Grid item xs={12} md={12}>
                    <FormControl>
                        <CustomDatePicker
                            name="start_date"
                            id="start_date"
                            selectedDate={FormFields.start_date}
                            handleDateChange={HandleTimeEntryDateChange}
                            error={Errors.start_date}
                            helperText={Errors.start_date}
                            required={true}
                            label={'Start Date'}
                        />
                    </ FormControl>

                </Grid>}
                {(parseInt(FormFields.pay_type) === 2) && <Grid item xs={12} md={12}>
                    <FormControl>
                        <CustomDatePicker
                            name="end_date"
                            id="end_date"
                            selectedDate={FormFields.end_date}
                            handleDateChange={HandleTimeEntryDateChange}
                            error={Errors.end_date}
                            helperText={Errors.end_date}
                            label={'End Date'}
                        />
                    </ FormControl>

                </Grid>}
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
