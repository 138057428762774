import React from 'react';
import { ReportDetailsComponent } from './ReportDetails.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const formValues = {
    'user': null,
    'client': null,
    'project': null,
};

export class ReportDetailsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            report_details: [],
            formInputs: [],
            errors: {},
            Open: false,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            hasPermission : true,
            popupType : '',
            formValues:formValues,
            deleteId:0,
            dataLoaded : false,
            showLoader : true,
            buttonLoader : false,
            level : ''
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getReportDetails();
    };
    
    getReportDetails = () => {
        let apiEndpoint = 'api/system/get-report-details';
        return httpServices.post(apiEndpoint,{id:this.props.match.params.id})
            .then(response => {
                if (response.data.permission) {
                    let formInputs  = this.state.formInputs;
                    formInputs.client_list=response.data.client_list;
                    formInputs.users_list=response.data.users_list;
                    this.setState({
                        report_details : response.data.details,
                        formInputs : formInputs,
                        showLoader : false,
                        dataLoaded:true,
                        level : response.data.level
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                        dataLoaded:true
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formValues;
        ff[field] = evt.target.value;
        if(field==='client' && this.state.level==='project') { 
            this.clientChange();
        }
        this.setState({ formValues: ff });        
    };

    handleTreeChange = (evt, cNode, sNodes, action) => {
        let project = 0;
        let formValues = this.state.formValues;
        if (sNodes.length < 1) {
            project = 0;
        }
        else if (cNode.project_id) {
            project = cNode.project_id;
        }
        formValues['project'] = project;
        this.setState({ formValues : formValues});
    }
    handleSubmit = () => {
        this.setState({ buttonLoader : true});
        let valid = this.validateFormData();
        if (valid) {
            let apiEndpoint = 'api/system/submit-report-permission';
            let data = this.state.formValues;
            data.action = 'add';
            data.level = this.state.level;
            data.report_id=this.props.match.params.id;
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data) {
                        this.setState({
                            snackbarOpen : true,
                            snackbarVarient: response.data.status,
                            snackbarMsg : response.data.msg,
                            buttonLoader : false
                        },()=>{
                            this.onClose();
                            this.getReportDetails();
                        }
                        )
                    }
                    else {
                        this.setState({buttonLoader : false});
                    } 
                });
        }
        else {
            this.setState({ buttonLoader : false});
        }
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    clearForm = () => {
        const ff = {
            'user': null,
            'client': null,
            'project': null,
        };
        this.setState({
            formValues: ff,errors:{}
        });
    };

    onClose = () =>{
        this.clearForm();
        this.setState({
            Open: false
        });
    };

    addClick = () => {
        let level = this.state.level;
        this.setState({
            popupType : level=='project'? 'add_project' : 'add_organization',
            Open : true,
        });
    }



    validateFormData() {
        let errors = {};
        let formValues = this.state.formValues;
        let valid = true;
        let level = this.state.level;
        if ((typeof formValues.project === 'undefined' || !formValues.project) && level==='project') {
            errors['project'] = 'Project cannot be empty';
            valid = false;
        }
        if (typeof formValues.user === 'undefined' || !formValues.user) {
            errors['user'] = 'User cannot be empty';
            valid = false;
        }
        if ((typeof formValues.client === 'undefined' || !formValues.client) && level==='organization') {
            errors['client'] = 'Organization cannot be empty';
            valid = false;
        }
        if (level==='project' && formValues.project && formValues.user) {
            let exist = this.state.report_details.user_project_details.filter(user_project_details=> user_project_details.project_id === formValues.project && user_project_details.user_id === formValues.user).length;
            if (exist) {
                valid = false;
                errors['project'] = 'Project already assigned to this user';
            }
        }
        if (level==='organization' && formValues.client && formValues.user) {
            let exist = this.state.report_details.user_organization_details.filter(user_organization_details=> user_organization_details.organization_id === formValues.client && user_organization_details.user_id === formValues.user).length;
            if (exist) {
                valid = false;
                errors['client'] = 'Organization already assigned to this user';
            }
        }
        this.setState({
            errors : errors,
        });
        return valid;
    }

    handleDeleteClick = (id) => {
        this.setState({
            Open : true,
            deleteId : id,
            popupType:'delete'
        });
    }

    deleteUser = () => {
        let apiEndpoint = 'api/system/submit-report-permission';
        let data = {action : 'delete', id : this.state.deleteId, level : this.state.level};
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    this.setState({
                        snackbarOpen : true,
                        snackbarVarient: response.data.status,
                        snackbarMsg : response.data.msg
                    },()=>{
                        this.getReportDetails();
                        this.onClose();
                    }
                    )} 
            });
    }
    clientChange = () => {
        let selectedClient = this.state.formValues.client;
        let apiEndpoint = 'api/project/getprojects';
        if ((selectedClient || this.props.Parent)) {
            return httpServices.post(apiEndpoint, {client_id : selectedClient})
                .then(response => {
                    if (response.data) {
                        let formInputs = this.state.formInputs;
                        formInputs.project_list = response.data.projects;
                        this.setState({
                            formInputs : formInputs
                        });
                    } 
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

    render(){
        return(<>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.dataLoaded) ?
                <>
                {(this.state.hasPermission)  ?
                    <ReportDetailsComponent
                        ReportDetails = {this.state.report_details }
                        FormInputs={this.state.formInputs}
                        FormValues={this.state.formValues}
                        OnSubmit={this.handleSubmit}
                        HandleOnChange={this.handleOnChange}
                        Open= {this.state.Open}
                        OnClose={this.onClose.bind(this)}
                        AddClick={this.addClick.bind(this)}
                        HandleTreeChange = {this.handleTreeChange}
                        Errors={this.state.errors}
                        DeleteClick={this.deleteUser.bind(this)}
                        PopupType = {this.state.popupType}
                        HandleDeleteClick = {this.handleDeleteClick.bind(this)}
                        SnackbarOpen={this.state.snackbarOpen}
                        SnackbarVarient={this.state.snackbarVarient}
                        SnackbarMsg={this.state.snackbarMsg}
                        SnackbarClose={this.snackbarClose}
                        ButtonLoader={this.state.buttonLoader}
                    />
                    :
                    <ErrorPage/>}
                </>
                :<></>
            }
        </>)
    }
}
