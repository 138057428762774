import React from "react";
import * as _R from "ramda";
import {CustomMaterialTable, CustomMTableToolbar} from '../common/CustomMaterialTable';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});
const ProjectStyle = styled(React.Fragment)(({ theme }) => ({
    '& .drawerList': {
        width: 250,
        padding:'20px'
    },
    '& .drawerFormControl':{
        width:'100%'
    },
    '& .filterButton':{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    '& .filterSubmitButton':{
        marginTop:'20px'
    }
}));
const StyledFilterButton = styled(IconButton)(() => ({
    position:'absolute',
    top:"0.5rem",
    right:"0.5rem"
}));
const SideList = withStyles(styles)(p => {
    return (<div role="presentation" className={'drawerList'}>
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>	   
            <FormControl className={'drawerFormControl'} variant="standard">
            <InputLabel htmlFor="status">Project Status</InputLabel>
            <Select
                inputProps={{
                    name: 'status',
                    id: 'project_status',
                }}
                value = {!_R.isNil(p.FilterValues.status) && p.FilterValues.status }
                onChange = {p.HandleFilterChange}
            >
                <MenuItem value={0} key={'st-0'}>All</MenuItem>
                {_R.type(p.FilterInputs.project_status !== 'Undefined') && p.FilterInputs.project_status.map((s, i) =>
                    <MenuItem value={s.project_status_id} key={'st-'+i}>{s.project_status_name}</MenuItem>
                )}
            </Select>
	    </FormControl>
	    
	    <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
	    <Button variant="outlined"  color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
    </div>
    );
});
export const UserProjectsComponent = withStyles(styles)(props => {
    const { classes, toggleDrawer } = props;
    const columns = [
        { title: "Project Name", field: "project_name" },
        { title: "Project Code", field: "project_code" },
        { title: "Client", field: "client_name" },
        {
            title: "Status",
            field: "project_status_name",
        },
        {
            title: "Start Date",
            field: "project_sdate",
        },
        {
            title: "Due Date",
            field: "project_edate",
        },
        {
            title: "Tasks",
            field: "task_names",
        },
    ];
    const dataArr =
        _R.type(props.Projects) !== "Undefined" &&
        _R.type(props.Projects) !== "Undefined"
            ? props.Projects
            : [];
    return (
        <ProjectStyle>
            <div className={"mtable-basic"}>
                 <CustomMaterialTable
                        title={"Project List"}
                        columns={columns}
                        data={dataArr}
                        parentChildData={(row, rows) =>
                            rows.find((a) => a.id === row.parentId)
                        }
                        options={{
                            search: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                        }}
                        components={{
                        Toolbar: props => (
                            <div style={{ position: 'relative', paddingRight:'50px' }}>
                                    <CustomMTableToolbar {...props} />
                                <Tooltip title="Filter" aria-label="Filter">
                                <StyledFilterButton  onClick={toggleDrawer('right', true)} className={'filterButton'}><FilterList /></StyledFilterButton>
                                </Tooltip>
                            </div>
                            )
                        }}
                onRowClick={(event, rowData) => props.HandleViewDetails(rowData.project_id)}
                    />
            
            <Drawer
                anchor="right"
                open={props.Right}
                onOpen={props.toggleDrawer('right', true)}
                onClose={props.toggleDrawer('right', false)}>
                <SideList
                    Right={'right'}
                    FilterValues = {props.FilterValues}
                    FilterInputs={props.FilterInputs}
                    HandleFilterChange={props.HandleFilterChange}
                    SubmitFilter={props.SubmitFilter}
                    ResetFilter={props.ResetFilter}
                />
            </Drawer>
            </div>
        </ProjectStyle>
    );
});

