import React,  { Component } from 'react';
import { styled } from '@mui/material/styles';
import {CustomMaterialTable, CustomMTableToolbar} from '../../../common/CustomMaterialTable';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import InfoIcon from '@mui/icons-material/Info';
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import moment from 'moment';
import { history } from '../../../../helpers/history';
import *as _R from 'ramda';
import { StaffConstants} from '../../../../constants/';
import { httpServices } from '../../../../services/httpServices';
import { formatName } from '../../../common/common';

const ProjectStyle = styled(React.Fragment)(({ theme }) => ({
    '& .drawerList': {
        width: 250,
        padding:'20px'
    },
    '& .drawerFormControl':{
        width:'100%'
    },
    '& .filterButton':{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    '& .filterSubmitButton':{
        marginTop:'20px'
    }
}));
const StyledFilterButton = styled(IconButton)(() => ({
    position:'absolute',
    top:"0.5rem",
    right:"0.5rem"
}));
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: '0.8rem',
        border: '1px solid black',
    },
  });

const displayLabourCategory = project => {
    let labour_categories = project.categories ? project.categories.split(';') : [];
    if (labour_categories.length > 1)
    {
        return <HtmlTooltip placement = "bottom" title = {
                    
                    <TableContainer >
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell> Labor Category </TableCell>
                                    <TableCell> Start Date </TableCell>
                                    <TableCell> End Date </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {labour_categories.map((r,i) => {
                                    let category_details = r.split('|');
                                    let start_date = moment(category_details[1]).format('MM/DD/YY');
                                    let end_date = category_details[2] ? moment(category_details[2]).format('MM/DD/YY') : '';
                                    return(
                                        <TableRow>
                                            <TableCell>{category_details[0]}</TableCell>
                                            <TableCell>{start_date}</TableCell>
                                            <TableCell>{end_date}</TableCell>
                                        </TableRow>
                                    )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            >
                <span className={'MuiTableCell-tooltip-container'}><InfoIcon/>{project.project_labor_category}</span>
            </HtmlTooltip>;
    }
    else{
        return project.project_labor_category;
    }
}
const formFields = {
    'project_status': '1',
    'client' : '0',
};
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

// This class component is depends Data property
export class ProjectDetails extends Component{
    // This is for future purpose
    constructor(props) {
        super(props);
        this.state = {
            formFields : formFields,
            project_status : [],
            ProjectData : [],
            selectedData : {},
            right : false,
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
    }

    componentDidMount() {
        let f = {
            'project_status': '1',
            'client' : '0',
        };        
        this.setState({
            formFields: f
        });
        this.getProjectList();
    } 

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }
    
    handleFilterChange=(evt, value) => {
        let f = this.state.formFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
    }

    submitFilter =() => {
        this.getProjectList();
    }

    getProjectList = () => {
        const project_status = this.state.formFields.project_status;
        const client = this.state.formFields.client;
        const {StaffID, UserID} = this.props;
        this.setState({
            project_status: project_status,
        });
        let apiEndpoint = StaffConstants['PROJECT'];
        return httpServices.post(apiEndpoint, { 'id': UserID, 'StaffID': StaffID, 'module': 'PROJECT', 'project_status': project_status, 'client' : client})
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedData : response.data,
                        right :false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            }); 
    }
    handleViewClick = (id) => {
        history.push(BASE_PATH+'/projects/view/' +id);
    }
    render(){
        return(
        <ProjectComponent 
            ProjectData = {(_R.not(_R.isEmpty(this.state.selectedData))) ? this.state.selectedData : this.props.ProjectData}
            SubmitFilter = {this.submitFilter.bind(this)}
            toggleDrawer = {this.toggleDrawer}
            FormFields = {this.state.formFields}
            ProjectStatus = {this.state.project_status}
            HandleFilterChange = {this.handleFilterChange.bind(this)}
            StaffID = {this.props.StaffID}
            HandleViewClick = {this.handleViewClick}
            UserID = {this.props.UserID}
            right = {this.state.right} />
        )
    }
}

const ProjectComponent = (props) => {
    const columns = [
        { title: 'Project Name', field: 'project_name' },
        { title: 'Client', field: 'client_name' },
        {
            title: 'Labor Category',
            field: 'project_labor_category',
            render: rowData => <>{displayLabourCategory(rowData)}</>
        },
        {
            title: 'Project Manager',
            field: 'pmanager',
            render: rowData => <>{formatName(rowData.manager_first_name, null, rowData.manager_last_name)} </>
        },
        {
            title: 'Tasks Assigned',
            field: 'task_name',
        },
        {
            title: 'Project Status',
            field: 'project_status_name',
        },
        {
            title: 'Resource Start Date',
            field: 'resource_start_date',
        },
        {
            title: 'Resource End Date',
            field: 'resource_end_date',
        },
    ];
    const { classes, toggleDrawer, ProjectData, right } = props;
    const dataArr = (_R.type(ProjectData) !== 'Undefined' && _R.type(ProjectData.projects) !== 'Undefined') ? ProjectData.projects :[];
    const sideList = side => (
        <div className={'drawerList'} role="presentation" >
            <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
            <FormControl className={'drawerFormControl'} variant="standard">
                <InputLabel htmlFor="age-simple">Project Status</InputLabel>
                <Select
                    inputProps={{
                    name: 'project_status',
                    id: 'project_status',
                    }}
                    value = {props.FormFields.project_status}
                    onChange = {props.HandleFilterChange}
                >
                    <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {typeof(props.ProjectData) !== 'undefined' && typeof(props.ProjectData.project_status) !== 'undefined' && (props.ProjectData.project_status != null)  && props.ProjectData.project_status.map((s, i) =>
                    <MenuItem value={s.project_status_id} key={'st-'+i}>{s.project_status_name}</MenuItem>
                )}
                </Select>
            </FormControl>
            <FormControl className={'drawerFormControl'} variant="standard">
                <InputLabel htmlFor="age-simple">Client</InputLabel>
                <Select
                    inputProps={{
                    name: 'client',
                    id: 'client',
                    }}
                    value = {props.FormFields.client}
                    onChange = {props.HandleFilterChange}
                >
                    <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {typeof(props.ProjectData) !== 'undefined' && typeof(props.ProjectData.clients) !== 'undefined' && (props.ProjectData.clients != null)  && props.ProjectData.clients.map((s, i) =>
                    <MenuItem value={s.client_id} key={'st-'+i}>{s.client_name}</MenuItem>
                )}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={props.SubmitFilter} className={'filterSubmitButton'}>Filter</Button>
        </div>
    );
    return (
        <ProjectStyle>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title= "Project List"
                    icons={tableIcons}
                    columns={columns}
                    data={dataArr}                    
                    actions={[
                        rowData =>({icon: 'visibility', tooltip: 'View',onClick: (event, rowData) => {
                            props.HandleViewClick(rowData.id);
                        }})                          
                    ]}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                    options={{
                        search:false,
                        actionsColumnIndex: -1,
                        pageSize:10,
                        pageSizeOptions: [10,20,30],
                    }}
                    components={{
                        Toolbar: props => (
                            <div style={{ position: 'relative', paddingRight:'50px' }}>
                                    <CustomMTableToolbar {...props} />
                                <Tooltip title="Filter" aria-label="Filter">
                                    <StyledFilterButton  onClick={toggleDrawer('right', true)} className={'filterButton'}><FilterList /></StyledFilterButton >
                                </Tooltip>
                            </div>
                            )
                        }}
                />
                <Drawer
                    anchor="right"
                    open={props.right}
                    onOpen={toggleDrawer('right', true)}
                    onClose={toggleDrawer('right', false)}
                >
                  {sideList('right')}
                </Drawer>             
            </div>
        </ProjectStyle>
    );
};
