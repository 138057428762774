import React  from 'react';
import * as _R from 'ramda';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DialogBoxMedium } from '../../common/dialog.medium';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { CustomizedSnackbars } from '../../common/snackbars';
import { Comment } from '../Common/Comment';
import { ChangeHistory } from '../Common/ChangeHistory';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Alert from '@mui/material/Alert';
import * as _cs from '../../common/common';
import { AddOvertime } from './OvertimeAdd.Container';
import { formatName } from '../../common/common';
import Tooltip from '@mui/material/Tooltip';

const API_URL = process.env.REACT_APP_API_URL;
const DialogComponent = (p) => {
    const {FormProps} = p;
        return (
                <div>
                <Box className={'mt-1'}>
                     <Typography variant="subtitle2" gutterBottom>
                        {FormProps.DialogMessage} 
                    </Typography>
                </Box>
                <Box className={'mt-1'}>
                {FormProps.Action === 'Reject' || FormProps.Action === 'unapprove' || FormProps.Action === 'Add Comment'  ? 
                <TextField
                    required
                    id="comments"
                    name="comments"
                    label="Comments"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={FormProps.HandleOnChange}
                    error={FormProps.Errors.comments && true}
                    helperText = {FormProps.Errors.comments}
                /> 
                    :
                <TextField
                    id="comments"
                    name="comments"
                    label="Comments"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={FormProps.HandleOnChange}
                    error={FormProps.Errors.comments && true}
                    helperText = {FormProps.Errors.comments}
                />
                }
                </Box>
                </div>
            )
};

export const ViewOvertimeComponent  = props => {
    let image_url = API_URL+'api/overtime/download?id='+ (_R.type(props.RequestData) !== 'Undefined' ? props.RequestData.attachment_id : []);
    let attachment_name = _R.type(props.RequestData) !== 'Undefined' ? props.RequestData.name : [];
    let approvers = _R.type(props.Approvers) !== 'Undefined' ? Object.values(props.Approvers) : [];
    let recipients =  _R.type(props.EmailRecipients) !== 'Undefined' ? Object.values(props.EmailRecipients) : [];
    for (let key in recipients) {
        if (approvers.includes(recipients[key]['user_id'])) {
            recipients[key]['rev'] = " (Reviewer)";
        }
        else {
             recipients[key]['rev'] = '';
        }
    }
    
    return (
          <React.Fragment>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'} >
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <div className={'section-title-wrap'}>
                                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                    Overtime Request
                                </Typography>
                                <Typography>ID: {props.RequestData.id}</Typography>
                            </div>
                        </div>
                        <div className={'action'}>
                            {/* <span><Button variant="contained" color="primary" onClick={props.HandleOnNewRequest} >New Request</Button></span> */}
                            {props.IsReviewer &&
                            <>
                                {(parseInt(props.RequestData.status) === 1) &&
                                <>
                                    <Button variant="contained" size="small"  color="primary" onClick={()=>props.HandleOnAction(props.RequestData.id,'Approve')}>Approve</Button>
                                    <i> </i><Button variant="contained" size="small"  color="secondary" onClick={()=>props.HandleOnAction(props.RequestData.id,'Reject')}>Reject</Button>
                                </>
                                }
                                {(parseInt(props.RequestData.status) === 2) &&

                                    <Button variant="contained" size="small"  color="secondary" onClick={()=>props.HandleOnAction(props.RequestData.id,'unapprove')}>UnApprove</Button>
                                }
                            </>
                            }
                            {(props.Logged_user === props.RequestData.request_user_id) &&
                            <>
                                {(parseInt(props.RequestData.status) !== 4) &&
                                <>
                                    <Button variant="contained" color="primary" size="small" onClick={()=>props.HandleOnAction(props.RequestData.id,'Edit')}>Edit</Button>
                                    <i> </i>
                                    <Button  variant="outlined" color="primary" size="small" onClick={()=>props.HandleOnAction(props.RequestData.id,'Cancel')}>Cancel</Button>
                                </>
                                }
                                {(props.ViewTokenButton) &&
                                <>
                                    <i> </i>
                                    <Button  variant="outlined" color="primary" size="small" onClick={()=>props.HandleOnAction(props.RequestData.id,'Send Review Token')}>Send Review Reminder</Button>
                                </>
                                }
                            </>
                            }
                            <i> </i>
                            {(!props.AllRequestView) &&
                                <Button variant="outlined" size="small" onClick={()=>props.HandleOnAction(props.RequestData.id,'Add Comment')}>Comment</Button>
                            }
                        </div>
                    </div>
                </div>
                <div className="section-body">
        <Paper>
            <div className="timesheet_list-summary">
                <div className="p-1rem summary box-details">
                    <ul className="list-standard list-ul-shrink">
                        <li className="list-item">
                            <label>Staff Name </label>
                            <span className="value">{formatName(props.RequestData.user_first_name,null,props.RequestData.user_last_name) }</span>
                        </li>
                        <li className="list-item">
                            <label>Request Type </label>
                            <span className="value">{props.RequestData.type_name}</span>
                        </li>
                        <li className="list-item">
                            <label>Week </label>
                            <span className="value">{props.RequestData.start_date} - {props.RequestData.end_date}</span>
                        </li>
                        <li className="list-item">
                            <label>Duration </label>
                            <span className="value">{props.RequestData.hours.toFixed(2)} hours</span>
                        </li>
                        <li className="list-item">
                            <label>Status </label>
                            <span className="value">{props.RequestData.request_status_name}</span>
                        </li>
                        {props.RequestData.attachment_id && 
                        <li className="list-item">
                              <AttachmentIcon /> 
                            <a href = {image_url} > {_cs.formatFileName(attachment_name, 19)} </a>
                        </li>
                        }
                    </ul>
                    <ul className="list-standard list-ul-grow list-ul-border-l">
                        <li className="list-item list-item-block">
                            <label> Reason </label>
                            <span className="value">
                                {props.RequestData.description}    
                            </span>
                        </li>
                        { (recipients != null) &&
                                    <li className="list-item list-item-block">
                                        <label>Email Recipients </label>
                                        <span className="value">
                                          {(recipients != null) && Object.keys(recipients).map((ry,i) =>
                                         <><span className="pill">{formatName(recipients[ry].user_first_name, null, recipients[ry].user_last_name)}{recipients[ry].rev}</span></>

                                         )}
                                        </span>
                                    </li>
                        }
                        <li className="list-item list-item-bottom-info">
                            <div>
                                <i><strong>Submitted by : </strong>
                                {formatName(props.RequestData.user_first_name,null,props.RequestData.user_last_name)}
                                &nbsp;&nbsp;on {props.RequestData.created_on} </i>
                                {(props.RequestData.updated_by && props.RequestData.updated_by > 0 ) &&
                                <>
                                    &nbsp;|<i>
                                        <strong> Modified by: </strong>{formatName(props.RequestData.mby_first_name,null,props.RequestData.mby_last_name)} on {props.RequestData.updated_on}
                                    </i>
                                </>
                                }
                                {(props.RequestData.reviewed_by && props.RequestData.reviewed_by > 0) &&
                                <>
                                    &nbsp;|<i>
                                        <strong> Reviewed by: </strong>{formatName(props.RequestData.rby_first_name,null,props.RequestData.rby_last_name)} on {props.RequestData.reviewed_on}
                                    </i>
                                </>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {(props.RequestData.request_user_id === props.Logged_user) &&
                 <div >
                    <Alert severity="info">DISCLAIMER: Submitting an Overtime Request does NOT guarantee overtime pay unless it is specified in your employment/contract terms. An Overtime request is intended just to top up your weekly limit. Please contact your Supervisor/PM or HR/Finance department if you have any questions. </Alert>
                </div>
            }
            <Comment Comments={_R.type(props.CommentsHistory) !== 'Undefined' ? props.CommentsHistory : []} />
            <ChangeHistory History = {_R.type(props.ChangeLog) !== 'Undefined' ? props.ChangeLog :[]} />
        </Paper>
    </div>
    </div>

    {props.Action === 'Edit' &&
          <AddOvertime
                Open = {props.EditPopup}
                EditId = {props.RequestId}
                Recipients = {props.Recipients}
                Approvers = {props.Approvers}
                Custom_recipients = {props.Custom_recipients}
                UpdateParent = {props.UpdateParentOnEditSuccess}
                UpdateParentOnAddSuccess = {props.UpdateParentOnEditSuccess}
                Doj = {props.Doj}
            />
        }

    <DialogBoxMedium
        Open={props.CommentBox}
        Cancel={props.Close}
        ButtonName='Yes'
        OnSubmit={parseInt(props.RequestId) !== 0 ? ()=>props.ReviewRequest(props.RequestId,props.Action):''}
        Title={props.CommentTitle}
        Content={<DialogComponent FormProps={props} />}
    />
    <CustomizedSnackbars
        open={props.SnackBarOpen}
        variant={props.SnackBarType}
        message={props.SnackBarMessage}
        handleClose={props.SnackBarClose}
    />
    </React.Fragment>
    )
};

