import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { ucwords } from 'bellajs';
import { formatName } from '../../common/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import * as _R from 'ramda'

const StyledTable = styled(Table)({
    width: '100%',
});
const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    maxWidth: 'none',
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
}));
const StyledGrid = styled(Grid)({
    minHeight: 'calc(100vh - 100px)'
});
const StyledTypography = styled(Typography)({
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    borderRight: '1px solid #eee',
    borderBottomRightRadius: 0,
    padding: 6 * 2,
});

export const TimeOffDetailsViewComponent = (props) => {
    const { classes, staffDetails } = props;
    const [showPTODetails, setShowPTODetails] = React.useState(0);
    const viewPTODetails = (value) => {
        let show = value ? 0 : 1;
        setShowPTODetails(show);
    };

    return (
        <React.Fragment>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'}> 
                    <div className={'section-header'}>
                        <div className={'col-left stretched'}>
                            {(props.listView)?
                                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>:<></>
                            }
                            <div className={'steps-boxed-input st-HSpacingL_1'}>
                                <Grid container className={'timeoff-filter'}>
                                    <Grid item md={6} >
                                        <Select
                                            variant='standard'
                                            value={props.selectedYear}
                                            inputProps={{
                                                name: 'year',
                                                id: 'year',
                                            }}
                                            onChange = {props.HandleOnChange}>
                                            {props.years.map((year, i) =>
                                                <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item md={6}> 
                                        <Button variant="contained" color="primary" size="small" onClick={props.getTimeOffDetails}>Filter</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>  
                <div className={'section-body'}>
                    <Paper style={{'width': '100%', 'flexGrow': '1' }} className={'mb-1'}>
                        <StyledGrid container justify="space-between" alignItems="stretch" spacing={0}>
                            {(typeof(staffDetails) != 'undefined' && staffDetails) ? 
                                <Grid  item xs={12} md={3}>
                                    <StyledTypography component="div" className={'profile-view'} >
                                        <div className={'profile-view__left-header'}>
                                            <div className={'porfile-view__avatar'}>
                                                <Avatar className={'staff_avatar'}>{staffDetails.first_name.charAt(0)+staffDetails.last_name.charAt(0)}</Avatar>
                                            </div>
                                            <div className={'profile-view__basics'}>
                                                <h1 className={'profile-view__basics-item item-title'}>
                                                    {_R.isNil(staffDetails.salutation)?formatName(staffDetails.first_name,null,staffDetails.last_name):staffDetails.salutation+' '+formatName(staffDetails.first_name,null,staffDetails.last_name)}
                                                </h1>
                                                <div className={'profile-view__basics-item item-designation'}>
                                                    {staffDetails.staff_type ? ucwords(staffDetails.staff_type) : null }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'profile-view__left-body'}>
                                            <div className={'profile-view__basics_default'}>
                                                <div className={'profile-view__basics__main'}>
                                                    <div className={'profile-view__basics_default-item item-role'}>
                                                        <label>Staff ID</label><span className={"value"}>{staffDetails.staff_id}</span>
                                                    </div>
                                                    <div className={'profile-view__basics_default-item item-date_joined'}>
                                                        <label>Date Joined</label><span className={"value"}>{staffDetails.date_joined}</span>
                                                    </div>
                                                    { staffDetails.status_text.toLowerCase() === 'active'?
                                                        <div className={'profile-view__basics_default-item item-status status-active'}>
                                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span></div>
                                                    :
                                                        <div className={'profile-view__basics_default-item item-status status-inactive'}>
                                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={'profile-view__basics_default-item item-mainproject'}>
                                                    <label>{"Experience as on "+props.onDate}</label>
                                                    <span className={"value"}>{props.experience.years+' Years '+props.experience.months +' months'}
                                                    </span>
                                                </div>
                                                { typeof(props.ptoExperience) != 'undefined' &&
                                                    <div className={'profile-view__basics_default-item item-mainproject'}>
                                                    <label>{"PTO Eligible Experience as on "+ props.onDate}</label>
                                                    <span className={"value"}>
                                                        {props.ptoExperience.years+' Years '+props.ptoExperience.months +' months'}
                                                    </span>
                                                    </div>
                                                }
                                                <div className={'profile-view__basics_default-item item-mainproject'}>
                                                    <Table className={'holiday-table no-left-pad'} size="small" aria-label="a dense table">
			                                            <TableHead>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" className={'section-title'}>Holidays </TableCell>
                                                                <TableCell component="th" scope="row" align="right">In Hours</TableCell>
                                                                <TableCell component="th" scope="row" align="right">In Days</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="td" scope="row">Maximum number of holidays</TableCell>
                                                                <TableCell component="td" scope="row" align="right">{(props.holidayMax * props.dailyMinimum).toFixed(2)}</TableCell>
                                                                <TableCell component="td" scope="row" align="right">{parseFloat(props.holidayMax).toFixed(2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="td" scope="row">Holidays used in this Year</TableCell>
                                                                <TableCell component="td" scope="row" align="right">{parseFloat(props.holidayTaken).toFixed(2)}</TableCell>
                                                                <TableCell component="td" scope="row" align="right">{(props.holidayTaken/props.dailyMinimum).toFixed(2)}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                </StyledTypography>
                            </Grid>
                        :<></>
                        }
                        <Grid  item xs={12} md={9}>
                            <Grid container alignItems="stretch" spacing={3} className={"p-20"}>
                                {props.timeoffTypes.map((value,index)=><>{(value['full_details'])?
                                    <Grid  item md={12}>
                                        <Paper>
                                            <StyledTable  size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" className={'section-title'}> {value['to_type']} </TableCell>
                                                    <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                    <TableCell component="th" scope="row" align="right" width="150" >Days</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            { showPTODetails ?
                                                value['full_details'].map((v,i) =>
                                                <TableBody>
                                                    <TableRow>
                                                    <TableCell component="td" scope="row">{ v['label'] } 
                                                        {(i === 0 && value['pto_info']) &&
                                                            <StyledTooltip
                                                                title={value['pto_info'].map((k,v)=><p>{
                                                                    (k.eligible_hours).toFixed(2) +" (Hours)"+" * "+(('months' in k)? k.months +"(months)":('pay_periods' in k)?k.pay_periods+"(pay periods)":('days' in k)?k.days+" (days)":"")+" = "+(k.total_hours).toFixed(2)+" (Hours) "
                                                                    }
                                                                    </p>)} 
                                                                    arrow
                                                            >
                                                                <InfoOutlinedIcon color="primary" fontSize='small' />
                                                            </StyledTooltip>
                                                        }
                                                        </TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={ (v['hours'] < 0)  ? 'error' : ''}>{parseFloat(v['hours']).toFixed(2)}</TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={ (v['days'] < 0)  ? 'error' : ''}>{parseFloat(v['days']).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                    {((value['full_details'].length - 1) === i) &&
                                                        <TableRow>
                                                            <TableCell component="td" scope="row" colSpan="3" align="right"> 
                                                                {(value['adjustments'] && value['adjustments'].length == 0) &&
                                                                    <Button color="primary" variant="outlined" size="small" className={'btn-white'} onClick={()=> viewPTODetails(showPTODetails)}>Hide detailed view</Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                                )
                                            :
                                                value['details'].map((val,id) =>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="td" scope="row">{val['label']}</TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={ (val['hours'] < 0)  ? 'error' : ''}>{parseFloat(val['hours']).toFixed(2)}</TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={ (val['days'] < 0)  ? 'error' : ''}>{parseFloat(val['days']).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                    {((value['details'].length - 1) === id) &&
                                                        <TableRow>
                                                            <TableCell component="td" scope="row" colSpan="3" align="right">
                                                                <Button color="primary" size="small" variant="outlined" className={'btn-white'} onClick={()=> viewPTODetails(showPTODetails)}>Show detailed view</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                                )
                                            }
                                            </StyledTable>
                                            {(value['adjustments'] && value['adjustments'].length > 0 && showPTODetails) ?
                                                <div className={"mt-1"}>
                                                    <StyledTable  size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" >
                                                                    <Typography variant="button" display="block" gutterBottom>PTO Adjustment/Carry forward</Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">Note</TableCell>
                                                                <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(value['adjustments']).map((row) =>
                                                                <TableRow>
                                                                    <TableCell component="td" scope="row">{row.category}</TableCell>
                                                                    <TableCell component="td" scope="row">{row.note} </TableCell>
                                                                    <TableCell component="td" scope="row" align="right">{parseFloat(row.hours).toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            <TableRow>
                                                                <TableCell component="td" scope="row" colSpan={2}><b>Total</b> </TableCell>
                                                                <TableCell component="td" scope="row" align="right">{parseFloat(value['adjustmentshours']).toFixed(2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="td" scope="row" colSpan="3" align="right">
                                                                    <Button color="primary" variant="outlined" size="small" className={'btn-white'} onClick={() => viewPTODetails(showPTODetails)}>Hide detailed view</Button>

                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </StyledTable>
                                                </div>
                                                : <></>
                                            }
                                      </Paper>
                                    </Grid>
                                :
                                <> </>
                                }</> )}
                                <Grid  item xs={12} >
                                    <Grid container alignItems="stretch" spacing={3} className={"p-20"}>
                                        {props.timeoffTypes.map((value,index)=><>{(!value['full_details'])?
                                            <Grid  item md={12}>
                                                <Paper>
                                                    <StyledTable  size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" className={'section-title'}> {value['to_type']} </TableCell>
                                                                <TableCell component="th" scope="row"  align="right" width="150">Hours</TableCell>
                                                                <TableCell component="th" scope="row"  align="right" width="150" >Days</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {_R.type(value.details) !== 'Undefined' && value.details.map((timeoff_details)=>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="td" scope="row"> {timeoff_details['label']} </TableCell>
                                                                <TableCell component="td" scope="row" align="right"> {(timeoff_details['hours'] != 'N/A') ? parseFloat(timeoff_details['hours']).toFixed(2) : 'N/A'} </TableCell>
                                                                <TableCell component="td" scope="row" align="right"> {(timeoff_details['days'] != 'N/A') ? parseFloat(timeoff_details['days']).toFixed(2) : 'N/A'} </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                        )}
                                                    </StyledTable>
                                                    {
                                                        (value['adjustments'] && value['adjustments'].length > 0) ?
                                                                <div className={"mt-1"}>
                                                                    <StyledTable size="small" aria-label="a dense table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell component="th" scope="row" colspan={3} className={'section-title'}>FTO Adjustments</TableCell>
                                                                            </TableRow>
                                                                        <TableRow>
                                                                            {value['adjustment_period'] === 'payperiod' ?
                                                                                <TableCell component="th" scope="row">Pay Period</TableCell>
                                                                                : <><TableCell component="th" scope="row">ADJUSTMENT/CARRY FORWARD</TableCell></>}
                                                                            <TableCell component="th" scope="row">Note</TableCell>
                                                                            <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                                        </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {(value['adjustments']).map((row) =>
                                                                                <TableRow>
                                                                                    {value['adjustment_period'] === 'payperiod' ?
                                                                                    <TableCell component="td" scope="row">{row.payperiod_sdate + ' - ' + row.payperiod_edate}</TableCell>
                                                                                    : <><TableCell component="td" scope="row">{row.category}</TableCell></>}
                                                                                    <TableCell component="td" scope="row">{row.note} </TableCell>
                                                                                    <TableCell component="td" scope="row" align="right">{parseFloat(row.hours).toFixed(2)}</TableCell>
                                                                                </TableRow>)
                                                                            }
                                                                            <TableRow>
                                                                                <TableCell component="td" scope="row" colSpan={2}><b>Total</b> </TableCell>
                                                                                <TableCell component="td" scope="row" align="right">{parseFloat(value['adjustmentshours']).toFixed(2)}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </StyledTable>
                                                                </div>
                                                                : <></>
                                                        }
                                                </Paper>
                                            </Grid>
                                        :
                                        <> </>
                                        }
                                    </>
                                    )}
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                        </StyledGrid>
                    </Paper>
                </div>
		            <Paper style={{'width': '100%'}}> 
                        <Grid container  justify="space-between" alignItems="stretch" spacing={0} >
                            <div className="p1">
                                <h6 class="MuiTypography-root section-title MuiTypography-h6">Log</h6>
                            </div>
                	        <StyledTable size="small" border={1}>
			                    <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row"> </TableCell>
                                        <TableCell component="th" scope="row"> </TableCell>
                                        {Object.keys(props.accrualsTimeoff).map((key)=> <>
                                                <TableCell component="th" scope="row" colspan={(props.accruals[0][props.accrualsTimeoff[key]].hours != 'NA') ? 2 : 1}> {props.accrualsTimeoff[key]}</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Pay Period</TableCell>
                                        <TableCell component="th" scope="row">Status </TableCell>
                                        {Object.keys(props.accrualsTimeoff).map((key)=> <>
                                            {(props.accruals[0][props.accrualsTimeoff[key]].hours != 'NA') && <TableCell component="th" scope="row" align="right"> Accrued (In Hours)</TableCell>}
                                            <TableCell component="th" scope="row" align="right"> Used (In Hours)</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                            <TableBody>
				            { props.accruals && Object.keys(props.accruals).map((key) =>
                                <TableRow>
                                    <TableCell component="td" scope="row">{props.accruals[key].payperiod_sdate+' - '+props.accruals[key].payperiod_edate}</TableCell>
                                    <TableCell component="td" scope="row">{props.accruals[key].status}</TableCell>
                                    {Object.keys(props.accrualsTimeoff).map((k)=> <>
                                            {(props.accruals[0][props.accrualsTimeoff[k]].hours != 'NA') && <TableCell component="td" scope="row" align="right">{(props.accruals[key][props.accrualsTimeoff[k]].hours != 'NA') ? parseFloat(props.accruals[key][props.accrualsTimeoff[k]].hours).toFixed(2) : ''}</TableCell>}
                                            <TableCell component="td" scope="row" align="right">{(props.accruals[key][props.accrualsTimeoff[k]].taken != 0) ? parseFloat(props.accruals[key][props.accrualsTimeoff[k]].taken).toFixed(2) : ''}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            )}
				            { (props.accruals && (Object.keys(props.accruals).length>0)) && 
                                <TableRow>                                    
                                    <TableCell component="td" scope="row" align="right" colspan={2}><b>Total</b></TableCell>
                                    {Object.keys(props.accrualsTimeoff).map((k)=> <>
                                                {(props.accruals[0][props.accrualsTimeoff[k]].hours != 'NA') && <TableCell component="td" scope="row" align="right">{Object.keys(props.accruals).map((key) =>  { return  props.accruals[key][props.accrualsTimeoff[k]].hours?parseFloat(props.accruals[key][props.accrualsTimeoff[k]].hours):0;}).reduce((a,b) => { return  a+b;}).toFixed(2)
                                                }</TableCell>}
                                                <TableCell component="td" scope="row" align="right">{Object.keys(props.accruals).map((key) =>  { return props.accruals[key][props.accrualsTimeoff[k]].taken?parseFloat(props.accruals[key][props.accrualsTimeoff[k]].taken):0;}).reduce((a,b) => { return a+b;}).toFixed(2)
                                                }</TableCell>
                                                </>
                                    )}
                                </TableRow>
                            }                           
                            </TableBody>
                            </StyledTable>
                         </Grid>
                    </Paper> 
        </div>
    </React.Fragment>
    )
};
