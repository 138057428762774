import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

export class LoginInformationForm extends React.Component{
    render(){
        return (
            <>
            <Grid container spacing={3} md={6}>

                <Grid item xs={12} md={6}>
                    <FormControl error={this.props.Errors.auth_provider && true} variant="standard">
                        <InputLabel htmlFor="auth_provider" required>Auth Type</InputLabel>
                        <Select
                            value={(typeof this.props.FormFields.auth_provider !== 'undefined') ? this.props.FormFields.auth_provider : ''}
                            onChange={this.props.HandleOnChange}
                            inputProps={{
                                name: 'auth_provider',
                                id: 'auth_provider',
                            }}>
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof this.props.FormInputs.auth_providers !== 'undefined' && this.props.FormInputs.auth_providers.map((v, i) =>
                                <MenuItem value={v.id} key='auth_provider-{v.id}'>{v.display_name}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{this.props.Errors.auth_provider}</FormHelperText>
                    </ FormControl>

                        <FormControl>
                            <TextField variant="standard"
                                id="username"
                                name="username"
                                label="Username"
                                type="text"
                                required
                                value={(typeof this.props.FormFields.username !== 'undefined') ? this.props.FormFields.username : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.username && true}
                                helperText={this.props.Errors.username}
                            />
                        </ FormControl>
                {this.props.ShowPassword &&
                    <>
                        <FormControl>
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                required
                                value={(typeof this.props.FormFields.password !== 'undefined') ? this.props.FormFields.password : null}
                                onChange={this.props.HandleOnChange}
                                onBlur={this.props.ValidatePassword}
                                error={this.props.Errors.password && true}
                                helperText={this.props.Errors.password}
                            />
                        </ FormControl>
                    
                        <FormControl>
                            <TextField
                                id="re_password"
                                name="re_password"
                                label="Retype Password"
                                type="password"
                                required
                                value={(this.props.FormFields.re_password !== 'undefined') ? this.props.FormFields.re_password : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.re_password && true}
                                helperText={this.props.Errors.re_password}
                            />
                        </ FormControl>
                    </>
                }
                </Grid>
            </Grid>

        </>        
        )
    }
};
