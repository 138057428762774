import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';

export const WorkLocationComponent = (props) => {
    const locations = (typeof props.ProjectLocations !== 'undefined') ? props.ProjectLocations : [];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <React.Fragment>
                <TableContainer >
                    <Table aria-label="simple table"  >
                        <TableHead>
                            <TableRow>
                                <TableCell><Checkbox
                                    onChange={props.HandleCheckboxChange}
                                    inputProps={{
                                        name: 'select_all_locations',
                                        id: 'select_all_locations',
                                    }}
                                    checked={props.SelectAllLocations}
                                    color="primary"
                                />
                                </TableCell>
                                <TableCell> Location Name </TableCell>
                                <TableCell> Timesheet </TableCell>
                                <TableCell> Default </TableCell>
                                <TableCell> Created By </TableCell>
                                <TableCell> Created On </TableCell>
                                <TableCell> Modified By </TableCell>
                                <TableCell> Modified On </TableCell>
                                <TableCell> Type </TableCell>
                                <TableCell> Status </TableCell>
                            </TableRow>
                        </TableHead>
                        {(locations.length === 0) &&
                            <TableBody>
                                <TableRow>
                                    <TableCell width="200">No location found</TableCell>
                                </TableRow>
                            </TableBody>
                        }
                        <TableBody>
                            {Object.keys(locations).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                <>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                onChange={(e) => props.HandleCheckboxChange(e, locations[row].id)}
                                                inputProps={{
                                                    name: 'location_row_select',
                                                    id: 'location_row_select',
                                                }}
                                                checked={(props.SelectedLocations.includes(locations[row].id)) ? true : false}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell> {locations[row].location}</TableCell>
                                        <TableCell>{locations[row].timesheet === 1 ? "Enabled" : "Disabled"}</TableCell>
                                        <TableCell>{locations[row].default === 1 ? "Yes" : "No"}</TableCell>
                                        <TableCell>{locations[row].created_by_username}</TableCell>
                                        <TableCell>{locations[row].created_on}</TableCell>
                                        <TableCell>{locations[row].modified_by_username}</TableCell>
                                        <TableCell>{locations[row].modified_on}</TableCell>
                                        <TableCell>{locations[row].type}</TableCell>
                                        <TableCell>{locations[row].status}</TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {<TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={locations.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                }
            </React.Fragment>
        </>
    )
};
