import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';


export const ProjectReviewStatus = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
      {(typeof props.MultipleReview !== 'undefined' && props.MultipleReview === 1) &&
      <>
      <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Reviewers</TableCell>
                <TableCell>Reviewed By</TableCell>
                <TableCell>Reviewed On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_R.type(props.ProjectReviewStatus) !== 'Undefined' && !_R.isNil(props.ProjectReviewStatus) && (props.ProjectReviewStatus).map((item) =>
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row"> {item.project_path}</TableCell>
                  <TableCell component="th" scope="row"> {item.status_label}</TableCell>
                  <TableCell component="th" scope="row"> {item.reviewers}</TableCell>
                  <TableCell component="th" scope="row"> {item.reviewer}</TableCell>
                  <TableCell component="th" scope="row">{item.reviewed_on}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
            </>
        }
    </div>
  );
}
