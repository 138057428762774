import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { GroupViewComponent } from './GroupView.Component';

export class GroupViewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            groupDetails : [],
            errors : [],
            showLoader : true,
            groupId :0,
            members : [],
            group_policy : [],
            all_policies : [],
            snackbarOpen: false,
            valid: false,
            snackbarMsg: null,
            error_message: null,
            editPermission : false,
            taskCount : 0,
            popupType: "",
            open: false,
            dataLoaded : false,
            formFields:[],
            selectedUsers:[],
            workPolicy: []
        };
        this.handleEditClick = this.handleEditClick.bind(this);
        this.updateParentOnSuccess = this.updateParentOnSuccess.bind(this);
        this.addMember = this.addMember.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.submitMember = this.submitMember.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount(){
        if (this.props.match) {
            var id = (this.props.match.params.id) ? this.props.match.params.id: false;
            this.setState({ groupId : id } , function() {
                this.getGroupDetails(this.state.groupId);
            });
        }       
    }

    handleEditClick = (id) => {
        this.setState({
            popupType: "edit",
            open: true,
        });
    };

    updateParentOnSuccess = (status) => {
        if(status.success){
            this.setState({
                open : false,
                snackbarOpen : true,
                snackbarMsg : status.success_message,
            },()=>{
                this.getGroupDetails(this.state.groupId);
            }
            );}       
    }
    
    getGroupDetails = (groupId) => {
        let apiEndpoint = 'api/user/view-group';
        this.setState({
            showLoader : true,
        });
        return httpServices.post(apiEndpoint,{id : groupId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({ 
                            groupDetails : response.data.group_details,
                            users:response.data.user_list,
                            members : response.data.members,
                            group_policy : response.data.group_policy,
                            all_policies : response.data.all_policies,
                            showLoader : false,
                            dataLoaded : true,
                            permission : true,
                            editPermission : response.data.edit_permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

     onClose = () => {
        this.setState({
            open: false,
        });
    };
    
    onUpdate = (id) => () => {
        let GroupID = this.state.groupId;
        let apiEndpoint = 'api/staff/work/workpolicygroup';
        return httpServices.post(apiEndpoint, {'groupID':GroupID, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            allPolicies: response.data.all_policies,
                            formFields: response.data.work_policy,
                            isDelete: false,
                            groupID: GroupID
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            allPolicies: response.data.all_policies,
                            isEditable: false,
                            groupID: GroupID
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    addMember = () => {
        this.setState({
            popupType: "add",
            open: true,
        });
    };

    confirmDelete = () => {
        this.setState({
            popupType: "delete",
            open: true,
        });
    };

    submitMember = () => {
        if(this.state.formFields.user) {
            return httpServices
                .post("api/user/add-group-member", {id: this.state.groupId, user: this.state.formFields.user, manager: this.state.formFields.manager})
                .then((response) => {
                    if (response.data.success) {
                        this.setState({ open: false,
                            snackbarOpen: true,
                            snackbarMsg:response.data.success_message,
                            formFields:[]},
                            ()=>{
                                this.getGroupDetails(this.state.groupId);
                            }
                        );
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        if (field === 'manager') {
            ff[field] = evt.target.checked;
        }
        this.setState({
            formFields: ff,
        });
    };
    handleSelection = (evt,value) => {
        let users = this.state.selectedUsers;
        let user = evt.target.value;
        if(user==='all') {
            if(value){
                users.push('all');
                this.state.members.forEach((row) => {
                    if(users.indexOf(row.user_id)===-1){
                        users.push(row.user_id);
                    }
                });
            }
            else{
                users=[];
            }
        }
        else{
            if(value){
                users.push(parseInt(user));
            }
            else{
                let index = users.indexOf(parseInt(user));
                if(index!==-1){
                    users.splice(index,1);
                }
                index=users.indexOf('all');
                if(index!==-1){
                    users.splice(index,1);
                }
            }
        }
        this.setState({
            selectedUsers: users,
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    
    deleteMember = () => {
        if(this.state.selectedUsers) {
            let users = this.state.selectedUsers;
            let index = users.indexOf('all');
            if(index!==-1){
                users.splice(index,1);
            }
            return httpServices
                .post("api/user/delete-group-members", {
                    id: this.state.groupId, 
                    users: users,                  
                })
                .then((response) => {
                    if (response.data.success) {
                        this.setState({ 
                            open: false,
                            selectedUsers:[],
                            snackbarOpen: true,
                            snackbarMsg:response.data.success_message
                        },()=>{
                            this.getGroupDetails(this.state.groupId);
                        });
                    } 
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };

    render()
    {
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <GroupViewComponent
                            GroupDetails={this.state.groupDetails}
                            Members={this.state.members}
                            Group_policy={this.state.group_policy}
                            All_policies={this.state.all_policies}
                            EditPermission={this.state.editPermission}
                            AddMember={this.addMember}
                            Open = {this.state.open}
                            OnClose = {this.onClose}
                            OnUpdate = {this.onUpdate}
                            PopupType = {this.state.popupType}
                            Users={this.state.users}
                            HandleOnChange={this.HandleOnChange}
                            FormFields={this.state.formFields}
                            SubmitMember={this.submitMember}
                            HandleEditClick={this.handleEditClick}
                            GroupId={this.state.groupId}
                            UpdateParentOnSuccess = {this.updateParentOnSuccess}
                            SnackbarOpen={this.state.snackbarOpen}
                            SnackbarMsg={this.state.snackbarMsg}
                            HandleSelection={this.handleSelection}
                            SnackbarClose={this.snackbarClose}
                            SelectedUsers={this.state.selectedUsers}
                            DeleteMember={this.deleteMember}
                            ConfirmDelete={this.confirmDelete}
                            WorkData={this.props.WorkData}
                        />:
                        <ErrorPage/>
                    }</>
                    :<></>
                }
            </>
        )
    }
}
