import React from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; 
import Grid from '@mui/material/Grid';
import Table from '@mui/material//Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { formatName } from '../common/common';
import Badge from '@mui/material/Badge';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as R from 'ramda';
import 'tippy.js/dist/tippy.css';
import { TableHead } from '@mui/material';

const OLD_URL = process.env.REACT_APP_OLD_URL;


export const DashboardComponent = (props) => {
    const customStyle={
        container: {
            maxHeight: 200,
        }
    }
    props.WorkLocationDays.forEach((location)=>{
        if (location.location_color_code){
            customStyle['worklocation_' + location.location_id] = {
                "&::before": {
                    color: location.location_color_code
                }
            };
        }
    });
    const useStyles = makeStyles(customStyle);
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [expandedSL, setExpandedStaffLeave] = React.useState('expandedStaffLeave');
    const handleStaffLeaveChange = (panel) => (event, isExpanded) => {
        setExpandedStaffLeave(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                    <FullCalendar
                        plugins={[ dayGridPlugin, interactionPlugin ]}
                        buttonText={{today:'Today'}}
                        initialView={"dayGridMonth"}
                        events={props.events}
                        eventClick = {(arg)=> { 
                            if(R.type(arg.event.extendedProps.mode) !== 'Undefined' && (arg.event.extendedProps.mode === 'hours' || arg.event.extendedProps.mode === 'holiday')) {
                                props.viewTimeEntry(arg.event.start);
                            }
                        }
                        }
                        eventMouseEnter = {(arg)=> {
                            if(R.type(arg.event.extendedProps.mode) === 'Undefined') {
                                document.querySelectorAll('.day-entry-tooltip').forEach(node => { if(node._tippy) node._tippy.disable(); });
                                document.querySelectorAll('.day-tooltip').forEach(node => { if(node._tippy) node._tippy.disable(); });
                            }
                        }
                        }
                        eventMouseLeave = {(arg)=> {
                            if(R.type(arg.event.extendedProps.mode) === 'Undefined') {
                                document.querySelectorAll('.day-entry-tooltip').forEach(node => { if(node._tippy) node._tippy.enable(); });
                                document.querySelectorAll('.day-tooltip').forEach(node => { if(node._tippy) node._tippy.enable(); });
                            }
                        }
                        }
                        aspectRatio = {1.75}
                        datesSet = {(arg)=>{props.handleDateChange(arg.view.activeStart,arg.view.activeEnd);}}
                        dateClick = {(arg)=>{props.viewTimeEntry(arg.date)}}
                        dayCellClassNames = {(arg) => {
                            let month = ''+(arg.date.getMonth()+1);
                            let date = ''+arg.date.getDate();
                            month = (month.length<2)? '0'+month : month;
                            date = (date.length<2)? '0'+date : date;
                            let date_str=arg.date.getFullYear()+'-'+month+'-'+date;
                            let class_array =[];
                            if(props.holidays.indexOf(date_str)> -1 ){
                                class_array.push('fc-holiday');
                            }
                           if (props.WorkLocationDays.length){
                                props.WorkLocationDays.forEach(location => {
                                    if (location.days.indexOf(date_str) > -1) {
                                        class_array.push("fc-worklocation");
                                        class_array.push(classes['worklocation_'+location.location_id]);
                                    }
                                });
                            }
                            let view_timeentry_date = false;
                            props.TimeentryPeriods.forEach(period => {
                                if (moment(date_str).isBetween(period.start_date, period.end_date, 'date', '[]')) {
                                    view_timeentry_date = true;
                                }
                            });
                            if (view_timeentry_date && props.timeEntryPermission){
                                if(props.entryDates.indexOf(date_str)> -1 ){
                                    class_array.push('day-entry-tooltip');
                                }else{
                                    class_array.push('day-tooltip');
                                }
                                class_array.push('link');
                            }
                            return class_array;
                            }
                        }
                        displayEventTime={false}
                        fixedWeekCount={false}
                    />
                    <div className="fullcalendar-details">
                        {props.WorkLocationDays.map((location) =>
                            <div className='legend'>
                                <div className={"icon fc-worklocation"} style={{'color':location.location_color_code}}></div>
                                <div className="worklocationName">{location.location_name}</div>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <h3>My Action Items</h3>
                    <>
                    {(props.myActions && props.myActions.length === 0 ) ?
                            <Grid item className="p1" md={12}>
                                <div >{"No action items"}</div>                                   
                            </Grid>                            
                        :
                        <>
                        {(props.myActions && 'submission' in props.myActions )?
                            <Accordion expanded={expanded === 'submission'} onChange={handleChange('submission')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="submission-content"
                                    id="submission-header"
                                >
                                    <span><a onClick={props.viewLink('/timesheet/submit')} className='link'>Timesheets to be Submitted</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.submission_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.submission.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{row.payperiod_sdate+' - '+row.payperiod_edate}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.status_name}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.submission.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No timesheets"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            {(props.myActions && 'timesheet' in props.myActions )? 
                            <Accordion expanded={expanded === 'timesheet'} onChange={handleChange('timesheet')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="timesheet-content"
                                    id="timesheet-header"
                                >
                                    <span><a onClick={props.viewLink('/timesheet/review')} className='link'>Timesheets to be Approved</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.timesheet_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.timesheet.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_firstname,null,row.user_lastname)}</TableCell>
                                                <TableCell component="th" scope="row">{row.payperiod_sdate+' - '+row.payperiod_edate}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.review_type}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.timesheet.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No timesheets"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            {(props.myActions && 'unsubmission' in props.myActions )? 
                            <Accordion expanded={expanded === 'unsubmission'} onChange={handleChange('unsubmission')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="unsubmission-content"
                                    id="unsubmission-header"
                                >
                                    <span>Unsubmitted Timesheets of Subordinates &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.unsubmission_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.unsubmission.map((row) =>
                                            <TableRow className={'link'} onClick={props.viewLink(row.link)}>
                                                <TableCell component="th" scope="row">{formatName(row.user_firstname,null,row.user_lastname)}</TableCell>
                                                <TableCell component="th" scope="row">{row.payperiod_sdate+' - '+row.payperiod_edate}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.unsubmission.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No records"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            {(props.myActions && 'offsiteRequests' in props.myActions)? 
                            <Accordion expanded={expanded === 'offsiteRequests'} onChange={handleChange('offsiteRequests')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="offsiteRequests-content"
                                    id="offsiteRequests-header"
                                >
                                    <span><a onClick={()=>props.reviewRequest('location')} className='link'>Work Location Change Requests to be Approved</a>&nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.offsiteRequests_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        {props.myActions.offsiteRequests.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.location}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.request_date}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.offsiteRequests.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No Work Location Change Requests"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            { (props.myActions && 'timeoffRequests' in props.myActions)? 
                            <Accordion expanded={expanded === 'timeoffRequests'} onChange={handleChange('timeoffRequests')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="timeoffRequests-content"
                                    id="timeoffRequests-header"
                                >
                                    <span><a onClick={()=>props.reviewRequest('timeoff')} className='link'>Time Off Requests to be Approved</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.timeoffRequests_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.timeoffRequests.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.request_date}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.timeoffRequests.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No time off requests"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }{ (props.myActions && 'overtimeRequests' in props.myActions)?
                            <Accordion expanded={expanded === 'overtimeRequests'} onChange={handleChange('overtimeRequests')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="overtimeRequests-content"
                                    id="overtimeRequests-header"
                                >
                                    <span><a onClick={()=>props.reviewRequest('overtime')} className='link'>Overtime Requests to be Approved</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.overtimeRequests_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.overtimeRequests.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.request_date}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.overtimeRequests.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No overtime requests"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            { (props.myActions && 'additional_pto_requests' in props.myActions)?
                            <Accordion expanded={expanded === 'additionalPtoRequests'} onChange={handleChange('additionalPtoRequests')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="apr-content"
                                    id="apr-header"
                                >
                                    <span><a onClick={()=>props.reviewRequest('additionalpto')} className='link'>Additional PTO Requests to be Approved</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.additional_pto_requests_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.additional_pto_requests.map((apr) =>
                                            <TableRow onClick={props.viewLink(apr.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(apr.user_first_name,null,apr.user_last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{apr.year}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.additional_pto_requests.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No additional PTO requests"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                            }
                            { (props.myActions && 'expenses' in props.myActions)?
                            <Accordion expanded={expanded === 'expenses'} onChange={handleChange('expenses')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="expenses-content"
                                    id="expenses-header"
                                >
                                    <span><a onClick={props.viewLink('/expenses/review')} className='link'>Expense Vouchers to be Approved</a> &nbsp;&nbsp;&nbsp;<Badge color="secondary"  badgeContent={props.myActions.expenses_count} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.expenses.map((row) =>
                                            <TableRow onClick={props.viewExpense(row.id)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right">{row.start_date+' - '+row.end_date}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.expenses.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No expense vouchers"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                            :<></>
                        }{(props.myActions && 'notSubmittedTimesheets' in props.myActions)?
                            <Accordion expanded={expanded === 'notSubmittedTimesheets'} onChange={handleChange('notSubmittedTimesheets')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="notSubmittedTimesheets-content"
                                    id="notSubmittedTimesheets-header"
                                >
                                    <span>{"Unsubmitted Timesheets of Project Members" } &nbsp;&nbsp;&nbsp;<Badge color="secondary" max={999} style={{paddingLeft:'5px'}}  badgeContent={props.myActions.notSubmittedTimesheets.length} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                <TableContainer className={classes.container}>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.notSubmittedTimesheets.map((row) =>
                                            <TableRow onClick={props.viewLink(row.link)} className={'link'}>
                                                <TableCell component="th" scope="row">{formatName(row.user_firstname,null,row.user_lastname)}</TableCell>
                                                <TableCell component="th" scope="row" align="right" >{row.payperiod_sdate+' - '+row.payperiod_edate}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.notSubmittedTimesheets.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No records"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        :<></>
                        }{(props.myActions && 'unsavedTimesheets' in props.myActions)?
                            <Accordion expanded={expanded === 'unsavedTimesheets'} onChange={handleChange('unsavedTimesheets')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="unsavedTimesheets-content"
                                    id="unsavedTimesheets-header"
                                >
                                    <span>{"Timesheets not saved" } &nbsp;&nbsp;&nbsp;<Badge color="secondary" max={999} style={{paddingLeft:'5px'}}  badgeContent={props.myActions.unsavedTimesheets.length} ></Badge></span>
                                </AccordionSummary>
                                <AccordionDetails>
                                <TableContainer className={classes.container}>
                                    <Table  size="small" aria-label="a dense table">
                                        <TableBody>
                                        { props.myActions.unsavedTimesheets.map((row) =>
                                            <TableRow>
                                                <TableCell component="th" scope="row">{formatName(row.first_name,null,row.last_name)}</TableCell>
                                                <TableCell component="th" scope="row" align="right" >{row.payperiod_sdate+' - '+row.payperiod_edate}</TableCell>
                                            </TableRow>
                                        )}
                                        { (props.myActions.unsavedTimesheets.length === 0) &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">{"No records"}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        :<></>
                        }
                        </>
                    }
                    </>
                    <br/>
                    <>
                        {(props.weekTimeoff && props.weekTimeoff.length === 0) ?
                            <Grid>
                            </Grid>
                            :
                            <>
                                {(props.weekTimeoff) ?
                                    <Accordion expanded={expandedSL === 'expandedStaffLeave'} onChange={handleStaffLeaveChange('expandedStaffLeave')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="submission-content"
                                            id="submission-header"
                                        >
                                            <span><b>Staff Time Off This Week</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Badge color="secondary" badgeContent={props.weekTimeoff.length} ></Badge></span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                <TableRow>
                                                            <TableCell component="th" scope="row">Name</TableCell>
                                                            <TableCell component="th" scope="row">Date</TableCell>
                                                            <TableCell component="th" scope="row">Time Off Type</TableCell>
                                                            <TableCell component="th" scope="row">Hours</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {props.weekTimeoff.map((row) =>
                                                        <TableRow onClick={props.viewLink(row.link)} className='link'>
                                                            <TableCell component="th" scope="row">{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                                                            {(row.start_date === row.end_date) ?
                                                                <TableCell component="th" scope="row" >{row.start_date} ({row.start_day})</TableCell>
                                                                :
                                                                <TableCell component="th" scope="row" >{row.start_date} - {row.end_date} ({row.start_day} - {row.end_day})</TableCell>
                                                            }
                                                            <TableCell component="th" scope="row" >{row.task_name}</TableCell>
                                                            <TableCell component="th" scope="row" >{row.hours}</TableCell>
                                                        </TableRow>
                                                    )}
                                                    {(props.weekTimeoff.length === 0) &&
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">{"No staff on leave"}</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                    : <></>
                                }
                            </>
                        }
                    </>
                </Grid>
                </Grid>         
        </React.Fragment>
    )
};
