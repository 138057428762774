import React from 'react';
import { TimesheetSummaryReportComponent } from './TimesheetSummaryReport.Component';
import { httpServices }  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as validations from '../../common/validate';

const formFields = {
    startDate : '',
    startDateMax : '',
    endDate : '',
    minEndDate : '',
    clientStatus : '1', // Default active
    client : '0',
    projectStatus : '1',
    staffStatus : [],
    nameFormat : '',
    includeTask : false,
    project : 0,
    staff_status_ids :[0],
    staff : '0',
}

const formInputs = {
    clientStatuses : [],
    projectStatuses : [],
    clients : [],
    projects : [],
    staffs : [],
    nameFormats : []
}

export class TimesheetSummaryReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formInputs: formInputs,
            formFields: formFields,
            errors : {},
            hasPermission : true,
            reportHeaders : {},
            reportData : {},
            loader : false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.userstatusChange = this.userstatusChange.bind(this);
        this.clientStatusChange = this.clientStatusChange.bind(this);
        this.clientChange = this.clientChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleStaffStatusChange = this.handleStaffStatusChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }
    componentDidMount() {
        this.index();
        window.scrollTo(0, 0);
    }
    index = () => {
        httpServices.get('api/report/hrreport',{})
            .then(response => {
                if (response.data.permission) {
                    let finputs = this.state.formInputs;
                    finputs.clientStatuses = response.data.client_statuses ? response.data.client_statuses : [];
                    finputs.clients = response.data.clients ? response.data.clients : [];
                    finputs.projectStatuses = response.data.project_statuses ? response.data.project_statuses : [];
                    finputs.nameFormats = response.data.name_formats ? response.data.name_formats : [];
                    let formFields = this.state.formFields;
                    formFields.startDate = response.data.start_date ? response.data.start_date : null;
                    formFields.endDate = response.data.end_date ? response.data.end_date : null;
                    formFields.startDateMax = response.data.end_date ? response.data.end_date : null;
                    formFields.minEndDate = response.data.start_date ? response.data.start_date : null;
                    formFields.nameFormat = response.data.name_format ? response.data.name_format : '';
                    formFields.staffStatusList = response.data.employee_statuses; //? response.data.employee_statuses : [];
                    this.setState({
                        formInputs: finputs,
                        formFields: formFields,
                    }, function() {
                        this.userstatusChange();
                        this.clientChange();
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
    }
    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let value = evt.target.value;
        if (field === 'includeTask') {
            value = evt.target.checked;
        }
        if (field === 'startDate') {
            ff['minEndDate'] = value;
        }
        if (field === 'endDate') {
            ff['startDateMax'] = value;
        }
        ff[field] = value;
        this.setState({
            formFields: ff
        }, function(){
            if (field === 'clientStatus') {
                this.clientStatusChange();
            }
            if (field === 'client' || field === 'projectStatus') {
                this.clientChange();
            }
            if ((field === 'startDate' || field === 'endDate') && (this.state.formFields.startDate && this.state.formFields.endDate)) {
                this.userstatusChange();        
            }
        });
    }
    formSubmit = () => {
        this.setState({
            loader : true,
        });
        let filterFields = this.state.formFields;
        filterFields.user_status = filterFields.staff_status_id;
        return httpServices.post('api/report/hrreport', filterFields)
            .then(response => {
                if (response.data && response.data.permission && !response.data.errors) {
                    if (response.data.report_data) {
                        this.setState({
                            reportHeaders : response.data.report_headers,
                            reportData : response.data.report_data,
                            loader : false,
                        })
                    }
                } else if (response.data.errors) {
                    this.setState({
                        loader: false,
                        errors: response.data.errors
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    userstatusChange = () => {
        let staffStatus = this.state.formFields.staff_status_ids;
        let data = {
            status_id : JSON.stringify(staffStatus),
            start_date : this.state.formFields.startDate,
            end_date : this.state.formFields.endDate,
        }
        httpServices.post('api/timesheet/gethrusers', data)
            .then(response => {
                if (response.data) {
                    let formInputs = this.state.formInputs;
                    formInputs.staffs = response.data.users;
                    this.setState({
                        formInputs : formInputs,
                    });
                } 
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    clientStatusChange = () => {
        let clientStatus = this.state.formFields.clientStatus;
        httpServices.post('api/client/listbystatus', {status_id : clientStatus})
            .then(response => {
                if (response.data) {
                    let formInputs = this.state.formInputs;
                    formInputs.clients = response.data.clients;
                    this.setState({
                        formInputs : formInputs,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    clientChange = () =>
    {
        let clientSelected = this.state.formFields.client;
        let projectStatus = this.state.formFields.projectStatus;
        httpServices.post('api/timesheet/getprojectsByClient', {client_id : clientSelected, project_status : projectStatus,action_from:'timesheet-summary-report'})
            .then(response => {
                if (response.data && response.data.projects) {
                    let formInputs = this.state.formInputs;
                    let formFields = this.state.formFields;
                    formFields.project = 0;
                    formInputs.projects = _ts.prepareTreeData(response.data.projects, 'projects', true);
                    this.setState({
                        formInputs : formInputs,
                        formFields : formFields,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let projects = [];
        let formFields = this.state.formFields;
        if (cNode.value === 'selectAll') {
            projects = 0;
        }
        else {
            if (sNodes.length < 1) {
                projects = 0;
            }
            else if (cNode.project_id) {
                projects = cNode.project_id;
            }
        }
        formFields['project'] = projects;
        this.setState({ formFields: formFields });
    }

    handleStaffStatusChange = (evt, value) => {
        let ff = this.state.formFields;
        let staff_status_ids = [];  
        let new_value = [];
        if (value) {
            let isAllinList = value.findIndex(i => i.user_status_name === "All Staff Status");
            if (isAllinList !== -1 && isAllinList === value.length-1) { 
                new_value = [ff.staffStatusList[0]];
                staff_status_ids.push(0);
            } else {
                value.forEach(function(item) { 
                    if (item.user_status_name !== "All Staff Status") {
                        new_value.push(item);
                        staff_status_ids.push(parseInt(item.user_status_id));
                    }
                })
            }
            if (value.length === 0) {
                new_value = [ff.staffStatusList[0]];
                staff_status_ids.push(0);
            }
            ff['staffStatus'] = new_value;
            ff['staff_status_ids'] = staff_status_ids;
        }
        this.setState({
            formFields: ff
        }, function(){
            this.userstatusChange();
        });
     }

    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('hrreport').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'startDate', value: this.state.formFields.startDate, type: 'date', otherOptions: { required: true } },
            { name: 'endDate', value: this.state.formFields.endDate, type: 'date', otherOptions: { required: true } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    render(){
        return(<>
            <CustomProgressBar
               loaderOpen = {this.state.loader}
            />
            {(this.state.hasPermission)
            ?
            <TimesheetSummaryReportComponent 
                FormInputs = { this.state.formInputs }
                FormFields = { this.state.formFields }
                HandleOnChange = { this.handleOnChange }
                FormSubmit = {this.formSubmit}
                Errors = {this.state.errors}
                HandleTreeChange = {this.handleTreeChange}
                ReportHeaders = {this.state.reportHeaders}
                ReportData = {this.state.reportData}
                HandleStaffStatusChange = {this.handleStaffStatusChange}
                ExportReports = {this.exportReports}
            />
            :<ErrorPage/>
            }
            </>
        )
    }
}
