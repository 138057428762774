import React from 'react';
import Cookies from 'universal-cookie';
import {ProjectsComponent} from './Projects.Component';
import { httpServices } from '../../services/httpServices';
import { history } from '../../helpers/history';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const filterValues = {
    status : 1,
    client : 0
};

const cookies = new Cookies();
export class ProjectsContainer extends React.Component{
    constructor(props) {
        let filters = filterValues;
        let filterCookie = cookies.get('filter');
        if (filterCookie) {
            if (filterCookie.project) {
                filters = filterCookie.project;
            } else {
                filterCookie.project = filterValues;
            }
        }
        else {
            filterCookie = {'project' : filterValues}
        }
        super(props);
        this.state = {
            permission : false,
            filterInputs : [],
            filterValues : filters,
            showLoader : true,
            projects :[],
            right: false,
            popupType : '',
            dialogOpen : false,
            snackbarOpen : false,
            snackbarMsg : '',
            deletePermission : false,
            editId : 0,
            editPermission : false,
            selfEditPermission : false,
            dataLoaded : false,
            loggedUser : 0,
            filterCookie: filterCookie,
            nameFormat : 'FIRSTLAST',
        };
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.addProjectClick = this.addProjectClick.bind(this);
        this.updateParentOnAddSuccess = this.updateParentOnAddSuccess.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.exportProjectList = this.exportProjectList.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
    }
   
    componentDidMount() {
        this.getProjectList();
    }    

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('view/' + id);
        }
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterValues;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterValues: f
        });
    }

    getProjectList = () => {
        let filterCookie = this.state.filterCookie;
        filterCookie.project = this.state.filterValues;
        cookies.set('filter', filterCookie, { path: '/' });
        this.setState({
            filterCookie : filterCookie,
            right: false,
            showLoader : true,
        });
        const data = this.state.filterValues;
        let apiEndpoint = 'api/project';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            projects : response.data.projects,
                            showLoader : false,
                            permission : response.data.permission,
                            dataLoaded:true,
                            filterInputs : response.data.filters,
                            selfEditPermission : response.data.self_edit,
                            editPermission : response.data.edit,
                            loggedUser : response.data.logged_user,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded:true
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    addProjectClick = (id = 0) => {
        this.setState({
            popupType : 'add_project',
            dialogOpen : true,
            editId : id,
        });
    }
    updateParentOnAddSuccess = (successFlag) => {
        this.setState({
            dialogOpen : false,
            popupType : '',
        });
        if (successFlag) { // Update list data
            let editId = this.state.editId;
            let msg = '';
            if (editId) {
                msg = "Project updated successfully";
                editId = 0;
            } else {
                msg = "Project created successfully";
            }
            this.setState({
                snackbarOpen : true,
                snackbarMsg : msg,
            }, function() {
                this.getProjectList();
            });
        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    exportProjectList = () => {
       document.getElementById("exportProject").submit();
    }
    resetFilter = () => {
        let f = {
            status : 1,
            client : 0
        };
        this.setState({
            filterValues: f
        },() => {this.getProjectList();});
    }
    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <ProjectsComponent
                            Projects = {this.state.projects}
                            HandleViewDetails = {this.handleViewDetails}
                            FilterValues = {this.state.filterValues}
                            FilterInputs={this.state.filterInputs}
                            DeletePermission={this.state.deletePermission}
                            toggleDrawer={this.toggleDrawer}
                            Right={this.state.right}
                            HandleFilterChange={this.handleFilterChange.bind(this)}
                            SubmitFilter={this.getProjectList}
                            PopupType = {this.state.popupType}
                            DialogOpen = {this.state.dialogOpen}
                            AddProjectClick = {this.addProjectClick}
                            UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            ExportProject = {this.exportProjectList}
                            EditId = {this.state.editId}
                            ResetFilter={this.resetFilter.bind(this)}
                            EditPermission = {this.state.editPermission}
                            SelfEditPermission = {this.state.selfEditPermission}
                            LoggedUser = {this.state.loggedUser}
                            NameFormat = {this.state.nameFormat}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
