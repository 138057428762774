import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {LevelsComponent}  from './Levels.Component';
const formFields = {
    'id':0,
    'name': '',
    'sort_order': '',
};

export class LevelsView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            levels : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
            deletePermission : false,
        };
        this.editLevelClick = this.editLevelClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddLevel = this.onAddLevel.bind(this);
        this.deleteLevelClick = this.deleteLevelClick.bind(this);
        this.deleteLevel = this.deleteLevel.bind(this);
    }
    componentDidMount = () => {
        this.getAllLevels();
    };
    getAllLevels = () => {
        let apiEndpoint = 'api/settings/getAllLevels';
        return httpServices.get(apiEndpoint)
        .then(response => {
            if (response.data.permission) {
                if (response.data.levels && response.data.levels.length) {
                    this.setState({
                        levels : response.data.levels,
                        hasPermission : true,
                        editPermission : response.data.edit_permission,
                        deletePermission : response.data.delete_permission,
                    });
            } else {
                this.setState({
                    hasPermission : false,
                });
            }
        }
        else {
            this.setState({
                hasPermission : false,
            });
        }
    })          
        .catch(function(error) {
           console.log("Error: "+error);
         });
    };   
    editLevelClick = (id) => () => {
        let apiEndpoint = 'api/settings/get-level-details-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.level_details) {
                        this.setState({
                            formFields : response.data.level_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddLevel = () => {
        let formFields = {
            'id':0,
            'name': '',
            'sort_order': '',
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;            
            if (data) {
                 httpServices.post('api/settings/saveLevel', { data })
                    .then(response => {
                        if (response.data.status) {
                            if(data.id){
                                // Update DOM
                                const index = this.state.levels.findIndex(rr => rr.id === data.id);
                                let levels = this.state.levels;
                                levels[index] = data;
                                this.setState({
                                    levels : levels,
                                    open : false
                                });
                                alert("Level details updated successfully");
                            } else {
                                let levels = this.state.levels;
                                data['id'] = response.data.id;
                                levels.push(data);
                                this.setState({
                                    levels : levels,
                                    open : false,
                                });
                                alert("Level details saved successfully");
                            }
                        } else {
                            if (response.data.validation_errors) {
                                this.setState({ errors: response.data.validation_errors });
                            }
                            else
                                alert("Save failed. Duplicate level name not allowed");
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name.trim()) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.sort_order) {
            errors["sort_order"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    deleteLevelClick = (id) => () => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteLevelId : id,
            });
        }
    };
    deleteLevel = () => {
        let del_id = this.state.deleteLevelId;
        if (del_id) {
            let apiEndpoint = 'api/settings/delete-level';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    // Dom updation
                    if (response.data.status) {
                        const index = this.state.levels.findIndex(rr => rr.id === del_id);
                        let levels = this.state.levels;
                        levels.splice(index, 1);
                        this.setState({
                            levels : levels,
                            open : false
                        });
                        alert("Level deleted successfully");
                    } else {
                        alert(response.data.err_msg);
                        this.setState({
                            open : false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };

    render(){
        return (
            <LevelsComponent
                Levels = {this.state.levels}  
                EditLevelClick = {this.editLevelClick}
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddLevelClick = {this.onAddLevel}                
                PopupType = {this.state.popupType}
                DeleteLevelClick = {this.deleteLevelClick}
                DeleteLevel = {this.deleteLevel}
                HasPermission = {this.state.hasPermission}
                EditPermission = {this.state.editPermission}
                DeletePermission = {this.state.deletePermission}  
            />
        )
    }
}
