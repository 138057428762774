import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';


export const HoursByProject = (props) => {
  var clientTotalHours ;
  clientTotalHours = [];
  var clientTotalPercent ;
  clientTotalPercent = [];
  const data = (_R.type(props.HoursByProject) !== 'Undefined') ? props.HoursByProject : [];
  Object.keys(data).map(function(key,p_index) {
    let totalHours = 0.0;
    let totalPercent = 0.0;
    for(var j=0; j <props.HoursByProject[key].projects.length; j++)
    {
      totalHours = totalHours + parseFloat(props.HoursByProject[key].projects[j].hours);
      totalPercent = totalPercent + parseFloat(props.HoursByProject[key].projects[j].percent.toFixed(2));
    }
    clientTotalHours.push(totalHours.toFixed(2));
    clientTotalPercent.push(totalPercent.toFixed(2));
  })
 return (
        <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
            <Table  size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Client name</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Charge Code</TableCell>
                        <TableCell >Hours</TableCell>
                        <TableCell >Percentage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                   {_R.type(props.HoursByProject) !== 'Undefined' && Object.keys(props.HoursByProject).map((item,k) => 
                        <>
                            <TableRow key = {item}>
                                <TableCell component="th" scope="row" rowSpan={props.HoursByProject[item]['projects'].length + 1}>{item}</TableCell>
                            </TableRow>
                            {_R.type(props.HoursByProject[item]['projects']) === "Array" &&  props.HoursByProject[item].projects.map((projectItem, i) => 
                                <TableRow>
                                    <TableCell component="th" scope="row" >{props.HoursByProject[item]['projects'][i].project_path}</TableCell>
                                    <TableCell component="th" scope="row" >{props.HoursByProject[item]['projects'][i].project_code}</TableCell>
                                    <TableCell component="th" scope="row" >{props.HoursByProject[item]['projects'][i].hours}</TableCell>
                                    <TableCell component="th" scope="row" >{props.HoursByProject[item]['projects'][i].percent.toFixed(2)}%</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan="3" align="right">Client total</TableCell>
                                <TableCell component="th" scope="row">{clientTotalHours[k]}</TableCell>
                                <TableCell component="th" scope="row">{clientTotalPercent[k]}%</TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
