import React from 'react';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export const IdProofDetailsComponent = (props) => {
    const { onAdd, onUpdate, onDeleteSubmit, IdProofDetails} = props;
    let id_details = (_R.type(IdProofDetails) !== 'undefined') ? IdProofDetails : {};
    return (  
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Id proof</Typography>
                {(props.idProofnumber_edit)?
                <Tooltip title="Add ID Proof Details" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={onAdd}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                :""}
            </div>  
             <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell >ID Proof Type</TableCell>
                            <TableCell style={{ width:'48%' }}>ID Proof Number</TableCell>
                            <TableCell style={{ width:'28%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof id_details != 'undefined' && id_details.length
                            ? id_details.map( (d, index ) =>
                        <TableRow hover>
                            <TableCell> {d.title} </TableCell>
                            <TableCell> {(props.idProofnumber_fullview || props.idProofnumber_view )? <>
                                {d.id_number} 
                                {(props.idProofnumber_fullview)? 
                                <IconButton size="small"  onClick={()=> props.getIdProof_number(d.staff_id,d.idcard_id,index)} aria-label="show">
                                    {(props.idProof_flag && props.idProof_flag[d.idcard_id])?
                                        <VisibilityIcon fontSize="small"/>
                                    :<VisibilityOffIcon  fontSize="small"/>
                                    }
                                </IconButton>
                                :<></>
                                }</>:<></>
                                }
                            </TableCell>
                            <TableCell align="right">
                            {props.idProofnumber_edit  &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={onUpdate(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            {props.idProofnumber_delete &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <DeleteIcon fontSize="small" onClick={onDeleteSubmit(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            </TableCell>
                        </TableRow>
                        )
                    :<></> }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium 
                Open= { props.open }
                Cancel = { props.onClose }
                Title = { props.IsEditable ? 'Edit ID Proof Details' : 'Add ID Proof Details' }
                OnSubmit = { props.OnSubmit }
                Content = { <DialogContent FormProps = { props }/> }
            />
        </>
    )
};

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, FormInputs, HandleOnChange } = props;
    return (
      <form className="form-fullwidth" onSubmit={props.OnSubmit}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <FormControl variant="standard">
                    <InputLabel htmlFor="idproof_type"  required  error={props.Errors.idcard_id && true}>ID Proof Type</InputLabel>
                        <Select                            
                            value={_R.type(FormFields) !== 'Undefined' && FormFields.idcard_id}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'idcard_id',
                                id: 'idcard_id',
                            }}>
                            {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.idProof_types) !== 'Undefined' && FormInputs.idProof_types.map((t, i) =>
                                <MenuItem value={t.id} key={'sub-type-'+i}>{t.title}</MenuItem>
                            )}
                        </Select>
                        {props.Errors.idcard_id && <FormHelperText class="MuiFormHelperText-root Mui-error Mui-required">{props.Errors.idcard_id}</FormHelperText> }
                </ FormControl>
            </Grid>          
            <Grid item xs={12} md={6}>
                <FormControl>
                    <TextField variant="standard"
                        id="id_number"
                        name="id_number"
                        label="ID Proof Number"
                        type="text"
                        required
                        value={props.FormFields.id_number}
                        onChange={props.HandleOnChange}
                        error={props.Errors.id_number && true}
                        helperText={props.Errors.id_number}
                    />
                </FormControl>
            </Grid>
        </Grid>
      </form>

    )
}
