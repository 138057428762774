import React from "react";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import Paper from '@mui/material/Paper';
import { ReviewerComponent } from "./Reviewer.Component";
import { SubordinateComponent } from "./Subordinate.Component";
import { ServicePeriodComponent } from "./ServicePeriod.Component";
import Typography from "@mui/material/Typography";

export const EmploymentComponent = (props) => {
    let Profile = props.Profile;
    let address = props.OfficeAddress;
    const cellStyle = {
        paddingLeft: '30px'
    };
    const EmploymentStyle = styled(React.Fragment)(({ theme }) => ({
        root: {
            padding: theme.spacing(3, 2),
            width: '100%',
            overflowX: 'auto'
        },
        plane: {
            padding: 0,
            marginBottom: '40px',
            boxShadow: 'none',
        },
        fullHeight: {
            height: '100%'
        }
    }));
    return (
        <EmploymentStyle>
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                <Grid item xs={12}>
                    <Grid container spacing={3} style={{ position: "relative" }}>
                        <Grid item xs={12} sm={6}>
                            <div className={'profile-view__basics_default'}>
                                <div className={'profile-view__basics_default-item item-role'}><label>Working Time</label><span className={'value'}>{Profile.entry_time} {Profile.exit_time ? '-' : ''} {Profile.exit_time}</span> </div>
                                <div className={'profile-view__basics_default-item item-role'}><label>Pay Period</label><span className={'value'}>{Profile.pay_period ? Profile.pay_period.charAt(0).toUpperCase() + Profile.pay_period.slice(1) : ''}</span></div>
                                <div className={'profile-view__basics_default-item item-role'}><label>Main Project</label><span className={'value'}>{Profile.project_name ? `${Profile.client_name}/${Profile.project_name}` : ''}</span></div>
                                <div className={'profile-view__basics_default-item item-role'}>
                                    <label>Timesheet Review Method</label><span className={'value'}>{Profile.timesheet_multiple_review === 1 ? "Project Manager" : "Supervisor"}</span>
                                </div>

                            </div>
                        </Grid>                        
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className={'section-main'}>
                        <ServicePeriodComponent
                            ServicePeriods={props.ServicePeriods}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={'section-main'}>
                        <ReviewerComponent Reviewers={props.Reviewers} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={'section-main'}>
                        <SubordinateComponent Subordinates={props.Subordinates} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={'section-main'}>
                        <Paper className={'contact-group'}>
                            <div className={'contact-group_head'}>
                                <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                                    <LocationIcon className={'title-icon'} fontSize="small" />
                                    Address
                                </Typography>
                            </div>
                            <ul className={'contact-list'}>

                                {Object.keys(address).length && Object.keys(address).map((a, index) =>
                                    <li key={index}>
                                        <div className={'contact-detils'}>
                                            <div className={'contact-type'}>{address[a].title}</div>
                                            <div className={'contact-title'}>{address[a].street}</div>
                                            <div className={'contact-content'}>
                                                {address[a].city}, {address[a].county ? address[a].county + ', ' : ''} {address[a].short_code ? address[a].short_code : address[a].state} {address[a].zipcode}
                                                <div>{address[a].country}</div>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Paper>
                    </div>
                </Grid>
            </Grid>            
        </EmploymentStyle>
    );
};
