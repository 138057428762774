import React from 'react';
import {httpServices}  from '../../../services/httpServices'
import { history } from './../../../helpers/history';
import {SubmitComponent} from './Submit.Component'
import { ErrorPage } from '../../common/error';

import * as _R from 'ramda';  // used 0.04kb
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const formFields = {
    'agree' : '',
    'initials' : '',
    'reason' : '',
    'late_submission_reason' : '',
    'clearance_radio' : false
}
const donutData = [
  { region: 'Asia', val: 4119626293 },
  { region: 'Africa', val: 1012956064 },
  { region: 'Northern America', val: 344124520 },
  { region: 'Latin America and the Caribbean', val: 590946440 },
  { region: 'Europe', val: 727082222 },
  { region: 'Oceania', val: 35104756 },
];
let tagInputs = {
    'org': null,
    'contract': null,
    'lob': null,
}
export class SubmitContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            list : [],
            hasPermission : true,
            formData : [],
            formFields : formFields,
            id : 0,
            validation : [],
            dialogOpen : false,
            popupType : '',
            dialogButton : null,
            adjustTimesheetConfirm : 0,
            submitDisabled : true,
            snackbarOpen : false,
            donutData:donutData,
            clickedTimeSliceId : 0,
            timeSliceEditDialogOpen : false,
            timeSliceEditData : {},
            timesliceFormInputFields : {},
            timeEntryEditErrors : {},
            tagInputs: tagInputs,
            new_tag_records : [],
            deleted_tag_records:[],
            offsite : {},
            offsiteFlag : false,
            editErrorMsg : '',
            snackbarMsg : '',
            random_number : 0,
            view_type : '',
            review_comments : [],
            delete_timeslice_id : 0,
            timeEntryDeleteReason : '',
            timeEntryDeleteError : false,
            forceRerender : false,
            lateSubmitValidationReq : false,
            buttonLoader : false,
            timesheetLocation : 0,
            lockOpen : false,
            selectedClearanceLevels: [],
            clearanceGroup: [],
            groups : []
        }
        this.onTimeEntryEditClick = this.onTimeEntryEditClick.bind(this);
        this.listActionMenuClick = this.listActionMenuClick.bind(this);
        this.getTimesheetDetails = this.getTimesheetDetails.bind(this);
        this.listReviewNotes = this.listReviewNotes.bind(this);
        this.onTimeEntryDeleteClick = this.onTimeEntryDeleteClick.bind(this);
        this.handleTimeEntryDeleteReason = this.handleTimeEntryDeleteReason.bind(this);
        this.timesliceDelete = this.timesliceDelete.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onMissedDatesConfirm = this.onMissedDatesConfirm.bind(this);
        this.onClose = this.onClose.bind(this);
        this.setAdjustHours = this.setAdjustHours.bind(this);
        this.submitTimesheet = this.submitTimesheet.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleClearanceChange = this.handleClearanceChange.bind(this);
        this.onUnSubmitClick = this.onUnSubmitClick.bind(this);
        this.unSubmitTimesheet = this.unSubmitTimesheet.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.timeSliceEditDialogClose = this.timeSliceEditDialogClose.bind(this);
        this.showEditSuccess = this.showEditSuccess.bind(this);
        this.confirmSubmitTimesheet = this.confirmSubmitTimesheet.bind(this);
        this.getLockedDetails = this.getLockedDetails.bind(this);
    }
    componentDidMount = () => {
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.getTimesheetDetails(id);
    };
    getTimesheetDetails = (id) => {
        return httpServices.post('api/timesheet/submit', {id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.timesheet_exist) {
                    let force_rerender = !this.state.forceRerender;
                    let view_type = '';
                    if (response.data.view_type) {
                        view_type = response.data.view_type;
                    }
                    let optionIds = [];
                    let timesheetClearance = response.data.timesheet_clearance;
                    let options = response.data.clearence_level_options;                    
                    if (timesheetClearance) {
                        timesheetClearance.forEach(function (element) {
                            let foundOption = options.find(option => option.option_name === element);
                            if (foundOption) {
                                optionIds.push(foundOption.id);
                            }
                        });
                    }
                    this.setState({
                        formData : response.data,
                        hasPermission : response.data.permission,
                        view_type : view_type,
                        forceRerender : force_rerender,
                        timesheetLocation : response.data.timesheet_location,
                        clearanceGroup : response.data.clearanceByGroup
                    });                    
                }
                else
                {
                    history.push(BASE_PATH+'/timesheet/submit');
                }                
               }
        })        
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    //On submit button click, list missed dates if exist, otherwise go to the submit validations
    onSubmitClick = () => {
        let id = this.state.id;
        return httpServices.post('api/timesheet/list-missed-working-dates', {id : id})
            .then(response => {
                if (response.data) {
                    if (!response.data.valid) {
                        this.setState({
                            validation : response.data,
                            dialogOpen : true,
                            dialogButton : 'Confirm',
                            popupType : 'missed-dates',
                    });
                    }
                    else {
                        this.onMissedDatesConfirm();
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    //Timesheet Submit validations
    onMissedDatesConfirm = () => {
        let id = this.state.id;
        return httpServices.post('api/timesheet/validate-submit', {id : id})
            .then(response => {
                if (response.data) {
                    if (!response.data.valid) {
                        this.setState({
                            validation : response.data,
                            dialogOpen : true,
                            dialogButton : 'OK',
                            popupType : (typeof response.data.validation_failure_type !== undefined && response.data.validation_failure_type === 'warning') ? 'submit-confirmation' : 'submit-validate',
                        });
                    }
                    else {
                        if (response.data.late_submission) {
                            this.setState({
                                lateSubmitValidationReq : true,
                            }, function () {
                                this.displayAdjustHours();
                            });
                        } else {
                            this.displayAdjustHours();
                        }
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onClose = () => {
        this.setState({
            dialogOpen : false,
            timeEntryDeleteReason: '',
            timeEntryDeleteError: false,
            submitDisabled : true,
            lockOpen : false,
        },function(){
            this.clearForm();
        });
    }
    displayAdjustHours = () => {
        let id = this.state.id;
        return httpServices.post('api/timesheet/adjusttimesheethours', {id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.adjusted_timeslices.length > 0) {
                        this.setState({
                            validation : response.data,
                            dialogOpen : true,
                            dialogButton : 'Yes',
                            popupType : 'adjusttimesheethours',

                        });

                    }
                    else {
                        this.showSubmitPopup(0);
                    }
                }
            })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    
    //setAdjustHours and Call Function for displaying Submit confirm popup.
    setAdjustHours = (adjustTimesheetConfirm = 0) => () => {
        this.showSubmitPopup(adjustTimesheetConfirm);
    }
    //Display SubmitTimesheet Popup
    showSubmitPopup = (adjustTimesheetConfirm = 0) => {
        this.setState({
            adjustTimesheetConfirm : adjustTimesheetConfirm,
            popupType : 'submit',
            dialogOpen : true,
            dialogButton : 'Submit',
        });
    }
    //Submit Timesheet
    submitTimesheet = () => {
        let initials = this.state.formFields.initials;
        let lateSubmissionReason = this.state.formFields.late_submission_reason;
        let selectedClearanceLevels = this.state.selectedClearanceLevels;
        let userClearanceLevel = this.state.formData.user_clearence_level;
        let clearanceLevelOptions = this.state.formData.clearence_level_options;
        let valid = true;
        let errors = {};
        if (_R.length(initials.trim()) === 0) {
            valid = false;
            errors['initials'] = "Cannot be empty";
        }
        if (this.state.lateSubmitValidationReq && (typeof lateSubmissionReason === 'undefined' || _R.length(lateSubmissionReason.trim()) === 0)) {
            valid = false;
            errors['late_submission_reason_error'] = "Cannot be empty";
        }
        if (userClearanceLevel && clearanceLevelOptions)
        {
            if (selectedClearanceLevels.length === 0)
                {
                    valid = false;
                    errors['clearance_error'] = "Please select at least one clearance option";  
                }
        }
        if (valid) {
        this.setState({buttonLoader:true});
        let id = this.state.id;
        let adjustTimesheetConfirm = this.state.adjustTimesheetConfirm;
        let data = {id : id, action : 'submit', initials:this.state.formFields.initials, adjust_timesheet_confirm : adjustTimesheetConfirm,late_submission_reason : lateSubmissionReason, selectedClearanceLevels : JSON.stringify(this.state.selectedClearanceLevels)}
        return httpServices.post('api/timesheet/submit', data)
            .then(response => {
                if (response.data.status) {
                    let force_rerender = (adjustTimesheetConfirm == 0) ? this.state.forceRerender : !this.state.forceRerender;
                    this.setState({
                        formData : response.data,
                        snackbarMsg : response.data.message,
                        snackbarOpen : true,
                        dialogOpen : false,
                        lateSubmitValidationReq: false,
                        forceRerender : force_rerender,
                        buttonLoader : false
                    }, function () {
                        this.getTimesheetDetails(id);
                    });
                    this.clearForm();
                } else {
                    this.setState({
                        buttonLoader : false,
                        errors : response.data.errors ? response.data.errors : {},
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
        }
        else {
            this.setState({errors: errors});
        }
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        let submitDisabled = true;
        if (name === 'agree') {
            f[name] = evt.target.checked;
            if (!evt.target.checked) {
                f['initials'] = '';
            }
            else {
                submitDisabled = false;
            }
            this.setState({
                submitDisabled : submitDisabled,
            });
        }
        else {
            f[name] = val;
        }
        this.setState({
            formFields: f,
        });
        if (val) {
            var err = this.state.errors;
            err[name] = '';
            this.setState({errors: err});
        }
    };
    onUnSubmitClick = () => {
        let id = this.state.id;
        return httpServices.post('api/timesheet/unsubmitvalidation', {id : id})
            .then(response => {
                if (response.data) {
                    if (!response.data.valid) {
                        //Display Unsubmit validation failed popup
                        this.setState({
                            validation : response.data,
                            dialogOpen : true,
                            dialogButton : 'OK',
                            popupType : 'unsubmit_validation',
                        });
                    }
                    else {
                        //Display popup for confirming the Unsubmit
                        this.setState({
                            dialogOpen : true,
                            dialogButton : 'Confirm',
                            popupType : 'unsubmit_confirm',
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    handleClearanceChange = (index, group) => {
        let selected = this.state.selectedClearanceLevels;
        let ClearanceGroup = this.state.clearanceGroup;
        Object.keys(ClearanceGroup).forEach(grp => {
            if (grp !== group) {
                Object.keys(ClearanceGroup[grp]).forEach(k => {
                    selected = selected.filter(item => item !== k);
                });
            }
        });
        if (selected.includes(index)) {
            selected = selected.filter(item => item !== index)
        }
        else {
            selected.push(index);
        }
        this.setState({selectedClearanceLevels : selected});
    };
    unSubmitTimesheet = () => {
        let reason = this.state.formFields.reason;
        let valid = true;
        let errors = {};
        if (_R.length(reason.trim()) === 0) {
            valid = false;
            errors['reason'] = "Cannot be empty";
        }
        if (valid) {
            this.setState({buttonLoader : true});
            let id = this.state.id;
            let data = {id : id, action : 'unsubmit', comment : reason}
            return httpServices.post('api/timesheet/submit', data)
                .then(response => {
                    if (response.data.status) {
                        this.setState({
                            formData : response.data,
                            dialogOpen : false,
                            snackbarOpen : true,
                            snackbarMsg : response.data.message,
                            buttonLoader : false
                        }, function () {
                            this.getTimesheetDetails(id);
                        });
                        this.clearForm();
                    } else {
                        this.setState({
                            buttonLoader : false,
                            errors : response.data.errors ? response.data.errors : {},
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
        else {
            this.setState({errors: errors});
        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
            snackbarMsg : ''
        });
    }

    listReviewNotes = (id) => {
        if (id) {
            httpServices.post('api/timesheet/listreviewcomments', { timeslice_id : id })
                .then(response => {
                    if(response.data){
                        if (response.data) {
                            this.setState({
                                popupType : 'show-review-list',
                                dialogOpen : true,
                                review_comments : response.data.comments,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };
    onTimeEntryEditClick = (pid) => {
        let id = parseInt(pid);
        let rand = Math.random();
        if (id > 0) {
            this.setState({
                timeSliceEditDialogOpen : true,
                clickedTimeSliceId : id,
                random_number : rand
            });
        }
    }
    clearForm = () => {
        for (let gkey in formFields) {
            formFields[gkey] = '';
        }
        const ff = this.state.formFields;
        for (let key in ff) {
            if (key === 'projects') {
                ff['projects'] = [];
            }
            else {
                ff[key] = '';
            }
        }
        this.setState({
            errors: {},
            formFields: ff,
            selectedClearanceLevels : []
        });
    };
    listActionMenuClick = (timeslice_id) => {
        if (timeslice_id > 0) {
            this.setState({ clickedTimeSliceId : timeslice_id });
        }
    }
    timeSliceEditDialogClose = () => {
        this.setState({timeSliceEditDialogOpen:false});
    }
    onTimeEntryDeleteClick = (tid) => {
        let delete_id = 0;
        if (tid) {
            delete_id = tid;
        }
        this.setState({
            popupType : 'timeslice-delete',
            dialogOpen : true,
            delete_timeslice_id : delete_id,
        });
    }
    timesliceDelete = () => {
        let id = parseInt(this.state.delete_timeslice_id);   
        let reason = this.state.timeEntryDeleteReason;
        if (id > 0 && reason !== '') {
            this.setState({buttonLoader : true});
            httpServices.post('api/timesheet/delete', {id : id, comment : reason})
                .then(response => {
                    let force_rerender = !this.state.forceRerender;
                    this.setState({
                        dialogOpen : false,
                        snackbarMsg : 'Time entry deleted',
                        snackbarOpen : true,
                        forceRerender : force_rerender,
                        timeEntryDeleteReason: '',
                        timeEntryDeleteError: false,
                        buttonLoader : false,
                    }, function () {
                        this.getTimesheetDetails(this.state.id);
                    });
                })
                .catch(function(error) {
                    alert("Delete Failed");
                    console.log("error" + error);
                });

        } else {
            this.setState({
                timeEntryDeleteError : true,
            });
        }
    }
    handleTimeEntryDeleteReason = (evt) => {
        let val = evt.target.value;
        this.setState({
            timeEntryDeleteReason : val,
        });
    }

    showEditSuccess = () => {
        this.setState({
            snackbarOpen : true,
            snackbarMsg : "Time entry updated"
        });
    };
    confirmSubmitTimesheet = () => {
        this.setState({
            popupType : 'submit',
            dialogOpen : true,
            dialogButton : 'Submit',
        });
    }
    getLockedDetails = (lockedBy, lockedOn) => {
        this.setState({
        popupType : 'show-locked-details',
        lockOpen : true,
        popupMsg : 'Timesheet is locked by ' +lockedBy+  '  on ' + lockedOn +  '.  Please contact your Project Manager or PMO', 
        });
    }
    render(){
        return(            
            (this.state.hasPermission)
            ?
            <SubmitComponent
                FormData = {this.state.formData}
                FormFields = {this.state.formFields}
                Validation = {this.state.validation}
                PopupType = {this.state.popupType}
                DialogOpen = {this.state.dialogOpen}
                DialogButton = {this.state.dialogButton}
                OnSubmitClick = {this.onSubmitClick}
                OnMissedDatesConfirm = {this.onMissedDatesConfirm}
                OnClose = {this.onClose}
                SetAdjustHours = {this.setAdjustHours}
                SubmitTimesheet = {this.submitTimesheet}
                HandleOnChange = {this.handleOnChange}
                HandleClearanceChange = {this.handleClearanceChange}
                SelectedClearanceLevels = {this.state.selectedClearanceLevels}
                Errors = {this.state.errors}
                SubmitDisabled = {this.state.submitDisabled}
                OnUnSubmitClick = {this.onUnSubmitClick}
                UnSubmitTimesheet = {this.unSubmitTimesheet}
                SnackbarClose = {this.snackbarClose}
                SData = {this.state}
                listReviewNotes = {this.listReviewNotes}
                onTimeEntryEditClick = {this.onTimeEntryEditClick}
                donutData = {this.state.donutData}
                listActionMenuClick = {this.listActionMenuClick}
                clickedTimeSliceId = {this.state.clickedTimeSliceId}
                timeSliceEditDialogOpen = {this.state.timeSliceEditDialogOpen}
                timeSliceEditDialogClose = {this.timeSliceEditDialogClose}
                getTimesheetDetails = {this.getTimesheetDetails}
                timeSheetId = {this.state.id}
                randomNumber = {this.state.random_number}
                ViewType = {this.state.view_type}
                ReviewNotes = {this.state.review_comments}
                onTimeEntryDeleteClick = {this.onTimeEntryDeleteClick}
                HandleTimeEntryDeleteReason = {this.handleTimeEntryDeleteReason}
                TimeEntryDeleteReason = {this.state.timeEntryDeleteReason}
                TimesliceDelete = {this.timesliceDelete}
                TimeEntryDeleteError = {this.state.timeEntryDeleteError}
                ForceRerender = {this.state.forceRerender}
                LateSubmitValidationReq = {this.state.lateSubmitValidationReq}
                ButtonLoader = {this.state.buttonLoader}
                ShowEditSuccess = {this.showEditSuccess}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                ConfirmSubmitTimesheet = {this.confirmSubmitTimesheet}
                TimesheetLocation = {this.state.timesheetLocation}
                GetLockedDetails = {this.getLockedDetails}
                PopupMsg = {this.state.popupMsg}
                LockOpen = {this.state.lockOpen}
                clearanceByGroup = {this.state.clearanceGroup}
            />
            :<ErrorPage/>
        )
    }
}
