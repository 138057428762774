import React from 'react';
import { httpServices } from '../../services/httpServices';
import ReactHtmlParser from 'html-react-parser';
import { ErrorPage } from '../common/error';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

export class ViewMessage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            id : 0,
            message : {},
            permission : true
        }
        this.getMessage = this.getMessage.bind(this);
    }

    componentDidMount = () => {
        this.getMessage(this.props.match.params.id);
    };

    componentDidUpdate= ()=>{
        if(this.state.id !== this.props.match.params.id ) {
            this.getMessage(this.props.match.params.id);
        }
    };

    getMessage = (id) =>{
        let apiEndpoint = 'api/messages/viewMessage ';
        let data = {'id' : id};
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            message:response.data.message,
                            id : id,
                            permission : response.data.permission
                        });
                    } else {
                        this.setState({
                            id : id
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <>
            { (this.state.permission) ? 
                <ViewMessageComponent
                    Message = { this.state.message}
                />
                :
                <ErrorPage/>
            }
            </>
        )}
}

const ViewMessageComponent = (props) =>{
    return(
        <>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'} >
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <Typography variant="h6" component="h6" className={'section-title'} ><span className={'text-transform-none'}>{props.Message.msg_subject}</span></Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'section-body'}>
                <Paper className={'mb-1'}>
                    <div className={'p-1rem'}>
                        {(props.Message.msg_body) ?
                            <>{ReactHtmlParser(props.Message.msg_body)}</>
                            : <></>
                        }
                    </div>
                </Paper>
            </div>
        </>
    )
}

