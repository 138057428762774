import { createSlice } from '@reduxjs/toolkit';
import { httpServices } from '../../../services/httpServices';

export const savedFilters = createSlice({
  name: 'savedFilter',
  initialState: {
    'savedFilterValues': [],
  },
  reducers: {
    updateSavedFilterAction: (state, action) => {
      let url = action.payload.obj.relative_url;
      let filterOptions = action.payload.obj.filter_options;
      let indexValue = state.savedFilterValues.findIndex((element) => element.relative_url === url);
      let formattedValue = JSON.stringify(filterOptions);
      formattedValue = JSON.parse(formattedValue);
      if (indexValue !== -1) {
        state.savedFilterValues[indexValue]['filter_options'] = formattedValue;
      }
      else {
        state.savedFilterValues.push({ 'relative_url': url, 'filter_options': formattedValue });
      }
    },
    setSavedFilterAction: (state, action) => {
      let filters = action.payload.Filters;
      filters.forEach((element, index) => {
        filters[index]['filter_options'] = JSON.parse(element['filter_options']);
      });
      let formattedValue = JSON.stringify(filters);
      formattedValue = JSON.parse(formattedValue);
      state.savedFilterValues = formattedValue;
    },
  }
})
export const setSavedFilters = () => (dispatch, getStore) => {
  let apiEndpoint = 'api/user/get-saved-filter';
  let store = getStore();
  let savedFilterValues = store.savedFilters.savedFilterValues;
  if (typeof savedFilterValues !== 'undefined' && !savedFilterValues.length) {
    httpServices.get(apiEndpoint)
      .then(response => {
        if (response.data && response.data.saved_filter) {
          let responseFilters = response.data.saved_filter;
          if (responseFilters.length > 0) {
            dispatch(setSavedFilterAction({ Filters: responseFilters }));
            return true;
          }
        }
      })
      .catch(function (error) {
        console.log("Error: " + error);
      });
  }
}
// Action creators are generated for each case reducer function
export const { setSavedFilterAction, updateSavedFilterAction } = savedFilters.actions
export default savedFilters.reducer