import React from 'react';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { formatName } from '../../common/common';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';

const gridStyle = {
        margin: '15px 0px'
    };
const API_URL = process.env.REACT_APP_API_URL;
const BackDrop = styled(Backdrop) (({ theme }) => ({
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const mstyle={ chips: { background: "#f4f4f4", color: 'black' }};
function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}

export const PayperiodStatusReportComponent = (props) => {
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
     const handleChangePage = (event, newPage) => {
      setPage(newPage);
      }
     const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
     };

    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear; i >= props.StartYear; i--){
        years.push(i);
    }
    let months = ( _R.type(props.Months) !== 'Undefined' && Object.keys(props.Months).length > 0) ? Object.values(props.Months) : [];
    const columns = [
        { title: 'Staff Name', field: 'user_full_name', removable: false, render: rowData =>( props.ViewTimesheetDetails && (rowData.hours) > 0 )? <div className="link" onClick={() => props.HandleViewClick(rowData.id)}><a>{rowData.user_full_name}</a></div> : <div>{rowData.user_full_name}</div>},
        { title: 'Staff Status ', field: 'user_status', removable: false, },
        { title: 'Groups', field: 'groups', hidden: true},
        { title: 'Staff Type', field: 'staff_type_name', removable: false, },
        { title: 'Pay Period', field: 'payperiod_sdate', removable: false, render: rowData => (rowData.payperiod_sdate ? rowData.payperiod_sdate+' - '+rowData.payperiod_edate : '')},
        { title: 'Timesheet Status', field: 'status_name', removable: false, render: rowData =><span className={'status-circle '+rowData.status_style_class}>{rowData.status_name}</span>},
        { title: 'Total Hours ', field: 'hours', removable: false, },
        { title: 'Minimum Pay Period Hours', field: 'min_payperiod_hours', removable: false, },
        { title: 'Last Entry On', field: 'last_entry', removable: false,},
        { title: 'Last Modified On' , field: 'last_modified', removable: false, customSort:(a,b) => sort(new Date(a.last_modified),new Date(b.last_modified)) },
        { title: 'Submitted On', field: 'submitted_time', removable: false, customSort:(a,b) => sort(new Date(a.submitted_time),new Date(b.submitted_time)) },
        { title: 'Reviewed On', field: 'approved_time', removable: false, customSort:(a,b) => sort(new Date(a.approved_time),new Date(b.approved_time))},
        { title: 'Reviewed By', field: 'reviewer_name', removable: false, },
    ];
    let projects = (typeof props.Projects !== 'undefined') ? props.Projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof props.FormFields.projectIds !== 'undefined') ? props.FormFields.projectIds : []));
    }

    return (
        <React.Fragment>
            <Paper md={12}>
            <div class="p1">
                <Typography variant="h6" component="h6" className={'section-title'}>Pay Period Status Report</Typography>
                {(props.IsPayPeriod === false) &&
                        <div className={'root'}>
                            {props.PayPeriodType === "semi-monthly" ?
                            <Alert severity="error"> {"Pay period limit exceeds. Maximum 24 intervals are allowed."} </Alert> :
                            <Alert severity="error"> {"Pay period limit exceeds. Maximum 12 intervals are allowed."} </Alert> }
                        </div>
                    }
                <form className="form-fullwidth" action={API_URL+'api/report/payperiodStatus'} method="post">
                    <Grid container spacing={3} style={gridStyle}>
                            <Grid item md={2} className='justify-between'>
                                <FormControl>
                                    <CustomDatePicker
                                        name="start_date"
                                        id="start_date"
                                        selectedDate={props.FormFields.start_date}
                                        handleDateChange={props.HandleStartDateChange}
                                        error={props.Errors.start_date}
                                        required={true}
                                        label="Start Date"
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item md={2} className='justify-between'>
                                <FormControl>
                                    <CustomDatePicker
                                        name="end_date"
                                        id="end_date"
                                        selectedDate={props.FormFields.end_date}
                                        handleDateChange={props.HandleEndDateChange}
                                        error={props.Errors.end_date}
                                        required={true}
                                        label="End Date"
                                    />
                                </ FormControl>
                            </Grid>
                        <Grid item  md={2} className='justify-between'>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="group">Group</InputLabel>
                                <Select
                                    value={props.FormFields.group}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'group',
                                        id:   'group',
                                    }}
                                >
                                <MenuItem value='0'>-Select Group-</MenuItem>
                                {typeof props.Groups != 'undefined' && props.Groups !== null &&  props.Groups.map((t, i) =>
                                    <MenuItem value={t.id} key={'st-'+i}>{t.name}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item  md={2} className='justify-between'>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="staff_type">Staff Type</InputLabel>
                                <Select
                                    value={props.FormFields.staffType}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staffType',
                                        id:   'staffType',
                                    }}
                                >
                                <MenuItem value='0'>-Select Staff Type-</MenuItem>
                                { typeof props.StaffTypes != 'undefined' && props.StaffTypes !== null &&  props.StaffTypes.map((t, i) =>
                                    <MenuItem value={t.id} key={'st-'+i}>{t.type}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={gridStyle}>
                        { props.ViewProjects &&
                            <Grid item md={3} className='justify-between'>
                                <InputLabel htmlFor="project_id" className="MuiInputLabel-shrink">Project</InputLabel>
                                    <FormControl>
                                        <input type='hidden' name='projectIds' value={props.FormFields.projectIds}/>
                                            <TreeSelectComponent
                                                data = {projects}
                                                placeholderText = { "Search Projects"}
                                                type = 'project'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                            <FormHelperText className='error'>{props.Errors.projects}</FormHelperText>
                                    </FormControl>
                                </Grid>
                        }
                        <Grid item  md={2}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked = {(typeof props.FormFields.onlySubordinates !== 'undefined') ? props.FormFields.onlySubordinates : false}
                                            value = {(typeof props.FormFields.onlySubordinates !== 'undefined') ? props.FormFields.onlySubordinates : null}
                                            id = "onlySubordinates"
                                            name = "onlySubordinates"
                                            onChange = {props.HandleOnChange}
                                            color="primary"
                                        />
                                        }
                                        label="Only subordinates"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item  md={3}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked = {(typeof props.FormFields.inactiveResources !== 'undefined') ? props.FormFields.inactiveResources : false}
                                            value = {(typeof props.FormFields.inactiveResources !== 'undefined') ? props.FormFields.inactiveResources : null}
                                            id = "inactiveResources"
                                            name = "inactiveResources"
                                            onChange = {props.HandleOnChange}
                                            color="primary"
                                        />
                                        }
                                        label="Include inactive resources"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="small" color="primary" onClick={()=>{props.FilterSubmit()}}>Submit</Button>
                        </Grid>
                        {props.ResultSet.length !== 0 && props.DisplayExport &&
                        <Grid item>
                            <Button variant="contained" size="small" color="primary" type="submit" name="export" value="export">Export</Button>
                        </Grid>
                        }
                    </Grid>
                </form>
                </div>
            </Paper>
        {(Object.keys(props.ResultSet).length === 0 && props.Post) &&
            <div> No records found </div>
        }
        {Object.keys(props.ResultSet).length !== 0 &&
            <div className={'section-body'}>
                <CustomMaterialTable
                    title= ''
                    data = {Object.values(props.ResultSet)}
                    columns={columns}
                    options={{
                        actionsColumnIndex: -1,
                        paging: true,
                        columnsButton:true,
                        search:true,
                    }}
                    actions={[
                        rowData => ({icon: 'visibility', tooltip: 'View', hidden: (props.ViewTimesheetDetails && rowData.hours > 0) ? false:true ,onClick: (event, rowData) => {
                            props.HandleViewClick(rowData.id);
                        }})
                    ]}
                />
            </div>
        }
        <BackDrop className={'backdrop'} open={props.Loader}>
                    <CircularProgress color="inherit" />
        </BackDrop>
    </React.Fragment>
    )
}
