import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const DialogScheduleComponent = (p) => {
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear; i <= currentYear+2; i++){
        years.push(i)
    };
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    autoOk
                                    required
                                    views={["year", "month", "day"]}
                                    variant="inline"
                                    inputFormat="MM/dd/yyyy"
                                    className={'timeEntry-dateInput'}
                                    value=  {props.SelectedDate}
                                    onChange={props.HandleDateChange}
                                    label="Pay check date"
                                    error = { props.Errors.paycheck && true }
                                    helperText={props.Errors.paycheck}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="component_id" required error={props.Errors.component_id && true}>Component</InputLabel>
                            <Select
                                value = { props.FormFields.component_id }
                                onChange = { props.HandleOnChange }
                                inputProps = {{
                                    name: 'component_id',
                                    id: 'component_id',
                                }}>
                                {typeof(props.ConfigComponents) !== 'undefined' && props.ConfigComponents.map((c, i) =>
                                    <MenuItem value={c.id} key={'c-'+i} data-component={c.title}>{c.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.component_id && <FormHelperText className ='error'> {props.Errors.component_id} </FormHelperText> }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id = "sch_amount"
                                name = "sch_amount"
                                label = "Amount"
                                type = "text"
                                required
                                value = { props.FormFields.sch_amount }
                                onChange = { props.HandleOnChange }
                                error = { props.Errors.sch_amount && true }
                                helperText = { props.Errors.sch_amount }
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};

export const ScheduledPaymentComponent = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
        <Grid item md={12}>
            <Grid item xs={12} sm={12}>
                <Accordion expanded={expanded === 'panel-0'} onChange={handleChange('panel-0')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>Scheduled Payments</Typography>
                    </AccordionSummary>
                <AccordionDetails style={{'display': 'block'}}>
                    <CustomMaterialTable
                        title="Components"
                        columns={[
                            { title: 'Component', field: 'title'},
                            { title: 'Paycheck Date', field: 'paycheck_date'},
                            { title: 'Amount', field: 'amount' },
                        ]}
                        data={props.Components}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize:10,
                            pageSizeOptions: [10,20,30],
                            search: false
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Schedule New Payment',
                                isFreeAction: true,
                                onClick: (event, newData) => { 
                                    props.AddComponent();
                                },
                                hidden: (props.Edit) ? false : true,
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.HandleUpdate(rowData.id);
                                },
                                hidden: (props.Edit) ? false : true,
                            }
                        ]}
                        editable={{
                            isDeletable: rowData => (props.Delete) ? true : false,
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    props.OnDelete(rowData.id);
                                    resolve()
                                }, 500)
                            }),
                        }}
                    />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
        <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title={ (props.isEditable) ? 'Edit scheduled payment': 'Schedule New Payment'}
                    OnSubmit={props.SaveScheduledComponent}
                    Content={<DialogScheduleComponent FormProps={props}/>}/>
        </React.Fragment>
    );
}

