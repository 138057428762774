import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatAndSortNames } from '../../common/common';

const styles = theme => ({
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    }
});
export const FilterComponent = (props) => {
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear-5; i < currentYear+5; i++){
        years.push(i);
    }
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'first_name', middle_name: 'middle_name', last_name: 'last_name'};
    let users = typeof props.props.Users !== 'undefined' ? props.props.Users : [];
    if (users && users.length) {
        users = formatAndSortNames(users, [format, columnsToBeFormated], 'ASC');
    }
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Year</InputLabel>
                        <Select
                            inputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                            value={(!_R.isNil(props.props.FormFields.year) && props.props.FormFields.year) ? props.props.FormFields.year : currentYear}
                            onChange={props.handleOnChange}
                        >
                            {years.map((y) =>
                                <MenuItem value={y}>{y}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Request Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'requestStatus',
                                id: 'requestStatus',
                            }}
                            value={!_R.isNil(props.props.FormFields.requestStatus) && props.props.FormFields.requestStatus}
                            onChange={props.handleOnChange}
                        >
                            <MenuItem value="0" >All</MenuItem>
                            {typeof (props.props.RequestStatus) !== 'undefined' && props.props.RequestStatus.map((t, i) =>
                                <MenuItem value={t.request_status_id} key={'c-' + i}>{t.request_status_name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {props.tab !== 'my' && <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl variant = 'standard'>
                            <InputLabel htmlFor="age-simple">Staff Status</InputLabel>
                            <Select
                                inputProps={{
                                    name: 'employeeStatus',
                                    id: 'employeeStatus',
                                }}
                                value={!_R.isNil(props.props.FormFields.employeeStatus) && props.props.FormFields.employeeStatus}
                                onChange={props.handleOnChange}
                            >
                                <MenuItem value="0" >All</MenuItem>
                                {typeof (props.props.EmployeeStatus) !== 'undefined' && props.props.EmployeeStatus.map((n, i) =>
                                    <MenuItem value={n.user_status_id} key={'n-' + i}>{n.user_status_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl variant = 'standard'>
                            <InputLabel htmlFor="age-simple">Staff Name</InputLabel>
                            <Select
                                inputProps={{
                                    name: 'employeeName',
                                    id: 'employeeName',
                                }}
                                value={!_R.isNil(props.props.FormFields.employeeName) && props.props.FormFields.employeeName}
                                onChange={props.handleOnChange}
                            >
                                <MenuItem value="0" >All</MenuItem>
                                {users.map((n, i) =>
                                    <MenuItem value={n.user_id} key={'n-' + i}>{n.formatted_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid></>
            }
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={'d-flex justify-end mb-1'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(typeof props.SaveFilter !== 'undefined') ? props.SaveFilter : false}
                                    value={(typeof props.SaveFilter !== 'undefined') ? props.SaveFilter : false}
                                    id="saveFilter"
                                    name="saveFilter"
                                    onChange={props.handleOnChange}
                                    color={'primary'}
                                />
                            }
                            label="Save Filter"
                        />
                    </div>
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" className={'filterSubmitButton'} onClick={() => props.handleOnSubmit(props.tab)}> Submit </Button>
            <Button variant="outlined" color="primary" className={'ml-1 filterSubmitButton'} onClick={() => props.ResetFilter(props.tab)} > Reset </Button>
        </form>
    )
}
