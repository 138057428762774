import React from 'react';

import { TimeOffViewComponent } from './TimeOffView.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from './../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as _R from 'ramda';

const formFields = {
    'reason' : '',
    'comment' : '',
}

export class TimeOffViewContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            id : 0,
            requests : '',
            dialogOpen : false,
            snackbarOpen : false,
            popup : '',
            errors: {},
            formFields : formFields,
            hasPermission: true,
            showLoader : true,
            selfEdit : false,
            snackbarMsg : '',
            snackbarMsgType : 'success', 
        }
        this.getTimeoffDetails = this.getTimeoffDetails.bind(this);
        this.viewRelatedTimeoff = this.viewRelatedTimeoff.bind(this);
        this.approvebtnClick = this.approvebtnClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.submitReviewRequest = this.submitReviewRequest.bind(this);
        this.unApprovebtnClick = this.unApprovebtnClick.bind(this);
        this.rejectbtnClick = this.rejectbtnClick.bind(this);
        this.commentbtnClick = this.commentbtnClick.bind(this);
        this.cancelbtnClick = this.cancelbtnClick.bind(this);
        this.tokenbtnClick = this.tokenbtnClick.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.updateParentOnEditSuccess = this.updateParentOnEditSuccess.bind(this);
        this.closeLoader = this.closeLoader.bind(this);
    }

    componentDidMount = () => {
        let id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.getTimeoffDetails(id);
    };
    getTimeoffDetails = (id) =>{
        let apiEndpoint = 'api/request/timeoff/view';
        let data = {
            'id' : id,
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.permission) {
                    this.setState({
                        request : response.data,
                        //snackbarMsg : response.data.message,
                        snackbarMsgType : response.data.message_type,
                        showLoader : false,
                        selfEdit : response.data.self_edit,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    editClick = () => {
        this.setState({
            popup : 'edit',
            dialogOpen : true,
            showLoader : true,
        });
    }
    viewRelatedTimeoff = (id) => {
        if(id) {
            history.push(id);
            this.setState({
                id : id,
                showLoader : true,
            });
            this.getTimeoffDetails(id);
            window.scrollTo(0, 0);

        }
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        if (name === 'approve_related') {
            f[name] = evt.target.checked;
        }
        else {
            f[name] = val;
        }
        this.setState({
            formFields: f,
        });
        if (val) {
            var err = this.state.errors;
            err[name] = '';
            this.setState({errors: err});
        }
    };
    onClose = () => {
        this.setState({
            dialogOpen : false,
            errors: {},
        });
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = '';
        }
        this.setState({
            errors: {},
            formFields: ff,
        });
    };
    approvebtnClick = () => {
        //Display Approve popup
        this.setState({
            popup : 'approve',
            dialogOpen : true,
        });
    }
    unApprovebtnClick = () => {
        this.setState({
            popup : 'unapprove',
            dialogOpen : true,
        });
    }
    rejectbtnClick = () => {
        this.setState({
            popup : 'reject',
            dialogOpen : true,
        });
    }
    commentbtnClick = () => {
        this.setState({
            popup : 'comment',
            dialogOpen : true,
        });
    }
    cancelbtnClick = () => {
        this.setState({
            popup : 'cancel',
            dialogOpen : true,
        });
    }
    tokenbtnClick = () => {
        this.setState({
            popup : 'token_generate',
        },function() {
            this.submitReviewRequest();
        });
    }
    submitReviewRequest = () => {
        let id  =this.state.id;
        let action = this.state.popup;
        let data = this.state.formFields;
        data.id = id;
        data.action = action;
        let valid = this.validateSubmit(action);
        if (valid) {
            this.setState({
                showLoader : true                
            });
            return httpServices.post('api/request/timeoff/view', data)
                .then(response => {
                    if (response.data.message_type === 'success') {
                        this.setState({
                            request : response.data,
                            snackbarMsg : response.data.message,
                            snackbarMsgType : response.data.message_type,
                            snackbarOpen : true,
                            showLoader : false,
                            dialogOpen : false,
                        });
                        this.clearForm();
                    } else if (response.data.message_type === 'error'){
                        if (response.data.errors.length){
                            this.setState({
                                errors: response.data.errors,
                                showLoader : false,
                            });
                        } else if (response.data.message){
                            this.setState({
                                snackbarMsg: response.data.message,
                                snackbarMsgType: response.data.message_type,
                                snackbarOpen: true,
                                showLoader: false,
                                dialogOpen: false,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    validateSubmit = (action) => {
        let valid = true;
        let errors = {};
        if (action === 'unapprove' || action === 'reject' || action === 'comment') {
            let comment = this.state.formFields.comment;
            if (_R.length(comment.trim()) === 0) {
                valid = false;
                errors['comment'] = "Comment cannot be empty";
            }
        }
        this.setState({errors: errors});
        return valid;
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    updateParentOnEditSuccess = (status) => {
        if ( status ) {
            this.setState({
                snackbarOpen : true,
                snackbarMsg : "Request updated successfully",
                snackbarMsgType : 'success',
                dialogOpen : false,
            }, function () {
                this.getTimeoffDetails(this.state.id);
            });
        } else {
            this.setState({
                dialogOpen : false,
            });
        }
    }
    closeLoader = () => {
        this.setState({
            showLoader: false,
        });
    }
    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <TimeOffViewComponent 
            Request = {this.state.request}
            ViewRelatedTimeoff = {this.viewRelatedTimeoff}
            ApprovebtnClick = {this.approvebtnClick}
            UnApprovebtnClick = {this.unApprovebtnClick}
            RejectbtnClick = {this.rejectbtnClick}
            CommentbtnClick = {this.commentbtnClick}
            CancelbtnClick = {this.cancelbtnClick}
            TokenbtnClick = {this.tokenbtnClick}
            HandleOnChange = {this.handleOnChange}
            OnClose = {this.onClose}
            SubmitReviewRequest = {this.submitReviewRequest}
            DialogOpen = {this.state.dialogOpen}
            SnackbarOpen = {this.state.snackbarOpen}
            Popup = {this.state.popup}
            Errors = {this.state.errors}
            FormFields = {this.state.formFields}
            SnackbarClose = {this.snackbarClose}
            SelfEdit = {this.state.selfEdit}
            EditClick = {this.editClick}
            Id = {this.state.id}
            SnackbarMsg = {this.state.snackbarMsg}
            SnackbarMsgType = {this.state.snackbarMsgType}
            UpdateParentOnEditSuccess = {this.updateParentOnEditSuccess}
            CloseLoader = {this.closeLoader}
            />
            :
            <ErrorPage Message={'You have no permission to view timeoff request details'} />
            }
            </>
        )
    }
}
