import React from 'react';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import * as _R from 'ramda';
import { formatName } from '../../../common/common';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextareaAutosize
                                rowsMin={7}
                                id="note"
                                name="note"
                                label="Note"
                                type="text"
                                required
                                value={props.FormFields.note}
                                onChange={props.HandleOnChange}
                                error={props.Errors.note && true}
                            />
                            <small className='error'>{props.Errors.note && props.Errors.note}</small>
                        </FormControl>
                    </Grid>
                </Grid>
	<div class="notes">
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        {_R.type(props.Note) !== 'Undefined' && (props.Note).map((item) =>
        <div class="note-item">
        <div class="note-text">
                    {item.note.split ('\n').map ((noteitem, i) => <p key={i}>{noteitem}</p>) }
		{/* {(item.category) &&
                <div class="notes-labels">
                <span class="title">Related to:</span>
                <span class="chip">{item.category} </span>
                <span class="chip">Entity value</span>
            </div>}*/}
        </div>
        <div class="notes-footer">
            <div class="notes-details">
                <div class="item">Created by: {formatName(item.cby_first_name, null, item.cby_last_name)}, On: {item.created_on}</div>
		{ (item.modified_by) &&
                        <div class="item">Modified by: {formatName(item.mby_first_name, null, item.mby_last_name)}, On: {item.modified_on}</div>}
            </div>
        </div>
        </div>
        )}
        </Grid></Grid>
        </div>
        </form>
        )
};
export const AddNoteComponent = (props) => {
    return (
            <>
               <React.Fragment>
	          <DialogBoxMedium
                     Open={props.Open}
                     Cancel={props.Close}
                     Title= {props.IsEdit?'Edit Note':'Add Note'}
                     OnSubmit={props.SaveNote}
                     Content={<DialogComponent  FormProps={props}/>}
	        />
               </React.Fragment>
            </>
          )
};
