import React from 'react';
import {FTOComponent} from './FTO.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb

const formFields = {
    'id' : 0,
    'user_id': null,
    'hours': null,
    'payperiod_id': null,
    'note': null,
}

export class FTODetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            errors: {},
            fto: [],
            pay_periods: {},
            open:false,
            isEdit: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

    handleValidation() {
        let formIsValid = false;
        let errors = {};
        let hours = this.state.formFields.hours;
        if (!this.state.formFields.payperiod_id) {
            errors['payperiod_id'] = "Cannot be empty";
        }
        if (!hours) {
            errors["hours"] = "Cannot be empty";
        }
        else if (hours && isNaN(hours)) {
            errors["hours"] = "Hours is invalid";
        }
        if (!this.state.formFields.note) {
            errors["note"] = "Cannot be empty";
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    saveFTO =() => {
        var len = this.state.fto.length;
        if (len === 0) {
            if (_R.type(this.props.FTOInfo) !== 'Undefined') {
                this.setState({fto: this.props.FTOInfo});
            }
        }
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.staff_id = staffID;
            data.user_id = userID;
            return httpServices.post('api/timeoff/fto/save-fto-adjustments', {data})
                .then(response => {
                    if (response.data.valid) {
                        this.props.GetTimeoffDetails();
                        this.setState({ open: false });
                        this.clearForm();
                    }
                    else {
                        this.setState({ errors: response.data.errors });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    onAdd = () => {
        const userID = this.props.UserID;
        const year = this.props.YearVal;
        return httpServices.post('api/timeoff/fto/get-fto-adjustment', {'user_id' : userID, 'year' : year})
            .then(response => {
                if (response.data) {
                    this.setState({
                        pay_periods: response.data.pay_periods,
                        open: true,
                        isEdit: false,
                        id: 0,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            payperiod_id: null,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };

    onUpdate = (id) => {
        const userID = this.props.UserID;
        const year = this.props.YearVal;
        return httpServices.post('api/timeoff/fto/get-fto-adjustment', {'id': id, 'user_id' : userID, 'year' : year})
            .then(response => {
                if (response.data) {
                    this.setState({
                        formFields: response.data.selected_fto,
                        pay_periods: response.data.pay_periods,
                        open: true, 
                        id: id,
                        isEdit: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    render(){
        return(
            <FTOComponent
            FTO={_R.type(this.props.FTOInfo) !== 'Undefined' ? this.props.FTOInfo: undefined}
            PayPeriods={this.state.pay_periods}
            SaveFTO={this.saveFTO.bind(this)}
            Years = {_R.type(this.props.Years) !== 'Undefined' ? this.props.Years: undefined}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields} 
            Open={this.state.open}
            Close={this.onClose.bind(this)}
            OnAdd={this.onAdd.bind(this)}
            HandleUpdate={this.onUpdate.bind(this)}
            Errors={this.state.errors}
            IsEdit = {this.state.isEdit}
            Edit = {this.props.Edit}
            />
        )
    }
}
