import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {CustomMaterialTable, CustomMTableToolbar} from '../common/CustomMaterialTable';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

 const StyledBackdrop = styled(Backdrop) (({ theme }) => ({
    '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
        },
    }));



export const PayrollComponent = (p) => {

    const { HandleOnYearChange, Year} = p;
    var dataArr = [];
    if (typeof p.Intervals !== 'undefined' && p.Intervals.length > 0) {
        dataArr = p.Intervals;
    }
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear; i >= currentYear-4; i--){
        years.push(i)
    };
    return (
         <>
            <CustomMaterialTable
                title= "Payroll"
                columns={[
                    { title: 'Pay Periods', field: 'payperiod', render: rowData => <>{rowData.from_date+' - '+rowData.to_date} </> },
                    { title: 'Eligible Payrolls', field: 'eligible_staff_count'},
                    { title: 'Payrolls Generated', field: 'generated_payroll_count'},
                    { title: 'New', field: 'new_count'},
                    { title: 'Reviewed', field: 'reviewed_count'},
                    { title: 'Approved', field: 'approved_count'}
                ]}
                data={dataArr}
                options={{
                    search:false,
                    actionsColumnIndex: 0,
                    pageSize:10,
                    pageSizeOptions: [10,20,30]
                }}
                components={{
                    Toolbar: (props) => (
                        <div className={'mTable-toolbar-custom'}>
                            <CustomMTableToolbar {...props} />
                            <div className={'mTable-filter form-inline'}>
                                <FormControl>
                                    <InputLabel htmlFor="year">Year</InputLabel>
                                    <Select
                                        value={Year?Year:currentYear}
                                        inputProps={{
                                            name: 'year',
                                            id: 'year',
                                        }}
                                        onChange={HandleOnYearChange}
                                    >
                                    {years.map((year, i) =>
                                        <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )
                }}
                onRowClick={(event, rowData) => p.HandleReviewPage(rowData.id)}
            />
        <StyledBackdrop className={'backdrop'} open={p.LoaderOpen}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>

        </>
    )
};
