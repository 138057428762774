import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { ClientViewComponent } from './ClientView.Component';
import { history } from '../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export class ClientViewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            project : [],
            errors : [],
            showLoader : true,
            clientId :0,
            projects : [],
            client : '',
            ClientStatus : [],
            ClientTypes : [],
            snackbar: false,
            valid: false,
            success_message: null,
            error_message: null,
            editPermission : false,
            taskCount : 0,
            popupType: "",
            open: false,
            dataLoaded : false,
        };
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.updateParentOnSuccess = this.updateParentOnSuccess.bind(this);
    }

    componentDidMount(){
        if (this.props.match) {
            var id = (this.props.match.params.id) ? this.props.match.params.id: false;
            this.setState({ clientId : id } , function() {
                this.getClientDetails(this.state.clientId);
            });
        }
    }

    handleEditClick = (id) => {
        this.setState({
            popupType: "edit",
            open: true,
            clientId : id,
        });
    };

    updateParentOnSuccess = (status) => {
        this.setState({open : status});
        this.getClientDetails(this.state.clientId);
    }

     handleViewDetails = (id) => {
        if(id) {
            history.push(BASE_PATH +'/projects/view/' + id);
        }
    }
    
    getClientDetails = (clientId) => {
        let apiEndpoint = 'api/client/details';
        this.setState({
            showLoader : true,
        });

        return httpServices.post(apiEndpoint,{id : clientId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            projects : response.data.projects,
                            client : response.data.client,
                            ClientTypes:response.data.client_types,
                            ClientStatus:response.data.status,
                            showLoader : false,
                            dataLoaded : true,
                            permission : true,
                            editPermission : response.data.edit_permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

     render()
    {
        return(

            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {( this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <ClientViewComponent
                            Projects = {this.state.projects}
                            Client = {this.state.client}
                            FormFields = {this.state.FormFields}
                            Snackbar = {this.state.snackbar}
                            HandleEditClick = {this.handleEditClick}
                            PopupType={this.state.popupType}
                            Errors={this.state.errors}
                            ClientStatus={this.state.ClientStatus}
                            ClientTypes= {this.state.ClientTypes}
                            ClientId = {this.state.clientId}
                            Open={this.state.open}
                            Valid={this.state.valid}
                            ErrorMessage={this.state.error_message}
                            HandleViewDetails = {this.handleViewDetails}
                            SuccessMessage={this.state.success_message}
                            SnackbarClose={this.snackbarClose}
                            EditPermission = {this.state.editPermission}
                            UpdateParentOnSuccess = {this.updateParentOnSuccess}
                        />
                        : <ErrorPage />
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}




