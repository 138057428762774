import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';

export const MileageComponent = (props) => {
    const mileages = typeof props.Mileages !== 'undefined' ? props.Mileages : [];
    return (
        <>
            <div className={'listSubHeader pr-2'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                {props.EditPermission &&
                <Tooltip title="Add Mileage" aria-label="add">
                    <Button variant="contained" color="primary" size="small" onClick={props.AddMileageClick}>Add</Button>
                </Tooltip>
                }
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="Timesheet List" size='small' className={'list-table_basic steps-table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell> Start Date </TableCell>
                            <TableCell> End Date </TableCell>
                            <TableCell> Rate per mile </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(mileages.length === 0) &&
                            <TableRow >
                                <TableCell>No records found.</TableCell>
                            </TableRow>
                        }
                        {mileages.map((item, index) =>
                        <TableRow hover>
                            <TableCell>{item.start_date}</TableCell>
                            <TableCell>{item.end_date}</TableCell>
                            <TableCell>{props.CurrencySymbol}{item.mileage_rate}</TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.PopupType === 'add') &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Add Mileage ' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogAdd FormProps = { props }/> }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields,HandleOnChange,Errors,ValidMessage } = props.FormProps;
    return (
        <form className="form-fullwidth">
        {ValidMessage !== null &&
        <Alert severity="error"> {ValidMessage} </Alert> 
        }
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "startDate"
                            name = "startDate"
                            label = "Start Date"
                            type = "date"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.startDate : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.startDate !== 'undefined' && Errors.startDate)&& true }
                            helperText = { typeof Errors.startDate !== 'undefined' && Errors.startDate }
                        />
                        <TextField variant="standard"
                            required
                            id = "ratePerMile"
                            name = "ratePerMile"
                            label = "Rate Per Mile($)"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.ratePerMile : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.ratePerMile !== 'undefined' && Errors.ratePerMile)&& true }
                            helperText = { typeof Errors.ratePerMile !== 'undefined' && Errors.ratePerMile }
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
