import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';

const titledInputs = {
    titled : [
        {  value : "1", title : "Yes"},
        {  value : "0", title : "No"}
    ],
}

const DialogContent = (props) => {
    const {FormFields, HandleOnChange, JobTitles} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                value={FormFields.start_date}
                                error={props.Errors.start_date && true}
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                className="no-margin"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                            />
                            <small className='error'>{props.Errors.start_date && props.Errors.start_date}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="job_title" error={props.Errors.job_title && true} required>Job Title</InputLabel>
                            <Select
                                value={(typeof FormFields.job_title !== 'undefined') ? FormFields.job_title : ''}
                                onChange={HandleOnChange}
                                error={props.Errors.job_title && true}
                                inputProps={{
                                    name: 'job_title',
                                    id: 'job_title',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof JobTitles != 'undefined' && JobTitles.map((s, i) =>
                                    <MenuItem value={s.id} key='titled-{s.id}'>{s.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.job_title && props.Errors.job_title}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="project_title"
                                name="project_title"
                                label="Project Role"
                                type="text"
                                fullWidth
                                onChange={HandleOnChange}
                                value={FormFields.project_title}
                                error={props.Errors.project_title && true}
                            />
                            <small className='error'>{props.Errors.project_title && props.Errors.project_title}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                            <InputLabel htmlFor="titled" >Titled employee</InputLabel>
                            <Select
                                value={FormFields.titled }
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'titled',
                                    id: 'titled',
                                }}>
                                <MenuItem value="0"><em>Select</em></MenuItem>
                                { typeof titledInputs.titled != 'undefined' && titledInputs.titled.map((s, i) =>
                                    <MenuItem value={s.value} key='titled-{s.value}'>{s.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                
                </Grid>

            </form>
    )
}
const formFields = {
    'id': 0,
    'start_date': null,
    'job_title': null,
    'project_title':null,
    'titled':null,
}
class DesignationListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            errors: {},
            errorMessage: false,
            designations: this.props.Designations, 
            edit: this.props.Edit, 
            deletion: this.props.Delete,
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
    }

    onUpdate = (id = 0) => () => {
        if (!id) {
            this.setState({
                id:0,
                open:true,
                formFields: formFields,
                isDelete: false,
                isEditable: false,
            });
        }
        else{
            const {UserID} = this.props;
            let apiEndpoint = 'api/staff/work/designation';
            this.setState({
                id : id
            });
            return httpServices.post(apiEndpoint, {
                        'userID':UserID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        open:true,
                        formFields: (id) ? response.data.selected_designation : formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const userID = this.props.UserID;
            const staffID = this.props.StaffID;
            const data = this.state.formFields;
            delete data.job_title_str;
            data.id = this.state.id;
            data.user_id = userID;
            data.staff_id = staffID;
            let tab = StaffConstants.STAFF_TABS[2];
            let module = 'designations';
            if (data) {
                let apiEndpoint = 'api/staff/work/save-designation';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.input_errors) {
                            if (!response.data.error) {
                                this.setState({
                                    designations:response.data.designations,
                                    open:false,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                        else {
                            this.setState({ errors : response.data.input_errors }); 
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/work/delete-designation';
        return httpServices.post(apiEndpoint, {
            'user_id': UserID,
            'staff_id': StaffID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[2];
                    let module = 'designations';
                    this.setState({
                        designations:response.data.designations,
                        open:false,
                    });
                    this.clearForm();
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.job_title) {
            errors["job_title"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    render(){
        const { designations, edit, deletion } = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Designation</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Designation" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Job Title</TableCell>
                            <TableCell>Project Role</TableCell>
                            <TableCell>Titled Employee</TableCell>
                            {_R.or(edit, deletion) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof designations != 'undefined' && designations.map((d) =>
                        <TableRow hover>
                            <TableCell>{d.start_date} {d.end_date ? ' - ' + d.end_date: ''}</TableCell>
                            <TableCell>{d.job_title_str}</TableCell>
                            <TableCell>{d.project_title}</TableCell>
                            <TableCell>{(d.titled === 1) ? 'Yes' : 'No'}</TableCell>
                           
                            {_R.or(edit, deletion) &&
                            <TableCell align="right">
                                {_R.equals(edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={this.onUpdate(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                                }
                                {_R.equals(deletion, true) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                        <DeleteIcon fontSize="small" onClick={this.onDelete(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(designations) &&
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium
                Open={this.state.open}
                Cancel={this.onClose}
                Title={this.state.isDelete ?  'Delete Designation' : (this.state.isEditable ? 'Edit Designation' : 'Add Designation')}
                OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                ErrorMessage={this.state.errorMessage}
                Content={
                    this.state.isDelete ?
                        'Do you want to delete this Designation?'
                    :
                    <DialogContent
                        FormInputs={this.state.formInputs}
                        JobTitles = {this.props.JobTitles ? this.props.JobTitles : []}
                        FormFields={this.state.formFields}
                        OnSubmit={this.onSubmit}
                        HandleOnChange = {this.onHandleChange}
                        Errors = {this.state.errors}
                    />
                }
            />
            </>
        )
    }
}
export { DesignationListContainer as DesignationList };
