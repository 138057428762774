import React from 'react';
import { connect } from "react-redux";
import { ErrorPage } from '../../common/error';
import {httpServices}  from '../../../services/httpServices';
import { WorkpolicySettingsComponent }  from './WorkpolicySettings.Component.js';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { Tabs } from '../../../redux/store/reducers/tabs';


let frequency_array = [{value:"DAILY", name:"Daily"}, {value:"WEEKLY", name:"Weekly"}];
class WorkpolicySettingsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            expenseCodeView : false,
            expenseProjectView : false,
            mileageView : false,
            expenseLimitView : false,
            permission : false,
            showLoader : true,
            dataLoaded : false,
            frequency_array : frequency_array
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        let apiEndpoint = 'api/settings/workpolicy';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        permission : true,
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
                else
                {
                   this.setState({
                       permission : false,
                       showLoader : false,
                       dataLoaded : true,
                    });
                }
            })
    };
    handleChange = (event, value) => {
        let obj = { page: "policy-settings", selected: value }
        this.props.setTabIndexValue(obj);
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission)
                        ?
                        <WorkpolicySettingsComponent 
                          FrequencyArray = {this.state.frequency_array}
                          handleChange={this.handleChange}
                         />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTabIndexValue: (obj) => dispatch(Tabs.actions.setSelectedTabIndex(obj))
    }
}
const mapStateToProps = (state) => {
    const selectedPolicySettingsTab = state.Tabs.allTabs['policy-settings'] ? state.Tabs.allTabs['policy-settings'] : 0;
    return {
        selectedPolicySettingsTab: selectedPolicySettingsTab,
    }
}
const connectedWorkpolicySettingsContainer = connect(mapStateToProps, mapDispatchToProps)(WorkpolicySettingsContainer);
export { connectedWorkpolicySettingsContainer as WorkpolicySettingsContainer };
