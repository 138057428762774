import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FormHelperText from '@mui/material/FormHelperText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import * as _R from 'ramda';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import * as _cs from '../../common/common';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const StyledDiv = styled('div')(({ theme }) => ({
  '& .root': {
    width: '100%',
  },
  '& .heading': {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}));
const DialogAddRule = (props) => {
    return (
        <>
        <form className="form-fullwidth">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={props.Errors.sort_order && true}>Rules</InputLabel>
                        <Select
                            value={props.FormFields.rule}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'rule',
                                id: 'rule'
                            }}
                            className={'input-item'}
                        >
                            {_R.type(props.Rules) !== 'Undefined' && props.Rules.map((item) =>

                                <MenuItem value={item.id}>{item.rule_name}</MenuItem>
                            )}
                        </Select>
                        {props.FormFields.rule_string && <span>{props.FormFields.rule_string}</span>}
                        {props.Errors.sort_order && <FormHelperText className="error">{props.Errors.sort_order}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="sort_order"
                            name="sort_order"
                            label="Sort Order"
                            type={"number"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: { min: 1 }
                            }}
                            error={(typeof props.Errors.sort_order !== 'undefined' && props.Errors.sort_order) && true}
                            value={_R.type(props.FormFields) !== 'Undefined' ? props.FormFields.sort_order : ''}
                            helperText={typeof props.Errors.sort_order !== 'undefined' && props.Errors.sort_order}
                            onChange={props.HandleOnChange}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
            </form>
        </>

    )
}
export const PolicyViewComponent = (props) => {
    const [expanded, setExpanded] = React.useState(true);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };
    let policyDetails = _R.type(props.Policy) !== 'Undefined' ? props.Policy : '';
    return (
        <React.Fragment>
            <div className="section-main section-main-header-fixed">
                <div className={'header-fixed'}>
                    <div className="section-header">
                        <div className="col-left">
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <div className="section-title-wrap">
                                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                    Policy Details
                                </Typography>
                                <Typography>ID: {policyDetails.id}</Typography>
                            </div>
                        </div>
                        <div className="action">                
                        <Button variant="contained" size="small"  color="primary" onClick={props.AddRuleClick}>Add Rule to Policy</Button>
                        </div>
                    </div>
                </div>
            <div className="section-body">
                <Paper>
                    <div className="timesheet_list-summary">
                        <div className="p-1rem summary box-details">
                            <ul className="list-standard list-ul-shrink">
                                <li className="list-item">
                                    <label><b>Policy Name</b></label>
                                    <span className="value">{policyDetails.work_policy_name}</span>
                                </li>
                                <li className="list-item">
                                    <label><b>Frequency</b></label>
                                    <span className="value">{policyDetails.frequency}</span>
                                </li>
                                <li className="list-item">
                                    <label><b>Status</b></label>
                                    <span className="value">{policyDetails.status == 1 ? "Active" : "Inactive"}</span>
                                </li>
                                {/*<li className="list-item">
                                    <label>Created By</label>
                                    <span className="value"></span>
                                </li>                    
                                <li className="list-item">
                                    <label>Created On</label>
                                    <span className="value"></span>
                                </li>
                                <li className="list-item">
                                    <label>Modified By</label>
                                    <span className="value"></span>
                                </li>
                                <li className="list-item">
                                    <label>Modified On</label>
                                    <span className="value"></span>
                                </li> */}                                
                               
                            </ul>                              
                        </div>
                    </div>
                    {/* Summary Section Ends here */}

                    <StyledDiv className={'root'}>
                            <Accordion expanded={expanded} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={'heading'}>Policy Rules</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Table size="small" aria-label="a dense table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row"><b>Rule Name</b></TableCell>
                                                <TableCell component="th" scope="row"><b>Location</b></TableCell>
                                                <TableCell component="th" scope="row"><b>Rule</b></TableCell>
                                                <TableCell component="th" scope="row"><b>Sort Order</b></TableCell>
                                                <TableCell component="th" scope="row"></TableCell>
                                            </TableRow>
                                            {_R.type(props.PolicyRule) !== 'Undefined' && props.PolicyRule.map((item) =>
                                                <TableRow>
                                                    <TableCell scope="row"> {item.rule_name}</TableCell>
                                                    <TableCell scope="row"> {item.location}</TableCell>
                                                    <TableCell scope="row"> {item.rule_string}</TableCell>
                                                    <TableCell scope="row"> {item.sort_order}</TableCell>
                                                    <TableCell scope="row">
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                                <EditIcon fontSize="small" onClick={() => props.EditPolicyRule(item.id)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>                                   
                                </AccordionDetails>
                            </Accordion>     
                        
                    </StyledDiv>
                </Paper>
            </div>
        </div>
        {(props.Popup === 'addRule') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SaveNewRuleClick}
                ButtonName = 'Add'
                Disabled={props.buttonDisabled}
                Title='Add Rule To Policy'
                Content={
                    <DialogAddRule
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                        Rules = {props.Rules}
                    />
                }
            />
        }
        {(props.Popup === 'editRule') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SaveNewRuleClick}
                ButtonName = 'Update'
                Disabled={props.buttonDisabled}
                Title='Edit Policy Rule'
                Content={
                    <DialogAddRule
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                        Rules = {props.Rules}
                    />
                }
            />
        }      
       
        <CustomizedSnackbars
            open={props.SnackbarOpen}
            variant={props.SnackbarMsgType ? props.SnackbarMsgType : 'success'}
            message={props.SnackbarMsg ? props.SnackbarMsg : ''}
            handleClose={props.SnackbarClose}
        />
    </React.Fragment>    
)
};
