import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileTimePicker  } from '@mui/x-date-pickers/MobileTimePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'react-dropdown-tree-select/dist/styles.css'
import './reactdropdown.scss';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const TimeInOutComponent  = (props) => {
    return (
        <>
        <Dialog
            className={'modal_default large'}
            open={props.Open}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">Time In/Out&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{props.SDate}</DialogTitle>
                <DialogContent>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                    {/*** DialogContent [START] ***/}
                        <div className={'mb-1'}>
                            <span className="error">{props.Error}</span>
                        </div>
                        <div className={'st-timeEntry_wrapper time-inout'}>
                            <div className={'st-timeEntry'}>
                                <div className={'st-timeEntry_row-header'}>
                                    <div className={'action'}>
                                        <div>
                                           <Button variant="contained" size="small" color="primary" className={'startIcon-noMargin'} onClick={props.AddRow} startIcon={<AddIcon/>}>Add</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={'st-timeEntry_entry'}>
                                {props.TimeInoutRows.map((tr, index) =>
                                    <div className={'st-timeEntry_entry-item with-border'}>
                                        <div className={'input-container_left'}>
                                            <div className={'te-row'}>
                                                <div className={'te-item'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <MobileTimePicker 
                                                            clearable
                                                            ampm={true}
                                                            label="Time In"
                                                            value={tr.time_in}
                                                            onChange={props.HandleTimeChange(index+'_timein')}
                                                            minutesStep={5}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            <div className={'te-row'}>
                                                <div className={'te-item'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <MobileTimePicker 
                                                            clearable
                                                            ampm={true}
                                                            label="Time Out"
                                                            value={tr.time_out}
                                                            onChange={props.HandleTimeChange(index+'_timeout')}
                                                            minutesStep={5}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            { props.TimesheetLocation ? 
                                                <div className={'te-row'}>
                                                    <div className={'te-item'}>
                                                        <InputLabel required>Location</InputLabel>
                                                        <FormControl variant='standard'>
                                                            <Select
                                                                value={(typeof tr.location !== 'undefined') ? tr.location : props.SelectedLocation}
                                                                onChange={props.HandleWlChange(index)}
                                                                inputProps={{
                                                                    name: 'location',
                                                                    id: 'location',
                                                                }}
                                                            >
                                                                <MenuItem value="0">No Location</MenuItem>
                                                                {typeof props.Locations != 'undefined' && props.Locations.map((value) =>
                                                                    <MenuItem value={value.location_id}>{value.location_name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className={'input-container_right'}>
                                            <div className={'te-row'}>
                                            {tr.hours != undefined && tr.hours > 0 &&  <div className={'te-time'}>{tr.hours}</div> }
                                                <div className={'te-action'}>
                                                    <IconButton className={'btn-tags-delete'} aria-label="delete" size="small" tabindex = {"-1"} onClick={()=>props.Delete(tr.id,index)}>
                                                        <DeleteIcon fontSize="inherit" tabindex = {"-1"} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className={'st-timeEntry_row-footer'}>
                                        <div className={'col-summary'}>
                                            <div className={'dayTotal'}>
                                                <span className={'number'}>{props.TotalHours}</span> Hours Total
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/*** DialogContent [END] ***/}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" size="small" onClick={props.Cancel}>Cancel</Button>
                        <Button variant="contained" size="small" color="primary" onClick={props.Save}>Save</Button>
                    </DialogActions>
            </Dialog>
            </>
    )
};
