import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import {DialogBoxMedium} from '../common/dialog.medium';

export const LevelsComponent= (props) => {    
        return (
            <>
               <div className={'section-main'}>
               <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Levels</Typography>
                    {(props.HasPermission) && <Tooltip title="Add Level" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} >
                            <AddIcon onClick={props.OnAddLevelClick}/>
                        </Fab>
                    </Tooltip>}
                </div>
                {
                (props.HasPermission) ? <div className={'section-content roles-table'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Level Name</TableCell>
                                <TableCell>Sort Order</TableCell>
                                 <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.Levels.map((ry,i) =>    
                            <TableRow hover>
                                <TableCell>{ry.name}</TableCell>   
                                <TableCell>{ry.sort_order}</TableCell>                             
                                <TableCell align="right">
                                    {parseInt(ry.default) !== 1 &&
                                        <>
                                    {props.EditPermission && <Tooltip title="Edit" aria-label="edit">
                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                            <EditIcon fontSize="small" onClick={props.EditLevelClick(ry.id)} />
                                        </IconButton>
                                    </Tooltip>}
                                    {props.DeletePermission && <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Delete" >
                                            <DeleteIcon fontSize="small" onClick={props.DeleteLevelClick(ry.id)}/>
                                        </IconButton>
                                    </Tooltip>}
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                :
                <div><p className='error'>You are not authorized to view the Levels</p></div>}
                </div>
                
                {props.PopupType === 'add' &&
                <DialogBoxMedium 
                    Open= { props.Open }  
                    Cancel = { props.OnClose }
                    Title = { props.IsEditable ? 'Edit Level' : 'Add Level' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogContent FormProps = { props }/> }
                />
                }
                {props.PopupType === 'delete' &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteLevel}
                    Title='Confirm Delete' 
                    ButtonName='Ok'                
                    Content={
                        <DialogWithConfirmMessage msg="Do you really want to delete this level ?" />
                    }
                />
                }
            </>
        )
}

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.name && true }
                            helperText = { Errors.name }
                        />
                        <TextField variant="standard"
                            required
                            type = "number"
                            id = "sort_order"
                            name = "sort_order"
                            label = "Sort Order"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.sort_order : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.sort_order && true }
                            helperText = { Errors.sort_order }
                        />
                    </ FormControl>
                </Grid>               
            </Grid>
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
