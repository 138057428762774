import React from 'react';
import DualListBox from 'react-dual-listbox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
 

export class DualListBoxSelector extends React.Component {
    state = {
        selected : [],
        options : [], 
    };
    onChange = (selected) => {
        this.props.HandleChange(selected,this.props.Id);
    };
    render() {
        return (
            <DualListBox
                canFilter = {true}
                showHeaderLabels = {true}    
                options={this.props.Available}
                selected={this.props.Selected}
                onChange={this.onChange}
                icons={{
                    moveToAvailable: <KeyboardArrowLeftIcon />,
                    moveAllToAvailable: [
                        <FirstPageIcon key={0} />,
                    ],
                    moveToSelected: <KeyboardArrowRightIcon/>,
                    moveAllToSelected: [
                        <LastPageIcon key={0} />,
                    ],
                }}
            />
        );
    }
}
