import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';


import {DialogBoxMedium} from '../common/dialog.medium';

export const Yearly401kSettingsComponent = (props) => { 
        return (
            <>
               <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Yearly Settings</Typography>
                    {(props.EditPermission) &&
                    <Tooltip title="Add Yearly Setting" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} >
                            <AddIcon onClick={props.onAddYearlySetting}/>
                        </Fab>
                    </Tooltip>
                    }
                </div>
                <div className={'section-content roles-table'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Year</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.YearlySettings.map((ry,i) =>    
                            <TableRow hover>
                                <TableCell>{ry.name}</TableCell>   
                                <TableCell>{ry.year}</TableCell>
                                <TableCell>{ry.value}</TableCell>
                                <TableCell align="right">
                                    {(parseInt(ry.default) !== 1 && props.EditPermission) &&
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                            <EditIcon fontSize="small" onClick={props.EditYearlySettingClick(ry.id)} />
                                        </IconButton>
                                    </Tooltip>                                  
                                    }
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                </div>
                
                {props.PopupType === 'add' &&
                <DialogBoxMedium 
                    Open= { props.Open }  
                    Cancel = { props.OnClose }
                    Title = { props.IsEditable ? 'Edit Yearly Settings' : 'Add Yearly Settings' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogContent FormProps = { props }/> }
                />
                }
              
            </>
        )
}

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                       <InputLabel htmlFor="name">Name</InputLabel>
                        <Select
                            required
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'name',
                                id: 'name',
                            }}>
                            {props.FormProps.YearlySettingsNames.map((jt, i) =>
                                <MenuItem value={jt} key={'names'+i}>{jt}</MenuItem>
                            )}
                        </Select>
                       {Errors.name && <FormHelperText>{Errors.name}</FormHelperText> }
                    </ FormControl>

                    <FormControl>
                        <TextField
                            required
                            id = "value"
                            name = "value"
                            label = "Value"
                            type = "number"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.value : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.value && true }
                            helperText = { Errors.value }
                        />
                        <TextField
                            required
                            id = "year"
                            name = "year"
                            label = "Year"
                            type = "number"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.year : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.year && true }
                            helperText = { Errors.year }
                        />

                       </ FormControl>
                </Grid>               
            </Grid>
        </form>
    )
}
