import 'date-fns';
import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatName } from '../../common/common';

const StyledDiv = styled('div')(({ theme }) => ({
    width: 500,
    '& > * + *': {
        marginTop: theme.spacing(3),
    },
}));

export const OvertimeAddComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.Close}
                ButtonName='Submit' 
                OnSubmit={parseInt(props.FormFields.id) !== 0 ? ()=>props.AddRequest(props.FormFields.id) : ()=>props.AddRequest(0)}
                Title={parseInt(props.FormFields.id) !==0 ? 'Edit Overtime Request' :'New Overtime Request'}
                Content={
                    <DialogAddOvertime FormProps={props}  />
                }
            />
             <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid?'success':'error'}
                message={props.Valid? props.SuccessMessage:props.ErrorMessage}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    )
}

const DialogAddOvertime = (props) => {
    const {FormProps} = props;
    let doj = FormProps.Doj;
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id = "startDate"
                            name = "startDate"
                            label = "Week Starting On"
                            type = "date"
                            required
                            value = {(typeof FormProps.FormFields.startDate !== 'undefined') ? FormProps.FormFields.startDate  : null}
                            onChange = {FormProps.HandleOnChange}
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            inputProps={{
                                min: doj,
                                max: (typeof FormProps.RelievedDate !== 'undefined' && FormProps.RelievedDate) ? FormProps.RelievedDate : null,
                            }}
                            error = {FormProps.Errors.startDate && true}
                            helperText = {FormProps.Errors.startDate }
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>
                {props.FormProps.Week != null &&
                    <Grid item xs={12} md={6} >
                        <StyledDiv>
                            <div>
                                <Alert severity="info"> {props.FormProps.Week} </Alert> </div>
                        </StyledDiv>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id = "hours"
                            name = "hours"
                            label = "Hours"
                            type = "hours"
                            placeholder ="[Format: nn.nn or HH:MM ]"
                            required
                            value = {FormProps.FormFields.hours}
                            onChange = {FormProps.HandleOnChange}
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            error = {FormProps.Errors.hours && true }
                            helperText = {FormProps.Errors.hours }
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                required
                                value={FormProps.FormFields.description}
                                onChange={FormProps.HandleOnChange}
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                                error={FormProps.Errors.description && true}
                                helperText={FormProps.Errors.description}
                            />
                    </ FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id="refNo"
                            name="refNo"
                            label="Reference No"
                            type="text"
                            value={FormProps.FormFields.refNo}
                            onChange={FormProps.HandleOnChange}
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            error={FormProps.Errors.refNo && true}
                            helperText={FormProps.Errors.refNo}
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>
           
            { FormProps.FormFields.file_name !== null &&
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id="file_name"
                            name="file_name"
                            label="File Name"
                            type="text"
                            value={(typeof FormProps.FormFields.file_name !== 'undefined') ? FormProps.FormFields.file_name  : null}
                            disabled = "true"
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>
            }
            
            { FormProps.FormFields.file_name &&
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(typeof FormProps.FormFields.remove_file !== 'undefined') ? FormProps.FormFields.remove_file : false}
                                value={(typeof FormProps.FormFields.remove_file !== 'undefined') ? FormProps.FormFields.remove_file : null}
                                id = "remove_file"
                                name ="remove_file"
                                onChange={FormProps.HandleOnChange}
                            />
                        }
                        label="Remove attachment"
                    />
                </Grid>
            }

            { (FormProps.FormFields.remove_file || FormProps.FormFields.file_name === null) &&
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id="file"
                            name="file"
                            label={(typeof FormProps.FormFields.file !== 'undefined' && FormProps.FormFields.file) ? "Replace file" : "Upload File"}
                            InputLabelProps={{
                                    shrink: true,
                                }}
                            type="file"
                            value = {(typeof FormProps.FormFields.file !== 'undefined') ? FormProps.FormFields.file  : null}
                            onChange = {FormProps.HandleOnChange}
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>
             }
          { FormProps.Recipients &&
             <Grid item xs={12} md={12}>
                 <ul className="list-standard list-ul-shrink">
                 <label><b>Email Recipients</b></label><br/>
                 <label><i>Enter name of other users to receive this notification</i></label>
                 <li className="list-item"></li>
                 {typeof FormProps.Recipients !== 'undefined' && Object.keys(FormProps.Recipients).map((item) =>
                    <li className="list-item">
                    {(typeof FormProps.Approvers !== 'undefined' && FormProps.Approvers && FormProps.Approvers.indexOf(FormProps.Recipients[item]['user_id']) !== -1) ?
                     <span className="value">{formatName(FormProps.Recipients[item].first_name, null, FormProps.Recipients[item].last_name) } (Reviewer)</span>
                        :
                     <span className="value">{formatName(FormProps.Recipients[item].first_name, null, FormProps.Recipients[item].last_name)}</span>
                    }
                    </li>
                    )}
                      </ul>
                </Grid>
            }
            <Grid item xs={12} md={12}>
                <StyledDiv>
                    <Autocomplete
                        value={(typeof FormProps.FormFields.custom_recipient !== 'undefined' ? FormProps.FormFields.custom_recipient : [])}
                        onChange={FormProps.HandleRecipientChange}
                        multiple
                        id="tags-outlined"
                        options={(typeof FormProps.Custom_recipients !== 'undefined') ? FormProps.Custom_recipients : {}}
                        getOptionLabel={(option) => formatName(option.user_first_name, null, option.user_last_name)}
                        getOptionSelected={(option, value) => option.user_id === value.user_id}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Other Recipients"
                                placeholder="Other Recipients"
                            />
                        )}
                    />
                </StyledDiv>
            </Grid>
        </Grid>
        <div>
        <Alert severity="info">DISCLAIMER: Submitting an Overtime Request does NOT guarantee overtime pay unless it is specified in your employment/contract terms. An Overtime request is intended just to top up your weekly limit. Please contact your Supervisor/PM or HR/Finance department if you have any questions. </Alert>
        </div>
        </form> 
    )
}


