import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const API_URL = process.env.REACT_APP_API_URL;

export const WorkHoursEffectivenessReportComponent = (props) => {
     const gridStyle = {
        margin: '15px 0px'
    };
    const formValues = props.FormFields ? props.FormFields : {};
    const formInputs = props.FormInputs ? props.FormInputs : {};
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Work Hours Effectiveness Report</h6>
                </Grid>
                <Grid item>
                <form className="form-fullwidth" action={API_URL+'api/report/workhoursEffectivenessReport'} method="post" id="workhoursEReport">
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "startDate"
                                    name = "startDate"
                                    label = "Start Date"
                                    type = "date"
                                    required
                                    onChange = {props.HandleOnChange}
                                    value={(typeof formValues.startDate !== 'undefined') ? formValues.startDate : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { max: (typeof formValues.startDateMax !== 'undefined') ? formValues.startDateMax : null} 
                                    }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "endDate"
                                    name = "endDate"
                                    label = "End Date"
                                    required
                                    type = "date"
                                    value={(typeof formValues.endDate !== 'undefined') ? formValues.endDate : null}
                                    onChange = {props.HandleOnChange}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { min: (typeof formValues.minEndDate !== 'undefined') ? formValues.minEndDate : null} 
                                    }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl variant="standard">
                                <InputLabel>Staff Status</InputLabel>
                                <Select
                                    value={(typeof formValues.staffStatus !== 'undefined') ? formValues.staffStatus : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staffStatus',
                                        id: 'staffStatus',
                                    }}
                                >
                                    <MenuItem value={'0'}>{"All"}</MenuItem>
                                    { typeof formInputs.staffStatuses != 'undefined' && formInputs.staffStatuses.map((ss, i) =>
                                    <MenuItem value={ss.user_status_id}>{ss.user_status_name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <Button className={'ml-1'} size="small" color="primary" variant="contained" type="submit" value="export" name="export_btn">Export</Button>
                        </Grid>
                    </Grid>
                </form>
                </Grid>
            </Grid>
        </Paper>
        </>
     )
}
