import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatName } from '../../common/common';

export const ChangeHistory = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
        >
        <Typography variant="subtitle1" gutterBottom>Change History</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {props.History.length > 0 ?
            <Table size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Entity Field</TableCell>
            <TableCell>Previous Value</TableCell>
            <TableCell>Current Value</TableCell>
            <TableCell>Modified By</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {(props.History.length > 0) && (props.History).map((item) =>
                <TableRow>
                <TableCell component="th" scope="row"> {item.created_on} </TableCell>
                <TableCell component="th" scope="row"> {item.display_name} </TableCell>
                <TableCell component="th" scope="row"> {item.prev} </TableCell>
                <TableCell component="th" scope="row"> {item.new}</TableCell>
                <TableCell component="th" scope="row"> {formatName(item.user_first_name,null,item.user_last_name)} </TableCell>
                </TableRow>
            )}
            </TableBody>
            </Table>
            :
            props.Expense === true ?
            <Typography variant="body2" gutterBottom align='center'>No changes for this expense</Typography>
                    :
            <Typography variant="body2" gutterBottom align='center'>No changes for this request.</Typography>
        }
        </AccordionDetails>
        </Accordion>

    );
}
