import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import * as _R from 'ramda';

export const LateSubmissionHistory = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
        {_R.isEmpty(props.LateSubmissionHistory) === false  &&
            <>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell style={{ width: 50 }}>Pay Period</TableCell>
                    <TableCell style={{ width: 100 }}>Days Delayed</TableCell>
                    <TableCell style={{ width: 100 }}>Reason For Late Submission</TableCell>
                    <TableCell style={{ width: 100 }}>Waiver Recommended (Yes / No)</TableCell>
                    <TableCell style={{ width: 100 }}>Justification of Waiver by Reviewer</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>                
                    {_R.type(props.LateSubmissionHistory) !== 'Undefined' && Object.keys(props.LateSubmissionHistory).map((item,k) => 
                    <>
                    <TableRow>
                    <TableCell component="th" scope="row"> {typeof props.LateSubmissionHistory[k].pay_period !== 'undefined' ? props.LateSubmissionHistory[k].pay_period : ''} </TableCell>
                    <TableCell component="th" scope="row"> {typeof props.LateSubmissionHistory[k].submission_delay !== 'undefined' ? props.LateSubmissionHistory[k].submission_delay : ''}</TableCell>
                    <TableCell component="th" scope="row"> {typeof props.LateSubmissionHistory[k].late_submission_reason !== 'undefined' ? props.LateSubmissionHistory[k].late_submission_reason : ''}</TableCell>
                    <TableCell component="th" scope="row">{typeof props.LateSubmissionHistory[k].waiver_status !== 'undefined' ? props.LateSubmissionHistory[k].waiver_status : ''}</TableCell>
                    <TableCell component="th" scope="row">{typeof props.LateSubmissionHistory[k].waiver_justification !== 'undefined' ? props.LateSubmissionHistory[k].waiver_justification : ''}</TableCell>                  
                    </TableRow>
                    </>
                    )}
                </TableBody>
            </Table>
            </>
        }
        {_R.isEmpty(props.LateSubmissionHistory) &&
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row" colSpan="8">No Late Submission History for this period</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        }
    </div>
  );
}
