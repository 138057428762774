import React from 'react';
import { RequestConfigComponent } from './RequestConfigComponent';
import { httpServices } from '../../services/httpServices';
import { ErrorPage } from '../common/error';

const formFields = {
    'id': 0,
    'name': null,
    'code': null,
    'description': null,
    'status': null,
    'auto_approval' : null,
};

export class RequestConfig extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            request_config: [],
            title : 'Request Config',
            formFields: formFields,
            open: false,
            errors: {},
            Open: false,
            hasPermission : true,
            snackbarOpen : false,
            snackbarMsg : '',
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount = () => {
        this.getAllRequests();
    };
    getAllRequests = () => {
        let apiEndpoint = 'api/system/requestconfig';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    if (response.data.request_config && response.data.request_config.length) {
                        this.setState({request_config : response.data.request_config});
                    }
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
            this.setState({ 
                formFields: ff
            });        
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/system/edit-request-config', { data })
                    .then(response => {
                        if (response.data.valid) {
                            if(data.id){
                                let data = response.data.request_config;
                                this.setState({
                                    request_config : data, 
                                    Open : false,
                                    snackbarOpen : true,
                                    snackbarMsg : 'Request config updated successfully',
                                });
                            }
                        }
                        else
                        {
                            this.setState({
                                errors : response.data.errors,
                            });   
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    clearForm = () => {
        const ff = {
             'id':0,
             'name': null,
             'code': null,
             'description': null,
             'status': null,
             'auto_approval' : null
          };
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    
    onClose = () =>{
        this.clearForm();
        this.setState({
            Open: false
        });
    };
    
    onUpdate = (id) => {
        return httpServices.post('api/system/get-request-config-by-id', {'id': id})
            .then(response => { 
                if (response.data.permission) {
                    let data = response.data.request_config;
                    if (data){
                        const inputs = {
                            'id': data.id,
                            'name': data.name,
                            'code': data.code,
                            'description': data.description,
                            'status': data.status,
                            'auto_approval': data.auto_approval
                        };
                        this.setState({
                            formFields: inputs,
                            Open: true,
                            id: data.id,
                        });
                    }
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
 
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.status === null || this.state.formFields.status.length === 0) {
            errors["status"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.auto_approval === null || this.state.formFields.auto_approval.length === 0) {
            errors["auto_approval"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    render(){
        return(
            (this.state.hasPermission)
            ?
            <RequestConfigComponent
               Title={this.state.title}
               RequestConfig = {this.state.request_config}
               FormFields={this.state.formFields}
               OnSubmit={this.handleSubmit} 
               HandleOnChange={this.handleOnChange}
               Open= {this.state.Open} 
               OnClose={this.onClose.bind(this)}
               OnUpdate={this.onUpdate.bind(this)} 
               Errors={this.state.errors}
               SnackbarOpen = {this.state.snackbarOpen}
               SnackbarMsg = {this.state.snackbarMsg}
               SnackbarClose = {this.snackbarClose}
            />
            :
            <ErrorPage/>
        )
    }
}
