import 'date-fns';
import React from 'react';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import * as _ts from '../../common/treeSelect';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { formatName } from '../../common/common';
import { CustomDatePicker } from '../../common/CustomDatePicker';

const AutoCompleteDiv = styled('div')(({ theme }) => ({    
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },    
}));
export const LocationAddComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Loading={props.Loading}
                ButtonName='Submit' 
                OnSubmit={props.HandleSubmit}
                Title={props.FormFields.id ? 'Edit Work Location Change Request' : 'Work Location Change Request'}
                Content={
                    <DialogAddLocation FormProps={props} msg="Do you want to save without resolving issues?" />
                }
            />
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid ? 'success' : 'error'}
                message={props.Valid ? props.SuccessMessage : props.ErrorMessage}
                handleClose={props.SnackbarClose}
            />

        </React.Fragment>
    )
}

const DialogAddLocation = (props) => {
    const { FormProps } = props;
    let show_upload = true;
    if (FormProps.Filters.recipients) {
        FormProps.Filters.recipients.forEach(myFunction);
        function myFunction(item, index) {
            if (typeof item == 'object') {
                item[0].rev = "";
                FormProps.Filters.reviewers.forEach(eachReviewerFunction);
                function eachReviewerFunction(eachReviewer, index1) {
                    if (parseInt(eachReviewer[0].user_id) === parseInt(item[0].user_id)) {
                        if (FormProps.Filters.primary.includes(item[0].user_id)) {
                            item[0].rev = "(Primary Reviewer)";
                        }
                        else if (parseInt(FormProps.FormFields.projects) !== 0) {
                            item[0].rev = "(Secondary Reviewer)";
                        }
                        else {
                            item[0].rev = "(Reviewer)";
                        }
                    }
                }
            }
        }
    }
    if (FormProps.FormFields.file_name) {
        if (FormProps.FormFields.remove_file) {
            show_upload = true;
        }
        else {
            show_upload = false;
        }
    }
    let location = (_R.type(FormProps.location) !== 'Undefined' && _R.type(FormProps.location) !== 'Undefined') ? FormProps.location : null;
    let projects = (_R.type(FormProps.Filters) !== 'Undefined' && _R.type(FormProps.Filters.projects) !== 'Undefined') ? FormProps.Filters.projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(FormProps.FormFields) !== 'Undefined' && FormProps.FormFields.projects) ? FormProps.FormFields.projects : []));
    }

    return (
        FormProps.TimesheetLocation ? 
        <>
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                {(typeof props.FormProps.ApprovedTimesheetErr !== 'undefined' && props.FormProps.ApprovedTimesheetErr !== '') ?
                <Grid item xs={12} md={12} id="errorMsg">
                    <><span className={'error'}>{props.FormProps.ApprovedTimesheetErr}</span></>
                </Grid>
                :
                <>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "start_date"
                            name = "start_date"
                            selectedDate = {FormProps.FormFields.start_date}
                            handleDateChange={FormProps.HandleDateChange}
                            required = {true}
                            label = {'Start Date'}
                            minDate={FormProps.Filters.joined_date != null ? FormProps.Filters.joined_date : null}
                            maxDate={FormProps.Filters.relieved_date}
                        />
                        <small className='error'>{FormProps.Errors.start_date && FormProps.Errors.start_date}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "end_date"
                            name = "end_date"
                            selectedDate = {FormProps.FormFields.end_date}
                            handleDateChange={FormProps.HandleDateChange}
                            required = {true}
                            label = {'End Date'}
                            minDate={FormProps.FormFields.start_date ?  FormProps.FormFields.start_date : FormProps.Filters.joined_date != null ? FormProps.Filters.joined_date : null}
                            maxDate={FormProps.Filters.relieved_date}
                        />
                        <small className='error'>{FormProps.Errors.end_date && FormProps.Errors.end_date}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <input type='hidden' name='projects' value={(typeof FormProps.FormFields.projects !== 'undefined') ? FormProps.FormFields.projects : null} />
                        <TreeSelectComponent
                            dropdown_mode={"offsite"}
                            data={projects}
                            placeholderText={"Search Projects"}
                            type='project'
                            HandleTreeChange={FormProps.HandleTreeChange}
                        />
                        <small className='error'>{FormProps.Errors.projects && FormProps.Errors.projects}</small>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="hpd" required >Hours per day</InputLabel>
                        <Select
                            value={(typeof FormProps.FormFields.duration !== 'undefined') ? FormProps.FormFields.duration : ''}
                            onChange={FormProps.HandleOnChange}
                            inputProps={{
                                name: 'duration',
                                id: 'duration',
                            }}>
                            <MenuItem value={"full"} key={'full-0'}>Full Day</MenuItem>
                            <MenuItem value={"half"} key={'half-0'}>Half Day</MenuItem>
                            <MenuItem value={"hour"} key={'hour-0'}>Hours</MenuItem>
                            <MenuItem value={"no_limit"} key={'limit-0'}>No Limit</MenuItem>
                        </Select>
                        <FormHelperText className='error'></FormHelperText>
                    </ FormControl>
                </Grid>
                {FormProps.FormFields.duration === "hour" &&
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                required
                                id="hours"
                                name="hours"
                                label="Hours"
                                type="text"
                                variant = 'standard'
                                placeholder="[Format: nn.nn or HH:MM ]"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={(typeof FormProps.FormFields.hours !== 'undefined') ? FormProps.FormFields.hours : null}
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.hours && true}
                                helperText={FormProps.Errors.hours}
                            />
                        </ FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="hpd" required >Location</InputLabel>
                        <Select
                            value={(typeof FormProps.FormFields.location !== 'undefined') ? FormProps.FormFields.location : ''}
                            onChange={FormProps.HandleOnChange}
                            inputProps={{
                                name: 'location',
                                id: 'location',
                            }}>
                            <MenuItem value=""></MenuItem>
                            {typeof FormProps.FormFields.locations !== 'undefined' && FormProps.FormFields.locations.map((loc, key) =>
                                <MenuItem value={loc.id} >{loc.location}</MenuItem>
                            )}
                        </Select>
                        <small className='error'>{FormProps.Errors.location && FormProps.Errors.location}</small>
                    </ FormControl>
                </Grid>                
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            required
                            multiline={true}
                            variant="outlined"
                            value={(typeof FormProps.FormFields.description !== 'undefined') ? FormProps.FormFields.description : null}
                            onChange={FormProps.HandleOnChange}
                            error={FormProps.Errors.description && true}
                            helperText={FormProps.Errors.description}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id="reference_no"
                            name="reference_no"
                            label="Reference No"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            value={(typeof FormProps.FormFields.reference_no !== 'undefined') ? FormProps.FormFields.reference_no : null}
                            onChange={FormProps.HandleOnChange}
                            error={FormProps.Errors.reference_no && true}
                            helperText={FormProps.Errors.reference_no}
                            variant = 'standard'
                        />
                    </ FormControl>
                </Grid>
                {FormProps.FormFields.file_name &&
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                required
                                id="file_name"
                                name="file_name"
                                label="File Name"
                                type="text"
                                value={(typeof FormProps.FormFields.file_name !== 'undefined') ? FormProps.FormFields.file_name : null}
                                disabled="true"
                                variant = 'standard'
                            />
                        </ FormControl>
                    </Grid>
                }
                {FormProps.FormFields.file_name &&
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(typeof FormProps.FormFields.remove_file !== 'undefined') ? FormProps.FormFields.remove_file : false}
                                    value={(typeof FormProps.FormFields.remove_file !== 'undefined') ? FormProps.FormFields.remove_file : null}
                                    id="remove_file"
                                    name="remove_file"
                                    onChange={FormProps.HandleOnChange}
                                />
                            }
                            label="Remove attachment"
                        />
                    </Grid>
                }
                {show_upload &&
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="file"
                                name="file"
                                label={(typeof FormProps.FormFields.file_name !== 'undefined' && FormProps.FormFields.file_name) ? "Replace file" : "Upload File"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="file"
                                value={(typeof FormProps.FormFields.file !== 'undefined') ? FormProps.FormFields.file : null}
                                onChange={FormProps.HandleOnChange}
                                variant = 'standard'
                            />
                        </ FormControl>
                    </Grid>
                }
                {FormProps.Filters.recipients && (FormProps.Filters.recipients.length > 0) && (FormProps.FormFields.projects.length > 0) &&
                    <Grid item xs={12} md={12}>
                        <ul className="list-standard list-ul-shrink">
                            <label><b>Email Recipients</b></label>
                            <li className="list-item"></li>
                            {(typeof FormProps.Filters.recipients !== 'undefined' && FormProps.Filters.recipients) && FormProps.Filters.recipients.map((item) =>
                                <li className="list-item">
                                    <span className="value">{formatName(item[0].first_name, null, item[0].last_name)} {item[0].rev}</span>
                                </li>
                            )}
                        </ul>
                    </Grid>
                }
                <Grid item xs={12} md={12}>
                <AutoCompleteDiv>                    
                        <Autocomplete
                            value={(typeof FormProps.FormFields.custom_recipient !== 'undefined' && FormProps.FormFields.custom_recipient) ? FormProps.FormFields.custom_recipient : []}
                            onChange={FormProps.handleRecipientChange}
                            multiple
                            id="tags-outlined"
                            options={(typeof FormProps.Filters.custom_recipients !== 'undefined') ? FormProps.Filters.custom_recipients : []}
                            getOptionLabel={(option) => formatName(option.user_first_name, null, option.user_last_name)}
                            getOptionSelected={(option, value) => option.user_id === value.user_id}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Other Recipients"
                                    placeholder="Other Recipients"
                                />
                            )}
                        />                    
                </AutoCompleteDiv>
                </Grid>
            </>
                }
                
            </Grid>
        </form>
        </>
        :
        <>
        <span className='error'>Timesheet Location is disabled</span>
        </>
    )
}
