import React from 'react';
import {AdjustmentComponent} from './Adjustment.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb

const year = new Date().getFullYear();
const formFields = {
    'id' : 0,
    'user_id': null,
    'category': 'Adjustment',
    'hours': null,
    'year': year,
    'update_method': null,
    'payperiod':null,
     'note': null,
    'accrual': null,
    'timeoff_type_id':null,
    'payperiod_id':null,
    'adjustment_period': null
}
const formInputs = {

}

export class AdjustmentDetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            formInputs: formInputs,
            errors: {},
            adjustment: [],
            intervals:[],
            open:false,
            isEdit: false,
            openNote:false,
            variant : 'success',
            snackbar : false,
            buttonLoader : false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    snackbarClose = () => {
        this.setState({
            snackbar: false,
        });
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const fieldValue = evt.target.value;
          if (name === 'year') {
            this.getPayPeriods(fieldValue);
        }
        if (name === 'timeoff_type_id') {
            const timeofftypeData = this.props.TimeofftypeDetails;
            timeofftypeData.forEach(function (type) {
                if (type.id === fieldValue) {
                    f['timeoff_type_id'] = fieldValue;
                    f['adjustment_period'] = type.adjustment_period;
                }
            })
            this.getTimeOffTypeDetails(fieldValue);
        }
        if (name === 'pay_period' && evt.currentTarget.dataset.sdate) {
            f.sdate = evt.currentTarget.dataset.sdate;
        }
        if (name === 'pay_period' && evt.currentTarget.dataset.edate) {
            f.edate = evt.currentTarget.dataset.edate;
        }
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    getTimeOffTypeDetails = (id) => {
        let apiEndpoint = 'api/settings/timeoff/get-timeoff-type';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data) {
                    if (response.data.timeoff_details) {
                        let ff = this.state.formInputs;
                        ff['adjustment_type'] = response.data.timeoff_details.adjustment_type;
                        this.setState({
                            formInputs : ff,    
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    getPayPeriods = (year) => {
        const userID = this.props.UserID;
        if (year) {
            return httpServices.post('api/timeoff/getTimeoffAdjustment', { year: year, 'user_id': userID })
                .then(response => {
                    if (response.data.pay_periods) {
                        this.setState({
                            intervals: response.data.pay_periods,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleValidation() {
        let formIsValid = false;
        let errors = {};
        let hours = this.state.formFields.hours;
        if (!this.state.formFields.timeoff_type_id) {
            errors["timeoff_type_id"] = "Cannot be empty";
        }
        if (!this.state.formFields.category) {
            errors["category"] = "Cannot be empty";
        }
        if (!this.state.formFields.year) {
            errors["year"] = "Cannot be empty";
        }
        if (!this.state.formFields.payperiod_id && this.state.formFields.adjustment_period === 'payperiod') {
            errors["payperiod_id"] = "Cannot be empty";
        }
        if (!hours) {
            errors["hours"] = "Cannot be empty";
        }
        else if (hours && isNaN(hours)) {
            errors["hours"] = "Hours is invalid";
        }
        if (!this.state.formFields.note) {
            errors["note"] = "Cannot be empty";
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    saveAdjusments =() => {
        var len = this.state.adjustment.length;
        if (len === 0) {
            if (_R.type(this.props.PTOInfo) !== 'Undefined') {
                this.setState({adjustment: this.props.PTOInfo});
            }
        }
        if (this.handleValidation()) {
            this.setState({buttonLoader : true });
            const adjustmentforPeriod = this.state.formFields.adjustment_period;
            if (adjustmentforPeriod === 'year') {
                const f = this.state.formFields;
                f['payperiod_id'] = null;
                this.setState({ formFields: f });
            }
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.staff_id = staffID;
            data.user_id = userID;
            data.adjustment_period = adjustmentforPeriod;
            return httpServices.post('api/timeoff/saveTimeoffAdjustments', {data})
                .then(response => {
                    if (response.data.valid) {
                        if (!response.data.errors) {
                            this.props.GetTimeoffDetails();
                            this.setState({
                                buttonLoader : false,
                                open: false,
                                variant: 'success',
                                message: response.data.message,
                                snackbar: true,
                            });
                            this.clearForm();
                        }
                        else {
                            this.setState({
                                open: true,
                                variant: 'error',
                                errors: response.data.errors,
                                snackbar: true,
                                buttonLoader: false
                            });
                        }
                    }
                    else {
                        if (response.data.errors.duplicate_error) {
                            this.setState({
                                buttonLoader : false,
                                open: true,
                                variant: 'error',
                                snackbar: true,
                                errors: response.data.errors,
                            });
                        }
                        else {
                            this.setState({
                                buttonLoader : false,
                                errors: response.data.errors,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }

    onAdd = () => {
        this.setState({
            open: true,
            isEdit: false,
            id: 0,
            formFields: {}
        });
    }

    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            payperiod_id: null,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    onUpdate = (id) => {
        return httpServices.post('api/timeoff/getTimeoffAdjustment', {'id': id})
            .then(response => {
                if (response.data) {
                    let formInputs = this.state.formInputs;
                    formInputs['adjustment_type'] = response.data.adjustment_type;
                    this.getPayPeriods(response.data.timeoff_adjustment.year)
                    this.setState({
                        formFields: response.data.timeoff_adjustment,
                        formInputs: formInputs,
                        open: true, 
                        id: id,
                        isEdit: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleClose = () =>{
        this.setState({
            openNote:false,
        });
    }
    render(){
        return(
            <AdjustmentComponent
            Timeoff_Adjusment={_R.type(this.props.PTOInfo) !== 'Undefined' ? this.props.PTOInfo: undefined}
            SaveAdjusments={this.saveAdjusments.bind(this)}
            Years = {_R.type(this.props.Years) !== 'Undefined' ? this.props.Years: undefined}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields}
            FormInputs={this.state.formInputs} 
            Open={this.state.open}
            Close={this.onClose.bind(this)}
            OnAdd={this.onAdd.bind(this)}
            HandleUpdate={this.onUpdate.bind(this)}
            Errors={this.state.errors}
            IsEdit = {this.state.isEdit}
            Edit = {this.props.Edit}
            openNote={this.state.openNote}
            CloseNote={this.handleClose.bind(this)}
            CurrentYearValue={this.props.CurrentYear}
            TimeofftypeData={this.props.TimeofftypeDetails}
            Intervals={this.state.intervals}
            Variant={this.state.variant}
            Message={this.state.message}
            Snackbar={this.state.snackbar}
            SnackbarClose={this.snackbarClose}
            ButtonLoader = {this.state.buttonLoader}
            />
        )
    }
}
