import React from 'react';
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';

import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';


const DialogContent = (props) => {
    const {FormFields, HandleOnChange, HandleKeyIdentifierChange} = props;
    const filter = createFilterOptions();
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                        <Autocomplete
                         id="key_identifier"
                         onChange={HandleKeyIdentifierChange}
                         value={FormFields.key_identifier}
                         error={props.Errors.key_identifier && true}
                         options={props.KeyIdentifiers}  
                         getOptionLabel={option => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.name) {
                                return option.name;
                            }
                            else if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Add "${params.inputValue}"`,
                                    id : 0,
                                });
                            }
                            return filtered;
                        }}
                         renderInput={(params) => (
                                <TextField variant="standard"
                                    {...params}
                                    required
                                    name="key_identifier"
                                    label="Key Identifier"
                                    placeholder="Key Identifier"
                                />
                            )} 
                            /> 
                         <small className='error'>{props.Errors.key_identifier && props.Errors.key_identifier}</small>
                        </ FormControl>
                    </Grid>
                
                    <Grid item xs={12} md={6}>
                        <FormControl Autocomplete="on">
                            <TextField variant="standard"
                                id="alias_name"
                                name="alias_name"
                                label="Alias Name"
                                required
                                type="text"
                                fullWidth
                                onChange={HandleOnChange}
                                value={FormFields.alias_name}
                                error={props.Errors.alias_name && true}
                            />
                            <small className='error'>{props.Errors.alias_name && props.Errors.alias_name}</small>
                        </ FormControl>
                    </Grid>
                    
                </Grid>

            </form>
    )
}

const formFields = {
    'id': 0,
    'key_identifier': null,
    'alias_name':null,
}
class AliasNameListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            errors: {},
            errorMessage: false,
            aliasNames: this.props.AliasNames, 
            edit: this.props.Edit, 
            deletion: this.props.Delete,
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.handleKeyIdentifierChange= this.handleKeyIdentifierChange.bind(this);
    }

    onUpdate = (id = 0) => () => {
        const StaffID = this.props.StaffID;
        let apiEndpoint = 'api/staff/work/getaliasname';
        let editable = (id) ? true : false;
        this.setState({
            id : id
        })
        return httpServices.post(apiEndpoint, {
                       
                        'staff_id':StaffID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {

                    this.setState({
                        open:true,
                        formFields: (id) ? response.data.selected_name : formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        primary: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onSubmit = ()  => {
        if (this.handleValidation()) {
            const UserID = this.props.UserID;
            const staffID = this.props.StaffID;
            const data = this.state.formFields;
            data.id = this.state.id;
            data.staff_id = staffID;
            let tab = StaffConstants.STAFF_TABS[2];
            let module = 'alias_names';
            if (data) {
                let apiEndpoint = 'api/staff/work/save-alias-name';
                return httpServices.post(apiEndpoint, { data })
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({
                                aliasNames:response.data.alias_names,
                                open:false,
                            });
                            this.clearForm();
                        }
                        else {
                            let input_errors = response.data.input_errors;
                            if (Object.keys(input_errors).length) {
                                this.setState({
                                    errors:response.data.input_errors,
                                });
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                    }
                    else {
                        this.setState({ errors: response.data.errors });
                    }
                })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const StaffID = this.props.StaffID;
        const UserID = this.props.UserID;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/work/delete-alias-name';
        return httpServices.post(apiEndpoint, {
            
            'staff_id': StaffID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[2];
                    let module = 'alias_names';
                    this.setState({
                        aliasNames:response.data.alias_names,
                        open:false,
                        
                    });
                    this.clearForm();
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    handleKeyIdentifierChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
             if (typeof value === 'object' && value) {
                if (value.inputValue) {
                    ff['key_identifier'] = value.inputValue;
                }
                else if (value.name) {
                    ff['key_identifier'] = value.name;
                }
            }
            this.setState({
                formFields: ff
            });
        } 
        
    };
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.key_identifier) {
            errors["key_identifier"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.alias_name) {
            errors["alias_name"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    render(){
        const { aliasNames, edit, deletion } = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Alias Name</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Alias Name" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Key Identifier</TableCell>
                            <TableCell>Alias Name</TableCell>
                            {_R.or(edit, deletion) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof aliasNames != 'undefined' && aliasNames.map((a) =>
                    
                        <TableRow hover>
                            <TableCell>{a.key_identifier}</TableCell>
                            <TableCell>{a.alias_name}</TableCell>
                            {_R.or(edit, deletion) &&
                            <TableCell align="right">
                                {_R.equals(edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={this.onUpdate(a.id)}/>
                                    </IconButton>
                                </Tooltip>
                                }
                                {_R.equals(deletion, true) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                        <DeleteIcon fontSize="small" onClick={this.onDelete(a.id)}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(aliasNames) &&
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium
                Open={this.state.open}
                Cancel={this.onClose}
                Title={this.state.isDelete ?  'Delete Alias Name' : (this.state.isEditable ? 'Edit Alias Name' : 'Add Alias Name')}
                OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                ErrorMessage={this.state.errorMessage}
                Content={
                    this.state.isDelete ?
                        'Do you want to delete this Alias Name?'
                    :
                    <DialogContent
                        FormInputs={this.state.formInputs}
                        FormFields={this.state.formFields}
                        OnSubmit={this.onSubmit}
                        HandleOnChange = {this.onHandleChange}
                        Errors = {this.state.errors}
                        KeyIdentifiers={this.props.KeyIdentifiers}
                        HandleKeyIdentifierChange={this.handleKeyIdentifierChange}
                    />
                }
            />
            </>
        )
    }
}
export { AliasNameListContainer as AliasNameList };
