import React from 'react';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import * as R from 'ramda';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TreeIcon from '@mui/icons-material/AccountTree';
import FormHelperText from '@mui/material/FormHelperText';
import Drawer from '@mui/material/Drawer';
import NativeSelect from '@mui/material/NativeSelect';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {MaterialTreeView} from '../../../../components/CustomMaterialUiTree/MaterialUiTree';
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import { DialogBoxMedium } from '../../../common/dialog.medium';

export const TimeEntryRowComponent  = props => {
    let key = (Object.keys(props.RowData).filter(function (propertyName) {
        return propertyName.indexOf("rowKey_") === 0;
    }));
    let rowIndex = props.RowData[key];
    let projects = R.type(props.clientProjects) !== 'Undefined' &&props.clientProjects;
    let projectInput = projects;
    let tagType = R.type(props.RowData) !== 'Undefined' && props.RowData['tag_type_'+rowIndex];
    let timeSliceID = (R.type(props.RowData) !== 'Undefined' && props.RowData['timeslice_id'] !== 'Undefined'&&props.RowData['timeslice_id']);
    //let selected_task_value = typeof props.RowData['task_name_'+rowIndex] !== 'undefined' ? props.RowData['task_name_'+rowIndex] : '';
    let default_open_state =  false ;
    const [
        task_picker_open_flag,
        setTaskPickerViewState
    ] = React.useState(default_open_state)
    /*const taskAutoCompleteInputChange = (evt,value) => {
        if (selected_task_value && value && selected_task_value.trim() === value.trim()) {
            setTaskPickerViewState(false);
        }
        else if (value) {
            setTaskPickerViewState(true);
        } else {
            setTaskPickerViewState(false);
        }
    }*/
    return (
        <React.Fragment>
            <div className={(R.type(props.RowData) !== 'Undefined' && R.type(props.TimesliceReqErrors) !== 'Undefined' &&
                            props.TimesliceReqErrors['task_duplicate_'+rowIndex]) ?'st-timeEntry_entry-item te-req-error' : 'st-timeEntry_entry-item'}>
                <div className={'input-container_left'}>
                    <div className={'te-row'}>
                        <div className={'te-item te-tree-btn'}>
                            <Tooltip title="Select Task Using Tree View">
                                <IconButton 
                                    onClick={props.ToggleTreeView('right', true)} 
                                    aria-label="delete" 
                                    className={'btn'} 
                                    size="small" 
                                    tabindex = {"-1"}
                                    disabled={(R.type(props.RowData)!=='Undefined'
                                        &&props.RowData['timeslice_id']!=='Undefined'
                                        &&props.RowData['timeslice_id']>0&&props.editedIndex!==rowIndex)
                                        ?true:false}
                                >
                                    <TreeIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Drawer anchor={'right'} open={props.TaskTreeViewState} onClose={props.ToggleTreeView('right', false)}>
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Select Task</div>
                                    <MaterialTreeView
                                        Data = {projectInput}
                                        OnChange = {props.HandleTaskTreeChange}
                                        RowIndex = {rowIndex}
                                    />
                                </div>
                            </Drawer>
                        </div>
                        <div className={'te-item te-task st-HSpacingR_1'}>
                            <Autocomplete
                                id = {"project-task-select"+rowIndex}
                                value = {typeof props.RowData['task_name_'+rowIndex] !== 'undefined' ? props.RowData['task_name_'+rowIndex] : ''}
                                options = {R.type(props.FullPathTasks) !== 'Undefined' ? props.FullPathTasks : []}
                                classes={{root: (R.type(props.RowData) !== 'Undefined' && R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['task_'+rowIndex]) !== 'Undefined') ? 'te-req-error' : ''}}
                                getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.task_path) {
                                        return option.task_path;
                                    }
                                    return option.task_path;
                                }}
                                filterOptions={(options, state) => {
                                    if (state.inputValue && state.inputValue !== "") {
                                        let input_string = state.inputValue.toLowerCase();
                                        let search_result = options.filter(el => (((el.client_name && el.task_path) && (el.client_name.toLowerCase().includes(input_string) || el.task_path.toLowerCase().includes(input_string) )) || (el.project_code.toLowerCase().includes(input_string)) || (el.project_description.toLowerCase().includes(input_string)) || el.task_description.toLowerCase().includes(input_string)));
                                        return search_result;
                                    } 
                                    return options;
                                }}
                                onChange = {props.HandleTaskOnChange(rowIndex)}
                                disabled = {(R.type(props.RowData)!=='Undefined'
                                    &&props.RowData['timeslice_id']!=='Undefined'
                                    &&props.RowData['timeslice_id']>0&&props.editedIndex!==rowIndex)
                                    ?true:false}
                                groupBy = {(option) => option.group_key}
                                //onInputChange = {taskAutoCompleteInputChange}
                                //open ={task_picker_open_flag}
                                renderInput = {(params) => (
                                    <TextField {...params} label="Select Task"  className={'input-dropdown'}/>
                                )}
                            />
                            <span className='error'>
                                {(R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['post_err_task_'+rowIndex]) !== 'Undefined') ? props.TimesliceReqErrors['post_err_task_'+rowIndex] : ''}
                            </span>
                            <div className={'te-task-helper'}>
                                <FormHelperText>{R.type(props.RowData) !== 'Undefined' && props.RowData['project_path_display']}</FormHelperText>{(R.type(props.RowData) !== 'Undefined' && props.RowData['task_description_'+rowIndex]) && <Tooltip title={props.RowData['task_description_'+rowIndex]}><HelpOutlineIcon fontSize='small' /></Tooltip>}
                            </div>
                            <span className="tEntry-info"> {R.type(props.RowData) !== 'Undefined' && parseInt(props.RowData['client_timesheet']) ?<span className="wrap"> <WarningIcon/>{'Requires client timesheet'}</span> : ''}</span>                             </div>
                        {(props.RowData['enable_location_' + rowIndex] == true) &&
                            <div className={(R.type(props.RowData) !== 'Undefined' && R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['location_' + rowIndex]) !== 'Undefined') ? 'te-item st-HSpacingL_2 te-location te-req-error' : 'te-item st-HSpacingL_2 te-location'}>
                                <div className={'te-input'}>
                                    <NativeSelect
                                        value={(R.type(props.RowData) !== 'Undefined' && props.RowData['location_' + rowIndex] !== '') ? props.RowData['location_' + rowIndex] : ""}
                                        name={'location_' + rowIndex}
                                        onChange={props.HandleOnChange}
                                        data-key={rowIndex}
                                        disabled={(R.type(props.RowData) !== 'Undefined' && props.RowData['timeslice_id'] !== 'Undefined'
                                            && props.RowData['timeslice_id'] > 0 && props.editedIndex !== rowIndex)
                                            ? true : false}
                                    >
                                        <option value="">Location</option>
                                        {typeof props.RowData['selected_project_locations_' + rowIndex] !== 'undefined' && props.RowData['selected_project_locations_' + rowIndex].map((t, i) =>
                                            <option value={t.location_id}>{t.location_name}</option>
                                        )}
                                    </NativeSelect>
                                </div>
                            </div>
                        } 
                    </div>
                    <div className={'te-row'}>
                        <div className={(R.type(props.RowData) !== 'Undefined' && R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['desc_'+rowIndex]) !== 'Undefined') ? 'te-item te-description te-req-error' : 'te-item te-description'}>
                            <TextareaAutosize
                                className={'te-description-textarea'}
                                placeholder="Description"
                                minRows={3}
                                data-key={rowIndex}
                                onChange={props.HandleDescriptionChange}
                                name={'desc_'+rowIndex}
                                data-title="desc"
                                value={R.type(props.RowData) !== 'Undefined' && props.RowData['desc_'+rowIndex]}
                                disabled={(R.type(props.RowData) !== 'Undefined'
                                    &&props.RowData['timeslice_id'] !== 'Undefined'
                                    &&props.RowData['timeslice_id']>0&&props.editedIndex!==rowIndex)
                                    ?true:false}
                            />
                            <span className='error'>
                                {(R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['post_err_desc_'+rowIndex]) !== 'Undefined') ? props.TimesliceReqErrors['post_err_desc_'+rowIndex] : ''}
                            </span>                            
                        </div>                        
                        {(R.type(props.RowData) !== 'Undefined' && props.RowData['non_confirmity'] && parseInt(props.RowData['non_confirmity'])) ?
                        <div className={'te-item te-resolve st-HSpacingL_2'}>
                            <Tooltip title="Resolve Issue" aria-label="resolve-issue">
                                <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={'nonconfirmity_'+rowIndex}
                                        name={'nonconfirmity_'+rowIndex}
                                        className={'checkbox'}
                                        onChange={props.HandleOnChange}
                                        data-key={rowIndex}
                                        color="primary"
                                        inputProps={{
                                            tabindex : "-1"
                                        }}
                                      />
                                    }
                                    label="Resolve"
                                />
                            </Tooltip>
                        </div>
                        :<></>}
                    </div>
                    {R.type(props.RowData) !== 'Undefined' && !R.isNil(props.RowData['tag_type_'+rowIndex])&&
                    <div className={'te-row'}>
                        <div className={'te-item te-tag'}>
                                {(!(R.type(props.RowData) !== 'Undefined' && props.RowData['timeslice_id'] !== 'Undefined' && props.RowData['timeslice_id'] > 0 && props.editedIndex !== rowIndex) || (R.type(props.tempAddedTagsJson[rowIndex]) !== 'Undefined' && props.tempAddedTagsJson[rowIndex].length)) ? 
                                <div className={'ta-tag-title'}>
                                    Tags
                                </div>
                                : <></>
                                }
                                <div className={'tags'}>
                                    {R.type(props.tempAddedTagsJson[rowIndex]) !== 'Undefined'&&props.tempAddedTagsJson[rowIndex].map((t, i) =>
                                    <span className={'tags-tag'}>
                                        {t.tag_name}
                                        <>
                                        {(R.type(props.RowData) !== 'Undefined'
                                        &&props.RowData['timeslice_id'] !== 'Undefined'
                                        &&props.RowData['timeslice_id']>0&&props.editedIndex!==rowIndex) ?
                                            <IconButton className={'btn-tags-delete'} aria-label="delete" size="small" tabIndex = {"-1"}></IconButton>
                                            : 
                                            <IconButton className={'btn-tags-delete'} aria-label="delete" size="small" tabIndex = {"-1"}>
                                                <DeleteIcon fontSize="inherit" data-tag={t.id} data-rindex={rowIndex} tabIndex = {"-1"} onClick={props.OnTagTempDelete}/>
                                            </IconButton>
                                        }
                                        </>
                                    </span>
                                    )}
                                </div>
                                {!(R.type(props.RowData) !== 'Undefined' && props.RowData['timeslice_id'] !== 'Undefined' && props.RowData['timeslice_id'] > 0 && props.editedIndex !== rowIndex) &&
                                <Tooltip title="Add Tag" placement="bottom">
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="primary"
                                        aria-label="add"
                                        className={'btn-tags-add'}
                                        onClick={() => props.onAddTagIconClicked(tagType, timeSliceID, rowIndex)}
                                        >
                                            <AddIcon />
                                    </Fab>
                                </Tooltip>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className={'input-container_right'}>
                    <div className={'te-row'}>
                        <div className={(R.type(props.RowData) !== 'Undefined' && R.type(props.TimesliceReqErrors) !== 'Undefined' &&
                            R.type(props.TimesliceReqErrors['hour_'+rowIndex]) !== 'Undefined')? 'te-time te-req-error' : 'te-time'}>
                            <Tooltip title="Format: nn.nn or HH:MM" placement="top-start" arrow>
                                <TextField
                                  id={'hour_'+rowIndex}
                                  name={'hour_'+rowIndex}
                                  placeholder="Hours"
                                  type="text"
                                  className={'te-input'}
                                  onChange={props.HandleOnChange}
                                  value={R.type(props.RowData) !== 'Undefined' &&props.RowData['hour_'+rowIndex]}
                                  InputProps={{
                                      readOnly: (R.type(props.RowData)!=='Undefined'
                                      &&props.RowData['timeslice_id']!=='Undefined'
                                      &&props.RowData['timeslice_id']>0&&props.editedIndex!==rowIndex)?true:false,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  error={props.Errors['hour_'+rowIndex] && true}
                                  helperText={props.Errors['hour_'+rowIndex]}
                                />
                            </Tooltip>
                            <span className='error'>
                                {(R.type(props.TimesliceReqErrors) !== 'Undefined' && R.type(props.TimesliceReqErrors['post_err_hour_'+rowIndex]) !== 'Undefined') ? props.TimesliceReqErrors['post_err_hour_'+rowIndex] : ''}
                            </span>
                        </div>                        
                        <div  className={'te-action'}>
                            {props.RowData['show_edit_button'] && (props.RowData['sync_timeslice_id'] == null) && (props.RowData['locked'] == false) &&
                            <IconButton aria-label="delete" size="small" tabindex = {"-1"}>
                                <DeleteIcon fontSize="inherit" tabindex = {"-1"} data-timeslice_id ={props.RowData['timeslice_id']} data-key={rowIndex} onClick={props.OnDelete} />
                            </IconButton>
                            }
                            {(R.type(props.RowData) !== 'Undefined'&&props.RowData['timeslice_id'] !== 'Undefined' && props.RowData['timeslice_id']>0 && props.RowData['show_edit_button']) && (props.RowData['sync_timeslice_id'] == null) && (props.RowData['locked'] == false) &&
                            <IconButton aria-label="delete" size="small" tabindex = {"-1"}>
                                <EditIcon fontSize="inherit" tabindex = {"-1"} onClick={() => props.OnEdit(props.RowData['timeslice_id'], rowIndex,props.RowData['project_id'] )}/>
                            </IconButton>
                            }
                            {(props.RowData['locked'] ==  true) && 
                            <div className='timesheet-list_row-locked'>
                                <IconButton size="small" className={'action-btn'} edge="end">
                                    <LockIcon aria-haspopup="true" onClick={() => props.GetLockedDetails(props.RowData.lock_created_by, props.RowData.locked_on)}>
                                    </LockIcon>
                                </IconButton>
                            </div>
                            }
                        </div>
                        <div className={'te-timeinfo'}>
                            {(!R.isNil(props.RowData['project_'+rowIndex]))
                            ?
                                (R.type(props.RowData) === 'Undefined' || R.isNil(props.RowData['minimum_inc_' + rowIndex]))
                                ?
                                    <FormHelperText className={'te-helper'}>Min. Increment {props.minimumInc}</FormHelperText>
                                    : ((parseFloat(props.RowData['minimum_inc_' + rowIndex]) !== 0)
                                    ? 
                                    <FormHelperText className={'te-helper'}>Min. Increment {props.RowData['minimum_inc_'+rowIndex]}</FormHelperText>
                                    :<></>)
                            :<></>
                            }
                            <span className="tEntry-info"> {R.type(props.RowData) !== 'Undefined' && parseInt(props.RowData['client_timesheet']) ?<span className="wrap"><WarningIcon/>{'Hours should match with the hours in client timsheet system (Eg: CTMS)'}</span> : ''}</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

