import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { EmailActionComponent } from './EmailAction.Component';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class RequestEmailActionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            snackbarMsg: '',
            snackbarType: 'success',
            action: '',
            requestDetails: {},
            showApproveBtn: false,
            showRejectBtn: false,
            token: null,
            invalidTokenFlag: false,
            errorMsg: '',
            relatedRequest: false,
            showRelatedRequest: false,
            timeoffData: {},
            comments:'',
            validationErrors: {}
        }
        this.snackbarClose = this.snackbarClose.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.verifyCallbackReject = this.verifyCallbackReject.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.handleRelatedRequestChange = this.handleRelatedRequestChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    componentDidMount = () => {
        let token = this.props.match.params.id;
        let action = this.props.match.params.action;
        if (token && action) {
            this.validateToken(token, action);
        }
    };
    validateToken = (token, action) => {
        let apiEndpoint = 'api/request/public/' + action;
        let data = {
            'token': token,
            'action': 'get',
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data.valid) {
                    let requestDetails = response.data.request;
                    let showRelatedRequest = false;
                    if (response.data.related_req) {
                        showRelatedRequest = true;
                    }
                    this.setState({
                        action: action,
                        requestDetails: requestDetails,
                        token: token,
                        showRelatedRequest: showRelatedRequest,
                        timeoffData: response.data.timeoffData,
                    });
                } else {
                    let errorMsg = (response.data.error && response.data.error !== '') ? response.data.error : "Invalid Token";
                    this.setState({
                        invalidTokenFlag: true,
                        errorMsg: errorMsg
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    verifyCallback = (recaptchaToken) => {
        console.log(recaptchaToken, "<= your recaptcha token");
        if (recaptchaToken) {
            this.setState({
                showApproveBtn: true,
            })
        }
    }
    verifyCallbackReject = (recaptchaToken) => {
        console.log(recaptchaToken, "<= your recaptcha token");
        if (recaptchaToken) {
            this.setState({
                showRejectBtn: true,
            })
        }
    }
    approve = () => {
        let action = this.state.action;
        let token = this.state.token;
        let comments = this.state.comments;
        let apiEndpoint = 'api/request/public/' + action;
        let data = {
            'token': token,
            'action': 'approve',
            'comments' : comments,
        };
        if (action === 'timeoffapprove') {
            data['related_req'] = this.state.relatedRequest;
        }
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data.valid && !response.data.errors) {
                    this.setState({
                        showApproveBtn: false,
                        snackbarOpen: true,
                        snackbarMsg: "Request approved successfully",
                        snackbarType: "success",
                    });
                    setTimeout(function () {
                        window.location = BASE_PATH + '/login';
                    }, 15000);
                } else if (response.data.errors) {
                    this.setState({
                        validationErrors: response.data.errors,
                    });
                } else {
                    let errorMsg = (response.data.error && response.data.error !== '') ? response.data.error : "Invalid Token";
                    this.setState({
                        invalidTokenFlag: true,
                        errorMsg: errorMsg
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    validateReject() {
        let errors = {};
        let valid = true;        
        if (this.state.comments.trim() === '' ) {
            valid = false;
            errors['comments'] = 'Cannot be empty';
        }
        this.setState({ validationErrors: errors });
        return valid;
    }
    reject = () => {
        let action = this.state.action;
        let token = this.state.token;
        let comments = this.state.comments;
        let apiEndpoint = 'api/request/public/' + action;
        if (this.validateReject()) {
            let data = {
                'token': token,
                'action': 'reject',
                'comments': comments,
            };
            if (action === 'timeoffreject') {
                data['related_req'] = this.state.relatedRequest;
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data.valid && !response.data.errors) {
                        this.setState({
                            showRejectBtn: false,
                            snackbarOpen: true,
                            snackbarMsg: "Request rejected successfully",
                            snackbarType: "success",
                        });
                        setTimeout(function () {
                            window.location = BASE_PATH + '/login';
                        }, 15000);
                    } else if (response.data.errors) {
                        this.setState({
                            validationErrors: response.data.errors,
                        });
                    } else {
                        let errorMsg = (response.data.error && response.data.error !== '') ? response.data.error : "Invalid Token";
                        this.setState({
                            invalidTokenFlag: true,
                            errorMsg: errorMsg
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }
    handleRelatedRequestChange = (event) => {
        let value = event.target.checked;
        this.setState({
            relatedRequest: value,
        });
    };
    onClose = () => {
        window.location = BASE_PATH + '/login';
    }
    handleOnChange = (evt, value) => {
        let comment = evt.target.value;
        this.setState({
            comments: comment,
        });
    }
    render() {
        return (
            <EmailActionComponent
                Action={this.state.action}
                RequestDetails={this.state.requestDetails}
                VerifyCallback={this.verifyCallback}
                ShowApproveBtn={this.state.showApproveBtn}
                Approve={this.approve}
                VerifyCallbackReject={this.verifyCallbackReject}
                ShowRejectBtn={this.state.showRejectBtn}
                Reject={this.reject}
                SnackbarOpen={this.state.snackbarOpen}
                SnackbarClose={this.snackbarClose}
                SnackbarMsg={this.state.snackbarMsg}
                SnackbarType={this.state.snackbarType}
                InvalidTokenFlag={this.state.invalidTokenFlag}
                ErrorMsg={this.state.errorMsg}
                ShowRelatedRequest={this.state.showRelatedRequest}
                RelatedRequest={this.state.relatedRequest}
                HandleRelatedRequestChange={this.handleRelatedRequestChange}
                Close={this.onClose}
                TimeoffData={this.state.timeoffData}
                HandleOnChange={this.handleOnChange}
                ValidationErrors={this.state.validationErrors}

            />
        )
    }
}
