import React from 'react';
import {ImportComponent} from './Import.Component'
import {httpServices}  from '../../services/httpServices'
import { ErrorPage } from '../common/error';

export class ImportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            file: null,
            hasPermission: true,
            errors: {},
            snackbarOpen: false,
            message: null,
            error_message: null
        }
        this.HandleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        let sfile = evt.target.files[0];
        this.setState ({file: sfile});
    };
    onSubmit = () => {
        if (this.handleValidation()) {
            this.importPayroll();
        }
        document.getElementById('fileToUpload').value = null;

    };
    componentDidMount = () => {
        this.importPayroll();
    };
    importPayroll = () => {
        let file= this.state.file;
        let postData = {
            file: file
        };
        return httpServices.post('api/payroll/import-payroll', postData)
            .then(response => {
                if (response.data) {
                    let valid = response.data.valid;
                    if (valid) {
                        this.setState({
                            hasPermission:response.data.permission,
                            message:response.data.message,
                            snackbarOpen: true,
                        });

                    }
                    else {
                        let error_message = response.data.message;
                        this.setState({
                            hasPermission:response.data.permission,
                            error_message: error_message,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleValidation() {
        let valid = true;
        let errors = {};
        let file = this.state.file;

        if (!file) {
            errors["file"] = "Please upload a file";
            valid = false;
        }
        else {
            let file_name = file.name.trim();
            file_name = file_name.replace(/[()]/g,'');
            let allowed_files = [".xls"];
            var regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowed_files.join('|') + ")$");
            if (!regex.test(file_name.toLowerCase())) {
                errors["file"] =  "Please upload files having extensions: " + allowed_files.join(', ') + " only."
                valid = false;
            }
        }
        this.setState({errors: errors, error_message: null});
        return valid;
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    render(){
        return(
            (this.state.hasPermission) ?
            <ImportComponent
                HandleOnChange={this.handleOnChange}
                OnSubmit={this.onSubmit.bind(this)}
                SnackbarClose = {this.snackbarClose.bind(this)}
                {...this.state}
            />
            :
            <ErrorPage/>
        )
    }
}
