import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { httpServices } from '../../services/httpServices';
import { CustomProgressBar } from './CustomProgressBar';
import Button from '@mui/material/Button';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class UnderMaintenanceContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
            supportEmail: ''
        }
    }
    componentDidMount = () => {
        this.checkMaintenanceComplete();
    };

    checkMaintenanceComplete = () => {
        this.setState({showLoader: true});
        httpServices.get('api/application/check-maintenance')
            .then(response => {
                if (response.data.status) {
                    window.location = BASE_PATH;
                } else {
                    this.setState({
                        showLoader: false,
                        supportEmail: response.data.support_email
                    });
                }
            })
            .catch(function (error) {
            });
    }

    render() {
        return (
            <>
            <React.Fragment>
                <AppBar color="default" className={'appBarMain'}>
                    <div className={'steps-bar'}>
                        <div className={'steps-logo'}>
                            <div className={'helper'}>
                                <img src={BASE_PATH + "/steps-logo.svg"} alt="" />
                            </div>
                        </div>
                    </div>
                    <Toolbar className={'appBarMain__toolbar'}>
                        <Typography variant="h6" color="inherit" className={'branding'}>
                            <div className={'appBarLogo'}>
                                <div className={'helper'}>
                                    <img src={BASE_PATH + "/company_logo.png"}  alt="" />
                                </div>
                            </div>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
                <div className={'temp-email-action pl-1 d-flex direction-column'}>
                {this.state.showLoader ?
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                :
                <>
                    <h1>System Maintenance.</h1>
                    <p >Apologies for the inconvenience. Please visit after some time.</p>
                    <p><strong>{'Contact '+this.state.supportEmail+' if you have any questions'}</strong></p>
                    <Button color="primary" size="small" variant="outlined" onClick={this.checkMaintenanceComplete}>Reload</Button>
                </>
                }
            </div>
            </>
        )
    }
}
