import React from "react";
import { EstimationComponent } from "./Estimation.Component";
import { httpServices } from "../../../services/httpServices";
import { ErrorPage } from "../../common/error";
import { CustomProgressBar } from "../../common/CustomProgressBar";

const ReportFields = {
    user_status: "",
};

export class EstimationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: true,
            loaderOpen: false,
            ReportFields: ReportFields,
            resources: [],
            tasks: [],
            popupType: "",
            open: false,
            estimated_task_users: [],
            est_tasks: [],
            snackbarOpen: false,
            success_message: "",
            nameFormat : 'FIRSTLAST',
            errors : {},
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.HandleHoursChange = this.HandleHoursChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount() {
        const ff = this.state.ReportFields;
        ff["user_status"] = 1;
        ff["task_status"] = 1;
        ff["task_type"] = "All";
        this.setState({
            ReportFields: ff,
        });
        this.formSubmit();
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
        ff[field] = evt.target.value;
        this.setState({
            ReportFields: ff,
        });
    };
    HandleHoursChange = (evt, value) => {
        const field = evt.target.name;
        let items = [];
        let ff = this.state.estimated_task_users;
        ff.forEach(function (item) {
            if (item.user_id == field) {
                item.estimated_hours = evt.target.value;
            }
            items.push(item);
        });
        this.setState({
            estimated_task_users: items,
        });
    };
    editClick = (id) => {
        this.setState({
            popupType: "edit",
            open: true,
        });
        this.getEdit(id);
    };
    onClose = () => {
        this.setState({
            open: false,
        });
    };
    handleSubmit = () => {
        //if(this.handleValidation()) {
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/saveestimatedhours", {
                est_tasks: this.state.est_tasks,
                estimated_task_users: JSON.stringify(
                    this.state.estimated_task_users
                ),
            })
            .then((response) => {
                if (response.data.valid) {
                    this.setState({
                        success_message: response.data.success,
                        open: false,
                        snackbarOpen: true,
                    });
                    this.formSubmit();
                }
                else {
                    this.setState({
                        errors : response.data.errors,
                        loaderOpen: false
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        // }
    };
    getEdit = (id) => {
        let filterFields = this.state.ReportFields;
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/taskestimatedhours", {
                task_id: id,
                selected_status: filterFields.user_status,
                projectid: this.props.ProjectId,
                task_status: filterFields.task_status,
                task_type: filterFields.task_type,
            })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        estimated_task_users:
                            response.data.estimated_task_users,
                        est_tasks: response.data.est_tasks,
                        loaderOpen: false,
                        errors : {}
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    formSubmit = () => {
        let filterFields = this.state.ReportFields;
        //if(this.handleValidation()) {
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/taskestimatedhours", {
                selected_status: filterFields.user_status,
                projectid: this.props.ProjectId,
                task_status: filterFields.task_status,
                task_type: filterFields.task_type,
            })
            .then((response) => {
                if (response.data) {
                    const ff = this.state.ReportFields;
                    ff["user_statuses"] = response.data.status;
                    ff["task_statuses"] = response.data.task_status;
                    ff["task_types"] = response.data.task_type;
                    this.setState({
                        ReportFields: ff,
                        resources: response.data.users,
                        permission: response.data.permission,
                        tasks: response.data.tasks,
                        estimated_task_users:
                            response.data.estimated_task_users,
                        est_tasks: response.data.est_tasks,
                        loaderOpen: false,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        // }
    };
    render() {
        return (
             <>
            <CustomProgressBar
                loaderOpen={this.state.loaderOpen}
            />
            {(this.state.permission) ?
            <EstimationComponent
                HandleOnChange={this.HandleOnChange}
                EditClick={this.editClick}
                OnClose={this.onClose}
                ReportFields={this.state.ReportFields}
                FormSubmit={this.formSubmit}
                Resources={this.state.resources}
                Tasks={this.state.tasks}
                PopupType={this.state.popupType}
                Open={this.state.open}
                EstimatedTaskUsers={this.state.estimated_task_users}
                EstimatedTasks={this.state.est_tasks}
                HandleHoursChange={this.HandleHoursChange}
                HandleSubmit={this.handleSubmit}
                SnackbarOpen={this.state.snackbarOpen}
                SnackbarClose={this.snackbarClose}
                Message={this.state.success_message}
                LoaderOpen={this.state.loaderOpen}
                NameFormat = {this.state.nameFormat}
                Errors = {this.state.errors}
            />
           : <ErrorPage/>
                 }
           </>
        );

    }
}

