import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CustomizedSnackbars } from '../common/snackbars';

export const ImportComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    return (
        <React.Fragment>
            <Paper>
                <Grid container spacing={3} style={gridStyle}>
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                            <Typography variant="h6" component="h6" className = {'section-title'}> Import Payroll </Typography>
                            <p className='error'>{props.error_message && props.error_message}</p>
                            <input type="file" name="fileToUpload" id="fileToUpload" onChange={props.HandleOnChange}/>
                            <Button variant="contained" size="small" color="primary" onClick={props.OnSubmit}>Save</Button>
                            <div>
                                <small className='error'>{props.errors.file && props.errors.file}</small>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Paper>

            <CustomizedSnackbars
                open={props.snackbarOpen}
                variant='success'
                message={props.message}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    )
};
