import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: 20,
  color: '#fff',
}));

export const CustomProgressBar = (props) => {
  return (
    <CustomBackdrop open={props.loaderOpen}>
      <CircularProgress disableShrink />
    </CustomBackdrop>
  );
};
