import React from 'react';
import { CustomMaterialTable } from '../../../../common/CustomMaterialTable';

export const RolesComponent = (props) => {
    return (
        <>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Roles"
                    columns={[
                        { title: 'Role', field: 'name' },
                        { title: 'Description', field: 'description' },
                    ]}
                    data={props.Roles}
                    options={{
                        search: false,
                        paging: false
                    }}

                />
            </div>
        </>
    )
};