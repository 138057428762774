import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { TimeOffTypesComponent }  from './TimeOffTypes.Component.js';

let formFields = {
    to_type : null,
    description : null,
    category : null,
    custom_workflow: null,
    calendar_days : null,
    paid : null,
    requests_per_year : null,
    accrual : null,
    eligibility_criteria_staff_override : null,
    reset_calendar_year : null,
    adjustments : null,
    adjustment_period  : null,
    minimum_eligibility : null,
    approver : null,
    editable : null,
    enable_grant : null,
    weight : null,
    status : 1,
    id : 0,
    allow_partial_hours : 1,
    prorate_hours : 1,
    is_eligibility_criteria : 0,
    eligibility_criteria_unit : null,
    adjustment : false,
    carry_forward : false,
}
export class TimeOffTypesContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            timeoff_types : [],
            editPermission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant: '',
            buttonLoader : false,
            timesheetStatus : []

        }
        this.addTimeOffTypesClick = this.addTimeOffTypesClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => {
        this.getAllRequests();
    };

    getAllRequests = () => {
        let apiEndpoint = 'api/settings/timeoff/getalltimeofftypes';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        timeoff_types : response.data.timeoff_types,
                        editPermission : response.data.edit_permission,
                        timesheetStatus : response.data.timesheetStatus

                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        if (field === 'is_eligibility_criteria' && evt.target.value == 1) {
            ff['eligibility_criteria_unit'] = 'year';
        }
        if(field === 'adjustment' || field === 'carry_forward') {
            ff[field] = evt.target.checked;
        }
        this.setState({
            formFields : ff,
        });
    };


    addTimeOffTypesClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }
    handleSubmit = (event) => {
        this.setState({buttonLoader : true });
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/timeoff/edit-timeoff-type', { data })
                    .then(response => {
                        if (response.data.permission) {
                            this.getAllRequests();
                            let successMsg = ''
                            if(data.id){
                                successMsg = "Time off type updated successfully";
                            } else {
                                successMsg = "Time off type added successfully";
                            }
                            if (response.data.valid) {
                                this.setState({
                                    snackbarOpen : true,
                                    snackbarMsg : successMsg,
                                    snackbarVariant: 'success',
                                    open : false,
                                    popupType : '',
                                    formFields : {},
                                    errors : {},
                                    buttonLoader : false,
                                });
                            }
                            else
                            {
                                this.setState({
                                    errors : response.data.errors,
                                    buttonLoader : false,
                                });   
                            }
                        }
                        else {
                            this.setState({
                                buttonLoader : false
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
        else {
            this.setState({ buttonLoader : false });
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let adjustments = this.state.formFields.adjustments;
        let adjustmentFor = this.state.formFields.adjustment_period;
        if (adjustments === 1 && !adjustmentFor) {
            errors["adjustment_period"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.to_type) {
            errors["to_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.requests_per_year && isNaN(this.state.formFields.requests_per_year)) {
            errors['requests_per_year'] = "Invalid data";
            formIsValid = false;
        }
        if (this.state.formFields.weight && isNaN(this.state.formFields.weight)) {
            errors['weight'] = "Invalid data";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/timeoff/get-timeoff-type';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.timeoff_details) {
                       let formFields = {
                           id : response.data.timeoff_details.id,
                           to_type : response.data.timeoff_details.to_type,
                           description : response.data.timeoff_details.description,
                           accrual : response.data.timeoff_details.accrual,
                           eligibility_criteria_staff_override : response.data.timeoff_details.eligibility_criteria_staff_override,
                           reset_calendar_year : response.data.timeoff_details.reset_calendar_year,
                           custom_workflow : response.data.timeoff_details.custom_workflow,
                           calendar_days : response.data.timeoff_details.calendar_days,
                           paid : response.data.timeoff_details.paid,
                           requests_per_year : response.data.timeoff_details.requests_per_year,
                           adjustments : response.data.timeoff_details.adjustments,
                           adjustment_period : response.data.timeoff_details.adjustment_period,
                           approver : response.data.timeoff_details.approver,
                           editable : response.data.timeoff_details.editable,
                           enable_grant : response.data.timeoff_details.enable_grant,
                           weight : response.data.timeoff_details.weight,
                           status : response.data.timeoff_details.status,
                           allow_partial_hours : response.data.timeoff_details.allow_partial_hours,
                           prorate_hours : response.data.timeoff_details.prorate_hours,
                           is_eligibility_criteria : response.data.timeoff_details.is_eligibility_criteria,
                           eligibility_criteria_unit : response.data.timeoff_details.eligibility_criteria_unit,
                           show_variable_name : response.data.timeoff_details.show_variable_name,                           
                           accrual_timesheet_status : response.data.timeoff_details.accrual_timesheet_status,
                       	   event_based : response.data.timeoff_details.event_based, 
		       }
                       if (response.data.timeoff_details.adjustment_type.includes("Adjustment")) {
                            formFields.adjustment = true;                        
                       }
                       if (response.data.timeoff_details.adjustment_type.includes("Carry Forward")) {
                            formFields.carry_forward = true;
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <TimeOffTypesComponent
                TimeOffTypes = {this.state.timeoff_types}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddTimeOffTypesClick = {this.addTimeOffTypesClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                OnSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                SnackbarVariant = {this.state.snackbarVariant}
                ButtonLoader = {this.state.buttonLoader}
                TimesheetStatus = {this.state.timesheetStatus}
            />
        )
    }
}
