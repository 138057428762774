import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { UserViewComponent } from './UserView.Component';
import { history } from '../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class UserViewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            project : [],
            showLoader : true,
            userId :0,
            otherPermissions : [],
            snackbarOpen : false,
            snackbarMsg : '',
            editPermission : false,
            selfEditPermission : false,
            dialogOpen : false,
            user : [],
            tabPermissions : [],
            changeLogData : []
        };
        this.getUserDetails = this.getUserDetails.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.updateParentOnSuccess = this.updateParentOnSuccess.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }

    componentDidMount(){
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({ userId : id });this.getUserDetails(id);
    }

    getUserDetails = (userId) => {
        let apiEndpoint = 'api/user/get-user-details';
        return httpServices.post(apiEndpoint,{user_id : userId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            user : response.data.user,
                            showLoader : false,
                            permission : response.data.permission,
                            tabPermissions : response.data.tab_permission,
                            changeLogData : response.data.changelog_data,
                            editPermission : response.data.edit_permission
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onUpdate = () => {
        history.push(BASE_PATH+'/system/user/edit/' + this.state.userId );
    };
    handleBackClick = () => {
        if (history.length > 2) {
            window.history.back();
        }
        else 
        {
            history.push(BASE_PATH+'/users/list');
        }
    }
    onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    };
    updateParentOnSuccess = (status) => {
         this.setState({
                dialogOpen : false});
        if (status) {
            this.setState({
                snackbarOpen : true,
                snackbarMsg : 'User updated successfully',
            }, function() {
                this.getUserDetails(this.state.userId);
            });
        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }



    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(! this.state.showLoader) ?
                    <>
                    {(this.state.permission) ?
                        <UserViewComponent
                            User = {this.state.user}
                            UserId = {this.state.userId}
                            TabPermissions = {this.state.tabPermissions}
                            ChangeLogData = {this.state.changeLogData}
                            DialogOpen = {this.state.dialogOpen}
                            OnUpdate = {this.onUpdate}
                            HandleBackClick = {this.handleBackClick}
                            OnClose = {this.onClose}
                            UpdateParentOnSuccess = {this.updateParentOnSuccess}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            EditPermission = { this.state.editPermission}
                        />
                        : <ErrorPage />
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
