import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { RequestList } from './RequestList.Component';
import { AddLocation } from './LocationAdd.Container';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LocationFilterComponent } from './LocationFilterComponent';
import { CustomizedSnackbars } from '../../common/snackbars';
import format from 'date-fns/format';
import {GetConfig} from '../../../redux/middleware/configMiddleware';
import { GetSelectedTab } from '../../../redux/middleware/tabMiddleware';

export const LocationComponent = (props) => {
    //Tabs
    const selectedLocationTab =  GetSelectedTab("location");
    let myTabIndex = (props.RequestView) ? 0 : null;
    let reviewTabIndex = (props.RequestView) ? 1 : null;
    let allTabIndex = null;
    if (props.RequestView && props.AllRequestView) {
        allTabIndex = 2;
    } else if(props.AllRequestView) {
        allTabIndex = 0;
    }
    let tabIndex = (selectedLocationTab === 'my' && props.RequestView) ? myTabIndex : (selectedLocationTab === 'review' && props.RequestView) ? reviewTabIndex : allTabIndex;
    const nameFormat =  GetConfig('name_format');
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    return (
        <React.Fragment>
            <div className={'section-main'}>
              <div className={'section-header'}>
                <div className={'col-left'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        Work Location Change Requests
                    </Typography>
                </div>
                <div className={'st-timeEntry_payperiod-title center'}>
                    <span className={'label'}> Period</span>
                    {format(props.PeriodStartDate, 'MM/dd/yy')} - {format(props.PeriodEndDate, 'MM/dd/yy')}
                </div>
                <div className={'action'}>
                  {props.LocationEditPermission && (props.ExternalUser === false) &&
                       <Button variant="contained" size="small" color="primary" onClick={props.CreateLocationClick}>New Request</Button>
                  }
                       <Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                    >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)} >
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Filter</div>
                                    <LocationFilterComponent props={props} saveFilter = {props.SaveFilter} NameFormat = {nameFormat} handleOnSubmit={props.FilterSubmit} HandleOnChange={props.HandleOnChange} handleStartDateChange={props.handleStartDateChange} handleEndDateChange={props.handleEndDateChange}  HandleTreeChange={props.HandleTreeChange} ResetFilter = {props.ResetFilter}/>
                                </div>
                            </Drawer>

                    </div>
                   </div>
                <div className={'section-body'}>
                    <Paper>

                    <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                        <Tabs
                            value={tabIndex}
                            onChange={props.HandleChange}
                            indicatorColor="primary"
                            aria-label="Location lists Tabs"
                        >
                            {props.RequestView ? <Tab label="My Requests" onClick = {() => props.onTabChange("my")} /> : <></>}
                            {props.RequestView ? <Tab label = {parseInt(props.ReviewCount) !== 0 ? <Badge color="secondary" badgeContent={props.ReviewCount} >Review </Badge> : "Review"} onClick = {() =>props.onTabChange("review")}/> : <></>}
                            {props.AllRequestView ? <Tab label="All Requests" onClick = {() => props.onTabChange("all")} /> : <></>}
                        </Tabs>
                    </div>
                    <div className={'tab-panelContainer'}>
                        <TabPanel value={tabIndex} index={myTabIndex}>
                            {props.RequestView ? <RequestList
                                NameFormat = {nameFormat}
                                Requests = {props.Requests}
                                HandleEditClick = {props.HandleEditClick}
                                HandleViewClick = {props.HandleViewClick}
                                LocationEditPermission  = {props.LocationEditPermission}
                                Tab = {'my'}
                                Logged_user = {props.Logged_user}
                            /> : <></>}
                        </TabPanel>
                        <TabPanel value={tabIndex} index={reviewTabIndex}>
                            {props.RequestView ? <RequestList
                                NameFormat = {nameFormat}
                                Requests = {props.ReviewRequests}
                                HandleViewClick = {props.HandleViewClick}
                                Tab = {'review'}
                                Logged_user = {props.Logged_user}
                            /> : <></>}
                        </TabPanel>
                        <TabPanel value={tabIndex} index={allTabIndex}>
                            {props.AllRequestView ? <RequestList
                                NameFormat = {nameFormat}
                                Requests = {props.AllRequests}
                                HandleViewClick = {props.HandleViewClick}
                                Tab = {'all'}
                                Logged_user = {props.Logged_user}
                            /> : <></>}

                        </TabPanel>
                    </div>
                </Paper>
            </div>
        </div>
        {props.Open ?
        <AddLocation
                Open = {props.Open}
                EditId = {props.EditId}
                getLocationDetails = {props.getLocationDetails}
                UpdateParent = { props.UpdateParent}
            />
            : <></>
        }
        {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }


        </React.Fragment>    
    )
};
