import React from "react";
import MaterialTable, { MTableToolbar, MTableCell } from "@material-table/core";

export const CustomMaterialTable = (props) => {
    let options = typeof props.options !== 'undefined' ? props.options : {};
    let pageSize = typeof props.options.pageSize !== 'undefined' ? props.options.pageSize : 50;
    let pageSizeOptions = typeof props.options.pageSizeOptions !== 'undefined' ? props.options.pageSizeOptions : [50, 100, 150];
    let dataCount = typeof props.data !== 'undefined' ?  props.data.length : 0;   
    if (dataCount < pageSize) {
        pageSize = dataCount;
        pageSizeOptions = [];
    }
    options.pageSize = pageSize;
    options.pageSizeOptions = pageSizeOptions;
    options.emptyRowsWhenPaging = false;
    options.thirdSortClick = false;
    if (typeof props.freezeHeader !== 'undefined' && props.freezeHeader) {
        options.headerStyle = { position: 'sticky', top: 0 };
        options.maxBodyHeight = '550px';
    }
    return (
        <div className={'sts-table-wrapper'}>
            <div className={'sts-table-title-sm'}>
                {props.title ? props.title : ''}
            </div>
            <MaterialTable
                {...props}
                key = {dataCount}
                options = {options}
                title = {<span className={'sts-table-title'}>{props.title ? props.title : ''}</span> }
            />
        </div>
    );
}   
export const CustomMTableToolbar = (props) => {
    return (        
            <MTableToolbar
                {...props}
            />
    );
}
export const CustomMTableCell = (props) => {
    return (        
            <MTableCell
                {...props}
            />
    );
}
