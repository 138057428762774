import React from 'react';
import { connect } from "react-redux";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';
import { CustomizedSnackbars } from '../../../common/snackbars';
import MixpanelManager from  '../../../../services/Analytics';

const DialogContent = (props) => {
    const {FormFields, FormInputs, HandleOnChange} = props;
    //const [value, setValue] = React.useState(null);
    const filter = createFilterOptions();
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <Autocomplete
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.university}
                                onChange={props.HandleUniversityChange}
                                id="free-solo-with-text-demo"
                                options={typeof FormInputs.universities !== 'undefined' ? FormInputs.universities : []}
                                getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (typeof option.inputValue !== 'undefined') {
                                        return option.inputValue;
                                    }
                                    if (typeof option.university !== 'undefined') {
                                        return option.university;
                                    }
                                    else {
                                        return '';
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            university: `Add "${params.inputValue}"`,
                                            id : 0,
                                        });
                                    }
                                    return filtered;
                                }}
                                error={props.Errors.university && true}
                                renderInput={params => (
                                    <TextField {...params} label="University" variant="outlined"
                                    error={props.Errors.university && true}
                                    helperText = {props.Errors.university}
                                    />
                                )}
                            />   
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl> 
                            <Autocomplete
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.course}
                                onChange={props.HandleCourseChange}
                                id="free-solo-with-text-demo"
                                options={typeof FormInputs.universities !== 'undefined' ? FormInputs.universities : []}
                                getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (typeof option.inputValue !== 'undefined') {
                                        return option.inputValue;
                                    }
                                    if (typeof option.course !== 'undefined') {
                                        return option.course;
                                    }
                                    else {
                                        return '';
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            course: `Add "${params.inputValue}"`,
                                            id : 0,
                                        });
                                    }
                                    return filtered;
                                }}
                                error={props.Errors.course && true}
                                renderInput={params => (
                                    <TextField {...params} label="Course" variant="outlined"
                                    error={props.Errors.course && true}
                                    required
                                    helperText = {props.Errors.course}
                                    />
                                )}
                            />   
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="year"
                                name="year"
                                label="Year"
                                type="text"
                                fullWidth
                                onChange={HandleOnChange}
                                value={FormFields.year}
                                error={props.Errors.year && true}
                                helperText = {props.Errors.year}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="notes"
                                name="notes"
                                label="Notes"
                                type="text"
                                multiline
                                rows="4"
                                fullWidth
                                onChange={HandleOnChange}
                                value={FormFields.notes}
                                error={props.Errors.notes && true}
                                helperText = {props.Errors.notes}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

const formFields = {
    'id': 0,
    'university': '',
    'course': '',
    'notes': '',
    'year': '',
}
class EducationContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            primary: false,
            errors: {},
            snackbarOpen : false,
            variant : 'success',
            education: []
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleUniversityChange = this.handleUniversityChange.bind(this);
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onUpdate = (id = 0) => () => {
        const {UserID, StaffID} = this.props;
        let apiEndpoint = 'api/staff/personal/get-education';
        let editable = (id) ? true : false;
        return httpServices.post(apiEndpoint, {
                        'user_id':UserID,
                        'staff_id':StaffID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        id: id,
                        open:true,
                        formFields: (id) ? response.data.selected_education: formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        primary: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = ()  => { 
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.user_id = userID;
            data.staff_id = staffID;
            let tab = StaffConstants.STAFF_TABS[1];
            let module = 'education';
            if (data) {
                let apiEndpoint = 'api/staff/personal/save-education';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    open:false,
                                    snackbarOpen : true,
                                    varient: 'success',
                                    message : response.data.message,
                                    education : response.data.education,
                                }, () => { this.props.updateChange() });
                                this.clearForm();
                                this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'Education/certification', 'action' : 'save/update-on-education/certification-field'});
                            }
                            else {
                                let input_errors = response.data.input_errors;
                                if (Object.keys(input_errors).length) {
                                    this.setState({
                                        errors:response.data.input_errors,
                                    });
                                }
                                else {
                                    alert(response.data.msg);
                                }
                            }
                        }
                        else {
                            this.setState({
                                errors:response.data.errors,
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    };
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/personal/delete-education';
        return httpServices.post(apiEndpoint, {
            'staff_id': StaffID,
            'user_id': UserID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[1];
                    let module = 'education';
                    this.setState({
                        open:false,
                        snackbarOpen : true,
                        varient: 'success',
                        message : response.data.message,
                        education : response.data.education,
                    }, () => { this.props.updateChange() });
                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'Education/certification', 'action' : 'delete-on-education/certification-field'});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    };
    handleValidation() {
        let formIsValid = true;
        let year = this.state.formFields.year;
        let text = /^[0-9]+$/;
        let errors = {};
        if (year && year.toString().trim() != "") {
            if (!text.test(year)) {
                errors["year"] = "Invalid Year";
                formIsValid = false;
            }
            if (year.toString().length != 4) {
                errors["year"] = "Invalid Year";
                formIsValid = false;
            }              
        }       
        if (!this.state.formFields.course) {
            errors["course"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const val = evt.target.value;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        let ffield = this.state.formFields;
        this.setState({
            formFields: ffield
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    handleUniversityChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') {
                ff['university'] = value;
            } else if (typeof value === 'object' && value) { 
                if (value.inputValue) {
                    ff['university'] = value.inputValue;
                }
                else if (value.university) {
                    ff['university'] = value.university;
                }
                if (value.id === 0) {
                    ff['university'] = value.inputValue;
                } else {
                    ff['university'] = value.university;
                }
            }
        } else {
            ff['university'] = '';
        } console.log(ff);
        this.setState({
            formFields: ff
        });
    };
    handleCourseChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') { 
                ff['course'] = value;
            } else if (typeof value === 'object' && value) {
                if (value.inputValue) {
                    ff['course'] = value.inputValue;
                }
                else if (value.course) {
                    ff['course'] = value.course;
                }
                if (value.id === 0) {
                    ff['course'] = value.inputValue;
                } else {
                    ff['course'] = value.course;
                }
            }
        } else {
            ff['course'] = '';
        }
        this.setState({
            formFields: ff
        });
    };
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    };
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
            this.setState({formFields: ff});
        }
        this.setState({errors: {}});
    };
    render(){
        const education = this.state.education.length > 0 ? this.state.education : this.props.Education;
        return (
            <React.Fragment>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Education / Certification</Typography>
                    <Tooltip title="Add Education/Certification" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div> 
                <div className={'section-content'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>University/Institution</TableCell>
                                <TableCell>Course</TableCell>
                                <TableCell>Year</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { typeof education != 'undefined' && education.length
                                ? education.map( (m, index ) =>
                            <TableRow>
                                <TableCell>{m.university}</TableCell>
                                <TableCell>{m.course}</TableCell>
                                <TableCell>{m.year}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                            <EditIcon fontSize="small" onClick={this.onUpdate(m.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                            <DeleteIcon fontSize="small" onClick={this.onDelete(m.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            )
                            :<></> }
                        </TableBody>
                    </Table>
                </div>
                <DialogBoxMedium
                    Open={this.state.open}
                    Cancel={this.onClose}
                    Title={this.state.isDelete ?  'Delete Education' : (this.state.isEditable ? 'Edit Education' : 'Add Education')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Education?'
                        :
                        <DialogContent
                            FormInputs={this.state.formInputs}
                            Education={this.state.education}
                            FormFields={this.state.formFields}
                            OnSubmit={this.onSubmit}
                            HandleOnChange = {this.onHandleChange}
                            Errors = {this.state.errors}
                            HandleUniversityChange = {this.handleUniversityChange}
                            HandleCourseChange = {this.handleCourseChange}
                        />
                    }
                />
                <CustomizedSnackbars
                open={this.state.snackbarOpen}
                variant={this.state.variant}
                message={this.state.message}
                handleClose ={this.snackbarClose}
                />
            </React.Fragment>
        )
    }
}
export { EducationContainer as Education };
