import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { LocationsComponent }  from './Locations.Component.js';

let formFields = {
    location : null,
    description : null,
    status : 'Active',
    color_code : null,
    id : 0,
}
export class LocationsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            locations : [],
            location_type : [],
            editPermission : false,
            open : false,
            popupType : '',
            formFields : Object.assign({},formFields),
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            deleteId : 0,
            snackbarVariant: ''
        }
        this.addLocationClick = this.addLocationClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteLocation = this.deleteLocation.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    componentDidMount = () => {
        this.getAllLocations();
    };

    getAllLocations = () => {
        let apiEndpoint = 'api/settings/workpolicy/listlocations';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        locations : response.data.locations,
                        editPermission : response.data.edit_permission,
                        location_type : response.data.location_type,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addLocationClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            formFields : Object.assign({},formFields),
            errors : {},
        })
    }

    handleDeleteClick = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/workpolicy/saveLocation', { data })
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.valid) {
                                this.getAllLocations();
                                let successMsg = ''
                                if (data.id) {
                                    successMsg = 'Location updated successfully';
                                } else {
                                    successMsg = 'Location added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                if (response.data.errors) {
                                    this.setState({
                                        errors: response.data.errors,
                                    });
                                }
                                else {
                                    let errorMsg = 'Location already exists';
                                    this.setState({
                                        snackbarOpen: true,
                                        snackbarMsg: errorMsg,
                                        snackbarVariant: 'error'
                                    });
                                }
                            }
                        }
                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.location) {
            errors["location"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.color_code) {
            let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
            if (!regex.test(this.state.formFields.color_code)) {
                errors["color_code"] = "Invalid color code";
                formIsValid = false;
            }
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/workpolicy/getlocation-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.location) {
                       let formFields = {
                           id : response.data.location.id,
                           location : response.data.location.location,
                           type : response.data.location.type,
                           description : response.data.location.description,
                           status : response.data.location.status,
                           color_code: response.data.location.color_code
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    deleteLocation = () => {
        let apiEndpoint = 'api/settings/workpolicy/deleteLocation';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    if (response.data.valid) {
                        this.getAllLocations();
                        this.setState({
                            snackbarOpen : true,
                            snackbarMsg : "Location deleted successfully",
                            open : false,
                            popupType : '',
                            snackbarVariant: 'success'
                        });
                    } else {
                        alert(response.data.err_msg);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <LocationsComponent
                locations = {this.state.locations}
                location_type = {this.state.location_type}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddLocationClick = {this.addLocationClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                DeleteLocation = {this.deleteLocation}
                HandleDeleteClick = {this.handleDeleteClick}
                SnackbarVariant = {this.state.snackbarVariant}
            />
        )
    }
}
