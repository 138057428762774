import React from 'react';
import * as _R from 'ramda';
import { httpServices } from '../../../services/httpServices';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { AdditionalptoDetails } from './AdditionalptoDetailsComponent';
import { ErrorPage } from '../../common/error';

const formFields = {
    'id':0,
    'hours' : null,
    'comment' :null,
    'carry_forward':false,
}

export class AdditionalptoDetailsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            requestDetails : [],
            ptodetails :[],
            open : false,
            viewId :0, 
            viewClick : false, 
            action :'',
            formFields :formFields,
            is_reviewer :false,
            can_comment:false,
            is_cancel:false,
            snackbarOpen:false,
            message : [],
            errors: {},
            showLoader : true,
            permission:false,
            SelfEdit : true,
            dataLoaded : false
        }
        this.getAdditionalptoDetails = this.getAdditionalptoDetails.bind(this);
        this.getPtoDetails = this.getPtoDetails.bind(this);
    }

    componentDidMount = () => {
        this.getAdditionalptoDetails(this.props.match.params.id);
    };

    getAdditionalptoDetails = (id) =>{
        let apiEndpoint = 'api/additionalpto/view';
        let data = {
            'id' : id,
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        let requests = response.data.requests;
                        this.getPtoDetails(requests.year,requests.user_ids);
                        let formfields=[];
                        formfields.id = id;
                        formfields.hours = requests.hours;
                        this.setState({
                            requestDetails:requests,
                            formFields:formfields ,
                            is_reviewer :response.data.is_reviewer,
                            can_comment:response.data.is_comment,
                            is_cancel:response.data.is_cancel,
                            SelfEdit : response.data.self_edit,
                            permission:true,
                            showLoader : false,
                            dataLoaded : true
                        });
                    } else {
                        this.setState({
                            permission:false,
                            showLoader : false,
                            dataLoaded : true
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    getPtoDetails = (year,user_id) => {
        return httpServices.post('api/additionalpto/ptoinfo', {'year': year,'user_id': user_id})
            .then(response => {
                if (response.data) {
                    
                    this.setState({
                        ptodetails:response.data.pto_details
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
        });
    }

    confirmRequest = (action ) => {
        if(action !== 'token_generate'){
            let formfields=this.state.formFields;
            formfields.hours = this.state.requestDetails.hours;
            formfields.comment='';
            this.setState({ 
                open:true ,
                action : action ,
                formFields: formfields,
                errors: []
            });
        }
    }

    sendRequest = (action) => {
        let data = {
            'id' : this.state.requestDetails.id,
            'action':action,
            'hours':this.state.formFields.hours,
            'comment': this.state.formFields.comment,
            'carry_forward':this.state.formFields.carry_forward?'Y':'',
            'year':this.state.requestDetails.year
        };
        if ( this.validateSubmit(action) ){
            this.setState({
                showLoader : true,                
            });
            return httpServices.post('api/additionalpto/review', data)
                .then(response => {
                    let requestDetails = this.state.requestDetails;
                    if (response.data.status) {
                        this.getAdditionalptoDetails(requestDetails.id); 
                        this.setState({ 
                            showLoader : false,
                            snackbarOpen : true,
                            message :response.data,
                            open : false,
                        }); 
                    } else {
                        this.setState({
                            errors : typeof response.data.errors !== 'undefined' ? response.data.errors : {},
                            showLoader : false,
                        });
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
        });
        }
    }
    onClose = () => {
       this.setState({
           open:false
       });
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if(name === 'carry_forward'){
            f[name]=this.state.formFields.carry_forward ?false:true;
        }
        else { 
            f[name] = evt.target.value;
        }
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    };
    validateSubmit = (action) => {
        let valid = true;
        let errors = {};
        if (action === 'approve') {
            let val = this.state.formFields.hours;
            if (!val) {
                valid = false;
                errors['hours'] = "Hours cannot be empty";
            }
            else
            {
                let minimumInc = 0.25;
                let re = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
                let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
                let re2 = new RegExp("^(.)[0-9]{1,2}");
                if(re2.test(val)) {
                    val = '0'+val;
                }
                if(re1.test(val)) {
                    let hm = val.split(':');
                    let fr = hm[1]/60;
                    val = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
                }
                if(!(re.test(val) || re2.test(val))) {
                    valid = false;
                    errors["hours"] = 'Hours is invalid';
                }
                else {
                    let min = minimumInc*100;
                    let frp = val%1;
                    frp = frp.toFixed(2)*100;
                    if(!(frp%min===0)) {
                        valid = false;
                        errors["hours"] = 'Hours should be entered as a multiple of '+minimumInc+' hours';
                    }
                }
            }
        }
        if (action === 'approve' || action === 'reject' || action === 'comment') {
            let comment = this.state.formFields.comment;
            if (_R.length(comment.trim()) === 0) {
                valid = false;
                errors['comment'] = "Comment cannot be empty";
            }
        }
        this.setState({errors: errors});
        return valid;
    }

    updateParent = (successFlag) => {
        this.setState({
            open : false,
        });
        if (successFlag) { 
            let msg={};
            msg['message'] = "Request updated successfully";
            msg['message_type']='success';
            this.setState({ 
                showLoader : true,
                snackbarOpen : true,
                message : msg
            },
                function(){ 
                    this.getAdditionalptoDetails(this.props.match.params.id); 
                }
            );
        }
    }
    

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                <>
                {(this.state.permission)?
                    <AdditionalptoDetails
                        RequestDetails = {this.state.requestDetails} 
                        PtoDetails = {this.state.ptodetails} 
                        SendRequest = {this.sendRequest.bind(this)}  
                        ConfirmRequest = {this.confirmRequest.bind(this)} 
                        HandleOnChange = {this.handleOnChange.bind(this)}
                        open = {this.state.open} 
                        Close = {this.onClose.bind(this)} 
                        action = {this.state.action} 
                        FormFields = {this.state.formFields} 
                        is_reviewer = {this.state.is_reviewer} 
                        can_comment = {this.state.can_comment}
                        is_cancel ={this.state.is_cancel}
                        SnackbarOpen = {this.state.snackbarOpen} 
                        SnackbarClose = {this.snackbarClose} 
                        message ={this.state.message}
                        Errors = {this.state.errors}
                        SelfEdit = {this.state.SelfEdit}
                        UpdateParent = {this.updateParent.bind(this)}
		            />
                    :<ErrorPage/>
                }
                </>
                :<></>
                }
        </>
        )
    }
}
