import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import { CustomizedSnackbars } from '../../../common/snackbars';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import * as _R from 'ramda';
import {DialogBoxMediumAlert} from '../../../common/dialog.alert';
import { formatName } from '../../../common/common';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="Timeoff type" required error={props.Errors.timeoff_type_id && true}>Timeoff Type</InputLabel>
                            <Select
                                value={props.FormFields.timeoff_type_id}
                                onChange={props.HandleOnChange}
                                error={props.Errors.timeoff_type_id && true}
                                inputProps={{
                                    name: 'timeoff_type_id',
                                    id: 'timeoff_type_id',
                                }}>
                                {props.TimeofftypeData.map((type, i) =>
                                    <MenuItem value={type.id}>{type.to_type}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.timeoff_type_id}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="category" required  error={props.Errors.category && true}>Category</InputLabel>
                            <Select
                                value={props.FormFields.category}
                                onChange={props.HandleOnChange}
                                error={props.Errors.category && true}
                                inputProps={{
                                    name: 'category',
                                    id: 'category',
                                }}>
                                     {props.FormInputs.adjustment_type && props.FormInputs.adjustment_type.map((type, i) =>
                                    <MenuItem value={type}>{type}</MenuItem>
                                )}
                               
                            </Select>
                            <small className='error'>{props.Errors.category}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="Year" required error={props.Errors.year && true}>Year</InputLabel>
                            <Select
                                value={props.FormFields.year}
                                error={props.Errors.year && true}
                                inputProps={{
                                    name: 'year',
                                    id: 'year',
                                }}
                                onChange={props.HandleOnChange}
                            >
                                <MenuItem value={props.CurrentYearValue - 1} key={'year-' + (props.CurrentYearValue - 1 )}>{props.CurrentYearValue - 1}</MenuItem>
                                <MenuItem value={props.CurrentYearValue} key={'year-' + (props.CurrentYearValue)}>{props.CurrentYearValue}</MenuItem>
                                <MenuItem value={props.CurrentYearValue + 1} key={'year-' + (props.CurrentYearValue + 1)}>{props.CurrentYearValue + 1}</MenuItem>
                            </Select>
                            <small className='error'>{props.Errors.year}</small>
                        </ FormControl>
                    </Grid>
                    {(props.FormFields.adjustment_period === 'payperiod') &&
                        <Grid item xs={12} md={6}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="pay_period">Pay Period</InputLabel>
                                <Select
                                    value={props.FormFields.payperiod_id !== null ? props.FormFields.payperiod_id : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'payperiod_id',
                                        id: 'payperiod_id',
                                    }}>
                                    {_R.type(props.Intervals !== 'Undefined') && props.Intervals.map((p, i) =>
                                        <MenuItem value={p.id} key={'pay-period-' + i}>{p.sdate + ' - ' + p.edate}</MenuItem>
                                    )}
                                </Select>
                                <small className='error'>{props.Errors.payperiod_id}</small>
                            </ FormControl>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="hours"
                                name="hours"
                                label="Hours"
                                type="text"
                                fullWidth
                                required
                                onChange={props.HandleOnChange}
                                value={props.FormFields.hours}
                                error={props.Errors.hours && true}
                                helperText={props.Errors.hours}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="note"
                                name="note"
                                label="Note"
                                multiline
                                rows="4"
                                required
                                value={_R.type(props.FormFields) !== 'Undefined' && props.FormFields.note}
                                onChange={props.HandleOnChange}
                                margin="normal"
                                error={props.Errors.note && true}
                                helperText={props.Errors.note}
                            />
                        </FormControl>
                    </Grid>
                    
                </Grid>
            </form>
        )
};
export const AdjustmentComponent = (props) => {
    const { Edit } = props;
    const columns = [
        { title: 'Timeoff type', field: 'to_type',disableClick:true },
        { title: 'Year', field: 'year',disableClick:true },
        { title: 'Payperiod', field: 'payperiod', disableClick:true},
        { title: 'Category', field: 'category',disableClick:true},
        { title: 'Hours', field: 'hours',disableClick:true},
        { title: 'Updated method', field: 'update_method',disableClick:true},
        { title: 'Note', field: 'note'},
        { title: 'Created on', field: 'created_on',disableClick:true},
        { title: 'Created by', field: 'created_by',disableClick:true ,render: rowData => <>{formatName(rowData.cby_first_name,null,rowData.cby_last_name)}</>},
        { title: 'Modified on', field: 'modified_on',disableClick:true},
        { title: 'Modified by', field: 'modified_by',disableClick:true,render: rowData => <>{formatName(rowData.mby_first_name,null,rowData.mby_last_name)}</> },
    ];
    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Adjustment"
                    columns={columns}
                    data={props.Timeoff_Adjusment}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging:false,
                    }}
                    actions={[
                        {   
                            icon: 'add',
                            tooltip: 'Add Adjustment Hours',
                            isFreeAction: true,
                            onClick: (event, newData) => { 
                                props.OnAdd();
                            },
                            hidden: (Edit && parseInt(props.CurrentYearValue) >= new Date().getFullYear()) ? false : true,
                        },
                       rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                props.HandleUpdate(rowData.id);
                            },
                            hidden: (rowData.adjustments == '1' && parseInt(props.CurrentYearValue) >= new Date().getFullYear()) ? false : true,
                        }),
                    ]}
            />
            </div>
            <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {props.IsEdit ? 'Edit Adjustment Hours' : 'Add Adjustment Hours'}
                    OnSubmit={props.SaveAdjusments}
                    Loading ={props.ButtonLoader}
                    Content={<DialogComponent FormProps={props}/>}
            />
            <DialogBoxMediumAlert
            Open={props.openNote} 
            Content={props.FormFields.note}
            Cancel={props.CloseNote}
            />
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Errors.duplicate_error?props.Errors.duplicate_error:props.Message}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    );
}
