import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb

import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';


const formFields = {
    'id': 0,
    'start_date': null,
    'end_date': null,
    'schedule': '',
}
const DialogContent = (props) => {
    const {FormFields, FormInputs, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                value={FormFields.start_date}
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                className="no-margin"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                error={props.Errors.start_date && true}
                            />
                            <small className='error'>{props.Errors.start_date && props.Errors.start_date}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {FormFields.end_date}
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                className="no-margin"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="schedule" required error={props.Errors.schedule && true}>Schedule</InputLabel>
                            <Select
                                required
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.schedule}
                                onChange={HandleOnChange}
                                error={props.Errors.schedule && true}
                                inputProps={{
                                    name: 'schedule',
                                    id: 'schedule',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.schedules) !== 'Undefined' && FormInputs.schedules.map((t, i) =>
                                    <MenuItem value={t.id} key={'schedule-'+i}>{t.schedule_name}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.schedule && props.Errors.schedule}</small>
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

class WorkScheduleListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            errors: {},
            errorMessage: false,
            schedule: this.props.Schedule, 
            edit: this.props.Edit, 
            deletePermission: this.props.Delete,
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.OnClose = this.OnClose.bind(this);
    }
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.id = this.state.id;
            data.user_id = userID;
            data.staff_id = this.props.StaffID;
            let tab = StaffConstants.STAFF_TABS[2];
            let module = 'work_schedule';
            let apiEndpoint = 'api/staff/work/save-work-schedule';
            if (data) {
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.input_errors) {
                            if (!response.data.error) {
                                this.setState({
                                    schedule : response.data.work_schedule,
                                    open:false,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                        else {
                            this.setState({
                                errors: response.data.input_errors,
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    onUpdate = (id = 0) => () => {
        const {UserID} = this.props;
        let apiEndpoint = 'api/staff/work/workschedule';
        let editable = (id) ? true : false;
        return httpServices.post(apiEndpoint, {
                        'userID':UserID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        id: id,
                        open:true,
                        formFields: (id) ? response.data.selected_schedule : formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    OnClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.schedule) {
            errors["schedule"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    render(){
        const {schedule, edit, deletePermission} = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Work Schedule</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Work Schedule" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Schedule</TableCell>
                            {_R.or(edit, deletePermission) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof schedule != 'undefined' && schedule.map((s, i)  =>
                        <TableRow hover key={i}>
                            <TableCell>{s.start_date}</TableCell>
                            <TableCell>{s.end_date}</TableCell>
                            <TableCell>{s.title}</TableCell>
                            {_R.or(edit, deletePermission) &&
                            <TableCell align="right">
                            {_R.equals(edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={this.onUpdate(s.id)}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(schedule) &&
                        <TableRow>
                            <TableCell colSpan={4} align="center" color="textSecondary">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </div>
            <DialogBoxMedium 
                    Open={this.state.open} 
                    Cancel={this.OnClose}
                    Title={this.state.isEditable ? 'Edit Work Schedule' : 'Add Work Schedule'}
                    OnSubmit={this.onSubmit}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        <DialogContent
                            FormInputs={this.state.formInputs}
                            FormFields={this.state.formFields}
                            OnSubmit={this.onSubmit}
                            HandleOnChange = {this.HandleOnChange}
                            Errors = {this.state.errors}
                        />
                    }
                />
            </>
        )
    }
}
export { WorkScheduleListContainer as WorkScheduleList };
