import React from 'react';
import {PreliminaryReviewComponent} from './PreliminaryReview.Component';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _R from 'ramda';  // used 0.04kb
import moment from 'moment';


const formFields = {
    staff: ['0'],
    startDate : null,
    lock_status : 0
}

export class PreliminaryReviewContainer extends React.Component{
    constructor(props) {
        super(props);       
        this.state ={
            projects :[],
            formFields : formFields,
            errors :{},
            project_id : 0,
            project_resources : [],
            week : null,
            selectedDates  : [],
            selectedTimesliceCbyIds : [],
            dialogConfirmOpen: false,
            lockAction : '',
            selectedTimeslices : [],
            snackbarOpen : false,
            message : '',
            timesheet_details : [],
            submitClick : false,
            lockPermission : false,
            unlockPermission : false,
            selectedValues : [],
            dialogWarningOpen: false,

        }
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleLockClick = this.handleLockClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleLockSubmit = this.handleLockSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount = () => {        
        window.scrollTo(0, 0);
        this.getReviewList();
    };
    getReviewList = () => {              
        return httpServices.post('api/timesheet/prelimreview')
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        projects : response.data.projects,
                        lockPermission : response.data.lock_permission,
                        unlockPermission : response.data.unlock_permission,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader :false,
                    });
                }
            })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    onClose = () => {
        this.setState({
            dialogConfirmOpen : false,
            dialogWarningOpen : false,
        }); 
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let projects = [];
        let formFields = this.state.formFields;
        if (sNodes.length < 1) {
            projects = 0;
        }
        else if (cNode.prelim_review_flag == 0) {
            projects = 0;
        }       
        else if (cNode.project_id) {
            projects = cNode.project_id;
        }
        formFields['project_id'] = projects;
        this.setState({formFields: formFields}, () => {
            if (projects) {
                this.getStaff(projects);
            }
        });
    }
    getStaff = (project) => {
        return httpServices.post('api/timesheet/getprelimreviewprojectstaff', {project})
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        project_resources : response.data.project_resources,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader :false,
                    });
                }
            })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};      
        if (this.state.projects.length === 0 || this.state.formFields.project_id === 0 || _R.type(this.state.formFields.project_id) == 'Undefined'){
            errors["project_id"] = "Project cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.staff.length === 0 || (this.state.formFields.staff.length === 1 && this.state.formFields.staff[0] == 0)){
            errors["staff"] = "Staff cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.startDate) {
            errors["startDate"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({
            errors : errors
        });
        return formIsValid;
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let val = evt.target.value;
        ff[field] = val;
        if (field === 'staff' && val && val.length) {
            let length = val.length;
            let staffArr = val;
            if (val[0] === '0' && val.length > 1) {
                staffArr = val.filter(stf => stf !== '0');
            } else if (val[length - 1] === '0') {
                staffArr = val.filter(stf => stf === '0');
            }
            ff[field] = staffArr;
        }
       
        this.setState({
            formFields : ff
        });
    };
    
    handleStartDateChange = (date) => {
        let errors = this.state.errors;
        const f = this.state.formFields;
        const field = 'startDate';
        f[field] = date;
        errors['startDate'] = "";
            let week = null;
            let day = moment(date, 'YYYY-MM-DD');
            if (day.isValid()) {
                let weekStart = day.startOf('week').format("MM/DD/YYYY");
                let weekEnd = day.endOf('week').format("MM/DD/YYYY");
                week = weekStart + " - " + weekEnd;
                f['weekStart'] = weekStart;
                f['weekEnd'] = weekEnd;
            }      
      
        this.setState({
            formFields: f,
            errors : errors,
            week: week
        });
    };
    handleSubmit = () => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (this.state.formFields.staff) {
                data.json_staff = JSON.stringify(this.state.formFields.staff);
            }
            this.setState({ showLoader: true });
            httpServices.post('api/timesheet/getPrelimReviewTimeEntry', data)
                .then(response => {
                    if (response.data.permission && !response.data.errors) {
                        this.prepareTimeSliceData(response.data.timeslices);
                        this.setState({
                            //timesheet_details: response.data.timeslices,
			    selectedTimeslices : [],
			    selectedTimesliceCbyIds: [],
                selectedValues: [],
                            submitClick : true
                        });
                    }
                    else {
                        this.setState({
                            hasPermission: false,
                        });
                    }
                })

        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,                            
        });
    }
    handleLockClick = (action) => {
        if(this.state.selectedValues.length > 0){
            this.setState({
            dialogConfirmOpen: true,
            lockAction : action
        });
    }
    else{
        this.setState({
            dialogWarningOpen : true,
        });
    }
}
    handleLockSubmit = () => {
        if (this.state.selectedDates ) {
            let data = {'timeslices' : JSON.stringify(this.state.selectedValues ), 'action' : this.state.lockAction, 'fields' : this.state.formFields , 'selected_staff' : JSON.stringify(this.state.selectedTimesliceCbyIds)};
            this.setState({ showLoader: true });
            httpServices.post('api/timesheet/lockPrelimReviewTimeEntry', data)
                .then(response => {
                    if (response.data.permission && !response.data.errors) {
                        this.setState({
                            snackbarOpen : true,                            
                            message : response.data.message,
                            dialogConfirmOpen: false,
                        });
                        this.handleSubmit();
                    }
                    else {
                        this.setState({
                            hasPermission: false,
                        });
                    }
                })

        }

    }
    handleSelectionChange = (evt, value) => {
        let val = evt.target.value;
        const name = evt.target.name;
        let selectedTimeslices = this.state.selectedTimeslices; 
        let selectedTimesliceCbyIds = this.state.selectedTimesliceCbyIds;
        let details = this.state.timesheet_details;
	let selectedValues = this.state.selectedValues;
        if (name == "timeslice_checkbox") {
            let val_array = val.split("_");
            if (evt.target.checked) {
                Object.keys(details).map(function(key,p_index) {
                    if (details[key].id == val_array[1]) {
                        let staff = details[key]['timeslice'];                       
                        Object.keys(staff).map(function(each_date , s_index) {
                            if (each_date == val_array[0]) {
                                if (staff[each_date].hasOwnProperty('each_timeslice')) {
                                    let each_timeslice = staff[each_date].each_timeslice;
                                    Object.keys(each_timeslice).map(function(slice , index) {
                                        let each_val = each_timeslice[slice];
                                        selectedValues.push([each_val.timeslice_date, val_array[1], each_val.project_id]);
                                    })
                                }
                                else
                                {
                                    selectedValues.push([each_date, val_array[1]]);
                                   
                                }
                            }                           
                        })
                    }
                });                         
                selectedTimeslices.push(val);
            }
            else {
                selectedValues = selectedValues.filter(function(value, index, arr){
                    return (value[0] !== val_array[0] || value[1] !== val_array[1]);
                }); 
                selectedTimeslices = selectedTimeslices.filter(function(value, index, arr){
                    return (value !== val);
                });               
            }
        }
        else
        {
            if (evt.target.checked) {
                selectedTimesliceCbyIds.push(parseInt(val));                
                Object.keys(details).map(function(key,p_index) {
                    if(details[key].id == val) {
                        let staff = details[key]['timeslice'];                       
                        Object.keys(staff).map(function(each_date , s_index) {
                            selectedTimeslices.push(each_date+"_"+val);
                            if (staff[each_date].hasOwnProperty('each_timeslice')) {
                                let each_timeslice = staff[each_date].each_timeslice;
                                Object.keys(each_timeslice).map(function(slice , index) {
                                    let each_val = each_timeslice[slice];
                                    selectedValues.push([each_val.timeslice_date, val, each_val.project_id]);
                                })
                            }
                            else
                            {
                                selectedValues.push([each_date, val]);
                               
                            }
                        })
                    }
                });               
            }
            else {
                selectedTimesliceCbyIds = selectedTimesliceCbyIds.filter(function(value, index, arr){
                    return value !== parseInt(val);
                });
                selectedTimeslices = selectedTimeslices.filter(function(value, index, arr) {
                    let split_value = value.split("_");
                    return (split_value[1] !== val);
                });
                
                selectedValues = selectedValues.filter(function(value, index, arr){
                    return (value[1] !== val);
                });                
            }
        }
        this.setState({
            selectedTimesliceCbyIds: selectedTimesliceCbyIds,
            selectedTimeslices : selectedTimeslices,
            selectedValues : selectedValues
        });       
    }
    prepareTimeSliceData = (data) => {        
        let monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        Object.keys(data).map((key,p_index) => {
            let obj = {};
            obj['date'] = key;
            obj['m_date'] = '';            
            let slice = data[key].timeslice;
            Object.keys(slice).map((key1, p_index1) => {
               let dateObj = {};
                dateObj['m_date'] = key1;
                let monthDate = new Date(dateObj['m_date']+"T00:00:00");
                dateObj['monthName'] = monthNames[monthDate.getMonth()]
                dateObj['dateValue'] = monthDate.getDate();
                dateObj['dateYear'] =  monthDate.getFullYear(); 
                dateObj['dayName'] = days[monthDate.getDay()];
                slice[key1]['ts_date'] = dateObj;
                if (this.state.formFields.lock_status === 1 || this.state.formFields.lock_status === 0){
                    if (slice[key1]['each_timeslice'] !== undefined) {
                        let each_timeslice = slice[key1]['each_timeslice'];
                        let filtered_array = each_timeslice.filter(el => el.lock_status == this.state.formFields.lock_status);
                        if (filtered_array.length > 0) {
                            slice[key1]['each_timeslice'] = filtered_array;
                        }
                        else
                        {
                            delete slice[key1];
                        }                   
                    }
                    else {
                        if (slice[key1].lock_status != this.state.formFields.lock_status) {
                            delete slice[key1];
                        }
                    }           
                }
            })
        });
        this.setState({
            timesheet_details: data,
        });       
    }  
    render(){
        return(            
                <PreliminaryReviewComponent
                    FormFields = {this.state.formFields}
                    HandleTreeChange = {this.handleTreeChange}
                    Errors = {this.state.errors}
                    Projects = {this.state.projects}
                    ProjectResources = {this.state.project_resources}
                    HandleOnChange = {this.handleOnChange}
                    Week = {this.state.week}
                    HandleSubmit = {this.handleSubmit}
                    HandleStartDateChange = {this.handleStartDateChange}
                    TimesheetDetails = {this.state.timesheet_details}
                    HandleSelectionChange = {this.handleSelectionChange}
                    SelectedTimesliceCbyIds = {this.state.selectedTimesliceCbyIds}
                    HandleLockClick = {this.handleLockClick}
                    DialogConfirmOpen = {this.state.dialogConfirmOpen}
                    OnClose = {this.onClose}
                    LockAction = {this.state.lockAction}
                    HandleLockSubmit = {this.handleLockSubmit}
                    SelectedTimeslices = {this.state.selectedTimeslices}
                    SnackbarOpen = {this.state.snackbarOpen}
                    Message = {this.state.message}
                    SnackbarClose = {this.snackbarClose}
                    SubmitClick = {this.state.submitClick}
                    LockPermission = {this.state.lockPermission}
                    UnlockPermission = {this.state.unlockPermission}
                    DialogWarningOpen = {this.state.dialogWarningOpen}
                />
        )
    }
}
