import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const ProjectStatusReportComponent = (props) => {
 
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Project Status Report</h6>
                </Grid>
                <Grid item>
                    <form className="form-fullwidth" >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.FilterFields.start_date}
                                        id="start_date"
                                        name="start_date"
                                        label="Start Date"
                                        type="date"
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.Errors.start_date && true}
                                        helperText={props.Errors.start_date}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.FilterFields.end_date}
                                        id="end_date"
                                        name="end_date"
                                        required
                                        label="End Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.Errors.end_date && true}
                                        helperText={props.Errors.end_date}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <div class="filter-column">
                                    <div class="filter-column-content">
                                        <FormControl error={_R.type(props.Errors.client) !== 'Undefined'?true:false} variant="standard">
                                            <InputLabel required htmlFor="client">Client</InputLabel>
                                            <Select
                                                required
                                                value={props.FilterFields.client}
                                                inputProps={{
                                                    name: 'client',
                                                    id: 'client',
                                                }
                                                }
                                                onChange={props.HandleFilterChange}
                                            >
                                                <MenuItem key={'client-0'} value={0}>-Select-</MenuItem>
                                            {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.clients) !== 'Undefined' && props.Filters.clients.map((rtype, i) =>
                                                <MenuItem key={'client-'+i} value={rtype.client_id}>{rtype.client_name}</MenuItem>
                                            )}
                                            </Select>
                                            {_R.type(props.Errors.client) !== 'Undefined' && <FormHelperText>{props.Errors.client}</FormHelperText>}
                                        </FormControl>                                        
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <div class="filter-column">
                                    <div class="filter-column-content">
                                        <FormControl error={_R.type(props.Errors.project) !== 'Undefined'?true:false} variant="standard">
					                    <InputLabel required htmlFor="project">Project</InputLabel>
                                        <Select
                                            required
                                            value={props.FilterFields.project}
                                            inputProps={{
                                                name: 'project',
                                                id: 'project',
                                            }}
                                            onChange={props.HandleFilterChange}
                                        >
                                            <MenuItem key={'project-0'} value={0}>-Select-</MenuItem>
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.projects) !== 'Undefined' && props.Filters.projects.map((rtype, i) =>
                                            <MenuItem key={'project-'+i} value={rtype.project_id}>{rtype.project_name}</MenuItem>
                                        )}
                                        </Select>
                                        {_R.type(props.Errors.project) !== 'Undefined' && <FormHelperText>{props.Errors.project}</FormHelperText>}
                                        </FormControl>                                        
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2} >
                                <FormControl variant="standard">
                                    <InputLabel required htmlFor="report_type">Report By</InputLabel>
                                    <Select
                                        required
                                        value={props.FilterFields.report_type}
                                        inputProps={{
                                            name: 'report_type',
                                            id: 'report_type',
                                        }}
                                        onChange={props.HandleFilterChange}
                                    >
                                    {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.report_types) !== 'Undefined' && props.Filters.report_types.map((rtype, i) =>
                                        <MenuItem key={'report-type-'+i} value={rtype.id}>{rtype.value}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>                           
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={(typeof props.FilterFields.nbillable !== 'undefined') ? props.FilterFields.nbillable : false}  
                                                value={(typeof props.FilterFields.nbillable !== 'undefined') ? props.FilterFields.nbillable : null}
                                                id = "nbillable" 
                                                name ="nbillable"
                                                onChange={props.HandleFilterChange}
                                            />
                                        }
                                        label="Include Non-billable tasks"
                                    />
                                </div>
                                {(props.FilterFields.report_type === 'task') ?
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(typeof props.FilterFields.include_employee !== 'undefined') ? props.FilterFields.include_employee : false}
                                                    value={(typeof props.FilterFields.include_employee !== 'undefined') ? props.FilterFields.include_employee : null}
                                                    id = "include_employee"
                                                    name ="include_employee"
                                                    onChange={props.HandleFilterChange}
                                                />
                                            }
                                            label="Include Staff Name"
                                        />
                                    </div>
                                    :<></>
                                }
                            </Grid>
                            {(props.FilterFields.report_type==='resource' || props.FilterFields.report_type==='labour-category' || props.FilterFields.include_employee) ?
                                <Grid item xs={12} sm={6} md={5} lg={2}>
                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="name_format">Report Name Format</InputLabel>
                                        <Select
                                            value={props.FilterFields.name_format}
                                            onChange={props.HandleFilterChange}
                                            inputProps={{
                                            name: 'name_format',
                                            id: 'name_format',
                                            }}
                                        >
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.name_formats) !== 'Undefined' && props.Filters.name_formats.map((k, i) =>
                                            <MenuItem value={k.key}>{k.value}</MenuItem>
                                        )}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                :<></>
                            }
                            <Grid item xs={12} sm={6} md={2} >
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(typeof props.FilterFields.include_timeoff !== 'undefined') ? props.FilterFields.include_timeoff : false}
                                                value={(typeof props.FilterFields.include_timeoff !== 'undefined') ? props.FilterFields.include_timeoff : null}
                                                id = "include_timeoff"
                                                name ="include_timeoff"
                                                onChange={props.HandleFilterChange}
                                            />
                                        }
                                        label="Include Time Off details"
                                    />
                                </div>
                            </Grid>
                            {(props.FilterFields.include_timeoff) ? <>
                                <Grid item xs={12} sm={6} md={2} >
                                    <div>
                                        <FormControl>
                                            <TextField variant="standard"
                                                value = {props.FilterFields.timeoff_start_date}
                                                id="timeoff_start_date"
                                                name="timeoff_start_date"
                                                label="From"
                                                type="date"
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.HandleFilterChange}
                                                error={props.Errors.timeoff_start_date && true}
                                                helperText={props.Errors.timeoff_start_date}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} >
                                    <div>
                                        <FormControl>
                                            <TextField variant="standard"
                                                value = {props.FilterFields.timeoff_end_date}
                                                id="timeoff_end_date"
                                                name="timeoff_end_date"
                                                required
                                                label="To"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.HandleFilterChange}
                                                error={props.Errors.timeoff_end_date && true}
                                                helperText={props.Errors.timeoff_end_date}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                </>
                                :<></>
                            }
                            <Grid item xs={12} class='filter-action'>
                                <div class="filter-column">
                                    <div class="d-flex align-center justify-end">
                                        <Button variant="contained" size="small" color="primary" onClick={props.ValidateFilter}> Submit</Button>
                                    </div>                                   
                                </div>
                            </Grid> 
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper>
        <Paper className={'mt-1'}>
        { (props.Type === 'task') ?
            <Grid item xs={12} class="p1">
            { Object.values(props.Data).map((value,key) =><>
                { (Object.keys(value.logs).length > 0) ? <>
                    <h3>{value.project_name}</h3>
                    <ul>
                    { Object.keys(value.logs).map((log_key) =><>
                        <li><b>{log_key}</b></li> 
                        <ul>
                            {Object.values(value.logs[log_key]).map((log_value,k) =>
                                <li>{log_value.timeslice_log+' ['+log_value.location+'] '+(props.FilterFields.include_employee?'['+log_value.user_fullname+']':'')}</li>
                            )}
                        </ul>
                        </>
                    )}
                    </ul>
                    </>
                    :<></>
                }
                </> 
            )}             
            </Grid>
            : (props.Type === 'resource') ?
            <Grid item xs={12} class="p1">
            { Object.values(props.Data).map((value,key) => <>
                <h3>{value.project_name}</h3>
                <p className={'pl-1'}>Total Hours Worked During Status Reporting Period: { value.total_hours}</p>
                <p className={'pl-1'}>Cumulative Hours: {value.c_total_hours}</p>
                <ul>
				{Object.values(value.logs).map((v,k) =>
                    <>
                        <li><b>{v.resource_name+" [Total Hours:"+v.total_hours+"]"}</b></li>
                        <ul>
                            {Object.values(v.logs).map((log_value,k) =>
                                <li>{log_value}</li>
                            )}
                        </ul>
                    </>
                )}
                </ul>
                </> 
            )}             
            </Grid>
            :(props.Type === 'labour-category') ?
            <Grid item xs={12} class="p1">
                {Object.values(props.Data).map((value,key) => <>
                    <h3>{value.project_name}</h3>
                    <ul>
                        {Object.values(value.logs).map((v,k) => <>
                            <li><b>{v.lcat_name}</b></li>
                            <ul>{ Object.values(v.resources).map((resource,k) => <>
                                <li>{resource.resource_name}</li>
                                <ul>{ Object.values(resource.logs).map((log_value,log_key) =>
                                    <li>{log_value}</li>
                                )}
                                </ul>
                                </>
                            )}
                            </ul>
                            </>
                        )}
                    </ul>
                    </> 
                )}
            </Grid>
            :<></>
        }
        {(Object.keys(props.TimeoffData).length > 0) ?
                <Grid item xs={12} class="p1">
                    <h3>Upcoming Timeoff Details</h3> 
                    <Table  size="small" aria-label="a dense table" border={1}>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Staff Name</TableCell>
                                <TableCell component="th" scope="row">Timeoff Date</TableCell>
                                <TableCell component="th" scope="row">Duration</TableCell>
                                <TableCell component="th" scope="row">Saved Hours</TableCell>
                                <TableCell component="th" scope="row">Type</TableCell>
                                <TableCell component="th" scope="row">Status</TableCell>
                            </TableRow>                            
                        </TableHead>
                        <TableBody>
                        { Object.values(props.TimeoffData).map((value,key) =>
                            <>{ (Object.keys(value.timeoff).length > 0 ) ?  <>
                                { Object.values(value.timeoff).map((log_value,log_key) => <> 
                                    { (log_key) ?
                                        <TableRow>
                                            <TableCell component="td" scope="row">{log_value.date}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.hours}</TableCell>
                                            <TableCell component="td" scope="row">{parseFloat(log_value.saved_hours)?log_value.saved_hours+' hours':''}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.timeoff_type}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.status}</TableCell>
                                        </TableRow>
                                        :<TableRow>
                                            <TableCell component="td" scope="row" rowspan={Object.keys(value.timeoff).length}>{value.user_fullname}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.date}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.hours}</TableCell>
                                            <TableCell component="td" scope="row">{parseFloat(log_value.saved_hours)?log_value.saved_hours+' hours':''}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.timeoff_type}</TableCell>
                                            <TableCell component="td" scope="row">{log_value.status}</TableCell>
                                        </TableRow>
                                    }
                                    </>
                                )}
                                </>
                                :<></>
                            }
                            </>
                        )}                            
                        </TableBody>
                    </Table>
                </Grid>
                :<></>
        }
        </Paper>
        </>
    )
};
