import React from 'react';
import {TimeOffListComponent} from './TimeOffListComponent'
import {httpServices}  from '../../../services/httpServices'
import { history } from '../../../helpers/history';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const filterFields = {
    'status' :'1',
    'year' : new Date().getFullYear(),
    'name_format' : 'FIRSTLAST',
}
const formFields = {
    'day_radio' : true,
    'hour_radio' : false,
}

export class TimeOffListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formFields: formFields,
            filetrViewState : false,
            filterInputs : {},
            filterFields : filterFields,
            showLoader : true,
            dataLoaded : false,
        }
        this.filterView = this.filterView.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.viewDetailsPage = this.viewDetailsPage.bind(this);
    }
    componentDidMount() {
        this.getTimeOffList();
    }

    getTimeOffList = () => {
        this.setState({ showLoader : true });
        let ff = this.state.formFields;
        ff['search'] = '';
        httpServices.post('api/timeoff/list',this.state.filterFields)
            .then(response => {
                this.setState({ showLoader : false,
                dataLoaded : true
                });
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        timeOffTypeList : inputs.timeoff_list_header,
                        timeOffListDetails : inputs.timeoff_list_details,
                        columns:inputs.columns,
                        filterInputs : {
                            status : inputs.statuses,
                            years : inputs.employee_start_years,
                            name_formats : inputs.name_format
                        },
                        hasPermission : true,
                        loader : false,
                        formFields : ff,
                    });
                }
                else if (response.data.self_permission){
                    let url = '/timesheet/timeoff/view/';
                    if (window.location.href.includes("public/requests/")) {
                        url = '/requests/timeoffdetails/view/';
                    }
                    history.push({pathname:BASE_PATH+ url + response.data.user_id, state:{year:response.data.year}});
                }
                else {
                    this.setState({
                        hasPermission : false,
                        loader : false
                    });
                }
            })
    }
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    handleFilterChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.filterFields;
        ff[field] = evt.target.value;
        this.setState({
            filterFields: ff
        });
    };
    handleFilter = () => { 
        this.setState({
            loader:true,
            right: false,
            filterViewState : false
        },()=>{
                this.getTimeOffList();
            }
        );
    }
    resetFilter = () => {
        let formfield = {
            'status' :'1',
            'year' : new Date().getFullYear(),
            'name_format' : 'FIRSTLAST',
        }
        this.setState({
            filterFields: formfield,
            right: false,
            filterViewState : false
        },
         ()=>{
          this.getTimeOffList();}
        );
    }

    viewDetailsPage  = (userID ) =>{
        let url = '/timesheet/timeoff/view/';
        if (window.location.href.includes("public/requests/")) {
            url = '/requests/timeoffdetails/view/';
        }
        history.push({
            pathname:BASE_PATH+ url + userID,
            state:{
                year:this.state.filterFields.year
            }
        });
    }
    
    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let value = evt.target.value;
        if (field === 'day_radio') {
            value = evt.target.checked;
            ff['hour_radio'] = false;
        }
        if (field === 'hour_radio') {
            value = evt.target.checked;
            ff['day_radio'] = false;
        }
        ff[field] = value;
        this.setState({
            formFields: ff,
        });
    }

    
    render(){
       return(
           <>
             <CustomProgressBar loaderOpen={this.state.showLoader}/>
                {(this.state.dataLoaded) ?
                <TimeOffListComponent
                    TimeOffTypeList = {this.state.timeOffTypeList}
                    FormFields = {this.state.formFields}
                    HandleOnChange = {this.handleOnChange}
                    TimeOffListDetails = {this.state.timeOffListDetails}
                    Columns = {this.state.columns}
                    FilterView = {this.filterView}
                    FilterViewState = {this.state.filterViewState}
                    FilterInputs = {this.state.filterInputs}
                    FilterFields = {this.state.filterFields}
                    HandleFilterChange = {this.handleFilterChange}
                    HandleFilter = {this.handleFilter}
                    ResetFilter = {this.resetFilter}
                    HandleViewDetailsPage = {this.viewDetailsPage}
                />
                : <></>}
           </>
       )
    }
}

