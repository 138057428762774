import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PersonalInfoComponent } from './PersonalInfo.Component';
import { UserSettingsComponent } from './UserSettings.Component';
import { UserProjectsContainer } from './UserProjects.Container';
import { EmploymentComponent } from './Employment.Component';
import { UserAccountComponent } from './UserAccount.Component';
import { UserBasic } from './UserBasic.Component';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as _R from 'ramda';
import MenuItem from '@mui/material/MenuItem';
import { CustomizedSnackbars } from '../common/snackbars';
import { DialogBoxMedium } from '../common/dialog.medium';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StaffViewStyle = styled(React.Fragment)(({ theme }) => ({
    '& .paper': {
        color: theme.palette.text.secondary,
        minHeight: 'calc(100vh - 100px)',
        boxShadow: 'none'
    },
    '& .gridRoot': {
        minHeight: 'calc(100vh - 100px)'
    },
    '& .tab': {
        backgroundColor: '#eee',
        display: 'block'
    },
    '& .bgWhiteLeft': {
        width: '100%', height: '100%',
        backgroundColor: '#fff',
        borderRadius: '4px',
        borderRight: '1px solid #eee',
        borderBottomRightRadius: 0,
        padding: 6 * 2,
    },
    '& .bgWhiteContent': {
        backgroundColor: '#fff',
        padding: 8 * 3,
        display: 'block',
        position: 'relative'
    },
}));
export const ProfileComponent = (props) => {
    let tabIndex = "personal";
    //Tabs
    const [tabValue, setTabValue] = React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    return (
        <StaffViewStyle>
            <Paper className={'paper'} style={{ 'width': '100%', 'flexGrow': '1' }}>
                <Grid container justify="space-between" alignItems="stretch" spacing={0} className={'gridRoot'}>
                    <Grid item xs={12} md={2}>
                        <Typography component="div" className={'bgWhiteLeft profile-view'} >
                            <UserBasic
                                PersonalInfo={props.UserDetails}
                                Profile={props.Profile}
                                Accounts={props.Accounts}
                                UserName={props.uname}
                                OnChangePassword={props.OnChangePassword}
                                Photo={props.Photo}
                                Emergency={props.Emergency}
                                OtherAddress={props.OtherAddress}
                                HREmail={props.HREmail}
                                JobTitle = {props.JobTitle}
                                DisplayEmployeeCode={props.DisplayEmployeeCode}
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={10}>
                        <Tabs
                            value={tabValue}
                            onChange={tabChange}
                            variant="scrollable"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="Project Tabs"
                            scrollButtons="auto"
                            className={'tab-main'}
                        >
                            <Tab label="Personal" value="personal" />
                            <Tab label="Work" value="employment" />
                            <Tab label="Projects" value="projects" />
                            <Tab label="Accounts" value="accounts" />
                            <Tab label="Settings" value="settings" />
                        </Tabs>
                        <div className={`bgWhiteContent tabContainer`}>
                            {(tabValue === 'personal') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <PersonalInfoComponent
                                        PersonalInfo={props.UserDetails}
                                        Profile={props.Profile}
                                        Accounts={props.Accounts}
                                        UserName={props.uname}
                                        OnChangePassword={props.OnChangePassword}
                                        Photo={props.Photo}
                                        Emergency={props.Emergency}
                                        OtherAddress={props.OtherAddress}
                                        HREmail={props.HREmail}
                                        Phone = {props.Phone}
                                        Email = {props.Email}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'employment') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <EmploymentComponent
                                        PersonalInfo={props.UserDetails}
                                        ServicePeriods={props.ServicePeriods}
                                        Reviewers={props.Reviewers}
                                        Subordinates={props.Subordinates}
                                        Profile={props.Profile}
                                        UserName={props.uname}
                                        DisplayEmployeeCode={props.DisplayEmployeeCode}
                                        OfficeAddress={props.OfficeAddress}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'settings') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <UserSettingsComponent
                                        OnEmailSettingsEdit={props.OnEmailSettingsEdit}
                                        EditPermission={props.EditPermission}
                                        PersonalInfo={props.UserDetails}
                                        Profile={props.Profile}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'accounts') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <UserAccountComponent
                                        Accounts={props.Accounts}
                                        OnChangePassword={props.OnChangePassword}
                                    />
                                </TabPanel>
                            }
                            {(tabValue === 'projects') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                    <UserProjectsContainer />
                                </TabPanel>
                            }
                        </div>


                    </Grid>
                </Grid>
            </Paper>
            <DialogBoxMedium
                Open={props.OpenPassword}
                Cancel={props.OnClose}
                ButtonName='Update'
                OnSubmit={props.HandleOnUpdate}
                Title={'Change Password'}
                Content={<DialogChangePassword UserName={props.UserName} FormFields={props.FormFields} Errors={props.Errors} HandleOnChange={props.HandleOnChange} />}
            />
            <DialogBoxMedium
                Open={props.OpenEmailEdit}
                Cancel={props.OnClose}
                ButtonName='Update'
                OnSubmit={props.HandleEmailSubmit}
                Title={props.EmailType === "email_own_action" ? "Receive email for own actions" : props.EmailType === "secondary_email_notification" ? "Receive secondary email notifications" : "Email Notification"}
                Content={<DialogEmailSettings FormFields={props.FormFields} HandleOnChange={props.HandleOnChange} />}
            />

            <CustomizedSnackbars
                open={props.snackbarOpen}
                variant={props.valid ? 'success' : 'error'}
                message={props.valid ? props.success_message : props.error_message}
                handleClose={props.SnackbarClose}
            />
        </StaffViewStyle>

    )
};
const DialogChangePassword = (props) => {
    return (
        <div class="chp-form">
            {
                <>
                    <form className="form-fullwidth" >
                        <Grid container spacing={1}>
                            <Grid item xs={9}>&nbsp;&nbsp;&nbsp;
                                <Typography> Username :  {props.UserName} </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="old_pswd"
                                        name="old_pswd"
                                        label="Old Password"
                                        type="password"
                                        required
                                        value={props.FormFields.old_pswd}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.old_pswd && true}
                                        helperText={props.Errors.old_pswd}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="new_pswd"
                                        name="new_pswd"
                                        label="New Password"
                                        type="password"
                                        required
                                        value={props.FormFields.new_pswd}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.new_pswd && true}
                                        helperText={props.Errors.new_pswd}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="confm_pswd"
                                        name="confm_pswd"
                                        label="Confirm Password"
                                        type="password"
                                        required
                                        value={props.FormFields.confm_pswd}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.confm_pswd && true}
                                        helperText={props.Errors.confm_pswd}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </>
            }
        </div>
    )
}
const DialogEmailSettings = (props) => {
    return (
        <div class="chp-form">
            {
                <>
                    <form className="form-fullwidth" >
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard">
                                    <Select
                                        value={_R.type(props.FormFields) !== 'Undefined' && props.FormFields.email_action}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'email_action',
                                            id: 'email_action',
                                        }}>
                                        <MenuItem value={'1'} key={'type-1'}>Yes</MenuItem>
                                        <MenuItem value={'0'} key={'type-0'}>No</MenuItem>
                                    </Select>
                                </ FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </>
            }
        </div>
    )
}

