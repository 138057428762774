import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import Drawer from '@mui/material/Drawer';
import * as _R from 'ramda';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterList from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';



//Filters
const SideList = (p) => {
    let years = (_R.type(p.FilterInputs.years) !== 'Undefined' && p.FilterInputs.years.length > 0) ? p.FilterInputs.years : [];
    let months = ( _R.type(p.FilterInputs.months) !== 'Undefined' && Object.keys(p.FilterInputs.months).length > 0) ? Object.values(p.FilterInputs.months) : [];
    let timesheetStatus = (_R.type(p.FilterInputs.status) !== 'Undefined' && p.FilterInputs.status.length > 0) ? p.FilterInputs.status : [];
        
    return (
        <div role="presentation" className={'drawerList'}>
            <Typography className={'filter-title'} component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
            <FormControl className={'drawerFormControl'} variant='standard'>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                    value={p.FilterFields.status}
                    inputProps={{
                        name: 'status',
                        id: 'status',
                    }}
                    onChange = {p.HandleFilterChange}
                >
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="-1">To be Submitted</MenuItem>
                {timesheetStatus.map((tstatus, i) =>
                    <MenuItem value={tstatus.timesheet_status_id} key={'status'+i}>{tstatus.timesheet_status_name}</MenuItem>
                )}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl className={'drawerFormControl'} variant='standard'>
                        <InputLabel htmlFor="year">Year</InputLabel>
                        <Select
                            value={p.FilterFields.year}
                            inputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value="0">All</MenuItem>
                        {years.map((year, i) =>
                            <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className={'drawerFormControl'} variant='standard'>
                        <InputLabel htmlFor="month">Month</InputLabel>
                        <Select
                            value={p.FilterFields.month}
                            inputProps={{
                                name: 'month',
                                id: 'month',
                            }}
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value="0">All</MenuItem>
                        {months.map((month, i) =>
                            <MenuItem value={++i} key={'month'+i}>{month}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={p.HandleFilter} className={'filterSubmitButton'}>Filter</Button>
            <Button variant="outlined" color="primary" className={'ml-1 filterSubmitButton'} onClick={p.ResetFilter} >Reset</Button>
        </div>
    );
};
function sort(current, next) {
    if (current < next) return -1;
    if (current > next) return 1;
    return 0;
}
export const SubmitListComponent = (p) => {
    const columns = [
        {
            title: 'Pay Period',
            field: 'pay_peirod',
            render: rowData => <>{rowData.payperiod_sdate+' - '+rowData.payperiod_edate} </>,
            cellStyle: {
                color: '#0062ad'
            },
            customSort:(a,b) => sort(new Date(a.payperiod_sdate),new Date(b.payperiod_sdate))
        },
        { title: 'Total Hours', field: 'hours',  customSort:(a,b) => sort(parseFloat(a.hours), parseFloat(b.hours))},
        { title: 'Status', field: 'status_name' },
        { title: 'Last Entry', field: 'last_entry' },
    ];

    let dataArr = (typeof p.list !== 'undefined' && p.list.length > 0) ?  p.list : [];

    return (
         <>
            <CustomMaterialTable
                title= "Submit Timesheet"
                columns={columns}
                data={dataArr}
                options={{
                    search:false,
                    actionsColumnIndex: 0,
                    pageSize:10,
                    pageSizeOptions: [10,20,30],
                    thirdSortClick: false 
                }}
                components={{
                    Toolbar: (props) => (
                        <div className={'mTable-toolbar-custom'}>
                            <CustomMTableToolbar {...props} />
                            <Tooltip title="Filter" aria-label="Filter">
                                <IconButton onClick={p.ToggleDrawer('right', true)}><FilterList /></IconButton>
                            </Tooltip>
                        </div>
                    )
                }}
                onRowClick={(event, rowData) => p.HandleSubmitPage(rowData.id)}
            />
            <Drawer 
                anchor="right" 
                open={p.right} 
                onOpen={p.ToggleDrawer('right', true)}
                onClose={p.ToggleDrawer('right', false)}>
                <SideList 
                    Right={'right'} 
                    FilterInputs = {p.filterInputs}
                    FilterFields={p.filterFields} 
                    HandleFilterChange={p.HandleFilterChange} 
                    HandleFilter={p.HandleFilter} 
                    ResetFilter={p.ResetFilter} 
                />
            </Drawer> 
        </>
    )
};
