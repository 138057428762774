import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { formatName } from '../../../common/common';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { CustomDatePicker } from '../../../common/CustomDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label" htmlFor="timeoff_type_id" required  error={props.Errors.timeoff_type_id && true}>Timeoff Type</InputLabel>
                            <Select
                                value={props.FormFields.timeoff_type_id}
                                onChange={props.HandleOnChange}
                                disabled={props.IsEdit}
                                error={props.Errors.timeoff_type_id && true}
                                displayEmpty
                                inputProps={{
                                    name: 'timeoff_type_id',
                                    id: 'timeoff_type_id',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                {typeof(props.FormInputs) !== 'undefined' && props.FormInputs.timeoff_types.map((timeoff, i) =>
                                    <MenuItem value={timeoff.id} key={'timeoff-'+i}>{timeoff.to_type}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.timeoff_type_id}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label" htmlFor="config" required  error={props.Errors.config && true}>
                            Config
                            </InputLabel>
                            <Select
                                disabled={props.IsEdit}
                                value={props.FormFields.config}
                                onChange={props.HandleOnChange}
                                error={props.Errors.config && true}
                                displayEmpty
                                inputProps={{
                                    name: 'config',
                                    id: 'config',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                {typeof(props.FormInputs.config) !== 'undefined' && props.FormInputs.config.map((config, i) =>
                                    <MenuItem value={config} key={'config-'+i}>{config}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.config}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <label for="html">Description</label>
                            <TextareaAutosize
                                disabled
                                className={'input-bg w100'}
                                onChange={props.HandleOnChange}
                                name={'description'}
                                value={props.FormFields.description}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description} />
                        </ FormControl>
                    </Grid>
                    {(props.FormFields.config == 'experience_override_date') ?
                    <Grid item xs={12} md={6}>
                                <FormControl>
                                    <CustomDatePicker
                                        id = "value"
                                        name = "value"
                                        selectedDate={props.FormFields.value}
                                        handleDateChange={props.HandleDateChange}
                                        required={true}
                                        label={'Date'}
                                    />
                                </ FormControl>
                                     <small className='error'>{props.Errors.value}</small>
                            </Grid>
                        :
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="value"
                                name="value"
                                label="Value"
                                type="text"
                                fullWidth
                                required
                                onChange={props.HandleOnChange}
                                value={props.FormFields.value}
                                error={props.Errors.value && true}
                                helperText={props.Errors.value}
                            />
                        </ FormControl>
                    </Grid>
                    }
                </Grid>
            </form>
        )
};
export const TimeoffConfigOverrideComponent = (props) => {
    const { Edit, Delete } = props;
    const columns = [
        { title: 'Timeoff type', field: 'timeoff_type_name' },
        { title: 'Config', field: 'config'},
        { title: 'Value', field: 'value' },
        { title: 'Created by', field: 'created_by', render: rowData => <>{formatName(rowData.cby_first_name,null,rowData.cby_last_name)}</> },
        { title: 'Created on', field: 'created_on' },
        { title: 'Modified by', field: 'modified_by' , render: rowData => <>{formatName(rowData.mby_first_name,null,rowData.mby_last_name)}</>},
        { title: 'Modified on', field: 'modified_on' },
    ];
    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Config Override"
                    columns={columns}
                    data={props.TimeoffPolicy}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging:false,
                    }}
                    actions={[
                        {   
                            icon: 'add',
                            tooltip: 'Add Timeoff Config Override',
                            isFreeAction: true,
                            onClick: (event, newData) => { 
                                props.OnAdd();
                            },
                            hidden: (Edit) ? false : true,
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                props.HandleUpdate(rowData.id);
                            },
                            hidden: (Edit) ? false : true,
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete',
                            onClick: (event, rowData) => {
                                var c = window.confirm("Do you want to delete time off config override?");
                                if (c) {
                                    props.OnDelete(rowData.id);
                                }
                            },
                            hidden: (Delete) ? false : true,
                        },

                    ]}
                />
            </div>
            <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {props.IsEdit ? 'Edit Timeoff Config Override' : 'Add Timeoff Config Override'}
                    OnSubmit={props.SaveTimeoffPolicy}
                    Content={<DialogComponent FormProps={props}/>}/>
                <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    );
}
