import React from 'react';
import {httpServices}  from '../../../services/httpServices';
import { TimeInOutComponent } from './TimeInOutComponent';

export class TimeInOut  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit  : false,
            time_inout_rows : [],
            selectedDate : null,
            wlc : false,
            wlc_total : [],
            deleted_ids : [],
            total_hours : 0,
            error : '',
            user_time_inout : {},
            locations : [],
            default_location : '',
            location : '',
            timesheet_location : 1
        }
        this.save = this.save.bind(this);
        this.addRow = this.addRow.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleWlChange = this.handleWlChange.bind(this);
        this.delete_inout = this.delete_inout.bind(this);
    };
    componentDidMount = () =>{
       this.getIndex(); 
    };

    getIndex = () => {
        let isUpdate = false;
        let selected_date = this.props.SelectedDate;
        let _this = this;
        return httpServices.post('api/timesheet/timeinandout', {date : selected_date,action:'get'})
            .then(response => {
                if (response.data) {
                    let wlc = false;
                    let locations = [];
                    let location = '';
                    locations = response.data.wlc.locations;
                    let location_ids = locations.map(item => item['location_id']);
                    if (response.data.wlc.request) {
                        wlc = true;
                        if (locations.length == 2) {
                            location = location_ids.find(element => element != response.data.default_location); 
                        }                     
                    }
                    let time_inout_rows = [];
                    let temp_date_arr = selected_date.split("-");
                    let yy = parseInt(temp_date_arr[0]);
                    let mm = parseInt(temp_date_arr[1]) - 1;
                    let dd = parseInt(temp_date_arr[2]);
                    let default_location_id = (locations.length > 1) ? 0 : response.data.default_location;
                    if (response.data.timein_rows && response.data.timein_rows.length > 0) {
                        let temp_timein_out_rows = response.data.timein_rows;
                        temp_timein_out_rows.forEach(function(item,idx){
                            if (item.time_in) {
                                let t_in = item.time_in;
                                let t_in_arr = t_in.split(':');
                                item.time_in_ampm = item.time_in;
                                item.time_in = new Date(yy,mm,dd,t_in_arr[0],t_in_arr[1]);
                            }
                            if (item.time_out) {
                                let t_out = item.time_out;
                                let t_out_arr = t_out.split(':');
                                item.time_out_ampm = item.time_out;
                                item.time_out = new Date(yy,mm,dd,t_out_arr[0],t_out_arr[1]);
                            }
                            if (item.time_in && item.time_out) {
                                let diff_hours = _this.calculateHours(item.time_in_ampm,item.time_out_ampm);
                                item.hours = diff_hours;
                            }
                            if (typeof item.location === 'undefined') {
                                item.location = location;
                            }
                            if (item.location == null) {
                                item.location = 0;
                            }
                        });
                        if (temp_timein_out_rows && temp_timein_out_rows.length > 0) {
                             isUpdate = true;
                            time_inout_rows = temp_timein_out_rows;
                        }
                    } else {
                        let rand = 'temp'+ Math.floor(1000 + Math.random() * 9000);
                        let time_in_ampm=null,time_in=null,time_out_ampm=null,time_out=null;
                        if(response.data.user_timeinout){
                            if(response.data.user_timeinout.time_in){ 
                                let t_in = response.data.user_timeinout.time_in;
                                let t_in_arr = t_in.split(':');
                                time_in_ampm = t_in;
                                time_in = new Date(yy,mm,dd,t_in_arr[0],t_in_arr[1]);
                            }
                            if(response.data.user_timeinout.time_out){
                                let t_out = response.data.user_timeinout.time_out;
                                let t_out_arr = t_out.split(':');
                                time_out_ampm =  t_out ;
                                time_out = new Date(yy,mm,dd,t_out_arr[0],t_out_arr[1]);
                            }
                        }
                        let time_inout_obj = {
                            id : 0,
                            temp_id : rand,
                            wlc : 0,
                            time_in_ampm : time_in_ampm,
                            time_out_ampm : time_out_ampm,
                            time_in : time_in,
                            time_out : time_out,
                            hours : '',
                            date : this.props.SelectedDate,
                            locations : locations,
                            location : (location !== '') ? location : default_location_id,
                            default_location : response.data.default_location
                        };
                        time_inout_rows.push (time_inout_obj);
                    }
                    this.calculateGtotal(time_inout_rows);
                    this.setState({
                        time_inout_rows : time_inout_rows,
                        selectedDate : response.data.formatted_date,
                        isEdit : isUpdate,
                        wlc : wlc,
                        user_time_inout : response.data.user_timeinout,
                        error : '',
                        deleted_ids : [],
                        locations : locations,
                        location : (location !== '') ? location : default_location_id,
                        default_location : response.data.default_location,
                        timesheet_location : response.data.timesheet_location
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    save = () => {
        let valid = this.validateForm();
        if (valid.status) {
            let data = this.state.time_inout_rows;
            let selected_date = this.props.SelectedDate;
            let wlc_hours = valid.wlc_total;
            let deleted_ids = this.state.deleted_ids;
            let isEditTimeinout = this.state.isEdit;
            return httpServices.post('api/timesheet/timeinandout', {date : selected_date,action:'save',inout_data : JSON.stringify(data), wlc_hours : wlc_hours,deleted_ids : JSON.stringify(deleted_ids)})
                .then(response => {
                    if (response.data) {
                       if(response.data.status) {
                            this.props.Cancel();
                            let selectedLocation = '';
                            response.data.timein_rows.forEach(function (item, index) {
                                selectedLocation = ((selectedLocation == item['location']) || (index == 0)) ? item['location'] : '';
                            });
                            let addLunchBreak = response.data.show_lunch_break;
                            this.props.TimeInOutSaveSuccess(selectedLocation, addLunchBreak, isEditTimeinout);
                       } else {
                           if (response.data.error_msg) {
                               this.setState({
                                   error : response.data.error_msg,
                               });
                           }
                           if (response.data.valid_in_out_records && response.data.valid_in_out_records.length) {
                               this.setState({
                                   time_inout_rows : response.data.valid_in_out_records,
                               });
                               this.calculateGtotal(response.data.valid_in_out_records);
                               this.props.TimeInOutSaveSuccess();
                           }
                       }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };
    addRow = () => {
        let rand = 'temp'+ Math.floor(1000 + Math.random() * 9000);
        let time_in_ampm = (this.state.time_inout_rows[this.state.time_inout_rows.length-1] !== 'undefined' && this.state.time_inout_rows[this.state.time_inout_rows.length-1]) ? this.state.time_inout_rows[this.state.time_inout_rows.length-1]['time_out_ampm'] : null;
        let time_in= (this.state.time_inout_rows[this.state.time_inout_rows.length-1] !== 'undefined' && this.state.time_inout_rows[this.state.time_inout_rows.length-1]) ? this.state.time_inout_rows[this.state.time_inout_rows.length-1]['time_out'] : null;
        let time_out_ampm = null;
        let time_out = null;
        let user_timeinout = this.state.user_time_inout;
        if(user_timeinout.time_out){
            let temp_date_arr = this.props.SelectedDate.split("-");
            let yy = parseInt(temp_date_arr[0]);
            let mm = parseInt(temp_date_arr[1]) - 1;
            let dd = parseInt(temp_date_arr[2]);
            time_out_ampm =  user_timeinout.time_out;
            let t_out_arr = time_out_ampm.split(':');
            time_out = new Date(yy,mm,dd,t_out_arr[0],t_out_arr[1]);
        }
        let time_inout_obj = {
            id : 0,
            temp_id : rand,
            wlc : 0,
            time_in_ampm : time_in_ampm,
            time_out_ampm : time_out_ampm,
            time_in : time_in,
            time_out : time_out,
            hours : '',
            date : this.props.SelectedDate,
            location : this.state.location
        };

        let time_inout_rows = this.state.time_inout_rows;
        time_inout_rows.push (time_inout_obj);
        this.setState({
            time_inout_rows : time_inout_rows,
        });
    };
    handleTimeChange = (id) => (time) => {
        if (id && time) { // Unique id of the picker
            let id_arr = id.split('_');
            let index = id_arr[0];
            let picker_type = id_arr[1]; // timein or timeout
            let hh = time.getHours();
            let mm = time.getMinutes();
            let ss = '00';
            let am_pm = hh+':'+mm+':'+ss;
            let time_in_out_rows = this.state.time_inout_rows;
            let current_obj = time_in_out_rows[index];
            let diff_hours = 0;
            if (picker_type === 'timein') {
                current_obj.time_in = time;
                current_obj.time_in_ampm = am_pm; 
                diff_hours = this.calculateHours(am_pm,current_obj.time_out_ampm);
            } else if (picker_type === 'timeout') {
                current_obj.time_out = time;
                current_obj.time_out_ampm = am_pm;
                diff_hours = this.calculateHours(current_obj.time_in_ampm,am_pm);
            }
            current_obj.hours = diff_hours;
            time_in_out_rows[index] = current_obj;
            this.calculateGtotal(time_in_out_rows);
            this.setState({
                time_inout_rows : time_in_out_rows,
            });
        }
    }
    handleWlChange = (row_index) => (ev) => {
        let time_in_out_rows = this.state.time_inout_rows;
        let current_time_in_obj = time_in_out_rows[row_index];
        current_time_in_obj.location = ev.target.value ? ev.target.value : this.state.location;
        time_in_out_rows[row_index] = current_time_in_obj;
        this.setState({
            time_inout_rows : time_in_out_rows,
        });
    }
    validateForm = () => {
        let is_valid = true;
        let wlc_total = [];
        let time_inout_rows = this.state.time_inout_rows;
        let default_location = this.state.default_location;
        let timesheet_location = this.state.timesheet_location;
        let _this = this;
        let error = '';
        let re = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
        time_inout_rows.forEach(function(tr){
                if (tr.time_in_ampm) {
                    if (!(re.test(tr.time_in_ampm))) {
                        is_valid = false;
                        error = 'Invalid time format';
                    }    
                               
                } else {
                    is_valid = false;
                }
                if (tr.time_out_ampm) {
                    if (!(re.test(tr.time_out_ampm))) {
                        is_valid = false;
                        error = 'Invalid time format';
                    }  
                   
                } else {
                    is_valid = false;
                }
                if (tr.time_in_ampm && tr.time_out_ampm) {
                    let diff_hour = _this.calculateHours(tr.time_in_ampm,tr.time_out_ampm);   
                    if (diff_hour < 0) {
                        is_valid = false;
                        error = 'Invalid Start and End time';
                    }
                }
                if (parseInt(tr.location) != default_location && tr.hours) {
                    if (typeof wlc_total[tr.location] !== 'undefined') {
                        wlc_total[tr.location] = parseFloat(wlc_total[tr.location]) + parseFloat(tr.hours);
                    } else {
                        wlc_total[tr.location] = parseFloat(tr.hours);
                    }
                }
                // if (timesheet_location && (tr.location == null || tr.location == 0)) {
                //     is_valid = false;
                //     error = 'Please Select Location';
                // }
            });
        if (wlc_total.length) {
            this.setState({wlc_total : wlc_total});
        }
        if(!is_valid) {
            if (error === '') {
                error = "Please fill all the required fields";
            } 
            this.setState({error : error});
        }
        let ret_obj = {status:is_valid,wlc_total:wlc_total};
        return ret_obj;
    }
    isTime = (time) => {
        return time.match(/((?:(?:[0-1][0-9])|(?:[2][0-3])|(?:[0-9])):(?:[0-5][0-9])(?::[0-5][0-9])?(?:\\s?(?:am|AM|pm|PM))?)/);
    };
    calculateHours = (in_time,out_time) => {
        let hours = 0;
        if (in_time && out_time) {
            hours = this.diffHours(in_time,out_time);
            if (hours) {
                return hours.toFixed(2);
            }
        }
    };
    diffHours = (in_time,out_time) => {
        let in_time_arr = in_time.split(":");
        let out_time_arr = out_time.split(":");
        let mins = (out_time_arr[0]*3600+out_time_arr[1]*60)-(in_time_arr[0]*3600+in_time_arr[1]*60);
        let diff_hours = mins/3600;
        return diff_hours; 
    };
    delete_inout = (id,row_index) => {
        let time_inout_rows = this.state.time_inout_rows;
        time_inout_rows.splice(row_index,1);
        let deleted_ids = this.state.deleted_ids;
        if (id > 0) {
            deleted_ids.push(id);
        }
        this.calculateGtotal(time_inout_rows);
        this.setState({
            deleted_ids : deleted_ids,
            time_inout_rows : time_inout_rows,
        });
    };
    calculateGtotal = (time_in_out_rows) => {
        let temp_time_inout_records = time_in_out_rows;
        let sum = 0;
        temp_time_inout_records.forEach(function(tr){
            if (tr.hours) {
                let hour = parseFloat(tr.hours);
                sum = sum + hour;
            }
        });
        sum = sum.toFixed(2);
        this.setState({
            total_hours : sum,
        });
    }
    render(){
        return(
            <TimeInOutComponent
                TimeInoutRows = {this.state.time_inout_rows}
                Open = {this.props.Open}
                Cancel = {this.props.Cancel}
                Save = {this.save}
                SDate = {this.state.selectedDate}
                AddRow = {this.addRow}
                HandleTimeChange = {this.handleTimeChange}
                Wlc = {this.state.wlc}
                HandleWlChange = {this.handleWlChange}
                Delete = {this.delete_inout}
                TotalHours = {this.state.total_hours}
                Error = {this.state.error}
                Locations = {this.state.locations}
                SelectedLocation = {this.state.location}
                TimesheetLocation = {this.state.timesheet_location}
            />
        )
    }
};

