import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import * as _R from 'ramda';
import { DialogBoxMedium } from '../../common/dialog.medium';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { CustomizedSnackbars } from '../../common/snackbars';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';


export const PtoConfigComponent = (props) => {
    const { Config: dataArr } = props;
    let table_size = 10; // Default
    if (dataArr) {
        if (Array.isArray(dataArr)) {
            let data_arr_length = dataArr.length;
            if (data_arr_length < 10) {
                table_size = data_arr_length < 3 ? 2 : data_arr_length;
            }
        }
    }
    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Config"
                    columns={[
                        {
                            title: 'Config', field: 'config', render: rowData => rowData.override_id ? <span>{rowData.config}
                                <IconButton>
                                    <Tooltip title="Yearly time off config exist">
                                        <InfoOutlinedIcon />
                                    </Tooltip>
                                </IconButton>
                            </span> : <span>{rowData.config}</span>
                        },
                        {
                            title: 'Value',
                            field: 'value',
                        },
                        {
                            title: 'Yearly override',
                            field: 'yearly_override',
                            render: rowData => rowData.yearly_override == 1 ? "Yes" : "No"
                        },
                        {
                            title: 'Staff level override',
                            field: 'staff_override',
                            render: rowData => rowData.staff_override == 1 ? "Yes" : "No"
                        },
                        {
                            title: 'Description',
                            field: 'description',
                        },
                    ]}
                    data={props.Config}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: false,
                        sorting: false,
                        showTitle: false,
                        toolbar: false,
                        headerStyle: { position: 'sticky', top: 0 },
                        maxBodyHeight: '550px'
                    }}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit config',
                            hidden: !props.EditPermission,
                            onClick: (event, rowData) => {
                                props.EditConfigClick(rowData.id);
                            },
                        },
                       rowData =>({
                            icon: 'add',
                            tooltip: 'Add yearly override',
                            hidden: (!props.EditPermission || rowData.config == "upper_limit" || (rowData.yearly_override == 0)),
                            onClick: (event, rowData) => {
                                props.AddPConfigOverrideClick(rowData.id);
                            },
                        }),
                    ]}
                    detailPanel={[
                        {
                            render: ({ rowData }) => {
                                if (rowData.override_id) {
                                    return (
                                        rowData.childs.map((r, i) =>
                                            <div class="staff_tabel-detail-panel">
                                                <div class="staff_tabel-detail-item"><label class="small-case">Year from</label><span class="value">{r.from_year}</span></div>
                                                <div class="staff_tabel-detail-item"><label class="small-case">Year to</label><span class="value">{r.to_year}</span></div>
                                                <div class="staff_tabel-detail-item"><label class="small-case">Value</label><span class="value">{r.override_value}</span></div>
                                                <div class="staff_tabel-detail-item"><label class="small-case">Description</label><span class="value">{r.override_description}</span></div>
                                                <div class="staff_tabel-detail-item">
                                                    <span class="value">
                                                        <IconButton aria-label="edit">
                                                            <EditIcon onClick={() => props.EditOverrideClick(r.override_id)} />
                                                        </IconButton>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            }
                        }
                    ]}
                />
            </div>
            {(props.PopupType === 'edit') &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={'Edit  Config'}
                    OnSubmit={props.OnSubmit}
                    Content={<DialogEdit FormProps={props} />}
                    Loading={props.ButtonLoader}
                />
            }
            {(props.PopupType === 'add' || props.PopupType === 'editoverride') &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={props.PopupType === 'add' ? 'Add Yearly Override' : 'Edit Override'}
                    OnSubmit={props.OnAdd}
                    Content={<DialogAdd FormProps={props} />}
                    Loading={props.ButtonLoader}
                />
            }
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarVariant}
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            }
        </React.Fragment>
    )
};
const DialogEdit = (props) => {
    const { FormFields, HandleOnChange, Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            disabled
                            id="config"
                            name="config"
                            label="Config"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={FormFields.config !== 'undefined' ? FormFields.config : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.config !== 'undefined' && Errors.config) && true}
                            helperText={typeof Errors.config !== 'undefined' && Errors.config}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="value"
                            name="value"
                            label="Value"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.value !== 'undefined' ? FormFields.value : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.value !== 'undefined' && Errors.value) && true}
                            helperText={typeof Errors.value !== 'undefined' && Errors.value}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel>Yearly Override</InputLabel>
                        <Select
                            value={parseInt(FormFields.yearly_override)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'yearly_override',
                                id: 'yearly_override'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='0'>No</MenuItem>
                            <MenuItem value='1'>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel>Staff Level Override</InputLabel>
                        <Select
                            value={parseInt(FormFields.staff_override)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'staff_override',
                                id: 'staff_override'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='0'>No</MenuItem>
                            <MenuItem value='1'>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl >
                        <TextField variant="standard"
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.description !== 'undefined' ? FormFields.description : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.description !== 'undefined' && Errors.description) && true}
                            helperText={typeof Errors.description !== 'undefined' && Errors.description}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogAdd = (props) => {
    const { FormFields, HandleOnChange, Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            disabled
                            id="config"
                            name="config"
                            label="Config"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={FormFields.config !== 'undefined' ? FormFields.config : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.config !== 'undefined' && Errors.config) && true}
                            helperText={typeof Errors.config !== 'undefined' && Errors.config}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="from_year"
                            name="from_year"
                            label="Year from"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.from_year !== 'undefined' ? FormFields.from_year : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.from_year !== 'undefined' && Errors.from_year) && true}
                            helperText={typeof Errors.from_year !== 'undefined' && Errors.from_year}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="to_year"
                            name="to_year"
                            label="Year to"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.to_year !== 'undefined' ? FormFields.to_year : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.to_year !== 'undefined' && Errors.to_year) && true}
                            helperText={typeof Errors.to_year !== 'undefined' && Errors.to_year}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="value"
                            name="value"
                            label="Value"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.value !== 'undefined' ? FormFields.value : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.value !== 'undefined' && Errors.value) && true}
                            helperText={typeof Errors.value !== 'undefined' && Errors.value}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard" 
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={FormFields.description !== 'undefined' ? FormFields.description : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.description !== 'undefined' && Errors.description) && true}
                            helperText={typeof Errors.description !== 'undefined' && Errors.description}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
