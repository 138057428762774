import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CKEditor } from 'ckeditor4-react';
import { DialogBoxLarge } from '../common/dialog.large';
import { CustomProgressBar } from '../common/CustomProgressBar';
import Checkbox from '@mui/material/Checkbox';

export const AddAnnouncementsComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxLarge
                Open={props.AddDialogOpen}
                Cancel={props.HandleAddDialogue}
                OnSubmit={props.HandleSubmit}
                Disabled={props.DisableSubmit}
                Title={(props.FormFields.id != undefined && props.FormFields.id > 0) ? 'Edit Announcement' : 'Add Announcement'}
                HideSaveButton={false}
                Content={
                    <AnnouncementsAddComponent FormProps={props} />
                }
            />
        </React.Fragment>
    )
}
const AnnouncementsAddComponent = (props) => { 
    const FormProps = props.FormProps;
    return (
        <React.Fragment>
            <CustomProgressBar
                loaderOpen={FormProps.ShowLoader}
            />
            <div className={'p1'}>
                <div>
                    <form onSubmit={FormProps.HandleSubmit} encType="multipart/form-data">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <TextField 
                                    id="announcement_title"
                                    name="announcement_title"
                                    label="Title"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    onChange={FormProps.HandleOnChange}
                                    value={FormProps.FormFields.announcement_title}
                                />
                                <small className='error'>{typeof FormProps.Errors !== 'undefined' && FormProps.Errors.announcement_title && FormProps.Errors.announcement_title}</small>
                            </Grid>
                            <Grid item xs={12} md={12}>
	                         <CKEditor
                                    initData={(FormProps.FormFields.message && FormProps.FormFields.message !='') ? FormProps.FormFields.message : ''}
                                    name="message_html"
                                    debug={true}
                                    config={{
                                        toolbar: [
                                            [ 'Bold', 'Italic'],
                                            [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ],
                                            [ 'Undo', 'Redo' ],
                                            [ 'Source' ]
                                        ]                                   
                                    }}
                                    style={
                                        [border=> '2px solid red']
                                    }
                                    onChange={FormProps.HandleOnHtmlChange}
                                />
                                <small className='error'>{typeof FormProps.Errors !== 'undefined' && FormProps.Errors.message && FormProps.Errors.message}</small>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputLabel htmlFor="announcement_status" >Status</InputLabel>
                                <FormControl variant="standard">
                                    <Select
                                        value={FormProps.FormFields.announcement_status}
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: 'announcement_status',
                                            id: 'announcement_status',
                                        }}>
                                        <MenuItem value={1}>Draft</MenuItem>
                                        <MenuItem value={2}>Published</MenuItem>
                                        <MenuItem value={3}>Archived</MenuItem>
                                    </Select>
                                    <small className='error'>{typeof FormProps.Errors !== 'undefined' && FormProps.Errors.announcement_status && FormProps.Errors.announcement_status}</small>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputLabel htmlFor="target_users" >Target Users</InputLabel>
                                <FormControl>
                                    <Select
                                        value={FormProps.FormFields.target_users}
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: 'target_users',
                                            id: 'target_users',
                                        }}>
                                        <MenuItem value={1}>-- All --</MenuItem>
                                        <MenuItem value={2}>PM</MenuItem>
                                        <MenuItem value={3}>Reviewers</MenuItem>
                                    </Select>
                                    <small className='error'>{typeof FormProps.Errors !== 'undefined' && FormProps.Errors.target_users && FormProps.Errors.target_users}</small>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputLabel htmlFor="email_notification" >Send email notification</InputLabel>
                                <FormControl>
                                <Checkbox
                                    inputProps={{
                                        name: 'email_notification',
                                        id: 'email_notification',
                                    }}
                                    onChange={FormProps.HandleOnChange}
                                    value={FormProps.FormFields.email_notification}
                                    checked={FormProps.FormFields.email_notification ? FormProps.FormFields.email_notification : false}
                                    color="primary"
                                />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} container justify="flex-end">
                                <inputType
                                    id={'id'}
                                    name={'id'}
                                    placeholder="Id"
                                    type="hidden"
                                    className={'te-input'}
                                    value={FormProps.FormFields.id}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
