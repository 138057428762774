import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { ViewOvertimeComponent } from './ViewOvertimeDetails.Component';
import { OvertimeComponent} from './Overtime.Component';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const formFields = {
    'comments': null,
}

export class ViewOvertimeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            formFields:formFields,
            id:0,
            requestData:[],
            open :false,
            isReviewer: false,
            commentBox : false,
            commentTitle :null,
            dialogMessage : null,
            request_id :0,
            action :null,
            snackBarOpen:false,
            snackBarType:'success',
            snackBarMessage:null,
            errors:{},
            commentsHistory :{},
            changeLog:{},
            emailRecipients:{},
            approvers: {},
            viewTokenButton :false,
            editPopup : false,
            custom_recipients:[],
            doj : null,
            permission : false,
            recipients : {},
            allRequestView : false,
            loader : true,
            dataLoaded : false
        }
        this.handleOnNewRequest = this.handleOnNewRequest.bind(this);
        this.handleOnAction = this.handleOnAction.bind(this);
        this.reviewRequest = this.reviewRequest.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackBarClose = this.snackBarClose.bind(this);
        this.updateParentOnEditSuccess = this.updateParentOnEditSuccess.bind(this); 
    };
    componentDidMount()
    {
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.viewRequestData(id);
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
    };
        
    viewRequestData = (id) => {
        this.setState({loader : true});
        if(id) {
            let requestId = id;
            return httpServices.post('api/overtime/view',{'requestId': requestId})
                .then(response => {
                    if (response.data.permission) {
                        this.setState({requestData : response.data.requestData,
                            isReviewer : response.data.isReviewer,
                            commentsHistory : response.data.comments,
                            changeLog : response.data.history, 
                            emailRecipients: response.data.emailRecipients,
                            approvers : response.data.approvers,
                            logged_user : response.data.logged_user,
                            viewTokenButton : response.data.reviewer_token_generate,
                            custom_recipients : response.data.custom_recipients,
                            doj : response.data.doj,
                            permission : response.data.permission,
                            recipients : response.data.recipients,
                            allRequestView : response.data.allrequest_view,
                            loader : false,
                            dataLoaded : true
                        });
                    }
                    else {
                        this.setState({
                            permission : response.data.permission,
                            loader : false,
                            dataLoaded : true
                        });
                    }
                })
        }
    };
    
    handleOnNewRequest = () => {
        this.setState({open:true});
    }
    
    handleOnAction = (id, action) => {
        if (action === 'Edit') {
             this.setState({ editPopup: true, 
                            action : action,
                            request_id : id,
             });
        }
        else  if (action === 'Send Review Token') {
            this.reviewRequest(id,action);
        }
        else {
            this.setState({commentBox : true, 
                commentTitle : (action === 'Add Comment' ? 'Add Comments' : action+' Request'), 
                dialogMessage : (action === 'Add Comment' ? '' : 'Do you want to '+action+' request(s)?'), 
                request_id : id,
                action : action,
            });
        }
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.comments || typeof this.state.formFields.comments === 'undefind' || this.state.formFields.comments.trim() === '') {
            errors["comments"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    reviewRequest = (id,action) => {
        this.setState({ loader:true });
        let valid = true;
        if (id && action) {
            if(action === 'Reject' || action === 'unapprove' || action === 'Add Comment') {
                if(!this.handleValidation()) {
                   valid =false; 
                }
            }
            if (valid) {
                let comments = this.state.formFields.comments;
                return httpServices.post('api/overtime/review',{'action' : action, 'requestId': id, 'comments' : comments})
                .then(response => {
                    if (response.data.success) {
                        this.viewRequestData(id);
                        this.setState({commentBox:false,
                            loader:false,
                            snackBarOpen:true,
                            snackBarMessage:response.data.message
                        });
                        this.clearForm();
                    } else {
                        this.setState({
                            errors : response.data.errors ? response.data.errors : {},
                            loader:false,
                        });
                    }
                })
            }
        }
    }

    onClose = () =>{
        this.setState({
            commentBox: false,
            loader : false
        });
        this.clearForm();
    }

    snackBarClose = () => {
        this.setState({
            snackBarOpen : false,
        });
    }

    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    
    updateParentOnEditSuccess = (valid, attachmentMsg = '') => {
        if(valid) {
            let msg = 'Request Updated Successfully';
            if (attachmentMsg != '') {
                msg = msg+". "+attachmentMsg;
            }
        this.setState({
            snackBarOpen : true,
            snackBarMessage : msg,
            snackBarType : 'success',
            editPopup : false,
        }, function () {
            this.viewRequestData(this.state.request_id);
        });
        }
        else {
            this.setState({editPopup : false});
        }
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.loader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.open === false ) ?
                        (this.state.permission) ?
                        <ViewOvertimeComponent
                            RequestData =  {this.state.requestData}
                            HandleOnNewRequest = {this.handleOnNewRequest}
                            IsReviewer = {this.state.isReviewer}
                            HandleOnAction = {this.handleOnAction}
                            CommentBox = {this.state.commentBox}
                            CommentTitle = {this.state.commentTitle}
                            Close={this.onClose.bind(this)}
                            DialogMessage = {this.state.dialogMessage}
                            RequestId = {this.state.request_id}
                            Action = {this.state.action}
                            ReviewRequest = {this.reviewRequest}
                            HandleOnChange = {this.handleOnChange}
                            FormFields ={this.state.formFields}
                            SnackBarOpen ={this.state.snackBarOpen}
                            SnackBarClose ={this.snackBarClose}
                            SnackBarType ={this.state.snackBarType}
                            SnackBarMessage ={this.state.snackBarMessage}
                            Errors ={this.state.errors}
                            CommentsHistory = {this.state.commentsHistory}
                            ChangeLog = {this.state.changeLog}
                            EmailRecipients = {this.state.emailRecipients}
                            Logged_user = {this.state.logged_user}
                            Approvers = {this.state.approvers}
                            ViewTokenButton = {this.state.viewTokenButton}
                            EditPopup = {this.state.editPopup}
                            Custom_recipients = {this.state.custom_recipients}
                            UpdateParentOnEditSuccess  = {this.updateParentOnEditSuccess}
                            Doj = {this.state.doj}
                            Recipients = {this.state.recipients}
                            AllRequestView = {this.state.allRequestView}
                        />
                            : <ErrorPage />
                        :
                        <OvertimeComponent
                            Open = {this.state.open}
                        />
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
