import React, { Component }  from 'react';
import { withStyles } from '@mui/styles';
import {CustomMaterialTable, CustomMTableToolbar} from '../../../common/CustomMaterialTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { StaffConstants} from '../../../../constants/';
import { httpServices } from '../../../../services/httpServices'
import * as _R from 'ramda';
import { formatName } from '../../../common/common';
import { ErrorPage } from '../../../common/error';
import { history } from '../../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const styles = theme => ({
    filterToolbar:{
        display:'flex',
        justifyContent:'space-between'
    },
    inlineFilter:{
        marginBottom:'10px',
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'flex-end',
        color:'#333'
    },
    filterTitle:{
        fontSize:'1.1rem',
        marginRight:'20px'
    },
    formControl:{
        minWidth:'120px',
        margin:0
    }
});
const columns = [
    { title: 'Pay Period', field: 'payperiod', defaultSort: 'desc' },
    { title: 'Min.Hours', field: 'min_hours'},
    { title: 'Saved Hours', field: 'hours' },
    { title: 'Timesheet Status', field: 'status_name' },
    { title: 'Submitted On', field: 'submitted_date', render: rowData => <>{rowData.submitted_date ? rowData.submitted_date : ''}</>},
    { title: 'Approved By', field: 'approved_user', render: rowData => <>{formatName(rowData.aby_first_name,null,rowData.aby_last_name)}</>,
 },
];

// This class component is depends Data property
export class TimesheetDetails extends Component{
    // This is for future purpose
    constructor(props) {
        super(props);
        this.state = {
            year:new Date().getFullYear(),
            selectedData: {}
        };
        this.HandleYearFilterChange = this.HandleYearFilterChange.bind(this);
        this.HandleViewClick = this.HandleViewClick.bind(this);
    }

    HandleYearFilterChange = (evt, value) => {
        const year = evt.target.value;
        const {StaffID, UserID} = this.props;
        this.setState({
            year: year,
        });
        let apiEndpoint = StaffConstants['TIMESHEET'];
        return httpServices.post(apiEndpoint, { 'id': UserID, 'staffID': StaffID, 'module': 'TIMESHEET', 'year':year })
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedData: response.data
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    HandleViewClick = (id) => {
        history.push(BASE_PATH+'/reports/payperiod/' +id);
    }

    render(){
        return(
            <TimesheetComponent 
                TimesheetData={(_R.not(_R.isEmpty(this.state.selectedData)))?this.state.selectedData:this.props.TimesheetData}
                Permission={this.props.Permission}
                YearVal={this.state.year}
                HandleYearFilterChange={this.HandleYearFilterChange}
                StaffID={this.props.StaffID}
                UserID={this.props.UserID}
                HandleViewClick = {this.HandleViewClick}
                />
        )
    }
}


const TimesheetComponent = withStyles(styles)(props => {
    const { classes, TimesheetData, HandleYearFilterChange, YearVal, HandleViewClick, Permission} = props;
    const dataArr = (_R.type(TimesheetData) !== 'Undefined' && _R.type(TimesheetData.details) !== 'Undefined')?TimesheetData.details:[];
    const yearFilter = (_R.type(TimesheetData) !== 'Undefined' && _R.type(TimesheetData.years) !== 'Undefined')?TimesheetData.years:[];
    const columns = [
        { title: 'Pay Period', field: 'payperiod', defaultSort: 'desc' },
        { title: 'Min.Hours', field: 'min_hours'},
        { title: 'Saved Hours', field: 'hours' },
        { title: 'Timesheet Status', field: 'status_name' },
        { title: 'Submitted On', field: 'submitted_date', render: rowData => <>{rowData.submitted_date ? rowData.submitted_date : ''}</>},
        { title: 'Approved By', field: 'approved_user', render: rowData => <>{formatName(rowData.aby_first_name,null,rowData.aby_last_name)}</>,
        },
    ];
    let table_size = 30; // Default
    let data_arr_length = dataArr.length ;
    let year_filter_length = yearFilter.length ;
    if (data_arr_length < 30) {
        table_size = data_arr_length < 3 ? 2 : data_arr_length;
    }
    if (year_filter_length < 30) {
        table_size = year_filter_length < 3 ? 2 : year_filter_length;
    }
    return (
        (Permission) ?
        <>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title= "Timesheet"
                    columns={columns}
                    data={dataArr}
                    actions={[
                        rowData =>({icon: 'visibility', tooltip: 'View',onClick: (event, rowData) => {
                            props.HandleViewClick(rowData.id);
                        }})
                          
                    ]}
                    options={{
                        search:false,
                        actionsColumnIndex: -1,
                        pageSize:30,
                        pageSizeOptions: [30,60,90]
                    }}
                    components={{
                    Toolbar: props => (
                        <div className={classes.filterToolbar}>
                            <CustomMTableToolbar {...props} />
                            <div className={classes.inlineFilter}>
                                <FormControl className={classes.formControl} variant="standard">
                                    <InputLabel htmlFor="age-simple">Year</InputLabel>
                                    <Select
                                        value={YearVal !== 0 ? YearVal : '2019'}
                                        onChange={HandleYearFilterChange}
                                        inputProps={{
                                            name: 'year',
                                            id: 'filter_year',
                                        }}
                                    >
                                    {typeof(yearFilter) !== 'undefined' && yearFilter.map((year, i) =>
                                        <MenuItem value={year} key={'year-'+i}>{year}</MenuItem>
                                    )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )
                }}
                />
            </div>
            
        </>
        :
        <ErrorPage/>
    )
});
