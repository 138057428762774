import React from 'react';
import { ViewExpenseDetailsComponent } from './ViewExpenseDetails.Component';
import { httpServices } from '../../services/httpServices';
import MixpanelManager from  '../../services/Analytics';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const formFields = {
    'date': null,
    'type_id': 0,
    'expense_category': null,
    'expense_code': null,
    'amount': 0,
    'description': null,
    'note': '',
    'billing_from': '',
    'billing_to': '',
    'actualPaymentValue': '',
    'advancePayCheckBox': false,
    'sec_reviewer': [],
    'advance_payment': null,
    'payrollDate': null,
    'estimatedAmount': null,
    'expense_project_code': 0,
    'mileage': 0,
    'payment_date': null,
    'payment_mode': 2,
    'declarations' : null,
    'agree' : [], 
};
const filters = {
    'sec_reviewers': '',
};
const approvePopUpFields = {
    'actualPay': 0,
    'spDate': null,
    'note': '',
    'estimatedAmount': null,
    'advancePayment': null,
    'payrollDate': null,
}
const formValues = {
    'purpose': null,
};

export class ViewExpenseDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: Object.assign({}, formFields),
            filters: filters,
            hasPermission: true,
            voucherList: {},
            isFinanceTeam: false,
            attachmentMin: null,
            filterViewState: false,
            actualPayment: null,
            openViewDetails: false,
            personalDetails: {},
            itemizedExpense: {},
            travelExpenses: {},
            open: false,
            changeLog: [],
            expenseNotes: [],
            personalExpense: [],
            expenseDetails: {},
            displayEligibility: '',
            ytdPaidLabel: '',
            ytdUnpaidLabel: '',
            firstAmountLabel: '',
            totalAmt: '',
            utoLabel: '',
            eligibilityLabel: '',
            balanceLabel: '',
            expenseChangeHistory: [],
            voucherId: '',
            viewExpense: false,
            editExpense: false,
            expenseTypes: [],
            expenseCategories: [],
            expenseCodes: [],
            imageOpen: '',
            imageId: '',
            DialogOpen: false,
            errors: {},
            snackbarOpen: false,
            snackbarType: null,
            message: null,
            editableStatus: [],
            ClientExpenses: [],
            unsubmitStatus: [],
            loginUser: null,
            itemizedSubTotal: 0,
            travelSubTotal: 0,
            submittedAmount: 0,
            action: '',
            confirmDialog: false,
            edit: false,
            statusLabels: [],
            status: [],
            reportStatus: [],
            current: '',
            dialogOpen: false,
            attachments: [],
            popupType: '',
            actionPopUpOpen: false,
            billing_from: '',
            billing_to: '',
            review: false,
            formValues: formValues,
            actualPaymentValue: '',
            submittedAmountValue: '',
            attachmentOpen: false,
            uploadButtonName: 'Upload',
            uploadTitle: 'Select or Upload Attachment',
            selectedFile: [],
            recentFiles: [],
            attachFiles: [],
            attached_files: '',
            expenseId: '',
            advancePaymentFlag: false,
            secondaryFlag: false,
            privateNoteFlag: false,
            approvePopUpFields: approvePopUpFields,
            approveValidationErrors: {},
            actualPaymentApprove: null,
            currency: '',
            rejectNote: '',
            rejectError: '',
            reviewPermision: false,
            directExpenseProject: [],
            indirectExpenseProject: [],
            estimatedAmount: null,
            itemError: false,
            attachmentError: false,
            reportApprovers: null,
            mileageRate: 0,
            validationError: null,
            addPermissionErr : '',
            projectCodes : [],
            fileType : '',
            buttonLoader : false,
            expenseDeleteOpen : false,
            expenseDeleteId : '',
            displayEmployeeCode : false,
            showLoader : false,
            uploadSize : '',
            fileOpen : '',
            viewPermission : true,
            previewedItem : {},
            hideButton : false,
            currentRate : 0, 
        }
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleOnchange = this.handleOnChange.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.addNoteClick = this.addNoteClick.bind(this);
        this.handleNoteSubmit = this.handleNoteSubmit.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.detailPageAction = this.detailPageAction.bind(this);
        this.handleExpenseSubmit = this.handleExpenseSubmit.bind(this);
        this.handleCancelViewPopup = this.handleCancelViewPopup.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.reviwerAction = this.reviwerAction.bind(this);
        this.handleApprovePopupChange = this.handleApprovePopupChange.bind(this);
        this.approveExpenseVoucher = this.approveExpenseVoucher.bind(this);
        this.updateBillingData = this.updateBillingData.bind(this);
        this.handlePurposeOnChange = this.handlePurposeOnChange.bind(this);
        this.HandleAttachmentClick = this.HandleAttachmentClick.bind(this);
        this.getRecentFiles = this.getRecentFiles.bind(this);
        this.attachmentCancel = this.attachmentCancel.bind(this);
        this.handleAttachmentSelection = this.handleAttachmentSelection.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.attachFiles = this.attachFiles.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleRejectChange = this.handleRejectChange.bind(this);
        this.rejectExpenseVoucher = this.rejectExpenseVoucher.bind(this);
        this.unApproveExpenseVoucher = this.unApproveExpenseVoucher.bind(this);
        this.handleApproverChange = this.handleApproverChange.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.deleteExpense = this.deleteExpense.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleViewFileClick = this.handleViewFileClick.bind(this);
        this.filterView = this.filterView.bind(this);
    }
    componentDidMount = () => {
        let id = (this.props.match.params.id) ? this.props.match.params.id : false;
        let page = (this.props.match.params.action == "vouchers") ? "submit" : (this.props.match.params.action ? this.props.match.params.action: "submit");
        this.setState({
            current: page,
        });
        this.getDetails(id, page);
        this.checkPermision();
    };
    handleViewClick = (id) => {
        if (id) {
            this.setState({ open: true,viewExpense: true });
            this.viewExpenseItem(id);
        }
    }
    handleViewFileClick = (id, item = null) => {
        if (id) {
            let apiEndpoint = 'api/expenses/previewAttachment';
            return httpServices.post(apiEndpoint, { 'id': id })
            .then(response => {
                if (response.data) {
                    this.setState({
                        fileOpen: true,
                        fileUrl : response.data,
                        previewedItem : item
                    });
                }
            })
        }
    }
    addNoteClick = () => {
        const form_fields = this.state.formFields;
        let errors = {};
        form_fields['note'] = '';
        this.setState({
            DialogOpen: true,
            errors: errors,
            formFields: form_fields,
        });
    }

    checkPermision = (id) => {
        return httpServices.post('api/expenses/checkPermision', { 'action': 'Expense.expense.review' })
            .then(response => {
                if (response.data) {
                    this.setState({
                        reviewPermision: response.data.permission
                    });
                }
            })
    }

    handleApproverChange = (evt, value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') {
            } else if (typeof value === 'object' && value) {
                ff['sec_reviewer'] = value;

            }
        } else {
        }
        this.setState({
            formFields: ff
        });
    };
    updateBillingData = () => {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.billing_from) {
            errors["billing_from"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.billing_to) {
            errors["billing_to"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.billing_to < this.state.formFields.billing_from) {
            errors["billing_to"] = "Billiling period to date should be greater than billing period from date";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        if (formIsValid) {
            let apiEndpoint = 'api/expenses/review';
            return httpServices.post(apiEndpoint, { 'id': this.state.voucherId, 'action': 'billing', 'billing_from': this.state.formFields.billing_from, 'billing_to': this.state.formFields.billing_to })
                .then(response => {
                    if (response.data.valid) {
                        if (response.data.message) {
                            this.setState({
                                actionPopUpOpen: false,
                                snackbarOpen: true,
                                snackbarType: "success",
                                message: response.data.message,
                            });
                            this.getDetails('', this.state.current);
                        }
                    }
                    else {
                        this.setState({
                            errors: response.data.errors,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    onSubmitClick = (action = '') => {
        if ((this.state.itemError || this.state.attachmentError ) && action === "submit") {
                let msg = '';
                if (this.state.itemError) {
                    msg = 'Mandatory fields are missing.';
                }
                if (this.state.attachmentError) {
                    let amsg = 'Receipts are required for all expenses '+this.state.currency+this.state.attachmentMin+' and above.';
                    msg = (msg != '') ? msg + ' ' + amsg : amsg ;
                }
                
                this.setState({
                    snackbarOpen: true,
                    snackbarType: "error",
                    message: msg,
                });
            }
        else  if (this.state.submittedAmountValue === 0 && action === "submit") {
                this.setState({
                    snackbarOpen: true,
                    snackbarType: "error",
                    message: "Atleast one expense item is required",
                });
            }
        else
        {
        let buttonHide = (this.state.declarations.length > 0  && action === 'submit') ? true : false;
        this.setState({
            hideButton : buttonHide,
            confirmDialog: true,
            action: action,
        });
       }
    }
    handleEditClick = (id, user_id) => {
        let currentPage = this.state.current;
        if (id) {
            this.setState({ open: true, editExpense: true, errors: {} });
            this.viewExpenseItem(id, user_id);
            let mixPanelFeature = '';
            if (currentPage === 'submit') {
                mixPanelFeature = 'expenses/submit';
            } else if (currentPage === 'review') {
                mixPanelFeature = 'expenses/review';
            }
            if (mixPanelFeature !== '') {
                this.MixpanelManager.trackEvent('Expenses', {'module' : 'Expenses', 'feature' : mixPanelFeature, 'action' : 'edit_clicked'});
            }
        }
    }
    handleNoteSubmit = (event) => {
        if (this.handleNoteValidation()) {
            let data = this.state.formFields;
            if (data) {
                if (this.state.current === "submit") {
                    this.detailPageAction(this.state.voucherId, "addnote", this.state.formFields.note)
                }
                else {
                    this.setState({ buttonLoader: true });
                    httpServices.post('api/expenses/review', { 'id': this.state.voucherId, 'action': 'addnote', 'note': this.state.formFields['note'], 'private': this.state.privateNoteFlag })
                        .then(response => {
                            if (response.data.valid) {
                                this.setState({
                                    snackbarOpen: true,
                                    DialogOpen: false,
                                    privateNoteFlag: false,
                                    snackbarType: "success",
                                    buttonLoader: false,
                                    message: response.data.message,
                                });
                                this.getDetails('', this.state.current);
                            }
                            else {
                                this.setState
                                    ({
                                        buttonLoader: false,
                                        errors: response.data.errors,
                                    });
                            }
                        })
                        .catch(function (error) {
                            console.log("Error: " + error);
                        });

                }
            }
        }
    };
    handleNoteValidation() {
        let formIsValid = true;
        let errors = {};
        if (!(this.state.formFields.note !== null &&  this.state.formFields.note.replace(/\s+/g, ''))) {
            errors["note"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    detailPageAction = (id, action, note = '') => {
        this.setState({ buttonLoader: true });
        let purpose = this.state.formValues.purpose;
        let apiEndpoint = 'api/expenses/detailPage';
        return httpServices.post(apiEndpoint, { 'id': id, 'action': action, 'note': note, 'purpose': purpose })
            .then(response => {
                if (response.data.valid) {
                    let errors = this.state.errors;
                    errors['purpose'] = '';
                    if (response.data.message) {
                        this.setState({
                            errors: errors,
                            DialogOpen: false,
                            confirmDialog: false,
                            snackbarOpen: true,
                            snackbarType: "success",
                            actionPopUpOpen: false,
                            message: response.data.message,
                            buttonLoader: false,
                        });
                        this.getDetails('', this.state.current);
                    }
                    else {
                        this.setState({ buttonLoader: false });
                    }
                }
                else {
                    this.setState({
                        errors: response.data.errors,
                        buttonLoader: false,
                        confirmDialog: false,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    handleExpenseSubmit = () => {
        if (this.state.action == 'submit' &&  this.state.declarations.length > 0 ) {
                if (this.state.declarations.length === this.state.formFields.agree.length) {
                    this.detailPageAction(this.state.voucherId, this.state.action, this.state.declarations);
                    this.setState({hideButton : false }); 
                }
                else {
                    this.setState({hideButton : true });
                }
        }
        else {
            let fields = this.state.formFields;
            fields['agree'] = [];
            this.setState({hideButton : false, formFields : fields});
            this.detailPageAction(this.state.voucherId, this.state.action);
        }
    };
    handleOnChange = (evt, value) => {
        const fields = this.state.formFields;
        const name = evt.target.name;
        var agree = fields.agree;
        let errors = this.state.errors;
        fields[name] = evt.target.value;
        if (name === 'mileage' && !isNaN(evt.target.value)) {
            var amount = parseFloat(this.state.mileageRate) * parseFloat(evt.target.value);
            if (!isNaN(amount)) {
                fields['amount'] = amount.toFixed(2);
            }
            else {
                fields['amount'] = '';
            }
        }
        if (name === 'amount' && !isNaN(evt.target.value)) {
            if (parseFloat(this.state.formFields.amount) >= parseFloat(this.state.attachmentMin) && (parseInt(this.state.formFields.expense_type) === 1) && this.state.selectedFile.length === 0) {
                errors['amount'] = "Receipts are required for all expenses "+this.state.currency+this.state.attachmentMin+" and above.";
            }
            else {
                errors['amount'] = '';
            }
        }
        this.setState({
            formFields: fields,
            errors: errors,
        });
        if (name === 'advancePayment') {
            this.setState({
                advancePaymentFlag: evt.target.checked,
            });
        }
        if (name === 'secondaryReviewer') {
            this.setState({
                secondaryFlag: evt.target.checked,
            });
        }
        if (name === 'privateNote') {
            this.setState({
                privateNoteFlag: evt.target.checked,
            });
        }
        if (name === 'agree') {
            if (evt.target.checked) {
                agree.push(evt.target.value);
            }
            else {
                agree = agree.filter(function(value, index, arr){
                    return value !== evt.target.value;
                });

            }
            fields['agree'] = agree;
            if (agree.length === this.state.declarations.length) {
                this.setState({hideButton : false });        
            }
            else {
                this.setState({hideButton : true});
            }
        }
    };

    handleRejectChange = (evt, value) => {
        this.setState({
            rejectNote: evt.target.value
        });
    };

    handleDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };

    viewExpenseItem = (id, user_id) => {
        return httpServices.post('api/expenses/view', { id: id, user_id: user_id })
            .then(response => {
                if (response.data) {
                    let attchment_fileids = response.data.attachment_fileids.toString().split(",");
                    let attchments = [];
                    if (attchment_fileids!="") {
                        attchments = attchment_fileids.map(function (x) {
                            return parseInt(x, 10);
                        });
                    }
                    this.setState({
                        expenseDetails: response.data.details,
                        expenseChangeHistory: response.data.changelogs,
                        expenseTypes: response.data.expenseTypes,
                        expenseCategories: response.data.expenseCategories,
                        expenseCodes: response.data.expenseCodes,
                        directExpenseProject: response.data.directExpenseProject,
                        indirectExpenseProject: response.data.indirectExpenseProject,
                        formFields: (this.state.editExpense ? response.data.expenseData : response.data.details),
                        dialogOpen: true,
                        attachments: response.data.attachments,
                        selectedFile: attchments,
                        mileageRate: response.data.mileage,
                        projectCodes : response.data.projectCodes,
                        attachedFileNames : response.data.attachment_file_names,
                        originalFileNames : response.data.attachment_file_names,
                        fileType : response.data.attachment_file_type,
                        uploadSize : response.data.max_size,
                    }, function () {
                    	let f = this.state.formFields;
                    	f['desc'] = this.state.formFields.description;
                    	f['uploadSize'] = response.data.max_size;
                    	this.setState({formFields:f});
		    });
                }
            })
    }
    getRecentFiles = (props) => {
        let apiEndpoint = 'api/expenses/listattachments';
        return httpServices.post(apiEndpoint, {})
            .then(response => {
                let response_data = response.data;
                this.setState({
                    recentFiles: response_data.attachments,
                    attachmentOpen:true
                });
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    updateExpenseData = (id) => {
        if (this.handleValidation(this.state.current)) {
            let data = this.state.formFields;
            data.id = id;
            data.attached_files = this.state.selectedFile;
            return httpServices.post('api/expenses/add', data)
                .then(response => {
                    if (response.data.permission) {
                        if (response.data.valid) {
                            this.setState({
                                open: false,
                                snackbarOpen: true,
                                snackbarType: "success",
                                message: "Expense voucher updated successfully",
                            });
                            this.getDetails('', this.state.current);
                        }
                        else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                        }
                    } else {
                        this.setState({
                            addPermissionErr: response.data.permision_err_msg,
                        });
                    }
                })
        }
    }

    handleValidation(current) {
        let formIsValid = true;
        let errors = {};
        const form_fields = this.state.formFields;
        if (!this.state.formFields.date && this.state.current === 'submit') {
            errors["date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.expense_type === 0 && this.state.current === 'submit') {
            errors["expense_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!(this.state.formFields.desc !== null && this.state.formFields.desc.replace(/\s+/g, ''))) {
            errors['desc'] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.expense_category == 0 || this.state.formFields.expense_category == null) {
            errors["expense_category"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.project == 0 && this.state.current === 'review') {
            errors["project"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.expense_code == 0 && this.state.current === 'review') {
            errors["expense_code"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.expense_type === 1) {
            form_fields['mileage'] = 0;
            this.setState({
                formFields: form_fields
            });
            if (parseInt(this.state.formFields.amount) <= 0) {
                errors["amount"] = "Amount should be greater than zero";
                formIsValid = false;
            }
            if (this.state.formFields.amount === "0" || !(this.state.formFields.amount.toString().replace(/\s+/g, ''))) {
                errors["amount"] = "Cannot be empty";
                formIsValid = false;
            }
            else {
                if (parseFloat(this.state.formFields.amount) >= parseFloat(this.state.attachmentMin) && this.state.selectedFile.length === 0) {
                    errors["amount"] = "Receipts are required for all expenses "+this.state.currency+this.state.attachmentMin+" and above.";
                    formIsValid = false;
                }
            }
        }
        else {
            if (this.state.formFields.mileage === "0" || this.state.formFields.mileage === null || !(this.state.formFields.amount.toString().replace(/\s+/g, '')) ) {
                errors["mileage"] = "Cannot be empty";
                formIsValid = false;
            }
            else if (isNaN(this.state.formFields.mileage)) {
                errors["mileage"] = "Please enter a valid number";
                formIsValid = false;
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleImageClick = (id) => {
        this.setState({
            imageOpen: true,
            imageId: id
        });
    }

    handleCancel = () => {
        this.setState({
            imageOpen: false,
            imageId: ''
        });
    }

    HandleAttachmentClick = (id) => {
        return httpServices.post('api/expenses/getitemattachments', { 'id': id })
            .then(response => {
                if (response.data) {
                    this.setState({
                        attachmentOpen: true,
                        expenseId: id,
                        selectedFile: response.data.attached_ids
                    });
                }
            })
    }

    attachmentCancel = (props) => {

        this.setState({
            attachmentOpen: false,

        });
    }

    fileUploadCallback = (acceptedFiles) => {
        this.setState({
            showLoader: true,
        });
        httpServices.post('api/expenses/upload', acceptedFiles)
            .then(response => {
                    const options = this.state.selectedFile;
                    let errors = this.state.errors;
                    errors['amount'] = '';
                    this.setState({
                        errors: errors,
                    });
                    let ids = response.data.id.split(',');
                    var i;
                    for (i = 0; i < ids.length; i++) {
                        options.push(+ids[i])
                    }
                    this.attachFiles();
                    if(response.data.error.length) {
                        this.setState({
                            showLoader : false,
                            snackbarOpen: true,
                            snackbarType: "error",
                            message: response.data.error,
                        });
                    }
                    else {
                        this.setState({
                            showLoader: false,
                        });
                    }
                })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }  

    attachFiles = (props) => {
        const f = this.state.formFields;
        f['attached_files'] = this.state.selectedFile.join();
        this.setState({
            formFields: f,
            attachmentOpen: false,
        });
        this.getFileNames();
    }

    getFileNames = () => {
        let apiEndpoint = 'api/expenses/getFileName';
        let value = { 'ids': this.state.selectedFile }
        return httpServices.post(apiEndpoint, value)
            .then(response => {
                this.setState({
                    attachedFileNames: response.data
                });
                
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    handleAttachmentSelection = (e) => {
        const options = this.state.selectedFile;
        let index;
        if (e.target.checked) {
            options.push(+e.target.value)
            let uniqueItems = [...new Set(options)]
            this.setState({
                selectedFile: uniqueItems,
            });
            //this.handleUpload();
        } else {
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
            let uniqueItems = [...new Set(options)]
            this.setState({
                selectedFile: uniqueItems,
            });
            //this.handleUpload();
        }
        options.sort()
    }
    getDetails = (id = '', page) => {
        let voucherId = (this.state.voucherId) ? this.state.voucherId : id;
        this.setState({
            showLoader: true,
        });
        return httpServices.post('api/expenses/details', { id: voucherId, page: page })
            .then(response => {
                if (response.data.permission) {
                    const f = this.state.formFields;
                    const ff = this.state.filters;
                    const fValue = this.state.formValues;
                    f['agree'] = [];
                    fValue['purpose'] = response.data.report.purpose;
                    f['billing_from'] = response.data.report.billing_period_from;
                    f['billing_to'] = response.data.report.billing_period_to;
                    f['actualPaymentValue'] = parseFloat((response.data.report.actual_payment) ? response.data.report.actual_payment : response.data.submittedAmountValue).toFixed(2);
                    ff['sec_reviewers'] = response.data.approvers;
                    let hide = (response.data.declarations && response.data.declarations.length > 0)? true : false
                    this.setState({
                        personalDetails: response.data.report,
                        itemError: response.data.item_error,
                        attachmentError: response.data.attachment_error,
                        isFinanceTeam: response.data.is_finance_team,
                        attachmentMin: response.data.attachment_min,
                        itemizedExpense: response.data.itemized_expenses,
                        actualPaymentApprove: f['actualPaymentValue'],
                        formFields: f,
                        filters: ff,
                        loginUser: response.data.user_id,
                        editableStatus: response.data.editable_status,
                        unsubmitStatus: response.data.unsubmit_status,
                        travelExpenses: response.data.travel_expenses,
                        changeLog: response.data.change_history,
                        expenseNotes: response.data.expense_notes,
                        personalExpense: response.data.expense_cap,
                        displayEligibility: response.data.display_eligibility,
                        ytdPaidLabel: response.data.ytd_paid_label,
                        ytdUnpaidLabel: response.data.ytd_unpaid_label,
                        firstAmountLabel: response.data.first_amt_label,
                        totalAmt: response.data.total_amt,
                        utoLabel: response.data.uto_label,
                        eligibilityLabel: response.data.eligibility_label,
                        balanceLabel: response.data.balance_label,
                        voucherId: voucherId,
                        edit: response.data.edit,
                        statusLabels: response.data.status_labels,
                        ClientExpenses: response.data.client_billable_expenses,
                        status: response.data.status,
                        reportStatus: response.data.report_status,
                        itemizedSubTotal: response.data.itemized_expense_subTotal,
                        travelSubTotal: response.data.travel_expenses_subTotal,
                        submittedAmount: response.data.submittedAmount,
                        submittedAmountValue: response.data.submittedAmountValue,
                        actualPaymentValue: response.data.report.actual_payment,
                        review: response.data.review,
                        estimatedAmount: response.data.estimated_amount,
                        currency: response.data.currency_symbol,
                        reportApprovers: response.data.report_approvers,
                        formValues: fValue,
                        validationError: response.data.error,
                        displayEmployeeCode : response.data.display_employee_code,
                        uploadSize: response.data.max_size,
                        viewPermission : true,
                        showLoader: false,
                        declarations: response.data.declarations,
                        hideButton : hide,
                        currentRate : response.data.latest_mileage
                    });
                } else {
                    this.setState({
                        viewPermission : false,
                        showLoader: false,
                    });
                }
            })
            
    }
    onClose = () => {
        if (this.state.popupType === 'approve') {
            this.resetApprovePopup();
        }
        let errors = {};
        let approveValidationErrors = {};
        let fields = this.state.formFields;
        fields['agree'] = [];
        this.setState({
            open: false,
            DialogOpen: false,
            fileOpen: false,
            editExpense: false,
            viewExpense: false,
            advancePaymentFlag: false,
            secondaryFlag: false,
            privateNoteFlag: false,
            confirmDialog: false,
            actionPopUpOpen: false,
            formFields: {},
            rejectNote: '',
            rejectError: '',
            addPermissionErr : '',
            expenseDeleteOpen : false,
            errors: errors,
            approveValidationErrors: approveValidationErrors,
            formFields : fields
        });
    }

    resetApprovePopup = () => {
        this.setState({
            approvePopUpFields: {},
            advancePaymentFlag: false,
            approveValidationErrors: {},
        });
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }

    handleCancelViewPopup = () => {
        const f = this.state.formFields;
        f['expense_date'] = new Date()
        f['expense_type'] = 1;
        f['expense_category'] = 0;
        f['project'] = 0;
        f['expense_code'] = 0;
        f['expense_code_name'] = '';
        f['desc'] = '';
        f['amount'] = '';
        f['mileage'] = 0;
        f['attached_files'] = '';
        f['id'] = 0;

        this.setState({
            formFields: f,
            open: false,
            imageOpen: false,
            attachments: [],
            viewExpense: false,
        });
    }

    deleteExpense = (id) => {
        this.setState({expenseDeleteOpen : true,
            expenseDeleteId : id,
        });
    }

    handleDeleteClick = () => {
        if (this.state.expenseDeleteId) {
            let value = { 'id': this.state.expenseDeleteId }
            return httpServices.post('api/expenses/delete', value)
                .then(response => {
                    this.setState({
                        message: "Expense deleted successfully",
                        snackbarOpen: true,
                        snackbarType: "success",
                        expenseDeleteOpen : false,
                    });
                    this.getDetails('', this.state.current);
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

    handlePayment = (props) => {
        if (this.handlePaymentValidation()) {
            this.setState({ buttonLoader: true });
            let data = this.state.formFields;
            let ids = [];
            ids.push(this.props.match.params.id);
            let value = { 'action': 'paid', 'note': data.paid_note, 'pay_mode': data.payment_mode, 'pay_date': data.payment_date, 'ids': ids }
            httpServices.post('api/expenses/payment', value)
                .then(response => {
                    if (response.data.valid) {
                        this.setState({
                            actionPopUpOpen: false,
                            snackbarOpen: true,
                            snackbarType: "success",
                            message: response.data.message,
                            buttonLoader: false,
                        });
                        this.getDetails('', this.state.current);
                    }
                    else {
                        this.setState({
                            errors: response.data.errors,
                            buttonLoader: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        props.preventDefault();
    }

    handlePaymentValidation() {
        let formIsValid = true;
        let errors = {};

        if (!this.state.formFields.payment_date || this.state.formFields.payment_date === null || isNaN(Date.parse(this.state.formFields.payment_date))) {
            errors["payment_date"] = "Payment date cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    reviwerAction = (id) => {
        let popupType = '';
        id = parseInt(id);
        switch (id) {
            case 3:
                popupType = 'approve';
                break;
            case 4:
                popupType = 'reject';
                break;
            case 5:
                popupType = 'review';
                break;
            case -1:
                popupType = 'un-approve';
                break;
            case -3:
                popupType = 'billing';
                break;
            case 6:
                if (this.state.personalDetails.billing_period_from) {
                    popupType = 'pay';
                    break;
                }
                else {
                    if (this.state.ClientExpenses.length > 0 && parseInt(this.state.personalDetails.status) !== 4 && parseInt(this.state.personalDetails.status) !== 1) {
                        this.setState({
                            snackbarOpen: true,
                            snackbarType: "error",
                            message: "Please enter the billing period to pay",
                        });
                    }
                    else {
                        popupType = 'pay';
                        break;
                    }
                }
            default:
                popupType = '';
        }
        let approvePopUpFields = this.state.approvePopUpFields;
        if (id === 3) {
            approvePopUpFields['actualPay'] = this.state.actualPaymentApprove;
        }
        let formFields = this.state.formFields;
        if (id === 5) {
            formFields['actualPaymentValue'] = this.state.actualPaymentApprove;
        }
        if (id === 6) {
            formFields['payment_mode'] = 2;
        }
        if (id === 3 || id === 5) {
            if (this.state.itemError || this.state.attachmentError) {
                let msg = '';
                if (this.state.itemError) {
                    msg = 'All fields are required.';
                }
                if (this.state.attachmentError) {
                    let amsg = 'Receipts are required for all expenses '+this.state.currency+this.state.attachmentMin+' and above';
                    msg = (msg != '') ? msg + ' ' + amsg : amsg ;
                }
                this.setState({
                    snackbarOpen: true,
                    snackbarType: "error",
                    message: msg,
                });
            }
            else {
                this.setState({
                    popupType: popupType,
                    actionPopUpOpen: true,
                    approvePopUpFields: approvePopUpFields,
                    formFields: formFields,
                });
            }
        }
        else {
            this.setState({
                popupType: popupType,
                actionPopUpOpen: true,
                approvePopUpFields: approvePopUpFields,
                formFields: formFields,
            });
        }
    }

    handlePurposeOnChange = (evt, value) => {
        var f = this.state.formValues;
        var name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formValues: f,
        });
    };

    handleApprovePopupChange = (evt, value) => {
        let approvePopUpFields = this.state.approvePopUpFields;
        let name = evt.target.name;
        if (name === 'advancePaymentFlag') {
            this.setState({
                advancePaymentFlag: evt.target.checked,
            });
        } else {
            approvePopUpFields[name] = evt.target.value;
            this.setState({
                approvePopUpFields: approvePopUpFields,
            });
        }
    }
    submitReview = () => {
        let submit = true;
        let actualPayment = this.state.formFields['actualPaymentValue'];
        // Actual Payment and Advance Payment details are editable only for finance
        submit = this.validatePayment(actualPayment);
        if (submit && this.state.advancePaymentFlag) {
            submit = this.validateReviewAdvancePayment();
        }
        if (submit) {
            let note = this.state.formFields['note'];
            let errors = {};
            if (note === '' || note === undefined || !(note.replace(/\s+/g, ''))) {
                errors['note'] = 'Note is required';
                submit = false;
            }
            this.setState({
                approveValidationErrors: errors
            });
        }
        if (submit) {
            this.setState({ buttonLoader : true});
            let data = {
                'id': this.state.voucherId,
                'action': 'review',
                'note': this.state.formFields['note'],
                'actual_payment': this.state.formFields['actualPaymentValue'],
                'estimated_amount': this.state.formFields['estimatedAmount'],
                'advance_payment': this.state.formFields['advance_payment'],
                'payroll_date': this.state.formFields['payrollDate'],
                'voucher_approver': (this.state.formFields.sec_reviewer) ? JSON.stringify(Object.values(this.state.formFields.sec_reviewer)) : null,
                'private': this.state.privateNoteFlag,
            }
            httpServices.post('api/expenses/review', data)
                .then(response => {
                    if (response.data.valid) {
                        let formFields = {};
                        this.setState({
                            actionPopUpOpen: false,
                            snackbarOpen: true,
                            snackbarType: "success",
                            message: response.data.message,
                            formFields: formFields,
                            approveValidationErrors: {},
                            buttonLoader: false,
                        });
                        this.getDetails('', this.state.current);
                    }
                    else {
                        this.setState({
                            approveValidationErrors: response.data.errors,
                            buttonLoader: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    approveExpenseVoucher = () => {
        let submit = true;
        let isFinanceTeam = this.state.isFinanceTeam;
        let submittedTotal = this.state.submittedAmountValue;
        let actualPayment = this.state.approvePopUpFields['actualPay'];
        // Actual Payment and Advance Payment details are editable only for finance
        // If the Actual Payment is changed, made Note field mandatory and displayed a confirmation message
        if (isFinanceTeam) {
            submit = this.validatePayment(actualPayment);
            if (submit && this.state.advancePaymentFlag) {
                submit = this.validateAdvancePayment();
            }
            if (submit && (parseFloat(actualPayment) !== parseFloat(submittedTotal))) {
                let note = this.state.approvePopUpFields['note'];
                let errors = {};
                if (typeof note === 'undefined' || note.trim() === '' || note === null) {
                    errors['noteErr'] = 'Note required';
                    submit = false;
                }
                this.setState({
                    approveValidationErrors: errors,
                });
                if (submit) {
                    let c = window.confirm("You have changed the Actual Payment Amount. Do you want to continue?");
                    if (!c) {
                        submit = false;
                    }
                }
            }
        }
        if (submit) {
            this.setState({ buttonLoader : true});
            let data = {
                'id': this.state.voucherId,
                'action': 'approve',
                'note': this.state.approvePopUpFields['note'],
                'payment_scheduledate': this.state.approvePopUpFields['spDate'],
                'actual_payment': this.state.approvePopUpFields['actualPay'],
                'estimated_amount': this.state.approvePopUpFields['estimatedAmount'],
                'advance_payment': this.state.approvePopUpFields['advancePayment'],
                'payroll_date': this.state.approvePopUpFields['payrollDate'],
            }
            httpServices.post('api/expenses/review', data)
                .then(response => {
                    if (response.data.valid) {
                        for (const key in approvePopUpFields) {
                            if (key === 'estimatedAmount' || key === 'advancePayment' || key === 'payrollDate') {
                                approvePopUpFields[key] = null;
                            }
                        }
                        this.setState({
                            snackbarOpen: true,
                            snackbarType: "success",
                            message: response.data.message,
                            approvePopUpFields: approvePopUpFields,
                            actionPopUpOpen: false,
                            approveValidationErrors: {},
                            buttonLoader: false,
                        });
                        this.getDetails('', this.state.current);
                    }
                    else {
                        let validationErrors = response.data.errors;
                        validationErrors['noteErr'] = validationErrors['note'];
                        this.setState({
                            approveValidationErrors: validationErrors,
                            buttonLoader: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    validateReviewAdvancePayment = () => {
        let estimatedAmount = this.state.formFields['estimatedAmount'];
        let advancePayment = this.state.formFields['advance_payment'];
        let payrollDate = this.state.formFields['payrollDate'];
        let note = this.state.formFields['note'];
        let errors = {};
        let flag = true;
        if (estimatedAmount === '' || estimatedAmount === null || isNaN(estimatedAmount)) {
            errors['estimateAmountErr'] = 'Cannot be empty';
            flag = false;
        }
        if (advancePayment === '' || advancePayment === null || isNaN(advancePayment)) {
            errors['advancePaymentErr'] = 'Cannot be empty';
            flag = false;
        }
        if (payrollDate === '' || payrollDate === null || payrollDate === undefined) {
            errors['payrollDateErr'] = 'Cannot be empty';
            flag = false;
        }
        if (note.trim() === '' || note === null) {
            errors['note'] = 'Note required';
            flag = false;
        }
        if (flag && (parseFloat(advancePayment) > parseFloat(estimatedAmount))) {
            errors['advancePay'] = 'Advance Amount should not be greater than Estimated Amount.';
            flag = false;
        }
        this.setState({
            approveValidationErrors: errors,
        });
        return flag;


    }

    validatePayment = (actualPayment) => {
        let flag = true;
        let approveValidationErrors = this.state.approveValidationErrors;
        if (actualPayment !== 0 && isNaN(actualPayment)) {
            flag = false;
            approveValidationErrors['validationTips'] = 'Invalid Actual Payment Amount';
        }
        if (!(actualPayment.trim())) {
            flag = false;
            approveValidationErrors['validationTips'] = 'Invalid Actual Payment Amount';
        }
        if (parseFloat(actualPayment) > parseFloat(this.state.actualPaymentValue ? this.state.actualPaymentValue : this.state.submittedAmountValue)) {
            flag = false;
            approveValidationErrors['validationTips'] = 'Actual Payment Amount should not be greater than Submitted Amount.';
        }
        if (!flag) {
            this.setState({
                approveValidationErrors: approveValidationErrors,
            });
        }
        return flag;
    }

    validateAdvancePayment = () => {
        let estimatedAmount = this.state.approvePopUpFields['estimatedAmount'];
        let advancePayment = this.state.approvePopUpFields['advancePayment'];
        let payrollDate = this.state.approvePopUpFields['payrollDate'];
        let errors = {};
        let flag = true;
        if (estimatedAmount === null || isNaN(estimatedAmount) || estimatedAmount === undefined || estimatedAmount.trim() === '') {
            errors['estimateAmountErr'] = 'Cannot be empty';
            flag = false;
        }
        if (advancePayment === null || isNaN(advancePayment) || advancePayment === undefined || advancePayment.trim() === '') {
            errors['advancePaymentErr'] = 'Cannot be empty';
            flag = false;
        }
        if (payrollDate === '' || payrollDate === null || payrollDate === undefined) {
            errors['payrollDateErr'] = 'Cannot be empty';
            flag = false;
        }
        if (flag && (parseFloat(advancePayment) > parseFloat(estimatedAmount))) {
            errors['validationTips'] = 'Advance Amount should not be greater than Estimated Amount.';
            flag = false;
        }
        this.setState({
            approveValidationErrors: errors,
        });
        return flag;
    }

    rejectExpenseVoucher = () => {
        this.setState({ buttonLoader: true });
        let rejectNote = this.state.rejectNote;
        if (rejectNote.length && rejectNote.replace(/\s+/g, '')) {
            let data = {
                'id': this.state.voucherId,
                'action': 'reject',
                'note': rejectNote,
            }
            httpServices.post('api/expenses/review', data)
                .then(response => {
                    if (!response.data.errors) {
                        this.setState({
                            snackbarOpen: true,
                            snackbarType: "success",
                            message: response.data.message,
                            rejectNote: '',
                            actionPopUpOpen: false,
                            rejectError: '',
                            buttonLoader: false,
                        });
                        this.getDetails('', this.state.current);
                    } else {
                        this.setState({
                            errors: response.data.errors,
                            buttonLoader: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        } else {
            this.setState({
                buttonLoader: false,
                rejectError: "Note cannot be empty",
            });
        }
    }

    unApproveExpenseVoucher = () => {
        this.setState({buttonLoader: true});
        let rejectNote = this.state.rejectNote;
        if (rejectNote.length && rejectNote.replace(/\s+/g, '')) {
            let data = {
                'id': this.state.voucherId,
                'action': 'unapprove',
                'note': rejectNote,
            }
            httpServices.post('api/expenses/review', data)
                .then(response => {
                    if (response.data.valid) {
                    this.setState({
                        snackbarOpen: true,
                        snackbarType: "success",
                        message: response.data.message,
                        rejectNote: '',
                        actionPopUpOpen: false,
                        rejectError: '',
                        buttonLoader: false,
                    });
                    this.getDetails('', this.state.current);
                }
                else
                {
                    this.setState({
                        errors: response.data.errors,
                        buttonLoader: false,
                    });
                }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        } else {
            let errors = this.state.errors;
            errors['note'] = "Note cannot be empty";
            this.setState({
                buttonLoader : false,
                errors: errors,
            });
        }
    }

    cancelExpenseVoucher = () => {
        this.detailPageAction(this.state.voucherId, 'cancel',this.state.formFields.note);
    };

    saveExpenseVoucher = () => {
        this.detailPageAction(this.state.voucherId, 'save',this.state.formFields.note);
    };

    onCancelClick = () => {
        const ff = this.state.formFields;
        ff['note'] = '';
        ff['agree'] = [];
        this.setState({
            popupType: 'cancel',
            actionPopUpOpen: true,
            formFields: ff
            });
    }

    onSaveClick = () => {
        const ff = this.state.formFields;
        ff['note'] = '';
        this.setState({
            popupType: 'save',
            actionPopUpOpen: true,
            formFields: ff
            });
    }

    removeItem = (id, props) => {
        const options = this.state.selectedFile;
        const f = this.state.formFields;
        let index = options.findIndex(x => parseInt(x) == parseInt(id));
        if (index !== -1) {
        options.splice(index, 1);
        f['attached_files'] = options.join();
        this.setState({
            selectedFile: options,
            formFields: f
        });
        this.getFileNames();
       }
    }

    filterView = (anchor, open) => (event) => {
        this.setState({fileOpen : open});
    };
    
    render() {
        return (
            <>
            <CustomProgressBar
                        loaderOpen={this.state.showLoader}
                    /> 
                {(this.state.viewPermission) ?
                <ViewExpenseDetailsComponent
                    PersonalDetails={this.state.personalDetails}
                    ItemizedExpense={this.state.itemizedExpense}
                    TravelExpenses={this.state.travelExpenses}
                    HandleViewClick={this.handleViewClick}
                    Open={this.state.open}
                    //EditClick = {this.state.editClick}
                    ChangeLog={this.state.changeLog}
                    ExpenseNotes={this.state.expenseNotes}
                    Close={this.onClose.bind(this)}
                    ExpenseDetails={this.state.expenseDetails}
                    PersonalExpense={this.state.personalExpense}
                    DisplayEligibility={this.state.displayEligibility}
                    YtdPaidLabel={this.state.ytdPaidLabel}
                    YtdUnpaidLabel={this.state.ytdUnpaidLabel}
                    FirstAmountLabel={this.state.firstAmountLabel}
                    TotalAmt={this.state.totalAmt}
                    UtoLabel={this.state.utoLabel}
                    EligibilityLabel={this.state.eligibilityLabel}
                    BalanceLabel={this.state.balanceLabel}
                    ExpenseChangeHistory={this.state.expenseChangeHistory}
                    VoucherId={this.state.voucherId}
                    ViewExpense = {this.state.viewExpense}
                    EditExpense={this.state.editExpense}
                    HandleEditClick={this.handleEditClick}
                    ExpenseTypes={this.state.expenseTypes}
                    FormFields={this.state.formFields}
                    UpdateExpenseData={this.updateExpenseData}
                    HandleOnChange={this.handleOnChange}
                    ActualPaymentValue={this.state.actualPaymentValue}
                    SubmittedAmountValue={this.state.submittedAmountValue}
                    ExpenseCategories={this.state.expenseCategories}
                    ExpenseCodes={this.state.expenseCodes}
                    handleImageClick={this.handleImageClick}
                    imageId={this.state.imageId}
                    imageOpen={this.state.imageOpen}
                    handleCancel={this.handleCancel}
                    AddNoteClick={this.addNoteClick}
                    OnSubmitClick={this.onSubmitClick}
                    DialogOpen={this.state.DialogOpen}
                    Errors={this.state.errors}
                    Edit={this.state.edit}
                    StatusLabels={this.state.statusLabels}
                    Status={this.state.status}
                    Current={this.state.current}
                    HandleNoteSubmit={this.handleNoteSubmit}
                    SnackbarOpen={this.state.snackbarOpen}
                    SnackbarType={this.state.snackbarType}
                    SnackbarClose={this.snackbarClose.bind(this)}
                    Message={this.state.message}
                    LoginUser={this.state.loginUser}
                    EditableStatus={this.state.editableStatus}
                    UnsubmitStatus={this.state.unsubmitStatus}
                    ItemizedSubTotal={this.state.itemizedSubTotal}
                    TravelSubTotal={this.state.travelSubTotal}
                    SubmittedAmount={this.state.submittedAmount}
                    AttachmentMin={this.state.attachmentMin}
                    Action={this.state.action}
                    ItemError={this.state.itemError}
                    AttachmentError = {this.state.attachmentError}
                    ConfirmDialog={this.state.confirmDialog}
                    HandleExpenseSubmit={this.handleExpenseSubmit}
                    FromSubmitTab={this.props.FromSubmitTab}
                    ReportStatus={this.state.reportStatus}
                    HandleDeleteClick={this.handleDeleteClick}
                    UpdateBillingData={this.updateBillingData}
                    ClientExpenses={this.state.ClientExpenses}
                    ReviwerAction={this.reviwerAction}
                    SubmitReview={this.submitReview}
                    PopupType={this.state.popupType}
                    ActionPopUpOpen={this.state.actionPopUpOpen}
                    ApprovePopUpFields={this.state.approvePopUpFields}
                    HandleApprovePopupChange={this.handleApprovePopupChange}
                    AdvancePaymentFlag={this.state.advancePaymentFlag}
                    PrivateNoteFlag={this.state.privateNoteFlag}
                    Filters={this.state.filters}
                    Currency={this.state.currency}
                    HandleApproverChange={this.handleApproverChange}
                    SecondaryFlag={this.state.secondaryFlag}
                    ApproveExpenseVoucher={this.approveExpenseVoucher}
                    ApproveValidationErrors={this.state.approveValidationErrors}
                    RejectNote={this.state.rejectNote}
                    HandleRejectChange={this.handleRejectChange}
                    RejectError={this.state.rejectError}
                    RejectExpenseVoucher={this.rejectExpenseVoucher}
                    UnApproveExpenseVoucher={this.unApproveExpenseVoucher}
                    Review={this.state.review}
                    FormValues={this.state.formValues}
                    HandleAttachmentClick={this.HandleAttachmentClick}
                    AttachmentOpen={this.state.attachmentOpen}
                    RecentFiles={this.state.recentFiles}
                    UploadButtonName={this.state.uploadButtonName}
                    UploadTitle={this.state.uploadTitle}
                    RefreshList={this.state.refreshList}
                    SelectedFile={this.state.selectedFile}
                    Attachments={this.state.attachments}
                    AttachedFileNames={this.state.attachedFileNames}
                    OriginalFileNames={this.state.originalFileNames}
                    GetRecentFiles={this.getRecentFiles}
                    AttachFiles={this.attachFiles}
                    AttachmentCancel={this.attachmentCancel}
                    FileUploadCallback={this.fileUploadCallback}
                    HandleAttachmentSelection={this.handleAttachmentSelection}
                    ExpenseId={this.expenseId}
                    HandlePayment={this.handlePayment}
                    reviewPermision={this.state.reviewPermision}
                    DirectExpenseProject={this.state.directExpenseProject}
                    IndirectExpenseProject={this.state.indirectExpenseProject}
                    IsFinanceTeam={this.state.isFinanceTeam}
                    EstimatedAmount={this.state.estimatedAmount}
                    ReportApprovers={this.state.reportApprovers}
                    HandlePurposeOnChange={this.handlePurposeOnChange}
                    MileageRate={this.state.mileageRate}
                    ValidationError={this.state.validationError}
                    OnCancelClick = {this.onCancelClick}
                    CancelExpenseVoucher = {this.cancelExpenseVoucher}
                    OnSaveClick = {this.onSaveClick}
                    SaveExpenseVoucher = {this.saveExpenseVoucher}
                    AddPermissionErr = {this.state.addPermissionErr}
                    ProjectCodes={this.state.projectCodes}
                    Filetype={this.state.fileType}
                    RemoveItem={this.removeItem}
                    ButtonLoader={this.state.buttonLoader}
                    ExpenseDeleteOpen={this.state.expenseDeleteOpen}
                    DeleteExpense = {this.deleteExpense}
                    HandleImageClick = {this.handleImageClick}
                    HandleCancelViewPopup = {this.handleCancelViewPopup}
                    DisplayEmployeeCode = {this.state.displayEmployeeCode}
                    ShowLoader = {this.state.showLoader}
                    UploadSize ={this.state.uploadSize}
                    HandleDateChange = {this.handleDateChange}
                    FileOpen={this.state.fileOpen}
                    FileUrl={this.state.fileUrl}
                    HandleViewFileClick={this.handleViewFileClick}
                    FilterView = {this.filterView}
                    PreviewedItem = {this.state.previewedItem}
                    Declarations = {this.state.declarations}
                    HideButton = {this.state.hideButton}
                    CurrentRate = {this.state.currentRate}
                />
                :<ErrorPage/>
                }
            </>
        )
    }
}
