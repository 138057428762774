import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DialogBoxMedium } from '../common/dialog.medium';
import * as _R from 'ramda';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CustomizedSnackbars } from '../common/snackbars';
import FormHelperText from '@mui/material/FormHelperText';

const colapsibleStyle = {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 60,
    paddingRight: 60
 };
const colapsibleHeaderStyle = {
   backgroundColor: '#EDEDEA'
};

export const EligibilityCriteriaGroupsComponent = (props) => {

    return (
        <>
            <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        Eligibility Criteria Groups
                    </Typography>
                    {(props.HasPermission) && (
                        <Tooltip title="Add Eligibility Criteria Group" aria-label="add">
                            <Fab size="small" color="default" aria-label="add" className={'fab-outline'}>
                                <AddIcon onClick={props.OnAddGroupClick} />
                            </Fab>
                        </Tooltip>
                    )}
                </div>
                {props.HasPermission ? (
                    <div className={'section-content roles-table'}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>Timeoff Type</TableCell>
                                    <TableCell>Start Year</TableCell>
                                    <TableCell>End Year</TableCell>
                                    <TableCell>Default</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {typeof props.CriteraGroups != 'undefined' && props.CriteraGroups.map((group) => (
                                    <React.Fragment key={group.id}>
                                        <TableRow>
                                            <TableCell align="left">
                                                {props.ExpandedRow !== group.id && <IconButton>
                                                    <KeyboardArrowRightIcon fontSize="small" onClick={props.HandleRowClick(group.id)} />
                                                </IconButton>}
                                                {props.ExpandedRow === group.id && <IconButton>
                                                    <ExpandMoreIcon fontSize="small" onClick={props.HandleRowClick(group.id)} />
                                                </IconButton>}
                                            </TableCell>
                                            <TableCell>{group.name}</TableCell>
                                            <TableCell>{group.timeoff_type}</TableCell>
                                            <TableCell>{group.start_year}</TableCell>
                                            <TableCell>{group.end_year}</TableCell>
                                            <TableCell>{group.is_default ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{group.description}</TableCell>
                                            <TableCell align="right">
                                                {props.EditPermission && (
                                                    <Tooltip title="Add Criteria" aria-label="edit">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Add Criteria">
                                                            <AddIcon fontSize="small" onClick={props.OnAddCriterionClick(group.id)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {props.EditPermission && (
                                                    <Tooltip title="Edit" aria-label="edit">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                            <EditIcon fontSize="small" onClick={props.EditGroupClick(group.id)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {(props.EditPermission && group.is_default === 0) && (
                                                    <Tooltip title="Delete" aria-label="delete">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Delete">
                                                            <DeleteIcon fontSize="small" onClick={props.DeleteGroupClick(group.id)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        {/* Expanded Row (Accordion) */}
                                        <TableRow>
                                            <TableCell colSpan={8} style={colapsibleStyle}>
                                                <Collapse in={props.ExpandedRow === group.id} timeout="auto" unmountOnExit>
                                                    <Table size="small">
                                                        <TableHead style={colapsibleHeaderStyle}>
                                                            <TableRow>
                                                                <TableCell>Experience From</TableCell>
                                                                <TableCell>Experience To </TableCell>
                                                                <TableCell>Unit</TableCell>
                                                                <TableCell>Days/Year</TableCell>
                                                                <TableCell>Hours/Year</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {typeof group.criterias != 'undefined' && group.criterias.map((criterion,i) =>
                                                            <TableRow hover>
                                                                <TableCell>{criterion.lower_limit}</TableCell>
                                                                <TableCell>{criterion.upper_limit}</TableCell>
                                                                <TableCell>{criterion.unit}</TableCell>
                                                                <TableCell>{criterion.no_days}</TableCell>
                                                                <TableCell>{criterion.no_days * props.DailyMinimum}</TableCell>
                                                                <TableCell align="right">
                                                                    {props.EditPermission && <Tooltip title="Edit" aria-label="edit">
                                                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                                            <EditIcon fontSize="small" onClick={props.EditCriterionClick(criterion.id)} />
                                                                        </IconButton>
                                                                    </Tooltip>}
                                                                    {props.EditPermission && <Tooltip title="Delete" aria-label="delete">
                                                                        <IconButton  className={'action-btn'} edge="end" aria-label="Delete" >
                                                                            <DeleteIcon fontSize="small" onClick={props.DeleteCriterionClick(criterion.id)}/>
                                                                        </IconButton>
                                                                    </Tooltip>}
                                                                </TableCell>
                                                            </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ) : (
                    <div>
                        <p className="error">You are not authorized to view the Eligibility Criteria Groups</p>
                    </div>
                )}
            </div>

            {props.PopupType === 'add' && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={props.IsEditable ? 'Edit Eligibility Criteria Group' : 'Add Eligibility Criteria Group'}
                    OnSubmit={props.HandleSubmit}
                    Content={<DialogContent FormProps={props} />}
                />
            )}

            {props.PopupType === 'delete' && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteGroup}
                    Title="Confirm Delete"
                    ButtonName="Ok"
                    Content={<DialogWithConfirmMessage msg="Do you really want to delete this Eligibility Criteria Group?" />}
                />
            )}

            {props.PopupType === 'add-criteria' && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={props.IsEditable ? 'Edit Eligibility Criterion' : 'Add Eligibility Criterion'}
                    OnSubmit={props.HandleCriteriaSubmit}
                    Content={<CriteriaDialogContent FormProps={props} />}
                />
            )}

            {props.PopupType === 'delete-criteria' && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteCriterion}
                    Title="Confirm Delete"
                    ButtonName="Ok"
                    Content={<DialogWithConfirmMessage msg="Do you really want to delete this Eligibility Criterion?" />}
                />
            )}
            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant={props.SnackbarMsgType ? props.SnackbarMsgType : 'success'}
                message={props.SnackbarMsg ? props.SnackbarMsg : ''}
                handleClose={props.SnackbarClose}
            />
        </>
    );
};

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors, TimeoffTypes  } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.name && true }
                            helperText = { Errors.name }
                        />
                        <FormControl required  component="fieldset">
                            <FormControlLabel
                                label="Is Default"
                                control={
                                    <Checkbox
                                            name='is_default'
                                            id='is_default'
                                            value={(_R.type(FormFields) !== 'undefined' && FormFields.is_default == 1) ? FormFields.is_default : 0}
                                            onChange={HandleOnChange}
                                            checked={(_R.type(FormFields) !== 'undefined' && FormFields.is_default == 1) ? true : false}
                                        />
                                }
                            />
                        </FormControl>
                        <TextField variant="standard"
                            type = "number"
                            id = "start_year"
                            name = "start_year"
                            label = "Start Year"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.start_year : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.start_year && true }
                            helperText = { Errors.start_year }
                        />
                        <TextField variant="standard"
                            type = "number"
                            id = "end_year"
                            name = "end_year"
                            label = "End Year"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.end_year : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.end_year && true }
                            helperText = { Errors.end_year }
                        />
                    </ FormControl>
                    <FormControl>
                        <TextField variant="standard"
                            id = "description"
                            name = "description"
                            label = "Description"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.description : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.description && true }
                            helperText = { Errors.description }
                        />
                    </FormControl>
                    <FormControl required variant="standard">
                        <InputLabel htmlFor="timeoff_type_id">Timeoff Type</InputLabel>
                        <Select
                            value={ _R.type(FormFields) !== 'undefined' ? FormFields.timeoff_type_id : '' }
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'timeoff_type_id',
                                id: 'timeoff_type_id'
                            }}
                            className={'input-item'}
                        >
                                <MenuItem value=''>Select</MenuItem>
                                { typeof TimeoffTypes != 'undefined' && TimeoffTypes.map((t,i) =>
                                    <MenuItem value={t.id}>{t.to_type}</MenuItem>
                                    
                                )}                            
                        </Select>
                        {Errors.timeoff_type_id && <FormHelperText className='error' >{Errors.timeoff_type_id}</FormHelperText>}
                    </ FormControl>
                </Grid>               
            </Grid>
        </form>
    )
}
const CriteriaDialogContent = (props) => {
    const { CriteriaFormFields,HandleOnCriteriaChange,Errors, CriteraUnits  } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            type = "number"
                            id = "lower_limit"
                            name = "lower_limit"
                            label = "Experience From"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(CriteriaFormFields) !== 'undefined' ? CriteriaFormFields.lower_limit : '' }
                            onChange = { HandleOnCriteriaChange }
                            error = { Errors.lower_limit && true }
                            helperText = { Errors.lower_limit }
                        />
                        <TextField variant="standard"
                            type = "number"
                            id = "upper_limit"
                            name = "upper_limit"
                            label = "Experience To"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(CriteriaFormFields) !== 'undefined' ? CriteriaFormFields.upper_limit : '' }
                            onChange = { HandleOnCriteriaChange }
                            error = { Errors.upper_limit && true }
                            helperText = { Errors.upper_limit }
                        />
                        <TextField variant="standard"
                            type = "number"
                            id = "no_days"
                            name = "no_days"
                            label = "Number Of Days"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(CriteriaFormFields) !== 'undefined' ? CriteriaFormFields.no_days : '' }
                            onChange = { HandleOnCriteriaChange }
                            error = { Errors.no_days && true }
                            helperText = { Errors.no_days }
                        />
                    </ FormControl>
                    <FormControl required variant="standard">
                        <InputLabel htmlFor="unit">Unit</InputLabel>
                        <Select
                            value={ _R.type(CriteriaFormFields) !== 'undefined' ? CriteriaFormFields.unit : '' }
                            onChange={HandleOnCriteriaChange}
                            inputProps={{
                                name: 'unit',
                                id: 'unit'
                            }}
                            className={'input-item'}
                        >
                                <MenuItem value=''>Select</MenuItem>
                                { typeof CriteraUnits != 'undefined' && CriteraUnits.map((u,i) =>
                                    <MenuItem value={u.id}>{u.name}</MenuItem>
                                )}
                        </Select>
                        {Errors.unit && <FormHelperText className='error' >{Errors.unit}</FormHelperText>}
                    </ FormControl>
                    <input type='hidden' name='group_id' value={CriteriaFormFields.group_id}/>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
