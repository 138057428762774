import React from 'react';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import * as _R from 'ramda';
import * as _ts from '../../../common/treeSelect';

import { DialogBoxMedium } from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { BasicDetailsEdit } from ".";
import { CustomProgressBar } from '../../../common/CustomProgressBar';
import MixpanelManager from  '../../../../services/Analytics';

const staffInputs = {
    pay_periods : [
        { id : 0, value : "monthly", title : "Monthly"},
        { id : 1,value : "semi-monthly", title : "Semi-Monthly"}
    ],
    ts_review_method : [
        { id : 0, value : 1, title : "Project Manager" },
        { id : 1,value : 0, title : "Supervisor"},
    ],
    disable_req_approval : [
        { id : 0, value : "1", title : "Yes"},
        { id : 1, value : "0", title: "No"},
    ],
    expense_eligibility : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    expense_limit_exempt : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    time_inout : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    owner_or_relative : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    timeoff_eligibility : [
        { id : 0, value : "joining_date", title : "Joining Date"},
        { id : 1, value : "service_period", title : "Service Period"},
        { id : 2, value : "custom_start_date", title : "Joining Date Override"},
    ],
    include_timeoff_eligibility_service_period : [
        { id : 0, value : "all_service_period", title : "All Service Period"},
        { id : 1, value : "employee_salary_service_period", title : "Employee + Salary Service Periods"}
    ],
    'projects' : [0],
    'relationship_to_owner' : null,
    leadership_program: [
        { id: 0, value: 1, title: "Yes" },
        { id: 1, value: 0, title: "No" },
    ],
    expert: [
        { id: 0, value: 1, title: "Yes" },
        { id: 1, value: 0, title: "No" },
    ]
};
    let getProjectTree = function(dataObj,selected_projects, result) {
        dataObj.forEach(function(key) {
            if(selected_projects) {
                if((selected_projects == key.project_id)) {
                    key.checked = true;
                    result.push(key);
                }
                else if(key.children instanceof Object) {
                    getProjectTree(key.children, selected_projects,result);
                }
            }
            if(!selected_projects) {
                if(key.checked) {
                    key.checked = false;
                }
                else if(key.children instanceof Object) {
                    getProjectTree(key.children, 0, result);
                }
            }
        });
    }

export class StaffBasicDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showLoader : false,
            buttonLoader : false,
            open: false,
            staff_data: {},
            errors: {},
            prev_data : {},
            attrition_type_list:this.props.attritionType,
            offices: this.props.Offices,
            locations: this.props.Locations,
            clearance_levels : this.props.ClearanceLevels,
            office_location: this.props.office_location,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount(){
        if (this.props.staffBasicDetails) {
            this.setState({
                prev_data: this.props.staffBasicDetails,
            });
        }
    }
    
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.staff_data;
        let projects = [];
        getProjectTree(staffInputs["projects"],0,projects);
        if (sNodes && sNodes.length > 0) {
            sNodes.forEach(function(el){
                if (el.project_id && staffInputs["projects"]) {
                    getProjectTree(staffInputs["projects"],el.project_id,projects);
                    f['main_project'] = projects[0]['project_id'];
                    f['project_name'] = projects[0]['project_name'];
                }
            });
        }
        this.setState({
            staff_data: f
        });
        this.getProjectLocations();
    }
    getProjectLocations = () => {
        this.setState({
            showLoader:true,
        });
        let f = this.state.staff_data;
        httpServices.post('api/staff/work/getProjectLocations', {project_id: f.main_project})
            .then(response => {
                if (response.data) {
                    this.setState({
                        locations: response.data.locations
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleClickOpen = () => {
        this.setState({
            showLoader:true,
        });
        httpServices.post('api/staff/work/getAllProjects')
            .then(response => {
                if (response.data) {
                    staffInputs["projects"] = _ts.prepareTreeData(response.data.projects,'projects', false);
                    let projects= [];
                    let selected_projectid = this.props.staffBasicDetails.main_project;
                    getProjectTree(staffInputs["projects"],selected_projectid,projects);
                    this.setState({
                        staff_data: JSON.parse(JSON.stringify(this.state.prev_data)),
                        open:true,
                        showLoader:false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onClose = () => {
        this.setState({
            open: false,
            errors: {}
        });
    };
   
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.staff_data;
        if (field === 'time_inout') {
            ff['time_inout_required'] = evt.target.value;
        }
        if (field === 'main_project') {
            let project_name = this.getProjectById(evt.target.value);
            if (project_name) {
                ff['main_project_name'] = project_name;
            }
        }
        ff[field] = evt.target.value;
        this.setState({
            staff_data: ff
        });
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.staff_data;
            if(!data.expense_eligibility){
                data.expense_limit_exempt = false;
            }
            if (data) {
                this.setState({
                    buttonLoader: true, 
                });
                httpServices.post('api/staff/work/saveStaffBasicDetails', { data })
                    .then(response => {
                        if (!response.data.validation_error) {
                            if (response.data.success) {
                                data['modified_relieved_date'] = null;
                                data['modified_time_inout_edate'] = null;
                                if(response.data.modified_relieved_date){
                                    data['modified_relieved_date'] = response.data.modified_relieved_date;
                                }
                                if(response.data.modified_time_inout_edate){
                                    data['modified_time_inout_edate'] = response.data.modified_time_inout_edate;
                                }
                                data['modified_date_joined'] = response.data.modified_date_joined;
                                data['modified_doj_override_date'] = response.data.modified_doj_override_date;
                                data['office_name'] = response.data.office_name;
                                data['clearance_name'] = response.data.clearance_name;
                                if (this.state.prev_data.expert !== data.expert) {
                                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-edit', 'action' : 'expert-field-update'});
                                }
                                if (this.state.prev_data.leadership_program !== data.leadership_program) {
                                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-edit', 'action' : 'leadership-program-field-update'});
                                }
                                if (this.state.prev_data.office_name !== data.office_name) {
                                    this.MixpanelManager.trackEvent('Staff', {'module' : 'Staff', 'feature' : 'staff-edit', 'action' : 'office-name-field-update'});
                                }
                                this.setState({
                                    prev_data :data,
                                    open: false,
                                    buttonLoader: false,
                                });
                                this.props.updateChange();
                            }
                            else{
                                this.setState({
                                    errors: response.data.errors,
                                    buttonLoader: false, 
                                });
                            }
                        }
                        else {
                            this.setState({
                                errors: response.data.validation_error,
                                 buttonLoader: false,
                            });
                        }
                    })
                    .catch(function(error) {
                        alert("Update failed");
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let date_joined = this.state.staff_data.date_joined;
        let releived_date = this.state.staff_data.relieved_date;
        let errors = {};
        if(!date_joined)
        {
            errors["date_joined"] = "Date joined cannot be empty";
            formIsValid = false;
        }
        if (date_joined && releived_date) {
            let date_joined_obj = new Date(date_joined);
            let releived_date_obj = new Date(releived_date);
            if(releived_date_obj < date_joined_obj){
                errors["relieved_date"] = "Please enter a valid relieved date";
                formIsValid = false;
            }
        }
        if (this.state.staff_data.user_status === 3){
            let reason=this.state.staff_data.attrition_reason;
           if  (!this.state.staff_data.attrition_type)
            {
                errors["attrition_type"] = "Required";
                formIsValid = false;
            }
            if ( !reason || reason.length>80)
            {
                errors["attrition_reason"] = "Enter reason(max length:80)";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }
        
    getProjectById(id) {
        if (id && staffInputs["projects"]) {
            let projects = staffInputs["projects"];
            let project = projects.find(p => p.project_id === id);
            if (project) {
                return project.project_name;
            }
        }
        return false
    }

    render(){
        const { staffBasicDetails, Edit } = this.props;
        let data = (_R.type(this.state.prev_data) !== 'undefined') ? this.state.prev_data : {};
        return (
            <> 
                <CustomProgressBar loaderOpen={this.state.showLoader}/>
                <Grid container spacing={3} style={{position:"relative"}}>
                    <Grid item xs={12} sm={6}>
                        <div className={'profile-view__basics_default'}>
                            {(staffBasicDetails.display_employee_code && data.employee_id)?
                            <div className={'profile-view__basics_default-item item-role'}><label>Employee ID</label><span className={'value'}>{data.employee_id}</span></div>
                            :<></>
                            }
                            <div className={'profile-view__basics_default-item item-role'}><label>Working Time</label><span className={'value'}>{data.entry_time} {data.exit_time ? '-' : ''} {data.exit_time}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Pay Period</label><span className={'value'}>{data.pay_period ? data.pay_period.charAt(0).toUpperCase() + data.pay_period.slice(1) : ''}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Main Project</label><span className={'value'}>{data.project_path} {data.project_status_name ? '(' + data.project_status_name + ')' : null}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Timesheet Review Method</label><span className={'value'}>{data.timesheet_multiple_review === 1 ? "Project Manager" : "Supervisor"}</span> 
                            </div>
                            {(data.timeoff_eligibility !== '') && <div className={'profile-view__basics_default-item item-role'}>
                                <label>Timeoff Eligibility Start Date</label><span className={'value'}>{(data.timeoff_eligibility === 'service_period') ? "Service Period" : ((data.timeoff_eligibility === 'custom_start_date') ? 'Joining Date Override' : 'Joining Date')}</span>
                            </div>}
                            {(data.timeoff_eligibility === 'custom_start_date' && data.modified_doj_override_date !== '0000-00-00') && <div className={'profile-view__basics_default-item item-role'}>
                                <label>Joining Date Override</label><span className={'value'}>{data.modified_doj_override_date}</span>
                            </div>}
                            {(data.timeoff_eligibility !== '' && data.timeoff_eligibility !== 'service_period') &&<div className={'profile-view__basics_default-item item-role'}>
                                <label>Timeoff Eligibility Service Period</label><span className={'value'}>{(data.include_timeoff_eligibility_service_period === 'all_service_period') ? "All Service Period" : 'Employee + Salary Service Periods' }</span>
                            </div>}
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Time In/Out</label><span className={'value'}>{parseInt(data.time_inout) ? "Yes" : "No"}</span>
                            </div>
                            { parseInt(data.time_inout) ?
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Time In/Out Required</label><span className={'value'}>{parseInt(data.time_inout_required) ? "Yes" : "No"}</span>
                            </div>
                            :<></>
                            }
                            {(parseInt(data.user_status) === 3)?
                            <>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Attrition Type</label><span className={'value'}>{this.state.attrition_type_list.map((t, i) =>
                                    <>{(data.attrition_type === t.id)? t.title:''}</>
                                )}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Attrition Reason</label><span className={'value'}>{data.attrition_reason}</span>
                            </div>
                            </>
                            :<></>
                            }
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Clearance Level</label><span className={'value'}>{data.clearance_name}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={'profile-view__basics_personal'}>
                            { parseInt(data.time_inout) ?
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Time In/out Effective Date</label><span className={'value'}>{data.modified_time_inout_edate} </span>
                            </div>
                            :<></>
                            }
                            <div className={'profile-view__basics_default-item item-role'}><label>Relieved Date</label><span className={'value'}>{data.modified_relieved_date} </span></div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Expense Eligibility</label>
                                <span className={'value'}>{parseInt(data.expense_eligibility) ? "Yes" : "No"}</span>
                            </div>
                            { parseInt(data.expense_eligibility) ?
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Expense Limit Exempt</label>
                                <span className={'value'}>{parseInt(data.expense_limit_exempt) ? "Yes" : "No"}</span>
                            </div>
                            : <></>
                            }
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Owner or Relative</label>
                                <span className={'value'}>{parseInt(data.owners_relatives) ? "Yes" : "No"}</span>
                            </div>
                            { parseInt(data.owners_relatives) ? 
                                <div className={'profile-view__basics_default-item item-role'}>
                                <label>Relationship With Owner</label>
                                <span className={'value'}>{data.relationship_to_owner}</span>
                            </div>
                            : <></>
                            }
                             <div className={'profile-view__basics_default-item item-role'}>
                                <label>Office Location</label><span className={'value'}>{data.office_name}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Leadership Program</label><span className={'value'}>{parseInt(data.leadership_program) ? "Yes" : "No"}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Expert</label><span className={'value'}>{parseInt(data.expert) ? "Yes" : "No"}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Supervisor</label><span className={'value'}>{parseInt(data.supervisor) ? "Yes" : "No"}</span>
                            </div>
                        </div>
                    </Grid>
                    <div className={'absolute-right'}>
                        {_R.equals(Edit, true) &&
                        <div className={'card-action'}>
                            <Fab size="small" color="secondary" aria-label="edit" onClick={this.handleClickOpen}><EditIcon fontSize="small" /></Fab>
                        </div>
                        }
                    </div>
                </Grid>
                <DialogBoxMedium
                    Open = { this.state.open }
                    Cancel = { this.onClose }
                    Title = { 'Edit Staff Work Details' }
                    OnSubmit = { this.handleSubmit }
                    ButtonName = { 'Save' }
                    Loading = { this.state.buttonLoader }
                    Content={
                        <BasicDetailsEdit
                            OnSubmit = { this.handleSubmit }
                            staffData = { this.state.staff_data } 
                            attrition_type_list = {this.state.attrition_type_list}
                            staffInputs = { staffInputs }
                            HandleOnChange = { this.HandleOnChange }
                            HandleTreeChange = {this.handleTreeChange}
                            Errors = { this.state.errors }
                            offices = {this.state.offices}
                            locations = {this.state.locations}
                            office_location = {this.state.office_location}
                            ClearanceLevels = {this.state.clearance_levels}

                        />
                    }
                />
            </>
        )
    }
}
