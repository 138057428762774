import React from 'react';
import {ConfigComponent} from './Config.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _r from 'ramda';
import { ErrorPage } from '../../../common/error';
import moment from 'moment';
import { flushSync } from 'react-dom';

const formFields = {
    'id':0,
    'component': null,
    'start_date': null,
    'end_date': null,
    'lower_limit': null,
    'upper_limit': null,
    'unit': null,
    'based_on': null,
    'amount': '',
    'pay_recurrence': null,
    'pay_frequency': null,
    'payroll_count': 0,
    'frequency' : null,
    'status': '1',
    'desc': null,
    'config_id': 0,
    'disabled' : false,
    'cap_unit':null,
    'cap_figure':'',
}
export class ConfigDetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formInputs: [],
            formFields: formFields,
            open: false,
            components: [],
            edit_id: false,
            errors: {},
            pref_amount_flag: false,
            preferred_amount:0,
            message: false,
            delete_config_id :false,
            staff_id : false,
            component_id :false,
            isEditable: false,
        }
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCloseDelete = this.onCloseDelete.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.getPreferredAmount = this.getPreferredAmount.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    onAdd = (id = false) => {
        const {base_components, components, units, frequency} = this.props.ConfigData;
        var inputsObj ={
            base_components: base_components,
            components: components,
            units: units,
            frequency: frequency,
        };
        if (id) {
             return httpServices.post('api/staff/config/getComponent', {'id': id, 'staff_id':this.props.StaffID})
            .then(response => {
               if (response.data) {
                 formFields.pay_recurrence = response.data.component.pay_recurrence;
                 formFields.pay_frequency = response.data.component.pay_frequency;
                }
                formFields.disabled = true;
                formFields.component = id;
                this.setState({
                    formInputs: inputsObj,
                    open: true,
                    formFields: formFields,
                    edit_id: false,
                });

        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
        }
        this.setState({
            formInputs: inputsObj,
            open: true,
            formFields: formFields,
            edit_id: false,
            isEditable: false,
        });
    }

    onClose = () =>{
        this.setState({open: false});
        this.clearForm();
    }
    onCloseDelete = () =>{
        this.setState({message:false});
    }

    handleOnChange = (evt, value) => {
        if (evt.target.name === 'preferred_amount') {
            this.setState({
                preferred_amount : evt.target.value,
            });
        } else {
            const f = this.state.formFields;
            f[evt.target.name] = evt.target.value;
            this.setState({
                formFields: f
            });
        }
    };

    handleCheckBoxChange = (event) => {
        this.setState({
            pref_amount_flag : event.target.checked,
        });
        if (event.target.checked) {
            this.getPreferredAmount();        
        }
    };

    validate() {
        let formIsValid = false;
        let errors = {};
        if (_r.isNil(this.state.formFields.component) || this.state.formFields.component === 'Null') {
            errors["component"] = "Component cannot be empty";
        }
        if (_r.isNil(this.state.formFields.start_date)) {
            errors["start_date"] = "Start date cannot be empty";
        }
        var lowerLimit = this.state.formFields.lower_limit;
        var upperLimit = this.state.formFields.upper_limit;
        if (lowerLimit && !String(lowerLimit).match(/^[0-9]+$/)) {
            errors["lower_limit"] = "Lower limit is invalid";
        }
        if (upperLimit && !String(upperLimit).match(/^[0-9]+$/)) {
            errors["upper_limit"] = "Upper limit is invalid";
        }
        if (this.state.formFields.amount && isNaN(this.state.formFields.amount)) {
            errors["amount"] = "Amount is invalid";
        }
        if (this.state.formFields.cap_figure && isNaN(this.state.formFields.cap_figure)) {
            errors["cap_figure"] = "Cap Figure is invalid";
        }
        if (!Object.keys(errors).length) {
            errors = {};
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleComponentOnChange = (evt, value) => {
        if (evt.target.value) {
        return httpServices.post('api/staff/config/getComponent', {'id': evt.target.value, 'staff_id':this.props.StaffID})
                .then(response => {
                    if (response.data) {
                        flushSync(() => {
                        this.setState({
                            formFields: response.data.component,
                        });
                    });
                        this.handleOnChange(evt, value);
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }

    onSubmit = () =>{
        if (this.validate()){
            const data = this.state.formFields;
            if (this.state.edit_id){
                data.edit_id = this.state.edit_id;
            }
            data.preferred_amount = this.state.preferred_amount;
            data.pref_amount_flag = this.state.pref_amount_flag; 
            const { StaffID:staffID } = this.props;
            if (data && staffID) {
                data.staffID = staffID;
                return httpServices.post('api/staff/config/updateConfig', data)
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.status) {
                                this.setState({
                                    open: false,
                                    components: response.data.staff_components,
                                    edit_id: 0
                                });
                                this.clearForm();
                            } else {
                                alert(response.data.err_msg);
                            }
                        }
                        else {
                            this.setState({errors : response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }

    onUpdate = (id) => {
        return httpServices.post('api/staff/config/editComponent', {'id': id})
            .then(response => {
               if (response.data) {
                   if (response.data.component) {
                       const {base_components, components, units, frequency} = this.props.ConfigData;
                       var inputsObj ={
                           base_components: base_components,
                           components: components,
                           units: units,
                           frequency: frequency
                       };
                       this.setState({
                           open: true,
                           isEditable: true,
                           formFields: response.data.component,
                           formInputs: inputsObj,
                           edit_id: id
                       });
                   }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onDelete = (id, index, componentID, start_date) => {
        if(id) {
            let components = this.state.components.length>0?this.state.components:((typeof this.props.ConfigData != 'undefined')?this.props.ConfigData.staff_components:[]);
            //components.splice(index, 1);
            this.setState({components: components});
            return httpServices.post('api/staff/config/deleteComponentValidate', {'id': id, 'component_id': componentID, 'staff_id': this.props.StaffID, 'start_date': start_date})
                .then(response => {
                    if (response.data.error) {
                        alert(response.data.error);
                    } else {
                        if(response.data.flag) {
                            this.setState({message: true, 
                                            delete_config_id:id});
                        }
                        else {
                            // TODO
                            this.setState({
                                components: response.data.staff_components,
                                delete_config_id:id,
                                staff_id : this.props.StaffID,
                                component_id : componentID,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    onDeleteConfirm  = () => {
        let delete_config_id = this.state.delete_config_id; 
        let staff_id = this.props.StaffID;
        let component_id = this.state.component_id;
        return httpServices.post('api/staff/config/deleteComponentStaffConfig',{'id': delete_config_id,'staff_id' :staff_id,'component_id':component_id})
                .then(response => {
                if(response.data) {
                    alert("Deleted Successfully ");               
                    this.setState({message: false,
                            components: response.data.staff_components});
                }
        });
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = (key === 'id')?0:null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            edit_id: false,
            pref_amount_flag: false,
            preferred_amount:0,
        });
    };
    getPreferredAmount = () => {
        const data = this.state.formFields;
        return httpServices.post('api/staff/config/getComponentPreferredAmount', {data,'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    this.setState({
                        preferred_amount : response.data.amount,
                    })
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleDateChange = (date, field) => {
        const f = this.state.formFields;
        let errors = {};
        f[field] = date;
        this.setState({ formFields: f,
            errors : errors
        });
    };

    render(){
        var components = [];let Edit; let Delete;
        if (typeof this.props.ConfigData != 'undefined') {
            components = this.props.ConfigData.staff_components;
            Edit = this.props.ConfigData.edit_permission
            Delete = this.props.ConfigData.delete_permission
        }
        return(
            (this.props.ConfigData.permission)
            ?
            <ConfigComponent 
                Open = {this.state.open}
                HandleClose = {this.onClose}
                OnCloseDelete = {this.onCloseDelete}
                HandleAdd = {this.onAdd}
                HandleOnChange = {this.handleOnChange}
                HandleCheckBoxChange = {this.handleCheckBoxChange.bind(this)}
                HandleSubmit = {this.onSubmit}
                HandleDelete = {this.onDelete}
                ConfirmDelete = {this.onDeleteConfirm}
                HandleUpdate = {this.onUpdate}
                FormFields = {this.state.formFields} 
                FormInputs = {this.state.formInputs} 
                Components = {this.state.components.length>0?this.state.components:components}
                HandleComponentOnChange = {this.handleComponentOnChange.bind(this)}
                Errors = {this.state.errors}
                Edit = {Edit}
                Delete = {Delete}
                PreferredAmountFlag = {this.state.pref_amount_flag}
                PreferredAmount = {this.state.preferred_amount}
                EditId = {this.state.edit_id}
                message = {this.state.message}
                IsEditable = {this.state.isEditable}
                GetPreferredAmount = {this.getPreferredAmount}
                HandleDateChange = {this.handleDateChange}
                DateJoined = {this.props.DateJoined}
                RelievedDate = {this.props.RelievedDate}
            />
            :
            <ErrorPage/>
        )
    }
}
