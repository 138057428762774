import React from 'react';
import {ProfileComponent} from './Profile.Component';
import { httpServices } from '../../services/httpServices';
import { UserConstants } from '../../constants/';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const formFields = {
    'old_pswd': null,
    'new_pswd': null,
    'confm_pswd': null,
    'email_action': '0',
}

export class ProfileContainer extends React.Component{
      constructor(props) {
        super(props);
        this.state ={
            permission : true,
            edit_permission : true,
            snackbarOpen: false,
            load : false,
            page : 'personal',
            showLoader : true,
            error_message : null,
            success_message : null,
            valid: false,
            errors : {},
            uname:null,
            userDetails: [],
            profile: [],
            service_periods: [],
            accounts: [],
            projects: [],
            reviewers: [],
            subordinates: [],
            formFields:formFields,
            openPassword : false,
            userName : null,
            openEmailEdit : false,
            emailType : '',
            displayEmployeeCode : false,
            photo : [],
            emergency_contact : '',
            other_address : [],
            office_address : [],
            hr_email : '',
            job_title : '',
            phone : '',
            email : ''
        }
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleChangeClick = this.handleChangeClick.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onEmailSettingsEdit = this.onEmailSettingsEdit.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    onChangePassword =(uname) =>
    {
        this.setState({openPassword : true, userName : uname});
    }
    onEmailSettingsEdit = (type) =>
    {
        let ff = this.state.formFields;
        if (type === "email_own_action") {
            ff['email_action'] = this.state.userDetails.email_own_action; 
        }
        else if (type === "secondary_email_notification") {
            ff['email_action'] = this.state.userDetails.secondary_email_notification;
        }
        else {
            ff['email_action'] = this.state.profile.email_notification;
        }
        this.setState({formFields : ff, openEmailEdit : true, emailType : type});
    }
    onClose = () => {
        this.setState({openPassword : false});
        this.setState({openEmailEdit : false});
        this.clearForm();
    }
    handleOnClick = () =>
    {
        this.setState({load:true, personal:false});
    };
    handleChangeClick = (page = '') =>
    {
        this.setState({page:page, load:false});
    };    
    componentDidMount() {
     this.getProfile(); 
    }
    getProfile = () => {
       httpServices.get('api/user/my-profile')
        .then(response => {
            if (response.data) {
                this.setState({permission: response.data.permission,
                edit_permission : response.data.edit_permission,
                uname:response.data.uname,
                showLoader:false,
                userDetails:response.data.user,
                profile:response.data.profile,
                accounts:response.data.accounts,
                reviewers:response.data.reviewers,
                subordinates:response.data.subordinates,
                service_periods:response.data.service_periods,
                displayEmployeeCode : response.data.display_employee_code,
                photo : response.data.photo,
                emergency_contact : response.data.emergency_contact,
                other_address : response.data.other_address,
                office_address : response.data.office_address,
                hr_email : response.data.hr_email,
                job_title : response.data.job_title,
                phone : response.data.phone,
                email : response.data.email
                });
            }
        })
        .catch(function(error) {
        }); 
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
            valid: false,
        });
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.old_pswd) {
            errors["old_pswd"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.new_pswd) {
            errors["new_pswd"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.confm_pswd) {
            errors['confm_pswd'] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.new_pswd !== this.state.formFields.confm_pswd) {
            errors['confm_pswd'] = "Password Mismatch!";
            formIsValid = false;
        }
        if ((this.state.formFields.old_pswd && this.state.formFields.new_pswd) && (this.state.formFields.old_pswd === this.state.formFields.new_pswd)) {
            errors["new_pswd"] = "Old password and New password can not be same";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleOnUpdate = () => {
          if (this.handleValidation()) {
              let data = this.state.formFields;
              data.userName = this.state.userName;
            httpServices.post('api/user/change-password', data)
                .then(response => {
                    if (response.data.valid) {
                        this.clearForm();
                        this.setState({snackbarOpen:true,
                            valid:true,
                            success_message:response.data.message});
                            this.handleMenuLogout();
                    } else if (response.data.errors) {
                        this.setState({
                            errors: response.data.errors,
                        });
                    }
                    else {
                        if (response.data.message_warning || response.data.message_warning_old) {
                            let errors = this.state.errors;
                            errors["new_pswd"] = response.data.message_warning;
                            errors["old_pswd"] = response.data.message_warning_old;
                            this.setState({errors: errors});
                        }
                        else
                        {
                        this.clearForm();
                        this.setState({error_message:response.data.message,
                            valid:false,
                            snackbarOpen:true });
                        }
                    }
                })
          }
    };
    handleEmailSubmit = () => {
              let data = this.state.formFields;
              let email_action = data.email_action;
              let email_type = this.state.emailType;
              httpServices.post('api/user/updateUserPreferences', {email_action, email_type})
                .then(response => {
                    if (response.data.success) {
                        let personalDetails = this.state.userDetails;
                        let profileDetails = this.state.profile;
                        if (email_type === "email_own_action" || email_type === "secondary_email_notification") {
                            personalDetails[email_type] = email_action;
                        }
                        else {
                            profileDetails[email_type] = email_action;
                        }
                        this.setState({
                            userDetails:personalDetails,
                            profile:profileDetails,
                            openEmailEdit:false,
                            snackbarOpen:true,
                            valid:true,
                            success_message:"Email preferences updated successfully"});
                    }
                })
    };
   handleMenuLogout = event => {
        var user = {
        };
        let apiEndpoint = UserConstants.LOGOUT_ROUTE;
        return httpServices.post(apiEndpoint, user)
        .then(response => {
            if (response.data) {
                    var myLength = response.data.instance_id.length;
                    Object.keys(window.localStorage)
                        .forEach(function(key){
                            if (key.substring(0,myLength) === response.data.instance_id) {
                                localStorage.removeItem(key);
                            }
                        });
                    window.location = BASE_PATH+'/login';
            }
            else {
                alert("logout fail");
            }
        })
        .catch(function(error) {
        });
    };
 
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = "";
        }
        this.setState({
            errors: {},
            formFields: ff
        });
    };

    render(){
        return(
            <>
             <CustomProgressBar
                    loaderOpen={this.state.showLoader}
             />
            {(this.state.permission) ?
            <ProfileComponent 
                Load = {this.state.load}
                Accounts = {this.state.accounts}
                Projects = {this.state.projects}
                UserDetails = {this.state.userDetails}
                Profile = {this.state.profile}
                HandleOnClick = {this.handleOnClick}
                HandleEmailSubmit = {this.handleEmailSubmit}
                HandleChangeClick = {this.handleChangeClick}
                Page = {this.state.page}
                ServicePeriods = {this.state.service_periods}
                Errors = {this.state.errors}
                Reviewers = {this.state.reviewers}
                Subordinates = {this.state.subordinates}
                HandleOnUpdate = {this.handleOnUpdate}
                HandleOnChange = {this.handleOnChange}
                FormFields = {this.state.formFields}
                error_message = {this.state.error_message}
                success_message = {this.state.success_message}
                snackbarOpen = {this.state.snackbarOpen}
                valid ={this.state.valid}
                SnackbarClose ={this.snackbarClose}
                EditPermission = {this.state.edit_permission}
                uname = {this.state.uname}
                OnChangePassword = {this.onChangePassword}
                OnEmailSettingsEdit = {this.onEmailSettingsEdit}
                OpenPassword = {this.state.openPassword}
                OpenEmailEdit = {this.state.openEmailEdit}
                OnClose = {this.onClose}
                EmailType = {this.state.emailType}
                UserName = {this.state.userName}
                DisplayEmployeeCode = {this.state.displayEmployeeCode}
                Photo = {this.state.photo}
                Emergency = {this.state.emergency_contact}
                OtherAddress = {this.state.other_address}
                OfficeAddress = {this.state.office_address}
                HREmail = {this.state.hr_email}
                JobTitle = {this.state.job_title}
                Phone = {this.state.phone}
                Email = {this.state.email}

            />
                :
                 <ErrorPage/>
                    }
            </>

        )
    }
}
