import React from 'react';
import Grid from '@mui/material/Grid';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { DualListBoxSelector } from '../../common/dualListBoxSelector';
import Button from '@mui/material/Button';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { DialogBoxLarge } from '../../common/dialog.large';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import {MaterialTreeView} from '../../../components/CustomMaterialUiTree/MaterialUiTree';
import ForwardIcon from '@mui/icons-material/Forward';
import * as _R from 'ramda';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { formatAndSortNames } from '../../common/common';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';



const mstyle={ chips: { background: "#f4f4f4", color: 'black' }};
const filterStyles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});

function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
function nl2br(str){
 return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export const TaskComponent = withStyles(filterStyles) (props => {
    const tasks = (typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.tasks !== 'undefined') ? props.FormInputs.tasks : [];
    const editPermission = (typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.is_edit !== 'undefined') ? props.FormInputs.is_edit : false;
    const allEditPermission = (typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.all_edit_permission !== 'undefined') ? props.FormInputs.all_edit_permission : false;
    const deletePermission = (typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.is_delete !== 'undefined') ? props.FormInputs.is_delete : false;
    const { classes, toggleDrawer } = props;
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'first_name', middle_name: 'middle_name', last_name: 'last_name'};
    return (
        <>
            <React.Fragment>
                <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title=" "
                        columns={[
                            {
                                title: 'Title',
                                field: 'task_name',
                            },
                            {
                                title: 'Task Type',
                                field: 'name',
                            },
                            {
                                title: 'Start Date',
                                field: 'task_sdate',
                                customSort:(a,b) => sort(new Date(a.task_sdate),new Date(b.task_sdate))
                            },
                            {
                                title: 'End Date',
                                field: 'task_edate',
                                customSort:(a,b) => sort(new Date(a.task_edate),new Date(b.task_edate)),
                                render: rowData => <>{(typeof rowData.task_edate === 'undefined' || rowData.task_edate === '' || !rowData.task_edate) ? "Never" : rowData.task_edate}</>
                            },
                            {
                                title: 'Status',
                                field: 'task_status_name',
                            },
                        ]}
                        data={tasks}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSizeOptions: [10,20,30],
                            rowStyle: rowData => ({
                                color: (typeof rowData.isDataChanged !== 'undefined' && rowData.isDataChanged) ? '#DC143C' : '',
                            })
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'New Task',
                                isFreeAction: true,
                                onClick: (event) => { props.HandleClick('add_task') },
                                hidden: (editPermission) ? false : true,
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit Task',
                                onClick: (event, rowData) => {
                                    props.HandleClick('add_task',rowData.task_id);
                                },
                                hidden: (editPermission) ? false : true,
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => {
                                    props.HandleClick('delete_task',rowData.task_id);
                                },
                                hidden: (deletePermission) ? false : true,
                            },
                            {
                                icon: ForwardIcon,
                                tooltip: 'Move Task',
                                onClick: (event, rowData) => {
                                    props.HandleClick('move_task',rowData.task_id,rowData);
                                },
                                hidden: (allEditPermission) ? false : true,
                            },
                        ]}
                        components={{
                            Toolbar: props => (
                                <div style={{ position: 'relative', paddingRight:'50px' }}>
                                    <CustomMTableToolbar {...props} />
                                    <Tooltip title="Filter" aria-label="Filter">
                                        <IconButton onClick={toggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                                    </Tooltip>
                                </div>
                            )
                        }}
                        detailPanel={[
                            {
                                render: ({rowData})  => {
                                    return (
                                        <div className={'project-task-detail-panel'}>
                                            <form className="form-fullwidth">
                                                <Grid container spacing={3} >
                                                    <Grid item xs={12} md={6}>
                                                        <div className={'profile-view__basics_default'}>
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Description</label>
                                                                <span className={'value'}>{parse(nl2br(rowData.task_description))}</span>
                                                            </div>
                                                            {(typeof rowData.tags !== 'undefined' && rowData.tags !== "") &&
                                                                <div className={'profile-view__basics_default-item item-role'}>
                                                                    <label>Labels</label>
                                                                    <span className={'value'}>{(typeof rowData.tags !== 'undefined') ? rowData.tags : ''}</span>
                                                                </div>
                                                            }
                                                            {(typeof rowData.clins !== 'undefined' && rowData.clins !== "") &&
                                                                <div className={'profile-view__basics_default-item item-role'}>
                                                                    <label>CLIN</label>
                                                                    <span className={'value'}>{(typeof rowData.clins !== 'undefined') ? rowData.clins : ''}</span>
                                                                </div>
                                                            }
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Created On</label>
                                                                <span className={'value'}>{rowData.task_cdate}</span>
                                                            </div>
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Created By</label>
                                                                <span className={'value'}>{typeof rowData.cby_user !== 'undefined' ? rowData.cby_user : ''}</span>
                                                            </div>
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Modified On</label>
                                                                <span className={'value'}>{rowData.task_mdate}</span>
                                                            </div>
                                                            <div className={'profile-view__basics_default-item item-role'}>
                                                                <label>Modified By</label>
                                                                <span className={'value'}>{typeof rowData.mby_user !== 'undefined' ? rowData.mby_user : ''}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <div className={'task-resource-box'}>
                                                            <div className={'header d-flex align-center'}>
                                                                <label>Assigned Resources</label>
                                                                <div className={'ml-1'}>
                                                                    <FormControl size="small" className={'rs-status-input'} variant="standard">
                                                                        <Select
                                                                            inputProps={{
                                                                                name: 'user_status',
                                                                                id: 'user_status',
                                                                            }}
                                                                            value={rowData.task_assignee_status}
                                                                            variant="outlined"
                                                                            onChange={(e) => props.HandleResourceStatusFilterChange(rowData.task_id, e)}
                                                                        >
                                                                            <MenuItem value={0} key={'st-0'}>All</MenuItem>
                                                                            {typeof props.FormInputs.statuses != 'undefined' && props.FormInputs.statuses.map((t, i) =>
                                                                                <MenuItem value={t.user_status_id}>{t.user_status_name}</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                {(editPermission) &&
                                                                    <Button size="small" color="primary" variant="outlined" onClick={() => props.HandleResourceEditClick(rowData.task_id)}>Edit</Button>
                                                                }
                                                            </div>
                                                            {(typeof rowData.users !== 'undefined' && rowData.users && rowData.users.length > 0) &&
                                                                <div className={'value'}>
                                                                    <ul>
                                                                        {formatAndSortNames(rowData.users, [format, columnsToBeFormated], props.NameSortOrder).map((name, key) =>
                                                                            <li>{name.formatted_name}</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            }
                                                            {(typeof rowData.users !== 'undefined' && rowData.users && rowData.users.length < 1) &&
                                                                <div className={'info'}>
                                                                    No Resources Found
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </div>
                                    )
                                }
                            }
                        ]}
                     />
                    <Drawer
                        anchor="right"
                        open={props.Right}
                        onOpen={props.toggleDrawer('right', true)}
                        onClose={props.toggleDrawer('right', false)}>
                        <Filter
                            Right={'right'}
                            FilterValues = {props.FilterValues}
                            FilterInputs={props.FilterInputs}
                            HandleFilterChange={props.HandleFilterChange}
                            SubmitFilter={props.SubmitFilter}
                            ResetFilter={props.ResetFilter}
                        />
                    </Drawer>

            </div>
            {(props.PopupType === 'add_task' && props.DialogOpen) &&
            <DialogBoxLarge
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={props.EditId ? "Save" : "Add Task"}
                OnSubmit={props.HandleSubmit}
                Title={props.EditId ? "Edit Task" : "Add New Task"}
                Loading = {props.ShowLoader}
                DisplayCheckBox = {true}
                HandleOnChange = {props.HandleOnChange}
                FormValues = {props.FormValues}
                Content={
                    <DialogAddTask 
                        Permission = {editPermission}
                        FormValues = {props.FormValues}
                        FormFields = {props.FormFields}
                        FormInputs = {props.FormInputs}
                        HandleOnChange = {props.HandleOnChange}
                        HandleDateChange = {props.HandleDateChange}
                        HandleTaskChange = {props.HandleTaskChange}
                        Tags = {props.Tags}
                        HandleTagAddition = {props.HandleTagAddition}
                        OnSelectResources = {props.OnSelectResources}
                        ShowTimeoffTypes = {props.ShowTimeoffTypes}
                        Errors = {props.Errors}
                    />
                }
            />
            }
            {(props.PopupType === 'update_res' && props.DialogOpen) &&
            <DialogBoxLarge
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Save"}
                OnSubmit={props.UpdateTaskResources}
                Title={"Add/Remove Task Assignees"}
                Loading = {props.ShowLoader}
                DisplayCheckBox = {true}
                HandleOnChange = {props.HandleOnChange}
                FormValues = {props.FormValues}
                Content={
                    <DualListBoxSelector
                        Selected = {(typeof props.UpdateResource !== 'undefined' && typeof props.UpdateResource.selected !== 'undefined') ? props.UpdateResource.selected : []}
                        Available = {(typeof props.UpdateResource !== 'undefined' && typeof props.UpdateResource.available !== 'undefined') ? props.UpdateResource.available : []}
                        HandleChange = {props.HandleResourceChange}
                        Id = {(typeof props.UpdateResource !== 'undefined' && typeof props.UpdateResource.taskId !== 'undefined') ? props.UpdateResource.taskId : 0}
                    />
                }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {(props.PopupType === 'delete_task' && props.DialogOpen) &&
            <DialogBoxMedium
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Delete"}
                OnSubmit={props.DeleteTask}
                Loading = {props.ShowLoader}
                Title={"Confirmation"}
                Content={
                    <DialogWithConfirmMessage 
                        msg={"Do you want to delete this task?"} 
                    />
                }
            />
            }
            {(props.PopupType === 'move_task' && props.DialogOpen) &&
            <DialogBoxMedium
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Save"}
                OnSubmit={props.MoveTask}
                Title={"Move To"}
                Content={
                    <MoveTask 
                        Data = {(typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.all_projects !== 'undefined') ? props.FormInputs.all_projects : []}
                        HandleTaskTreeChange = {props.HandleProjectChange}
                        TaskWithClin={props.TaskWithClin}
                    />
                }
            />
            }
            {(props.PopupType === 'end_date_confirmation' && props.DialogOpen) &&
            <DialogBoxMedium
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Update Task"}
                OnSubmit={props.HandleEndDateConfirmation}
                Title={"Set End Date"}
                Content={
                    <DialogConfirmDates
                        FormValues = {props.FormValues}
                        Errors = {props.Errors}
                        HandleOnChange = {props.HandleOnChange} 
                    />
                }
            />
            }
        </React.Fragment>
        </>
    );
});

const DialogAddTask = (props) => {
    if (typeof props.Errors !== 'undefined' && typeof props.Errors.post_validation_error !== 'undefined' && props.Errors.post_validation_error !== '') {
        let element = document.getElementById('errorMsg');
        if (element) {
            element.scrollIntoView(true);
        }
    }
    const filter = createFilterOptions();
    return (
        <>
        {props.Permission ?
        <form className="form-fullwidth">
            <Grid container spacing={3} >
                <Grid item xs={12} md={6}>
                <div>
                <Grid container spacing={1} >
                <Grid item xs={12} md={12} id="errorMsg">
                {(typeof props.Errors !== 'undefined' && typeof props.Errors.post_validation_error !== 'undefined' && props.Errors.post_validation_error !== '') &&
                    <span className={'error'}>{props.Errors.post_validation_error}</span>
                }
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="task_name"
                            name="task_name"
                            label="Task Name"
                            required
                            type="text"
                            variant="standard"
                            value={(typeof props.FormValues.task_name !== 'undefined') ? props.FormValues.task_name : ''}
                            onChange={props.HandleOnChange}
                            error={(typeof props.Errors !== 'undefined' && typeof props.Errors.task_name !== 'undefined') && true}
                            helperText={(typeof props.Errors !== 'undefined' && typeof props.Errors.task_name !== 'undefined') ? props.Errors.task_name : ''}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            name="start_date"
                            id="start_date"
                            selectedDate={(typeof props.FormValues.start_date !== 'undefined') ? props.FormValues.start_date : null}
                            handleDateChange = {props.HandleDateChange}
                            datepickerType={'keyboard'}
                            error={(typeof props.Errors !== 'undefined' && typeof props.Errors.start_date !== 'undefined') ? props.Errors.start_date : ''}
                            required={true}
                            label={'Start Date'}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            name="end_date"
                            id="end_date"
                            selectedDate={(typeof props.FormValues.end_date !== 'undefined') ? props.FormValues.end_date : null}
                            handleDateChange = {props.HandleDateChange}
                            datepickerType={'keyboard'}
                            error={(typeof props.Errors !== 'undefined' && typeof props.Errors.end_date !== 'undefined') ? props.Errors.end_date : ''}
                            label={'End Date'}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="type" required >Type</InputLabel>
                        <Select
                            value={(typeof props.FormValues.type !== 'undefined') ? props.FormValues.type : '1'}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'type',
                                id: 'type',
                            }}>
                            {(typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.task_type !== 'undefined') && props.FormInputs.task_type.map((tt, key) =>
                                <MenuItem value={tt.id} >{tt.name}</MenuItem>
                            )}
                        </Select>
                        {(typeof props.Errors !== 'undefined' && typeof props.Errors.type !== 'undefined') &&
                        <FormHelperText className='error'>{props.Errors.type}</FormHelperText>
                        }
                    </ FormControl>
                </Grid>
                {(props.ShowTimeoffTypes) &&
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required >Time Off Type</InputLabel>
                        <Select
                            value={(typeof props.FormValues.timeoff_type !== 'undefined') ? props.FormValues.timeoff_type : ''}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'timeoff_type',
                                id: 'timeoff_type',
                            }}>
                            {(typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.timeoff_types !== 'undefined') && props.FormInputs.timeoff_types.map((timeofftype, key) =>
                                <MenuItem value={timeofftype.id} >{timeofftype.to_type}</MenuItem>
                            )}
                        </Select>
                        {(typeof props.Errors !== 'undefined' && typeof props.Errors.timeoff_type !== 'undefined') &&
                        <FormHelperText className='error'>{props.Errors.timeoff_type}</FormHelperText>
                        }
                    </ FormControl>
                </Grid>
                }
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="client" required >Status</InputLabel>
                        <Select
                            value={(typeof props.FormValues.status !== 'undefined') ? props.FormValues.status : '1'}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}>
                            {(typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.task_status !== 'undefined') && props.FormInputs.task_status.map((ts, key) =>
                                <MenuItem value={ts.task_status_id} >{ts.task_status_name}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>                
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="task_description"
                            name="task_description"
                            label="Description"
                            required
                            type="text"
                            multiline={true}
                            variant="outlined"
                            value={(typeof props.FormValues.task_description !== 'undefined') ? props.FormValues.task_description : ''}
                            onChange={props.HandleOnChange}
                            error={(typeof props.Errors !== 'undefined' && typeof props.Errors.task_description !== 'undefined') && true}
                            helperText={(typeof props.Errors !== 'undefined' && typeof props.Errors.task_description !== 'undefined') ? props.Errors.task_description : ''}
                        />
                    </ FormControl>
                </Grid>
                </Grid>
                </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div>
                    <Grid container spacing={1} >
                    <Grid item xs={12} md={12}>
                    <span>Resources</span>
                    <FormControl>
                        <Multiselect
                            options={(typeof props.FormInputs !== 'undefined' && typeof props.FormInputs.project_resources !== 'undefined') ? props.FormInputs.project_resources : []}
                            displayValue="user_fullname"
                            placeholder="Select Resources"
                            selectedValues={(typeof props.FormValues.resources !== 'undefined') ? props.FormValues.resources : []}
                            style = {mstyle}
                            onSelect={props.OnSelectResources}
                            onRemove={props.OnSelectResources}
                            avoidHighlightFirstOption
                            closeIcon = "cancel"
                        />
                    </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                    <Autocomplete
                        multiple
                        value={typeof props.FormValues.tags !== 'undefined' && props.FormValues.tags ?  props.FormValues.tags : [] }
		        onChange={props.HandleTaskChange }
                        options={typeof props.FormInputs.all_lables !== 'undefined' ? props.FormInputs.all_lables : []}
			getOptionLabel={(option, params) => {
			    if (!option.id ) {
                                return option.name+' (New Label)';
			    }
			    else {
				return option.name;
 			    } 
                        }}
			getOptionSelected={(option, value) => option.id === value.id}
			filterOptions={(options, params) => {
                            let filtered = filter(options, params);
			    if ((params.inputValue).trim().length < 3) {
         			 return [];
        		    }
			    else if ((params.inputValue).trim() !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `${params.inputValue}`,
                                    id : 0,
                                });
				return filtered;
                            }
                        }}
			autoHighlight={true}
		        filterSelectedOptions = {true}
                        id="task-label-demo"
			renderTags={(value, getTagProps) =>
        		value.map((option, index) => (
          			<Chip key={index} {...getTagProps({ index })}
            			label={option.name}
          			/>
        			))
      			}
                        renderInput={params => {
			return(
                            <TextField {...params} label="Add Lables" variant="outlined"
				 inputProps={{ ...params.inputProps, tabIndex: "13" }}
				/>
                        );
			}}
                    />

               </FormControl>
                    </Grid>
                     </Grid>
                    </div>
                </Grid>
            </Grid>
        </form>
        :
        <><span className={'error'}>{props.PermissionErrMsg}</span></>
        }
        </>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>
            <div>{props.msg}</div>
        </>
    )
}

const MoveTask = (props) => {
    return (
        <>
            {(props.TaskWithClin) &&
            <Box bgcolor="text.disabled" color="background.paper" p={2}>
                CLINs associated with the tasks will be unlinked 
            </Box>
            }
            <div className={'mt-1'}>
            <MaterialTreeView
                Data = {props.Data}
                OnChange = {props.HandleTaskTreeChange}
                RowIndex = {0}
            />
            </div>
        </>
    )
}
const DialogConfirmDates = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3} >
                <Grid item xs={12} md={12}>
                    <span>You have to set the end date to close this task.</span>
                </Grid>
                <Grid item xs={12} md={12} id="errorMsg">
                {(typeof props.Errors !== 'undefined' && typeof props.Errors.post_validation_error !== 'undefined' && props.Errors.post_validation_error !== '') &&
                    <span className={'error'}>{props.Errors.post_validation_error}</span>
                }
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                    <TextField
                        id="end_date"
                        name="end_date"
                        label="End Date"
                        type="date"
                        variant="standard"
                        required
                        value={(typeof props.FormValues.end_date !== 'undefined') ? props.FormValues.end_date : null}
                        onChange={props.HandleOnChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min : (typeof props.FormValues.start_date !== 'undefined') ? props.FormValues.start_date : null,
                        }}
                        error={(typeof props.Errors.end_date !== 'undefined') && true}
                        helperText={(typeof props.Errors.end_date !== 'undefined') && props.Errors.end_date}
                    />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const Filter = withStyles(filterStyles)(p => {
    return (<div role="presentation" className={p.classes.drawerList}>
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <FormControl className={p.classes.drawerFormControl} variant="standard">
                    <InputLabel htmlFor="status">Task Type</InputLabel>
                        <Select
                            inputProps={{
                                name: 'type',
                                id: 'type',
                            }}
                            value = {typeof p.FilterValues.type  !== 'undefined' && p.FilterValues.type }
                            onChange = {p.HandleFilterChange}
                        >
                            <MenuItem value={0} key={'st-0'}>All</MenuItem>
                            {(typeof p.FilterInputs.task_type !== 'undefined' && p.FilterInputs.task_type) && p.FilterInputs.task_type.map((tt, i) =>
                                <MenuItem value={tt.code} key={'st-'+i}>{tt.name}</MenuItem>
                            )}
                        </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <FormControl className={p.classes.drawerFormControl} variant="standard">
                    <InputLabel htmlFor="task_type">Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}
                            value = {typeof p.FilterValues.status  !== 'undefined' && p.FilterValues.status}
                            onChange = {p.HandleFilterChange}
                        >
                            <MenuItem value={0} key={'st-0'}>All</MenuItem>
                            {(typeof p.FilterInputs.statuses !== 'undefined' && p.FilterInputs.statuses) && p.FilterInputs.statuses.map((s, i) =>
                                <MenuItem value={s.task_status_id} key={'st-'+i}>{s.task_status_name}</MenuItem>
                            )}
                        </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item>
                <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
            </Grid>
        </Grid>
    </div>
    );
});

