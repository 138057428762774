import 'date-fns';
import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import parse from 'html-react-parser';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import { DialogBoxLarge } from '../../common/dialog.large';
import { formatName } from '../../common/common';
import { CustomDatePicker } from '../../common/CustomDatePicker';

const systemSuggestionInfo = "This is automatically suggested by the system for the best use of your unused paid time off. Uncheck if you would like to manually plan your time off usage.";
const StyledInformation = styled('div')(({ theme }) => ({    
    '& .root': {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    '& .tableCell': {
        fontSize: '13px', borderBottom: "none"
    },
}));
export const TimeOffAddComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxLarge
                Open={(props.GrantOpen) ? props.GrantOpen : props.Open}
                Cancel={props.OnClose}
                ButtonName='Submit' 
                OnSubmit={props.SubmitTimeOff}
                ButtonTabIndex = "16"
                Title={(props.GrantOpen) ? props.Title : (props.Title + ' Time Off Request')}
                Loading = {props.ButtonLoader}
                HideSaveButton = {!props.IsEdit}
                Content={
                    <DialogAddTimeOff FormProps={props} />
                }
            />
        </React.Fragment>
    )
}

const DialogAddTimeOff = (p) => { 
    const { FormProps: props } = p;
    if (props.ValidationErrors && Object.keys(props.ValidationErrors).length > 0) {
        let element = document.getElementById('errorMsg');         
        if (element) {
            element.scrollIntoView(true);
        }
    }    
    if (props.ErrorsArr && props.ErrorsArr.length > 0) {
        let element = document.getElementById('errorMsg'); 
        if (element) {
            element.scrollIntoView(true);
        }
    }
    let data = props.FormValues ? props.FormValues : {};
    const TimeoffComponent = () => {
        return (
            <>
                {props.DisplaySuggestionFlag &&
                    <Grid item xs={12} md={12}>
                        {(props.SuggestionError == '') &&
                        <Tooltip title={systemSuggestionInfo}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={props.HandleSystemSuggestionChange}
                                        value={(props.FormValues && typeof props.FormValues.systemSuggestion !== 'undefined') ? props.FormValues.systemSuggestion : false}
                                        checked={(props.FormValues && typeof props.FormValues.systemSuggestion !== 'undefined') ? props.FormValues.systemSuggestion : false}
                                        color="primary"
                                        tabIndex="3"
                                    />
                                }
                                label="System Suggestion"
                            />
                        </Tooltip>
                        }
                        {props.SuggestionChanged && <span class="alert alert-danger">System suggestion has changed.</span>}
                        {(props.FormValues.systemSuggestion || props.SuggestionError !== '') &&
                            <>
                                {(props.SuggestionError && props.SuggestionError !== '') ?
                                    <div className={'te-task-helper'}>
                                        <FormHelperText className='error'>{props.SuggestionError}</FormHelperText>
                                    </div>
                                    :
                                    <div className={'te-task-helper'}>
                                        {props.SplitInfo.split('\n').map(tr => <p>{tr}</p>)}
                                    </div>
                                }
                            </>
                        }
			</Grid>
                }
                {((props.DisplaySuggestionFlag && props.SuggestionError !== '') || !props.DisplaySuggestionFlag || (props.DisplaySuggestionFlag && !props.FormValues.systemSuggestion)) &&
                    <Grid item xs={12} md={6}>
                        <FormControl variant = 'standard'>
                            <InputLabel htmlFor="timeoffTask" required >Time Off Task</InputLabel>
                            <Select
                                value={(typeof data.timeoffTask !== 'undefined') ? data.timeoffTask : ""}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'timeoffTask',
                                    id: 'timeoffTask',
                                    tabIndex: (typeof props.GrantOpen !== 'undefined' && props.GrantOpen) ? "2" : "4",
                                }}
                                error={props.ValidationErrors.task && true}>
                                {typeof props.FormInputs.timeoff_tasks !== 'undefined' && props.FormInputs.timeoff_tasks.map((tt, i) =>
                                    <MenuItem value={tt.task_id} >{tt.task_name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText className='error'>{props.ValidationErrors.task}</FormHelperText>
                        </ FormControl>
                    </Grid>
                }
                {((props.DisplaySuggestionFlag && props.SuggestionError !== '') || !props.DisplaySuggestionFlag || (props.DisplaySuggestionFlag && !props.FormValues.systemSuggestion)) &&
                    <StyledInformation>
		    <>
		    <Grid item xs={12} md={12}>
                        {( (typeof props.TimeoffDetails !== 'undefined' && typeof props.TimeoffDetails.view_object !== 'undefined') && Object.keys(props.TimeoffDetails.view_object).length !== 0) &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colspan='3'><b>{props.TimeoffDetails.to_type} Information</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.TimeoffDetails.view_object.map((item) =>
                                     (item.hours != 'N/A') && ((item.hours < 0) ?
                                        <TableRow>
                                            <TableCell classes={{ root: 'tableCell' }} className= 'error'>{item.label}</TableCell>
                                            <TableCell classes={{ root: 'tableCell' }} align="right" className= 'error'>{item.hours} Hours</TableCell>
                                            <TableCell classes={{ root: 'tableCell' }} align="right" className= 'error'>{item.days} Days</TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell classes={{ root: 'tableCell' }}>{item.label}</TableCell>
                                            <TableCell classes={{ root: 'tableCell' }} align="right">{item.hours} Hours</TableCell>
                                            <TableCell classes={{ root: 'tableCell' }} align="right">{item.days} Days</TableCell>
                                        </TableRow>)
                                    )}
                                </TableBody>
                            </Table>
                        }
		    </Grid>
		    </>
                    </StyledInformation>
                }
                {(props.ShowCoff && (typeof props.AvailableCoffDates !== 'undefined' && Array.isArray(props.AvailableCoffDates))) &&
                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            value={(typeof props.FormValues.coff_dates !== 'undefined' && props.FormValues.coff_dates) ? props.FormValues.coff_dates : []}
                            onChange={props.HandleCompDateChange}
                            multiple
                            id="coff_date"
                            required
                            options={(typeof props.AvailableCoffDates !== 'undefined' && Array.isArray(props.AvailableCoffDates)) ? props.AvailableCoffDates : []}
                            getOptionLabel={(option) => option.fm_date}
                            getOptionSelected={(option, value) => option.fm_date === value.fm_date}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                    id="coff_date"
                                    label="Compensatory Off Dates"
                                    placeholder="Compensatory Off Dates"
                                    error={props.ValidationErrors.coff_date && true}
                                    helperText={props.ValidationErrors.coff_date}
                                />
                            )}
                        />
                    </Grid>
                }
            </>
        );
    }

    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit} >
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} id="errorMsg">
                    {(props.ErrorsArr && props.ErrorsArr.length > 0) &&
                        <>
                            {props.ErrorsArr.map((er, i) =>
                                <><span className={'error'}>{parse(er)}</span><br /></>
                            )}
                        </>
                    }
                </Grid>
                { props.IsEdit &&
                <>
                    <Grid item md={6} xs={12} className="item-1" >
                        <Grid container spacing={3}>
                            {props.GrantOpen && <Grid item xs={12} md={12}>
                                <FormControl variant = 'standard'>
                                    <InputLabel htmlFor="hpd" required >Select Staff</InputLabel>
                                    <Select
                                        value={(typeof props.FormValues.employeeId !== 'undefined') ? props.FormValues.employeeId : ''}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'employeeId',
                                            id: 'employeeId',
                                            tabIndex: '1'
                                        }}>
                                        {typeof props.FormInputs.users_list !== 'undefined' && props.FormInputs.users_list.map((ul, i) =>
                                            <MenuItem value={ul.user_id} >{formatName(ul.first_name, null, ul.last_name, 'FIRSTLAST')}</MenuItem>
                                        )}
                                    </Select>
                                    <small className='error'>{props.ValidationErrors.user_id && props.ValidationErrors.user_id}</small>
                                </ FormControl>
                            </Grid>}
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <CustomDatePicker
                                        id="startDate"
                                        name="startDate"
                                        selectedDate={data.startDate}
                                        handleDateChange={props.HandleDateChange}
                                        required={true}
                                        label={'Start Date'}
                                        minDate={(typeof props.FormInputs.joined_date !== 'undefined') ? props.FormInputs.joined_date : null}
                                        maxDate={props.FormInputs.relieved_date}
                                        tabIndex={(typeof props.GrantOpen !== 'undefined' && props.GrantOpen) ? "3":"1"}
                                    />
                                    <small className='error'>{props.ValidationErrors.sdate && props.ValidationErrors.sdate}</small>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <CustomDatePicker
                                        id="endDate"
                                        name="endDate"
                                        selectedDate={data.endDate}
                                        handleDateChange={props.HandleDateChange}
                                        required={true}
                                        label={'End Date'}
                                        minDate={data.startDate ? data.startDate : ((typeof props.FormInputs.joined_date !== 'undefined') ? props.FormInputs.joined_date : null)}
                                        maxDate={props.FormInputs.relieved_date}
                                        tabIndex={(typeof props.GrantOpen !== 'undefined' && props.GrantOpen) ? "4" : "2"}
                                    />
                                    <small className='error'>{props.ValidationErrors.edate && props.ValidationErrors.edate}</small>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl>
                                    <TextField
                                        id="reason"
                                        name="reason"
                                        label="Reason"
                                        required
                                        type="text"
                                        multiline={true}
                                        variant="outlined"
                                        value={(typeof data.reason !== 'undefined') ? data.reason : ''}
                                        onChange={props.HandleOnChange}
                                        error={props.ValidationErrors.reason && true}
                                        helperText={props.ValidationErrors.reason}
                                        inputProps={{ tabIndex: "5" }}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant = 'standard'>
                                    <InputLabel htmlFor="hpd" required >Requested Hours</InputLabel>
                                    <Select
                                        value={(typeof props.FormValues.hoursPerDay !== 'undefined') ? props.FormValues.hoursPerDay : ''}
                                        onChange={props.HandleOnChange}
                                        error={props.ValidationErrors.hoursPerDay && true}
                                        inputProps={{
                                            name: 'hoursPerDay',
                                            id: 'hoursPerDay',
                                            tabIndex: "7"
                                        }}>
                                        {typeof props.HoursPerDay !== 'undefined' && props.HoursPerDay.map((h, i) =>
                                            <MenuItem value={h.value} disabled={h.disabled}>{h.label}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText className='error'>{props.ValidationErrors.hoursPerDay}</FormHelperText>
                                    {(props.Duration && props.Duration !== '') &&
                                        <div className={'te-task-helper'}>
                                            <FormHelperText>Requested Hours : {props.Duration}</FormHelperText>
                                        </div>
                                    }
                                </ FormControl>
                            </Grid>
                            {props.FormValues.hoursPerDay === "hour" && props.AllowPartialHours &&
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <TextField
                                            required
                                            id="hours"
                                            name="hours"
                                            label="Hours"
                                            type="text"
                                            placeholder="[Format: nn.nn or HH:MM ]"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={(typeof props.FormValues.hours !== 'undefined') ? props.FormValues.hours : null}
                                            onChange={props.HandleOnChange}
                                            error={props.ValidationErrors.hours && true}
                                            helperText={props.ValidationErrors.hours}
                                            inputProps={{ tabIndex: "8" }}
                                            variant = 'standard'
                                        />
                                    </ FormControl>
                                </Grid>
                            }
                        </Grid>
                        <Grid container className="show-mobile-view" spacing={3}>
                            <TimeoffComponent />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <TextField
                                        id="refNo"
                                        name="refNo"
                                        label="Reference No"
                                        type="text"
                                        value={(data.refNo !== 'undefined') ? data.refNo : null}
                                        onChange={props.HandleOnChange}
                                        error={props.ValidationErrors.refNo && true}
                                        helperText={props.ValidationErrors.refNo}
                                        inputProps={{ tabIndex: "9" }}
                                        variant = 'standard'
                                    />
                                </ FormControl>
                            </Grid>
                            {data.file_name &&
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <TextField
                                            id="file_name"
                                            name="file_name"
                                            label="File Name"
                                            type="text"
                                            value={(typeof data.file_name !== 'undefined') ? data.file_name : null}
                                            disabled="true"
                                            inputProps={{ tabIndex: "10" }}
                                            variant = 'standard'
                                        />
                                    </ FormControl>
                                </Grid>
                            }
                            {data.file_name &&
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(typeof data.remove_file !== 'undefined') ? data.remove_file : false}
                                                value={(typeof data.remove_file !== 'undefined') ? data.remove_file : null}
                                                id="remove_file"
                                                name="remove_file"
                                                onChange={props.HandleOnChange}
                                                inputProps={{ tabIndex: "11" }}
                                            />
                                        }
                                        label="Remove attachment"
                                    />
                                </Grid>
                            }
                            {data.remove_file &&
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <TextField
                                            id="file"
                                            name="file"
                                            label={(typeof props.FormValues.file !== 'undefined' && props.FormValues.file) ? "Replace file" : "Upload File"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="file"
                                            value={(typeof props.FormValues.file !== 'undefined') ? props.FormValues.file : null}
                                            onChange={props.HandleOnChange}
                                            inputProps={{ tabIndex: "12" }}
                                            variant = 'standard'
                                        />
                                    </ FormControl>
                                </Grid>
                            }
                            {props.FormInputs.recipients &&
                                <Grid item xs={12} md={12}>
                                    <ul className="list-standard list-ul-shrink">
                                        <label><b>Email Recipients</b></label>
                                        <li className="list-item"></li>
                                        {(typeof props.FormInputs.recipients !== 'undefined' && props.FormInputs.recipients) && Object.keys(props.FormInputs.recipients).map((key) =>

                                            <li className="list-item">
                                                {(typeof props.FormInputs.approvers !== 'undefined' && props.FormInputs.approvers && props.FormInputs.approvers.indexOf(props.FormInputs.recipients[key]['user_id']) !== -1) ?
                                                    <span className="value">{formatName(props.FormInputs.recipients[key]['first_name'], null, props.FormInputs.recipients[key]['last_name']) + ' (Reviewer)'}</span>
                                                    :
                                                    <span className="value">{formatName(props.FormInputs.recipients[key]['first_name'], null, props.FormInputs.recipients[key]['last_name'])}</span>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                </Grid>
                            }
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    value={typeof data.custom_recipient !== 'undefined' ? data.custom_recipient : []}
                                    onChange={props.HandleRecipientChange}
                                    multiple
                                    id="tags-outlined"
                                    options={(typeof props.FormInputs.custom_recipients_list !== 'undefined' && Array.isArray(props.FormInputs.custom_recipients_list)) ? props.FormInputs.custom_recipients_list : []}
                                    getOptionLabel={(option) => formatName(option.user_first_name, null, option.user_last_name)}
                                    getOptionSelected={(option, value) => option.user_id === value.user_id}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Other Recipients"
                                            placeholder="Other Recipients"
                                            inputProps={{ ...params.inputProps, tabIndex: "13" }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} className="item-2" >
                        <Grid container spacing={3} className="hide-mobile-view">
                            <TimeoffComponent />
                        </Grid>
                    </Grid>
                </>
                }
            </Grid>
        </form>
    );
}
