import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Multiselect } from 'multiselect-react-dropdown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

const API_URL = process.env.REACT_APP_API_URL;
export const StaffCountReportComponent = (props) => {
     const gridStyle = {
        margin: '15px 0px'
    };
    const formValues = props.FormFields ? props.FormFields : {};
    const formInputs = props.FormInputs ? props.FormInputs : {};
    const mstyle={ chips: { background: "#f4f4f4", color: 'black' }}
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Staff Count Report</h6>
                </Grid>
                <Grid item>
                <form className="form-fullwidth" action={API_URL+'api/report/staffCountReport'} method="post" id="staffCountForm">
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "start_date"
                                    name = "start_date"
                                    label = "From Date"
                                    type = "date"
                                    required
                                    onChange = {props.HandleOnChange}
                                    value={(typeof formValues.start_date !== 'undefined') ? formValues.start_date : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    error={props.Errors.start_date && true}
                                    helperText={props.Errors.start_date}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "end_date"
                                    name = "end_date"
                                    label = "To Date"
                                    required
                                    type = "date"
                                    value={(typeof formValues.end_date !== 'undefined') ? formValues.end_date : null}
                                    onChange = {props.HandleOnChange}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    error={props.Errors.end_date && true}
                                    helperText={props.Errors.end_date}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl error={props.Errors.report_type && true} variant="standard">
                                <InputLabel htmlFor="staff_type">Report Type</InputLabel>
                                <Select
                                    value={(typeof formValues.report_type !== 'undefined') ? formValues.report_type : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'report_type',
                                        id: 'report_type',
                                    }}
                                >
                                    { typeof formInputs.report_types != 'undefined' && formInputs.report_types.map((t, i) =>
                                    <MenuItem value={t.value}>{t.name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.report_type}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-content">
                                    <Grid>
                                        <input type='hidden' name='staff_type' value={formValues.staff_type}/>
                                        <FormControl>
                                             <Multiselect
                                                options={formInputs.staff_types}
                                                displayValue="type"
                                                placeholder="search Staff Type"
                                                selectedValues={props.SelectedStaffTypes}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption ={true}
                                                closeIcon="cancel"
                                            />
                                        </FormControl>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-content">
                                    <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={props.HandleOnChange}
                                                    value={formValues.genderwise_report_flag}
                                                    checked={formValues.genderwise_report_flag}
                                                    inputProps={{
                                                        name: 'genderwise_report_flag',
                                                        id: 'genderwise_report_flag',
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Gender based count"
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-content">
                                    <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={props.HandleOnChange}
                                                    value={formValues.exclude_work_location_flag}
                                                    checked={formValues.exclude_work_location_flag}
                                                    inputProps={{
                                                        name: 'exclude_work_location_flag',
                                                        id: 'exclude_work_location_flag',
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Exclude work location"
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={gridStyle}>
                        {(props.DisplayLocation === true) &&
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-content">
                                    <Grid>
                                        <input type='hidden' name='work_locations' value={formValues.work_locations}/>
                                        <FormControl>
                                            <Multiselect
                                                options={formInputs.work_locations}
                                                displayValue="short_code"
                                                placeholder="Search Work Location"
                                                selectedValues={props.SelectedWorkLocations}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption ={true}
                                                closeIcon="cancel"
                                            />
                                        </FormControl>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        }
                        { formValues.genderwise_report_flag && 
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-content">
                                    <Grid>
                                        <input type='hidden' name='gender' value={formValues.gender}/>
                                        <FormControl>
                                             <Multiselect
                                                options={formInputs.gender_opts}
                                                displayValue="title"
                                                placeholder="Gender"
                                                selectedValues={props.SelectedGenderOpts}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption ={true}
                                                closeIcon="cancel"
                                            />
                                        </FormControl>

                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <div class="filter-column">
                                <div class="d-flex align-center justify-end pr-2">
                                    <Button variant="contained" size="small" color="primary" value='export_excel' name="export_excel"  onClick={props.FormSubmit} >Export</Button>
                                </div>
                            </div>
                        </Grid> 
                    </Grid>
                </form>
                </Grid>
            </Grid>
         </Paper>
         </>
     )
}
