import React, { Component } from 'react';
import { UserConstants, SSO_AUTH_URL, GOOGLE_AUTH_URL } from '../../constants/';
import MixpanelManager from  '../../services/Analytics';
import { withStyles } from '@mui/styles';
import { LoginPage } from './login.component'
import { httpServices } from '../../services/httpServices';

class LoginConatiner extends Component{
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            nameFormat : 'FIRSTLAST',
            info: [],
            loginError : false,
            errors : {},
            authProviders : []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        httpServices.get('api/user/appinfo')
            .then(response => {
                if (response.data) {
                    if (response.data.auth_providers.length === 1) {
                        let auth_provider = response.data.auth_providers[0]['title'];
                        if (auth_provider ==='sso') {
                            console.log(SSO_AUTH_URL);
                            window.location = SSO_AUTH_URL;
                        }
                        else if (auth_provider === 'google'){
                            window.location = GOOGLE_AUTH_URL;
                        }
                    }
                    this.setState({
                        info: response.data.company_info,
                        authProviders : response.data.auth_providers
                    });
                    let app_title = response.data.company_info.short_name ? response.data.company_info.short_name + ' STEPS' : 'STEPS';
                    document.title = app_title; 
                }
            })
            .catch(function(error) {
            });
    }
    handleChange(e) {
        if (this.state.loginError === true) {
            this.setState({ 
                loginError: false  
            });
        }
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e, authmode) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            const { username, password } = this.state;
            if (username && password) {
                this.authenticate(username, password,authmode);
            }
        }
    }
    //login action
    authenticate(username, password, authtype) {
        let user = {
            username: username,
            password: password
        };
        let apiEndpoint = UserConstants.LOGIN_ROUTE + '?authtype=' + authtype;
        return httpServices.post(apiEndpoint, user)
            .then(response => {
                if (response.data.status) {                    
                    let mx = new MixpanelManager();
                    mx.trackEvent('Log in', { 'message': "Succefully logged in with " + username + " and auth type: " + authtype });
                    window.location = UserConstants.AFTER_LOGIN_ROUTE;                   
                }
                else {
                    this.setState({
                        loginError : true
                    });
                }
            })
            .catch(function (error) {
            });
    
    };  
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.username === undefined || this.state.username.trim() == '' ) {
            formIsValid = false;
            errors["username"] = "Cannot be empty";
        }
        if (this.state.password === undefined || this.state.password.trim() == '' ) {
            formIsValid = false;
            errors["password"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    
    render(){
        const { classes, loggingIn, alert } = this.props;
        const { username, password, submitted ,info } = this.state;
        return(
            <LoginPage
                username={username}
                password={password}
                submitted={submitted}
                loggingIn={loggingIn}
                alert={alert}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                companyInfo={info}
                LoginError={this.state.loginError}
                Errors={this.state.errors}
                AuthProviders={this.state.authProviders}
            />

        );
    }
}
const connectedLoginPage = LoginConatiner;
export { connectedLoginPage as LoginPage }; 
