import React from 'react';
import {AuditLogComponent} from './AuditLogComponent';
import {httpServices}  from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const formFields = {
    'staff' :'0' ,
    'start_date' : '',
    'end_date' : '',
    'module' : '0',
}

const formInputs = {
    staff_list : [],
    auditlog_list : [],
}
export class AuditLog extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formFields: formFields,
            formInputs: formInputs,
            hasPermission : true,
            modules : [],
            changeHistory : {},
            open : false,
            showLoader : true,
            dataLoaded : false,
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.showChangeHistory = this.showChangeHistory.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount = () => {
        httpServices.get('api/system/auditlog')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        formFields : {
                            staff : inputs.staff,
                            start_date : inputs.start_date,
                            end_date :inputs.end_date
                        },
                        formInputs :{
                            staff_list : inputs.staff_list
                        },
                        modules : inputs.modules ? inputs.modules : [],
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        dataLoaded : true,
                        showLoader : false,
                    });
                }
            });
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    }
     OnSearchClick = () => {
        let user_id = this.state.formFields.staff;
        let start_date = this.state.formFields.start_date;
        let end_date = this.state.formFields.end_date;
        let module = this.state.formFields.module;
        let data = {user_id : user_id, start_date : start_date, end_date : end_date, module : module}
        if(this.validateForm()){
            this.setState({
                showLoader : true,
            }); 
            httpServices.post('api/system/auditlog', data)
                .then(response => {
                    if (response.data) {
                         this.setState({
                            formInputs:{
                                staff_list : this.state.formInputs.staff_list,
                                auditlog_list:response.data.audit_log,
                                },
                            showLoader : false,
                        }); 
                }
            });
        }
    }
    validateForm()
    {
        let formIsValid = true;
        let errors = {};
        let start_date=this.state.formFields.start_date;
        let end_date=this.state.formFields.end_date;
        if (this.state.formFields.staff === 0) {
            errors["staff"] = "Select a staff";
            formIsValid = false;
        }
        if (!start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        else if(start_date && end_date){
            if(new Date(end_date) < new Date(start_date)){
                errors["end_date"] = "End date should be greater than start date";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    
    showChangeHistory = (auditId) => {
        httpServices.post('api/system/getChangeHistoryByauditlog',{id : auditId})
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        changeHistory : response.data.change_history ? response.data.change_history : {},
                        open : true,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            });
    }

    onClose = () => {
        this.setState({
            open : false,
        });
    }

    handleDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };

    render() {
        return (
            <>
                <CustomProgressBar loaderOpen={this.state.showLoader} />
                {(this.state.dataLoaded) ?
                    <>
                        {(this.state.hasPermission)
                            ?
                            <AuditLogComponent
                                formInputs={this.state.formInputs}
                                ShowLoader={this.state.showLoader}
                                formFields={this.state.formFields}
                                Errors={this.state.errors}
                                HandleOnChange={this.HandleOnChange}
                                OnSearchClick={this.OnSearchClick}
                                Modules={this.state.modules}
                                ShowChangeHistory={this.showChangeHistory}
                                Open={this.state.open}
                                Close={this.onClose}
                                ChangeHistory={this.state.changeHistory}
                                HandleDateChange={this.handleDateChange}
                            />
                            : <ErrorPage />
                        }
                    </>
                    : <></>}
            </>
        )
    }
}
