import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const PersonalExpense = (props) => {
    const [expanded, setExpanded] = React.useState('panel5');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };   
    return (
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
         <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            > 
        <Typography variant="subtitle1" gutterBottom>Personal Expense Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Table size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>{props.YtdPaidLabel}</TableCell>
            <TableCell>{props.YtdUnpaidLabel}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                <TableCell component="th" scope="row"> {props.PersonalExpense.year} </TableCell>
                <TableCell component="th" scope="row"> {props.PersonalExpense.personal_expense_paid1} </TableCell>
                <TableCell component="th" scope="row"> {props.PersonalExpense.personal_expense_unpaid1} </TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </AccordionDetails>
        </Accordion>
    );
}                                                    
