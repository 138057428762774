import React from 'react';
import Grid from '@mui/material/Grid';
import {StatesView}  from './States.Container';
import { CityContainer }  from './City.Container';

export class StateCity extends React.Component{ 
    render(){       
        return (
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                <Grid  item xs={12} md={6}>
                    <StatesView/>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <CityContainer/>
                </Grid>
            </Grid>
        )
    }
}

