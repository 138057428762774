import React from 'react';
import * as _R from 'ramda';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import parse from 'html-react-parser';

import { TimeSheetView } from '../../timesheet/details/TimeSheetView';
import { formatName, nl2br } from '../../common/common';
import { HoursByProject } from '../../timesheet/details/HoursByProject';
import { StatusChangeLog } from '../../timesheet/details/StatusChangeLog';
import { ProjectReviewStatus } from '../../timesheet/details/ProjectReviewStatus';
import { LateSubmission } from '../../timesheet/details/LateSubmission';
import { TimeOffRequests } from '../../timesheet/details/TimeOffRequests';
import { LocationChangeRequest } from '../../timesheet/details/LocationChangeRequest';
import { TimeOffLog } from '../../timesheet/details/TimeOffLog';
import { ClearanceConfirmation } from '../../timesheet/details/ClearanceConfirmation';
import Tooltip from '@mui/material/Tooltip';


export const TimeSheetDetails = (props) => {
     //Tabs
    const [tabValue, setTabValue] =  React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const { FormData } = props;
    const timesheet = (_R.type(FormData.timesheet) !== 'Undefined' ) ? FormData.timesheet : [];


    return (
        <>
        <div className={'section-main section-main-header-fixed'}>
            <div className={'header-fixed'} >
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                        <Typography variant="h6" component="h6" className={'section-title'}>
                            Timesheet of {formatName(timesheet.user_first_name,null,timesheet.user_last_name)}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
        <div className={'section-body'}>
            <Paper className={'mb-1'}>
                <div className={'timesheet_list-summary'}>
                    <div className={'p-1rem summary box-details'}>
                        <ul className={'list-standard'}>
                            <li className={'list-item list-item-status'}>
                                <label>Staff Name</label>
                                <span className={'value'}>{formatName(timesheet.user_first_name,null,timesheet.user_last_name)}</span>
                            </li>
                            <li className={'list-item'}>
                                <label>Pay Period</label>
                                <span className={'value'}>{timesheet.payperiod_sdate} - {timesheet.payperiod_edate}</span>
                            </li>
                            <li className={'list-item'}>
                                <label>Status</label>
                                <span className={'value'}>{timesheet.status_name}</span>
                            </li>
                            {(timesheet.status == '1') &&
                                <li className={'list-item'}>
                                    <label>Saved on</label>
                                    <span className={'value'}>{timesheet.created_time}</span>
                                </li>
                            }
                            {(timesheet.status == '2') &&
                            <>
                                <li className={'list-item'}>
                                    <label>Submitted by</label>
                                    <span className={'value'}>{formatName(timesheet.sby_first_name,null,timesheet.sby_last_name)}</span>
                                </li>
                                 <li className={'list-item'}>
                                    <label>Submitted on</label>
                                    <span className={'value'}>{timesheet.submitted_time}</span>
                                </li>
                            </>
                            }
                            {(timesheet.status == '3') &&
                            <>
                                <li className={'list-item'}>
                                    <label>Approved by</label>
                                    <span className={'value'}>{formatName(timesheet.rby_first_name,null,timesheet.rby_last_name)}</span>
                                </li>
                                 <li className={'list-item'}>
                                    <label>Approved on</label>
                                    <span className={'value'}>{timesheet.reviewed_time}</span>
                                </li>
                            </>
                            }
                            {(timesheet.status == '4') &&
                                <li className={'list-item'}>
                                    <label>Rejected on</label>
                                    <span className={'value'}>{timesheet.reviewed_time}</span>
                                </li>
                            }
                        </ul>
                        <ul className={'list-standard list-item-right'}>
                                    {(_R.type(props.FormData.total_hours) !== 'Undefined' && props.FormData.total_hours.length > 0) && props.FormData.total_hours.map((t_hrs,index)=>
                                    <>{(parseInt(t_hrs.task_type) !== 0 && t_hrs.code === "WH") ?
                                            <>
                                                {(_R.type(props.FormData.ts_summary) !== 'Undefined' && typeof props.FormData.ts_summary.location_hours !== 'undefined' && props.FormData.ts_summary.location_hours.length > 0) && props.FormData.ts_summary.location_hours.map((l_hrs) =>
                                                    <li className={'list-item list-items-onsite-hours'}>
                                                        <label>Hours ({l_hrs.location})</label>
                                                        <span className={'value right'}>{parseFloat(l_hrs.location_hours).toFixed(2)}</span>
                                                    </li>
                                                )}
                                            </>
                                        :<>
                                        <li className={'list-item list-items-onsite-hours'}>
                                            <label>{t_hrs.name}</label>
                                            <span className={'value right'}>{t_hrs.hours}</span>
                                        </li>
                                        </>}
                                    </>
                                    )}
                                    <li className={'list-item list-items-hours'}>
                                        <label>Total Hours</label>
                                        <span className={'value right'}>{_R.type(props.FormData.ts_summary) !== 'Undefined' ? props.FormData.ts_summary.hours_saved : ''}</span>
                                    </li>
                                    <li className={'list-item list-items-hours'}>
                                        <label>Min. Hours Required</label>
                                        <span className={'value right'}>{_R.type(props.FormData.min_hours) !== 'Undefined' ? parseFloat(props.FormData.min_hours).toFixed(2) : ''}</span>
                                    </li>                               
                                </ul>
                                <ul className={'list-standard list-item-right'}>
                                    {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                                        <>
                                            <li className={'list-item'}>
                                                <label>Delayed By</label>
                                                <span className={'value'}>{(typeof props.FormData.late_submission_details.delayed_by !== 'undefined') ? props.FormData.late_submission_details.delayed_by : ""}</span>
                                            </li>
                                            <li className={'list-item'}>
                                                <label>Reason For Late Submission</label>
                                                <span className={'value'}>{(typeof props.FormData.late_submission_details.late_submission_reason !== 'undefined' && props.FormData.late_submission_details.late_submission_reason) ? parse(nl2br(props.FormData.late_submission_details.late_submission_reason)) : ""}</span>
                                            </li>
                                            <li className={'list-item'}>
                                                <label>Late Submission Review Comment</label>
                                                <span className={'value'}>{(typeof props.FormData.late_submission_details.waiver_justification !== 'undefined' && props.FormData.late_submission_details.waiver_justification) ? parse(nl2br(props.FormData.late_submission_details.waiver_justification)) : ""}</span>
                                            </li>
                                        </>
                                    }
                                </ul>
                    </div>
                </div>
            </Paper>
            <div className={'timesheet_list'}>
                        <TimeSheetView
                            UserTimeIn = {props.FormData.user_timein}
                            UserTimeInReq = {props.FormData.user_timein_required}
                            UserTimeInDetails = {props.FormData.timeinout_rows}
                            Timesheet={props.FormData.timesheet}
                            TimeinTotal = {props.FormData.timein_total}
                            TotalHours = {props.FormData.total_hours}
                            TsSummary = {props.FormData.ts_summary}
                            MinHours = {props.FormData.min_hours}
                            DayOff = {props.FormData.day_off ? props.FormData.day_off.split(',') : []}
                            FwsVarianceInfo = {props.FormData.fws_var_info ? props.FormData.fws_var_info : {}}
                            listReviewNotes = {props.listReviewNotes}
                            ClientsCount = {props.FormData.clients_count ? parseInt(props.FormData.clients_count) : 0}
                            onTimeEntryEditClick = {props.onTimeEntryEditClick}
                            listActionMenuClick = {props.listActionMenuClick}
                            clickedTimeSliceId = {props.clickedTimeSliceId}
                            timeSliceEditDialogOpen = {props.timeSliceEditDialogOpen}
                            timeSliceEditDialogClose = {props.timeSliceEditDialogClose}
                            DialogOpen = {props.DialogOpen}
                            OnClose = {props.OnClose}
                            getTimesheetDetails = {props.getTimesheetDetails}
                            randomNumber = {props.randomNumber}
                            ViewType = {props.ViewType}
                            ReviewNotes = {props.ReviewNotes}
                            onTimeEntryDeleteClick = {props.onTimeEntryDeleteClick}
                            HandleTimeEntryDeleteReason = {props.HandleTimeEntryDeleteReason}
                            TimeEntryDeleteReason = {props.TimeEntryDeleteReason}
                            TimesliceDelete = {props.TimesliceDelete}
                            TimeEntryDeleteError = {props.TimeEntryDeleteError}
                            TimesheetId = {props.TimesheetId}
                            RequestedPage = {'payperiod-status-report-details'}
                            ForceRerender = {props.ForceRerender}
                            OnDetailsClick = {props.OnDetailsClick}
                            TimeSliceDataView = {props.TimesliceData}
                            PopupType = {'timeslice-report-view'}
                            Comments = {props.Comments}
                            History = {props.History}
                        />
                    </div>
        </div>
        <div className={''}>
                <Paper>
                    <div className={'tab-navContainer d-flex justify-between'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="Timesheet List View Tabs"
                            >
                                <Tab label="Hours By Project"/>
                                <Tab label="Status Change Log"/>
                                <Tab label="Time Off Request"/>
                                {(props.FormData.multiple_review === 1) &&
                                    <Tab label="Project Review Status"/>
                                }
                                {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                                   <Tab label="Late Submission Status"/>
                                }
                                <Tab label="Work Location Change Request"/>
                                <Tab label="Time Off Log"/>
                                {(props.FormData.user_clearence_level) &&
                                    <Tab label="Clearance Confirmation"/>
                                }
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabValue} index={0}>
                                <HoursByProject
                                    HoursByProject={props.FormData.chargecode_summary}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <StatusChangeLog
                                    StatusChangeLog={props.FormData.history}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <TimeOffRequests
                                    TimeOffRequests={props.FormData.time_off}
                                />
                            </TabPanel>
                            {(props.FormData.multiple_review === 1) &&
                                <TabPanel value={tabValue} index={3}>
                                    <ProjectReviewStatus
                                        ProjectReviewStatus={props.FormData.project_review}
                                        MultipleReview = {props.FormData.multiple_review}
                                    />
                                </TabPanel>
                            }
                            {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                            <TabPanel value={tabValue} index={(props.FormData.multiple_review === 1) ? 4 : 3}>
                                <LateSubmission
                                   LateSubmissionDetails = {props.FormData.late_submission_details}
                                />
                            </TabPanel>
                            }
                            <TabPanel value={tabValue} index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 5 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 4 : 3}>
                                <LocationChangeRequest
                                    OffsiteRequest = {props.FormData.offsites}
                                />
                            </TabPanel>
                             <TabPanel value={tabValue} index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 6 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 5 : 4}>
                                <TimeOffLog
                                    TimeOffLog = {props.FormData.timeofflog}
                                />
                            </TabPanel>
                            {(props.FormData.user_clearence_level) &&
                                <TabPanel value={tabValue}  index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 7 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 6 : 5}>
                                    <ClearanceConfirmation
                                        ClearanceConfirmation = {props.FormData.selectedClearanceOptions}                                    
                                    />                            
                                </TabPanel>
                            }
                        </div>
                </Paper>
            </div>
        </>
    )
}
