import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { DialogBoxLarge } from '../../common/dialog.large';
import { ChangeHistory } from '../../requests/Common/ChangeHistory';
import { Attachments } from '../Common/Attachments';

import * as _R from 'ramda';

export const ViewExpenseComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxLarge
                Open={props.dialogOpen}
                Cancel={props.handleCancel}
                OnSubmit={props.handleCancel}
                Title={'Expense Item'}
                HideSaveButton={true}
                Content={
                    <ExpenseViewComponent FormProps={props} />
                }
            />
        </React.Fragment>
    )
}
const ExpenseViewComponent = (props) => {
    const FormProps = props.FormProps;
    const History = _R.type(FormProps.History) !== 'Undefined' ? FormProps.History : [];
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <Typography component="h2" className={'section-title mt-2'} variant="button" display="block" gutterBottom color="primary">Details</Typography>
                <div>
                    <Paper className={'mb-1'}>
                        <div className={'timesheet_list-summary'}>
                            <div className={'p-1rem summary box-details'}>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Date</label>
                                        {(FormProps.formFields.date) ?
                                        <span className={'value'}>{FormProps.formFields.expense_date_formatted}</span>
                                        :
                                        <span className={'value'}>{FormProps.formFields.expense_date}</span>
                                        }
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Expense Category</label>
                                        <span className={'value'}>{FormProps.formFields.category_name}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Expense Code</label>
                                        <span className={'value'}>
                                            {(FormProps.formFields.exp_code && FormProps.formFields.expense_code_name) ? (FormProps.formFields.exp_code + ': ' + FormProps.formFields.expense_code_name) : ((FormProps.formFields.exp_code) ? FormProps.formFields.exp_code : '')}
                                        </span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Description</label>
                                        <span className={'value'}>{FormProps.formFields.desc}</span>
                                    </li>
                                </ul>
                                <ul className={'list-standard'}>
                                    <li className={'list-item'}>
                                        <label>Expense Type</label>
                                        <span className={'value'}>{FormProps.formFields.type}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Project</label>
                                        <span className={'value'}>
                                            {(FormProps.formFields.project_name) ? FormProps.formFields.project_name : ((FormProps.formFields.exp_project_code && FormProps.formFields.exp_project_name) ? (FormProps.formFields.exp_project_code + ': ' + FormProps.formFields.exp_project_name) : '')}
                                        </span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Amount</label>
                                        <span className={'value'}>{FormProps.formFields.amount}</span>
                                    </li>
                                    {(FormProps.formFields.mileage) &&
                                    <li className={'list-item'}>
                                        <label>Mileage</label>
                                        <span className={'value'}>
                                            {(FormProps.formFields.mileage && parseInt(FormProps.formFields.mileage) > 0) ? FormProps.formFields.mileage : ''}
                                        </span>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div>
                    <Attachments 
                        Attachments = {FormProps.attachments}
                        HandleImageClick = {FormProps.handleImageClick}
                    />
                    <ChangeHistory History={History} Expense={true} />
                </div>
            </div>
        </React.Fragment>
    )
}

