import React from 'react';
import {WeeklyOnsiteOffsiteReportComponent} from './weeklyOnsiteOffsiteReport.Component';
import {httpServices}  from '../../../services/httpServices';
import * as _ts from '../../common/treeSelect';
import { ErrorPage } from '../../common/error';
import * as validations from '../../common/validate';

const ReportFields = {
    start_date : '',
    end_date : '',
    staff_name_format : '',
    client : '0',
    projects : [],
    includeTask : false,
}
const formInputs = {
    client_list : [],
    project_list : [],
    name_format : [],
}
let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects,result);
        }
    });
}
export class weeklyOnsiteOffsiteReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            formInputs: formInputs,
            hasPermission : true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.exportClick = this.exportClick.bind(this);
    }
     exportClick = () => {
        let valid = this.validate();
        if (valid) {
         document.getElementById("weeklyReport").submit();
        }
    };
    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
                let value = evt.target.value;
                if (field === 'includeTask') {
                    value = evt.target.checked;
                }
                ff[field] = value;
                this.setState({
                   ReportFields: ff
                       }, function(){           
                     if (field === 'client' || field === 'project_status') {
                     this.getProjects();
                     }
        });
       
    };
     validate = () => {
         let formIsValid = true;
         let validationData = [
             { name: 'start_date', value: this.state.ReportFields.start_date, type: 'date', otherOptions: { required: true } },
             { name: 'end_date', value: this.state.ReportFields.end_date, type: 'date', otherOptions: { required: true } }
         ]
         let validateResponse = validations.validateFormElements(validationData);
         let validateErrors = validateResponse.errors;
         let isValid = validateResponse.valid;
         if (isValid) {
            let errors = {};
        let start_date = this.state.ReportFields.start_date ? new Date(this.state.ReportFields.start_date) : this.state.ReportFields.start_date;
        let end_date = this.state.ReportFields.end_date ? new Date(this.state.ReportFields.end_date) : this.state.ReportFields.end_date;
        if ( start_date && end_date ) {
            if(end_date < start_date){
                errors["end_date"] = "End date should be greater than start date";
                formIsValid = false;
            }
        }
        if (parseInt(this.state.ReportFields.client) === 0 || typeof this.state.ReportFields.client === 'undefined') {
            errors["client"] = "Client cannot be empty";
            formIsValid = false;         
        }
        let proj = this.state.ReportFields.projects;
        if ( typeof proj === 'undefined' ||  (proj !== 'undefined' && (parseInt(proj[0]) === 0 || proj.length === 0))) {
            errors["projects"] = "Project cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
         } else {
             formIsValid = false;
             this.setState({ errors: validateErrors });
         }
        return formIsValid;
     }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.ReportFields;
        let values = this.state.formInputs;
        let projects = [];
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                if (action === 'project') {
                    projects.push(0);
                }
            }
        }
         else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id || el.client_id || el.user_id || el.task_id) {
                   if (action === 'project') {
                        getProjectTree(values.project_list, el.project_id, projects);
                    }
                }
            });
        }
        if (action === 'project') {
            f['projects'] = projects;
        }
        this.setState({ReportFields : f});
    }
     getProjects = () => {
        if (this.state.ReportFields.client) {
            return httpServices.post('api/report/get-projects', {permission : "all", client : this.state.ReportFields.client, project_status :  this.state.ReportFields.project_status})
                .then(response => {
                    if (response.data) {
                        let formInputs = this.state.formInputs;
                        let ReportFields = this.state.ReportFields;
                        ReportFields.projects = [0];
                        formInputs.project_list = _ts.prepareTreeData(response.data, 'projects', false);
                        this.setState({
                            formInputs : formInputs,
                            ReportFields : ReportFields,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
     componentDidMount() {
        httpServices.get('api/report/offsitehours')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        ReportFields : {
                            start_date : inputs.first_day,
                            end_date : inputs.last_day,
                            staff_name_format : inputs.name_format,
                            project_statuses : inputs.projectStatus,
                            project_status : 1,
                        },
                        formInputs : {
                            client_list : inputs.clients,
                            name_format :inputs.name_formats,
                        },
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
        window.scrollTo(0, 0);
    }
    render(){
        return(
            (this.state.hasPermission)
            ?
           <WeeklyOnsiteOffsiteReportComponent
            HandleOnChange = { this.handleOnChange }
            ExportClick = { this.exportClick }
            ReportFields = { this.state.ReportFields }
            HandleTreeChange = {this.handleTreeChange}
            ReportInputs = {this.state.formInputs}
            Errors = { this.state.errors }
            />
            :<ErrorPage/>
        )
    }

}

