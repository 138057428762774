import React from 'react';
import {httpServices}  from '../../services/httpServices'
import { ErrorPage } from '../common/error';
import {ReimbursementReportComponent} from './ReimbursementReport.Component'
import { CustomProgressBar } from '../common/CustomProgressBar';
import * as validations from '../common/validate';

const formFields = {
    'periodFrom' : '',
    'periodTo' : '',
    'employeeName' : 0,
}

export class ReimbursementReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            formFields: formFields,
            employeeList:[],
            nameFormat : '',
            permission:false,
            loader:true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }

    componentDidMount(){
        httpServices.post('api/report/reimbursementReport')
            .then(response => {
                if(response.data.permission){
                    this.setState({employeeList : response.data.employeeList,
                                    nameFormat : response.data.nameFormat,
                                    permission : response.data.permission,
                                    loader: false,
                                    });
                }
                else {
                    this.setState({ loader: false });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        window.scrollTo(0, 0);
    }
    
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'periodFrom', value: this.state.formFields.periodFrom, type: 'date', otherOptions: { required: true } },
            { name: 'periodTo', value: this.state.formFields.periodTo, type: 'date', otherOptions: { required: true } },
            { name: 'employeeName', value: this.state.formFields.employeeName, type: 'integer', otherOptions: { required: false } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    render(){
       return(
           (this.state.loader )?
                <CustomProgressBar
                   loaderOpen={this.state.loader}
                />
           :
           (this.state.permission)
           ?
           <ReimbursementReportComponent
                HandleOnClick = {this.handleOnClick}
                HandleOnChange = {this.handleOnChange}
                FormFields = {this.state.formFields}
                EmployeeList ={this.state.employeeList}
                NameFormat = {this.state.nameFormat}
                Errors = {this.state.errors}
                ExportReports = {this.exportReports}
           />
           :<ErrorPage/>
       )
    }
}
