import React from 'react';
import {ClearanceComponent} from './ClearanceComponent';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';

let formFields = {
    message : '',
    email : null,
    clearanceGroup : null,
}
export class ClearanceContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            formFields: formFields,
            errors: {},
            hasPermission : true,
            clearanceOptions : [],
            open : false,
            action : '',
            editId : 0,
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant : '',
            editPermission : false,
            nameFormat : 'FIRSTLAST',
        }
        this.onAddClearanceOption = this.onAddClearanceOption.bind(this);
        this.onEditClearanceOption = this.onEditClearanceOption.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleOnHtmlChange = this.handleOnHtmlChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount()
    {
        this.getClearanceOptionsList();
    }

    getClearanceOptionsList = () => {
        let apiEndpoint = 'api/settings/getAllClearanceOptions';
        return httpServices.post(apiEndpoint)
            .then(response => {
                if(response.data.permission) {
                    this.setState({hasPermission : true,
                        clearanceOptions : response.data.clearance_options,                        
                        editPermission : response.data.edit_permission,
                    });
                }
                else {
                    this.setState({hasPermission : false,
                        editPermission : response.data.edit_permission
                    });
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };


    onAddClearanceOption = () => {
        this.setState({
            open: true,
            action : 'add'
        });
    }

    onEditClearanceOption = (id) => {
        this.setState({
            action : 'edit',
            editId : id
        },function () {
            this.getUpdateData(id);
        });
    }

    getUpdateData = (id) => {
        let apiEndpoint = 'api/settings/getAllClearanceOptions';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if(response.data.permission) {
                    let formFields = {};
                    formFields.message = response.data.selected_data.option_name;
                    formFields.email = response.data.selected_data.email_notification;
                    formFields.clearanceGroup = response.data.selected_data.group;
                    this.setState({hasPermission : true,
                        formFields : formFields,
                        open: true,
                    });
                }
                else {
                    this.setState({hasPermission : false});
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
        this.clearForm();
    }

    clearForm = () => {
        let ff = this.state.formFields;
        ff['option_name'] = null;
        ff['email_notification'] = false;
        this.setState({
            errors: {},
            errorMessage: false,
            formFields : {}
        });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        if (field === 'email') {
            ff[field] = evt.target.checked;
        }
        else
        {
            ff['clearanceGroup'] = evt.target.value;
        }
        this.setState({
            formFields: ff
        });
    }

    onSubmit = (id = null) => {
        if(this.handleValidation()) {
            this.onSave(id);
        }
    }

    handleOnHtmlChange = (evt) => {
        const ff = this.state.formFields;
        ff['email'] = this.state.formFields.email;
        ff['message'] = evt.editor.getData();
        this.setState({
            formFields: ff
        });
    }

    onSave = (id) => {
        let formFields = this.state.formFields;
        let apiEndpoint = 'api/settings/saveClearanceOptions';
        return httpServices.post(apiEndpoint,{'id' : id, 'option_name' : formFields.message, 'email_notification' : formFields.email, 'group' : formFields.clearanceGroup})
            .then(response => {
                if (response.data.permission) {
                    let message = '';
                    if (response.data.valid) {
                        if (id) {
                            message = 'Clearance option updated successfully';
                        }
                        else {
                            message = 'Clearance option added successfully';
                        }
                        this.setState({
                            open : false,
                            hasPermission: true,
                            snackbarOpen: true,
                            snackbarMsg: message,
                            snackbarVariant: 'success'
                        });
                        this.getClearanceOptionsList();
                        this.clearForm();
                    }
                    else {
                        if (response.data.errors) {
                            this.setState({ errors: response.data.errors });
                        }
                        else {
                            if (id) {
                                message = 'Update failed, Clearance option already exist';
                            }
                            else {
                                message = 'Add failed, Clearance option already exist';
                            }
                            this.setState({
                                open : false,
                                hasPermission: true,
                                snackbarOpen: true,
                                snackbarMsg: message,
                                snackbarVariant: 'error'
                            });
                            this.clearForm();
                        }
                    }
                }
                else {
                    this.setState({ hasPermission: false });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let msg = (this.state.formFields.message) ? this.state.formFields.message.trim() : '';
        msg = msg.replace(/&nbsp;/g, '').replace(/ /g,'');
        if ( msg == '<p></p>' || msg == '<p><br></p>' || msg == '') {
            errors["option_name"] = "Clearance option cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    render(){
        return(
            (this.state.hasPermission)
            ?
            <ClearanceComponent
            FormFields = {this.state.formFields}
            AddClearanceOption = {this.onAddClearanceOption}
            Open = {this.state.open}
            Close = {this.onClose}
            Action = {this.state.action}
            OnSubmit = {this.onSubmit}
            HandleOnChange = {this.handleOnChange}
            Errors = {this.state.errors}
            EditClearanceOption = {this.onEditClearanceOption}
            EditId = {this.state.editId}
            SnackbarOpen = {this.state.snackbarOpen}
            SnackbarMsg = {this.state.snackbarMsg}
            SnackbarVariant = {this.state.snackbarVariant}
            SnackbarClose = {this.snackbarClose}
            EditPermission = {this.state.editPermission}
            NameFormat = {this.state.nameFormat}
            HandleOnHtmlChange={this.handleOnHtmlChange}
            OnSave = {this.onSave}
            ClearanceOptions = {this.state.clearanceOptions}
            />
            :
            <ErrorPage/>
        )
    }
}