import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { RequestList } from './RequestList.Component';
import { AddAdditionalpto } from './AdditionalptoAdd.Container';
import { FilterComponent } from './FilterComponent';
import { CustomizedSnackbars } from '../../common/snackbars';
import { ErrorPage } from '../../common/error';
import {GetConfig} from '../../../redux/middleware/configMiddleware';
import { GetSelectedTab } from '../../../redux/middleware/tabMiddleware';

export const AdditionalptoComponent = (props) => {
    // //Tabs
    const nameFormat =  GetConfig('name_format');
    const selectedAdditionalptoTab =  GetSelectedTab("additionalpto");
    let tabIndex = (selectedAdditionalptoTab === 'my') ? 0 : (selectedAdditionalptoTab === 'review') ? 1 : 2;

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const DialogConfirm = (props) => {
        return (
            <div>
                <p>Additional PTO is requested only when the employee have used up all his accrued/available PTO and FTO for the year. We have found that you have <b>{props.PtoBalance}</b> Hours PTO balance in your account. If you are trying to save time off hours, please use <b>PTO Request</b> instead of <b>Additional PTO</b> Request.</p>
                <p>Click <b>Proceed</b> to continue with <b>Additional PTO Request</b>.</p>
            </div>
        )
    }
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"> Additional PTO Requests</Typography>
                    </div>
                    <div className={'action'}>
                        {(props.PtoEligible) ? <Button variant="contained" size="small" color="primary" onClick={()=> props.CreateAdditionalpto(false)}>New Request</Button> : <></>}
			<Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                    >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                                <div className={'tree-view-drawer'}>
                                    <div className={'drawer-title'}>Filter</div>
                                    <FilterComponent 
                                                props={props}
                                                NameFormat = {nameFormat} 
                                                handleOnSubmit = {props.FilterSubmit}
                                                SaveFilter = {props.SaveFilter} 
                                                handleOnChange = {props.HandleOnChange} 
                                                tab = {tabIndex === 0 ? 'my': tabIndex === 1 ? 'review' : 'all'}
                                                ResetFilter = {props.ResetFilter}/>
                                </div>
                            </Drawer>
                    </div>
                </div>
                <div className={'section-body'}>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabIndex}
                                onChange={props.HandleChange}
                                indicatorColor="primary"
                                aria-label="Additional pto List View Tabs"
                            >
                                {props.RequestView ? <Tab onClick={() => props.TabChange('my')} label="My Requests"/> : <></>}
                                {props.RequestView ? <Tab  onClick={() => props.TabChange('review')} label={props.ReviewRequestsCount !== 0 ? <Badge color="secondary"  badgeContent={props.ReviewRequestsCount} >Review </Badge> : "Review"}/> : <></>}
                                 {props.AllRequestView ? <Tab onClick={() => props.TabChange('all')} label="All Requests"/> :<></>}
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabIndex} index={0}>
                            {(props.PtoEligible) ? 
                                <RequestList 
                                    Requests = {props.MyRequests}
                                    Tab = {'my'} 
                                    HandleEditClick = {props.HandleEditClick} 
                                    HandleViewClick = {props.HandleViewClick}
                                />
                                : <ErrorPage Message={'You are not eligible for Additional PTO Request.'}/>
                            }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                {<RequestList
                                    Requests = {props.ReviewRequests}
                                    Tab = {'review'} 
                                    HandleViewClick = {props.HandleViewClick}
                                />}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                {<RequestList
                                    Requests = {props.AllRequests}
                                    Tab = {'all'} HandleViewClick = 
                                    {props.HandleViewClick}
                                />}
                            </TabPanel>
                        </div>
                    </Paper>
                </div>
            </div>
        {props.Open ? 
            <AddAdditionalpto 
                Open = {props.Open}
                EditId = {props.EditId} 
                UpdateParent = { props.UpdateParent}
                Title = {(props.EditId && props.EditId !== 0) ? "EDIT"  : "NEW"}
            /> 
            :<></>
        }
        {props.PtoDetails ? 
            <DialogBoxMedium
                Open={props.PtoDetails}
                Cancel={props.Close}
                OnSubmit={()=> props.CreateAdditionalpto(true)}
                ButtonName='Proceed' 
                Title='Info'
                Content={ <DialogConfirm
                PtoBalance = {props.PtoBalance}
                />}
            />
            :<></>
        }
        {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open = {props.SnackbarOpen}
                    variant= 'success'
                    message = {props.SnackbarMsg}
                    handleClose = {props.SnackbarClose}
                />
        }
        </React.Fragment>    
    )
};
