import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';  // used 0.04kb
import Link from '@mui/material/Link';

export const SyncInstancesComponent= (props) => {    
    return (
        <>
           <div className={'section-main'}>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Instances</Typography>
            </div>
            <div className={'section-content roles-table'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.Instances.map((name) => 
                        <TableRow>
                            <TableCell>
                                <Link onClick={props.ViewLink(name)} className={'link'} >
                                    {name}
                                </Link>    
                            </TableCell>   
                        </TableRow>
                       ) }
                      
                    </TableBody>
                </Table>
            </div>
            </div>
        </>
    )
        
}

