import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AnnouncementIcon from '@mui/icons-material/AnnouncementOutlined';
import AddIcon from '@mui/icons-material/Add';
import * as _R from 'ramda';  // used 0.04kb
import * as _cs from '../../../common/common';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';
import { CustomizedSnackbars } from '../../../common/snackbars';
import FormHelperText from '@mui/material/FormHelperText';

const DialogContent = (props) => {
    const {FormFields, FormInputs, HandleOnChange } = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="contact_name"
                                name="contact_name"
                                label="Contact Name"
                                type="text"
                                fullWidth
                                required
                                error={props.Errors.contact_name && true}
                                onChange={HandleOnChange}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.contact_name}
                                helperText={props.Errors.contact_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="relationship">Relationship</InputLabel>
                            <Select
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.relationship}
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'relationship',
                                        id: 'relationship',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.contact_relationship_opts) !== 'Undefined' && FormInputs.contact_relationship_opts.map((rel, i) =>
                                        <MenuItem value={rel.id} key={'relationship' + i}>{rel.title}</MenuItem>
                                    )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="contact_phone"
                                name="contact_phone"
                                label="Contact Phone"
                                type="text"
                                fullWidth
                                required
                                error={props.Errors.contact_phone && true}
                                onChange={HandleOnChange}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.contact_phone}
                                helperText={props.Errors.contact_phone}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="contact_email"
                                name="contact_email"
                                label="Contact Email"
                                type="text"
                                fullWidth
                                error={props.Errors.contact_email && true}
                                onChange={HandleOnChange}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.contact_email}
                            />
                            <small className='error'>{props.Errors.contact_email && props.Errors.contact_email}</small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="contact_type" error={props.Errors.contact_type && true} required>Contact Type</InputLabel>
                            <Select
                                    required
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.contact_type}
                                    onChange={HandleOnChange}
                                    error={props.Errors.contact_type && true}
                                    inputProps={{
                                        name: 'contact_type',
                                        id: 'contact_type',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.staff_emer_type_opts) !== 'Undefined' && FormInputs.staff_emer_type_opts.map((et, i) =>
                                        <MenuItem value={et.id} key={'contact_type' + i}>{et.title}</MenuItem>
                                    )}
                            </Select>
                            {props.Errors.contact_type && <FormHelperText className='error'>{props.Errors.contact_type}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="notes"
                                name="notes"
                                label="Notes"
                                multiline
                                rows="4"
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.notes}
                                onChange={HandleOnChange}
                                margin="normal"
                                error={props.Errors.notes && true}
                            />
                            <small className='error'>{props.Errors.notes && props.Errors.notes}</small>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

const formFields = {
    'id': 0,
    'contact_type': '',
    'contact_name': '',
    'relationship': '',
    'contact_email': '',
    'contact_phone': '',
    'notes': '',
}
class EmergencyContactListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            primary: false,
            errors: {},
            add: true,
            errorMessage: false,
            snackbarOpen : false,
            variant : 'success',
            emergencyInfo : []
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    componentDidMount() {
        this.setState({
            emergencyInfo : this.props.EmergencyInfo,
        });
    }
    onUpdate = (id = 0) => () => {
        const {UserID, StaffID} = this.props;
        let apiEndpoint = 'api/staff/contact/get-emergency-contact';
        let editable = (id) ? true : false;
        let add = (id) ? false : true;
        this.setState({
            id : id,
            add : add
        });
        return httpServices.post(apiEndpoint, {
                        'user_id':UserID,
                        'staff_id':StaffID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        open:true,
                        formFields: (id) ? response.data.selected_emergency_contact: formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        primary: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.id = this.state.id;
            data.staff_id = staffID;
            data.user_id = userID;
            data.contact_phone = this.state.formFields.contact_phone.replace( /[\s- .)(]/g, '');
            let tab = StaffConstants.STAFF_TABS[1];
            let module = 'emergency_info';
            if (data) {
                let apiEndpoint = 'api/staff/contact/save-emergency-contact';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    open:false,
                                    snackbarOpen : true,
                                    varient: 'success',
                                    message : response.data.message,
                                    emergencyInfo : response.data.emergency_info
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({ errorMessage: response.data.msg });
                            }
                        }
                        else {
                            this.setState({ errors:response.data.errors });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    };
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/contact/delete-emergency-contact';
        return httpServices.post(apiEndpoint, {
            'staff_id': StaffID,
            'user_id': UserID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[1];
                    let module = 'emergency_info';
                    this.setState({
                        open:false,
                        snackbarOpen : true,
                        varient: 'success',
                        message : response.data.message,
                        emergencyInfo : response.data.emergency_info,
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let email = this.state.formFields.contact_email;
        let phone_number = this.state.formFields.contact_phone;
        if (!this.state.formFields.contact_name) {
            errors["contact_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!phone_number) {
            errors["contact_phone"] = "Cannot be empty";
            formIsValid = false;
        }
        else if(phone_number) {
            let reg = /^[+]?[- .()0-9]{9,15}$/;
            let ok = reg.exec(phone_number);
            if (!ok) {
                errors["contact_phone"] = "Contact Phone isn't valid";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.contact_type) {
            errors["contact_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (email) {
            let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let ok = reg.exec(email);
            if (!ok) {
                errors["contact_email"] = "Email isn't valid";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const val = evt.target.value;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    };
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    render(){
        const { emergencyInfo } = this.state;
        return (
            <React.Fragment>
                <div className={'contact-group_head'}>
                    <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                        <AnnouncementIcon className={'title-icon'} fontSize="small"/>
                        Emergency Contact
                    </Typography>
                    <div className={'action'}>
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
                <ul className={'contact-list'}>
                    {emergencyInfo.map( (em, index ) =>
                        <li>
                            <div className={'contact-detils'}>
                                { em.type === 'Primary' && <div className={'contact-type primary'}>Primary</div> }
                                <div className={'contact-title'}>{em.contact_name} <div className={'title-sub'}>{em.relationship}</div></div>
                                <div className={'contact-content'}>
                                    {em.contact_phone && <span className={'contact-content_phone '}>{_cs.formatPhone(em.contact_phone)}</span> }
                                    {em.contact_email && <span className={'contact-content_email'}>{em.contact_email}</span> }
                                </div>
                                <div className={'contact-content'}>
                                    {em.notes && <span>{em.notes}</span> }
                                </div>

                            </div>
                            <div className={'contact-action'}>
                                <IconButton aria-label="edit">
                                    <EditIcon fontSize="small" onClick={this.onUpdate(em.id)}/>
                                </IconButton>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon fontSize="small" onClick={this.onDelete(em.id)}/>
                                </IconButton>
                            </div>
                        </li>
                    )}
                </ul>
                <DialogBoxMedium
                    Open={this.state.open}
                    Cancel={this.onClose}
                    Title={this.state.isDelete ?  'Delete Emergency Contact' : (this.state.isEditable ? 'Edit Emergency Contact' : 'Add Emergency Contact')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Emergency Contact?'
                        :
                        <DialogContent
                            FormInputs={this.state.formInputs}
                        FormFields={this.state.formFields}
                        OnSubmit={this.onSubmit}
                        HandleOnChange = {this.onHandleChange}
                        Add = {this.state.add}
                        Errors = {this.state.errors}
                        />
                    }
                />
                <CustomizedSnackbars
                open={this.state.snackbarOpen}
                variant={this.state.variant}
                message={this.state.message}
                handleClose ={this.snackbarClose}
                />
            </React.Fragment>
        )
    }
}
export { EmergencyContactListContainer as EmergencyContactList };
