import React from 'react';
import { TimeOffDetailsViewComponent } from './TimeOffDetailsView.Component';
import { ErrorPage } from '../../common/error';
import {httpServices}  from '../../../services/httpServices'
import { CustomProgressBar } from '../../common/CustomProgressBar';

export class TimeOffDetailsViewContainer extends React.Component{ 
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            staffDetails:{},
            permission:false,
            ptoExperience:{},
            experience:{},
            timeoffTypes:{},
            accruals:{},
            years:{},
            selectedYear:'',
            accrualsTimeoff:{},
            listView:false,
            onDate:'',
            showLoader : true,
            dataLoaded : false,
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.getTimeOffDetails = this.getTimeOffDetails.bind(this);        
    }
    componentDidMount = () =>  {
        if (this.props.location.state && this.props.location.state.year) {
            this.setState({
                selectedYear:this.props.location.state.year
            },()=>{
                this.getTimeOffDetails();
            });
        }else {
            this.getTimeOffDetails();
        }
    }   
    
    getTimeOffDetails = () =>  {
        this.setState({
            showLoader : true,
        });
        httpServices.post('api/timeoff/view',{'user':this.props.match.params.id,'year':this.state.selectedYear})
            .then(response => {
                if (response.data.permission) { 
                    let inputs = response.data;
                    this.setState({ 
                        permission : true,
                        staffDetails : inputs.staff_details,
                        ptoExperience : inputs.pto_experience,
                        experience : inputs.experience,
                        timeoffTypes : inputs.timeoff_types,
                        accruals : inputs.accruals,
                        years : inputs.years,
                        holidayMax : inputs.holiday_max,
                        holidayTaken : inputs.holiday_taken,
                        dailyMinimum : inputs.daily_minimum,
                        accrualsTimeoff : inputs.accruals_timeoff,
                        listView : inputs.list_permission,
                        onDate:inputs.on_date,
                        showLoader : false,
                        dataLoaded : true,
                        selectedYear : ((inputs.joined_year != '')  &&  (inputs.joined_year > this.state.selectedYear)) ? inputs.joined_year : this.state.selectedYear
                    });
                }
                else {
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
            });
    }

    HandleOnChange = (evt, value) => {
        const val = evt.target.value;         
        this.setState({
            selectedYear: val
        });        
    };

    render(){        
        return(
            <>
            <CustomProgressBar loaderOpen={this.state.showLoader}/>
            {( this.state.dataLoaded) ?
            <>
                { (this.state.permission)?
                <TimeOffDetailsViewComponent 
                    staffDetails = {this.state.staffDetails} 
                    ptoExperience = {this.state.ptoExperience}
                    experience = {this.state.experience}
                    timeoffTypes = {this.state.timeoffTypes}
                    accruals = {this.state.accruals}
                    years = {this.state.years}
                    selectedYear = {this.state.selectedYear}
                    HandleOnChange = {this.HandleOnChange}
                    getTimeOffDetails = {this.getTimeOffDetails}
                    holidayMax = {this.state.holidayMax}
                    holidayTaken = {this.state.holidayTaken}
                    dailyMinimum = {this.state.dailyMinimum}
                    accrualsTimeoff={this.state.accrualsTimeoff}
                    listView={this.state.listView}
                    onDate={this.state.onDate} 
                />
                :<ErrorPage/>}
            </>
            :<></> }
            </>
        )
    }
}
