import React from 'react';
import Grid from '@mui/material/Grid';
import {LevelsView}  from './Levels.Container';
import {JobTitleView}  from './JobTitle.Container';

export class JobLevel extends React.Component{
    render(){     
        return (
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                <Grid  item xs={12} md={6}>
            <LevelsView
            />
            </Grid>
            <Grid  item xs={12} md={6}>
            <JobTitleView
            />
            </Grid>
            </Grid>
        )
    }
}
