import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

export const BeneficiaryComponent = (props) => {
    let dataArr = props.beneficiary_details;
    let table_size = 10; // Default
    if (dataArr) {
        let data_arr_length = dataArr.length ;
        if (data_arr_length < 10) {
            table_size = data_arr_length < 3 ? 2 : data_arr_length;
        }
    }
    let Open = props.Open;
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={props.Title}
                        columns={[
                            {
                                title: 'Beneficiary Name',
                                field: 'beneficiary_name',
                            },
                            {
                                title: 'Beneficiary Type',
                                field: 'beneficiary_type_name',
                            },
                            {
                                title: 'Relationship',
                                field: 'beneficiary_relationship_name',
                            },
                            {
                                title: 'Percentage',
                                field: 'percentage',
                            },
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                            columnsButton:true,
                        }}
                        actions={[
                             {
                                icon: 'add',
                                tooltip: 'Add Beneficiary Details',
                                isFreeAction: true,
                                onClick: (event) => { props.OnAdd() }, 
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                            },                          

                        ]}
                        editable={{
                        onRowDelete: rowData =>
                            new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                props.OnDelete(rowData.id);
                            }
                            resolve()
                            }, 500)
                        }),
                    }}

                    />
                </Grid>
            </Grid>
            
            <DialogBoxMedium
                    Open = {Open}
                    Cancel = {props.OnClose}
                    Title = {props.isEditable ? "Edit Beneficiary Details" : "Add Beneficiary Details"}
                    OnSubmit = {props.OnSubmit}
                    Content = {<DialogContent FormProps={props}/>}
           />

        </React.Fragment>
        </>
    )
};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange,beneficiary_types,beneficiary_relationships} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="beneficiary_name"
                                name="beneficiary_name"
                                label="Beneficiary Name"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.beneficiary_name}
                                onChange={HandleOnChange}
                                error={props.Errors.beneficiary_name && true}
                                helperText={props.Errors.beneficiary_name}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="beneficiary_relationships" required  error={props.Errors.beneficiary_relationships && true}>Relationship</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.beneficiary_relationships}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'beneficiary_relationships',
                                    id: 'beneficiary_relationships',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(beneficiary_relationships) !== 'Undefined' && beneficiary_relationships.map((t, i) =>
                                    <MenuItem value={t.id} key={'i-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.beneficiary_relationships && <FormHelperText className='error'>{props.Errors.beneficiary_relationships }</FormHelperText> }
                        </ FormControl>
                    </Grid>

                     { parseInt(props.FormFields.beneficiary_relationships) === 7 &&
                     <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="new_relationship"
                                name="new_relationship"
                                label="Specify relation"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.new_relationship}
                                onChange={HandleOnChange}
                                error={props.Errors.new_relationship && true}
                                helperText={props.Errors.new_relationship}
                            />
                        </ FormControl>
                    </Grid>
                        }

                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="beneficiary_types" required  error={props.Errors.beneficiary_types && true}>Beneficiary Type</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.beneficiary_types}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'beneficiary_types',
                                    id: 'beneficiary_types',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(beneficiary_types) !== 'Undefined' && beneficiary_types.map((t, i) =>
                                    <MenuItem value={t.id} key={'i-type-'+i}>{t.beneficiary_type}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.beneficiary_types && <FormHelperText className='error'>{props.Errors.beneficiary_types}</FormHelperText> }
                        </ FormControl>
                    </Grid>

                     <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="percentage"
                                name="percentage"
                                label="Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.percentage}
                                onChange={HandleOnChange}
                                error={props.Errors.percentage && true}
                                helperText={props.Errors.percentage}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
)
}
