import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { styled} from '@mui/system';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const MyThemeComponent = styled(SnackbarContent)(({ theme }) => ({  
  '& .icon': {
    fontSize: 20,
  },
  '& .iconVariant': {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  '& .message': {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  let bgcol = "";
  switch (variant) {
    case "success":
      bgcol = "#43a047";
      break;
    case "error":
      bgcol = "#c62828";
      break;
    case "info":
      bgcol = "#1976d2";
      break;
    case "warning":
      bgcol = '#ffa000';
      break;  
  }

  return (
    <MyThemeComponent
      style={{backgroundColor : bgcol}}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" 
          className={'message'}
        >
           <Icon className={'icon iconVariant'} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon 
           className={'icon'} 
          />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export const CustomizedSnackbars = (props) => { 
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleClose}
    >
      <div>

        <MySnackbarContentWrapper
          onClose={props.handleClose}
          variant={props.variant}
          message={props.message}
        />
      </div>
    </Snackbar>

  )
}
