import React from 'react';
import {TimesheetExportComponent} from './TimesheetExport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';
import * as _ms from '../../common/multiSelect';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as validations from '../../common/validate';


const filterFields = {
    'client' : [],
    'projects' : [],
    'name_format' : 'LASTFIRST',
    'start_date' : '',
    'end_date' : '',
    'name_option' : 'alias_name',
    'clientStatus' : 1,
    'projectStatus' : 1,
    'staffStatus' : 1
}

const filters = {
}

let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) { 
            getProjectTree(e.children, selected_projects,result);
        }
    });
}

export class TimesheetExportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            open: false,
            filterFields: filterFields,
            filters: filters,
            errors: {},
            loaderOpen: false,
            permissionType : '',
            reportId : 0
        }
       
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({
            loaderOpen:true
        });
        let filterFields = this.state.filterFields;
        return httpServices.get('api/report/reconcile-timesheet-export')
            .then(response => {
                if (response.data.permission) {
                    let filters = response.data.filters;
                    filterFields.start_date=response.data.start_date;
                    filterFields.end_date=response.data.end_date;
                    filterFields.name_format=response.data.name_format;
                    this.setState({
                        filters: filters,
                        filterFields : filterFields,
                        permission: response.data.permission,
                        loaderOpen: false,
                        permissionType: response.data.permission_type,
                        reportId : response.data.report_id
                    },() => {
                        this.clientStatusChange();
                    });
                }
                else {
                    this.setState({
                        permission: response.data.permission,
                        loaderOpen: false
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let filters = this.state.filters;
        let name = evt.target.name;
        let val = evt.target.value;
        f[name] = val;
        this.setState({
            filterFields: f,
            filters: filters,
        }, function(){
            if (name === 'clientStatus') {
                this.clientStatusChange();
            }
            if (name === 'client' || name === 'projectStatus') {
                this.getProjects();
            }
        });
    }

    clientStatusChange = () => {
        let clientStatus = this.state.filterFields.clientStatus;
        httpServices.post('api/report/get-clients', {status : clientStatus, permission:this.state.permissionType , report_id:this.state.reportId})
            .then(response => {
                if (response.data) {
                    let filters = this.state.filters;
                    let filterFields = this.state.filterFields;
                    filterFields.client = [];
                    filterFields.projects = [];
                    let clients = _ms.prepareData(response.data,false);
                    filters.clients = clients.list;
                    filters.projects=[];
                    this.setState({
                        filters : filters,
                        filterFields : filterFields,
                        selectedClientList : []
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    getProjects = () => {
        if (this.state.filterFields.client.length) {
            return httpServices.post('api/report/get-projects', {permission : this.state.permissionType, client : this.state.filterFields.client, project_status:this.state.filterFields.projectStatus,report_id:this.state.reportId})
                .then(response => {
                    if (response.data) {
                        let filters = this.state.filters;
                        filters.projects = _ts.prepareTreeData(response.data, 'projects', false);
                        this.setState({
                            filters : filters,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        } else {
            let filters = this.state.filters;
            filters.projects = [];
            this.setState({
                filters : filters,
            });
        }
    }
    
    validateFilter = () => {
        let valid = this.validate();
        let f = this.state.filterFields;
        this.setState({ filterFields: f },
            function () {
                if (valid) {
                    document.getElementById("timesheetExport").submit();
                }

            }
        );
    }
    
    validate = () => {
        let formIsValid = true;
        let validationData = [
            { name: 'start_date', value: this.state.filterFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.filterFields.end_date, type: 'date', otherOptions: { required: true } },
            { name: 'staffStatus', value: this.state.filterFields.staffStatus, type: 'integer', otherOptions: { required: true } },
            { name: 'client', value: this.state.filterFields.client, type: 'select', otherOptions: { required: true } },
            { name: 'projects', value: this.state.filterFields.projects, type: 'select', otherOptions: { required: true } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        if (isValid) {
            let errors = {};
            if (this.state.filterFields.name_option === 'alias_name' && !this.state.filterFields.key_identifier) {
                errors["key_identifier"] = "Cannot be empty";
                formIsValid = false;
            }
            if (this.state.filterFields.name_option === 'staff_name' && !this.state.filterFields.staff_name_format) {
                errors["staff_name_format"] = "Cannot be empty";
                formIsValid = false;
            }
            this.setState({ errors: errors });
        } else {
            formIsValid = false;
            this.setState({ errors: validateErrors });
        }
        return formIsValid;
    }
    onClose = () =>{
        this.setState({
            open: false,
        });
    };

    onSelect = (selectedList, selectedItem) => {
        let selected = [];
        let f = this.state.filterFields;
        let selectedClientList = this.state.selectedClientList;
        if (typeof selectedItem.client_id !== 'undefined') {
            if (selectedItem.client_id !== 0) {
                if(selectedList[0].client_id === 0) {
                    selectedList.splice(0,1);
                }
            }
            selectedList.forEach((p, v) =>{
                selected.push(p.client_id);
            })
            selectedClientList = selectedList;
            f.client = selected;
        }
        this.setState({
            filterFields: f,
            selectedClientList : selectedClientList,
            
        }, function() {
            if (typeof selectedItem.client_id !== 'undefined') {
                this.getProjects();
            }
        });
    }
    onRemove(selectedList, removedItem) {
        let selected = [];
        let f = this.state.filterFields;
        if (typeof removedItem.client_id !== 'undefined') {
            selectedList.forEach((p, v) =>{
                selected.push(p.client_id);
            })
            f.client = selected;
        }
        this.setState({
            filterFields: f,
        },function() {
            if (typeof removedItem.client_id !== 'undefined') {
                this.getProjects();
            }
        });
    }
    handleTreeChange = (evt, cNode, sNodes) => {
        let f = this.state.filterFields;
        let projects = [];
        let filters = this.state.filters;
        if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id ) {
                    getProjectTree(filters.projects, el.project_id, projects);
                }
            });
        }
        f['projects'] = projects;
        this.setState({filterFields : f});
    }

    render(){
        return(
            <>
            { (this.state.permission) ? 
                <TimesheetExportComponent
                    HandleFilterChange = {this.handleFilterChange.bind(this)}
                    ValidateFilter = {this.validateFilter.bind(this)}
                    FilterFields = {this.state.filterFields}
                    Filters = {this.state.filters}
                    Errors = {this.state.errors}
                    OnClose={this.onClose.bind(this)}
                    OnSelect = {this.onSelect.bind(this)}
                    OnRemove = {this.onRemove.bind(this)}
                    HandleTreeChange = {this.handleTreeChange.bind(this)}
                    selectedClientList ={this.state.selectedClientList}
                />
                :
                <ErrorPage/>
            }
            <CustomProgressBar
                loaderOpen={this.state.loaderOpen}
            />
            </>            
        )
    }
}
