import React, {useState} from 'react';
import * as _R from 'ramda';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../common/common';
import { TableConstants } from '../../constants';
const API_URL = process.env.REACT_APP_API_URL;
export const TimeoffLogReport = (props) =>{
    let index=0;
    const PaperDiv = styled(Paper) (({ theme }) =>({
        '& .root': {
            width: '100%',
        },
        /*container: {
            maxHeight: 440,
        },
        color: {        
        backgroundColor: '#A3D1FF',
        },*/
    }));
    const gridStyle = {
        margin: '5px 0px'
    };
        return(
            <React.Fragment>
                <Paper md={12}>
                    <div class="p1">
                        <Typography variant="h6" component="h6" className={'section-title'}>Time Off Log Report</Typography>
                        <form id="time-off" className="form-fullwidth" action={API_URL+'api/report/timeoffConsolidatedlog'} method="post">
                            <input type="hidden" id="export" name="export"/> 
                            <input type="hidden" id="start_date" name="start_date" value={(typeof props.FilterFields.start_date !== 'undefined') ? props.FilterFields.start_date : null} /> 
                            <input type="hidden" id="end_date" name="end_date" value={(typeof props.FilterFields.end_date !== 'undefined') ? props.FilterFields.end_date : null} /> 
                            <input type="hidden" id="staff_status" name="staff_status" value='0'/>
                            <input type="hidden" id="staff_type" name="staff_type" value='0'/>
                            <input type="hidden" id="employee_id" name="employee_id" value={(typeof props.FilterFields.user_id !== 'undefined') ? props.FilterFields.user_id : null}/>
                            <input type="hidden" id="year" name="year" value={(typeof props.FilterFields.year !== 'undefined') ? props.FilterFields.year : null}/>
                            <input type="hidden" id="year" name="year" value={(typeof props.FilterFields.date_joined !== 'undefined') ? props.FilterFields.date_joined : null}/>
                            <input type="hidden" id="year" name="year" value={(typeof props.FilterFields.relieved_date !== 'undefined') ? props.FilterFields.relieved_date : null}/>
                                <Grid container spacing={3} style={gridStyle}>
                                    <Grid item  md={3}>
                                        <FormControl error={_R.type(props.Errors.user_id) !== 'Undefined'?true:false} variant="standard">
                                            <InputLabel htmlFor="staff_status">Staff Status</InputLabel>
                                            <Select
                                                value={(typeof props.FilterFields.staff_status !== 'undefined') ? props.FilterFields.staff_status : 1}
                                                onChange={props.HandleFilterChange}
                                                inputProps={{
                                                    name: 'staff_status',
                                                    id:   'staff_status',
                                                }}>
                                                <MenuItem value='0'>-All-</MenuItem>
                                                { typeof props.FilterFields.staff_statuses != 'undefined' && props.FilterFields.staff_statuses !=='' && props.FilterFields.staff_statuses.map((t, i) =>
                                                <MenuItem value={t.user_status_id} key={'stst-'+i}>{t.user_status_name}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item  md={3}>
                                        <FormControl error={_R.type(props.Errors.user_id) !== 'Undefined'?true:false} variant="standard">
                                            <InputLabel required htmlFor="user_id">Staff</InputLabel>
                                            <Select
                                                required
                                                value={props.FilterFields.user_id}
                                                inputProps={{
                                                    name: 'user_id',
                                                    id: 'user_id'
                                                }
                                                }
                                                onChange={props.HandleFilterChange}
                                            >
                                                <MenuItem key={'user-0'} value={0}>-Select-</MenuItem>
                                                {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.staff_list) !== 'Undefined' && props.Filters.staff_list.map((rtype, i) =>
                                                <MenuItem key={'user-'+i} value={rtype.user_id}>{rtype.first_name+' '+rtype.last_name}</MenuItem>
                                            )}
                                            </Select>
                                            {_R.type(props.Errors.user_id) !== 'Undefined' && <FormHelperText>{props.Errors.user_id}</FormHelperText>}
                                        </FormControl>                
                                    </Grid>
                                    <Grid item  md={3}>
                                        <FormControl error={_R.type(props.Errors.year) !== 'Undefined'?true:false} variant="standard">
                                            <InputLabel required htmlFor="year">Year</InputLabel>
                                            <Select
                                                required
                                                value={(typeof props.FilterFields.year !== 'undefined') ? props.FilterFields.year : 0}
                                                inputProps={{
                                                    name: 'year',
                                                    id: 'year',
                                                }}
                                                onChange={props.HandleFilterChange}
                                            >
                                                <MenuItem key={'year-0'} value={0}>-Select-</MenuItem>
                                                {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.year) !== 'Undefined' && props.Filters.year.map((rtype, i) =>
                                                <MenuItem key={'year-'+i} value={rtype}>{rtype}</MenuItem>
                                            )}
                                            </Select>
                                            {_R.type(props.Errors.year) !== 'Undefined' && <FormHelperText>{props.Errors.year}</FormHelperText>}
                                        </FormControl>            
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" size="small" color="primary" onClick={ props.OnSubmitClick('search') }>Submit</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button size = "small" color = "primary"  onClick={props.ExportReports}   variant = "contained" >Export</Button>
                                    </Grid>
                                </Grid>    
                        </form>
                    </div>
                </Paper>
                <div className={'ReportList justify-between align-stretch'}>
                    <PaperDiv className={'root'}>
                        <>
                            {(props.TimeOffListDetails)?
                                <Grid container style={gridStyle}>
                                    <Table stickyHeader aria-label="Report List" className={'list-table_basic-year'}>
                                        <TableHead className={'thead'}>
                                            <TableRow>
                                                <TableCell  rowSpan="2" align ="center" width = "10%"> Pay Period </TableCell>
                                                <TableCell  rowSpan="2" align ="center"> Status </TableCell>
                                                <TableCell  rowSpan="2" align ="center"> Minimum Pay Period Hours </TableCell>
                                                <TableCell  rowSpan="2" align ="center"> Total Working Hours </TableCell>
                                                {_R.type(props.HeadersList) !== 'Undefined' && Object.keys(props.HeadersList).map((item,k) =><TableCell align ="center" colSpan={props.HeadersList[k]['colspan_value']} >{props.HeadersList[k]['timeoff_type_name']}</TableCell>)}
                                                <TableCell  colSpan="1" align ="center"> Holidays </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {_R.type(props.HeadersList) !== 'Undefined'  && Object.keys(props.HeadersList).map((item,k) =>
                                                <>
                                                    {(props.HeadersList[k]['colspan_value'] == 2) ? <TableCell align ="center"> Accrued (In Hours) </TableCell> : null}
                                                    {<TableCell  align ="center"> Used (In Hours) </TableCell>}
                                                </>
                                                )}
                                                <TableCell  align ="center"> Used (In Hours) </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_R.type(props.TimeOffListDetails) !== 'Undefined' && Object.keys(props.TimeOffListDetails).map((frow, fc) =>
                                                <>
                                                    <TableRow>
                                                        <TableCell align ="center">      
                                                            <span> {props.TimeOffListDetails[frow]['payperiod_sdate']+' - '+props.TimeOffListDetails[frow]['payperiod_edate']}</span>
                                                        </TableCell>
                                                        <TableCell align ="center">      
                                                            <span> {props.TimeOffListDetails[frow][index]['status']}</span>
                                                        </TableCell>
                                                        <TableCell align ="center">      
                                                            <span> {props.TimeOffListDetails[frow][index]['min_payperiod_hours']}</span>
                                                        </TableCell>
                                                        <TableCell align ="center">      
                                                            <span> {props.TimeOffListDetails[frow][index]['total_working_hours']}</span>
                                                        </TableCell>
                                                        {_R.type(props.TimeOffListDetails) !== 'Undefined' && Object.keys(props.TimeOffListDetails[frow]).map((mrow, mc) =>
                                                            <>
                                                            {(props.TimeOffListDetails[frow][mrow]['accrual'] == 1) ?
                                                                <TableCell align ="center">
                                                                    <span> {(props.TimeOffListDetails[frow][mrow]['hours']) ? parseFloat(props.TimeOffListDetails[frow][mrow]['hours']).toFixed(2) : '0'}</span>
                                                                </TableCell>
                                                            :''}
                                                            {(props.TimeOffListDetails[frow][mrow]['accrual'] == 1 || props.TimeOffListDetails[frow][mrow]['accrual'] == 0) ?
                                                                <TableCell align ="center">
                                                                    <span> {(props.TimeOffListDetails[frow][mrow]['taken']) ? parseFloat(props.TimeOffListDetails[frow][mrow]['taken']).toFixed(2) : '0'}</span>
                                                                </TableCell>
                                                            :''}
                                                            </>
                                                        )}  
                                                        <TableCell align ="center">
                                                            <span> {(props.HolidayListDetails['holiday_data'][frow-1]['taken']) ? parseFloat(props.HolidayListDetails['holiday_data'][frow-1]['taken']).toFixed(2) : '0'}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )} 
                                            <TableRow>
                                                <TableCell  rowSpan="2" align ="center"> TOTAL </TableCell>
                                                <TableCell  rowSpan="2" align ="center">  </TableCell>
                                                <TableCell  rowSpan="2" align ="center">  </TableCell>
                                                <TableCell  rowSpan="2" align ="center">  </TableCell>
                                                {_R.type(props.HeadersList) !== 'Undefined'  && Object.keys(props.HeadersList).map((item,k) =>
                                                <>
                                                    {(props.HeadersList[k]['colspan_value'] == 2) ? <TableCell align ="center"> {parseFloat(props.HeadersList[k]['hours_total']).toFixed(2)} </TableCell> : null}
                                                    {<TableCell  align ="center"> {parseFloat(props.HeadersList[k]['hours_taken']).toFixed(2)} </TableCell>}
                                                </>
                                                )}
                                                <TableCell  align ="center"> {parseFloat(props.HolidayListDetails['total_holidays']).toFixed(2)} </TableCell>
                                            </TableRow> 
                                        </TableBody>
                                    </Table>
                                </Grid>
                            :<></>
                            }
                        </>
                        <>
                            {(! props.TimeOffListDetails)?
                                <>
                                    <Grid container style={gridStyle}>
                                        <Table size="small" aria-label="Report List" className={'list-table_basic'}>
                                            <TableRow>
                                                <TableCell colspan="6"></TableCell>
                                                <TableCell>No search result</TableCell>
                                            </TableRow>
                                        </Table>
                                    </Grid>
                                </>
                            :<></>
                            }
                        </>  
                    </PaperDiv>
                </div>
            </React.Fragment>
        )
}

