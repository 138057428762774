import React from 'react';

import { httpServices }  from '../../../services/httpServices';
import { ExpenseCodeComponent }  from './ExpenseCode.Component.js';

let formFields = {
    startDate : null,
    endDate : null,
    expenseCode : null,
    name : '',
    id : 0,
}
export class ExpenseCodeContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            expenseCodes : [],
            editPermission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            deleteId : 0,
            snackbarVariant: ''
        }
        this.addExpenseCodeClick = this.addExpenseCodeClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteExpCode = this.deleteExpCode.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    componentDidMount = () => {
        this.getAllExpenseCodes();
    };

    getAllExpenseCodes = () => {
        let apiEndpoint = 'api/settings/expenses/listexpensecode';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        expenseCodes : response.data.expense_codes,
                        editPermission : response.data.edit_permission,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addExpenseCodeClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    handleDeleteClick = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/expenses/saveExpenseCode', { data })
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.valid) {
                                this.getAllExpenseCodes();
                                let successMsg = ''
                                if (data.id) {
                                    successMsg = 'Expense code updated successfully';
                                } else {
                                    successMsg = 'Expense code added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                if (response.data.errors) {
                                    this.setState({
                                        errors: response.data.errors,
                                    });
                                }
                                else {
                                    let errorMsg = 'Expense code already exists';
                                    this.setState({
                                        snackbarOpen: true,
                                        snackbarMsg: errorMsg,
                                        snackbarVariant: 'error'
                                    });
                                }
                            }
                        }
                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.startDate) {
            errors["startDate"] = "Cannot be empty";
            formIsValid = false;
        }
        
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.expenseCode) {
            errors["expenseCode"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.startDate > this.state.formFields.endDate) {
            errors["endDate"] = "End date cannot be less than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/expenses/getexpensecode-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.exp_code) {
                       let formFields = {
                           id : response.data.exp_code.id,
                           startDate : response.data.exp_code.start_date,
                           endDate : response.data.exp_code.end_date,
                           name : response.data.exp_code.name,
                           expenseCode : response.data.exp_code.code,
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    deleteExpCode = () => {
        let apiEndpoint = 'api/settings/expenses/deleteexpensecode-by-id';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    if (response.data.valid) {
                        this.getAllExpenseCodes();
                        this.setState({
                            snackbarOpen : true,
                            snackbarMsg : "Expense code deleted successfully",
                            open : false,
                            popupType : '',
                            snackbarVariant: 'success'
                        });
                    } else {
                        alert(response.data.err_msg);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <ExpenseCodeComponent
                ExpenseCodes = {this.state.expenseCodes}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddExpenseCodeClick = {this.addExpenseCodeClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                DeleteExpCode = {this.deleteExpCode}
                HandleDeleteClick = {this.handleDeleteClick}
                SnackbarVariant = {this.state.snackbarVariant}
            />
        )
    }
}
