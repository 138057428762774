import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import * as _R from 'ramda';  // used 0.04kb
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {DialogBoxMedium} from '../../common/dialog.medium';
import { httpServices } from '../../../services/httpServices';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomDatePicker } from '../../common/CustomDatePicker';




const formFields = {
    work_policy_id : null,
    start_date : null,
    end_date : null,
    id : 0
}
const WorkPolicyStyle = styled(React.Fragment)(({ theme }) => ({
    '& .table': {
      minWidth: 650,
    },
  }));
const DialogContent = (props) => {
    const { FormFields, HandleOnChange, Errors, AllPolicies, HandleDateChange  } = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.work_policy_id && true}>Policy</InputLabel>
                        <Select
                            value={parseInt(FormFields.work_policy_id)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'work_policy_id',
                                id: 'work_policy_id'
                            }}
                            className={'input-item'}
                        >
                            {typeof AllPolicies !== 'undefined' && AllPolicies.map((policy) =>
                                <MenuItem value={policy.id} >{policy.work_policy_name}</MenuItem>
                            )}
                        </Select>
                        {Errors.work_policy_id && <FormHelperText className="error">{Errors.work_policy_id}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id="start_date"
                            name="start_date"
                            selectedDate={FormFields.start_date}
                            handleDateChange={HandleDateChange}
                            shouldDisableDate={(date) => { return date.day() !== 0; }}
                            required={true}
                            label={'Start Date'}
                        />
                        <small className='error'>{Errors.start_date && Errors.start_date}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id="end_date"
                            name="end_date"
                            selectedDate={FormFields.end_date}
                            handleDateChange={HandleDateChange}
                            label={'End Date'}
                        />
                        <small className='error'>{Errors.end_date && Errors.end_date}</small>
                    </ FormControl>
                </Grid>
            </Grid>
            </form>
    )
}

class WorkPolicyListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            deleteId : 0,
            open: false,
            isEditable: false,
            isDelete: false,
            formFields: [],
            allPolicies : [],
            editPermission : false,
            permission : true,
            errors : {},
            workPolicy: this.props.WorkPolicy, 
            groupID: this.props.GroupID, 
            group_policy: this.props.Group_policy, 
            edit: this.props.Edit,
            userId: 0,
            snackbarOpen: false,
            snackbarVariant: '',
            snackbarMsg: '',
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount = () => {
        this.getGroupDetails(this.state.groupID);
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onUpdate = (id) => () => {
        let group_id = this.state.groupID;
        let apiEndpoint = 'api/user/workpolicy';
        return httpServices.post(apiEndpoint, {'groupID':group_id, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            allPolicies: response.data.all_policies,
                            formFields: response.data.work_policy,
                            isDelete: false,
                            groupID: group_id
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            allPolicies: response.data.all_policies,
                            isEditable: false,
                            groupID: group_id
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }
    getGroupDetails = (groupId) => {
        let apiEndpoint = 'api/user/view-group';
        this.setState({
            showLoader : true,
        });
        return httpServices.post(apiEndpoint,{id : groupId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({ 
                            group_policy : response.data.group_policy,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) { 
                 let apiEndpoint = 'api/user/save-policy';
                 let group_id = this.state.groupID;
                 let id = (data.id) ? data.id : 0;
                 let work_policy_id = data.work_policy_id;
                 let start_date = data.start_date;
                 let end_date = (data.end_date) ? data.end_date : '';
                 return httpServices.post(apiEndpoint,{'id' : id, 'work_policy_id' : work_policy_id, 'start_date' : start_date, 'end_date' : end_date, 'group_id' : group_id })
                    .then(response => {
                        this.onUpdate();
                        if (response.data.valid) {
                            this.setState({
                                open: false,
                                formFields: {},
                                errors: {},
                                group_policy: response.data.work_policy
                            }, () => this.displaySnackBar(response.data.valid, response.data.msg));
                        }
                        else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            } else {
                                this.displaySnackBar(response.data.valid, response.data.error_msg)
                            }
                        }                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.work_policy_id) {
            errors["work_policy_id"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if ((this.state.formFields.end_date && this.state.formFields.start_date && this.state.formFields.end_date !== '0000-00-00') &&  (this.state.formFields.end_date < this.state.formFields.start_date)) {
            errors["end_date"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
 
    displaySnackBar = (status, msg) => {
        if (status) {
            this.setState({
                snackbarOpen: true,
                snackbarVariant: 'success',
                snackbarMsg: msg,
            });
        } else {
            this.setState({
                snackbarOpen: true,
                snackbarVariant: 'error',
                snackbarMsg: msg,
            });
        }
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    handleDateChange = (value, name) => {
        const form_field = this.state.formFields;
        form_field[name] = value;
        this.setState({
            formFields: form_field
        });
    };

    render(){
        const {group_policy} = this.state;
        return (
            <WorkPolicyStyle>
             <div className={'section-content'}>
                    <div className={'listSubHeader'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Group Policy</Typography>
                        <Tooltip title="Add Group Policy" aria-label="add">
                            <Button variant="contained" color="primary" size="small" onClick={this.onUpdate()}>Add</Button>
                        </Tooltip>
                    </div>
                    {_R.isEmpty(group_policy)
                        ?
                        <div className="p1">
                            No Records Found.
                        </div>
            : 
            <Paper className="p1">
                <Table className={'table'} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Policy</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof group_policy != 'undefined' && group_policy.map((p, i)  =>
                        <TableRow hover key={i}>
                            <TableCell>{p.work_policy_name}</TableCell>
                            <TableCell>{p.start_date}</TableCell>
                            <TableCell>{p.end_date}</TableCell>
                            <TableCell>
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onUpdate(p.id)}>
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>  
                </Paper>  
            }
            </div>  
            <DialogBoxMedium 
                    Open={this.state.open} 
                    Cancel={this.onClose}
                    Title={this.state.isEditable ? 'Edit Policy' : 'Add Policy'}
                    OnSubmit={this.onSubmit}
                    ButtonName={'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        <DialogContent
                            EditPermission = {this.state.editPermission}
                            Permission = {this.state.permission}
                            Open = {this.state.open}
                            FormFields = {this.state.formFields}
                            Errors = {this.state.errors}
                            HandleOnChange = {this.handleOnChange}
                            OnSubmit = {this.onSubmit}
                            WorkPolicy = {this.state.workPolicy}
                            GroupID = {this.state.groupID}
                            Group_policy = {this.state.group_policy}
                            AllPolicies = {this.state.allPolicies}
                            HandleDateChange={this.handleDateChange}
                        />
                    }
                />
                {this.state.snackbarOpen &&
                    <CustomizedSnackbars
                        open={this.state.snackbarOpen}
                        variant={this.state.snackbarVariant}
                        message={this.state.snackbarMsg}
                        handleClose={this.snackbarClose}
                    />
                }
            </WorkPolicyStyle>
        );
    };
}
export { WorkPolicyListContainer as WorkPolicyList };
