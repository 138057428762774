import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogBoxMedium } from '../../common/dialog.medium';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import { formatName } from '../../common/common';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import FormHelperText from '@mui/material/FormHelperText';
import parse from 'html-react-parser';
import Link from '@mui/material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const BasicDetailsComponent = (props => {
    let project = props.Project !== null ? props.Project : null;
    let parentProject = props.ParentProject ? props.ParentProject : null;
    return (
        <React.Fragment>
            <div className={'profile-view__left-header'}>
                <div className={'profile-view__basics'}>
                    <div className={'profile-view__basics-item '}> 
                        { parentProject !== null &&
                            <div className={'item full-width'}>
                            <label>Parent Project </label>
                            <span className={"value"}> 
                                 <Link color="primary" onClick={props.ViewLink(parentProject['link'], parentProject['project_id'])} className={'link'}> {parentProject['project_name']} </Link>
                            </span></div>
                        }
                        <div className={'item full-width'}>
                            <label> Client </label>
                            <span className={'value'}> {project['client_name']} </span></div>
                        <div className={'item'}>
                            <label> Start Date </label>
                            <span className={'value'}> {project['project_sdate']} </span></div>
                        <div className={'item'}>
                            <label> End Date </label>
                            <span className={'value'}> {(project['project_edate']) ? project['project_edate'] : "Never"} </span></div>
                    </div>
                </div>
            </div>
            <div className={'profile-view__left-body'}>
                <div className={'profile-view__basics_default'}>
                    <div className={'profile-view__basics__main'}>
                        <div className={'profile-view__basics_default-item item-center item-role'}><label>Project Manager</label><span className={"value"}>{formatName(project['user_first_name'], null, project['user_last_name'], props.NameFormat)}</span></div>
                            { project['project_status_name'] === 'Active'?
                                <div className={'profile-view__basics_default-item item-status status-active'}><label> Status </label><span className={"value"}>{project['project_status_name']}</span></div>
                            :
                                <div className={'profile-view__basics_default-item item-status status-inactive'}><label> Status </label><span className={"value"}>{project['project_status_name']}</span></div>
                            }
                    </div>
                    {(props.OtherPermissions.allView || props.OtherPermissions.pmPrView) &&
                    <>
                    <div className={'profile-view__basics_default-item item-fullwidth'}><label> Description </label><span className={'value'}>{parse(project['project_description'])}</span></div>
                    <div className={'profile-view__basics_default-item '}><label> Pay Type</label><span className={'value'}>{project['paytype'] }</span></div>
                    <div className={'profile-view__basics_default-item '}>
                        <label> Minimum Timeslice Increment </label>
                        <span className={'value'}>{project['minimum_increment']}&nbsp;
                        {project['minimum_increment'] === 0 ? <>
                            <Tooltip title="Time entry form will accept any decimal number" aria-label="add">
                                <InfoOutlinedIcon fontSize='small'/>
                            </Tooltip></>
                            :<></>
                        }
                        </span>
                    </div>
	            {(props.SystemLocation) ? 
	             <>
			<div className={'profile-view__basics_default-item '}><label> Location </label><span className={'value'}> {project['timesheet_location'] === 1 ? "Yes" : "No"}</span></div>    
                        <div className={'profile-view__basics_default-item '}><label> LCR Required </label><span className={'value'}> {project['lcr_required'] === 1 ? "Yes" : "No"}</span></div>
                    {(project['lcr_required'] === 1) ?
                    <div className={'profile-view__basics_default-item '}><label> Request Approval Method </label><span className={'value'}> {project['wlc_approver']}</span></div>
                    :<></>
                    }
	            </> : <> </>}
		    <div className={'profile-view__basics_default-item '}><label> Timesheet Preliminary Review </label><span className={'value'}>{project['prelim_review_flag'] === 1 ? "Yes" : "No"}</span></div>
                    <div className={'profile-view__basics_default-item '}><label> Require Labor Category </label><span className={'value'}>{project['labor_category'] === 1 ? "Yes" : "No"}</span></div>
                    <div className={'profile-view__basics_default-item '}><label> Notify Project Manager</label><span className={'value'}>{project['notify_pm'] === 1 ? "Yes" : "No" }</span></div>
                    <div className={'profile-view__basics_default-item '}><label> Require Client Time sheet system</label><span className={'value'}>{project['client_timesheet'] === 1 ? "Yes" : "No" }</span></div>
                    {( parentProject != null ) &&
                        <div className={'profile-view__basics_default-item '}><label> Inherit Resources </label><span className={'value'}>{project['inherit_resource'] === 1 ? "Yes" : "No" }</span></div>
                    }
                    <div className={'profile-view__basics_default-item '}><label>Is Contract </label><span className={'value'}>{project['is_contract'] === 1 ? "Yes" : "No"}</span></div>
                    <div className={'profile-view__basics_default-item '}><label> COR </label><span className={'value'}>{project['cor_name']}</span></div>
                    {( parentProject !== null ) &&
                        <div className={'profile-view__basics_default-item '}><label> Inherit Reviewers</label><span className={'value'}>{project['inherit_reviewers'] === 1 ? "Yes" : "No"}</span></div>
                    }
                    </>
                    }
                </div>
              {(props.ReviewerViewPermission) && !(parseInt(project['inherit_reviewers'])) &&
              <div className={'profile-view__left-body full-width'}>
                <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Reviewers</Typography>
                {(props.ReviewerEditPermission) &&
                 <Tooltip title="Add Project Reviewer" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'}>
                        <AddIcon onClick={props.OnAddReviewer}/>
                    </Fab>
                </Tooltip>
                }
            </div>
               <div className={'section-content'}>
                <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell colspan="3">{formatName(project['user_first_name'], null, project['user_last_name'], props.NameFormat)} (Project Manager)</TableCell>
                      </TableRow>
                    { typeof props.Reviewers != 'undefined' && props.Reviewers.length
                            ? props.Reviewers.map( (d, index ) =>
                        <TableRow hover>
                            <TableCell> {formatName(
                                            d.user_first_name,
                                            null,
                                            d.user_last_name,
                                            props.NameFormat
                                        )}
 </TableCell>
                            <TableCell> {d.status_label} </TableCell>
                            <TableCell align="right" width="80px">
                            <div className = "action-btn">
                               {(props.ReviewerEditPermission) &&
                                <Tooltip title="Change status" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments" size="small">
                                        <EditIcon fontSize="small" onClick= {() => props.OnChange(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                               }
                               {(props.ReviewerDeletePermission) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments" size="small">
                                        <DeleteIcon fontSize="small" onClick = {() => props.OnDeleteReviewer(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                               }
                                </div>
                            </TableCell>
                        </TableRow>
                        )
                        :<></> }
                    </TableBody>
                </Table>
              </div>
            </div>
              }
            </div>

	    {(project['prelim_review_flag'] === 1) ?
	     <div className={'profile-view__left-body full-width'}>
                <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Preliminary Reviewers</Typography>
                {(props.ReviewerEditPermission) &&
                 <Tooltip title="Add Preliminary Reviewer" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'}>
                        <AddIcon onClick= {props.OnAddPreliminaryReviewer}/>
                    </Fab>
                </Tooltip>
                }
	       </div>
	       <div className={'section-content'}>
                <Table size="small">
                    <TableBody>
                    { typeof props.ProjectPrelimReviewers != 'undefined' && props.ProjectPrelimReviewers.length
                            ? props.ProjectPrelimReviewers.map( (d, index ) =>
                        <TableRow hover>
                            <TableCell> {formatName(
                                            d.user_first_name,
                                            null,
                                            d.user_last_name,
                                            props.NameFormat
                                        )}
		            </TableCell>
		            <TableCell> {d.status == 1 ? "Active":"Inactive"} </TableCell>
		            <TableCell align="right" width="80px">
                            <div className = "action-btn">
			       {(props.ReviewerEditPermission) &&
                                <Tooltip title="Change status" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments" size="small">
                                        <EditIcon fontSize="small" onClick= {() => props.OnChangePrelimStatus(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                               }
                               {(props.ReviewerDeletePermission) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments" size="small">
                                        <DeleteIcon fontSize="small" onClick = {() => props.OnDeletePrelimReviewer(d.id)}/>
                                    </IconButton>
                                </Tooltip>
                               }
                                </div>
                            </TableCell>
	    		 </TableRow>
			  )
                        :<></> }
	    		</TableBody>
	    	</Table>
	    		
		</div>
	    </div> 
	: <></>}	

              {props.PopupType === "delete" && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={"Delete Reviewer"}
                    ButtonName = {"Yes"}
                    OnSubmit={() => props.OnAddReviewerSubmit("delete")}
                    Content={<DialogDelete FormProps={props} />}
                    Loading = {props.ButtonLoader}
                />
            )}
             {props.PopupType === "change" && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={"Change Status"}
                    OnSubmit={() => props.OnAddReviewerSubmit("change")}
                    Content={<DialogChangeStatus FormProps={props} />}
                    Loading = {props.ButtonLoader}
                />
            )}
            {props.PopupType === "addReviewer"&& (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={"Add Project Reviewer"}
                    OnSubmit={() => props.OnAddReviewerSubmit("add")}
                    Content={<DialogAddReviewer FormProps={props} />}
                    Loading = {props.ButtonLoader}
                />
            )}
	    {props.PopupType === "addPreliminaryReviewer"&& (
                <DialogBoxMedium
                    Open={props.OpenPrelim}
                    Cancel={props.OnClose}
                    Title={"Add Preliminary Reviewer"}
                    OnSubmit={() => props.OnAddPreliminaryReviewerSubmit("prelim_add")}
                    Content={<DialogAddPreliminaryReviewer FormProps={props} />}
                    Loading = {props.ButtonLoader}
                />
            )}
	     {props.PopupType === "delete" && (
                <DialogBoxMedium
                    Open={props.OpenPrelim}
                    Cancel={props.OnClose}
                    Title={"Delete Preliminary Reviewer"}
                    ButtonName = {"Yes"}
                    OnSubmit={() => props.OnAddPreliminaryReviewerSubmit("prelim_delete")}
                    Content={<DialogDelete FormProps={props} type="prelim" />}
                    Loading = {props.ButtonLoader}
                />
            )}
	    {props.PopupType === "prelim_change" && (
                <DialogBoxMedium
                    Open={props.OpenPrelim}
                    Cancel={props.OnClose}
                    Title={"Change Status"}
                    OnSubmit={() => props.OnAddPreliminaryReviewerSubmit("prelim_change")}
                    Content={<DialogChangePrelimStatus FormProps={props} />}
                    Loading = {props.ButtonLoader}
                />
            )}
             <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid ? 'success' : 'error'}
                message={props.Valid ? props.SuccessMessage : props.ErrorMessage}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    );
});
const DialogDelete = (props) => {
     return (
	 (props.type !== 'undefined' && props.type == "prelim") ?
	    <>
               <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom>Do you want to delete preliminary reviewer?</Typography>
            </>	
	    : 
            <>
               <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom>Do you want to delete reviewer?</Typography>
            </>
     )
}

const DialogChangeStatus = (props) => {
    const { FormProps } = props;
     return (
         <>
         <form className="form-fullwidth">
            <Grid container spacing={3}>
             <Grid item xs={12} md={6}>
                                <FormControl>
                                <TextField
                                label="Reviewer"
                                variant="standard"
                                type="text"
                                disabled
                                value={FormProps.ReviewerName}
                            />
                        </FormControl>
             </Grid>
             <Grid item xs={12} md={6}>
                              <FormControl variant="standard">
                                    <InputLabel htmlFor="stat">
                                        Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.sel_status !== "undefined"
                                                ? FormProps.FormFields.sel_status
                                                : '0'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "sel_status",
                                            id: "sel_status",
                                        }}
                                    >
                                    <MenuItem value="0">Inactive</MenuItem>
                                    <MenuItem value="1">Active</MenuItem>
                                    </Select>
                                </FormControl>
                    </Grid>
                     </Grid>
        </form>
         </>
     )
}
const DialogAddReviewer = (props) => {
    const { FormProps } = props;
     return (
        <>
         <form className="form-fullwidth">
            <Grid container spacing={3}>

             <Grid item xs={12} md={6}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="users">
                                        Reviewer
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.sel_user !== "undefined"
                                                ? FormProps.FormFields.sel_user
                                                : '0'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "sel_user",
                                            id: "sel_user",
                                        }}
                                    >
                                        <MenuItem value="0">-All-</MenuItem>
                                        {typeof FormProps.ReviewerList != "undefined" &&
                                            FormProps.ReviewerList.length >
                                                0 &&
                                            FormProps.ReviewerList.map(
                                                (t, i) => (
                                                    <MenuItem
                                                        value={t.user_id}
                                                        key={"stst-" + i}
                                                    >
                                                      {formatName(t.first_name, null, t.last_name)}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                    {FormProps.Errors.sel_user && <FormHelperText className="error">{FormProps.Errors.sel_user}</FormHelperText> }
                                </FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="users">
                                        Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.rev_status !== "undefined"
                                                ? FormProps.FormFields.rev_status
                                                : '1'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "rev_status",
                                            id: "rev_status",
                                        }}
                                    >
                                        <MenuItem value='1'>Active</MenuItem>
                                        <MenuItem value='0'>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                    </Grid>
         </Grid>
        </form></>
    )
}

const DialogAddPreliminaryReviewer = (props) => {
    const { FormProps } = props;
     return (
        <>
         <form className="form-fullwidth">
            <Grid container spacing={3}>

             <Grid item xs={12} md={6}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="users">
                                        Preliminary Reviewer
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.sel_prelim_user !== "undefined"
                                                ? FormProps.FormFields.sel_prelim_user
                                                : '0'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "sel_prelim_user",
                                            id: "sel_prelim_user",
                                        }}
                                    >
                                        <MenuItem value="0">-All-</MenuItem>
                                        {typeof FormProps.PrelimReviewerList != "undefined" &&
                                            FormProps.PrelimReviewerList.length >
                                                0 &&
                                            FormProps.PrelimReviewerList.map(
                                                (t, i) => (
                                                    <MenuItem
                                                        value={t.user_id}
                                                        key={"ststprelim-" + i}
                                                    >
                                                      {formatName(t.first_name, null, t.last_name)}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
				      {FormProps.Errors.sel_prelim_user && <FormHelperText className="error">{FormProps.Errors.sel_prelim_user}</FormHelperText> }
                                </FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="users">
                                        Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.rev_status !== "undefined"
                                                ? FormProps.FormFields.rev_status
                                                : '1'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "rev_status",
                                            id: "rev_status",
                                        }}
                                    >
                                        <MenuItem value='1'>Active</MenuItem>
                                        <MenuItem value='0'>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                    </Grid>
         </Grid>
        </form></>
    )
}
const DialogChangePrelimStatus = (props) => {
    const { FormProps } = props;
     return (
         <>
         <form className="form-fullwidth">
            <Grid container spacing={3}>
             <Grid item xs={12} md={6}>
                                <FormControl>
                                <TextField
                                label="Preliminary Reviewer"
                                variant="standard"
                                type="text"
                                disabled
                                value={FormProps.PrelimReviewerName}
                            />
                        </FormControl>
             </Grid>
             <Grid item xs={12} md={6}>
                              <FormControl variant="standard">
                                    <InputLabel htmlFor="stat">
                                        Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof FormProps.FormFields.prelim_sel_status !== "undefined"
                                                ? FormProps.FormFields.prelim_sel_status
                                                : '0'
                                        }
                                        onChange={FormProps.HandleOnChange}
                                        inputProps={{
                                            name: "prelim_sel_status",
                                            id: "prelim_sel_status",
                                        }}
                                    >
                                    <MenuItem value="0">Inactive</MenuItem>
                                    <MenuItem value="1">Active</MenuItem>
                                    </Select>
                                </FormControl>
                    </Grid>
	      </Grid>
        </form>
         </>
     )
}
