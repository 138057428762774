import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {StatesComponent}  from './States.Component';
import { ErrorPage } from '../common/error';

const formFields = {
    'id':0,
    'name': '',
    'short_code': '',
    'country_id': '',
};

export class StatesView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            states : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
        };
        this.editStateClick = this.editStateClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddState = this.onAddState.bind(this);
        this.deleteStateClick = this.deleteStateClick.bind(this);
        this.deleteState = this.deleteState.bind(this);
    }
    componentDidMount = () => {
        this.getAllCountries();
        this.getAllStates();
    };
    getAllStates = () => {
        let apiEndpoint = 'api/settings/getAllStates';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data && response.data.permission) {
                    if (response.data.states && response.data.states.length) {
                        let editPermission = false;
                        if (response.data.edit_permission) {
                            editPermission = true;
                        }
                        this.setState({
                            states : response.data.states,
                            editPermission : editPermission,
                        });
                    } 
                } else {
                    this.setState({
                        hasPermission : false,
                    });

                }
            })          
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getAllCountries = () => {
        let apiEndpoint = 'api/settings/getAllCountries';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data && response.data.permission) {
                    if (response.data.country && response.data.country.length) {
                        this.setState({countrylist : response.data.country});
                    } 
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    editStateClick = (id) => () => {
        let apiEndpoint = 'api/settings/get-state-details-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.state_details) {
                        this.setState({
                            formFields : response.data.state_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
     getCountryNameById = (id) => {
        if(this.state.countrylist){
            let cname = '';
            let countries_req = this.state.countrylist;
            countries_req.forEach(function(item){
                if(item.id === id){
                    cname = item.name;
                }
            });
            return cname;
        }
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddState = () => {
        let formFields = {
            'id':0,
            'name': '',
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;            
            if (data) {
                 httpServices.post('api/settings/saveState', { data })
                    .then(response => {
                        if (response.data.status) {
                            let added_country_name  = this.getCountryNameById(response.data.country_id);
                            if(data.id){
                                // Update DOM
                                const index = this.state.states.findIndex(rr => rr.id === data.id);
                                data['c_name'] = added_country_name;
                                let states = this.state.states;
                                states[index] = data;
                                this.setState({
                                    states : states,
                                    open : false
                                });
                                alert("State details updated successfully");
                            } else {
                                let states = this.state.states;
                                data['id'] = response.data.id;
                                data['c_name'] = added_country_name;
                                states.push(data);
                                this.setState({
                                    states : states,
                                    open : false,
                                });
                                alert("State details saved successfully");
                            }
                        } else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                            else {
                                alert("Save failed. Duplicate state name not allowed");
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.short_code) {
            errors["short_code"] = "Cannot be empty";
            formIsValid = false;
        }
        else if (this.state.formFields.short_code.length > 2) {
            errors["short_code"] = "Cannot be greater than two characters";
            formIsValid = false;
        }
        if (!this.state.formFields.country_id) {
            errors["country_id"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    deleteStateClick = (id) => () => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteStateId : id,
            });
        }
    };
    deleteState = () => {
        let del_id = this.state.deleteStateId;
        if (del_id) {
            let apiEndpoint = 'api/settings/delete-state';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    // Dom updation
                    if (response.data.status) {
                        const index = this.state.states.findIndex(rr => rr.id === del_id);
                        let states = this.state.states;
                        states.splice(index, 1);
                        this.setState({
                            states : states,
                            open : false
                        });
                        alert("State deleted successfully");
                    } else {
                        alert(response.data.err_msg);
                        this.setState({
                            open : false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };

    render(){
        return (
            (this.state.hasPermission)
            ?
            <StatesComponent
                States = {this.state.states} 
                Countries = {this.state.countrylist}
                EditStateClick = {this.editStateClick}
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddStateClick = {this.onAddState}                
                PopupType = {this.state.popupType}
                DeleteStateClick = {this.deleteStateClick}
                DeleteState = {this.deleteState}
                EditPermission = {this.state.editPermission}
            />
            : <ErrorPage Message={'You have no permission to view states'} />
        )
    }
}
