import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const AccountEdit = (props) => {
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            id="account_username"
                            name="account_username"
                            label="Username"
                            error={props.Errors.account_username && true}
                            type="text"
                            value={typeof props.FormData != 'undefined' && props.FormData.account_username}
                            onChange={props.HandleOnChange}
                        />
                        <small className='error'>{props.Errors && props.Errors.account_username}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                    <InputLabel htmlFor="account_provider" error={props.Errors.account_provider && true} required >Auth Type</InputLabel>
                    <Select
                        value={typeof props.FormData != 'undefined' && props.FormData.account_provider}
                        onChange={props.HandleOnChange}
                        error={props.Errors.account_provider && true}
                        inputProps={{
                            name: 'account_provider',
                            id: 'account_provider',
                        }}
                    >
                    { typeof props.FormInputs != 'undefined' && props.FormInputs.authProvider.map((t, i) => 
                        <MenuItem value={t.id}>{t.display_name}</MenuItem>

                    )}
                    </Select>
                    <small className='error'>{props.Errors.account_provider && props.Errors.account_provider}</small>
                </ FormControl>
                </Grid>
                {(!props.isEditable && props.FormData.change_password && parseInt(props.FormData.change_password)) ? <>
                <Grid item xs={12}>
                    <FormControl>
                        <TextField
                            id="new_pswd"
                            name="new_pswd"
                            label="New Password"
                            type="password"
                            required
                            value={props.FormData.new_pswd}
                            onChange={props.HandleOnChange}
                            error={props.Errors.new_pswd && true}
                            helperText={props.Errors.new_pswd}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <TextField
                            id="confm_pswd"
                            name="confm_pswd"
                            label="Confirm Password"
                            type="password"
                            required
                            value={props.FormData.confm_pswd}
                            onChange={props.HandleOnChange}
                            error={props.Errors.confm_pswd && true}
                            helperText={props.Errors.confm_pswd}
                        />
                    </FormControl>
                </Grid>
                </>:<></>
                }
                <Grid item xs={12} md={12}>
                    <FormControl error={props.Errors.account_status && true} variant="standard">
                    <InputLabel htmlFor="status" required>Account Status</InputLabel>
                    <Select
                        value={typeof props.FormData != 'undefined' && props.FormData.account_status}
                    onChange={props.HandleOnChange}
                    inputProps={{
                        name: 'account_status',
                        id: 'account_status',
                    }}>
                    { typeof props.FormInputs != 'undefined' && props.FormInputs.status.map((o, i) =>
                        <MenuItem value={o.user_status_id}>{o.user_status_name}</MenuItem>
                    )}
                </Select>
                <small className='error'>{props.Errors.account_status && props.Errors.account_status}</small>
            </ FormControl>
            </Grid>
        </Grid>
        </form>
    )
}
