import React from 'react';
import * as _r from 'ramda';

import { BenefitComponent } from './Benefit.Component';
import { httpServices } from '../../../../services/httpServices';
import { ErrorPage } from '../../../common/error';

const formFields = {
    'id':0,
    'insurance_type': null,
    'start_date': null,
    'end_date': null,
    'plan_type': null,
    'coverage': null,
    'paycheck_start_date': null,
    'paycheck_end_date' : null,
    'fsa_effective_date': null,
    'hsa_effective_date': null,
    'disability_type': null,
    'safeharbor_contrib': null,
    'deduction_components':null,
    'ctc_components':null,
};

export class BenefitDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            BenefitData:[],
            InsuranceStateData:[],
            formFields: formFields,
            open: false,
            isEditable: false,
            errors: {},
            familyAdd: false,
            family_id: null,
            insurance_id: null,
            editBasic: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChange2 = this.handleOnChange2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        if (field === 'family_id') {
            this.setState({'family_id': evt.target.value});
        }

        else {
            const ff = this.state.formFields;
            ff[field] = evt.target.value;
            this.setState({
                formFields: ff
            });
        }
    };

    handleOnChange2 = (evt, value) => {
        const field = evt.target.name;
            const ff = this.state.formFields;
            ff[field] = evt.target.value;
            this.setState({
                formFields: ff
            });
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        else
        { 
            let start_date = new Date(this.state.formFields.start_date);
            let join_date = new Date(this.props.Benefits.staff_join_date);
            if(start_date < join_date){
                errors["start_date"] = "Start date cannot be before joining date";
                formIsValid = false;
            }
        }

        if (!this.state.formFields.paycheck_start_date) {
            errors["paycheck_start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.insurance_type) {
            errors["insurance_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if(this.state.formFields.start_date && this.state.formFields.end_date){
            let start_date = new Date(this.state.formFields.start_date);
            let end_date = new Date(this.state.formFields.end_date);
            if(start_date > end_date){
                errors["end_date"] = "End date cannot be before start date";
                formIsValid = false;
            }
        }
        if(this.state.formFields.paycheck_start_date && this.state.formFields.paycheck_end_date){
            let paycheck_start_date = new Date(this.state.formFields.paycheck_start_date);
            let paycheck_end_date = new Date(this.state.formFields.paycheck_end_date);
            if(paycheck_start_date > paycheck_end_date){
                errors["paycheck_end_date"] = "End date cannot be before start date";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleValidation2() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.family_id) {
            errors["family_id"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleValidation3() {
        let formIsValid = true;
        let errors = {};
       
        if (!this.state.formFields.hsa_effective_date) {
            errors["hsa_effective_date"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/staff/benefit/add-insurance', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data) {
                                if(data.id){
                                    let details = response.data.insurance_details;
                                    this.setState({InsuranceStateData:details, open: false});
                                    alert("Insurance details updated successfully");
                                } else {
                                    let details = response.data.insurance_details;
                                    this.setState({
                                        InsuranceStateData:details,
                                        open: false,
                                    });
                                    alert("Insurance details saved successfully");
                                }
                            }
                            else
                            {
                                const ff = this.state.errors;
                                ff['insurance_overlap'] = "Insurance already exist for this period";
                                this.setState({ Open:true,errors:ff});
                            }
                        }
                        else {
                            this.setState({errors:response.data.errors}); 
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    onAdd = () => {
        this.clearForm();
        if (this.state.InsuranceStateData.length===0){
            this.setState({InsuranceStateData : this.props.Benefits.insurance_details});
        }
        this.setState({
            open: true,
            isEditable: false,
        });
    };

    onUpdate = (id) => {
        if (this.state.InsuranceStateData.length===0){
            this.setState({InsuranceStateData : this.props.Benefits.insurance_details});
        }
        return httpServices.post('api/staff/benefit/get-insurance-details-by-id', {'id': id})
            .then(response => {

                if (response.data) {
                    let data = response.data;
                    if (Object.keys(data).length){
                        const inputs = {
                            'id': data.id,
                            'insurance_type': data.insurance_type_id,
                            'start_date': data.start_date,
                            'end_date': data.end_date,
                            'plan_type': data.plan_type_id,
                            'coverage': data.coverage_id,
                            'paycheck_start_date': data.paycheck_start_date,
                            'paycheck_end_date': data.paycheck_end_date,
                            'monthly_prem_employee':data.monthly_premium_employee,
                            'monthly_prem_family':data.monthly_premium_family,
                            'employer_contrib_employee':data.employer_contrib_employee,
                            'employer_contrib_family':data.employer_contrib_family,
                            'employee_contrib_employee':data.employee_contrib_employee,
                            'employee_contrib_family':data.employee_contrib_family,
                            'deduction_components':data.ded_component_id,
                            'ctc_components':data.ctc_component_id,
                        };
                        this.setState({
                            formFields: inputs,
                            open: true,
                            id: data.id,
                            isEditable: true,
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onDelete = (index, rowID) => {
       var len = this.state.InsuranceStateData.length;
        if (len === 0) {
            this.setState({InsuranceStateData:this.props.Benefits.insurance_details}, function(){
                let b = this.state.InsuranceStateData;
                b.splice(index, 1);
                this.setState({InsuranceStateData: b});
            });
        }
        else {
            let b = this.state.InsuranceStateData;
            b.splice(index, 1);
            this.setState({InsuranceStateData: b});

        }
        return httpServices.post('api/staff/benefit/delete-insurance', {'id': rowID, 'staff_id': this.props.StaffID})
        .then(response => {
            if (!response.data.error) {
                alert("Insurance details deleted successfully");
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });

    }

    onAddFamily = (insurance_id) => {
        if (this.state.BenefitData.length===0){
            this.setState({BenefitData : this.props.Benefits});
        }
        this.setState({
            open: true,
            familyAdd: true,
            insurance_id: insurance_id,
            family_id: null
        });
    };

    onFamilySubmit = (event) => {
        if(this.handleValidation2()){
            let data = {
                'staff_id': this.props.StaffID,
                'insurance_id': this.state.insurance_id,
                'family_id': this.state.family_id
            };
            httpServices.post('api/staff/benefit/add-family-insurance', { data })
            .then(response => {
                if (!response.data.errors) {
                    if (response.data.valid) {
                        let details = this.state.BenefitData;
                        details.insured_family.push(response.data.insured_family);
                        alert("Family Insurance details saved successfully");
                        this.setState({
                            BenefitData:details,
                            open: false,
                        });
                    }
                    else {
                        alert("Already exist");
                    }
                }
                else {
                    this.setState({ errors: response.data.errors });
                }
            })
            .catch(function(error) {
                console.log("error" + error);
            });
        }
    }

    handleFamilyDelete = (id, rows) => {
        if (window.confirm("Do you really want to delete this record?")) {
            let idx = rows.findIndex(x => x.id === id);
            var len = this.state.BenefitData.length;
            if (len === 0) {
                this.setState({BenefitData:this.props.Benefits}, function(){
                    let b = this.state.BenefitData;
                    b.insured_family.splice(idx, 1);
                    this.setState({BenefitData: b});
                });
            }
            else {
                let b = this.state.BenefitData;
                b.insured_family.splice(idx, 1);
                this.setState({BenefitData: b});

            }
            return httpServices.post('api/staff/benefit/delete-insured-family', {'id': id, 'staff_id': this.props.StaffID})
            .then(response => {
                if (!response.data.error) {
                    alert("Family Insurance details deleted successfully");      
               }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }

    onEditBasic = () => {
        if (this.state.BenefitData.length===0){
            this.setState({BenefitData : this.props.Benefits});
        }
        this.setState({
            open: true,
            editBasic: true,
        });
    };

    onUpdateBasic = (event) => {
        if(this.handleValidation3()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/staff/benefit/update-benefit-config', { data })
                .then(response => {
                    if (!response.data.input_errors) {
                        if (response.data) {
                            if(response.data.fsa_effective_date && response.data.hsa_effective_date){
                                data['fsa_effective_date'] = response.data.fsa_effective_date;
                                data['hsa_effective_date'] = response.data.hsa_effective_date;
                            }
                            data['enable_safeharbor_contrib'] = response.data.safeharbor_contrib;
                            data['disability'] = response.data.disability;
                            let details = this.state.BenefitData;
                            details.benefit_config = response.data;
                            alert("Benefit config details updated successfully");
                            this.setState({BenefitData:details, open: false, formFields: data});
                        }
                    }
                    else {
                          this.setState({errors :response.data.input_errors});
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
            }
        }
    }

    onClose = (isBasicEdit) =>{
        this.clearForm();
        this.setState({
            open: false,
            familyAdd: false,
            editBasic: false
        });
    };

    componentDidMount = () => {
        let dataArr = this.state.BenefitData.length>0?this.state.BenefitData:this.props.Benefits;
        if(_r.type(dataArr) !== 'Undefined' && 'benefit_config' in dataArr && !_r.isNil(dataArr.benefit_config)) {
            let formData = {
                'fsa_effective_date': _r.type(dataArr['benefit_config']['fsa_effective_date'] !== 'Undefined')?dataArr['benefit_config']['fsa_effective_date']:null,
                'hsa_effective_date': _r.type(dataArr['benefit_config']['hsa_effective_date'] !== 'Undefined')?dataArr['benefit_config']['hsa_effective_date']:null,
                'disability_type': dataArr['benefit_config']['disability_id'],
                'safeharbor_contrib': dataArr['benefit_config']['enable_safeharbor_contrib']
            };
            this.setState({formFields: formData});
        }
    };

    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            let exceptional = ['fsa_effective_date', 'hsa_effective_date', 'disability_type', 'safeharbor_contrib'];
            if (exceptional.indexOf(key) === -1) {
                ff[key] = (key === 'id')?0:null;
            }
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            isEditable: false,
        });
    };

    render(){
        return(
            (this.props.Benefits.permission) ?
            <BenefitComponent
                Data={this.state.BenefitData.length>0?this.state.BenefitData:this.props.Benefits}
                InsuranceData = {this.state.InsuranceStateData.length>0?this.state.InsuranceStateData:this.props.Benefits.insurance_details}
                DisabilityData = {this.props.Benefits.disability_benefits}
                OnAdd={this.onAdd.bind(this)}
                FormFields={this.state.formFields} 
                Errors={this.state.errors}
                Open={this.state.open}
                OnClose={this.onClose.bind(this)}
                HandleOnChange={this.handleOnChange}
                OnSubmit={this.handleSubmit}
                OnDelete={this.onDelete.bind(this)}               
                OnUpdate={this.onUpdate.bind(this)}
                StaffID={this.props.StaffID}
                HandleFamilyDelete={this.handleFamilyDelete.bind(this)}
                FamilyAdd={this.state.familyAdd}
                OnAddFamily={this.onAddFamily.bind(this)}
                FamilyID={this.state.family_id}
                OnFamilySubmit={this.onFamilySubmit.bind(this)}
                OnEditBasic={this.onEditBasic.bind(this)}
                HandleOnChange2={this.handleOnChange2}
                IsEditBasic={this.state.editBasic}
                IsEditable = {this.state.isEditable}
                OnUpdateBasic={this.onUpdateBasic.bind(this)}
            />
            :
            <ErrorPage/>
        )
    }
}
