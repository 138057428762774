import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';

export const OfficeComponent = (props) => {
    const offices = typeof props.Offices !== 'undefined' ? props.Offices : [];
    return (
        <>
            <div className={'tableSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                    {props.EditPermission &&
                    <Tooltip title="Add Time Off Type" aria-label="add">
                        <div className={'header-action'}>
                            <Button variant="contained" color="primary" size="small" onClick={props.AddOfficeClick} >Add</Button>
                        </div>
                    </Tooltip>
                    }
            </div>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={"Office Locations"}
                        columns={[
                            {
                                title: 'Office',
                                field: 'name',
                            },                            
                        ]}
                        data={props.Offices}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                hidden: !props.EditPermission,
                                onClick: (event, rowData) => {
                                    props.EditClick(rowData.id);
                                },
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                hidden : props.EditPermission ? false : true,
                                onClick: (event, rowData) => {
                                    props.DeleteOffice(rowData.id);
                                },
                            }                         
                        ]}
                    />
                </Grid>
            </Grid>
            {props.Open &&
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.OnClose}
                Title = { props.PopupType === 'add' ? 'Add Office' : 'Edit Office' }
                OnSubmit = {props.OnSubmit}
                Content = {<DialogAdd FormProps={props}/>}
                Loading ={props.ButtonLoader}
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                OnSubmit={props.DeleteOfficeSubmit}
                Title='Confirm Delete'
                ButtonName='Ok'   
                Content={
                    <DialogWithConfirmMessage msg="Do you really want to delete this office location ?" />
                }
            />
            }
        </React.Fragment>
        </>
    )
}
const DialogAdd = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            required
                            id = "name"
                            name = "name"
                            label = "Office Location"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { FormFields.name ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.name !== 'undefined' && Errors.name) && true }
                            helperText = { typeof Errors.name !== 'undefined' && Errors.name }
                        />
                    </ FormControl>
                </Grid>                
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
