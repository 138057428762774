import React from 'react';
import {AdjustmentsComponent} from './Adjustments.Component';
import {httpServices}  from '../../../../services/httpServices';

const formFields = {
    'component': null,
    'component_id': null,
    'adjusted_amount': null,
    'year' : '',
    'note' : '',
    'edit_id': 0
}

export class Adjustments extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            intervals:[],
            errors: {},
            components: [],
            open:false,
            isEditable: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'component_id') {
            f.component = evt.currentTarget.dataset.component;
        }
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

    handleValidation() {
        let formIsValid = false;
        let errors = {};
        if (!this.state.formFields.year) {
            errors["year"] = "Cannot be empty";
        }
        if (!this.state.formFields.component_id) {
            errors["component_id"] = "Cannot be empty";
        }
        if (!this.state.formFields.adjusted_amount) {
            errors["adjusted_amount"] = "Cannot be empty";
        }
        else {
            var amt = this.state.formFields.adjusted_amount;
            amt = amt.replace(/([^0-9\\.])/i, '');
            let re = /,/g
            amt = amt.replace(re,'');
            if (!amt) {
                errors["adjusted_amount"] = "Cannot be empty";
            }
            else if (amt && !amt.match(/^[+-]?[$]?\d+(\.\d+)?$/)) {
                errors["adjusted_amount"] = "amount is invalid";
            }
        }
        if (Object.keys(errors).length === 0) {
            errors = {};
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    saveAdjustments =() => {
        if (this.handleValidation()) {
            var editID = this.state.edit_id;
            const data = {
                'staff_id': this.props.StaffID,
                'component_id': this.state.formFields.component_id,
                'amount': this.state.formFields.adjusted_amount,
                'year': this.state.formFields.year,
                'note': this.state.formFields.note,
                'edit_id': editID,
            }
            return httpServices.post('api/compensation/save_adjustments', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.status) {
                            this.setState({components : response.data.component_adjustments});
                            if (editID) {
                                this.setState({isEditable: false});
                                alert("Adjustments has been updated successfully");
                            }
                            else {
                                alert("Adjustments has been added successfully");
                            }
                            this.onClose();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }

    onAddComponent = () => {
        this.setState({
            open: true,
            edit_id: 0
        });
    }

    onClose = () =>{
        let formFields = {
            'year': null,
            'component': null,
            'component_id': null,
            'adjusted_amount': null,
            'note' : '',
            'edit_id': 0
        }

        this.setState({
            open: false,
            edit_id: 0,
            errors:{},
            formFields : formFields,
            isEditable:false
        });
    }

    onUpdate = (id) => {
        return httpServices.post('api/compensation/get_adjustments', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        formFields: response.data.component, 
                        open: true, 
                        edit_id:id,
                        id: id,
                        title: response.data.component.title,
                        isEditable: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onDelete = (rowId) => {
        return httpServices.post('api/compensation/delete_adjustment', {'id': rowId, 'staff_id': this.props.StaffID})
        .then(response => {
            if (!response.data.error) {
                this.setState({components : response.data.component_adjustments});
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    render(){
        return(
            <AdjustmentsComponent
                    Components={this.state.components.length>0?this.state.components:this.props.AdjustmentComponents}
                    ConfigComponents={this.props.ConfigComponents}
                    SaveAdjustments={this.saveAdjustments.bind(this)}
                    HandleOnChange={this.handleOnChange}
                    FormFields={this.state.formFields} 
                    Open={this.state.open}
                    Close={this.onClose.bind(this)}
                    AddComponent={this.onAddComponent.bind(this)}
                    OnDelete={this.onDelete.bind(this)}
                    HandleUpdate={this.onUpdate.bind(this)}
                    Errors={this.state.errors}
                    Edit={this.props.Edit}
                    isEditable = {this.state.isEditable}
                    Delete={this.props.Delete}
                />
        )
    }
}
