import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../../../common/common';

const DialogContent = (p) => {
    const {FormProps} = p;
    return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="referred_staff_id" required error={FormProps.Errors.referred_staff_id && true}>Refered Employee</InputLabel>
                            <Select
                                value={_R.type(FormProps.FormFields) !== 'Undefined' && FormProps.FormFields.referred_staff_id}
                                onChange={FormProps.HandleOnChange}
                                inputProps={{
                                    name: 'referred_staff_id',
                                    id: 'referred_staff_id',
                                }}>
                                <MenuItem value={null} key={'i-referred-0'}>Select</MenuItem>
                                {_R.type(FormProps.UserInfo) !== 'Undefined' && FormProps.UserInfo.map((t, i) =>
                                   <MenuItem value={t.staff_id} key={'referred-'+i}>{formatName(t.first_name,null,t.last_name)}</MenuItem>
                                )}
                            </Select>
                            {FormProps.Errors.referred_staff_id && <FormHelperText className = 'error'>{FormProps.Errors.referred_staff_id }</FormHelperText>}
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="doh_referred_staff"
                                name="doh_referred_staff"
                                label="Date Of Hire"
                                type="date"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.doh_referred_staff && true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={FormProps.Errors.doh_referred_staff}
                                value={_R.type(FormProps.FormFields) !== 'Undefined' && FormProps.FormFields.doh_referred_staff}
                            />
                        </ FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="bonus_amount"
                                name="bonus_amount"
                                label="Bonus Amount"
                                type="text"
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.bonus_amount && true}
                                helperText={FormProps.Errors.bonus_amount}
                                value={_R.type(FormProps.FormFields) !== 'Undefined' && FormProps.FormFields.bonus_amount}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
           )
    };
export const ReferralBonusComponent = (props) => {
    return (
        <>
             <React.Fragment>
                    <div  className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Referral Bonus"}
                        columns={[
                                    { title: 'Referred Employee', field: 'referred_employee'},
                                    { title: 'Date of Hire', field: 'doh_referred_staff'},
                                    { title: 'Bonus Amount', field: 'bonus_amount' },
                        ]}            
                        data={props.BonusInfo}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Referral Details',
                                isFreeAction: true,
                                onClick: (event, newData) => {
                                props.AddComponent();
                            },

                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.Update(rowData.id);
                                },
                            },
                        ]}
                        editable={{
                        onRowDelete: rowData =>
                            new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                const index = props.BonusInfo.indexOf(rowData);
                                props.OnDelete(index, rowData.id);
                            }
                            resolve()
                            }, 500)
                        }),
                    }}
                    />
                    </div>
                    <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {'Add Referral Details'}
                    OnSubmit={props.SaveReferral}
                    Content={<DialogContent FormProps={props}/>}
                    />
        </React.Fragment>
        </>
    )
};
