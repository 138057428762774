import React from 'react';
import { ChangeaccrualIgnoredComponent } from './changeAccrualIgnored.Component.js';
import * as _R from 'ramda';
import { httpServices } from '../../../services/httpServices';

export class ChangeaccrualIgnoredContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Change Accrual Ignored Timeoff Type',
            accrualignored: [],
        }
    }
    componentDidMount = () => {
        this.getAccrualIgnoredTimeoff();
    };
    getAccrualIgnoredTimeoff = () => {
        let id = this.props.timeoffId;
        let apiEndpoint = 'api/settings/timeoff/getAccrualIgnoredTimeoff';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data.permission) {
                    console.log("permission true")
                    this.setState({
                        accrualignored: response.data.accrualignored,
                    });
                }
            })
    }
    render() {
        return (
            <ChangeaccrualIgnoredComponent
                Tiitle={this.state.title}
                Accrualignored={this.state.accrualignored.length > 0 ? this.state.accrualignored : undefined}
            />
        )
    }
}
