import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

export class TBDForm extends React.Component {
    render() {
        return (
            <>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                required
                                value={
                                    typeof this.props.FormFields.first_name !==
                                    "undefined"
                                        ? this.props.FormFields.first_name
                                        : null
                                }
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.first_name && true}
                                helperText={this.props.Errors.first_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                value={
                                    typeof this.props.FormFields.last_name !==
                                    "undefined"
                                        ? this.props.FormFields.last_name
                                        : null
                                }
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.last_name && true}
                                helperText={this.props.Errors.last_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl
                            error={this.props.Errors.user_status && true}
                        >
                            <InputLabel htmlFor="status" required>
                                Status
                            </InputLabel>
                            <Select
                                value={
                                    typeof this.props.FormFields.user_status !==
                                    "undefined"
                                        ? this.props.FormFields.user_status
                                        : ""
                                }
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: "user_status",
                                    id: "user_status",
                                }}
                            >
                                {typeof this.props.FormInputs.user_status !=
                                    "undefined" &&
                                    this.props.FormInputs.user_status.map(
                                        (g, i) => (
                                            <MenuItem
                                                value={g.user_status_id}
                                                key="status-{g.id}"
                                            >
                                                {g.user_status_name}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                            <FormHelperText>
                                {this.props.Errors.user_status}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: 'flex-end'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.props.HandleTbdSubmit}
                    >
                        Save
                    </Button>
                </div>
            </>
        );
    }
}


