import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatName } from "../../common/common";
import * as _R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import TablePagination from '@mui/material/TablePagination';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from '@mui/material/FormHelperText';
import { DialogBoxMedium } from '../../common/dialog.medium';


export const ResourcesComponent = (props => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
        '& .tooltip': {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 500,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid black',
        },
    }));   

    return (
        <React.Fragment>
            {(props.ActionType == 'Copy') || (props.ActionType == 'AddSubProject' && props.InheritResource) ?
                <>
                    {(props.OtherPermissions.allView || props.OtherPermissions.pmView || props.OtherPermissions.resourceView) &&
                        <div className={'section-main'}>
                            <div className={'listSubHeader'}>
                                <div className={'col-left'}>
                                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                    </Typography>
                                    {props.InheritResourceFlag &&
                                        <p className="error">
                                            Resources are inherited from the parent project and can only be managed from there.
                                        </p>}
                                </div>
                                <div className={'action'}>
                                </div>
                            </div>
                            <>
                                <TableContainer >
                                    <Table aria-label="simple table"  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {props.ActionType != "AddSubProject" &&
                                                        <Checkbox
                                                            onChange={props.HandleCheckboxChange}
                                                            inputProps={{
                                                                name: 'select_all',
                                                                id: 'select_all',
                                                            }}
                                                            checked={props.SelectAllResources}
                                                            color="primary"
                                                        />
                                                    }
                                                </TableCell>
                                                <TableCell> Resource Name </TableCell>
                                                <TableCell> Labor Category </TableCell>
                                                {(typeof props.ContractVehicles !== 'undefined' && props.ContractVehicles.length > 0) &&
                                                    <TableCell> GSA Labor Category </TableCell>
                                                }
                                                <TableCell> Start Date </TableCell>
                                                <TableCell> End Date </TableCell>
                                                <TableCell> Staff Type </TableCell>
                                                <TableCell> Employment Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {(props.ProjectResources.length === 0) &&
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="200">No resource found</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        }
                                        <TableBody>
                                            {Object.keys(props.ProjectResources).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                                <>
                                                    <TableRow className={(props.ProjectResources[row].task_count === null || props.ProjectResources[row].task_count === "0") ? 'warning-bg' : ''}>
                                                        <TableCell>
                                                            {props.ActionType != "AddSubProject" &&
                                                                <Checkbox
                                                                    onChange={(e) => props.HandleCheckboxChange(e, props.ProjectResources[row].id)}
                                                                    inputProps={{
                                                                        name: 'row_select',
                                                                        id: 'row_select',
                                                                    }}
                                                                    checked={(props.SelectedResources.includes(props.ProjectResources[row].id)) ? true : false}
                                                                    color="primary"
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell> {formatName(props.ProjectResources[row].first_name, null, props.ProjectResources[row].last_name)}</TableCell>
                                                        {(props.ProjectResources[row].lcat_info_data.length > 1) ?
                                                            <TableCell>
                                                                <HtmlTooltip placement="bottom"
                                                                    title={
                                                                        <React.Fragment>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table" >
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell></TableCell>
                                                                                            <TableCell> Labor Category </TableCell>
                                                                                            <TableCell> Start Date </TableCell>
                                                                                            <TableCell> End Date </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {(props.ProjectResources[row].lcat_info_data).length > 1 && props.ProjectResources[row].lcat_info_data.map((r, i) =>
                                                                                            <TableRow  >
                                                                                                <TableCell>{r.lcat_name} {r.is_excluded == 1 ? <Chip style={{ backgroundColor: 'white', color: 'red', height: '15px', fontSize: '0.6rem', width: '70px' }} label="Excluded!" variant="outlined" /> : ''}</TableCell>
                                                                                                <TableCell>{r.start_date}</TableCell>
                                                                                                <TableCell>{r.end_date}</TableCell>
                                                                                            </TableRow>
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </React.Fragment>
                                                                    }>
                                                                    <span className={'MuiTableCell-tooltip-container'}><InfoIcon />
                                                                        {props.ProjectResources[row].labour_category}</span>
                                                                </HtmlTooltip>

                                                            </TableCell>

                                                            :
                                                            <TableCell> {props.ProjectResources[row].labour_category}</TableCell>
                                                        }
                                                        {(typeof props.ContractVehicles !== 'undefined' && props.ContractVehicles.length > 0) &&
                                                            <TableCell>{props.ProjectResources[row].gsa_lcat_name}</TableCell>
                                                        }
                                                        <TableCell>{props.ProjectResources[row].start_date}</TableCell>
                                                        <TableCell>{props.ProjectResources[row].end_date}</TableCell>
                                                        <TableCell>{props.ProjectResources[row].staff_type_name}</TableCell>
                                                        <TableCell>{props.ProjectResources[row].user_status_name}</TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {<TablePagination
                                    rowsPerPageOptions={[15, 30, 50]}
                                    component="div"
                                    count={props.ProjectResources.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                }
                            </>
                            <div className={'warning-bg info-iconbox'}> No task assigned </div>
                        </div>
                    }
                </>
                :
                <>
                    {(props.OtherPermissions.allView || props.OtherPermissions.pmView || props.OtherPermissions.resourceView) &&
                        <div className={'section-main'}>
                            <div className={'listSubHeader'}>
                                <div className={'col-left'}>
                                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                    </Typography>
                                </div>
                                <div className={'action'}>
                                    <Tooltip title="Add Project Resource" aria-label="add">
                                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={props.OnAddResourceClick}>
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                </div>
                            </div>
                            <>
                                <TableContainer >
                                    <Table aria-label="simple table"  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> Resource Name </TableCell>
                                                <TableCell> Start Date </TableCell>
                                                <TableCell> End Date </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.ResourceRows.map((row) =>
                                                <>
                                                <TableRow>                                                    
                                                        <TableCell>
                                                            <FormControl error={props.Errors[row.id] && props.Errors[row.id].selectedOption} variant="standard">
                                                                <Select
                                                                    value={row.selectedOption}
                                                                    onChange={(e) => props.HandleOnChange(e, row.id, 'selectedOption')}
                                                                    inputProps={{
                                                                        name: 'resourceName',
                                                                        id: 'resourceName',
                                                                    }}>
                                                                    <MenuItem value=""><em>-Select-</em></MenuItem>
                                                                    {typeof props.Users != 'undefined' && props.Users.map((idt, i) =>
                                                                        <MenuItem value={idt.user_id} key={'idtypes-' + i}>{formatName(idt.first_name, null, idt.last_name)}</MenuItem>
                                                                    )}
                                                                </Select>
                                                                <FormHelperText>{props.Errors[row.id] && props.Errors[row.id].selectedOption ? props.Errors[row.id].selectedOption : ''}</FormHelperText>
                                                            </ FormControl>
                                                            </TableCell>
                                                       
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    required
                                                                    id="start_date"
                                                                    name="start_date"
                                                                    label="Start Date"
                                                                    type="date"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={row.startDate}
                                                                    onChange={(e) => props.HandleOnChange(e, row.id, 'startDate')}
                                                                    error={props.Errors[row.id] && props.Errors[row.id].startDate}
                                                                    helperText={props.Errors[row.id] && props.Errors[row.id].startDate ? props.Errors[row.id].startDate : ''}
                                                                    variant="standard"
                                                                />
                                                            </ FormControl>
                                                            </TableCell>                                                       
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField                                                                    
                                                                    id="end_date"
                                                                    name="end_date"
                                                                    label="End Date"
                                                                    type="date"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={row.endDate}
                                                                    onChange={(e) => props.HandleOnChange(e, row.id, 'endDate')}
                                                                    error={props.Errors[row.id] && props.Errors[row.id].endDate}
                                                                    helperText={props.Errors[row.id] && props.Errors[row.id].endDate ? props.Errors[row.id].endDate : ''}
                                                                    variant="standard"
                                                                />
                                                            </ FormControl>
                                                            </TableCell>
                                                            <TableCell>
                                                            <FormControl>
                                                                <DeleteIcon fontSize="small" onClick={() => props.DeleteRow(row.id)} />
                                                            </FormControl>
                                                            </TableCell>                                                      
                                                    </TableRow>
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        </div>
                    }
                </>
            }        
        </React.Fragment>
    );
});

