import React from 'react';
import {InvoiceReportComponent} from './InvoiceReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';
import * as _ms from '../../common/multiSelect';
import * as validations from '../../common/validate';


const filterFields = {
    'report_type': 'emp-wise',
    'staff_status': 1,
    'staff': [],
    'client': [0],
    'projects': [0],
    'tasks' : [],
    'organization' : 0,
    'btn_type': 0,
    'name_format': 'LASTFIRST',
}

const filters = {
}

let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) { 
            getProjectTree(e.children, selected_projects,result);
        }
    });
}
export class InvoiceReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            permission_type : 'all',
            permission_organization_wise: false,
            data : [],
            open: false,
            filterFields: filterFields,
            filters: filters,
            errors: {},
            loaderOpen: false,
            selectedClientList : [],
            selectedStaffList : [],
        }
        this.handleTreeChange = this.handleTreeChange.bind(this);
    }

    componentDidMount = () => {
        this.getInvoiceReport();
        window.scrollTo(0, 0);
    };
    getInvoiceReport = () => {
        this.setState({loaderOpen:true});
        let filterFields = this.state.filterFields;
        return httpServices.post('api/report/invoice-report', filterFields)
            .then(response => {
                if (response.data && !response.data.errors) {
                    let filters = this.state.filters;
                    let group_manager = this.state.group_manager;
                    let project_manager = this.state.project_manager;
                    let selectedClientList = this.state.selectedClientList;
                    let selectedStaffList = this.state.selectedStaffList;
                    if (this.state.filterFields.btn_type === 0) {
                        filters = response.data.filters;
                        //filters.staffs = _ts.prepareTreeData(filters.staffs, 'staff', false);
                        filters.projects = _ts.prepareTreeData(filters.projects, 'projects');
                        //filters.clients = _ts.prepareTreeData(filters.clients, 'clients');
                        filters.tasks = _ts.prepareTreeData(filters.tasks, 'tasks');
                        if (filters.staffs.length === 1) {
                            filterFields.staff = [filters.staffs[0].user_id];
                            selectedStaffList = [filters.staffs[0]];
                        }
                        if (filters.organizations.length === 1 && response.data.permission_type !== 'self') {
                            filterFields.organization = filters.organizations[0].client_id;
                        }
                        let clients = _ms.prepareData(filters.clients);
                        filters.clients = clients.list;
                        selectedClientList = clients.selected;
                	filterFields.name_format=response.data.name_format;
                        group_manager = response.data.group_manager;
                        project_manager = response.data.project_manager;
                    }
                    this.setState({
                        data : response.data.data,
                        filters: filters,
                        permission: response.data.permission,
                        permission_type: response.data.permission_type,
                        permission_organization_wise: response.data.permission_organization_wise,
                        group_manager: group_manager,
                        project_manager: project_manager,
                        post: response.data.post,
                        loaderOpen: false,
                        selectedClientList : selectedClientList,
                        selectedStaffList : selectedStaffList,
                    }, function() {
                        if (filters.organizations.length === 1  && response.data.permission_type !== 'self' && this.state.filterFields.btn_type === 0) {
                            this.getInvoiceStaffList('organization');
                        }
                    });
                } else if (response.data.errors) {
                    this.setState({
                        errors: response.data.errors,
                        loaderOpen: false
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let filters = this.state.filters;
        let name = evt.target.name;
        let val = evt.target.value;
        if (name === 'report_type') {
            f['tasks'] = [];
            filters['tasks'] = [];
            filters['staffs'] = [];
        }
        if (name === 'nbillable' || name === 'detailed' || name === 'remove_footer') {
            f[name] = evt.target.checked;
        }
        else {
            f[name] = val;
        }
        this.setState({
            filterFields: f,
            filters: filters,
        });
        if (name === 'report_type') {
            this.clearFilter(name);
        }
        if (name === 'organization' || name === 'staff_status') {
            this.getInvoiceStaffList(name);
        }
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.filterFields;
        let projects = [];
        let tasks = [];
        let filters = this.state.filters;
        if (cNode.value === 'selectAll') {
            if (cNode.checked) {
                if (action === 'project') {
                    projects.push(0)
                }
                else if (action === 'tasks') {
                    tasks.push(0);
                }
            }
        }
        else {
            if (sNodes && sNodes.length >= 0) {
                sNodes.forEach(function(el){
                    if (el.project_id || el.task_id || el.user_id || el.client_id) {
                        if (action === 'project') {
                            getProjectTree(filters.projects, el.project_id, projects);
                        } else if (action === 'tasks') {
                            tasks.push(el.task_id);
                        }

                    }
                });
            }
        }
        if (action === 'project') {
            f['projects'] = projects;
            f['tasks'] = [];
        } else if (action === 'tasks') {
            f['tasks'] = tasks;
        }

        this.setState({filterFields : f},function(){
            if (action === 'project') {
                this.getTasksByProject(projects);
            } 
        });
    }
    
    clearFilter = (name) => {
        let f = this.state.filterFields;
        let report_type = f.report_type;
        let start_date = (name === 'report_type') ? f.start_date : '';
        let end_date = (name === 'report_type') ? f.end_date : '';
        let name_format = f.name_format;
        let projects = [0];
        let client = [0];
        if (name === 'report_type') {
            projects = f.projects;
            client = f.client;
        }
        let filterFields = {
            'report_type': report_type,
            'staff_status': 1,
            'staff': [],
            'client': client,
            'projects': projects,
            'tasks' : [0],
            'organization' : 0,
            'start_date': start_date,
            'end_date': end_date,
            'btn_type' : 0,
            'name_format' : name_format, 
        }
        this.setState({
            filterFields: filterFields,
            data: [],
            post: false,
            errors: [],
        }, function() {
            if (name === 'report_type') {
                this.getProjectsByClient(name);
            }
        })
    }
    getProjectsByClient = (name) => {
        let client_id = this.state.filterFields.client;
        let report_type = this.state.filterFields.report_type;
        let staff_status = this.state.filterFields.staff_status;
        let organization = this.state.filterFields.organization;
        let start_date = this.state.filterFields.start_date;
        let end_date = this.state.filterFields.end_date;
        let include_project_all_option = (report_type === 'emp-wise') ? true : false;
        let include_staff_all_option = true;
        let filterFields = this.state.filterFields;
        let selectedStaffList = this.state.selectedStaffList;
        let selectedClientList = this.state.selectedClientList;

        return httpServices.post('api/report/getInvoiceFilter', {client_id : client_id, report_type : report_type, staff_status : staff_status, organization : organization, start_date : start_date, end_date : end_date})
            .then(response => {
                if (response.data) {
                    let filters = this.state.filters;
                    filters.projects = _ts.prepareTreeData(response.data.projects, 'projects', include_project_all_option);
                    if (response.data.clients) {
                        let clients = _ms.prepareData(response.data.clients);
                        filters.clients = clients.list;
                        selectedClientList = clients.selected;
                    }
                    if (name === 'report_type') {
                        if (report_type === 'emp-wise' && response.data.staff.length === 1) {
                            filterFields.staff = response.data.staff[0]['user_id'];
                            include_staff_all_option = false;
                            selectedStaffList = [response.data.staff[0]];
                        }
                        else if (report_type === 'emp-wise' && filterFields.organization === 0) {
                            include_staff_all_option = false;
                        }
                        let staffs = _ms.prepareData(response.data.staff, include_staff_all_option);
                        filters.staffs = staffs.list;
                        if (include_staff_all_option) {
                            selectedStaffList = staffs.selected;
                        }
                    }
                    if (this.state.filterFields.projects[0] !== 0) {
                        let sprojects = [];
                        this.manageSelectedProjects(this.state.filters.projects, this.state.filterFields.projects, sprojects);
                        filterFields.projects = sprojects;
                    }
                    this.setState({
                        filters : filters,
                        filterFields: filterFields,
                        selectedStaffList : selectedStaffList,
                        selectedClientList : selectedClientList,
                    }, function() {
                        this.getTasksByProject(filterFields.projects);
                    }

                    );
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    getTasksByProject = (projects) => {
        let staff_status = this.state.filterFields.staff_status;
        let filterFields = this.state.filterFields;
        let selectedStaffList = this.state.selectedStaffList;
        return httpServices.post('api/report/getTasksByProjects', {projects : JSON.stringify(projects), staff_status: staff_status})
            .then(response => {
                if (response.data) {
                    let filters = this.state.filters;
                    let tasks = response.data.tasks;
                    // Sort the tast based on the selected project tree 
                    let sorted_tasks = [];
                    projects.forEach((p) =>{
                        if (p) {
                            if (tasks && tasks.length) {
                                tasks.forEach((task) => {
                                    if (task.task_project === p) {
                                        let json_task = JSON.stringify(task);
                                        sorted_tasks.push(json_task);
                                    }
                                });
                            }
                        } 
                    });
                    filters.tasks = _ts.prepareTreeData(sorted_tasks, 'tasks');
                    if (response.data.tasks.length > 0) {
                        filterFields.tasks = [0];
                    }
                    if (this.state.filterFields.report_type === 'project-wise') {
                        if (response.data.staffs) {
                            let staffs = _ms.prepareData(response.data.staffs);
                            filters.staffs = staffs.list;
                            selectedStaffList = staffs.selected;
                            if (response.data.staffs.length > 0) {
                                filterFields.staff = [0];
                            }
                        }
                        else {
                            filters.staffs = [];
                            selectedStaffList = [];
                        }
                    }
                    this.setState({
                        filters : filters,
                        selectedStaffList : selectedStaffList,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    getInvoiceStaffList = (field) => {
        let filterFields = this.state.filterFields;
        let staff_status = filterFields.staff_status;
        let organization = filterFields.organization;
        let projects = filterFields.projects;
        let report_type = filterFields.report_type;
        let start_date = filterFields.start_date;
        let end_date = filterFields.end_date;
        let include_project_all_option = true;
        let selectedStaffList = [];
        let selectedClientList = [];
        let include_all_option = false;
        if ((report_type === 'project-wise' && projects.length > 0 && projects[0] !== 0) || (report_type === 'emp-wise' && organization > 0)) {
            include_all_option = true;
        }
         return httpServices.post('api/report/getInvoiceStaffList', {staff_status: staff_status, organization: organization, projects: projects, report_type: report_type, field: field, start_date: start_date, end_date: end_date})
            .then(response => {
                if (response.data) {
                    let filters = this.state.filters;
                    let staffs = _ms.prepareData(response.data.staffs, include_all_option);
                    filters.staffs = staffs.list;
                    if (this.state.selectedStaffList) {
                        this.state.selectedStaffList.forEach((selectedStaffDetails) => {
                            selectedStaffList.push(selectedStaffDetails);
                        });
                    }
                    if (include_all_option) {
                        selectedStaffList = staffs.selected;
                        filterFields.staff = [0]
                    }
                    else {
                        if (report_type === 'emp-wise' && response.data.staffs.length === 1) {
                            filterFields.staff = response.data.staffs[0]['user_id'];
                            selectedStaffList = [response.data.staffs[0]];
                        }
                        else {
                            filterFields.staff = response.data.staffs[0]['user_id'];
                        }
                    }
                    if(field==='organization'){
                        if (response.data.projects) {
                            filters.projects = _ts.prepareTreeData(response.data.projects, 'projects', include_project_all_option);
                        }
                        if (response.data.clients) {
                            let clients = _ms.prepareData(response.data.clients);
                            filters.clients = clients.list;
                            selectedClientList = clients.selected;
                        }
                    }else{ 
                        selectedClientList=this.state.selectedClientList;
                    }
                    this.setState({
                        filters : filters,
                        filterFields: filterFields,
                        selectedStaffList : selectedStaffList,
                        selectedClientList : selectedClientList,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    validateFilter = () => {
        let valid = this.validate();
        let f = this.state.filterFields;
        f.btn_type = 'filter';
        this.setState({filterFields: f},
            function() {
                if (valid) {
                    this.getInvoiceReport();
                }

            }
        );
    }
    validateExport = (name) => {
        let f = this.state.filterFields;
        f.btn_type = name;

        this.setState({filterFields: f}, function () {

            let valid = this.validate();

            if (valid) {
                let data = JSON.stringify(this.state.filterFields);
                data = JSON.parse(data);
                data.projects = data.projects.toString();
                data.staff = data.staff.toString();
                data.tasks = data.tasks.toString();
                return httpServices.post('api/report/validate-invoice-timesheet', data)
                    .then(response => {
                        if (response.data) {
                            let valid = response.data.valid;
                            if (valid) {
                                document.getElementById("invoiceForm").submit();
                            }
                            else {
                                this.setState({
                                    open : true,
                                    warning_timesheets: response.data.warning_timesheets,
                                });
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        });
    }
    validate = () => {
        let formIsValid = true;
        let validationData = [
            { name: 'start_date', value: this.state.filterFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.filterFields.end_date, type: 'date', otherOptions: { required: true } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        if (isValid) {
            let errors = {};
            if (this.state.filterFields.start_date && this.state.filterFields.end_date) {
                if (new Date(this.state.filterFields.start_date) > new Date(this.state.filterFields.end_date)) {
                    errors["start_date"] = "Start Date should be less than End Date";
                }
            }
            if (this.state.filterFields.report_type === 'emp-wise') {
                if (this.state.permission_type === 'all' || (this.state.permission_type === 'self' && this.state.group_manager)) {
                    if (parseInt(this.state.filterFields.organization) === 0 && this.state.filterFields.staff.length === 0) {
                        errors["organization"] = "Organization or Staff required";
                        errors["staff"] = "Organization or Staff required";
                        formIsValid = false;
                    }
                }
                else {
                    if (this.state.filterFields.staff.length === 0) {
                        errors["staff"] = "Staff required";
                        formIsValid = false;
                    }
                }
            }
            if (this.state.filterFields.report_type === 'project-wise') {
                if (this.state.filterFields.projects.length === 0) {
                    errors["projects"] = "Projects required";
                    formIsValid = false;
                }
            }
            this.setState({ errors: errors });
        } else {
            formIsValid = false;
            this.setState({ errors: validateErrors });
        }
        return formIsValid;
    }
    onClose = () =>{
        this.setState({
            open: false,
        });
    };
    confirmTimesheetValidation = () => {
        document.getElementById("invoiceForm").submit();
        this.setState({
            open: false,
        });
    };

    onSelect = (selectedList, selectedItem) => {
        let selected = [];
        let f = this.state.filterFields;
        let selectedClientList = this.state.selectedClientList;
        let selectedStaffList = this.state.selectedStaffList
        if (typeof selectedItem.client_id !== 'undefined') {
            if (selectedItem.client_id !== 0) {
                if(selectedList[0].client_id === 0) {
                    selectedList.splice(0,1);
                }
            }
            else {
                selectedList = [{client_id:0,client_name:"All Clients"}]
            }
            selectedList.forEach((p, v) =>{
                selected.push(p.client_id);
            })
            selectedClientList = selectedList;
            f.client = selected;
        }
        if (typeof selectedItem.user_id !== 'undefined') {
            if (selectedItem.user_id !== 0) {
                if(selectedList[0].user_id === 0) {
                    selectedList.splice(0,1);
                }
            }
            else {
                selectedList = [{user_id:0,full_name:"All Staff"}]
            }
            selectedList.forEach((p, v) =>{
                selected.push(p.user_id);
            })
            selectedStaffList = selectedList;
            f.staff = selected;

        }
        this.setState({
            filterFields: f,
            selectedClientList : selectedClientList,
            selectedStaffList : selectedStaffList,
        }, function() {
            if (typeof selectedItem.client_id !== 'undefined') {
                this.getProjectsByClient('client');
            }
        });
    }
    onRemove(selectedList, removedItem) {
        let selected = [];
        let f = this.state.filterFields;
        if (typeof removedItem.client_id !== 'undefined') {
            selectedList.forEach((p, v) =>{
                selected.push(p.client_id);
            })
            f.client = selected;

        }
        if (typeof removedItem.user_id !== 'undefined') {
            selectedList.forEach((p, v) =>{
                selected.push(p.user_id);
            })
            f.staff = selected;
        }
        this.setState({
            filterFields: f,
        },function() {
            if (typeof removedItem.client_id !== 'undefined') {
                this.getProjectsByClient('client');
            }
        });
    }
    manageSelectedProjects = (projects, selectedprojects, sprojects) => {
        projects.forEach((p, v) =>{
            if (p.project_id) {
                if (selectedprojects.indexOf(p.project_id) >= 0) {
                    sprojects.push(p.project_id);
                }
            }
            if (p.children instanceof Object) {
                this.manageSelectedProjects(p.children, selectedprojects, sprojects);
            }
        })
        return sprojects;
    }
    render(){
        return(
            (this.state.permission) ? 
                <InvoiceReportComponent
                    HandleFilterChange = {this.handleFilterChange.bind(this)}
                    ValidateFilter = {this.validateFilter.bind(this)}
                    GetInvoiceReport = {this.getInvoiceReport.bind(this)}
                    ClearFilter = {this.clearFilter.bind(this)}
                    {...this.state}
                    HandleTreeChange = {this.handleTreeChange}
                    ValidateExport = {this.validateExport.bind(this)}
                    OnClose={this.onClose.bind(this)}
                    ConfirmTimesheetValidation = {this.confirmTimesheetValidation.bind(this)}
                    OnSelect = {this.onSelect.bind(this)}
                    OnRemove = {this.onRemove.bind(this)}
                />
                :
                <ErrorPage/>
            )
    }
}
