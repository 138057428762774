import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import * as _R from 'ramda';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { formatName } from '../../../common/common';
import TextField from '@mui/material/TextField';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={15}>
                        <FormControl>
                            <TextField
                                id = "note"
                                name = "note"
                                label = "Note"
                                type = "text"
                                multiline = {true}
                                variant = "outlined"
                                minRows = {5}
                                required
                                value = {props.FormFields.note}
                                onChange = {props.HandleOnChange}
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                                error = {props.Errors.note && true}
                                helperText = {props.Errors.note}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};
export const NoteComponent = (props) => {
    return (
	    <>
	    <React.Fragment>
	<div class="notes">
	<Grid container spacing={3} style={{position:"relative"}}>
	   <Grid item md={12}>
                <Typography className={'section-title'} variant="h6" component="h6"> STAFF NOTES</Typography>
		   <div className={'absolute-right'}>
                      <div className={'card-action'}>
	                 <Tooltip title="Add new note">
                            <Fab size="small" color="primary" aria-label="add" onClick={(event, item) =>props.AddComponent()}> <AddIcon fontSize="small" /></Fab>
	                 </Tooltip>
                      </div>
                   </div>
           </Grid>
	<Grid item xs={12} sm={12}>
  	{_R.type(props.Note) !== 'Undefined' && (props.Note).map((item) =>
    	<div class="note-item">
        <div class="note-text">
		    {item.note && item.note.split ('\n').map ((noteitem, i) => <p key={i}>{noteitem}</p>)}
		 {(item.category) &&
		 <div class="notes-labels">
                <span class="title">Related to:</span>
                <span class="chip">{item.category} </span>
                {item.title && 
                    <span class="chip">{item.title}</span>
                }
            </div>}
        </div>
        <div class="notes-footer">
            <div class="notes-details">
                <div class="item">Created by: {formatName(item.cby_first_name,null,item.cby_last_name)}, On: {item.created_on}</div>
                { (item.modified_by) &&
			<div class="item">Modified by: {formatName(item.mby_first_name,null,item.cby_last_name)}, On: {item.modified_on}</div>}
            </div>
            <div class="notes-action">
        {(props.edit_permission)?
		    <IconButton aria-label="edit">
              		<EditIcon fontSize="small" id={item.id} onClick={()=> props.Update(item.id)}/>
        	</IconButton> :<></>
        }
        {(props.delete_permission)?
		    <IconButton aria-label="delete" >
                	<DeleteIcon fontSize="small" onClick ={()=> new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                const index = props.Note.indexOf(item);
                                props.OnDelete(index, item.id, item.category);
                            }
                            resolve()
                            }, 500)
                        })}
			 />
        	</IconButton>:<></>
        }
            </div>
        </div>
    </div> 
	)}
	</Grid></Grid>
</div>
	    <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {props.IsEdit?'Edit Note':'Add Note'}
                    OnSubmit={props.SaveNote}
                    Content={<DialogComponent FormProps={props}/>}/>
        </React.Fragment>
        </>
    )
};

