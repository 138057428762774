import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import {DialogBoxMedium} from '../../common/dialog.medium';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda'; 
import { formatName } from '../../common/common';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import format from 'date-fns/format';

export const ContractComponent = (props => {
    let ContractDetail = props.ContractDetails ? props.ContractDetails : null;
    let LaborCategory = props.LaborCategories ? props.LaborCategories : null;
    let project = props.Project !== null ? props.Project : null;
    
    return (
        <>
        <React.Fragment>
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                {((project['is_contract'] == '1') ? true : false)&&
                <Grid item xs={12} md={5}>
                    <div className={'section-main'}>
                        <div className={'listSubHeader'}> 
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                Contract Details    
                            </Typography>
                        </div>
                        <div className={'profile-view__basics_default'}>
                        <div className={'profile-view__basics_default-item item-role'}><label>Contract Title</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.contract_title)!=='Undefined')?(ContractDetail.contract_title):null}</span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Contract Number</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.contract_number)!=='Undefined')?(ContractDetail.contract_number):null}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Solicitation Number</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.solicitation_number)!=='Undefined')?(ContractDetail.solicitation_number):null}</span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Classification</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.classification_name)!=='Undefined')?(ContractDetail.classification_name):null}</span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Contract Award Date/Effective Date</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.effective_date)!=='Undefined')?(ContractDetail.effective_date):null}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Type</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.type_name)!=='Undefined')?(ContractDetail.type_name):null}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Client Manager</label><span className={'value'}> {formatName(ContractDetail.first_name,null,ContractDetail.last_name)}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>CLIN Required</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.clin_required )!=='Undefined')?(ContractDetail.clin_required=== '1'? "Yes" : "No"):null}</span> </div>                        <div className={'profile-view__basics_default-item item-role'}><label>Category</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.category_name)!=='Undefined')?(ContractDetail.category_name):null}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Contract Vehicle</label><span className={'value'}>{(ContractDetail && _R.type(ContractDetail.vechicle_name)!=='Undefined')?(ContractDetail.vechicle_name):null}</span> </div>
                    </div>
                    </div>
                </Grid>
                }
                <Grid item xs={12} md={7}>
                    <div className={'section-main'}>
                        <div className={'listSubHeader'}> 
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                Labor Categories    
                            </Typography>                            
                            {(props.OtherPermissions.allEdit || props.OtherPermissions.pmPrEdit) && !props.InheritResourceFlag &&
                            <Tooltip title="Add Labor Category" aria-label="add">
                                <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={props.OnAdd}>
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                            }
                        </div>
                        {props.InheritResourceFlag && 
                            <p className="error">
                                Labor categories are inherited from the parent project and can only be managed from there.
                            </p>}
                        <div className={'section-content'}>
                        {_R.isEmpty(LaborCategory) === false &&
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell> Category </TableCell> 
                                    <TableCell> Start Date </TableCell>
                                    <TableCell> End Date </TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {LaborCategory.map((row, index) =>
                            <>
                                <TableRow hover>
                                    <TableCell> {row.name} </TableCell>
                                    <TableCell> {row.start_date} </TableCell>
                                    <TableCell> {row.end_date} </TableCell>
                                    <TableCell align="right" className={'cell-action'} >
                                        {(props.OtherPermissions.allEdit || props.OtherPermissions.pmPrEdit) && !props.InheritResourceFlag &&
                                        <>
                                            <Tooltip title="Edit" aria-label="edit">
                                                <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                                    <EditIcon fontSize="small" onClick={() => props.OnUpdate(props.ProjectId, row.id)}/>
                                                </IconButton>
                                            </Tooltip>
                                            {(props.DeletePermission) &&
                                            <Tooltip title="Delete" aria-label="delete">
                                                <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                                    <DeleteIcon fontSize="small" onClick={() => props.OnDelete(row.id)}/>
                                                </IconButton>
                                            </Tooltip>
                                            }
                                        </>
                                        }
                                    </TableCell>
                                </TableRow>
                            </>
                            )}
                            </TableBody>
                        </Table>
                        }
                        {_R.isEmpty(LaborCategory) &&
                            <Table  size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan="8">No records found</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }
                        </div>
                    </div>
                </Grid>
            </Grid>
          <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.IsEditable ? 'Edit Labor Category' : 'Add Labor Category' }
                OnSubmit = { ()=>props.HandleSubmit(props.Id) }
                Content = { <DialogContent FormProps = { props }/> }
            />
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
            {(props.ShowConfirmation) && <DialogBoxMedium
                Open={props.ShowConfirmation}
                Cancel={props.OnConfirmClose}
                Title={'Confirm Update Resource'}
                ButtonName ={'Yes'}
                CancelButtonName={'No'}
                OnSubmit={props.HandleUpdateResourceClick}
                Content={<DialogUpdateResource FormProps={props} />}
            />}
        </React.Fragment>
        </>
    );
});

const DialogContent = (p) => {
    const {FormProps:props} = p;
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                 <Grid item xs={12} md={12}>
                <FormControl>
                    <TextField
                        id="labCategoryName"
                        name="lbCategoryName"
                        label="Labor Category Name "
                        type="text"
                        required
                        value={props.FormFields.lbCategoryName}
                        onChange={props.HandleOnChange}
                        error={props.Errors.lbCategoryName && true}
                        helperText={props.Errors.lbCategoryName}
                        InputLabelProps={{
                                    shrink: true,
                                }}
                        variant="standard"
                    />
                </FormControl>
            </Grid>
             <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                value={ props.FormFields.description ? props.FormFields.description : ''}
                                onChange={props.HandleOnChange}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description}
                        />
                    </ FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "start_date"
                            name = "start_date"
                            label = "Start Date"
                            selectedDate = {props.FormFields.start_date}
                            handleDateChange = {props.HandleDateChange}
                            error = {props.Errors.start_date && true}
                            required = {true}
                        />
                    </ FormControl>
                </Grid>
                {(props.Project['is_contract'] === "1") ?
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "end_date"
                            name = "end_date"
                            label = "End Date"
                            selectedDate = {props.FormFields.end_date}
                            handleDateChange = {props.HandleDateChange}
                            error = {props.Errors.end_date && true}
                            required = {true}
                        />
                    </ FormControl>
                </Grid>
                    :
                    <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "end_date"
                            name = "end_date"
                            label = "End Date"
                            selectedDate = {props.FormFields.end_date}
                            handleDateChange = {props.HandleDateChange}
                            error = {props.Errors.end_date && true}
                        />
                    </ FormControl>
                </Grid>
                }
            </Grid>
        </form>
    )
}
const DialogUpdateResource = (props) => {
    return (
        <>
            <div>Resource end date of the following resources will be updated to {format(new Date(props.FormProps.FormFields.end_date), 'MM/dd/yy')}?
                {props.FormProps.FormFields.lcatResources.map((row, index) =>
                    <p>{formatName(row.user_first_name, null, row.user_last_name)}</p>
                )}
                Do you want to proceed. ?
            </div>
        </>
    )
}

