import React from 'react';
import Cookies from 'universal-cookie';
import { UserListComponent} from './UserList.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from '../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';


const filterFields = {
    'status': 1,
    'user_type':0,
    'group':0,
}

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

const cookies = new Cookies();
export class UserListContainer extends React.Component{
    constructor(props) {
        let filterValues = filterFields;
        let filterCookie = cookies.get('filter');
        if (filterCookie) {
            if (filterCookie.users) {
                filterValues = filterCookie.users;
            } else {
                filterCookie.users = filterValues;
            }
        }
        else {
            filterCookie = {'users' : filterValues}
        }
        super(props);
        this.state = {
            permission : false,
            showLoader : true,
            dialogOpen : false,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            editId : 0,
            editPermission : false,
            deletePermission : false,
            dataLoaded : false,
            loggedUser : 0,
            users : [],
            open : false,
            errors : [],
            popupType:'',
            right: false,
            filters : [],
            filterFields : filterValues,
            filterCookie: filterCookie,
            editPermission : false
        };
        this.getUserList = this.getUserList.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.handleDeleteClick =  this.handleDeleteClick.bind(this);
        this.handleEditClick =  this.handleEditClick.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
    }
   
    componentDidMount() {
        this.getUserList();
    }

    getUserList = () => {
        let filterCookie = this.state.filterCookie;
        filterCookie.users = this.state.filterFields;
        cookies.set('filter', filterCookie, { path: '/' });
        this.setState({
            showLoader : true,
        });
        let data = this.state.filterFields;
        let apiEndpoint = 'api/user/list';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        let filters = this.state.filters;
                        filters['status'] = response.data.statuses;
                        filters['userTypes'] = response.data.user_types;
                        filters['groups'] = response.data.groups;
                        this.setState({
                            showLoader : false,
                            users : response.data.users,
                            permission : response.data.permission,
                            dataLoaded : true,
                            filters: filters,
                            filterCookie : filterCookie,
                            editPermission : response.data.edit_permission
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
     onClose = () => {
        this.setState({
            open: false,
        });
    };

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
    }

    submitFilter = () => {
        this.setState({
            right: false
        },() => {
            this.getUserList();
            });
    }
    resetFilter = () => {
        let f = {
            status : 1,
            user_type : 0,
            group : 0,
        };
        this.setState({
            filterFields: f,
            right: false
            },() => {
            this.getUserList();
            });
    }

    exportUser = () => {
       document.getElementById("exportUserList").submit();
    }
    handleEditClick = (id) => {
        history.push(BASE_PATH+'/system/user/edit/' + id );
    }

     onClose = () => {
        this.setState({
            open: false,
        });
    };

    handleDeleteClick = (id) => {
        this.setState({
            open : true,
            editId : id,
            popupType:'delete'
        });
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('view/' + id);
        }
    }
    handleAddUser = () => {
        history.push('add');
    }
    updateParentOnSuccess = (status) => {
        this.setState({open : false});
        if (status) {
            this.setState({
                snackbarOpen : true,
                snackbarMsg : 'User updated successfully',
                snackbarVarient : 'success',
            }, function() {
                this.getUserList();
            });
        }
    }

    deleteUser = (id) => {
    }


    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <UserListComponent
                        Users = {this.state.users}
                        Open = {this.state.open}
                        SnackbarOpen={this.state.snackbarOpen}
                        SnackbarVarient={this.state.snackbarVarient}
                        SnackbarMsg={this.state.snackbarMsg}
                        SnackbarClose={this.snackbarClose}
                        PopupType={this.state.popupType}
                        toggleDrawer={this.toggleDrawer}
                        Right={this.state.right}
                        Filters={this.state.filters}
                        FilterFields={this.state.filterFields}
                        HandleFilterChange={this.handleFilterChange.bind(this)}
                        SubmitFilter = {this.submitFilter.bind(this)}
                        ResetFilter = {this.resetFilter.bind(this)}
                        ExportUser={this.exportUser.bind(this)}
                        HandleEditClick = {this.handleEditClick}
                        HandleDeleteClick = {this.handleDeleteClick}
                        OnClose={this.onClose}
                        HandleViewDetails ={this.handleViewDetails}
                        DeleteClick={this.deleteUser}
                        EditPermission = {this.state.editPermission}
                        EditId = {this.state.editId}
                        UpdateParentOnSuccess = {this.updateParentOnSuccess}
                        HandleAddUser = {this.handleAddUser}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                :<></>
            }
            </>
        )
    }
}
