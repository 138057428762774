import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import FormHelperText from '@mui/material/FormHelperText';
const gridStyle = {
    margin: '15px 0px'
};
const API_URL = process.env.REACT_APP_API_URL;
export const PtoRolloverReportComponent = (props) =>{
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const StartYear = currentYear - 1;
    const years = [];
    for(var i = currentYear +1; i >= StartYear; i--){
        years.push(i);
    }
    return(
    <React.Fragment>
            <Paper md = {12}>
            <div class = "p1">
            <Typography variant = "h6" component="h6" className = {'section-title'}> PTO Rollover Report</Typography>
            <form id="export-form" className = "form-fullwidth" action = {API_URL+'api/report/generate-pto-rollover-report'} method = "post">
                    <Grid container spacing={3} style = {gridStyle}>               
                        <Grid item  md = {2} className = 'justify-between'>
                        <FormControl error={props.Errors.year && true}  variant="standard">
                                <InputLabel htmlFor = "year">Year</InputLabel>
                                <Select
                                    inputProps = {{
                                        name: 'year',
                                        id: 'year',
                                    }}
                                    value = {props.FormFields.year }
                                    onChange = {props.HandleOnChange}
                                >
                                { years.map((y) =>
                                    <MenuItem value = {y}>{y}</MenuItem>
                                )}
                                </Select>
                                <FormHelperText >{props.Errors.year}</FormHelperText>
                            </FormControl>
                        </Grid>              
                        <Grid item >
                            <Button variant = "contained" size = "small" color = "primary" onClick={props.ExportReports} name = "export" >Export</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>    
            </Paper>
            </React.Fragment>
            )
}

