import React from 'react';
import { TimeOffDetailsComponent } from './TimeOffDetailsComponent';
import { TimeOffDetailsMobileComponent } from './TimeoffDetailsMobileComponent';
import { ErrorPage } from '../../common/error';
import {httpServices}  from '../../../services/httpServices'
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { history } from '../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class TimeOffDetailsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            staffDetails:{},
            permission:false,
            experience:{},
            timeoffTypes:{},
            holidayLog:{},
            years:{},
            listView:false,
            onDate:'',
            showLoader : true,
            dataLoaded : false,
            selectedType : Array(),
            selectedId : '',
            mobileView : false,
            errorMessage : ''
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.getTimeOffDetails = this.getTimeOffDetails.bind(this);
        this.changeTimeoffType = this.changeTimeoffType.bind(this);
        this.changeHolidayType = this.changeHolidayType.bind(this);
        this.viewLink = this.viewLink.bind(this);
    }

    componentDidMount = () =>  {
        if (this.props.location.state && this.props.location.state.year) {
            this.setState({
                selectedYear:this.props.location.state.year
            },()=>{
                this.getTimeOffDetails();
            });
        }else {
            this.setState({
                selectedYear: new Date().getFullYear()
            },()=>{
                this.getTimeOffDetails();
            });
        }
        window.scrollTo(0, 0);
        this.checkDevice();
    }

    getTimeOffDetails = () =>  {
        this.setState({
            showLoader : true,
        });
        httpServices.post('api/timeoff/view',{'user':this.props.match.params.id,'year':this.state.selectedYear})
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        permission : true,
                        staffDetails : inputs.staff_details,
                        experience : inputs.experience,
                        timeoffTypes : inputs.timeoff_types,
                        holidayLog : inputs.holiday_log,
                        years : inputs.years,
                        dailyMinimum : inputs.daily_minimum,
                        listView : inputs.list_permission,
                        onDate:inputs.on_date,
                        showLoader : false,
                        dataLoaded : true,
                        selectedYear : ((inputs.joined_year != '')  &&  (inputs.joined_year > this.state.selectedYear)) ? inputs.joined_year : this.state.selectedYear,
                        selectedType : (inputs.timeoff_types[0] !== undefined && inputs.timeoff_types[0]) ? inputs.timeoff_types[0] : Array(),
                        selectedId : (inputs.timeoff_types[0] !== undefined && inputs.timeoff_types[0]) ? inputs.timeoff_types[0]['id'] : '',
                        errorMessage : inputs.display_error_msg,
			cutoff_year_const : inputs.cutoff_year_const
                    });
                }
                  else {
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
            });
    }
    HandleOnChange = (evt, value) => {
        const val = evt.target.value;
        this.setState({
            selectedYear: val
        });
    };
    changeTimeoffType  = (id)=> () => {
        let timeoffTypes = this.state.timeoffTypes;
        timeoffTypes.forEach(function (arrayItem) {
            if (arrayItem.id == id) {
                this.setState({
                    selectedType: arrayItem,
                    selectedId: arrayItem.id
                });
                return;
            }
        }, this);
    };
    changeHolidayType  = (id)=> () => {
        let holidayLog = this.state.holidayLog;
        holidayLog.forEach(function (arrayItem) {
            if (arrayItem.id == id) {
                this.setState({
                    selectedType: arrayItem,
                    selectedId: arrayItem.id
                });
                return;
            }
        }, this);
    };
    checkDevice = () => {
        if (window.matchMedia("(max-width: 767px)").matches) {
            this.setState({
                mobileView : true,
            });
        }
    }
    viewLink = (timesheet_id) => () => {
        window.scrollTo(0, 0); 
        let link = '/reports/payperiod/'+ timesheet_id;
        history.push({
            pathname:BASE_PATH+link,
        });

    }
     render(){
        return(
            <>
            <CustomProgressBar loaderOpen={this.state.showLoader}/>
            {( this.state.dataLoaded) ?
            <>
                { (this.state.permission)?
                (this.state.mobileView) ?
                <TimeOffDetailsMobileComponent
                    staffDetails = {this.state.staffDetails}
                    experience = {this.state.experience}
                    timeoffTypes = {this.state.timeoffTypes}
                    holidayLog = {this.state.holidayLog}
                    years = {this.state.years}
                    selectedYear = {this.state.selectedYear}
                    HandleOnChange = {this.HandleOnChange}
                    getTimeOffDetails = {this.getTimeOffDetails}
                    dailyMinimum = {this.state.dailyMinimum}
                    listView={this.state.listView}
                    onDate={this.state.onDate}
                    SelectedType = {this.state.selectedType}
                    SelectedId = {this.state.selectedId}
                    ChangeTimeoffType = {this.changeTimeoffType}
                    ChangeHolidayType = {this.changeHolidayType}
                    ViewLink = {this.viewLink}
                    ErrorMsg = {this.state.errorMessage}
                />
                :
                <TimeOffDetailsComponent
                    staffDetails = {this.state.staffDetails}
                    experience = {this.state.experience}
                    timeoffTypes = {this.state.timeoffTypes}
                    holidayLog = {this.state.holidayLog}
                    years = {this.state.years}
                    selectedYear = {this.state.selectedYear}
                    HandleOnChange = {this.HandleOnChange}
                    getTimeOffDetails = {this.getTimeOffDetails}
                    dailyMinimum = {this.state.dailyMinimum}
                    listView={this.state.listView}
                    onDate={this.state.onDate}
                    SelectedType = {this.state.selectedType}
                    SelectedId = {this.state.selectedId}
                    ChangeTimeoffType = {this.changeTimeoffType}
                    ChangeHolidayType = {this.changeHolidayType}
                    ViewLink = {this.viewLink}
                    ErrorMsg = {this.state.errorMessage}
	            CutoffYear = {this.state.cutoff_year_const}
                />
                :<ErrorPage/>}
            </>
            :<></> }
            </>
        )
    }
}
       
