import React from 'react';
import * as _ts from '../../common/treeSelect';
import { httpServices } from '../../../services/httpServices';
import { ExpenseProjectComponent } from './ExpenseProject.Component.js';

let formFields = {
    expenseCode: null,
    name: '',
    id: 0,
    pay_type: 1,
    pay_project: 0,
    projects: '',
    start_date: null,
    end_date: '',
    'start_date_obj': null,
    'end_date_obj': null,
    code: ''
}
let filters = {
}
  let getProjectTree = function (dataObj, selected_projects, result) {
    dataObj.forEach(function (e) {
        if ((selected_projects && selected_projects === e.project_id) || !selected_projects) {
                result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children, 0, result);
            }
        } else if (selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects, result);
        }
    });

}
export class ExpenseProjectContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expenseProjects: [],
            filters : filters,
            editPermission: false,
            open: false,
            popupType: '',
            formFields: formFields,
            errors: {},
            snackbarOpen: false,
            snackbarMsg: '',
            deleteId: 0,
            projectCodes: {},
            snackbarVarient: 'success',
            filterViewState: false,
            selectedPayType: '0'
        }
        this.addExpenseProjectClick = this.addExpenseProjectClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteExpProject = this.deleteExpProject.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleTimeEntryDateChange = this.handleTimeEntryDateChange.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);

    }

    componentDidMount = () => {
        this.getAllExpenseProjects();
    };

    getAllExpenseProjects = (props) => {
        let apiEndpoint = 'api/settings/expenses/listexpenseproject';
        return httpServices.post(apiEndpoint, { 'pay_type': this.state.selectedPayType })
            .then(response => {
                let response_data = response.data;
                this.setState({
                    expenseProjects: response_data.expense_projects,
                    editPermission: response_data.edit_permission,
                    filterViewState: false
                });
            })
            .catch(function (error) {

                console.log("Error: " + error);
            });
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
    };

    handleFilterChange = (evt, value) => {
        let selected_value = evt.target.value;
        this.setState({
            selectedPayType: selected_value
        });
    };

    getExpenseCodes = (type = 'add', open = true, formFieldValues = '') => {
        let apiEndpoint = 'api/settings/expenses/getprojectcode';
        return httpServices.post(apiEndpoint, {'type' : type})
            .then(response => {
                if (response.data) {
                    formFields = (formFieldValues !== '') ? formFieldValues : this.state.formFields;
                    let projects = response.data.all_projects;
                    filters.projects = _ts.prepareTreeData(projects, 'projects', false);
                    this.setState({
                        projectCodes: response.data,
                        popupType: type,
                        filters: filters,
                        open: open,
                    },function(){ this.setState({formFields: formFields})});
                }
            })
    }
    addExpenseProjectClick = () => {
        this.getExpenseCodes('add', true);

    };

    onClose = () => {
        let formFields = {
            expenseCode: null,
            name: '',
            id: 0,
            pay_type: 1,
            pay_project: 0,
            start_date: null,
            start_date_obj: null,
            end_date_obj: null,
            end_date: '',
            code: ''
        }
        this.setState({
            open: false,
            formFields: formFields,
            errors: {},
        })
    }

    handleDeleteClick = (id) => {
        if (id) {
            this.setState({
                open: true,
                popupType: 'delete',
                deleteId: id,
            });
        }
    };

    handleTimeEntryDateChange = (date, name) => {
        const f = this.state.formFields;
        f[name] = date;
        this.setState({
            formFields: f
        })
    };
     handleTreeChange = (evt, cNode, sNodes, action) => {
        let projects = [];
        let tasks = [];
        let filters = this.state.filters;
        if (cNode.value === 'selectAll') {
            if (cNode.checked) {
                if (action === 'project') {
                    projects.push(0)
                }
                else if (action === 'tasks') {
                    tasks.push(0);
                }
            }
        }
        else {
             if (sNodes && sNodes.length >= 0) {
                sNodes.forEach(function (el) {
                    if (el.project_id || el.task_id || el.user_id || el.client_id) {
                        if (action === 'project') {
                            getProjectTree(filters.projects, el.project_id, projects);
                        } else if (action === 'tasks') {
                            tasks.push(el.task_id);
                        }

                    }
                });
            }
        }
        if (action === 'project') {
            formFields['projects'] = projects;
        }
        this.setState({ 
            formFields: formFields,
        });
    }
    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            let projects = JSON.stringify(this.state.formFields.projects);
            if (data) {
                httpServices.post('api/settings/expenses/createProjectCode', { data, projects})
                    .then(response => {
                        if (response.data.permission) {
                            this.getAllExpenseProjects();
                            let successMsg = ''
                            let formFields = {
                                expenseCode: null,
                                name: '',
                                id: 0,
                                pay_type: 1,
                                pay_project: 0,
                                start_date: null,
                                end_date: '',
                                code: ''
                            }

                            successMsg = response.data.message;
                            let response_type = response.data.response_type;
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: successMsg,
                                snackbarVarient: response_type,
                                open: false,
                                popupType: '',
                                formFields: formFields,
                                errors: {},
                            });
                        } else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                            else {
                                let successMsg = 'Expense project already exists';
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVarient: 'error'
                                });
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (parseInt(this.state.formFields.pay_type) === 1 && this.state.formFields.projects && this.state.formFields.projects.length === 0) {
            errors["projects"] = "Project cannot be empty";
            formIsValid = false;
        }
        if (parseInt(this.state.formFields.pay_type) === 2 && !this.state.formFields.code) {
            errors["code"] = "Project code cannot be empty";
            formIsValid = false;
        }
        if (parseInt(this.state.formFields.pay_type) === 2 && !this.state.formFields.name) {
            errors["name"] = "Project name cannot be empty";
            formIsValid = false;
        }
        if (parseInt(this.state.formFields.pay_type) === 2 && !this.state.formFields.start_date) {
            errors["start_date"] = "Start date cannot be empty";
            formIsValid = false;
        }
        if (parseInt(this.state.formFields.pay_type) === 2 && this.state.formFields.start_date && this.state.formFields.end_date && (this.state.formFields.end_date < this.state.formFields.start_date)) {
            errors["end_date"] = "End date cannot be less than start date";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/expenses/getexpenseproject-by-id';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data) {
                    if (response.data.expense_project) {                     
                          let formFieldValues = {
                            id: response.data.expense_project.id,
                            name: response.data.expense_project.name,
                            code: response.data.expense_project.code,
                            pay_type: response.data.expense_project.paytype,
                            pay_project: response.data.expense_project.project_id,
                            start_date: response.data.expense_project.start_date,
                            end_date: response.data.expense_project.end_date, 
                            projects: [response.data.expense_project.project_id],
                        }
                         if (response.data.expense_project.start_date) {
                              let sDateArr = response.data.expense_project.start_date.split('-');
                              let start_date_obj = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
                              formFieldValues['start_date_obj'] = start_date_obj;
                            }
                            if (response.data.expense_project.end_date) {
                              let eDateArr = response.data.expense_project.end_date.split('-');
                              let end_date_obj = new Date(eDateArr[0],eDateArr[1]-1,eDateArr[2]);
                              formFieldValues['end_date_obj'] = end_date_obj;
                            }
                        this.getExpenseCodes('edit', true, formFieldValues);

                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };

    deleteExpProject = () => {
        let apiEndpoint = 'api/settings/expenses/deleteexpenseproject-by-id';
        return httpServices.post(apiEndpoint, { id: this.state.deleteId })
            .then(response => {
                if (response.data) {

                    this.getAllExpenseProjects();
                    this.setState({
                        snackbarOpen: true,
                        snackbarMsg: response.data.message,
                        snackbarVarient: response.data.response_type,
                        open: false,
                        popupType: '',
                    });

                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };

    filterView = (anchor, open) => (event) => {
        this.setState({ filterViewState: open });
    };
    filterSubmit = () => {
        this.getAllExpenseProjects();
    }

    render() {
        return (
            <ExpenseProjectComponent
                ExpenseProjects={this.state.expenseProjects}
                EditPermission={this.state.editPermission}
                PopupType={this.state.popupType}
                Open={this.state.open}
                AddExpenseProjectClick={this.addExpenseProjectClick}
                HandleTreeChange={this.handleTreeChange}
                FormFields={this.state.formFields}
                Filters={this.state.filters}
                Errors={this.state.errors}
                HandleOnChange={this.handleOnChange}
                HandleSubmit={this.handleSubmit}
                SnackbarClose={this.snackbarClose}
                SnackbarOpen={this.state.snackbarOpen}
                SnackbarMsg={this.state.snackbarMsg}
                EditClick={this.editClick}
                OnClose={this.onClose}
                DeleteExpProject={this.deleteExpProject}
                HandleDeleteClick={this.handleDeleteClick}
                ProjectCodes={this.state.projectCodes}
                SnackbarVarient={this.state.snackbarVarient}
                HandleTimeEntryDateChange={this.handleTimeEntryDateChange}
                SelectedPayType={this.state.selectedPayType}
                FilterView={this.filterView}
                FilterViewState={this.state.filterViewState}
                FilterSubmit={this.filterSubmit}
                HandleFilterChange={this.handleFilterChange}
            />
        )
    }
}
