import React from 'react';
import { BasicDetailsComponent} from './BasicDetails.Component';
import { httpServices } from '../../../services/httpServices';
import { formatName } from '../../common/common';

const FormFields = {
    sel_user: '0',
    rev_status: '1',
    sel_prelim_user: '0',
    prelim_rev_status: '1',
};
export class BasicDetailsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            showLoader : false,
            FormFields : FormFields,
            projects :[],
            projectId : 0,
            reviewers : [],
            open : false,
            popupType : '',
            reviewerName : '',
            reviewerList : [],
            errors : [],
            success_message : '',
            snackbar: false,
            sel_status : '',
            edit_id : '',
            dialogOpen : false,
            nameFormat : 'FIRSTLAST',
            buttonLoader : false,
	    prelimReviewerList : [],
	    projectPrelimReviewers : [],
	    openPrelim : false,
	    prelimReviewerName: '',
	    prelim_sel_status: ''
        };
        this.getReviewers = this.getReviewers.bind(this);
        this.onAddReviewer = this.onAddReviewer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.onAddReviewerSubmit = this.onAddReviewerSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onDeleteReviewer = this.onDeleteReviewer.bind(this);
	this.getPrelimReviewers = this.getPrelimReviewers.bind(this);
	this.onAddPreliminaryReviewer = this.onAddPreliminaryReviewer.bind(this);
	this.onAddPreliminaryReviewerSubmit = this.onAddPreliminaryReviewerSubmit.bind(this);
	this.onDeletePrelimReviewer = this.onDeletePrelimReviewer.bind(this);
	this.onChangePrelimStatus = this.onChangePrelimStatus.bind(this);
    }
    componentDidMount() {
        this.getReviewers();
	this.getPrelimReviewers();
    }
    componentDidUpdate(prevProps) {
        if (this.props.Project && prevProps.Project) {
            if (this.props.Project.project_id !== prevProps.Project.project_id) {
                this.getReviewers();
            }
        }
    }
    onDeleteReviewer = (id) => {
        this.setState({
                        open : true,
                        popupType : 'delete',
                        edit_id : id,
                    });
    }
    onDeletePrelimReviewer = (id) =>{
	 this.setState({
                        openPrelim : true,
                        popupType : 'delete',
                        edit_id : id,
                    });

    }
    onChangeStatus = (id) => {
       let revs = this.state.reviewers;
       const ff = this.state.FormFields;
       let item_status = '1';
       let revName = '';
       revs.forEach(function (item) {
            if (item.id === id) {
                 item_status = item.status;
                 revName = formatName(item.user_first_name, null, item.user_last_name);
            }
        }); 
	ff['sel_status'] = (parseInt(item_status) === 1) ? '1':'0';
        this.setState({
                        open : true,
                        popupType : 'change',
                        reviewerName : revName,
                        FormFields : ff,
                        edit_id : id,
                    });
    };
    onAddReviewer = () => {
         this.setState({
                        open : true,
		   popupType : 'addReviewer',
                    });
    }
    onAddPreliminaryReviewer = () => {
         this.setState({
              openPrelim : true,
              popupType : 'addPreliminaryReviewer',
         });
    }	
     snackbarClose = () => {
        this.setState({
            snackbar: false,
            valid: false,
        });
    }
    onAddReviewerSubmit = (act) => {
        if (this.handleValidation(act)) {
        this.setState({ loaderOpen: true,
                        buttonLoader : true });
        return httpServices
            .post("api/project/listreviewers", {project_id: this.props.Project.project_id, reviewer: this.state.FormFields.sel_user, reviewer_status: this.state.FormFields.rev_status, action: act, edit_id : this.state.edit_id,selected_status : this.state.FormFields.sel_status})
            .then((response) => {
                if (response.data) {
                    this.setState({
                        success_message: response.data.success,
                        snackbar: true,
                        valid: true,
                        buttonLoader : false
                    });
                    this.getReviewers();
                    this.onClose();
                }
                else {
                    this.setState({ buttonLoader : false });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        }
    }
    onAddPreliminaryReviewerSubmit = (act) => {
        if (this.handleValidation(act)) {
        this.setState({ loaderOpen: true,
                        buttonLoader : true });
        return httpServices
            .post("api/project/prelim-reviewers", {project_id: this.props.Project.project_id, prelim_reviewer: this.state.FormFields.sel_prelim_user, prelim_reviewer_status: this.state.FormFields.prelim_rev_status, action: act, edit_id : this.state.edit_id,selected_status : this.state.FormFields.prelim_sel_status})
            .then((response) => {
                if (response.data) {
		   if (response.data.success) {
                    this.setState({
                        success_message: response.data.success,
                        snackbar: true,
                        valid: true,
                        buttonLoader : false
                    });
                    this.getPrelimReviewers();
                    this.onClose();
		   }
		    else {
			this.setState({
                        error_message: response.data.error,
                        snackbar: true,
                        valid: false,
                        buttonLoader : false
                    });

		    }
                }
                else {
                    this.setState({ buttonLoader : false });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        }
    }
    onChangePrelimStatus = (id) => {
       let revs = this.state.projectPrelimReviewers; 
       const prelim = this.state.FormFields;
       let item_status = '1';
       let revName = '';
       revs.forEach(function (item) {
            if (item.id === id) {
                 item_status = item.status;
                 revName = formatName(item.user_first_name, null, item.user_last_name);
            }
        });
        prelim['prelim_sel_status'] = (parseInt(item_status) === 1) ? '1':'0';
        this.setState({
                        openPrelim : true,
                        popupType : 'prelim_change',
                        prelimReviewerName : revName,
                        FormFields : prelim,
                        edit_id : id,
                    });
		   
    };
    handleValidation(act) {
        let formIsValid = true;
        let errors = {};
        if (act === "add") {
        if (!this.state.FormFields.sel_user || this.state.FormFields.sel_user === '0') {
            errors["sel_user"] = "Cannot be empty";
            formIsValid = false;
        }
	}
	if (act === "prelim_add") {
	if (!this.state.FormFields.sel_prelim_user || this.state.FormFields.sel_prelim_user === '0') {
            errors["sel_prelim_user"] = "Cannot be empty";
            formIsValid = false;
        }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.FormFields;
        ff[field] = evt.target.value;
        this.setState({
            FormFields: ff,
        });
    };
    onClose = () => {
        const ff = this.state.FormFields;
        ff['sel_user'] = '0';
        ff['rev_status'] = '1';
	ff['sel_prelim_user'] = '0';
        ff['prelim_rev_status'] = '1';
        this.setState({
            open: false,
	    openPrelim: false,
            FormFields: ff,
            errors : [],
        });
    };
    getReviewers = () => {
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/listreviewers", {project_id: this.props.Project.project_id})
            .then((response) => {
                if (response.data) {
                    this.setState({
                        reviewers: response.data.reviewers,
                        reviewerList : response.data.reviewerList,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    getPrelimReviewers = () => {
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/prelim-reviewers", {project_id: this.props.Project.project_id})
            .then((response) => {
                if (response.data) {
                    this.setState({
                        prelimReviewerList : response.data.prelimReviewerList,
                        projectPrelimReviewers : response.data.projectPrelimReviewers
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    render(){
        return(
            <BasicDetailsComponent
            Project = {this.props.Project}
            ParentProject = {this.props.ParentProject}
            ReviewerName = {this.state.reviewerName}
            ViewLink = {this.props.ViewLink}
            OtherPermissions = {this.props.OtherPermissions}
            Reviewers = {this.state.reviewers}
            ReviewerList = {this.state.reviewerList}
            Open = {this.state.open}
            PopupType = {this.state.popupType}
            OnAddReviewer = {this.onAddReviewer}
            OnClose = {this.onClose}
            HandleOnChange = {this.HandleOnChange}
            OnChange = {this.onChangeStatus}
            FormFields = {this.state.FormFields}
            OnAddReviewerSubmit = {this.onAddReviewerSubmit}
            Snackbar={this.state.snackbar}
            Valid={this.state.valid}
            Errors={this.state.errors}
            ErrorMessage={this.state.error_message}
            SuccessMessage={this.state.success_message}
            SnackbarClose={this.snackbarClose}
            UserStatus = {this.state.sel_status}
            OnDeleteReviewer = {this.onDeleteReviewer}
            DialogOpen = {this.state.dialogOpen}
            ProjectId = {this.state.projectId}
            UpdateParentOnEditSuccess = {this.updateParentOnEditSuccess}
            EditPermission = {this.props.EditPermission}
            SelfEditPermission = {this.props.SelfEditPermission}
            ReviewerEditPermission = {this.props.ReviewerEditPermission}
            ReviewerDeletePermission = {this.props.ReviewerDeletePermission}
            ReviewerViewPermission = {this.props.ReviewerViewPermission}
            NameFormat = {this.state.nameFormat}
            ButtonLoader = {this.state.buttonLoader}
	    SystemLocation = {this.props.SystemLocation}
	    PrelimReviewerList = {this.state.prelimReviewerList}
	    ProjectPrelimReviewers = {this.state.projectPrelimReviewers}
	    OnAddPreliminaryReviewer = {this.onAddPreliminaryReviewer}
	    OpenPrelim = {this.state.openPrelim}
	    OnAddPreliminaryReviewerSubmit = {this.onAddPreliminaryReviewerSubmit}
	    OnDeletePrelimReviewer = {this.onDeletePrelimReviewer}
	    OnChangePrelimStatus = {this.onChangePrelimStatus}
	    PrelimReviewerName = {this.state.prelimReviewerName}
            />
        )
    }
}
