import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { DialogBoxMedium } from "../../common/dialog.medium";
import { CustomizedSnackbars } from '../../common/snackbars';
import { formatName } from '../../common/common';
import { ServicePeriodEdit } from "../../staff/view/employment/EditServicePeriod";

export const UserEditComponent = (props) => {
    return(
        <React.Fragment>
            <div class="main-container">
                <div class="section-main">
                    <div class="section-header">
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">User Edit</h6>
                    <form className={"form-fullwidth"}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="user_type">User Type</InputLabel>
                                    <Select
                                        value={(typeof props.FormFields.user_type_id != 'undefined' && props.FormFields.user_type_id) ? props.FormFields.user_type_id : '' }
                                        onChange={props.HandleOnChange}
                                        disabled = {parseInt(props.UserType) == 3 ? false :true}
                                        inputProps={{
                                            name: 'user_type_id',
                                            id: 'user_type_id',
                                        }}>
                                        { typeof props.Filters.user_types != 'undefined' && props.Filters.user_types.map((t, i) =>
                                            <MenuItem value={t.id}>{t.type}</MenuItem>
                                        )}
                                    </Select>
                                </ FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        type="text"
                                        required
                                        value={(typeof props.FormFields.first_name !== 'undefined') ? props.FormFields.first_name : null}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.first_name && true}
                                        helperText={props.Errors.first_name}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        type="text"
                                        required = { props.FormFields.user_type_id === '3' ? false : true }
                                        value={(typeof props.FormFields.last_name !== 'undefined') ? props.FormFields.last_name : null}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.last_name && true}
                                        helperText={props.Errors.last_name}
                                    />
                                </ FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="user_email"
                                        name="user_email"
                                        label="Email"
                                        type="email"
                                        required
                                        value={(typeof props.FormFields.user_email !== 'undefined') ? props.FormFields.user_email : null}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.user_email && true}
                                        helperText={props.Errors.user_email}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="user_mobile"
                                        name="user_mobile"
                                        label="Mobile"
                                        type="tel"
                                        required = { props.FormFields.user_type_id === '3' ? false : true }
                                        value={(typeof props.FormFields.user_mobile !== 'undefined') ? props.FormFields.user_mobile : null}
                                        onChange={props.HandleOnChange}
                                        error={props.Errors.user_mobile && true}
                                        helperText={props.Errors.user_mobile}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl error={props.Errors.user_status && true} variant="standard">
                                    <InputLabel htmlFor="status" required>Status</InputLabel>
                                    <Select
                                        value={(typeof props.FormFields.user_status !== 'undefined') ? props.FormFields.user_status : ''}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'user_status',
                                            id: 'user_status',
                                        }}>
                                        { typeof props.Filters.statuses != 'undefined' && props.Filters.statuses.map((t, i) =>
                                            <MenuItem value={t.user_status_id}>{t.user_status_name}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{props.Errors.user_status}</FormHelperText>
                                </ FormControl>
                            </Grid>
                            {(props.UserType !== props.FormFields.user_type_id) &&
                                <Grid  item xs={12} md={4}>
                                    <FormControl>
                                        <TextField
                                            id="date_joined"
                                            name="date_joined"
                                            label="Date Joined"
                                            type="date"
                                            required
                                            value={(typeof props.FormFields.date_joined !== 'undefined') ? props.FormFields.date_joined : null}
                                            onChange={props.HandleOnChange}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            error={props.Errors.date_joined && true}
                                            helperText={props.Errors.date_joined}
                                        />
                                    </ FormControl>
                                </Grid>
                            }
                            {(parseInt(props.FormFields.user_status) === 3 && parseInt(props.UserType) === 1) &&
                                <Grid  item xs={12} md={4}>
                                    <FormControl>
                                        <TextField
                                            id="relieved_date"
                                            name="relieved_date"
                                            label="Relieved Date"
                                            type="date"
                                            required
                                            value={(typeof props.FormFields.relieved_date !== 'undefined') ? props.FormFields.relieved_date : null}
                                            onChange={props.HandleOnChange}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            error={props.Errors.relieved_date && true}
                                            helperText={props.Errors.relieved_date}
                                        />
                                    </ FormControl>
                                </Grid>
                            }
                        </Grid>
                        {(props.UserType !== props.FormFields.user_type_id) && <>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <InputLabel htmlFor="pay_period">Pay Period</InputLabel>
                                    <Select
                                        value={(typeof props.FormFields.payperiod != 'undefined' && props.FormFields.payperiod) ? props.FormFields.payperiod : 'semi-monthly' }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'payperiod',
                                            id: 'payperiod',
                                        }}>
                                        { typeof props.Filters.payperiod != 'undefined' && props.Filters.payperiod.map((t, i) =>
                                            <MenuItem value={t.value}>{t.title}</MenuItem>
                                        )}
                                    </Select>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl error={props.Errors.category && true}>
                                    <InputLabel htmlFor="category">Category</InputLabel>
                                    <Select
                                        value={(typeof props.FormFields.category != 'undefined' && props.FormFields.category) ? props.FormFields.category : '' }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'category',
                                            id: 'category',
                                        }}>
                                        { typeof props.Filters.category != 'undefined' && props.Filters.category.map((t, i) =>
                                            <MenuItem value={t.value}>{t.title}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{props.Errors.category}</FormHelperText>
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl error={props.Errors.overtime && true}>
                                    <InputLabel htmlFor="overtime">Overtime</InputLabel>
                                    <Select
                                        value={(typeof props.FormFields.overtime != 'undefined' && props.FormFields.overtime) ? props.FormFields.overtime : '' }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'overtime',
                                            id: 'overtime',
                                        }}>
                                        { typeof props.Filters.overtime != 'undefined' && props.Filters.overtime.map((t, i) =>
                                            <MenuItem value={t.value}>{t.title}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>{props.Errors.overtime}</FormHelperText>
                                </ FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(typeof props.FormFields.email_notification !== 'undefined') ? props.FormFields.email_notification : false}
                                            id = "email_notification"
                                            name ="email_notification"
                                            onChange={props.HandleOnChange}
                                        />
                                    }
                                    label="Email Notification"
                                />
                            </Grid>
                            {(typeof props.FormFields.email_notification !== 'undefined' && props.FormFields.email_notification) ? <>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(typeof props.FormFields.secondary_email_notification !== 'undefined') ? props.FormFields.secondary_email_notification : false}
                                            id = "secondary_email_notification"
                                            name ="secondary_email_notification"
                                            onChange={props.HandleOnChange}
                                        />
                                    }
                                    label="Secondary Email Notification"
                            />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(typeof props.FormFields.email_own_action !== 'undefined') ? props.FormFields.email_own_action : false}
                                            id = "email_own_action"
                                            name ="email_own_action"
                                            onChange={props.HandleOnChange}
                                        />
                                    }
                                    label="Receive Email For Own Actions"
                                />
                            </Grid>
                            </>
                            :<></>
                            }
                        </Grid>
                        </>
                        }
                        <div className={'form-footer'}>
                                    <Button onClick={ () => { window.history.back();} }>
                                        Back
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={props.OnSubmit} 
                                    >
                                        Save
                                    </Button>
                        </div>
                    </form>
                    {(props.PopupType === 'service_period' &&  props.Open) &&
                        <DialogBoxMedium
                            Open= { props.Open }
                            Cancel = { props.Close } 
                            CancelButtonName = {'No'}
                            Title = { 'Add Service Period' }
                            OnSubmit = { props.CreateServicePeriod} 
                            ButtonName = {'Yes'}
                            Content = {"Do you really want to add a new service period?"}
                        />
                    }
                    {(props.PopupType === 'edit_warning' &&  props.Open) &&
                        <DialogBoxMedium
                            Open= { props.Open }
                            Cancel = { props.Close }
                            Title = { 'Transfer Role' }
                            HideCancel = {true}
                            OnSubmit = { props.Close}
                            ButtonName = {'Ok'}
                            Content = {
                                <RoleAllocationWarning
                                    Projects = {props.Projects}
                                    Subordinates = {props.Subordinates}
                                    UserName = {formatName(props.FormFields.first_name, null, props.FormFields.last_name)}
                                />
                            }
                        />
                    }
                    {(props.PopupType === 'add_service_period' &&  props.Open) &&
                            <DialogBoxMedium
                                Open={props.Open}
                                Cancel = {props.OnServicePeriodClose} 
                                CancelButtonName = {'No'}
                                Title={ 'Add Service Period'}
                                OnSubmit={props.OnServicePeriodSubmit}
                                ButtonName = {'Yes'}
                                ErrorMessage={props.ErrorMessage}
                                Loading = {props.ButtonLoader}
                                Content={
                                    <ServicePeriodEdit
                                        OnSubmit={props.OnServicePeriodSubmit}
                                        spInputs={props.Filters}
                                        HandleOnChange={props.HandleOnChange}
                                        spData={props.FormFields}
                                        Errors = {props.Errors}
                                    />
                                }
                            />
                    }
                    {props.SnackbarOpen &&
                        <CustomizedSnackbars
                            open = {props.SnackbarOpen}
                            variant= 'success'
                            message = {props.SnackbarMsg}
                            handleClose = {props.SnackbarClose}
                        />
                    }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const RoleAllocationWarning = (props) => {
    return(
        <Grid container spacing={1}>
            <Grid item md={12}>This user {props.UserName} is the</Grid>
            <Grid item md={12}>
                {props.Projects.length?
                <div>
                    <b>Project manager</b> for the following projects:
                    <ul>
                        {props.Projects.map((ry,i) =>
                            <li>{ry.project_name}</li>
                        )}
                    </ul>
                </div>
                :<></>
                }
                {props.Subordinates.length?
                <div>
                    <b>Supervisor</b> for the following users:
                    <ul>
                        {props.Subordinates.map((ry,i) =>
                            <li>{formatName(ry.user_first_name,null,ry.user_last_name)}</li>
                        )}
                    </ul>
                </div>
                :<></>
                }
                <span className={'error'}>Please reallocate the above roles for changing the status of the user {props.UserName} to relieved.</span>
            </Grid>
        </Grid>
    )
}
