import React from "react";
import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import { AddProject } from '../AddProject/AddProject.Container.js';
import { formatName } from '../../common/common';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import * as _R from 'ramda';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from '@mui/material/styles';


function sort(date1, date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
const FilterButtonStyles = styled('div')(({ theme }) => ({
    '& .filterSubmitButton':{
        marginTop:'20px'
    },
    '& .filterResetButton':{
        marginTop:'20px',
        marginLeft:'10px'
    },
})); 
export const SubProjectComponent = (props) => {
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <div className={'col-left'}>
                    </div>
                    <div className="action">
                        <Tooltip title="Filter">
                            <IconButton onClick={props.FilterView('right', true)}>
                                <FilterListIcon color="action" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                    { <div className={'tree-view-drawer'}>
                        <div className={'drawer-title'}>Filter</div>
                        <SubProjectFilterComponent
                            ProjectStatuses = {props.ProjectStatuses}
                            ResetFilter = {props.ResetFilter}                           
                            ProjectStatus = {props.ProjectStatus}
                            HandleFilterOnSubmit = {props.HandleFilterOnSubmit}
                            HandleFilterOnChange = {props.HandleFilterOnChange}
                        />
                    </div>
                    }
                    </Drawer>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title=""
                    columns={[
                        { title: 'Charge Code', field: 'project_code' },
                        { title: 'Project Name', field: 'project_name' },
                        { title: 'Project Manager', field: 'project_manager', render: rowData => (formatName(rowData.manager_first_name, null, rowData.manager_last_name)) },
                        { title: 'Start date', field: 'project_sdate', customSort: (a, b) => sort(new Date(a.project_sdate), new Date(b.project_sdate)) },
                        { title: 'End Date', field: 'project_edate', customSort: (a, b) => sort(new Date(a.project_edate), new Date(b.project_edate)) },
                        { title: 'Status', field: 'project_status_name' },
                        { title: 'Last Modified', field: 'project_mdate' },

                    ]}
                    components={{
                        Toolbar: () => null,
                      }}
                    data={props.SubProjects}
                    options={{
                        actionsColumnIndex: -1,
                        pageSizeOptions: [10, 20, 30],
                        search: false,
                        thirdSortClick: false,
                        filtering: false,
                    }}
                    actions={[                       
                        {
                            icon: 'visibility',
                            tooltip: 'View Project',
                            onClick: (event, rowData) => {
                                props.OnView(rowData.project_id);
                            },
                        },

                        rowData => ({
                            icon: 'edit',
                            tooltip: 'Edit Project',
                            hidden: rowData.project_edit_permission ? false : true,
                            onClick: (event, rowData) => {
                                props.OnUpdate(rowData.project_id);
                            },
                        })

                    ]}

                />
            </div>
            {(props.PopupType === 'edit_project' && props.DialogOpen) &&
                <AddProject
                    Open={props.DialogOpen}
                    UpdateParentOnAddSuccess={props.UpdateParentOnEditSuccess}
                    EditId={props.ProjectId}
                    EditSubProject={'1'}
                    Title={"Edit Subproject"}
                />
            }
            {(props.PopupType === 'add_project' && props.DialogOpen) &&
                <AddProject
                    Open={props.DialogOpen}
                    UpdateParentOnAddSuccess={props.UpdateParentOnAddSuccess}
                    EditId={0}
                    Title={"Add Subproject"}
                    Parent={props.Parent}
                />
            }
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant='success'
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            }
        </React.Fragment>
    );
};
const SubProjectFilterComponent = (props) => {
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>                 
                 <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status">Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'status',
                            id: 'status',
                        }}
                        value = {!_R.isNil(props.ProjectStatus) && props.ProjectStatus}
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.ProjectStatuses) !== 'undefined' && props.ProjectStatuses.map((t, i) =>
                            <MenuItem value={t.project_status_id} key={'c-'+i}>{t.project_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <FilterButtonStyles>
                        <Button variant="contained" color="primary" onClick={props.HandleFilterOnSubmit} className={'filterSubmitButton'} > Submit </Button>
                    </FilterButtonStyles>
                </Grid>                
            </Grid>
        </form>
    )
};
