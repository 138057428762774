import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { formatName } from '../../../common/common';


export class RequestUserConfig extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            dialog_open: false,
            IsEditable: false,
            IsDelete: false,
            formFields: {},
            supervisors: [],
            errors: {},
            errorMessage: false,
            request_user_config : props.RequestUserConfig,
            delete_id : 0
        };
        this.addRequestConfig = this.addRequestConfig.bind(this);
        this.onClose = this.onClose.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.saveRequestConfig = this.saveRequestConfig.bind(this);
        this.deleteRequestConfig = this.deleteRequestConfig.bind(this);
    }
    
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
    };
    
    addRequestConfig = () =>  {
        this.setState({
            dialog_open: true,
            formFields: {},
            IsDelete: false,
        });
    }
    
    onClose = () =>{
        this.setState({
            dialog_open: false
        });
        this.clearForm();
    }
    
    clearForm = () => {
        const ff = this.state.formFields;
        if (_R.length(ff) > 0) {
            for (let key in ff) {
                ff[key] = null;
            }
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    
    saveRequestConfig = (action) => () =>  {
        const {UserID, StaffID} = this.props;
        const data = this.state.formFields;
        data.user_id = UserID;
        data.action = action;
        if (data) {
            if (this.handleValidation(action)) {
                let apiEndpoint = 'api/staff/work/save-request-user-config';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.success) {
                                this.setState({
                                    dialog_open:false,
                                    request_user_config: response.data.request_user_config,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                        else {
                             this.setState({ errors: response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    
    handleValidation(action) {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.request) {
            errors["request"] = "Cannot be empty";
            formIsValid = false;
        }
        else {
            let id = this.state.request_user_config.findIndex(x => x.request_type_id === this.state.formFields.request);
            if (id >= 0) {
                errors["request"] = "Auto approval already enabled";
                formIsValid = false;
            }

        }
        console.log(errors);
        this.setState({errors: errors});
        return formIsValid;
    };
    
    deleteRequestConfig = (id) => () =>  {
        this.setState({
            dialog_open:true,
            formFields: [],
            IsDelete: true,
            delete_id : id
        });
    }
    
    submitDeleteConfig = () =>  {
        let apiEndpoint = 'api/staff/work/delete-request-user-config';
        const {UserID, StaffID} = this.props;
        return httpServices.post(apiEndpoint, { id : this.state.delete_id, user_id: UserID})
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        dialog_open:false,
                        request_user_config: response.data.request_user_config,
                        delete_id : 0
                    });
                }
                else {
                    this.setState({
                        errorMessage: response.data.msg,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    render(){
        const {Edit, Delete} = this.props;
        const RequestUserConfig = this.state.request_user_config;
        return (
            <>
                <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Request User Config</Typography>
                {_R.equals(Edit, true) &&
                    <Tooltip title="Add request config" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.addRequestConfig}>
                        <AddIcon />
                    </Fab>
                    </Tooltip>
                }
                </div>
                 { _R.isEmpty(RequestUserConfig) ? <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography> :
           
                <div className={'section-content'}>
                    <Table size = "small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Request Type</TableCell>
                            <TableCell>Auto Approval</TableCell>
                            {_R.equals(Delete,true) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { typeof RequestUserConfig != 'undefined' && RequestUserConfig.map((d, id) =>
                        <TableRow hover>
                            <TableCell>{d.request_name}</TableCell>
                            <TableCell>{d.auto_approval == '1' ? 'Enabled': 'Disabled'}</TableCell>
                            {_R.equals(Delete,true) &&
                                <TableCell align="right">
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Delete">
                                            <DeleteIcon fontSize="small" onClick={this.deleteRequestConfig(d.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            }
                        </TableRow>
                        )}
                        { _R.isEmpty(RequestUserConfig) &&
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                </TableCell>
                            </TableRow>
                        }   
                    </TableBody>
                </Table>
            </div>
            }
                <DialogBoxMedium
                    Open = {this.state.dialog_open}
                    Cancel = {this.onClose}
                    OnSubmit = {this.state.IsDelete ? this.submitDeleteConfig : this.saveRequestConfig('add')}
                    Title = {this.state.IsDelete ? 'Delete Request Auto Approval' : 'Add Request Auto Approval'}
                    ButtonName = {this.state.IsDelete ? 'Yes' : 'Save'}
                    CancelButtonName =  {this.state.IsDelete ? 'No' : 'Cancel'}
                    Content = { this.state.IsDelete ?
                        <>Do you want to remove this request auto approval?</>:
                        <DialogAddContent
                            AutoApprovalRequests = {this.props.AutoApprovalRequests}
                            FormFields={this.state.formFields}
                            HandleOnChange = {this.HandleOnChange}
                            Errors = {this.state.errors}
                        />
                    }
                />
            </>
        );
    }
}

const DialogAddContent = (props) => {
    const {FormFields, AutoApprovalRequests, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="reviewer_id" error={props.Errors.request && true} required>Request Type For Auto Approval</InputLabel>
                            <Select
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.request}
                                    onChange={HandleOnChange}
                                    required
                                    error={props.Errors.request && true}
                                    inputProps={{
                                        name: 'request',
                                        id: 'request',
                                    }}>
                                    {_R.type(AutoApprovalRequests) !== 'Undefined' && AutoApprovalRequests.map((t, i) =>
                                        <MenuItem value={t.id} key={'reviewer-'+i}>{t.name}</MenuItem>
                                    )}
                                </Select>
                                <small className='error'>{props.Errors.request && props.Errors.request}</small>
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}
