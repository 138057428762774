import React from 'react';
import {ReviewComponent} from './Review.Component';
import {httpServices}  from '../../services/httpServices';
import { ErrorPage } from '../common/error';

const API_URL = process.env.REACT_APP_API_URL;
export class ReviewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            columns:[],
            payrolls: [],
            column_total: [],
            period: null,
            payroll_status: [],
            payroll_ids:[],
            p_status: null,
            permissions:[],
            hasPermission: true,
            permision_err_msg: '',
            sdate: null,
            edate: null,
            loaderOpen: true,
        }
    }

    handleOnChange = (evt, value) => {
        if (evt.target.value) {
            this.setState({
                p_status: evt.target.value
            });
        }
    };

    exportPayrollMatrix = () => {
       let url = API_URL+'api/payroll/export-payroll/'+this.props.match.params.id;
        window.location.assign(url);
                    
    }


    displayPayrollMatrix = (payperiod_id, status) => {
        return httpServices.post('api/payroll/review', {'payperiod_id': payperiod_id, 'status': status})
            .then(response => {
                if (response.data) {
                    this.setState({
                     loaderOpen: false, 
                    });
                    let p = {};
                    p.edit = response.data.edit_permission;
                    p.sent = response.data.sent_permission;
                    p.approve = response.data.approve_permission;

                    let payrollObj = response.data.payrolls;
                    let payrollObjColumns = response.data.payrolls.columns;
                    let column_total = payrollObj.total;
                    payrollObjColumns.forEach(addCellStyle);
                    function addCellStyle(item,index)
                    {                       
                        if (item.cellStyle) {
                          item.cellStyle = JSON.parse(item.cellStyle);
                         }
                    }
                    let pRows = payrollObj.rows;
                    let resultArray = Object.keys(pRows).map(function(idx){
                        let rows = pRows[idx];
                        // do something with person
                        return rows;
                    });
                    if (Object.keys(payrollObj)) {
                        this.setState({
                            columns: payrollObj.columns,
                            payrolls:resultArray,
                            period: payrollObj.start_date+' - '+payrollObj.end_date,
                            payroll_status: response.data.status,
                            permissions: p,
                            hasPermission: response.data.permission,
                            sdate: payrollObj.sdate,
                            edate: payrollObj.edate,
                            column_total: column_total,
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    componentDidMount = () => {
        var payperiod_id = (this.props.match.params.id) ? this.props.match.params.id: false;
        var status = (this.props.match.params.status) ? this.props.match.params.status: false;
        this.displayPayrollMatrix(payperiod_id, status);
    };

    onCellEditCompleted = (rowData, value, field) => {
        if (parseInt(rowData.status) === 3 ) return true; //For Approved status
        const postData = {
            'id': rowData.payroll_component_id,
            'payroll_id': rowData.payroll_id,
            'component_id': field.split("_").pop(-1),
            'amount': value,
            'staff_id': rowData.staff_id,
            'status': this.state.p_status,
            'pay_period_id': rowData.pay_period_id
        };

        return httpServices.post('api/payroll/update-payroll-amount', postData)
        .then(response => {
            if (response.data) {
                let pRows = response.data.payrolls;
                let resultArray = Object.keys(pRows).map(function(idx){
                    let rows = pRows[idx];
                    // do something with person
                    return rows;
                });
                let column_total = response.data.total;
                this.setState({
                    payrolls:resultArray,
                    column_total:column_total,
                });
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    handleSaveStatus = (ids, payperiod_id) => {
        if(this.state.p_status){
            let postData = {
                'ids': ids,
                'status': this.state.p_status
            };
            return httpServices.post('api/payroll/update-payroll-status', postData)
                .then(response => {
                    if (response.data) {
                        this.setState({p_status: null});
                        this.displayPayrollMatrix(payperiod_id);
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }else{ 
            alert('Please select a status');
        }
    }

    regeneratePayroll = (rows) => {
        if (rows.length>0) {
            let c = window.confirm("All manual adjustments will be reset. Do you want to continue?" );
            if (c) {
            let dataRows = [];
            let payPeriodID = null;

            rows.map(function(val, index){
                if (index === 0 && val['pay_period_id'] != null) payPeriodID = val['pay_period_id'];
                let dataObj = {};
                dataObj.staff_id = val.id;
                dataObj.desg = val.designation;
                dataObj.date_joined = val.date_joined;
                dataObj.date_relieved = val.relieved_date;
                dataObj.payroll_id = val.payroll_id;
                dataObj.user_id = val.user_id;
                dataObj.emp_category = val.emp_category;
                dataObj.pay_date = val.pay_date;
                dataRows.push(dataObj);
                return dataRows;
            });

            let postData = {
                'data_rows': JSON.stringify(dataRows),
                'payperiod_id': payPeriodID,
                'start_date': this.state.sdate,
                'end_date': this.state.edate,
                'regenerate': true
            };
            this.setState({loaderOpen:true});

            return httpServices.post('api/payroll/generate', postData)
                .then(response => {
                    if (response.data) {
                        this.setState({loaderOpen:false});
                        this.displayPayrollMatrix(response.data.payperiod_id, false);
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
            }
        }
        else{
            alert("Please select at least one user");
        }
    }

    render(){
        return(
            (this.state.hasPermission)
            ?<ReviewComponent
                Columns={this.state.columns}
                Rows={this.state.payrolls}
                Period={this.state.period}
                ColumnTotal = {this.state.column_total}
                OnCellEdit={this.onCellEditCompleted.bind(this)}
                exportPayroll = {this.exportPayrollMatrix.bind(this)}
                HandleSaveStatus={this.handleSaveStatus.bind(this)}
                PayrollStatus={this.state.payroll_status}
                HandleOnChange={this.handleOnChange.bind(this)}
                pStatus={this.state.p_status}
                Permissions={this.state.permissions}
                RegeneratePayroll={this.regeneratePayroll}
                LoaderOpen={this.state.loaderOpen}
            />
            :<ErrorPage/>
        )
    }
}
