import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import format from 'date-fns/format';
import { RequestList } from './RequestList.Component';
import { AddTimeOff } from './TimeOffAdd.Container';
import { TimeoffFilterComponent } from './TimeOffFilter.Component';
import { CustomizedSnackbars } from '../../common/snackbars';
import {GetConfig} from '../../../redux/middleware/configMiddleware';
import { GetSelectedTab } from '../../../redux/middleware/tabMiddleware';


export const TimeOffComponent = (props) => { 
    const selectedTimeoffTab =  GetSelectedTab("timeoff");
    let myTabIndex = (props.RequestView) ? 0 : null;
    let reviewTabIndex = (props.RequestView && props.TimeoffReviewPermission) ? 1 : null;
    let allTabIndex = null;
    if (props.RequestView && props.TimeoffReviewPermission && props.AllRequestViewPermission) {
        allTabIndex = 2;
    } else if(props.RequestView && props.AllRequestViewPermission) {
        allTabIndex = 1;
    } else if(props.AllRequestViewPermission) {
        allTabIndex = 0;
    }
    let tabIndex = (selectedTimeoffTab === 'my' && props.RequestView) ? myTabIndex : (selectedTimeoffTab === 'review' && props.RequestView && props.TimeoffReviewPermission) ? reviewTabIndex : allTabIndex;
    //Tabs
    const nameFormat =  GetConfig('name_format');
   function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Time Off Request
                        </Typography>
                    </div>
                    <div className={'st-timeEntry_payperiod-title center'}>
                            <span className={'label'}> Period</span>
                            {format(props.PeriodStartDate, 'MM/dd/yy')} - {format(props.PeriodEndDate, 'MM/dd/yy')}
                    </div>
                    <div className={'action'}> 
                      {props.TimeoffEditPermission && (props.ExternalUser === false) &&
                        <Button variant="contained" color="primary" size="small" onClick={()=>props.CreateTimeOffClick(0)}>New Request</Button>
                      }<i> </i>
                      {props.TimeoffGrantPermission && (props.ExternalUser === false) && props.TimeoffReviewPermission &&
                        <Button variant="contained" color="primary" size="small" onClick={()=>props.GrantTimeOffClick(0)}>Grant Time Off</Button>
                      }
                        <Tooltip title="Filter">
                            <IconButton
                                onClick={props.FilterView('right', true)}
                            >
                                <FilterListIcon color="action" />
                            </IconButton>
                        </Tooltip>
                        <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                            <div className={'tree-view-drawer'}>
                                <div className={'drawer-title'}>
                                    Filter
                                </div>
                                <TimeoffFilterComponent 
                                    formFields = {props.FilterFields} 
                                    handleOnSubmit = {props.FilterSubmit} 
                                    NameFormat = {nameFormat}
                                    handleOnChange = {props.HandleFilterChange} 
                                    tab={selectedTimeoffTab}
                                    users = {props.Users}
                                    requestStatus = {props.RequestStatus}
                                    employeeStatus = {props.EmployeeStatus}
                                    filterTOTasks = {props.FilterTOTasks}
                                    handleStartDateChange={props.handleStartDateChange} 
                                    handleEndDateChange={props.handleEndDateChange}
                                    ResetFilter = {props.ResetFilter}
                                    errors = {props.errors}
                                    saveFilter = {props.SaveFilter}
                                />
                            </div>
                        </Drawer>
                    </div>
                </div>

                <div className={'section-body'}>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabIndex}
                                onChange = {props.handleChange}
                                indicatorColor="primary"
                                aria-label="Time Off List View Tabs"
                            >
                                {props.RequestView ? <Tab onClick={() => props.GetTimeOffDetails('my')} label="My Requests"/> : <></>}
                                {props.RequestView  && props.TimeoffReviewPermission ? <Tab onClick={() => props.GetTimeOffDetails('review')} label = {props.ReviewCount !== 0 ? <Badge color="secondary" badgeContent={props.ReviewCount} >Review </Badge> : "Review"}/> : <></>}
                                {props.AllRequestViewPermission ? <Tab onClick={() => props.GetTimeOffDetails('all')} label="All Requests"/> : <></> }
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabIndex} index={myTabIndex}>
                                {props.RequestView ? <RequestList
                                    Requests = {props.MyRequests}
                                    Tab = {'my'}
                                    HandleViewClick = {props.HandleViewClick}
                                    CreateTimeOffClick = {props.CreateTimeOffClick}
                                /> : <></>}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={reviewTabIndex}>
                                {props.RequestView  && props.TimeoffReviewPermission ? <RequestList
                                    Requests = {props.ReviewRequests}
                                    Tab = {'review'}
                                    HandleViewClick = {props.HandleViewClick}
                                    CreateTimeOffClick = {props.CreateTimeOffClick}
                                /> : <></>}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={allTabIndex}>
                                {props.AllRequestViewPermission ? <RequestList
                                    Requests = {props.AllRequests}
                                    Tab = {'all'}
                                    HandleViewClick = {props.HandleViewClick}
                                    CreateTimeOffClick = {props.CreateTimeOffClick}
                                /> : <></>}
                            </TabPanel>
                        </div>
                    </Paper>
                </div>
            </div>
            {(props.Open || props.GrantOpen) &&
            <AddTimeOff 
                Open = {props.Open}
                UpdateParentOnAddSuccess = {props.UpdateParentOnAddSuccess}
                EditId = {props.EditId}
                Title = {(props.GrantOpen) ? 'GRANT TIME OFF' : ((props.EditId && props.EditId !== 0) ? "EDIT"  : "NEW")}
                CloseLoader = {props.CloseLoader}
                GrantOpen = {props.GrantOpen}
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>    
    )
};
