import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CustomizedSnackbars } from '../common/snackbars';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import {FullScreenDialog} from '../common/fullscreendialog.medium';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer) (({ theme }) => ({
  '& .container': {
    maxHeight: 440,
  },
}));

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Paper>
                    <TableContainer className={'container'}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Transaction Type</TableCell>
                                <TableCell>Benefit Month</TableCell>
                                <TableCell>Pre-tax Amount</TableCell>
                                <TableCell>Post-tax Amount</TableCell>
                                <TableCell>Deduction Amount</TableCell>
                                <TableCell>Change Code</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                {_R.type(props.component_data) !== 'Undefined' && (props.component_data).map((item) =>
                            <TableRow>
                                <TableCell> {item.staff_name} </TableCell>
                                <TableCell> {item.txn_type} </TableCell>
                                <TableCell> {item.month_year} </TableCell>
                                <TableCell> {item.amount} </TableCell>
                                <TableCell> {item.posttax_amount} </TableCell>
                                <TableCell> {item.deduction_amount} </TableCell>
                                <TableCell> {item.change_code} </TableCell>
                            </TableRow>
                )}
                    <TableRow> <TableCell></TableCell><TableCell></TableCell><TableCell>Total :</TableCell>
                    <TableCell>{props.total_pretax_amount}</TableCell><TableCell>{props.total_posttax_amount}</TableCell></TableRow>
                    <TableRow><TableCell></TableCell><TableCell></TableCell><TableCell>Grand Total :</TableCell><TableCell>{props.grand_total}</TableCell>
                    </TableRow>
                    </TableBody> 
                    </Table>
                    </TableContainer>
                </Paper>
           </form>
        )
};
export const ImportEdenRedStatementComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    return (
        <React.Fragment>
            <Paper>
                <Grid container spacing={3} style={gridStyle}>
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                            <Typography variant="h6" component="h6" className = {'section-title'}> Import Edenred </Typography>
                            <input type="file" name="fileToUpload" id="fileToUpload" onChange={props.HandleOnChange}/>
                            <Button variant="contained" size="small" color="primary" onClick={props.OnSubmit}>Import</Button>
                            <div>
                                <small className='error'>{props.errors.file && props.errors.file}</small>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
            
             <div className={'mtable-basic'} >
             <CustomMaterialTable
                title={"Commuter Transactions"}
                columns={[
                            {
                                title: 'Month & Year',
                                field: 'month_year',
                            },
                            {
                                title: 'Created By',
                                field: 'created_by',
                            },
                            {
                                title: 'Created On',
                                field: 'created_on',
                            },
                        ]}
                        data = {props.commutedData}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                            tableLayout: 'fixed',
                        }}
                onRowClick={(event, rowData) => props.HandleEdenredDetailsPage(rowData.month,rowData.year)}
            />
            <FullScreenDialog
                    Open={props.Open}
                    Cancel={props.Close}
                    ButtonName={"Save"}
                    Title= {"Edenred Statement Details"}
                    OnSubmit={props.SaveEdenred}
                    Disabled={props.disable}
                    Content={<DialogComponent FormProps={props}/>}
        />

            </div>
            <CustomizedSnackbars
                open={props.snackbarOpen}
                variant={props.valid?'success':'error'}
                message={props.valid? props.message:props.error_message}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    )
};
