import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CustomizedSnackbars } from "../../common/snackbars";
import { DialogBoxMedium } from "../../common/dialog.medium";
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import { GroupEditContainer } from './GroupEdit.Container';
import { formatName } from '../../common/common';
import * as _R from 'ramda';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { WorkPolicyList } from './WorkPolicy';
import Tooltip from '@mui/material/Tooltip';
const formFields = {
    work_policy_id : null,
    start_date : null,
    end_date : null,
    id : 0
}
const GroupViewStyle = styled(React.Fragment)(({theme}) => ({
  '& .table': {
    minWidth: 650,
  },
}));

export const GroupViewComponent = (props) => {
    const { GroupDetails, Group_policy} = props;
      const gridStyle = {
        display: "block",
        padding: "24px",
        position: "relative",
        background: "#fff",
    };
    let tabIndex = 0;
    const [tabValue, setTabValue] =  React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };

  function TabPanel(props) {
        const { children, value, index, ...other } = props;
       return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    let data = typeof props.GroupDetails !== "undefined" ? props.GroupDetails : "";
    return (
        <GroupViewStyle>
        <div className="section-main">
        <div className="section-header">
            <div className="col-left">
                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                <div className="section-title-wrap">
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        {data.name}
                    </Typography>
                    <Typography></Typography>
                </div>
            </div>
            <div className="actions">
                   {props.EditPermission &&
                        <Button variant="contained" size="small"  color="primary" onClick={() => props.HandleEditClick(data.id)}>Edit</Button>
                   }
             </div>
        </div>
        <div className="section-body">
             <Paper className={"mb-1"} style={gridStyle}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Name</label>
                                <span className={"value"}>
                                    {data.name}
                                </span>{" "}
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Created By</label>
                                <span className={"value"}>
                                    {formatName(data.cby_first_name,null,data.cby_last_name)}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Modified By</label>
                                <span className={"value"}>
                                    {formatName(data.mby_first_name,null,data.mby_last_name)}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Created On</label>
                                <span className={"value"}>
                                    {data.created_on}
                                </span>
                            </div>
                            <div
                                className={
                                    "profile-view__basics_default-item item-role"
                                }
                            >
                                <label>Modified On</label>
                                <span className={"value"}>
                                    {data.modified_on}
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>

            <Grid item xs={12} md={12}>
                <Tabs 
                    value={tabValue} 
                    onChange={tabChange} 
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Staff Tabs"
                    scrollButtons="auto"
                    className={'tab-main'}
                >   
                    <Tab label="Members" value={0}/>
                    <Tab label="Policy" value={1}/>
                </Tabs>
                <TabPanel value={0} index={0}>
                    { tabValue === 0 && <div>
                        <div className={"listSubHeader"}>
                        <Typography
                            component="h2"
                            className={"section-title"}
                            variant="button"
                            display="block"
                            gutterBottom
                            color="primary"
                        ></Typography>
                        { props.EditPermission && 
                            <div>
                                <Button variant="outlined" size="small"  color="primary" onClick={() => props.AddMember()}>Add Member</Button>
                                <Button className="ml-1" variant="outlined" size="small"  disabled={props.SelectedUsers.length === 0?true:false} onClick={() => props.ConfirmDelete()}>Delete Selected</Button>
                            </div>
                        }
                        </div>
                        <Paper className="p1">
                            {(props.Members.length>0) ?
                                <Table className={'table'} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox 
                                                name='user' 
                                                value={'all'} 
                                                checked={(props.SelectedUsers.indexOf('all')!==-1)?true:false}
                                                onChange={props.HandleSelection}
                                            />
                                        </TableCell>
                                        <TableCell align="left">User Name</TableCell>
                                        <TableCell align="left">User Status</TableCell>
                                        <TableCell align="left">Group Manager</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {props.Members.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell  padding="checkbox">
                                            <Checkbox
                                                name='user'
                                                value={row.user_id}
                                                onChange={props.HandleSelection}
                                                checked={(props.SelectedUsers.indexOf(row.user_id)!==-1)?true:false}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="left">{formatName(row.first_name, null, row.last_name)}</TableCell>
                                        <TableCell align="left">{row.user_status_name}</TableCell>
                                        <TableCell align="left">{parseInt(row.is_manager)?'Yes':'No'}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                </Table>
                                : <div className="p1">No members found</div>
                            }
                        </Paper>     
                    </div>
                    }
                </TabPanel>
                <TabPanel value={1} index={1}>
                    { tabValue === 1 && <div>
                            <div className={'section-main'}>
                               <WorkPolicyList
                                    Group_policy={Group_policy}
                                    GroupID = {GroupDetails.id}
                                />
                           
                            </div>
                    </div>
                    }    
                </TabPanel>          
            </Grid>                        
        </div>
    </div>
            {(props.PopupType === 'add' &&  props.Open) &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Add member' }
                OnSubmit = { props.SubmitMember}
                Content = { <DialogContent 
                                Users = { props.Users }
                                HandleOnChange={props.HandleOnChange}
                                FormFields={props.FormFields}
                            /> }
            />
            }
            {(props.PopupType === 'delete' &&  props.Open) &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Confirm Delete' }
                OnSubmit = { props.DeleteMember}
                ButtonName={'Delete'}
                Content = {"Do you really want to delete "+((props.SelectedUsers.indexOf('all')!==-1)?props.SelectedUsers.length-1:props.SelectedUsers.length)+" member(s) from this group?"}
            />}
            {(props.PopupType === 'edit' && props.Open)  && <GroupEditContainer
                Open = {props.Open}
                EditId = {props.GroupId}
                UpdateParentOnSuccess = {props.UpdateParentOnSuccess}
                OnClose={props.OnClose}
            />}
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }

        </GroupViewStyle>
    );
};
const DialogContent = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <FormControl variant="standard">
                        <Select
                            name='user' 
                            id='user'
                            value={_R.type(props.FormFields.user) !== 'Undefined'?props.FormFields.user:null}
                            onChange={props.HandleOnChange}
                        >
                            {_R.type(props.Users) !== 'Undefined' && props.Users.map((u, i) =>
                            <MenuItem value={u.user_id}>{formatName(u.first_name,null,u.last_name)}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                    <Checkbox
                                        checked={(typeof props.FormFields.manager !== 'undefined' && props.FormFields.manager) ? true : false}
                                        value={(typeof props.FormFields.manager !== 'undefined') ? props.FormFields.manager : null}
                                        id="manager"
                                        name="manager"
                                        onChange={props.HandleOnChange}
                                    />
                                }
                            label="Group Manager"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
