import React from 'react';
import { ActualEstimateReportComponent} from './ActualEstimateReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

export class ActualEstimateReportContainer extends React.Component{
    constructor(props) {
        super(props);
        let formFields = {
            client_id : 0,
            task_status : false,
            task_estimate : false,
            startDate: null,
            endDate :null,
            post : false,
        }
        this.state = {
            clients: [],
            formFields : formFields,
            permission : true,
            loader: true,
            resultSet : [],
            errors :{},
            projects :[],
            parentProject : null,
            project_id : 0,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
    }

    componentDidMount(){
        httpServices.post('api/report/actualEstimateVariance')
            .then(response => {
                if(response.data.permission){
                    this.setState({
                        clients : response.data.clients,
                        permission : response.data.permission,
                        loader: false,
                    });
                }
                else {
                    this.setState({ 
                        loader: false, 
                        permission: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name  === 'task_status') {
            f[name] = evt.target.checked;
        }
        else if(name === 'task_estimate') {
            f[name] = evt.target.checked;
        }
        else if (name === 'client_id') {
            let client_id = evt.target.value;
            f['project_id'] = 0;
            if (client_id != '') {
                httpServices.post('api/timesheet/getprojectsByClient', {'client_id':client_id})
                .then(response => {
                    if(response.data){
                        this.setState({projects : response.data.projects,
                                        loader : false,
                                        });
                    }
                    else {
                        this.setState({ loader: false });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
            } else {
                this.setState({projects : [], loader: false});
            }
            f[name] = evt.target.value;
            this.state.projects = [];
        }
        else {
            f[name] = evt.target.value;
        }
        this.setState({
            formFields: f
        });
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let projects = [];
        let formFields = this.state.formFields;
        if (sNodes.length < 1) {
            projects = 0;
        }
        else if (cNode.project_id) {
            projects = cNode.project_id;
        }
        formFields['project_id'] = projects;
        this.setState({ formFields: formFields });
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.formFields.client_id === 0) {
            errors["client_id"] = "Client cannot be empty";
            formIsValid = false;
        }
        if (this.state.projects.length === 0 || this.state.formFields.project_id === 0){
            errors["project_id"] = "Project cannot be empty";
            formIsValid = false;
        }
        this.setState({
            errors : errors
        });
        return formIsValid;
    }

    onSubmitClick =()=>{
        this.setState({
            loader : true,
        });
        if (this.handleValidation()) {
            let data = this.state.formFields;
            httpServices.post('api/report/actualEstimateVariance', data)
                .then(response => {
                    if (response.data.permission && !response.data.errors) {
                        this.setState({
                            resultSet: response.data.data_report,
                            parentProject: response.data.parentProjectName,
                            loader: false,
                            post: true,
                            permission: true,
                        });
                    } else if (response.data.errors) {
                        this.setState({
                            loader: false,
                            errors: response.data.errors,
                        });
                    }
                    else {
                        this.setState({
                            loader: false,
                            permission: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        else {
            this.setState({ loader: false });
        }
    }

    render(){
        return(<>
                <CustomProgressBar
                   loaderOpen={this.state.loader}
                />
                {(this.state.permission)?
                <ActualEstimateReportComponent
                    Clients = {this.state.clients}
                    HandleOnChange = {this.handleOnChange}
                    FormFields = {this.state.formFields}
                    Projects = {this.state.projects}
                    OnSubmitClick = {this.onSubmitClick}
                    Errors = {this.state.errors}
                    ResultSet = {this.state.resultSet}
                    ParentProject = {this.state.parentProject}
                    Post = {this.state.post}
                    HandleTreeChange = {this.handleTreeChange}
                />
                :
                <ErrorPage />}
                </>
            )
        }
}
