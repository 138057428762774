import { configureStore } from '@reduxjs/toolkit'
import systemConfig from './reducers/systemConfig'
import menuItems from './reducers/menuItems'
import Tabs from './reducers/tabs'
import savedFilters from './reducers/savedFilters'

export default configureStore({
    reducer: {
      systemConfig: systemConfig,
      menuItems: menuItems,
      Tabs: Tabs,
      savedFilters: savedFilters,
    }
  })