import React from 'react';
import {HolidayComponent} from './HolidayComponent';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';

export class HolidayContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            formFields: {},
            errors: {},
            hasPermission : true,
            dataLoaded : false,
            showLoader : true,
            startYear : '2008',
            holidays : [],
            open : false,
            action : '',
            year : new Date().getFullYear(),
            editId : 0,
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant : '',
            filterViewState : false,
            deletConfirm : false,
            deleteId :0,
            editPermission : false,
            maxYear : '',
            nameFormat : 'FIRSTLAST',
            right: false,
        }
        this.onAddHoliday = this.onAddHoliday.bind(this);
        this.onEditHoliday = this.onEditHoliday.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnYearChange = this.handleOnYearChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    componentDidMount()
    {
        this.getHolidaysList();        
    }

    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };

    getHolidaysList = () => {
        let year = this.state.year;
        let apiEndpoint = 'api/system/holiday/list';
        return httpServices.post(apiEndpoint,{year : year})
            .then(response => {
                if(response.data.permission) {
                    this.setState({hasPermission : true,
                        holidays : response.data.holidays,
                        editPermission : response.data.edit_permission,
                        maxYear : response.data.max_year
                    });
                }
                else {
                    this.setState({hasPermission : false, 
                        editPermission : response.data.edit_permission
                    });
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleOnYearChange = (evt, value) => {
        this.setState({
            year: evt.target.value
        });
    }

    handleSubmitFilter = () => {
        this.setState({right : false});
        this.getHolidaysList();
    }

    resetFilter = () => {
        this.setState({
            year: new Date().getFullYear(),
            right : false
        }, function () { 
            this.getHolidaysList();
        });
    }

    onAddHoliday = () => {
        this.setState({
            open: true,
            action : 'add'
        });
    }

    onEditHoliday = (id) => {
        this.setState({
            open: true,
            action : 'edit',
            editId : id
        },function () {
            this.getUpdateData(id);
        });

    }

    getUpdateData = (id) => {
        let apiEndpoint = 'api/system/holiday/list';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if(response.data.permission) {
                    let formFields = this.state.formFields;
                    formFields.date = response.data.selected_data.holiday_date;
                    formFields.name = response.data.selected_data.holiday_name;
                    formFields.description = response.data.selected_data.holiday_desc;
                    formFields.restricted_holiday = response.data.selected_data.restricted_holiday;
                    this.setState({hasPermission : true,
                        formFields : formFields,
                    });
                }
                else {
                    this.setState({hasPermission : false});
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onClose = () =>{
        let ff = this.state.formFields;
        ff['description'] = null;
        ff['name'] = null;
        ff['restricted_holiday'] = false;
        this.setState({
            open: false,
            errors:{},
            deleteConfirm : false,
            formFields : ff 
        });
        this.clearForm();
    }

    clearForm = () => {
        let ff = this.state.formFields;
        ff['description'] = null;
        ff['name'] = null;
        ff['restricted_holiday'] = false;
        this.setState({
            errors: {},
            errorMessage: false,
            formFields : {} 
        });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        if (field === 'description') {
            ff[field] = evt.target.value;
        }
        if (field === 'name') {
            ff[field] = evt.target.value;
        }
        if (field === 'restricted_holiday') { // Check box
            ff[field] = evt.target.checked;
        } 
        this.setState({
            formFields: ff
        });
    }

    onSubmit = (id = null) => {
        if(this.handleValidation()) {
            this.onSave(id);
        }
    }

    onSave = (id) => {
        let formFields = this.state.formFields;
        let apiEndpoint = 'api/system/holiday/save';
        return httpServices.post(apiEndpoint,{'id' : id, 'date' : formFields.date, 'name' :formFields.name, 'description': formFields.description, 'restricted_holiday' : formFields.restricted_holiday })
            .then(response => {
                if (response.data.permission) {
                    let message = '';
                    if (response.data.valid) {
                        if (id) {
                            message = 'Holiday updated successfully';
                        }
                        else {
                            message = 'Holiday added successfully';
                        }
                        this.setState({
                            open : false,
                            hasPermission: true,
                            snackbarOpen: true,
                            snackbarMsg: message,
                            snackbarVariant: 'success'
                        });
                        this.getHolidaysList();
                        this.clearForm();
                    }
                    else {
                        if (response.data.errors) {
                            this.setState({ errors: response.data.errors });
                        }
                        else {
                            if (id) {
                                message = 'Update failed, Holiday already exist';
                            }
                            else {
                                message = 'Add failed, Holiday already exist';
                            }
                            this.setState({
                                open : false,
                                hasPermission: true,
                                snackbarOpen: true,
                                snackbarMsg: message,
                                snackbarVariant: 'error'
                            });
                            this.clearForm();
                        }
                    }
                }
                else {
                    this.setState({ hasPermission: false });
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.date) {
            errors["date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.name || typeof this.state.formFields.name === 'undefined' || this.state.formFields.name.trim() === '') {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };

    onDelete = (id) => {
        this.setState({deleteConfirm : true, deleteId : id});
    }

    handleDelete =() => {
        this.setState({deleteConfirm : false});
        let apiEndpoint = 'api/system/holiday/delete';
        let id = this.state.deleteId;
        return httpServices.post(apiEndpoint,{id})
            .then(response => {
                if(response.data.permission) {
                    this.getHolidaysList();
                    this.setState({hasPermission : true,
                        snackbarOpen : true,
                        snackbarMsg : 'Holiday deleted successfully',
                        snackbarVariant : 'success'
                    });
                }
                else {
                    this.setState({hasPermission : false});
                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }


    render(){
        return(
            (this.state.hasPermission)
            ?
            <HolidayComponent
            StartYear = {this.state.startYear}
            FormFields = {this.state.formFields}
            Holidays = {this.state.holidays}
            AddHoliday = {this.onAddHoliday}
            Open = {this.state.open}
            Close = {this.onClose}
            Action = {this.state.action}
            HandleOnYearChange = {this.handleOnYearChange}
            OnSubmit = {this.onSubmit}
            HandleDateChange = {this.handleDateChange}
            Year = {this.state.year}
            HandleOnChange = {this.handleOnChange}
            Errors = {this.state.errors}
            EditHoliday = {this.onEditHoliday}
            EditId = {this.state.editId}
            DeleteHoliday = {this.onDelete}
            SnackbarOpen = {this.state.snackbarOpen}
            SnackbarMsg = {this.state.snackbarMsg}
            SnackbarVariant = {this.state.snackbarVariant}
            SnackbarClose = {this.snackbarClose}
            HandleDelete = {this.handleDelete}
            FilterView = {this.filterView}
            DeleteConfirm = {this.state.deleteConfirm}
            EditPermission = {this.state.editPermission}
            MaxYear  = {this.state.maxYear}
            NameFormat = {this.state.nameFormat}
            ToggleDrawer={this.toggleDrawer}
            Right={this.state.right}
            HandleSubmitFilter = {this.handleSubmitFilter}
            ResetFilter = {this.resetFilter}
            />
            :
            <ErrorPage/>
        )
    }
}
