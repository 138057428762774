import React from 'react';
import { WorkLocationComponent } from './WorkLocation.Component';
import { httpServices } from '../../../services/httpServices';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';

export class WorkLocationContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            title : 'Work Locations',
            workLocations  : [],
            editPermission : false,
            dataLoaded : false,
            popUpOpen : false,
            popUpAction : '',
            confirmationMessage :'',
            locationId : 0,
            projectLocations: [],
            formValues : {},
            errors : {}
        }
        this.setDefaultLocation = this.setDefaultLocation.bind(this);
        this.deleteWorkLocation = this.deleteWorkLocation.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.editWorkLocation = this.editWorkLocation.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }

    componentDidMount(){
        this.getworkLocationsDetails(this.props.ProjectId);
    }

    editWorkLocation = (loc_id, loc_name, defaultValue, timesheet) => {
        let f = this.state.formValues;
        f['project_location'] = loc_id;
        f['location_name'] = loc_name;
        f['default'] = defaultValue;
        f['timesheet'] = timesheet;
        this.setState({
            popUpOpen: true,
            popUpAction: 'edit',
            formValues: f,
            locationId: loc_id,
        });

    }
    getworkLocationsDetails = (projectId) => {
        let apiEndpoint = 'api/project/getProjectWorkLocations';
        return httpServices.post(apiEndpoint,{project_id : projectId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            projectLocations: response.data.project_locations,
                            showLoader : false,
                            permission : response.data.permission,
                            editPermission : response.data.edit_permission,
                            dataLoaded :true,
                            workLocations: response.data.work_locations,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded: true
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onClose = () => {
        this.setState({
            popUpOpen: false, 
            popUpAction: '', 
            locationId: 0 ,
            formValues : {},
            errors:{}
        });
    }

    onAdd = () => {
        this.setState({
            popUpOpen: true,
            popUpAction: 'add',
            locationId: 0
        });
    }

    handleOnChange = (evt, value) => {
        let f = this.state.formValues;
        const name = evt.target.name;
        if (name === 'default' || name === 'timesheet'){
            f[name] = evt.target.checked ? 1 : 0;   
        }else{
            f[name] = evt.target.value;
        }
        this.setState({
            formValues: f
        });
    };

    setDefaultLocation = (locationId, location_name) => {
        let msg = "Set '"+location_name+"' as default project location";
        this.setState({
            popUpOpen: true,
            popUpAction: 'set-default',
            confirmationMessage: msg,
            locationId: locationId 
        });
    }

    deleteWorkLocation = (locationId) => {
        let msg = 'Do you want to delete this location?';
        this.setState({
            popUpOpen: true,
            popUpAction: 'delete',
            confirmationMessage: msg,
            locationId: locationId
        });
    }

    onConfirm = () => {
        let projectId = this.props.ProjectId;
        let locationId = this.state.locationId;
        if (this.state.popUpAction === 'delete'){
            let apiEndpoint = 'api/project/deleteProjectLocation';
            return httpServices.post(apiEndpoint, { project_id: projectId, location_id: locationId })
                .then(response => {
                    if (response.data) {
                        if (response.data.permission) {
                            this.setState({
                                popUpOpen: false,
                                popUpAction: '',
                                locationId: 0
                            }, () => this.getworkLocationsDetails(projectId));
                            this.MixpanelManager.trackEvent('Project', {module:'Project',feature:'delete-location',action:'delete'});
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        if (this.state.popUpAction === 'set-default') {
            let apiEndpoint = 'api/project/saveProjectLocation';
            return httpServices.post(apiEndpoint, { project_id: projectId, location_id: locationId, action: "set-default" })
                .then(response => {
                    if (response.data) {
                        if (response.data.permission) {
                            this.setState({
                                popUpOpen: false,
                                popUpAction: '',
                                locationId: 0
                            }, () => this.getworkLocationsDetails(projectId));
                            this.MixpanelManager.trackEvent('Project', {module:'Project',feature:'defalut-location',action:'set-default'});
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        if (this.state.popUpAction === 'add') {
            let apiEndpoint = 'api/project/saveProjectLocation';
            if (this.validateFormData()){
                let project_location = this.state.formValues.project_location;
                let set_default = this.state.formValues.default;
                return httpServices.post(apiEndpoint, { project_id: projectId, location_id: project_location, set_default: set_default, action: "add" })
                    .then(response => {
                        if (response.data) {
                            if (response.data.permission) {
                                this.setState({
                                    popUpOpen: false,
                                    popUpAction: '',
                                    locationId: 0
                                }, () => this.getworkLocationsDetails(projectId));
                                this.MixpanelManager.trackEvent('Project', {module:'Project',feature:'save-location',action:'add'});
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        }
        if (this.state.popUpAction === 'edit') {
            let apiEndpoint = 'api/project/saveProjectLocation';
            if (this.validateFormData("edit")){
                let project_location = this.state.formValues.project_location;
                let set_default = this.state.formValues.default;
                return httpServices.post(apiEndpoint, {  project_id: projectId, location_id: locationId, set_default: set_default, action: "edit" , timesheet : this.state.formValues.timesheet })
                    .then(response => {
                        if (response.data) {
                            if (response.data.permission) {
                                this.setState({
                                    popUpOpen: false,
                                    popUpAction: '',
                                    locationId: 0
                                }, () => this.getworkLocationsDetails(projectId));
                                this.MixpanelManager.trackEvent('Project', {module:'Project',feature:'save-location',action:'edit'});
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        }
    }
    validateFormData = (action = '') => {
        let errors = {};
        let formValues = this.state.formValues;
        let valid = true;
        if (action == "edit")
        {
            if (formValues.default === 1)
             {
                if (formValues.timesheet == 0)
                {
                    errors['timesheet'] = 'Timesheet should be enabled for default location';
                    valid = false;
                }
            }
        }
        else if (formValues.project_location === 'undefined' || !formValues.project_location) {
            errors['project_location'] = 'Location cannot be empty';
            valid = false;
        }
        this.setState({
            errors: errors
        });
        return valid;
    }


    render(){
        return (
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <WorkLocationComponent 
                            WorkLocations = {this.state.workLocations}
                            ProjectLocations = {this.state.projectLocations}
                            EditPermission = {this.state.editPermission}
                            SetDefaultLocation = {this.setDefaultLocation}
                            DeleteWorkLocation = {this.deleteWorkLocation}
                            Open = {this.state.popUpOpen}
                            Action = {this.state.popUpAction}
                            ConfirmationMessage = {this.state.confirmationMessage}
                            OnClose = {this.onClose}
                            OnConfirm = {this.onConfirm}
                            OnAdd = {this.onAdd}
                            FormValues={this.state.formValues}
                            HandleOnChange = {this.handleOnChange}
                            Errors = {this.state.errors}
                            EditWorkLocation = {this.editWorkLocation}
                        />
                        :<></>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
