import React from "react";
import {withStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import { amber } from "@mui/material/colors";

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#000000',
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700]
        }
    }
}))(Button);

export const YellowButton =(props)=> {
    return (
        <ColorButton
            variant="contained"
            color="primary" 
            onClick={props.onClick}
        >
            {props.buttonName}
        </ColorButton>
    );
}
