import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

export const VoucherFilterComponent = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Year</InputLabel>
                        <Select
                            inputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                            value={(typeof props.FilterFields.year !== 'undefined') ? parseInt(props.FilterFields.year) : 0}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="0">All</MenuItem>
                            {typeof (props.Years) !== 'undefined' && (props.Years != null) && props.Years.map((t, i) =>
                                <MenuItem value={t.id} key={'c-' + i}>{t.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Month</InputLabel>
                        <Select
                            inputProps={{
                                name: 'month',
                                id: 'month',
                            }}
                            value={(typeof props.FilterFields.month !== 'undefined') ? props.FilterFields.month : 0}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="0">All</MenuItem>
                            {typeof (props.Months) !== 'undefined' && (props.Months != null) && props.Months.map((t, i) =>
                                <MenuItem value={i + 1} key={'c-' + i}>{t}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'report_status',
                                id: 'report_status',
                            }}
                            value={(typeof props.FilterFields.report_status !== 'undefined') ? props.FilterFields.report_status : 0}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="-1">To be submitted</MenuItem>
                            {typeof (props.ExpenseStatus) !== 'undefined' && (props.ExpenseStatus != null) && props.ExpenseStatus.map((t, i) =>
                                <MenuItem value={t.id} key={'c-' + i}>{t.status}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Employee</InputLabel>
                        <Select
                            inputProps={{
                                name: 'employee',
                                id: 'employee',
                            }}
                            value={(typeof props.FilterFields.employee !== 'undefined') ? props.FilterFields.employee : 0}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="0">All</MenuItem>
                            {typeof (props.Employees) !== 'undefined' && (props.Employees != null) && props.Employees.map((t, i) =>
                                <MenuItem value={t.user_id}>{(t.user_fullname) ? t.user_fullname : (t.user_first_name + ' ' + t.user_last_name)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Name Format</InputLabel>
                        <Select
                            inputProps={{
                                name: 'name_format',
                                id: 'name_format',
                            }}
                            value={(typeof props.FilterFields.name_format !== 'undefined') ? props.FilterFields.name_format : 'FIRSTLAST'}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="FIRSTLAST">Firstname Lastname</MenuItem>
                            <MenuItem value="LASTFIRST">Lastname, Firstname</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={props.HandleOnSubmit}> Submit </Button>
        </form>
    )
}
