import React from 'react';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import {MTableCell } from "@material-table/core";
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterList from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import * as _R from 'ramda';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PreliminaryReviewContainer } from './PreliminaryReview.Container'; 

const DrawerList = styled('div')({
    width: 250,
    padding: '20px',
  });
  
  const DrawerFormControl = styled(FormControl)({
    width: '100%',
  });
  
  const FilterSubmitButton = styled(Button)({
    marginTop: '20px',
  });
  
  const FilterResetButton = styled(Button)({
    marginTop: '20px',
    marginLeft: '10px',
  });  

//Filters
const SideList = (p) => {
    let years = (_R.type(p.FilterInputs.years) !== 'Undefined' && p.FilterInputs.years.length > 0) ? p.FilterInputs.years : [];
    let timesheetStatus = (_R.type(p.FilterInputs.timesheet_status) !== 'Undefined' && p.FilterInputs.timesheet_status.length > 0) ? p.FilterInputs.timesheet_status : [];
    let employeeStatus = (_R.type(p.FilterInputs.employee_status) !== 'Undefined' && p.FilterInputs.employee_status.length > 0) ? p.FilterInputs.employee_status : [];
    let users = (_R.type(p.FilterInputs.users) !== 'Undefined' && p.FilterInputs.users.length > 0) ? p.FilterInputs.users : [];
    let payperiods = (_R.type(p.FilterInputs.payperiods) !== 'Undefined' && p.FilterInputs.payperiods.length > 0) ? p.FilterInputs.payperiods : [];
    
    return (<DrawerList role="presentation">
            
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
        
        <DrawerFormControl variant='standard'>
            
            <InputLabel htmlFor="year">Year</InputLabel>
            <Select
                value={p.FilterFields.year}
                inputProps={{
                    name: 'year',
                    id: 'year',
                }}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value="0"><em>All</em></MenuItem>
            {years.map((year, i) =>
                <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
            )}
            </Select>
        </DrawerFormControl>

        <DrawerFormControl variant='standard'>
            <InputLabel htmlFor="payperiod">Pay Period</InputLabel>
            <Select
                value={p.FilterFields.pay_period}
                inputProps={{
                    name: 'pay_period',
                    id: 'pay_period',
                }}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value="0"><em>All</em></MenuItem>
            {_R.type(p.FilterInputs.payperiods) !== 'Undefined' &&
            payperiods.map((payperiod, i) =>
                <MenuItem value={payperiod.payperiod_sdate+' - '+payperiod.payperiod_edate} key={'payperiod'+i}>{payperiod.payperiod_sdate+'-'+payperiod.payperiod_edate}</MenuItem>
            )}
            </Select>
        </DrawerFormControl>


        <DrawerFormControl variant='standard'>
            <InputLabel htmlFor="status">Timesheet Status</InputLabel>
            <Select
                value={p.FilterFields.timesheet_status}
                inputProps={{
                    name: 'timesheet_status',
                    id: 'timesheet_status',
                }}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value="0"><em>All</em></MenuItem>
            <MenuItem value="-1">To be Reviewed</MenuItem>
            {timesheetStatus.map((tstatus, i) =>
                <MenuItem value={tstatus.timesheet_status_id} key={'status'+i}>{tstatus.timesheet_status_name}</MenuItem>
            )}
            </Select>
        </DrawerFormControl>

        <DrawerFormControl variant='standard'>
            <InputLabel htmlFor="employee status">Staff Status</InputLabel>
            <Select
                value={p.FilterFields.user_status}
                inputProps={{
                    name: 'user_status',
                    id: 'user_status',
                }}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value="0"><em>All</em></MenuItem>
            {employeeStatus.map((estatus, i) =>
                <MenuItem value={estatus.user_status_id} key={'user_status'+i}>{estatus.user_status_name}</MenuItem>
            )}
            </Select>
        </DrawerFormControl>

        <DrawerFormControl variant='standard'>
            <InputLabel htmlFor="users">Staff</InputLabel>
            <Select
                value={p.FilterFields.user}
                inputProps={{
                    name: 'user',
                    id: 'user',
                }}
                onChange = {p.HandleFilterChange}
            >
            <MenuItem value="0"><em>All</em></MenuItem>
            {users.map((user, i) =>
                <MenuItem value={user.user_id} key={'user_status'+i}>
                {user.first_name+' '+user.last_name}
                </MenuItem>
            )}
            </Select>
        </DrawerFormControl>
        <FilterSubmitButton variant="contained" color="primary" onClick={p.HandleFilter}>Filter</FilterSubmitButton>
        <FilterResetButton variant="outlined" color="primary"  onClick={p.ResetFilter} >Reset</FilterResetButton>
        </DrawerList>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            className="tab-panel"
            hidden={value !== index}
            {...other}
        >
            {children}
        </Typography>
    );
}

export const ReviewListComponent = (p) => {
    const reviewNowStatus = [2, 5, 6];
    let tabIndex = p.tabIndex;
    const columns = [
        { 
            title: 'Staff Name', 
            field: 'name',
            cellStyle: {
                color: '#0062ad'    
            }
        },
        { title: 'Pay Period', field: 'pay_peirod', render: rowData => <>{rowData.payperiod_sdate+' - '+rowData.payperiod_edate} </>, 
            customSort:(a,b) => sort(new Date(a.payperiod_sdate),new Date(b.payperiod_sdate))},
        { title: 'Timesheet Status', field: 'status_name' },
        { title: 'Submitted On', field: 'submitted_time',render: rowData => <>{(typeof rowData.is_timesheet_late !== 'undefined' && rowData.is_timesheet_late == '1') ? <span className={'error'}> {rowData.submitted_time}</span>:<>{rowData.submitted_time}</>} </>,customSort:(a,b) => sort(new Date(a.submitted_time),new Date(b.submitted_time))},
        { title: 'Reviewed By', field: 'reviewer_name' },
        { title: 'Reviewed On', field: 'approved_time', customSort:(a,b) => sort(new Date(a.approved_time),new Date(b.approved_time)) },
        { title: 'Action', field: 'action', render: rowData => <> {(rowData.review_type !== null && rowData.review_type === "Details") ? 'Details' : ((reviewNowStatus.includes(parseInt(rowData.status))) ? ((p.multipleReview === 'yes' && (rowData.review_type) && rowData.review_type !== null ) ? ('Review Now ('+ rowData.review_type+ ')') : 'Review Now') : 'Details')} </>}
    ];
    function sort(date1,date2) {
    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
    }
    let dataArr = (_R.type(p.list) !== 'Undefined' && p.list.length > 0) ?  p.list : [];
    let pageSize = 50;
    let pageSizeOptions = [50,100,150];
    if (dataArr.length < 10) {
        pageSize = 10;
        pageSizeOptions = [];
    } else if (dataArr.length > 10 &&  dataArr.length < 50) {
        pageSize = dataArr.length;
        pageSizeOptions = [];
    }
    return ( 
        <>
            <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                <Tabs
                    value={tabIndex}
                    onChange={p.HandleChange}
                    indicatorColor="primary"
                    aria-label="Location lists Tabs"
                >
                    {p.timesheetReviewPermission &&
                    <Tab label="Review Timesheets" onClick={() => p.HandleTabChange(0)} />
                    }
                    {p.prelimReviewPermission &&
                    <Tab label="Preliminary Review" onClick={() => p.HandleTabChange(1)} />
                    }
                </Tabs>
            </div>
            {p.timesheetReviewPermission &&
            <TabPanel value={tabIndex} index={0}>
                <CustomMaterialTable
                    columns={columns}
                    data={dataArr}
                    options={{
                        search: false,
                        actionsColumnIndex: 0,
                        pageSize: pageSize,
                        pageSizeOptions: pageSizeOptions,
                        thirdSortClick: false
                    }}
                    components={{
                        Toolbar: props => (
                            <div className={'mTable-toolbar-custom'}>
                                <CustomMTableToolbar {...props} />
                                <Tooltip title="Filter" aria-label="Filter">
                                    <IconButton onClick={p.ToggleDrawer('right', true)}><FilterList /></IconButton>
                                </Tooltip>
                            </div>
                        ),
                        Cell: props => (
                            <Tooltip title={(typeof props.rowData.is_timesheet_late !== 'undefined' && props.rowData.is_timesheet_late) ? "Late Submitted Timesheet" : ""}>
                                <MTableCell {...props} />
                            </Tooltip>
                        )
                    }}
                    onRowClick={(event, rowData) => p.HandleSubmitPage(rowData.id)}
                />
                <Drawer
                    anchor="right"
                    open={p.right}
                    onOpen={p.ToggleDrawer('right', true)}
                    onClose={p.ToggleDrawer('right', false)}>
                    <SideList
                        Right={'right'}
                        FilterInputs={p.filterInputs}
                        FilterFields={p.filterFields}
                        HandleFilterChange={p.HandleFilterChange}
                        HandleFilter={p.HandleFilter}
                        ResetFilter={p.ResetFilter}
                    />
                </Drawer>
            </TabPanel>
            }
            {p.prelimReviewPermission &&
                <TabPanel value={tabIndex} index={1}>
                    <PreliminaryReviewContainer
                    />
                </TabPanel>
            }
        </>
    )
};
