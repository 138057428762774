import React from 'react';
import {WorkHoursReportComponent} from './workHoursReport.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import * as validations from '../../common/validate';

const ReportFields = {
    'on_date' : '',
    'max_date': '',
}

export class WorkHoursReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            hasPermission : true,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.exportReports  = this.exportReports.bind(this);       
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
                ff[field] = evt.target.value;
                this.setState({
                                ReportFields: ff,
                            });                    
    };
    componentDidMount() {
        httpServices.post('api/report/work-hours-report')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        ReportFields : {
                            on_date : inputs.select_date,
                            staff_statuses : inputs.employee_status,
                            staff_status : inputs.status,
                        },
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
        window.scrollTo(0, 0);
    }
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'on_date', value: this.state.ReportFields.on_date, type: 'date', otherOptions: { required: true } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    render(){
        return(
            (this.state.hasPermission)
            ?
            <WorkHoursReportComponent
            HandleOnChange = { this.HandleOnChange }
            ReportFields = { this.state.ReportFields }
            Errors = { this.state.errors}
            ExportReports = {this.exportReports}
            />
            :<ErrorPage/>
        )
    }

}



