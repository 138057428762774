import React from 'react';
import { connect } from "react-redux";
import { httpServices } from '../../../services/httpServices';
import { AddExpenseComponent } from './AddExpense.Component';
import format from 'date-fns/format';
import { history } from './../../../helpers/history';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';
const BASE_PATH = process.env.REACT_APP_BASE_PATH;

const formFields = {
    'expense_date': new Date(),
    'expense_type': 1,
    'expense_category': 0,
    'category_name': '',
    'project': 0,
    'project_name': '',
    'expense_code': 0,
    'expense_code_name': '',
    'type': '',
    'desc': '',
    'amount': '',
    'id': 0,
    'mileage': 0,
    'attached_files': '',
    'exp_project_code': '',
    'exp_project_name': ''
};
const viewPageFields = {
    'expense_date': new Date(),
    'expense_type': 1,
    'expense_category': 0,
    'category_name': '',
    'project': 0,
    'project_name': '',
    'expense_code': 0,
    'expense_code_name': '',
    'type': '',
    'desc': '',
    'amount': '',
    'id': 0,
    'mileage': 0,
    'attached_files': '',
    'exp_project_code': '',
    'exp_project_name': ''
};
class AddExpenseContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: formFields,
            viewPageFields : viewPageFields,
            hasPermission: true,
            requests: [],
            open: false,
            editId: 0,
            viewId: null,
            viewClick: false,
            requestStatus: [],
            allRequestView: '',
            errors: {},
            requestView: false,
            valid: false,
            expenseData: [],
            expenseCodes: [],
            expenseCategories: [],
            expenseTypes: [],
            projectCodes: [],
            dialogOpen: false,
            uploadButtonName: 'Attach',
            uploadTitle: 'Select Recent Files',
            refreshList: false,
            selectedFile: [],
            attachments: [],
            recentFiles: [],
            attachFiles: [],
            imageOpen: false,
            imageId: '',
            attachedFileNames: '',
            permisionError: '',
            snackOpen: false,
            snackVariant: '', 
            snackMessage: '',
            joinedDate: '',
            relievedDate: '',
            report: [],
            reportId: '',
            editableStatus: [],
            uneditableStatus: [],
            focus: true,
            attachmentsDetails: [],
            currencySymbol :'',
            expenseMinAmount :'',
            openEdit:false ,
            showLoader : false,
            filetype:'image/*,application/pdf',
            expenseChangeHistory: [],
            expenseDeleteOpen : false,
            expenseDeleteId : '',
            expenseDeleteDate : '',
            uploadSize : '',
            hasPermission : true,
        }
        this.createExpenseClick = this.createExpenseClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.getExpenseList = this.getExpenseList.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.getExpenseCodes = this.getExpenseCodes.bind(this);
        this.getProjectCode = this.getProjectCode.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.cancel = this.cancel.bind(this);
        this.getRecentFiles = this.getRecentFiles.bind(this);
        this.handleAttachmentSelection = this.handleAttachmentSelection.bind(this);
        this.attachFiles = this.attachFiles.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
	this.handleExpenseDateChange = this.handleExpenseDateChange.bind(this);
        this.snakbarClose = this.snakbarClose.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.getIndex = this.getIndex.bind(this);
        this.deleteExpense = this.deleteExpense.bind(this);
        this.closeExpense = this.closeExpense.bind(this);
        this.findSubMenuRoute = this.findSubMenuRoute.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }

    componentDidMount = () => {
        this.getIndex();
    };
    componentDidUpdate(prevProps) {
        if ((Object.keys(prevProps.menuItems).length !== Object.keys(this.props.menuItems).length) || (prevProps.selectedMenuIndex !== this.props.selectedMenuIndex)) {
            if (!this.state.hasPermission) {
                this.findSubMenuRoute();
            }
        }
    }
    getIndex = () => {
        this.setState({showLoader :true});
        let apiEndpoint = 'api/expenses/index';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    let formFields = this.state.formFields;
                    formFields['uploadSize'] = response.data.max_size;
                    formFields.expense_date = new Date(response.data.expense_date);
                    this.setState({
                        filetype: response.data.attachment_file_type,
                        uploadSize : response.data.max_size,
                        expenseCodes : response.data.expense_codes,
                        expenseCategories : response.data.expense_categories,
                        expenseTypes : response.data.expense_types,
                        showLoader : false,
                        formFields : formFields
                    }, function() {
                        this.getProjectCode();
                        this.getExpenseList();
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                    }, function() {
                        this.findSubMenuRoute();
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    /*
     * Function for redirecting to the next sub menu item if the default
     * route of the expense emodule has no permission
     */
    findSubMenuRoute = () => {
        if (this.props.selectedMenuIndex !== -1 && this.props.menuItems) {
            let subMenu = this.props.menuItems[this.props.selectedMenuIndex]['sub_menu'];
            let matchingSubMeuFound = false;
            if (Object.keys(subMenu).length) {
                Object.keys(subMenu).forEach(function(childKey,ind) {
                    if (subMenu[childKey]['link'] && !matchingSubMeuFound) {
                        matchingSubMeuFound = true;
                        history.push(BASE_PATH+'/'+subMenu[childKey]['link']);
                    }
                });
            }
        }
    }
    createExpenseClick = () => {
        this.setState({
            expense_date: true,
            editId: 0,
        });
    }
    handleCancel = () => {
        this.setState({
            dialogOpen: false,
            imageOpen: false
        });
    }

    handleSubmit = (props) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            let expense_date = data.expense_date;
            if (typeof data.expense_date === 'object') {
                let dateobj = this.getStandardDates(data.expense_date);
                expense_date = dateobj.modified_date;
            }
            if (parseInt(data.expense_type) === 1) {
                data.mileage = 0;
            }
            props.preventDefault();
            let value = { 'expense_date': expense_date, 'project': data.project, 'expense_category': data.expense_category, 'expense_code': data.expense_code, 'expense_type': data.expense_type, 'amount': data.amount, 'desc': data.desc, 'id': data.id, 'mileage': data.mileage, 'attached_files': data.attached_files }
            httpServices.post('api/expenses/add', value)
                .then(response => {
                    if (response.data.permission) {
                        if (response.data.valid) {
                            let snackVariant = 'success';
                            let snackMessage = '';
                            if (data.id > 0) {
                                snackMessage = 'Expense edited successfully';
                            }
                            else {
                                snackMessage = 'Expense added successfully';
                            }
                            const f = this.state.formFields;
                            //f['expense_date'] = new Date();
                            f['expense_type'] = 1;
                            f['expense_category'] = 0;
                            f['project'] = 0;
                            f['expense_code'] = 0;
                            f['expense_code_name'] = '';
                            f['desc'] = '';
                            f['amount'] = '';
                            f['id'] = 0;
                            f['mileage'] = 0;
                            f['attached_files'] = '';

                            this.setState({
                                formFields: f,
                                refreshList: true,
                                attachments: [],
                                snackOpen: true,
                                snackVariant: snackVariant,
                                snackMessage: snackMessage,
                                attachedFileNames: '',
                                focus: true,
                                selectedFile: [],
                                openEdit: false
                            });
                            this.getExpenseList(expense_date);
                        }
                        else {
                            this.setState({
                                errors: response.data.errors
                            });                            
                        }
                    } 
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
                this.MixpanelManager.trackEvent('Expenses', {'module' : 'Expenses', 'feature' : 'expenses/add', 'action' : 'add_clicked'});
        }
        props.preventDefault();
    }

    handleUpload = (props) => {
        this.setState({
            open: true
        });
    }

    cancel = (props) => {
        this.setState({
            open: false
        });
    }

    cancelEdit = (props) => {
        const f = this.state.formFields;
        f['expense_date'] = new Date();
        f['expense_type'] = 1;
        f['expense_category'] = 0;
        f['project'] = 0;
        f['expense_code'] = 0;
        f['expense_code_name'] = '';
        f['desc'] = '';
        f['amount'] = '';
        f['id'] = 0;
        f['mileage'] = 0;
        f['attached_files'] = '';
        this.setState({
            formFields: f,
            attachedFileNames: '',
            openEdit: false,
            errors: [],
        });
    }

    attachFiles = (props) => {
        const f = this.state.formFields;
        f['attached_files'] = this.state.selectedFile.join();
        this.setState({
            formFields: f,
            open: false,
        });
        this.getFileNames();
    }

    getFileNames = () => {
        let apiEndpoint = 'api/expenses/getFileName';
        let value = { 'ids': this.state.selectedFile }
        return httpServices.post(apiEndpoint, value)
            .then(response => {
                this.setState({
                    attachedFileNames: response.data
                });
                
            })
            .catch(function (error) {
                alert('error');
                console.log("Error: " + error);
            });
    }

    getRecentFiles = (props) => {
        let apiEndpoint = 'api/expenses/listattachments';
        return httpServices.post(apiEndpoint, {})
            .then(response => {
                let response_data = response.data;
                this.setState({
                    recentFiles: response_data.attachments,
                    open: true
                });

            })
            .catch(function (error) {
                alert('error');
                console.log("Error: " + error);
            });
    }

    handleAttachmentSelection = (e) => {
        const options = this.state.selectedFile;
        let index;
        if (e.target.checked) {
            options.push(+e.target.value)
            this.setState({
                selectedFile: options,
            });
            this.handleUpload();
        } else {
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
            this.setState({
                selectedFile: options,
            });
            this.handleUpload();
        }
        options.sort()
    }

    fileUploadCallback = (acceptedFiles, preview) => {
        this.setState({
            showLoader: true,
        });
        httpServices.post('api/expenses/upload', acceptedFiles)
            .then(response => {
                    const options = this.state.selectedFile;
                    let errors = this.state.errors;
                    errors['amount'] = '';
                    this.setState({
                        errors: errors,
                    });
                    let ids = response.data.id.split(',');
                    var i;
                    for (i = 0; i < ids.length; i++) {
                        options.push(ids[i]);
                    }
                    this.attachFiles();
                    if(response.data.error.length) {
                         this.setState({
                            showLoader: false,
                            snackOpen: true,
                            snackVariant: 'error' ,
                            snackMessage: response.data.error,
                        });
                    }
                    else {
                        this.setState({
                            showLoader: false,
                        });
                    }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    itemClicked = (evt, value) => {
        const f = this.state.formFields;
        f['project'] = evt.target.value;
        this.setState({
            formFields: f
        });
    }
    handleOnChange = (evt, value) => {

        const f = this.state.formFields;
        const name = evt.target.name;
        let errors = this.state.errors;

        f[name] = evt.target.value;
        if (name === 'mileage' && !isNaN(evt.target.value)) {
            var amount = parseFloat(this.state.mileageRate) * parseFloat(evt.target.value);
            if (!isNaN(amount)) {
                f['amount'] = amount.toFixed(2);
            }
            else {
                f['amount'] = '';
            }
        }
        if (name === 'amount' && !isNaN(evt.target.value)) {
            if (parseFloat(this.state.formFields.amount) >= parseFloat(this.state.expenseMinAmount) && (parseInt(this.state.formFields.expense_type) === 1) && this.state.formFields.attached_files.length === 0) {
                errors['amount'] = "Receipts are required for all expenses "+this.state.currencySymbol+this.state.expenseMinAmount+" and above.";
            }
            else {
                errors['amount'] = '';
            }
        }
        this.setState({
            formFields: f,
            errors: errors,
        });
    };

    handleExpenseDateChange = (value, name) => {
        let dateobj = this.getStandardDates(value);
	let current_date = new Date();
	let errors = this.state.errors;
        if (dateobj.modified_date < this.getStandardDates(this.state.joinedDate).modified_date) {
            errors['expense_date'] = "Expense date should  be greater than joining date";
        }
        else if (this.state.relievedDate && dateobj.modified_date  > this.getStandardDates(this.state.relievedDate).modified_date) {
            errors["expense_date"] = "Expense date should not be greater than relieved date";
        }
        else if (dateobj.modified_date  > this.getStandardDates(current_date).modified_date) {
            errors["expense_date"] = "Expense entry not possible for future dates";
        }
        else
        {
             errors['expense_date'] = "";
        }
	const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f,
	    errors: errors,
        })
	this.getProjectCode();
        this.getExpenseList(value);
        this.getExpenseCodes();
    };

    getStandardDates = (date) => {
        let resObj = {
            modified_date: '',
            display_date: '',
        }
        let dateobj = new Date(date);
        let year = dateobj.getFullYear();
        let month = dateobj.getMonth() + 1;
        let tempMonth = dateobj.getMonth();
        if (month < 10) {
            month = '0' + month;
        }
        let cdate = dateobj.getDate();
        let tempDate = dateobj.getDate();
        if (cdate < 10) {
            cdate = '0' + cdate;
        }
        resObj['modified_date'] = year + '-' + month + '-' + cdate;
        resObj['display_date'] = new Date(year,tempMonth,tempDate);
        return resObj;
    }

    handleImageClick = (id) => {
        this.setState({
            imageOpen: true,
            imageId: id
        });
    }

    snakbarClose = (id) => {
        this.setState({
            snackOpen: false
        });
    }


    handleViewClick = (id) => {
        if (id) {
            let value = { 'id': id }
            return httpServices.post('api/expenses/view', value)
                .then(response => {
                    const f = this.state.viewPageFields;
                    f['expense_date'] = response.data.details.expense_date_formatted;
                    f['expense_type'] = response.data.details.type_id;
                    f['expense_category'] = response.data.details.expense_category;
                    f['category_name'] = response.data.details.category_name;
                    f['type'] = response.data.details.type;
                    f['mileage'] = response.data.details.mileage;
                    f['attached_files'] = response.data.attachment_ids;
                    f['project_name'] = response.data.details.project_name;
                    f['project'] = response.data.details.expense_project_code;
                    f['expense_code'] = response.data.details.expense_code;
                    f['expense_code_name'] = response.data.details.exp_code_name;
                    f['exp_code'] = response.data.details.exp_code;
                    f['desc'] = response.data.details.description;
                    f['amount'] = response.data.details.amount;
                    f['id'] = response.data.details.id;
                    f['exp_project_code'] = response.data.details.exp_project_code;
                    f['exp_project_name'] = response.data.details.exp_project_name;
                    f['attachment_min'] = response.data.details.attachment_min;
                    this.setState({
                        viewPageFields: f,
                        dialogOpen: true,
                        imageOpen: false,
                        expenseChangeHistory: response.data.changelogs,
                        open: false,
                        attachments: response.data.attachments
                    });
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

    handleDownloadClick = (id, props) => {
        window.location = "api/expenses/downloadImage";
    }

    handleEditClick = (id, props) => {
        if (id) {
            let value = { 'id': id }
            return httpServices.post('api/expenses/getexpensedetails', value)
                .then(response => {
                    const f = this.state.formFields;
                    let eDateArr = response.data.date.split('-');
                    f['expense_date'] = new Date(eDateArr[0],eDateArr[1]-1,eDateArr[2]);
                    f['expense_type'] = response.data.type_id;
                    f['expense_category'] = response.data.expense_category;
                    f['category_name'] = response.data.category_name;
                    f['type'] = response.data.type;
                    f['project'] = response.data.expense_project_code;
                    f['project_name'] = response.data.project_name;
                    f['expense_code'] = response.data.expense_code;
                    f['expense_code_name'] = response.data.exp_code_name;
                    f['desc'] = response.data.description;
                    f['amount'] = response.data.amount;
                    f['id'] = response.data.id;
                    f['mileage'] = response.data.mileage;
                    f['attached_files'] = response.data.attachment_fileids;
                    let attchment_fileids = response.data.attachment_fileids.toString().split(",");
                    var attchments = attchment_fileids.map(function (x) {
                        return parseInt(x, 10);
                    });
                    let current_ptree = document.getElementById("desc");
                    if (current_ptree) {
                        current_ptree.focus();
                    }
                    this.setState({
                        formFields: f,
                        attachments: [],
                        selectedFile: attchments,
                        attachedFileNames: response.data.attachment_file_names,
                        openEdit:true,
                        errors: []
                    },()=>{
                        this.getExpenseCodes();
                    });
                    this.MixpanelManager.trackEvent('Expenses', {'module' : 'Expenses', 'feature' : 'expenses/add', 'action' : 'edit_clicked'});
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

    deleteExpense = (id,sdate) => {
        this.setState({expenseDeleteOpen : true,
            expenseDeleteId : id,
            expenseDeleteDate : sdate,
        });
    }

    handleDeleteClick = () => {
        if (this.state.expenseDeleteId ) {
            let value = { 'id': this.state.expenseDeleteId}
            return httpServices.post('api/expenses/delete', value)
                .then(response => {
                    var snackVariant = 'success';
                    var snackMessage = 'Expense deleted successfully';
                    this.setState({
                        snackOpen: true,
                        snackVariant: snackVariant,
                        snackMessage: snackMessage,
                        expenseDeleteOpen : false,
                    });
                    this.getExpenseList(this.state.expenseDeleteDate);
                    this.clearForm();
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

     clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            if (key === 'expense_date') {
                ff[key] = new Date();
            }
            else if(key === 'expense_type') {
                ff[key] = 1;
            }
            else if(key === 'expense_category' || key === 'project' || key === 'expense_code' || key === 'id' || key === 'mileage') {
                ff[key] = 0;
            }
            else {
                ff[key] = '';
            }
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        const form_fields = this.state.formFields;
        if ((this.state.report) && (this.state.report.status) && (this.state.editableStatus) && !this.state.editableStatus.includes(parseInt(this.state.report.status))) {
            formIsValid = false;
            errors["payment"] = "Expense for this period is already " + this.state.report.status_name;
            this.setState({
                snackOpen: true,
                snackVariant: 'error',
                snackMessage: "Expense for this period is already " + this.state.report.status_name
            });
            return false;
        }
        if (!this.state.formFields.expense_date) {
            errors["expense_date"] = "Expense date cannot be empty";
            formIsValid = false;
        }
        else  {
            let current_date = new Date(); 
            let expense_date = '';
            if (typeof this.state.formFields.expense_date === 'object') {
                let dateobj = this.getStandardDates(this.state.formFields.expense_date);
                expense_date = dateobj.modified_date;
            }
            else
            {
                expense_date = this.state.formFields.expense_date;
            }
            if (expense_date < this.getStandardDates(this.state.joinedDate).modified_date) {
                errors["expense_date"] = "Expense date should  be greater than joining date";
                formIsValid = false;
            }
            else if (this.state.relievedDate && expense_date > this.getStandardDates(this.state.relievedDate).modified_date) {
                errors["expense_date"] = "Expense date should not be greater than relieved date";
                formIsValid = false;
            }
            else if (expense_date  > this.getStandardDates(current_date).modified_date) {
                errors["expense_date"] = "Expense entry not possible for future dates";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.expense_type) {
            errors["expense_type"] = "Expense type cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.expense_category) {
            errors["expense_category"] = "Expense category cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.expense_type === 1) {            
            form_fields['mileage'] = 0;
            this.setState({
                formFields: form_fields
            });
            if (!this.state.formFields.amount) {
                errors["amount"] = "Expense amount cannot be empty";
                formIsValid = false;
            }
            else if (this.state.formFields.amount <= 0 ) {
                errors["amount"] = "Expense amount should be greater than zero";
                formIsValid = false;
            }
            else if (isNaN(this.state.formFields.amount)) {
                errors["amount"] = "Please enter a valid amount";
                formIsValid = false;
            }
            else {
                if (parseFloat(this.state.formFields.amount) >= parseFloat(this.state.expenseMinAmount) && (parseInt(this.state.formFields.expense_type) === 1)) {
                    if (!this.state.formFields.attached_files) {
                        errors["amount"] = "Receipts are required for all expenses "+this.state.currencySymbol+this.state.expenseMinAmount+" and above.";
                        formIsValid = false;
                    }
                }
            }
        }
        else {
            if (this.state.formFields.mileage == 0 || this.state.formFields.mileage === null || !(this.state.formFields.mileage.toString().replace(/\s+/g, ''))) {
                errors["mileage"] = "Mileage cannot be empty";
                formIsValid = false;
            }
            else if (isNaN(this.state.formFields.mileage)) {
                errors["mileage"] = "Please enter a valid number";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.desc) {
            errors["desc"] = "Expense description cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    getExpenseList = (sdate, props) => {
        this.setState({showLoader :true});
        let apiEndpoint = 'api/expenses/list';
        return httpServices.post(apiEndpoint, { 'sdate': sdate })
            .then(response => {
                let response_data = response.data;
                if (response_data.permission === false) {
                    this.setState({
                        permisionError: "You are not authorized to do this action",
                        showLoader : false,
                    }, function () {
                        // Deprecated code after the implementation STEPS-3032 (Permission based menu)
                        /*if (response.data.action === 'review') {
                            history.push('review-list');
                        }*/
                    });
                }
                else {
                    let date_joined = this.getStandardDates(response_data.date_joined).display_date;
                    let relieved_date = response_data.relieved_date && this.getStandardDates(response_data.relieved_date).display_date;
                    if (response_data && Object.keys(response_data).length > 0) {
                        this.state.formFields.expense_date = response_data.expense_delete_date;
                        this.setState({ 
                            expenseData: response_data.saved_expenses,
                            expenseFrom: response_data.period.date_from,
                            expenseTo: response_data.period.date_to,
                            totalAmount: response_data.total_amount,
                            mileageRate: response_data.mileage_rate,
                            joinedDate: date_joined,
                            relievedDate: relieved_date,
                            report: response_data.report,
                            reportId: response_data.report_id,
                            editableStatus: response_data.editable_status,
                            uneditableStatus: response_data.uneditable_status,
                            currencySymbol : response_data.currency_symbol,
                            expenseMinAmount : response_data.expense_min_amount
                        });
                    }
                    this.setState({showLoader :false});
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    getExpenseCodes = (props) => {
        let expense_date = '';
        if (typeof this.state.formFields.expense_date === 'object') {
            let dateobj = this.getStandardDates(this.state.formFields.expense_date);
            expense_date = dateobj.modified_date;
        }
        else
        {
            expense_date = this.state.formFields.expense_date;
        }
        this.setState({showLoader :true});
        let apiEndpoint = 'api/expenses/listexpensecodes';
        return httpServices.post(apiEndpoint, {'expense_date' : expense_date})
            .then(response => {
                let response_data = response.data;
                this.setState({
                    expenseCodes: response_data,
                    showLoader : false,
                });
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    getProjectCode = (props) => { 
        let expense_date = '';
        if (typeof this.state.formFields.expense_date === 'object') {
            let dateobj = this.getStandardDates(this.state.formFields.expense_date);
            expense_date = dateobj.modified_date;
        }
        else
        {
            expense_date = this.state.formFields.expense_date;
        }
        this.setState({showLoader :true});
        let apiEndpoint = 'api/expenses/getProjectCode';
        return httpServices.post(apiEndpoint, {'expense_date' : expense_date})
            .then(response => {
                let response_data = response.data;
                this.setState({
                    projectCodes: response_data,
                    showLoader:false,
                });
            })
            .catch(function (error) {
                alert('error');
                console.log("Error: " + error);
            });
    }

    removeItem = (id, props) => {
        const options = this.state.selectedFile;
        const f = this.state.formFields;
        let index = options.findIndex(x => parseInt(x) == parseInt(id))
        if (index !== -1) {
        options.splice(index, 1);
        f['attached_files'] = options.join();
        this.setState({
            selectedFile: options,
            formFields: f
        });

        this.getFileNames();
        }
    }
    closeExpense = () => {
        this.setState({
            expenseDeleteOpen : false,
        });
    }

    render() {
        return (
	<>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            <AddExpenseComponent
                createExpenseClick={this.createExpenseClick}
                handleSubmit={this.handleSubmit}
                Open={this.state.open}
                dialogOpen={this.state.dialogOpen}
                imageOpen={this.state.imageOpen}
                handleOnChange={this.handleOnChange}
                handleEditClick={this.handleEditClick}
                handleViewClick={this.handleViewClick}
                handleDeleteClick={this.handleDeleteClick}
                handleCancel={this.handleCancel}
                handleUpload={this.handleUpload}
                fileUploadCallback={this.fileUploadCallback}
                handleAttachmentSelection={this.handleAttachmentSelection}
                cancel={this.cancel}
                getRecentFiles={this.getRecentFiles}
                attachFiles={this.attachFiles}
                handleDownloadClick={this.handleDownloadClick}
                handleImageClick={this.handleImageClick}
                HandleExpenseDateChange = {this.handleExpenseDateChange}
		formFields={this.state.formFields}
                viewPageFields={this.state.viewPageFields}
                errors={this.state.errors}
                valid={this.state.valid}
                expenseData={this.state.expenseData}
                expenseCodes={this.state.expenseCodes}
                expenseCategories={this.state.expenseCategories}
                expenseTypes={this.state.expenseTypes}
                projectCodes={this.state.projectCodes}
                uploadButtonName={this.state.uploadButtonName}
                uploadTitle={this.state.uploadTitle}
                refreshList={this.state.refreshList}
                selectedFile={this.state.selectedFile}
                expenseFrom={this.state.expenseFrom}
                expenseTo={this.state.expenseTo}
                attachments={this.state.attachments}
                totalAmount={this.state.totalAmount}
                recentFiles={this.state.recentFiles}
                mileageRate={this.state.mileageRate}
                imageId={this.state.imageId}
                attachedFileNames={this.state.attachedFileNames}
                permisionError={this.state.permisionError}
                snakbarClose={this.snakbarClose}
                snackMessage={this.state.snackMessage}
                snackOpen={this.state.snackOpen}
                snackVariant={this.state.snackVariant}
                cancelEdit={this.cancelEdit}
                joinedDate={this.state.joinedDate}
                relievedDate = {this.state.relievedDate}
                report={this.state.report}
                reportId={this.state.reportId}
                editableStatus={this.state.editableStatus}
                uneditableStatus={this.state.uneditableStatus}
                focus={this.state.focus}
                itemClicked={this.itemClicked}
                removeItem={this.removeItem}
                currencySymbol={this.state.currencySymbol}
                expenseMinAmount={this.state.expenseMinAmount}
                openEdit={this.state.openEdit}
                showLoader={this.state.showLoader}
                Filetype = {this.state.filetype}
                expenseChangeHistory = {this.state.expenseChangeHistory}
                ExpenseDeleteOpen={this.state.expenseDeleteOpen}
                DeleteExpense = {this.deleteExpense}
                CloseExpense ={this.closeExpense}
                UploadSize = {this.state.uploadSize}
            />
	</>
        )
    }
}

const mapStateToProps = (state) => {
    const menuItems = state.menuItems.menu ? state.menuItems.menu : {};
    const selectedMenuIndex = state.menuItems.selectedMenuIndex ? state.menuItems.selectedMenuIndex : -1;
    return {
        menuItems: menuItems,
        selectedMenuIndex: selectedMenuIndex,
    }
}
const connectedAddExpenseContainer = connect(mapStateToProps)(AddExpenseContainer);
export {connectedAddExpenseContainer as AddExpenseContainer};
