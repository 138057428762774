import React from 'react';
import {TimeoffConfigOverrideComponent} from './TimeoffConfigOverride.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb

const formFields = {
    'id' : 0,
    'user_id': null,
    'timeoff_type_id': '',
    'config': '',
    'value': null,
    'description': ''
}

export class TimeoffConfigOverrideDetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            errors: {},
            timeoff_types: {},
            timeoff_policy: [],
            open:false,
            isEdit: false,
            formInputs: {},
            Variant : 'success',
            snackbar : false,
        }          
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
        if (name === 'timeoff_type_id') {
            const formFields = this.state.formFields;
            formFields['config'] = '';
            formFields['value'] = '';
            formFields['description'] = '';
            this.setState({
                formFields: formFields
            });
            this.onTimeoffTypeChange(val);
        }
        if (name === 'config') {
            const formFields = this.state.formFields;
            formFields['description'] = '';
            this.setState({
                formFields: formFields
            });
            this.onConfigChange(val);
        }

    };

    handleValidation() {
        let formIsValid = false;
        let errors = {};
        let timeoff_type_id = this.state.formFields.timeoff_type_id;
        let config = this.state.formFields.config;
        let value = this.state.formFields.value;
        if (!timeoff_type_id) {
            errors['timeoff_type_id'] = "Cannot be empty";
        }
        if (!config) {
            errors['config'] = "Cannot be empty";
        }
        if (value === undefined || value === null || !value.trim()) {
            errors["value"] = "Cannot be empty";
        }
        else if (value && (isNaN(value) && !(config === 'advance_hours' && value ==='NOLIMIT'))) {
            errors["value"] = "Value is invalid";
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    saveTimeoffPolicy =() => {
        var len = this.state.timeoff_policy.length;
        if (len === 0) {
            if (_R.type(this.props.TimeoffPolicy) !== 'Undefined') {
                this.setState({timeoff_policy: this.props.TimeoffPolicy});
            }
        }
        if (this.handleValidation()) { 
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            const id = (this.state.id) ? this.state.id : 0
            data.staff_id = staffID;
            data.user_id = userID;
            return httpServices.post('api/timeoff/userTimeoffConfigOverride', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({
                                open:false,
                                timeoff_policy: response.data.timeoff_user_configs,
                                snackbar : true,
                                variant : 'success',
                                message : response.data.message,
                            });

                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors:response.data.errors});
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }

    onAdd = () => {
        return httpServices.post('api/timeoff/getTimeoffUserConfig')
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['timeoff_types'] = response.data.timeoff_types;
                    this.setState({
                        formInputs: finputs,
                        open: true,
                        id: 0,
                        isEdit: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            payperiod_id: null,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key]= null;
        }
        let finputs = this.state.formInputs;
        finputs['timeoff_types'] = [];
        finputs['config'] = [];
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            formInputs: finputs,
        });
    };

    onUpdate = (id) => {
        return httpServices.post('api/timeoff/getTimeoffUserConfig', {'id': id})
        .then(response => {
               if (response.data) {
                    let timeoff_type_id = response.data.timeoff_user_config.timeoff_type_id;
                    let config = response.data.timeoff_user_config.config;
                    this.onTimeoffTypeChange(timeoff_type_id);
                    let finputs = this.state.formInputs;
                    finputs['timeoff_types'] = response.data.timeoff_types;
                    this.setState({
                        formFields: response.data.timeoff_user_config,
                        formInputs: finputs,
                        open: true, 
                        id: id,
                        isEdit: true,
                    });
                    this.onConfigChange(config);
                }      
        })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onTimeoffTypeChange = (id) => {
        let apiEndpoint = 'api/timeoff/getOverrideItems';
        return httpServices.post(apiEndpoint, { 'timeoff_type_id': id })
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['config'] = response.data;
                    this.setState({
                        formInputs: finputs,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onConfigChange = (config) => {
        let apiEndpoint = 'api/timeoff/getConfigDetails';
        let timeoff_type_id = this.state.formFields.timeoff_type_id;
        return httpServices.post(apiEndpoint, { 'config': config, 'timeoff_type_id': timeoff_type_id})
            .then(response => {
                if (response.data) {
                    let fFields = this.state.formFields;
                    fFields['description'] = response.data;
                    this.setState({
                        formFields: fFields
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });

    }
    onDelete = (id) => {
        var len = this.state.timeoff_policy.length;
        if (len === 0) {
            if (_R.type(this.props.TimeoffPolicy) !== 'Undefined') {
                this.setState({timeoff_policy: this.props.TimeoffPolicy}, function(){
                    this.deleteTimeoff(id);
                });
            }
        }
        else {
            this.deleteTimeoff(id);
        }
    }
    deleteTimeoff = (id) => {
        const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        return httpServices.post('api/timeoff/deleteUserTimeoffConfig', {'id': id, 'staff_id': staffID, 'user_id': userID})
            .then(response => {
                if (response.data) {
                    this.setState({
                        snackbar : true,
                        variant : 'success',
                        message : response.data.message,
                        timeoff_policy : response.data.timeoff_user_config
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }

    handleDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };


    render(){
        return(
            <TimeoffConfigOverrideComponent
            TimeoffPolicy={this.state.timeoff_policy.length > 0 ? this.state.timeoff_policy : _R.type(this.props.TimeoffPolicy) !== 'Undefined' ? this.props.TimeoffPolicy: undefined}
            FormInputs={this.state.formInputs}
            SaveTimeoffPolicy={this.saveTimeoffPolicy.bind(this)}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields} 
            Open={this.state.open}
            Close={this.onClose.bind(this)}
            OnAdd={this.onAdd.bind(this)}
            OnDelete={this.onDelete.bind(this)}
            HandleUpdate={this.onUpdate.bind(this)}
            Errors={this.state.errors}
            IsEdit = {this.state.isEdit}
            Edit = {this.props.Edit}
            Delete = {this.props.Delete}
            Variant = {this.state.variant}
            Message = {this.state.message}
            Snackbar = {this.state.snackbar}
            SnackbarClose = {this.snackbarClose}
            HandleDateChange = {this.handleDateChange}
            EligibilityCriteriaGroups = {this.props.eligibilityCriteriaGroups}
            />
        )
    }
}
