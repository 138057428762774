export const formatPhone = (value) => {
    if(value){
        if (value.length===12) {
            return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}.${value.slice(8, 12)}`;
        }
        else if(value.length===13){
            return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}.${value.slice(9, 13)}`;
        }
        if (value.length===10) {
            return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 10)}`;
        }
        else{
            return value;
        }
    }
};

//File name formatting
export const formatFileName = (file_name, maxlength) => {
  var file_ext = file_name.substring(file_name.lastIndexOf('.')+1);
  if (file_name.length > maxlength) {
    file_name = file_name.substring(0, maxlength)+'...'+file_ext;
  }
    return file_name;
}

export const formatName = (first_name,middle_name,last_name,format = '') =>  {
    let name = '';
    if (first_name) {
        switch (format) {
            case 'FIRSTLAST' :               
            case 'LEGALFIRSTLAST' :
                name = last_name ? first_name+' '+ last_name : first_name;
                break;
            case 'LASTFIRST' :               
            case 'LEGALLASTFIRST' :
                name = last_name ? last_name+', '+ first_name : first_name;
                break;
            case 'FIRSTMIDDLELAST' :               
            case 'LEGALFIRSTMIDDLELAST' :
                name = first_name ? first_name : name;
                name = middle_name ? name+' '+middle_name : name;
                name = last_name ? name+' '+last_name : name;
                break;
            case 'LASTFIRSTMIDDLE' :               
            case 'LEGALLASTFIRSTMIDDLE' :
                name = last_name ? last_name : name;
                name = first_name ? name+', '+first_name : name;
                name = middle_name ? name+' '+middle_name : name;
                break;
            default :
                name = last_name ? first_name+' '+ last_name : first_name;
                break;
        }
    }
    return name;
}

export const nl2br = (str) => {
    if (str && str !== '') {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    return '';
}

export const formatAndSortNames = (items, format = [], sortOrder = 'ASC') =>  {
    let name_format = format[0];
    let columns = format[1];
    if (items) {
        items.forEach(function (item) {
            item['formatted_name']= formatName(item[columns['first_name']], null, item[columns['last_name']], name_format.format);
        })
        items.sort(compareItems(sortOrder));
    }
    return items;
}

function compareItems(order) {
    if (order == 'ASC') {
        return function(item1, item2) {
            if (item1['formatted_name'] > item2['formatted_name']) {
                return 1;
            } else if (item1['formatted_name'] < item2['formatted_name']) {
                return -1;
            }
            else { 
                return 0;
            }
        }
    }
    else {
        return function(item1, item2) {
            if (item1['formatted_name'] > item2['formatted_name']) {
                return -1;
            } else if (item1['formatted_name'] < item2['formatted_name']) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}

export const getRelativeUrl = () => {
    const BASE_PATH = process.env.REACT_APP_BASE_PATH;
    let url = window.location.href;
    let pathname = window.location.pathname;
    let relativeUrl = '';
    if (BASE_PATH && BASE_PATH !== '') {
        let pathArr = pathname.split(BASE_PATH); // Splitting the curent browser pathname by the base path for getting the actual route path
        relativeUrl = pathArr[1]; // Url portion after the base path
    } else {
        relativeUrl = pathname;
    }
    if (relativeUrl !== '') {
        relativeUrl = relativeUrl.replace(/^\/|\/$/g, '');
    }
    return relativeUrl;
}
