import React, { Component } from 'react';
import { connect } from "react-redux";
import { StaffConstants } from './../../constants';
import { history } from './../../helpers/history';
import { StaffList } from './staffList.component'
import { ErrorPage } from '../common/error';
import { httpServices } from '../../services/httpServices';
import { Tabs } from '../../redux/store/reducers/tabs';
import { CustomProgressBar } from '../common/CustomProgressBar';

let filterFields = {
    'status': null,
    'staff_types':null,
    'name_format':null,
}

class StaffListConatainer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            staffID : 0,
            userID: 0,
            right: false,
            filterFields: filterFields,
            users: [],
            displayEmployeeCode: false,
            statuses: [],
            permission: true,
            staffTypes: [],
            nameFormats: [],
            showLoader: true,
            dataLoaded: false,
        };
        this.viewDetail = this.viewDetail.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    componentDidMount() {
        this.fetchAllUsers();
        let lc_filter = localStorage.getItem('stafflist-filter');
        if (lc_filter) {
            lc_filter = JSON.parse(lc_filter);
            this.setState({
                filterFields: lc_filter
            },function(){
                this.handleFilter();
            });
        } else {
            // Default filter options
            this.setState({
                filterFields : {
                    'status': 1,
                    'staff_types':0,
                    'name_format':'FIRSTLAST',
                }
            });
        }
    }

    fetchAllUsers() {
        let lc_filter = localStorage.getItem('stafflist-filter');
        if (!lc_filter) {
            let apiEndpoint = StaffConstants.API_STAFF_LIST;
            return httpServices.get(apiEndpoint)
                .then(response => {
                    if (response.data) {
                        if (response.data.permission) {
                            this.setState({
                                users: response.data.users,
                                displayEmployeeCode: response.data.display_employee_code,
                                statuses: response.data.statuses,
                                permission: response.data.permission,
                                staffTypes: response.data.staff_types,
                                nameFormats: response.data.name_format,
                                showLoader: false,
                                dataLoaded: true,
                            });
                        }
                        else {
                            this.setState({
                                permission: response.data.permission,
                                showLoader: false,
                                dataLoaded: true,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }

    viewDetail  = (userID, staffID) =>{
        this.setState({
            userID: userID,
            staffID: staffID
        });
        const { dispatch } = this.props;
        dispatch(Tabs.actions.setSelectedTabIndex({page : "staff-view", selected : 0}));
        history.push(StaffConstants.STAFF_VIEW_PAGE + '/' + userID);
    }

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        localStorage.setItem('stafflist-filter', JSON.stringify(f));
        this.setState({
            filterFields: f
        });
    }

    fetchStaffByFilters =  (data) => {
            let apiEndpoint = StaffConstants.API_STAFF_LIST;
            this.setState({
                showLoader: true,
            });
            return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    this.setState({
                        users: response.data.users,
                        displayEmployeeCode: response.data.display_employee_code,
                        statuses:  response.data.statuses,
                        permission: response.data.permission, 
                        staffTypes: response.data.staff_types,
                        nameFormats: response.data.name_format,
                        showLoader: false,
                        dataLoaded: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: " + error);
            });
    }

    handleFilter = () => {
        const data = this.state.filterFields;
        if (data) {
            this.fetchStaffByFilters(data);
            this.setState({right: false});
        }
    }
    clearForm = () => {
        const ff = this.state.filterFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            filterFields: ff
        });
    };

    render() {
        const { classes } = this.props;
        const { users, permission } = this.state;
        return (
            <>
                <CustomProgressBar loaderOpen={this.state.showLoader} />
                {(this.state.dataLoaded) ?
                    <>
                        {(permission)
                        ?
                        <StaffList
                            tableData={users}
                            classes={classes}
                            handleViewDetail={this.viewDetail}
                            toggleDrawer={this.toggleDrawer}
                            DisplayStatus={this.state.displayEmployeeCode}
                            Right={this.state.right}
                            Statuses={this.state.statuses}
                            staff_types={this.state.staffTypes}
                            NameFormat={this.state.nameFormats}
                            FilterFields={this.state.filterFields}
                            HandleFilterChange={this.handleFilterChange.bind(this)}
                            HandleFilter={this.handleFilter.bind(this)}
                        />
                        :
                        <ErrorPage />
                        }
                    </>
                    :
                    <></>}
            </>
        )
    }
}

const connectedStaffPage = connect()(StaffListConatainer);
export { connectedStaffPage as StaffList };
