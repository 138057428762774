import React from 'react';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import CachedIcon from '@mui/icons-material/Cached';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import parse from 'html-react-parser';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import * as _ls from '../../../common/ls';
import {httpServices}  from '../../../../services/httpServices'
import {TimeEntryRowComponent} from './TimeEntryRowComponent';
import * as _v from '../ValidateTimeEntry';
import { DialogBoxMedium } from '../../../common/dialog.medium';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { CustomProgressBar } from '../../../common/CustomProgressBar';
import MixpanelManager from  '../../../../services/Analytics';

let successMsg = '';
let isFirst = true;
let confirmReason = '';
let tempTimeEntryData = null;
let saveForm = false;
let tempTimesliceID = false;
let tempTagsList = [];
let tempDBTags = [];
let tempIndexID = false;
const index = 0;
let component_update_count = 1;
let reRenderFlag = false;

export class TimeEntryRow extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            userID:false,
            rowData:[],
            clientProjects:[],
            isFirst:true,
            selectedDate: props.SelectedDate,
            time_inout:false,
            buttonLoader : false,
            currentDate:null,
            selectedDateObj:new Date(),
            displayDate:null,
            minDate:new Date(),
            formInputs:[],
            tempAddedTagsJson:[],
            deletedDbTags : [],
            time_inout_total:0,
            multiple_review: null,
            approved_projects: [],
            timesheet_id: false,
            dayTotal: null,
            editedIndex: null,
            tempAddedTagsLabel:[],
            enableDelete: true,
            rowKey:index,
            dialogOpen : false,
            popupType : '',
            dialogButton: null,
            timesheetList: [],
            errors:{},
            validation : [],
            snackbarOpen:false,
            reuseInputValues:false,
            full_path_tasks : [],
            hours_details : [],
            snackbarVariant : 'success',
            taskTreeViewState : false,
            timesliceReqErrors : {},
            showLoader : false,
            selectedLocation : '',
            addLunchBreak : false,
            breakTask : [],
            breakTime : '',
            disableSaveButton : false,
            reused_date : null,
            copied_date : null,
            work_location_change: false,
            wlc_projects: [],
            wlcRequest : false,
            requested_locations : [],
            default_location : 0,
            selected_project_locations : [],
            timesheetId: null,
            isApproved :false,
        }
        this.getTimesheetByDate = this.getTimesheetByDate.bind(this);
        this.handleProjectOnChange = this.handleProjectOnChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.onTagTempDelete = this.onTagTempDelete.bind(this);
        this.onAddTagIconClicked = this.onAddTagIconClicked.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.reload = this.reload.bind(this);
        this.handleKeepValueChange = this.handleKeepValueChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onEditConfirmed = this.onEditConfirmed.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onTimeEntryDeleteConfirm = this.onTimeEntryDeleteConfirm.bind(this);
        this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
        this.deleteUnsavedData = this.deleteUnsavedData.bind(this);
        this.setUnsavedFormData = this.setUnsavedFormData.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        this.handleTagInputChange = this.handleTagInputChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleTaskOnChange = this.handleTaskOnChange.bind(this);
        this.handleTaskTreeChange = this.handleTaskTreeChange.bind(this);
        this.toggleTreeView = this.toggleTreeView.bind(this);
        this.copyTimeEntries = this.copyTimeEntries.bind(this);
        this.deleteTimeEntryStateRowByIndex = this.deleteTimeEntryStateRowByIndex.bind(this);
        this.appendTimeEntryRow = this.appendTimeEntryRow.bind(this);
        this.onAddButtonClick = this.onAddButtonClick.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.getLockedDetails = this.getLockedDetails.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.SelectedDate !== this.props.SelectedDate) {
            this.setState({reused_date: prevProps.SelectedDate});
            this.setState({selectedDate:this.props.SelectedDate}, function(){
                this.getTimesheetByDate();
                if (component_update_count === 1) { // No need to execute on every update
                    if(_ls.is_ls_enabled()){
                        this.initUserChargeCodes(this.props.SelectedDate);
                    }
                    component_update_count += 1;
                }
            });
        } else if (this.props.ForceRerender) {
            reRenderFlag = true;
            this.getTimesheetByDate();
            this.props.UpdateRenderFlag();
        }
        if (( this.props.DeletedTsIdFromParent !== 0 && prevProps.DeletedTsIdFromParent !== this.props.DeletedTsIdFromParent)) {
            tempTimesliceID = this.props.DeletedTsIdFromParent;
            this.setState({
                dialogOpen : true,
                dialogButton : 'Ok',
                popupType : 'time-entry-delete',
            });
        }
        if (this.props.CopyTimeEntryFlag && prevProps.CopyTimeEntryFlag !== this.props.CopyTimeEntryFlag) {
            this.copyTimeEntries(this.props.CopiedDate);
            this.props.UpdateCopyTimeEntryFlag();
        }
        if (this.props.SelectedLocation !== undefined && prevProps.SelectedLocation !== this.props.SelectedLocation) {
            this.setState({ selectedLocation : this.props.SelectedLocation });
        } 
        if (this.props.AddLunchBreak !== undefined && prevProps.AddLunchBreak !== this.props.AddLunchBreak) {
            this.setState({ addLunchBreak : this.props.AddLunchBreak });
        }
        if ((this.props.AddLunchBreak !== undefined && prevProps.AddLunchBreak !== this.props.AddLunchBreak) || (this.props.SelectedLocation !== undefined && prevProps.SelectedLocation !== this.props.SelectedLocation) || (this.props.InOutDate !== undefined && prevProps.InOutDate !== this.props.InOutDate && this.props.AddLunchBreak)) {
            this.setState({ addLunchBreak : this.props.AddLunchBreak }); 
            let filteredTask = this.state.activeTasks.find(t => t.task_id === this.props.AddLunchBreak);
            let breakTask = (filteredTask !== undefined) ? filteredTask : [];
            let filteredRowData = (this.state.rowData.length) ? this.state.rowData.find(t => (t !== undefined && t.timeslice_task !== undefined && t.timeslice_task === this.props.AddLunchBreak)) : [];
            let appendBreak = (filteredRowData !== undefined) ? false : true;
            if(this.props.AddLunchBreak && (breakTask.task_id !== undefined && breakTask.task_id > 0) && appendBreak){
                isFirst = (this.state.rowData.length) ? false : true; 
                breakTask['location'] = this.props.SelectedLocation; 
                breakTask['break_time'] = this.state.breakTime;
                this.setState({
                    breakTask : breakTask            
                });
                this.appendBreakRow(breakTask)
            }            
        }
    }
    // Initialize the user charge codes
    initUserChargeCodes = (todayDate) => {
        let chargecodes = _ls.ls_get_item_chargecodes();
        let date = this.state.selectedDate;
        if (chargecodes===null ) {
            (async () => {
                const response = await httpServices.post('api/timesheet/get-assigned-charge-codes', {date:date});
                if (response.data) {
                    let dataObj = response.data;
                    _ls.ls_set_instance_id(this.props.InstanceID);
                    _ls.ls_set_item_chargecodes(dataObj);
                    _ls.validateProjects(todayDate);
                    this.getTimesheetByDate();
                    return true;
                }
            })();
        }
        else {
            this.getTimesheetByDate();
        }
    };
    getTimesheetByDate = (date=false) => {
        this.setState({ showLoader: true });
        let data = {
            date: date || this.state.selectedDate,
        };
        data.reuse_input  = this.state.reuseInputValues;
        let newRow = true;
        tempDBTags = [];
        let projects = _ls.validateProjects(data.date);
        return httpServices.post('api/timesheet/get-timesheet-by-date', data)
            .then(response => {
                if (response.data) {   
                    let rowData = [];
                    let jsonStrData = JSON.stringify(response.data);
                    let data = response.data;
                    let timeEntryRows = data.timeentry;
                    let sum = 0;
                    if (this.state.reuseInputValues) {
                        //if already have time entry, removed previous
                        if(timeEntryRows.length>0) {
                            rowData = [];
                        }
                        else {
                            let tempData = this.state.rowData;
                            let formInputs = this.state.formInputs;
                            //set id to null
                            if (tempData.length) {
                                let active_tasks = (response.data.task_inputs && response.data.task_inputs.length) ? response.data.task_inputs : [];
                                tempData.map(function(obj, idx){
                                    let reset_task = false;
                                    if (active_tasks.length) {
                                        let task_index = active_tasks.findIndex(o => o.task_id === tempData[idx]['task_'+idx]);
                                        if (task_index === -1) {
                                            reset_task = true;
                                        }
                                    } else {
                                        reset_task = true;
                                    }
                                    newRow = false;
                                    tempData[idx]['timeslice_id'] = null;                                    
                                    if (reset_task) {
                                        tempData[idx]['project_'+idx] = null;
                                        tempData[idx]['project_path_display'] = null;
                                        tempData[idx]['task_'+idx] = null;
                                        tempData[idx]['task_name_'+idx] = '';
                                        tempData[idx]['client_timesheet'] = 0;
                                    }
                                    let work_location_change = false;
                                    if(data.work_location_change.request && data.work_location_change.projects && data.work_location_change.projects.length)
                                    {
                                        let project_id = (obj.project_id && !reset_task) ? obj.project_id : null;
                                        work_location_change = data.work_location_change.projects.includes(project_id);
                                    }
                                    tempData[idx]['work_location_change_allowed'+idx] = work_location_change;
                                    tempData[idx]['work_location_change_'+idx] = work_location_change ? 1: 0;  
                                    let selected_project_locations = [];
                                    let locations = data.work_location_change.locations;
                                    if (locations) {
                                        locations.forEach((lc_value, lc_key) => {
                                            if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(tempData[idx]['project_' + idx])) {
                                                selected_project_locations.push(lc_value);
                                            }
                                        });
                                        tempData[idx]['selected_project_locations_' + idx] = selected_project_locations;
                                    }
                                    if (!selected_project_locations.filter(l => l.location_id == tempData[idx]['location_' + idx]).length) {
                                        tempData[idx]['location_' + idx] = null;
                                    }
                                    // calculate total hours
                                    if( tempData[idx]['hour_'+idx] !== null && !isNaN(tempData[idx]['hour_'+idx]) && parseFloat(tempData[idx]['hour_'+idx])!==0 ) {
                                        sum += parseFloat(tempData[idx]['hour_'+idx]);
                                    }
				    tempData[idx]['locked'] = false;
                                });

                                this.setState({
                                    formInputs: formInputs,
                                    timesheetId: response.data.timesheet_id
                                });
                            }
                            rowData = tempData;
                            //isEdited = false;
                            saveForm =true;
                        }
                        let temp_tags = this.state.tempAddedTagsJson;
                        temp_tags.forEach(function(tt){
                            if (tt) {
                                tt.forEach(function(tchild) {
                                    let rand = Math.floor(1000 + Math.random() * 9000);
                                    tchild.id = 'new_'+rand;
                                });
                            }
                        });
                        // Reuse input values [END].
                    }
                    else {
                        saveForm = false;
                        if (date) {
                            this.setState({
                                tempAddedTagsJson : [],
                            });
                        }
                    } 
                    let location = '';
                    response.data.timeinout_details.forEach(function (item, index) {
                        location = ((location == item['location']) || (index == 0)) ? item['location'] : '';
                      });
                      Object.values(timeEntryRows).forEach(function(obj, idx){
                        //tags
                        if (_R.type(obj.tags)!=='Undefined') {
                            Object.values(obj.tags).forEach(function(tObj, tIdx){
                                if (!tempDBTags.includes(tObj.tag_ids)) {
                                    tempDBTags.push(tObj.tag_ids);
                                }
                            });
                        }
                        //calculate total hours
                        if(!isNaN(obj.timeslice_hours)) {
                            sum += parseFloat(obj.timeslice_hours);
                        }
                    });
                    sum = (timeEntryRows.length && sum >= 0) ? parseFloat(sum).toFixed(2) : ((this.state.dayTotal !== undefined && this.state.dayTotal !== null && parseFloat(this.state.dayTotal) > 0) ? parseFloat(this.state.dayTotal).toFixed(2) : parseFloat(sum).toFixed(2));
                    this.setState({
                        rowData: rowData,
                        deletedDbTags : [],
                        time_inout_total : response.data.time_inout_hours,
                        full_path_tasks : response.data.task_inputs,
                        hours_details : response.data.hours_summary,
                        userID : response.data.user_id,
                        timesliceReqErrors : {}, 
                        selectedLocation : location,
                        breakTime : response.data.break_time,
                        activeTasks :response.data.task_inputs,
                        addLunchBreak : data.show_lunch_break,
                        wlcRequest : response.data.work_location_change.request, 
                        disableSaveButton : this.props.DisableSaveButton ? true :false,
                        default_location : response.data.default_location,
                        isApproved: response.data.is_approved,
                        dayTotal: sum,

                        work_location_change: data.work_location_change,
                        requested_locations: data.work_location_change.locations,
                        selected_project_locations: data.work_location_change.locations,
                        wlc_projects: data.work_location_change.projects,
                        multiple_review: data.multiple_review,
                        approved_projects: data.approved_projects,
                        timesheet_id: data.timesheet_id,
                        editedIndex: null,
                        clientProjects: projects,
                        tempAddedTagsLabel: [],
                        showLoader : false,
                        errors : {},
                    }, () => {
                        if (timeEntryRows.length) {
                            isFirst = false;
                            let wlc_info = {
                                'request': data.work_location_change.request,
                                'projects': data.work_location_change.projects,
                                'default_location' : data.work_location_change.default_location,
    
                            };
                            this.SetTimeEntryData(timeEntryRows,wlc_info, data.work_location_change.locations);
                           
                        }
                        else if (response.data.show_lunch_break && !saveForm) {
                            let filteredBreakTask = response.data.task_inputs.filter(function(value){
                                return response.data.show_lunch_break==value.task_id;
                            });
                            let breakTask = (filteredBreakTask[0] !== undefined) ? filteredBreakTask[0] : [];
                            if ((breakTask['task_id'] !== undefined && breakTask['task_id'] > 0)) {
                                isFirst = true; 
                                breakTask['location'] = this.state.selectedLocation;
                                breakTask['break_time'] = response.data.break_time;
                                this.setState({
                                    addLunchBreak : data.show_lunch_break,
                                    breakTask : breakTask,
                                }, () => {
                                    this.appendBreakRow(breakTask, data.work_location_change)
                                });
                            }
                        }
                        else {
                            if (!saveForm) {
                                isFirst = true;
                                this.appendTimeEntryRow();
                            }
                        }
                    });                   
                   
                    if(_ls.is_ls_enabled()) {
                        this.loadLSTimeEntries(JSON.parse(jsonStrData), newRow, sum)
                    }
                }
            })
            .catch(function(error) {
                this.setState({showLoader : false});
            });
    }
    /**
    * Set saved/LS stored form data into time entry page
    **/
    SetTimeEntryData = (obj_data, wlc_info = false, locations=false) => {
        let errors = {};
        let minimumIncrement = this.props.MinimumInc;
        let formInputs = this.state.formInputs;
        let rowData = this.state.rowData;
        let selDate = this.state.selectedDate;
        let temp_added_tags = [];
        let count = 0;
        let wlc_request = false;
        let wlc_projects = [];
        if (wlc_info) {
            wlc_request = wlc_info['request'];
            wlc_projects = wlc_info['projects'];
        }
        if (_R.type(obj_data) !== 'Undefined') {
            Object.values(obj_data).forEach(function(obj, idx){
                if (_R.type(formInputs.tasks) === 'Undefined') {
                    formInputs.tasks = [];
                }
                formInputs.tasks[idx] = _ls.ls_get_tasks(obj.project_id, selDate);
                let rowFields = obj;
                // setting work location data of each time entry to the time entry array
                let selected_project_locations = [];
                if (locations) {
                    locations.forEach((lc_value, lc_key) => {
                        if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(obj.project_id)) {
                            selected_project_locations.push(lc_value);
                        }
                    });
                    rowFields['selected_project_locations_' + idx] = selected_project_locations;
                }
                rowFields['client_'+idx] = obj.client_id;
                rowFields['project_'+idx] = obj.project_id;
                rowFields['task_'+idx] = obj.timeslice_task;
                rowFields['task_name_'+idx] = obj.task_name;
                rowFields['task_code_'+idx] = obj.code;
                rowFields['enable_location_'+idx] = obj.enable_location;
                if (obj.location && selected_project_locations.filter(l => l.location_id == obj.location).length) {
                    rowFields['location_'+idx] = obj.location;
                }else{
                    rowFields['location_' + idx] = null;
                }
                rowFields['desc_'+idx] = obj.timeslice_log;
                rowFields['hour_'+idx] = obj.timeslice_hours;
                rowFields['rowKey_'+idx]= idx;
                rowFields['wlc_'+idx]= obj.location;
                rowFields['minimum_inc_'+idx]= obj.minimum_increment;
                rowFields['client_txt_'+idx]= '';
                rowFields['tag_type_'+idx]= obj.tag_type_id;
                rowFields['tags_'+idx]= obj.tags;
                rowFields['task_description_'+idx]= obj.task_description;
                if (obj.project_path_display) {
                    rowFields['project_path_display'] = obj.project_path_display;
                } else {
                    rowFields['project_path_display'] = obj.client_name+" / "+obj.project_path+" / "+obj.task_name;
                }
                if (obj.timeslice_hours){
                    let params = {
                        'val': obj.timeslice_hours,
                        'minimum_inc': minimumIncrement,
                        'project_id': (obj.project_id) ? obj.project_id :null,
                        'project_minimum_inc': (_R.type(obj.minimum_increment) !== 'Undefined' && (parseFloat(obj.minimum_increment) || obj.minimum_increment === 0)) ? obj.minimum_increment : null,
                        }
                    let isValid = _v.validateHour(params);
                    if (!isValid.status) {
                        errors['hour_'+idx] = isValid.msg;
                    }
                }
                if (wlc_info) {
                    let location = false;
                    if (wlc_request)
                    {
                        let project_id = obj.project_id ? obj.project_id : null;
                        location = wlc_projects.includes(project_id);
                    }
                    rowFields['wlc_allowed'+idx] = location;
                }
                rowFields['work_location_allowed_' + idx] = obj.location_ids;
                let tag_arr = [];
                if (obj.tags) {
                    obj.tags.forEach(function(t){
                        let tobj = {};
                        tobj.id = t.id;
                        tobj['tag_name'] = t.name;
                        let temp_tag_ids = [];
                        let t_ids = t.tag_ids;
                        let t_ids_arr = [];
                        if (t_ids) {
                            t_ids_arr = t_ids.split("-");
                        }
                        if (t_ids_arr && t_ids_arr.length > 0) {
                            t_ids_arr.forEach(function(id){
                                temp_tag_ids.push(id);
                                tobj['tag_ids'] = temp_tag_ids;
                            })
                        }
                        tag_arr.push(tobj);
                    })
                }
                rowFields['client_timesheet'] = obj.client_timesheet;
                //rowFields['project_path_display'] = obj.client_name+" -> "+obj.project_path;
                if (rowFields['code'] === 'TO' && parseInt(rowFields['editable']) === 0) {
                    rowFields['show_edit_button'] = false;
                }
                else {
                    rowFields['show_edit_button'] = true;
                }
                rowFields['index'] = idx;
                temp_added_tags.push(tag_arr);
                rowData.push(rowFields);
                count=idx;
            });
        }
        this.setState({
            formInputs: formInputs,
            rowData: rowData,
            enableDelete: false,
            rowKey: count,
            tempAddedTagsJson : temp_added_tags,
            errors :errors
        }, function(){
            let row_key = 0;
            let current_ptree = document.getElementById("project_tree_"+row_key+"_trigger");
            if (current_ptree) {
                current_ptree.focus();
            }
        });
    }
    /* Load LS entries based on the selected date*/
    loadLSTimeEntries = (dbDataObj, newRow, sum) => {
        tempTimeEntryData = dbDataObj;
        let data = _ls.ls_get_time_entries(this.state.userID);
        let tdate = this.state.selectedDate;
        if (data!==null &&data.hasOwnProperty(tdate)){
            if (reRenderFlag) {
                this.setUnsavedFormData();
            } else {
                this.setState({
                    dialogOpen : true,
                    dialogButton : 'Yes',
                    popupType : 'load-unsaved-data',
                });
            }
        }
        reRenderFlag = false;
    }
    // TODO: Delete this function after testing TSD-1053
    handleProjectOnChange = (currentNode, selectedNodes, rowIdx) => {
        let data = this.state.rowData;
        let name = 'project_'+rowIdx;
        let clientTxt = '';
        if (currentNode.client_timesheet === '1') {
            clientTxt = 'Hours should match with the hours in client timsheet system (Eg: CTMS)';
        }
        let project_id = selectedNodes.length? currentNode.project_id ? currentNode.project_id : null : null;
        // clear db tags on project change
        if (this.state.tempAddedTagsJson && this.state.tempAddedTagsJson[rowIdx]) {
            let tags = this.state.tempAddedTagsJson[rowIdx];
            let deleted_tags = this.state.deletedDbTags;
            if (tags) {
                tags.forEach(function(t){
                    if (t.id) {
                        let is_db_tag = t.id.search('new');
                        if (is_db_tag === -1) {
                            deleted_tags.push(t.id);
                        }
                    }
                });
                this.setState({
                    deletedDbTags : deleted_tags,
                });
            }
        }
        //clear temp tags:
        if (this.state.tempAddedTagsJson.length){
            let tags = this.state.tempAddedTagsJson;
            if (tags[rowIdx]) {
                tags[rowIdx] = [];
            }
            this.setState({tempAddedTagsJson: tags});
        }
        //check local stoarge is available
        //let tasks = [];
        if(_ls.is_ls_enabled()){
            let formInputs = this.state.formInputs;
            let projectObj = {};
            let location = false;
            if (this.state.work_location_change['request'])
            {
                let wlc_projects = this.state.work_location_change['projects'];
                location = wlc_projects.includes(project_id);
            }
            projectObj.location = (project_id && currentNode.location && location) ? '1' : '0';
            projectObj.minimum_inc = project_id ? currentNode.minimum_increment : null; 
            projectObj.client_txt = clientTxt;
            if (project_id) {
                projectObj.client_timesheet = currentNode.client_timesheet;
            } else {
                projectObj.client_timesheet = 0;
            }
            //projectObj.tag_type = project_id ? currentNode.tag_type_id : null; TODO
            projectObj.location_ids = (currentNode != null && currentNode.location_ids) ?  currentNode.location_ids : "";
            projectObj.task_id = currentNode.task_id;
            projectObj.task_name = currentNode.task_name;
            this.setState({FormInputs: formInputs}, function(){
                //this.handleOnChange(currentNode, pID, rowIdx, name, projectObj, keep_task);
                this.handleOnChange(currentNode, project_id, rowIdx, name, projectObj);
            });
        }
        let project_path_display = "";
        if (project_id) {
            //project_path_display = currentNode.client_name+" -> "+currentNode.project_path; TODO:
        }
        data[rowIdx]['project_path_display'] = project_path_display;
        this.setState({
            rowData: data,
        });
    }
    handleTaskOnChange = (rowIdx) => (evt,currentNode) => {
        let name = 'project_' + rowIdx;
        let clientTxt = '';
        if (currentNode && currentNode.client_timesheet === '1') {
            clientTxt = 'Hours should match with the hours in client timsheet system (Eg: CTMS)';
        }
        let project_id = currentNode && currentNode.project_id ? currentNode.project_id : null;
        // clear db tags on project change
        if (this.state.tempAddedTagsJson && this.state.tempAddedTagsJson[rowIdx]) {
            let tags = this.state.tempAddedTagsJson[rowIdx];
            let deleted_tags = this.state.deletedDbTags;
            if (tags) {
                tags.forEach(function(t){
                    if (t.id) {
                        let is_db_tag = t.id.search('new');
                        if (is_db_tag === -1) {
                            deleted_tags.push(t.id);
                        }
                    }
                });
                this.setState({
                    deletedDbTags : deleted_tags,
                });
            }
        }
        //clear temp tags:
        if (this.state.tempAddedTagsJson.length){
            let tags = this.state.tempAddedTagsJson;
            if (tags[rowIdx]) {
                tags[rowIdx] = [];
            }
            this.setState({tempAddedTagsJson: tags});
        }
        //check local stoarge is available
        if(_ls.is_ls_enabled()){
            let formInputs = this.state.formInputs;
            let projectObj = {};
            let location = false;
            if (this.state.work_location_change['request'] && project_id)
            {
                let wlc_projects = this.state.work_location_change['projects'];
                location = wlc_projects.includes(project_id);
            }
            projectObj.location = (project_id && currentNode.location_ids && location) ? '1' : '0';
            projectObj.default_location_id =  (currentNode.default_location_id !== null && currentNode.default_location_id !== undefined) ? currentNode.default_location_id : false;
            projectObj.minimum_inc = project_id ? currentNode.minimum_increment : null; 
            projectObj.client_txt = clientTxt;
            if (project_id) {
                projectObj.client_timesheet = currentNode.client_timesheet;
            } else {
                projectObj.client_timesheet = 0;
            }
            projectObj.location_ids = (currentNode != null && currentNode.location_ids) ? currentNode.location_ids : "";
            projectObj.tag_type = project_id ? currentNode.tag_type_id : null; 
            projectObj.task_id = currentNode && currentNode.task_id ? currentNode.task_id : null;
            projectObj.task_name = currentNode && currentNode.task_name ? currentNode.task_name : '';
            // Set project path display
            let project_path_display = "";
            if (project_id) {
                if (currentNode.group_key === 'Recent') {
                    project_path_display = currentNode.task_path;
                } else {
                    project_path_display = currentNode.client_name+" / "+currentNode.task_path;
                }
            }
            projectObj.task_description = currentNode && currentNode.task_description ? currentNode.task_description : '';
            projectObj.task_code = currentNode && currentNode.code ? currentNode.code : '';
            projectObj.enable_location = currentNode && currentNode.enable_location ? currentNode.enable_location : '';
            projectObj.project_path_display = project_path_display;
            projectObj.location_ids = currentNode && currentNode.location_ids ? currentNode.location_ids : '';
            this.setState({FormInputs: formInputs}, function(){
                //this.handleOnChange(currentNode, pID, rowIdx, name, projectObj, keep_task);
                this.handleOnChange(currentNode, project_id, rowIdx, name, projectObj);
            });
        }
    }
    handleOnChange = (event, value, rowIdx, field, pObj, keepTask = false) => {
        let errors = this.state.errors;
        let index =  (field)?rowIdx:event.currentTarget.dataset.key;
        let name = (field)?field:event.target.name;
        let ff = this.state.rowData;
        let breakTask = this.state.breakTask;
        let val = '';
        if (name.indexOf('location') !== -1) {
            let name_arr = name.split("_");
            index = parseInt(name_arr[1]);
        }
        if (name.indexOf('nonconfirmity') !== -1) {
            val = event.target.checked;
            let name_arr = name.split("_");
            index = parseInt(name_arr[1]);
            name = 'resolve';
        } else {
            val = (field)?value:event.target.value;
        }
        if (_R.type(pObj)!=='Undefined'&& Object.keys(pObj).length && name.indexOf('project_') !== -1 && _R.type(ff[index]['project_'+index]) !== 'Undefined') {
            let location = false;
            let wlc_request = false;
            // setting work location data of each time entry to the time entry array
            let selected_project_locations = [];
            let default_location_data = [];
            this.state.requested_locations.forEach((lc_value, lc_key) => {
                if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(value)) {
                    selected_project_locations.push(lc_value);
                    wlc_request = this.state.work_location_change.request;
                }
            });
            ff[index]['selected_project_locations_'+index] = selected_project_locations;
            let wlc_projects = this.state.wlc_projects;
            if (wlc_request && wlc_projects && wlc_projects.length && event !== null)
            {
                let project_id = (event.project_id !== undefined) ? event.project_id : null;
                location = wlc_projects.includes(project_id);
            }
            ff[index]['minimum_inc_'+index] = pObj.minimum_inc;
            ff[index]['wlc_'+index] = (breakTask && breakTask.task_id !== undefined && pObj.task_id == breakTask.task_id && location) ? ((breakTask['location']) ? breakTask['location']  : this.state.default_location) : pObj.location;
            // Checking whether multiple locations are present
            let locationIds = _R.type(pObj.location_ids) !== 'Undefined' ? pObj.location_ids.split(","):[];

            ff[index]['wlc_allowed'+index] = (breakTask && breakTask.task_id !== undefined && pObj.task_id === breakTask.task_id && location) ? ((breakTask['location']) ? true : false) : (location && locationIds.length > 1) ? true : false;
            ff[index]['client_txt_'+index] = pObj.client_txt;
            ff[index]['tag_type_'+index]= pObj.tag_type;
            ff[index]['work_location_allowed_' + index] = locationIds;
            if (!keepTask) {
                ff[index]['task_'+index] = null;
            }
            ff[index]['client_timesheet']= pObj.client_timesheet;
            ff[index]['task_'+index] = pObj.task_id;
            ff[index]['task_name_'+index] = pObj.task_name;
            ff[index]['task_code_'+index] = pObj.task_code;
            ff[index]['enable_location_'+index] = pObj.enable_location;
            if (breakTask && breakTask.task_id !== undefined && pObj.task_id === breakTask.task_id) {
                ff[index]['location_'+index] = breakTask.location;
            }
            ff[index]['task_description_'+index] = pObj.task_description;
            ff[index]['project_path_display'] = pObj.project_path_display;
            if(ff[index]['hour_'+index]){
                let params = {
                    'val': ff[index]['hour_'+index],
                    'minimum_inc': this.props.MinimumInc,
                    'project_id': ff[index]['project_'+index],
                    'project_minimum_inc': (parseFloat(pObj.minimum_inc) || pObj.minimum_inc === 0) ? pObj.minimum_inc : null,
                }
                let isValid = _v.validateHour(params);
                if (!isValid.status) {
                    errors['hour_'+index] = isValid.msg;
                }
                else {
                    if(_R.type(errors['hour_'+index])!=='Undefined') {
                        delete errors['hour_'+index];
                    }
                }
            }
            let data = {
                date: this.state.selectedDate,
                index: index
            };
            if (location) {
                if (selected_project_locations){
                    if (selected_project_locations.length == 1){
                        ff[index]['location_' + index] = selected_project_locations[0]['location_id'];
                    } else if(this.state.selectedLocation != '' && selected_project_locations.filter(l => l.location_id == this.state.selectedLocation).length){
                        ff[index]['location_' + index] = this.state.selectedLocation;
                    }
                    else if(pObj.default_location_id && selected_project_locations.filter(l => l.location_id == pObj.default_location_id).length){
                        ff[index]['location_' + index] = pObj.default_location_id;
                    }
                }
            } else {
                if (pObj.enable_location && selected_project_locations.filter(l => l.location_id == this.state.default_location).length) {
                    ff[index]['location_' + index] = this.state.default_location;
                }
                else
                {
                    ff[index]['location_' + index] = null;
                }
            }
            ff[index]['index']= index;
        }
        if (name.indexOf('hour_') !== -1) {
            index = name.split("_").pop(-1);
            // validate hours format
            let params = {
                'val': val,
                'minimum_inc': this.props.MinimumInc,
                'project_id': (ff[index]['project_'+index])?ff[index]['project_'+index]:null,
                'project_minimum_inc': (_R.type(ff[index]['minimum_inc_' + index]) !== 'Undefined' && (parseFloat(ff[index]['minimum_inc_' + index]) || ff[index]['minimum_inc_' + index] === 0)) ? ff[index]['minimum_inc_'+index] : this.props.MinimumInc,
            }
            let isValid = _v.validateHour(params);
            if (!isValid.status) {
                errors[name] = isValid.msg;
            }
            else {
                if(_R.type(errors[name])!=='Undefined') {
                    delete errors[name];
                }
            }
        }
        ff[index][name] = val;
        this.setState({
            rowData: ff,
        }, () => { this.insertLSEntries(); });
        let tempHours = [];
        Object.values(ff).forEach(function(obj, idx){ 
            index = (obj.index !== undefined) ? obj.index : null;
            for ( var p in obj ) {
                if (index !== null)  {
                    if (p.indexOf('hour_'+index) > -1) tempHours.push(obj[p]);
                } else {
                    if (p.indexOf('hour_') > -1) tempHours.push(obj[p]);
                }
            }
        });
        let sum = 0.00;
        let ret = _v.calculateSum(tempHours);
        if (!ret.msg) {
            sum = ret.sum;
            if(_R.type(errors['daytotal'])!=='Undefined') {
                    delete errors['daytotal'];
                }
        }
        else {
            errors['daytotal'] = ret.msg;
        }
        this.setState({errors: errors, dayTotal: sum});
    }

    handleDescriptionChange = (evt,value) => {
        let name = evt.target.name;
        let val = evt.target.value;
        let index = evt.currentTarget.dataset.key;
        let ff = this.state.rowData;
        ff[index][name] = val;
        this.setState({
            rowData: ff,
        }, () => { this.insertLSEntries();});
    }
    onTagTempDelete = (event, value) => {
        let tag_row_id = event.currentTarget.dataset.tag;
        let r_index = event.currentTarget.dataset.rindex;
        if (tag_row_id && tag_row_id !== 'undefined') {
            let is_db_tag = tag_row_id.search('new');
            if (is_db_tag === -1) {
                let deleted_tags = this.state.deletedDbTags;
                deleted_tags.push(tag_row_id);
                this.setState({
                    deletedDbTags : deleted_tags,
                });
            }
            if (this.state.tempAddedTagsJson[r_index]) {
                let temp_added_tag_json = this.state.tempAddedTagsJson;
                var filtered = temp_added_tag_json[r_index].filter(function(value){
                    return tag_row_id!=value.id;
                });
                temp_added_tag_json[r_index] = filtered;
                this.setState({tempAddedTagsJson: temp_added_tag_json});
            }
        }
    }
    /* Add new tags*/
    onAddTagIconClicked = (tagType, timesliceID, indexID) => {
        let tagInputs = {
            'org': null,
            'contract': null,
            'lob': null,
            'custom': null,
            'org_label': null,
            'contract_label': null,
            'lob_label': null,
            'custom_label': null
        }
        this.setState({tagInputs: tagInputs});
        tempTimesliceID = timesliceID;
        tempIndexID = indexID;
        tempTagsList = _ls.ls_get_tags(tagType);
        this.setState({
            dialogOpen : true,
            dialogButton : 'Add',
            popupType : 'add-tags',
        });
    }
    /*Enable selection when clicks on the edit button*/
    onEdit = (timesliceID, rowIndex, project_id) => {
        if (this.props.DisableSaveButton && !this.state.isApproved) {
            this.setState({disableSaveButton : false});
        }
        let selected_project_locations = [];
        this.state.requested_locations.forEach((lc_value, lc_key) => {           
            if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(project_id)) {
                selected_project_locations.push(lc_value);
            }
        });
        this.setState({
            editedIndex:rowIndex,
            selected_project_locations : selected_project_locations
        }, function(){
            if (typeof rowIndex === 'number') {
                let current_task_tree = document.getElementById("project-task-select"+rowIndex);
                if (current_task_tree) {
                    current_task_tree.focus();
                }
            }
        });
    }
    onDelete = (event, value) => {
        tempTimesliceID = false;
        let index = event.currentTarget.dataset.key;
        let timesliceID = event.currentTarget.dataset.timeslice_id;
        if (timesliceID !== 'undefined' && timesliceID > 0) {
            tempTimesliceID = timesliceID;
            this.setState({
                dialogOpen: true,
                dialogButton: 'Ok',
                popupType: 'time-entry-delete',

            });
        }
        else {
            if (this.props.DisableSaveButton) { // Submitted timesheet
                this.setState({ disableSaveButton: true });
            }
            //Delete selected entry based on the index:
            this.deleteTimeEntryStateRowByIndex(index);
        }
        this.MixpanelManager.trackEvent('Timesheet', { module: 'Timesheet', feature: 'timesheet/time-entry', action: 'delete' });

    }
    deleteTimeEntryStateRowByIndex = (index) => {
        let rowData = this.state.rowData;
        delete rowData[index];
        if (Object.keys(rowData).length === 0) {
            this.appendTimeEntryRow();
        }
        else {
            let tempHours = [];
            Object.values(rowData).forEach(function(obj, idx){
                for ( var p in obj ) {
                    if (p.indexOf('hour_') > -1) tempHours.push(obj[p]);
                }
            });
            let ret = _v.calculateSum(tempHours);
            let errors = this.state.errors;
            if (!ret.msg) {
                delete errors['daytotal'];
                this.setState({dayTotal: ret.sum, rowData: rowData, errors: errors});
            }
            else {
                errors['daytotal'] = ret.msg;
                this.setState({errors: errors, dayTotal: 0.00});
            }
            // Remove deleted row records from local storage:
            if (_ls.is_ls_enabled()) {
                this.removeTimeEntries(this.state.selectedDate);
                this.insertLSEntries();
            }
        }
    }
    /**
    * This should executed when clicks on the 'Add' Button,
    * insert selected row data into LS
    ***/
    appendTimeEntryRow = (insertLS=true) => {
        let rowKey = (isFirst) ? 0 : this.state.rowKey + 1;
        if (isFirst) {
            isFirst = false;
        }
        let rowData = this.state.rowData; 
        //After removed LS, reset the rowData entry
        if (!insertLS)rowData = [];
        let rowFields = {};
        rowFields['client_'+rowKey]= null;
        rowFields['project_'+rowKey]= null;
        rowFields['task_'+rowKey]= null;
        rowFields['location_'+rowKey]= null;
        rowFields['desc_'+rowKey]= null;
        rowFields['hour_'+rowKey]= null;
        rowFields['rowKey_'+rowKey]= rowKey;
        rowFields['wlc_'+rowKey]= null;
        rowFields['minimum_inc_'+rowKey]= null;
        rowFields['client_txt_'+rowKey]= null;
        rowFields['tag_type_'+rowKey]= null;
        rowFields['show_edit_button'] = true;
        rowFields['work_location_allowed_' + rowKey] = null;
        rowFields['index'] = rowKey;
	rowFields['locked'] = false;
        rowData.push(rowFields);
        let tempHours = [];
        Object.values(rowData).forEach(function(obj, idx){ 
            for ( var p in obj ) { 
                if (p.indexOf('hour_') > -1) tempHours.push(obj[p]);
            }
        });
        let sum = 0.00;
        let errors = [];
        let ret = _v.calculateSum(tempHours);
        if (!ret.msg) {
            sum = ret.sum;
            if(_R.type(errors['daytotal'])!=='Undefined') {
                    delete errors['daytotal'];
                }
        }
        else {
            errors['daytotal'] = ret.msg;
        }
        this.setState({
            rowKey: rowKey,
            rowData: rowData,
            dayTotal: sum
        }, function(){
            if (rowKey > 0 ) {
                let current_task_tree = document.getElementById("project-task-select"+rowKey);
                if (current_task_tree) {
                    current_task_tree.focus();
                }
            }
            if (insertLS) {
                this.insertLSEntries();
            }
        });
    }
     /*Remove time entries from local storage based on the date*/
    removeTimeEntries = (tDate) => {
        let data = _ls.ls_get_time_entries(this.state.userID);
        if( data!==null&&data.hasOwnProperty(tDate)){
            delete data[tDate];
        }
        _ls.ls_set_time_entries(data, this.state.userID);
    }
    /* Put entry into LS based on the selected date*/
    insertLSEntries = () => {
        let values = {};
        let cnt = 1;
        let add = false;
        let tdate = this.state.selectedDate;
        let rowData = this.state.rowData;
        if (rowData.length) {
            rowData.forEach(function(obj, idx){
                let rowIndex = obj['rowKey_'+idx];
                let info = {};
                let taskID = obj['task_'+rowIndex];
                let hour = obj['hour_'+rowIndex];
                let timesliceID = obj['timeslice_id'];
                let desc = obj['desc_'+rowIndex];
                let locVal = '0';
                let location = '0';
                if (obj['location_'+rowIndex]) {
                    location = obj['location_'+rowIndex];
                } 
                //TODO
                /*if ($('#resolve_'+row).is(':checked')) {
                    var non_confirmity = 1;
                }
                else {
                    var non_confirmity = 0;
                }*/
                if ((taskID !== 0 && taskID !== null) && (obj['project_'+rowIndex] !== null || desc !== null || hour !== null)) {
                    if (!add) {
                        if (timesliceID === '') {
                            add = true;
                        }
                        else {
                            if (_R.type(obj['timeslice_id']) === 'Undefined') {
                                add = true;
                            }
                        }
                    }
                    info['timeslice_date'] = tdate;
                    info['timeslice_id'] = timesliceID;
                    info['project_id'] = obj['project_'+rowIndex];
                    info['timeslice_task'] = taskID;
                    info['timeslice_log'] = desc;
                    info['timeslice_hours'] = hour;
                    info['wlc_'+rowIndex] = location;
                    info['wlc_allowed'+rowIndex] = obj['wlc_allowed'+rowIndex];
                    info['project_'+rowIndex] = obj['project_'+rowIndex];
                    info['task_'+rowIndex] = taskID;
                    info['task_name'] = obj['task_name_'+rowIndex];
                    info['hour_'+rowIndex] = hour ? hour : 0;
                    info['desc_'+rowIndex] = desc;
                    info['location'] = location;
                    info['project_path_display'] = obj['project_path_display'];
                    info['minimum_increment'] = obj['minimum_inc_'+rowIndex];
                    info['index'] = rowIndex;
                    info['selected_project_locations_'+rowIndex] = obj['selected_project_locations_'+rowIndex];
                    info['task_code_' + rowIndex] = obj['task_code_' + rowIndex];
                    info['enable_location'] = obj['enable_location_' + rowIndex];
                    //TODO: same as old timeshet code
                    //info['non_confirmity'] = non_confirmity;
                    info['code'] = obj['task_code_' + rowIndex];
                    //info['editable'] = editable;
                    values[cnt]=info;
                    cnt++;
                }
            });
            if (cnt > 1 && add){
                let data = _ls.ls_get_time_entries(this.state.userID);
                if (data == null){
                    data = {};
                }
                data[tdate]= values; 
                _ls.ls_set_time_entries(data, this.state.userID);
            }
        }
    }
    reload = () => {
        this.MixpanelManager.trackEvent('Timesheet', { module: 'Timesheet', feature: 'reload-project', action: 'clicked' });
        //TODO: show loader
        (async () => {
            let date = this.state.selectedDate;
            const response = await httpServices.post('api/timesheet/get-assigned-charge-codes', {date:date});
            if (response.data) {
                let dataObj = response.data;
                _ls.ls_set_item_chargecodes(dataObj);
                //_ls.ls_populateProjects(dataObj.projects, _this.state.selectedDate);
                if(_ls.is_ls_enabled()) {
                    let projects = _ls.validateProjects(this.state.selectedDate);
                    this.setState({
                        clientProjects: projects,
                        full_path_tasks : response.data.task_inputs,
                    });
                    this.reloadTimeEntries();
                }
            }
        })();
        /*_ls.ls_init_user_chargecodes('api/timesheet/get-assigned-charge-codes', this.state.instanceID, true);
        if(_ls.is_ls_enabled()) {
            let projects = _ls.validateProjects(_this.state.selectedDate);
            this.setState({
                clientProjects: projects,
            });
            this.reloadTimeEntries();
        }*/
        successMsg = "Reloaded the projects and tasks in local storage";
        this.setState({
            dialogOpen : false,
            snackbarOpen : true,
            snackbarVariant : 'success',
        });
        return false;
    }
    /* This is for resue input values for the other date*/
    handleKeepValueChange = (event)=> {
        let reuseInputValues = event.target.checked;
        this.setState({reuseInputValues:reuseInputValues});
    }
    /* Reset the form inputs */
    onReset = () => {
        this.getTimesheetByDate(this.state.selectedDate);
    }
    /**
     * Save time entry records
     * if server side validation return true, display assocaited error message in a dialog box
     **/
    handleSave(confirmed=false) {
        tempIndexID = null;
        let rowData = this.state.rowData;
        let validatedObj = _v.validate(rowData, this.state.errors);
        if (validatedObj.valid) {
            if (this.state.editedIndex!==null&&confirmed!==false) {
                let dateobj_arr = rowData[0]['timeslice_date'].split('-');
                let dateobj = new Date(dateobj_arr[0], parseInt(dateobj_arr[1]) - 1, dateobj_arr[2]);
                let current_date = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
                if (dateobj < current_date) {       
                   this.onTimeEntryEditConfirm();
                   return true;
                }
            }
            let valid_entries = true;
            if (validatedObj.non_confirmity_found) {
                if (!this.state.skip_non_confirmity) {
                    valid_entries = false;
                    this.showSkipNonConfirmityModal();
                }
            }
            if (valid_entries) {
                if (_ls.is_ls_enabled()) {
                    this.insertLSEntries();
                }
                let selDate = this.state.selectedDate;
                let copiedDate = this.state.copied_date;
                let dataRows = [];
                let tHours = [];
                if (rowData.length) {
                    rowData.map(function(val, index){
                        let dataObj = {};
                        let taskID = val['task_'+index];
                        let hour = val['hour_'+index];
                        let locVal = val['location_'+index];
                        dataObj.rowIndex = index;
                        let hourStr = taskID+'|'+hour;
                        let location_value = locVal++;
                        hourStr += '|'+ location_value +'|'+val['project_'+index];
                        dataObj.location = location_value;
                        dataObj.id = val['timeslice_id'];
                        dataObj.tid = val['timeslice_id'];
                        dataObj.date = selDate;
                        dataObj.task_id = taskID;
                        dataObj.hours = hour;
                        dataObj.desc = val['desc_'+index];
                        dataObj.timesheet_id = (_R.type(val['timesheet_id']) !== 'Undefined')?val['timesheet_id']:null;
                        dataObj.resolve = val.resolve ? val.resolve : 0;
                        dataObj.log = val['desc_'+index];
                        dataObj.enable_location = val['enable_location_'+index];
			dataObj.project_id = val['project_'+index];
			dataObj.task_name = val['task_name_'+index];
			dataObj.locked = val['locked'] ;
                        if (val['timeslice_cdate']) {
                            if (copiedDate != null)  {
                                dataObj.option = 'copy';
                            }
                            else {
                                dataObj.option = 'reuse';
                            }
                            dataObj.reused = true;
                        }
                        else {
                            dataObj.reused = false;
                        }
                        dataRows.push(dataObj);
                        tHours.push(hourStr);
                        return dataRows;
                    });
                }
                //let temp_tags = this.state.tempAddedTagsJson;
                //let tag_ids = [];
                let data = {
                    'date': selDate,
                    'hours': this.state.dayTotal,
                    'edit': 'false',
                    'data_rows': JSON.stringify(dataRows),
                    'hours_arr': JSON.stringify(tHours),
                    'tags': JSON.stringify(this.state.tempAddedTagsJson),
                    'comments': confirmReason,
                    'deleted_tags' : JSON.stringify(this.state.deletedDbTags),
                }
                if (this.state.reuseInputValues) {
                    data.reuse_input = this.state.reuseInputValues;
                    data.selected_date = this.state.selectedDate;
                    data.reused_date = this.state.reused_date;
                }
                if (this.state.copied_date) {
                    data.copied_date = this.state.copied_date;
                }
                this.setState({ buttonLoader : true});
                return httpServices.post('api/timesheet/save', {data})
                    .then(response => {
                        if (!response.data.status) {
                            if (typeof response.data.input_type_errors !== 'undefined') {
                                successMsg = 'Save failed';
                                this.setState({
                                    buttonLoader : false,
                                    timesliceReqErrors : response.data.input_type_errors,
                                    dialogOpen : false,
                                    snackbarVariant : 'error',
                                    snackbarOpen : true,
                                });
                            } else if (typeof response.data.message !== 'undefined') {
                                successMsg = response.data.message;
                                this.setState({
                                    buttonLoader: false,
                                    dialogOpen: false,
                                    snackbarVariant: 'error',
                                    snackbarOpen: true,
                                });
                            } else {
                                this.setState({
                                    buttonLoader : false,
                                    validation : response.data,
                                    dialogOpen : true,
                                    dialogButton : 'Ok',
                                    popupType : 'duplicate-entry',
                                    skip_non_confirmity : false,
                                });
                            }
                        }
                        else {
                            //display success message:
                            successMsg = response.data.message;
                            this.setState({
                                buttonLoader : false,
                                dialogOpen : false,
                                snackbarOpen : true,
                                snackbarVariant : 'success',
                                tempAddedTagsLabel: [],
                                skip_non_confirmity : false,
                            });
                            // Remove corresponding time entries from local storage:
                            if(_ls.is_ls_enabled()) {
                                this.removeTimeEntries(this.state.selectedDate);
                            }
                            //this.getTimesheetByDate();
                            this.props.UpdateParentContainer('time-entry-save');
                            this.MixpanelManager.trackEvent('Timesheet', {module:'Timesheet',feature:'timesheet/time-entry',action:'save'});
                            //tempAddedTagsJson = [];
                            confirmReason = '';
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
        else {
            successMsg = validatedObj.msg;
            this.setState({
                snackbarVariant : 'error',
                snackbarOpen : true,
                timesliceReqErrors : validatedObj.errors
            });
        }
    }
    /*TimeEntry delete reason*/
    onTimeEntryEditConfirm = () => {
        this.setState({
            dialogOpen : true,
            dialogButton : 'OK',
            popupType : 'time-entry-edit-reason',
        });
    };
    /* On tags on change */
    handleTagInputChange = (evt, value) => {
        let name = evt.target.name;
        let tagInputs = this.state.tagInputs;
        tagInputs[name] = evt.target.value;
        tagInputs[name+'_label'] = evt.currentTarget.dataset.title;
        if (evt.target.value !== '') {
            this.setState({tagInputs: tagInputs});
        }
    }
    /* Save New Tag */
    onAddTag = () => {
        let tags = this.state.tagInputs;
        let tagLabelStr = '';
        if (!_R.isNil(tags.org)) {
            tagLabelStr = tags.org_label;
        }
        if (!_R.isNil(tags.contract)) {
            tagLabelStr = (tagLabelStr!=='')?tagLabelStr+'-'+tags.contract_label:tags.contract_label;
        }
        if (!_R.isNil(tags.lob)) {
            tagLabelStr = (tagLabelStr!=='')?tagLabelStr+'-'+tags.lob_label:tags.lob_label;
        }
        if (!_R.isNil(tags.custom)) {
            tagLabelStr = (tagLabelStr!=='')?tagLabelStr+'-'+tags.custom_label:tags.custom_label;
        }
        let errors = this.state.errors;
        let tempAddedTagsJson = this.state.tempAddedTagsJson;
        // Validate tags
        let valid = true;
        if(tempAddedTagsJson[tempIndexID]) {
            tempAddedTagsJson[tempIndexID].forEach(function(tag){
                if (tag.tag_name === tagLabelStr) {
                    valid = false;
                }
            });
        }
        if (valid) {
            //let s = {'org':tags.org, 'contract': tags.contract, 'lob':tags.lob, 'custom':tags.custom};
            // Get Tag Ids
            let selected_tag_ids = [];
            for (var key in tags) {
                if (tags.hasOwnProperty(key)) {
                    if (tags[key]) {
                        let tag_id = parseInt(tags[key]);
                        if (tag_id && !isNaN(tag_id)) {
                            selected_tag_ids.push(tag_id);
                        }
                    }
                }
            }
            let rand = Math.floor(1000 + Math.random() * 9000);
            let tag_obj = {
                id:'new_'+rand,
                tag_ids : selected_tag_ids,
                tag_name : tagLabelStr,
            }
            if(typeof tempAddedTagsJson[tempIndexID] === 'undefined') {
                tempAddedTagsJson[tempIndexID] = [];
                tempAddedTagsJson[tempIndexID].push(tag_obj);
            }
            else {
                tempAddedTagsJson[tempIndexID].push(tag_obj);
            }
            let tagInputs = {
                'org': null,
                'contract': null,
                'lob': null,
                'custom': null,
                'org_label': null,
                'contract_label': null,
                'lob_label': null,
                'custom_label': null
            }
            delete errors['tag'];
            this.setState({tagInputs: tagInputs, errors:errors,tempAddedTagsJson:tempAddedTagsJson});
        }
        else {
            errors['tag'] = 'Tag already exists';
            this.setState({errors: errors});
        }
    }
    setUnsavedFormData = () => {
        let data = _ls.ls_get_time_entries(this.state.userID);
        let work_location_change = this.state.work_location_change;
        let tDate = this.state.selectedDate;
        let tIDs = [];
        let sum = 0;
        let tempRowData = [];
        // Load DB data
        if (tempTimeEntryData.timeentry.length !== 0){
            let timeEntryData = tempTimeEntryData.timeentry;
            Object.values(tempTimeEntryData.timeentry).forEach(function(obj, idx){
                let dbDataTid = obj.timeslice_id;
                if( !isNaN(obj.timeslice_hours) && parseFloat(obj.timeslice_hours)!==0 ) {
                    sum += parseFloat(obj.timeslice_hours);
                }
                tempRowData.push(obj);
                tIDs.push(dbDataTid);
            });
        }
        //Load & display data from LS:
        if (data!==null &&data.hasOwnProperty(tDate)) {
            let timeEntries = data[tDate];
            Object.values(timeEntries).forEach(function(obj, idx){
                if(_R.type(obj.timeslice_id)!=='Undefined'&&obj.timeslice_id !== ''){
                    if (tIDs.indexOf(obj.timeslice_id) <= -1) {
                        tempRowData.push(obj);
                        sum += parseFloat(obj.timeslice_hours);
                    }
                }
                else{
                    let hour = 0;
                    tempRowData.push(obj);
                    for ( var p in obj ) {
                        if (p.indexOf('hour_') > -1&&!isNaN(obj[p]) && parseFloat(obj[p])!==0) hour = obj[p];
                    }
                    sum += parseFloat(hour);
                }
            });
        }
        this.setState({rowData:[], dayTotal: sum.toFixed(2)}, function(){
            if (tempRowData.length) {
                this.SetTimeEntryData(tempRowData, work_location_change, work_location_change.locations);
            }
        });
        this.onClose();
    }
    /* Delete time entry records from the LS based on the selected date*/
    deleteUnsavedData = () => {
        let sum = 0;
        // Delete corresponding data from LS:
        this.removeTimeEntries(this.state.selectedDate);
        // If data exist in the DB, load DB data:
        // TODO : Remove this code after testing STEPS-1192
        /*if (tempTimeEntryData.timeentry.length!==0){
            this.SetTimeEntryData(tempTimeEntryData.timeentry);
            Object.values(tempTimeEntryData.timeentry).forEach(function(obj, idx){
                //calculate total hours
                if( !isNaN(obj.timeslice_hours) && parseInt(obj.timeslice_hours)!==0 ) {
                    sum += parseFloat(obj.timeslice_hours);
                }
            });
            this.setState({dayTotal: sum.toFixed(2)});
        }
        else{
            isFirst = true;
            this.appendTimeEntryRow(false);
        }*/
        this.onClose();
    }
    onEditConfirmed = (id = 0) => () => {
        if (confirmReason.trim()==='') {
            var err = this.state.errors;
            err['confirm_reason'] = 'Reason cannot be empty';
            this.setState({errors: err});
            return true;
        }
        else {
            this.setState({editedIndex:null}, function(){this.handleSave(true);});
        }
    }
    showSkipNonConfirmityModal= () => {
        this.setState({
            dialogOpen : true,
            dialogButton : 'OK',
            popupType : 'non_confirmity_user_confirmation',
        });
    };
    skipNonConfirmity = () => {
        this.setState({
            skip_non_confirmity : true,
            //dialogOpen : true,
        }, function (){
            this.handleSave();
        });
    }
    onClose = () => {
        confirmReason= '';
        let err = this.state.errors;
        if (err.hasOwnProperty('confirm_reason')) {
            delete err['confirm_reason'];
        }
        if (this.state.popupType === 'time-entry-delete' || this.state.popupType === 'time-entry-delete-reason') {
            this.props.UpdateParentContainer('delete');  
        }
        this.setState({
            dialogOpen : false,
            errors: err,
        });
    }
    handleTaskTreeChange = (currentNode,rowIdx) => (evt)=>{
        this.MixpanelManager.trackEvent('Timesheet', { module: 'Timesheet', feature: 'task-tree', action: 'selected-task-from-task-tree' });
        if (currentNode.task_id) {
            let data = this.state.rowData;
            let name = 'project_'+rowIdx;
            let clientTxt = '';
            if (currentNode && currentNode.client_timesheet === '1') {
                clientTxt = 'Hours should match with the hours in client timsheet system (Eg: CTMS)';
            }
            let project_id = currentNode && currentNode.project_id ? currentNode.project_id : null;
            // clear db tags on project change
            if (this.state.tempAddedTagsJson && this.state.tempAddedTagsJson[rowIdx]) {
                let tags = this.state.tempAddedTagsJson[rowIdx];
                let deleted_tags = this.state.deletedDbTags;
                if (tags) {
                    tags.forEach(function(t){
                        if (t.id) {
                            let is_db_tag = t.id.search('new');
                            if (is_db_tag === -1) {
                                deleted_tags.push(t.id);
                            }
                        }
                    });
                    this.setState({
                        deletedDbTags : deleted_tags,
                    });
                }
            }
            //clear temp tags:
            if (this.state.tempAddedTagsJson.length){
                let tags = this.state.tempAddedTagsJson;
                if (tags[rowIdx]) {
                    tags[rowIdx] = [];
                }
                this.setState({tempAddedTagsJson: tags});
            }
            //check local stoarge is available
            if(_ls.is_ls_enabled()){
                let formInputs = this.state.formInputs;
                let projectObj = {};
                let location = false;
                if (this.state.work_location_change['request'])
                {
                    let wlc_projects = this.state.work_location_change['projects'];
                    location = wlc_projects.includes(project_id);
                }
                projectObj.location = (project_id && currentNode.location && location) ? '1' : '0';
                projectObj.minimum_inc = project_id ? currentNode.minimum_increment : null; 
                projectObj.client_txt = clientTxt;
                if (project_id) {
                    projectObj.client_timesheet = currentNode.client_timesheet;
                } else {
                    projectObj.client_timesheet = 0;
                }
                projectObj.location_ids = (currentNode != null && currentNode.location_ids) ?  currentNode.location_ids : "";
                projectObj.tag_type = project_id ? currentNode.tag_type_id : null; 
                projectObj.task_id = currentNode && currentNode.task_id ? currentNode.task_id : null;
                projectObj.task_name = currentNode && currentNode.task_name ? currentNode.task_name : '';
                // Set project path display
                let project_path_display = "";
                if (project_id) {
                    project_path_display = currentNode.client_name+" / "+currentNode.task_path+" / "+currentNode.task_name;
                }
                projectObj.task_description = currentNode && currentNode.task_description ? currentNode.task_description : '';
                projectObj.project_path_display = project_path_display;
                projectObj.location_ids = currentNode && currentNode.location_ids ? currentNode.location_ids : '';
                projectObj.enable_location = currentNode && currentNode.enable_location ? currentNode.enable_location : '';
                this.setState({FormInputs: formInputs, taskTreeViewState : false}, function(){
                    //this.handleOnChange(currentNode, pID, rowIdx, name, projectObj, keep_task);
                    this.handleOnChange(currentNode, project_id, rowIdx, name, projectObj);
                });
            }
        }
    }

    onDeleteConfirm = (evt, value) => {
        let val = evt.target.value;
        if (val.trim()) {
            confirmReason = val;
            this.setState({errors: {}});
        }
        else {
            var err = this.state.errors;
            err['confirm_reason'] = 'Reason cannot be empty';
            this.setState({errors: err});
        }
    }
    /*TimeEntry delete reason*/
    onTimeEntryDeleteConfirm = () => {
        this.setState({
            dialogOpen : true,
            dialogButton : 'OK',
            popupType : 'time-entry-delete-reason',
        });
    };
    onDeleteConfirmed = (id = 0) => () => {
        this.setState({ buttonLoader : true });
        if (confirmReason.trim()==='') {
            var err = this.state.errors;
            err['confirm_reason'] = 'Reason cannot be empty';
            this.setState({
                errors: err ,
                buttonLoader : false
            });
            return true;
        }
        let postData = {
            'id': tempTimesliceID,
            'comment': confirmReason,
        };
        return httpServices.post('api/timesheet/delete', postData)
        .then(response => {
            successMsg = response.data.message;
            if (response.data.errors) {
                this.setState({
                    errors: response.data.errors,
                    buttonLoader: false
                });
            } else if (!response.data.status) {
                this.onClose();
                this.setState({
                    snackbarOpen: true,
                    snackbarVariant: 'error',
                    buttonLoader: false
                });
            }
            else {
                tempTimesliceID = false;
                this.setState({
                    snackbarOpen: true,
                    snackbarVariant: 'success',
                    popupType: '',
                    buttonLoader : false
                }, function () {
                    this.onClose();
                }
                );
                // Update DOM
                let rowData = this.state.rowData;
                if (rowData) {
                    let index = rowData.findIndex(o => o.timeslice_id === postData.id);
                    if (index !== -1) {
                        this.deleteTimeEntryStateRowByIndex(index);         
                    }
                }
                this.props.UpdateParentContainer('time-entry-delete');
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    reloadTimeEntries = () => {
        let data = _ls.ls_get_time_entries(this.state.userID);
        let tDate = this.state.selectedDate;
        let tIDs = [];
        //loadLsBtnClick = false;
        if (data !== null && data.hasOwnProperty(tDate)){
            //TODO : remove all rows
            let timeEntries = data[tDate];
            let sum = 0;
            Object.values(timeEntries).forEach(function(data, index){
                if(data.timeslice_id !== ''){
                    tIDs.push(data.timeslice_id);
                }
                else{
                    isFirst = true;
                    this.appendTimeEntryRow();
                }
                sum += parseFloat(data.timeslice_hours);
            });
        }
    }
    toggleTreeView = (anchor, open) => (event) => {
        this.MixpanelManager.trackEvent('Timesheet', { module: 'Timesheet', feature: 'task-tree', action: 'clicked' });
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        this.setState({taskTreeViewState : open});
    };
    copyTimeEntries = (date) => {
        let data = {
            date: date,
        };
        let newRow = true;
        tempDBTags = [];
        let requested_locations = this.state.requested_locations;
        let projects = _ls.validateProjects(this.state.selectedDate);
        return httpServices.post('api/timesheet/get-timesheet-by-date', data)
            .then(response => {
                if (response.data) { 
                    let rowData = [];
                    let timeEntryRows = response.data.timeentry;
                    let default_location = response.data.default_location;
                    let sum = 0;
                    let wlc_request = this.state.work_location_change.request;
                    let wlc_projects = this.state.wlc_projects;
                    let temp_tag_inputs = [];
                    let count = 0;
                    let stateRowData = this.state.rowData;
                    if(stateRowData.length) {
                        let savedTimeEntryFound = false;
                        stateRowData.forEach(function(stateObj, stateIdx){
                            if (stateObj.timeslice_id) {
                                savedTimeEntryFound = true;
                            }
                        });
                        if (timeEntryRows.length && !savedTimeEntryFound) { // Copy time entry feature will not work if there is a saved time entry
                            this.scrollToTop();
                            let length = timeEntryRows.length;
                            let active_tasks = (this.state.full_path_tasks && this.state.full_path_tasks.length) ? this.state.full_path_tasks : [];
                            let valid_projects = _ls.ls_get_valid_project();
                            let idx_exclude_arr = [];
                            timeEntryRows.forEach(function(obj, idx){
                                if (obj['code'] === 'TO' || obj['code'] === 'HO') {
                                    idx_exclude_arr.push(idx);
                                }
                            });
                            idx_exclude_arr.forEach(function(exclude) {
                                timeEntryRows.splice(exclude, 1);
                            });                            
                            timeEntryRows.forEach(function(obj, idx) {
                                count = idx;
                                let row = obj;
                                let reset_task = false;                                
                                timeEntryRows[idx]['client_'+idx] = obj.client_id;
                                timeEntryRows[idx]['project_'+idx] = obj.project_id;
                                timeEntryRows[idx]['task_'+idx] = obj.timeslice_task;
                                timeEntryRows[idx]['task_name_'+idx] = obj.task_name;                               
                                timeEntryRows[idx]['desc_'+idx] = obj.timeslice_log;
                                timeEntryRows[idx]['hour_'+idx] = obj.timeslice_hours;
                                timeEntryRows[idx]['rowKey_'+idx]= idx;
                                timeEntryRows[idx]['wlc_'+idx]= obj.location;
                                timeEntryRows[idx]['minimum_inc_'+idx]= obj.minimum_increment;
                                timeEntryRows[idx]['client_txt_'+idx]= '';
                                timeEntryRows[idx]['tag_type_'+idx]= obj.tag_type_id;
                                timeEntryRows[idx]['tags_'+idx]= obj.tags;
                                timeEntryRows[idx]['task_description_'+idx]= obj.task_description;
                                timeEntryRows[idx]['work_location_allowed_' + idx] = obj.location_ids;
                                timeEntryRows[idx]['task_code_' + idx] = obj.code;
                                timeEntryRows[idx]['enable_location_' + idx] = obj.enable_location;
				timeEntryRows[idx]['locked'] = false;
                                if (obj.project_path_display) {
                                    timeEntryRows[idx]['project_path_display'] = obj.project_path_display;
                                } else {
                                    timeEntryRows[idx]['project_path_display'] = obj.client_name+" / "+obj.project_path+" / "+obj.task_name;
                                }
                                timeEntryRows[idx]['client_timesheet'] = obj.client_timesheet;
                                if (obj['code'] === 'TO' && parseInt(obj['editable']) === 0) {
                                    timeEntryRows[idx]['show_edit_button'] = false;
                                }
                                else {
                                    timeEntryRows[idx]['show_edit_button'] = true;
                                }

                                if (active_tasks.length) {
                                    let task_index = active_tasks.findIndex(o => o.task_id === obj.timeslice_task);
                                    if (task_index === -1) {
                                        reset_task = true;
                                    }
                                } else {
                                    reset_task = true;
                                }
                                timeEntryRows[idx]['timeslice_id'] = null;
                                if (reset_task) {
                                    timeEntryRows[idx]['project_'+idx] = null;
                                    timeEntryRows[idx]['project_path_display'] = null;
                                    timeEntryRows[idx]['task_'+idx] = null;
                                    timeEntryRows[idx]['task_name_'+idx] = '';
                                    timeEntryRows[idx]['client_timesheet'] = 0;
                                    timeEntryRows[idx]['tag_type_'+idx]= null;
                                }
                                let location = false;
                                if (wlc_request && wlc_projects && wlc_projects.length)
                                {
                                    let project_id = (obj.project_id && !reset_task) ? obj.project_id : null;
                                    location = wlc_projects.includes(project_id);
                                }
                                timeEntryRows[idx]['wlc_allowed'+idx] = location;
                                timeEntryRows[idx]['wlc_'+idx] = location;
                                // setting work location data of each time entry to the time entry array
                                let selected_project_locations = [];
                                requested_locations.forEach((lc_value, lc_key) => {
                                    if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(obj.project_id)) {
                                        selected_project_locations.push(lc_value);
                                    }
                                });
                                timeEntryRows[idx]['selected_project_locations_'+idx] = selected_project_locations;
                                if (typeof obj.location !== 'undefined' && selected_project_locations.filter(l => l.location_id == obj.location).length) {
                                    timeEntryRows[idx]['location_' + idx] = obj.location;
                                } else {
                                    timeEntryRows[idx]['location_' + idx] = null;
                                }
                                // calculate total hours
                                if( !isNaN(obj.timeslice_hours) && parseFloat(obj.timeslice_hours)!==0 ) {
                                    sum += parseFloat(obj.timeslice_hours);
                                }
                                // Set tags
                                if (!reset_task) {
                                    if (obj.tags && obj.tags.length) {
                                        let this_timeslice_tags = [];
                                        obj.tags.forEach(function(tag_obj,tag_index){
                                            let rand = Math.floor(1000 + Math.random() * 9000);
                                            let tag_ids = tag_obj.tag_ids.split("-");
                                            let tag = {
                                                id : 'new_'+rand,
                                                tag_name : tag_obj.name,
                                                tag_ids : tag_ids,
                                            };
                                            this_timeslice_tags.push(tag);
                                        });
                                        temp_tag_inputs.push(this_timeslice_tags);
                                    } else {
                                        let tag_arr = [];
                                        temp_tag_inputs.push(tag_arr);
                                    }
                                }
                            });
                            this.setState({
                                tempAddedTagsJson : temp_tag_inputs,
                                rowData : timeEntryRows,
                                rowKey : count,
                                dayTotal: sum.toFixed(2),
                                timesliceReqErrors : {},
                                copied_date : date
                            });
                        }
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    
    /*Add Lunch break to timeentry*/
    appendBreakRow = (breakTask = null, workLocChange = null) => {
        let rowData = this.state.rowData;
        breakTask = (breakTask !== null) ? breakTask : this.state.breakTask;
        let wlc_request = workLocChange ? workLocChange.request : this.state.work_location_change.request;
        let wlc_projects = workLocChange ? workLocChange.projects : this.state.wlc_projects;
        rowData = ((rowData.length > 0) && (rowData[0] && rowData[0]['task_0'] != undefined)) ? rowData : [];
        let rowKey = (isFirst) ? 0 : rowData.length; 
        if (isFirst) {
            isFirst = false;
        }
        let rowFields = {};
        rowFields['client_'+rowKey] = breakTask.client_code;
        rowFields['project_'+rowKey] = breakTask.project_id;
        rowFields['project_id'] = breakTask.project_id;
        rowFields['task_'+rowKey] = breakTask.task_id;
        rowFields['task_name_'+rowKey] = breakTask.task_name;
        rowFields['task_name'] = breakTask.task_name;
        rowFields['task_code_'+rowKey] = breakTask.code;
        rowFields['desc_'+rowKey] = breakTask.task_description;
        rowFields['hour_'+rowKey] = breakTask.break_time;
        rowFields['enable_location_'+rowKey] = breakTask.enable_location;
        let location = false;
        if (wlc_request && wlc_projects && wlc_projects.length)
        {
            let project_id = (breakTask.project_id) ? breakTask.project_id : null;
            location = wlc_projects.includes(project_id);
        }
        let selected_project_locations = [];
        let reqLocations =  workLocChange ? workLocChange.locations : this.state.requested_locations;
        if (reqLocations) {
            reqLocations.forEach((lc_value, lc_key) => {
                if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(breakTask.project_id)) {
                    selected_project_locations.push(lc_value);
                }
            });
        }
        rowFields['selected_project_locations_'+rowKey] = selected_project_locations;
        rowFields['wlc_'+rowKey] = (wlc_request && location) ? 1 : 0;
        if (selected_project_locations.filter(l => l.location_id == breakTask.location).length) {
            rowFields['location_'+rowKey] = breakTask.location;
        }
        else {
            rowFields['location_'+rowKey] = null;
        }
        rowFields['wlc_allowed'+rowKey] = (location) ? true : false;
        rowFields['minimum_inc_'+rowKey] = breakTask.minimum_increment;
        rowFields['client_txt_'+rowKey] = '';
        rowFields['tag_type_'+rowKey] = null;        
        rowFields['tags_'+rowKey] = null;
        rowFields['task_description_'+rowKey] = breakTask.task_description;
        rowFields['project_path_display'] = breakTask.client_name+" / "+breakTask.task_path+" / "+breakTask.task_name;
        rowFields['client_timesheet'] = breakTask.client_timesheet;
        rowFields['show_edit_button'] = true;
        rowFields['timeslice_task'] = breakTask.task_id;
        rowFields['rowKey_'+rowKey] = rowKey;
        rowFields['index'] = rowKey;
        rowFields['work_location_allowed_' + rowKey] = _R.type(breakTask.location_ids) !== 'Undefined' ? breakTask.location_ids.split(",") : [];

        rowData.push(rowFields); 
        let tempHours = [];
        Object.values(rowData).forEach(function(obj, idx){ 
            for ( var p in obj ) { 
                if (p.indexOf('hour_') > -1) tempHours.push(obj[p]);
            }
        });
        let sum = 0.00;
        let errors = [];
        let ret = _v.calculateSum(tempHours);
        if (!ret.msg) {
            sum = ret.sum;
            if(_R.type(errors['daytotal'])!=='Undefined') {
                    delete errors['daytotal'];
                }
        }
        else {
            errors['daytotal'] = ret.msg;
        }
        this.setState({
            rowKey: rowKey,
            rowData: rowData,
            errors: errors,
            dayTotal: sum
        }, function(){
            if (rowKey > 0 ) {
                let current_task_tree = document.getElementById("project-task-select"+rowKey);
                if (current_task_tree) {
                    current_task_tree.focus();
                }
            }
        });
    }
    onAddButtonClick = () => {
        if (this.props.DisableSaveButton && !this.state.isApproved) {
            this.setState({ disableSaveButton: false });
        }
        this.appendTimeEntryRow();
    }
    getLockedDetails = (lockedBy, lockedOn) => {
        this.setState({
        popupType : 'show-locked-details',
        dialogOpen : true,
        popupMsg : 'Timesheet is locked by ' +lockedBy+  '  on ' + lockedOn +  '.  Please contact your Project Manager or PMO', 
        });
    }
    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            <React.Fragment>
                <div className={'st-timeEntry_entry'}> 
                    {_R.type(this.state.rowData) !== 'Undefined' && this.state.rowData.map((block, idx) => ( 
                        <TimeEntryRowComponent 
                            RowData = {block}
                            clientProjects = {this.state.clientProjects}
                            formInputs = {this.state.formInputs}
                            HandleProjectOnChange = {this.handleProjectOnChange}
                            editedIndex = {this.state.editedIndex}
                            HandleOnChange = {this.handleOnChange}
                            HandleDescriptionChange = {this.handleDescriptionChange}
                            tempAddedTagsJson = {this.state.tempAddedTagsJson}
                            OnTagTempDelete = {this.onTagTempDelete}
                            onAddTagIconClicked = {this.onAddTagIconClicked}
                            Errors = {this.state.errors}
                            minimumInc = {this.props.MinimumInc}
                            OnEdit = {this.onEdit}
                            OnDelete = {this.onDelete}
                            FullPathTasks = {this.state.full_path_tasks}
                            HandleTaskOnChange = {this.handleTaskOnChange}
                            HandleTaskTreeChange = {this.handleTaskTreeChange}
                            Open = {this.state.open}
                            OnClose = {this.onClose}
                            TaskTreeViewState = {this.state.taskTreeViewState}
                            ToggleTreeView = {this.toggleTreeView}
                            TimesliceReqErrors = {this.state.timesliceReqErrors}
                            SelectedLocation = {this.state.selectedLocation}
                            WlcRequest = {this.state.wlcRequest}
                            RequestedLocations = {this.state.requested_locations}
                            SelectedProjectLocations = {this.state.selected_project_locations}
                            DefaultLocation = {this.state.default_location}
                            GetLockedDetails = {this.getLockedDetails}
                        />
                    ))}
                    <div className={'st-timeEntry_row-footer'}>
                        <div className={'col-summary'}>
                            <div className={'dayTotal'}>
                                <span className={'number'}>{this.state.dayTotal}</span> Hours Day Total
                                <div className={'error'}>{this.state.errors['daytotal']}</div>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={'startIcon-noMargin'}
                                    startIcon={<AddIcon />}
                                    onClick={this.onAddButtonClick}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'st-timeEntry_footer'}>
                    <div className={'st-timeEntry_footer-item left'}>
                        <Tooltip title="Reload Project &amp; Tasks" aria-label="refresh">
                            <Button color="primary" size="small" tabIndex="-1" onClick={this.reload}>
                                <CachedIcon fontSize="inherit" />&nbsp;Reload Projects
                            </Button>
                        </Tooltip>
                    </div>
                    <div className={'st-timeEntry_footer-item middle'}>
                        {/*<div className={'hours-summary-table'}>
                            <table>
                                <tr>
                                    <th colSpan={2} className={'text-right'} >
                                        <Tooltip title="Min. hours required"><span>RQD.</span></Tooltip>
                                    </th>
                                    <th>Used</th>
                                    <th>Variance</th>
                                </tr>
                                <tr>
                                    <th>Till {typeof this.state.hours_details.sdate !== 'undefined' ? this.state.hours_details.sdate : ''}</th>
                                    <td>{typeof this.state.hours_details.min_hours_till !== 'undefined' ? this.state.hours_details.min_hours_till : ''}</td>
                                    <td>{typeof this.state.hours_details.total_hours_till !== 'undefined' ? this.state.hours_details.total_hours_till : ''}</td>
                                    <td className={'error'}>{(typeof this.state.hours_details.total_hours_till !== 'undefined' && typeof this.state.hours_details.min_hours_till !== 'undefined') ? this.state.hours_details.total_hours_till - this.state.hours_details.min_hours_till : ''}</td>
                                </tr>
                                <tr>
                                    <th>Payperiod</th>
                                    <td>{typeof this.state.hours_details.min_hours !== 'undefined' ? this.state.hours_details.min_hours : ''}</td>
                                    <td>{typeof this.state.hours_details.total_hours !== 'undefined' ? this.state.hours_details.total_hours : ''}</td>
                                    <td className={'success'}>{(typeof this.state.hours_details.min_hours_till !== 'undefined' && typeof this.state.hours_details.total_hours !== 'undefined') ? this.state.hours_details.total_hours - this.state.hours_details.min_hours : ''}</td>
                                </tr>
                            </table>
                        </div>*/}
                    </div>
                    <div className={'st-timeEntry_footer-item right'}>
                        <div className={'st-timeEntry_action'}>
                            <Tooltip title="Keep form values" aria-label="reuse">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        className={'keepValue-checkbox'}
                                        checked={this.state.reuseInputValues}
                                        onChange={this.handleKeepValueChange}
                                        value="keepValue"
                                        color="primary"
                                        inputProps={{
                                            tabindex : "-1"
                                        }}
                                    />
                                    }
                                    label="Reuse input values"
                                />
                            </Tooltip>
                        </div>
                        <div className={'st-timeEntry_action'}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="btn-save"
                                startIcon={<SaveIcon />}
                                onClick={this.handleSave}
                                disabled = {((_R.type(this.state.disableSaveButton) !== 'Undefined' && (this.state.disableSaveButton)) || (this.state.buttonLoader) )? true : false}
                            >
                                Save {this.state.disableSaveButton}
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className="btn-cancel"
                                tabIndex="-1"
                                onClick={this.onReset}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </div>
                {/*Time entry page popups[STRT]*/}
                {this.state.popupType === 'time-entry-edit-reason' &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.onClose}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.onEditConfirmed()}
                    Title='Reason'
                    Content={
                        <DialogDeleteTimeEntryReason
                            HandleOnChange = {this.onDeleteConfirm}
                            Errors ={this.state.errors}
                        />
                    }
                />
                }
                {(this.state.popupType === 'duplicate-entry') &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.onClose}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.onClose}
                    Title='Time entry save failed!'
                    Content={
                        <DialogDuplicateEntry
                            Validation = {this.state.validation}
                            PopupType = {this.state.popupType}
                        />
                    }
                />
                }
                {this.state.popupType === 'time-entry-delete' &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.onClose}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.onTimeEntryDeleteConfirm}
                    Title='Confirm Delete'
                    Content={
                        <DialogWithConfirmMessage msg="Do you really want to delete this time entry ?" />
                    }
                />
                }
                {this.state.popupType === 'time-entry-delete-reason' &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.onClose}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.onDeleteConfirmed(4)}
                    Loading = {this.state.buttonLoader}
                    Title='Reason'
                    Content={
                        <DialogDeleteTimeEntryReason
                            HandleOnChange = {this.onDeleteConfirm}
                            Errors ={this.state.errors}
                        />
                    }
                />
                }
                {this.state.popupType === 'load-unsaved-data' &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.deleteUnsavedData}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.setUnsavedFormData}
                    Title='Confirm'
                    CancelButtonName={'No'}
                    Content={
                        <DialogWithConfirmMessage msg="Do you wish to reload unsaved data?" />
                    }
                />
                }
                {this.state.popupType === 'add-tags' &&
                <DialogBoxMedium
                    Open={this.state.dialogOpen}
                    Cancel={this.onClose}
                    ButtonName={this.state.dialogButton}
                    OnSubmit={this.onAddTag}
                    Title='Add Tags'
                    CancelButtonName={'Close'}
                    Content={
                        <DialogAddTags
                            Tags={tempTagsList}
                            HandleOnChange = {this.handleTagInputChange}
                            Errors ={this.state.errors}
                            TagInputs={this.state.tagInputs}
                        />
                    }
                />
                }
                {this.state.popupType === 'show-locked-details' && 
                <DialogBoxMedium
                Open={this.state.dialogOpen}
                Cancel={this.onClose}
                HideSaveButton={true}
                Title='Warning'
                OnSubmit={this.onClose}
                Content={
                    this.state.popupMsg
                }
                />
                }
                <CustomizedSnackbars
                    open={this.state.snackbarOpen}
                    variant={this.state.snackbarVariant}
                    message={successMsg}
                    handleClose={this.snackbarClose}
                />
                {/*Time entry page popups[END]*/}
            </React.Fragment>
            </>
        )
    }
}

const DialogDeleteTimeEntryReason = (props) => {
    return (
        <React.Fragment>
            <TextField
                required
                id="reason"
                name="reason"
                label="Reason"
                multiline
                variant="outlined"
                fullWidth
                onChange={props.HandleOnChange}
                error={props.Errors.confirm_reason && true}
                helperText={props.Errors.confirm_reason}
            />
        </React.Fragment>
    )
};
const DialogDuplicateEntry = (props) => {
    return (
        <div>
        { (!_R.isEmpty(props.Validation) &&
                parse(props.Validation.msg)
        )}
        </div>
    )
};
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
const DialogAddTags = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    {props.Errors.tag &&
                        <Grid item xs={12}>
                        <p className='error'>{props.Errors.tag}</p>
                        </Grid>
                    }

                    {_R.type(props.Tags) !== 'Undefined'&&_R.type(props.Tags.organization) !== 'Undefined'&&
                    <Grid item xs={6}>
                        <FormControl variant='standard'>
                            <InputLabel htmlFor="year">Account</InputLabel>
                            <Select
                                inputProps={{
                                    name: 'org',
                                    id: 'org',
                                }}
                                onChange={props.HandleOnChange}
                                value={_R.type(props.TagInputs) !== 'Undefined'&& !_R.isNil(props.TagInputs.org) && props.TagInputs.org}
                            >
                            <MenuItem value={null} key={'type-0'}>Select</MenuItem>
                            {props.Tags.organization.map((t, i) =>
                                <MenuItem key={'type-'+t.id} value={t.id} data-title={t.tag_name}>{t.tag_name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    {_R.type(props.Tags) !== 'Undefined'&&_R.type(props.Tags.contract) !== 'Undefined'&&
                    <Grid item xs={6}>
                        <FormControl variant='standard'>
                            <InputLabel htmlFor="year">Contract</InputLabel>
                            <Select
                                onChange={props.HandleOnChange}
                                value={_R.type(props.TagInputs) !== 'Undefined' && !_R.isNil(props.TagInputs.contract) && props.TagInputs.contract}
                                inputProps={{
                                    name: 'contract',
                                    id: 'contract',
                                }}
                            >
                            <MenuItem value={null} key={'type-0'}>Select</MenuItem>
                            {props.Tags.contract.map((t, i) =>
                                <MenuItem key={'type-'+t.id} value={t.id} data-title={t.tag_name} >{t.tag_name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    {_R.type(props.Tags) !== 'Undefined'&&_R.type(props.Tags.lob) !== 'Undefined'&&
                    <Grid item xs={6}>
                        <FormControl variant='standard'>
                            <InputLabel htmlFor="year">LOB</InputLabel>
                            <Select
                                onChange={props.HandleOnChange}
                                value={_R.type(props.TagInputs) !== 'Undefined' && !_R.isNil(props.TagInputs.lob) && props.TagInputs.lob}
                                inputProps={{
                                    name: 'lob',
                                    id: 'lob',
                                }}
                            >
                            <MenuItem value={null} key={'type-0'}>Select</MenuItem>
                            {props.Tags.lob.map((t, i) =>
                                <MenuItem key={'type-'+t.id} value={t.id} data-title={t.tag_name}>{t.tag_name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    {_R.type(props.Tags) !== 'Undefined'&&_R.type(props.Tags.custom) !== 'Undefined'&&
                    <Grid item xs={6}>
                        <FormControl variant='standard'>
                            <InputLabel htmlFor="year">Custom</InputLabel>
                            <Select
                                onChange={props.HandleOnChange}
                                value={_R.type(props.TagInputs) !== 'Undefined' && !_R.isNil(props.TagInputs.custom) && props.TagInputs.custom}
                                inputProps={{
                                    name: 'lob',
                                    id: 'lob',
                                }}
                            >
                            <MenuItem value={null} key={'type-0'}>Select</MenuItem>
                            {props.Tags.lob.map((t, i) =>
                                <MenuItem key={'type-'+t.id} value={t.id} data-title={t.tag_name}>{t.tag_name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                </Grid>
            </form>
        </React.Fragment>
    )
};
