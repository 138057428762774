import React from 'react';
import {DailyHoursSummaryReportComponent} from './DailyHoursSummaryReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as validations from '../../common/validate';

const YEAR = new Date().getFullYear();
const MONTH = new Date().getMonth() + 1;
const START_DATE = (MONTH < 10)?(YEAR+'-0'+MONTH+'-01'):(YEAR+'-'+MONTH+'-01'); 
const END_DATE = (MONTH < 10)?(YEAR+'-0'+MONTH+'-15'):(YEAR+'-'+MONTH+'-15');
const filterFields = {
    'start_date': START_DATE,
    'emp_status': 1,
    'employee_id': 0,
    'client_id': 0,
    'project_id': 0,
    'end_date': END_DATE,
}
const filters = {
}

export class DailyHoursSummaryReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            clients : [],
            employees : [],
            employeeStatus : [],
            open: false,
            loader: true,
            empStatus : 1,
            filterFields: filterFields,
            taskTypes: [],
            summaryData: [],
            totalData: [],
            errors : {},
            projects : [],
            showList : false
        }
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }

    componentDidMount = () => {
        this.getDailyHoursSummary();
    };
    getDailyHoursFilterSummary = () => { 
        let valid = this.validateRequest();
        if(valid == 1){

            this.getDailyHoursSummary();
        }
    }
    filterData = () => { 
        let valid = this.validateRequest();
        if(valid == 1)
        {
            this.setState({
                showList : true,
                loader : true
            }); 
            this.getDailyHoursFilterSummary();
        }
    }
    getDailyHoursSummary = () => {
        let data = this.state.filterFields;
        return httpServices.post('api/report/daily-hours-summary-report', data)
            .then(response => {
                if (response.data.errors) {
                    this.setState({
                        errors: response.data.errors,
                        loader: false
                    })
                }
                else if (response.data && !response.data.errors) {
                    this.setState({
                        permission: response.data.permission,
                        clients: response.data.clients,
                        employees: response.data.employees,
                        employeeStatus: response.data.employee_status,
                        projects: response.data.projects,
                        taskTypes: response.data.task_types,
                        summaryData: response.data.summary_data,
                        totalData: response.data.total_data,
                        loader: false
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            }); 
    };
    exportReports = () => {   
        let valid = this.validateRequest(); 
        if(valid == 1)
        { 
            document.getElementById('exp_employee_id').value = this.state.filterFields.employee_id;
            document.getElementById('exp_project_id').value = this.state.filterFields.project_id;
            document.getElementById('exp_client_id').value = this.state.filterFields.client_id;
            document.getElementById('exp_start_date').value = this.state.filterFields.start_date;
            document.getElementById('exp_end_date').value = this.state.filterFields.end_date;
            document.getElementById('export_form').submit();            
        }       
    };
    validateRequest(){ 
        let valid = 1;
        let showlist = true;
       let validationData = [
            { name: 'emp_status', value: this.state.filterFields.emp_status, type: 'integer', otherOptions: { required: false } },
            { name: 'employee_id', value: this.state.filterFields.employee_id, type: 'integer', otherOptions: { required: true } },
            { name: 'start_date', value: this.state.filterFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.filterFields.end_date, type: 'date', otherOptions: { required: true } },
            { name: 'client_id', value: this.state.filterFields.client_id, type: 'integer', otherOptions: { required: false } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        if (isValid) {
            let errors = {};
            if (this.state.filterFields.end_date <= this.state.filterFields.start_date) { 
                errors['end_date'] = 'Please select a date greater than start date';
                valid = 0;
                showlist = false;
            } 
            this.setState({ errors: errors,showList : showlist });
        } else {
            valid = 0;
            showlist = false;
            this.setState({ errors: validateErrors, showList : showlist });
       }
        return valid;
    }
    
    handleFilterChange = (evt, value) => { 
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;      
        if(name === 'emp_status') {
            f['employee_id'] = 0;  
            this.getDailyHoursData();
        }
        if(name === 'client_id') {
            f['project_id'] = 0;  
            this.getDailyHoursData();
        }
        this.setState({
            filterFields: f
        });
    }
    clearFilter = () => {
        let filterFields = {
            'start_date': START_DATE,
            'emp_status': 1,
            'employee_id': 0,
            'client_id': 0,
            'project_id': 0,
            'end_date': END_DATE,
        }
        this.setState({
            filterFields: filterFields,
            showList : false
        }, function(){
                this.getDailyHoursSummary();
        });

    }
    handleTreeChange = (evt, cNode, sNodes, action) => { 
         
        let project = "";
        if (typeof sNodes !== 'undefined' && typeof sNodes[0] !== 'undefined' && sNodes[0].project_id !== 'undefined') {
            project = sNodes[0].project_id;
         }

        let formValues = this.state.formValues;        
        //let project = cNode.project_id;
        
        let f = this.state.filterFields;
        f['project_id'] = project;        
        this.setState({
            filterFields: f
        });
    }
    getDailyHoursData = () => {
        let data = this.state.filterFields; 
        return httpServices.post('api/report/daily-hours-summary-report', data)
        .then(response => { 
            if (response.data) { 
                this.setState({ 
                    employees : response.data.employees,
                    projects : response.data.projects,
                });
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        }); 
    };
    render(){
        return(
            
            (this.state.permission) ? 
                <DailyHoursSummaryReportComponent
                    ExportReports = {this.exportReports}
                    HandleFilterChange = {this.handleFilterChange.bind(this)}
                    GetDailyHoursSummary = {this.getDailyHoursSummary.bind(this)}
                    GetDailyHoursFilterSummary = {this.getDailyHoursFilterSummary.bind(this)}
                    GetDailyHoursData = {this.getDailyHoursData.bind(this)}
                    FilterData = {this.filterData.bind(this)}
                    Errors = {this.state.errors}
                    ClearFilter = {this.clearFilter.bind(this)}
                    HandleTreeChange = {this.handleTreeChange}
                    {...this.state}
                />
                :
                <ErrorPage/>
            )
    }
}
