import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {Yearly401kSettingsComponent}  from './Yearly401k.Component';
import { ErrorPage } from '../common/error';

const formFields = {
    'id':0,
    'year': '',
    'value': '',
};

export class Yearly401kSettingsView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            yearly_settings : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
        };
        this.editYearlySettingClick = this.editYearlySettingClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddYearlySetting = this.onAddYearlySetting.bind(this);
    }
    componentDidMount = () => {
        this.getAllYearlySettings();
    };
    getAllYearlySettings = () => {
        let apiEndpoint = 'api/settings/getAllYearlySettings';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data && response.data.permission) {
                    if (response.data.yearly_settings && response.data.yearly_settings.length) {
                        let editPermission = false;
                        if (response.data.edit_permission) {
                            editPermission = true;
                        }
                        this.setState({
                            yearly_settings : response.data.yearly_settings,
                            namelist : response.data.name_list,
                            editPermission : editPermission,
                        });
                    } 
                } else {
                    this.setState({
                        hasPermission : false,
                    });

                }
            })          
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    editYearlySettingClick = (id) => () => {
        let apiEndpoint = 'api/settings/get-yearly-settings-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.yearly_settings_details) {
                        this.setState({
                            formFields : response.data.yearly_settings_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };  

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddYearlySetting = () => {
        let formFields = {
            'id':0,
            'name': '',
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;            
            if (data) {
                 httpServices.post('api/settings/saveYearlySettings', { data })
                    .then(response => {
                        if (response.data.status) {
                            if(data.id){
                                // Update DOM
                                const index = this.state.yearly_settings.findIndex(rr => rr.id === data.id);
                                let yearly_settings = this.state.yearly_settings;
                                yearly_settings[index] = data;
                                this.setState({
                                    yearly_settings : yearly_settings,
                                    open : false
                                });
                                alert("Yearly settings updated successfully");
                            } else {
                                let yearly_settings = this.state.yearly_settings;
                                data['id'] = response.data.id;
                                yearly_settings.push(data);
                                this.setState({
                                    yearly_settings : yearly_settings,
                                    open : false,
                                });
                                alert("Yearly settings saved successfully");
                            }
                        } else {
                            alert("Save failed. Duplicate record not allowed");
                             }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.year) {
            errors["year"] = "Cannot be empty";
            formIsValid = false;
        }      
        if (!this.state.formFields.value) {
            errors["value"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    render(){
        return (
            (this.state.hasPermission)
            ?
            <Yearly401kSettingsComponent
                YearlySettings = {this.state.yearly_settings} 
                YearlySettingsNames = {this.state.namelist}
                EditYearlySettingClick = {this.editYearlySettingClick }
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                onAddYearlySetting = {this.onAddYearlySetting}                
                PopupType = {this.state.popupType}
                EditPermission = {this.state.editPermission}
            />
            : <ErrorPage Message={'You have no permission to view yearly settings'} />
        )
    }
}
