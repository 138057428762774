import mixpanel from 'mixpanel-browser';
import { jwtDecode } from 'jwt-decode';
import cookie from 'react-cookies';
const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;


export default class MixpanelManager {
    static sharedInstance = MixpanelManager.sharedInstance || new MixpanelManager();

    constructor() {
        this.configMixpanel();
    }

    configMixpanel = async () => {
        if(this.isEnabled() == true) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
            await this.setIdentity();
        }
    }
    setIdentity = async () => {
        if(cookie.load(AUTH_KEY) && (this.isEnabled() == true) ) {
            let decoded = jwtDecode(cookie.load(AUTH_KEY));
            mixpanel.identify(decoded.user_id);
            mixpanel.people.set_once({'name':decoded.user_fullname})
        }
    }
    isEnabled = () => {
        if(process.env.REACT_APP_MIXPANEL_TRACKING == 'true') {
            return true;
        }
        return false;
    }
    trackEvent = async (eventstr,props={}) => {
        if(this.isEnabled() == true) {
            await mixpanel.track(eventstr,props);
        }
    }
}
