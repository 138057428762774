import React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Typography from '@mui/material/Typography';

export const UserAccountComponent = (props) => {
    let Accounts = props.Accounts;
    return (
        <>
         <div className={"listSubHeader"}>
                <Typography
                    component="h2"
                    className={"section-title"}
                    variant="button"
                    display="block"
                    gutterBottom
                    color="primary"
                >
                    Accounts
                </Typography>
            </div>
            <div className={"section-main"}>
                <div className={"section-content"}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>Auth Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {typeof Accounts != "undefined" && Accounts.length ? (
                                Accounts.map((d, index) => (
                                    <TableRow>
                                        <TableCell>{d.account_username}</TableCell>
                                        <TableCell>{d.title}</TableCell>
                                        <TableCell>{parseInt(d.account_status) === 1 ? 'Active' : (parseInt(d.account_status) === 2 ? 'Inactive' : 'Releived')}</TableCell>
                                        {(d.change_password === '1') ?
                                            <TableCell><Button variant="contained" size="small" onClick={() => props.OnChangePassword(d.account_username)}> Change Password</Button> </TableCell>
                                            :
                                            <TableCell> </TableCell>
                                        }
                                    </TableRow>
                                ))
                            ) : (
                                <></>
                            )}
                        </TableBody>
                    </Table>
                </div></div>
        </>
    );
};
