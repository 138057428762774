import React from 'react';
import { ListAnnouncementsComponent } from './ListAnnouncements.Component';
import { AddAnnouncementsComponent } from './AddAnnouncements.Component';
import { CustomizedSnackbars } from '../common/snackbars';
import { CustomProgressBar } from '../common/CustomProgressBar';

export const AnnouncementsComponent = (props) => {
    return (
        <React.Fragment>
            <CustomProgressBar
                loaderOpen={props.ShowLoader}
            />
            <AddAnnouncementsComponent
                FormFields={props.FormFields}
                Errors={props.Errors}
                AddDialogOpen={props.AddDialogOpen}
                HandleOnChange={props.HandleOnChange}
                HandleSubmit={props.HandleSubmit}
                HandleOnHtmlChange={props.HandleOnHtmlChange}
                HandleAddDialogue={props.HandleAddDialogue}
                ShowLoader={props.ShowLoader}
                DisableSubmit={props.DisableSubmit}
            />
            <ListAnnouncementsComponent
                AllAnnouncements={props.AllAnnouncements}
                HandleEditClick={props.HandleEditClick}
                FilterFields={props.FilterFields}
                HandleSearch={props.HandleSearch}
                HandleOnFilterChange={props.HandleOnFilterChange}
                HandleViewClick={props.HandleViewClick}
                ViewPageFields={props.ViewPageFields}
                DialogOpen={props.DialogOpen}
                HandleDialogCancel={props.HandleDialogCancel}
                HandleAddDialogue={props.HandleAddDialogue}
                EditPermision={props.EditPermision}
                ToggleDrawer={props.ToggleDrawer}
                Right={props.Right}
                ResetFilter={props.ResetFilter}
            />
            <CustomizedSnackbars
                open={props.SnackOpen}
                variant={props.SnackVarient}
                message={props.SnackMessage}
                handleClose={props.SnakbarClose}
            />
        </React.Fragment>
    )
}

