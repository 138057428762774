import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { DialogBoxMedium } from '../../common/dialog.medium';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';
import * as _R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';

export const LabelsComponent = (props => {
    const handleDelete = (id) => {
        props.ConfirmDelete(id);
    };
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        </Typography>
                    </div>
                    {props.EditPermission && 
                    <div className={'action'}>

                        <Tooltip title="Add Project Label" aria-label="add">
                            <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={props.OnAdd}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>

                    </div>
                    }
                </div>
                <>
                    <Grid container spacing={1}>
                        {Object.keys(props.ProjectLabels).map((row) =>
                            <Grid item>
                                {props.EditPermission ?
                                    <Chip label={props.ProjectLabels[row].name} onDelete={() => handleDelete(props.ProjectLabels[row].id)} />
                                    :
                                    <Chip label={props.ProjectLabels[row].name} />
                                }
                            </Grid>
                        )}
                    </Grid>
                </>
            </div>

            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={"Add Project Label"}
                OnSubmit={() => props.HandleSubmit()}
                Content={<DialogContentAdd FormProps={props} />}
                Loading={props.ButtonLoader}
            />
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
            <DialogBoxMedium
                Open={props.DeleteId ? true : false}
                Cancel={props.OnClose}
                ButtonName={"Delete"}
                OnSubmit={() => props.DeleteLabel()}
                Title={"Confirmation"}
                Content={
                    <DialogWithConfirmMessage
                        msg={"Do you want to remove this label from the project ?"}
                    />
                }
            />
        </React.Fragment>
    );
});
const DialogWithConfirmMessage = (props) => {
    return (
        <>
            <div>{props.msg}</div>
        </>
    )
}
const DialogContentAdd = (p) => {
    const { FormProps: props } = p;
    const filter = createFilterOptions();
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
			<Autocomplete
                        multiple
                        value={typeof props.FormFields.tags !== 'undefined' && props.FormFields.tags ?  props.FormFields.tags : [] }
                        onChange={props.HandleOnChange }
                        options={typeof props.FormInputs.all_labels !== 'undefined' ? props.FormInputs.all_labels : []}
                        getOptionLabel={(option) => {
			    if (!option.id ) {
                                return option.name+' (New Label)';
                            }
                            else {
                                return option.name;
			    }
                        }}
			getOptionSelected={(option, value) => option.id === value.id}
                        filterOptions={(options, params) => {
                            let filtered = filter(options, params);
			    if ((params.inputValue).trim().length < 3) {
                                 return [];
                            }
                            if ((params.inputValue).trim() !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `${params.inputValue}`,
                                    id : 0,
                                });
                                return filtered;
                            }
                        }}
			autoHighlight={true}
                        filterSelectedOptions = {true}
			id="label-demo"
			renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                            <Chip key={index} {...getTagProps({ index })}
                                label={option.name}
                            />
                            ))
                        }
                        renderInput={params => (
                            <TextField {...params} label="Lable Name" variant="outlined"
                            />
                        )}
                    	/>
	    	    </FormControl> 
                </Grid>
            </Grid>
        </form>
    )
}

