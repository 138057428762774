import React from 'react';
import { httpServices } from '../../services/httpServices';
import ReactHtmlParser from 'html-react-parser';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';
import { history } from '../../helpers/history';
import * as _R from 'ramda';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export class MessagesList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messages : [],
            permission : true,
            selectedMessages : [],
            rowsPerPage : 10,
            page : 0,
            showLoader : true,
            dataLoaded : false
        }
        this.listMessages = this.listMessages.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.getMessage = this.getMessage.bind(this);
        this.submitAction = this.submitAction.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount = () => {
        this.listMessages();
    };

    listMessages = () =>{
        let apiEndpoint = 'api/messages/listMessages';
        return httpServices.post(apiEndpoint)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            messages : response.data.messages,
                            permission : response.data.permission,
                            dataLoaded : true,
                            showLoader : false
                        });
                    } else {
                        this.setState({
                            dataLoaded : true,
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleSelection = (evt,value) => {
        let messages = this.state.selectedMessages;
        let message = evt.target.value;
        if (message === 'all') {
            if (value) {
                messages.push('all');
                Object.values(this.state.messages).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                    if (messages.indexOf(row.msg_id) === -1){
                        messages.push(row.msg_id);
                    }
                });
            }
            else {
                messages = [];
            }
        }
        else {
            let message_id = parseInt(message);
            if (value) {
                messages.push(message_id);
            }
            else {
                let index = messages.indexOf(message_id);
                if (index !== -1) {
                    messages.splice(index,1);
                }
                index = messages.indexOf('all');
                if (index !== -1) {
                    messages.splice(index,1);
                }
            }
        }
        this.setState({
            selectedMessages: messages,
        });
    };

    getMessage  = (id) => () => {
        history.push(BASE_PATH+'/profile/messages/view/'+id);
    };

    submitAction = (action) => () => {
        let selected_messages = this.state.selectedMessages;
        if (selected_messages.length) {
            this.setState({showLoader : true});
            let apiEndpoint = 'api/messages/listMessages';
            return httpServices.post(apiEndpoint,{action : action ,messages: this.state.selectedMessages})
            .then(response => {
                if (response.data.success) {
                    let messages = this.state.messages;
                    if (action === 'delete') {
                        messages = response.data.messages
                    } else
                    {
                        selected_messages.forEach((message_id) => {
                            if(message_id !== 'all') {
                                let index =  messages.findIndex(x => x.msg_id === message_id);
                                messages[index]['msg_status'] = action === 'read' ? 1 : 0;
                            }
                        });
                    }
                    this.setState({
                        messages : messages,
                        selectedMessages : [],
                        showLoader : false
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page : newPage, 
            selectedMessages : []
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page : 0,
            rowsPerPage : event.target.value,
            selectedMessages : []
        });
    };

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
             { (this.state.dataLoaded) ?
                 <>
                 { (this.state.permission) ? 
                     <MessagesListComponent
                        Messages = { this.state.messages}
                        SelectedMessages = {this.state.selectedMessages}
                        HandleSelection = {this.handleSelection}
                        GetMessage = {this.getMessage}
                        SubmitAction = {this.submitAction}
                        HandleChangePage = {this.handleChangePage}
                        HandleChangeRowsPerPage = {this.handleChangeRowsPerPage}
                        Page = {this.state.page}
                        RowsPerPage = {this.state.rowsPerPage}
                    />
                    :
                    <ErrorPage/>
                 }
                </>
                :<></>
             }
            </>
        )}
}

const MessagesListComponent = (props) =>{
    return(
        <>
            <div className={'section-body'}>
                <div className={"listSubHeader"}>
                    <Typography
                        component="h2"
                        className={"section-title"}
                        variant="button"
                        display="block"
                        gutterBottom
                        color="primary"
                    >Messages</Typography>
                </div>
                <Paper className="p1">
                { (props.Messages.length>0) ?
                    <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox 
                                    name = 'message' 
                                    value = {'all'} 
                                    checked = {_R.includes('all', props.SelectedMessages)? true : false}
                                    onChange = {props.HandleSelection}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <Tooltip title="Mark as read" aria-label="mark_read">
                                    <IconButton  edge="end" disabled = { props.SelectedMessages.length ? false : true} onClick={props.SubmitAction('read')}>
                                        <DraftsOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Mark as unread" aria-label="mark_unread">
                                    <IconButton className="ml-1"  disabled = { props.SelectedMessages.length ? false : true} edge="end" onClick={props.SubmitAction('unread')}>
                                        <MarkunreadMailboxOutlinedIcon  />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton  edge="end" className="ml-1" disabled = { props.SelectedMessages.length ? false : true} onClick={props.SubmitAction('delete')} >
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.keys(props.Messages).slice(props.Page * props.RowsPerPage, props.Page * props.RowsPerPage + props.RowsPerPage).map((row) => (
                        <TableRow key={props.Messages[row].id}>
                            <TableCell  padding="checkbox">
                                <Checkbox
                                    name='message'
                                    value={props.Messages[row].msg_id}
                                    onChange={props.HandleSelection}
                                    checked={_R.includes(props.Messages[row].msg_id, props.SelectedMessages)?true:false}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className ="link" onClick = {props.GetMessage(props.Messages[row].msg_id)}>
                                {(parseInt(props.Messages[row].msg_status)===1)?props.Messages[row].msg_subject:<b>{props.Messages[row].msg_subject}</b>}
                            </TableCell>
                            <TableCell align="left" style = {{width : '15%'}}>{props.Messages[row].msg_ctime}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    </Table>
                    : <div className="p1">No messages</div>
                }
                </Paper>
                {props.Messages.length ?
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={props.Messages.length}
                        rowsPerPage={props.RowsPerPage}
                        page={props.Page}
                        onPageChange={props.HandleChangePage}
                        onRowsPerPageChange={props.HandleChangeRowsPerPage}
                    />
                    : <></>}
            </div>
        </>
    )
}

