import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _R from 'ramda';
import { formatName } from '../../common/common';
import Tooltip from '@mui/material/Tooltip';
export class StaffDetailsForm extends React.Component{
    render(){
        let projects = ( _R.type(this.props.FormInputs) !== 'Undefined' && _R.type(this.props.FormInputs.projects) !== 'Undefined') ? this.props.FormInputs.projects : [];
        return (
            <>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Work Details</Typography>
                <Grid container spacing={6}>
                <Grid  item xs={12} md={2}>
                        <FormControl error={this.props.Errors.staff_type && true} variant="standard">
                            <InputLabel htmlFor="staff_type" required >Staff Type</InputLabel>
                             <Select
                                value={(typeof this.props.FormFields.staff_type !== 'undefined') ? this.props.FormFields.staff_type : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'staff_type',
                                    id: 'staff_type',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.staff_types !== 'undefined' && this.props.FormInputs.staff_types.map((t, i) =>
                                    <MenuItem value={t.id} key='stafftype-{t.id}'>{t.type}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.staff_type}</FormHelperText>
                        </ FormControl>
                        </Grid>
                        <Grid  item xs={12} md={2}>
                        <FormControl error={this.props.Errors.employment_type && typeof this.props.FormFields.staff_type !== 'undefined' && this.props.FormFields.staff_type == 1 && true} variant="standard">
                            <InputLabel htmlFor="employment_type" required = { (typeof this.props.FormFields.staff_type !== 'undefined' && this.props.FormFields.staff_type == 1) ? true : false}>Employment Type</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.employment_type !== 'undefined') ? this.props.FormFields.employment_type : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'employment_type',
                                    id: 'employment_type',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.employee_types !== 'undefined' && this.props.FormInputs.employee_types.map((t, i) =>
                                    <MenuItem value={t.id} key='emptype-{t.id}'>{t.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.employment_type}</FormHelperText>
                        </ FormControl>
                         </Grid>
                        <Grid  item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id="date_joined"
                                name="date_joined"
                                label="Date Joined"
                                type="date"
                                required
                                value={(typeof this.props.FormFields.date_joined !== 'undefined') ? this.props.FormFields.date_joined : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={this.props.Errors.date_joined && true}
                                helperText={this.props.Errors.date_joined}
                            />
                        </ FormControl>
                        </Grid>
            </Grid>
             
             <Grid container spacing={6}>
                <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel>Job Title</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.job_title !== 'undefined') ? this.props.FormFields.job_title : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'job_title',
                                    id: 'job_title',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.job_titles != 'undefined' && this.props.FormInputs.job_titles.map((s, i) =>
                                    <MenuItem value={s.id} key='titled-{s.id}'>{s.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
            </Grid>
             <Grid container spacing={6}>
                    <Grid  item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="project_title"
                                name="project_title"
                                label="Project Title"
                                type="text"
                                required
                                value={(typeof this.props.FormFields.project_title !== 'undefined') ? this.props.FormFields.project_title : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.project_title && true}
                                helperText={this.props.Errors.project_title}
                            />
                        </ FormControl>
                     </Grid>
                   </Grid>
                    {(typeof this.props.FormFields.designation !== 'undefined' && this.props.FormFields.designation) &&
                         <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
    
                        <FormControl variant="standard">
                            <InputLabel htmlFor="titled">Titled employee</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.titled !== 'undefined') ? this.props.FormFields.titled : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'titled',
                                    id: 'titled',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.yes_no_options != 'undefined' && this.props.FormInputs.yes_no_options.map((s, i) =>
                                    <MenuItem value={s.value} key='titled-{s.id}'>{s.title}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                        </Grid></Grid>
                    }
                    
                      <Grid container spacing={6}>
                           <Grid  item xs={12} md={2}>
                        <FormControl error={this.props.Errors.reviewer_id && true} variant="standard">
                            <InputLabel htmlFor="reviewer_id" required>Supervisor</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.reviewer_id !== 'undefined') ? this.props.FormFields.reviewer_id : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'reviewer_id',
                                    id: 'reviewer_id',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.users != 'undefined' && this.props.FormInputs.users.map((s, i) =>
                                    <MenuItem value={s.user_id} key='supervisor-{s.user_id}'>{formatName(s.first_name,null,s.last_name)}</MenuItem>
                                )}
                            </Select>
                        <FormHelperText>{this.props.Errors.reviewer_id}</FormHelperText>
                        </ FormControl>
                         </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id="supervisor_start_date"
                                name="supervisor_start_date"
                                label="Start Date"
                                type="date"
                                value={(typeof this.props.FormFields.supervisor_start_date !== 'undefined') ? this.props.FormFields.supervisor_start_date : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={this.props.Errors.supervisor_start_date && true}
                                helperText={this.props.Errors.supervisor_start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id="supervisor_end_date"
                                name="supervisor_end_date"
                                label="End Date"
                                type="date"
                                value={(typeof this.props.FormFields.supervisor_end_date !== 'undefined') ? this.props.FormFields.supervisor_end_date : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={this.props.Errors.supervisor_end_date && true}
                                helperText={this.props.Errors.supervisor_end_date}
                            />
                        </ FormControl>
                    </Grid>
                         </Grid>
                        <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl error={this.props.Errors.category && true} variant="standard">
                            <InputLabel htmlFor="category" required>Category</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.category !== 'undefined') ? this.props.FormFields.category : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'category',
                                    id: 'category',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.category != 'undefined' && this.props.FormInputs.category.map((v, i) =>
                                    <MenuItem value={v.value} key='category-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        <FormHelperText>{this.props.Errors.category}</FormHelperText>
                        </FormControl>
                         </Grid></Grid>
                         <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="overtime">Overtime</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.overtime !== 'undefined') ? this.props.FormFields.overtime : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'overtime',
                                    id: 'overtime',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.overtime != 'undefined' && this.props.FormInputs.overtime.map((v, i) =>
                                    <MenuItem value={v.value} key='overtime-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                         </Grid></Grid>
                    { (this.props.FormFields.category) === 'salary' && (this.props.FormFields.overtime) === 'exempt' &&
                       <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="daily_minimum"
                                name="daily_minimum"
                                label="Daily Minimum"
                                type="text"
                                value={(this.props.FormFields.daily_minimum !== 'undefined') ? this.props.FormFields.daily_minimum : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.daily_minimum && true}
                                helperText={this.props.Errors.daily_minimum}
                            />
                        </ FormControl>
                        </Grid></Grid>
                    }

                        <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="weekly_limit"
                                name="weekly_limit"
                                label="Weekly limit"
                                type="text"
                                value={(typeof this.props.FormFields.weekly_limit !== 'undefined') ? this.props.FormFields.weekly_limit : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.weekly_limit && true}
                                helperText={this.props.Errors.weekly_limit}
                            />
                        </ FormControl>
                        </Grid></Grid>
                       
                        <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControlLabel variant="standard"
                            control={
                                <Checkbox 
                                    checked={(typeof this.props.FormFields.include_holidays !== 'undefined') ? this.props.FormFields.include_holidays : false}  
                                    value={(typeof this.props.FormFields.include_holidays !== 'undefined') ? this.props.FormFields.include_holidays : null}
                                    id = "include_holidays" 
                                    name ="include_holidays"
                                    onChange={this.props.HandleOnChange}
                                />
                            }
                            label="Include Holidays"
                        />
                        </Grid></Grid>
                 
                      <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="timesheet_multiple_review">Timesheet Review Method</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.timesheet_multiple_review !== 'undefined' && this.props.FormFields.timesheet_multiple_review) ? this.props.FormFields.timesheet_multiple_review : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'timesheet_multiple_review',
                                    id: 'timesheet_multiple_review',
                                }}>
                                { typeof this.props.FormInputs.ts_review_method != 'undefined' && this.props.FormInputs.ts_review_method.map((v, i) =>
                                    <MenuItem value={v.value} key='ts_review_method-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                         </Grid></Grid>

                         <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="expense_eligibility">Expense Eligibility</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.expense_eligibility !== 'undefined') ? this.props.FormFields.expense_eligibility : 0}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'expense_eligibility',
                                    id: 'expense_eligibility',
                                }}>
                                { typeof this.props.FormInputs.expense_eligibility != 'undefined' && this.props.FormInputs.expense_eligibility.map((v, i) =>
                                    <MenuItem value={v.value} key='expense_eligibility-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                       </Grid></Grid>
                    { parseInt(this.props.FormFields.expense_eligibility) === 1 && 
                         <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="expense_limit_exempt">Expense Limit Exempt</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.expense_limit_exempt !== 'undefined') ? this.props.FormFields.expense_limit_exempt : 0}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'expense_limit_exempt',
                                    id: 'expense_limit_exempt',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.expense_limit_exempt != 'undefined' && this.props.FormInputs.expense_limit_exempt.map((v, i) =>
                                    <MenuItem value={v.value} key='expense_limit_exempt-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid></Grid>
                    }
                    {typeof this.props.FormInputs.display_employee_code != 'undefined' && this.props.FormInputs.display_employee_code &&
                     <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="employee_id"
                            name="employee_id"
                            label="Employee Code"
                            type="text"
                            value={(typeof this.props.staffData!= 'undefined' && this.props.staffData.employee_id) ? this.props.staffData.employee_id : null}
                            onChange={this.props.HandleOnChange}
                        />
                    </ FormControl>
                    </Grid></Grid>
                    }
                      <Grid container spacing={6}>
                           <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="organization">Organization</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.organization !== 'undefined') ? this.props.FormFields.organization : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'organization',
                                    id: 'organization',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.organizations != 'undefined' && this.props.FormInputs.organizations.map((v, i) =>
                                    <MenuItem value={v.client_id} key='organization-{v.client_id}'>{v.client_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                         </Grid></Grid>
                     <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                             <div class="project-column-title"> Main Project </div>
                             <input type='hidden' name='projects' value={this.props.FormFields.main_project} />
                             <TreeSelectComponent
                             data = {projects}
                             placeholderText = { "Search Projects"}
                             type = 'project'
                             dropdown_mode = 'true'
                             HandleTreeChange = {this.props.HandleTreeChange}
                             />
                        </FormControl>
                         </Grid></Grid>
                      <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="disable_req_approval">Disable Request approval</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.disable_req_approval !== 'undefined') ? this.props.FormFields.disable_req_approval : 0}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'disable_req_approval',
                                    id: 'disable_req_approval',
                                }}>
                                { typeof this.props.FormInputs.disable_req_approval != 'undefined' && this.props.FormInputs.disable_req_approval.map((v, i) =>
                                    <MenuItem value={v.value} key='disable_req_approval-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid></Grid>
             
                        <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="pay_period">Pay Period</InputLabel>
                            <Select
                                value={this.props.FormFields.pay_period}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'pay_period',
                                    id: 'pay_period',
                                }}>
                                { typeof this.props.FormInputs.pay_periods != 'undefined' && this.props.FormInputs.pay_periods.map((v, i) =>
                                    <MenuItem value={v.value} key='pay_period-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid></Grid>

                     <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="time_inout">Time In/Out</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.time_inout !== 'undefined') ? this.props.FormFields.time_inout: 0}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'time_inout',
                                    id: 'time_inout',
                                }}>
                                { typeof this.props.FormInputs.time_inout != 'undefined' && this.props.FormInputs.time_inout.map((v, i) =>
                                    <MenuItem value={v.value} key='time_inout-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid></Grid>
                        { parseInt(this.props.FormFields.time_inout) === 1 && 

                            <>
                        <Grid container spacing={6}>
                        <Grid  item xs={12} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="time_inout_required">Time In/Out Required</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.time_inout_required !== 'undefined') ? this.props.FormFields.time_inout_required: 0}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'time_inout_required',
                                    id: 'time_inout_required',
                                }}>
                                { typeof this.props.FormInputs.time_inout != 'undefined' && this.props.FormInputs.time_inout.map((v, i) =>
                                    <MenuItem value={v.value} key='time_inout_required-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                       </Grid>
                        <Grid  item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id="time_inout_edate"
                                name="time_inout_edate"
                                label="Time In/Out Effective Date"
                                type="date"
                                value={(typeof this.props.FormFields.time_inout_edate!== 'undefined') ? this.props.FormFields.time_inout_edate: null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                            />
                        </ FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl error={this.props.Errors.entry_time && true} variant="standard">
                                <label>Working Time Start</label>
                                <Tooltip title="Format: HH:MM" placement="top-start" arrow>
                                    <TextField
                                        id="entry_time"
                                        name="entry_time"
                                        type="time"
                                        defaultValue="HH:MM"
                                        value={(typeof this.props.FormFields.entry_time !== 'undefined') ? this.props.FormFields.entry_time : null}
                                        onChange={this.props.HandleOnChange}
                                        error={this.props.Errors.entry_time && true}
                                        helperText={this.props.Errors.entry_time}
                                    />
                                </Tooltip>
                            </ FormControl>
                        </Grid>
                        <Grid  item xs={12} md={2}>
                            <FormControl error={this.props.Errors.exit_time && true} variant="standard">
                                <label>Working Time End</label>
                                <Tooltip title="Format: HH:MM" placement="top-start" arrow>
                                    <TextField
                                        id="exit_time"
                                        name="exit_time"
                                        type="time"
                                        defaultValue="HH:MM"
                                        value={(typeof this.props.FormFields.exit_time !== 'undefined') ? this.props.FormFields.exit_time : null}
                                        onChange={this.props.HandleOnChange}
                                        error={this.props.Errors.exit_time && true}
                                        helperText={this.props.Errors.exit_time}
                                    />
                                </Tooltip>
                            </ FormControl> 
                            </Grid></Grid>
                            </>
                        }
                    <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="owners_relatives">Owner or Relative</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.owners_relatives !== 'undefined') ? this.props.FormFields.owners_relatives : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'owners_relatives',
                                    id: 'owners_relatives',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.owner_or_relative != 'undefined' && this.props.FormInputs.owner_or_relative.map((v, i) =>
                                    <MenuItem value={v.value} key='owners_relatives-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="clearance">Clearance Level</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.clearance_level_id !== 'undefined') ? this.props.FormFields.clearance_level_id : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'clearance_level_id',
                                    id: 'clearance_level_id',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.clearance_levels != 'undefined' && this.props.FormInputs.clearance_levels.map((v, i) =>
                                    <MenuItem value={v.id} key='clearance-{v.id}'>{v.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl error={this.props.Errors.timeoff_eligibility && true} variant="standard">
                            <InputLabel htmlFor="timeoff_eligibility">Timeoff Eligibility Start Date</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.timeoff_eligibility !== 'undefined') ? this.props.FormFields.timeoff_eligibility : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'timeoff_eligibility',
                                    id: 'timeoff_eligibility',
                                }}>
                                { typeof this.props.FormInputs.timeoff_eligibility != 'undefined' && this.props.FormInputs.timeoff_eligibility.map((v, i) =>
                                    <MenuItem value={v.value} key='timeoff_eligibility-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.timeoff_eligibility}</FormHelperText>
                        </FormControl>
                        </Grid>
                    </Grid>
                    {(this.props.FormFields.timeoff_eligibility == 'custom_start_date') && <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl error={this.props.Errors.doj_override_date && true}>
                            <TextField variant="standard"
                                id="doj_override_date"
                                name="doj_override_date"
                                label="Joining Date Override"
                                type="date"
                                value={(typeof this.props.FormFields.doj_override_date !== 'undefined') ? this.props.FormFields.doj_override_date : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={this.props.Errors.doj_override_date && true}
                                helperText={this.props.Errors.doj_override_date}
                            />
                        </ FormControl>
                        </Grid>
                    </Grid>}
                    {(this.props.FormFields.timeoff_eligibility == 'custom_start_date' || this.props.FormFields.timeoff_eligibility == 'joining_date') && <Grid container spacing={6}>
                        <Grid  item xs={12} md={6}>
                        <FormControl error={this.props.Errors.include_timeoff_eligibility_service_period && true} variant="standard">
                            <InputLabel htmlFor="include_timeoff_eligibility_service_period">Timeoff Eligibility Service Perid</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.include_timeoff_eligibility_service_period !== 'undefined') ? this.props.FormFields.include_timeoff_eligibility_service_period : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'include_timeoff_eligibility_service_period',
                                    id: 'include_timeoff_eligibility_service_period',
                                }}>
                                { typeof this.props.FormInputs.include_timeoff_eligibility_service_period != 'undefined' && this.props.FormInputs.include_timeoff_eligibility_service_period.map((v, i) =>
                                    <MenuItem value={v.value} key='include_timeoff_eligibility_service_period-{v.id}'>{v.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.include_timeoff_eligibility_service_period}</FormHelperText>
                        </FormControl>
                        </Grid>
                    </Grid>}
        </>        
        )
    }
};
