import React, { Component } from 'react';
import { httpServices } from '../../services/httpServices';
import { StaffConstants } from '../../constants';
import { StaffView } from './StaffView.Component';
import * as _R from 'ramda';
import { ErrorPage } from '../common/error';
import { Tabs } from '../../redux/store/reducers/tabs';
import { connect } from "react-redux";


const tabs = {
    '0' : 'PERSONAL',
    '1' : 'CONTACT',
    '2' : 'WORK',
    '3' : 'PROJECT',
    '4' : 'SALARY',
    '5' : 'BENEFITS',
    '6' : 'TIMEOFF',
    '7' : 'APPRAISAL',
    '8' : 'FAMILY',
    '9' : 'TIMESHEET',
    '10' : 'CONFIG',
    '11' : 'NOTE',
    '12' : 'FILES',
    '13' : 'HISTORY'
}
class StaffViewConatainer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            right: false,
            anchorEl: null,
            open: false,
            userID: 0,
            selTab: 0,
            staffID: 0,
            staffDetails:{},
            staffBasicDetails: {},
            permission: true,
            fileOpen : ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.fetchUserByID = this.fetchUserByID.bind(this);
        this.handlePhotoClick = this.handlePhotoClick.bind(this);
        this.filterView = this.filterView.bind(this);
    }
    componentDidMount() {
        if (this.props.selectedStaffTab === 0) {
            this.fetchUserByID(this.props.match.params.id, this.state.staffID);
        }
        else
        {   
            this.setStaffBasicDetails(this.props.match.params.id);
        }
    }
    setStaffBasicDetails = (userID) => {
        let apiEndpoint = StaffConstants.API_STAFF_VIEW;
        return httpServices.post(apiEndpoint, { 'id': userID })
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                            this.setState({
                                userID: userID,
                                staffID: response.data.data.staff_id,
                                staffBasicDetails: response.data.data,
                                permission: response.data.permission,
                                year:0,
                                selTab:this.props.selectedStaffTab
                            }, () => {
                                this.fetchUserByID(this.props.match.params.id, this.state.staffID, tabs[this.props.selectedStaffTab], this.state.year);
                            });
                    }                    
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    fetchUserByID = (userID, staffID, module, year) => {
        let apiEndpoint = StaffConstants.API_STAFF_VIEW;
        if (typeof module !== 'undefined' && module !== 'PERSONAL') {
            apiEndpoint = StaffConstants[module];
        }
        return httpServices.post(apiEndpoint, { 'id': userID, 'staffID': staffID, 'module': module, 'year': year })
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        let userDetails = {};
                        if (typeof module != 'undefined') {
                            userDetails[module.toLowerCase()] = response.data;
                            this.setState({
                                userID: userID,
                                staffDetails: userDetails,

                            });
                        }
                        else {
                            userDetails[userID] = response.data.data;
                            this.setState({
                                userID: userID,
                                staffID: response.data.data.staff_id,
                                staffBasicDetails: userDetails[userID]
                            });
                        }
                    }
                    this.setState({
                        permission: response.data.permission,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };

    handleChange = (event, value) => {
        const {staffID} = this.state;
        const { dispatch } = this.props;
        dispatch(Tabs.actions.setSelectedTabIndex({page : "staff-view", selected : value}));
        this.setState({value, staffID: staffID});
        if (value !== 0) { 
            this.setState({
                selTab: value,
                year:0
            });
            this.fetchUserByID(this.props.match.params.id, staffID, tabs[value]);
        }
        
    };
    handleMenuOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };
    handleOpenModal = () => {
        this.setState({
            anchorEl: null,
            open: true
        });
    };
    handleCloseModal = () => {
        this.setState({
            open: false
        });
    }
    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }    
    updateChange = () =>{
        this.fetchUserByID(this.props.match.params.id, this.state.staffID).then(()=>{ this.updateModule(); });
    }
    updateModule = () =>{
        this.fetchUserByID(this.props.match.params.id, this.state.staffID, tabs[this.state.value]);
    }
    handlePhotoClick = (url) => {
        this.setState({
            fileOpen: true,
            fileUrl: url
        });
    }
    filterView = (anchor, open) => (event) => {
        this.setState({ fileOpen: open });
    };
    render(){
        const { classes,permission} = this.state;
        const staffDetails= this.state.staffDetails;
        const staffBasicDetails = this.state.staffBasicDetails;
        return( 
            (this.state.selTab === 0) ?
            (
                (permission) ?
                <StaffView
                classes={classes}
                value={this.state.value}
                userID={this.props.match.params.id}
                staffID={this.state.staffID}
                handleChange={this.handleChange}
                anchorEl={this.state.anchorEl}
                staffDetails={ staffDetails }
                staffBasicDetails = {staffBasicDetails}
                updateChange={this.updateChange}
                handlePhotoClick = {this.handlePhotoClick}
                filterView = {this.filterView}
                fileUrl = {this.state.fileUrl}
                fileOpen = {this.state.fileOpen}
                />
                :
                (typeof (permission) !== 'undefined'
                    ?
                    <ErrorPage/>
                    :
                    <></>
                )
            )
            :
            (
                <StaffView
                classes={classes}
                value={this.state.value}
                userID={this.props.match.params.id}
                staffID={this.state.staffID}
                Permission={this.state.permission}
                handleChange={this.handleChange}
                anchorEl={this.state.anchorEl}
                staffBasicDetails = {staffBasicDetails}
                staffDetails={ staffDetails }
                updateChange={this.updateChange}
                handlePhotoClick = {this.handlePhotoClick}
                filterView = {this.filterView}
                fileUrl = {this.state.fileUrl}
                fileOpen = {this.state.fileOpen}
                />
            )
        );
    }
}
const mapStateToProps = (state) => {
    const selectedStaffTab = state.Tabs.allTabs['staff-view'] ? state.Tabs.allTabs['staff-view'] : 0;
    return {
        selectedStaffTab: selectedStaffTab
    }
}
const connectedStaffView = connect(mapStateToProps)(StaffViewConatainer);
export { connectedStaffView as StaffView };
