import React  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as R from 'ramda';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import { formatName} from '../../common/common';

export const RequestList  = props => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table" className={'list-table_basic steps-table'}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        {(props.Tab !== 'my') && 
                            <TableCell>Staff Name</TableCell>
                        }
                        <TableCell>Year</TableCell>
                        <TableCell>Reason</TableCell> 
                        <TableCell>Duration</TableCell>
                        <TableCell>Status</TableCell>
                        {(props.Tab !== 'my') &&
                            <TableCell>Submitted by</TableCell>
                        }
                        <TableCell>Submitted on</TableCell>
                        <TableCell>Reviewed by</TableCell>
                        <TableCell>Reviewed on</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={'tbody'}>
                     {(props.Requests.length === 0) &&
                         <TableRow >
                            <TableCell colSpan="5">No Additional PTO Requests found.</TableCell>
                        </TableRow>
                    }
                    {R.type(props.Requests) !== 'Undefined'  && props.Requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                    <TableRow hover>
                        <TableCell><a className={'item-link'} onClick={()=>props.HandleViewClick(row.id)}>{row.id}</a></TableCell>
                        {(props.Tab !== 'my') &&
                            <TableCell>{formatName(row.user_first_name,null,row.user_last_name)}</TableCell>
                        }
                        <TableCell>{row.year}</TableCell>
                        <TableCell>{(row.description.length > 20) ? row.description.substring(0, 20)+'...':row.description}</TableCell>
                        <TableCell>{row.hours.toFixed(2)} Hours</TableCell>
                        <TableCell>{row.request_status_name}</TableCell>
                        {(props.Tab !== 'my') &&
                            <TableCell>{formatName(row.cby_first_name,null,row.cby_last_name)}</TableCell>
                        }
                        <TableCell>{row.created_on}</TableCell>
                        <TableCell>{formatName(row.reviewer_first_name,null,row.reviewer_last_name)}</TableCell>
                        <TableCell>{parseInt(row.reviewed_on) === 0 ? "Never" : row.reviewed_on}</TableCell>
                         <TableCell align="right">{(props.Tab === 'my' && parseInt(row.status) === 1) &&
                             <Tooltip title="Edit" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={()=>props.HandleEditClick(row.id)}/>
                                    </IconButton>
                                </Tooltip>
                               } 
                             <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(row.id)}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={props.Requests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </>
    )
}

