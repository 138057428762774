import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as _R from 'ramda';
import { styled } from '@mui/material/styles';

const DialogBox = styled(Dialog)(({ theme }) => ({
    '& .root': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .wrapper': {
    margin: theme.spacing(1),
    position: 'relative',
  },  
  '& .buttonProgress': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  '& .dialogContent': {
    paddingTop : '20px !important',
    	  
  },	
  '& .dialogTitle': {
    color: '#333',
    fontSize: '.9rem', 
    fontWeight: '700', 
    letterSpacing: '.005em', 
    textTransform: 'uppercase'
  },
}));

export const DialogBoxMedium = (props) => {
    return (
        <DialogBox
                className={'modal_default'}
                open={props.Open}
                aria-labelledby="scroll-dialog-title"
                disableEnforceFocus
            >
                <DialogTitle id="scroll-dialog-title" className='dialogTitle'>{props.Title}</DialogTitle>
                <DialogContent className={'dialogContent'}>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                    {props.Content}
                </DialogContent>
                <DialogActions>
                    {!(props.HideCancel === true) && 
                    <Button color="primary" size="small" onClick={props.Cancel}>{props.CancelButtonName ? props.CancelButtonName : 'Cancel'}</Button>
                    }
                    {props.HideSaveButton ?
                     <></>
                    :
                    <>{props.Disabled ?
                        <Button variant="contained" size="small" color="primary" disabled onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                    :
                        <div className={'root'}>
                            <div className={'wrapper'}>
                                <Button variant="contained" size="small" color="primary" disabled = {_R.type(props.Loading) !== 'Undefined' && props.Loading} onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                                { _R.type(props.Loading) !== 'Undefined' && props.Loading && <CircularProgress size={24} className={'buttonProgress'} />}
                            </div>
                        </div>
                    }</>
                    }
                    {props.DisplayNoButton && 
                        <Button variant="contained" size="small" color="primary" onClick={props.OnNoClick}>No</Button>
                    }
                </DialogActions>
            </DialogBox> 
    )
}

