import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { TimeInOut} from '../timeEntry/TimeInOut';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../../common/common';

export const SearchInOutComponent = (props) => {
    console.log(props);
    const gridStyle = {
        margin: '15px 0px'
    };
    return (
        <React.Fragment>    
            <Paper md={12}>
                <form className="form-fullwidth">
                    <Grid container spacing="3" style={gridStyle}>
                        <Grid item  md={2} className='justify-between'>
                            <FormControl variant='standard'>
                                <InputLabel htmlFor="employee_status">Staff status</InputLabel>
                                <Select
                                    value={(typeof props.timesheetFields.employee_status !== 'undefined') ? props.timesheetFields.employee_status : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'employee_status',
                                        id: 'employee_status',
                                    }}>
                                { typeof props.timesheetInputs != 'undefined' && props.timesheetInputs.employee_status.map((t, i) =>
                                    <MenuItem value={t.value}>{t.title}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item  md={2}>
                            <FormControl variant='standard'>
                                <InputLabel htmlFor="employee">Staff</InputLabel>
                                <Select
                                    value={(typeof props.timesheetFields.staff !== 'undefined') ? props.timesheetFields.staff : null}
                                    onChange={props.HandleOnChange}
                                     error = { props.Errors.staff && true}
                                    inputProps={{
                                        name: 'staff',
                                        id: 'staff',
                                    }}>
                                    <MenuItem value='0'>-Select-</MenuItem>
                                    {  typeof props.timesheetInputs.staff_list != 'undefined' && props.timesheetInputs.staff_list.map((t, i) =>
                                        <MenuItem value={t.user_id}>{ formatName(t.first_name,null,t.last_name)}</MenuItem>
                                    )}
                                </Select>
                                { props.Errors.staff && <FormHelperText error>{props.Errors.staff}</FormHelperText> }
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField
                                id = "start_date"
                                name = "start_date"
                                label = "From Date"
                                type = "date"
                                required
                                onChange = {props.HandleOnChange}
                                value={(typeof props.timesheetFields.start_date !== 'undefined') ? props.timesheetFields.start_date : null}
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField
                                id = "end_date"
                                name = "end_date"
                                label = "To Date"
                                type = "date"
                                required
                                value={(typeof props.timesheetFields.end_date !== 'undefined') ? props.timesheetFields.end_date : null}
                                onChange = {props.HandleOnChange}
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                            />
                            </ FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="small" color="primary"  onClick={ props.OnSearchClick('search') }>Search</Button>
                        </Grid>
                        <Grid item >
                            <Button size="small" color="primary" variant="contained" onClick={ props.ExportTimeInOut }>Export</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <div className={'searchInOutList  justify-between align-stretch'}>
                <Paper>
                    <>
                    {   (props.timesheetInputs.timeinout_list && Object.keys(props.timesheetInputs.timeinout_list).length>0)?
                    <Grid container>
                        <Table size="small" aria-label="Timesheet List" className={'list-table_basic'}>
                            <TableHead className={'thead'}>
                                <TableRow>
                                    <TableCell className={'date'} align="center">Date</TableCell>
                                    <TableCell align="center">Time in</TableCell>
                                    <TableCell align="center">Time out</TableCell>
                                    <TableCell align="center">Hours</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Last Modified</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {   Object.keys(props.timesheetInputs.timeinout_list).map((item) =>
                                    <>
                                    {item !== 'grand_total' ?
                                        <>
                                        {Object.keys(props.timesheetInputs.timeinout_list[item]).map((k) =>
                                            <>
                                            { k !== 'total' ?
                                            <>
                                            <TableRow>
                                            { k === '0' ? <TableCell  align="center" rowspan={Object.keys(props.timesheetInputs.timeinout_list[item]).length-1}>{item}</TableCell> : <></> }
                                                <TableCell align="center" component="th" scope="row" >{props.timesheetInputs.timeinout_list[item][k].time_in_ampm} </TableCell>
                                                <TableCell align="center" component="th" scope="row" >{props.timesheetInputs.timeinout_list[item][k].time_out_ampm} </TableCell>
                                                <TableCell align="center" component="th" scope="row" >{props.timesheetInputs.timeinout_list[item][k].diff_hours} </TableCell>
                                                <TableCell align="center" component="th" scope="row" >{props.timesheetInputs.timeinout_list[item][k].location_name} </TableCell>
                                                <TableCell align="center" component="th" scope="row" >{props.timesheetInputs.timeinout_list[item][k].modified_on} </TableCell>
                                                <TableCell align="center" component="th" scope="row">
                                                { (props.timesheetFields.staff === props.User)?
                                                    <>
                                                    <Tooltip title="Edit" aria-label="edit">
                                                        <IconButton   className={'action-btn'} edge="end" aria-label="Edit">
                                                            <EditIcon  aria-haspopup="true" onClick={ props.editTimeInOutClick(props.timesheetInputs.timeinout_list[item][k].date) } fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    </>
                                                    :<></>
                                                }
                                                </TableCell>
                                            </TableRow>
                                            </>
                                            : 
                                            <>
                                            <TableRow>
                                                <TableCell colspan={2}></TableCell>
                                                <TableCell align="center"><strong>Day Total</strong></TableCell>
                                                <TableCell align="center"><strong>{props.timesheetInputs.timeinout_list[item]['total']} Hours</strong></TableCell>
                                                <TableCell colspan={3}></TableCell>
                                            </TableRow>
                                            </>
                                            }
                                            </>
                                        )}
                                        </>
                                        :<>
                                        <TableRow  className={'timesheet-list_row-weektotal shaded'}>
                                            <TableCell colspan={2}></TableCell>
                                            <TableCell align="center"><strong>Grand Total</strong></TableCell>
                                            <TableCell align="center"><strong>{props.timesheetInputs.timeinout_list['grand_total']} Hours</strong></TableCell>
                                            <TableCell colspan={3}></TableCell>
                                        </TableRow>
                                        </>
                                    }
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                    :<></>
                    }
                    </>
                    <>
                    {   (props.timesheetInputs.timeinout_list && Object.keys(props.timesheetInputs.timeinout_list).length === 0)?
                        <>
                        <Grid container style={gridStyle}>
                            <Table size="small" aria-label="Timesheet List" className={'list-table_basic'}>
                                <TableRow>
                                    <TableCell colspan="6"></TableCell>
                                    <TableCell>No search result</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        </>
                        :<></>
                    }
                    </>
                </Paper>
            </div>
        {(props.PopupType === 'timein_out' && props.DialogOpen) &&
            <TimeInOut
                SelectedDate = {props.selectedDate}
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                RandomNumber = {props.randomNumber}
                TimeInOutSaveSuccess = {props.TimeInOutSaveSuccess}
            />
        }
        </React.Fragment>
    )

}
