import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import * as _R from 'ramda';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {ScheduledPayment} from './ScheduledPayment.Container';
import {TaxDetails} from './Fedtax.Container';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddNoteContainer } from '../note/AddNote.Container';
import {Adjustments} from './Adjustments.Container';
import { BankList } from './BankInfo.Container';
import { ReferralBonusList } from './ReferralBonus.Container';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { CustomDatePicker } from '../../../common/CustomDatePicker';

// dialog content
const DialogContent = (p) => {
    const {FormProps:props} = p;
    return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                {props.ValidationError &&
                    <Grid item xs={12} md={12}>
                        <p class='error'>{props.ValidationError}</p>
                    </Grid>
                }
                    <Grid item xs={12} md={6}>
                        <FormControl>                           
                            <CustomDatePicker
                                        name="effective_date"
                                        id="effective_date"
                                        selectedDate={props.EffectiveDate}
                                        handleDateChange={props.HandleDateChange}
                                        error={props.Errors.effective_date}
                                        required={true}
                                        label="Start Date"
                                    />
                        </ FormControl>
                    </Grid>
                    {props.IsCompensationEdit && 
                    <Grid item xs={12} md={6}>
                        <FormControl>                       
                            <CustomDatePicker
                                        name="end_date"
                                        id="end_date"
                                        selectedDate={props.EndDate}
                                        handleDateChange={props.HandleDateChange}
                                        error={props.Errors.end_date}
                                        clearable={true}
                                        required={true}
                                        label="End Date"
                                    />
                        </ FormControl>
                    </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="pay_type" required  error={props.Errors.pay_type && true}>Pay Type</InputLabel>
                            <Select
                                value = {props.SelPayType}
                                onChange = {props.HandleOnChange}
                                inputProps = {{
                                    name: 'pay_type',
                                    id: 'pay_type',
                                }}>
                                {_R.type(props.PayTypes) !== 'Undefined' && props.PayTypes.map((t, i) =>
                                    <MenuItem value={t.id} key={'pay_type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                             <small className='error'>{props.Errors.pay_type && props.Errors.pay_type}</small>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {props.TxtSalary}
                                id = "txt_salary"
                                name = "txt_salary"
                                label = "Salary"
                                type = "text"
                                required
                                error = {props.Errors.salary && true}
                                helperText = {props.Errors.salary}
                                onChange = {props.HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
                    {props.IsCompensationEdit &&
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <span className={'compensation-update-info'}>
                                * Compensation details will be recalculated while saving the changes
                            </span>
                        </FormControl>
                    </Grid>
                    }
                </Grid>
            </form>
    )
}
const DialogAddComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="component_id" error={props.Errors.duplicate_entry && true}>Component</InputLabel>
                            <Select
                                value={props.SelComponent}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'component_id',
                                    id: 'component_id',
                                }}>
                                {_R.type(props.ConfigComponents) !== 'Undefined' && props.ConfigComponents.map((c, i) =>
                                    <MenuItem value={c.id} key={'c-'+i}>{c.title}</MenuItem>
                                )}
                            </Select>
                        {props.Errors.duplicate_entry && <FormHelperText class="error">{props.Errors.duplicate_entry}</FormHelperText> }
                       </ FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};


const divStyle = {
  display: 'block'
};
const CompensationStyle = styled(React.Fragment)(({ theme })=> ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    closeButton: { 
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
export const CompensationComponent = (props => {
    let [expanded, setExpanded] = React.useState('panel0');
    let handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <CompensationStyle>
            <Grid container spacing={3} style={{position:"relative"}}>
                <Grid item xs={12} sm={6}>
                    <div className={'absolute-right'}>
                        {_R.equals(props.Edit, true) &&
                        <div className={'card-action'}>
                            <Fab size="small" color="primary" aria-label="add" onClick={props.AddNewSalary}><AddIcon fontSize="small" /></Fab>
                        </div>
                        }
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Grid item xs={12} sm={12}>
                        <Typography className={'section-title'} variant="h6" component="h6"> COMPENSATION DETAILS</Typography>
                    </Grid>
                </Grid>
                {_R.type(props.SalaryDetails) !== 'Undefined' && props.SalaryDetails.length > 0 &&
                    <Grid item md={12}>
                    {props.SalaryDetails.map((s, i) =>
                        <Accordion expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
			        <Grid container spacing={3}>
                		   <Grid item xs={12} sm={4}>
                                      <Typography variant='body2'>{s.start_date + (!_R.isNil(s.end_date)?' - '+s.end_date:'' )}</Typography>
                                   </Grid>
			    	   <Grid item xs={12} sm={4}>
                                      <Typography variant='subtitle2' style={{ paddingLeft:'5px'}}> {s.amount}  {'('+s.pay_frequency+')'}</Typography>
                                   </Grid>
			    	   <Grid item xs={12} sm={4}>
			              {_R.equals(props.Edit, true) &&
                                         <FormControlLabel
                                            aria-label="Edit Compensation"
                                            onClick={
                                               (event) => props.SalaryUpdateClick(event,s.id)
                                            }
                                            onFocus={event => event.stopPropagation()}
                                            control={<IconButton aria-label="edit">
                                                        <EditIcon fontSize="small"  />
                                                     </IconButton>}
                                        />
                                     }
                                     {_R.equals(props.Delete, true) &&
                                        <FormControlLabel
                                             aria-label="Delete Compensation"
                                             onClick={
                                                (event) => props.SalaryDeleteClick(event, s.id)
                                             }
                                             onFocus={event => event.stopPropagation()}
                                             control={<IconButton aria-label="delete">
                                                         <DeleteIcon fontSize="small" />
                                                      </IconButton>}
                                        />
                                     }
			           </Grid> 
			    	</Grid>
                            </AccordionSummary>
                            <AccordionDetails style={divStyle}>
                                {(props.Result === true) &&
                                    <div className={props.root}>
                                        <Alert severity="success"> {"Successfully updated the amount"} </Alert>
                                    </div>
                                }
                                {(props.Result === false) &&
                                    <div className={props.root}>
                                        <Alert severity="error"> {props.AmountMessage} </Alert>
                                    </div>
                                }
                                    <CustomMaterialTable
                                    title="Components"
                                    columns={[
                                        { title: 'Title', field: 'title', grouping: false, editable: 'never'},
                                        { title: 'Type', field: 'type', editable: 'never'},
                                        { title: 'Sub-Type', field: 'sub_type', editable: 'never'},
                                        { title: 'Amount', field: 'pref_amount', grouping: false},
                                        { title: 'Pay Frequency', field: 'pay_frequency_title', editable: 'never'},
                                    ]}
                                    data={s.components.length?s.components:[]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        pageSize:s.components.length ? (s.components.length < 10 ? s.components.length : 10) : 2,
                                        pageSizeOptions: [10,20,30],
                                        search: false,
                                        grouping: true
                                    }}
                                    actions={[
                                        {
                                            icon: 'add',
                                            tooltip: 'Add New Component',
                                            isFreeAction: true,
                                            onClick: (event, newData) => { props.AddComponent(s.id,s.config_components) },
                                            hidden: (props.Edit) ? false : true,
                                        },
				                        rowData => ({ 
					                        icon:'comments',
					                        tooltip:'Add note',
					                        onClick:(event,newData) => {
					                        props.AddComponentNote(newData.id) },
                                            iconProps: rowData.note_id !== null ? {style:{color:"blue"}}: {},    
                                        }),
				                    ]}
                                    editable={{
                                        isEditable: rowData => (props.Edit) ? true : false,
                                        onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            {
                                                const dataArr = s.components;
                                                const index = dataArr.indexOf(oldData);
                                                dataArr[index] = newData;
                                                props.HandleUpdate(props.SalaryDetails, dataArr, index)
                                                resolve();
                                            }
                                            resolve()
                                          }, 500)
                                        }),
                                    }}
                                    />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    </Grid>
                    }
                    <ScheduledPayment ScheduledComponents={props.ScheduledComponents} ConfigComponents={props.ConfigComponents} StaffID={props.StaffID} Edit={props.Edit} Delete={props.Delete}/>
                    { (props.ViewAdjustments) ?
                        <Adjustments AdjustmentComponents={props.ComponentAdjustments} ConfigComponents={props.ConfigComponents} StaffID={props.StaffID} Edit={props.Edit} Delete={props.Delete}/>
                        : <></>
                    }
                    <Grid item md={12}>
                        <Grid item xs={12} sm={12}>
                            <ReferralBonusList 
                             BonusInfo={_R.type(props.BonusInfo) !== 'Undefined' ? props.BonusInfo : undefined}
                             UserInfo={_R.type(props.Users) !== 'Undefined' ? props.Users : undefined}
                             StaffID={_R.type(props.StaffID) !== 'Undefined' ? props.StaffID : undefined}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Grid item xs={12} sm={12}>
                            <BankList 
                                BankInfo={ _R.type(props.BankInfo) !== 'Undefined' && props.BankInfo.length ? props.BankInfo : undefined}
                                BankAccountType={ _R.type(props.BankAccountType) !== 'Undefined' && props.BankAccountType.length ? props.BankAccountType : undefined}
                                StaffID={_R.type(props.StaffID) !== 'Undefined' ? props.StaffID : undefined}
                            />
	    		    <AddNoteContainer
	    			    OpenNote = {props.OpenNote}
	    		        Category = {"Compensation"}
	                    StaffID = {props.StaffID}
	                    Compen_component_id = {props.Compen_component_id}
	    			    note ={props.note}
                        OnCloseNote = {props.OnCloseNote}
	    			/>
                    </Grid>
                    </Grid>
        <TaxDetails
                TaxInfo = {props.Data}
                StateTaxInfo = {props.StateTaxData}
                AllStates = {props.AllStates}
                StaffID={props.StaffID}
                MaritalStatuses = {props.MaritalStatuses}
                FederalFilingStatuses = {props.FederalFilingStatuses}
                TaxViewPermission = {props.Edit}
                TaxEditPermission = {props.Edit}
                TaxDeletePermission = {props.Edit}
        />
        </Grid>
            <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title={props.IsCompensationEdit ? 'Edit Compensation' : props.AddComponentFlag?'Add Component':'Add New Compensation'}
                    OnSubmit={props.AddComponentFlag?props.SaveComponent:props.GenerateSalary}
                    Content={props.AddComponentFlag?<DialogAddComponent FormProps={props}/>:<DialogContent FormProps={props}/>}/>
             <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
        </CompensationStyle>
);
});
