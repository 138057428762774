import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {CustomizedSnackbars} from '../../common/snackbars';
import {DialogBoxMedium} from '../../common/dialog.medium';
import {CustomMaterialTable} from '../../common/CustomMaterialTable';

export const ConfigComponent = (props) => {
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={"Configuration"}
                        columns={[
                            {
                                title: 'Parameter',
                                field: 'configitem',
                            },
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Default Value',
                                field: 'default',
                            },
                            {
                                title: 'Current Value',
                                field: 'value',
                            },
                        ]}
                        data={props.Configs}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            sorting: false,
                            paging: false,
                        }}
                        actions={[                            
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdateClick(rowData.id);
                                },
                            },                          
                        ]}
                        freezeHeader = {true}
                    />
                </Grid>
            </Grid>
            {props.Open && 
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.OnClose}
                Title = {"Edit Configuration"}
                OnSubmit = {props.OnSubmit}
                Content = {<DialogContent FormProps={props}/>}
                Loading ={props.ButtonLoader}
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>
    </>
)};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormValues, HandleOnChange} = props;
    let allowedOptions = [];
    if (typeof FormValues.allowed_values !== 'undefined' && FormValues.allowed_values) {
        let allowedValues = FormValues.allowed_values.split(',');
        if (allowedValues && allowedValues.length) {
            allowedValues.map(function(allowedVal, i) {
                let allowedItem = allowedVal.split('=');
                let obj = {value: allowedItem[0], label: allowedItem[1]}
                allowedOptions.push(obj);
            }) 
        }
    }
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            disabled
                            id="configitem"
                            name="configitem"
                            label="Parameter"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormValues.configitem !== 'undefined' && FormValues.configitem) ? FormValues.configitem : ''}
                        />
                    </ FormControl>
                </Grid>              
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField 
                            id="description"
                            name="description"
                            label="Description"
                            multiline={true}
                            variant="outlined"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormValues.description !== 'undefined' && FormValues.description) ? FormValues.description : ''}
                            onChange={HandleOnChange}
                            error = {(typeof props.Errors.description !== 'undefined' && props.Errors.description) && true}
                            helperText = {(typeof props.Errors.description !== 'undefined' && props.Errors.description) ? props.Errors.description : ''}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            disabled    
                            id="default"
                            name="default"
                            label="Default Value"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormValues.default !== 'undefined' && FormValues.default) ? FormValues.default : ''}                            
                        />
                    </ FormControl>
                </Grid>
                {(allowedOptions.length) ?
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="current_value">Current Value</InputLabel>
                        <Select
                            value={(typeof FormValues.value !== 'undefined' && FormValues.value)? FormValues.value : ''}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'value',
                                id: 'value',
                            }}>
                            {allowedOptions.map((opt, i) =>
                            <MenuItem value={opt.value} >{opt.label}</MenuItem>
                            )}
                        </Select>
                        <small className='error'>
                            {(typeof props.Errors.config_value !== 'undefined' && props.Errors.config_value) ? props.Errors.config_value : ''}
                        </small>
                    </ FormControl>
                </Grid>
                :
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="value"
                            name="value"
                            label="Current Value"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof FormValues.value !== 'undefined' && FormValues.value) ? FormValues.value : ''}
                            onChange={HandleOnChange}
                            error = {(typeof props.Errors.config_value !== 'undefined' && props.Errors.config_value) && true}
                            helperText = {(typeof props.Errors.config_value !== 'undefined' && props.Errors.config_value) ? props.Errors.config_value : ''}
                        />
                    </ FormControl>
                </Grid>
                }
            </Grid>
        </form>
    )
}
