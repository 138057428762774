import React from 'react';
import moment from 'moment';
import {UserAddComponent} from './UserAdd.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';

const formFields = {
    'user_status' : '1',
    'veteran_id' : [],
    'work_schedule' : 1,
}
const FilterFields = {
    user_type: "1",
};
const FilterInputs = {
    user_types: "",
};
const formInputs = {
    category : [
        { id : 0, value : "salary", title : "Salary"},
        { id : 1, value : "hourly", title : "Hourly"}
    ],
    overtime : [
        { id : 0, value : "exempt", title : "Exempt"},
        { id : 1, value : "non-exempt", title : "Non-exempt"}
    ],
    pay_periods : [
        { id : 0, value : "monthly", title : "Monthly"},
        { id : 1,value : "semi-monthly", title : "Semi-Monthly"}
    ],
    ts_review_method : [
        { id : 0, value : "1", title : "Project Manager" },
        { id : 1,value : "0", title : "Supervisor"},
    ],
    disable_req_approval : [
        { id : 0, value : "1", title : "Yes"},
        { id : 1, value : "0", title: "No"},
    ],
    expense_eligibility : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    expense_limit_exempt : [
        { id : 0, value : 1, title : "Yes"},
        { id : 1, value : 0, title : "No"},
    ],
    time_inout : [
        { id : 0, value : "1", title : "Yes"},
        { id : 1, value : "0", title : "No"},
    ],
    owner_or_relative : [
        { id : 0, value : "1", title : "Yes"},
        { id : 1, value : "0", title : "No"},
    ],
    yes_no_options : [
        {id : 0, value : "1", title : "Yes"},
        { id : 1, value : "0", title : "No"},
    ],
    'projects' :[0],
    timeoff_eligibility : [
        { id : 0, value : "joining_date", title : "Joining Date"},
        { id : 1, value : "service_period", title : "Service Period"},
        { id : 2, value : "custom_start_date", title : "Joining Date Override"},
    ],
    include_timeoff_eligibility_service_period : [
        { id : 0, value : "all_service_period", title : "All Service Period"},
        { id : 1, value : "employee_salary_service_period", title : "Employee + Salary Service Periods"}
    ],
};

const tabDetails = {
    basicDetails: ['first_name', 'last_name', 'user_email', 'user_mobile', 'pob', 'id_number'],
    employmentDetails: ['project_title', 'daily_minimum', 'weekly_limit', 'employee_id'],
    loginDetails: ['auth_provider', 'username', 'password', 're_password']
};
let getProjectTree = function(dataObj,selected_projects, result) {
        dataObj.forEach(function(key) {
            if(selected_projects) {
                if((selected_projects == key.project_id)) {
                    key.checked = true;
                    result.push(key);
                }
                else if(key.children instanceof Object) {
                    getProjectTree(key.children, selected_projects,result);
                }
            }
            if(!selected_projects) {
                if(key.checked) {
                    key.checked = false;
                }
                else if(key.children instanceof Object) {
                    getProjectTree(key.children, 0, result);
                }
            }
        });
    }

export class UserAddContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            activeStep: 0,
            snackbarOpen: false,
            errors: {},
            formFields: formFields,
            email_exists: false,
            phone_exists: false,
            formInputs: formInputs,
            hasPermission: true,
            permisionErrMsg: '',
            showPassword: false,
            showLoader: false,
            system_components : [],
            selectAllComponentsFlag : true,
            selectedComponentsLength : 0,
            FilterFields: FilterFields,
            FilterInputs: FilterInputs,
            isValidPassword: true,
            tabValue: null,
            passwordPolicyText: '',
        }
        this.HandleOnChange = this.handleOnChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.setActiveStep = this.setActiveStep.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.setLoader = this.setLoader.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }
    componentDidMount() {
        this.clearForm();
        this.setLoader(true);
        httpServices.post('api/user/add')
            .then(response => {
                if (response.data) {
                    let permission  = response.data.permission;
                    let permision_err_msg = (response.data.permission) ? '' : response.data.permision_err_msg;
                    this.setLoader(false); 
                    if (response.data.permission) {
                    let inputs = response.data.form_inputs;
                    inputs.projects = _ts.prepareTreeData(inputs.projects, 'projects', false);
                    let finputs = this.state.formInputs;
                    const new_inputs = Object.assign(finputs, inputs);
                    const fi = this.state.FilterInputs;
                    let ff =  this.state.formFields;
                    ff['timesheet_multiple_review'] = '0';
                    if (inputs.multiple_review_config === "yes") {
                        ff['timesheet_multiple_review'] = '1';
                    }
                    ff['pay_period'] =  (typeof response.data.form_inputs.pay_period !== 'undefined' && response.data.form_inputs.pay_period) ? response.data.form_inputs.pay_period : 'semi-monthly';
                    fi["user_types"] = response.data.user_type_arr;
                    this.setState({
                        formInputs : new_inputs,
                        FilterInputs : fi,
                        formFields : ff,
                        hasPermission: permission,
                        permisionErrMsg : permision_err_msg,
                        passwordPolicyText : response.data.password_policy_text,
                    });
                 }
                    else
                    {
                        this.setState({
                        hasPermission: permission,
                        permisionErrMsg : permision_err_msg,
                    });

                    }
                }
            })
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.formFields;
        let projects = [];
        getProjectTree(formInputs["projects"],0,projects);
        if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id && formInputs["projects"]) {
                    getProjectTree(formInputs["projects"],el.project_id,projects);
                    f['main_project'] = projects[0]['project_id'];
                    f['project_name'] = projects[0]['project_name'];
                }
            });
        }
        this.setState({
            formFields: f
        });
           }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        if (name === 'auth_provider') {
            if (parseInt(val) > 0) {
                let index = this.state.formInputs.auth_providers.findIndex(x => x.id === val);
                let change_password = this.state.formInputs.auth_providers[index]['change_password'];
                if (change_password === '1') {
                    this.setState({showPassword: true});
                }
                else {
                    this.setState({showPassword: false});
                }
            }
            else {
                this.setState({showPassword: false});
            }
        }
        if (name === 'include_holidays' || name === 'email_notification' || name === 'secondary_email_notification' || name === 'email_own_action') {
            f[name] = evt.target.checked;
        }
        else {
            f[name] = val;
        }
        if (name === 'user_email' || name === 'user_mobile') {
            this.checkDuplicates();
        }
        this.setState({
            formFields: f
        });
        if (val) {
            var err = this.state.errors;
            err[name] = '';
            this.setState({errors: err});
        }
    };
    handleFilterChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.FilterFields;
        ff[field] = evt.target.value;
        this.setState({
            FilterFields: ff,
        });
    };
    handleValidation = (step) => {
        let formIsValid = false;
        let errors = {};
        if (step === 0 ) {
            let user_email = this.state.formFields.user_email;
            let user_mobile = this.state.formFields.user_mobile;
            if (!this.state.formFields.first_name || this.state.formFields.first_name.trim() === '') {
                errors['first_name'] = "Cannot be empty";
            }
            if ((!this.state.formFields.last_name || this.state.formFields.last_name.trim() === '') && parseInt(this.state.FilterFields.user_type) !== 3) {
                errors['last_name'] = "Cannot be empty";
            }
            if ((parseInt(this.state.FilterFields.user_type) === 1) || (parseInt(this.state.FilterFields.user_type) === 2)) {
            if (!user_email || user_email.trim() === '') {
                errors['user_email'] = "Cannot be empty";
            }
            else if (user_email) {
                let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                let ok = reg.exec(user_email);
                if (!ok) {
                    errors["user_email"] = "Email isn't valid";
                }
            }
            if (this.state.email_exists && !(errors["user_email"])) {
                 errors["user_email"] = "Email already exists";
            }
            }
            if (parseInt(this.state.FilterFields.user_type) === 1) {
            if (!user_mobile || user_mobile.trim() === '') {
                errors['user_mobile'] = "Cannot be empty";
            }
            else if (user_mobile) {
                let reg = /^(\+\d{1,2}[\s.-]?)\(?\d{3}\)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{4}$/;
                let ok = reg.exec(user_mobile);
                if (!ok) {
                    errors["user_mobile"] = "Invalid phone number. Valid format is +number";
                }
            }
            if (this.state.phone_exists && !(errors["user_mobile"])) {
                 errors["user_mobile"] = "Phone number already exists";
            }
            if (this.state.formFields.idcard_type) {
            if (!this.state.formFields.id_number || this.state.formFields.id_number.trim() === '') {
                errors['id_number'] = "Cannot be empty";
            }
            }
            if (!this.state.formFields.gender) {
                errors['gender'] = "Cannot be empty";
            }
            }
        }

        //Staff Details Tab
        if (step === 1 && (parseInt(this.state.FilterFields.user_type) === 1)) {
            let category = this.state.formFields.category;
            let work_schedule = this.state.formFields.work_schedule;
            let project_title = this.state.formFields.project_title;
            let pay_period = this.state.formFields.pay_period;
            if (!this.state.formFields.staff_type) {
                errors['staff_type'] = "Cannot be empty";
            } else if (this.state.formFields.staff_type == 1 && !this.state.formFields.employment_type) {
                errors['employment_type'] = "Cannot be empty";
            }
            if (!this.state.formFields.date_joined) {
                errors['date_joined'] = "Cannot be empty";
            }
            if (!this.state.formFields.reviewer_id) {
                errors['reviewer_id'] = "Cannot be empty";
            }
            if (!category) {
                errors['category'] = "Cannot be empty";
            }
            if (this.state.formFields.supervisor_start_date) {
                if (this.state.formFields.date_joined && this.state.formFields.date_joined > this.state.formFields.supervisor_start_date) {
                    errors['supervisor_start_date'] = "Start date should be greater than joining date.";
                }
                let invalid_start_date = true;
                if (this.state.formFields.date_joined && this.state.formFields.date_joined == this.state.formFields.supervisor_start_date) {
                    invalid_start_date = false;
                }
                let day = moment(this.state.formFields.supervisor_start_date).date();
                if (pay_period === 'semi-monthly') {
                    if (day === 1 || day === 16) {
                        invalid_start_date = false;
                    }
                } else {
                    if (day === 1) {
                        invalid_start_date = false;
                    }
                }
                if (invalid_start_date){
                    errors['supervisor_start_date'] = "Start date should be joining date/payperiod start date.";
                }   
            }
            if (this.state.formFields.supervisor_end_date) {
                if (!this.state.formFields.supervisor_start_date){
                    errors['supervisor_start_date'] = "Cannot be empty";
                } else if (this.state.formFields.supervisor_start_date > this.state.formFields.supervisor_end_date) {
                    errors['supervisor_end_date'] = "End date should be greater than start date.";
                }
            }
            if (category && work_schedule)
                if (category !== 'salary' &&  work_schedule === 2) {
                    errors['work_schedule'] = "Only salaried employees are eligible for Flexible Work Schedule.";
                }
            let entry_time = this.state.formFields.entry_time;
            let exit_time = this.state.formFields.exit_time;
            if (entry_time) {
                let reg = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
                let ok = reg.exec(entry_time);
                if (!ok) {
                    errors["entry_time"] = "Working Time Start isn't valid";
                }
            }
            if (exit_time) {
                let reg = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
                let ok = reg.exec(exit_time);
                if (!ok) {
                    errors["exit_time"] = "Working Time End isn't valid";
                }
            }
            let daily_minimum = this.state.formFields.daily_minimum;
            if (daily_minimum) {
                let reg = /^[1-9]+[0-9]*$/;
                let ok = reg.exec(daily_minimum);
                if (!ok) {
                    errors["daily_minimum"] = "Daily minimum isn't valid";
                }
            }
            let weekly_limit = this.state.formFields.weekly_limit;
            if (weekly_limit) {
                let reg = /^[1-9]+[0-9]*$/;
                let ok = reg.exec(weekly_limit);
                if (!ok) {
                    errors["weekly_limit"] = "Weekly limit isn't valid";
                }
            }
            if (!project_title || project_title.trim() === '') {
                errors['project_title'] = "Cannot be empty";
            }
        }
        if ((step === 4 && (parseInt(this.state.FilterFields.user_type) === 1)) || (step === 2 && (parseInt(this.state.FilterFields.user_type) === 2)) || (step === 3 && !(parseInt(this.state.formInputs.component_count) && parseInt(this.state.formFields.staff_type) === 1))) {
            //Login Information
            if (!this.state.formFields.auth_provider) {
                errors['auth_provider'] = "Cannot be empty";
            }
            if (!this.state.formFields.username || this.state.formFields.username.trim() === '') {
                errors['username'] = "Cannot be empty";
            }
            if (this.state.formFields.auth_provider && this.state.showPassword ) {
                let password = this.state.formFields.password;
                let re_password = this.state.formFields.re_password;
                if (!password || password.trim() === '') {
                    errors['password'] = "Cannot be empty";
                }                 
                if (this.state.isValidPassword === false && !errors['password']) {
                    errors["password"] = this.state.passwordPolicyText;
                }
                if (!re_password || re_password.trim() === '') {
                    errors['re_password'] = "Cannot be empty";
                }
                if (password && re_password && password !== re_password) {
                    errors['re_password'] = "Password entry mismatch";
                }
            }
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    setLoader = (show) => {
        this.setState({showLoader: show});
    };
    clearForm = () => {
        const formFieldsTemp = {
            'user_status' : '1',
            'pay_period' : 'semi-monthly',
            'veteran_id' : [],
            'work_schedule' : 1,
        }

        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = (formFieldsTemp[key]) ? formFieldsTemp[key] : null;
        }
        this.setState({formFields: ff});
        formFields['first_name'] = '';
        formFields['last_name'] = '';
        formFields['middle_name'] = '';
        formFields['user_email'] = '';
        formFields['user_mobile'] = '';
        formFields['pob'] = '';
        formFields['dob'] = '';
        formFields['id_number'] = '';
        this.setState({errors: {}});
    };
    clearErrors = () => {
      this.setState({errors: {}});
    }
    checkDuplicates = () => {
        let email = (typeof this.state.formFields.user_email !== 'undefined') ? this.state.formFields.user_email : null;
        let mobile = (typeof this.state.formFields.user_mobile !== 'undefined') ? this.state.formFields.user_mobile.replace( /[\s- .)(]/g, '') : null;
        let error = {};
        if (email || mobile) {
            return httpServices.post('api/user/validateEmail', {'email': email, 'phone': mobile})
                .then(response => {
                    if (response.data) {
                             this.setState({
                            email_exists : response.data.email_exists,
                            phone_exists : response.data.user_mobile_exists,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }

    handleSubmit = () => {
        this.setLoader(true);
        const data = this.state.formFields;
        data.userType = this.state.FilterFields.user_type;
        data.components = JSON.stringify(this.state.system_components);
        data.action = 'save';
	data.first_name = this.state.formFields.first_name.trim();
	data.last_name = this.state.formFields.last_name.trim();
        data.user_mobile = this.state.formFields.user_mobile ? this.state.formFields.user_mobile.replace( /[\s- .)(]/g, '') : null;
        httpServices.post('api/user/add', data)
            .then(response => {
                if (response.data.valid) {
                    this.setLoader(false);
                    this.clearForm();
                    if (parseInt(this.state.FilterFields.user_type) !== 3) {
                        this.setState({ activeStep: 0 });
                    }
                    // For displaying the success message
                    this.setState({ snackbarOpen: true })
                } else if (response.data.errors) {
                    let tabIndexArray = [];
                    for (let tab in tabDetails) {
                        let isIncluded;
                        const tabContent = tabDetails[tab]
                        for (let key in response.data.errors) {
                            isIncluded = tabContent.includes(key);
                            if (isIncluded) {
                                if (tab === 'basicDetails') {
                                    tabIndexArray.push(0);
                                } else if (tab === 'employmentDetails') {
                                    tabIndexArray.push(1);
                                } else {
                                    if (parseInt(this.state.FilterFields.user_type) === 1) {
                                        tabIndexArray.push(4);
                                    } else if (parseInt(this.state.FilterFields.user_type) === 2) {
                                        tabIndexArray.push(2);
                                    }
                                }
                                this.setState({ tabValue: tabIndexArray });
                            }
                        }
                    }
                    this.setLoader(false);
                    this.setState({ errors: response.data.errors });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    validatePassword = () => {
        let password = (typeof this.state.formFields.password !== 'undefined') ? this.state.formFields.password : null;
        if (password) {
            return httpServices.post('api/user/validatePassword', {'password': password})
                .then(response => {
                    if (response.data) {
                             this.setState({
                                isValidPassword : response.data.password_validate,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    getSystemComponents = () => {
        let date_joined = (typeof this.state.formFields.date_joined !== 'undefined') ? this.state.formFields.date_joined : null;
        if (date_joined) {
            return httpServices.post('api/staff/config/getSystemComponents', {'date': date_joined})
                .then(response => {
                    if (response.data.permission) {
                        this.setState({
                            system_components : response.data.components,
                             selectedComponentsLength : 0,
                        })
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
        else {
            alert("Please enter joined date");
        }
    }
    handleComponentCheckBoxClick = (event, id) => {
        let system_components = this.state.system_components;
        let selected_length = 0;
        if (system_components.length > 0) {
            let index = system_components.findIndex(x => x.id === id);
            let status = system_components[index].isSelected;
            if (status === true) {
                system_components[index].isSelected = false;
            } else {
                system_components[index].isSelected = true;
            }
            this.setState({
                system_components : system_components,
            }, function() {
                this.state.system_components.map(function(x) {
                if (x.isSelected) {
                    ++selected_length;
                }
                return x
            });
             this.setState({
                selectedComponentsLength : selected_length,
            });
            });
        }
    };  
    
    setActiveStep (step) {
        this.setState({
            activeStep : step
        });
    }
    
     snackbarClose = () => {
        this.setState({
            snackbarOpen : false
        });
    };

    handleSelectAllClick = () => {
        let flag = false;
        let system_components = this.state.system_components;
        let selected_components = 0;
        if (this.state.selectAllComponentsFlag) {
            flag = false;
            // TODO: Unselect all components

        } else {
            flag = true;
            // TODO : Select all components
        }
        system_components.map(function(x) {
            x.isSelected = flag;
            return x
        });
        if (flag) {
            selected_components = system_components.length + 1;
        } else {
            selected_components = 0;
        }
        this.setState({
            selectAllComponentsFlag : flag,
            system_components : system_components,
            selectedComponentsLength : selected_components,
        });
    };


    render(){
        let permission = this.state.hasPermission;
        return(
            <React.Fragment>
                { (permission)  ? 
                    <UserAddComponent
                        FormInputs = {this.state.formInputs}
                        UserTypeSelected = {this.state.FilterFields.user_type}
                        StaffInputs = {this.state.staffInputs}
                        FormFields = {this.state.formFields}
                        ShowPassword = {this.state.showPassword}
                        ShowLoader = {this.state.showLoader}
                        SetLoader = {this.setLoader}
                        HandleOnChange={this.HandleOnChange}
                        ValidatePassword={this.validatePassword}
                        HasPermission={this.state.hasPermission}
                        FilterInputs={this.state.FilterInputs}
                        Errors={this.state.errors}
                        HandleValidation = {this.handleValidation}
                        FilterFields={this.state.FilterFields}
                        HandleFilterChange = {this.handleFilterChange}
                        ClearForm = {this.clearForm}
                        ClearErrors = {this.clearErrors}
                        GetSystemComponents = {this.getSystemComponents.bind(this)}
                        SystemComponents = {this.state.system_components}
                        HandleComponentCheckBoxClick = {this.handleComponentCheckBoxClick.bind(this)}
                        HandleSelectAllClick = {this.handleSelectAllClick.bind(this)}
                        SelectAllComponentsFlag = {this.state.selectAllComponentsFlag}
                        SelectedComponentsLength = {this.state.selectedComponentsLength}
                        HandleTreeChange = {this.handleTreeChange}
                        ActiveStep = {this.state.activeStep}
                        SnackbarOpen = {this.state.snackbarOpen}
                        TabValue = {this.state.tabValue}
                        SetActiveStep = {this.setActiveStep}
                        HandleSubmit = {this.handleSubmit}
                        SnackbarClose = {this.snackbarClose}
                    />
                    :
                    <ErrorPage Message = {this.state.permisionErrMsg}/>
                }
            </React.Fragment>
        )
    }
}
