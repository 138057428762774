import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { DialogBoxLarge } from '../../common/dialog.large';
import { CustomizedSnackbars } from '../../common/snackbars';
import { history } from './../../../helpers/history';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';

export const TimeOffTypesComponent = (props) => {
    const TimeOffTypes = typeof props.TimeOffTypes !== 'undefined' ? props.TimeOffTypes : [];
    return (
        <>
            <div className={'tableSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                    {props.EditPermission &&
                    <Tooltip title="Add Time Off Type" aria-label="add">
                        <div className={'header-action'}>
                            <Button variant="contained" color="primary" size="small" onClick={props.AddTimeOffTypesClick} >Add</Button>
                        </div>
                    </Tooltip>
                    }
            </div>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={"Time Off Type"}
                        columns={[
                            {
                                title: 'Time Off Type',
                                field: 'to_type',
                            },
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Custom Workflow',
                                field: 'policy',
                                render: rowData => <>{(rowData.custom_workflow == '' || rowData.custom_workflow == null ? "-" : rowData.custom_workflow)}</>
                            },
                            {
                                title: 'Approver',
                                field: 'approver',
                            },
                            {
                                title: 'Allow Partial Hours',
                                field: 'allow_partial_hours',
                                lookup: { '1': 'Yes','0':'No'},
                            },
                            {
                                title: 'Prorate Eligible Hours',
                                field: 'prorate_hours',
                                lookup: { '1': 'Yes','0':'No'},
                            },
                            {
                                title: 'Status',
                                field: 'status',
                                lookup: { '1': 'Active','0':'Inactive'},
                            },
                        ]}
                        data={props.TimeOffTypes}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                hidden: !props.EditPermission,
                                onClick: (event, rowData) => {
                                    props.EditClick(rowData.id);
                                },
                            },
                            {
                                icon: 'visibility',
                                tooltip: 'View',
                                onClick: (event, rowData) => {
                                    handleOnClick(rowData.id);
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            {props.Open &&
            <DialogBoxLarge
                Open = {props.Open}
                Cancel = {props.OnClose}
                Title = { props.PopupType === 'add' ? 'Add Time Off Type' : 'Edit Time Off Type' }
                OnSubmit = {props.OnSubmit}
                Content = {<DialogAdd FormProps={props}/>}
                Loading ={props.ButtonLoader}
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>
        </>
    )
}
const handleOnClick  = (id) =>{
    history.push('timeoff/view/' + id);
}
const DialogAdd = (props) => {
    const { FormFields,HandleOnChange,Errors,TimesheetStatus } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "to_type"
                            name = "to_type"
                            label = "Time Off Type"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { FormFields.to_type ? FormFields.to_type : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.to_type !== 'undefined' && Errors.to_type)&& true }
                            helperText = { typeof Errors.to_type !== 'undefined' && Errors.to_type }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                    <TextField variant="standard"
                        required
                        id="description"
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        rowsMax="4"
                        InputLabelProps={{ shrink: true }}
                        value = { FormFields.description ? FormFields.description : '' }
                        onChange = { HandleOnChange }
                        error = { (typeof Errors.description !== 'undefined' && Errors.description)&& true }
                        helperText = { typeof Errors.description !== 'undefined' && Errors.description }
                    />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Custom Workflow</InputLabel>
                        <Select
                            value={typeof FormFields.custom_workflow !== 'undefined' ? FormFields.custom_workflow : null}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'custom_workflow',
                                id: 'policy'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={null}>Select</MenuItem>
                            <MenuItem value='PTO'>PTO</MenuItem>
                            <MenuItem value='FTO'>FTO</MenuItem>
                            <MenuItem value='C-OFF'>C-OFF</MenuItem>
                            <MenuItem value='F-HOLIDAY'>F-HOLIDAY</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Accrual</InputLabel>
                        <Select
                            value={typeof FormFields.accrual !== 'undefined' ? FormFields.accrual : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'accrual',
                                id: 'accrual'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Reset Calendar Year Balance</InputLabel>
                        <Select
                            value={typeof FormFields.reset_calendar_year !== 'undefined'  ? FormFields.reset_calendar_year : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'reset_calendar_year',
                                id: 'reset_calendar_year'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Calendar Days</InputLabel>
                        <Select
                            value={typeof FormFields.calendar_days !== 'undefined' ? FormFields.calendar_days : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'calendar_days',
                                id: 'calendar_days'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Paid</InputLabel>
                        <Select
                            value={typeof FormFields.paid !== 'undefined'  ? FormFields.paid : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'paid',
                                id: 'paid'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "requests_per_year"
                            name = "requests_per_year"
                            label = "Requests Per Year"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { FormFields.requests_per_year }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.requests_per_year !== 'undefined' && Errors.requests_per_year)&& true }
                            helperText = { typeof Errors.requests_per_year !== 'undefined' && Errors.requests_per_year }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Adjustments</InputLabel>
                        <Select
                            value={typeof FormFields.adjustments !== 'undefined'  ? FormFields.adjustments : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'adjustments',
                                id: 'adjustments'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                {(FormFields.adjustments === 1) &&
                    <Grid item xs={12} md={3}>
                        <FormControl variant="standard">
                            <InputLabel>Adjustment Period</InputLabel>
                            <Select
                                value={typeof FormFields.adjustment_period !== 'undefined' ? FormFields.adjustment_period : ''}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'adjustment_period',
                                    id: 'adjustment_period'
                                }}
                                className={'input-item'}
                            >
                                <MenuItem value='year'>Year</MenuItem>
                                <MenuItem value='payperiod'>Payperiod</MenuItem>
                            </Select>
                            {(typeof Errors.adjustment_period !== 'undefined' && Errors.adjustment_period) &&
                                <FormHelperText className='error'>{Errors.adjustment_period}</FormHelperText>
                            }
                        </ FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Approver</InputLabel>
                        <Select
                            value={FormFields.approver ? FormFields.approver : 'Supervisor'}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'approver',
                                id: 'approver'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='Supervisor'>Supervisor</MenuItem>
                            <MenuItem value='Senior Management'>Senior Management</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Time Entry Editable</InputLabel>
                        <Select
                            value={typeof FormFields.editable !== 'undefined' ? FormFields.editable : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'editable',
                                id: 'editable'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Enable Grant</InputLabel>
                        <Select
                            value={typeof FormFields.enable_grant !== 'undefined' ? FormFields.enable_grant : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'enable_grant',
                                id: 'enable_grant'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "weight"
                            name = "weight"
                            label = "Time Off Type Suggestions Weight"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { FormFields.weight ? FormFields.weight : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.weight !== 'undefined' && Errors.weight)&& true }
                            helperText = { typeof Errors.weight !== 'undefined' && Errors.weight }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={typeof FormFields.status !== 'undefined' ? FormFields.status : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Allow Partial Hours</InputLabel>
                        <Select
                            value={typeof FormFields.allow_partial_hours !== 'undefined' ? FormFields.allow_partial_hours : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'allow_partial_hours',
                                id: 'allow_partial_hours'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Prorate Eligible Hours</InputLabel>
                        <Select
                            value={typeof FormFields.prorate_hours !== 'undefined' ? FormFields.prorate_hours : 1}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'prorate_hours',
                                id: 'prorate_hours'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Add Eligibility Criteria</InputLabel>
                        <Select
                            value={typeof FormFields.is_eligibility_criteria !== 'undefined' ? FormFields.is_eligibility_criteria : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'is_eligibility_criteria',
                                id: 'is_eligibility_criteria'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                {(FormFields.is_eligibility_criteria == 1) && <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Eligibility Criteria Unit</InputLabel>
                        <Select
                            value={FormFields.eligibility_criteria_unit ? FormFields.eligibility_criteria_unit : 'year'}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'eligibility_criteria_unit',
                                id: 'eligibility_criteria_unit'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='year'>Year</MenuItem>
                            <MenuItem value='month'>Month</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>}
                {(FormFields.is_eligibility_criteria == 1) && <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Staff Eligibility Criteria Override</InputLabel>
                        <Select
                            value={typeof FormFields.eligibility_criteria_staff_override !== 'undefined' ? FormFields.eligibility_criteria_staff_override : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'eligibility_criteria_staff_override',
                                id: 'eligibility_criteria_staff_override'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>}
                 <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Show variable Name</InputLabel>
                        <Select
                            value={typeof FormFields.show_variable_name !== 'undefined' ? FormFields.show_variable_name : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'show_variable_name',
                                id: 'show_variable_name'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
	        <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Event Based</InputLabel>
                        <Select
                            value={typeof FormFields.event_based !== 'undefined' ? FormFields.event_based : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'event_based',
                                id: 'event_based'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Accrual Timesheet Status</InputLabel>
                        <Select
                            value={typeof FormFields.accrual_timesheet_status !== 'undefined' ? FormFields.accrual_timesheet_status : null}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'accrual_timesheet_status',
                                id: 'statusKey'
                            }}
                            className={'input-item'}
                        >
                            {TimesheetStatus.map((statusKey, i) =>
                            <MenuItem value={statusKey.timesheet_status_id}>{statusKey.timesheet_status_name}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                <FormControl>
                        <FormLabel>Adjustment Type</FormLabel>
                    <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={HandleOnChange}
                                value={FormFields.adjustment}
                                checked={FormFields.adjustment}
                                name='adjustment'
                                color="primary"
                            />
                        }
                        label="Adjustment"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={HandleOnChange}
                                value={FormFields.carry_forward}
                                checked={FormFields.carry_forward}
                                name='carry_forward'
                                color="primary"
                            />
                        }
                        label="Carry Forward"
                    />
                    </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
