import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as _R from 'ramda';  // used 0.04kb
import { ErrorPage } from '../common/error';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatName } from '../common/common';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StyledBackdrop  = styled(Backdrop) (({ theme }) => ({
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const PayrollGenerateComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = currentYear; i >= currentYear-5; i--){
        years.push(i)
    };
    const [shown, setShown] = React.useState('none');
    const [rows, setRows]= React.useState('[]');
    return (
        <React.Fragment>
            <Paper>
                <Grid container spacing={3} style={gridStyle}>
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                            <Grid container spacing={10}>
                                <Grid item xs={4} md={2}>
                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="year">Year</InputLabel>
                                        <Select
                                            value={props.FormFields.year!=null?props.FormFields.year:currentYear}
                                            inputProps={{
                                                name: 'year',
                                                id: 'year',
                                            }}
                                            onChange={props.HandleOnYearChange}
                                        >
                                        {years.map((year, i) =>
                                            <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
                                        )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="pay_period">Pay Period</InputLabel>
                                        <Select
                                            value={props.FormFields.pay_period!=null?props.FormFields.pay_period:''}
                                            onChange={props.HandleOnChange}
                                            inputProps={{
                                                name: 'pay_period',
                                                id: 'pay_period',
                                            }}>
                                            {_R.type(props.Intervals !== 'Undefined') && props.Intervals.map((p, i) =>
                                                <MenuItem value={p.id} data-sdate={p.sdate} data-edate={p.edate} key={'pay-period-'+i}>{p.from_date+' - '+p.to_date}</MenuItem>
                                            )}
                                        </Select>
                                    </ FormControl>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Button variant="contained" size="small" color="primary" onClick={props.OnSubmit}> Filter</Button>
                                    <Button color="primary" onClick={props.OnReset} size="small">Clear</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container spacing={10}>
                <Grid item md={12}>
                    {props.HasPermission && 
                    <CustomMaterialTable
                        columns={[
                            { title: 'Name', field: (props.NameFormat === 'LASTFIRST' || props.NameFormat === 'LASTFIRSTMIDDLE' ) ? 'user_last_name': 'user_first_name', defaultSort: 'asc', render: rowData => <>{formatName(rowData.user_first_name, null, rowData.user_last_name)} </>},
                            { title: 'Designation', field: 'designation'},
                            { title: 'Date Joined', field: 'date_joined'},
                            { title: 'Relieved Date', field: 'relieved_date'},
                        ]}
                        data={_R.length(props.Users) > 0?props.Users:[]}
                        options={{
                            actionsColumnIndex: -1,
                            columnsButton:true,
                            toolbar:false,
                            paging: false,
                            selection: true,
                            showSelectAllCheckbox:true,
                                thirdSortClick: false
                        }}
                        onSelectionChange={(rows) => {
                            setRows(rows);
                            setShown(rows.length ? 'block' : 'none');
                        }}
                    />
                    }
                </Grid>
            </Grid>
            {props.HasPermission === false &&
            <Grid container spacing={10}>
                <Grid item md={12}>
                    <ErrorPage Message = {props.PermissionMsg}/>
                </Grid>
            </Grid>
            }
            {props.HasPermission &&
            <Grid container align={'right'}>
                <Grid item xs={12} sm={10} style={{display: shown }}>
                    <div className={'timeEntry__date'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                autoOk
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="MM/dd/yyyy"
                                className={'timeEntry-dateInput'}
                                value={props.SelectedDate}
                                onChange={props.handleTimeEntryDateChange}
                                label="Pay check date"
                            />
                        </LocalizationProvider>
                    </div>  
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button id="btnGeneratePayroll" variant="outlined" size="small" color="primary" align="right" style={{display: shown, margin: '1%' }} onClick={() => props.GeneratePayroll(rows)} >{'Generate Payroll'}</Button>
                </Grid>
            </Grid>
            }
            <StyledBackdrop className={'backdrop'} open={props.LoaderOpen}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
        </React.Fragment>
    )
};
