import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import { ListExpenseComponent } from './ListExpense.Component';
import { ExpenseForm } from './ExpenseForm';
import { ViewExpenseComponent } from './ViewExpense.Component';
import { ViewImageComponent } from './ViewImage.Component';
import SaveIcon from '@mui/icons-material/Save';
import { CustomizedSnackbars } from '../../common/snackbars';
import { DialogBoxLarge } from '../../common/dialog.large';
import { CustomProgressBar } from '../../common/CustomProgressBar';

export const AddExpenseComponent = (props) => {
    const FormProps = props;
    return (
        <React.Fragment>
            {!FormProps.permisionError && <form onSubmit={props.handleSubmit} encType="multipart/form-data">
                <div className={'col-left'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        {FormProps.permisionError} 
                    </Typography>
                </div>
                <Paper>
                    <ExpenseForm
                        formFields={props.formFields}
                        HandleExpenseDateChange = {props.HandleExpenseDateChange}
		    	Open={props.Open}
                        dialogOpen={props.dialogOpen}
                        handleOnChange={props.handleOnChange}
                        fileUploadCallback={props.fileUploadCallback}
                        handleAttachmentSelection={props.handleAttachmentSelection}
                        cancel={props.cancel}
                        getRecentFiles={props.getRecentFiles}
                        attachFiles={props.attachFiles}
                        errors={props.errors}
                        expenseCodes={props.expenseCodes}
                        expenseCategories={props.expenseCategories}
                        expenseTypes={props.expenseTypes}
                        projectCodes={props.projectCodes}
                        uploadButtonName={props.uploadButtonName}
                        uploadTitle={props.uploadTitle}
                        selectedFile={props.selectedFile}
                        recentFiles={props.recentFiles}
                        mileageRate={props.mileageRate}
                        attachedFileNames={props.attachedFileNames}
                        permisionError={props.permisionError}
                        joinedDate={props.joinedDate}
                        relievedDate={props.relievedDate}
                        focus={props.focus}
                        removeItem={props.removeItem}
                        currencySymbol={props.currencySymbol}
                        expenseMinAmount={props.expenseMinAmount}
                        Filetype={props.Filetype}
                        UploadSize={props.UploadSize}
                        Current={'add'}
                        />
                            <div className={'p1'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={2} className={'d-flex'}>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={'d-flex'}>
                                    </Grid>
                                    <Grid item xs={12} md={2} className={'d-flex justify-end'}>
                                        <DialogActions>
                                            <Button variant="contained" size="small" color="primary" type="submit" ><span className=
                                                {'MuiButton-startIcon MuiButton-iconSizeSmall'}><SaveIcon /></span>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                                            <Button color="primary" size="small" className={'MuiButton-outlined'} onClick={props.cancelEdit}>Cancel</Button>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </div>
                    </Paper>
            </form>}
            {props.permisionError && <p className='error' align='center'>{props.permisionError}</p>}
            {!props.permisionError && <ListExpenseComponent
                getExpenseList={props.getExpenseList}
                expenseData={props.expenseData}
                handleEditClick={props.handleEditClick}
                handleDeleteClick={props.handleDeleteClick}
                handleDownloadClick={props.handleDownloadClick}
                handleViewClick={props.handleViewClick}
                refreshList={props.refreshList}
                expenseFrom={props.expenseFrom}
                expenseTo={props.expenseTo}
                totalAmount={props.totalAmount}
                handleImageClick={props.handleImageClick}
                report={props.report}
                reportId={props.reportId}
                editableStatus={props.editableStatus}
                uneditableStatus={props.uneditableStatus}
                currencySymbol={props.currencySymbol}
                expenseMinAmount={props.expenseMinAmount}
                DeleteExpense={props.DeleteExpense}
                ExpenseDeleteOpen={props.ExpenseDeleteOpen}
                CloseExpense={props.CloseExpense}
            />}
            {!props.permisionError && <ViewExpenseComponent
                Open={props.open}
                dialogOpen={props.dialogOpen}
                formFields={props.viewPageFields}
                errors={props.errors}
                handleCancel={props.handleCancel}
                attachments={props.attachments}
                handleImageClick={props.handleImageClick}
                imageId={props.imageId}
                History={props.expenseChangeHistory}
            />}
            {!props.permisionError && <ViewImageComponent
                imageOpen={props.imageOpen}
                formFields={props.formFields}
                handleCancel={props.handleCancel}
                handleImageClick={props.handleImageClick}
                imageId={props.imageId}
            />}
            <CustomizedSnackbars
                open={props.snackOpen}
                variant={props.snackVariant}
                message={props.snackMessage}
                handleClose={props.snakbarClose}
            />
            {!props.permisionError && <DialogBoxLarge
                Open={props.openEdit}
                Cancel={props.cancelEdit}
                ButtonName={'Save'}
                OnSubmit={props.handleSubmit}
                Title={'Edit Expense'}
                RecentFiles={props.recentFiles}
                Content={
                   <Paper>
                        <CustomProgressBar
                            loaderOpen={props.showLoader}
                        />
                        <ExpenseForm
                            formFields={props.formFields}
                            HandleExpenseDateChange = {props.HandleExpenseDateChange}
			    Open={props.Open}
                            dialogOpen={props.dialogOpen}
                            handleOnChange={props.handleOnChange}
                            fileUploadCallback={props.fileUploadCallback}
                            handleAttachmentSelection={props.handleAttachmentSelection}
                            cancel={props.cancel}
                            getRecentFiles={props.getRecentFiles}
                            attachFiles={props.attachFiles}
                            errors={props.errors}
                            expenseCodes={props.expenseCodes}
                            expenseCategories={props.expenseCategories}
                            expenseTypes={props.expenseTypes}
                            projectCodes={props.projectCodes}
                            uploadButtonName={props.uploadButtonName}
                            uploadTitle={props.uploadTitle}
                            selectedFile={props.selectedFile}
                            recentFiles={props.recentFiles}
                            mileageRate={props.mileageRate}
                            attachedFileNames={props.attachedFileNames}
                            permisionError={props.permisionError}
                            joinedDate={props.joinedDate}
                            relievedDate={props.relievedDate}
                            focus={props.focus}
                            removeItem={props.removeItem}
                            currencySymbol={props.currencySymbol}
                            expenseMinAmount={props.expenseMinAmount}
                            Filetype={props.Filetype}
                            Current={'edit'}
                        />
                    </Paper>
                }
            />}

        </React.Fragment>
    )
};

