import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BusinessIcon from '@mui/icons-material/Business';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

function getIcon(icon) {
    let element = <div className="icon"><VolumeUpIcon style={{ fontSize: 60 }} /></div>;
    switch (icon) {
        case "PersonOutlineIcon":
            element = <div className="icon"><PersonOutlineIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "MailOutlineIcon":
            element = <div className="icon"><MailOutlineIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "GolfCourseIcon":
            element = <div className="icon"><GolfCourseIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "ListAltIcon":
            element = <div className="icon"><ListAltIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "BusinessIcon":
            element = <div className="icon"><BusinessIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "TuneIcon":
            element = <div className="icon"><TuneIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "SettingsIcon":
            element = <div className="icon"><SettingsIcon style={{ fontSize: 60 }} /></div>;
            break;
        case "VolumeUpIcon":
            element = <div className="icon"><VolumeUpIcon style={{ fontSize: 60 }} /></div>;
            break;
    }
    return element;
}

export const SystemComponent = (props) => { 
    return (
        <>
            <div className="box-list">
                {props.SystemComponents.map((r,i) =>
                <>
                {r.permission_flag &&
                <div className="item">
                    <a href onClick={props.assignLink(r.link)} style={{ cursor: 'pointer'}}>
                        <table>
                            <tr>
                                <td>
                                    {getIcon(r.icon)}
                                </td>
                                <td>
                                    <div className="content">
                                        <div className="title">{r.title}</div>
                                        <span className="info">{r.description}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>              
                    </a>  
                </div>
                }
                </>
                )}
            </div>
        </>
    )
}