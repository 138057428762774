import React from 'react';
import {IifReportComponent} from './IifReportComponent';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';
import { formatName } from '../../common/common';
import * as validations from '../../common/validate';

const formFields = {
    'staff_status':'',
    'staff_type' : '',
    'client_status' : '',
    'project_status' : '',
    'start_date' : '',
    'end_date' : '',
    'staffs' : [],
    'clients' : [],
    'projects' : [],
    'tasks' : [],
    'exclude_projects' : [],
    'labor_category' : '',
    'clin' : '',
}

const formInputs = {
    staff_status : [],
    staff_type : [],
    client_status : [],
    project_status : [],
    staff_list : [],
    client_list : [],
    project_list : [],
    exclude_project_list : [],
    labor_category :  [],
    clin :  [],
    hideLaborCategory : true,
    hideClin : true,
}
let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) { 
            getProjectTree(e.children, selected_projects,result);
        }
    });
}

export class IifReportContainer extends React.Component{
     constructor(props) {
        super(props);
        this.state ={
            formFields: formFields,
            formInputs: formInputs,
            hasPermission : true,
            name_format: '',
            loaderOpen : false,
            onSearch : false,
            errors : {}
        }
        this.exportReports = this.exportReports.bind(this);
     }
     componentDidMount() {
        httpServices.get('api/report/timesheetIifReport')
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        formInputs:{
                            staff_status : response.data.staff_status,
                            staff_type : response.data.staff_type,
                            client_status : response.data.client_status,
                            project_status : response.data.project_status,
                        },
                        formFields:{
                            'staff_status' : "0",
                            'staff_type' : "0",
                            'client_status' : "1",
                            'project_status' :"1",
                            'start_date' : response.data.start_date,
                            'end_date' : response.data.end_date,
                            'staffs' : [0],
                            'clients' : [0],
                            'projects' : [0],
                            'exclude_projects' :[],
                            'labor_category' : 0,
                            'clin' : 0,
                        },
                        name_format : response.data.name_format
                    });
                    this.getStaffByType("0","0");
                    this.getClientBystatus(1);
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
        window.scrollTo(0, 0);    
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        if(field === 'staff_status'){
             this.getStaffByType(evt.target.value,ff['staff_type']);
             ff['staffs']=[];
        }
        if(field === 'staff_type'){
             this.getStaffByType(ff['staff_status'],evt.target.value);
             ff['staffs']=[];
        }
        if(field=== 'client_status')
        {
            this.getClientBystatus(evt.target.value);
            ff['clients']=[];
            ff['projects']=[];
        }
        if(field=== 'project_status')
        {
            this.getProjectBystatus(evt.target.value);
            ff['projects']=[];
        }
        if(field=== 'labor_category')
        {
            ff['labor_category']=evt.target.checked;
        }
        if(field=== 'clin')
        {
             ff['clin']=evt.target.checked;
        }
    
        this.setState({
            formFields: ff
        });
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.formFields;
        let values=this.state.formInputs;
        let staffs = [];
        let clients=[];
        let projects = [];
        let excluded = [];
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                if (action === 'clients') {
                    clients.push(0);
                }
                else if (action === 'staffs') {
                    staffs.push(0);
                }
                else if (action === 'project') {
                    projects.push(0);
                }
            }
        }
        else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id || el.client_id || el.user_id) {
                    if (action === 'staffs') {
                        staffs.push(el.user_id);
                    }
                    else if (action === 'clients') {
                        clients.push(el.client_id);
                    }
                    else if (action === 'project') {
                        getProjectTree(values.project_list, el.project_id, projects);     
                    }
                    else if (action === 'excluded_project') {
                        getProjectTree(values.exclude_project_list, el.project_id, excluded);
                    }
                }
            });
        }
        if (action === 'staffs') {
            f['staffs'] = staffs;
        }
        else if(action === 'clients'){
            f['clients'] = clients;
            f['projects']=[0];
            this.getProjectBystatus(this.state.formFields.project_status);
        }
        else if (action === 'project') {
            f['projects'] = projects;
        }
        else if (action === 'excluded_project') {
            f['exclude_projects'] = excluded;
        }
        this.setState({formFields : f});
    }
    getStaffByType(staff_status,staff_type){
        httpServices.post('api/staff/list-staff-by-type',{ 'staff_status':staff_status,'staff_type' : staff_type })
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    let users=[];
                    response.data.users.forEach(function(user){
                        user['full_name'] = formatName(user.first_name,null,user.last_name);
                        users.push(user);
                    });
                    finputs['staff_list'] = _ts.prepareTreeData(users, 'staff');
                    this.setState({
                        formInputs:finputs,
                    });
                }
            })
    }
    getClientBystatus(client_status){
        httpServices.post('api/project/get-clients-by-status',{ 'status' : client_status })
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['client_list'] = _ts.prepareTreeData(response.data, 'clients');
                    this.setState({
                        formInputs:finputs,
                    });
                    this.getProjectBystatus(this.state.formFields.project_status);
                }
            })
    }
    getProjectBystatus(project_status){
        let client=JSON.parse(JSON.stringify(this.state.formFields.clients));
        if( !(client.length && parseInt(client))){
            client=[];
            this.state.formInputs.client_list.map((t, i) =>
                client.push(t.client_id)
                );
        }
        httpServices.post('api/project/get-projects-by-status',{ 'status' : project_status , 'clients' : client})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['project_list'] =  _ts.prepareTreeData(response.data, 'projects');
                    finputs['exclude_project_list'] =  _ts.prepareTreeData(JSON.parse(JSON.stringify(response.data)),'projects',false);
                    this.setState({
                        formInputs:finputs,
                    });
                }
            })
    }
    OnSearchClick = () =>{
        this.setState({loaderOpen : true, onSearch : true });
        httpServices.post('api/report/timesheetIifReport',{ 'data' : this.state.formFields, 'clients' : this.state.formFields.clients, 'projects' : this.state.formFields.projects, 'staff' : this.state.formFields.staffs, 'exclude_projects' : this.state.formFields.exclude_projects})
            .then(response => {
                if (!response.data.errors) {
                    let finputs = this.state.formInputs;
                    finputs['timesheet_list'] = response.data;
                    let hideLaborCategory = true;
                    let hideClin = true;
                    if (this.state.formFields.labor_category) {
                        hideLaborCategory = false;
                    }
                    if (this.state.formFields.clin) {
                        hideClin = false;
                    }
                    this.setState({
                        formInputs:finputs,
                        loaderOpen : false,
                        hideLaborCategory : hideLaborCategory,
                        hideClin : hideClin,
                    });
                }
                else {
                    this.setState({
                        errors: response.data.errors,
                        loaderOpen: false
                    });
                }
            })
        }
    
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'start_date', value: this.state.formFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.formFields.end_date, type: 'date', otherOptions: { required: true } },
            { name: 'staff_status', value: this.state.formFields.staff_status, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'staff_type', value: this.state.formFields.staff_type, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'client_status', value: this.state.formFields.client_status, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'project_status', value: this.state.formFields.project_status, type: 'alphanumeric', otherOptions: { required: false } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }
            
    render(){
       return(
                (this.state.hasPermission)
                ?
                <IifReportComponent 
                    formInputs = { this.state.formInputs }
                    formFields = { this.state.formFields }
                    Errors = { this.state.errors }
                    HandleOnChange = { this.HandleOnChange }
                    OnSearchClick = { this.OnSearchClick.bind(this) }
                    HandleTreeChange = {this.handleTreeChange}
                    name_format ={ this.state.name_format}
                    LoaderOpen = {this.state.loaderOpen}
                    OnSearch = {this.state.onSearch}
                    ExportReports = {this.exportReports}
                    Errors = {this.state.errors}
                    HideLaborCategory = {this.state.hideLaborCategory}
                    HideClin = {this.state.hideClin}
                />
                :<ErrorPage/>
       )
    }
}
