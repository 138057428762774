import React from 'react';
import { connect } from "react-redux";
import { setSelectedTabIndex } from '../../../redux/store/reducers/tabs';
import { history } from './../../../helpers/history';
import {AdditionalptoComponent} from './AdditionalptoComponent';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';
import { getRelativeUrl } from '../../common/common';
import { setSavedFilters, updateSavedFilterAction } from '../../../redux/store/reducers/savedFilters';


const filterFields = {
    'my': {
        'year' : null,
        'requestStatus' : 0,
        'employeeName' : 0,
        'employeeStatus':1,
    } ,
    'review': {
        'year' : null,
        'requestStatus' : 1,
        'employeeName' : 0,
        'employeeStatus':1,
    } ,
    'all':{
        'year' : null,
        'requestStatus' : 0,
        'employeeName' : 0,
        'employeeStatus':1,
    }

}
class AdditionalptoContainer extends React.Component{
    constructor(props){
        super(props);
        const additionalptoTabSelected = this.props.selectedAdditionalptoTab;
        let filter = filterFields[additionalptoTabSelected];
        this.state ={
            myRequests : [],
            allRequests : [],
            reviewRequests : [],
            editId : 0,
            open : false,
            viewId :0,
            viewClick : false,
            formFields : filter,
            pto_eligible :false,
            pto_balance : 0,
            pto_details : false,
            review_count : 0,
            tab : additionalptoTabSelected,
            snackbarOpen : false,
            snackbarMsg : '',
            permission : false,
            allrequest_view : false,
            showLoader : true,
            dataLoaded : false,
            requestView : false,
            saveFilter : false,
            counter : 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.getAdditionalptoDetails = this.getAdditionalptoDetails.bind(this);
        this.createAdditionalpto = this.createAdditionalpto.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.saveFilterToStore = this.saveFilterToStore.bind(this);
        this.saveFilterValues = this.saveFilterValues.bind(this);
    }

    componentDidMount = () => { 
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;
        if (fromDashboard) {
            let formFields = this.state.formFields;
            formFields['year'] = null;
            formFields['requestStatus'] = 1;
            formFields['employeeName'] = 0;
            formFields['employeeStatus'] = 1;
            this.setState({
                formFields: formFields
            });
        }
        let tab = this.state.tab;
        this.props.setSavedFilterVal();
        this.getAdditionalptoDetails(tab);
    };
    componentDidUpdate = (prevProps) => { 
        // Fetching additional pto details based on the redux store values after store values are set initially
        if (prevProps.savedFilterValues.length > 0 && this.state.counter === 0) {
            this.getAdditionalptoDetails(this.state.tab);
            this.setState({
                counter: 1
            });
        }
    }
    
    getAdditionalptoDetails = (tab = '') =>{
        let apiEndpoint = 'api/additionalpto/list';
        let filter = filterFields[tab];
        let savedFilterValues = this.props.savedFilterValues;
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl + '#' + tab;
            let indexValue = savedFilterValues.findIndex((element) => element.relative_url === relativeUrl);
            if (indexValue !== -1) {
                let savedFilter = savedFilterValues[indexValue]['filter_options'];
                filter = Object.assign(filter, savedFilter);
            }
        }
        let data =  {
            'year' : filter.year,
            'requestStatus' : filter.requestStatus,
            'employeeName' : filter.employeeName,
            'employeeStatus':filter.employeeStatus,
            'tab':tab 
        }
        this.setState({showLoader : true});
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission){
                        let requests = response.data.requests;
                        this.setState({ 
                                requestView : response.data.request_view,
                                tab : response.data.tabName,
                                dataLoaded : true,
                        });
                        filter.year = response.data.year;
                        if (data.tab !== this.state.tab) {
                            this.filterSubmit(this.state.tab);
                        }
                        if (tab === 'my') {
                            this.setState({
                                myRequests : requests,
                                requestStatus :response.data.requestStatus,
                                pto_eligible :response.data.pto_eligible, 
                                pto_balance:response.data.pto_balance , 
                                review_count:response.data.review_count ,
                                permission : response.data.permission ,
                                allrequest_view : response.data.allrequest_view,
                                formFields :filter,
                                showLoader : false
                            });

                        } else if (tab === 'all') {
                            this.setState({
                                allRequests : requests,
                                requestStatus :response.data.requestStatus,
                                emp_status:response.data.emp_status,
                                users:response.data.users, 
                                review_count:response.data.review_count, 
                                pto_balance:response.data.pto_balance,
                                permission : response.data.permission,
                                allrequest_view : response.data.allrequest_view,
                                formFields :filter,
                                showLoader : false
                            });
                        } else if (tab === 'review') {
                            this.setState({
                                reviewRequests : requests,
                                requestStatus :response.data.requestStatus,
                                emp_status:response.data.emp_status,
                                users:response.data.users,
                                review_count:response.data.review_count, 
                                pto_balance:response.data.pto_balance,
                                permission : response.data.permission,
                                allrequest_view : response.data.allrequest_view,
                                formFields :filter,
                                showLoader : false
                            });
                        }
                    }
                    else {
                        this.setState({
                                permission : response.data.permission,
                                showLoader : false,
                                dataLoaded : true,
                            });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    createAdditionalpto = (agree) => {
        if ( this.state.pto_balance > 0 && !agree ) {
            this.setState({
                pto_details: true
            });
        } else {
            this.setState({
                open : true,
                editId : 0,
                pto_details: false
            });
            this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'additional-pto', 'action' : 'create-additional-pto-request-clicked'});
        }
    }
    handleEditClick = (id) => {
        if(id) {
            this.setState({open : true,
                editId :id});
        }
    }

    handleChange = (event, value) => {
        let tab = '';
        switch (value) {
            case 0:
                tab = 'my';
                break;
            case 1:
                tab = 'review';
                break;
            case 2:
                tab = 'all';
                break;
            default:
                tab = 'my';
        }
        let obj = { page: "additionalpto", selected: tab }
        this.props.setTabIndexValue(obj);
        this.setState({ tab: tab, });
    }
 
    handleViewClick = (id) => {
        if(id) {
            history.push('additionalpto/view/' + id);
        }
    }
    updateParent = (successFlag) => {
        
        this.setState({
            open : false,
        });
        if (successFlag) { // Update list data
            let editId = this.state.editId;
            let msg = '';
            if (editId) {
                msg = "Request updated successfully";
                editId = 0;
            } else {
                msg = "Request submitted successfully";
            }
            this.getAdditionalptoDetails('my');
            this.setState({
                snackbarOpen : true,
                snackbarMsg : msg,
            });
        }
    }
    
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            formFields: ff
        });
    };


    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'saveFilter') {
            this.setState({
                saveFilter: value
            });
        }
        else {
            f[name] = evt.target.value;
            this.setState({
                formFields: f
            }, () => {
                let tab = this.state.tab
                if (tab != 'my' && (name === 'employeeStatus' || name === 'year')) {
                    this.getEmployee();
                }
            });
        }
    }
    saveFilterValues = (tab) => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl+'#'+tab;
            let apiEndpoint = 'api/user/save-filter';
            let data = {
                relative_url : relativeUrl,
                filter_options : this.state.formFields,
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    saveFilterToStore = (tab) => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl + '#' + tab;
            let obj = { relative_url: relativeUrl, filter_options: this.state.formFields }
            this.props.updateSavedFilterVal({ obj });
        }
    }    
    filterSubmit = (tab) =>{
        this.saveFilterToStore(tab);
        if (this.state.saveFilter) {
            this.saveFilterValues(tab);
        }
       this.setState({
            filterViewState : false
        },function() {
            this.getAdditionalptoDetails(tab);
        });
    }
    getEmployee = () =>{
        let f = this.state.formFields;
        let apiEndpoint = 'api/additionalpto/list-users';
        let data = {'employeeStatus': f.employeeStatus, 'year': f.year, 'tab' : this.state.tab};
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) {
                            this.setState({
                                users:response.data.users
                            });
                        }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    closePtoInfo = () => {
        this.setState({
            pto_details: false
        });
    }
    
    tabChange = (tab) =>{
        this.setState({
           saveFilter : false,      
        });
        this.setState({
          showLoader : true}, 
            function(){  
                this.getAdditionalptoDetails(tab);
            }
            );
        let mixPanelAction = '';
        if (tab === 'my') {
            mixPanelAction = 'my-requests-tab-clicked';
        } else if (tab === 'review') {
            mixPanelAction = 'review-requests-tab-clicked';
        } else if (tab === 'all') {
            mixPanelAction = 'all-requests-tab-clicked';
        }
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'additional-pto', 'action' : mixPanelAction});
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    resetFilter = (tab) => {
        let ff = this.state.formFields; 
        ff['year'] = null;
        ff['requestStatus'] = tab === 'review' ? 1:0;
        ff['employeeStatus'] = '1';
        ff['employeeName'] = '0' ;
        this.setState({
            formFields: ff,
            right: false
        },()=>{
            this.filterSubmit(tab);}
        );
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                    <AdditionalptoComponent 
                        HandleChange={this.handleChange}
                        Requests = {this.state.requests}
                        TabChange = {this.tabChange}
                        MyRequests = {this.state.myRequests}
                        AllRequests = {this.state.allRequests}
                        EditId = {this.state.editId}
                        ReviewRequests = {this.state.reviewRequests}
                        Open = {this.state.open}
                        CreateAdditionalpto = {this.createAdditionalpto}
                        HandleEditClick = {this.handleEditClick} 
                        UpdateParent = {this.updateParent} 
                        HandleViewClick = {this.handleViewClick}
                        FilterView = {this.filterView}
                        FilterViewState = {this.state.filterViewState}
                        FilterSubmit = {this.filterSubmit}
                        FormFields = {this.state.formFields}
                        HandleOnChange ={this.handleOnChange}
                        ClearForm ={this.clearForm.bind(this)}
                        RequestStatus = {this.state.requestStatus} 
                        EmployeeStatus = {this.state.emp_status} 
                        Users = {this.state.users} 
                        PtoEligible = {this.state.pto_eligible}
                        PtoDetails = {this.state.pto_details} 
                        ReviewRequestsCount = {this.state.review_count}
                        Close = {this.closePtoInfo} 
                        PtoBalance= {this.state.pto_balance}
                        SnackbarOpen = {this.state.snackbarOpen}
                        SnackbarMsg = {this.state.snackbarMsg}
                        SnackbarClose = {this.snackbarClose}
                        AllRequestView= {this.state.allrequest_view}
                        Tab = {this.state.tab}
                        RequestView = {this.state.requestView}
                        ResetFilter = {this.resetFilter.bind(this)}
                        SaveFilter = {this.state.saveFilter}
                    />
                    :<ErrorPage/>}
                    </>
                :<></>}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const savedFilterValues =  state.savedFilters.savedFilterValues ? state.savedFilters.savedFilterValues : {};
    const selectedAdditionalptoTab = state.Tabs.allTabs['additionalpto'] ? state.Tabs.allTabs['additionalpto'] : 'my';
    return {
        savedFilterValues: savedFilterValues,
        selectedAdditionalptoTab:selectedAdditionalptoTab
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setSavedFilterVal: () => dispatch(setSavedFilters()),
        setTabIndexValue: (obj) => dispatch(setSelectedTabIndex(obj)),
        updateSavedFilterVal: ({obj}) => dispatch(updateSavedFilterAction({obj}))
    }
}
const connectedAdditionalptoContainer = connect(mapStateToProps, mapDispatchToProps)(AdditionalptoContainer);
export { connectedAdditionalptoContainer as AdditionalptoContainer };
