import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import * as _R from 'ramda';  
import { formatName } from '../../../common/common';
import { CustomizedSnackbars } from '../../../common/snackbars';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const API_URL = process.env.REACT_APP_API_URL;

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="Eligibility Criteria Group" error={props.Errors.group_id && true} required>Eligibility Criteria Group</InputLabel>
                            <Select
                                value={props.FormFields.group_id}
                                inputProps={{
                                    name: 'group_id',
                                    id: 'group_id',
                                }}
                                onChange={props.HandleOnChange}
                            >
                                {props.EligibilityCriteriaGroups.map((group, i) =>
                                    <MenuItem value={group.id}>{group.name}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{props.Errors.group_id}</small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value={props.FormFields.effective_date}
                                id="effective_date"
                                name="effective_date"
                                label="Effective date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={props.HandleOnChange}
                                error={props.Errors.effective_date && true}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="note"
                                name="note"
                                label="Note"
                                multiline
                                required
                                value={_R.type(props.FormFields) !== 'Undefined' && props.FormFields.note}
                                onChange={props.HandleOnChange}
                                margin="normal"
                                error={props.Errors.note && true}
                                helperText={props.Errors.note}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};
export const TimeofftypePolicyComponent = (props) => {
    const { Edit } = props;
    const columns = [
        { title: 'Eligibility Criteria Group', render: rowData => (
            <a href={API_URL + 'system/functional/eligibilitycriteria'} onClick={() => localStorage.setItem('clicked_group', rowData.group_id)}> {rowData.criteria_group} </a>
          )
        },
        { title: 'Effective date', field: 'effective_date' },
        { title: 'Note', field: 'note' },
        { title: 'Created by', field: 'created_by',render: rowData => <>{formatName(rowData.cby_first_name,null,rowData.cby_last_name)}</> },
        { title: 'Created on', field: 'created_on' },
    ];
    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Staff Eligibility Criteria Override"
                    columns={columns}
                    data={props.PTOPolicy}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging:false,
                    }}
                    actions={[
                        {   
                            icon: 'add',
                            tooltip: 'Add Staff Eligibility Criteria Override',
                            isFreeAction: true,
                            onClick: (event, newData) => { 
                                props.OnAdd();
                            },
                            hidden: (Edit) ? false : true,
                        }
                    ]}
                />
            </div>
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.Close}
                Title={props.IsEdit ? 'Edit Staff Eligibility Criteria  Override' : 'Add Staff Eligibility Criteria Override'}
                OnSubmit={props.SavePTOPolicy}
                Content={<DialogComponent FormProps={props} />} />
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Variant}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    );
}
