import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { PreviewComponent } from '../../../common/Preview'; 
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

export const PhotoComponent = (props) => {
    const { Photos, onAdd, onDeleteSubmit, EditPermission} = props;
    let data = (_R.type(Photos) !== 'undefined') ? Photos : {};
    let Edit = EditPermission;
    return (  
        <>
         {_R.equals(Edit, true) &&
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Photos</Typography>
                <Tooltip title="Add Photo" aria-label="add">
                    <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={onAdd}>
                        <AddIcon />
                    </Fab>
                </Tooltip>                
            </div>
             }
            {_R.equals(Edit, true) &&
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Set as Default</TableCell>
                            <TableCell>Uploaded by</TableCell>
                            <TableCell>Uploaded on</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {typeof data != 'undefined' && data.length
                                ? data.map((d, index) =>
                                    <TableRow hover>
                                        <TableCell> {d.name} </TableCell>
                                        <TableCell>
                                            <RadioGroup aria-label="default_option" name="default_option" value={1} onChange={() => props.HandleOptionChange(d.id)}>
                                                <FormControlLabel value={d.default_photo} control={<Radio />} label="" />
                                            </RadioGroup>
                                        </TableCell>
                                        <TableCell>{d.uploadedBy}</TableCell>
                                       <TableCell>{d.uploadedOn?d.uploadedOn:''}</TableCell>
                                        <TableCell align="right">
                                            <IconButton className={'action-btn'} edge="end" aria-label="View File">
                                                <FindInPageIcon fontSize="small" onClick={() => props.HandleViewClick(d.aws_url)} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {d.default_photo === 0 &&
                                                <Tooltip title="Delete" aria-label="delete">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                        <DeleteIcon fontSize="small" onClick={() => props.HandleDelete(d.id)} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </TableCell>

                                    </TableRow>
                                )
                                : <></>}
                    </TableBody>
                </Table>
            </div>
            }
            <DialogBoxMedium 
                Open= { props.DialogOpen }  
                Cancel = { props.onClose }
                Title = { 'Add Photo' }
                OnSubmit = { props.OnSubmit }
                Loading={props.ButtonLoader}
                Content = { <DialogContent FormProps = { props }/> }
            />
             <CustomizedSnackbars
                open={props.snackOpen}
                variant={props.snackVarient}
                message={props.snackMessage}
                handleClose={props.snakbarClose}
            />
            {props.FileOpen  && 
             <PreviewComponent 
                FilterView = {props.FilterView}
                FileOpen = {props.FileOpen}
                FileUrl = {props.FileUrl}
                Variant = "persistent"
                Anchor = "right"
                classes = {'drawerheight'}
            />
            }
             <DialogBoxMedium
                Open= { props.DialogWarningOpen }  
                Cancel = { props.onClose }
                Title={'Confirm Default Photo'}
                ButtonName ={'Yes'}
                OnSubmit={props.HandleSetDefaultPhoto}
                Content={<DialogConfirm msg = {"Do you want to set this photo as default ?"}/>}
            />
             <DialogBoxMedium
                Open= { props.DialogDeleteWarning }  
                Cancel = { props.onClose }
                Title={'Confirm Delete Photo'}
                ButtonName ={'Yes'}
                OnSubmit={props.OnDeleteSubmit}
                Content={<DialogConfirm msg = {"Do you want to delete this photo ?"}/>}
            />
        </>
    )
};

const DialogContent = (p) => {
    const { FormProps: props } = p;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <input type="file" name="file" id="file" onChange={props.HandleOnChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.setasdefault == 1 ? true : false}
                                onChange={props.HandleDefaultClick}
                                color="primary"
                            />
                        }
                        label="Set as default"
                    />
                </Grid>

            </Grid>
        </form>
    )
}
const DialogConfirm = (p) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    {p.msg}
                </Typography>
            </Box>
        </div>
    )
}
