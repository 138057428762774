import React  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import * as R from 'ramda';
import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LockIcon from '@mui/icons-material/Lock';
import { DialogBoxMedium } from '../../common/dialog.medium';

import { TimeEntryEdit } from './TimeEntryEdit';
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const TimesheetList  = props => {
    const timesheetLists = props.TimesheetList;
    const approvedTimesheetLink = () => {
        window.location = BASE_PATH+'/timesheet/submit/'+props.TimesheetId;
    };
    return (
        <React.Fragment>
            <div className={'timeSheet-list_header d-flex justify-between align-stretch'}>
                <div className={'col-main d-flex justify-between align-stretch d-felx_grow1'}>
                    <div className={'col-item d-felx_grow1'}>
                        {(R.type(timesheetLists) !== 'Undefined' && R.type(timesheetLists.status) !== 'Undefined') &&
                        <div className={'widget_status'}>
                            <label>Status</label>
                            <span className="value"><span className={'statusSqr ' + timesheetLists.status.toLowerCase()}></span>  {timesheetLists.status}</span>
                        </div>
                        }
                    </div>
                </div>
                { ((typeof props.HoursSummary !== 'undefined') && (props.HoursSummary.limit==='daily_minimum')) &&
                <>
                    <div className={'col-action col-summary'}>
                        <div className={'col-time'}>Till {typeof props.HoursSummary !== 'undefined' ? props.HoursSummary.sdate : ''}</div>
                    </div> 
                    <div className={'col-action'}>
                        <div className={'col-time'}>{typeof props.HoursSummary !== 'undefined' ? props.HoursSummary.min_hours_till : ''} Hours<span className={'info'}> Required</span></div>
                    </div>
                    <div className={'col-action col-summary'}>
                        <div className={'col-time'}>
                            <span className={(props.HoursSummary !== 'undefined' && parseFloat(props.HoursSummary.total_hours_till)) ? (parseFloat(props.HoursSummary.min_hours_till) > parseFloat(props.HoursSummary.total_hours_till) ? 'min-hour-warning-label' : 'min-hour-success-label' ): 'min-hour-error-label'}>{typeof props.HoursSummary !== 'undefined' ? props.HoursSummary.total_hours_till : ''} Hours</span>
                            <span className={'info'}> Saved</span>
                        </div>
                    </div>
                    <div className={'col-action'}>
                        <div className={'col-time col-time-variance'}>
                            {(typeof props.HoursSummary !== 'undefined' && typeof props.HoursSummary.total_hours_till !== 'undefined' && typeof props.HoursSummary.min_hours_till !== 'undefined') ? ((props.HoursSummary.total_hours_till - props.HoursSummary.min_hours_till) == 0 ? <span>{(props.HoursSummary.total_hours_till - props.HoursSummary.min_hours_till).toFixed(2)} Hours</span> : (props.HoursSummary.total_hours_till - props.HoursSummary.min_hours_till > 0 ?<span style={{color:'green'}}>{(props.HoursSummary.total_hours_till - props.HoursSummary.min_hours_till).toFixed(2)} Hours</span>:<span style={{color:'red'}}>{(props.HoursSummary.total_hours_till - props.HoursSummary.min_hours_till).toFixed(2)} Hours</span> )): ''}                            
                            <span className={'info'}> Variance</span>
                        </div>
                    </div>
                </>}
                {((typeof props.HoursSummary !== 'undefined') && (props.HoursSummary.limit==='weekly_limit')) &&
                <div className={'col-action'}>
                    <div className={'col-time'}>{timesheetLists.grand_total_hours ? timesheetLists.grand_total_hours : '0.00'} Hours<span className={'info'}>Total</span></div>
                </div>}
            </div>
            <div className={'timeSheet-list_body'}>
                <Table size="small" aria-label="Timesheet List" className={'list-table_basic'}>
                    <TableHead className={'thead'}>
                        <TableRow>
                            <TableCell className={'date'}>Date</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>Task</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell className={'text-right'}>Last modified</TableCell>
                            <TableCell className={'action'}>Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {
                            R.type(timesheetLists) !== 'Undefined' && R.type(timesheetLists.timeslices) !== 'Undefined' && (!timesheetLists.is_submitted) && timesheetLists.timeslices.map((list, parent_index) =>
                                R.type(list.project_details) !== 'Undefined' && list.project_details.length > 0 ? 
                                <>
                                {list.project_details.map((rdata, ci) => 
                                <TableRow hover className={(props.selectedDate === list.date)?'td-active':''}>
                                    {(ci === 0) && 
                                    <TableCell className={'vtop'} rowSpan ={list.row_span_count}>
                                        <Link href="#" onClick={() => props.GetTimesheetByDate(list.date)} >{list.modified_date}</Link>
                                        <Tooltip title="Copy Time Entries" aria-label="copy_time_entries">
                                            <IconButton  size="small"  className={'action-btn'} edge="end">
                                             {(list.project_details.length === 1 ? (rdata.code !== "HO" && rdata.code !== "TO") : true) &&
                                                <FileCopyIcon fontSize="small" aria-haspopup="true" onClick={() => props.CopyTimeEntries(list.date)} />
                                             }
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    }
                                    <TableCell>
                                                <Tooltip title={rdata.tasktype_name} aria-label="tasktype">
                                                    <span className={'tasktype-badge '+rdata.code.toLowerCase()}>{rdata.code}</span>
                                                </Tooltip>
                                    </TableCell>
                                    <TableCell className={parseInt(rdata.non_confirmity) ? 'error' :''}>{rdata.client_name}</TableCell>
                                    <TableCell className={parseInt(rdata.non_confirmity) ? 'error' :''}>{rdata.project_path}</TableCell>
                                    <TableCell className={parseInt(rdata.non_confirmity) ? 'error' :''}>{rdata.task_name+' '} 
                                        {(rdata.enable_location == true) &&
                                            <>
                                                {rdata.location_color_code ?
                                                    <span className='tasktype-badge wh'  style={{ backgroundColor: rdata.location_color_code }}>
                                                        {rdata.location_name}
                                                    </span>
                                                    :
                                                    rdata.location_name ? 
                                                    <span className='tasktype-badge wh'>
                                                        {rdata.location_name}
                                                    </span>
                                                    :
                                                    <span></span>
                                                }
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <div className={parseInt(rdata.non_confirmity) ? 'timesheet-list_row-description error' : 'timesheet-list_row-description'}>{parse(rdata.timeslice_log)}</div>
                                        {rdata.tags &&
                                        <div className={'timesheet-list_row-tags'}>
                                            {Object.values(rdata.tags).map((tag) =>
                                                        <span className={'tag'}>{tag.tag_name}</span>
                                                    )}
                                        </div>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <div className={parseInt(rdata.non_confirmity) ? 'timesheet-list_row-logtime error' : 'timesheet-list_row-logtime'}>{rdata.formatted_timeslice_mdate}</div>
                                    </TableCell>
                                    <TableCell className={'td-action'}>
                                        <div  className="action">
                                            <span className={'timesheet-list_row-time col-time'}>{rdata.timeslice_hours}</span>
                                            <div className="action-btn">
                                            {rdata.can_edit &&
                                            <>
                                            <Tooltip title="Review Notes" aria-label="review_notes">
                                                <IconButton  size="small"  className={'action-btn'} edge="end" aria-label="Review Notes">
                                                    <AssignmentIcon fontSize="small" aria-haspopup="true" onClick={() => props.ListReviewNotes(rdata.timeslice_id)} />
                                                </IconButton>
                                            </Tooltip>
                                                {rdata.sync_timeslice_id == null  &&
                                                    <>
							{(!rdata.locked) ?
							<>
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton size="small" className={'action-btn'} edge="end" aria-label="Edit">
                                                                <EditIcon onClick={() => props.EditTimeEntry(rdata.timeslice_id)} fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" aria-label="delete">
                                                            <IconButton size="small" className={'action-btn'} edge="end" aria-label="Delete">
                                                                <DeleteIcon fontSize="small" data-timeslice_id={rdata.timeslice_id} onClick={props.OnDelete} />
                                                            </IconButton>
                                                        </Tooltip>
							</>
							:
                                                            <div className='timesheet-list_row-locked'>
                                                                <IconButton size="small" className={'action-btn'} edge="end">
                                                                    <LockIcon aria-haspopup="true" onClick={() => props.GetLockedDetails(rdata.lock_created_by, rdata.locked_on)}>
                                                                    </LockIcon>
                                                                </IconButton>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {rdata.sync_timeslice_id && rdata.locked &&
                                                    <>
                                                        <div className='timesheet-list_row-locked'>
                                                            <IconButton size="small" className={'action-btn'} edge="end">
                                                                <LockIcon aria-haspopup="true" onClick={() => props.GetLockedDetails(rdata.lock_created_by, rdata.locked_on)}>
                                                                </LockIcon>
                                                            </IconButton>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            }
                                            </div>
                                        </div>
                                    </TableCell>
                                   
                                </TableRow>
                            )}
                            <TableRow className={'timesheet-list_row-daytotal'}>
                                <TableCell colSpan="7"></TableCell>
                                <TableCell>
                                    <div  className="action">
                                        <span className={'timesheet-list_row-time col-time'}><span className={'info'}> Day total </span>{list.day_total.toFixed(2)}</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                            </>
                            : R.type(list.project_details) !== 'Undefined' && list.project_details.length < 1 && 
                                list.off_day ? 
                                <TableRow hover className={(props.selectedDate === list.date)?'td-active holiday':'holiday'}>
                                    <TableCell className={'vtop'}><Link href="#" onClick={() => props.GetTimesheetByDate(list.date)}>{list.modified_date}</Link></TableCell>
                                    <TableCell colSpan={7}></TableCell>
                                </TableRow>
                                :
                                <TableRow hover className={(props.selectedDate === list.date)?'td-active':''}>
                                    <TableCell className={'vtop'}><Link href="#" onClick={() => props.GetTimesheetByDate(list.date)}>{list.modified_date}</Link></TableCell>
                                    <TableCell colSpan={7}><span className={'error'}>No hours saved</span></TableCell>
                                </TableRow>
                            )
                        }
                        {R.type(timesheetLists) !== 'Undefined' && (timesheetLists.status === 'Approved') &&
                            <TableRow hover>
                                <TableCell colSpan={7}>Timesheet for this pay period is approved. <Link href="#" onClick={approvedTimesheetLink}>Click here</Link> to view</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <TimeEntryEdit
                timeSliceId={props.clickedTimeSliceId}
                Open= { props.timeSliceEditDialogOpen }
                Cancel = { props.timeSliceEditDialogClose }
                timeSheetId = {props.timeSheetId}
                getTimesheetDetails = {props.getTimesheetDetails}
            />
            {props.PopupType === 'show-review-list' &&
            <ReviewList
                ReviewComments = {props.ReviewNotes}
                Open = {props.Open}
                Cancel={props.Close}
                Title='Review Notes'
            />
            }
            {props.PopupType === 'show-locked-details' && 
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.Close}
                HideSaveButton={true}
                Title='Warning'
                OnSubmit={props.Close}
                Content={
                    props.PopupMsg
                }
            />
            }
        </React.Fragment>
    )
}
const ReviewList = (props) => {
    let cmnts = props.ReviewComments.length > 0 ? props.ReviewComments : [];
    return (
        <Dialog
            className={'modal_default'}
            open={props.Open}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">{props.Title}</DialogTitle>
                <DialogContent>
                    <>
                        <Grid container spacing={3}>
                        {(cmnts && cmnts.length > 0) ? cmnts.map((c,i) =>
                        <>
                            <Grid item xs={6}>
                                <p>{c.note}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{c.user_fullname}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>{c.comment_ctime}</p>
                            </Grid>
                        </>
                        )
                        : <Grid item xs={6}>
                            <span>No notes for this time entry.</span> </Grid>
                        }
                </Grid>
            </>
        </DialogContent>
        <DialogActions>
            <Button color="primary" size="small" onClick={props.Cancel}>{props.CancelButtonName ? props.CancelButtonName : 'Cancel'}</Button>
        </DialogActions>
    </Dialog>
    )
}



