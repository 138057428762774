import React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material//TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material//MenuItem";
import { CustomizedSnackbars } from "../../common/snackbars";
import { DialogBoxMedium } from "../../common/dialog.medium";
import FormHelperText from "@mui/material/FormHelperText";

export const ClientEditComponent = (props) => {
    return (
            <>
            <DialogBoxMedium
            Open={props.Open}
            Cancel={props.OnClose}
            Title={props.PopupType === "add" ? "Add Client" : "Edit Client"}
            OnSubmit={ ()=>props.HandleSubmit(props.ClientId)}
            Content={<DialogEdit FormProps={props} />}
            />
            
            <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid ? "success" : "error"}
                message={
                    props.Valid ? props.SuccessMessage : props.ErrorMessage
                }
                handleClose={props.SnackbarClose}
            />
            </>
    );
};

const DialogEdit = (props) => {
    const { FormProps } = props;
    return (
        <>
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="client_name"
                                name="client_name"
                                label="Client"
                                required
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={
                                    typeof FormProps.FormFields.client_name !==
                                    "undefined"
                                        ? FormProps.FormFields.client_name
                                        : ""
                                }
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.client_name && true}
                                helperText={FormProps.Errors.client_name}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="client_code"
                                name="client_code"
                                required
                                label="Code"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={
                                    typeof FormProps.FormFields.client_code !==
                                    "undefined"
                                        ? FormProps.FormFields.client_code
                                        : ""
                                }
                                onChange={FormProps.HandleOnChange}
                                error={FormProps.Errors.client_code && true}
                                helperText={FormProps.Errors.client_code}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                     <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField
                                id="client_description"
                                name="client_description"
                                label="Description"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                required
                                value={
                                    typeof FormProps.FormFields
                                        .client_description !== "undefined"
                                        ? FormProps.FormFields
                                              .client_description
                                        : ""
                                }
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                                onChange={FormProps.HandleOnChange}
                                error={
                                    FormProps.Errors.client_description && true
                                }
                                helperText={FormProps.Errors.client_description}
                            />
                        </FormControl>
                    </Grid>
                       <Grid item xs={12} md={6}>
                        <FormControl required error={FormProps.Errors.type && true} variant="standard">
                            <InputLabel shrink htmlFor="type">Type</InputLabel>
                            <Select
                                value={typeof FormProps.FormFields.type !== "undefined" ? FormProps.FormFields.type : "0" }
                                onChange={FormProps.HandleOnChange}
                                inputProps={{
                                    name: "type",
                                    id: "type",
                                }}
                            >
                                <MenuItem value="0">-Select-</MenuItem>
                                {typeof FormProps.ClientTypes != "undefined" && FormProps.ClientTypes !== "" && FormProps.ClientTypes.map((t, i) => (
                                        <MenuItem  value={t.id} key={"stst-" + i} >
                                            {t.name}
                                        </MenuItem>
                                ))}
                            </Select>
                                {FormProps.Errors.type && (
                                <FormHelperText className="error">
                                    {FormProps.Errors.type}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                        <FormControl required error={FormProps.Errors.client_status && true} variant="standard">
                            <InputLabel shrink htmlFor="client_status" >
                                Status
                            </InputLabel>
                            <Select
                                value={
                                    typeof FormProps.FormFields
                                        .client_status !== "undefined"
                                        ? FormProps.FormFields.client_status
                                        : 1
                                }
                                onChange={FormProps.HandleOnChange}
                                inputProps={{
                                    name: "client_status",
                                    id: "client_status",
                                }}
                            >
                                <MenuItem value="0">-Select-</MenuItem>
                                {typeof FormProps.ClientStatus != "undefined" &&
                                    FormProps.ClientStatus !== "" &&
                                    FormProps.ClientStatus.map((t, i) => (
                                        <MenuItem
                                            value={t.client_status_id}
                                            key={"stst-" + i}
                                        >
                                            {t.client_status_name}
                                        </MenuItem>
                                    ))}
                            </Select>
                          {FormProps.Errors.client_status && (
                                <FormHelperText className="error">
                                    {FormProps.Errors.client_status}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

