import React from 'react';
import {ChargeabilityReportComponent} from './ChargeabilityReportComponent'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import * as validations from '../../common/validate';
const ReportFields = {
    'year' : '',
    'month' : '',
    'include_inactive_employees' : '',
    'current_month' : '',
    'current_year' : '',
    'name_format' : '',
    'excluded_staff' : [],
}
const ReportInputs = {
    'years': [],
    'months': [],
    'name_formats': [],
    'staff_list': {},
}


export class ChargeabilityReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            ReportInputs: ReportInputs,
            hasPermission : true,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleRecipientChange = this.handleRecipientChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
        ff[field] = evt.target.value;
        if (field === 'include_inactive_employees') {
            ff['include_inactive_employees'] = evt.target.checked;
            let year = ff['year'];
            let yearStartDate = year+'-01-01';
            let yearEndDate = year+'-12-31'
            this.getStaffByStatus(0, yearStartDate, yearEndDate, 0);
        }
        if (field === 'year') {
            let year = ff['year'];
            let yearStartDate = year+'-01-01';
            let yearEndDate = year+'-12-31'
            this.getStaffByStatus(0, yearStartDate, yearEndDate, 0);
        }
        this.setState({
            ReportFields: ff
        });
    };
    handleValidation() {
        let validationData = [
            { name: 'year', value: this.state.ReportFields.year, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'month', value: this.state.ReportFields.month, type: 'integer', otherOptions: { required: true } },
            { name: 'name_format', value: this.state.ReportFields.name_format, type: 'alphanumeric', otherOptions: { required: false } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    componentDidMount() {
        httpServices.post('api/report/employee-chargeability-report')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                         ReportInputs : {
                            months : inputs.month,
                            years : inputs.year,
                            name_formats : inputs.name_formats
                        },
                        ReportFields : {
                            year : inputs.current_year,
                            month : parseInt(inputs.current_month),
                            name_format : inputs.name_format
                        },

                      
                     },function() {
                            let year = this.state.ReportFields.year;
                            let yearStartDate = year+'-01-01';
                            let yearEndDate = year+'-12-31'
                            this.getStaffByStatus(0, yearStartDate, yearEndDate, 0);
                     });
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
        window.scrollTo(0, 0);
    }
    getStaffByStatus(status_id,start_date,end_date,staff_type){
        httpServices.post('api/report/getStaffList',{ 'status_id' : status_id,'start_date' : start_date,'end_date' : end_date,'staff_type' : staff_type, 'inactive' : this.state.ReportFields['include_inactive_employees'] })
            .then(response => {
                if (response.data) {
                    let staff_list = response.data;
                    let RepInputs = this.state.ReportInputs;
                    RepInputs['staff_list'] = staff_list;
                    this.setState({
                        ReportInputs : RepInputs
                    });
                }
            })
    }
    handleRecipientChange = (evt,value) => {
        let ff = this.state.ReportFields;
        if (value) {
            if (typeof value === 'string') {
                //ff['county'] = 'new_'+value;
            } else if (typeof value === 'object' && value) {
                    ff['excluded_staff'] = value;
                    ff['excluded_resources'] = JSON.stringify(Object.values(ff['excluded_staff']));
            }
        }
        this.setState({
            ReportFields: ff
        });
    };
    formSubmit = () => {
        let valid = this.handleValidation();
        if (valid) {
            document.getElementById("ChargeabilityReportForm").submit();
        }
    }
    render(){
       return(
           (this.state.hasPermission)
            ?
           <ChargeabilityReportComponent
                FormSubmit = {this.formSubmit}
                Errors = { this.state.errors }
                HandleOnChange = { this.HandleOnChange }
                ReportFields = { this.state.ReportFields }
                ReportInputs = {this.state.ReportInputs}
                handleRecipientChange = {this.handleRecipientChange}
            />
           :<ErrorPage/>
       )
    }
}
