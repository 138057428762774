import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../../helpers/history';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';

import MixpanelManager from  '../../services/Analytics';
import { setSelectedMenuIndex } from '../../redux/store/reducers/menuItems';
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const API_URL = process.env.REACT_APP_API_URL;
const TsNav = styled('div')(({ theme }) => ({
    '& .root':{
        background:theme.palette.primary.main,
        borderRadius: 3,
        border: 0,
        height: 38,
        padding: '0 15px',
        color: 'white',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
        '&:hover':{
            background:theme.palette.primary.main,
        },
        '&:focus':{
            background:theme.palette.primary.main,
        }
    },
    '& .primary':{
        color:theme.palette.common.white,
    },
    '& .menuItem':{
        width:180
    }
}));

class TsMainNav extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            anchorEl: null,
            selectedMenuIndex: -1,
            mainNavOptions: props.mainNavOptions,
            isDesktop: false,
            listItemOpen: false,
            activeSubMenuLink:null,
            currentUrl : '',
        };
        this.updatePredicate = this.updatePredicate.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.setSelectedMenuIndex = this.setSelectedMenuIndex.bind(this);
    };
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
        let url = window.location.href;
        this.setState({ 
            currentUrl : url
        });
    };
    componentDidUpdate(prevProps,prevState) {
        let url = window.location.href;
        if (Object.keys(this.state.mainNavOptions).length  !== Object.keys(this.props.mainNavOptions).length) {
            this.setState({
                mainNavOptions: this.props.mainNavOptions,
            }, function() {
                this.setSelectedMenuIndex();
            });
        }
        else if (url !== prevState.currentUrl) {
            this.setSelectedMenuIndex();
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    };
    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 959 });
    }
    handleClickListItem = (event) => {
        this.setState({ anchorEl: event.currentTarget});
    };
    handleClickMobileListItem = (event, key, link, child) => {
        let listItemOpen = this.state.listItemOpen;
        listItemOpen = (listItemOpen && listItemOpen == key) ? false : key; 
        this.setState(state => ({ listItemOpen: listItemOpen }));
        this.MixpanelManager.trackEvent('Menu',{'module':'Menu','feature':link,'action':'clicked_from_mobile'});
        history.push(BASE_PATH+'/'+link);
        if (!child) { // Close the menu
            this.props.drawerAction('left',false);
        }
    };
    handleMainMenuClick = (event, index, link, isMobileClick = false) => {
        event.preventDefault();
        this.setState({ 
            selectedMenuIndex: index, 
            anchorEl: null,
            activeSubMenuLink: link,
        });
        let mixPanelAction = 'clicked';
        if (isMobileClick) {
            mixPanelAction = 'clicked_from_mobile';
        }
        this.MixpanelManager.trackEvent('Menu',{'module':'Menu','feature':link,'action':mixPanelAction});
        history.push(BASE_PATH+'/'+link);
        this.props.drawerAction('left',false); // Close menu
    }
    handleMenuButtonClick = (event,link,id) => {
        event.preventDefault();
        this.MixpanelManager.trackEvent('Menu',{'module':'Menu','feature':link,'action':'clicked'});
        history.push(BASE_PATH+'/'+link);
        this.setState({ activeSubMenuLink: link });
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    // Function for setting the active menu item and submenu item based on the browser url
    setSelectedMenuIndex = () => {
        let url = window.location.href;
        let pathname = window.location.pathname;
        let mainNavOptions = this.props.mainNavOptions;
        let endPath = '';
        if (BASE_PATH && BASE_PATH !== '') {
            let pathArr = pathname.split(BASE_PATH); // Splitting the curent browser pathname by the base path for getting the actual route path
            endPath = pathArr[1]; // Url portion after the base path
        } else {
            endPath = pathname;
        }
        let index = 0;
        if (endPath && endPath !== '') {
            let tempEndPath = endPath.replace(/^\/|\/$/g, '');   
            endPath = (tempEndPath && tempEndPath !== '') ? tempEndPath : 'dashboard';
        } else {
            endPath = 'dashboard';
        }
        let endPathArr = endPath.split("/");
        let tempPath = endPathArr[0];
        if (endPathArr.length > 1) {
            tempPath = tempPath + "/" + endPathArr[1];    
        }
        let isLinkMatched = false;
        Object.keys(mainNavOptions).forEach(function(key,ind) {
            if (mainNavOptions[key]['link'] === tempPath) {
                index = key;
                isLinkMatched = true;
            }
        });
        if (!isLinkMatched) {
            Object.keys(mainNavOptions).forEach(function(key,ind) {
                if (mainNavOptions[key]['link'].includes(endPathArr[0])) {
                    index = key;
                    isLinkMatched = true;
                }
            });
        }
        if (!isLinkMatched) {
            endPath = endPathArr[0];
        } else {
            endPath = tempPath;
        }
        this.setState({ 
            activeSubMenuLink: endPath,
            currentUrl : url,
            selectedMenuIndex: index,
        });
        const { dispatch } = this.props;
        dispatch(setSelectedMenuIndex(index));
    }
    render(){
        const { anchorEl } = this.state;
        const { classes } = this.props;
        const isDesktop = this.state.isDesktop;
        const listItemOpen = this.state.listItemOpen;
        return(
            <>
            {isDesktop ? (
                <TsNav className={'appbarMain__toolbar-navmain'}>
                    {(typeof this.state.mainNavOptions !== 'undefined' && Object.keys(this.state.mainNavOptions).length !== 0) ? (
                    <>
                    <List component="nav">
                        <ListItemButton aria-haspopup="true"  tabindex="-1" classes={{root:'root'}} aria-controls="lock-menu" aria-label="When device is locked" onClick={event=>this.handleClickListItem(event)}>
                            <ListItemText classes={{primary:'primary'}} primary={this.state.mainNavOptions[this.state.selectedMenuIndex] && this.state.mainNavOptions[this.state.selectedMenuIndex].title} />
                            <ArrowDownIcon />
                        </ListItemButton>
                    </List>
                    <Menu id="lock-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                        {(typeof this.state.mainNavOptions !== 'undefined') && Object.keys(this.state.mainNavOptions).map((key) =>  
                        <a className={'navmenu-anchor-tag navmenu-menu-link'} href={BASE_PATH+'/'+this.state.mainNavOptions[key].link}>
                            <MenuItem key={this.state.mainNavOptions[key].id} className={'menuItem'} selected={this.props.path === this.state.mainNavOptions[key].link}  onClick={event => this.handleMainMenuClick(event, key, this.state.mainNavOptions[key].link)} >
                                {this.state.mainNavOptions[key].title}
                            </MenuItem>
                        </a>
                        )}
                    </Menu>
                    </>
                    ):('')}
                    <div className={'subnav'}>
                        {(this.state.mainNavOptions[this.state.selectedMenuIndex] && this.state.mainNavOptions[this.state.selectedMenuIndex]['sub_menu']) && Object.values(this.state.mainNavOptions[this.state.selectedMenuIndex]['sub_menu']).map((option) => 
                                <a className={'navmenu-anchor-tag'} href={BASE_PATH+'/'+option.link}>
                                    <Button key={option.id} color="inherit" className={'subnav-item '+(this.state.activeSubMenuLink === option.link ? 'active': null)} tabindex="-1" onClick={event=>this.handleMenuButtonClick(event, option.link, option.id)}>{option.title}</Button>
                                </a>
                        )}
                    </div>
                </TsNav>
            ) : (
                <TsNav className={'appbarMain__toolbar-navmain'}>
                    {(this.state.mainNavOptions) ? (
                        <List component="nav">
                            {(typeof this.state.mainNavOptions !== 'undefined') && Object.values(this.state.mainNavOptions).map((option, index) => 
                                    <>
                                    <ListItem button key={index} onClick={event => this.handleClickMobileListItem(event, index, option.link,option.sub_menu)} tabindex="-1">
                                        <ListItemText primary={option.title} />
                                        {(option && typeof option.sub_menu !== 'undefined' && option.sub_menu) ? ((listItemOpen && listItemOpen == index) ? <ExpandLess /> : <ExpandMore />):''}
                                    </ListItem>
                                    {(listItemOpen !== false && option && option['sub_menu']) ?  
                                        <> {
                                            Object.values(option['sub_menu']).map((child_option, child_key) =>
                                                { let open=(listItemOpen && listItemOpen == index) ? true:false;
                                                return  <Collapse key={child_option.id} in={open} timeout="auto" unmountOnExit>
                                                        <ListItem button className={'nested'}>
                                                            <ListItemText primary={child_option.title} onClick={event => this.handleMainMenuClick(event, index, child_option.link, true)} />
                                                        </ListItem>
                                                    </Collapse>
                                            })
                                        }
                                        </>
                                        :<></>
                                    }
                                    </>
                            )}
                        </List>
                    ):('')}
                </TsNav>
            )}
            </>
        );
    }
}
TsMainNav.propTypes = {
  classes: PropTypes.object.isRequired,
};
const connectedTsMainNav = connect()(TsMainNav);
export { connectedTsMainNav as TsMainNav};
