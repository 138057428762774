import React from 'react';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import {DialogBoxMedium} from '../../../common/dialog.medium';

export const StaffPersonalDetailsComponent = (props) => {
    const { staffDetails, EditPermission } = props;
    let data = (_R.type(staffDetails) !== 'undefined') ? staffDetails : {};
    let Edit = EditPermission;
    return (        
        <>
            <Grid container spacing={3} style={{position:"relative"}}>
                <Grid item xs={12} sm={6}>
                    <div className={'profile-view__basics_default'}>
                        <div className={'profile-view__basics_default-item item-role'}><label>Salutation</label><span className={'value'}>{data.salutation}</span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Display Name</label>
	    		    <span className={'value'}>
	    			{data.first_name &&
	    			  <span className='first'> {data.first_name} </span>
				}
	    			{data.last_name &&
                                    <span className='last'>{data.last_name}</span>
                                }
			     </span>
                        </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Legal Name</label>
                            <span className={'value'}>
                                {data.legal_first_name &&
                                    <span className='first'>{data.legal_first_name}</span>
                                }
                                {data.legal_middle_name &&
                                    <span className='middle'>{data.legal_middle_name}</span>
                                }
                                {data.legal_last_name &&
                                    <span className='last'>{data.legal_last_name}</span>
                                }
                            </span>
                        </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Gender</label><span className={'value'}>{data.gender}</span> </div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Blood Group</label><span className={'value'}>{data.blood_group}</span> </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={'profile-view__basics_personal'}>
    <div className={'profile-view__basics_default-item item-role'}><label>Date of Birth</label><span className={'value'}>{data.dob} </span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Place of Birth</label><span className={'value'}>{data.place_of_birth}</span> </div>
                
                        {(data.staff_type === 'Employee') ?                       
                        <>
                        <div className={'profile-view__basics_default-item item-role'}><label>Race/ Ethinicity</label><span className={'value'}>{data.race_ethnicity} </span></div>
                        <div className={'profile-view__basics_default-item item-role'}><label>Protected Veteran</label><span className={'value'}>{data.protected_veteran}</span></div>
                         </>: <></> }                        
                        { (parseInt(data.protected_veteran_id) === 1) ?
                        <div className={'profile-view__basics_default-item item-role'}><label>Protected Veteran Category</label>
                            <span className={'value'}>
                            { (typeof data.veteran_category_details !== 'undefined' && data.veteran_category_details.length > 0) && data.veteran_category_details.map((t, i) =>
                                <span className={'value'}>{t.title}</span>
                            )}
                            </span>
                        </div> : <></> }                                         
                    </div>
                </Grid>
                <div className={'absolute-right'}>
                    {_R.equals(Edit, true) &&
                    <div className={'card-action'}>
                        <Fab size="small" color="secondary" onClick={ props.onUpdate } aria-label="edit"><EditIcon fontSize="small" /></Fab>
                    </div>
                    }
                </div>
            </Grid>
            <DialogBoxMedium 
                Open= { props.open }  
                Cancel = { props.onClose }
                Title = { 'Edit Staff Personal Details' }
                OnSubmit = { props.OnSubmit }
                Content = { <DialogContent FormProps = { props }/> }
            />
        </>
    )
};

const DialogContent = (p) => {
    const { FormProps : props } = p;
    const { handleOnChange, FormData } = props;
    let data = (_R.type(FormData) !== 'Undefined') ? FormData : {};
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="salutation">Salutation</InputLabel>
                        <Select
                            name = "staff_salutation"
                            id = "staff_salutation"
                            value = {(data.staff_salutation !== 'undefined') ? data.staff_salutation : null}
                            onChange = { handleOnChange }
                        >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.FormInputs.salutations !== 'undefined' && props.FormInputs.salutations.map((t, i) =>
                            <MenuItem value={t.id} key='Salutation-{t.id}'>{t.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText></FormHelperText>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "first_name"
                            name = "first_name"
                            label = "First Name"
                            required
                            type = "text"
                            value={(data.first_name !== 'undefined') ? data.first_name : null}
                            onChange={ handleOnChange }
                            error = { props.Errors.first_name && true }
                            helperText = { props.Errors.first_name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            type="text"
                            required
                            value={(data.last_name !== 'undefined') ? data.last_name : null}
                            onChange={handleOnChange}
                            error = { props.Errors.last_name && true }
                            helperText = { props.Errors.last_name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "legal_first_name"
                            name = "legal_first_name"
                            label = "Legal First Name"
                            type = "text"
                            value={(data.legal_first_name !== 'undefined') ? data.legal_first_name : null}
                            onChange={ handleOnChange }
                            error = { props.Errors.legal_first_name && true }
                            helperText = { props.Errors.legal_first_name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "legal_middle_name"
                            name = "legal_middle_name"
                            label = "Legal Middle Name"
                            type = "text"
                            value={(data.legal_middle_name !== 'undefined') ? data.legal_middle_name : null}
                            onChange={ handleOnChange }
                            error = { props.Errors.legal_middle_name && true }
                            helperText = { props.Errors.legal_middle_name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "legal_last_name"
                            name = "legal_last_name"
                            label = "Legal Last Name"
                            type = "text"
                            value={(data.legal_last_name !== 'undefined') ? data.legal_last_name : null}
                            onChange={ handleOnChange }
                            error = { props.Errors.legal_last_name && true }
                            helperText = { props.Errors.legal_last_name}
                        />
                    </ FormControl>
                </Grid>             
       
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "staff_dob"
                            name = "staff_dob"
                            label = "Date of Birth"
                            type = "date"
                            required
                            value = {(data.staff_dob !== 'undefined') ? data.staff_dob : null}
                            onChange = {handleOnChange}
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            error = { props.Errors.dob && true }
                            helperText = { props.Errors.dob }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="gender" required error={props.Errors.gender && true}>Gender</InputLabel>
                        <Select
                            value = {(data.staff_gender !== 'undefined' && data.staff_gender !== "" ) ? parseInt(data.staff_gender) : null}
                            onChange = {handleOnChange}
                            inputProps = {{
                                name: 'staff_gender',
                                id: 'staff_gender',
                            }}>
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.FormInputs.staff_gender_opts != 'undefined' && props.FormInputs.staff_gender_opts.map((g, i) =>
                                <MenuItem value={g.id}>{g.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.gender}</FormHelperText>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="place_of_birth"
                            name="place_of_birth"
                            label="Place of Birth"
                            type="text"
                            value={(data.place_of_birth !== 'undefined') ? data.place_of_birth : null}
                            onChange={handleOnChange}
                            placeholder={"City, State, Country"}
                            error = { props.Errors.place_of_birth && true }
                            helperText = { props.Errors.place_of_birth }
                        />
                    </ FormControl>
                </Grid>
                {(data.staff_type === 'Employee') ? 
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="race_ethnicity" required error={props.Errors.race_ethnicity && true}>Race/ Ethinicity</InputLabel>
                        <Select
                            value = {(data.staff_race_ethnicity !== 'undefined') ? data.staff_race_ethnicity : null}
                            onChange={handleOnChange}
                            inputProps={{
                                name: 'staff_race_ethnicity',
                                id: 'staff_race_ethnicity',
                            }}>
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.FormInputs.staff_race_ethinicity_opts != 'undefined' && props.FormInputs.staff_race_ethinicity_opts.map((g, i) =>
                                <MenuItem value={g.id}>{g.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.race_ethnicity}</FormHelperText>
                    
                    </ FormControl>
                </Grid> : <></> } 
                {(data.staff_type === 'Employee') ? 
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="protected_veteran" required error={props.Errors.protected_veteran && true}>Protected Veteran</InputLabel>
                        <Select
                            value = {(data.protected_veteran_id !== 'undefined') ? data.protected_veteran_id : null}
                            onChange = { handleOnChange }
                            inputProps = {{
                                name: 'protected_veteran_id',
                                id: 'protected_veteran_id',
                            }}>
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.FormInputs.staff_veteran_opts != 'undefined' && props.FormInputs.staff_veteran_opts.map((g, i) =>
                                <MenuItem value={g.id}>{g.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText className='error'>{props.Errors.protected_veteran}</FormHelperText>
                    </ FormControl>
                </Grid>  
                 : <></> }               
                {(parseInt(data.protected_veteran_id) === 1) ? 
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="veteran_id">Protected Veteran Category</InputLabel>
                        <Select
                            value = { (data.veteran_category_ids !== 'undefined' && data.veteran_category_ids.length > 0 ) ? data.veteran_category_ids : [] }
                            onChange = {handleOnChange}
                            multiple
                            inputProps={{
                                name: 'veteran_category_ids',
                                id: 'veteran_category_ids',
                            }}>
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.FormInputs.staff_veteran_category_opts != 'undefined' && props.FormInputs.staff_veteran_category_opts.map((g, i) =>
                                <MenuItem value={g.id}>{g.title}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid> : <></> }
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="blood_group">Blood Group</InputLabel>
                        <Select
                            name = "blood_group"
                            id = "blood_group"
                            value = {(data.blood_group !== 'undefined') ? data.blood_group : null}
                            onChange = { handleOnChange }
                        >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            { typeof props.PersonalInputs.blood_groups !== 'undefined' && props.PersonalInputs.blood_groups.map((t, i) =>
                            <MenuItem value={t.value} >{t.title}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText></FormHelperText>
                    </ FormControl>
                </Grid>
                </Grid>                        
                </form> 
                )
          };
