import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {httpServices}  from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import { TimeEntryComponent } from './TimeEntry.Component';
import { history } from '../../helpers/history';
import MixpanelManager from  '../../services/Analytics';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
let successMsg = '';
let dates_in_page = [];

class TimeEntryContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            hasPermission : true,
            selectedDate: null,
            timesheetList: [],
            userID: false,
            instanceID: false,
            dialogOpen : false,
            popupType : '',
            dialogButton: null,
            minimumInc: null,
            snackbarOpen : false,
            project_summary: [],
            daily_summary: [],
            hour_summary: [],
            maxDate:null,
            clickedTimeSliceId : 0,
            timeSliceEditDialogOpen : false,
            random_number : 0,
            displayDate : null,
            minDate : new Date(),
            timein_out : 0,
            time_inout_details: [],
            time_inout_date : null,
            time_inout_total : 0,
            review_comments : [],
            skip_non_confirmity : false,
            timesheetIsSubmitted : false,
            payPeriodTxt : '',
            deletedTsIdFromParent : 0,
            forceRerender : false,
            copyTimeEntryFlag : false,
            copied_date : null,
            timeoffReqPermission : false,
            offsiteReqPermission : false,
            requestOptions : [{ btnName : "New Request", btnClick : null }],
            showLoader : true,
            timesheet_id : 0,
            selectedLocation : '',
            addLunchBreak : false,
            inOutDate : null,
            disableSaveButton : false,
            timeentryPeriods: [],
        }
        this.editSavedTimeEntry = this.editSavedTimeEntry.bind(this);
        this.timeSliceEditDialogClose = this.timeSliceEditDialogClose.bind(this);
        this.showEditSuccess = this.showEditSuccess.bind(this);
        this.addTimeInOutClick = this.addTimeInOutClick.bind(this);
        this.timeInOutSaveSuccess = this.timeInOutSaveSuccess.bind(this);
        this.listReviewNotes = this.listReviewNotes.bind(this);
        this.handleTimeEntryDateChange = this.handleTimeEntryDateChange.bind(this);
        this.getTimeEntrySummary = this.getTimeEntrySummary.bind(this);
        this.getTimeInOutDetails = this.getTimeInOutDetails.bind(this);
        this.getSavedTimeSlices = this.getSavedTimeSlices.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.updateParentContainer = this.updateParentContainer.bind(this);
        this.displayRequestSuccess = this.displayRequestSuccess.bind(this);
        this.deleteSavedTimeEntry = this.deleteSavedTimeEntry.bind(this);
        this.getTimeSlicesByDate = this.getTimeSlicesByDate.bind(this);
        this.updateTimeEntryOnEdit = this.updateTimeEntryOnEdit.bind(this);
        this.copyTimeEntries = this.copyTimeEntries.bind(this);
        this.updateCopyTimeEntryFlag = this.updateCopyTimeEntryFlag.bind(this);
        this.requestClick = this.requestClick.bind(this);
        this.closeLoader = this.closeLoader.bind(this);
        this.findSubMenuRoute = this.findSubMenuRoute.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.getLockedDetails = this.getLockedDetails.bind(this);
    }
    componentDidMount = () => {
        this.getIndex();
    };
    componentDidUpdate(prevProps) {
        if ((Object.keys(prevProps.menuItems).length !== Object.keys(this.props.menuItems).length) || (prevProps.selectedMenuIndex !== this.props.selectedMenuIndex)) {
            if (!this.state.hasPermission) {
                this.findSubMenuRoute();
            }
        }
    }
    /* 
     * Function for redirecting to the next sub menu item if the default 
     * route of the timesheet module has no permission
     */
    findSubMenuRoute = () => {
        if (this.props.selectedMenuIndex !== -1 && this.props.menuItems) {
            let subMenu = this.props.menuItems[this.props.selectedMenuIndex]['sub_menu'];
            let matchingSubMeuFound = false;
            if (Object.keys(subMenu).length) {
                Object.keys(subMenu).forEach(function(childKey,ind) {
                    if (subMenu[childKey]['link'] && !matchingSubMeuFound) {
                        matchingSubMeuFound = true;
                        history.push(BASE_PATH+'/'+subMenu[childKey]['link']);
                    }
                });
            } 
        } 
    }
    /* Handle Time entry date change*/
    handleTimeEntryDateChange = (date) => {
        let dateobj = this.getStandardDates(date);
        this.setState({
            selectedDate : dateobj.modified_date,
            displayDate : dateobj.display_date,
        },function(){
            if (moment(date).isValid()){
                if (!dates_in_page.includes(dateobj.modified_date)) {
                    this.getSavedTimeSlices();
                }
                this.getTimeInOutDetails();
                this.getTimeEntrySummary();
            }
        })
    };
    getTimeSlicesByDate = (date) => {
        let dateObj = this.getStandardDates(date);
        this.setState({
            selectedDate:dateObj.modified_date,
            displayDate:dateObj.display_date,
        }, function() {
            this.getTimeInOutDetails();
            this.getTimeEntrySummary();
        });
    }
    getStandardDates = (date) => {
        let resObj = {
            modified_date : '',
            display_date : '',
        }
        let dateobj = moment(date);
        resObj['modified_date'] = dateobj.format('YYYY-MM-DD');
        resObj['display_date'] = dateobj.format('MM/DD/YYYY');
        return resObj;
    }
    getIndex = () => {
        return httpServices.get('api/timesheet/index')
            .then(response => {
                if (response.data.permission) {
                    let retObj = response.data;
                    let todayDate = retObj.date;
                    let requestTypes = retObj.request_types;
                    var res = todayDate.split("-");
                    let display_date = retObj.max_date;
                    let min_date = '';
                    if (retObj.user_date_joined && retObj.user_date_joined !== "") {
                        min_date = retObj.user_date_joined;
                        let min_date_arr = min_date.split("-");
                        min_date = min_date_arr[1]+'/'+min_date_arr[2]+'/'+min_date_arr[0];
                        min_date = new Date(min_date);
                    } else {
                        min_date = new Date();
                    }
                    let requestOptions = this.state.requestOptions;
                    if (requestTypes) {
                        const keys = Object.keys(requestTypes);
                        keys.forEach(function(key) {
                            let reqBtn = { btnName : requestTypes[key].name, btnClick : ()=>this.requestClick(requestTypes[key].code) };
                            requestOptions.push(reqBtn);
                        }.bind(this));
                    }
                    this.setState({
                        instanceID: retObj.instance_id,
                        userID: retObj.user_id,
                        selectedDate: todayDate,
                        minimumInc: retObj.minimum_increment,
                        timein_out : retObj.time_inout,
                        hasPermission: retObj.edit_permission,
                        maxDate: display_date,
                        displayDate : display_date,
                        minDate : min_date,
                        timeoffReqPermission : retObj.timeoff_req_permission,
                        offsiteReqPermission : retObj.offsite_req_permission,
                        requestOptions : requestOptions,
                        timeentryPeriods: response.data.timeentry_period
                    },function(){
                        if(this.props.location.state && this.props.location.state.date )
                        {
                            this.getSavedTimeSlices(this.props.location.state.date);
                            this.handleTimeEntryDateChange(this.props.location.state.date);
                        } else {
                            this.getSavedTimeSlices(todayDate);
                            this.getTimeInOutDetails();
                            this.getTimeEntrySummary(); 
                        }
                    });
                    if (!retObj.edit_permission) {
                        this.findSubMenuRoute();
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    };
    getSavedTimeSlices = (date=false) => {
        let data = {
            date : date ? date : this.state.selectedDate,
            //mode : 'semi-monthly' : TBD
        };
        return httpServices.post('api/timesheet/timeslicesearch', {data : data})
            .then(response => {
                if (response.data) {
                    if (response.data.timeslices && response.data.timeslices.length) {
                        dates_in_page = this.arrayColumn(response.data.timeslices,'date');
                    }
                    this.setState({
                        timesheetList : response.data,
                        timesheetIsSubmitted : response.data.is_submitted,
                        disableSaveButton : response.data.disable_save_button, 
                        payPeriodTxt: response.data.payperiod_sdate+' - '+response.data.payperiod_edate,
                    })
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    };
    /*TimeEntry delete reason*/
    onTimeEntryEditConfirm = () => {
        this.setState({
            dialogOpen : true,
            dialogButton : 'OK',
            popupType : 'time-entry-edit-reason',
        });
    };
    getTimeEntrySummary = () => {
        let postData = {
            date: this.state.selectedDate
        };
        return httpServices.post('api/timesheet/get-project-summary', postData)
            .then(response => {
                if (response.data) {
                    let data = response.data;
                    this.setState({
                        project_summary: data.project_summary,
                        daily_summary: data.daily_summary,
                        hours_summary: data.hours_summary,
                        timesheet_id: data.timesheet_id,
                        formData : response.data,
                     });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };
    getTimeInOutDetails = () => {
        let postData = {
            date :this.state.selectedDate
        };
        return httpServices.post('api/timesheet/get-time-in-out-details', postData)
            .then(response => {
                if (response.data) {
                    let total_timeinout_hours = 0;//this.state.time_inout_total;
                    if (response.data.total_time_in_out_hours) {
                        total_timeinout_hours = response.data.total_time_in_out_hours;
                    }
                    this.setState({
                        time_inout_details :response.data.time_inout_details,
                        time_inout_total : total_timeinout_hours,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };
    editSavedTimeEntry = (id) => {
        let timeslice_id = parseInt(id);
        let rand = Math.random();
        if (timeslice_id > 0) {
            this.setState({
                clickedTimeSliceId : timeslice_id,
                timeSliceEditDialogOpen : true,
                random_number : rand,
            });
        }
        this.MixpanelManager.trackEvent('Timesheet', {module:'Timesheet',feature:'timesheet/time-entry',action:'edit'});
    };
    timeSliceEditDialogClose = () => {
        this.setState({timeSliceEditDialogOpen:false});
    };
    showEditSuccess = () => {
        successMsg = "Time entry updated";
        this.setState({
            snackbarOpen : true,
        });
    };
    addTimeInOutClick = (date=false) => {
        let time_inout_date = date ? date : this.state.selectedDate;
        let rand = Math.random();
        this.setState({
            dialogOpen : true,
            //dialogButton : 'Ok',
            popupType : 'timein_out',
            random_number : rand,
            time_inout_date : time_inout_date,
        });
    };
    timeInOutSaveSuccess = (location = '', add_lunch_break = false, isEditTimeinout) => {
        if (this.state.popupType === 'timein_out') {
            if (isEditTimeinout) {
                successMsg = "Updated time in/out records";
            } else {
                successMsg = "Saved time in/out records";
            }
            this.setState({
                snackbarOpen : true,
                selectedLocation : location,
                addLunchBreak : add_lunch_break,
                inOutDate : this.state.selectedDate,
                popupType : ''
            },function() {
                this.getTimeInOutDetails(add_lunch_break);
            });
        }
    };
    listReviewNotes = (id) => {
        if (id) {
            httpServices.post('api/timesheet/listreviewcomments', { timeslice_id : id })
                .then(response => {
                    if(response.data){
                        if (response.data) {
                            this.setState({
                                popupType : 'show-review-list',
                                dialogOpen : true,
                                review_comments : response.data.comments,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };
    //Handles request add success message display
    displayRequestSuccess = (successFlag, msg) => {      
        if (successFlag) {          
              successMsg = msg;            
              this.setState({
                snackbarOpen : true,
            });
        }
    }
    updateParentContainer = (action) => {   
        if (action === 'delete'){
            this.setState({
                deletedTsIdFromParent : 0, 
            });    
        }
        else {
            this.getSavedTimeSlices(); 
            this.getTimeEntrySummary();
            let reRender = this.state.forceRerender;
            this.setState({
                dialogOpen : false,
                forceRerender : !reRender,
            });
        }
    }
    // Handles the delete cick from the saved timesheet section
    deleteSavedTimeEntry = (event, value) => {
        let timesliceID = event.currentTarget.dataset.timeslice_id;
        if (timesliceID !=='undefined' && timesliceID>0){
            this.setState({
                deletedTsIdFromParent : timesliceID, 
            });
            this.MixpanelManager.trackEvent('Timesheet', {module:'Timesheet',feature:'timesheet/time-entry',action:'delete'});
        }
    }
    arrayColumn = (array, columnName) => {
        return array.map(function(value,index) {
            return value[columnName];
        })
    }
    /* 
     * updateTimeEntryOnEdit - Function for re-rendering the time entry row data section(top section) 
     * when an edit occurs from the saved timesheet section
     */
    updateTimeEntryOnEdit = () => {
        this.getTimeEntrySummary();
        let reRender = this.state.forceRerender;
        this.setState({forceRerender : !reRender});
    }
    copyTimeEntries = (date) => {
        let dateobj = this.getStandardDates(date);
        this.setState({
            copied_date : dateobj.modified_date,
            copyTimeEntryFlag : true,
        });
    }
    updateCopyTimeEntryFlag = () => {
        this.setState({copyTimeEntryFlag : false});
    }
    requestClick = (request) => {
        let popupType = '';
        let mixPanelAction = '';
        if (request === 'timeoff') {
            popupType = 'request_timeoff';
            mixPanelAction = 'create-timeoff-request-clicked';
        } else if (request === 'location') {
            popupType = 'request_offsite';
            mixPanelAction = 'create-offsite-request-clicked';
        }
        this.setState({
            popupType : popupType,
            dialogOpen : true,
        });
        this.MixpanelManager.trackEvent('Timesheet', {module : 'Timesheet', feature : 'timesheet/time-entry', action : mixPanelAction});
    }
    closeLoader = () => {
        this.setState({
            showLoader: false,
        });
    }
    getLockedDetails = (lockedBy, lockedOn) => {
        this.setState({
        popupType : 'show-locked-details',
        dialogOpen : true,
        popupMsg : 'Timesheet is locked by ' +lockedBy+  '  on ' + lockedOn +  '.  Please contact your Project Manager or PMO', 
        });
    }
    render(){
        return(
            (this.state.hasPermission)
            ? <TimeEntryComponent 
                    InstanceID = {this.state.instanceID}
                    handleTimeEntryDateChange = {this.handleTimeEntryDateChange}
                    getTimeEntrySummary = {this.getTimeEntrySummary}
                    getTimeInOutDetails = {this.getTimeInOutDetails}
                    selectedDate = {this.state.selectedDate}
                    timesheetList = {this.state.timesheetList}
                    GetSavedTimeSlices = {this.getSavedTimeSlices}
                    PopupType = {this.state.popupType}
                    DialogOpen = {this.state.dialogOpen}
                    DialogButton = {this.state.dialogButton}
                    OnClose = {this.onClose}
                    FormInputs = {this.state.FormInputs}
                    OnTimeEntryDeleteConfirm = {this.onTimeEntryDeleteConfirm}
                    SnackbarClose = {this.snackbarClose}
                    Message = {successMsg}
                    project_summary = {this.state.project_summary}
                    daily_summary = {this.state.daily_summary}
                    hours_summary = {this.state.hours_summary}
                    EditSavedTimeEntry = {this.editSavedTimeEntry}
                    ClickedTimeSliceId = {this.state.clickedTimeSliceId}
                    TimeSliceEditDialogOpen = {this.state.timeSliceEditDialogOpen}
                    TimeSliceEditDialogClose = {this.timeSliceEditDialogClose}
                    ShowEditSuccess = {this.showEditSuccess}
                    randomNumber = {this.state.random_number}
                    DisplayDate = {this.state.displayDate}
                    minDate = {this.state.minDate}
                    TimeInOut = {this.state.timein_out}
                    addTimeInOutClick = {this.addTimeInOutClick}
                    time_inout_details = {this.state.time_inout_details}
                    TimeInoutDate = {this.state.time_inout_date}
                    TimeInOutSaveSuccess = {this.timeInOutSaveSuccess}
                    TimeInOutTotal = {this.state.time_inout_total}
                    ListReviewNotes = {this.listReviewNotes}
                    ReviewNotes = {this.state.review_comments}
                    maxDate = {this.state.maxDate}
                    MinimumInc = {this.state.minimumInc}
                    snackbarOpen = {this.state.snackbarOpen}
                    UpdateParentContainer = {this.updateParentContainer}
                    DisplayRequestSuccess = {this.displayRequestSuccess}
                    TimesheetIsSubmitted = {this.state.timesheetIsSubmitted}
                    PayPeriodText = {this.state.payPeriodTxt}
                    DeleteSavedTimeEntry = {this.deleteSavedTimeEntry}
                    DeletedTsIdFromParent = {this.state.deletedTsIdFromParent}
                    GetTimeSlicesByDate = {this.getTimeSlicesByDate}
                    UpdateTimeEntryOnEdit = {this.updateTimeEntryOnEdit}
                    ForceRerender = {this.state.forceRerender}
                    CopyTimeEntries = {this.copyTimeEntries}
                    CopyTimeEntryFlag = {this.state.copyTimeEntryFlag}
                    CopiedDate = {this.state.copied_date}
                    UpdateCopyTimeEntryFlag = {this.updateCopyTimeEntryFlag}
                    RequestClick = {this.requestClick}
                    TimeoffReqPermission = {false}
                    OffsiteReqPermission = {this.state.offsiteReqPermission}
                    RequestOptions = {this.state.requestOptions}
                    CloseLoader = {this.closeLoader}
                    TimesheetId = {this.state.timesheet_id}
                    SelectedLocation = {this.state.selectedLocation}
                    AddLunchBreak = {this.state.addLunchBreak}
                    InOutDate = {this.state.inOutDate}
                    DisableSaveButton = {this.state.disableSaveButton}
                    TimeentryPeriods = {this.state.timeentryPeriods}
                    GetLockedDetails = {this.getLockedDetails}
                    PopupMsg = {this.state.popupMsg}
                />
            :<ErrorPage Message ={this.state.permision_err_msg}/>
        )
    }
}
const mapStateToProps = (state) => {
    const menuItems = state.menuItems.menu ? state.menuItems.menu : {};
    const selectedMenuIndex = state.menuItems.selectedMenuIndex ? state.menuItems.selectedMenuIndex : -1;
    return {
        menuItems: menuItems,
        selectedMenuIndex: selectedMenuIndex,
    }
}
const connectedTimeEntryContainer = connect(mapStateToProps)(TimeEntryContainer);
export {connectedTimeEntryContainer as TimeEntryContainer};

