import React from 'react';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {DialogBoxMedium} from '../../common/dialog.medium';
import FormHelperText from '@mui/material/FormHelperText';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import { formatName } from '../../common/common';
import { CustomizedSnackbars } from '../../common/snackbars';
import Tooltip from '@mui/material/Tooltip';

const project_columns = [
    { title: 'User', field: 'user_fullname',  defaultSort: 'asc'
    },
    { title: 'Project', field: 'project_path', removable: false, },
];
const organization_columns = [
    { title: 'User', field: 'user_fullname',  defaultSort: 'asc'
    },
    { title: 'Organization', field: 'client_name', removable: false, },
];

const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});
export const ReportDetailsComponent = withStyles(styles)(props => {
    const { classes, toggleDrawer } = props;
    return (
        <React.Fragment>
            <div className={'section-main section-main-header-fixed'}>
                {!_R.isEmpty(props.ReportDetails) &&
                    <div className={'header-fixed'} >
                        <div className={'section-header'}>
                            <div className={'col-left'}>
                                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                                <Typography variant="h6" component="h6" className={'section-title'}>
                                    {props.ReportDetails.name}
                                </Typography>
                            </div>                            
                        </div>
                    </div>
                }
                <div className={'section-body'}>
                {_R.type(props.ReportDetails.user_project_details) !=='Undefined' &&
                <CustomMaterialTable
                    data = {props.ReportDetails.user_project_details}
                    columns={project_columns}
                    title={'Project Level'}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: (event, newData) => { props.AddClick('project') },
                       
                        },
                        { icon: DeleteOutline, tooltip: 'Delete', onClick: (event, rowData) => { props.HandleDeleteClick(rowData.id)}},
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize:10,
                        pageSizeOptions: [10,20,30],
                        search:true,
                    }}
                
                /> 
                }
                {_R.type(props.ReportDetails.user_organization_details) !=='Undefined' &&
                <CustomMaterialTable
                    data = {props.ReportDetails.user_organization_details}
                    columns={organization_columns}
                    title={'Organization Level'}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: (event, newData) => { props.AddClick('organization') },
                        },
                        { icon: DeleteOutline, tooltip: 'Delete', onClick: (event, rowData) => { props.HandleDeleteClick(rowData.id)}},
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize:10,
                        pageSizeOptions: [10,20,30],
                        search:true,
                    }}
                />
                }
                </div>
            </div>
            {(props.PopupType === 'add_project' && props.Open) ?
                <DialogBoxMedium
                    Open = {props.Open}
                    Cancel = {props.OnClose}
                    Title = {"Add Project Permission"}
                    OnSubmit = {props.OnSubmit}
                    Loading = {props.ButtonLoader}
                    Content = {
                        <AddProject 
                            FormValues={props.FormValues}
                            FormInputs={props.FormInputs}
                            HandleOnChange={props.HandleOnChange}
                            HandleTreeChange={props.HandleTreeChange}
                            Errors={props.Errors}
                        />
                    }
                />
                :<></>
            }
            {(props.PopupType === 'add_organization' && props.Open) ?
                <DialogBoxMedium
                    Open = {props.Open}
                    Cancel = {props.OnClose}
                    Title = {"Add Organization Permission"}
                    OnSubmit = {props.OnSubmit}
                    Loading = {props.ButtonLoader}
                    Content = {
                        <AddOrganization
                            FormValues={props.FormValues}
                            FormInputs={props.FormInputs}
                            HandleOnChange={props.HandleOnChange}
                            Errors={props.Errors}
                        />
                    }
                />
                :<></>
            }
            {(props.PopupType === 'delete' &&  props.Open) &&
                    <DialogBoxMedium
                        Open= { props.Open }
                        Cancel = { props.OnClose }
                        Title = { 'Confirm Delete' }
                        OnSubmit = { props.DeleteClick} ButtonName={'Yes'}
                        Content = {"Do you really want to delete this user?"}
                    />
            }
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open = {props.SnackbarOpen}
                    variant= 'success'
                    message = {props.SnackbarMsg}
                    handleClose = {props.SnackbarClose}
                />
            }
        </React.Fragment>
    );
});

const AddProject = withStyles(styles)(p => {
	let projects = (typeof p.FormInputs.project_list !== 'undefined') ? p.FormInputs.project_list : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof p.FormValues.project !== 'undefined') ? [p.FormValues.project] : []));
    }
    return (
        <div>
	    <Grid container spacing={2}>
            <Grid item xs={6}>
            <FormControl className={p.classes.drawerFormControl} error={_R.type( p.Errors.user) !== 'Undefined' ? true:false}>
            <InputLabel htmlFor="status">User</InputLabel>
                <Select
                    inputProps={{
                        name: 'user',
                        id: 'user',
                    }}
                    value = {p.FormValues.user}
                    onChange = {p.HandleOnChange}
                >                
                {_R.type(p.FormInputs.users_list) !== 'Undefined' && p.FormInputs.users_list.map((s, i) =>
                    <MenuItem value={s.user_id} key={'st-'+i}>{formatName(s.first_name,null,s.last_name)}</MenuItem>
                )}
                </Select>
                <FormHelperText>{_R.type( p.Errors.user) !== 'Undefined' && p.Errors.user}</FormHelperText>
	        </FormControl>
	        </Grid>
	        <Grid item xs={6}>
	        <FormControl className={p.classes.drawerFormControl} error={_R.type( p.Errors.client) !== 'Undefined' ? true:false}>
	        <InputLabel htmlFor="client">Client</InputLabel>
	            <Select
                    inputProps={{
                        name: 'client',
                        id: 'client',
                    }}
                    value = {p.FormValues.client}
                    onChange = {p.HandleOnChange}
                >
	            {_R.type(p.FormInputs.client_list !== 'Undefined') && p.FormInputs.client_list.map((s, i) =>
                    <MenuItem value={s.client_id} key={'st-'+i}>{s.client_name}</MenuItem>
                )}
                </Select>
                <FormHelperText>{_R.type( p.Errors.user) !== 'Undefined' && p.Errors.client}</FormHelperText>
            </FormControl>
	        </Grid>
            <Grid item xs={9}>
            <FormControl className={p.classes.drawerFormControl} error={_R.type( p.Errors.project) !== 'Undefined' ? true:false}>
                <TreeSelectComponent
                    dropdown_mode={'true'}
                    data={typeof projects ? projects : []}
                    placeholderText={"Project"}
                    type='project'
                    HandleTreeChange={p.HandleTreeChange}
                />
                <FormHelperText>{_R.type( p.Errors.project !== 'Undefined') && p.Errors.project}</FormHelperText>                    
	        </FormControl>
	        </Grid>
	    </Grid>
        <div style={{width: "100%", height:"220px" ,display:"block", overflow: "hidden"}} ></div>
        </div>
    );
});
const AddOrganization = withStyles(styles)(p => {
    return (
        <div>
        <Grid container spacing={2}>
            <Grid item xs={6}>
            <FormControl className={p.classes.drawerFormControl} error={_R.type( p.Errors.user) !== 'Undefined' ? true:false}>
            <InputLabel htmlFor="status">User</InputLabel>
                <Select
                    inputProps={{
                        name: 'user',
                        id: 'user',
                    }}
                    value = {p.FormValues.user}
                    onChange = {p.HandleOnChange}
                >
                {_R.type(p.FormInputs.users_list) !== 'Undefined' && p.FormInputs.users_list.map((s, i) =>
                    <MenuItem value={s.user_id} key={'st-'+i}>{formatName(s.first_name,null,s.last_name)}</MenuItem>
                )}
                </Select>
                <FormHelperText>{_R.type( p.Errors.user) !== 'Undefined' && p.Errors.user}</FormHelperText>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl className={p.classes.drawerFormControl} error={_R.type( p.Errors.client) !== 'Undefined' ? true:false}>
            <InputLabel htmlFor="client">Organization</InputLabel>
                <Select
                    inputProps={{
                        name: 'client',
                        id: 'client',
                    }}
                    value = {p.FormValues.client}
                    onChange = {p.HandleOnChange}
                >
                {_R.type(p.FormInputs.client_list !== 'Undefined') && p.FormInputs.client_list.map((s, i) =>
                    <MenuItem value={s.client_id} key={'st-'+i}>{s.client_name}</MenuItem>
                )}
                </Select>
                <FormHelperText>{_R.type( p.Errors.client !== 'Undefined') && p.Errors.client}</FormHelperText>
            </FormControl>
            </Grid>
        </Grid>
        </div>
    );
});
