import React  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { formatName } from '../../common/common';

const API_URL = process.env.REACT_APP_API_URL;
export const MyRequestOvertime  = props => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return (
        <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table"  className={'list-table_basic steps-table'}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        {props.Tab !== 'my' &&
                           <TableCell>Staff Name</TableCell>
                        }
                        <TableCell>Description</TableCell>
                        <TableCell>Week</TableCell>
                        <TableCell>Hours</TableCell>
                        <TableCell>Status</TableCell>
                        {props.Tab !== 'my' &&
                           <TableCell>Submitted by</TableCell>
                        }
                        <TableCell>Submitted on</TableCell>
                        <TableCell>Reviewed by</TableCell>
                        <TableCell>Reviewed on</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                {(props.Requests.length === 0) &&
                        <TableBody>
                        <TableRow >
                            <TableCell colSpan="5">No Overtime Requests found.</TableCell>
                        </TableRow>
                        </TableBody>
                }
                {Object.keys(props.Requests).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => 
                <>
                <TableBody>
                    <TableRow hover>
                        <TableCell><a className={'item-link'} onClick={()=>props.HandleViewClick(props.Requests[row].id)}>{props.Requests[row].id}</a></TableCell>
                        {props.Tab !== 'my' &&
                            <TableCell> {formatName(props.Requests[row].user_first_name,null,props.Requests[row].user_last_name)}</TableCell>
                        }
                        {(props.Requests[row].description !== 'Undefined' && props.Requests[row].description.length > 20) ? 
                            <TableCell>{props.Requests[row].description.substring(0, 20)}...</TableCell>
                          :  <TableCell>{props.Requests[row].description.substring(0, 20)}</TableCell>
                        }
                        <TableCell>{props.Requests[row].week}</TableCell>
                        <TableCell>{props.Requests[row].hours.toFixed(2)} hours</TableCell>
                        <TableCell>{props.Requests[row].request_status_name}</TableCell>
                        {props.Tab !== 'my' &&
                           <TableCell>{formatName(props.Requests[row].cby_first_name,null,props.Requests[row].cby_last_name) }</TableCell>
                        }
                        <TableCell>{props.Requests[row].created_on}</TableCell>
                        <TableCell>{formatName(props.Requests[row].rby_first_name,null,props.Requests[row].rby_last_name)}</TableCell>
                        <TableCell>{parseInt(props.Requests[row].reviewed_on) === 0 ? "Never" : props.Requests[row].reviewed_on}</TableCell>
                        <TableCell align="right">
                             <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(props.Requests[row].id)}/>
                                    </IconButton>
                             </Tooltip>
                             {(props.Logged_user === props.Requests[row].created_by && props.Requests[row].status !== "4") &&
                                 <Tooltip title="Edit" aria-label="edit">
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                                        <EditIcon fontSize="small" onClick={()=>props.HandleEditClick(props.Requests[row].id)}/>
                                    </IconButton>
                             </Tooltip>
                             }
                      {props.Requests[row].attachment_id != null &&
                          <>  <IconButton   className={'action-btn'} edge="end" aria-label="Comments">
                            <a href = {API_URL+'api/overtime/download?id='+ props.Requests[row].attachment_id}>  <AttachmentIcon /> </a>
                               </IconButton>
                          </>
                      }
                    </TableCell>
                    </TableRow>
                </TableBody>
                </>
                )}
        </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={props.Requests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </>
    )
};

