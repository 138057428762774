import React from 'react';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';  // used 0.04kb
import Switch from '@mui/material/Switch';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import {DialogBoxMedium} from '../common/dialog.medium';
import { IconButton } from '@mui/material';
import { formatName } from '../common/common';
import { CustomizedSnackbars } from '../common/snackbars';
import { TreeSelectComponent } from '../common/TreeSelectComponent';
import * as _ts from '../common/treeSelect';


const onSubmit =  (event) => {
  event.preventDefault();
}
export const RoleDetailsComponent = (props) => {
    //Tabs
    const [tabValue, setTabValue] =  React.useState(0);    
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    let actions = (_R.type(props.ActionList) !== 'Undefined' && _R.type(props.ActionList) !== 'Undefined') ? props.ActionList : [];
    if (actions.length) {
        _ts.findActionKey(actions, ((_R.type(props.Actions) !== 'Undefined') ? props.Actions : []));
    }

    return (
        <>
            <Paper>
            {_R.type(props.SelectedRoleDetails) !== 'undefined' && 
            <>
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                               {(_R.type(props.SelectedRoleDetails.name) !== 'undefined') ? props.SelectedRoleDetails.name : ''} 
                            </Typography>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className={"profile-view__basics_default"}>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Description</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.description}
                                        </span>{" "}
                                    </div>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Role Type</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.role_type}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Role Slug</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.role_slug}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Is Default</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.is_default == 1 ? "Yes" : "No"}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={"profile-view__basics_default"}>                                        
                                        <div
                                            className={
                                                "profile-view__basics_default-item item-role"
                                            }
                                        >
                                            <label>Created By</label>
                                            <span className={"value"}>
                                                {formatName(props.SelectedRoleDetails.cby_first_name, null, props.SelectedRoleDetails.cby_last_name)}
                                            </span>
                                        </div>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Created On</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.created_on}
                                        </span>
                                    </div>
                                    <div
                                            className={
                                                "profile-view__basics_default-item item-role"
                                            }
                                        >
                                            <label>Modified By</label>
                                            <span className={"value"}>
                                                {formatName(props.SelectedRoleDetails.mby_first_name, null, props.SelectedRoleDetails.mby_last_name)}
                                            </span>
                                        </div>
                                    <div
                                        className={
                                            "profile-view__basics_default-item item-role"
                                        }
                                    >
                                        <label>Modified On</label>
                                        <span className={"value"}>
                                            {props.SelectedRoleDetails.modified_on}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
            </>
            }               
                    
            <div className={'tab-navContainer d-flex justify-between'}>
                <Tabs
                    value={tabValue}
                    onChange={tabChange}
                    indicatorColor="primary"
                    aria-label="User Role Details Tabs"
                >
                    <Tab label="Actions"/>
                    <Tab label="Users" />
                </Tabs>
            </div>
            <div className={'tab-panelContainer'}>
                <Typography
                    component="div"
                    role="tabpanel"
                    className="tab-panel"
                    hidden={tabValue !== 0}
                >
                    <Actions 
                        AllActions = {props.AllActions}
                        HandlePermissionChange = {props.HandlePermissionChange}
                        AllRoles = {props.AllRoles}
                        AllModules = {props.AllModules}
                        Filter = {props.Filter}
                        HandleFilterChange = {props.HandleFilterChange}
                        FilterModule = {props.FilterModule}
                        AddAction = {props.AddAction}
                        DeleteRoleActionClick = {props.DeleteRoleActionClick}
                    />
                </Typography>
                <Typography
                    component="div"
                    role="tabpanel"
                    className="tab-panel"
                    hidden={tabValue !== 1}
                >
                    <Users 
                        Users = {props.Users}
                        UserSearchKey = {props.UserSearchKey}
                        HandleSearch = {props.HandleSearch}
                        Search = {props.Search}
                        AddUserClick = {props.AddUserClick}
                        DeleteUserRoleClick = {props.DeleteUserRoleClick}
                        EnterPressed = {props.EnterPressed}
                    />
                </Typography>
            </div>
            </Paper>
             <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            {props.PopupType === 'add' &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Add user to role' }
                OnSubmit = { props.AddUser}
                Content = { <DialogContent FormProps = { props }/> }
            />
            }
             {props.PopupType === 'add_action' &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Add action to role' }
                OnSubmit = { props.AddActionToRole}
                Content = { <DialogAddAction FormProps = { props }/> }
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Confirm Delete' }
                OnSubmit = { props.DeleteUserRole}
                Content = { <DialogWithConfirmMessage msg="Do you really want to delete this user from this role ?" /> }
            />
            }
            {props.PopupType === 'delete_role_action' &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Confirm Delete' }
                OnSubmit = { props.DeleteRoleAction}
                Content = { <DialogWithConfirmMessage msg={"Do you really want to delete '" + props.ActionName + "' action from this role?"} />}
                ButtonName = { 'OK' }
            />
            }
        </>
    )
}

const Actions  = props => {
    return (
        <React.Fragment>
          
            <form className="form-fullwidth role-details-modules-filter" onSubmit={onSubmit}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={8}></Grid>
                    <Grid item xs={12} md={1}>
                        <InputLabel id="demo-simple-select-label"> </InputLabel>
                        <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.AddAction}>Add action</Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <InputLabel id="demo-simple-select-label">Modules</InputLabel>
                        <FormControl variant="standard">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name={'filter_module'}
                                value={props.FilterModule}
                                onChange={props.HandleFilterChange}
                            >
                                {_R.type(props.AllModules) !== 'Undefined' && props.AllModules.map((m, i) =>
                                    <MenuItem value={m.module}>{m.module}</MenuItem>
                                )}

                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <InputLabel id="demo-simple-select-label"> </InputLabel>
                        <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.Filter}>Filter</Button>
                    </Grid>
                </Grid>
            </form>
          
            <div className={'timeSheet-list_body'}>
                <Table size="small" aria-label="Timesheet List" className={'list-table_basic'}>
                    <TableHead className={'thead'}>
                        <TableRow>
                            <TableCell>Module</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(_R.type(props.AllActions) !== 'Undefined') && props.AllActions.map((ac,i) => 
                        <TableRow hover>
                            <TableCell>
                                <span>{ac.module}</span>
                            </TableCell>
                            <TableCell>
                                <span>{ac.action}</span>
                            </TableCell>
                            <TableCell>
                                <span>{ac.description}</span>
                            </TableCell>
                            <TableCell>
                                <IconButton>
                                <CancelOutlinedIcon
                                    onClick={props.DeleteRoleActionClick(ac.action_id, ac.action)}
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                </IconButton>
                            </TableCell>                           
                        </TableRow>
                        )
                        }
                    </TableBody>
                </Table>
            </div>
        </React.Fragment>
    )
}

const Users  = props => {
    return (
        <>
            <form className="form-fullwidth role-details-user-search" onSubmit={onSubmit}>
                <Grid container spacing={3}>                
                <Grid item xs={12} md={7}></Grid>
                <Grid item xs={12} md={2}>
                    <div style = {{width:'100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <InputLabel id="demo-simple-select-label"> </InputLabel>
                        <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.AddUserClick}>Add User To Role</Button>
                    </div>
                </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id = "user_search_key"
                                name = "user_search_key"
                                label = "Search user"
                                type = "text"
                                onChange = {props.HandleSearch}
                                value={props.UserSearchKey}
                                onKeyUp={props.EnterPressed}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <InputLabel id="demo-simple-select-label"> </InputLabel>
                        <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.Search}>Search</Button>
                    </Grid>
                  
                </Grid>
            </form>

            <div className={'timeSheet-list_body'}>
                <Table size="small" aria-label="Timesheet List" className={'list-table_basic'}>
                    <TableHead className={'thead'}>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        { _R.type(props.Users) !== 'Undefined' && props.Users.map((us,i) =>
                        <TableRow hover>
                            <TableCell>
                                <span>{formatName(us.first_name,null,us.last_name)}</span>
                            </TableCell>
                            <TableCell>
                                <IconButton>
                                <CancelOutlinedIcon
                                    //checked={ac.selected}
                                    onClick={props.DeleteUserRoleClick(us.user_id,i)}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        )
                        }
                    </TableBody>
                </Table>
            </div>
        </>
    )
}

const DialogContent = (props) => {
    const { AllUsers,OnSelectUser,SelectedUserIds } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={onSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                        <Select
                            value={SelectedUserIds ? SelectedUserIds : []}
                            onChange={OnSelectUser}
                            multiple={true}
                        >
                            {_R.type(AllUsers) !== 'Undefined' && AllUsers.map((u, i) =>
                            <MenuItem value={u.user_id}>{formatName(u.first_name,null,u.last_name)}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogAddAction = (props) => {    
    return (
        <div style={{minHeight: "250px"}}>
        <form className="form-fullwidth" id="add_action_form" onSubmit={onSubmit}>
            <Grid container spacing={3}>
	     <Grid item xs={12} md={12}>
            <InputLabel id="demo-simple-select-label">Modules</InputLabel>
                    <FormControl variant="standard">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={'filter_module_add_action'}
                            value={props.FormProps.AddActionFilterModule}
                            onChange={props.FormProps.AddActionHandleFilterChange}
                        >
                            {_R.type(props.FormProps.AllModules) !== 'Undefined' && props.FormProps.AllModules.map((m, i) =>
                                <MenuItem value={m.module}>{m.module}</MenuItem>
                            )}

                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
	        <InputLabel id="demo-simple-select-label">Actions</InputLabel>
                    <input type='hidden' name='actions' value={(typeof props.FormProps.Actions !== 'undefined') ? props.FormProps.Actions : null} />
                    <FormControl>
                        <TreeSelectComponent
                            data={typeof props.FormProps.ActionList != 'undefined' && props.FormProps.ActionList ? props.FormProps.ActionList : []}
                            placeholderText={"Search Actions"}
                            type='actions'
                            HandleTreeChange={props.FormProps.HandleTreeChange}
                        />
                        {props.FormProps.Errors &&
                            <span class="error">{props.FormProps.Errors.add_action}</span>
                        }
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
        </div>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
