import React from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { CustomizedSnackbars } from '../common/snackbars';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatName } from '../common/common';
import * as _R from 'ramda';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ChangeHistory } from '../requests/Common/ChangeHistory';
import { PersonalExpense } from './Common/PersonalExpense';
import { Comment } from './Common/Notes';
import { DialogBoxMedium } from '../common/dialog.medium';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ViewImageComponent } from './Add/ViewImage.Component';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaymentComponent } from './Review/Payment.Component';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ExpenseForm } from './Add/ExpenseForm';
import { DialogBoxLarge } from '../common/dialog.large';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { ViewExpenseComponent } from './Add/ViewExpense.Component';
import { CustomProgressBar } from '../common/CustomProgressBar';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { PreviewComponent } from './Common/Preview'; 
import { styled } from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;
const StyledTable = styled(Table) (({ theme }) => ({
    '& .root': {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    '& .tableCell': {
        color: "red"
    },
}));

const DialogEditExpense = (props) => {
    const { FormProps } = props;
    return (
        <div className={'section-main'}>
            <div className={'section-header'}>
                <div className={'col-left'}>
                    <Typography component="h2" className={'section-title mt-2'} variant="button" display="block" gutterBottom color="primary">
                        Details
                    </Typography>
                </div>
            </div>
            <div className={'section-body'}>
                <form className="form-fullwidth">
                        {(FormProps.AddPermissionErr !== '') &&
                        <Grid item xs={12} md={12} id="errorMsg">
                            <>
                                <span className={'error'}>{FormProps.AddPermissionErr}</span>
                            </>
                        </Grid>
                        }
                    <CustomProgressBar
                        loaderOpen={FormProps.ShowLoader}
                    /> 
                    <ExpenseForm
                        formFields={FormProps.FormFields}
                        HandleExpenseDateChange = {props.HandleExpenseDateChange}
	    		Open={FormProps.AttachmentOpen}
                        handleOnChange={FormProps.HandleOnChange}
                        fileUploadCallback={FormProps.FileUploadCallback}
                        handleAttachmentSelection={FormProps.HandleAttachmentSelection}
                        cancel={FormProps.AttachmentCancel}
                        getRecentFiles={FormProps.GetRecentFiles}
                        attachFiles={FormProps.AttachFiles}
                        errors={FormProps.Errors}
                        expenseCodes={FormProps.ExpenseCodes}
                        expenseCategories={FormProps.ExpenseCategories}
                        expenseTypes={FormProps.ExpenseTypes}
                        projectCodes={FormProps.ProjectCodes}
                        uploadButtonName={FormProps.uploadButtonName}
                        uploadTitle={FormProps.UploadTitle}
                        selectedFile={FormProps.SelectedFile}
                        recentFiles={FormProps.RecentFiles}
                        mileageRate={FormProps.MileageRate}
                        attachedFileNames={FormProps.AttachedFileNames}
                        OriginalFileNames={FormProps.OriginalFileNames}
                        joinedDate={FormProps.joinedDate}
                        removeItem={FormProps.RemoveItem}
                        currencySymbol={FormProps.Currency}
                        expenseMinAmount={FormProps.AttachmentMin}
                        Filetype={FormProps.Filetype}
                        Current={FormProps.Current}
                        LoginUser={FormProps.LoginUser}
                        UploadSize = {FormProps.UploadSize}
                    />
                </form>
            </div>
            {/*<ChangeHistory History={_R.type(FormProps.ExpenseChangeHistory) !== 'Undefined' ? FormProps.ExpenseChangeHistory : []} Expense={true} />*/}
        </div>
    )
}

export const ViewExpenseDetailsComponent = (props) => {
    function getReferer()
    {  
        var path = document.referrer;
        var referer = path.split("/").pop(); 
        return referer;
    }
    
    const bgHighlight = {
        background: "#fdecea",
    };
    const bgNormal = {
        background: "#fff",
    }
    const disabled = (props.IsFinanceTeam) ? false : true;
    const hideAdvPaySection = (props.IsFinanceTeam) ? false : ((props.EstimatedAmount) ? false : true);

    let approvers_list = '';
    let approvers = props.ReportApprovers;
    let approvers_count = (approvers !== null) ? approvers.length : 0;
    for (let key in approvers) {
        approvers_list = approvers_list + formatName(approvers[key]['reviewer_first_name'], null, approvers[key]['reviewer_last_name']);
        if (key < approvers_count - 1) {
            approvers_list += '; ';
        }
    }
    return (
        <React.Fragment>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'} >
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} >
                            </Button>
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"> 
                            {(getReferer() === 'vouchers')?'Expense Voucher Details':((props.Current !== '' && props.Current === 'submit') ? "Submit Expense Voucher" : "Review Expense Voucher")}
                            </Typography>
                        </div>
                        <div className="actions">
                            {(props.Current === "review") ?
                                <>
                                    {(props.Edit === true) ?
                                        <>
                                            {props.Status.map((item) =>
                                                parseInt(props.ReportStatus) !== 7 &&
                                                <>
                                                    {parseInt(item.id) === 4 ?
                                                        <>
                                                            <Button color='secondary' variant="contained" size="small" onClick={() => props.ReviwerAction(item.id)}>
                                                                {props.StatusLabels[item.id]}
                                                            </Button>
                                                            <i> </i>
                                                        </>
                                                        :
                                                        <>
                                                            <Button color='primary' variant="contained" size="small" onClick={() => props.ReviwerAction(item.id)}>
                                                                {props.StatusLabels[item.id]}
                                                            </Button>
                                                            <i> </i>
                                                        </>
                                                    }
                                                </>
                                            )}
                                            {(parseInt((props.ReportStatus)) === 3) &&
                                                <><Button variant="contained" size="small" onClick={() => props.ReviwerAction(-1)} color="secondary">Un-Approve</Button><i> </i></>
                                            }
                                            <><Button variant="outlined" size="small" onClick={() => props.AddNoteClick()}>Add Note</Button><i> </i></>
                                        </>
                                        :
                                        <></>
                                    }
                                    <>{(typeof props.ClientExpenses !== 'undefined') && (props.ClientExpenses.length > 0) && props.reviewPermision && (parseInt(props.PersonalDetails.status) !== 1) && (parseInt(props.PersonalDetails.status) !== 4) &&
                                        <><Button variant="outlined" size="small" onClick={() => props.ReviwerAction(-3)}>Billing Period</Button><i> </i></>
                                    }
                                    </>
                                </>
                                :
                                <> {(props.PersonalDetails.user_id === props.LoginUser) &&
                                    <>
                                        {(props.EditableStatus.includes(parseInt(props.PersonalDetails.status))) ?
                                            <>
                                                <Button variant="contained" size="small" color="secondary" onClick={() => props.OnSubmitClick("submit")}>Submit</Button></> :
                                            (props.UnsubmitStatus.includes(parseInt(props.PersonalDetails.status))) ? <><Button variant="contained" size="small" color="secondary" onClick={() => props.OnSubmitClick("unsubmit")}>UnSubmit</Button></> : <></>
                                        }
                                        { (parseInt(props.PersonalDetails.status)===1 || parseInt(props.PersonalDetails.status)===2) ? 
                                                <Button variant="outlined" color="secondary" size="small" onClick={() => props.OnCancelClick()}>Cancel</Button>
                                                :<></>
                                        }
                                        { (parseInt(props.PersonalDetails.status)=== 7 ) ?
                                                <Button variant="outlined" color="secondary" size="small" onClick={() => props.OnSaveClick()}>Save</Button>
                                                :<></>
                                        }
                                        <Button variant="outlined" size="small" onClick={() => props.AddNoteClick()}>Add Note</Button><i> </i>
                                    </>
                                }</>
                            }
                            <a href={API_URL + 'api/expenses/export?id=' + props.VoucherId}>
                                <Button color='primary' variant="contained" size="small" type="button">Export as PDF</Button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'section-body'}>
                  {(props.ItemError || props.AttachmentError) &&
                        <>
                            <Alert severity="error">
                                {(props.ItemError && props.Current === 'submit') &&
                                <div>Mandatory fields are missing.</div> 
                                }
                                {(props.ItemError && props.Current === 'review') &&
                                <div>All fields are required.</div>
                                }
                                {(props.AttachmentError) &&
                                <div>Receipts are required for all expenses {props.Currency}{parseFloat(props.AttachmentMin).toFixed(2)} and above.</div>
                                }
                      </Alert>
                    </>
                  }
                  {(props.ValidationError) &&
                    <Alert severity = "error">
                        {props.ValidationError}
                    </Alert>
                  }
                  {
                    props.SubmittedAmountValue === 0 &&
                        <Alert severity="error">
                            Atleast one expense item is required.
                        </Alert>
                  }
                  <Paper className={'mb-1'}>
                        <div className={'timesheet_list-summary'}>
                            <div className={'p-1rem summary box-details'}>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Staff Name</label>
                                        <span className={'value'}>{formatName(props.PersonalDetails.user_first_name, null, props.PersonalDetails.user_last_name)}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Staff Id</label>
                                        <span className={'value'}>{props.PersonalDetails.staff_id} </span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Voucher #</label>
                                        <span className={'value'}>{props.VoucherId} </span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Expense Period</label>
                                        <span className={'value'}>{props.PersonalDetails.period} </span>
                                    </li>
                                </ul>
                                <ul className={'list-standard'}>
                                    {props.DisplayEmployeeCode &&
                                        <li className={'list-item'}>
                                            <label>Employee Id</label>
                                            <span className={'value'}>{props.PersonalDetails.employee_id} </span>
                                        </li>
                                    }
                                    <li className={'list-item list-item-status'}>
                                        <label>Purpose</label>
                                        {(props.Edit && props.Current === 'submit') ?
                                            <FormControl className='flex-grow1'>
                                                <TextField
                                                    type="text"
                                                    multiline={true}
                                                    variant="outlined"
                                                    className={'input-bg w100'}
                                                    name="purpose"
                                                    onChange={props.HandlePurposeOnChange}
                                                    value={props.FormValues.purpose}
                                                    error={props.Errors.purpose && true}
                                                    helperText={props.Errors.purpose}
                                                />
                                            </ FormControl>
                                            : <span className={'value'}>{props.PersonalDetails.purpose}</span>
                                        }
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Status</label>
                                        <span className={'value'}>{props.PersonalDetails.status_name} </span>
                                    </li>
                                    {(props.PersonalDetails.status === 3) &&
                                        <li className={'list-item'}>
                                            <label>Payment Scheduled On</label>
                                            <span className={'value'}>{props.PersonalDetails.payment_scheduled_on} </span>
                                        </li>
                                    }
                                    {(props.PersonalDetails.status === 5) &&
                                        <li className={'list-item'}>
                                            <label>Secondary Reviewer(s)</label>
                                            <span className={'value'}>{approvers_list}</span>
                                        </li>
                                    }
                                    {(props.PersonalDetails.status === 6) &&
                                        <li className={'list-item'}>
                                            <label>Payment Details</label>
                                            <span className={'value'}>Date: {props.PersonalDetails.payment_date_formatted}, Mode: {props.PersonalDetails.payment_mode_name} </span>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className={'section-main'}>
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Itemized Expenses
                        </Typography>
                    </div>
                </div>
                <div className={'section-body'}>
                    <div className={'mb-1'}>
                       <div className={'timesheet_list'}>
                        <div className={'timeSheet-list_body'}>
                            <StyledTable aria-label="Timesheet List" className={'list-table_basic'} size="small">
                                <TableHead className={'thead'}>
                                    <TableRow>
                                        <TableCell> Date </TableCell>
                                        <TableCell> Category </TableCell>
                                        <TableCell> Project </TableCell>
                                        <TableCell> Code </TableCell>
                                        <TableCell colSpan='2'> Description/ Attachments</TableCell>
                                        <TableCell> Amount </TableCell>
                                        <TableCell width={'10%'}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={'tbody tbody-bordered'}>
                                    {(props.ItemizedExpense.length === 0) &&
                                        <TableRow><TableCell><i> No Itemized Expenses </i> </TableCell></TableRow>
                                    }
                                    {_R.type(props.ItemizedExpense) !== 'Undefined' && Object.keys(props.ItemizedExpense).map((item, k) =>
                                        <TableRow style = {(props.ItemizedExpense[item].error_flag === true) ? bgHighlight : bgNormal}>
                                            <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''}>
                                                {props.ItemizedExpense[item].date}
                                            </TableCell>
                                            <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''}>
                                                {props.ItemizedExpense[item].category_name}
                                            </TableCell>
                                            {(props.ItemizedExpense[item].paytype === 1 ?
                                                (props.ItemizedExpense[item].project_name && props.ItemizedExpense[item].project_code ?
                                                    <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''}> 
                                                        {props.ItemizedExpense[item].project_name} ({props.ItemizedExpense[item].project_code})
                                                    </TableCell>
                                                    : <TableCell> </TableCell>
                                                )
                                                :
                                                (props.ItemizedExpense[item].exp_project_name && props.ItemizedExpense[item].exp_project_code ?
                                                    <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''}>
                                                        {props.ItemizedExpense[item].exp_project_name} ({props.ItemizedExpense[item].exp_project_code})
                                                    </TableCell>
                                                    : <TableCell> </TableCell>
                                                )
                                            )}
                                            {(props.ItemizedExpense[item].exp_code && props.ItemizedExpense[item].exp_code_name) ?
                                                <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''}>
                                                    {props.ItemizedExpense[item].exp_code}:  {props.ItemizedExpense[item].exp_code_name} 
                                                </TableCell>
                                                :
                                                <TableCell> </TableCell>
                                            }
                                            <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' : ''} colSpan='2'>
                                                {props.ItemizedExpense[item].description}
                                                    <div>
                                                    {_R.type(props.ItemizedExpense[item].attachments) !== 'Undefined' && Object.keys(props.ItemizedExpense[item].attachments).map((attach, k) =>
                                                    <div>
                                                        <span className={"btn-attachment"} >
                                                        {props.ItemizedExpense[item].attachments[attach].attachment_name.length > 10 ?props.ItemizedExpense[item].attachments[attach].attachment_name.substring(0, 3) + "..." + props.ItemizedExpense[item].attachments[attach].attachment_name.substr(props.ItemizedExpense[item].attachments[attach].attachment_name.length-5) : props.ItemizedExpense[item].attachments[attach].attachment_name }
                                                        <a href={API_URL + 'api/expenses/downloadImage?id=' + props.ItemizedExpense[item].attachments[attach].file_id}><GetAppIcon fontSize="small" /></a>
                                                        </span>
                                                        {(props.ItemizedExpense[item].attachments[attach].preview_enabled === true) ?
                                                            <>
                                                            <IconButton className={'action-btn'} edge="end" aria-label="View File">
                                                                <FindInPageIcon fontSize="small" onClick={() => props.HandleViewFileClick(props.ItemizedExpense[item].attachments[attach].file_id, props.ItemizedExpense[item])} />
                                                            </IconButton>
                                                            </>
                                                            : <></>
                                                        }
                                                    </div>
                                                    )}
                                                    </div>
                                            </TableCell>
                                            <TableCell className={(props.ItemizedExpense[item].error_flag === true) ? 'tableCell' +' text-right' : 'text-right'}> 
                                                {props.ItemizedExpense[item].amount}
                                            </TableCell>
                                            <TableCell width={'10%'}>
                                                <Tooltip title="View" aria-label="view">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" size="small">
                                                        <VisibilityIcon fontSize="small" onClick={() => props.HandleViewClick(props.ItemizedExpense[item].id)} />
                                                    </IconButton>
                                                </Tooltip>
                                                {(props.Edit === true) ?
                                                    <>
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                                <EditIcon fontSize="small" onClick={() => props.HandleEditClick(props.ItemizedExpense[item].id, props.ItemizedExpense[item].created_by)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    : <></>}
                                                {(props.Review !== true && (props.PersonalDetails.status === 1 || props.PersonalDetails.status === 4) && props.Current === 'submit') ?
                                                    <Tooltip title="Delete" aria-label="delete">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                            <DeleteIcon fontSize="small" onClick={() => props.DeleteExpense(props.ItemizedExpense[item].id)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : <></>}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {(props.ItemizedExpense.length !== 0) &&
                                        <TableRow className={'timesheet-list_row-daytotal shaded'}>
                                            <TableCell colSpan='6' align='right'> SubTotal : </TableCell>
                                            <TableCell align='right'>{props.ItemizedSubTotal}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                                <TableBody className={'tbody'}>
                                    <TableRow className={'table-section-header'}>
                                        <TableCell colSpan='8'>
                                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                                Private Auto Travel
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableBody className={'tbody tbody-bordered'}>
                                    <TableRow className={'thead'}>
                                        <TableCell> Date </TableCell>
                                        <TableCell> Category </TableCell>
                                        <TableCell> Project </TableCell>
                                        <TableCell> Code </TableCell>
                                        <TableCell> Description/Attachments  </TableCell>
                                        <TableCell> Mileage </TableCell>
                                        <TableCell> Amount </TableCell>
                                        <TableCell width={'10%'}>Actions</TableCell>
                                    </TableRow>

                                    {(props.TravelExpenses.length === 0) &&
                                        <TableRow><TableCell colSpan='9'><i> No Private Auto Travel Expenses</i> </TableCell></TableRow>
                                    }
                                    {_R.type(props.TravelExpenses) !== 'Undefined' && Object.keys(props.TravelExpenses).map((item, k) =>
                                        <TableRow style = {(props.TravelExpenses[item].error_flag === true) ? bgHighlight : bgNormal}>
                                            <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}>
                                                {props.TravelExpenses[item].date}
                                            </TableCell>
                                            <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}>
                                                {props.TravelExpenses[item].category_name}
                                            </TableCell>
                                            {(props.TravelExpenses[item].paytype === 1 ?
                                                (props.TravelExpenses[item].project_name && props.TravelExpenses[item].project_code ?
                                                    <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}> 
                                                        {props.TravelExpenses[item].project_name} ({props.TravelExpenses[item].project_code}) 
                                                    </TableCell>
                                                    : <TableCell> </TableCell>
                                                )
                                                :
                                                (props.TravelExpenses[item].exp_project_name && props.TravelExpenses[item].exp_project_code ?
                                                    <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}>
                                                        {props.TravelExpenses[item].exp_project_name} ({props.TravelExpenses[item].exp_project_code})
                                                    </TableCell>
                                                    : <TableCell> </TableCell>
                                                )
                                            )}
                                            {(props.TravelExpenses[item].exp_code && props.TravelExpenses[item].exp_code_name) ?
                                                <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}>
                                                    {props.TravelExpenses[item].exp_code} : {props.TravelExpenses[item].exp_code_name}
                                                </TableCell>
                                                :
                                                <TableCell> </TableCell>
                                            }
                                            <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' : ''}>
                                                {props.TravelExpenses[item].description} 
                                                    <div>
                                                    {_R.type(props.TravelExpenses[item].attachments) !== 'Undefined' && Object.keys(props.TravelExpenses[item].attachments).map((attach, k) =>
                                                    <span className={"btn-attachment"}>
                                                        {props.TravelExpenses[item].attachments[attach].attachment_name.length > 10 ? props.TravelExpenses[item].attachments[attach].attachment_name.substring(0, 3) + "..." + props.TravelExpenses[item].attachments[attach].attachment_name.substr(props.TravelExpenses[item].attachments[attach].attachment_name.length-5) : props.TravelExpenses[item].attachments[attach].attachment_name }
                                                        <a href={API_URL + 'api/expenses/downloadImage?id=' + props.TravelExpenses[item].attachments[attach].file_id}><GetAppIcon fontSize="small" /></a>
                                                        {(props.TravelExpenses[item].attachments[attach].preview_enabled === true) ?
                                                            <>
                                                            <IconButton className={'action-btn'} edge="end" aria-label="View File">
                                                                <FindInPageIcon fontSize="small" onClick={() => props.HandleViewFileClick(props.TravelExpenses[item].attachments[attach].file_id, props.TravelExpenses[item])} />
                                                            </IconButton>
                                                            </>
                                                            : <></>
                                                        }
                                                    </span>
                                                    )}
                                                    </div> 
                                                </TableCell>
                                            <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell' +' text-right' : 'text-right'}> 
                                                {props.TravelExpenses[item].mileage}
                                            </TableCell>
                                            <TableCell className={(props.TravelExpenses[item].error_flag === true) ? 'tableCell'+' text-right' : 'text-right'}>
                                                {props.TravelExpenses[item].amount}
                                            </TableCell>
                                            <TableCell width={'10%'}>
                                                <Tooltip title="View" aria-label="view">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                        <VisibilityIcon fontSize="small" onClick={() => props.HandleViewClick(props.TravelExpenses[item].id)} />
                                                    </IconButton>
                                                </Tooltip>
                                                {(props.Edit === true) ?
                                                    <>
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                                <EditIcon fontSize="small" onClick={() => props.HandleEditClick(props.TravelExpenses[item].id, props.TravelExpenses[item].created_by)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </> : <></>
                                                }
                                                {(props.Review !== true && (props.PersonalDetails.status === 1 || props.PersonalDetails.status === 4) && props.Current === 'submit') ?
                                                    <Tooltip title="Delete" aria-label="delete">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                            <DeleteIcon fontSize="small" onClick={() => props.DeleteExpense(props.TravelExpenses[item].id)} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : <></>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={'timesheet-list_row-daytotal shaded'}>
                                        <TableCell colspan='4'>
                                            <span className={'table-info'}> {"Mileage reimbursement for private auto travel: "+props.Currency+"/Mile = "+props.CurrentRate} </span>
                                        </TableCell>
                                        {(props.ItemizedExpense.length !== 0) ? 
                                            <>
                                                <TableCell colspan='2' align='right'> SubTotal : </TableCell>
                                                <TableCell colSpan='1' className={'text-right'}>{props.TravelSubTotal}</TableCell>
                                                <TableCell></TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell colspan='4'></TableCell>
                                            </>
                                        }
                                    </TableRow>
                                    <TableRow className={'timesheet-list_row-weektotal shaded'}>
                                        <TableCell colspan='6' align='right' > Submitted Amount : </TableCell>
                                        <TableCell colSpan='1' className={'text-right'}>{props.SubmittedAmount} </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    {(props.PersonalDetails.actual_payment) &&
                                        <>
                                            <TableRow className={'timesheet-list_row-weektotal shaded'}>
                                                <TableCell colspan='6' align='right' > Actual Payment : </TableCell>
                                                <TableCell colSpan='2'>{props.Currency}{parseFloat(props.PersonalDetails.actual_payment).toFixed(2)} </TableCell>
                                            </TableRow>
                                            {((props.PersonalDetails.estimated_amount) || (props.PersonalDetails.advance_payment) || (props.PersonalDetails.payroll_date)) && <TableRow className={'timesheet-list_row-weektotal shaded'}>
                                                <TableCell colspan='8'>Advance Payment Details -  {(props.PersonalDetails.estimated_amount) && <>Estimated Amount : {props.Currency}{parseFloat(props.PersonalDetails.estimated_amount).toFixed(2)},</>}{(props.PersonalDetails.advance_payment) && <> Advance Payment : {props.Currency}{parseFloat(props.PersonalDetails.advance_payment).toFixed(2)},</>}{(props.PersonalDetails.payroll_date) && <> Payroll Date : {props.PersonalDetails.payroll_date}</>}</TableCell>
                                            </TableRow>}
                                        </>
                                    }
                                </TableBody>
                            </StyledTable>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'section-main'}>
                <div className={'section-body'}>
                    <Paper>
                        {(props.Current === "review") &&
                            <>
                                <PersonalExpense
                                    PersonalExpense={_R.type(props.PersonalExpense) !== 'Undefined' ? props.PersonalExpense : []}
                                    Expense={true}
                                    DisplayEligibility={_R.type(props.DisplayEligibility) !== 'Undefined' ? props.DisplayEligibility : ''}
                                    YtdPaidLabel={_R.type(props.YtdPaidLabel) !== 'Undefined' ? props.YtdPaidLabel : ''}
                                    YtdUnpaidLabel={_R.type(props.YtdUnpaidLabel) !== 'Undefined' ? props.YtdUnpaidLabel : ''}
                                    FirstAmountLabel={_R.type(props.FirstAmountLabel) !== 'Undefined' ? props.FirstAmountLabel : ''}
                                    TotalAmt={_R.type(props.TotalAmt) !== 'Undefined' ? props.TotalAmt : ''}
                                    UtoLabel={_R.type(props.UtoLabel) !== 'Undefined' ? props.UtoLabel : ''}
                                    EligibilityLabel={_R.type(props.EligibilityLabel) !== 'Undefined' ? props.EligibilityLabel : ''}
                                    BalanceLabel={_R.type(props.BalanceLabel) !== 'Undefined' ? props.BalanceLabel : ''}
                                /></>
                        }
                        <Comment Comments = {_R.type(props.ExpenseNotes) !== 'Undefined' ? props.ExpenseNotes : []} Page = {props.Current} />
                        <ChangeHistory History={_R.type(props.ChangeLog) !== 'Undefined' ? props.ChangeLog : []} Expense={true} />
                    </Paper>
                </div>
            </div>
            <DialogBoxMedium
                Open={props.ExpenseDeleteOpen}
                Cancel={props.Close}
                Title={'Confirm Delete'}
                ButtonName ={'Delete'}
                OnSubmit={props.HandleDeleteClick}
                Content={<DialogDeleteExpense FormProps={props} />}
            />
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.Close}
                Title={'Add Note'}
                OnSubmit={props.HandleNoteSubmit}
                Content={<DialogContent FormProps={props} />}
                Loading = {props.ButtonLoader}
            />
            <ViewImageComponent
                imageOpen={props.imageOpen}
                handleCancel={props.handleCancel}
                handleImageClick={props.handleImageClick}
                imageId={props.imageId}
            />
            <DialogBoxMedium
                Open={props.ConfirmDialog}
                Cancel={props.Close}
                Title={(props.Action === "submit") ? "Submit Expense Voucher" : "Unsubmit Expense Voucher"}
                ButtonName={"Confirm"}
                OnSubmit={props.HandleExpenseSubmit}
                Loading = {props.ButtonLoader}
                Disabled = {props.HideButton}
                Content={<DialogConfirm FormProps={props} />}
            />
            <PreviewComponent 
                FilterView = {props.FilterView}
                FileOpen = {props.FileOpen}
                PreviewedItem = {props.PreviewedItem}
                FileUrl = {props.FileUrl}
                Variant = "persistent"
                Anchor = "left"
            />

            {props.PopupType === 'approve' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Approve Expense Voucher'}
                    Content={<DialogApprove
                        FormProps={props.ApprovePopUpFields}
                        HandleApprovePopupChange={props.HandleApprovePopupChange}
                        AdvancePaymentFlag={props.AdvancePaymentFlag}
                        ApproveValidationErrors={props.ApproveValidationErrors}
                        Disabled={disabled}
                        Currency={props.Currency}
                        HideAdvPaySection={hideAdvPaySection}
                    />}
                    Loading = {props.ButtonLoader}
                    OnSubmit={props.ApproveExpenseVoucher}
                />
            }
            {props.PopupType === 'review' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Review Expense Voucher'}
                    Content={<DialogReview FormProps={props} />}
                    OnSubmit={props.SubmitReview}
                    Loading = {props.ButtonLoader}
                />
            }
            {props.PopupType === 'billing' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    Title={'Billing Period'}
                    Content={<DialogBilling FormProps={props} />}
                    OnSubmit={props.UpdateBillingData}
                />
            }
            {props.PopupType === 'pay' &&
                <PaymentComponent
                    formFields={props.FormFields}
                    paymentOpen={props.ActionPopUpOpen}
                    handleCancel={props.Close}
                    handlePayment={props.HandlePayment}
                    handleOnChange={props.HandleOnChange}
                    errors={props.Errors}
                    handleDateChange = {props.HandleDateChange}
                    ButtonLoader={props.ButtonLoader}
                />
            }
            {props.PopupType === 'reject' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Reject Expense Voucher'}
                    Content={<DialogReject
                        RejectNote={props.RejectNote}
                        HandleRejectChange={props.HandleRejectChange}
                        Error={props.RejectError ? props.RejectError : props.Errors.note} 
                    />}
                    Loading={props.ButtonLoader}
                    OnSubmit={props.RejectExpenseVoucher}
                />
            }
            {props.PopupType === 'un-approve' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Unapprove Expense Voucher'}
                    Content={<DialogUnApprove
                        Errors={props.Errors}
                        RejectNote={props.RejectNote}
                        HandleRejectChange={props.HandleRejectChange}
                    />}
                    Loading = {props.ButtonLoader}
                    OnSubmit={props.UnApproveExpenseVoucher}
                />
            }
            {props.PopupType === 'cancel' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Cancel Expense Voucher'}
                    Content={<DialogCancel FormProps={props} />}
                    OnSubmit={props.CancelExpenseVoucher}
                    Loading = {props.ButtonLoader}
                />
            }
            {props.PopupType === 'save' &&
                <DialogBoxMedium
                    Open={props.ActionPopUpOpen}
                    Cancel={props.Close}
                    ButtonName={"Yes"}
                    Title={'Save Expense Voucher'}
                    Content={<DialogSave FormProps={props} />}
                    OnSubmit={props.SaveExpenseVoucher}
                    Loading = {props.ButtonLoader}
                />
            }            

            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant={props.SnackbarType}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
            {props.ViewExpense ?
                <ViewExpenseComponent
                    dialogOpen={props.Open}
                    formFields={props.FormFields}
                    attachments={props.Attachments}
                    handleCancel={props.HandleCancelViewPopup}
                    History={props.ExpenseChangeHistory}
                    HandleImageClick = {props.HandleImageClick}
                />:
                <DialogBoxLarge
                    Open={props.Open}
                    Cancel={props.Close}
                    Title={'Expense Item'}
                    Content={<DialogEditExpense FormProps={props} />}
                    OnSubmit={() => props.UpdateExpenseData(props.ExpenseDetails.id)}
                />
            }
        </React.Fragment>
    )
};

const DialogDeleteExpense = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you really want to delete this expense item?   
                </Typography>
            </Box>
        </div>
    )
}

const DialogConfirm = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        {(props.FormProps.Action === "unsubmit") ?
                            <>
                                <Typography variant="subtitle2" gutterBottom>Do you really want to Unsubmit this expense voucher?</Typography></>
                            :
                            <>
                                <Typography variant="subtitle2" gutterBottom>Do you really want to Submit this expense voucher for the period {props.FormProps.PersonalDetails.period}?</Typography>
                                <Typography variant="body2" gutterBottom>Please note that you will not be able to save any other expenses for {props.FormProps.PersonalDetails.period} after you submit the voucher. If you expect any other expenses for this month, do not submit it now.</Typography>
                               {_R.type(props.FormProps.Declarations) !== 'Undefined' && Object.keys(props.FormProps.Declarations).map((item) =>
                                   <FormControl required  component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={_R.type(props.FormProps.Declarations !== 'Undefined') ?(_R.includes(item, props.FormProps.FormFields.agree) ? true : false) : false}
                                                value={item}
                                                id="agree"
                                                name="agree"
                                                onChange={props.FormProps.HandleOnChange}
                                            />
                                        }   
                                        label={<Typography variant="body2"> {props.FormProps.Declarations[item]} </Typography>}
                                    />
                               </FormControl>
                               )}
                            </>
                        }
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogContent = (props) => {
    return (
        <form className="form-fullwidth" onSubmit={props.FormProps.OnActionSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="note"
                            name="note"
                            label="Note"
                            type="text"
                            multiline={true}
                            required
                            variant="outlined"
                            value={(typeof props.FormProps.FormFields.note !== 'undefined') ? props.FormProps.FormFields.note : null}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.Errors.note && true}
                            helperText={props.FormProps.Errors.note}
                        />
                    </ FormControl>
                    {props.FormProps.Current === "review" &&
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(typeof props.FormProps.PrivateNoteFlag !== 'undefined') ? props.FormProps.PrivateNoteFlag : false}
                                        value={(typeof props.FormProps.PrivateNoteFlag !== 'undefined') ? props.FormProps.PrivateNoteFlag : null}
                                        id="privateNote"
                                        name="privateNote"
                                        onChange={props.FormProps.HandleOnChange}
                                    />
                                }
                                label="Private Note"
                            />
                        </FormControl>
                    }
                </Grid>
            </Grid>
        </form>
    )
}
const DialogBilling = (props) => {
    return (
        <form className="form-fullwidth" onSubmit={props.FormProps.onBillingSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            required
                            id="billing_from"
                            name="billing_from"
                            label="From Date"
                            type="date"
                            value={!_R.isNil(props.FormProps.FormFields.billing_from) && props.FormProps.FormFields.billing_from}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.Errors.billing_from && true}
                            helperText={props.FormProps.Errors.billing_from}
                        />
                        <TextField
                            required
                            id="billing_to"
                            name="billing_to"
                            label="To Date"
                            type="date"
                            value={!_R.isNil(props.FormProps.FormFields.billing_to) && props.FormProps.FormFields.billing_to}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.Errors.billing_to && true}
                            helperText={props.FormProps.Errors.billing_to}
                        />

                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogReview = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    Do you want to review expense voucher?
                    </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            required
                            id="actualPaymentValue"
                            name="actualPaymentValue"
                            type="number"
                            label="Actual Payment"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment:<InputAdornment position="start">{props.FormProps.Currency}</InputAdornment>,
                            }}
                            value={_R.type(props.FormProps.FormFields.actualPaymentValue) !== 'undefined' ? props.FormProps.FormFields.actualPaymentValue : ''}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.ApproveValidationErrors.validationTips && true}
                            helperText={props.FormProps.ApproveValidationErrors.validationTips}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(typeof props.FormProps.AdvancePaymentFlag !== 'undefined') ? props.FormProps.AdvancePaymentFlag : false}
                                    value={(typeof props.FormProps.AdvancePaymentFlag !== 'undefined') ? props.FormProps.AdvancePaymentFlag : null}
                                    id="advancePayment"
                                    name="advancePayment"
                                    onChange={props.FormProps.HandleOnChange}
                                />
                            }
                            label="Advance Payment"
                        />
                    </FormControl>
                </Grid>
                {props.FormProps.AdvancePaymentFlag &&
                    <>
                        <Grid item xs={12} md={6}>
                            <FormControl>
                                <TextField
                                    required
                                    id="estimatedAmount"
                                    type="number"
                                    name="estimatedAmount"
                                    label="Estimated Amount"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment:<InputAdornment position="start">{props.FormProps.Currency}</InputAdornment>,
                                    }}
                                    value={props.FormProps.FormFields.estimatedAmount}
                                    onChange={props.FormProps.HandleOnChange}
                                    error={typeof props.FormProps.ApproveValidationErrors.estimateAmountErr !== 'undefined' && true}
                                    helperText={props.FormProps.ApproveValidationErrors.estimateAmountErr}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl>
                                <TextField
                                    required
                                    id="advance_payment"
                                    name="advance_payment"
                                    type="number"
                                    label="Advance Payment "
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        startAdornment:<InputAdornment position="start">{props.FormProps.Currency}</InputAdornment>,
                                    }}
                                    value={props.FormProps.FormFields.advance_payment}
                                    onChange={props.FormProps.HandleOnChange}
                                    error={typeof props.FormProps.ApproveValidationErrors.advancePaymentErr !== 'undefined' && true}
                                    helperText={props.FormProps.ApproveValidationErrors.advancePaymentErr}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl>
                                <TextField
                                    required
                                    id="payrollDate"
                                    name="payrollDate"
                                    label="Payroll date"
                                    type="date"
                                    value={!_R.isNil(props.FormProps.FormFields.payrollDate) && props.FormProps.FormFields.payrollDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={props.FormProps.HandleOnChange}
                                    error={typeof props.FormProps.ApproveValidationErrors.payrollDateErr !== 'undefined' && true}
                                    helperText={props.FormProps.ApproveValidationErrors.payrollDateErr}
                                />
                            </FormControl>
                        </Grid>
                    </>
                }
                <Grid item xs={12} md={6}>
                    <FormControl >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(typeof props.FormProps.SecondaryFlag !== 'undefined') ? props.FormProps.SecondaryFlag : false}
                                    value={(typeof props.FormProps.SecondaryFlag !== 'undefined') ? props.FormProps.SecondaryFlag : null}
                                    id="secondaryReviewer"
                                    name="secondaryReviewer"
                                    onChange={props.FormProps.HandleOnChange}
                                />
                            }
                            label="Require Secondary Reviewer"
                        />
                    </FormControl>
                </Grid>
                {props.FormProps.SecondaryFlag &&
                    <Grid item xs={12} md={12}>
                        <div>
                            <Autocomplete
                                value={(typeof props.FormProps.FormFields.sec_reviewer !== 'undefined' && props.FormProps.FormFields.sec_reviewer) ? props.FormProps.FormFields.sec_reviewer : []}
                                onChange={props.FormProps.HandleApproverChange}
                                multiple
                                id="tags-outlined"
                                options={(typeof props.FormProps.Filters.sec_reviewers !== 'undefined') ? props.FormProps.Filters.sec_reviewers : []}
                                getOptionLabel={(option) => formatName(option.first_name, null, option.last_name)}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Secondary Reviewer"
                                        placeholder="Secondary Reviewer"
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                }
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            required
                            id="note"
                            name="note"
                            label="Add Note"
                            type="text"
                            multiline={true}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof props.FormProps.FormFields.note !== 'undefined') ? props.FormProps.FormFields.note : null}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.ApproveValidationErrors.note && true}
                            helperText={props.FormProps.ApproveValidationErrors.note}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(typeof props.FormProps.PrivateNoteFlag !== 'undefined') ? props.FormProps.PrivateNoteFlag : false}
                                    value={(typeof props.FormProps.PrivateNoteFlag !== 'undefined') ? props.FormProps.PrivateNoteFlag : null}
                                    id="privateNote"
                                    name="privateNote"
                                    onChange={props.FormProps.HandleOnChange}
                                />
                            }
                            label="Private Note"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogApprove = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        Do you want to approve expense voucher?
                    </Grid>
                    {(props.ApproveValidationErrors && typeof props.ApproveValidationErrors['validationTips'] !== 'undefined') &&
                        <Grid item xs={12} md={12}>
                            <span className={'error'}>{props.ApproveValidationErrors['validationTips']}</span>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="actualPay"
                                name="actualPay"
                                label="Actual Payment"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment:<InputAdornment position="start">{props.Currency}</InputAdornment>,
                                }}
                                value={props.FormProps.actualPay}
                                onChange={props.HandleApprovePopupChange}
                                disabled={props.Disabled}
                                error={typeof props.ApproveValidationErrors.actual_payment !== 'undefined' && true}
                                helperText={props.ApproveValidationErrors.actual_payment}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField
                                id="spDate"
                                name="spDate"
                                label="Scheduled Payment Date"
                                type="date"
                                value={!_R.isNil(props.FormProps.spDate) && props.FormProps.spDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={props.HandleApprovePopupChange}
                                error={typeof props.ApproveValidationErrors.payment_scheduledate !== 'undefined' && true}
                                helperText={props.ApproveValidationErrors.payment_scheduledate}
                            />
                        </FormControl>
                    </Grid>
                    {!props.HideAdvPaySection &&
                        <Grid item xs={12} md={6}>
                            <FormControl >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(typeof props.AdvancePaymentFlag !== 'undefined') ? props.AdvancePaymentFlag : false}
                                            value={(typeof props.AdvancePaymentFlag !== 'undefined') ? props.AdvancePaymentFlag : null}
                                            id="advancePaymentFlag"
                                            name="advancePaymentFlag"
                                            onChange={props.HandleApprovePopupChange}
                                        />
                                    }
                                    label="Advance Payment"
                                />
                            </FormControl>
                        </Grid>
                    }
                    {props.AdvancePaymentFlag &&
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <TextField
                                        required
                                        id="estimatedAmount"
                                        name="estimatedAmount"
                                        label="Estimated Amount"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            startAdornment:<InputAdornment position="start">{props.Currency}</InputAdornment>,
                                        }}
                                        disabled={props.Disabled}
                                        value={props.FormProps.estimatedAmount}
                                        onChange={props.HandleApprovePopupChange}
                                        error={typeof props.ApproveValidationErrors.estimateAmountErr !== 'undefined' && true}
                                        helperText={props.ApproveValidationErrors.estimateAmountErr}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <TextField
                                        required
                                        id="advancePayment"
                                        name="advancePayment"
                                        label="Advance Payment "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            startAdornment:<InputAdornment position="start">{props.Currency}</InputAdornment>,
                                        }}
                                        disabled={props.Disabled}
                                        value={props.FormProps.advancePayment}
                                        onChange={props.HandleApprovePopupChange}
                                        error={typeof props.ApproveValidationErrors.advancePaymentErr !== 'undefined' && true}
                                        helperText={props.ApproveValidationErrors.advancePaymentErr}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl>
                                    <TextField
                                        required
                                        id="payrollDate"
                                        name="payrollDate"
                                        label="Payroll date"
                                        type="date"
                                        value={!_R.isNil(props.FormProps.payrollDate) && props.FormProps.payrollDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={props.Disabled}
                                        onChange={props.HandleApprovePopupChange}
                                        error={typeof props.ApproveValidationErrors.payrollDateErr !== 'undefined' && true}
                                        helperText={props.ApproveValidationErrors.payrollDateErr}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField
                                id="note"
                                name="note"
                                label="Note"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                value={props.FormProps.note}
                                onChange={props.HandleApprovePopupChange}
                                error={typeof props.ApproveValidationErrors.noteErr !== 'undefined' && true}
                                helperText={props.ApproveValidationErrors.noteErr}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

const DialogReject = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        {'Do you want to reject this expense voucher?'}
                    </Grid>
                    {(props.Error && typeof props.Error !== 'undefined') &&
                        <Grid item xs={12} md={12}>
                            <span className={'error'}>{props.Error}</span>
                        </Grid>
                    }
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField
                                required
                                id="rejectNote"
                                name="rejectNote"
                                label="Note"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                value={props.RejectNote}
                                onChange={props.HandleRejectChange}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

const DialogUnApprove = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        Do you want to unapprove expense voucher?
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField
                                required
                                id="rejectNote"
                                name="rejectNote"
                                label="Note"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                value={props.RejectNote}
                                onChange={props.HandleRejectChange}
                                error={props.Errors.note && true}
                                helperText={props.Errors.note}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}
const DialogCancel = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <Typography variant="subtitle2" gutterBottom>Do you really want to cancel this expense voucher?</Typography>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="note"
                            name="note"
                            label="Add Note"
                            type="text"
                            multiline={true}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof props.FormProps.FormFields.note !== 'undefined') ? props.FormProps.FormFields.note : null}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.Errors.note && true}
                            helperText={props.FormProps.Errors.note}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogSave = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <Typography variant="subtitle2" gutterBottom>Do you want to save this expense voucher?</Typography>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id="note"
                            name="note"
                            label="Add Note"
                            type="text"
                            multiline={true}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={(typeof props.FormProps.FormFields.note !== 'undefined') ? props.FormProps.FormFields.note : null}
                            onChange={props.FormProps.HandleOnChange}
                            error={props.FormProps.Errors.note && true}
                            helperText={props.FormProps.Errors.note}                            
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

