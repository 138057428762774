import React from 'react';

import { PolicyViewComponent } from './PolicyView.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from './../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as _R from 'ramda';

const formFields = {
    'reason' : '',
    'comment' : '',
    'rule_string' : ''
}

export class PolicyViewContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            id : 0,
            policyRule : [],
            policy : '',
            dialogOpen : false,
            snackbarOpen : false,
            popup : '',
            errors: {},
            formFields : formFields,
            hasPermission: true,
            showLoader : true,
            selfEdit : false,
            snackbarMsg : '',
            snackbarMsgType : 'success',
            rules : [],
            editId : 0
        }
        this.addRuleClick = this.addRuleClick.bind(this);
        this.editPolicyRule = this.editPolicyRule.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.closeLoader = this.closeLoader.bind(this);
        this.saveNewRuleClick = this.saveNewRuleClick.bind(this);
    }

    componentDidMount = () => {
        let id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.getPolicyRuleDetails(id);
    };
    getPolicyRuleDetails = (id) =>{
        let apiEndpoint = 'api/settings/workpolicy/policy-rule-view';
        let data = {
            'id' : id,
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.permission) {
                    this.setState({
                        policy : response.data.policy,
                        policyRule : response.data.policy_rule,
                        rules : response.data.rules,
                        //snackbarMsg : response.data.message,
                        //snackbarMsgType : response.data.message_type,
                        showLoader : false,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    addRuleClick = () => {
        this.clearForm();
        this.setState({
            popup : 'addRule',
            dialogOpen : true,
        });
    }
    editPolicyRule = (id) => {
        if (id) {
            let apiEndpoint = 'api/settings/workpolicy/get-policy-rule-by-id';
            return httpServices.post(apiEndpoint, { id: id })
                .then(response => {
                    if (response.data) {
                        if (response.data.policy_rule) {
                            let formFields = {
                                rule: response.data.policy_rule.rule_id,
                                sort_order: response.data.policy_rule.sort_order,
                                rule_string: response.data.policy_rule.rule_string,
                            }
                            this.setState({
                                formFields: formFields,
                                dialogOpen: true,
                                popup: 'editRule',
                                editId : id
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });

        }
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        let rule_string = '';
        if (name === 'approve_related') {
            f[name] = evt.target.checked;
        }
        else {
            if (name === 'rule') {
                const rules = this.state.rules;
                rules.forEach(function (arrayItem) {
                    var rule_id = parseInt(arrayItem.id);
                    if (rule_id === parseInt(val)) {
                        rule_string = arrayItem.rule_string;
                        f['rule_string'] = rule_string;
                    }
                });
            }
            f[name] = val;
        }
        this.setState({
            formFields: f,
        });
        if (val) {
            var err = this.state.errors;
            err[name] = '';
            this.setState({errors: err});
        }
    };
    onClose = () => {
        this.setState({
            dialogOpen : false,
            errors: {},
        });
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = '';
        }
        this.setState({
            errors: {},
            formFields: ff,
            editId : 0
        });
    };    
    saveNewRuleClick = () => {
        let id  =this.state.id;
        let action = this.state.popup;
        let data = this.state.formFields;
        data.editId = this.state.editId;
        data.id = id;
        data.action = action;
        let valid = this.validateSubmit(action);
        if (valid) {
            this.setState({
                showLoader : true                
            });
            return httpServices.post('api/settings/workpolicy/savePolicyRule', data)
                .then(response => {
                    if (response.data.message_type === 'success') {
                        this.setState({
                            request : response.data,
                            snackbarMsg : response.data.message,
                            snackbarMsgType : response.data.message_type,
                            snackbarOpen : true,
                            showLoader : false,
                            dialogOpen : false,
                        });
                        this.getPolicyRuleDetails(this.state.id);
                        this.clearForm();
                    } else if (response.data.message_type === 'error'){                        
                            this.setState({
                                snackbarMsg: response.data.message,
                                snackbarMsgType: response.data.message_type,
                                snackbarOpen: true,
                                showLoader: false,
                                dialogOpen: false,
                            });
                        
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }        
    }
    validateSubmit = (action) => {
        let valid = true;
        let errors = {};
        if (!this.state.formFields.rule) {
            valid = false;
            errors['rule'] = "Cannot be empty";
        }
        if (!this.state.formFields.sort_order) {
            valid = false;
            errors['sort_order'] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return valid;
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    closeLoader = () => {
        this.setState({
            showLoader: false,
        });
    }
    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <PolicyViewComponent 
            PolicyRule = {this.state.policyRule}
            Policy = {this.state.policy}
            EditPolicyRule = {this.editPolicyRule}
            AddRuleClick = {this.addRuleClick}
            SaveNewRuleClick = {this.saveNewRuleClick}
            Rules = {this.state.rules}
            HandleOnChange = {this.handleOnChange}
            OnClose = {this.onClose}
            DialogOpen = {this.state.dialogOpen}
            SnackbarOpen = {this.state.snackbarOpen}
            Popup = {this.state.popup}
            Errors = {this.state.errors}
            FormFields = {this.state.formFields}
            SnackbarClose = {this.snackbarClose}
            Id = {this.state.id}
            SnackbarMsg = {this.state.snackbarMsg}
            SnackbarMsgType = {this.state.snackbarMsgType}
            CloseLoader = {this.closeLoader}
            />
            :
            <ErrorPage Message={'You have no permission to view this page'} />
            }
            </>
        )
    }
}
