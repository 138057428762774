import React from 'react';
import * as _R from 'ramda';
import { DialogBoxLarge } from '../common/dialog.large';
import { DialogBoxMedium } from '../common/dialog.medium';
import { DualListBoxSelector } from '../common/dualListBoxSelector';
import { CustomizedSnackbars } from '../common/snackbars';
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { TableHead } from '@mui/material';

const DialogExternalEmail = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                <TextField
                    required
                    id="external_email"
                    name="external_email"
                    label="External Email"
                    value ={props.FormFields.external_email}
                    variant="standard"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.external_email && true}
                    helperText={props.Errors.external_email}
                />
            </Box>
        </div>
    )
}

export const EmailNotificationDetailsComponent = (props) => {
    let Notification = props.EmailNotifications;
    let EventUserNames = props.EventUserNames;
    let EventGroupNames = props.EventGroupNames;
    let Open = props.Open;
      
    return (
        <>
            <React.Fragment>
                <div className={'section-main section-main-header-fixed'}>
                    <div className={'header-fixed'} >
                        <div className={'section-header'}>
                            <div className="col-left">
                                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                                <Typography
                                    component="h2"
                                    className={"section-title"}
                                    variant="button"
                                    display="block"
                                    gutterBottom
                                    color="primary"                                >
                                    Email Notifications
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            <div className={"section-main"}>
                <div className={"section-content"}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width='30%'>Category</TableCell>
                                <TableCell>{Notification.category}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Event</TableCell>
                                <TableCell>{Notification.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>{Notification.description}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Subject</TableCell>
                                <TableCell>{Notification.subject}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Modified By</TableCell>
                                <TableCell>{Notification.modified_by}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Modified Date</TableCell>
                                <TableCell>{Notification.modified_on}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>{Notification.status == 1 ? "Active" : "Inactive"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>     
            </div>
            &nbsp;
            <div className={"listSubHeader"}>
                <Typography
                    component="h2"
                    className={"section-title"}
                    variant="button"
                    display="block"
                    gutterBottom
                    color="primary"
                >
                    Email Recipients
                </Typography>
                <div className={'actions'}>
                    <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.OnAddUser}>Add Users</Button>&nbsp;&nbsp;
                    <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.OnAddGroup}>Add Groups</Button>&nbsp;&nbsp;
                </div>
            </div>
            <div className={"section-main"}>
                <div className={"section-content"}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width='30%'>Users</TableCell>
                                <TableCell>{EventUserNames}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Groups</TableCell>
                                <TableCell>{EventGroupNames}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>     
            </div>
            &nbsp;
            <div className={"listSubHeader"}>
                <Typography
                    component="h2"
                    className={"section-title"}
                    variant="button"
                    display="block"
                    gutterBottom
                    color="primary"
                >
                    External Email Recipients
                </Typography>
                <div className={'actions'}>
                    <Button className={'role-details-filter-btn'} variant="contained" size="small" color="primary" onClick={props.OnAddExternalEmail}>Add External Email</Button>
                </div>
            </div>
            {(props.ExternalEmail.length > 0) &&
            <div className={"section-main"}>
                <div className={"section-content"}>
                    <Table size="small" >
                        <TableBody>
                        { props.ExternalEmail.map((item) =>
                            <TableRow >
                                <TableCell component="th" scope="row">{item.external_email}</TableCell> 
                                <TableCell align="left">
                                            <IconButton className={'action-btn'} edge="end" >
                                                <DeleteIcon fontSize="small" onClick={() => props.DeleteExternalEmail(item.id)} />
                                            </IconButton>                                          
                                </TableCell>
                                </TableRow>
                        )}        
                        </TableBody>
                    </Table>
                </div>     
            </div>
            }
            {(props.DialogOpen) &&
            <DialogBoxLarge
                Open = {props.DialogOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Save"}
                OnSubmit={props.OnSubmit}
                Title={"Add/Remove Users"}
                Loading = {props.ShowLoader}
                Content={
                <div className={'notification-users'}>
                    <DualListBoxSelector
                        Selected = {(typeof props.AssignedUsers !== 'undefined' && typeof props.AssignedUsers !== 'undefined') ? props.AssignedUsers : []}
                        Available = {(typeof props.AllUsers !== 'undefined' && typeof props.AllUsers !== 'undefined') ? props.AllUsers : []}
                        HandleChange = {props.HandleResourceChange}
                        Id = {(typeof props.EventId !== 'undefined' && typeof props.EventId !== 'undefined') ? props.EventId : 0}
                    /></div>
                }
            />
            }
            {(props.DialogGroupOpen) &&
            <DialogBoxLarge
                Open = {props.DialogGroupOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Save"}
                OnSubmit={props.OnGroupSubmit}
                Title={"Add/Remove Groups"}
                Loading = {props.ShowLoader}
                Content={
                <div className={'notification-groups'}>
                    <DualListBoxSelector
                        Selected = {(typeof props.AssignedGroups !== 'undefined' && typeof props.AssignedGroups !== 'undefined') ? props.AssignedGroups : []}
                        Available = {(typeof props.AllGroups !== 'undefined' && typeof props.AllGroups !== 'undefined') ? props.AllGroups : []}
                        HandleChange = {props.HandleGroupChange}
                        Id = {(typeof props.EventId !== 'undefined' && typeof props.EventId !== 'undefined') ? props.EventId : 0}
                    />
                </div>
                }
            />
            }
            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant={props.SnackbarType}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />
            {(props.DialogExternalOpen) &&
                <DialogBoxMedium
                Open = {props.DialogExternalOpen}
                Cancel = {props.PopupClose}
                ButtonName={"Save"}
                OnSubmit={props.OnExternalEmailSubmit}
                Title={"Add External Email"}
                Loading = {props.ShowLoader}
                Content={
                   <DialogExternalEmail
                        HandleOnChange = {props.HandleOnChange}
                        FormFields = {props.FormFields}
                        Errors = {props.Errors}
                   />
                 }
                />
            }

        </React.Fragment>
        </>
    )
};
