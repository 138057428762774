import React from 'react';
import {ScheduledPaymentComponent} from './ScheduledPayment.Component';
import {httpServices}  from '../../../../services/httpServices';

const formFields = {
    'component': null,
    'component_id': null,
    'sch_amount': null,
    'paycheck': null,
    'edit_id': 0
}

export class ScheduledPayment extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            intervals:[],
            errors: {},
            components: [],
            open:false,
            selectedDate: null,
            isEditable: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'component_id') {
            f.component = evt.currentTarget.dataset.component;
        }
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

    handleValidation() {
        let formIsValid = false;
        let errors = {};
        if (!this.state.selectedDate) {
            errors["paycheck"] = "Cannot be empty";
        }
        if (!this.state.formFields.component_id) {
            errors["component_id"] = "Cannot be empty";
        }
        if (!this.state.formFields.sch_amount) {
            errors["sch_amount"] = "Cannot be empty";
        }
        else {
            var amt = this.state.formFields.sch_amount;
            amt = amt.replace(/([^0-9\\.])/i, '');
            let re = /,/g
            amt = amt.replace(re,'');
            if (!amt) {
                errors["sch_amount"] = "Cannot be empty";
            }
            else if (amt && !amt.match(/^[+-]?\d+(\.\d+)?$/)) {
                errors["sch_amount"] = "amount is invalid";
            }
        }
        if (Object.keys(errors).length === 0) {
            errors = {};
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    saveScheduledComponent =() => {
        if (this.handleValidation()) {
            var editID = this.state.edit_id;
            let payDate = null;
            if (this.state.selectedDate) { 
                var dateobj = new Date(this.state.selectedDate);
                let year = dateobj.getFullYear();
                let month = dateobj.getMonth() + 1;
                if (month < 10) {
                    month = '0'+month;
                }
                let cdate = dateobj.getDate();
                if (cdate < 10) {
                    cdate = '0'+cdate;
                }
                payDate = year+'-'+month+'-'+cdate;
            }
            const data = {
                'staff_id': this.props.StaffID,
                'component_id': this.state.formFields.component_id,
                'amount': this.state.formFields.sch_amount,
                'paycheck_date': payDate,
                'edit_id': editID,
            }
            return httpServices.post('api/compensation/save_scheduled_payment', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.status) {
                            this.setState({components : response.data.scheduled_components});
                            if (editID) {
                                this.setState({isEditable: false});
                                alert("Scheduled Component details has been updated successfully");
                            }
                            else {
                                alert("Scheduled Component details has been added successfully");
                            }
                            this.onClose();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }

    onAddComponent = () => {
        this.setState({
            open: true,
            edit_id: 0
        });
    }

    onClose = () =>{
        let formFields = {
            'year': null,
            'component': null,
            'component_id': null,
            'sch_amount': null,
            'paycheck': null,
            'edit_id': 0
        }

        this.setState({
            open: false,
            edit_id: 0,
            payperiod_id: null,
            errors:{},
            formFields : formFields,
            selectedDate: null,
            isEditable:false
        });
    }

    onUpdate = (id) => {
        return httpServices.post('api/compensation/getScheduledComponent', {'id': id, 'staff_id' : this.props.StaffID})
            .then(response => {
                if (response.data) {                    
                    var date = response.data.component.paycheck_date.split("-");
                    var formatted_date = new Date(date[0],date[1]-1,date[2]);
                    this.setState({
                        formFields: response.data.component, 
                        open: true, 
                        edit_id:id,
                        id: id,
                        title: response.data.component.title,
                        selectedDate: formatted_date,
                        isEditable: true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onDelete = (rowId) => {
        return httpServices.post('api/compensation/delete_scheduled_payment', {'id': rowId, 'staff_id': this.props.StaffID})
        .then(response => {
            if (!response.data.error) {
                this.setState({components : response.data.scheduled_components});
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate : date,
        });
    };

    render(){
        return(
            <ScheduledPaymentComponent
                    Components={this.state.components.length>0?this.state.components:this.props.ScheduledComponents}
                    ConfigComponents={this.props.ConfigComponents}
                    SaveScheduledComponent={this.saveScheduledComponent.bind(this)}
                    HandleOnChange={this.handleOnChange}
                    HandleOnYearChange={this.HandleOnYearChange}
                    FormFields={this.state.formFields} 
                    Intervals={this.state.intervals}
                    Open={this.state.open}
                    Close={this.onClose.bind(this)}
                    AddComponent={this.onAddComponent.bind(this)}
                    OnDelete={this.onDelete.bind(this)}
                    HandleUpdate={this.onUpdate.bind(this)}
                    Errors={this.state.errors}
                    Edit={this.props.Edit}
                    isEditable = {this.state.isEditable}
                    Delete={this.props.Delete}
                    HandleDateChange = {this.handleDateChange.bind(this)}
                    SelectedDate={this.state.selectedDate}
                />
        )
    }
}
