import React from 'react';
import * as _R from 'ramda';
import moment from 'moment';
import {httpServices}  from '../../../services/httpServices';

import * as _ls from '../../common/ls';
import { TimeEntryEditComponent } from './TimeEntryEditComponent';

let tagInputs = {
    'org': null,
    'contract': null,
    'lob': null,
}
let initial_project_id = 0;
let initial_tags = [];
export class TimeEntryEdit  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeSliceEditData : {},
            formInputFields : {},
            wlc_info : {},
            wlcFlag : false,
            showReasonForEdit : false,
            timeEntryEditErrors : {resolve : 0},
            tagInputs: tagInputs,
            new_tag_records : [],
            deleted_tag_records:[],
            editErrorMsg : '',
            errors : [],
            dialogOpen : false,
            minDate : new Date(),
            non_confirmity : 0,
            skip_resolve : 0,
            project_task_inputs : [],
            task_name : '',
            taskTreeViewState : false,
            buttonLoader: false,
            showLoader : false,
            project : {},
            requested_locations : [],
            default_location : 0,
            selected_project_locations :[],
            timeSliceEditDate: '',
            timeentryPeriods : [],
            enableLocation : ''
        }
        this.getTimeEntryDetails = this.getTimeEntryDetails.bind(this);
        this.handleTagInputChange = this.handleTagInputChange.bind(this);
        this.handleTimeEntryDateChange = this.handleTimeEntryDateChange.bind(this);
        this.handleTimeEntryChange = this.handleTimeEntryChange.bind(this);
        this.onTagDelete = this.onTagDelete.bind(this); 
        this.onAddTag = this.onAddTag.bind(this);
        this.handleTasksOnChange = this.handleTasksOnChange.bind(this);
        this.updateTimeEntry = this.updateTimeEntry.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.skipResolveNow = this.skipResolveNow.bind(this);
        this.handleTaskTreeChange = this.handleTaskTreeChange.bind(this);
        this.toggleTreeView = this.toggleTreeView.bind(this);
    };
    componentDidUpdate(prevProps) {
        if (prevProps.randomNumber !== this.props.randomNumber) {
            this.resetTags();
            this.getTimeEntryDetails();
        } 
    }
    getTimeEntryDetails = () => {
        this.setState({showLoader :true});
        let id = parseInt(this.props.timeSliceId);
        if (id > 0 ) {
            return httpServices.post('api/timesheet/timeentryedit', {id : id, action:'get'})
                .then(response => {
                    if (response.data) {
                        let res = response.data;
                        let timeslice_date = res.timeslice.timeslice_date;
                        let date_obj = new Date();
                        if (timeslice_date) {
                            let date_arr = timeslice_date.split("-");
                            let month = parseInt(date_arr[1]) - 1;
                            date_obj = new Date(date_arr[0],month,date_arr[2]);
                        }
                        initial_project_id = res.timeslice.project_id;
                        initial_tags = response.data.tags;
                        let formInputFields = {
                            date : timeslice_date,
                            project_id : res.timeslice.project_id,
                            task : res.timeslice.timeslice_task,
                            description : res.timeslice.timeslice_log,
                            hours : res.timeslice.timeslice_hours,
                            location : res.timeslice.location,
                            default_location : res.default_location,
                            date_obj : date_obj,
                            client_timesheet : res.timeslice.client_timesheet,
                            resolve : parseInt(res.timeslice.non_confirmity) ? 0 : null,
                            task_name : res.timeslice.task_name,
                            task_path: '',
                            task_description: res.timeslice.task_description ? res.timeslice.task_description : ''
                        }                        
                        if (res.timeslice.task_path && res.timeslice.client_name) {
                            formInputFields.project_path = res.timeslice.client_name+" / "+res.timeslice.task_path+" / "+res.timeslice.task_name;
                        }
                        let tempTagTypes = [];
                        if (response.data.tag_types && response.data.tag_types !== "" && response.data.all_tags) {
                            tempTagTypes = this.getTagTypes(response.data.tag_types, response.data.all_tags);
                            response.data.tagList = tempTagTypes;
                        }
                        // Handle wlc checkbox.
                        let wlc_info = false;
                        let wlc_flag = false;
                        if (response.data.work_location_change['request'])
                        {
                            let wlc_projects = response.data.work_location_change['projects'];
                            let project_id = res.timeslice.project_id;
                            wlc_info = wlc_projects.includes(project_id);
                        }
                        if (wlc_info) {
                            wlc_flag = true;
                        }
                        // Handle reason for edit input field
                        let dateobj_arr = res.timeslice.timeslice_date.split('-');
                        var dateobj = new Date(dateobj_arr[0], parseInt(dateobj_arr[1]) - 1, dateobj_arr[2]);
                        let current_date = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
                        let showReasonForEdit = false;
                        if (dateobj < current_date) {
                            showReasonForEdit = true;
                        }
                        if (response.data.projects) {
                            let validated_projects = _ls.validateProjects(timeslice_date,'edit',response.data.projects);
                            if (validated_projects) {
                                response.data.projects = validated_projects;
                            }
                        }
                        // Set minimum date for calendar.
                        let min_date = '';
                        if (response.data.user_date_joined && response.data.user_date_joined !== ""){
                            min_date = response.data.user_date_joined;
                            let min_date_arr = min_date.split("-");
                            min_date = min_date_arr[1]+'/'+min_date_arr[2]+'/'+min_date_arr[0];
                            min_date = new Date(min_date);
                        } else {
                            min_date = new Date();
                        }
                        let selected_project_locations = [];
                        response.data.work_location_change.locations.forEach((lc_value, lc_key) => {                          
                            if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(res.timeslice.project_id)) {
                                 selected_project_locations.push(lc_value);
                            }
                        });
                        if (formInputFields['location'] && !selected_project_locations.filter(l => l.location_id == formInputFields['location']).length) {
                            formInputFields['location'] = null;
                        }
                        this.setState({
                            timeSliceEditData : response.data,
                            formInputFields : formInputFields,
                            wlc_info : response.data.work_location_change,
                            wlcFlag : wlc_flag,
                            showReasonForEdit : showReasonForEdit,
                            minDate : min_date,
                            non_confirmity : res.timeslice.non_confirmity,
                            project_task_inputs : response.data.task_inputs,
                            task_name : res.timeslice.task_name,
                            showLoader : false,
                            project : response.data.project,
                            selected_project_locations : selected_project_locations,
                            timeSliceEditDate : timeslice_date,
                            timeentryPeriods: response.data.timeentry_periods,
                            enableLocation : response.data.timeslice.enable_location,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    /* On tags on change */
    handleTagInputChange = (evt, value) => {
        let name = evt.target.name;
        let tagInputs = this.state.tagInputs;
        tagInputs[name] = evt.target.value;
        if (evt.target.value !== '') {
            this.setState({tagInputs: tagInputs});
        }
    }
    /* Handle Time entry date change*/
    handleTimeEntryDateChange = (date) => {
        let dateobj = moment(date);
        let editDateObj = moment(this.state.timeSliceEditDate);
        let formFields = this.state.formInputFields;
        formFields.date = dateobj.format('YYYY-MM-DD');
        formFields.date_obj = dateobj;
        let showReasonForEdit = false;
        if (dateobj.isBefore(moment(), 'day') || editDateObj.isBefore(moment(), 'day')) {
          showReasonForEdit = true;
        }
        this.setState({
            formInputFields: formFields,
            showReasonForEdit : showReasonForEdit,
        }, function(){
            this.getTimesheetByDate();
        });
    };
    handleTimeEntryChange = (evt) => {
        let name = evt.target.name;
        let value = '';
        if (name === 'resolve') {
            value = evt.target.checked ? 1 : 0;
        } else {
            value = evt.target.value;
        }
        let ff = this.state.formInputFields;
        ff[name] = value;
        this.setState({
            formInputFields : ff,
        });
    }
    onTagDelete = (event, value) => {
        let tagRowID = event.currentTarget.dataset.tag_row_id;
        let deleted_tag_records = this.state.deleted_tag_records;
        let new_tag_records = this.state.new_tag_records;
        if (tagRowID !=='undefined'){
            let new_tag = tagRowID.search('new');
            if (new_tag === -1) {
                deleted_tag_records.push(tagRowID);
            } else {
                let t_index = new_tag_records.findIndex(x => x.id === tagRowID);
                if (t_index >= 0 ) {
                    new_tag_records.splice(t_index, 1);
                }
            }
            let formData = this.state.timeSliceEditData;
            if (_R.type(formData.tags) !== 'Undefined') {
                var index = formData.tags.findIndex(x => x.id === tagRowID);
            }
            formData.tags.splice(index, 1);
            this.setState({
                timeSliceEditData: formData,
                deleted_tag_records : deleted_tag_records,
                new_tag_records : new_tag_records
            });
        }
    }
    /* Save New Tag */
    onAddTag = () => {
        let tag_info = this.getTagName(this.state.tagInputs);
        let tags = this.state.timeSliceEditData.tags;
        let timeEntryEditErrors = {};
        if (tag_info.tag_ids.length > 0) {
            // Tag validation
            let tag_name = tag_info.tag_name.trim();
            let tag_found = false;
            tags.forEach(function(item){
                if (item.tag_name.trim() === tag_name) {
                    tag_found = true;
                }
            })
            if (!tag_found) {
                let tag_row_obj = {
                    id : 'new_' + Math.floor((Math.random() * 10000) ),
                    tag_name : tag_info.tag_name,
                    tag_ids : tag_info.tag_ids,
                };
                let new_tags = this.state.new_tag_records;
                new_tags.push(tag_row_obj);
                let formData = this.state.timeSliceEditData;
                if (_R.type(formData.tags) !== 'Undefined') {
                    formData.tags.push(tag_row_obj) ;
                }
                this.setState({
                    timeSliceEditData: formData,
                    new_tag_record : new_tags,
                    tagInputs : {},
                });
            } else {
                timeEntryEditErrors['tags'] = "Tag already exists";
            }
        }
        this.setState({timeEntryEditErrors: timeEntryEditErrors});
    }
    handleTasksOnChange = (evt, cNode) => {
        let project_id = cNode && cNode.project_id ? cNode.project_id : null;
        let task_id = cNode && cNode.task_id ? cNode.task_id : null;
        let task_name = cNode && cNode.task_name ? cNode.task_name : '';
        // Handle tags
        let editData = this.state.timeSliceEditData;
        editData.tags = [];
        this.setState({
            timeSliceEditData : editData,
            new_tag_record : [],
            deleted_tag_records : [],
        });
        if (parseInt(initial_project_id) === parseInt(project_id)) {
            editData.tags = initial_tags;
            this.setState({
                timeSliceEditData : editData,
            });
        }
        let postData = {
            'project-id': project_id,
            'date': this.state.formInputFields.date
        };
        // Handle wlc checkbox.
        let wlc = false;
        if (this.state.wlc_info['request'])
        {
            let wlc_projects = this.state.wlc_info['projects'];
            wlc = wlc_projects.includes(project_id);
        }
        if (wlc) {
            this.setState({wlcFlag : true});
        } else {
            this.setState({wlcFlag : false});
        }
        if (project_id) {
            return httpServices.post('api/timesheet/get-tasks-by-user', postData)
                .then(response => {
                    if (response.data) {
                        
                        let project_details = response.data.project_details;
                        let formData = this.state.timeSliceEditData;
                        let tempTagTypes = [];
                        if (response.data.tag_types && response.data.tag_types !== "" && formData.all_tags) {
                            tempTagTypes = this.getTagTypes(response.data.tag_types, formData.all_tags);
                            var tagList = tempTagTypes;
                        }
                        /*if (_R.type(formData.tasks) === 'Undefined') {
                            formData.tasks = [];
                        }
                        else {
                            formData.tasks = response.data.tasks;
                        }*/
                        //let tasks = response.data.tasks;
                        formData.tagList = tagList;
                        formData.tag_types = response.data.tag_types;
                        let formFields = this.state.formInputFields;
                        /*if (tasks && tasks.length === 1) {
                            formFields.task = tasks[0].task_id;
                        } else {
                            formFields.task = '';
                        }*/
                        formFields.project_id = project_id;
                        formFields.task = task_id;
                        formFields.task_name = task_name;
                        formFields.task_code = cNode.code;
                        formFields.client_timesheet = cNode.client_timesheet;
                        formFields.project_path = '';
                        if (cNode && cNode.group_key === 'Recent' && cNode.task_path) {
                            formFields.project_path = cNode.task_path;
                        } else if(cNode.task_path) {
                            formFields.project_path = cNode.client_name+" / "+cNode.task_path;
                        }
                        formFields.task_description = cNode && cNode.task_description ? cNode.task_description : '';
                        let projects = this.state.timeSliceEditData.projects;
                        if (project_details && project_details.client_timesheet) {
                            formFields.client_timesheet = project_details.client_timesheet;
                        }
                        let selected_project_locations = [];
                        response.data.work_location_change.locations.forEach((lc_value, lc_key) => {
                            if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(project_id)) {
                                selected_project_locations.push(lc_value);
                            }
                        });
                        this.setState({
                            timeSliceEditData: formData,
                            formInputFields: formFields,
                            task_name: task_name,
                            project : project_details,
                            selected_project_locations : selected_project_locations,
                            enableLocation : cNode.enable_location,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        } else {
            let formFields = this.state.formInputFields;
            formFields.task = '';
            formFields.project_id = project_id;
            formFields.client_timesheet = 0;
            formFields.task_name = '';
            formFields.project_path = '';
            formFields.task_description = '';
            this.setState({
                formInputFields:formFields,
                task_name: '',
            });
        }
    }
    handleTaskTreeChange = (cNode) => (evt)=>{
        if (cNode.task_id) {
            let project_id = cNode && cNode.project_id ? cNode.project_id : null;
            let task_id = cNode && cNode.task_id ? cNode.task_id : null;
            let task_name = cNode && cNode.task_name ? cNode.task_name : '';
            // Handle tags
            let editData = this.state.timeSliceEditData;
            editData.tags = [];
            this.setState({
                timeSliceEditData : editData,
                new_tag_record : [],
                deleted_tag_records : [],
                taskTreeViewState : false,
            });
            if (parseInt(initial_project_id) === parseInt(project_id)) {
                editData.tags = initial_tags;
                this.setState({
                    timeSliceEditData : editData,
                });
            }
            let postData = {
                'project-id': project_id,
                'date': this.state.formInputFields.date
            };
            // Handle wlc checkbox.
            let wlc = false;
            if (this.state.wlc_info['request'])
            {
                let wlc_projects = this.state.wlc_info['projects'];
                wlc = wlc_projects.includes(project_id);
            }
            if (wlc) {
                this.setState({wlcFlag : true});
            } else {
                this.setState({wlcFlag : false});
            }
            if (project_id) {
                return httpServices.post('api/timesheet/get-tasks-by-user', postData)
                    .then(response => {
                        if (response.data) {
                            let project_details = response.data.project_details;
                            let formData = this.state.timeSliceEditData;
                            let tempTagTypes = [];
                            if (response.data.tag_types && response.data.tag_types !== "" && formData.all_tags) {
                                tempTagTypes = this.getTagTypes(response.data.tag_types, formData.all_tags);
                                var tagList = tempTagTypes;
                            }
                            formData.tagList = tagList;
                            formData.tag_types = response.data.tag_types;
                            let formFields = this.state.formInputFields;
                            formFields.project_id = project_id;
                            formFields.task = task_id;
                            formFields.task_name = task_name;
                            formFields.client_timesheet = cNode.client_timesheet;
                            formFields.project_path = '';
                            if (cNode.task_path && cNode.client_name) {
                                formFields.project_path = cNode.client_name+" / "+cNode.task_path+" / "+cNode.task_name;
                            }
                            formFields.task_description = cNode && cNode.task_description ? cNode.task_description : '';
                            let projects = this.state.timeSliceEditData.projects;
                            if (project_details && project_details.client_timesheet) {
                                formFields.client_timesheet = project_details.client_timesheet;
                            }
                            let project_locations = response.data.project_locations;
                            this.setState({
                                timeSliceEditData: formData,
                                formInputFields:formFields,
                                task_name: task_name,
                                project : project_details,
                                project_locations : project_locations 
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            } 
        }
    }
    updateTimeEntry = () => {
        this.setState({ buttonLoader : true});
        this.onAddTag();
        let id = parseInt(this.props.timeSliceId);
        let data = {};
        data = this.state.formInputFields;
        if (data.hasOwnProperty('new_tags')) {
            delete data.new_tags;
        }
        if (data.hasOwnProperty('deleted_tags')) {
            delete data.deleted_tags;
        }
        //let tags = this.state.timeSliceEditData.tags;
        let deleted_tags = [];
        deleted_tags = this.state.deleted_tag_records;
        if (deleted_tags) {
            data['deleted_tags'] = JSON.stringify(deleted_tags);
        }
        if (this.state.timeSliceEditData.tag_types && this.state.timeSliceEditData.tag_types !== "" && (data.project_id === initial_project_id)) { 
            let new_tags = this.getNewTags();
            if (new_tags.length > 0) {
                data['new_tags'] = JSON.stringify(new_tags);
            }
        } else if (this.state.timeSliceEditData.tag_types && this.state.timeSliceEditData.tag_types !== "" && (data.project_id !== initial_project_id)){
            // Delete the existing tags
            let deleted_tag_ids = this.getDeletedTagIds();
            if (deleted_tag_ids.length > 0) {
                data['deleted_tags'] = JSON.stringify(deleted_tag_ids);
            }
            // Add tags
            let new_tags = this.getNewTags();
            if (new_tags.length > 0) {
                data['new_tags'] = JSON.stringify(new_tags);
            }
        } 
        else {
            // Delete the existing tags
            let deleted_tag_ids = this.getDeletedTagIds();
            if (deleted_tag_ids.length > 0) {
                data['deleted_tags'] = JSON.stringify(deleted_tag_ids);
            }
        }
        if (!this.state.wlcFlag) {
            data.wlc_info = '0';
            data.location = data.default_location;
        }
        data.enable_location = this.state.enableLocation;     
        if (this.validate(data)) {
            return httpServices.post('api/timesheet/timeentryedit', {id : id, action:'update', data: data })
                .then(response => {
                    if (response.data) {
                        if (!response.data.errors) {
                            if (response.data.status) {
                                this.setState({
                                    buttonLoader: false,
                                });
                                this.clearFormClose();
                                this.props.showEditSuccess();
                                this.props.getTimesheetDetails(this.props.timeSheetId); // Update Timesheet view on edit success
                                this.props.UpdateTimeEntryOnEdit();
                            } else {
                                if (typeof response.data.input_type_errors !== 'undefined' && response.data.input_type_errors.length) { // Handling post validation errors
                                    this.setState({
                                        timeEntryEditErrors: response.data.input_type_errors,
                                        buttonLoader: false,
                                    });
                                } else {
                                    this.setState({
                                        editErrorMsg: response.data.err_msg ? response.data.err_msg : '',
                                        dialogOpen: true,
                                        buttonLoader: false,
                                    });
                                }
                            }
                        } else {
                            this.setState({
                                errors: response.data.errors,
                                buttonLoader: false,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
        else{
            this.setState({
                buttonLoader : false,
            });  
        } 
    }
    validate = (data) => {
        this.setState({timeEntryEditErrors:{}});
        let formIsValid = true;
        let timeEntryEditErrors = {};
        if (data) {
            //valdiate project/task
            if (!data.project_id) {
                timeEntryEditErrors['project'] = 'Task cannot be empty';
                formIsValid = false;
            }
            //validate description
            if (!data.description.trim()) {
                timeEntryEditErrors['description'] = 'Description cannot be empty';
                formIsValid = false;
            }
            //validate hours
            if (!data.hours) {
                timeEntryEditErrors['hours'] = 'Hour cannot be empty';
                formIsValid = false;
            }
            if (data.enable_location && (_R.isNil(data['location']) || data['location'] === '')){
                timeEntryEditErrors['location'] = 'Location cannot be empty';
                formIsValid = false;
            }
            //validate reason for edit
            if (this.state.showReasonForEdit) {
                if(!data.reason) {                             
                    timeEntryEditErrors['reason'] = 'Please enter reason for edit';
                    formIsValid = false;      
                }
                else if(data.reason.trim() === "")
                {
                    timeEntryEditErrors['reason'] = 'Please enter reason for edit';
                    formIsValid = false;
                }
            }
            if (parseInt(this.state.non_confirmity)) {
                if (!this.state.formInputFields.resolve && !this.state.skip_resolve) {
                    timeEntryEditErrors['resolve'] = true;
                    formIsValid = false;
                }
            }
        }
        // Hours details validation
        if (formIsValid) {
            var isValidHour = true;
            let val = data.hours;
            let project = this.state.project;
            let defaultInc = this.state.timeSliceEditData.minimum_increment;
            let projectMinimumInc = project.minimum_increment;
            let project_id = data.project_id;
            let re = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
            let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
            let re2 = new RegExp("^(\\.)[0-9]{1,2}");
            if(re2.test(val)) {
                val = '0'+val;
            }
            if(re1.test(val)) {
                let hm = val.split(':');
                let fr = hm[1]/60;
                val = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
            }
            if(!(re.test(val) || re2.test(val))) {
                isValidHour = false;
                timeEntryEditErrors['hours'] = 'Hours is invalid';
            }
            else {
                let minimumInc = null;
                if (project_id!==0) {
                    minimumInc = projectMinimumInc;
                }
                if (minimumInc === null) {
                    minimumInc = defaultInc;
                }
                if (minimumInc !== 0) {
                    let min = minimumInc*100;
                    let frp = val%1;
                    frp = frp.toFixed(2)*100;
                    if(!(frp%min===0)) {
                        isValidHour = false;
                        timeEntryEditErrors['hours'] = 'Hours should be entered as a multiple of '+minimumInc+' hours';
                    }
                }
                if(!(val>=0 && val<=24)) {
                    timeEntryEditErrors['hours'] = 'Hours should not exceed 24.00';
                    isValidHour = false;
                }
            }
        }
        // Validate hours end.
        this.setState({timeEntryEditErrors: timeEntryEditErrors});
        if (formIsValid && isValidHour) {
            return true;
        } else {
            return false;
        }
    }
    getTagTypes = (tag_ids,all_tags) => {
        let tag_types = tag_ids.split(',');
        let tags = [];
        tags = all_tags.reduce(function (r, a) {
            if (tag_types.some(t => t == a.tag_type)) { 
                r[a.tag_type_name] = r[a.tag_type_name] || [];
                r[a.tag_type_name].push(a);
            }
            return r;
        }, Object.create(null));
        return tags;
    }
    getTagName = (tagInputs) => {
        let taginput_ids = [];
        let all_tags = this.state.timeSliceEditData.all_tags;
        let tag_info = {};
        let tag_name = '';
        for (var key in tagInputs) {
            if (tagInputs.hasOwnProperty(key)) {
                if (tagInputs[key]) {
                    taginput_ids.push(tagInputs[key]);
                    for ( let i = 0; i < all_tags.length; i++) {
                        if (all_tags[i].id === tagInputs[key]) {
                            if (tag_name === '') {
                                tag_name = all_tags[i].tag_name;
                            } else {
                                tag_name = tag_name +'-'+ all_tags[i].tag_name;
                            }
                        }
                    }
                }
            }
        }
        tag_info['tag_name'] = tag_name;
        tag_info['tag_ids'] = taginput_ids;
        return tag_info;
    }
    getTimesheetByDate = () => {
        let date = this.state.formInputFields.date;
        if (date) {
            let data = {
                date: date,
                project_id: this.state.formInputFields.project_id,
            };
            return httpServices.post('api/timesheet/get-timesheet-by-date', data)
                .then(response => {
                    if (response.data) {
                        let selected_project_locations = [];
                        response.data.work_location_change.locations.forEach((lc_value, lc_key) => {
                            if ((typeof lc_value['project_id'] != 'undefined') && lc_value['project_id'].includes(this.state.formInputFields.project_id)) {
                                 selected_project_locations.push(lc_value);
                            }
                        });
                        this.setState({wlc_info:response.data.work_location_change,
                            requested_locations : response.data.work_location_change.locations,
                            selected_project_locations : selected_project_locations
                        });
                        // Handle wlc checkbox.
                        let wlc_info = false;
                        let wlc_flag = false;
                        let edit_data = this.state.timeSliceEditData;
                        let validated_projects = [];
                        if (response.data.projects) {
                            validated_projects = _ls.validateProjects(date,'edit',response.data.projects);
                        }
                        edit_data.projects = validated_projects;
                        edit_data.tasks = response.data.tasks;
                        let form_inputs = this.state.formInputFields;
                        if (response.data.work_location_change['request'])
                        {
                            let wlc_projects = response.data.work_location_change['projects'];
                            let project_id = this.state.formInputFields.project_id;
                            wlc_info = wlc_projects.includes(project_id);
                        }
                        if (wlc_info) {
                            wlc_flag = true;
                        }
                        let reset_task = false;
                        let active_tasks = (response.data.task_inputs && response.data.task_inputs.length) ? response.data.task_inputs : [];
                        if (active_tasks.length) {
                            let task_index = active_tasks.findIndex(o => o.task_id === form_inputs.task);
                            if (task_index === -1) {
                                reset_task = true;
                            }
                        } else {
                            reset_task = true;
                        }
                        let task_name = form_inputs.task_name;
                        if (reset_task) {
                            form_inputs.project_id = null;
                            form_inputs.task = null;
                            form_inputs.client_timesheet = 0;
                            task_name = '';
                        }
                        this.setState({
                            wlc_info : response.data.work_location_change,
                            wlcFlag : wlc_flag,
                            timeSliceEditData : edit_data,
                            formInputFields : form_inputs,
                            project_task_inputs : response.data.task_inputs,
                            task_name : task_name,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        } else {
            alert("Please select a valid Date");
        }
    }
    dialogClose = () => {
        this.setState({
            dialogOpen : false,
        });
    }

    clearFormClose = () =>{
        this.resetTags();
        this.clearForm();
        this.props.Cancel();
    }

    clearForm = () => {
        this.setState({
            timeEntryEditErrors: {},
            tagInputs : {},
            skip_resolve : 0,
            formInputFields : {},
            task_name : '',
        });
    };

    getNewTags = () => {
        let new_tags = this.state.new_tag_records;
        let new_tags_arr = [];
        if (new_tags.length > 0) {
            new_tags.forEach(t => {
                if (t.tag_ids) {
                    new_tags_arr.push(t.tag_ids);
                }
            });
        }
        return new_tags_arr;
    }

    getDeletedTagIds = () => {
        let deleted_tags = this.state.deleted_tag_records;
        let existing_tag_ids = [];
        let concat_array = [];
        if (initial_tags.length > 0 ){
            existing_tag_ids = initial_tags.map(el => el.id);
        }
        if (existing_tag_ids || deleted_tags) {
            concat_array = existing_tag_ids.concat(deleted_tags);
        }
        return concat_array;
    }

    resetTags = () => {
        this.setState({
            new_tag_records : [],
            deleted_tag_records : [],
        });
    };

    skipResolveNow = (skip_flag) => {
        let time_entry_err = this.state.timeEntryEditErrors;
        if (skip_flag) {
            this.setState({ skip_resolve : 1}, function(){
                this.updateTimeEntry();
            });
        } else {
            time_entry_err['resolve'] = 0;
            this.setState({
                TimeEntryEditErros : time_entry_err
            });
        }
    };
    toggleTreeView = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        this.setState({taskTreeViewState : open});
    };
    render(){
        return(
            <TimeEntryEditComponent
                timeSliceEditData = {this.state.timeSliceEditData}
                formInputFields = {this.state.formInputFields}
                WlcFlag = {this.state.wlcFlag}
                timeSliceId = {this.props.timeSliceId}
                showReasonForEdit = {this.state.showReasonForEdit}
                updateTimeEntry = {this.updateTimeEntry}
                className = {'editDialog'}
                Open= { this.props.Open }
                Cancel = { this.props.Cancel }
                handleTagInputChange = {this.handleTagInputChange}
                handleTimeEntryDateChange = {this.handleTimeEntryDateChange}
                handleTimeEntryChange = {this.handleTimeEntryChange}
                onTagDelete = {this.onTagDelete}
                onAddTag = {this.onAddTag}
                HandleTasksOnChange = {this.handleTasksOnChange}
                DialogOpen = {this.state.dialogOpen}
                DialogClose = {this.dialogClose}
                ClearFormClose = {this.clearFormClose.bind(this)}
                EditErrorMsg = {this.state.editErrorMsg}
                Errors = {this.state.errors}
                TimeEntryEditErrors = {this.state.timeEntryEditErrors}
                TagInputs = {this.state.tagInputs}
                minDate = {this.state.minDate}
                NonConfirmity = {this.state.non_confirmity}
                SkipResolveNow = {this.skipResolveNow}
                ProjectTaskInputs = {this.state.project_task_inputs}
                HandleTaskTreeChange = {this.handleTaskTreeChange}
                TaskName = {this.state.task_name}
                TaskTreeViewState = {this.state.taskTreeViewState}
                ToggleTreeView = {this.toggleTreeView}
                ButtonLoader={this.state.buttonLoader}
                showLoader={this.state.showLoader}
                SelectedProjectLocations = {this.state.selected_project_locations}
                DefaultLocation = {this.state.default_location}
                TimeentryPeriods = {this.state.timeentryPeriods}
                EnableLocation = {this.state.enableLocation}
            />
        )
    }
};
