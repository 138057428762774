import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import * as _R from 'ramda';
import { AddTimeOff } from './TimeOffAdd.Container';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import { Comment } from '../Common/Comment';
import { ChangeHistory } from '../Common/ChangeHistory';
import { RelatedTimeEntries } from '../Common/RelatedTimeEntries';
import AttachmentIcon from '@mui/icons-material/Attachment';
import * as _cs from '../../common/common';
import { formatName } from '../../common/common';

const API_URL = process.env.REACT_APP_API_URL;
const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .root': {
    width: '100%',
  },
  '& .heading': {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}));
const DialogApprove = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you want to approve request(s)?
                </Typography>
                {props.IsParent &&
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormControl required error={props.Errors.approve_related && true} component="fieldset">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(typeof props.FormFields.approve_related !== 'undefined') ? props.FormFields.approve_related : false}
                                        value={(typeof props.FormFields.approve_related !== 'undefined') ? props.FormFields.approve_related : null}
                                        id="approve_related"
                                        name="approve_related"
                                        onChange={props.HandleOnChange}
                                    />
                                }
                                label="Approve all related time off requests."
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                }
            </Box>
            <Box className={'mt-1'}>
                <TextField
                    id="approve_comment"
                    name="approve_comment"
                    label="Comments (optional)"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.approve_comment && true}
                    helperText={props.Errors.approve_comment}
                />
            </Box>
        </div>
    )
}
const DialogUnApprove = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you want to unapprove request(s)?
                </Typography>
            </Box>
            <Box className={'mt-1'}>
                <TextField
                    required
                    id="comment"
                    name="comment"
                    label="Comment"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.comment && true}
                    helperText={props.Errors.comment}
                />
            </Box>
        </div>
    )
}
const DialogReject = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                {props.IsParent &&
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormControl required error={props.Errors.req_related && true} component="fieldset">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(typeof props.FormFields.req_related !== 'undefined') ? props.FormFields.req_related : false}
                                        value={(typeof props.FormFields.req_related !== 'undefined') ? props.FormFields.req_related : null}
                                        id="req_related"
                                        name="req_related"
                                        onChange={props.HandleOnChange}
                                    />
                                }
                                label="Reject all related time off requests."
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                }
            </Box>
            <Box className={'mt-1'}>
                <TextField
                    required
                    id="comment"
                    name="comment"
                    label="Reason for Rejection"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.comment && true}
                    helperText={props.Errors.comment}
                />
            </Box>
        </div>
    )
}
const DialogComment = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                <TextField
                    required
                    id="comment"
                    name="comment"
                    label="Comment"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.comment && true}
                    helperText={props.Errors.comment}
                />
            </Box>
        </div>
    )
}
const DialogCancel = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you want to cancel request(s)?
                </Typography>
            </Box>
            <Box className={'mt-1'}>
                <TextField
                    id="comment"
                    name="comment"
                    label="Comment"
                    multiline
                    variant="outlined"
                    fullWidth
                    onChange={props.HandleOnChange}
                    error={props.Errors.comment && true}
                    helperText={props.Errors.comment}
                />
            </Box>
        </div>
    )
}
export const TimeOffViewComponent = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    let requests = _R.type(props.Request) !== 'Undefined' ? props.Request : [];
    let request_coff_dates = _R.type(props.Request) !== 'Undefined' ? props.Request.coff_dates : '';
    let request = _R.type(props.Request) !== 'Undefined' ? props.Request.request : [];
    let recipients =  _R.type(props.Request) !== 'Undefined' ? Object.values(props.Request.recipients) : [];
    let reviewers = _R.type(props.Request) !== 'Undefined' ? Object.values(props.Request.reviewers) : [];
    let related_requests = _R.type(props.Request) !== 'Undefined' ? Object.values(props.Request.related_requests) : [];
    let timesheet = _R.type(props.Request) !== 'Undefined' ? Object.values(props.Request.timesheet) : [];
    let timeoff_details = _R.type(props.Request) !== 'Undefined' ? props.Request.timeoff_details : [];
    let history = _R.type(props.Request) !== 'Undefined' ? props.Request.history : [];
    let comments = _R.type(props.Request) !== 'Undefined' ? props.Request.comments : [];
    let image_url = API_URL+'api/request/timeoff/download?id='+request.attachment_id;
    var reviewer_arr = [];
    var recipient_arr = [];
    for (let key in recipients) {
        if (reviewers.includes(recipients[key]['user_id'])) {
            recipients[key]['rev'] = " (Reviewer)";
            reviewer_arr.push(recipients[key]);
        }
        else {
            recipients[key]['rev'] = '';
            recipient_arr.push(recipients[key]);
        }
    }
    recipients = reviewer_arr.concat(recipient_arr);
    return (
        <React.Fragment>
            <div className="section-main section-main-header-fixed">
                <div className={'header-fixed'}>
                    <div className="section-header">
                        <div className="col-left">
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <div className="section-title-wrap">
                                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                    Time off Request Details
                                </Typography>
                                <Typography>ID: {request.id}</Typography>
                            </div>
                        </div>
                        <div className="action">
                        { (props.SelfEdit) &&
                            <Button variant="contained" size="small"  color="primary" onClick={props.EditClick}>Edit</Button>
                        }
                        {(requests.is_reviewer && request.status === 1 && requests.parent_status !== 1) &&
                            <Button variant="contained" size="small"  color="primary" onClick={props.ApprovebtnClick}>Approve</Button>
                        }
                        {(requests.is_reviewer && request.status === 1) &&
                            <>
                            <i> </i>
                            <Button variant="contained" size="small" color="secondary" onClick={props.RejectbtnClick}>Reject</Button>
                            </>
                        }
                        {(requests.is_reviewer && request.status === 2 && requests.timesheetedit.length > 0) &&
                            <>
                            <i> </i>
                            <Button variant="contained" size="small" color="secondary" onClick={props.UnApprovebtnClick}>UnApprove</Button>
                            </>
                        }
                        {requests.comment &&
                            <>
                            <i> </i>
                            <Button variant="outlined" size="small" onClick={props.CommentbtnClick}>Comment</Button>
                            </>
                        }
                        {requests.is_cancel &&
                            <>
                            <i> </i>
                            <Button variant="outlined" color="primary" size="small" onClick={props.CancelbtnClick}>Cancel</Button>
                            </>
                        }
                        {(requests.show_reviewer && !requests.is_reviewer) &&
                            <>
                            <i> </i>
                            <Button variant="outlined" color="primary" size="small" onClick={props.TokenbtnClick}>Send Review Reminder</Button>
                            </>
                        }
                        </div>
                    </div>
                </div>
            <div className="section-body"> {/* refer timesheet > submit > view */}
                {(requests.is_reviewer && request.status === 1 && requests.parent_status === 1) &&
                    <Paper>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs>
                                <Alert severity="warning"><a onClick={()=>props.ViewRelatedTimeoff(request.parent_request_id)} className='link'>You have to approve the parent request first. <strong>Click here</strong></a> to approve parent time off request.</Alert>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                <StyledPaper>
                    <div className="timesheet_list-summary">
                        <div className="p-1rem summary box-details">
                            <ul className="list-standard list-ul-shrink">
                                <li className="list-item">
                                    <label>Staff Name</label>
                                    <span className="value">{formatName(request.ru_first_name,null,request.ru_last_name)}</span>
                                </li>
                                <li className="list-item">
                                    <label>Time Off Task</label>
                                    <span className="value">{request.task_name}</span>
                                </li>
                                <li className="list-item">
                                    <label>Reference Number</label>
                                    <span className="value">{request.reference_no}</span>
                                </li>
                                <li className="list-item">
                                    <label>Date</label>
                                    <span className="value">{request.rdate}</span>
                                </li>
                                {request_coff_dates &&
                                    <li className="list-item">
                                      <label>Compensatory Off Dates</label>
                                      <span className="value">{request_coff_dates}</span>
                                    </li>
                                }
                                <li className="list-item">
                                    <label>Requested Hours</label>
                                    <span className="value">{request.hours}</span>
                                </li>
                                <li className="list-item">
                                    <label>Status</label>
                                    <span className="value">{request.request_status_name}</span>
                                </li>                               
                                {request.name &&
                                    <li className="list-item">
                                      <label><AttachmentIcon /></label>
                                      <a href = {image_url} > {_cs.formatFileName(request.name, 19)} </a>
                                    </li>
                                }
                            </ul>
                            <ul className="list-standard list-ul-grow list-ul-border-l">
                                <li className="list-item list-item-block">
                                    <label>Reason</label>
                                    <span className="value">
                                        {request.description}
                                    </span>
                                    </li>
                                    { (recipients != null) && (recipients.length > 0) &&
                                    <li className="list-item list-item-block">
                                        <label>Email Recipients </label>
                                        <span className="value">
                                          {(recipients != null) && Object.keys(recipients).map((ry,i) =>
                                         <><span className="pill">{formatName(recipients[ry].user_first_name, null, recipients[ry].user_last_name)}{recipients[ry].rev}</span></>

                                         )}
                                        </span>
                                    </li>
                                    }
                                    <li className="list-item list-item-bottom-info">
                                    <div>
                                        <i><strong>Submitted by: </strong>
                                            {request.creator} on {request.created_on} 
                                        </i>
                                        {(!!request.updated_by && request.updated_by > 0) &&
                                            <>
                                                 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                                <i><strong>Modified by: </strong></i>
                                                <i>{request.modifier} on {request.updated_on} </i>
                                            </>
                                        }
                                        {(request.approver_name && request.approver_name !== '') &&
                                            <>
                                                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                                <i><strong>Reviewed by: </strong></i>
                                                <i>{request.approver_name} on {request.reviewed_on} </i>
                                            </>
                                        }
                                    </div>
                               </li>
                            </ul>
                        </div>
                    </div>
                    {/* Summary Section Ends here */}

                    <div className={'root'}>
                        <Comment
                            Comments = {comments}
                        />
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={'heading'}>Related Time Off Requests</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {related_requests.length > 0 ?
                                    <Table  size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Task</TableCell>
                                                <TableCell>Requested Hours</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {(related_requests).map((item) => 
                                            <TableRow key = {item.id}>
                                                <TableCell component="th" scope="row"> {item.rdate} </TableCell>
                                                <TableCell component="th" scope="row"> {item.task_name} </TableCell>
                                                <TableCell component="th" scope="row"> {item.hours}</TableCell>
                                                <TableCell component="th" scope="row"> {item.request_status_name} </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Tooltip title="View" aria-label="view">
                                                        <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                                            <VisibilityIcon fontSize="small" onClick={()=>props.ViewRelatedTimeoff(item.id)}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                    :
                                    <Typography variant="body2" gutterBottom align='center'>No related time off requests.</Typography>
                                }
                            </AccordionDetails>
                        </Accordion>

                        <RelatedTimeEntries
                            TimeEntries = {timesheet}
                            Total = {requests.timesheet_total}
                        />

                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={'heading'}>Time Off Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Table size="small" aria-label="a dense table">
                                        <TableBody>
                                            {_R.type(timeoff_details.view_object) !== 'Undefined' && timeoff_details.view_object.map((item) =>
                                                <TableRow>
                                                    <TableCell component="th" scope="row"> {item.label}</TableCell>
                                                    <TableCell component="th" scope="row"> {item.hours} Hours</TableCell>
                                                    <TableCell component="th" scope="row"> {item.days} Days</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    {Object.keys(timeoff_details).length === 0 &&
                                        <Typography variant="body2" gutterBottom align='center'>{request.to_type} details not available.</Typography>
                                    }
                                </AccordionDetails>
                            </Accordion>
        
                        <ChangeHistory
                            History = {history}
                        />
                    </div>
                </StyledPaper>
            </div>
        </div>
        {(props.Popup === 'approve') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SubmitReviewRequest}
                ButtonName = 'Approve'
                Disabled={props.buttonDisabled}
                Title='Approve Request'
                Content={
                    <DialogApprove
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                        IsParent = {requests.is_parent}
                    />
                }
            />
        }
        {(props.Popup === 'unapprove') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SubmitReviewRequest}
                ButtonName = 'Unapprove'
                Disabled={props.buttonDisabled}
                Title='Unapprove Request'
                Content={
                    <DialogUnApprove
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                    />
                }
            />
        }
        {(props.Popup === 'reject') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SubmitReviewRequest}
                ButtonName = 'Reject'
                Disabled={props.buttonDisabled}
                Title='Reject Request'
                Content={
                    <DialogReject
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                    />
                }
            />
        }
        {(props.Popup === 'comment') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SubmitReviewRequest}
                ButtonName = 'Add'
                Disabled={props.buttonDisabled}
                Title='Add Comment'
                Content={
                    <DialogComment
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                    />
                }
            />
        }
        {(props.Popup === 'cancel') &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                OnSubmit={props.SubmitReviewRequest}
                ButtonName = 'Yes'
                Disabled={props.buttonDisabled}
                Title='Cancel Request'
                Content={
                    <DialogCancel
                        Errors = {props.Errors}
                        FormFields = {props.FormFields}
                        HandleOnChange = {props.HandleOnChange}
                    />
                }
            />
        }
        {(props.Popup === 'edit' && props.DialogOpen) &&
            <AddTimeOff 
                Open = {props.DialogOpen}
                UpdateParentOnAddSuccess = {props.UpdateParentOnEditSuccess}
                EditId = {props.Id}
                Title = {"EDIT"}
                CloseLoader = {props.CloseLoader}
            />
        }

        <CustomizedSnackbars
            open={props.SnackbarOpen}
            variant={props.SnackbarMsgType ? props.SnackbarMsgType : 'success'}
            message={props.SnackbarMsg ? props.SnackbarMsg : ''}
            handleClose={props.SnackbarClose}
        />
    </React.Fragment>    
)
};
