import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import { ucfirst } from 'bellajs';
import * as _R from 'ramda';  // used 0.04kb
import Typography from '@mui/material/Typography';

export const ServicePeriodComponent = (props) => {
    let servicePeriods = props.ServicePeriods;
    return (
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Service Period</Typography>

            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Pay Type</TableCell>
                            <TableCell>Overtime</TableCell>
                            <TableCell>Daily Min.</TableCell>
                            <TableCell>Daily Max.</TableCell>
                            <TableCell>Weekly Limit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {typeof servicePeriods != 'undefined' && servicePeriods.map((sp) =>
                            <TableRow hover>
                                <TableCell>{sp.start_date} - {sp.end_date}</TableCell>
                                <TableCell>{sp.category != null ? ucfirst(sp.category) : ''}</TableCell>
                                <TableCell>{ucfirst(sp.overtime)}</TableCell>
                                <TableCell>{sp.daily_minimum}</TableCell>
                                <TableCell>{sp.daily_max_limit}</TableCell>
                                <TableCell>{sp.weekly_limit} <span className={'sub'}>{(sp.weekly_limit !== null && sp.weekly_limit !== '0') ? (sp.include_holidays === 'Yes' ? '(Include Holidays)' : '(Exclude Holidays)') : <></>}</span></TableCell>

                            </TableRow>
                        )}
                        {_R.isEmpty(servicePeriods) &&
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        </>
    );
};


