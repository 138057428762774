import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { formatName } from '../../../common/common';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={15}>
                        <FormControl>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                required
                                value={props.FormFields.description}
                                onChange={props.HandleOnChange}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={15}>
                        <FormControl>
                            <TextField
                                id="sharepoint_url"
                                name="sharepoint_url"
                                label="SharePoint URL"
                                type="text"
                                required
                                value={props.FormFields.sharepoint_url}
                                onChange={props.HandleOnChange}
                                error={props.Errors.sharepoint_url && true}
                                helperText={props.Errors.sharepoint_url}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};
export const FilesComponent = (props) => {
    return (
	    <>
	    <React.Fragment>
        <div className={'mtable-basic'} >
        <CustomMaterialTable
                        title={props.title}
                        columns={[
                            {
                                title: 'SharePoint URL',
                                field: 'sharepoint_url',
                                render: rowData => <a href={rowData.sharepoint_url} target="_blank" rel="noopener noreferrer">{(rowData.sharepoint_url.length >= 20) ? <span>{rowData.sharepoint_url.substring(0, 20)}</span>:<span>{rowData.sharepoint_url}</span>}</a>,
                            },
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Created By',
                                field: 'created_by',
                                render: rowData => <>{formatName(rowData.cby_first_name,null,rowData.cby_last_name)}</>,
                            },
                            {
                                title: 'Created On',
                                field: 'created_on',
                            },
                            {
                                title: 'Modified By',
                                field: 'modified_by',
                                render: rowData => <>{formatName(rowData.mby_first_name,null,rowData.mby_last_name)}</>,
                            },
                            {
                                title: 'Modified On',
                                field: 'modified_on',
                            },
                        ]}
                        data = {props.files}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                            tableLayout: 'fixed',
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Files',
                                isFreeAction: true,
                                onClick: (event, newData) => {
                                props.AddFiles();
                                },
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                props.Update(rowData.id);
                                },
                            },
                        ]}
        />
        <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {props.IsEdit?'Edit URL':'Add URL'}
                    OnSubmit={props.SaveFiles}
                    Content={<DialogComponent FormProps={props}/>}/>
        </div>
        </React.Fragment>
        </>
    )
};

