import React from 'react';
import { httpServices } from '../../../services/httpServices'
import { history } from './../../../helpers/history';
import { TimeOffTypesViewComponent } from './TimeOffTypesView.Component'
import * as _R from 'ramda';  // used 0.04kb
const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export class TimeofftypesViewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            editPermission: false,
            timeoff_types: [],
            dialogOpen: false,
            popupType: '',
            formFieldsEdit: {},
            formFieldsView: {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant: '',
            id: 0,
        }
        this.getTimeOffTypeDetails = this.getTimeOffTypeDetails.bind(this);
        this.onClose = this.onClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount = () => {
        let id = (this.props.match.params.id) ? this.props.match.params.id : false;
        this.setState({
            id: id
        });
        this.getTimeOffTypeDetails(id);
    };
    editClick = () => {
        let id = this.state.id
        this.setState({
            dialogOpen: true,
            popupType: 'edit',
        });
        this.getTimeOffTypeDetails(id);
    };
    getTimeOffTypeDetails = (id) => {
        let apiEndpoint = 'api/settings/timeoff/get-timeoff-type';
        return httpServices.post(apiEndpoint, { id: id })
            .then(response => {
                if (response.data) {
                    if (response.data.timeoff_details) {
                        let formFieldsView = {
                            id: response.data.timeoff_details.id,
                            to_type: response.data.timeoff_details.to_type,
                            description: response.data.timeoff_details.description,
                            accrual: response.data.timeoff_details.accrual,
                            eligibility_criteria_staff_override: response.data.timeoff_details.eligibility_criteria_staff_override,
                            reset_calendar_year: response.data.timeoff_details.reset_calendar_year,
                            custom_workflow: response.data.timeoff_details.custom_workflow,
                            calendar_days: response.data.timeoff_details.calendar_days,
                            paid: response.data.timeoff_details.paid,
                            requests_per_year: response.data.timeoff_details.requests_per_year,
                            adjustments: response.data.timeoff_details.adjustments,
                            adjustment_period: response.data.timeoff_details.adjustment_period,
                            approver: response.data.timeoff_details.approver,
                            editable: response.data.timeoff_details.editable,
                            enable_grant: response.data.timeoff_details.enable_grant,
                            weight: response.data.timeoff_details.weight,
                            status: response.data.timeoff_details.status,
                            allow_partial_hours: response.data.timeoff_details.allow_partial_hours,
                            prorate_hours: response.data.timeoff_details.prorate_hours,
                            createdby: response.data.timeoff_details.createdby,
                            modifiedby: response.data.timeoff_details.modifiedby,
                            modified_on: response.data.timeoff_details.modified_on,
                            created_on: response.data.timeoff_details.created_on,
                            policyList: response.data.timeoff_details.policylist,
                            eligibility_criteria_unit: response.data.timeoff_details.eligibility_criteria_unit,
                            is_eligibility_criteria: response.data.timeoff_details.is_eligibility_criteria,
			    event_based : response.data.timeoff_details.event_based,
                            show_variable_name : response.data.timeoff_details.show_variable_name,                            
                            adjustment_type : response.data.timeoff_details.adjustment_type,
                            accrualStatusName : response.data.timeoff_details.accrualStatusName,
                        }                       
                        this.setState({
                            formFieldsEdit: response.data.timeoff_details,
                            formFieldsView : formFieldsView,    
                        }, function() { 
                        let formFieldsEdit = this.state.formFieldsEdit;
                        if (response.data.timeoff_details.adjustment_type.includes("Adjustment")) {
                            formFieldsEdit.adjustment = true;                        
                        }
                       if (response.data.timeoff_details.adjustment_type.includes("Carry Forward")) {
                            formFieldsEdit.carry_forward = true;
                       }
                       this.setState({
                        formFieldsEdit: formFieldsEdit,
                    });
			});
                    } else {
                        history.push(BASE_PATH + '/settings/timeoff');
                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    onClose = () => {
        this.setState({
            dialogOpen: false,
        })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFieldsEdit;
        ff[field] = evt.target.value;
        if (field === 'is_eligibility_criteria' && evt.target.value == 1) {
            ff['eligibility_criteria_unit'] = 'year';
        }
        if(field === 'adjustment' || field === 'carry_forward') {
            ff[field] = evt.target.checked;
        }
        this.setState({
            formFieldsEdit: ff,
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }
    handleSubmit = (event) => {
    if (this.handleValidation()) {
        let data = this.state.formFieldsEdit;
        if (data) {
            httpServices.post('api/settings/timeoff/edit-timeoff-type', { data })
                .then(response => {
                    if (response.data.permission) {
                        if (response.data.valid) {
                        if (data.id) {
                            this.getTimeOffTypeDetails(data.id);
                            this.setState({
                                snackbarOpen : true,
                                snackbarMsg : 'Time off type updated successfully',
                                snackbarVariant: 'success',
                            })
                        }
                            this.setState({
                                dialogOpen: false,
                                popupType: '',
                            });
                        }
                        else {
                            this.setState({
                                errors: response.data.errors,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log("error" + error);
                });
        }
    }
    };
    handleValidation() {
        let formIsValid = false;
        let errors = {};
        let adjustments = this.state.formFieldsEdit.adjustments;
        let adjustmentFor = this.state.formFieldsEdit.adjustment_period;
        if (adjustments === 1 && !adjustmentFor) {
            errors["adjustment_period"] = "Cannot be empty";
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    render() {
        return (
            <TimeOffTypesViewComponent
                FormFieldsEdit={this.state.formFieldsEdit}
                FormFieldsView={this.state.formFieldsView}
                Errors={this.state.errors}
                HandleOnChange={this.handleOnChange}
                PopupType={this.state.popupType}
                DialogOpen={this.state.dialogOpen}
                OnClose={this.onClose}
                OnSubmit={this.handleSubmit}
                TimeoffTypeId={this.state.id}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarVariant = {this.state.snackbarVariant}
                OnEditClick = {this.editClick}
            />
        )
    }
}
