import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _R from 'ramda';
import * as _ts from '../../common/treeSelect';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
const API_URL = process.env.REACT_APP_API_URL;


export const WeeklyOnsiteOffsiteReportComponent = (props) => {
     const gridStyle = {
        margin: '15px 0px'
      };
      const input = props.ReportInputs;
      const values = props.ReportFields;
      let projects = ( _R.type(input) !== 'Undefined' && _R.type(input.project_list) !== 'Undefined') ? input.project_list : [];
      if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(values) !== 'Undefined') ? values.projects : []));
      }
     return(
            <React.Fragment>
            <Paper>
            <div className={'p1'}>
            <Typography variant="h6" component="h6" className={'section-title'}>Weekly Onsite Offsite Hours Report</Typography>
             <Grid container style={gridStyle}><Grid item sm={12}></Grid></Grid>
                <form className="form-fullwidth" action={API_URL+'api/report/offsitehours'} method="post" id="weeklyReport">
                    <Grid container spacing={3}>
                        <Grid item  md={3}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="client">Client</InputLabel>
                                <Select
                                    value= {(typeof props.ReportFields.client !== 'undefined') ? props.ReportFields.client : 0}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'client',
                                        id: 'client',
                                    }}>
                                    <MenuItem value = '0'>-Select Client-</MenuItem>
                                    {  typeof props.ReportInputs.client_list !== 'undefined' &&  props.ReportInputs.client_list !== '' && props.ReportInputs.client_list.map((t, i) =>
                                        <MenuItem value={t.client_id}>{t.client_name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className='error'>{props.Errors.client}</FormHelperText>
                            </ FormControl>
                        </Grid>
                          <Grid item  md={3}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="project_status">Project Status</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.project_status !== 'undefined') ? props.ReportFields.project_status : 1}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'project_status',
                                        id:   'project_status',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                    { typeof props.ReportFields.project_statuses != 'undefined' && props.ReportFields.project_statuses !=='' && props.ReportFields.project_statuses.map((t, i) =>
                                    <MenuItem value={t.project_status_id} key={'stst-'+i}>{t.project_status_name}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </Grid>
                         <Grid md={5}>
                          <label className='dropdown-label'>Projects</label>
                                        <FormControl>
                                            <input type='hidden' name='projects' value={props.ReportFields.projects}/>
                                            <TreeSelectComponent
                                                data = {projects}
                                                placeholderText = { "Search Projects"}
                                                type = 'project'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                            <FormHelperText className='error'>{props.Errors.projects}</FormHelperText>
                                        </FormControl>
                                       <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={props.HandleOnChange}
                                            value={(typeof props.ReportFields.includeTask !== 'undefined') ? props.ReportFields.includeTask : false}
                                            checked={(typeof props.ReportFields.includeTask !== 'undefined') ? props.ReportFields.includeTask : false}
                                            inputProps={{
                                                name: 'includeTask',
                                                id: 'includeTask',
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Include Task Details"
                                />

                        </Grid>
                       <Grid item  md={2} className='justify-between'>
                            <FormControl>
                                <TextField variant="standard"
                                    value = {props.ReportFields.start_date}
                                    id="start_date"
                                    name="start_date"
                                    label="Timesheet Start Date"
                                    type="date"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={props.HandleOnChange}
                                    error = { props.Errors.start_date && true}
                                    helperText = { props.Errors.start_date}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item  md={2} className='justify-between'>
                            <FormControl>
                                <TextField variant="standard"
                                    value = {props.ReportFields.end_date}
                                    id="end_date"
                                    name="end_date"
                                    label="Timesheet End Date"
                                    type="date"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={props.HandleOnChange}
                                    error = { props.Errors.end_date && true}
                                    helperText = { props.Errors.end_date}
                                />
                            </FormControl>
                        </Grid>
                         <Grid item  md={2}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="staff_name_format">Staff Name Format</InputLabel>
                                <Select
                                    value= {(typeof props.ReportFields.staff_name_format !== 'undefined') ? props.ReportFields.staff_name_format : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_name_format',
                                        id: 'staff_name_format',
                                    }}>
                                    {  typeof props.ReportInputs.name_format !== 'undefined' &&  props.ReportInputs.name_format !== '' && props.ReportInputs.name_format.map((t, i) =>
                                        <MenuItem value={t.key}>{t.value }</MenuItem>
                                    )}
                                </Select>
                            </ FormControl>
                        </Grid>
                        <Grid item md={10}>
                           <div className='d-flex align-center justify-end'> <Button size="small" color="primary"  name="export" variant="contained" onClick={props.ExportClick}>Export</Button></div>
                        </Grid>
                    </Grid>
                </form>
            </div>
            </Paper>
        </React.Fragment>
        )
}


