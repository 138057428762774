import React from 'react';
import AppBar from '@mui/material/AppBar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomCaptcha } from '../../common/captcha';
import { ErrorPage } from '../../common/error';
import { formatName } from '../../common/common';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const OLD_URL = process.env.REACT_APP_OLD_URL;
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const EmailActionComponent = (props) => {
    const googleCaptchaSiteKey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
    return (
        <>
            <React.Fragment>
                <AppBar color="default" className={'appBarMain'}>
                    <div className={'steps-bar'}>
                        <div className={'steps-logo'}>
                            <div className={'helper'}>
                                <img src={BASE_PATH + "/steps-logo.svg"} alt="" />
                            </div>
                        </div>
                    </div>
                    <Toolbar className={'appBarMain__toolbar'}>
                        <Typography variant="h6" color="inherit" className={'branding'}>
                            <div className={'appBarLogo'}>
                                <div className={'helper'}>
                                    <img src={BASE_PATH + "/company_logo.png"} alt={"this.props.title"} />
                                </div>
                            </div>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarType}
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            </React.Fragment>
            {props.InvalidTokenFlag ?
                <div className={'temp-email-action'}>
                    <ErrorPage
                        Message={props.ErrorMsg}
                    />
                </div>
                :
                <div className="main-container">
                    {props.Action === 'timeoffapprove' &&
                        <TimeoffApprove
                            RequestDetails={props.RequestDetails}
                            VerifyCallback={props.VerifyCallback}
                            ShowApproveBtn={props.ShowApproveBtn}
                            Approve={props.Approve}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            ShowRelatedRequest={props.ShowRelatedRequest}
                            RelatedRequest={props.RelatedRequest}
                            HandleRelatedRequestChange={props.HandleRelatedRequestChange}
                            Close={props.Close}
                            TimeoffData={props.TimeoffData}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                    {props.Action === 'timeoffreject' &&
                        <TimeoffReject
                            RequestDetails={props.RequestDetails}
                            VerifyCallbackReject={props.VerifyCallbackReject}
                            ShowRejectBtn={props.ShowRejectBtn}
                            Reject={props.Reject}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            ShowRelatedRequest={props.ShowRelatedRequest}
                            RelatedRequest={props.RelatedRequest}
                            HandleRelatedRequestChange={props.HandleRelatedRequestChange}
                            Close={props.Close}
                            TimeoffData={props.TimeoffData}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                    {props.Action === 'overtimeapprove' &&
                        <OvertimeApprove
                            RequestDetails={props.RequestDetails}
                            VerifyCallback={props.VerifyCallback}
                            ShowApproveBtn={props.ShowApproveBtn}
                            Approve={props.Approve}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            Close={props.Close}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                    {props.Action === 'overtimereject' &&
                        <OvertimeReject
                            RequestDetails={props.RequestDetails}
                            VerifyCallbackReject={props.VerifyCallbackReject}
                            ShowRejectBtn={props.ShowRejectBtn}
                            Reject={props.Reject}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            Close={props.Close}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                    {props.Action === 'wlcapprove' &&
                        <WLCApprove
                            RequestDetails={props.RequestDetails}
                            VerifyCallback={props.VerifyCallback}
                            ShowApproveBtn={props.ShowApproveBtn}
                            Approve={props.Approve}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            Close={props.Close}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                    {props.Action === 'wlcreject' &&
                        <WLCReject
                            RequestDetails={props.RequestDetails}
                            VerifyCallbackReject={props.VerifyCallbackReject}
                            ShowRejectBtn={props.ShowRejectBtn}
                            Reject={props.Reject}
                            googleCaptchaSiteKey={googleCaptchaSiteKey}
                            Close={props.Close}
                            HandleOnChange={props.HandleOnChange}
                            ValidationErrors={props.ValidationErrors}
                        />
                    }
                </div>
            }
        </>
    )
};
const TimeoffApprove = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    let TimeoffData = props.TimeoffData ? props.TimeoffData :{};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Time Off Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Approve Time Off Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Staff Name</label><span className={'value'}>{(typeof data.ru_first_name !== 'undefined') ? formatName(data.ru_first_name, null, data.ru_last_name) : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Task</label><span className={'value'}>{typeof data.task_name !== 'undefined' ? data.task_name : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span>
                            </div>
                            {TimeoffData &&
                            <>
                            <div> </div>
                            <div> <h4> {typeof data.task_name !== 'undefined' ? data.task_name : ""} Details </h4></div>
                            {_R.type(TimeoffData) !== 'Undefined' && Object.keys(TimeoffData).map((item) =>
                                <div  className={'profile-view__basics_default-item item-role'}>
                                    <label> {TimeoffData[item]['label']} </label>
                                <span className={'value'}> {TimeoffData[item]['hours']} Hours ({TimeoffData[item]['days']} Days)</span> </div>
                            )}
                            </>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    {props.ShowRelatedRequest &&
                        <Grid item xs={12} sm={6} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={props.HandleRelatedRequestChange}
                                        value={props.RelatedRequest}
                                        checked={props.RelatedRequest}
                                        color="primary"
                                    />
                                }
                                label="Approve all related requests"
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments (optional)"
                            multiline={true}
                            variant="outlined"
                            fullWidth 
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallback}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowApproveBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Approve}>Approve</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Approve}>Approve</Button>
                        } <i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
const TimeoffReject = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    let TimeoffData = props.TimeoffData ? props.TimeoffData :{};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Time Off Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Reject Time Off Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Staff Name</label><span className={'value'}>{(typeof data.ru_first_name !== 'undefined') ? formatName(data.ru_first_name, null, data.ru_last_name) : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Task</label><span className={'value'}>{typeof data.task_name !== 'undefined' ? data.task_name : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span>
                            </div>
                            <div className={'profile-view__basics_default-item item-role'}>
                                <label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span>
                            </div>
                            {TimeoffData &&
                            <>
                            <div> </div>
                            <div> <h4> {typeof data.task_name !== 'undefined' ? data.task_name : ""} Details </h4></div>
                            {_R.type(TimeoffData) !== 'Undefined' && Object.keys(TimeoffData).map((item) =>
                                <div  className={'profile-view__basics_default-item item-role'}>
                                    <label> {TimeoffData[item]['label']} </label>
                                <span className={'value'}> {TimeoffData[item]['hours']} Hours ({TimeoffData[item]['days']} Days)</span> </div>
                            )}
                            </>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    {props.ShowRelatedRequest &&
                        <Grid item xs={12} sm={6} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={props.HandleRelatedRequestChange}
                                        value={props.RelatedRequest}
                                        checked={props.RelatedRequest}
                                        color="primary"
                                    />
                                }
                                label="Reject all related requests"
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments"
                            multiline={true}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallbackReject}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowRejectBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Reject}>Reject</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Reject}>Reject</Button>
                        }<i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
const OvertimeApprove = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Overtime Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Approve Overtime Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}><label>Staff Name</label><span className={'value'}>{(typeof data.request_user_id !== 'undefined') ? formatName(data.user_first_name, null, data.user_last_name) : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span> </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments (optional)"
                            multiline={true}
                            variant="outlined"
                            fullWidth
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallback}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowApproveBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Approve}>Approve</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Approve}>Approve</Button>
                        }
                        <i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
const OvertimeReject = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Overtime Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Reject Overtime Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}><label>Staff Name</label><span className={'value'}>{( data.request_user_id !== 0) ? formatName(data.user_first_name, null, data.user_last_name) : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span> </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments"
                            multiline={true}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallbackReject}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowRejectBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Reject}>Reject</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Reject}>Reject</Button>
                        }
                        <i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
const WLCApprove = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Work Location Change Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Approve Work Location Change Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}><label>Staff Name</label><span className={'value'}>{(typeof data.user_first_name !== 'undefined') ? formatName(data.user_first_name, null, data.user_last_name) : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Location</label><span className={'value'}>{(typeof data.location_name !== 'undefined') ? data.location_name:""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span> </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments (optional)"
                            multiline={true}
                            variant="outlined"
                            fullWidth
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallback}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowApproveBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Approve}>Approve</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Approve}>Approve</Button>
                        }
                        <i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
const WLCReject = (props) => {
    let data = props.RequestDetails ? props.RequestDetails : {};
    return (
        <>
            <Paper>
                <Grid container spacing={3} className={'pl-1 pt-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <h4>Work Location Change Request Details</h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <div> <h4>Reject Work Location Change Request</h4></div>
                        <div className={'profile-view__basics_default'}>
                            <div className={'profile-view__basics_default-item item-role'}><label>Staff Name</label><span className={'value'}>{(typeof data.user_first_name !== 'undefined') ? formatName(data.user_first_name, null, data.user_last_name) : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Location</label><span className={'value'}>{(typeof data.location_name !== 'undefined') ? data.location_name:""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Date</label><span className={'value'}>{(typeof data.start_date !== 'undefined' || typeof data.end_date !== 'undefined') ? data.start_date + " - " + data.end_date : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reference Number</label><span className={'value'}>{(typeof data.reference_no !== 'undefined') ? data.reference_no : ""}</span></div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Hours</label><span className={'value'}>{(typeof data.hours !== 'undefined') ? data.hours : ""}</span> </div>
                            <div className={'profile-view__basics_default-item item-role'}><label>Reason</label><span className={'value'}>{(typeof data.description !== 'undefined') ? data.description : ""}</span> </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <Grid container spacing={3} className={'pl-1'}>
                    <Grid item xs={12} sm={6} md={12}>
                        <FormControl>
                        <TextField
                            id="comments"
                            name="comments"
                            label="Comments"
                            required
                            multiline={true}
                            variant="outlined"
                            fullWidth
                            onChange={props.HandleOnChange}
                            error={props.ValidationErrors.comments && true}
                            helperText={props.ValidationErrors.comments}
                        />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <CustomCaptcha
                            Sitekey={props.googleCaptchaSiteKey}
                            VerifyCallback={props.VerifyCallbackReject}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {props.ShowRejectBtn ?
                            <Button variant="contained" size="small" color="primary" onClick={props.Reject}>Reject</Button>
                            : <Button variant="contained" size="small" color="primary" disabled={true} onClick={props.Reject}>Reject</Button>
                        }
                        <i> </i>
                        <Button variant="outlined" color="primary" size="small" onClick={props.Close}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
