import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { formatName } from '../../common/common';

export const RelatedTimeEntries = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography variant="subtitle1">Related Time Entries</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        {props.TimeEntries.length > 0 ?
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Staff Name</TableCell>
                                        <TableCell>Task</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Last Modified</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                            <TableBody>
                            {(props.TimeEntries).map((item) =>
                                <TableRow key = {item.id}>
                                    <TableCell component="th" scope="row"> {item.timeslice_date} </TableCell>
                                    <TableCell component="th" scope="row"> {formatName(item.first_name,null,item.last_name)} </TableCell>
                                    <TableCell component="th" scope="row"> {item.task_name} </TableCell>
                                    <TableCell component="th" scope="row"> {item.timeslice_log}</TableCell>
                                    <TableCell component="th" scope="row"> {item.timeslice_hours} </TableCell>
                                    <TableCell component="th" scope="row"> {item.timeslice_mdate} </TableCell>
                                    <TableCell component="th" scope="row"> {item.timesheet_status_name} </TableCell>
                                </TableRow>
                            )}
                            <TableRow key = 'timeentry-total'>
                                <TableCell component="th" scope="row" colSpan ='4'></TableCell>
                                <TableCell component="th" scope="row" colSpan ='3'><strong>{(props.Total).toFixed(2)}</strong></TableCell>
                            </TableRow>
                            </TableBody>
                        </Table>
                        :
                        <Typography variant="body2" gutterBottom align='center'>No time entries related to this request</Typography>
                    }
                </AccordionDetails>
          </Accordion>


    );
}
