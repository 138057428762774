import { createSlice } from '@reduxjs/toolkit';

export const Tabs = createSlice({
  name: 'Tab',
  initialState: {
    allTabs: { 'staff-view': 0,
        'timeoff': 'my',
        'location': 'my',
        'overtime': 'my',
        'additionalpto': 'my',
        'policy-settings' : 0
    },
  },
  reducers: {
    setSelectedTabIndex: (state, action) => {
      let value = action.payload.selected;
      state.allTabs[action.payload.page] = value;

    },
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedTabIndex } = Tabs.actions
export default Tabs.reducer
