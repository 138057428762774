import React  from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as R from 'ramda';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import AttachmentIcon from '@mui/icons-material/Attachment';

const API_URL = process.env.REACT_APP_API_URL;
export const RequestList  = props => {
    // Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="Timesheet List" className={'list-table_basic steps-table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {(props.Tab !== 'my') && 
                            <TableCell>Staff Name</TableCell>
                            }
                            <TableCell>Date</TableCell> 
                            <TableCell>Task Name</TableCell>
                            <TableCell>Reason</TableCell>
                            <TableCell>Requested Hours</TableCell>
                            <TableCell>Saved Hours</TableCell>
                            <TableCell>Status</TableCell>
                            {(props.Tab !== 'my') &&
                            <TableCell>Submitted by</TableCell>
                            }
                            <TableCell>Submitted on</TableCell>
                            <TableCell>Reviewed by</TableCell>
                            <TableCell>Reviewed on</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(props.Requests.length === 0) &&
                            <TableRow >
                                <TableCell colSpan="5">No Time Off Requests found.</TableCell>
                            </TableRow>
                        }
                        {R.type(props.Requests) !== 'Undefined'  && props.Requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                        <TableRow hover>
                            <TableCell><a className={'item-link'} onClick={()=>props.HandleViewClick(item.id)}>{item.id}</a></TableCell>
                            {(props.Tab !== 'my') &&
                            <TableCell>{(item.request_users != item.created_by) ? item.request_users : item.created_by}</TableCell>
                            }
                            <TableCell>{item.timeoff_date}</TableCell>
                            <TableCell>{item.task_name}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{parseFloat(item.duration).toFixed(2)}</TableCell>
                            <TableCell>{item.saved_hours}</TableCell>
                            <TableCell>{item.request_status}</TableCell>
                            {(props.Tab !== 'my') &&
                            <TableCell>{item.created_by}</TableCell>
                            }
                            <TableCell>{item.created_on}</TableCell>
                            <TableCell>{item.reviewed_by}</TableCell>
                            <TableCell>{item.reviewed_on ? item.reviewed_on : 'Never'}</TableCell>
                            <TableCell align="right">
                                <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                        <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                                {(props.Tab === 'my' && item.request_status !== "Cancelled" ) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Edit">
                                        <EditIcon fontSize="small" onClick={()=>props.CreateTimeOffClick(item.id[0])}/>
                                    </IconButton>
                                </Tooltip>
                                }
                                {item.attachment_id != null &&
                                <>  
                                    <IconButton   className={'action-btn'} edge="end" aria-label="Attachment">
                                        <a href = {API_URL+'api/request/timeoff/download?id='+ item.attachment_id}>  <AttachmentIcon  fontSize="small"/> </a>
                                    </IconButton>
                                </>
                                }
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={props.Requests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

