import React from 'react';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import * as R from 'ramda';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import { DialogBoxMedium } from '../../common/dialog.medium';
import parse from 'html-react-parser';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Drawer from '@mui/material/Drawer';
import TreeIcon from '@mui/icons-material/AccountTree';
import NativeSelect from '@mui/material/NativeSelect';
import WarningIcon from '@mui/icons-material/Warning';
import FormHelperText from '@mui/material/FormHelperText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { CustomDatePicker } from '../../common/CustomDatePicker';

import {MaterialTreeView} from '../../../components/CustomMaterialUiTree/MaterialUiTree';

const ButtonStyles = styled('div')(({ theme }) => ({  
    '& .root': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .wrapper': {
      margin: theme.spacing(1),
      position: 'relative',
    },  
    '& .buttonProgress': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

export const TimeEntryEditComponent  = (props) => {
    let edit_data = props.timeSliceEditData;
    let project_tree_inputs = typeof edit_data.projects !== 'undefined' ? edit_data.projects : [];
    let form_inputs = props.formInputFields ? props.formInputFields : {};
    let disabled = false;
    if (form_inputs.task_code) {
     disabled = (form_inputs.task_code === "HO" || form_inputs.task_code === "TO") ? true : false;
    }
    else if(edit_data.project) {
     disabled = (edit_data.project.task_code === "HO" || edit_data.project.task_code === "TO") ? true:false;
    }
    if (disabled) {
         form_inputs.location = props.DefaultLocation;
    }
    const handleTagInputChange = props.handleTagInputChange;
    return (
        <>
        <Dialog
            className={'modal_default large'}
            open={props.Open}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">Time Entry Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                        <div className={'st-timeEntry_wrapper'}>
                            <div className={'st-action_bar '}>
                                <div className={'st-action_item st-timeEntry_date'}>
                                    <CustomDatePicker
                                        datepickerType='keyboard'
                                        id="timeEntry_date"
                                        name="date"
                                        className={'timeEntry-dateInput'}
                                        selectedDate={form_inputs.date}
                                        handleDateChange={props.handleTimeEntryDateChange}
                                        maxDate={edit_data.max_date}
                                        minDate={props.minDate}
                                        minDateMessage={"Error: Timesheet date should not be prior to Date of Joining"}
                                        shouldDisableDate={(date) => {
                                            let timeentry_date = true;
                                            props.TimeentryPeriods.forEach(period => {
                                                if (moment(date).isBetween(period.start_date, period.end_date, 'date', '[]')) {
                                                    timeentry_date = false;
                                                }
                                            });
                                            return timeentry_date;
                                        }}
                                    />
                                </div>
                            </div>
                            {/*Time entry row [START]*/}
                            <div className={'st-timeEntry st-time-entry-edit'}>
                                <div className={'st-timeEntry_entry-item'}>
                                    <div className={'input-container_left'}>
                                        <div className={'te-row'}>
                                            {/*Task tree [START]*/}
                                            <div className={'te-item te-tree-btn'}>
                                                <Tooltip title="Select Task Using Tree View">
                                                    <IconButton onClick={props.ToggleTreeView('right', true)} aria-label="delete" className={'btn'} size="small">
                                                        <TreeIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Drawer anchor={'right'} open={props.TaskTreeViewState} onClose={props.ToggleTreeView('right', false)}>
                                                    <div className={'tree-view-drawer'}>
                                                        <div className={'drawer-title'}>
                                                            Select Task
                                                        </div>
                                                        <MaterialTreeView
                                                            Data = {project_tree_inputs}
                                                            OnChange = {props.HandleTaskTreeChange}
                                                            RowIndex = {1}
                                                        />
                                                    </div>
                                                </Drawer>
                                            </div>
                                            {/*Task tree [END]*/}
                                            <div className={'te-item te-task st-HSpacingR_1'}>
                                                <Autocomplete
                                                    id = {"project-task"}
                                                    value = {R.type(props.TaskName) !== 'Undefined' && props.TaskName}
                                                    options = {props.ProjectTaskInputs}
                                                    getOptionLabel={option => {
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        if (option.task_path) {
                                                            return option.task_path;
                                                        }
                                                        return option.task_path;
                                                    }}
                                                    filterOptions={(options, state) => {
                                                        if (state.inputValue && state.inputValue !== "") {
                                                            let input_string = state.inputValue.toLowerCase();
                                                            let search_result = options.filter(el => (((el.client_name && el.task_path) && (el.client_name.toLowerCase().includes(input_string) || el.task_path.toLowerCase().includes(input_string))) || (el.project_code.toLowerCase().includes(input_string)) || (el.project_description.toLowerCase().includes(input_string)) || el.task_description.toLowerCase().includes(input_string)));
                                                            return search_result;
                                                        }
                                                        return options;
                                                    }}
                                                    onChange = {props.HandleTasksOnChange}
                                                    groupBy = {(option) => option.group_key}
                                                    renderInput = {(params) => (
                                                    <TextField {...params} label="Select Task" className={'input-dropdown'}/>
                                                    )}
                                                />
                                                <div className={'te-task-helper'}>
                                                    <FormHelperText>{(R.type(form_inputs) !== 'Undefined' && R.type(form_inputs.project_path) !== 'Undefined') && form_inputs.project_path}</FormHelperText>
                                                    {(R.type(form_inputs) !== 'Undefined' && form_inputs.task_description) && 
                                                        <Tooltip title={form_inputs.task_description}><HelpOutlineIcon fontSize='small' /></Tooltip>}
                                                </div>
                                                {(R.type(form_inputs) !== 'Undefined'  && R.type(form_inputs.client_timesheet) !== 'Undefined' && parseInt(form_inputs.client_timesheet) === 1) &&
                                                <span className="tEntry-info"> 
                                                    <span className="wrap"> <WarningIcon/>
                                                        {'Requires client timesheet'}
                                                    </span>
                                                </span>
                                                }
                                                {(R.type(props.TimeEntryEditErrors) !== 'Undefined' && R.type(props.TimeEntryEditErrors.project) !== 'Undefined') &&
                                                <span className="tentry-edit-error">
                                                    {props.TimeEntryEditErrors.project }
                                                </span>
                                                }
                                            </div>
                                            {(props.EnableLocation == true) &&
                                            <div className={(R.type(props.TimeEntryEditErrors) !== 'Undefined' && R.type(props.TimeEntryEditErrors) !== 'Undefined' && R.type(props.TimeEntryEditErrors['location']) !== 'Undefined') ? 'te-item st-HSpacingL_2 te-location te-req-error' : 'te-item st-HSpacingL_2 te-location'}>
                                                <div className={'te-input '}>
                                                    <NativeSelect
                                                        value={R.type(form_inputs) !== 'Undefined' && form_inputs.location}
                                                        displayEmpty
                                                        name={'location'}
                                                        onChange={props.handleTimeEntryChange}
                                                        disabled={disabled}
                                                    >
                                                        <option value="">Location</option>
                                                        { typeof props.SelectedProjectLocations !== 'undefined' && props.SelectedProjectLocations.map((t, i) =>
                                                        <option value={t.location_id}>{t.location_name}</option>
                                                        )}
                                                    </NativeSelect>
                                                </div>
                                                {(R.type(props.TimeEntryEditErrors) !== 'Undefined' && R.type(props.TimeEntryEditErrors.location) !== 'Undefined') &&
                                                    <span className="tentry-edit-error">
                                                        {props.TimeEntryEditErrors.location}
                                                    </span>
                                                }
                                            </div>
                                            }
                                        </div> 
                                        <div className={'te-row'}> 
                                            <div className={'te-item te-description'}>
                                                <TextareaAutosize
                                                    className={'te-description-textarea'}
                                                    placeholder="Description"
                                                    onChange={props.handleTimeEntryChange}
                                                    name={'description'}
                                                    data-title="desc"
                                                    minRows = {3}
                                                    value={R.type(form_inputs) !== 'Undefined' && form_inputs.description}
                                                    error={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.description) ? true : false}
                                                    helperText={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.description) ? props.TimeEntryEditErrors.description : ""}
                                            />
                                            {(props.TimeEntryEditErrors && props.TimeEntryEditErrors.description) &&
                                                <span class="tentry-edit-error">{(props.TimeEntryEditErrors.description)}</span>
                                            }
                                            {(props.Errors && props.Errors.description) &&
                                                <span class="tentry-edit-error">{(props.Errors.description)}</span>
                                            }
                                        </div>
                                        </div>
                                        <div className={'te-row'}>
                                            {props.showReasonForEdit &&
                                            <div className={'te-item te-description time-entry-edit-reason'}>
                                                <TextareaAutosize
                                                    className={'te-description-textarea'}
                                                    placeholder="Reason For Edit"
                                                    onChange={props.handleTimeEntryChange}
                                                    name={'reason'}
                                                    data-title="reason"
                                                    minRows = {2}
                                                    error={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.reason) ? true : false}
                                                    helperText={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.reason) ? props.TimeEntryEditErrors.reason : ''}
                                                />
                                                {(props.TimeEntryEditErrors && props.TimeEntryEditErrors.reason) &&
                                                    <span className="tentry-edit-error">{(props.TimeEntryEditErrors.reason)}</span>
                                                }
                                                {(props.Errors && props.Errors.reason) &&
                                                    <span class="tentry-edit-error">{(props.Errors.reason)}</span>
                                                }
                                            </div>
                                            }
                                        </div>
                                        <div className={'te-row'}>
                                            {(_R.type(edit_data.tag_types) !== 'Null' && edit_data.tag_types !== "") && 
                                                <div className={'te-item te-tag time-entry-edit-tag'}>
                                                        <div className={'ta-tag-title'}>
                                                            Tags
                                                        </div>
                                                        <div className={'tag-col-tags'}>
                                                            {(R.type(props.timeSliceEditData.tags) !== 'Undefined' && props.timeSliceEditData.tags.length > 0) &&
                                                            <div className={'tags'}>
                                                                {props.timeSliceEditData.tags.map((tag,index)=>
                                                                <span className={'tags-tag'}>
                                                                    {tag.tag_name} 
                                                                    <IconButton className={'btn-tags-delete'} aria-label="delete" size="small" >
                                                                        <DeleteIcon fontSize="inherit" data-tag_row_id = {tag.id} onClick={props.onTagDelete} />
                                                                    </IconButton>
                                                                </span>
                                                                )}
                                                            </div>
                                                            }
                                                            {_R.type(edit_data.tagList) !== 'Undefined'&&_R.type(edit_data.tagList.organization) !== 'Undefined'&&
                                                            <div className={'tag-input te-input time-entry-edit-tag'}>
                                                                <FormControl variant='standard'>
                                                                    <InputLabel htmlFor="organization">Account</InputLabel>
                                                                    <Select
                                                                        onChange={handleTagInputChange}
                                                                        inputProps={{
                                                                            name: 'org',
                                                                            id: 'org',
                                                                        }}
                                                                        value = {(_R.type(props.TagInputs) !== 'Undefined'&& !_R.isNil(props.TagInputs.org)) ? props.TagInputs.org : ''}
                                                                    >
                                                                        <MenuItem value={null} key={'0'}>Select</MenuItem>
                                                                        {edit_data.tagList.organization.map((t, i) =>
                                                                        <MenuItem key={t.id} value={t.id}>{t.tag_name}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            }
                                                            {_R.type(edit_data.tagList) !== 'Undefined'&&_R.type(edit_data.tagList.contract) !== 'Undefined'&&
                                                            <div className={'tag-input te-input time-entry-edit-tag'}>
                                                                <FormControl variant='standard'>
                                                                    <InputLabel htmlFor="contract">Contract</InputLabel>
                                                                    <Select
                                                                        onChange={handleTagInputChange}
                                                                        inputProps={{
                                                                            name: 'contract',
                                                                            id: 'contract',
                                                                        }}
                                                                        value = {(_R.type(props.TagInputs) !== 'Undefined'&& !_R.isNil(props.TagInputs.contract)) ? props.TagInputs.contract : ''} 
                                                                    >
                                                                        <MenuItem value={null} key={'0'}>Select</MenuItem>
                                                                        {edit_data.tagList.contract.map((t, i) =>
                                                                        <MenuItem key={t.id} value={t.id}>{t.tag_name}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            }
                                                            {_R.type(edit_data.tagList) !== 'Undefined'&&_R.type(edit_data.tagList.lob) !== 'Undefined'&&
                                                            <div className={'tag-input te-input time-entry-edit-tag'}>
                                                                <FormControl variant='standard'>
                                                                    <InputLabel htmlFor="lob">LOB</InputLabel>
                                                                    <Select
                                                                        onChange={handleTagInputChange}
                                                                        inputProps={{
                                                                            name: 'lob',
                                                                            id: 'lob',
                                                                        }}
                                                                        value = {(_R.type(props.TagInputs) !== 'Undefined'&& !_R.isNil(props.TagInputs.lob)) ? props.TagInputs.lob : ''}
                                                                    >
                                                                        <MenuItem value={null} key={'0'}>Select</MenuItem>
                                                                        {edit_data.tagList.lob.map((t, i) =>
                                                                        <MenuItem key={t.id} value={t.id}>{t.tag_name}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            }
                                                            {_R.type(edit_data.tagList) !== 'Undefined' && 
                                                            <Tooltip title="Add Tag" placement="bottom">
                                                                <Fab
                                                                    variant="extended"
                                                                    size="small"
                                                                    color="primary"
                                                                    aria-label="add"
                                                                    className={'btn-tags-add'}
                                                                >
                                                                    <AddIcon  onClick={props.onAddTag} />
                                                                </Fab>
                                                            </Tooltip>}
                                                        </div>
                                                        {(props.TimeEntryEditErrors && props.TimeEntryEditErrors.tags) &&
                                                        <span className = "tentry-edit-error">{(props.TimeEntryEditErrors.tags)}</span>
                                                        }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={'input-container_right'}>
                                        <div className={'te-row'}>
                                            <div className={'te-time'}>
                                                <Tooltip title="Format: nn.nn or HH:MM" placement="top-start" arrow>
                                                    <TextField
                                                        placeholder="Hours"
                                                        name={'hours'} 
                                                        className={'te-input'}
                                                        onChange={props.handleTimeEntryChange}
                                                        InputLabelProps={{ shrink: true }}
                                                        value={R.type(form_inputs) !== 'Undefined' && form_inputs.hours}
                                                        error={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.hours) ? true : false }
                                                        helperText={(props.TimeEntryEditErrors && props.TimeEntryEditErrors.hours) ? props.TimeEntryEditErrors.hours : ''}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <span className="info">[Format: nn.nn or HH:MM ]</span>
                                            <span className="tEntry-info"> {(R.type(form_inputs) !== 'Undefined' && form_inputs.client_timesheet) && parseInt(form_inputs.client_timesheet) ?<span className="wrap"><WarningIcon/>{'Hours should match with the hours in client timsheet system (Eg: CTMS)'}</span> : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <CustomProgressBar
                        loaderOpen={props.showLoader}
                    />
                    {!props.TimeEntryEditErrors['resolve'] ?
                    <DialogActions>
                    {(R.type(form_inputs) !== 'Undefined' && parseInt(props.NonConfirmity)) ? 
                    <FormControlLabel
                        control={
                        <Checkbox
                            onChange={props.handleTimeEntryChange}
                            value={"true"}
                            name = 'resolve'
                            color="primary"
                        />
                        }
                        label="Resolve issue"
                    /> : <></>
                    }
                    <Button color="primary" size="small" onClick={props.ClearFormClose}>Cancel</Button>
                    {props.Disabled ?
                        <Button variant="contained" size="small" color="primary" disabled onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                    :
                    <ButtonStyles>
                        <div className={'root'}>
                            <div className={'wrapper'}>
                                <Button variant="contained" size="small" color="primary" onClick={props.updateTimeEntry} disabled={_R.type(props.ButtonLoader) !== 'Undefined' && props.ButtonLoader}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                                {_R.type(props.ButtonLoader) !== 'Undefined' && props.ButtonLoader && <CircularProgress size={24} className={'buttonProgress'} />}
                            </div>
                        </div>
                    </ButtonStyles>

                    }
                    {props.DisplayNoButton && 
                        <Button variant="contained" size="small" color="primary" onClick={props.OnNoClick}>No</Button>
                    }
                    </DialogActions>
                    : 
                    <DialogActions>
                        <span className={'error'}>Do you want to save without resolving issues ? </span>
                        <Button variant="contained" size="small" color="default" onClick={() => props.SkipResolveNow(0)}>No</Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => props.SkipResolveNow(1)}>Yes</Button>
                    </DialogActions>
                    }
            </Dialog>
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.DialogClose}
                ButtonName={"OK"}
                OnSubmit={props.DialogClose}
                Title='Time entry edit failed'
                Content= {<>{parse(props.EditErrorMsg?props.EditErrorMsg:'Validation failed')}</>}
            />
            </>
    )
};
