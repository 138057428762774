import React from 'react';
import { httpServices } from '../../../../services/httpServices';
import * as _R from 'ramda';
import { CustomProgressBar } from '../../../common/CustomProgressBar';
import { UserConfigComponent} from './UserConfiguration.Component';


export class UserConfigurationContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            showLoader : true,
            dialogOpen : false,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            editId : 0,
            editPermission : false,
            deletePermission : false,
            userConfigs : [],
            open : false,
            errors : [],
            popupType:'',
            formData : {},
            formInputs : [],
        };
        this.listUserConfig =  this.listUserConfig.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
    }
    componentDidMount() {
        this.listUserConfig();
    }
    listUserConfig = () => {
        this.setState({
            showLoader : true,
        });
        let apiEndpoint = 'api/user/list-user-config';
        return httpServices.post(apiEndpoint,{'user_id':this.props.UserId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            showLoader : false,
                            userConfig : response.data.user_config,
                            permission : response.data.permission,
                            editPermission : response.data.edit_permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }


    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formData;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formData: ff
        });
    };
    handleClickOpen = (id) => {
        const { UserId } = this.props;
        let apiEndpoint = 'api/user/get-user-config';
        return httpServices.post(apiEndpoint, {'user_id':UserId, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            formInputs: response.data,
                            formData: response.data.config,
                            isDelete: false,
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            formInputs : response.data,
                            isEditable: false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formData;
        var val = evt.target.value;
        ff[field] = val;
        this.setState({
            formData: ff
        });
    };

    onSubmit = ()  => {
        if (this.handleValidation()) {
            const userID = this.props.UserId;
            const data = this.state.formData;
            const id = this.state.id;
            data.user_id = userID;
            let apiEndpoint = 'api/user/save-user-config';
            if (id) {
                data.id = id;
                data.action = 'update';
            }
            else {
                data.action = 'add';
            }
            if (data) {
                return httpServices.post(apiEndpoint, data )
                    .then(response => {
                        if (response.data.success) {
                            this.setState({
                                open:false,
                                snackbarOpen : true,
                                snackbarVarient : 'success',
                                snackbarMsg : response.data.success_message,
                            });
                            this.clearForm();
                            this.listUserConfig();
                        } else if (response.data.errors) {
                            this.setState({
                                errors: response.data.errors,
                            });
                        }
                        else{
                             this.setState({
                                open:false,
                                snackbarOpen : true,
                                snackbarVarient : 'error',
                                snackbarMsg : response.data.message,
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let formData = this.state.formData;
        if (!formData.value || formData.value.trim()=='') {
            errors["value"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!formData.config) {
            errors["config"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onDeleteSubmit = () => {
        let apiEndpoint = 'api/user/delete-user-config';
        return httpServices.post(apiEndpoint, {
            'id': this.state.id,
        })
            .then(response => {
                this.setState({
                    open:false,
                    snackbarOpen : true,
                    snackbarVarient : 'success',
                    snackbarMsg : response.data.success_message,
                });
                this.listUserConfig();
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onDelete = (id) => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                <UserConfigComponent
                    UserConfig = {this.state.userConfig}
                    HandleClickOpen={this.handleClickOpen}
                    OnClose = {this.onClose}
                    FormData = {this.state.formData}
                    FormInputs = {this.state.formInputs}
                    Open = {this.state.open}
                    Errors = {this.state.errors}
                    HandleOnChange = {this.handleOnChange}
                    OnDelete = {this.onDelete}
                    isDelete = {this.state.isDelete}
                    isEditable = {this.state.isEditable}
                    onDeleteSubmit = {this.onDeleteSubmit}
                    onSubmit = {this.onSubmit}
                    SnackbarOpen={this.state.snackbarOpen}
                    SnackbarVarient={this.state.snackbarVarient}
                    SnackbarMsg={this.state.snackbarMsg}
                    SnackbarClose={this.snackbarClose}
                    EditPermission = {this.state.editPermission}
                />
            </>
        )
    }
};
