export const prepareData = (data, all_option = true) => {
    let  list = [];
    let selected = [];
    let all = {};
    if (data.length > 0) {
        if (data[0].client_id) {
            all = {client_id : 0, client_name : "All Clients", value: 'selectAll'};
        }
        else if (data[0].user_id) {
            all = {user_id : 0, full_name : "All Staff"};
        }
        else if (data[0].id && data[0].type) {
            all = {id :0, type: "All"};
        }
        else if (data[0].id && data[0].title) {
            all = {id :0, title: "All", value:0};
        }
        else if (data[0].id) {
            all = {id : 0, name: "All Locations" ,short_code: "All" };
        }
        if (all_option) {
            data.unshift(all);
            selected = [all];
        }
        list = data;
    }
    let rdata = {'list' : list, 'selected': selected};
    return rdata;
}
