import React from 'react';
import Grid from '@mui/material/Grid';
import { formatName } from '../../../common/common';
import Paper from "@mui/material/Paper";
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';

export const HistoryComponent = (props) => {
    const gridStyle = {
        display: "block",
        padding: "24px",
        position: "relative",
        background: "#fff",
    };
    return (
        <>
            <React.Fragment>
                <Paper className={"mb-1"} style={gridStyle}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div className={"profile-view__basics_default"}>
                                <div className={"profile-view__basics_default-item item-role"}>
                                    <label>Created By</label>
                                    <span className={"value"}>
                                        {formatName(props.StaffDetails.cby_first_name, null, props.StaffDetails.cby_last_name)}
                                    </span>
                                </div>
                                <div className={"profile-view__basics_default-item item-role"}>
                                    <label>Modified By</label>
                                    <span className={"value"}>
                                        {formatName(props.StaffDetails.mby_first_name, null, props.StaffDetails.mby_last_name)}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={"profile-view__basics_default"}>
                                <div className={"profile-view__basics_default-item item-role"}>
                                    <label>Created On</label>
                                    <span className={"value"}>
                                        {props.StaffDetails.created_on}
                                    </span>
                                </div>
                                <div className={"profile-view__basics_default-item item-role"}>
                                    <label>Modified On</label>
                                    <span className={"value"}>
                                        {props.StaffDetails.modified_on}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
	            <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Change History"}
                        columns={[
                            {title: 'Date and Time',field: 'created_on'},
                            {title: 'Modified By',field: 'user_fullname'},
                            {title: 'Entity',field: 'entity_name'},
                            {title: 'Entity Id',field: 'entity_id'},
                            {title: 'Change Log',field: 'changelog', render: rowData => (
                                <pre>{JSON.stringify(JSON.parse(rowData.changelog), null, 3)}</pre>
                            )},
                        ]}
                        data={props.History}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	            </div>
            </React.Fragment>
        </>
    )
};

