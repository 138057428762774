import React from 'react';
import { httpServices } from '../../../services/httpServices';
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { DialogBoxLarge } from "../../common/dialog.large";

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import {UserEditComponent } from './UserEdit.Component';


const filters = {
    category : [
        { id : 0, value : "salary", title : "Salary"},
        { id : 1, value : "hourly", title : "Hourly"}
    ],
    overtime : [
        { id : 0, value : "exempt", title : "Exempt"},
        { id : 1, value : "non-exempt", title : "Non-exempt"}
    ],
    payperiod : [
        { id : 0, value : "monthly", title : "Monthly"},
        { id : 1, value : "semi-monthly", title : "Semi-Monthly"},
    ],
}
export class UserEditContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            errors : [],
            formFields : [],
            filters:filters,
            userType : 0,
            buttonLoader : false,
            dataLoaded : false,
            showLoader : true,
            open : false,
            popup : '',
            snackbarOpen : false,
            snackbarMsg : '',
            errorMessage : '',
            form : 'edit',
            subordinates : [],
            projects : [],
            buttonLoader : false,
            servicePeriodEditPermission : false,
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.onClose = this.onClose.bind(this);
        this.createServicePeriod = this.createServicePeriod.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onServicePeriodSubmit = this.onServicePeriodSubmit.bind(this);
        this.onServicePeriodClose = this.onServicePeriodClose.bind(this);
    }

    componentDidMount(){
        this.getDetails(this.props.match.params.id);
    }
    getDetails = (id) => {        
        let apiEndpoint = 'api/user/edit';
        return httpServices.post(apiEndpoint,{user_id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        let filter=this.state.filters;
                        filter.statuses = response.data.statuses;
                        filter.user_types = response.data.user_types;
                        filter.staff_types = response.data.staff_types;
                        filter.organizations = response.data.organizations;
                        filter.employment_types = response.data.employment_types;
                        this.setState({
                            formFields : response.data.user,
                            permission : response.data.permission,
                            filters : filter,
                            userType : response.data.user.user_type_id,
                            subordinates : response.data.subordinates,
                            projects : response.data.assigned_projects,
                            dataLoaded : true,
                            showLoader : false,
                            servicePeriodEditPermission : response.data.sp_edit_permission,
                        });
                    }
                    else{
                        this.setState({
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        if (field === 'email_notification' || field === 'secondary_email_notification' || field === 'email_own_action') {
            ff[field] = evt.target.checked ? 1 : 0;
        }
        else if(field === 'include_holidays') {
            let val = evt.target.value;
            ff[field] = (val === 'Yes') ? 'No' : 'Yes';
        }
        else if (field === 'user_status' && evt.target.value === 3 ) {
            if (this.state.subordinates.length || this.state.projects.length) {
                this.setState({
                    open : true,
                    popup : 'edit_warning'
                });
            }
            else {
                ff[field] = evt.target.value;
            }
        }
        else {
            ff[field] = evt.target.value;
        }
        if (field === 'user_status' && parseInt(evt.target.value) === 1 && this.state.userType === 1 && this.state.servicePeriodEditPermission) {
            this.setState({
                open : true,
                popup : 'service_period'
            });
        }
        this.setState({
            formFields: ff,
        });

    };

    handleSubmit = () => {
        if(this.handleValidation()) {
            this.setState({ showLoader: true})
            let data = this.state.formFields;
	    data.first_name = this.state.formFields.first_name.trim();
	    data.last_name = this.state.formFields.last_name.trim();
            data.user_type = this.state.userType;
            data.action = 'submit';
            data.user_mobile = this.state.formFields.user_mobile ? this.state.formFields.user_mobile.replace( /[\s- .)(]/g, '') : null;
            return httpServices
                .post("api/user/edit",
                    data            
                )
                .then((response) => {
                    if (response.data.success) {
                         this.setState({
                            snackbarOpen : true,
                            snackbarMsg : 'User updated successfully',
                       },()=>{window.history.back();});
                    } else {
                       this.setState({ 
                           errors: response.data.errors ,
                            showLoader:false
                       });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let data = this.state.formFields;
        let form = this.state.form;
        if (form === 'edit') {
            let user_email = data.user_email;
            let user_mobile = data.user_mobile;
            if (data.first_name.trim() === '') {
                errors["first_name"] = "Cannot be empty";
                formIsValid = false;
            }
            if(data.user_type_id != 3 && (!data.last_name || data.last_name.trim() === '') )
            {
                errors["last_name"] = "Cannot be empty";
                formIsValid = false;
            }
            if(data.user_type_id !== this.state.userType)
            {
                if( !data.date_joined ){
                    errors["date_joined"] = "Cannot be empty";
                    formIsValid = false;
                }
            }
            if(parseInt(this.state.userType) === 1 && parseInt(data.user_status) === 3)
            {
                if(!data.relieved_date)
                {
                    errors["relieved_date"] = "Relieved date cannot be empty";
                    formIsValid = false;
                }
                if (data.date_joined && data.relieved_date) {
                    let date_joined_obj = new Date(data.date_joined);
                    let relieved_date_obj = new Date(data.relieved_date);
                    if(relieved_date_obj < date_joined_obj){
                        errors["relieved_date"] = "Please enter a valid relieved date";
                        formIsValid = false;
                    }
                }
            }
            if ( (!user_mobile || user_mobile.trim() === '') && parseInt(data.user_type_id) !== 3 ) {  
                errors['user_mobile'] = "Cannot be empty";
                formIsValid = false;
            }
            else if (user_mobile) {
                let reg = /^(\+\d{1,2}[\s.-]?)\(?\d{3}\)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{4}$/;
                let ok = reg.exec(user_mobile);
                if (!ok) {
                    errors["user_mobile"] = "Invalid phone number. Valid format is +number";
                    formIsValid = false;
                }
            }
            if (!user_email || user_email.trim() === '') {
                errors['user_email'] = "Cannot be empty";
                formIsValid = false;
            }
            else if (user_email) {
                let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                let ok = reg.exec(user_email);
                if (!ok) {
                    errors["user_email"] = "Email isn't valid";
                    formIsValid = false;
                }
            }
        }
        else {
            let daily_minimum = data.daily_minimum;
            let weekly_limit = data.weekly_limit;
            if (!data.staff_type) {
                errors["staff_type"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!data.start_date) {
                errors["start_date"] = "Cannot be empty";
                formIsValid = false;
            }
            if (daily_minimum) {
                let reg = /^[0-9]+$/;
                let ok = reg.exec(daily_minimum);
                if (!ok) {
                    errors["daily_minimum"] = "Daily Minimum isn't valid";
                    formIsValid = false;
                }
            }
            if (weekly_limit) {
                let reg = /^[0-9]+$/;
                let ok = reg.exec(weekly_limit);
                if (!ok) {
                    errors["weekly_limit"] = "Weekly Limit isn't valid";
                    formIsValid = false;
                }
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    onClose = () => {
       this.setState({
           open:false
       });
    }
    createServicePeriod = () => {
       this.setState({
           popup : 'add_service_period',
           open:true
       });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onServicePeriodSubmit = () => {
        let formdata = this.state.formFields;
        this.setState({
            form : 'serviceperiod'
       },()=>{
           if (this.handleValidation()) {
               this.setState({buttonLoader : true});
                let apiEndpoint = 'api/user/add-service-period';
                let data = {'start_date' : formdata.start_date,
                    'staff_type':formdata.staff_type,
                    'organization':formdata.organization? formdata.organization : null,
                    'end_date':formdata.end_date ? formdata.end_date:null,
                    'category':formdata.category? formdata.category:null,
                    'overtime':formdata.overtime? formdata.overtime:null,
                    'daily_minimum':formdata.daily_minimum? formdata.daily_minimum:null,
                    'weekly_limit':formdata.weekly_limit? formdata.weekly_limit:null,
                    'include_holidays':formdata.include_holidays? formdata.include_holidays:null,
                    'is_relieved' : 1,
                    'user_id' : formdata.user_id,
                    'employment_type' : formdata.employment_type ? formdata.employment_type : null,
                }
                if (data) {
                    return httpServices.post(apiEndpoint, { data })
                        .then(response => {
                            if (response.data.valid) {
                                this.setState({
                                    open:false,
                                    snackbarOpen : true,
                                    snackbarVarient : 'success',
                                    snackbarMsg : response.data.msg,
                                    form : 'edit',
                                    buttonLoader : false
                                },()=>{this.handleSubmit();});
                            }
                            else {
                                let input_errors = response.data.input_errors;
                                if (Object.keys(input_errors).length) {
                                    this.setState({
                                        errors:response.data.input_errors,
                                        buttonLoader : false
                                    });
                                }   
                                else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                    buttonLoader : false
                                });
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
                }
            }
        });
    }
    onServicePeriodClose = () => {
        this.setState({
            form : 'edit',
            open : false,
            errors : {},
            errorMessage : ''
       });
    }

   render(){
       return(
           <>
           <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
           {this.state.dataLoaded ?
               <UserEditComponent
                    FormFields = {this.state.formFields}
                    HandleOnChange = {this.HandleOnChange}
                    Errors = {this.state.errors}
                    Filters = {this.state.filters}
                    UserType = {this.state.userType}
                    OnSubmit = { ()=>this.handleSubmit()}
                    Loading = {this.state.buttonLoader}
                    Open = {this.state.open}
                    PopupType = {this.state.popup}
                    Close = {this.onClose}
                    CreateServicePeriod = {this.createServicePeriod}
                    SnackbarOpen = {this.state.snackbarOpen}
                    SnackbarMsg = {this.state.snackbarMsg}
                    SnackbarClose = {this.snackbarClose}
                    OnServicePeriodSubmit = {this.onServicePeriodSubmit}
                    ErrorMessage = {this.state.errorMessage}
                    OnServicePeriodClose = {this.onServicePeriodClose}
                    Subordinates = {this.state.subordinates}
                    Projects = {this.state.projects}
                    ButtonLoader = {this.state.buttonLoader}
                />
               :<></>}
           </>

       )
   }
};
