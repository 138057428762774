import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

export const DialogBoxMediumAlert = (props) => {
    return (
        <Dialog
                className={'modal_default'}
                open={props.Open}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogContent>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                    {props.Content}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" size="small" onClick={props.Cancel}>{props.CancelButtonName ? props.CancelButtonName : 'Cancel'}</Button>
                </DialogActions>
            </Dialog> 
    )
}

