import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ClientEditComponent } from './ClientEdit.Component';

const FormFields = {
    client_status: null,
    client_name : null,
    client_description : null,
    client_code : null,
    type : null,
};

export class ClientEditContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            project : [],
            errors : [],
            showLoader : false,
            clientId :0,
            projects : [],
            client : '',
            FormFields : FormFields,
            ClientStatus : this.props.Status,
            ClientTypes : this.props.ClientTypes,
            snackbar: false,
            valid: false,
            success_message: null,
            error_message: null,
            editPermission : false,
            taskCount : 0,
            popupType: "",
            open: false,
        };
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        if (this.props.EditId) {
            var id = (this.props.EditId) ? this.props.EditId: false;
            this.setState({ clientId : id } , function() {
                this.getClientDetails(this.state.clientId);
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.Open && this.props.EditId && this.props.EditId !== this.state.clientId) {
            this.setState({ clientId: this.props.EditId }, function() {
                this.getClientDetails(this.state.clientId);
            });
        }
        else {
            if (this.props.Open && this.props.Open !== this.state.open) {
                this.setState({open : this.props.Open,
                popupType : this.props.popupType,
                permission : true,
                ClientStatus: this.props.Status,
                ClientTypes: this.props.ClientTypes,
            });
            
            }
        }
    }

     getClientDetails = (clientId) => {
        let apiEndpoint = 'api/client/details';
        return httpServices.post(apiEndpoint,{id : clientId,action : 'edit'})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            FormFields : response.data.client,
                            showLoader : false,
                            permission : true,
                            editPermission : response.data.edit_permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

     onClose = () => {
        this.setState({
            open: false,
            errors : [],
            clientId : 0,
        });
        this.clearForm();
        this.props.UpdateParentOnSuccess(false);
    };
     snackbarClose = () => {
        this.setState({
            snackbar: false,
        });
    };
     handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.FormFields.client_status || this.state.FormFields.client_status === '0') {
            errors["client_status"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.FormFields.type || this.state.FormFields.type === '0') {
            errors["type"] = "Cannot be empty";
            formIsValid = false;
        }
         if (!this.state.FormFields.client_name) {
            errors["client_name"] = "Cannot be empty";
            formIsValid = false;
        }
         if (!this.state.FormFields.client_description) {
            errors["client_description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.FormFields.client_code) {
            errors["client_code"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.FormFields;
        ff[field] = evt.target.value;
        this.setState({
            FormFields: ff,
        });
    };

    handleSubmit = (id) => {
        if (this.handleValidation()) {
            this.setState({ loaderOpen: true });
            let editId = id ? id : 0;
            return httpServices
                .post("api/client/submit", {
                    client_id: editId,
                    client_name: this.state.FormFields.client_name,
                    client_description: this.state.FormFields.client_description,
                    client_code: this.state.FormFields.client_code,
                    client_status: this.state.FormFields.client_status,
                    type: this.state.FormFields.type,
                })
                .then((response) => {
                        if (response.data.success) {
                            this.setState({
                                success_message: response.data.success,
                                open: false,
                                snackbar: true,
                                valid: true,
                                clientId: 0,
                            });
                            this.clearForm();
                            this.props.UpdateParentOnSuccess(false);
                        }
                        else if (response.data.validation_errors) {
                            this.setState({ errors: response.data.validation_errors });
                        }
                        else {
                            let error_msg = response.data.error;
                            let error_val = '';
                            if (typeof error_msg === 'object' && error_msg !== null) {
                                Object.values(error_msg).forEach(val => {
                                    error_val += val;
                                    error_val += ',';
                                });
                            }
                            else {
                                error_val = error_msg;
                            }
                            this.setState({
                                open: true,
                                snackbar: true,
                                valid: false,
                                error_message: error_val,
                            });

                        }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };

    clearForm = () => {
        let ff = this.state.FormFields;
        for (let key in ff) {
             ff[key] = null;
        }
        this.setState({
            FormFields: ff
        });
    };

   render(){
       return(
           <ClientEditComponent
           Projects = {this.state.projects}
           Client = {this.state.client}    
           FormFields = {this.state.FormFields}
           Snackbar = {this.state.snackbar}
           PopupType={this.props.PopupType}
           Errors={this.state.errors}
           ClientStatus={this.state.ClientStatus}
           ClientTypes= {this.state.ClientTypes}
           OnClose={this.onClose}
           Open={ this.props.Open }
           HandleOnChange = {this.HandleOnChange}
           HandleSubmit = {this.handleSubmit}
           Valid={this.state.valid}
           ErrorMessage={this.state.error_message}
           SuccessMessage={this.state.success_message}
           SnackbarClose={this.snackbarClose}
           EditPermission = {this.state.editPermission}
           ClientId ={this.state.clientId}
           />

       )
   }
};
