import React from 'react';

import {history} from './../../helpers/history';
import {httpServices}  from '../../services/httpServices';
import {SystemRoleComponent}  from './SystemRoleComponent';
import { StaffConstants } from '../../constants';
import { ErrorPage } from '../common/error';

const formFields = {
    'id':0,
    'name': '',
    'description': '',
};

export class SystemRole extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            roles : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            snackbarOpen: false,
            snackbarType: null,
            snack_message: ''
        };
        this.editRoleClick = this.editRoleClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAddRole = this.onAddRole.bind(this);
        this.deleteRoleClick = this.deleteRoleClick.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.viewRoleDetails = this.viewRoleDetails.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    componentDidMount = () => {
        this.getAllRoles();
    };
    getAllRoles = () => {
        let apiEndpoint = 'api/system/role/get-all-roles';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    if (response.data.roles && response.data.roles.length) {
                        this.setState({roles : response.data.roles});
                    }
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    }
    editRoleClick = (id) => () => {
        let apiEndpoint = 'api/system/role/get-role-details-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                    if (response.data.role_details) {
                        this.setState({
                            formFields : response.data.role_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddRole = () => {
        let formFields = {
            'id':0,
            'name': '',
            'description': '',
        };
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/system/role/saveRole', { data })
                    .then(response => {
                        if (response.data.status) {
                            if (data.id) {
                                // Update DOM
                                const index = this.state.roles.findIndex(rr => rr.id === data.id);
                                let roles = this.state.roles;
                                roles[index] = data;
                                this.setState({
                                    roles: roles,
                                    open: false,
                                    snack_message : "Role details updated successfully",
                                    snackbarOpen: true,
                                    snackbarType: "success"
                                });
                            } else {
                                let roles = this.state.roles;
                                data['id'] = parseInt(response.data.id);
                                roles.push(data);
                                this.setState({
                                    roles: roles,
                                    open: false,
                                    snack_message : "Role details saved successfully",
                                    snackbarOpen: true,
                                    snackbarType: "success"
                                });
                            }
                            this.getAllRoles();
                        } else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                            else {
                                alert(response.data.error_msg);
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    deleteRoleClick = (id) => () => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteRoleId : id,
            });
        }
    };
    deleteRole = () => {
        let del_id = this.state.deleteRoleId;
        if (del_id) {
            let apiEndpoint = 'api/system/role/delete-role';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    if (response.data.status) {
                    // Dom updation
                    const index = this.state.roles.findIndex(rr => rr.id === del_id);
                    let roles = this.state.roles;
                    roles.splice(index, 1);
                    this.setState({
                        roles : roles,
                        open : false,
                        snack_message : "Role deleted successfully",
                        snackbarOpen: true,
                        snackbarType: "success"
                    });
                }
                else
                {
                    this.setState({
                        open : false,
                        snack_message : response.data.error_msg,
                        snackbarOpen: true,
                        snackbarType: "error"
                    });
                }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };
    viewRoleDetails  = (roleId) => () =>{
        history.push(StaffConstants.ROLE_DETAILS_PAGE + '/' + roleId);
    }

    render(){
        return (
            (this.state.hasPermission)
            ?
            <SystemRoleComponent
                Roles = {this.state.roles}  
                EditRoleClick = {this.editRoleClick}
                Open = {this.state.open}
                IsEditable = {this.state.isEditable}
                FormFields = {this.state.formFields}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddRoleClick = {this.onAddRole}
                PopupType = {this.state.popupType}
                DeleteRoleClick = {this.deleteRoleClick}
                DeleteRole = {this.deleteRole}
                ViewRoleDetails = {this.viewRoleDetails}                
                SnackbarOpen={this.state.snackbarOpen}
                SnackbarType={this.state.snackbarType}
                SnackbarClose={this.snackbarClose}
                Message = {this.state.snack_message}
            />
            :
            <ErrorPage/>
        )
    }
}
