import Drawer from '@mui/material/Drawer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';

const drawerWidth = 600;
const StyledDrawer = styled(Drawer) (({ theme }) => ({
    '& .drawer': {
        width: drawerWidth,
    }
}));

export const PreviewComponent = (props) => {
    return (
        <ClickAwayListener onClickAway = {props.FilterView('right', false)}>
            <StyledDrawer open = {props.FileOpen} variant = {props.Variant} anchor = {props.Anchor}  className = {'drawer'} >
                <div className = {'tree-view-drawer'} style = {{minWidth:'600px'}}>
                    <div>
                        <strong> 
                            { props.PreviewedItem.date } 
                            { props.PreviewedItem.project_name ?  ' - ' + props.PreviewedItem.project_name  : '' } 
                            { props.PreviewedItem.category_name ? ' - ' + props.PreviewedItem.category_name : '' } 
                        </strong>
                    </div>
                </div>
            <iframe src={props.FileUrl}  width="600px"  height="500px" />
            </StyledDrawer>
        </ClickAwayListener>
    )
}
