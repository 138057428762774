import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment';
import EventIcon from "@mui/icons-material/Event";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
export const CustomDatePicker = (props) => {
    let clearDate = (e) => {
        e.stopPropagation();
        if (props.selectedDate) {
            props.handleDateChange(null, props.name);
        }
    }
    let disableDays = (date) =>{
        if (typeof props.shouldDisableDate!=='undefined') {
            return props.shouldDisableDate(date);
        }
        return false;
    }
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment} >
	         <DatePicker
                        autoOk = {(typeof props.autoOk !== 'undefined') ? props.autoOk : true}
                        views = {(typeof props.view !== 'undefined') ? props.view : ['year', 'month', 'day']}
                        variant= {(typeof props.variant !== 'undefined') ? props.variant : "inline"}
                        type = "text"
                        id = {(typeof props.id !== 'undefined') ? props.id : "date"}
                        name = {(typeof props.name !== 'undefined') ? props.name : "date"}
                        inputFormat = {dateFormat}
                        label = {(typeof props.label !== 'undefined') ? props.label : ""}
                        onChange = {(date) =>{props.handleDateChange(date ? date.format('YYYY-MM-DD') : null,props.name)}}
                        value = {props.selectedDate ? moment(props.selectedDate) : null}
                        KeyboardButtonProps = {{'aria-label': 'change date',}}
                        minDateMessage = {(typeof props.minDateMessage !== 'undefined') ? props.minDateMessage : ""}
                        maxDateMessage = {(typeof props.maxDateMessage !== 'undefined') ? props.maxDateMessage : "Future date is not allowed"}
                        minDate = {props.minDate ? moment(props.minDate) : undefined}
                        maxDate = {props.maxDate ? moment(props.maxDate) : undefined}
                        disabled = { (typeof props.disabled !== 'undefined' && props.disabled)? true : false}
                        className = { (typeof props.className !== 'undefined') ? props.className : ''}
                        invalidDateMessage = {null}
                        inputProps={{ tabIndex: (typeof props.tabIndex !== 'undefined') ? props.tabIndex : "0" }}
                        shouldDisableDate={disableDays}
                        slotProps={{
                            field: { clearable: (typeof props.clearable !== 'undefined' && props.clearable), onClear: (e) => clearDate(e) },
                            textField: {
                                required: (typeof props.required !== 'undefined' && props.required) ? true : false,
                              },
                        }}
                 />
            </LocalizationProvider>
            <small className='error'>{(typeof props.error !== 'undefined') && props.error}</small>
        </>
    );
}   
