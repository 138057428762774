import React from 'react';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';

import { httpServices } from '../../../../services/httpServices';
import { StaffPersonalDetails } from "./StaffPersonalDetails.Container";
import { DisabilityDetails } from "./Disability.Container";
import { MaritalStatus } from "./MaritalStatus.Container";
import { IdProofDetails } from "./IdProof.Container";
import { PhotoContainer } from './Photo.Container';
import { Education } from './Education';

export class PersonalDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {        
            formInputs : {},
        };
    }

    componentDidMount = () => {
        this.getStaffPersonalInputData();
    }

    getStaffPersonalInputData = () => {
        let apiEndpoint = 'api/staff/personal/getStaffPersonalInputData';
        let staff_id = 0;
        if (this.props.staffDetails) {
            staff_id = this.props.staffDetails.staff_id;
        }
        return httpServices.post(apiEndpoint, {staff_id})
            .then(response => {
                if (response.data) {
                    this.setState({formInputs : response.data});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    render(){
        return (
            <PersonalComponent
                updateChange = { this.props.updateChange } 
                staffDetails = { this.props.staffDetails }   
                formInputs = { this.state.formInputs }
                Edit = { this.props.staffDetails.personal_edit_permission }
                PhotoPermission = {this.props.staffDetails.photo_edit_permission}
                View = { this.props.staffDetails.personal_view_permission }
                Delete = { this.props.staffDetails.personal_delete_permission }
            />
        )
    }
}

export const PersonalComponent = (props) => { 
    const { staffDetails, formInputs, View, Delete, Edit, PhotoPermission } = props;
    let data = (_R.type(staffDetails) !== 'Undefined') ? staffDetails : {};
    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                <Grid item xs={12}>
                    <StaffPersonalDetails
                        updateChange = { props.updateChange } 
                        staffDetails = { data }
                        formInputs = { formInputs.personal_input_data }
                        Edit = { Edit }
                    />                   
                </Grid>
                <Grid  item xs={12} md={6}>
                    <div className={'section-main'}>
                    { _R.type(data) !== 'Undefined'
                        ?
                        <DisabilityDetails 
                            disabilityDetails = { data.disability }
                            staffId = { data.staff_id }
                            formInputs = { formInputs }
                            Edit = { Edit }
                            View = { View }
                            Delete = { Delete }
                        />
                        : <></> }
                    </div>
                    <div className={'section-main'}>
                    { _R.type(data) !== 'Undefined'
                        ? 
                        <MaritalStatus
                            maritalDetails = { data.marital_details }
                            staffId = { data.staff_id }
                            formInputs = { formInputs }
                            Edit = { Edit }
                            View = { View }
                            Delete = { Delete }
                        />
                        : <></> }
                    </div>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <div className={'section-main'}>
                    { _R.type(data) !== 'Undefined'
                        ?
                        <IdProofDetails
                            idProofDetails = { data.idProofDetails }
                            staffId = { data.staff_id }
                            formInputs = { formInputs }
                            Edit = { Edit }
                            View = { View }
                            Delete = { Delete }
                            idProofnumber_view = {data.idproof_view_permission}
                            idProofnumber_edit = {data.idproof_edit_permission}
                            idProofnumber_delete = {data.idproof_delete_permission}
                            idProofnumber_fullview = {data.ssn_view_permission}
                        />
                        : <></> }
                    </div>
                    <div className={'section-main'}>
                        {_R.type(data) !== 'Undefined'
                        ? 
                        <Education
                            Education = {data.education}
                            StaffID = { data.staff_id }
                            UserID = { data.user_id }
                            formInputs = { formInputs }
                            Edit = { Edit }
                            View = { View }
                            Delete = { Delete }
                            updateChange={props.updateChange}
                        />
                        : <></> }
                    </div>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <div className={'section-main'}>
                    { _R.type(data) !== 'Undefined'
                        ?
                        <PhotoContainer
                            photos = {formInputs.photos ? formInputs.photos : data.photos}
                            staffId = { data.staff_id }
                            formInputs = { formInputs }
                            Edit = { PhotoPermission }
                            updateChange = { props.updateChange } 
                        />
                        : <></> }
                    </div>
                </Grid>

            </Grid>
        </React.Fragment>
    )
};
