import React, { useState } from 'react';
import  {useDropzone} from 'react-dropzone';

import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import { DialogBoxMedium } from './dialog.medium';

export const CustomDropZone= props => {


  const [errors, setErrors] = useState("");
  let original_files = props.OriginalFileNames;
  let attached_files = props.attachedFileNames;
  if (attached_files) {
      attached_files.forEach(myFunction);
      function myFunction(item, index) {
          item['show_remove'] = true;
          if (original_files) {
              original_files.forEach(i => {
                if ((parseInt(i.file_id) === parseInt(item['file_id'])) && (parseInt(i.uploaded_by) !== parseInt(props.LoginUser))) {
                    item['show_remove'] = false;
                }
           });
          }
        }
  }
  const {getRootProps, getInputProps, open} = useDropzone({
    accept: props.Filetype,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles,fileRejections) => {
        if (fileRejections.length) {
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    setErrors(`${err.message}`);
                });
            });
        } else {
            setErrors("");
        }
        if (acceptedFiles.length) {
            props.fileUploadCallback(acceptedFiles);
        }
    }
  });


  return (
      <>
        <div className="dropzone-container">
            <label>Attachments</label>
            <div {...getRootProps({className: 'dropzone'})} >
                <input {...getInputProps()} />
                <p>Drag and drop files here</p>
                <Button variant="outlined" className={'btn'} size="small"  onClick={open}>
                Upload Files
                </Button>
                <Button variant="outlined" className={'btn'} size="small" onClick={props.getRecentFiles}>
                Select Recent Files
                </Button>
            </div>
            <div className="files">
                <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>{errors}</p>
                <ul>
                    {(attached_files) && attached_files.map((row, i) =>
                        <li><div className="file-name">{row.name}</div>{row.show_remove && <IconButton size="small" onClick={() => props.removeItem(row.file_id)}><HighlightOffIcon fontSize={'small'}/></IconButton> }</li>
                    )}
                </ul>
            </div>
        </div>	
        <DialogBoxMedium
            Open={props.Open}
            Cancel={props.cancel}
            ButtonName={'Attach'}
            OnSubmit={props.attachFiles}
            Title={props.uploadTitle}
            RecentFiles={props.recentFiles}
            Content={
                <FileUploader FormProps={props} />
            }
        />
      </>
  );
}

const FileUploader = (p) => {
    // Tabs
    const { FormProps: props } = p;
    return (

        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-body'}>
                    <Paper>
                        <div className={'ml-1'}>
                                <div className={'uploads'}>
                                    {(typeof props.recentFiles !== 'undefined' && props.recentFiles.length) ?
                                        <>
                                            {props.recentFiles.map((rf, i) =>
                                                <div className={"item"}>
                                                 <label>
                                                        <Checkbox
                                                            onChange={props.handleAttachmentSelection}
                                                            value={rf.id}
                                                            checked={props.selectedFile.includes(parseInt(rf.id))}
                                                            color="primary"
                                                        />
                                                        <span className={'value'}>{rf.name}<span className={"info"}>{rf.uploaded_on}</span></span>
                                                    </label>
                                                </div>
                                            )}
                                        </>
                                        : <div>No recent files found</div>
                                    }
                                </div>
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>
    );
}

