import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import { history } from '../../../helpers/history';

import { formatName } from '../../common/common';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

function viewRequest(id){
  history.push(BASE_PATH+'/requests/location/view/' + id);
 }

export const LocationChangeRequest = (props) => {
    return (
        <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
            {_R.isEmpty(props.OffsiteRequest) === false &&
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Reference No</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Reviewed By</TableCell>
                            <TableCell>Reviewed On</TableCell>
                        </TableRow>
                    </TableHead>
                    {_R.type(props.OffsiteRequest) !== 'Undefined' && (props.OffsiteRequest).map((item) =>
                    <TableBody>
                        <TableRow key = {item.id}>
                            <TableCell component="th" scope="row"><a onClick={()=>viewRequest(item.id)} className='link'> {item.id} </a></TableCell>
                            <TableCell component="th" scope="row"> {item.description} </TableCell>
                            <TableCell component="th" scope="row"> {item.start_date} - {item.end_date}</TableCell>
                            <TableCell component="th" scope="row"> {item.location_name} </TableCell>
                            <TableCell component="th" scope="row"> {item.hours} </TableCell>
                            <TableCell component="th" scope="row"> {item.reference_no} </TableCell>
                            <TableCell component="th" scope="row"> {item.project_name ? item.project_name : "All projects"} </TableCell>
                            <TableCell component="th" scope="row"> {item.request_status_name} </TableCell>
                            <TableCell component="th" scope="row"> {formatName(item.rby_first_name,null,item.rby_last_name)} </TableCell>
                            <TableCell component="th" scope="row"> {item.reviewed_on != 0 ? item.reviewed_on : null} </TableCell>
                        </TableRow>
                    </TableBody>
                    )}
                </Table>
            }
            {_R.isEmpty(props.OffsiteRequest) &&
                <Table  size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row" colSpan="8">No location change requests for this period</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            }
        </div>
    );
}
