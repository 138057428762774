import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatName } from "../../common/common";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { DialogBoxMedium } from '../../common/dialog.medium';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';

export const EstimationComponent = (props) => {
    const TableContainers = styled(TableContainer)(({ theme }) => ({       
        '& .container': {
            maxHeight: 440,
        },
    }));
    return (
        <React.Fragment>
            <Paper>
                <div className={"p1"}>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={"section-title"}
                    ></Typography>
                    <form className="form-fullwidth">
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="task_type">
                                        Task Type
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof props.ReportFields
                                                .task_type !== "undefined"
                                                ? props.ReportFields.task_type
                                                : "All"
                                        }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: "task_type",
                                            id: "task_type",
                                        }}
                                    >
                                        <MenuItem value="All">-All-</MenuItem>
                                        {typeof props.ReportFields.task_types !=
                                            "undefined" &&
                                            props.ReportFields.task_types !==
                                                "" &&
                                            props.ReportFields.task_types.map(
                                                (t, i) => (
                                                    <MenuItem
                                                        value={t.code}
                                                        key={"stst-" + i}
                                                    >
                                                        {t.name}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="task_status">
                                        Task Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof props.ReportFields
                                                .task_status !== "undefined"
                                                ? props.ReportFields.task_status
                                                : 1
                                        }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: "task_status",
                                            id: "task_status",
                                        }}
                                    >
                                        <MenuItem value="0">-All-</MenuItem>
                                        {typeof props.ReportFields
                                            .task_statuses != "undefined" &&
                                            props.ReportFields.task_statuses !==
                                                "" &&
                                            props.ReportFields.task_statuses.map(
                                                (t, i) => (
                                                    <MenuItem
                                                        value={t.task_status_id}
                                                        key={"stst-" + i}
                                                    >
                                                        {t.task_status_name}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="user_status">
                                        Staff Status
                                    </InputLabel>
                                    <Select
                                        value={
                                            typeof props.ReportFields
                                                .user_status !== "undefined"
                                                ? props.ReportFields.user_status
                                                : 1
                                        }
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: "user_status",
                                            id: "user_status",
                                        }}
                                    >
                                        <MenuItem value="0">-All-</MenuItem>
                                        {typeof props.ReportFields
                                            .user_statuses != "undefined" &&
                                            props.ReportFields.user_statuses !==
                                                "" &&
                                            props.ReportFields.user_statuses.map(
                                                (t, i) => (
                                                    <MenuItem
                                                        value={t.user_status_id}
                                                        key={"stst-" + i}
                                                    >
                                                        {t.user_status_name}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <div
                                    className={
                                        "d-flex align-center justify-end h100"
                                    }
                                >
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={props.FormSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
            <Paper className={"mb-1"}>
                <TableContainers className={'container'}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Task Title</TableCell>
                                {typeof props.Resources !== 'undefined' && props.Resources.map((row) => (
                                    <TableCell>
                                        {formatName(
                                            row.user_first_name,
                                            null,
                                            row.user_last_name,
                                            props.NameFormat
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.Tasks.map((row) => (
                                <TableRow>
                                     <TableCell>
                                     {typeof props.Resources !== 'undefined' && row.estimated_task_users.length > 0 && props.Resources.length &&
                                        <span class="value">
                                            <IconButton aria-label="edit">
                                                <EditIcon
                                                    onClick={() =>
                                                        props.EditClick(
                                                            row.task_id
                                                        )
                                                    }
                                                />
                                            </IconButton>
                                        </span>
                                    }
                                    </TableCell>

                                    <TableCell>{row.task_name}</TableCell>
                                    {row.users.map((roweach) => (
                                        (roweach.assigned) ?
                                        <TableCell>
                                            {roweach.estimated_hours}
                                        </TableCell>
                                        :
                                         <TableCell>-</TableCell>

                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainers>
            </Paper>
            {props.PopupType === "edit" && (
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={"Estimated Hours"}
                    OnSubmit={props.HandleSubmit}
                    Content={<DialogEdit FormProps={props} />}
                />
            )}
            <CustomizedSnackbars
            open={props.SnackbarOpen}
            variant='success'
            message={props.Message}
            handleClose={props.SnackbarClose}
        />
        </React.Fragment>
    );
};
const DialogEdit = (props) => {
    const { FormProps } = props;
     return (
        <>
        <Typography className={"error"}>{ Object.keys(FormProps.Errors).length !== 0 && Object.values(FormProps.Errors)[0]}</Typography>
        <Typography className={"mb-1"}><b>Task Name :</b> { typeof FormProps.EstimatedTaskUsers !== 'undefined' && FormProps.EstimatedTasks['task_name']}</Typography>
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Table stickyHeader aria-label="simple table" className={'no-border-table'}  size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="80%">Name</TableCell>
                                <TableCell width="20%">Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

            {typeof FormProps.EstimatedTaskUsers !== 'undefined' && FormProps.EstimatedTaskUsers.map(
                                                (t, i) => (
                <TableRow>
                  <TableCell>{formatName(
                                            t.first_name,
                                            null,
                                            t.last_name,                                            
                                        )}
                        </TableCell>
                         <TableCell className={'text-box-type'}>
                            <TextField
                                id={t.user_id}
                                name={t.user_id}
                                inputProps={{ style: {textAlign: 'center'} }}
                                type="text"
                                variant="outlined"
                                placeholder = "In Hours"
                                value = {(typeof t.estimated_hours !== 'undefined' && t.estimated_hours !== 0) ? t.estimated_hours : ''}
                                onChange={FormProps.HandleHoursChange}
                                error={FormProps.Errors["validation_error_"+t.user_id] && true}
                            />
                        </TableCell>
                 </TableRow>
              ))}
           </TableBody>
          </Table>
        </form></>
    )
}
