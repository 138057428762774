import React from 'react';
import { DialogBoxLarge } from '../../common/dialog.large';

const API_URL = process.env.REACT_APP_API_URL;
export const ViewImageComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxLarge
                Open={props.imageOpen}
                Cancel={props.handleCancel}
                OnSubmit={props.handleCancel}
                handleImageClick={props.handleImageClick}
                HideSaveButton={true}
                Title={'Attachment'}
                Content={
                    <ImageViewComponent FormProps={props} />
                }
            />
        </React.Fragment>
    )
}
const ImageViewComponent = (props) => {
    const FormProps = props.FormProps;
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <iframe src={API_URL + 'api/expenses/viewAttachment?id=' + FormProps.imageId} width="100%" height="100%" frameBorder="0" />
            </div>
        </React.Fragment>
    )
}

