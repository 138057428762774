import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as _R from 'ramda';

//import related presentational components
import { PhoneList } from './ListPhone';
import { EmailList } from './ListEmail';
import { AddressList } from './ListAddress';
import { EmergencyContactList } from './ListEmergencyContact';


const ContactDetailsComponent = (props) => {
    const { Data, StaffID, UserID } = props;
    var cObj = {};
    if (_R.type(Data) !== 'Undefined' && _R.type(Data.contact) !== 'Undefined') {
        cObj = Data.contact;
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Paper className={'contact-group'}>
                    { _R.type(cObj)!== 'Undefined' && !_R.isEmpty(cObj)
                        ?
                        <PhoneList
                            Phone={cObj.phone}
                            UserID={UserID}
                            StaffID={StaffID}
                        />
                        :
                        <></>
                    }
                </Paper>        

                <Paper className={'contact-group'}>
                    { _R.type(cObj)!== 'Undefined' && !_R.isEmpty(cObj)
                        ?
                        <EmailList
                            Email={cObj.email}
                            UserID={UserID}
                            StaffID={StaffID}
                        />
                        :
                        <></>
                    }
                </Paper>

                <Paper className={'contact-group'}>
                    { _R.type(cObj)!== 'Undefined' && !_R.isEmpty(cObj)
                        ?
                        <AddressList
                            Address={cObj.address}
                            UserID={UserID}
                            StaffID={StaffID}
                        />
                        :
                        <></>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div  className={'contact-group'}>
                    { _R.type(cObj)!== 'Undefined' && !_R.isEmpty(cObj)
                        ?
                        <EmergencyContactList
                            EmergencyInfo={cObj.emergency_info}
                            UserID={UserID}
                            StaffID={StaffID}
                        />
                        :
                        <></>
                    }
                </div>
            </Grid>
        </Grid>
    );
};
// This class component is depends Data property
export class ContactDetails extends Component{

    render(){
        return(
            <ContactDetailsComponent 
                Data={this.props.staffDetails}
                UserID={this.props.UserID}
                StaffID={this.props.StaffID}

            />
        )
    }
}

