import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

export class BasicForm extends React.Component{
    render(){
        let display = (parseInt(this.props.UserTypeSelected) === 1) || (parseInt(this.props.UserTypeSelected) === 2);
        let internal = parseInt(this.props.UserTypeSelected) === 1;
        let external = parseInt(this.props.UserTypeSelected) === 2;
        return (
            <>
             {internal &&
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Personal Details</Typography>
             }
                <Grid container spacing={6}>
                    <Grid item xs={12} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="salutation">Salutation</InputLabel>
                            <Select
                                name="salutation"
                                id="salutation"
                                value={(typeof this.props.FormFields.salutation !== 'undefined') ? this.props.FormFields.salutation : ''}
                                onChange={this.props.HandleOnChange}
                            >
                            { typeof this.props.FormInputs.salutation !== 'undefined' && this.props.FormInputs.salutation.map((t, i) =>
                                <MenuItem value={t.id} key='Salutation-{t.id}'>{t.title}</MenuItem>
                            )}
                            </Select>
                        </ FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField variant="standard"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                required
                                value={(typeof this.props.FormFields.first_name !== 'undefined') ? this.props.FormFields.first_name : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.first_name && true}
                                helperText={this.props.Errors.first_name}
                            />
                        </ FormControl>
                    </Grid>                    
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField variant="standard"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                required
                                value={(typeof this.props.FormFields.last_name !== 'undefined') ? this.props.FormFields.last_name : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.last_name && true}
                                helperText={this.props.Errors.last_name}
                            />
                        </ FormControl>
                    </Grid>
                   {internal &&
                     <Grid item xs={12} md={4}>
                        <FormControl error={this.props.Errors.user_status && true} variant="standard">
                            <InputLabel htmlFor="status" required>Status</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.user_status !== 'undefined') ? this.props.FormFields.user_status : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'user_status',
                                    id: 'user_status',
                                }}>
                                { typeof this.props.FormInputs.user_status != 'undefined' && this.props.FormInputs.user_status.map((g, i) =>
                                    <MenuItem value={g.user_status_id} key='status-{g.id}'>{g.user_status_name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.user_status}</FormHelperText>
                        </ FormControl>
                    </Grid>                    
                   }
                </Grid>
                {internal &&
                    <>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                <TextField variant="standard"
                                    id="legal_first_name"
                                    name="legal_first_name"
                                    label="Legal First Name"
                                    type="text"                                    
                                    value={(typeof this.props.FormFields.legal_first_name !== 'undefined') ? this.props.FormFields.legal_first_name : null}
                                    onChange={this.props.HandleOnChange}
                                    error={this.props.Errors.legal_first_name && true}
                                    helperText={this.props.Errors.legal_first_name}
                                />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                <TextField variant="standard"
                                    id="legal_middle_name"
                                    name="legal_middle_name"
                                    label="Legal Middle Name"
                                    type="text"
                                    value={(typeof this.props.FormFields.legal_middle_name !== 'undefined') ? this.props.FormFields.legal_middle_name : null}
                                    onChange={this.props.HandleOnChange}
                                    error={this.props.Errors.legal_middle_name && true}
                                    helperText={this.props.Errors.legal_middle_name}
                                />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <TextField variant="standard"
                                        id="legal_last_name"
                                        name="legal_last_name"
                                        label="Legal Last Name"
                                        type="text"
                                        value={(typeof this.props.FormFields.legal_last_name !== 'undefined') ? this.props.FormFields.legal_last_name : null}
                                        onChange={this.props.HandleOnChange}
                                        error={this.props.Errors.legal_last_name && true}
                                        helperText={this.props.Errors.legal_last_name}
                                    />
                                </ FormControl>
                            </Grid>
                        </Grid>
                    </>
                }
            {display &&
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField variant="standard"
                                id="user_email"
                                name="user_email"
                                label="Email"
                                type="email"
                                required
                                value={(typeof this.props.FormFields.user_email !== 'undefined') ? this.props.FormFields.user_email : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.user_email && true}
                                helperText={this.props.Errors.user_email}

                            />
                        </ FormControl>
                    </Grid>
                {external &&
                    <>
                    <Grid item xs={12} md={4}>
                        <FormControl error={this.props.Errors.user_status && true} variant="standard">
                            <InputLabel htmlFor="status" required>Status</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.user_status !== 'undefined') ? this.props.FormFields.user_status : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'user_status',
                                    id: 'user_status',
                                }}>
                                { typeof this.props.FormInputs.user_status != 'undefined' && this.props.FormInputs.user_status.map((g, i) =>
                                    <MenuItem value={g.user_status_id} key='status-{g.id}'>{g.user_status_name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.user_status}</FormHelperText>
                        </ FormControl>
                    </Grid>
                <Grid item xs={12} md={4}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="group">Group</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.group !== 'undefined') ? this.props.FormFields.group : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'group',
                                    id: 'group',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.groups != 'undefined' && this.props.FormInputs.groups.map((g, i) =>
                                    <MenuItem value={g.id} key='group-{g.id}'>{g.name}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    </>
                }
                </Grid>
            }
            {internal &&
               <><Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField variant="standard"
                                id="user_mobile"
                                name="user_mobile"
                                label="Mobile"
                                type="tel"
                                required
                                value={(typeof this.props.FormFields.user_mobile !== 'undefined') ? this.props.FormFields.user_mobile : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.user_mobile && true}
                                helperText={this.props.Errors.user_mobile}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={2}>
                        <FormControl error={this.props.Errors.gender && true} variant="standard">
                            <InputLabel htmlFor="gender" required>Gender</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.gender !== 'undefined') ? this.props.FormFields.gender : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'gender',
                                    id: 'gender',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.staff_gender_opt != 'undefined' && this.props.FormInputs.staff_gender_opt.map((g, i) =>
                                    <MenuItem value={g.id} key='gender-{g.id}'>{g.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.gender}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                id="dob"
                                name="dob"
                                label="Date Of Birth"
                                type="date"
                                value={(typeof this.props.FormFields.dob !== 'undefined') ? this.props.FormFields.dob : null}
                                onChange={this.props.HandleOnChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={this.props.Errors.dob && true}
                                helperText={this.props.Errors.dob}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl>
                            <TextField variant="standard"
                                id="pob"
                                name="pob"
                                label="Place Of Birth"
                                type="text"
                                value={(typeof this.props.FormFields.pob !== 'undefined') ? this.props.FormFields.pob : null}
                                onChange={this.props.HandleOnChange}
                                placeholder="City, State, Country"
                                error={this.props.Errors.pob && true}
                                helperText={this.props.Errors.pob}
                            />
                        </ FormControl>
                    </Grid>

                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl error={this.props.Errors.race_ethnicity && true} variant="standard">
                            <InputLabel htmlFor="race_ethnicity">Race/ Ethinicity</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.race_ethnicity !== 'undefined') ? this.props.FormFields.race_ethnicity : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'race_ethnicity',
                                    id: 'race_ethnicity',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.staff_race_ethinicity_opt != 'undefined' && this.props.FormInputs.staff_race_ethinicity_opt.map((g, i) =>
                                    <MenuItem value={g.id} key='race-ethnicity-{g.id}'>{g.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.race_ethnicity}</FormHelperText>
                        </ FormControl>
                    </Grid>
                </Grid> 
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControl error={this.props.Errors.protected_veteran && true} variant="standard">
                            <InputLabel htmlFor="protected_veteran">Protected Veteran</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.protected_veteran !== 'undefined') ? this.props.FormFields.protected_veteran : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'protected_veteran',
                                    id: 'protected_veteran',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.staff_veteran_opt != 'undefined' && this.props.FormInputs.staff_veteran_opt.map((g, i) =>
                                    <MenuItem value={g.id} key='protected_veteran-{g.id}'>{g.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.protected_veteran}</FormHelperText>
                        </ FormControl>
                    </Grid>            
                    <Grid item xs={12} md={4}>
                        { parseInt(this.props.FormFields.protected_veteran) === 1 && 
                        <FormControl variant="standard">
                            <InputLabel htmlFor="veteran_id">Protected Veteran Category</InputLabel>
                            <Select
                                multiple
                                value={(typeof this.props.FormFields.veteran_id !== 'undefined') ? this.props.FormFields.veteran_id : []}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'veteran_id',
                                    id: 'veteran_id',
                                }}>
                                { typeof this.props.FormInputs.staff_veteran_category_opt != 'undefined' && this.props.FormInputs.staff_veteran_category_opt.map((g, i) =>
                                    <MenuItem value={g.id} key='veteran-id-{g.id}'>
                                        {g.title}
                                    </MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                        }
                    </Grid>
                </Grid></>}
             {display &&
                     <>
             {internal &&
                <Typography component="h2" className={'section-title mt-2'} variant="button" display="block" gutterBottom color="primary">Other Details</Typography>
             }
                <Grid container spacing={6}>
                  {internal &&
                      <>
                    <Grid item xs={12} md={3}>
                        <FormControl error={this.props.Errors.idcard_type && true} variant="standard">
                            <InputLabel htmlFor="idcard_type">ID Card Type</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.idcard_type !== 'undefined') ? this.props.FormFields.idcard_type : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'idcard_type',
                                    id: 'idcard_type',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.idcard_type != 'undefined' && this.props.FormInputs.idcard_type.map((g, i) =>
                                    <MenuItem value={g.id} >{g.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{this.props.Errors.idcard_type}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl>
                            <TextField variant="standard"
                                id="id_number"
                                name="id_number"
                                label="ID Number"
                                type="text"
                                value={(typeof this.props.FormFields.id_number !== 'undefined') ? this.props.FormFields.id_number : null}
                                onChange={this.props.HandleOnChange}
                                error={this.props.Errors.id_number && true}
                                helperText={this.props.Errors.id_number}
                            />
                        </ FormControl>
                    </Grid></>
                 }
                {internal &&
                    <Grid item xs={12} md={3}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="group">Group</InputLabel>
                            <Select
                                value={(typeof this.props.FormFields.group !== 'undefined') ? this.props.FormFields.group : ''}
                                onChange={this.props.HandleOnChange}
                                inputProps={{
                                    name: 'group',
                                    id: 'group',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof this.props.FormInputs.groups != 'undefined' && this.props.FormInputs.groups.map((g, i) =>
                                    <MenuItem value={g.id} key='group-{g.id}'>{g.name}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                }
                </Grid></>
             }
            </>
        )
    }
};
