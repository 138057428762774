import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import { formatName } from "../common/common";
import * as _R from 'ramda';  // used 0.04kb
import Typography from '@mui/material/Typography';

export const ReviewerComponent = (props) => {
    let supervisors = props.Reviewers;
    return (
        <>
        <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Supervisors</Typography>
            </div>
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Priority</TableCell>
                            <TableCell>Supervisor</TableCell>                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { _R.type(supervisors) !== 'Undefined' && supervisors.map((s, i) =>
                        <TableRow hover>
                            <TableCell>{s.priority}</TableCell>
                            <TableCell>{formatName(s.user_first_name, null,s.user_last_name)}</TableCell>
                        </TableRow>
                        )}
                        { _R.isEmpty(supervisors) &&
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>            
        </>
    );
};

