import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { DialogBoxMedium } from '../../common/dialog.medium';
import { ErrorPage } from '../../common/error';
import { CustomizedSnackbars } from '../../common/snackbars';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const mstyle = { chips: { background: "#f4f4f4", color: 'black' } };
export const SystemPolicyComponent = (props) => {
    const SystemPolicies = typeof props.SystemPolicies !== 'undefined' ? props.SystemPolicies : [];
    return (
        <>
            {props.Permission ?
                <>
                    <div className={'listSubHeader pr-2'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                        {props.EditPermission &&
                            <Tooltip title="Add System Policy" aria-label="add">
                                <Button variant="contained" color="primary" size="small" onClick={props.AddSystemPolicy}>Add</Button>
                            </Tooltip>
                        }
                    </div>

                    <TableContainer component={Paper}>
                        <Table size='small' >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    {props.EditPermission &&
                                        <TableCell>Actions</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody className={'tbody'}>
                                {(SystemPolicies.length === 0) &&
                                    <TableRow >
                                        <TableCell>No system policies found.</TableCell>
                                    </TableRow>
                                }
                                {SystemPolicies.map((item, index) =>
                                    <TableRow hover>
                                        <TableCell>{item.work_policy_name}</TableCell>
                                        <TableCell>{item.start_date}</TableCell>
                                        <TableCell>{item.end_date}</TableCell>
                                        {props.EditPermission &&
                                            <TableCell>
                                                <Tooltip title="Edit" aria-label="edit">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                        <EditIcon fontSize="small" onClick={() => props.EditSystemPolicy(item.id)} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" aria-label="Delete">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Delete">
                                                        <DeleteOutlinedIcon fontSize="small" onClick={() => props.HandleDelete(item.id)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {(props.PopupType === 'add' || props.PopupType === 'edit') &&
                        <DialogBoxMedium
                            Open={props.Open}
                            Cancel={props.OnClose}
                            Title={props.PopupType === 'add' ? 'Add System Policy' : 'Edit System Policy'}
                            OnSubmit={props.HandleSubmit}
                            Content={<DialogAdd FormProps={props} />}
                        />
                    }
                    {props.SnackbarOpen &&
                        <CustomizedSnackbars
                            open={props.SnackbarOpen}
                            variant={props.SnackbarVariant}
                            message={props.SnackbarMsg}
                            handleClose={props.SnackbarClose}
                        />
                    }
                    {props.PopupType === 'delete' &&
                        <DialogBoxMedium
                            Open={props.Open}
                            Cancel={props.OnClose}
                            OnSubmit={props.DeleteSystemPolicy}
                            Title='Confirm Delete'
                            ButtonName='Ok'
                            Content={
                                <DialogWithConfirmMessage msg="Do you want to delete this system policy" />
                            }
                        />
                    }
                </>

                :
                <ErrorPage />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields, HandleOnChange, Errors, HandleDateChange } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.policy && true}>Policy</InputLabel>
                        <Select
                            value={parseInt(FormFields.policy)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'policy',
                                id: 'policy'
                            }}
                            className={'input-item'}
                        >
                            {typeof props.FormProps.AllPolicies !== 'undefined' && props.FormProps.AllPolicies.map((policy) =>
                                <MenuItem value={policy.id} >{policy.work_policy_name}</MenuItem>
                            )}
                        </Select>
                        {Errors.policy && <FormHelperText className="error">{Errors.policy}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "start_date"
                            name = "start_date"
                            selectedDate = {FormFields.start_date}
                            handleDateChange={HandleDateChange}
                            shouldDisableDate={(date) => { return (new Date(date)).getDay() !== 0;} }
                            required = {true}
                            label = {'Start Date'}
                        />
                        <small className='error'>{Errors.start_date && Errors.start_date}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id = "end_date"
                            name = "end_date"
                            selectedDate = {FormFields.end_date}
                            handleDateChange={HandleDateChange}
                            label = {'End Date'}
                        />
                        <small className='error'>{Errors.end_date && Errors.end_date}</small>
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
