import * as _ls from './ls';
export const prepareTreeData = (data, type, include_all_option = true, offsite = false) => {
    if (type === 'staff') {
        let slist = [];
        if (data.length > 0 && include_all_option) {
            slist.push({label:'All Staff',key:0,user_id:0,value:"selectAll"});
        }
        data.forEach((p, v) =>{
            slist.push({label:p.full_name,key:p.user_id,user_id:p.user_id});
        });
        data = slist;
    }
    if (type === 'users') {
        let slist = [];
        if (data.length > 0 && include_all_option) {
            slist.push({label:'All Users',key:0,user_id:0,value:"selectAll"});
        }
        data.forEach((p, v) =>{
            slist.push({label:p.full_name,key:p.user_id,user_id:p.user_id});
        });
        data = slist;
    }
    if (type === 'clients') {
        if (data.length > 0) {
            let clist = [];
            clist.push({label:'All Clients',key:0,client_id:0,value:"selectAll"});
            data.forEach((c, v) =>{
                clist.push({label:c.client_name,key:c.client_id,client_id:c.client_id});
            });
            data = clist;
        }
    }
    if (type === 'actions') {
        if (data.length > 0) {
            let alist = [];
            data.forEach((c, v) =>{
                alist.push({label:c.action,key:c.action_id,action_id:c.action_id});
            });
            data = alist;
        }
    }
    if (type === 'projects') {
        if (typeof data !== 'undefined' && data.length > 0) {
            data =  _ls.makeOptGroupsForReport(data);
            if  (include_all_option) {
             var newdata =  {
                        label: "All projects",
                        value: "selectAll",
                        key:0,
                        project_id:0,
                        className: 'no-radio',
                    };
                    data.unshift(newdata);
            }
            if  (offsite) {
             var newdata =  {
                        label: "All Projects",
                        value: "selectAll",
                        key:0,
                        project_id:0,
                        className: 'no-radio',
                    };
                    data.unshift(newdata);
            }
        }
    }
    if (type === 'tasks') {
        if (typeof data !== 'undefined' && data.length > 0) {
                data = groupTasks(data);
            var newdata1 =  {
                        label: "All tasks",
                        value: "selectAll",
                        key:0,
                        task_id:0,
                        className: 'no-radio',
                    };
                    data.unshift(newdata1);

        }
    }

    return data;
}
export const groupTasks = (options) => {
    let dataArr = [];
    let temp_arr = [];
    options.forEach((opt,i)=>{
        let temp = {};
        temp = JSON.parse(opt);
        temp_arr.push(temp)
    });
    //temp_arr.sort((a, b) => a.task_project.localeCompare(b.task_project));
    if (temp_arr.length) {
        temp_arr.forEach(function(obj, idx){
            if (dataArr.findIndex(d => d.task_project === obj.task_project) === -1){
                let t = {};
                t['label'] = obj.project_name;
                t['className'] = 'no-radio';
                t['task_id'] = false;
                t['disabled'] = true;
                dataArr.push(t);
            }
            dataArr.push(obj);
        });
    }
    return dataArr;
}
let result = [];
export const findProjectKey = function (dataObj, selected_projects) {
    dataObj.forEach(function (e) {
        e['checked'] = false;
        if (selected_projects.indexOf(e.project_id) !== -1) {
            if (e.children instanceof Object) result = findProjectKey(e.children, selected_projects);
            result = e;
            result['checked'] = true;
            return result;
        }
        else {
            if (e.children instanceof Object) result = findProjectKey(e.children, selected_projects);
            result = e;
            result['checked'] = false;
            return result;
        }
    });
}
let task_result = [];
export const findTaskKey = function(dataObj, selected_tasks) {
    dataObj.forEach(function(e) {
        e['checked'] = false;
        if(selected_tasks.indexOf(e.task_id) !== -1){
            task_result = e;
            task_result['checked'] = true;
            return task_result;
        }
        else if(e.children instanceof Object) task_result = findTaskKey(e.children, selected_tasks)
    });
}
let client_result = [];
export const findClientKey = function(dataObj, selected_clients) {
    dataObj.forEach(function(e) {
        e['checked'] = false;
        if(selected_clients.indexOf(e.client_id) !== -1){
            client_result = e;
            client_result['checked'] = true;
            return client_result;
        }
    });
}
let action_result = [];
export const findActionKey = function(dataObj, selected_actions) {
    dataObj.forEach(function(e) {
        e['checked'] = false;
        if(selected_actions.indexOf(e.action_id) !== -1){
            action_result = e;
            action_result['checked'] = true;
            return action_result;
        }
    });
}
let staff_result = [];
export const findStaffKey = function(dataObj, selected_staffs) {
    dataObj.forEach(function(e) {
        e['checked'] = false;
        if(selected_staffs.indexOf(e.user_id) !== -1){
            staff_result = e;
            staff_result['checked'] = true;
            return staff_result;
        }
    });
}
export const findStaffDetailsReportColumns = function(dataObj, selectedColumns) {
    dataObj.forEach(function(e) {
        e['checked'] = false;
        if(selectedColumns.indexOf(e.value) !== -1){
            e['checked'] = true;
        }
        if(e.children instanceof Object) findStaffDetailsReportColumns(e.children, selectedColumns)
    });
}

