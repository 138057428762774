import { BankInfoDetails } from './BankInfo.Component';
import React from 'react';
import {httpServices}  from '../../../../services/httpServices';

    const formFields = {
     'bank_name' :null,
     'pay_allocation_percent' :null,
     'routing_num' :null,
     'account_num' :null,
     'account_type' :null,
    }
    export class BankList extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            bank_info:[],
            errors: {},
        };
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    onAddBank = () => {
        this.setState({Open: true,
        isEdit: false,
        });
    }
    onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.bank_name) {
            errors["bank_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.pay_allocation_percent) {
            errors["pay_allocation_percent"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.routing_num) {
            errors["routing_num"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.account_num) {
            errors["account_num"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.account_type) {
            errors["account_type"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    submit =() => {
        if (this.handleValidation()) {
            this.saveBankDetails();
        }
    }
    saveBankDetails = () => {
        let data = this.state.formFields;
        const staffID = this.props.StaffID;
        data.staff_id = staffID;
        if(this.handleValidation()){
            httpServices.post('api/compensation/save-Bank-Details', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({bank_info:response.data.bank_info});
                            this.setState({Open:false});
                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors: response.data.errors});
                    }
          })
       .catch(function(error) {
            console.log("Error: "+error);
            alert("Something went wrong. Please try again later");
        });
        }
   }
     onUpdate = (id) => {
     return httpServices.post('api/compensation/get-bank-details', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        Open: true,
                        id: id,
                        isEdit: true,
                        formFields: response.data.bank_info[0],
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }    
    onDelete = (index, rowID) => {
        return httpServices.post('api/compensation/delete-bank-details', {'id': rowID, 'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data.valid) {
                    this.setState({bank_info:response.data.bank_info});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    render(){
    return(
        <BankInfoDetails
        Open={this.state.Open}
        BankInfo={this.state.bank_info.length>0 ? this.state.bank_info : this.props.BankInfo}
        AccountType={this.props.BankAccountType}
        AddBankDetails={this.onAddBank.bind(this)}
        Close={this.onClose.bind(this)}
        HandleOnChange ={this.handleOnChange.bind(this)}
        SaveBankDetails={this.submit.bind(this)}
        Update={this.onUpdate.bind(this)}
        FormFields={this.state.formFields}
        OnDelete={this.onDelete.bind(this)} 
        Errors={this.state.errors}
        />
        )
    }
}
