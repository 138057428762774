import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import {DialogBoxMedium} from '../common/dialog.medium';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const ReportConfigComponent = (props) => {
    let dataArr = props.report_config;
    let table_size = 10; // Default
    if (dataArr) {
        let data_arr_length = dataArr.length ;
        if (data_arr_length < 10) {
            table_size = data_arr_length < 3 ? 2 : data_arr_length;
        }
    }
    let Open = props.Open;
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={props.Title}
                        columns={[
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Code',
                                field: 'code',
                            },
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Custom permission enabled',
                                field: 'other_permission',
                            },
                            {
                                title: 'Status',
                                field: 'status_name',
                            },
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                            columnsButton:true,
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                            },
                            rowData => ({
                                icon: VisibilityIcon,
                                tooltip: 'Set Permission',
                                onClick: (event, rowData) => {
                                    props.ViewReport(rowData.id);
                                },
                                hidden: (_R.type(rowData.other_permission) !=='Undefined') ? false : true,
                            }),
                        ]}
                     

                    />
                </Grid>
            </Grid>
            
            <DialogBoxMedium
                    Open = {Open}
                    Cancel = {props.OnClose}
                    Title = {"Edit Report Config"}
                    OnSubmit = {props.OnSubmit}
                    Content = {<DialogContent FormProps={props}/>}
           />

        </React.Fragment>
        </>
    )
};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.name}
                                onChange={HandleOnChange}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </ FormControl>
                    </Grid>              


                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="status" required  error={props.Errors.status && true}>Status</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.status}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={1} key={'i-type-1'}>Enabled</MenuItem>
                                <MenuItem value={0} key={'i-type-2'}>Disabled</MenuItem>
                                
                            </Select>
                            {props.Errors.status && <FormHelperText className='error'>{props.Errors.status}</FormHelperText> }
                        </ FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField 
                                required
                                id="description"
                                name="description"
                                label="Description"
                                multiline={true}
                                variant="outlined"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.description}
                                onChange={HandleOnChange}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
)
}
