import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export const LateSubmission = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
   {(typeof props.LateSubmissionDetails !== 'undefined' && props.LateSubmissionDetails) &&
          <>
      <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Reviewers</TableCell>
                <TableCell>Reviewed By</TableCell>
                <TableCell>Reviewed On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row"> {typeof props.LateSubmissionDetails.status_name !== 'undefined' ? props.LateSubmissionDetails.status_name : ''}</TableCell>
                  <TableCell component="th" scope="row"> {typeof props.LateSubmissionDetails.all_reviewers !== 'undefined' ? props.LateSubmissionDetails.all_reviewers : ''} </TableCell>
                  <TableCell component="th" scope="row"> {typeof props.LateSubmissionDetails.reviewed_by !== 'undefined' ? props.LateSubmissionDetails.reviewed_by : ''}</TableCell>
                  <TableCell component="th" scope="row">{typeof props.LateSubmissionDetails.reviewed_on !== 'undefined' ? props.LateSubmissionDetails.reviewed_on : ''}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
            </>
        }
    </div>
  );
}
