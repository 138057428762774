import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ucwords } from 'bellajs';
import { formatName } from '../../common/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErrorPage } from '../../common/error';
import * as _R from 'ramda'

const StyledTable = styled(Table)({
    width: '100%',
});
const StyledTooltip = styled(Tooltip)(({ theme }) => ({    
        maxWidth: 'none',
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,    
}));
const API_URL = process.env.REACT_APP_API_URL;
export const TimeOffDetailsMobileComponent = (props) => {
    const { staffDetails } = props;
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
            <form action={API_URL + 'api/report/TimeoffConsolidatedlog'} method="post">
                <input type="hidden" name='user_id' value={staffDetails.user_id}></input>
                <div className={'section-main section-main-header-fixed'}>
                    <>
                        <div className={'header-fixed'}>
                            <div className={'section-header'}>
                                <div className={'col-left stretched'}>
                                    {(props.listView) ?
                                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                                        : <></>
                                    }
                                    <div className={'steps-boxed-input st-HSpacingL_1'}>
                                        <Grid container className={'timeoff-filter'}>
                                            <Grid item md={6} >
                                                <Select
                                                    variant='standard'
                                                    value={props.selectedYear}
                                                    inputProps={{
                                                        name: 'year',
                                                        id: 'year',
                                                    }}
                                                    onChange={props.HandleOnChange}>
                                                    {props.years.map((year, i) =>
                                                        <MenuItem value={year} key={'year' + i}>{year}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button variant="contained" color="primary" size="small" onClick={props.getTimeOffDetails}>Filter</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'section-body'}>
                            <>
                                <div className={'page-details-header profile-view__basics_default profile-view__basics_sm'}>
                                    <div className={'title-box profile-view__basics__main'}>
                                        {(typeof (staffDetails) != 'undefined' && staffDetails) ?
                                            <>
                                                <h1 className={'profile-view__basics-item item-title'}>
                                                    {_R.isNil(staffDetails.salutation) ? formatName(staffDetails.first_name, null, staffDetails.last_name) : staffDetails.salutation + ' ' + formatName(staffDetails.first_name, null, staffDetails.last_name)}
                                                </h1>
                                                <div className={'status'}>
                                                    {staffDetails.staff_type && <span className={'staff-type'}>
                                                        {ucwords(staffDetails.staff_type)}
                                                    </span>}
                                                    {staffDetails.emp_category && <span className={'emp-category'}>
                                                        {ucwords(staffDetails.emp_category)}
                                                    </span>}
                                                    {staffDetails.overtime && <span className={'overtime'}>
                                                        {ucwords(staffDetails.overtime)}
                                                    </span>}
                                                </div>
                                            </>
                                            :
                                            <> </>
                                        }
                                    </div>
                                    <div className={'profile-view__basics_default-item'}>
                                        <label>Staff ID</label><span className={"value"}>{staffDetails.staff_id}</span>
                                    </div>
                                    <div className={'profile-view__basics_default-item item-designation'}>
                                        <label>Date Joined</label>
                                        <span className={"value"}>{staffDetails.date_joined}</span>
                                    </div>
                                    {staffDetails.status_text.toLowerCase() === 'active' ?
                                        <div className={'profile-view__basics_default-item item-status status-active'}>
                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span>
                                        </div>
                                        :
                                        <div className={'profile-view__basics_default-item item-status status-inactive'}>
                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span>
                                        </div>
                                    }
                                    {(staffDetails.timeoff_eligibility === 'custom_start_date') &&
                                        <div className={'profile-view__basics_default-item item-mainproject'}>
                                            <label> Time Off Eligibility Start Date </label>
                                            <span className={"value"}>{staffDetails.doj_override_date}</span>
                                        </div>
                                    }
                                    <div className={'profile-view__basics_default-item item-mainproject'}>
                                        <label>Time Off Eligible Experience as on {props.onDate}</label>
                                        <span className={"value"}>{props.experience.years + ' Years ' + props.experience.months + ' months'}</span>
                                    </div>

                                </div>
                                {props.ErrorMsg ?
                                    <ErrorPage Message={props.ErrorMsg} />
                                    : <></>
                                }
                                <div className={'page-details-content page-details-content_sm'}>
                                    <div className={'content-main'}>
                                        {_R.type(props.timeoffTypes) !== undefined && Object.keys(props.timeoffTypes).map((k) =>
                                            <>
                                                <Accordion expanded={expanded === 'panel'.concat(props.timeoffTypes[k]['to_type'])} onChange={handleChange('panel'.concat(props.timeoffTypes[k]['to_type']))}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="submission-content"
                                                        id="submission-header"
                                                    >
                                                        <span>{props.timeoffTypes[k]['to_type']}</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                            <div className={'timeoff-summary-sm'}>
                                                                <>
                                                                    <div className={'value'}>
                                                                        <div className={'label'}> ELIGIBLE </div>
                                                                        <span className="days"> {props.timeoffTypes[k]['details']['eligible_days']} Days </span>
                                                                        <span className="hours"> {props.timeoffTypes[k]['details']['eligible_hours']} Hours </span>
                                                                    </div>
                                                                    <div className={'value'}>
                                                                        <div className={'label'}> USED </div>
                                                                        <span className="days"> {props.timeoffTypes[k]['details']['used_days']} Days </span>
                                                                        <span className="hours"> {props.timeoffTypes[k]['details']['used_hours']} Hours </span>
                                                                    </div>
                                                                    <div className={'value'}>
                                                                        <div className={'label'}> BALANCE </div>
                                                                        <span className="days"> {props.timeoffTypes[k]['details']['balance_days']} Days </span>
                                                                        <span className="hours"> {props.timeoffTypes[k]['details']['balance_hours']} Hours </span>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        
                                                        {(props.timeoffTypes[k]['to_type']) && <Paper>
                                                            <StyledTable  size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell style={{ 'width': '400px' }} component="th" scope="row" className={'section-title title-main'}></TableCell>
                                                                        <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                                        <TableCell component="th" scope="row" align="right" width="150" >Days</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                {(props.timeoffTypes[k]['full_details']['view_object']) && <TableBody>
                                                                    {props.timeoffTypes[k]['full_details']['view_object'].map((value, index) => <TableRow>
                                                                        <TableCell component="td" scope="row">{value['label']}
                                                                            {(props.timeoffTypes[k]['full_details']['additional_info']) && (index === 0) &&
                                                                                <StyledTooltip
                                                                                    title={props.timeoffTypes[k]['full_details']['additional_info'].map((u, v) => <p>{
                                                                                        (u.eligible_hours).toFixed(2) + " (Hours)" + " * " + (('months' in u) ? u.months + "(months)" : ('pay_periods' in u) ? u.pay_periods + "(pay periods)" : ('days' in u) ? u.days + " (days)" : "") + " = " + (u.total_hours).toFixed(2) + " (Hours) "
                                                                                    }
                                                                                    </p>)
                                                                                    }
                                                                                    arrow
                                                                                >
                                                                                    <InfoOutlinedIcon color="primary" fontSize='small' />
                                                                                </StyledTooltip>
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell component="td" scope="row" align="right" className={(value['hours'] < 0) ? 'error' : ''}>{parseFloat(value['hours']).toFixed(2)}</TableCell>
                                                                        <TableCell component="td" scope="row" align="right" className={(value['days'] < 0) ? 'error' : ''}>{parseFloat(value['days']).toFixed(2)}</TableCell>
                                                                    </TableRow>)}
                                                                </TableBody>
                                                                }
                                                            </StyledTable>
                                                            {(props.timeoffTypes[k]['adjustments'] && props.timeoffTypes[k]['adjustments'].length > 0) && <div className={"mt-1"}>
                                                                <div className={"mt-1 ml-1"}>
                                                                    <Typography variant="button" display="block" gutterBottom style={{ fontWeight: 'bold', color: '#000', textTransform: 'capitalize' }}>{props.timeoffTypes[k]['full_details']['to_type']} Adjustment/Carry forward</Typography>
                                                                </div>
                                                                <Table className="userTimeoffList" size="small" aria-label="a dense table">
                                                                    <TableHead className='header'>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" >Category</TableCell>
                                                                            <TableCell component="th" scope="row">Note</TableCell>
                                                                            <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {(props.timeoffTypes[k]['adjustments']).map((row) =>
                                                                            <TableRow>
                                                                                <TableCell component="td" scope="row">{row.category}</TableCell>
                                                                                <TableCell component="td" scope="row">{row.note} </TableCell>
                                                                                <TableCell component="td" scope="row" align="right">{parseFloat(row.hours).toFixed(2)}</TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                        <TableRow>
                                                                            <TableCell component="td" scope="row" colSpan={2}><b>Total</b> </TableCell>
                                                                            <TableCell component="td" scope="row" align="right">{parseFloat(props.timeoffTypes[k]['adjustmentshours']).toFixed(2)}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </div>}
                                                            {(props.timeoffTypes[k]['timeoff_type_user_policies'] && props.timeoffTypes[k]['timeoff_type_user_policies'].length > 0) && <div className={"mt-1"}>
                                                                <div className={"mt-1 ml-1"}>
                                                                    <Typography variant="button" display="block" gutterBottom style={{ fontWeight: 'bold', color: '#000', textTransform: 'capitalize' }}>Eligibility Criteria Override</Typography>
                                                                </div>
                                                                <Table className="userTimeoffList" size="small" aria-label="a dense table">
                                                                    <TableHead className='header'>
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" >Experience from</TableCell>
                                                                            <TableCell component="th" scope="row" >Experience to</TableCell>
                                                                            <TableCell component="th" scope="row" >Unit</TableCell>
                                                                            <TableCell component="th" scope="row" >Effective Date</TableCell>
                                                                            <TableCell component="th" scope="row" >Days</TableCell>
                                                                            <TableCell component="th" scope="row" >Note</TableCell>
                                                                            <TableCell component="th" scope="row" >Created by</TableCell>
                                                                            <TableCell component="th" scope="row" >Created on</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {Object.keys(props.timeoffTypes[k]['timeoff_type_user_policies']).map((key) =>
                                                                            <TableRow>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['lower_limit']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['upper_limit']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['eligibility_criteria_unit']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['effective_date']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['no_days']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['note']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['cby_first_name'] + ' ' + props.timeoffTypes[k]['timeoff_type_user_policies'][key]['cby_last_name']}</TableCell>
                                                                                <TableCell component="td" scope="row">{props.timeoffTypes[k]['timeoff_type_user_policies'][key]['created_on']}</TableCell>
                                                                            </TableRow>)}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>}
                                                            {(props.timeoffTypes[k]['display_labels'] != undefined) && <Grid container justify="space-between" alignItems="stretch" spacing={0} >
                                                                <div className={"mt-1 ml-1"}>
                                                                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Log</h6>
                                                                </div>
                                                                <div className={"mt-1 ml-1"}>
                                                                    <Button variant="contained" color="primary" size="small" type='submit' name='export' value="export">Consolidated Log</Button>
                                                                </div>
                                                                <div style={{'overflow-x' : 'auto'}}>
                                                                <Table className="userTimeoffList" size="small">
                                                                    <TableHead className='header'>
                                                                        <TableRow>
                                                                            <TableCell>Pay Period</TableCell>
                                                                            <TableCell> Timesheet Status </TableCell>
                                                                            {(props.timeoffTypes[k]['display_labels']).map((key, value) => <>
                                                                                <TableCell align="right"> {key}</TableCell>
                                                                            </>)}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {props.timeoffTypes[k]['accruals'] && Object.keys(props.timeoffTypes[k]['accruals']).map((key) =>
                                                                            <TableRow onClick={props.ViewLink(props.SelectedType['accruals'][key].timesheet_id)} className={'link'}>
                                                                                <TableCell>{props.timeoffTypes[k]['accruals'][key].payperiod_sdate + ' - ' + props.timeoffTypes[k]['accruals'][key].payperiod_edate}</TableCell>
                                                                                <TableCell>{props.timeoffTypes[k]['accruals'][key].status}</TableCell>
                                                                                {(props.timeoffTypes[k]['accruals'][key].eligible_hours !== undefined) && <TableCell align="right">{(props.timeoffTypes[k]['accruals'][key].eligible_hours != 'N/A') ? parseFloat(props.timeoffTypes[k]['accruals'][key].eligible_hours).toFixed(2) : ''}</TableCell>}
                                                                                <TableCell align="right">{(props.timeoffTypes[k]['accruals'][key].taken_hours != 'N/A') ? parseFloat(props.timeoffTypes[k]['accruals'][key].taken_hours).toFixed(2) : ''}</TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                        {(props.timeoffTypes[k]['accruals'] && (Object.keys(props.timeoffTypes[k]['accruals']).length > 0)) &&
                                                                            <TableRow>
                                                                                <TableCell align="right" colSpan={2}><b>Total</b></TableCell>
                                                                                {(props.timeoffTypes[k]['accruals'][0].eligible_hours !== undefined) &&
                                                                                    <TableCell align="right">
                                                                                        {Object.keys(props.timeoffTypes[k]['accruals']).map((key, value) => { return props.timeoffTypes[k]['accruals'][key].eligible_hours ? parseFloat(props.timeoffTypes[k]['accruals'][key].eligible_hours) : 0; }).reduce((a, b) => { return a + b; }).toFixed(2)}
                                                                                    </TableCell>
                                                                                }
                                                                                <TableCell align="right">
                                                                                    {Object.keys(props.timeoffTypes[k]['accruals']).map((key) => { return props.timeoffTypes[k]['accruals'][key].taken_hours ? parseFloat(props.timeoffTypes[k]['accruals'][key].taken_hours) : 0; }).reduce((a, b) => { return a + b; }).toFixed(2)
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                                </div>
                                                            </Grid>}
                                                        </Paper>}
                                                    </AccordionDetails>
                                                </Accordion>

                                            </>
                                        )}
                                        <Accordion expanded={expanded === 'panelHolidays'} onChange={handleChange('panelHolidays')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="submission-content"
                                                id="submission-header"
                                            >
                                                <span>Holidays</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                    <div className={'timeoff-summary-sm'}>
                                                        <div className={'value'}>
                                                            <div className={'label'}> ELIGIBLE </div>
                                                            <span className="days"> {props.holidayMax} Days </span>
                                                            <span className="hours"> {(props.holidayMax * props.dailyMinimum).toFixed(2)} Hours </span>
                                                        </div>
                                                        <div className={'value'}>
                                                            <div className={'label'}> USED </div>
                                                            <span className="days"> {props.holidayUsed} Days </span>
                                                            <span className="hours"> {(props.holidayUsed * props.dailyMinimum).toFixed(2)} Hours </span>
                                                        </div>
                                                        <div className={'value'}>
                                                            <div className={'label'}> BALANCE </div>
                                                            <span className="days"> {props.holidayMax - props.holidayUsed} Days </span>
                                                            <span className="hours"> {((props.holidayMax - props.holidayUsed) * props.dailyMinimum).toFixed(2)} Hours </span>
                                                        </div>
                                                    </div>
                                                
                                            </AccordionDetails>
                                        </Accordion>

                                    </div>
                                </div>
                            </>
                        </div>
                    </>
                </div>
            </form>
        </React.Fragment>
    )
};
