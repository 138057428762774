import React from 'react';
import {CustomMaterialTable } from '../../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {type} from 'ramda';
import { formatName } from '../../../common/common';
import Paper from "@mui/material/Paper";

export const ChangeLogComponent = (props) => {
      const gridStyle = {
        display: "block",
        padding: "24px",
        position: "relative",
        background: "#fff",
    };
    const data = props.UserDetails;
    return (
        <>
            <Paper className={"mb-1"} style={gridStyle}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Created By</label>
                                <span className={"value"}>
                                    {formatName(data.cby_first_name,null,data.cby_last_name)}
                                </span>
                            </div>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Modified By</label>
                                <span className={"value"}>
                                    {formatName(data.mby_first_name,null,data.mby_last_name)}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Created On</label>
                                <span className={"value"}>
                                    {data.user_cdate}
                                </span>
                            </div>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Modified On</label>
                                <span className={"value"}>
                                    {data.user_mdate}
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
          <Grid container spacing={3} >
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                                <Grid item xs={4} md={2}>
                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="age-simple">Entity Name</InputLabel>
                                        <Select
                                        value = {type(props.selectedEntity) !== 'Undefined' ? props.selectedEntity: ''}
                                        onChange = {props.HandleOnChange}
                                        inputProps = {{
                                        name: 'selectedEntity',
                                        id: 'selectedEntity',
                                        }}>
                                        {Object.keys(props.ChangeLogEntities).map((i) =>
                                        <MenuItem value = {i} > {props.ChangeLogEntities[i]} </MenuItem>
                                        )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                        </form>
                    </Grid>
            </Grid>
            <React.Fragment>
	     <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Change History"}
                        columns={[
                            {title: 'Date and Time',field: 'created_on'},
                            {title: 'Modified By',field: 'user_fullname'},
                            {title: 'Entity',field: 'entity_name'},
                            {title: 'Field',field: 'display_name'},
                            {title: 'Previous Value',field: 'prev'},
                            {title: 'Current Value',field: 'new'},
                        ]}
                        data={props.History}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	    </div>
        </React.Fragment>
        </>
    )
};
