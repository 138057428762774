import React from 'react';
import Cookies from 'universal-cookie';
import { connect } from "react-redux";
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { DashboardComponent } from './Dashboard.Component';
import { history } from './../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

const cookies = new Cookies();
class RequestDashboardContainer extends React.Component{
    constructor(props){
        let tabCookie = cookies.get('tab');
        if (!tabCookie) {
            tabCookie = {};
        }
        super(props);
        this.state ={
            showLoader : true,
            tabCookie : tabCookie,
            hasPermission : true,
        }
        this.viewRequest = this.viewRequest.bind(this);
        this.viewList = this.viewList.bind(this);
        this.findSubMenuRoute = this.findSubMenuRoute.bind(this);
    }
    componentDidMount = () => {
        this.getDashboardItems();
    };
    componentDidUpdate(prevProps) {
        if ((Object.keys(prevProps.menuItems).length !== Object.keys(this.props.menuItems).length) || (prevProps.selectedMenuIndex !== this.props.selectedMenuIndex)) {
            if (!this.state.hasPermission) {
                this.findSubMenuRoute();
            }
        }
    }
    /*
     * Function for redirecting to the next sub menu item if the default
     * route of the report emodule has no permission
     */
    findSubMenuRoute = () => {
        if (this.props.selectedMenuIndex !== -1 && this.props.menuItems) {
            let subMenu = this.props.menuItems[this.props.selectedMenuIndex]['sub_menu'];
            let matchingSubMeuFound = false;
            if (Object.keys(subMenu).length) {
                Object.keys(subMenu).forEach(function(childKey,ind) {
                    if (subMenu[childKey]['link'] && !matchingSubMeuFound) {
                        matchingSubMeuFound = true;
                        history.push(BASE_PATH+'/'+subMenu[childKey]['link']);
                    }
                });
            }
        }
    }
    getDashboardItems = () => {
        let apiEndpoint = 'api/request/index';
        return httpServices.post(apiEndpoint)
            .then(response => {
                if (response.data && response.data.permission) {
                    this.setState({
                        hasPermission : true,
                        request : response.data,
                        showLoader : false,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                    }, function() {
                        this.findSubMenuRoute();
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    
    }
    viewRequest = (id, type) => {
        history.push(type+'/view/' + id);
    }
    viewList = (type, tab) => {
        let tabCookie = this.state.tabCookie;
        if (type === 'timeoff') {
            tabCookie.timeoff = tab;
        }
        if (type === 'offsite') {
            tabCookie.offsite = tab;
        }
        if (type === 'overtime') {
            tabCookie.overtime = tab;
        }
        if (type === 'additionalpto') {
            tabCookie.additionalpto = tab;
        }
        cookies.set('tab', tabCookie, { path: '/' });
        history.push(type);
    }
    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <DashboardComponent
                RequestData = {this.state.request}
                ViewRequest = {this.viewRequest}
                ViewList = {this.viewList}
            />
            :
            (this.state.hasPermission === false) ?
                <ErrorPage Message={'You have no permission to view request dashboard'} />
                :
                <></>
            }
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const menuItems = state.menuItems.menu ? state.menuItems.menu : {};
    const selectedMenuIndex = state.menuItems.selectedMenuIndex ? state.menuItems.selectedMenuIndex : -1;
    return {
        menuItems:  menuItems,
        selectedMenuIndex: selectedMenuIndex,
    }
}
const connectedRequestDashboardContainer = connect(mapStateToProps)(RequestDashboardContainer);
export {connectedRequestDashboardContainer as RequestDashboardContainer};
