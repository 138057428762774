import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { PolicyComponent }  from './Policy.Component.js';

export class PolicyContainer extends React.Component{
    currentYear = new Date().getFullYear();
    constructor(props){
        super(props);
        this.state ={
            policy : []
        }
    }

    componentDidMount = () => {
        this.getAllRequests();
    };

    getAllRequests = (year=this.props.currentYear) => {
        let id = this.props.timeoffId
        let apiEndpoint = 'api/settings/timeoff/getAllCriteria';
        return httpServices.post(apiEndpoint, { id: id , year : year})
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        policy : response.data.policy_details,
                        daily_minimum : response.data.daily_minimum,
                        eligibility_criteria_unit : response.data.eligibility_criteria_unit
                    });
                }
            })
    }

    render(){
        return(
            <PolicyComponent
                Policy = {this.state.policy}
                DailyMinimum  = {this.state.daily_minimum}
                PolicyUnit  = {this.state.eligibility_criteria_unit}
            />
        )
    }
}