import React from 'react';
import { ContractComponent} from './Contract.Component';
import { httpServices } from '../../../services/httpServices';

const formFields = {
    'id' : 0,
    'lbCategoryName':null,
    'description': null,
    'start_date': null,
    'end_date': null,
    'prev_end_date': null,
    'lcatResources': [],
    'project_id': null,
    'update_resources': false
};

export class ContractContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            showLoader : true,
            laborCategories : [],
            contractDetails : [],
            open: false,
            formFields :formFields,
            errors : {},
            isEditable : false,
            snackbar : false,
            Variant : 'success',
            otherPermissions : [],
            project : [],
            inheritResourceFlag : false,
            deletePermission : false,
            nameFormat : 'FIRSTLAST',
            showConfirmation : false
        };
        this.onAdd = this.onAdd.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onConfirmClose = this.onConfirmClose.bind(this);
        this.handleUpdateResourceClick = this.handleUpdateResourceClick.bind(this);
    }
  
    componentDidMount(){
        this.getLaborCategoryDetails(this.props.ProjectId);
        this.getContractDetails(this.props.ProjectId);
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };

    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.end_date && this.state.project['is_contract'] === "1" ) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.lbCategoryName) {
            errors["lbCategoryName"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    handleDateChange = (date, name) => {
        const ff = this.state.formFields;
        ff[name] = date;
        this.setState({
            formFields : ff
        });
    };
    clearForm = () => {
        let ff = this.state.formFields;
        for (let key in ff) {
            if(key === 'id') {
                ff[key] = 0;
            }
            else {
                ff[key] = null;
            }
        }
        this.setState({
            formFields: ff
        });
    };

    handleUpdateResourceClick = () => {
        this.setState({
            showConfirmation : false,
        });
        let data = this.state.formFields;
        data.update_resources = true;
        this.handleSave(data);
    }
    handleSubmit = (id) => {
        if (this.handleValidation()) {
            let project_id = this.props.ProjectId;
            let data = this.state.formFields;
            data.project_id = project_id;
            if (id) {
                data.id = id;
                if ((typeof(data.prev_end_date) !== 'undefined') && data.prev_end_date !== data.end_date && data.lcatResources.length > 0) {
                    //this.props.preventDefault();
                    this.setState({
                        showConfirmation: true,
                        formFields : data
                    });
                } else {
                    this.handleSave(data);
                }
            } else {
                this.handleSave(data);
            }
        }
    };

    handleSave = (data) => {
        console.log(data);
        if (data) {
            let apiEndpoint = 'api/project/saveLaborCategory';
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data.valid) {
                        if (response.data.permission) {
                            if (response.data.error) {
                                this.setState({
                                    open: true,
                                    snackbar: true,
                                    variant: 'error',
                                    message: response.data.msg,
                                });
                            }
                            else {
                                this.setState({
                                    open: false,
                                    snackbar: true,
                                    variant: 'success',
                                    message: response.data.successMessage,
                                    showLoader: false,
                                    permission: response.data.permission,
                                    otherPermissions: response.data.otherPermissions,
                                });
                                this.clearForm();
                                this.getLaborCategoryDetails(this.props.ProjectId);
                            }
                        }
                        else {
                            this.setState({
                                showLoader: false
                            });
                        }
                    }
                    else {
                        this.setState({
                            errors: response.data.errors
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });

        }
    };

    getLaborCategoryDetails = (projectId, id) => {
        let apiEndpoint = 'api/project/laborCategory';
        return httpServices.post(apiEndpoint, {'project_id' : projectId, 'lcat_id' : id})
            .then(response => {
                if (response.data.permission) {
                    if (id) {
                        const f = this.state.formFields;
                        f['lbCategoryName'] = response.data.lcat_info.name;
                        f['description'] = response.data.lcat_info.description;
                        f['start_date'] = response.data.lcat_info.start_date;
                        f['end_date'] = response.data.lcat_info.end_date;
                        f['prev_end_date'] = response.data.lcat_info.end_date;
                        f['lcatResources'] = (typeof(response.data.lcat_info.lcat_resources) !== 'undefined') ? response.data.lcat_info.lcat_resources : []
                        this.setState({formFields : f,  
                        otherPermissions : response.data.otherPermissions});
                    }
                    else {
                        this.setState({
                            project : response.data.project,
                            laborCategories : response.data.laborCategories,
                            showLoader : false,
                            permission : response.data.permission,
                            otherPermissions : response.data.otherPermissions,
                            inheritResourceFlag : response.data.inherit_resource_flag,
                            deletePermission : response.data.delete_permission,
                            });
                    }
                }
                else {
                    this.setState({
                        showLoader : false
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onAdd = () => {
        let startDate = this.state.project.project_sdate;
        let endDate = this.state.project.project_edate;
        let fields = this.state.formFields;
        fields['start_date'] = startDate;
        fields['end_date'] = endDate;
        this.setState({
            open : true,
            isEditable : false,
            formFields : fields,
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
        this.clearForm();
    };

    onConfirmClose = () => {
        this.setState({
            showConfirmation : false
        });
        let data = this.state.formFields;
        this.handleSave(data);
    };

    onUpdate = (projectId, id) => {
        this.setState({
            open : true,
            isEditable : true,
            id : id,
        });
        this.getLaborCategoryDetails(projectId, id);
    }

    onDelete = (id) => {
        let apiEndpoint = 'api/project/deleteLCat';
        return httpServices.post(apiEndpoint, {'lcat_id' : id})
            .then(response => {
                if (response.data.permission) {
                    if (response.data.error) {
                        this.setState({
                            snackbar : true,
                            variant : 'error',
                            message : response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            snackbar : true,
                            variant : 'success',
                            message : response.data.message,
                        });
                        this.getLaborCategoryDetails(this.props.ProjectId);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    getContractDetails = (projectId) => {
        let apiEndpoint = 'api/project/contractDetails';
        return httpServices.post(apiEndpoint,{project_id : projectId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            project : response.data.project,
                            contractDetails : response.data.contractDetails,
                            showLoader : false,
                            permission : response.data.permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    render(){
        return(
            <ContractComponent
            LaborCategories = {this.state.laborCategories}
            ContractDetails = {this.state.contractDetails}
            OnAdd = {this.onAdd}
            Open = {this.state.open}
            OnUpdate = {this.onUpdate}
            OnDelete = {this.onDelete}
            OnClose = {this.onClose}
            OnConfirmClose = {this.onConfirmClose}
            HandleSubmit = {this.handleSubmit}
            HandleDateChange = {this.handleDateChange}
            Errors = {this.state.errors}
            IsEditable = {this.state.isEditable}
            FormFields = {this.state.formFields}
            HandleOnChange = {this.handleOnChange}
            ProjectId = {this.props.ProjectId}
            Id = {this.state.id}
            Variant = {this.state.variant}
            Message = {this.state.message}
            Snackbar = {this.state.snackbar}
            SnackbarClose = {this.snackbarClose}
            OtherPermissions = {this.state.otherPermissions}
            Project = {this.state.project}
            InheritResourceFlag = {this.state.inheritResourceFlag}
            DeletePermission = {this.state.deletePermission}
            NameFormat = {this.state.nameFormat}
            ShowConfirmation = {this.state.showConfirmation}
            HandleUpdateResourceClick = {this.handleUpdateResourceClick}
            />
        )
    }
}
