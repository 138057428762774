import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const gridStyle = {
        margin: '15px 0px'
    };
const API_URL = process.env.REACT_APP_API_URL;
export const ActualEstimateReportComponent = (props) => {
    let projects = (_R.type(props.Projects) !== 'Undefined') ? props.Projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.FormFields.project_id) !== 'Undefined') ? [props.FormFields.project_id] : []));
    }
    return (
        <React.Fragment>
            <Paper md={12} classname={'mb-2'}>
            <div class="p1">
                <Typography variant="h6" component="h6" className={'section-title'}> Actual <span style={{textTransform :'initial'}}>Vs</span> Estimate Review Report </Typography>
                <form className="form-fullwidth" action={API_URL+'api/report/actualEstimateVariance'} method="post">
                    <Grid container spacing={3} style={gridStyle}>
                        <Grid item  md={4} className='justify-between'>
                            <FormControl error={props.Errors.client_id && true}  variant="standard">
                                <InputLabel htmlFor="client" required  error={props.Errors.client_id && true} >Client</InputLabel>
                                <Select
                                    value={props.FormFields.client_id}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'client_id',
                                        id:   'client_id',
                                    }}
                                >
                                <MenuItem value={0}>-Select Client-</MenuItem>
                                {_R.type(props.Clients) !== 'Undefined' &&  props.Clients.map((t, i) =>
                                    <MenuItem value={t.client_id} key={'cl-'+i}>{t.client_name}</MenuItem>
                                )}
                                </Select>
                                <FormHelperText>{props.Errors.client_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={4} className='justify-between'>
                        <InputLabel htmlFor="project_id" className="MuiInputLabel-shrink" required  error={props.Errors.project_id && true} >Project</InputLabel>
                            <FormControl error={props.Errors.project_id && true}>
                                <TreeSelectComponent
                                    dropdown_mode={'true'}
                                    data={typeof projects ? projects : []}
                                    placeholderText={"Select Project"}
                                    type='project'
                                    HandleTreeChange={props.HandleTreeChange}
                                />
                                <input type="hidden" value={props.FormFields.project_id} name="project_id"/>
                                <FormHelperText >{props.Errors.project_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField  variant="standard"
                                    id = "startDate"
                                    name = "startDate"
                                    label = " Start Date"
                                    type = "date"
                                    onChange = {props.HandleOnChange}
                                    value={(typeof props.FormFields.startDate !== 'undefined') ? props.FormFields.starDate : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { max: (typeof props.FormFields.endDate !== 'undefined') ? props.FormFields.endDate : null}
                                    }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField  variant="standard"
                                    id = "endDate"
                                    name = "endDate"
                                    label = "End Date"
                                    type = "date"
                                    onChange = {props.HandleOnChange}
                                    value={(typeof props.FormFields.endDate !== 'undefined') ? props.FormFields.endDate : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { min: (typeof props.FormFields.startDate !== 'undefined') ? props.FormFields.startDate : null} 
                                    }}

                                />
                            </ FormControl>
                        </Grid>
                    <Grid container spacing={3} style={gridStyle}>
                        <Grid item  md={3}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked = { props.FormFields.task_status }
                                            value = { props.FormFields.task_status }
                                            id = "task_status"
                                            name = "task_status"
                                            onChange = {props.HandleOnChange}
                                            color="primary"
                                        />
                                        }
                                        label="Include closed/inactive tasks"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item  md={3}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                           checked = { props.FormFields.task_estimate }
                                           value = { props.FormFields.task_estimate }
                                           id = "task_estimate"
                                           name = "task_estimate"
                                           onChange = {props.HandleOnChange}
                                           color="primary"
                                        />
                                        }
                                        label="Include tasks without estimate "
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={'filter-column'}>
                            <div className={'d-flex align-center justify-end'}>
                                    <Button variant="contained" color="primary" size="small"  onClick={props.OnSubmitClick}> Show </Button>
                            <div className={'ml-1'}>
                                    {(props.FormFields.project_id !== 0 && props.FormFields.client_id !== 0 ) &&
                                        <Button className={'ml-1'} variant="contained" color="primary" size="small" type="submit" name="export" value="export"> Export as excel </Button>
                                    }
                            </div>
                            </div>
                        </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
            </Paper>


            {(Object.keys(props.ResultSet).length === 0 && props.Post) &&
                <><div><p> No records found </p></div></>
            }
            {(Object.keys(props.ResultSet).length !== 0) &&
                <div className={'section-body'}>
                <Typography variant="h6" gutterBottom>Parent Project : {props.ParentProject} </Typography>
                {_R.type(props.ResultSet) !== 'Undefined' && Object.keys(props.ResultSet).map((item,k) =>
                    <>            
                    {((props.ResultSet[item]['tasks']).length !== 0) &&
                                <>
                                    {_R.type(props.ResultSet[item]['tasks']) === "Array" &&  props.ResultSet[item].tasks.map((task, i) =>
                                    <>
                                    <Paper className={'mb-1'}>
                                    {(props.ResultSet[item]['tasks'][i]['resources']).length !== 0 &&
                                    <>
                                    <Table  className={'list-table_basic'}>
                                    <TableRow>
                                        <TableCell> <strong>Sub Project </strong>
                                        <>
                                        {(props.ResultSet[item].projectTreeList).length === 3 ?
                                        <div> {props.ResultSet[item].projectTreeList[1]} / {props.ResultSet[item].projectTreeList[2]}</div> 
                                                : <div> {props.ResultSet[item].projectTreeList[1]} </div>
                                        }
                                        </>
                                        </TableCell>
                                        <TableCell><strong> Timesheet Task </strong>
                                        <div> {props.ResultSet[item]['tasks'][i].task_name}</div></TableCell>
                                    </TableRow>
                                    </Table>
                                    <Table  className={'list-table_basic'}>
                                    <TableHead className={'thead'}>
                                        <TableCell><b> Resources </b></TableCell>
                                        <TableCell><b> Labour Category </b></TableCell>
                                        <TableCell className={'text-right'} ><b> Estimated Hours </b></TableCell>
                                        <TableCell className={'text-right'} ><b> Actual Hours </b></TableCell>
                                        <TableCell className={'text-right'} ><b> Variance </b></TableCell>
                                    </TableHead>
                                        
                                    {_R.type(props.ResultSet[item]['tasks'][i]['resources']) === "Array" && props.ResultSet[item]['tasks'][i].resources.map((reso, r) =>
                                        <TableRow>
                                            <TableCell width="20"> {props.ResultSet[item]['tasks'][i]['resources'][r].res_name} </TableCell>
                                            <TableCell width="30"> {props.ResultSet[item]['tasks'][i]['resources'][r].lcat_name} </TableCell>
                                            <TableCell className={'text-right'} width="15">{parseFloat(props.ResultSet[item]['tasks'][i]['resources'][r].estimate_hrs).toFixed(2)}</TableCell>
                                            <TableCell className={'text-right'} width="15">{parseFloat(props.ResultSet[item]['tasks'][i]['resources'][r].actual_hrs).toFixed(2)}</TableCell>
                                            <TableCell className={'text-right'} width="15">{parseFloat(props.ResultSet[item]['tasks'][i]['resources'][r].variance).toFixed(2)}</TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className='timesheet-list_row-daytotal shaded'> 
                                        <TableCell colspan="2"><b> Total </b> </TableCell>
                                        <TableCell className={'text-right'} width="20"><b> {parseFloat(props.ResultSet[item]['tasks'][i].estimate_total).toFixed(2)} </b> </TableCell>
                                        <TableCell className={'text-right'} width="20"><b> {parseFloat(props.ResultSet[item]['tasks'][i].actual_total).toFixed(2)} </b> </TableCell>
                                        <TableCell className={'text-right'} width="20"><b> {parseFloat(props.ResultSet[item]['tasks'][i].variance_total).toFixed(2)} </b> </TableCell>
                                    </TableRow>
                                    </Table>
                                    </>
                                    }
                                    </Paper>
                                    </>
                                    )}
                                </>
                                }
                    </>
                )}
                </div>
            }
        </React.Fragment>
    )
}
