import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { ucfirst, ucwords } from 'bellajs';
import * as _R from 'ramda';
import * as _cs from '../../common/common';
import { formatName } from '../../common/common';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


import { AccountContainer } from './view/Account.Container';
import { GroupsContainer } from './view/Groups.Container';
import { PreferencesContainer } from './view/Preferences.Container';
import { ChangeLogContainer } from './view/ChangeLog.Container';
import { RolesContainer } from './view/Roles/Roles.Container';
import { ServicePeriodContainer } from './view/ServicePeriod.Container';
import { ProjectsContainer } from './view/Projects.Container';
import { UserConfigurationContainer } from './view/UserConfiguration.Container';
import { CustomizedSnackbars } from '../../common/snackbars';
import Tooltip from '@mui/material/Tooltip';


const UserViewStyle = styled(React.Fragment)(({theme}) => ({
    '& .paper': {
        color: theme.palette.text.secondary,
        minHeight:'calc(100vh - 100px)',
        boxShadow:'none'
    },
    '& .gridRoot':{
        minHeight:'calc(100vh - 100px)'  
    },
    tab:{
        backgroundColor:'#eee',
        display:'block'
    },
    '& .bgWhiteLeft':{
        width:'100%',height:'100%',
        backgroundColor:'#fff',
        borderRadius:'4px',
        borderRight:'1px solid #eee',
        borderBottomRightRadius:0,
        padding: 6*2,
    },
    '& .bgWhiteContent':{
        backgroundColor:'#fff',
        padding: 8*3,
        display:'block',
        position:'relative'
    },
}));

export const UserViewComponent = (props) => {
    const { classes, anchorEl, User  } = props;
    let tabIndex = 0;
    const [tabValue, setTabValue] =  React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };

  function TabPanel(props) {
        const { children, value, index, ...other } = props;
       return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const servicePeriodTabId = 0;
    const projectTabId = props.TabPermissions.service_period_view ? 1 : 0;
    const accountTabId = props.TabPermissions.project_view ? projectTabId + 1 : projectTabId;
    const groupTabId = (props.TabPermissions.account_view) ? accountTabId + 1 : accountTabId;
    const preferenceTabId = (props.TabPermissions.group_view) ? groupTabId + 1: groupTabId;
    const configTabId = props.TabPermissions.preference_view ? preferenceTabId + 1: preferenceTabId; 
    const changelogTabId = props.TabPermissions.config_view ? configTabId+1 : configTabId;
    const rolesTabId = props.TabPermissions.change_history_view ? changelogTabId+1 : changelogTabId;
    return (
        <UserViewStyle>
            <div className="section-main">
                <div className="section-header">
                    <div className="col-left">
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick = {props.HandleBackClick} ></Button>
                        <div className="section-title-wrap">
                        </div>
                    </div>
                    <div className="action">
                        {(props.EditPermission) &&
                            <div className={'profile-view__action-right'}>
                                <Button variant="contained" size="small" onClick= {props.OnUpdate} color="primary" >Edit </Button>
                            </div>
                        }                 
                    </div>
                </div>
        {_R.type(User) !== 'Undefined'?
            <Paper style={{'width': '100%', 'flexGrow': '1' }}>
                <Grid container justify="space-between" alignItems="stretch" spacing={0} className={'gridRoot'}>
                    { typeof(User) != 'undefined'
                    ? <Grid  item xs={12} md={3}>
                        <Typography component="div" className={'bgWhiteLeft profile-view'} >       
                            <div className={'profile-view__left-header'}>
                                <div className={'porfile-view__avatar'}>
                                    <Avatar className={'staff_avatar'}>{User.last_name ? (User.first_name.charAt(0)+User.last_name.charAt(0)) : User.first_name.charAt(0)}</Avatar>
                                </div>
                                <div className={'profile-view__basics'}>
                                    <h1 className={'profile-view__basics-item item-title'}>{formatName(User.first_name,null,User.last_name)}</h1>
                                    <div className={'profile-view__basics-item item-designation'}>{User.staff_type ? ucwords(User.staff_type) : null }</div>
                                    <div data-icon="mail_outline" className={'profile-view__basics-item item-email'}>{User.user_email}</div>
                                    { (parseInt(User.user_type_id) !== 3) &&
                                        <div data-icon="phone" className={'profile-view__basics-item item-phone'}>{_cs.formatPhone(User.user_mobile)}</div>
                                    }
                                </div> 
                                <div className={'profile-view__action-right'}>
                                </div>
                            </div>
                            <div className={'profile-view__left-body'}>
                                <div className={'profile-view__basics_default'}>
                                    <div className={'profile-view__basics__main'}>
                                        <div className={'profile-view__basics_default-item item-role'}>
                                            <label>User Type</label><span className={"value"}>{User.user_type.toUpperCase()}</span>
                                        </div>
                                        { (User && User.user_status_name.toLowerCase() === 'active')?
                                            <div className={'profile-view__basics_default-item item-status status-active'}>
                                                <label>Status</label><span className={"value"} >{User.user_status_name.toUpperCase()}</span>
                                            </div>
                                            :<div className={'profile-view__basics_default-item item-status status-inactive'}>
                                                <label>Status</label><span className={"value"} >{User.user_status_name.toUpperCase()}</span>
                                            </div>
                                        }
                                    </div>
                                    { (User && User.date_joined) &&
                                        <div className={'profile-view__basics_default-item item-category'}><label>Date Joined</label><span className={"value"}>{User.date_joined}</span></div>
                                    }
                                    { (User && User.relieved_date) &&
                                        <div className={'profile-view__basics_default-item item-overtime'}><label>Relieved Date</label><span className={"value"}>{User.relieved_date}</span></div>
                                    }
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    :<></>
                    }
                    <Grid  item xs={12} md={9}>
                        <Tabs 
                            value={tabValue} 
                            onChange={tabChange} 
                            variant="scrollable"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="Staff Tabs"
                            scrollButtons="auto"
                            className={'tab-main'}
                        >   {(props.TabPermissions.service_period_view) && <Tab label="Service Period" value={0}/>}
                            {(props.TabPermissions.project_view) && <Tab label="Projects" value={1}/>}
                            {(props.TabPermissions.account_view) && <Tab label="Accounts"/>}
                            {(props.TabPermissions.group_view) && <Tab label="Groups"/>}
                            {(props.TabPermissions.preference_view) && <Tab label="Preferences"/>}
                            {(props.TabPermissions.config_view) && <Tab label="User Configuration"/>}
                            {(props.TabPermissions.change_history_view) && <Tab label="Change Log"/>}
                            {(props.TabPermissions.roles_view) && <Tab label="Roles"/>}
                        </Tabs>
                        <div className={'bgWhiteContent tabContainer'}>
                                {(props.TabPermissions.service_period_view) &&
                                    <TabPanel value={servicePeriodTabId} index={servicePeriodTabId}>
                                        {tabValue === servicePeriodTabId &&
                                            <ServicePeriodContainer UserId = {props.UserId} />
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.project_view) &&
                                    <TabPanel value={projectTabId} index={projectTabId}>
                                        {tabValue === projectTabId &&
                                            <ProjectsContainer UserId = {props.UserId} />
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.account_view) &&
                                    <TabPanel value={accountTabId} index={accountTabId}>
                                        {tabValue === accountTabId &&
                                            <AccountContainer UserId = {props.UserId} />
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.group_view) &&
                                    <TabPanel value={groupTabId} index={groupTabId}>
                                        {tabValue === groupTabId &&
                                            <GroupsContainer UserId = {props.UserId} />
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.preference_view) &&
                                    <TabPanel value={preferenceTabId} index={preferenceTabId}>
                                        {tabValue === preferenceTabId &&
                                            <PreferencesContainer  UserDetails = {props.User}/>
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.config_view) &&
                                    <TabPanel value={configTabId} index={configTabId}>
                                        {tabValue === configTabId &&
                                            <UserConfigurationContainer  UserId = {props.UserId}/>
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.change_history_view) &&
                                    <TabPanel value={changelogTabId} index={changelogTabId}>
                                        {tabValue === changelogTabId &&
                                        <ChangeLogContainer
                                            UserId = {props.UserId}
                                            ChangeLogData = {props.ChangeLogData}
                                            UserDetails = {props.User}
                                            />
                                        }
                                    </TabPanel>
                                }
                                {(props.TabPermissions.roles_view) &&
                                    <TabPanel value={rolesTabId} index={rolesTabId}>
                                        {tabValue === rolesTabId &&
                                            <RolesContainer
                                                UserId={props.UserId}
                                            />
                                        }
                                    </TabPanel>
                                }
                        </div>
                    </Grid>
                </Grid>
            </Paper>:<></>}
            </div>
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </UserViewStyle>
    );
};
