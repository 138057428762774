import React, {Component} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import { StaffConstants} from '../../../../constants/';
import { httpServices } from '../../../../services/httpServices'
//import related presentational components
import { AliasNameList , SupervisorList, SubordinateList, ServicePeriodList, DesignationList, WorkScheduleList, PreviousEmploymentList, StaffBasicDetails, RequestUserConfig, WorkPolicyList } from '.';


import { ErrorPage } from '../../../common/error';

const EmployementStyle = styled(React.Fragment)(({ theme }) => ({
    root:{
        padding: theme.spacing(3, 2),
        width:'100%',
        overflowX:'auto'
    },
    plane:{
        padding:0,
        marginBottom:'40px',
        boxShadow:'none',
    },
    fullHeight:{
        height:'100%'
    }
}));

// This class component is depends Data property
export class EmploymentDetails extends Component{
    // This is for future purpose
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            sp: StaffConstants.spInputs,
            errors: {},
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
    }

    handleClickOpen = () => {
        // TODO
        const {UserID} = this.props;
        let apiEndpoint = StaffConstants.API_SERVICE_PERIOD;
        return httpServices.post(apiEndpoint, {'userID':UserID })
            .then(response => {
                if (response.data) {
                    this.setState({
                        open:true,
                        sp_inputs: response.data
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleClose = () => {
        this.setState({
            open:false,
        });
    };

    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const sp = this.state.sp;
        sp[field] = evt.target.value;
        this.setState({sp: sp});
    };

    render(){
        return(
            (this.props.WorkData.permission)
            ?
            <EmploymentComponent
                WorkData={this.props.WorkData}
                YearVal={this.state.year}
                HandleYearFilterChange={this.HandleYearFilterChange}
                handleClickOpen={this.handleClickOpen}
                handleClose={this.handleClose}
                spInputs= {this.state.sp_inputs}
                spData={this.state.sp}
                HandleOnChange={this.HandleOnChange}
                open={this.state.open} 
                UserID={this.props.UserID}
                StaffID={this.props.StaffID}
                updateChange={this.props.updateChange}
            />
            :
            <ErrorPage/>
        )
    }

}

const EmploymentComponent = (props) => {
    const { WorkData, handleClickOpen, handleClose, open, UserID, StaffID , updateChange} = props;
    return (
        <EmployementStyle>
                <Grid container justify="space-between" alignItems="stretch" spacing={8}>
                    <Grid item xs={12}>
                        { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.staff_basic_details)!== 'Undefined'
                        ?   <StaffBasicDetails 
                                Edit = {WorkData.edit_permission}
                                Offices = {WorkData.offices}
                                staffBasicDetails = {WorkData.staff_basic_details}
                                updateChange={updateChange}
                                attritionType = {WorkData.attrtion_type} 
                                Locations = {WorkData.office_locations}
                                ClearanceLevels = {WorkData.clearance_levels}
                            />
                        : <></> }
                    </Grid>
                    <Grid item xs={12}>
                        <div className={'section-main'}>
                        { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.service_periods)!== 'Undefined'&&WorkData.service_periods.length
                            ?   <ServicePeriodList 
                                    handleClickOpen = {handleClickOpen} 
                                    handleClose = {handleClose} 
                                    ServicePeriods = {WorkData.service_periods}
                                    open = {open}
                                    spInputs = {props.spInputs}
                                    HandleOnChange = {props.HandleOnChange}
                                    spData = {props.spData}
                                    Edit = {WorkData.edit_permission}
                                    Delete = {WorkData.delete_permission}
                                    UserID = {UserID}
                                    StaffID = {StaffID}                           
                                    TotalExperience = {WorkData.total_experience}
                                    />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.designations)!== 'Undefined'
                                ?   <DesignationList
                                Designations={WorkData.designations}
                                JobTitles = {WorkData.job_titles}
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.supervisors)!== 'Undefined'
                                ?   <SupervisorList
                                Supervisors={WorkData.supervisors}
                                UserID = {UserID}
                                handleClose = {handleClose}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                                StaffDetails = {WorkData.staff_basic_details}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            <SubordinateList Subordinates={_R.type(WorkData)!=='Undefined'&&_R.type(WorkData.subordinates)!== 'Undefined'&& WorkData.subordinates}/>
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.work_schedule)!== 'Undefined'
                                ?   <WorkScheduleList
                                Schedule={WorkData.work_schedule}
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.alias_names)!== 'Undefined'
                                ?   <AliasNameList
                                AliasNames={WorkData.alias_names}
                                KeyIdentifiers={WorkData.key_identifiers}
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.work_policy)!== 'Undefined'
                                ?   <WorkPolicyList
                                WorkPolicy={WorkData.work_policy}
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={12}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined'&&_R.type(WorkData.previous_employment)!== 'Undefined'
                                ?   <PreviousEmploymentList
                                PreviousEmployment={WorkData.previous_employment}
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                            />
                        : <></> }
                        </div>
                    </Grid>
                    <Grid  item xs={12} md={6}>
                        <div className={'section-main'}>
                            { _R.type(WorkData) !=='Undefined' && _R.type(WorkData.request_user_config)!== 'Undefined'
                                ?   <RequestUserConfig
                                UserID = {UserID}
                                StaffID = {StaffID}
                                Edit = {WorkData.edit_permission}
                                Delete = {WorkData.delete_permission}
                                RequestUserConfig = {WorkData.request_user_config}
                                AutoApprovalRequests = {WorkData.auto_approval_requests}
                            />
                        : <></> }
                        </div>
                    </Grid>
                </Grid>
        </EmployementStyle>
    );
};
