import React from 'react';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import { formatName } from '../../common/common';

export const ChangeaccrualIgnoredComponent = (props) => {
    return (
        <>
            <React.Fragment>
                <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Accrual Ignored Timeoff"}
                        columns={[
                            { title: 'Time Off Types', field: 'timeoff_type_name' },
                        ]}
                        data={props.Accrualignored}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}

                    />
                </div>
            </React.Fragment>
        </>
    )
};