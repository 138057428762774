import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {ucfirst} from 'bellajs';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

//ag grid
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const StyledBackdrop = styled(Backdrop) (({ theme }) => ({
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

let payrollIDs = [];
let payPeriodID = null;
let gridApi = null;
const bottomOptions = {
            alignedGrids: [],

                editable: true,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
            };
const topOptions = {
            alignedGrids: [],
            defaultColDef: {
                editable: true,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
            },
            suppressHorizontalScroll: true
        };
        topOptions.alignedGrids.push(bottomOptions);
        bottomOptions.alignedGrids.push(topOptions);
//let gridColumnApi = null;
export const ReviewComponent = (props) => {
    const [shown, setShown] = React.useState('none');
    const onGridReady = params => {
        gridApi = params.api;
        //gridColumnApi = params.columnApi;
    };

    function onBtStopEditing() {
        gridApi.stopEditing();
    }

    function onRegenerate() {
        props.RegeneratePayroll(gridApi.getSelectedRows());
        gridApi.deselectAll();
    }

    let p = props.pStatus;
    
    function isFirstColumn(params) {
      var displayedColumns = params.columnApi.getAllDisplayedColumns();
      var thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }

    function onFirstDataRendered(params) {
        params.columnApi.autoSizeAllColumns();
    }
    return (
         <>
        <div className={'section-main'}>
                 <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                        <Typography variant="h6" component="h6" className={'section-title'}>
                            <>Pay Period: {props.Period}</>
                        </Typography>
                    </div>
                    <div className={'actions'}>
                        <Button size="small" variant="contained" color="secondary" onClick={onRegenerate.bind(this)}>Regenerate</Button>
                        <Button size="small" variant="contained" color="secondary" onClick={props.exportPayroll}>Export as EXCEL</Button>
                    </div>
                </div>
            </div>


        <div style={{
                        display: 'inline-block',
                        height: '80vh',
                        width: '100%' }} >
                <div style={{ display: 'flex', 'flex-direction': 'column', height: '100%' }} className="ag-theme-balham">
                    <div style={{flex: '1 1 auto'}} >

                    <AgGridReact
                        // define columns
                        columnDefs={props.Columns}
                        rowData={props.Rows}
                         gridOptions={topOptions}
                        rowClassRules= {{
                         "bg-highlight": function(params) {
                          if( (params.data.relieve_status) || (params.data.new_joinee) )
                          return true ;
                         }
                       }}
    
                        defaultColDef={{
                            resizable: true,
                            width: 20,
                            headerCheckboxSelection: isFirstColumn,
                            checkboxSelection: isFirstColumn,
                        }}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        rowSelection={'multiple'}
                        rowDeselection={true}
                        suppressRowClickSelection={true}
                        suppressMenuHide={true}
                        onCellValueChanged={(cellData) => { props.OnCellEdit(cellData.data, cellData.value, cellData.colDef.field)}}
                        onSelectionChanged={(event) => { 
                            let rows = event.api.getSelectedRows();
                            setShown(rows.length ? 'block' : 'none');  
                            if (rows.length) {
                                payrollIDs = rows
                                    .map( item => item.payroll_id )
                                    .filter( ( item, idx, arr ) => arr.indexOf( item ) === idx ) 
                                payPeriodID = rows[0]['pay_period_id'];
                            }
                        }}
                        isRowEditable={(rowNode) =>{
                            return rowNode.data ? rowNode.data.status !== '3' : false;
                        }}
                        isRowSelectable={(rowNode) =>{
                            return rowNode.data ? rowNode.data.status !== '3' : false;
                        }}
                        onCellEditingStarted={(rowNode)=>{
                            if (rowNode.data && rowNode.data.status === '3') {
                                onBtStopEditing()
                            };
                        }}
                    >
                    </AgGridReact>
                    </div>
                    <div style={{flex: 'none', height: '60px'}}>

                        <AgGridReact
                            rowData={props.ColumnTotal}
                            gridOptions={bottomOptions}
                            columnDefs={props.Columns}
                            headerHeight="0"
                            rowStyle={{fontWeight: 'bold'}}
                        />
                    </div>
            </div>
        </div>
            <div style={{ 'float': 'right', 'marginTop':'1%', 'display': shown}}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl>
                            <InputLabel shrink htmlFor="age-label-placeholder"> Payroll Status </InputLabel>
                            <Select
                                inputProps={{
                                    name: 'p_status',
                                    id: 'p_status',
                                }}
                                displayEmpty
                                name="p_status"
                                autoWidth
                                onChange={props.HandleOnChange}
                                value={(p!==null)?p:''}
                            >
                                <MenuItem value=""> <em>None</em></MenuItem>
                                {typeof (props.PayrollStatus) !== 'undefined' && props.PayrollStatus.map((p, i) =>
                                    <MenuItem value={p.id} key={'p-status-'+i}>{ucfirst(p.title)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" size="small" color="primary" align="right" disabled={props.pStatus?false:true} onClick={()=> {props.HandleSaveStatus(payrollIDs, payPeriodID)}} >{'Ok'}</Button>
                    </Grid>
                </Grid>
            </div>
            <StyledBackdrop className={'backdrop'} open={props.LoaderOpen}>
                <CircularProgress color="inherit" />
            </StyledBackdrop>
        </>
    )
};
