import React from 'react';
import {SyncInstancesComponent}  from './SyncInstances.Component';
import { httpServices }  from '../../../services/httpServices';
import { history } from '../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class SyncInstancesContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
           instances : [],
           hasPermission : false,
           dataLoaded :false,
           showLoader: false
        };
        this.viewLink = this.viewLink.bind(this);
    }
    componentDidMount = () => {
        this.getAllInstances();
        
    };
    getAllInstances = () => {
        this.setState({
            showLoader: true
        });
        let apiEndpoint = 'api/settings/syncinstance/list-sync-instances';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            hasPermission: response.data.permission,
                            instances: response.data.sync_instance_names,
                            dataLoaded: true,
                            showLoader: false
                        });
                    }else{
                        this.setState({
                            dataLoaded: true,
                            showLoader: false
                        });
                    }
                }
            })          
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    viewLink = (name) => ()=> {
        window.scrollTo(0, 0); 
        let link = '/system/instancedetails';
        history.push({
            pathname:BASE_PATH+link,
            state:{
                instanceName : name
            }
        });
    }

    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.dataLoaded)
            ?
                (this.state.hasPermission)
                ?
                <SyncInstancesComponent
                    Instances = {this.state.instances}
                    ViewLink = {this.viewLink}
                />
                : <ErrorPage Message={'You are not authorized to view this page.'} />
            :<></>
            }
            </>
        )
        
    }
}
