import React from 'react';
import {ReviewComponent} from './ReviewList.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from './../../../helpers/history';
import { ViewExpenseDetailsContainer } from '../ViewExpenseDetails.Container';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const formFields = {
    'voucherStatus' :-1, 
    'year': new Date().getFullYear(),
    'month': 0,
    'employee' :0,
    'paymentDate' :null,
    'paymentDateObj' : null,
    'payment_date' : null,
    'payment_mode' : 2,
    'paid_note' : ''
};
export class ReviewExpenseContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            formFields : formFields,
            hasPermission : true,
            voucherList : {},
            isFinanceTeam : false,
            filterViewState : false,
            submittedAmount :null,
            actualPayment : null,
            openViewDetails : false,
            userId : null,
            voucherStatus : null,
            staffNameList : null,
            permisionError: '',
            paymentOpen: false,
            selectedVouchers: [],
            snackOpen: false,
            snackVarient: 'success',
            snackMessage: 'Expense voucher status changed to Paid',
            submittedAmountValue : '',
            actualPaymentValue : '',
            errors:{},
            buttonLoader : false,
            showLoader : false,
            anchorEl: null,
        }
        this.getReviewList = this.getReviewList.bind(this);
        this.filterView = this.filterView.bind(this);
        this.getExpenseDetails = this.getExpenseDetails.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleExportIIF = this.handleExportIIF.bind(this);
        this.handlePayButton = this.handlePayButton.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handlePaymentDateChange = this.handlePaymentDateChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.snakbarClose = this.snakbarClose.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
        this.handleExportClose = this.handleExportClose.bind(this);
    }

    componentDidMount = () => {
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;
        if (fromDashboard) {
            let formFields = this.state.formFields;
            formFields['year'] = new Date().getFullYear();
            formFields['month'] = 0;
            formFields['employee'] = 0;
            formFields['voucherStatus'] = -1;
            formFields['paymentDate'] = null;
            this.setState({
                formFields: formFields
            });
        }
        this.getReviewList();
        //this.handlePayment();
    };

    filterSubmit = () =>{
        this.setState({filterViewState : false});
        this.getReviewList();
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
    }

    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    
    getExpenseDetails =(id)=>{
        history.push(BASE_PATH+'/expenses/review/' +id);
    }

    handleExportIIF = (version) => {
        document.getElementById("export_version").value = version;
        document.getElementById("reviewList").submit();
        this.handleExportClose();
    }

    getReviewList=()=> {
        this.setState({showLoader : true});
        let data = this.state.formFields;
        return httpServices.post('api/expenses/reviewList',data)
            .then(response => {
                if (response.data) {
                    if(response.data.permission === false)
                    {
                        this.setState({
                            permisionError: "You are not authorized to do this action",
                            showLoader : false,
                        });
                    }
                    else
                    {
                        this.setState({voucherList: response.data.expenseVoucherList,
                            isFinanceTeam : response.data.is_finance_team,
                            filterViewState : false,
                            submittedAmount: response.data.submitted_amount_total,
                            submittedAmountValue : response.data.submitted_amount_total_format,
                            actualPayment: response.data.actual_payment_total,
                            actualPaymentValue: response.data.actual_payment_total_format,
                            voucherStatus : response.data.status,
                            staffNameList : response.data.employees,
                            showLoader : false
                            });
                    }                    
                }
            });
    }

    handlePayButton = (props) => {
        this.setState({
            paymentOpen: true
        });
    }
    handleDateChange = (date) => {
        let dateobj = this.getStandardDates(date);
        const f = this.state.formFields;
        f['paymentDate'] = dateobj.modified_date;
        f['paymentDateObj'] = dateobj.display_date;
        this.setState({
            formFields: f
        })
    };

    handleSelection = (e) => {
        const options = this.state.selectedVouchers;
        let index;
        if (e.target.checked) {
            options.push(+e.target.value)
            this.setState({
                selectedVouchers: options,
            });
        } else {
            index = options.indexOf(+e.target.value)
            options.splice(index, 1)
            this.setState({
                selectedVouchers: options,
            });
        }
        options.sort()
    }
    handlePayment = (props) => {
        if (this.handlePaymentValidation()) {
            this.setState({ buttonLoader: true });
            let data = this.state.formFields;
            if (typeof data.expense_date === 'object') {
                let dateobj = this.getStandardDates(data.payment_date);
                data.payment_date = dateobj.modified_date;
            }

            let value = { 'action': 'paid', 'note': data.paid_note, 'pay_mode': data.payment_mode, 'pay_date': data.payment_date, 'ids': this.state.selectedVouchers }
            httpServices.post('api/expenses/payment', value)
                .then(response => {
                    if (response.data.valid) {
                        this.setState({
                            selectedVouchers: [],
                            paymentOpen: false,
                            snackOpen: true,
                            buttonLoader: false,
                        });
                        this.getReviewList();
                    }
                    else {
                        this.setState({
                             errors: response.data.errors,
                             buttonLoader: false,
                         });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });

        }
        props.preventDefault();
    }

     handlePaymentValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.payment_date || this.state.formFields.payment_date === null || isNaN(Date.parse(this.state.formFields.payment_date))) {
            errors["payment_date"] = "Payment date cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
     }

     handleCancel= (props) => {
        this.setState({
            paymentOpen: false
        });
     }

     handlePaymentDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };

    snakbarClose = (id) => {
        this.setState({
            snackOpen: false
        });
    }

    getStandardDates = (date) => {
        let resObj = {
            modified_date: null,
            display_date: null,
        }
        if(date){
            let dateobj = new Date(date);
            let year = dateobj.getFullYear();
            let month = dateobj.getMonth() + 1;
            let tempMonth = dateobj.getMonth();
            if (month < 10) {
                month = '0' + month;
            }
            let cdate = dateobj.getDate();
            let tempDate = dateobj.getDate();
            if (cdate < 10) {
                cdate = '0' + cdate;
            }
            resObj['modified_date'] = year + '-' + month + '-' + cdate;
            resObj['display_date'] = new Date(year,tempMonth,tempDate);
        }   
        return resObj;
    }

    handleExportClick = (event) => {
        this.setState({ anchorEl: event.currentTarget});
    };
    handleExportClose = () => {
        this.setState({ anchorEl: null });
    };

    render(){
        return(
            (this.state.openViewDetails === true && this.state.userId !== null )?
                <ViewExpenseDetailsContainer
                    UserId = {this.state.userId}
                    Page = {"review"}
                    SubmittedAmountValue = {this.state.submittedAmountValue}
                    ActualPaymentValue = {this.state.actualPaymentValue}
                    GetReviewList= {this.getReviewList}
                />
            :
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            <ReviewComponent 
                GetReviewList = {this.getReviewList}
                VoucherList = {this.state.voucherList}
                IsFinanceTeam = {this.state.isFinanceTeam}
                FilterView = {this.filterView}
                FilterViewState = {this.state.filterViewState}
                SubmittedAmount = {this.state.submittedAmount}
                ActualPayment = {this.state.actualPayment}
                GetExpenseDetails = {this.getExpenseDetails}
                VoucherStatus = {this.state.voucherStatus}
                StaffNameList = {this.state.staffNameList}
                FormFields = {this.state.formFields}
                FilterSubmit = {this.filterSubmit}
                HandleOnChange = {this.handleOnChange}
                permisionError ={this.state.permisionError}
                HandleExportIIF ={this.handleExportIIF}
                handlePayButton={this.handlePayButton}
                handlePayment={this.handlePayment}
                handleCancel={this.handleCancel}
                paymentOpen={this.state.paymentOpen}
                handlePaymentDateChange = {this.handlePaymentDateChange}
                handleSelection = {this.handleSelection}
                selectedVouchers = {this.state.selectedVouchers}
                snackOpen = {this.state.snackOpen}
                snackVarient = {this.state.snackVarient}
                snackMessage = {this.state.snackMessage}
                Errors ={this.state.errors}
                handleDateChange = {this.handleDateChange}
                ButtonLoader = {this.state.buttonLoader}
                snakbarClose = {this.snakbarClose}
                HandleExportClick = {this.handleExportClick}
                AnchorEl = {this.state.anchorEl}
                HandleExportClose = {this.handleExportClose}
            />
            </>
        )
    }
}
