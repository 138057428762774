import React from 'react';

import { httpServices }  from '../../../services/httpServices';
import { ExpenseLimitComponent }  from './ExpenseLimit.Component.js';

let formFields = {
    year : new Date().getFullYear(),
    percentage : null,
    id : 0,
}
export class ExpenseLimitContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            expenseLimits : [],
            editPermission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarType : '', 
        }
        this.addExpenseLimitClick = this.addExpenseLimitClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => {
        this.getAllExpenseLimits();
    };

    getAllExpenseLimits = () => {
        let apiEndpoint = 'api/settings/expenses/listExpenseLimit';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        expenseLimits : response.data.expenseLimits,
                        editPermission : response.data.edit_permission,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addExpenseLimitClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/settings/expenses/addExpenseLimit', { data })
                    .then(response => {
                        if (response.data.valid) {
                            this.getAllExpenseLimits();
                            let successMsg = ''
                            if (data.id) {
                                successMsg = 'Expense limit updated successfully';
                            } else {
                                successMsg = 'Expense limit added successfully';
                            }
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: successMsg,
                                snackbarType: 'success',
                                open: false,
                                popupType: '',
                            });
                        } else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                });
                            }
                            else {
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: response.data.errorMsg,
                                    snackbarType: 'error',
                                    open: false,
                                    popupType: '',
                                });
                            }
                        }
                        let ff = this.state.formFields;
                        for (let key in ff) {
                            if (key === 'year') {
                                ff[key] = new Date().getFullYear();
                            }
                            else if (key === 'percentage') {
                                ff[key] = null;
                            } else if (key === 'id') {
                                ff[key] = 0;
                            }
                        }
                        this.setState({
                            formFields: ff,
                        });

                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.percentage) {
            errors["percentage"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/expenses/getExpenseLimitById';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                   if (response.data.expenseLimit) {
                       let formFields = {
                           id : response.data.expenseLimit.id,
                           year : response.data.expenseLimit.year,
                           percentage : response.data.expenseLimit.percentage,
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <ExpenseLimitComponent
                ExpenseLimits = {this.state.expenseLimits}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddExpenseLimitClick = {this.addExpenseLimitClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                SnackbarType = {this.state.snackbarType}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
            />
        )
    }
}
