import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';

import { formatName } from '../../common/common';

export const StatusChangeLog = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{minHeight:'200px'}}>
        <Table  size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>Modified On</TableCell>
                    <TableCell>Modified By</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Status Change</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {_R.type(props.StatusChangeLog) !== 'Undefined' && (props.StatusChangeLog).map((item) => 
                    <TableRow key = {item.id}>
                        <TableCell component="th" scope="row"> {item.created_on} </TableCell>
                        <TableCell component="th" scope="row"> {formatName(item.user_first_name,null,item.user_last_name)} </TableCell>
                        <TableCell component="th" scope="row"> {item.action} </TableCell>
                        <TableCell component="th" scope="row"> {item.comment} </TableCell>
                        <TableCell component="th" scope="row"> {item.previous_status_name} => {item.current_status_name} </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </div>
  );
}
