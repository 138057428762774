import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { ChangehistoryContainer }  from './changeHistory.Container.js';
import { ChangeaccrualIgnoredContainer }  from './changeAccrualIgnored.Container.js';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import * as _R from 'ramda';  // used 0.04kb
import { DialogBoxLarge } from '../../common/dialog.large';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PolicyContainer }  from './Policy.Container.js';
import { ConfigContainer }  from './PtoConfig.Container.js';
import { CustomizedSnackbars } from '../../common/snackbars';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';

export const TimeOffTypesViewComponent = (props) => {
	console.log(props);
    const [tabValue, setTabValue] = React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    
    return (
        <div>
            <div className={'section-main section-main-header-fixed'}><div className={'header-fixed'} >
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                        <Typography variant="h6" component="h6" className={'section-title'}>
                            {(_R.type(props.FormFieldsView.to_type) !== 'Undefined') &&
                                <>
                                    {props.FormFieldsView.to_type}
                                </>
                            }
                        </Typography>
                    </div>
                    <div className={'actions'}>
                        <Button variant="contained" color="primary" onClick={props.OnEditClick}>EDIT</Button>
                        {props.PopupType === 'edit' &&
                            <DialogBoxLarge
                                Open={props.DialogOpen}
                                Cancel={props.OnClose}
                                Title={'Edit Time Off Type'}
                                OnSubmit={props.OnSubmit}
                                Content={<DialogEdit FormProps={props} />}
                                Loading={props.ButtonLoader}
                            />
                        }
                    </div>
                </div></div>
                <div className={'section-body'}>
                    <Paper className={'mb-1'}>
                        {!_R.isEmpty(props.FormFieldsView) &&
                            <div className={'timesheet_list-summary'}>
                                <div className={'p-1rem summary box-details'}>
                                    <ul className={'list-standard'}>
                                        <li className={'list-item list-item-status'}>
                                            <label>Description</label>
                                            <span className={'value'}>{props.FormFieldsView.description}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Accrual</label>
                                            <span className={'value'}>{props.FormFieldsView.accrual === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Adjustments</label>
                                            <span className={'value'}>{props.FormFieldsView.adjustments === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        {props.FormFieldsView.adjustment_type &&
                                        <li className={'list-item'}>
                                            <label>Adjustment Type</label>
                                            {props.FormFieldsView.adjustment_type.map((option, i) =>
                                            <>{option} </>
                                            )}
                                        </li>
                                        }
                                        {(props.FormFieldsView.adjustments === 1) &&
                                            <li className={'list-item'}>
                                                <label>Adjustment Period</label>
                                                <span className={'value'}>{props.FormFieldsView.adjustment_period ? props.FormFieldsView.adjustment_period : ''}</span>
                                            </li>
                                        }
                                        <li className={'list-item'}>
                                            <label>Approver</label>
                                            <span className={'value'}>{props.FormFieldsView.approver}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Calendar Days</label>
                                            <span className={'value'}>{props.FormFieldsView.calendar_days === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Custom Workflow</label>
                                            <span className={'value'}>{props.FormFieldsView.custom_workflow}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Add Eligibility Criteria</label>
                                            <span className={'value'}>{props.FormFieldsView.is_eligibility_criteria === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        {(props.FormFieldsView.is_eligibility_criteria === 1) && <li className={'list-item'}>
                                            <label>Eligibility Criteria Unit</label>
                                            <span className={'value'}>{props.FormFieldsView.eligibility_criteria_unit ? props.FormFieldsView.eligibility_criteria_unit : ''}</span>
                                        </li>}
                                        {(props.FormFieldsView.is_eligibility_criteria === 1) && <li className={'list-item'}>
                                            <label>Staff Eligibility Criteria Override</label>
                                            <span className={'value'}>{props.FormFieldsView.eligibility_criteria_staff_override === 0 ? 'No' : 'Yes'}</span>
                                        </li>}
                                        <li className={'list-item'}>
                                            <label>Reset Calendar Year Balance</label>
                                            <span className={'value'}>{props.FormFieldsView.reset_calendar_year === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Status</label>
                                            <span className={'value'}>{props.FormFieldsView.status === 0 ? 'Inactive' : 'Active'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Created by</label>
                                            <span className={'value'}>{props.FormFieldsView.createdby ? props.FormFieldsView.createdby : ''}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Modified by</label>
                                            <span className={'value'}>{props.FormFieldsView.modifiedby ? props.FormFieldsView.modifiedby : ''}</span>
                                        </li>
                                    </ul>
                                    <ul className={'list-standard list-item-right'}>
                                        <li className={'list-item list-items-hours'}>
                                            <label>Enable Grant</label>
                                            <span className={'value'}>{props.FormFieldsView.enable_grant === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item list-items-min-hours'}>
                                            <label>Time Off Type Suggestions Weight</label>
                                            <span className={'value'}>{props.FormFieldsView.weight}</span>
                                        </li>
                                        <>
                                            <li className={'list-item list-items-min-hours'}>
                                                <label>Prorate Eligible Hours</label>
                                                <span className={'value'}>{props.FormFieldsView.prorate_hours === 0 ? 'No' : 'Yes'}</span>
                                            </li>
                                        </>
                                        <li className={'list-item'}>
                                            <label>Time Entry Editable</label>
                                            <span className={'value'}>{props.FormFieldsView.editable === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Allow Partial Hours</label>
                                            <span className={'value'}>{props.FormFieldsView.allow_partial_hours === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Requests Per Year</label>
                                            <span className={'value'}>{props.FormFieldsView.requests_per_year}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Paid</label>
                                            <span className={'value'}>{props.FormFieldsView.paid === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Show Variable Name</label>
                                            <span className={'value'}>{props.FormFieldsView.show_variable_name === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Accrual Timesheet Status </label>
                                            <span className={'value'}>{props.FormFieldsView.accrualStatusName}</span>
                                        </li>
					<li className={'list-item'}>
                                            <label>Event Based</label>
                                            <span className={'value'}>{props.FormFieldsView.event_based === 0 ? 'No' : 'Yes'}</span>
                                        </li>
                                         <li className={'list-item'}>   
                                            <label>Created on</label>
                                            <span className={'value'}>{props.FormFieldsView.created_on ? props.FormFieldsView.created_on : ''}</span>
                                        </li>
                                        <li className={'list-item'}>
                                            <label>Modified on</label>
                                            <span className={'value'}>{props.FormFieldsView.modified_on ? props.FormFieldsView.modified_on : ''}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                        <>
                        </>
                    </Paper>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {props.FormFieldsView.is_eligibility_criteria === 1 && <Tab label="ELIGIBILITY CRITERIA"  /> }
                                <Tab label="CONFIG" />
                                <Tab label="ACCRUAL IGNORED TIME OFF TYPE" />
                                <Tab label="CHANGE HISTORY" />
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                        {props.FormFieldsView.is_eligibility_criteria === 1 && <TabPanel value={tabValue} index={0}>
                                <PolicyContainer
                                    timeoffId={props.TimeoffTypeId}
                                    policyUnit = {props.FormFieldsView.eligibility_criteria_unit ? props.FormFieldsView.eligibility_criteria_unit : ''}
                                />
                            </TabPanel>}
                            <TabPanel value={tabValue} index={props.FormFieldsView.is_eligibility_criteria === 0 ? 0 : 1}>
                                <ConfigContainer
                                    timeoffId={props.TimeoffTypeId}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={props.FormFieldsView.is_eligibility_criteria === 0 ? 1 : 2}>
                                <ChangeaccrualIgnoredContainer
                                    timeoffId={props.TimeoffTypeId}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={props.FormFieldsView.is_eligibility_criteria === 0 ? 2 : 3}>
                                <ChangehistoryContainer
                                    timeoffId={props.TimeoffTypeId}
                                />
                            </TabPanel>
                        </div>
                    </Paper>
                </div>
            </div>
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant='success'
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            }
        </div>
    );
}
const DialogEdit = (props) => {
    const { FormFieldsEdit, HandleOnChange, Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="to_type"
                            name="to_type"
                            label="Time Off Type"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={FormFieldsEdit.to_type ? FormFieldsEdit.to_type : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.to_type !== 'undefined' && Errors.to_type) && true}
                            helperText={typeof Errors.to_type !== 'undefined' && Errors.to_type}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rowsMax="4"
                            InputLabelProps={{ shrink: true }}
                            value={FormFieldsEdit.description ? FormFieldsEdit.description : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.description !== 'undefined' && Errors.description) && true}
                            helperText={typeof Errors.description !== 'undefined' && Errors.description}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Custom Workflow</InputLabel>
                        <Select
                            value={FormFieldsEdit.custom_workflow ? FormFieldsEdit.custom_workflow : null}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'custom_workflow',
                                id: 'policy'
                            }}
                            className={'input-item'}
                        >
                            {FormFieldsEdit.policylist.map((policy, i) =>
                            <MenuItem value={policy}>{policy}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Accrual</InputLabel>
                        <Select
                            value={FormFieldsEdit.accrual ? FormFieldsEdit.accrual : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'accrual',
                                id: 'accrual'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Reset Calendar Year Balance</InputLabel>
                        <Select
                            value={FormFieldsEdit.reset_calendar_year ? FormFieldsEdit.reset_calendar_year : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'reset_calendar_year',
                                id: 'reset_calendar_year'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Calendar Days</InputLabel>
                        <Select
                            value={FormFieldsEdit.calendar_days ? FormFieldsEdit.calendar_days : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'calendar_days',
                                id: 'calendar_days'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Paid</InputLabel>
                        <Select
                            value={FormFieldsEdit.paid ? FormFieldsEdit.paid : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'paid',
                                id: 'paid'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            id="requests_per_year"
                            name="requests_per_year"
                            label="Requests Per Year"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={FormFieldsEdit.requests_per_year}
                            onChange={HandleOnChange}
                            error={(typeof Errors.requests_per_year !== 'undefined' && Errors.requests_per_year) && true}
                            helperText={typeof Errors.requests_per_year !== 'undefined' && Errors.requests_per_year}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Adjustments</InputLabel>
                        <Select
                            value={FormFieldsEdit.adjustments ? FormFieldsEdit.adjustments : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'adjustments',
                                id: 'adjustments'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                {(FormFieldsEdit.adjustments === 1) &&
                    <Grid item xs={12} md={3}>
                        <FormControl variant="standard">
                            <InputLabel>Adjustment Period</InputLabel>
                            <Select
                                value={FormFieldsEdit.adjustment_period ? FormFieldsEdit.adjustment_period : ''}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'adjustment_period',
                                    id: 'adjustment_period'
                                }}
                                className={'input-item'}
                            >
                                <MenuItem value='year'>Year</MenuItem>
                                <MenuItem value='payperiod'>Payperiod</MenuItem>
                            </Select>
                            {(typeof Errors.adjustment_period !== 'undefined' && Errors.adjustment_period) &&
                                <FormHelperText className='error'>{Errors.adjustment_period}</FormHelperText>
                            }
                        </ FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Approver</InputLabel>
                        <Select
                            value={FormFieldsEdit.approver ? FormFieldsEdit.approver : 'Supervisor'}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'approver',
                                id: 'approver'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='Supervisor'>Supervisor</MenuItem>
                            <MenuItem value='Senior Management'>Senior Management</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Time Entry Editable</InputLabel>
                        <Select
                            value={FormFieldsEdit.editable ? FormFieldsEdit.editable : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'editable',
                                id: 'editable'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Enable Grant</InputLabel>
                        <Select
                            value={FormFieldsEdit.enable_grant ? FormFieldsEdit.enable_grant : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'enable_grant',
                                id: 'enable_grant'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl>
                        <TextField variant="standard"
                            id="weight"
                            name="weight"
                            label="Time Off Type Suggestions Weight"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={FormFieldsEdit.weight ? FormFieldsEdit.weight : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.weight !== 'undefined' && Errors.weight) && true}
                            helperText={typeof Errors.weight !== 'undefined' && Errors.weight}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={FormFieldsEdit.status ? FormFieldsEdit.status : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Allow Partial Hours</InputLabel>
                        <Select
                            value={FormFieldsEdit.allow_partial_hours ? FormFieldsEdit.allow_partial_hours : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'allow_partial_hours',
                                id: 'allow_partial_hours'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Prorate Eligible Hours</InputLabel>
                        <Select
                            value={FormFieldsEdit.prorate_hours ? FormFieldsEdit.prorate_hours : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'prorate_hours',
                                id: 'prorate_hours'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Add Eligibility Criteria</InputLabel>
                        <Select
                            value={FormFieldsEdit.is_eligibility_criteria ? FormFieldsEdit.is_eligibility_criteria : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'is_eligibility_criteria',
                                id: 'is_eligibility_criteria'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                {(FormFieldsEdit.is_eligibility_criteria === 1) && <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Eligibility Criteria Unit</InputLabel>
                        <Select
                            value={FormFieldsEdit.eligibility_criteria_unit ? FormFieldsEdit.eligibility_criteria_unit : ''}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'eligibility_criteria_unit',
                                id: 'eligibility_criteria_unit'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='year'>Year</MenuItem>
                            <MenuItem value='month'>Month</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>}
                {(FormFieldsEdit.is_eligibility_criteria === 1) && <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Staff Eligibility Criteria Override</InputLabel>
                        <Select
                            value={FormFieldsEdit.eligibility_criteria_staff_override ? FormFieldsEdit.eligibility_criteria_staff_override : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'eligibility_criteria_staff_override',
                                id: 'eligibility_criteria_staff_override'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>}
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Show Variable Name</InputLabel>
                        <Select
                            value={FormFieldsEdit.show_variable_name ? FormFieldsEdit.show_variable_name : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'show_variable_name',
                                id: 'show_variable_name'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
	    	<Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Event Based</InputLabel>
                        <Select
                            value={FormFieldsEdit.event_based ? FormFieldsEdit.event_based : 0}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'event_based',
                                id: 'event_based'
                            }}
                            className={'input-item'}
                        >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl variant="standard">
                        <InputLabel>Accrual Timesheet Status</InputLabel>
                        <Select
                            value={FormFieldsEdit.accrual_timesheet_status ? FormFieldsEdit.accrual_timesheet_status : null}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'accrual_timesheet_status',
                                id: 'timesheetStatus'
                            }}
                            className={'input-item'}
                        >
                            {FormFieldsEdit.timesheetStatusList.map((timesheetStatus, i) =>
                                <MenuItem value={timesheetStatus.timesheet_status_id}>{timesheetStatus.timesheet_status_name}</MenuItem>
                            )}
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                <FormControl>
                        <FormLabel>Adjustment Type</FormLabel>
                    <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={HandleOnChange}
                                value={FormFieldsEdit.adjustment}
                                checked={FormFieldsEdit.adjustment}
                                name='adjustment'
                                color="primary"
                            />
                        }
                        label="Adjustment"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={HandleOnChange}
                                value={FormFieldsEdit.carry_forward}
                                checked={FormFieldsEdit.carry_forward}
                                name='carry_forward'
                                color="primary"
                            />
                        }
                        label="Carry Forward"
                    />
                    </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
