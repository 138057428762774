import React from 'react';
import { ReportConfigComponent } from './ReportConfigComponent';
import { httpServices } from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import { history } from '../../helpers/history';
import { CustomProgressBar } from '../common/CustomProgressBar';

const formFields = {
    'id': 0,
    'name': null,
    'code': null,
    'description': null,
    'status': null,
};

export class ReportConfig extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            report_config: [],
            title : 'Report Config',
            formFields: formFields,
            open: false,
            errors: {},
            Open: false,
            hasPermission : true,
            dataLoaded : false,
            showLoader : true,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getAllReports();
    };
    getAllReports = () => {
        let apiEndpoint = 'api/system/reportconfig';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    if (response.data.report_config && response.data.report_config.length) {
                        this.setState({
                        report_config : response.data.report_config,
                        showLoader : false,
                        dataLoaded:true
                        });
                    }
                } else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                        dataLoaded:true
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
            this.setState({ 
                formFields: ff
            });        
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/system/edit-report-config', { data })
                    .then(response => {
                        if (response.data.valid) {
                            if(data.id){
                                let details = response.data.report_config;
                                this.setState({report_config:details, Open: false});
                                alert("Report config updated successfully");
                            }
                        }
                        else
                        {
                            this.setState({errors:response.data.errors});
                        }                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        
        }
    };

  

    clearForm = () => {
        const ff = {
             'id':0,
             'name': null,
             'code': null,
             'description': null,
             'status': null,
          };

        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };
    
    onClose = () =>{
        this.clearForm();
        this.setState({
            Open: false
        });
    };
    
    onUpdate = (id) => {
        return httpServices.post('api/system/get-report-config-by-id', {'id': id})
            .then(response => { 

                if (response.data.permission) {
                    let data = response.data.report_config;
                    if (Object.keys(data).length){
                        const inputs = {
                            'id': data.id,
                            'name': data.name,
                            'code': data.code,
                            'description': data.description,
                            'status': data.status,              
                        };
                        this.setState({
                            formFields: inputs,
                            Open: true,
                            id: data.id,
                        });
                    }
                }
                else
                {
                    this.setState({
                        hasPermission : false,
                    });

                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }


 
    handleValidation() {
        let formIsValid = true;
        let errors = {};       
     
        if (!this.state.formFields.name) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.description) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }

        if (this.state.formFields.status ==='') {
            errors["status"] = "Cannot be empty";
            formIsValid = false;
        }
       
        this.setState({errors: errors});
        return formIsValid;
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('reportconfig/details/' + id);
        }
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    { (this.state.hasPermission) ?
                    <ReportConfigComponent
                        Title={this.state.title}
                        report_config = {(this.state.report_config) && (this.state.report_config.length > 0)  ? this.state.report_config:this.props.report_config}
                        FormFields={this.state.formFields}
                        OnSubmit={this.handleSubmit}
                        HandleOnChange={this.handleOnChange}
                        Open= {this.state.Open}
                        OnClose={this.onClose.bind(this)}
                        OnUpdate={this.onUpdate.bind(this)}
                        Errors={this.state.errors}
                        ViewReport={this.handleViewDetails.bind(this)}
                    />
                    : <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
