import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import parse from 'html-react-parser';
import { formatName,nl2br } from '../../common/common';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Autocomplete from '@mui/material/Autocomplete';
import * as _R from 'ramda';
import FormHelperText from '@mui/material/FormHelperText';

const API_URL = process.env.REACT_APP_API_URL;
const nameFormat = 'FIRSTLAST';
const columns = [
    { title: 'Pay Period', field: 'payperiod_sdate',render: rowData =><>{rowData.payperiod_sdate+'-'+rowData.payperiod_edate}</>},
    { title: 'Staff Name', field: 'first_name',render: rowData =><>{formatName(rowData.first_name,null,rowData.last_name,nameFormat)}</>},
    { title: 'First Submitted On', field: 'first_submission_date' ,render: rowData =><span className={'error'}>{rowData.first_submission_date}</span>},
    { title: 'Deadline', field: 'deadline'},
    { title: 'No. Of Days Delayed', field: 'submission_delay',render: rowData =><span className={'add_review_note_btn'}>{rowData.submission_delay}</span>},
    { title: 'Reason For Late Submission', field: 'late_submission_reason', render: rowData =><span>{parse(nl2br(rowData.late_submission_reason))}</span>},
    { title: 'Waiver Recommended (Yes / No)', field: 'waiver_status',render: rowData =><span>{rowData.waiver_status === 2 ? 'Yes' : (rowData.waiver_status === 3) ? 'No' : ''}</span>},
    { title: 'Justification of Waiver by Reviewer', field: 'waiver_justification', render: rowData =><span>{parse(nl2br(rowData.waiver_justification))}</span>},
    { title: 'Supervisor', field: 'supervisors'},
];
export const TimesheetLateSubmissionComponent  = (props) => {
    const lateSubmissionData = typeof props.ReportData !== 'undefined' ? props.ReportData : [];
    const gridStyle = {
        margin: '15px 0px'
    };
    return(
            <React.Fragment>
                <Paper>
                    <div className={'p1'}>
                        <Typography variant="h6" component="h6" className={'section-title'}>Timesheet Late Submission Report</Typography>
                        <form id="export-form" className="form-fullwidth" action={API_URL+'api/report/timesheetLateSubmission'} method="post">
                        <input type="hidden" id="export_csv" name="export_csv" /> 
                            <Grid container spacing={2} style={gridStyle}>
                                <Grid item  md={2} className='justify-between'>
                                    <FormControl>
                                        <TextField variant="standard"
                                            value = {typeof props.FormValues.start_date !== 'undefined' ? props.FormValues.start_date : ''}
                                            id="start_date"
                                            name="start_date"
                                            label="From Date"
                                            type="date"
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={props.HandleOnChange}
                                            inputProps={{
                                                max: (typeof props.FormValues.end_date !== 'undefined') ? props.FormValues.end_date : null,
                                            }}
                                            error = { typeof props.Errors.start_date !== 'undefined' && true}
                                            helperText = { typeof props.Errors.start_date !== 'undefined' && props.Errors.start_date }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item  md={2} className='justify-between'>
                                    <FormControl>
                                        <TextField variant="standard"
                                            value = {typeof props.FormValues.end_date !== 'undefined' ? props.FormValues.end_date : ''}
                                            id="end_date"
                                            name="end_date"
                                            label="To Date"
                                            type="date"
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={props.HandleOnChange}
                                            error = { typeof props.Errors.end_date !== 'undefined' && true}
                                            helperText = { typeof props.Errors.end_date !== 'undefined' && props.Errors.end_date }
                                            inputProps={{
                                                min: (typeof props.FormValues.start_date !== 'undefined') ? props.FormValues.start_date : null,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item  xs={12} sm={6} md={4}>
                                    <input type='hidden' name='staffStatus' value={(typeof props.FormValues.staff_status_ids !== 'undefined' && props.FormValues.staff_status_ids) ? props.FormValues.staff_status_ids : []}/>
                                    <Autocomplete
                                        value={(!_R.isEmpty(props.FormValues.staffStatus) && props.FormValues.staffStatus) ? props.FormValues.staffStatus : (typeof props.FormValues.staffStatusList !== 'undefined' ? [props.FormValues.staffStatusList[0]] :[])}
                                        onChange={props.HandleStaffStatusChange}
                                        multiple
                                        id="tags-outlined"
                                        options={(typeof props.FormValues.staffStatusList !== 'undefined') ? props.FormValues.staffStatusList : []}
                                        getOptionLabel={(option) => option.user_status_name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Staff Status"
                                                placeholder="Select Staff Status"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={gridStyle}>
                                <Grid item  xs={12} sm={6} md={4}>
                                <input type='hidden' name='staff_type' value={(typeof props.FormValues.staff_type_ids !== 'undefined' && props.FormValues.staff_type_ids) ? props.FormValues.staff_type_ids : []}/>
                                <Autocomplete
                                    value={(!_R.isEmpty(props.FormValues.staffTypes) && props.FormValues.staffTypes) ? props.FormValues.staffTypes : (typeof props.FormValues.staffTypeList !== 'undefined' ? [props.FormValues.staffTypeList[0]] :[])}
                                    onChange={props.HandleStaffTypeChange}
                                    multiple
                                    id="tags-outlined"
                                    options={(typeof props.FormValues.staffTypeList !== 'undefined') ? props.FormValues.staffTypeList : []}
                                    getOptionLabel={(option) => option.type}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Staff Types"
                                        placeholder="Select Staff Types"
                                    />
                                    )}
                                />
                            </Grid>
                                <Grid item  md={4} className='justify-between'>
                                    <FormControl error={props.Errors.staff && true} variant="standard">
                                        <InputLabel htmlFor="emp_category">Staff Name</InputLabel>
                                        <Select
                                            value = {typeof props.FormValues.staff !== 'undefined' ? props.FormValues.staff : []}
                                            multiple
                                            onChange={props.HandleOnChange}
                                            inputProps={{
                                                name: 'staff',
                                                id:   'staff',
                                            }}>
                                            <MenuItem value={'0'}>{'All'}</MenuItem>
                                            {typeof props.FormInputs.all_staff != 'undefined' && props.FormInputs.all_staff.map((as, i) =>
                                            <MenuItem value={as.user_id}>{formatName(as.first_name,null,as.last_name, props.NameFormat)}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText >{props.Errors.staff}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item >
                                    <div className={'d-flex align-center justify-end'}>
                                        <Button size="small" color="primary"  variant="contained" onClick={props.HandleSubmit}>Submit</Button>
                                        <div className={'ml-1'}>
                                            <Button size="small" color="primary" onClick={props.ExportReports}  value='1' variant="contained">
                                                Export
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Paper>
                {(props.IsSearch) &&
                <Paper className={'mb-1'}>
                    <CustomMaterialTable
                        title= ""
                        columns={columns}
                        data={lateSubmissionData}
                        options={{
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            search: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 }, 
                            maxBodyHeight: '550px', 
                        }}
                    />
              </Paper>
              }
            </React.Fragment>
        )
}
