import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { PoliciesComponent }  from './Policies.Component.js';
import { history } from '../../../helpers/history';
import { ErrorPage } from '../../common/error';

let formFields = {
    policy : null,
    frequency : null,
    status : 1,
    id : 0,
}
export class PoliciesContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            policies : [],
            editPermission : false,
            permission : true,
            open : false,
            popupType : '',
            formFields : Object.assign({},formFields),
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            deleteId : 0,
            snackbarVariant: '',
            error_msg : true,
        }
        this.addPolicyClick = this.addPolicyClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deletePolicy = this.deletePolicy.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
    }

    componentDidMount = () => {
        this.getPolicies();
    };

    getPolicies = () => {
        let apiEndpoint = 'api/settings/workpolicy/listpolicies';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    this.setState({
                        policies : response.data.policies,
                        editPermission : response.data.edit_permission,
                        permission : response.data.permission,
                        error_msg : response.data.permision_err_msg,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addPolicyClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : Object.assign({},formFields),
            errors : {},
        })
    }

    handleDeleteClick = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };

    handleViewClick = (id) => {
        if(id) {
            history.push('workpolicy/policy/view/' + id);
        }
    }

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/workpolicy/savePolicy', { data })
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.valid) {
                                this.getPolicies();
                                let successMsg = ''
                                if (data.id) {
                                    successMsg = 'Policy updated successfully';
                                } else {
                                    successMsg = 'Policy added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                if (response.data.errors) {
                                    this.setState({
                                        errors: response.data.errors,
                                    });
                                }
                                else {
                                    let errorMsg =  response.data.err_msg;
                                    this.setState({
                                        snackbarOpen: true,
                                        snackbarMsg: errorMsg,
                                        snackbarVariant: 'error'
                                    });
                                }
                            }
                        }
                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.policy || !this.state.formFields.policy.trim()) {
            errors["policy"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.frequency) {
            errors["frequency"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/workpolicy/get-policy-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.policy) {
                       let formFields = {
                           id : response.data.policy.id,
                           policy : response.data.policy.work_policy_name,
                           frequency : response.data.policy.frequency,
                           status : (response.data.policy.status == '1') ? 1 : 0,
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    deletePolicy = () => {
        let apiEndpoint = 'api/settings/workpolicy/deletePolicy';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    if (response.data.valid) {
                        this.getPolicies();
                        this.setState({
                            snackbarOpen : true,
                            snackbarMsg : "Policy deleted successfully",
                            open : false,
                            popupType : '',
                            snackbarVariant: 'success'
                        });
                    } else {
                        alert(response.data.err_msg);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            (this.state.permission)?
            <PoliciesComponent
                policies = {this.state.policies}
                EditPermission = {this.state.editPermission}
                Permission = {this.state.permission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddPolicyClick = {this.addPolicyClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                DeletePolicy = {this.deletePolicy}
                HandleDeleteClick = {this.handleDeleteClick}
                HandleViewClick = {this.handleViewClick}
                SnackbarVariant = {this.state.snackbarVariant}
                FrequencyArray = {this.props.FrequencyArray}
            />
            :
            <ErrorPage Message = {this.state.error_msg} />
        )
    }
}
