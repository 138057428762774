import React from 'react';
import * as _R from 'ramda';
import {httpServices}  from '../../../services/httpServices';

import { AdditionalptoAddComponent } from './AdditionalptoAdd.Component';

const formFields = {
    'id':0,
    'hours' : null,
    'description' :null,
    'year' : null,
}

const getYear = () => {
    return new Date().getFullYear();
}
const currentYear = getYear();
const years = [];
for(var i = currentYear; i < currentYear+5; i++){
    years.push(i);
}

export class AddAdditionalpto  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields : formFields,
            ptodetails : {}, 
            years : years,
            stateData : {},
            flag : false,
            open : false,
            errors :{},
            snackbar : false,
            valid: false,
            EditId: 0,
            isEdit :true, 
            success_message:null,
            error_message :null,
            buttonLoader : false,
        }
    };

    componentDidMount(){ 
        this.onList(this.props.EditId);
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        if (name === 'year') { 
            this.getPtoDetails(evt.target.value);
        }
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };

     handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.year) {
            errors["year"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.hours) {
            errors["hours"] = "Cannot be empty";
            formIsValid = false;
        }
         else{
             let val = this.state.formFields.hours;
             let minimumInc = 0.25;
             let re = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
             let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
             let re2 = new RegExp("^(\\.)[0-9]{1,2}");
             if(re2.test(val)) {
                 val = '0'+val;
             }
             if(re1.test(val)) {
                 let hm = val.split(':');
                 let fr = hm[1]/60;
                 val = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
             }
             if(!(re.test(val) || re2.test(val))) {
                 formIsValid = false;
                 errors["hours"] = 'Hours is invalid';
             }
             else {
                 let min = minimumInc*100;
                 let frp = val%1;
                 frp = frp.toFixed(2)*100;
                 if(!(frp%min===0)) {
                     formIsValid = false;
                     errors["hours"] = 'Hours should be entered as a multiple of '+minimumInc+' hours';
                 }
                 if(!(val>0)) {
                     errors["hours"] = 'Hours cannot be empty';
                     formIsValid = false;
                 }
             }
         }
         let description = this.state.formFields.description;
         if (!description || _R.length(description.trim()) === 0) {
             errors['description'] = "Cannot be empty";
             formIsValid = false;
         }
        this.setState({errors: errors});
        return formIsValid;
    }

    onAddRequest = (id) => {
        if(id) {
            this.onEditRequest(id);
        }
        else {
            this.onSaveRequest();
        }
    }

    onSaveRequest = () => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            let value = {'type': 4,'hours' : this.getStandardHour(data.hours), 'year':data.year, 'description':data.description}
            this.setState({ buttonLoader: true });
            return httpServices.post('api/additionalpto/create',  value )
                .then(response => {
                    if (response.data.permission) {
                        if (response.data.valid) {
                            this.setState({ flag : true,
                                    open: false,
                                    snackbar:true,
                                    valid: true,
                                    success_message: response.data.message
                            });
                            this.props.UpdateParent(true);
                        } else {
                            this.setState({
                                errors : response.data.errors
                            });
                        }
                        this.setState({ buttonLoader: false });
                    }else{
                        this.setState({ flag:false,
                            open:true,
                            snackbar:true,
                            valid: false,
                            error_message : 'You are not authorized to do this action',
                            buttonLoader: false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
            }
        }

    onEditRequest = (id) => {
        let data = this.state.formFields;
        if (this.handleValidation()) {
            let value={ 'id' : id,'type': 4,'hours' : this.getStandardHour(data.hours), 'year':data.year, 'description':data.description}
            return httpServices.post('api/additionalpto/edit',value)
            .then(response => { 
                if (response.data.permission) {
                    if (response.data.edit_success) {
                        this.setState({ flag :true,
                            open: false,
                            snackbar: true,
                            valid: true,
                            success_message : response.data.message,
                        });
                        this.props.UpdateParent(true);
                    } else {
                        this.setState({
                            errors : response.data.errors
                        });
                    }
                }
                else {
                    this.setState({ flag:false,
                                    open:true,
                                    snackbar:true,
                                    valid: false,
                                    error_message : 'You are not authorized to do this action',
                                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }

    }
    onList = (id) => {
        if(id){
            return httpServices.post('api/additionalpto/list', {'id': id})
                .then(response => {
                    if (response.data.requests) {
                            formFields.year = response.data.requests[0].year;
                            formFields.hours = response.data.requests[0].hours;
                            formFields.description = response.data.requests[0].description;
                            formFields.id = response.data.requests[0].id;
                        this.setState({
                            formFields: formFields,
                            EditId: id,
                            open: true,
                            flag :true,
                            ptodetails:response.data.pto_details,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }else {
            this.getPtoDetails(currentYear);
        }
    }
    getPtoDetails = (year) => {
        return httpServices.post('api/additionalpto/ptoinfo', {'year': year})
            .then(response => {
                if (response.data) {
                    let formFields = [];
                    formFields.year = year;
                    this.setState({
                        ptodetails:response.data.pto_details,
                        isEdit: false,
                        formFields:formFields,
                        EditId: 0,
                        open: true,
                        flag :true,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
        });
    }

    onClose = () => {
       this.setState({flag : true, 
                    open:false});
        this.props.UpdateParent(false); 
    }
    snackbarClose = () => {
        this.setState({
            snackbar : false,
            valid: false,
        });
    }
    
    getStandardHour = (hours) => {
        let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
        if(re1.test(hours)) {
            let hm = hours.split(':');
            let fr = hm[1]/60;
            hours = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
        }
        return hours;
    }
    
    render(){
        return(
            <AdditionalptoAddComponent
                Open = {this.state.open}
                Close = {this.onClose.bind(this)}
                AddRequest={this.onAddRequest.bind(this)}
                HandleOnChange={this.handleOnChange.bind(this)}
                Errors={this.state.errors}
                FormFields={this.state.formFields}
                Snackbar = {this.state.snackbar}
                Valid = {this.state.valid}
                ErrorMessage = {this.state.error_message}
                SuccessMessage = {this.state.success_message}
                SnackbarClose = {this.snackbarClose.bind(this)}
                PtoDetails = {this.state.ptodetails} 
                years = {this.state.years}
                Title = {this.props.Title}
                ButtonLoader={this.state.buttonLoader}
            />
        )
    }
};
