import React from 'react';
import { connect } from "react-redux";
import {LocationComponent} from './Location.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { history } from './../../../helpers/history';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';
import { getRelativeUrl } from '../../common/common';
import { setSelectedTabIndex } from '../../../redux/store/reducers/tabs';
import { setSavedFilters, updateSavedFilterAction } from '../../../redux/store/reducers/savedFilters';
import * as _ts from '../../common/treeSelect';

const filterFields = {
    'my': {
        'start_date' : null,
        'end_date' : null,
        'requestStatus' : 0,
        'project' : 0,
        'emp_status' : 0,
        'user_id' : 0,
        'location' : 0
    } ,
    'review': {
        'start_date' : null,
        'end_date' : null,
        'requestStatus' : 1,
        'project' : 0,
        'emp_status' : 0,
        'user_id' : 0,
        'location' : 0
    } ,
    'all': {
        'start_date' : null,
        'end_date' : null,
        'requestStatus' : 0,
        'project' : 0,
        'emp_status' : 0,
        'user_id' : 0,
        'location' : 0
    },
}
class LocationContainer extends React.Component{
    constructor(props){
      super(props);
        const offsiteTabSelected = this.props.selectedOffsiteTab;
        let filter = filterFields[offsiteTabSelected];

        this.state = {
            offsiteTabSelected: offsiteTabSelected,
            hasPermission : true,
            requests : [],
            all_requests : [],
            review_requests : [],
            open : false,
            editId : 0,
            viewId :null,
            viewClick : false,
            start_date : '',
            end_date : '',
            filterViewState : false,
            formFields : filter,
            requestStatus :[],
            projects : [],
            employeeStatus : [],
            Employee : [],
            ReviewCount : '',
            snackbarOpen : false,
            snackbarMsg : '',
            allRequestView : '',
            errors : {},
            logged_user:null,
            requestView : false,
            externalUser : false,
            locationEditPermission : false,
            showLoader: true,
            dataLoaded: false,
            periodStartDate: new Date (),
            periodEndDate: new Date (),
            saveFilter : false,
            counter: 0,
        }
        this.onTabChange = this.onTabChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmitRequest = this.onSubmitRequest.bind(this);
        this.createLocationClick = this.createLocationClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.filterView = this.filterView.bind(this);
        this.filterSubmit = this.filterSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.saveFilterValues = this.saveFilterValues.bind(this);
        this.saveFilterToStore = this.saveFilterToStore.bind(this);
    }
    componentDidMount = () => {
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;        
        if (fromDashboard) {
            var dashboardFilter = (this.props.location.state && this.props.location.state.dashboardFilter) ? this.props.location.state.dashboardFilter : [];
            let startDate = (dashboardFilter.offsiteRequestsStartDate && dashboardFilter.offsiteRequestsStartDate != '') ? dashboardFilter.offsiteRequestsStartDate : null;
            let endDate = (dashboardFilter.offsiteRequestsEndDate && dashboardFilter.offsiteRequestsEndDate != '') ? dashboardFilter.offsiteRequestsEndDate : null;
            let formFields = this.state.formFields;
            formFields['start_date'] = startDate;
            formFields['end_date'] = endDate;
            formFields['requestStatus'] = 1;
            formFields['project'] = 0;
            formFields['emp_status'] = 0;
            formFields['user_id'] = 0;
            formFields['location'] = 0;
            this.setState({
                formFields: formFields
            });
        }
        let tab = this.state.offsiteTabSelected;
        this.props.setSavedFilterVal();    
        this.getOffsiteDetails(tab);
    };
    componentDidUpdate = (prevProps) => {
        // Fetching offsite details based on the redux store values after store values are set initially
        if (prevProps.savedFilterValues.length > 0 && this.state.counter === 0) {
            this.getOffsiteDetails(this.state.offsiteTabSelected);
            this.setState({
                counter: 1
            });
        }
    }
    onTabChange = (tab='') => {
        let tabchange = "change";
        this.getOffsiteDetails(tab, tabchange);
        this.setState({
            tabVal : tab,
            errors : {},
            saveFilter : false,      
        });        
        let mixPanelAction = '';
        if (tab === 'my') {
            mixPanelAction = 'my-requests-tab-clicked';
        } else if (tab === 'review') {
            mixPanelAction = 'review-requests-tab-clicked';
        } else if (tab === 'all') {
            mixPanelAction = 'all-requests-tab-clicked';
        }
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'location', 'action' : mixPanelAction});
    };
   
    handleChange = (event, value) => {
        let tab = '';
        switch (value) {
            case 0:
                tab = 'my';
                break;
            case 1:
                tab = 'review';
                break;
            case 2:
                tab = 'all';
                break;
            default:
                tab = 'my';
        }
        let obj = { page: "location", selected: tab }
        this.props.setTabIndexValue(obj);
        this.setState({ offsiteTabSelected: tab });
    }
     updateParent = (successFlag, attachmentMsg = '') => {
        this.getOffsiteDetails('my');
        this.setState({
            open : false,
        });
        if (successFlag) { // Update list data
            let editId = this.state.editId;
            let msg = '';
            if (editId) {
                msg = "Request updated successfully";
                editId = 0;
            } else {
                msg = "Request submitted successfully";
            }
            if (attachmentMsg != '') {
                msg = msg+". "+ attachmentMsg;
            }
            this.setState({
                snackbarOpen : true,
                snackbarMsg : msg,
            });
        }

    }
     snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    onSubmitRequest = () => {
         let apiEndpoint = 'api/location/create';
        return httpServices.post(apiEndpoint)
            .then(response => {
                 if (response.data && response.data.permission) {
                    let requests = response.data.offsite_requests;
                    if (requests && requests.length) {
                        this.setState({
                            requests : requests,
                        });                        
                    }
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });

                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getOffsiteDetails = (tab = '', tabchange = '') =>{
        let apiEndpoint = 'api/location/list';
        let filter = filterFields[tab];
        let savedFilterValues = this.props.savedFilterValues;
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl + '#' + tab;
            let indexValue = savedFilterValues.findIndex((element) => element.relative_url === relativeUrl);
            if (indexValue !== -1) {
                let savedFilter = savedFilterValues[indexValue]['filter_options'];
                filter = Object.assign(filter, savedFilter);
            }
        }
        let data = {
            'tab': tab, 
            'start_date': filter.start_date, 
            'end_date' : filter.end_date, 
            'emp_status' : filter.emp_status, 
            'status' : filter.requestStatus, 
            'project' : filter.project, 
            'user_id' : filter.user_id,
            'location' : filter.location
        } 
        this.setState({showLoader : true});
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data && response.data.permission && response.data.timesheet_location) {
                    let requests = response.data.location_requests;
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                        requestStatus : response.data.requestStatus,
                        location : response.data.location,
                        employeeStatus : response.data.emp_status,
                        ReviewCount : response.data.reviewCount,
                        allRequestView : response.data.allrequest_view,
                        logged_user : response.data.logged_user,
                        requestView : response.data.request_view,
                        offsiteTabSelected : response.data.tab,
                        locationEditPermission : response.data.edit_permission,
                        externalUser : response.data.external_user,
                    });
                    let filterFields = filter;
                    filterFields.locations = response.data.locations;
                    filterFields.location = response.data.location;
                    if (response.data.payperiod_start_date && !filterFields.start_date) {
                        filterFields.start_date = response.data.payperiod_start_date;
                        filterFields.end_date = response.data.payperiod_end_date;
                    }
                    //if (requests && requests.length) {
                    let startDateArr = filterFields.start_date.split('-');
                    let startDateObj = new Date(startDateArr[0], startDateArr[1]-1, startDateArr[2]);
                    let endDateArr = filterFields.end_date.split('-');
                    let endDateObj = new Date(endDateArr[0], endDateArr[1]-1, endDateArr[2]);
                    if (response.data.tab === "all") {
                        this.setState({
                            all_requests : requests,
                            projects : _ts.prepareTreeData(response.data.all_project, 'projects'),
                            formFields: filterFields,
                            periodStartDate :startDateObj,
                            periodEndDate :endDateObj,
                        },()=>this.getUsers());

                    }
                    else if (response.data.tab === "review") {
                        this.setState({
                            review_requests : requests,
                            projects : _ts.prepareTreeData(response.data.review_project, 'projects'),
                            formFields: filterFields,
                            periodStartDate :startDateObj,
                            periodEndDate :endDateObj,
                        },()=>this.getUsers());

                    }
                    else 
                    {
                        this.setState({
                            projects : _ts.prepareTreeData(response.data.my_project, 'projects'),
                            requests : requests,
                            formFields: filterFields,
                            periodStartDate :startDateObj,
                            periodEndDate :endDateObj,
                        });
                    }
                    // }
                }
                else {
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                        hasPermission : false,
                    });

                }

            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    createLocationClick = () => {
        this.setState({
            open : true,
            editId :0,
        });
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'location', 'action' : 'create-offsite-request-clicked'});
    }
    handleEditClick = (id) => {
        if(id) {
            this.setState({
                open :true,
                editId :id,
            });
        }
    }
    handleViewClick = (id) => {
        if(id) {
            history.push('location/view/' + id);
        }
    }
    handleStartDateChange = (date) => {
        const f = this.state.formFields;
        const field = 'start_date';
        f[field] = date;
        this.setState({
            formFields: f
        },()=>{
            let tab = this.state.offsiteTabSelected;
            if(tab === "all" || tab === "review") {
                this.getUsers();
            }
        })
    };
    handleEndDateChange = (date) => {
        const f = this.state.formFields;
        const field = 'end_date';
        f[field] = date;
        this.setState({
            formFields: f
        },()=>{
            let tab = this.state.offsiteTabSelected;
            if(tab === "all" || tab === "review") {
                this.getUsers();
            }
        });
    };
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'saveFilter') {
            this.setState({
                saveFilter: value
            });
        }
        else {
            f[name] = evt.target.value;
            this.setState({
                formFields: f
            }, () => {
                if (name === "emp_status") {
                    this.getUsers();
                }
            });
        }
    }
     handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.formFields.start_date > this.state.formFields.end_date) {
            errors["endDate"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    saveFilterValues = () => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl+'#'+this.state.offsiteTabSelected;
            let apiEndpoint = 'api/user/save-filter';
            let data = {
                relative_url : relativeUrl,
                filter_options : this.state.formFields,
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    saveFilterToStore = () => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl + '#' + this.state.offsiteTabSelected;
            let obj = {relative_url : relativeUrl, filter_options : this.state.formFields}
            this.props.updateSavedFilterVal({obj});
        }
    }
    filterSubmit = () => {
        if (this.handleValidation()) {
            this.saveFilterToStore();
            if (this.state.saveFilter) {
                this.saveFilterValues();
            }
            this.setState({
                filterViewState: false,
            }, function () {
                this.getOffsiteDetails(this.state.offsiteTabSelected);
            });
        }
    }
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let projects = 0;
        let formFields = this.state.formFields;
        if (cNode.value === 'selectAll') {
            if( cNode.checked) {
                projects = 0;
            }
        }
        else if (sNodes.length < 1) {
            projects = 0;
        }
        else if (cNode.project_id) {
            projects = cNode.project_id;
        }
        formFields['project_id'] = projects;
        formFields['project'] = projects;
        this.setState({ formFields: formFields });
    }

    resetFilter = (tab) => {
        let ff = this.state.formFields; 
        ff['start_date'] = null;
        ff['end_date'] = null;
        ff['requestStatus'] = tab === 'review' ? 1:0;
        ff['project'] = '0';
        ff['emp_status'] = '0';
        ff['user_id'] = '0';
        ff['project_id'] = 0;
        ff['location'] = 0;
        this.setState({
            formFields: ff,
            right: false,
            saveFilter : false,
        },()=>{
            this.filterSubmit(tab);}
        );
    }

    getUsers = () => {
        let tab = this.state.offsiteTabSelected;
        let filter = this.state.formFields;
        let apiEndpoint = 'api/location/list-users';
        return httpServices.post(apiEndpoint,{emp_status : filter.emp_status, tab : tab , start_date: filter.start_date, end_date: filter.end_date})
        .then(response => {
            if (response.data) {
                this.setState({
                    Employee : response.data,
                });
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    render(){
        return(
              <>
                <CustomProgressBar loaderOpen={this.state.showLoader}/>
                    {(this.state.dataLoaded) ?
                        <>
                        {(this.state.hasPermission) ?
                        <LocationComponent
                            HandleChange={this.handleChange}
                            Requests = {this.state.requests}
                            FormFields =  {this.state.formFields}
                            AllRequests = {this.state.all_requests}
                            ReviewRequests = {this.state.review_requests}
                            onTabChange = {this.onTabChange }
                            getOffsiteDetails = {this.getOffsiteDetails}
                            Open = {this.state.open}
                            CreateLocationClick = {this.createLocationClick}
                            HandleEditClick = {this.handleEditClick}
                            HandleViewClick = {this.handleViewClick}
                            HandleOnChange = {this.handleOnChange}
                            EditId = {this.state.editId}
                            FilterView = {this.filterView}
                            FilterViewState = {this.state.filterViewState}
                            FilterSubmit = {this.filterSubmit}
                            RequestStatus = {this.state.requestStatus}
                            EmployeeStatus = {this.state.employeeStatus}
                            Employee = {this.state.Employee}
                            Projects = {this.state.projects}
                            start_date = {this.state.start_date}
                            end_date = {this.state.end_date}
                            UpdateParent = {this.updateParent}
                            ReviewCount = {this.state.ReviewCount}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            Tab = {this.state.offsiteTabSelected}
                            AllRequestView = {this.state.allRequestView}
                            Errors = {this.state.errors}
                            Logged_user = {this.state.logged_user}
                            RequestView = {this.state.requestView}
                            LocationEditPermission = {this.state.locationEditPermission}
                            ExternalUser = {this.state.externalUser}
                            handleStartDateChange={this.handleStartDateChange}
                            handleEndDateChange={this.handleEndDateChange}
                            PeriodStartDate = {this.state.periodStartDate}
                            PeriodEndDate = {this.state.periodEndDate}
                            ResetFilter = {this.resetFilter.bind(this)}
                            SaveFilter = {this.state.saveFilter}
                            HandleTreeChange = {this.handleTreeChange}
                        />
                        :<ErrorPage />}
                        </>
                    :<></>}
              </>
        )
    }
}
const mapStateToProps = (state) => {
    const savedFilterValues =  state.savedFilters.savedFilterValues ? state.savedFilters.savedFilterValues : {};
    const selectedOffsiteTab = state.Tabs.allTabs['location'] ? state.Tabs.allTabs['location'] : 'my';
    return {
        savedFilterValues: savedFilterValues,
        selectedOffsiteTab: selectedOffsiteTab
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setSavedFilterVal: () => dispatch(setSavedFilters()),
        setTabIndexValue: (obj) => dispatch(setSelectedTabIndex(obj)),
        updateSavedFilterVal: ({obj}) => dispatch(updateSavedFilterAction({obj}))
    }
}
const connectedLocationContainer = connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
export { connectedLocationContainer as LocationContainer };
