import React from 'react';

import { httpServices }  from '../../../services/httpServices';
import { MileageComponent }  from './Mileage.Component.js';

let formFields = {
    startDate : null,
    ratePerMile : null,
    id : 0,
}
export class MileageContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            mileages : [],
            editPermission : false,
            currencySymbol :null,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            validMessage : null,
        }
        this.addMileageClick = this.addMileageClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => {
        this.getAllExpenseMileage();
    };

    getAllExpenseMileage = () => {
        let apiEndpoint = 'api/settings/expenses/listMileage';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        mileages : response.data.mileages,
                        editPermission : response.data.edit_permission,
                        currencySymbol : response.data.currency_symbol,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addMileageClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
            validMessage : null,
        })
    }


    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/settings/expenses/validateMileage', { data })
                    .then(response => {
                        if (response.data.error) {
                            this.setState({ validMessage: response.data.message });
                        }
                        else {
                            httpServices.post('api/settings/expenses/addMileage', { data })
                                .then(response => {
                                    if (response.data.valid) {
                                        if (response.data.permission) {
                                            this.getAllExpenseMileage();
                                            let successMsg = ''
                                            if (data.id) {
                                                successMsg = 'Mileage updated successfully';
                                            } else {
                                                successMsg = 'Mileage added successfully';
                                            }
                                            this.setState({
                                                snackbarOpen: true,
                                                snackbarMsg: successMsg,
                                                open: false,
                                                popupType: '',
                                                formFields: {},
                                                errors: {},
                                            });
                                        } else {
                                            alert("Something went wrong");
                                        }
                                    }
                                    else {
                                        this.setState({
                                            errors: response.data.errors,
                                        });
                                    }
                                })

                                .catch(function (error) {
                                    console.log("error" + error);
                                });
                        }
                    })
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.startDate) {
            errors["startDate"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.ratePerMile || (this.state.formFields.ratePerMile.trim() === '')) {
            errors["ratePerMile"] = "Cannot be empty";
            formIsValid = false;
        }
        else if (isNaN(this.state.formFields.ratePerMile)) {
            errors['ratePerMile'] = "Invalid Rate";
            formIsValid = false;
        }
        this.setState({errors : errors,
            validMessage:null,
        });
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    render(){
        return(
            <MileageComponent
                Mileages = {this.state.mileages}
                EditPermission = {this.state.editPermission}
                CurrencySymbol = {this.state.currencySymbol}
                ValidMessage = {this.state.validMessage}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddMileageClick = {this.addMileageClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                OnClose = {this.onClose}
            />
        )
    }
}
