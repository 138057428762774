import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TablePagination from '@mui/material/TablePagination';
import { VoucherFilterComponent } from './VoucherFilter.Component';
import TextField from '@mui/material/TextField';

const API_URL = process.env.REACT_APP_API_URL;
export const ExpenseVoucherComponent = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <React.Fragment>
            {!props.permisionError && <div className={'section-main'}>
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                            Expense Vouchers
                        </Typography>
                    </div>


                    <div className={'action'}>
                        <form action={API_URL + 'api/expenses/exportVouchers'} method="post">
                            <TextField
                                name={'year'}
                                type="hidden"
                                value={(typeof props.FilterFields.year !== 'undefined') ? props.FilterFields.year : ''}
                            />
                            <TextField
                                name={'month'}
                                type="hidden"
                                value={(typeof props.FilterFields.month !== 'undefined') ? props.FilterFields.month : ''}
                            />
                            <TextField
                                name={'status'}
                                type="hidden"
                                value={(typeof props.FilterFields.report_status !== 'undefined') ? props.FilterFields.report_status : ''}
                            />
                            <TextField
                                name={'user_id'}
                                type="hidden"
                                value={(typeof props.FilterFields.employee !== 'undefined') ? props.FilterFields.employee : ''}
                            />
                            <TextField
                                name={'name_format'}
                                type="hidden"
                                value={(typeof props.FilterFields.name_format !== 'undefined') ? props.FilterFields.name_format : ''}
                            />
                            <Button variant="contained" size="small" color="primary" type="submit">Export</Button>


                            <Tooltip title="Filter">
                                <IconButton
                                    onClick={props.FilterView('right', true)}
                                >
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                        </form>
                        <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)} >
                            <div className={'tree-view-drawer'}>
                                <div className={'drawer-title'}>Filter</div>
                                <VoucherFilterComponent
                                    ExpenseStatus={props.ExpenseStatus}
                                    FilterFields={props.FilterFields}
                                    Years={props.Years}
                                    Months={props.Months}
                                    StartYear={props.StartYear}
                                    EndYear={props.EndYear}
                                    HandleOnSubmit={props.GetVoucherList}
                                    HandleOnChange={props.HandleOnChange}
                                    Employees={props.Employees} />
                            </div>
                        </Drawer>

                    </div>
                </div>
                <div className={'section-body'}>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table aria-label="Timesheet List" className={'list-table_basic steps-table'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Employee Name</TableCell>
                                        <TableCell>Voucher ID</TableCell>
                                        <TableCell>Expense Period</TableCell>
                                        <TableCell>Submitted Amount</TableCell>
                                        <TableCell>Actual Payment</TableCell>
                                        {(parseInt(props.FilterFields.report_status) === 3) && <TableCell>Payment Scheduled On</TableCell>}
                                        {(parseInt(props.FilterFields.report_status) === 6) && <TableCell>Payment Date</TableCell>}
                                        {(props.FilterFields.report_status === '' || parseInt(props.FilterFields.report_status) === 0) && <TableCell>Payment Scheduled On</TableCell>}
                                        {(props.FilterFields.report_status === '' || parseInt(props.FilterFields.report_status) === 0) && <TableCell>Payment Date</TableCell>}
                                        <TableCell>Submitted On</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={'tbody'}>
                                    {
                                        (props.VoucherList.length > 0) && props.VoucherList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((voucher) =>

                                            <TableRow>
                                                <TableCell>{(props.FilterFields.name_format && props.FilterFields.name_format === 'LASTFIRST') ? (voucher.user_last_name + ', ' + voucher.user_first_name) : (voucher.user_first_name + ' ' + voucher.user_last_name)}</TableCell>
                                                <TableCell className={(voucher.status_name === 'Approved' || voucher.status_name === 'Paid') && (voucher.total_amount != voucher.actual_payment) && 'submit'}>{voucher.id}</TableCell>
                                                <TableCell>{voucher.start_date + ' - ' + voucher.end_date}</TableCell>
                                                <TableCell>{(voucher.total_amount) && props.CurrencySymbol + parseFloat(voucher.total_amount).toFixed(2)}</TableCell>
                                                <TableCell>{(voucher.actual_payment) && props.CurrencySymbol + parseFloat(voucher.actual_payment).toFixed(2)}</TableCell>
                                                {(parseInt(props.FilterFields.report_status) === 3) && <TableCell>{(voucher.payment_scheduled_on) && voucher.payment_scheduled_on}</TableCell>}
                                                {(parseInt(props.FilterFields.report_status) === 6) && <TableCell>{(voucher.payment_date) && voucher.payment_date}</TableCell>}

                                                {(props.FilterFields.report_status === '' || parseInt(props.FilterFields.report_status) === 0) && <TableCell>{(voucher.payment_scheduled_on) && voucher.payment_scheduled_on}</TableCell>}
                                                {(props.FilterFields.report_status === '' || parseInt(props.FilterFields.report_status) === 0) && <TableCell>{(voucher.payment_date) && voucher.payment_date}</TableCell>}

                                                <TableCell>{(voucher.submitted_on !== 0) && voucher.submitted_on}</TableCell>
                                                <TableCell className={voucher.colour_code}>{voucher.status_name}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="View" aria-label="view">
                                                        <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                        <a href={'vouchers/' + voucher.id}><VisibilityIcon fontSize="small" /></a>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {(props.VoucherList.length > 0) && <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                        <TableCell><b>Total Amount</b></TableCell>
                                        <TableCell><b>{props.CurrencySymbol+((props.SubmitAmount && props.SubmitAmount > 0) ? parseFloat(props.SubmitAmount).toFixed(2) : '0.00')}</b></TableCell>
                                        <TableCell><b>{props.CurrencySymbol+((props.ActualAmount && props.ActualAmount > 0) ? parseFloat(props.ActualAmount).toFixed(2) : '0.00')}</b></TableCell>
                                    </TableRow>}
                                    {(props.VoucherList.length < 1) && <TableRow>
                                        <TableCell colSpan={5}>No records found</TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>}

            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={props.VoucherList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </React.Fragment>
    )
};
