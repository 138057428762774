import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { Multiselect } from 'multiselect-react-dropdown';
import Checkbox from '@mui/material/Checkbox';

import { DialogBoxMedium } from '../../common/dialog.medium';
import { ErrorPage } from '../../common/error';
import { CustomizedSnackbars } from '../../common/snackbars';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const mstyle = { chips: { background: "#f4f4f4", color: 'black' } };
export const RulesComponent = (props) => {
    const rules = typeof props.rules !== 'undefined' ? props.rules : [];
    return (
        <>
            {props.Permission ?
                <>
                    <div className={'listSubHeader pr-2'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                        {props.EditPermission &&
                            <Tooltip title="Add Rule" aria-label="add">
                                <Button variant="contained" color="primary" size="small" onClick={props.AddRules}>Add</Button>
                            </Tooltip>
                        }
                    </div>

                    <TableContainer component={Paper}>
                        <Table size='small' >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Rule</TableCell>
                                    <TableCell>Status</TableCell>
                                    {props.EditPermission &&
                                        <TableCell>Actions</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody className={'tbody'}>
                                {(rules.length === 0) &&
                                    <TableRow >
                                        <TableCell>No rules found.</TableCell>
                                    </TableRow>
                                }
                                {rules.map((item, index) =>
                                    <TableRow hover>
                                        <TableCell>{item.rule_name}</TableCell>
                                        <TableCell>{item.location}</TableCell>
                                        <TableCell>{item.rule_string}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        {props.EditPermission &&
                                            <TableCell>
                                                <Tooltip title="Edit" aria-label="edit">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                                        <EditIcon fontSize="small" onClick={() => props.EditRule(item.id)} />
                                                    </IconButton>
                                                </Tooltip>
                                                {/*<Tooltip title="Delete" aria-label="Delete">
                                                    <IconButton className={'action-btn'} edge="end" aria-label="Delete">
                                                        <DeleteOutlinedIcon fontSize="small" onClick={() => props.HandleDelete(item.id)} />
                                                    </IconButton>
                                                </Tooltip>*/}
                                            </TableCell>
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {(props.PopupType === 'add' || props.PopupType === 'edit') &&
                        <DialogBoxMedium
                            Open={props.Open}
                            Cancel={props.OnClose}
                            Title={props.PopupType === 'add' ? 'Add Rule' : 'Edit Rule'}
                            OnSubmit={props.HandleSubmit}
                            Content={<DialogAdd FormProps={props} />}
                        />
                    }
                    {props.SnackbarOpen &&
                        <CustomizedSnackbars
                            open={props.SnackbarOpen}
                            variant={props.SnackbarVariant}
                            message={props.SnackbarMsg}
                            handleClose={props.SnackbarClose}
                        />
                    }
                    {props.PopupType === 'delete' &&
                        <DialogBoxMedium
                            Open={props.Open}
                            Cancel={props.OnClose}
                            OnSubmit={props.DeleteRule}
                            Title='Confirm Delete'
                            ButtonName='Ok'
                            Content={
                                <DialogWithConfirmMessage msg="Do you want to delete this rule" />
                            }
                        />
                    }
                </>

                :
                <ErrorPage />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields, HandleOnChange, Errors, OnSelectDays } = props.FormProps;console.log(FormFields);
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="name"
                            name="name"
                            label="Rule Name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={_R.type(FormFields) !== 'Undefined' ? FormFields.name : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.name !== 'undefined' && Errors.name) && true}
                            helperText={typeof Errors.name !== 'undefined' && Errors.name}
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.location && true}>Location</InputLabel>
                        <Select
                            value={parseInt(FormFields.location)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'location',
                                id: 'location'
                            }}
                            className={'input-item'}
                        >
                            {typeof props.FormProps.Locations !== 'undefined' && props.FormProps.Locations.map((location) =>
                                <MenuItem value={location.id} >{location.location}</MenuItem>
                            )}
                        </Select>
                        {Errors.location && <FormHelperText className="error">{Errors.location}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={FormFields.status}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status'
                            }}
                            className={'input-item'}
                        >
                           <MenuItem value='Active'>Active</MenuItem>
                           <MenuItem value='Inactive'>Inactive</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.reccur_frequency && true}>Frequency</InputLabel>
                        <Select
                            value={FormFields.reccur_frequency}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'reccur_frequency',
                                id: 'reccur_frequency'
                            }}
                            className={'input-item'}
                        >
                            {typeof props.FormProps.FrequencyArray !== 'undefined' && props.FormProps.FrequencyArray.map((frequency) =>
                                <MenuItem value={frequency.value} >{frequency.name}</MenuItem>
                            )}
                        </Select>
                        {Errors.reccur_frequency && <FormHelperText className="error">{Errors.reccur_frequency}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id="repeat"
                            name="repeat"
                            label="Every"
                            type={"number"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: { min: 1 },
                                endAdornment: <InputAdornment position="end">{FormFields.reccur_frequency === "MONTHLY" ? "Month(s)" : FormFields.reccur_frequency === "DAILY" ? "Day(s)" : FormFields.reccur_frequency === "WEEKLY" ? "Week(s)" : ""}</InputAdornment>
                            }}
                            value={_R.type(FormFields) !== 'Undefined' ? FormFields.repeat : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.repeat !== 'undefined' && Errors.repeat) && true}
                            helperText={typeof Errors.repeat !== 'undefined' && Errors.repeat}
                        />
                    </ FormControl>
                </Grid>
                {FormFields.reccur_frequency === "WEEKLY" &&
                    <ul class="weekDays-selector">
                        <li><input type="checkbox" name="weekday[]" id="weekday-sun" className="weekday" value={"SU"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("SU") ? true : false} />
                        <label for="weekday-sun">S</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-mon" className="weekday" value={"MO"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("MO") ? true : false}/>
                        <label for="weekday-mon">M</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-tue" className="weekday" value={"TU"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("TU") ? true : false}/>
                        <label for="weekday-tue">T</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-wed" className="weekday" value={"WE"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("WE") ? true : false}/>
                        <label for="weekday-wed">W</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-thu" className="weekday" value={"TH"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("TH") ? true : false}/>
                        <label for="weekday-thu">T</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-fri" className="weekday" value={"FR"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("FR") ? true : false}/>
                        <label for="weekday-fri">F</label></li>
                        <li><input type="checkbox" name="weekday[]" id="weekday-sat" className="weekday" value={"SA"} onClick={OnSelectDays} checked = {_R.type(FormFields.selectedDays) !== 'Undefined' && FormFields.selectedDays.includes("SA") ? true : false}/>
                        <label for="weekday-sat">S</label></li>
                    </ul>

                }
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
