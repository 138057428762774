import React from 'react';
import {httpServices}  from '../../../services/httpServices'
import {ErrorPage} from '../../common/error';
import {TimesheetLateSubmissionComponent} from './TimesheetLateSubmissionReport.Component';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import * as validations from '../../common/validate';

const formValues = {
    staff: ['0'],
    staffStatus : [],
    staff_status_ids :[0],
    staffTypes : [],
    staff_type_ids : [0]
}
export class TimesheetLateSubmissionReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            formValues : formValues,
            formInputs : {},
            hasPermission : true,
            errors : {},
            reportData : [],
            isSearch: false,
            showLoader : false,
            nameFormat : 'FIRSTLAST'
        }
        this.getFormInputs = this.getFormInputs.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.handleStaffStatusChange = this.handleStaffStatusChange.bind(this);
        this.handleStaffTypeChange = this.handleStaffTypeChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }
    componentDidMount () {
        this.getFormInputs();
        window.scrollTo(0, 0);
    }
    componentWillUnmount () {
        formValues['staff'] = ['0'];
    }
    getFormInputs = () => {
        httpServices.get('api/report/timesheetLateSubmission',{})
            .then(response => {
                if (response.data.permission) {
                    let formValues = this.state.formValues;
                    formValues.staffStatusList = response.data.staff_status;
                    formValues.staffTypeList = response.data.staff_types;
                    this.setState({
                        hasPermission : true,
                        formInputs : response.data,
                    })
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formValues;
        let val = evt.target.value;
        ff[field] = val;
        if (field === 'staff' && val && val.length) {
            let length = val.length;
            let staffArr = val;
            if (val[0] === '0' && val.length > 1) {
                staffArr = val.filter(stf => stf !== '0');
            } else if (val[length - 1] === '0') {
                staffArr = val.filter(stf => stf === '0');
            }
            ff[field] = staffArr;
        }
        this.setState({
            formValues : ff
        }, function(){
            if (field !== 'staff') {
                this.statusChange()
            }
        });                   
    };
    handleSubmit = () => {
        let data = this.state.formValues;
        let valid = true;
        // Validate start and end date
        let errors = {};
        if (!data.start_date || data.start_date === '' ) {
            errors['start_date'] = 'Start date cannot be empty';
            valid = false;
        }
        if (!data.end_date || data.end_date === '' ) {
            errors['end_date'] = 'End date cannot be empty';
            valid = false;
        }
        if (valid) {
            if (data.staff && data.staff.length) {
                data.json_staff = JSON.stringify(data.staff);
            }
            this.setState({ showLoader : true});
            httpServices.post('api/report/timesheetLateSubmission',data)
                .then(response => {
                    if (response.data.permission && !response.data.errors) {
                        this.setState({
                            reportData: response.data.report_data,
                            isSearch: true,
                            errors: errors,
                            showLoader: false
                        })
                    } else if (response.data.errors) {
                        this.setState({
                            errors: response.data.errors,
                            showLoader: false
                        });
                    }
                    else {
                        this.setState({
                            hasPermission : false,
                            showLoader:false
                        });
                    }
                })
        } else {
            this.setState({
                errors : errors,
            });
        }
    }
    statusChange = () => {
        let data = this.state.formValues; 
        data.staff_status = this.state.formValues.staff_status_ids;
        data.staff_types = this.state.formValues.staff_type_ids;
        httpServices.post('api/report/getUsersByStatus',data)
            .then(response => {
                if (response.data) {
                    let formInputs = this.state.formInputs;
                    formInputs.all_staff = response.data.users;
                    this.setState({
                        formInputs : formInputs,
                    })
                } 
            })
    }

    handleStaffStatusChange = (evt, value) => {
        let ff = this.state.formValues;
        let staff_status_ids = [];
        let new_value = [];
        if (value) {
            let isAllinList = value.findIndex(i => i.user_status_name === "All");
            if (isAllinList !== -1 && isAllinList === value.length-1) {
                new_value = [ff.staffStatusList[0]];
                staff_status_ids.push(0);
            } else {
                value.forEach(function(item) {
                    if (item.user_status_name !== "All") {
                        new_value.push(item);
                        staff_status_ids.push(parseInt(item.user_status_id));
                    }
                })
            }
            if (value.length === 0) {
                new_value = [ff.staffStatusList[0]];
                staff_status_ids.push(0);
            }
            ff['staffStatus'] = new_value;
            ff['staff_status_ids'] = staff_status_ids;
        }
        this.setState({
            formFields: ff
        }, function(){
            this.statusChange()
        });
    }

    handleStaffTypeChange = (evt, value) => {
        let ff = this.state.formValues;
        let staff_type_ids = [];
        let new_value = [];
        if (value) {
            let isAllinList = value.findIndex(i => i.type === "All");
            if (isAllinList !== -1 && isAllinList === value.length-1) {
                new_value = [ff.staffTypeList[0]];
                staff_type_ids.push(0);
            } else {
                value.forEach(function(item) {
                    if (item.type !== "All") {
                        new_value.push(item);
                        staff_type_ids.push(parseInt(item.id));
                    }
                })
            }
            if (value.length === 0) {
                new_value = [ff.staffTypeList[0]];
                staff_type_ids.push(0);
            }
            ff['staffTypes'] = new_value;
            ff['staff_type_ids'] = staff_type_ids;
        }
        this.setState({
            formFields: ff
        }, function(){
            this.statusChange()
        });
    }
     
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export_csv').value = true;
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let data = this.state.formValues;
        let validationData = [
            { name: 'start_date', value: data.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: data.end_date, type: 'date', otherOptions: { required: true } },

        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }


    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <TimesheetLateSubmissionComponent
                FormInputs = {this.state.formInputs}
                FormValues = {this.state.formValues}
                HandleOnChange = {this.handleOnChange}
                Errors = {this.state.errors}
                HandleSubmit = {this.handleSubmit}
                ReportData = {this.state.reportData}
                IsSearch = {this.state.isSearch}
                HandleStaffStatusChange = {this.handleStaffStatusChange}
                HandleStaffTypeChange = {this.handleStaffTypeChange}
                NameFormat = {this.state.nameFormat}
                ExportReports = {this.exportReports}
            />
            :<ErrorPage/>
            }
            </>
        )
    }
}
