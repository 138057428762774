import React from 'react';
import {PtoRolloverReportComponent} from './ptoRolloverReportComponent';
import * as validations from '../../common/validate';

const formFields = {
    'year' : new Date().getFullYear(),
}

export class ptoRolloverReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            formFields : formFields,
            name_format:'',
            errors: {}
        }
        this.exportReports = this.exportReports.bind(this);
    }
    componentDidMount = () => {
        window.scrollTo(0, 0);
    };
    handleOnChange = (evt, value) => {
        let f = this.state.formFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
    }

    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'year', value: this.state.formFields.year, type: 'alphanumeric', otherOptions: { required: false } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    render(){
        return(
            <PtoRolloverReportComponent
            Year = {this.state.year }
            Errors = {this.state.errors}
            FormFields = {this.state.formFields}
            HandleOnChange = {this.handleOnChange.bind(this)}
            ExportReports = {this.exportReports}
            />  
        )
    }
}
     
