import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../../../common/common';
import {DialogBoxMedium} from '../../../common/dialog.medium';

export const AppraisalComponent = (props) => {
    const { Data, OnUpdate, OnView, OnDelete, Edit ,ViewScaleComments} = props;
    const dataArr = (_R.type(Data) !== 'Undefined' && 'appraisalInfo' in Data) ? Data.appraisalInfo:[];  
    let table_size = 10; // Default
    let data_arr_length = dataArr.length ;
    if (data_arr_length < 10) {
        table_size = data_arr_length < 3 ? 2 : data_arr_length;
    }

    return (
        <>
            <React.Fragment>
	     <div className={'mtable-basic'}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title="Performance Appraisal"
                        columns={[
                            { 
                                title: 'Appraisal Type', 
                                field: 'type',  
                            },
                            {
                                title: 'Start Date',
                                field: 'start_date',
                            },
                            {
                                title: 'End Date',
                                field: 'end_date',
                            },
                            {
                                title: 'Evaluator',
                                field: 'evaluator_name',
                                render: rowData => <>{formatName(rowData.eval_first_name,null,rowData.eval_last_name)}</>,
                            },
                            {
                                title: 'Approver',
                                field: 'approver_name',
                                render: rowData => <>{formatName(rowData.approver_first_name,null,rowData.approver_last_name)}</>,
                            },
                            {
                                title: 'Rating',
                                field: 'rating',
                            },
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,                            
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Appraisal Informations',
                                isFreeAction: true,
                                onClick: (event) => { props.OnAdd() },
                                hidden: (Edit) ? false : true,
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    OnUpdate(rowData.id);
                                },
                                hidden: (Edit) ? false : true,
                            },
                            {
                                icon: 'visibility',
                                tooltip: 'View',
                                onClick: (event, rowData) => {
                                    OnView(rowData.id);
                                },
                                hidden: (ViewScaleComments) ? false : true,
                            }                           

                        ]}
                        editable={{
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                    OnDelete(rowData.id);
                                    resolve()
                                    }, 500)
                            }),
                        }}
                     />
                </Grid>
            </Grid>
	     </div>
            <DialogBoxMedium 
                    Open={props.open}  
                    Cancel={props.onClose}
                    Title={props.isViewable ? 'View Appraisal Details' : props.isEditable ? 'Edit Appraisal Details':'Add Appraisal Details'}
                    OnSubmit={props.OnSubmit}
                    Content={<DialogContent FormProps={props}/>}/>
        </React.Fragment>
        </>
    )
};

const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, FormInputs, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>                   
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="appraisal_type" required  error={props.Errors.appraisal_type && true}>Appraisal Type</InputLabel>
                            <Select
                                disabled={props.isViewable ? true : false}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.appraisal_type}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'appraisal_type',
                                    id: 'appraisal_type',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.appraisalTypes) !== 'Undefined' && FormInputs.appraisalTypes.map((t, i) =>            
                                    <MenuItem value={t.id} key={'sub-type-'+i}>{t.title}</MenuItem>
                                )}
                             </Select>
                                {props.Errors.appraisal_type && <FormHelperText className='error'> {props.Errors.appraisal_type} </FormHelperText> }
                        </ FormControl>
                    </Grid>                                        
                    <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="rating"
                                name="rating"
                                label="rating"
                                type="text"
                                disabled={props.isViewable ? true : false}
                                fullWidth
                                value={FormFields.rating}
                                onChange={HandleOnChange}
                                required
                                error={props.Errors.rating && true}
                                helperText={props.Errors.rating}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="evaluator" required  error={props.Errors.evaluator && true}>Evaluator</InputLabel>
                            <Select
                                required
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.evaluator}
                                disabled={props.isViewable ? true : false}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'evaluator',
                                    id: 'evaluator',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.evaluator) !== 'Undefined' && FormInputs.evaluator.map((t, i) =>
                                    <MenuItem value={t.staff_id} key={'evaluator-'+i}>{formatName(t.first_name,null,t.last_name)}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.evaluator && <FormHelperText className='error'> {props.Errors.evaluator} </FormHelperText> }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="approver" required  error={props.Errors.evaluator && true}>Approver</InputLabel>
                            <Select
                                required
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.approver}
                                disabled={props.isViewable ? true : false}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'approver',
                                    id: 'approver',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.approver) !== 'Undefined' && FormInputs.approver.map((t, i) =>
                                    <MenuItem value={t.staff_id} key={'approver-'+i}>{formatName(t.first_name,null,t.last_name)}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.approver && <FormHelperText className='error'> {props.Errors.approver} </FormHelperText>}
                        </ FormControl>
                    </Grid>
                     <Grid item xs={12} md={6}>
                        <FormControl>
                          <TextField variant="standard"
                                id="scale"
                                name="scale"
                                label="scale"
                                type="text"
                                disabled={props.isViewable ? true : false}
                                fullWidth
                                value={FormFields.scale}
                                onChange={HandleOnChange}
                                error={props.Errors.scale && true}
                                helperText={props.Errors.scale}
                            />

                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                          <TextField
                             id="comments"
                             name="comments"
                             label="Comments"
                             disabled={props.isViewable ? true : false}
                             multiline
                             variant="outlined"
                             fullWidth
                             value={FormFields.comments}
                             onChange={HandleOnChange}
                             error={props.Errors.comments && true}
                             helperText={props.Errors.comments}
                           />

                        </ FormControl>
                       </Grid>


                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                disabled={props.isViewable ? true : false}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.start_date && true}
                                helperText={props.Errors.start_date}
                            />
                        </ FormControl>
                    </Grid>                                        
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                disabled={props.isViewable ? true : false}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.end_date}
                                onChange={HandleOnChange}
                                error={props.Errors.end_date && true}
                                helperText={props.Errors.end_date}
                            />
                        </ FormControl>
                    </Grid>                    
                 </Grid>
            </form>
    )
}
