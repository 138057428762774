import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { StateTax } from './Statetax.Container';
import {DialogBoxMedium} from '../../../common/dialog.medium';

export const FedtaxComponent = (props) => {
    const { Data } = props;
    const {TaxEditPermission:Edit, TaxDeletePermission:Delete} = props;
    const dataArr = (_R.type(Data) !== 'Undefined' && Data);
    const StaffID = (_R.type(props.StaffID) !== 'Undefined' && 'StaffID' in props) ? props.StaffID:0;
    let table_size = 10; // Default
    let data_arr_length = dataArr.length ;
    if (data_arr_length < 10) {
        table_size = data_arr_length < 3 ? 2 : data_arr_length;
    }
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <div  className={'mtable-basic'}>
                    <CustomMaterialTable
                        title="Federal Tax"
                        columns={[
                            
                            {
                                title: 'Start Date',
                                field: 'start_date',
                            },
                            {
                                title: 'End Date',
                                field: 'end_date',
                            },
                            
                            {
                                title: 'Fed Tax Allowances',
                                field: 'fed_tax_allowances',
                            },
                            
                            {
                                title:'Fed Tax Exempt Withholding',
                                field:'exempt_label',
                                
                            },
                            {
                                title:'Fed Tax Extra Withholding',
                                field:'fed_tax_extra_withholding',
                                
                            },
                            {
                                title:'Filing Status',
                                field:'marital_status',
                            },
                            {
                                title:'Multiple Jobs',
                                field:'job_label',
                            },
                            {
                                title:'Claim Dependents',
                                field:'claim_dependents',
                            },
                            {
                                title:'Other Income',
                                field:'other_income',
                            },
                            {
                                title:'Deductions',
                                field:'deductions',
                            },


                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                            columnsButton:true,
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Federal Tax  Details',
                                isFreeAction: true,
                                onClick: (event) => { props.OnAdd() },
                                hidden: (Edit) ? false : true,
                            },
                           
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                                hidden: (Edit) ? false : true,
                            },
                        ]}
                        editable={{
                            isDeletable: rowData => (Delete) ? true : false,
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                    {
                                        let idx = dataArr.findIndex(x => x.id === rowData.id);
                                        props.OnDelete(idx, rowData.id);
                                    }
                                    resolve()
                                    }, 500)
                            }),
                        }}
                     />
                   </div>
                </Grid>
            </Grid>
            
            <DialogBoxMedium 
                    Open={props.Open}
                    Cancel={props.OnClose}
                    Title={(props.isEditable?'Edit Federal Tax Details':'Add Federal Tax Details')}
                    OnSubmit={props.OnSubmit}
                    Content={  <DialogContent FormProps={props} /> }
           />
        <StateTax
                TaxInfo = {props.StateTaxData}
                MaritalStatuses = {props.MaritalStatuses}
                AllStates = {props.AllStates}
                StaffID = {StaffID}
                TaxViewPermission = {props.TaxViewPermission}
                TaxEditPermission = {props.TaxEditPermission}
                TaxDeletePermission = {props.TaxDeletePermission}
        />

        </React.Fragment>
        </>
    )
};



const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange2} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <div className = "error"> {props.Errors.fedtax_overlap}</div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.start_date}
                                onChange={HandleOnChange2}
                                error={props.Errors.start_date && true}
                                helperText={props.Errors.start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.end_date}
                                onChange={HandleOnChange2}
                                error={props.Errors.end_date && true}
                                helperText={props.Errors.end_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="fed_filing_status" required error={props.Errors.fed_filing_status && true}>Federal Tax Filing Status</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.fed_filing_status}
                                onChange={HandleOnChange2}
                                inputProps={{
                                    name: 'fed_filing_status',
                                    id: 'fed_filing_status',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                 {_R.type(p.FormProps.FederalFilingStatuses) !== 'Undefined' && p.FormProps.FederalFilingStatuses.map((t, i) =>
                                    <MenuItem value={t.id} key={'m-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                             {props.Errors.fed_filing_status && <FormHelperText className = 'error'>{props.Errors.fed_filing_status}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                 <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="fed_tax_allowances"
                                name="fed_tax_allowances"
                                label="Federal Tax Allowances"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.fed_tax_allowances}
                                onChange={HandleOnChange2}
                                error={props.Errors.fed_tax_allowances && true}
                                helperText={props.Errors.fed_tax_allowances}
                            />
                        </ FormControl>
                    </Grid>
                <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="fed_tax_extra_withholding"
                                name="fed_tax_extra_withholding"
                                label="Federal Tax Extra WithHolding"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.fed_tax_extra_withholding}
                                onChange={HandleOnChange2}
                                error={props.Errors.fed_tax_extra_withholding && true}
                                helperText={props.Errors.fed_tax_extra_withholding}
                            />
                        </ FormControl>
                    </Grid>
               <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="fed_tax_exempt_withholding" required error={props.Errors.fed_tax_exempt_withholding && true}>Federal Tax Exempt Withholding</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.fed_tax_exempt_withholding}
                                onChange={HandleOnChange2}
                                inputProps={{
                                    name: 'fed_tax_exempt_withholding',
                                    id: 'fed_tax_exempt_withholding',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={"1"} key={'i-type-1'}>Yes</MenuItem>
                                <MenuItem value={"0"} key={'i-type-2'}>No</MenuItem>   
                            </Select>
                           {props.Errors.fed_tax_exempt_withholding && <FormHelperText className = 'error'>{props.Errors.fed_tax_exempt_withholding}</FormHelperText> } 
                        </ FormControl>                        
                    </Grid>
                <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="multiple_jobs"  required error={props.Errors.multiple_jobs && true}>Multiple Jobs</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.multiple_jobs}
                                onChange={HandleOnChange2}
                                inputProps={{
                                    name: 'multiple_jobs',
                                    id: 'multiple_jobs',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={"1"} key={'i-type-1'}>Yes</MenuItem>
                                <MenuItem value={"0"} key={'i-type-2'}>No</MenuItem>
                            </Select>
                           {props.Errors.multiple_jobs && <FormHelperText className = 'error'>{props.Errors.multiple_jobs}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="claim_dependents"
                                name="claim_dependents"
                                label="Claim Dependents"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.claim_dependents}
                                onChange={HandleOnChange2}
                                error={props.Errors.claim_dependents && true}
                                helperText={props.Errors.claim_dependents}
                            />
                        </ FormControl>
                    </Grid>
                <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="other_income"
                                name="other_income"
                                label="Other Income"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.other_income}
                                onChange={HandleOnChange2}
                                error={props.Errors.other_income && true}
                                helperText={props.Errors.other_income}
                            />
                        </ FormControl>
                    </Grid>
                <Grid item  xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="deductions"
                                name="deductions"
                                label="Deductions"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.deductions}
                                onChange={HandleOnChange2}
                                error={props.Errors.deductions && true}
                                helperText={props.Errors.deductions}
                            />
                        </ FormControl>
                    </Grid>
                 </Grid>
            </form>
    )
}
