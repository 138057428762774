import React from 'react';
import { StateTaxComponent } from './Statetax.Component';
import { httpServices } from '../../../../services/httpServices';

const formFields = {
    'id':0,
    'start_date': null,
    'end_date': null,
    'income_tax_state' :null,
    'unemployment_filing_state':null,
    'state_tax_allowances' :null,
    'state_tax_extra_withholding' :null,
    'state_filing_status' : null,
    'state_tax_exempt_withholding' : null,
};

export class StateTax extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            state_tax_data: [],
            title : 'State Tax',
            formFields: formFields,
            open: false,
            isEditable: false,
            errors: {},
            editBasic: false,
            Open: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        if (field === 'family_id') {
            this.setState({'family_id': evt.target.value});
        }

        else {
            const ff = this.state.formFields;
            ff[field] = evt.target.value;
            this.setState({ 
                formFields: ff
            });
        }
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/compensation/add-state-tax', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data) {
                                if(data.id){
                                    let details = this.state.state_tax_data;
                                    const index = details.findIndex(a => a.id === data.id);
                                    details[index] = response.data.statetax_details;
                                    this.setState({state_tax_data:details, Open: false});
                                    alert("State tax details updated successfully");
                                } else {
                                    let details = this.state.state_tax_data;
                                    details.push(response.data.statetax_details);
                                    this.setState({
                                        state_tax_data:details,
                                        Open: false,
                                    });
                                    alert("State tax  details saved successfully");
                                }
                            }
                            else
                            {
                                const ff = this.state.errors;
                                ff['statetax_overlap'] = "State Tax already exist for this period";
                                this.setState({open: true,errors:ff});
                            }
                        }
                        else {
                            this.setState({errors:response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    onAdd = () => {
        this.clearForm();
        if (this.state.state_tax_data.length === 0){   
           this.setState({state_tax_data : this.props.TaxInfo});
        }
        this.setState({
            Open: true,
            isEditable: false,
        });
    };

    clearForm = () => {
        const ff = {
          'id':0,
          'start_date': null,
          'end_date': null,
          'income_tax_state' :null,
          'unemployment_filing_state':null,
          'state_tax_allowances' :null,
          'state_tax_extra_withholding' :null,
          'state_filing_status' : null
         };

        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            isEditable: false,
        });
    };
    
    onClose = (isBasicEdit) =>{
        this.clearForm();
        this.setState({
            Open: false,
            editBasic: false
        });
    };
    
    onUpdate = (id) => {
        if (this.state.state_tax_data.length===0){
            this.setState({
                state_tax_data: this.props.TaxInfo
            });
        }
        this.setState({
                isEditable: true
            });

        return httpServices.post('api/compensation/get-state-tax-details-by-id', {'id': id})
            .then(response => {

                if (response.data) {
                    let data = response.data;
                    if (Object.keys(data).length){
                        const inputs = {
                            'id': data.id,
                            'start_date': data.start_date,
                            'end_date': data.end_date,
                            'income_tax_state': data.income_tax_state,
                            'unemployment_filing_state':data.unemployment_filing_state,
                            'state_tax_allowances':data.state_tax_allowances,
                            'state_tax_extra_withholding':data.state_tax_extra_withholding,
                            'state_filing_status':data.state_filing_status,
                            'state_tax_exempt_withholding':data.state_tax_exempt_withholding,
                        };
                        this.setState({
                            formFields: inputs,
                            Open: true,
                            id: data.id,
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onDelete = (index, rowID) => {
       var len = this.state.state_tax_data.length;
        if (len === 0) {
            this.setState({state_tax_data:this.props.TaxInfo}, function(){
                let b = this.state.state_tax_data;
                b.splice(index, 1);
                this.setState({state_tax_data: b});
            });
        }
        else {
            let b = this.state.state_tax_data;
            b.splice(index, 1);
            this.setState({state_tax_data: b});

        }
        return httpServices.post('api/compensation/delete-state-tax', {'id': rowID, 'staff_id': this.props.StaffID})
        .then(response => {
            if (!response.data.error) {
                alert("State Tax details deleted successfully");
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });

    }

 
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }       
        if(this.state.formFields.start_date && this.state.formFields.end_date){
            let start_date = new Date(this.state.formFields.start_date);
            let end_date = new Date(this.state.formFields.end_date);
            if(start_date > end_date){
                errors["end_date"] = "End date cannot be before start date";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.income_tax_state) {
            errors["income_tax_state"] = "Cannot be empty";
            formIsValid = false;
        }       
        if (!this.state.formFields.unemployment_filing_state) {
            errors["unemployment_filing_state"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.state_filing_status) {
            errors["state_filing_status"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.state_tax_exempt_withholding && this.state.formFields.state_tax_exempt_withholding !== 0) {
            errors["state_tax_exempt_withholding"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    render(){
        return(
            <StateTaxComponent
               Data = {this.props.TaxInfo}
               EditPermission = {this.props.TaxEditPermission}
               DeletePermission = {this.props.TaxDeletePermission}
               ViewPermission = {this.props.TaxViewPermission}
               AllStates = {this.props.AllStates}
               Title={this.state.title}
               FormFields={this.state.formFields}
               StaffID = {this.props.StaffID}
               MaritalStatuses = {this.props.MaritalStatuses}
               OnSubmit={this.handleSubmit} 
               IsEditable={this.state.isEditable}
               HandleOnChange={this.handleOnChange}
               OnAdd={this.onAdd.bind(this)}
               OnDelete={this.onDelete.bind(this)} 
               Open= {this.state.Open} 
               OnClose={this.onClose.bind(this)}
               OnUpdate={this.onUpdate.bind(this)} 
               Errors={this.state.errors}
            />
        )
    }
}
