import { createSlice } from '@reduxjs/toolkit';
import { httpServices } from '../../../services/httpServices';

export const menuItems = createSlice({
  name: 'menu',
  initialState: {
    menu: {}, 
    'selectedMenuIndex' : -1
  },
  reducers: {
    setMenus: (state, action) => {
      state.menu = action.payload;    
    },
    setSelectedMenuIndex: (state, action) => {
      state.selectedMenuIndex = action.payload;    
    }, 
  }
})

export const setMenuItems = () => (dispatch) => {
  httpServices.get('api/system/get-menu-items')
  .then(response => {
      if (response.data) {
        dispatch(setMenus(response.data.menu))
      }
  })
  .catch(function (error) {
    console.log("Error: "+error);
  });   
  }

// Action creators are generated for each case reducer function
export const { setMenus, setSelectedMenuIndex } = menuItems.actions
export default menuItems.reducer