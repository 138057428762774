import { ReferralBonusComponent } from './ReferralBonus.Component';
import React from 'react';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';

const formFields = {
    'id' : 0,
    'referred_staff_id' : null,
    'refered_employee' : null,
    'doh_referred_staff' : null,
    'bonus_amount' : null,
}
export class ReferralBonusList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            Open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            referral_bonus:[],
            errors: {},
        };
    this.handleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    onAddComponent = () => {
        this.setState({Open: true,
        isEdit: false,
        });
    }
    onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    submit =() => {
        if (this.handleValidation()) {
            this.saveReferral();
        }
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.referred_staff_id) {
            errors["referred_staff_id"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.doh_referred_staff) {
            errors["doh_referred_staff"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.bonus_amount) {
            errors["bonus_amount"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

     saveReferral = () => {
        let data = this.state.formFields;
        const staffID = this.props.StaffID;
        data.staff_id = staffID;
        if(this.handleValidation()){
            httpServices.post('api/compensation/save-Referral', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({referral_bonus:response.data.referral_bonus});
                            this.setState({Open:false});
                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
          })
       .catch(function(error) {
            console.log("Error: "+error);
            alert("Something went wrong. Please try again later");
        });
        }
    }
    onUpdate = (id) => {
        return httpServices.post('api/compensation/get-referral-bonus-details', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        Open: true,
                        id: id,
                        isEdit: true,
                        formFields: response.data.ref_bonus_info[0],
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onDelete = (index, rowID) => {
        return httpServices.post('api/compensation/delete-referral-bonus-details', {'id': rowID, 'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data.valid) {
                    this.setState({referral_bonus:response.data.referral_bonus});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    render(){
        return(
        <ReferralBonusComponent
            AddComponent={this.onAddComponent.bind(this)}
            Open={this.state.Open}
            Close={this.onClose.bind(this)}
            Employee_list={this.props.BonusInfo && this.props.BonusInfo.users ? this.props.BonusInfo.users : undefined}
            FormFields={this.state.formFields}
            HandleOnChange={this.handleOnChange}
            SaveReferral={this.submit.bind(this)}
            BonusInfo={this.state.referral_bonus.length>0 ? this.state.referral_bonus : (_R.type(this.props.BonusInfo)!== 'Undefined')? this.props.BonusInfo:undefined}
            UserInfo={this.props.UserInfo}
            Update={this.onUpdate.bind(this)}
            OnDelete={this.onDelete.bind(this)} 
            Errors={this.state.errors}
        />
        )
    }

}

