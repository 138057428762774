import React from 'react';
import { GroupListComponent} from './GroupList.Component';
import { httpServices } from '../../../services/httpServices';
import { history } from '../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';


export class GroupListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            showLoader : true,
            dialogOpen : false,
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            editId : 0,
            editPermission : false,
            deletePermission : false,
            dataLoaded : false,
            loggedUser : 0,
            groups : [],
            open : false,
            errors : [],
            popupType:''
        };
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.getGroupList = this.getGroupList.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.updateParentOnSuccess = this.updateParentOnSuccess.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDeleteClick =  this.handleDeleteClick.bind(this);
        this.deleteGroup =  this.deleteGroup.bind(this);
    }
   
    componentDidMount() {
        this.getGroupList();
    }

   addGroup = () => {
        this.setState({
            editId : 0,
            open: true,
            popupType:'edit'
        });
    };

    getGroupList = () => {
        this.setState({
            showLoader : true,
        });
        let apiEndpoint = 'api/user/get-user-groups';
        return httpServices.post(apiEndpoint)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            showLoader : false,
                            groups : response.data.groups,
                            permission : response.data.permission,
                            editPermission : response.data.edit_permission,
                            deletePermission : response.data.delete_permission,
                            dataLoaded : true,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded : true,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('groups/view/' + id);
        }
    }

    handleEditClick = (id) => {
        this.setState({            
            open : true,
            editId : id,
            popupType:'edit'
        });
    }

     onClose = () => {
        this.setState({
            open: false,
        });
    };

    updateParentOnSuccess = (status) => {
        if(status.success){
            this.setState({
                open : false,
                snackbarOpen : true,
                snackbarVarient : 'success',
                snackbarMsg : status.success_message,
            },()=>{
                this.getGroupList();});
            }
        
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    handleDeleteClick = (id) => {
        this.setState({            
            open : true,
            editId : id,
            popupType:'delete'
        });
    }

    deleteGroup = () => {        
        let apiEndpoint = 'api/user/delete-group';
        return httpServices.post(apiEndpoint,{id : this.state.editId})
            .then(response => {
                if (response.data && response.data.permission) {
                    if (response.data.success) {
                        this.setState({
                            snackbarOpen : true,
                            snackbarVarient : 'success',
                            snackbarMsg : response.data.success_message,
                            open : false,
                            },()=>{
                            this.getGroupList();
                            });
                    }else{
                        this.setState({
                            snackbarOpen : true,
                            snackbarVarient : 'error',
                            open : false,
                            snackbarMsg : response.data.error_message,
                            });
                    }
            
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <GroupListComponent
                        Groups = {this.state.groups}
                        AddGroup = {this.addGroup}
                        HandleEditClick = {this.handleEditClick}
                        Open = {this.state.open}
                        HandleViewDetails ={this.handleViewDetails}
                        EditId = {this.state.editId}
                        UpdateParentOnSuccess = {this.updateParentOnSuccess}
                        EditPermission = {this.state.editPermission}
                        DeletePermission = {this.state.deletePermission}
                        OnClose={this.onClose}
                        SnackbarOpen={this.state.snackbarOpen}
                        SnackbarVarient={this.state.snackbarVarient}
                        SnackbarMsg={this.state.snackbarMsg}
                        SnackbarClose={this.snackbarClose}
                        DeleteClick={this.deleteGroup}
                        HandleDeleteClick = {this.handleDeleteClick}
                        PopupType={this.state.popupType}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                :<></>
            }
            </>
        )
    }
}
