import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { BasicDetailsContainer } from './BasicDetails.Container';
import { SubProjectContainer } from './SubProject.Container';
import { ContractContainer } from './Contract.Container';
import { EstimationContainer } from './Estimation.Container';
import { CustomizedSnackbars } from '../../common/snackbars';
import { ResourcesContainer } from './Resources.Container';
import { TaskContainer } from './Task.Container';
import { ChangeLogContainer } from './ChangeLog.Container';
import { LabelsContainer } from './Labels.Container';
import { WorkLocationContainer } from './WorkLocation.Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AddProject } from '../AddProject/AddProject.Container.js';
import { CreateProjectWizard } from '../CopyProject/CreateProjectWizard.Container.js';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { DialogBoxMedium } from '../../common/dialog.medium';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';


const Styles = styled(React.Fragment)(({ theme }) => ({
    '& .paper': {
        color: theme.palette.text.secondary,
        minHeight: 'calc(100vh - 100px)',
        boxShadow: 'none',
    },
    '& .gridRoot': {
        minHeight: 'calc(100vh - 100px)',
    },
    '& .tab': {
        backgroundColor: '#eee',
        display: 'block',
    },
    '& .bgWhiteLeft': {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '4px',
        borderRight: '1px solid #eee',
        borderBottomRightRadius: 0,
        padding: theme.spacing(2),
    },
    '& .bgWhiteContent': {
        backgroundColor: '#fff',
        padding: theme.spacing(3),
        display: 'block',
        position: 'relative',
    },
}));

export const ProjectViewComponent = (props) => {
    const display = (((props.Project['project_parent'] !== 0) || props.Project['is_contract'] == "1" || props.Project['labor_category'] == "1"));
    let displayLocationTab = (props.Project['timesheet_location'] == "1") ? true : false;
    let firstTab = props.SubProjects ?  "subprojects" : "tasks";
    let tabIndex = firstTab ;
    //Tabs
    const [tabValue, setTabValue] =  React.useState(tabIndex);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
       return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    const pmprpermission = (props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission) ? true : false;
    const estimationTabView = (props.TaskCount && (props.Project.paytype === "Direct") && (props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission)) ? true : false;
    return (
            <Styles>
              <div className="section-main">
                <div className="section-header">
                    <div className="col-left">
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                        <div className="section-title-wrap">
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                                {props.Project['project_name']} 
                            </Typography>
                            <Typography> {props.Project['project_code']} </Typography>
                        </div>
                    </div>
                    <div className={'action profile-view__action-right'}>
                        <Grid container spacing={2}>
                            {(props.EditPermission || props.SelfEditPermission) &&
                                    <Grid item>
                                        <Button variant="contained" size="small" onClick={props.OnCopy} color="primary" >Copy project </Button>
                                    </Grid>
                            }
                            {(props.EditPermission) ?
                                <Grid item>
                                    <Button size="small" variant="contained" onClick={props.OnAddSubProjectClick} color="primary" >Add SubProject </Button>
                                </Grid>
                                :
                                <></>
                            }
                            {props.WorkLocations.length && (props.EditPermission || props.SelfEditPermission) && displayLocationTab ?
                            <Grid item>
                                <Button size="small" variant="contained" onClick={props.OnAddWorkLocationClick} color="primary" >Add Work Location </Button>
                            </Grid>
                            :
                            <></>
                            }
                            {(props.EditPermission || props.SelfEditPermission) &&
                                <Grid item>
                                    <Button variant="contained" size="small" onClick={props.OnUpdate} color="primary" >Edit </Button>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
                <Paper style={{'width': '100%', 'flexGrow': '1' }}>
                <Grid container justify="space-between" alignItems="stretch" spacing={0} className={'gridRoot'}>
                     <Grid  item xs={12} md={3}>
                            <Typography component="div" className={'bgWhiteLeft profile-view project-view'}>
                                <BasicDetailsContainer  ProjectId={props.ProjectId} UpdateParentOnEditSuccess={props.UpdateParentOnEditSuccess} Project ={props.Project} ParentProject = {props.ParentProject} ViewLink={props.ViewLink} OtherPermissions={props.OtherPermissions}  EditPermission={props.EditPermission} SelfEditPermission={props.SelfEditPermission} ReviewerViewPermission = {props.ReviewerViewPermission} ReviewerEditPermission = {props.ReviewerEditPermission} ReviewerDeletePermission = {props.ReviewerDeletePermission} SystemLocation = {props.SystemLocation}/>
                            </Typography>
                        </Grid>
                        
                        <Grid  item xs={12} md={9}>
                            <Tabs
                            value={tabValue}
                            onChange={tabChange}
                            variant="scrollable"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="Project Tabs"
                            scrollButtons="auto"
                            className={'tab-main'}
                            >                          
                            
                            {props.SubProjects && 
                                    < Tab label="Subprojects" value="subprojects"/>
                             }
                            <Tab label="Tasks" value="tasks"/>
                            {(props.ResourceView) &&
                                <Tab label="Resources" value="resource"/>
                            }
                            {((props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission) && (display === true)) &&
                               (props.Project['is_contract'] == "0" && props.Project['labor_category'] == "1" ) &&
                               <Tab label="Labor category" value="laborcategory"/>
                            }
                            {((props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission) && (display === true)) &&
                               (props.Project['is_contract'] == "1") &&
                              <Tab label="Contract" value="contract"/>
                            }
                            {estimationTabView &&
                            <Tab label="Estimation" value="estimation"/>
                            }
                            {displayLocationTab &&
                              <Tab label="Work Locations" value="worklocations" />
                            }
                            <Tab label="Labels" value="labels" />
                            {pmprpermission &&
                            <Tab label="Change Log" value="changelog"/>
                            }
                        </Tabs>
                        <div className={'bgWhiteContent tabContainer'}>
                        {(props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission) &&
                            (display === true && (tabValue === 'contract' || tabValue === 'laborcategory')) && 
                                <TabPanel value={tabValue} index={tabValue}>
                                    <ContractContainer ProjectId = {props.ProjectId} InheritResourceFlag = {props.InheritResourceFlag}/>
                                </TabPanel>
                        }
                        {(props.ResourceView) &&
                                (tabValue === 'resource') &&
                                <TabPanel value={tabValue} index={tabValue}>
                                   <ResourcesContainer ProjectId = {props.ProjectId} InheritResourceFlag = {props.InheritResourceFlag} />
                                </TabPanel>
                                
                        }
                            {(tabValue === 'subprojects') &&
                            <TabPanel value={tabValue} index={tabValue}>
                                  <SubProjectContainer ProjectId = {props.ProjectId}/>
                            </TabPanel>
                            }
                            {(tabValue === 'tasks') &&
                            <TabPanel value={tabValue} index={tabValue}>
                                <TaskContainer
                                    ProjectId = {props.ProjectId}
                                />
                            </TabPanel>
                            }
                        {(props.OtherPermissions.allView || props.OtherPermissions.pmPrView || props.ProjectParentPermission) &&
                            <>
                            {(estimationTabView && tabValue === 'estimation') && 
                            <TabPanel value={tabValue} index={tabValue}>                             
                              <EstimationContainer 
                                  ProjectId = {props.ProjectId}
                              />
                             </TabPanel>
                            }
                                    {(tabValue === 'worklocations') && displayLocationTab &&
                                        <TabPanel value={tabValue} index={tabValue}>
                                            <WorkLocationContainer
                                                ProjectId={props.ProjectId}
                                            />
                                        </TabPanel>
                                    }
                                    {(tabValue === 'labels') &&
                                        <TabPanel value={tabValue} index={tabValue}>
                                            <LabelsContainer
                                                ProjectId={props.ProjectId}
                                            />
                                        </TabPanel>
                                    }
                            {(tabValue === 'changelog') &&
                            <TabPanel value={tabValue} index={tabValue}>
                            <ChangeLogContainer 
                                ProjectId = {props.ProjectId}
                            />  
                            </TabPanel>
                            }
                            </>
                        }
                        </div>
                        </Grid>
                </Grid>
            </Paper>
            </div>
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {(props.DialogOpen) &&
            <AddProject
                Open = {props.DialogOpen}
                UpdateParentOnAddSuccess = {props.UpdateParentOnEditSuccess}
                EditId = {props.ProjectId}
                Title = {"Edit Project"}
            />            
            }
            {(props.DialogCopy) &&
            <CreateProjectWizard
                Open = {props.DialogCopy}
                UpdateParentOnCopySuccess = {props.UpdateParentOnCopySuccess}
                EditId = {props.ProjectId}
                Title = {"Copy Project"}
                Type = {"Copy"}
            />            
            }
            {(props.AddSubProjectPopup) &&
                <CreateProjectWizard
                    Open={props.AddSubProjectPopup}
                    UpdateParentOnCopySuccess={props.UpdateParentOnAddSuccess}
                    EditId={0}
                    Title={"Add Subproject"}
                    Parent={props.ProjectId}
                    Type = {"AddSubProject"}
                />
            }
            <DialogBoxMedium
                    Open={props.AddLocationPopUp}
                    Cancel={props.OnClose}
                    ButtonName={'Add'}
                    OnSubmit={props.OnConfirm}
                    Title={'Add Project Location'}
                    Content={ 
                        <AddLocation 
                            WorkLocations = {props.WorkLocations}
                            ProjectLocation = {props.ProjectLocation}
                            Default = {props.Default}
                            TimesheetLocation = {props.TimesheetLocation}
                            HandleTimesheetLocationChange = {props.HandleTimesheetLocationChange}
                            HandleOnChange = {props.HandleOnChange}
                            HandleDefaultChange = {props.HandleDefaultChange}
                            Error = {props.Error}
                            TimesheetError = {props.TimesheetError}
                        />                         
                    }
                />
        </Styles>        
    );
}
const AddLocation = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="Location" required >Work Location</InputLabel>
                            <Select
                                value={(typeof props.ProjectLocation !== 'undefined') ? props.ProjectLocation : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'project_location',
                                    id: 'project_location',
                                }}>
                                {typeof props.WorkLocations !== 'undefined' && props.WorkLocations.map((cl, key) =>
                                    <MenuItem value={cl.id} >{cl.location}</MenuItem>
                                )}
                            </Select>
                            {(typeof props.Error !== 'undefined') && props.Error &&
                                <FormHelperText className='error'>{props.Error}</FormHelperText>
                            }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked = {(typeof props.Default !== 'undefined') && props.Default === 1 ? true : false}
                                    onChange={props.HandleDefaultChange}
                                    color="primary"
                                    inputProps={{
                                        name: 'default',
                                        id: 'default',
                                    }}
                                />
                            }
                            label="Set as default"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked = {(typeof props.TimesheetLocation !== 'undefined') && props.TimesheetLocation === 1 ? true : false}
                                    onChange={props.HandleTimesheetLocationChange}
                                    color="primary"
                                    inputProps={{
                                        name: 'timesheet',
                                        id: 'timesheet',
                                    }}
                                />
                            }
                            label="Timesheet Location"
                        />
                        {(typeof props.TimesheetError !== 'undefined') && props.TimesheetError &&
                                <FormHelperText className='error'>{props.TimesheetError}</FormHelperText>
                        }
                    </Grid>                    
                </Grid>
            </form>
        </React.Fragment>
    )
}
