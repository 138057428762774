import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../common/common';
import { TreeSelectComponent } from '../common/TreeSelectComponent';
import * as _R from 'ramda';
import * as _ts from '../common/treeSelect';
const API_URL = process.env.REACT_APP_API_URL;
export const ProjectCodeReport = (props) =>{
    const ReportList = styled('div')(({ theme }) => ({
        '& .root': {
            width: '100%',
        },
        '& .container': {
            maxHeight: 440,
        },
        '& .color': {        
        backgroundColor: '#A3D1FF',
        },
    }));
    var ros = props.ReportTable.rows;
    let headers = props.ReportTable.header;
    let cols_count = props.ReportTable.header.length-1;
     
    let projects = ( _R.type(props.ReportInputs) !== 'Undefined' && _R.type(props.ReportInputs.project_list) !== 'Undefined') ? props.ReportInputs.project_list : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.ReportFields) !== 'Undefined') ? props.ReportFields.projects : []));
    }
    const gridStyle = {
        margin: '15px 0px'
    };
        return(
            <React.Fragment>
            <Paper md={12}>
            <div class="p1">
            <Typography variant="h6" component="h6" className={'section-title'}>Labor Distribution Report</Typography>
                <form id="labor-distribution" className="form-fullwidth" action={API_URL+'api/report/generate-pc-report'} method="post">
                <input type="hidden" id="export" name="export" /> 

                    <Grid container spacing={3} style={gridStyle}>
                         <Grid item md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                id = "start_date"
                                name = "start_date"
                                label = "Start Date"
                                type = "date"
                                required
                                onChange = {props.HandleOnChange}
                                error = { props.Errors.start_date && true }
                                helperText = { props.Errors.start_date }
                                value={(typeof props.ReportFields.start_date !== 'undefined') ? props.ReportFields.start_date : null}
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                                />
                            </ FormControl>
                        </Grid>

                       <Grid item md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                id = "end_date"
                                name = "end_date"
                                label = "End Date"
                                type = "date"
                                required
                                value={(typeof props.ReportFields.end_date !== 'undefined') ? props.ReportFields.end_date : null}
                                onChange = {props.HandleOnChange}
                                error = { props.Errors.end_date && true }
                                helperText = { props.Errors.end_date }
                                InputLabelProps = {{
                                    shrink: true,
                                }}
                            />
                            </ FormControl>
                        </Grid>


                        <Grid item  md={2} className='justify-between'>
                            <FormControl error={props.Errors.client_status && true} variant="standard">
                                <InputLabel htmlFor="client_status">Client status</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.client_status !== 'undefined') ? props.ReportFields.client_status : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'client_status',
                                        id:   'client_status',
                                    }}>
                                   <MenuItem value='0'>-All-</MenuItem>
               { typeof props.ReportInputs.client_statuses != 'undefined' && props.ReportInputs.client_statuses !=='' && props.ReportInputs.client_statuses.map((t, i) =>
                                    <MenuItem value={t.client_status_id} key={'cst-'+i}>{t.client_status_name}</MenuItem>
                                )} 
                                </Select>
                                <FormHelperText >{props.Errors.client_status}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={2}>
                            <FormControl error={props.Errors.client && true} variant="standard">
                                <InputLabel htmlFor="client">Client</InputLabel>
                                <Select
                                   value={(typeof props.ReportFields.client !== 'undefined') ? props.ReportFields.client : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'client',
                                        id: 'client',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                 {  typeof props.ReportInputs.client_list != 'undefined' && props.ReportInputs.client_list !== '' && props.ReportInputs.client_list.map((t, i) =>
                                        <MenuItem value={t.client_id} key={'client-'+i}>{t.client_name}</MenuItem>
                                    )} 
                                </Select>
                                <FormHelperText >{props.Errors.client}</FormHelperText>
                            </ FormControl>
                        </Grid>
                                            
                        <Grid item  md={2} className='justify-between'>
                            <FormControl error={props.Errors.project_status && true} variant="standard">
                                <InputLabel htmlFor="project_status">Project Status</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.project_status !== 'undefined') ? props.ReportFields.project_status : 1}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'project_status',
                                        id:   'project_status',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                    { typeof props.ReportFields.project_statuses != 'undefined' && props.ReportFields.project_statuses !=='' && props.ReportFields.project_statuses.map((t, i) =>
                                    <MenuItem value={t.project_status_id} key={'stst-'+i}>{t.project_status_name}</MenuItem>
                                )}
                                </Select>
                                <FormHelperText >{props.Errors.project_status}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={2} className='justify-between'>
                            <FormControl>
                                <input type='hidden' name='projects' value={props.ReportFields.projects}/>
                                <TreeSelectComponent
                                    data = {projects}
                                    placeholderText = { "Search Projects"}
                                    type = 'project'
                                    HandleTreeChange = {props.HandleTreeChange}
                                />
                            </FormControl>
                        </Grid>
                        
                        <Grid item  md={2} className='justify-between'>
                            <FormControl error={props.Errors.staff_status && true} variant="standard">
                                <InputLabel htmlFor="staff_status">Staff status</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.staff_status !== 'undefined') ? props.ReportFields.staff_status : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_status',
                                        id:   'staff_status',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
              { typeof props.ReportInputs.staff_statuses != 'undefined' && props.ReportInputs.staff_statuses !=='' && props.ReportInputs.staff_statuses.map((t, i) =>
                                    <MenuItem value={t.user_status_id} key={'stst-'+i}>{t.user_status_name}</MenuItem>
                                )}  
                                </Select>
                                <FormHelperText >{props.Errors.staff_status}</FormHelperText>
                               </FormControl>
                        </Grid>
                    
                        <Grid item  md={2} className='justify-between'>
                            <FormControl error={props.Errors.staff_type && true} variant="standard">
                                <InputLabel htmlFor="staff_type">Staff Type</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.staff_type !== 'undefined') ? props.ReportFields.staff_type : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_type',
                                        id:   'staff_type',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                      { typeof props.ReportInputs.staff_type != 'undefined' && props.ReportInputs.staff_type !== '' &&  props.ReportInputs.staff_type.map((t, i) =>
                                    <MenuItem value={t.id} key={'st-'+i}>{t.type}</MenuItem>
                                )}
                                </Select>
                                <FormHelperText >{props.Errors.staff_type}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item  md={2}>
                            <FormControl error={props.Errors.staff_name && true} variant="standard">
                                <InputLabel htmlFor="staff_name">Staff Name</InputLabel>
                                <Select
                                   value={(typeof props.ReportFields.staff_name !== 'undefined') ? props.ReportFields.staff_name : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_name',
                                        id: 'staff_name',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                {  typeof props.ReportInputs.staff_list != 'undefined' &&  props.ReportInputs.staff_list !== '' && props.ReportInputs.staff_list.map((t, i) =>
                                        <MenuItem value={t.user_id} key={'sn-'+i}>{ formatName(t.first_name,null,t.last_name, props.ReportFields.staff_name_format) }</MenuItem>
                                    )} 
                                </Select>
                                <FormHelperText >{props.Errors.staff_name}</FormHelperText>
                        </ FormControl>
                        </Grid>
                     
                      
                        <Grid item  md={2}>
                            <FormControl error={props.Errors.staff_name_format && true} variant="standard">
                                <InputLabel htmlFor="staff_name_format">Staff Name Format</InputLabel>
                                <Select
                                     value= {(typeof props.ReportFields.staff_name_format !== 'undefined') ? props.ReportFields.staff_name_format : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_name_format',
                                        id: 'staff_name_format',
                                    }}>
                                    {  typeof props.ReportInputs.name_format != 'undefined' &&  props.ReportInputs.name_format !== '' && props.ReportInputs.name_format.map((t, i) =>
                                        <MenuItem value={t.key}>{t.value }</MenuItem>
                                    )} 
                                </Select>
                                <FormHelperText >{props.Errors.staff_name_format}</FormHelperText>
                         </ FormControl>
                        </Grid>
                      
                         <Grid item  md={2}>
                            <FormControl error={props.Errors.report_type && true} variant="standard">
                                <InputLabel htmlFor="report_type">Report Type</InputLabel>
                                <Select
                                value= {(typeof props.ReportFields.report_type !== 'undefined') ? props.ReportFields.report_type : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'report_type',
                                        id: 'report_type',
                                    }}>
                                    <MenuItem value='PROJECTCODE'>By Project Code</MenuItem>
                                    <MenuItem value='RESOURCE'>By Resource</MenuItem>
                                </Select>
                                <FormHelperText >{props.Errors.report_type}</FormHelperText>
                            </ FormControl>
                        </Grid>

                         <Grid item  md={2}>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked = {(typeof props.ReportFields.include_hours !== 'undefined') ? props.ReportFields.include_hours : false}  
                                                value = {(typeof props.ReportFields.include_hours !== 'undefined') ? props.ReportFields.include_hours : null}
                                                id = "include_hours" 
                                                name = "include_hours"
                                                onChange = {props.HandleOnChange}
                                                color="primary"
                                            />
                                        }
                                        label="Include 0 hours"
                                    />
                                     </ FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} className={'d-flex'}>
                        </Grid>
                        <Grid item xs={12} md={8} className={'d-flex'}>
                        </Grid>
                        <Grid item xs={12} md={2} className={'d-flex justify-end pr-2'}>        
                            <Button variant="contained" size="small" color="primary" className={'mr-1'} onClick={ props.OnSubmitClick('search') }>Submit</Button>
                            <span className={'MuiButton-startIcon MuiButton-iconSizeSmall'}>   </span>
                            <Button size = "small" color = "primary"  onClick={props.ExportReports}   variant = "contained" >Export</Button>
                        </Grid>
                    </Grid>
                </form>
                </div>
            </Paper>

             <ReportList className={'ReportList  justify-between align-stretch'}>
               <Paper className={'root'}>
               <TableContainer className={'container'}>
                    <>
                    {   (props.ReportTable && Object.keys(props.ReportTable.rows).length>0)?
                    <Grid container>
                        <Table  stickyHeader aria-label="Report List" className={'list-table_basic-year'}>
                            <TableHead className={'thead'}>
                                <TableRow>
                                 { Object.keys(headers).map((key) =>
                                    <TableCell>{headers[key]}</TableCell>
                                 )}
                                </TableRow>
                            </TableHead>
                           <TableBody>
                                  {  Object.keys(ros).map((key) =>
                            (ros[key]['type'] === "client" || ros[key][3] === "Total") ?
                          <TableRow>
                                { Object.keys(ros[key]).map((key1,i) =>
                                 (i === parseInt(key1)) ?
                           <TableCell className = {'color'}><b>
                                 {ros[key][key1]}</b>
                          </TableCell>
                                 :
                             <TableCell></TableCell>

                           ) }
                              </TableRow>
                                 :
                            <TableRow>
                                { Object.keys(ros[key]).map((key1,i) =>
                                 (i === parseInt(key1)) ?
                                (i === cols_count) ?

                           <TableCell><b>
                                 {ros[key][key1]}</b>
                          </TableCell>
                                :
                            <TableCell>
                                 {ros[key][key1]}
                          </TableCell>
                                 :
                             <TableCell></TableCell>

                           ) }
                              </TableRow>


                                 ) }
                    

                            </TableBody>
                        </Table>
                    </Grid>
                    :<></>
                    }
                    </>
                    <>
                    {   (props.ReportTable.rows && Object.keys(props.ReportTable.rows).length === 0)?
                        <>
                        <Grid container style={gridStyle}>
                            <Table size="small" aria-label="Report List" className={'list-table_basic'}>
                                <TableRow>
                                    <TableCell colspan="6"></TableCell>
                                    <TableCell>No search result</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        </>
                        :<></>
                    }
                    </>  
                </TableContainer>
                </Paper>
            </ReportList>

            </React.Fragment>


            )
    
}

