import React from 'react';
import Grid from '@mui/material/Grid';
import {CustomMaterialTable} from '../../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { AccountEdit} from './AccountEdit.Component';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoopIcon from '@mui/icons-material/Loop';
export const AccountsComponent = (props) => {
    return (        
        <>
	        <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title="User Accounts"
                        columns={[
                            {title: 'User Name',field: 'account_username'},
                            {title: 'Auth Type',field: 'display_name'},
                            {title: 'Status',field: 'status_name'},
                        ]}
                        actions={[
                            {
                            icon: 'add',
                            tooltip: 'Add Account',
                            isFreeAction:true,
                            onClick: (event, newData) => {props.HandleClickOpen()},
                            hidden : props.EditPermission ?  (props.UserStatus == '1' ? false : true) : true,
                        },
                        rowData => ({
                            icon: Edit,
                            tooltip: 'Edit',
                            onClick: (event, rowData) => { props.HandleClickOpen(rowData.account_id)},
                            hidden : props.EditPermission ? false:true,
                        }),
                        rowData => ({
                            icon: DeleteOutline, 
                            tooltip: 'Delete', 
                            onClick: (event, rowData) => { props.OnDelete(rowData.account_id)},
                            hidden : props.EditPermission ? false:true,
                        }),
                        rowData => ({
                            icon: 'loop',
                            tooltip: 'Reset Password',
                            onClick: (event, rowData) => {props.HandleClickReset(rowData.account_id)},
                            hidden : props.EditPermission ? (rowData.account_provider == '3' ? false : true) : true,
                        }),
                        ]}
                        data={props.Accounts}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	        </div>
            { props.OpenPassword &&
            <DialogBoxMedium
                Open = {props.OpenPassword}
                Cancel ={props.OnClose}
                ButtonName ='Reset'
                OnSubmit = {props.onResetSubmit}
                Title = {'Reset Password'}
                Content = { <DialogResetPassword UserName = {props.FormData.account_username} FormData = {props.FormData} Errors = {props.Errors} HandleOnChange = {props.HandleOnChange}/>    }
            />
            }
            { props.Open &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={props.isDelete ?  'Delete Account' : (props.isEditable ? 'Edit Account' : 'Add Account')}
                OnSubmit={props.isDelete ? props.onDeleteSubmit : props.onSubmit}
                ButtonName={props.isDelete ? 'Ok' : 'Save'}
                ErrorMessage={props.errorMessage}
                Loading = {props.ButtonLoader}
                Content={
                    props.isDelete ?
                    'Do you want to delete this account?'
                    :
                    <AccountEdit 
                        OnSubmit={props.onSubmit}
                        FormInputs={props.FormInputs} 
                        HandleOnChange={props.HandleOnChange} 
                        FormData={props.FormData}
                        Errors = {props.Errors}
                        HandleClickReset = {props. HandleClickReset}
                        OnChangePassword = {props.OnChangePassword}
                        OnResetSubmit = {props. OnResetSubmit}
                        isEditable = {props.isEditable}
                    />
            }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }


        </>
    )
};
const DialogResetPassword = (props) => {
    return (
        <div class = "chp-form">
        {
            <>
            <form className = "form-fullwidth" onSubmit={props.onResetSubmit}>
            <Grid container spacing = {1}>
                    <Grid item xs = {9}>
                         <Typography> Username :  {props.UserName} </Typography>
                    </Grid>
                    <Grid item xs = {12}>
                        <FormControl>
                            <TextField variant="standard"
                                id = "new_pswd"
                                name = "new_pswd"
                                label = "New Password"
                                type = "password"
                                required
                                value = {props.FormData.new_pswd}
                                onChange = {props.HandleOnChange}
                                error = {props.Errors.new_pswd && true}
                                helperText = {props.Errors.new_pswd}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs = {12}>
                        <FormControl>
                            <TextField variant="standard"
                                id = "confm_pswd"
                                name = "confm_pswd"
                                label = "Confirm Password"
                                type = "password"
                                required
                                value = {props.FormData.confm_pswd}
                                onChange = {props.HandleOnChange}
                                error = {props.Errors.confm_pswd && true}
                                helperText = {props.Errors.confm_pswd}
                            />
                        </FormControl>
                    </Grid>
            </Grid>
            </form>
            </>
        }
        </div>
    )
}
