import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _R from 'ramda';
import * as _ts from '../../common/treeSelect';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {DialogBoxMedium} from '../../common/dialog.medium';
import {DialogBoxLarge} from '../../common/dialog.large';
import { styled } from '@mui/material/styles';
import { TimeSliceView } from '../../timesheet/Common/TimeSliceView';
import { TimeSliceReviewNotes } from '../../timesheet/Common/TimeSliceReviewNotes';
import { TimeEntryEdit } from '../../timesheet/timeEntry/TimeEntryEdit';
import { CustomizedSnackbars } from '../../common/snackbars';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Badge from '@mui/material/Badge';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import FormHelperText from '@mui/material/FormHelperText';
import Edit from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const API_URL = process.env.REACT_APP_API_URL;
const BackDrop = styled(Backdrop) (({ theme }) => ({
   '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const tableIcons = {
  Edit: Edit,
  View: VisibilityIcon,
  Review : AssignmentOutlinedIcon,
};
function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            className="tab-panel"
            hidden={value !== index}
            {...other}
        >
            {children}
        </Typography>
    );
}
export const CustomReportComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [saveAnchor, setSaveAnchor] = React.useState(null);
    let tabIndex = props.TabIndex;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveClick = (event) => {
        setSaveAnchor(event.currentTarget);
    };
    const handleExportClose = (btn) => {
        if (btn === 'excel' || btn === 'csv') {
            props.ExportReports(btn);   
        }
        setAnchorEl(null);
    };
    const handleSaveClose = (action) => {
        if (action === 'saveas') {
            props.HandlesSaveFilterClick(action);
        }
        else
        {
            if (action === 'save') {
              props.HandleUpdateFilterClick(action);
            }
        }
        setSaveAnchor(null);
    };
    const columns = [
        { title: 'Date', field: 'timeslice_date',customSort:(a,b) => sort(new Date(a.timeslice_date),new Date(b.timeslice_date))},
        { title: 'Staff', field: 'full_name'},
        { title: 'Staff Type', field: 'staff_type',hidden: true},
        { title: 'Project', field: 'project_name' },
        { title: 'Task', field: 'task_name'},
        { title: 'Charge Code', field: 'chargecode'},
        { title: 'Description', field: 'timeslice_log'},
        { title: 'Hours', field: 'timeslice_hours'},
        { title: 'Status', field: 'timesheet_status_name', hidden: true},
        { title: 'Last Modified', field: 'timeslice_mdate',customSort:(a,b) => sort(new Date(a.timeslice_mdate),new Date(b.timeslice_mdate))},
    ];
    let dataArr = [];
    if (typeof props.ReportData !== 'undefined' && props.ReportData.length > 0) {
        dataArr = Object.values(props.ReportData);
    }
     const gridStyle = {
        margin: '15px 0px'
    };
    const input = props.formInputs;
    const values = props.ReportFields;
    let clients = (_R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.client_list) !== 'Undefined') ? props.formInputs.client_list : [];
    if (clients.length) {
        _ts.findClientKey(clients, ((_R.type(props.ReportFields) !== 'Undefined') ? props.ReportFields.clients : []));
    }
    let projects = ( _R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.project_list) !== 'Undefined') ? props.formInputs.project_list : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.ReportFields) !== 'Undefined') ? props.ReportFields.projects : []));
    }
    let tasks = (_R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.task_list) !== 'Undefined') ? props.formInputs.task_list : [];
    if (tasks.length) {
        _ts.findTaskKey(tasks, ((_R.type(props.ReportFields) !== 'Undefined') ? props.ReportFields.tasks : []));
    }
    let staffs = (_R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.staff_list) !== 'Undefined') ? props.formInputs.staff_list : [];
    if (staffs.length) {
        _ts.findStaffKey(staffs, ((_R.type(props.ReportFields) !== 'Undefined' && props.ReportFields.staffs.length > 0) ? props.ReportFields.staffs : [0]));
    }
        return(
            <React.Fragment>
                <Paper>
                     <div className={'p1'}>
                    <Typography variant="h6" component="h6" className={'section-title'}>CUSTOM REPORT</Typography>
                    <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                        <Tabs
                            value={tabIndex}
                            onChange={props.HandleChange}
                            indicatorColor="primary"
                            aria-label="Location lists Tabs"
                        >
                            <Tab label="Search" onClick={()=>props.HandleTabChange(0)} />
                            <Tab label="Saved Search" onClick={() =>props.HandleTabChange(1)} />
                        </Tabs>
                    </div>
                    <TabPanel value={tabIndex} index = {0}>
                            <form id="export-form" className="form-fullwidth" action={API_URL + 'api/report/search'} method="post">
                                <Grid container spacing={2} style={gridStyle}>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl>
                                            <TextField variant="standard"
                                                value={props.ReportFields.date_from}
                                                id="date_from"
                                                name="date_from"
                                                label="From Date"
                                                type="date"
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.HandleOnChange}
                                                error={props.Errors.date_from && true}
                                                helperText={props.Errors.date_from}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl>
                                            <TextField variant="standard"
                                                value={props.ReportFields.date_to}
                                                id="date_to"
                                                name="date_to"
                                                label="To Date"
                                                type="date"
                                                required
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={props.HandleOnChange}
                                                error={props.Errors.date_to && true}
                                                helperText={props.Errors.date_to}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="emp_category">Employee Pay Type</InputLabel>
                                            <Select
                                                value={(typeof props.ReportFields.emp_category !== 'undefined') ? props.ReportFields.emp_category : 0}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'emp_category',
                                                    id: 'emp_category',
                                                }}>
                                                <MenuItem value='0'>-All-</MenuItem>
                                                {typeof props.ReportFields.emp_categories != 'undefined' && props.ReportFields.emp_categories !== '' && props.ReportFields.emp_categories.map((t, i) =>
                                                    <MenuItem value={t.category} key={'stst-' + i}>{t.category}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="pay_type">Project Pay Type</InputLabel>
                                            <Select
                                                value={(typeof props.ReportFields.pay_type !== 'undefined') ? props.ReportFields.pay_type : 0}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'pay_type',
                                                    id: 'pay_type',
                                                }}>
                                                <MenuItem value='0'>-All-</MenuItem>
                                                {typeof props.ReportFields.pay_types != 'undefined' && props.ReportFields.pay_types !== '' && props.ReportFields.pay_types.map((t, i) =>
                                                    <MenuItem value={t.id} key={'stst-' + i}>{t.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="location">Location</InputLabel>
                                            <Select
                                                value={(typeof props.ReportFields.location !== 'undefined') ? props.ReportFields.location : 0}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'location',
                                                    id: 'location',
                                                }}>
                                                <MenuItem value='0'>-All-</MenuItem>
                                                {typeof props.ReportFields.locations != 'undefined' && props.ReportFields.locations !== '' && props.ReportFields.locations.map((t, i) =>
                                                    <MenuItem value={t.id} key={'stst-' + i}>{t.location}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} className='justify-between'>
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="status">Timesheet Status</InputLabel>
                                            <Select
                                                value={(typeof props.ReportFields.status !== 'undefined') ? props.ReportFields.status : 0}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'status',
                                                    id: 'status',
                                                }}>
                                                <MenuItem value='0'>-All-</MenuItem>
                                                {typeof props.ReportFields.statuses != 'undefined' && props.ReportFields.statuses !== '' && props.ReportFields.statuses.map((t, i) =>
                                                    <MenuItem value={t.timesheet_status_id} key={'stst-' + i}>{t.timesheet_status_name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={gridStyle}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <div class="filter-column">
                                            <div class="filter-column-title">Staff</div>
                                            <div class="filter-column-content">
                                                <Grid>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="user_groups">User Groups</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.user_groups !== 'undefined') ? props.ReportFields.user_groups : 0}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'user_groups',
                                                                id: 'user_groups',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.groups != 'undefined' && props.ReportFields.groups !== '' && props.ReportFields.groups.map((t, i) =>
                                                                <MenuItem value={t.id} key={'stst-' + i}>{t.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <FormControl error={props.Errors.user_status && true} variant="standard">
                                                        <InputLabel htmlFor="user_status">Staff Status</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.user_status !== 'undefined') ? props.ReportFields.user_status : 1}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'user_status',
                                                                id: 'user_status',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.user_statuses != 'undefined' && props.ReportFields.user_statuses !== '' && props.ReportFields.user_statuses.map((t, i) =>
                                                                <MenuItem value={t.user_status_id} key={'stst-' + i}>{t.user_status_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <FormHelperText >{props.Errors.user_status}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <input type='hidden' name='staff_type' value={(typeof props.ReportFields.staff_type_ids !== 'undefined' && props.ReportFields.staff_type_ids) ? props.ReportFields.staff_type_ids : []} />
                                                    <Autocomplete
                                                        value={(typeof props.ReportFields.staff_type !== 'undefined' && props.ReportFields.staff_type) ? props.ReportFields.staff_type : [props.ReportFields.staff_types[0]]}
                                                        onChange={props.HandleStaffTypeChange}
                                                        multiple
                                                        id="tags-outlined"
                                                        options={(typeof props.ReportFields.staff_types !== 'undefined') ? props.ReportFields.staff_types : []}
                                                        getOptionLabel={(option) => option.type}
                                                        filterSelectedOptions
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Staff Type"
                                                                placeholder="Select Staff Type"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <input type='hidden' name='staffs' value={(typeof values.staffs !== 'undefined') ? values.staffs : null} />
                                                    <FormControl>
                                                        <TreeSelectComponent
                                                            data={typeof input.staff_list != 'undefined' && input.staff_list ? input.staff_list : []}
                                                            placeholderText={"Staff Name"}
                                                            type='staffs'
                                                            HandleTreeChange={props.HandleTreeChange}
                                                        />
                                                    </ FormControl>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                                        <div class="filter-column">
                                            <div class="filter-column-title">Client</div>
                                            <div class="filter-column-content">
                                                <Grid className='justify-between'>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="client_status">Client Status</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.client_status !== 'undefined') ? props.ReportFields.client_status : 1}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'client_status',
                                                                id: 'client_status',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.client_statuses != 'undefined' && props.ReportFields.client_statuses !== '' && props.ReportFields.client_statuses.map((t, i) =>
                                                                <MenuItem value={t.client_status_id} key={'stst-' + i}>{t.client_status_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <input type='hidden' name='clients' value={(typeof values.clients !== 'undefined') ? values.clients : null} />
                                                    <FormControl>
                                                        <TreeSelectComponent
                                                            data={typeof input.client_list != 'undefined' && input.client_list ? input.client_list : []}
                                                            placeholderText={"Search Clients"}
                                                            type='clients'
                                                            HandleTreeChange={props.HandleTreeChange}
                                                        />
                                                    </ FormControl>
                                                </Grid>
		                                 <Grid>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="labels">Label Types</InputLabel>
                                                        <Select
							    value={(typeof props.ReportFields.label_type !== 'undefined') ? props.ReportFields.label_type:'-1'}
							    onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'label_type',
                                                                id: 'label_type',
                                                            }}>
		                                            <MenuItem value='-1'> -Select- </MenuItem>
                                                            <MenuItem value='project_label'> Project Label </MenuItem>
                                                            <MenuItem value='task_label'> Task Label </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
						<Grid>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="labels">Labels</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.selected_label !== 'undefined') ? props.ReportFields.selected_label : 0}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'selected_label',
                                                                id: 'selected_label',
                                                            }}>
                                                            <MenuItem value='0'>-Select-</MenuItem>
                                                            {typeof props.ReportFields.labels != 'undefined' && props.ReportFields.labels !== '' && props.ReportFields.labels.map((t, i) =>
                                                                <MenuItem value={t.id} key={'label-' + i}>{t.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                                        <div class="filter-column">
                                            <div class="filter-column-title">Project and Task</div>
                                            <div class="filter-column-content">
                                                <Grid>
                                                    <FormControl error={props.Errors.project_status && true} variant="standard">
                                                        <InputLabel htmlFor="project_status">Project Status</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.project_status !== 'undefined') ? props.ReportFields.project_status : 1}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'project_status',
                                                                id: 'project_status',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.project_statuses != 'undefined' && props.ReportFields.project_statuses !== '' && props.ReportFields.project_statuses.map((t, i) =>
                                                                <MenuItem value={t.project_status_id} key={'stst-' + i}>{t.project_status_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <FormHelperText >{props.Errors.project_status}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <FormControl>
                                                        <input type='hidden' name='projects' value={props.ReportFields.projects} />
                                                        <TreeSelectComponent
                                                            data={projects}
                                                            placeholderText={"Search Projects"}
                                                            type='project'
                                                            HandleTreeChange={props.HandleTreeChange}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="task_status">Task Status</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.task_status !== 'undefined') ? props.ReportFields.task_status : 0}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'task_status',
                                                                id: 'task_status',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.task_statuses != 'undefined' && props.ReportFields.task_statuses !== '' && props.ReportFields.task_statuses.map((t, i) =>
                                                                <MenuItem value={t.task_status_id} key={'stst-' + i}>{t.task_status_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid>
                                                    <input type='hidden' name='tasks' value={(typeof values.tasks !== 'undefined') ? values.tasks : null} />
                                                    <FormControl>
                                                        <TreeSelectComponent
                                                            data={typeof input.task_list != 'undefined' && input.task_list ? input.task_list : []}
                                                            placeholderText={"Search Tasks"}
                                                            type='tasks'
                                                            HandleTreeChange={props.HandleTreeChange}
                                                        />
                                                    </ FormControl>
                                                </Grid>
                                                <Grid>
                                                    <FormControl variant="standard">
                                                        <InputLabel htmlFor="type">Task Type</InputLabel>
                                                        <Select
                                                            value={(typeof props.ReportFields.type !== 'undefined') ? props.ReportFields.type : 0}
                                                            onChange={props.HandleOnChange}
                                                            inputProps={{
                                                                name: 'type',
                                                                id: 'type',
                                                            }}>
                                                            <MenuItem value='0'>-All-</MenuItem>
                                                            {typeof props.ReportFields.job_types != 'undefined' && props.ReportFields.job_types !== '' && props.ReportFields.job_types.map((t, i) =>
                                                                <MenuItem value={t.id} key={'stst-' + i}>{t.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} className='justify-between'>
                                        <FormControl>
                                            <TextField
                                                id="timeslice_log"
                                                name="timeslice_log"
                                                label="Search In Description"
                                                multiline={true}
                                                variant="outlined"
                                                type="text"
                                                value={(typeof props.ReportFields.timeslice_log !== 'undefined') ? props.ReportFields.timeslice_log : ''}
                                                onChange={props.HandleOnChange}
                                            />
                                        </ FormControl>
                                    </Grid>
                                    <Grid item >
                                        <div className={'d-flex align-center justify-end'}>
                                            <Button size="small" color="primary" variant="contained" onClick={props.FormSubmit}>Search</Button>&nbsp;&nbsp;&nbsp;

                                            <div class={'ml-1'}>
                                                <input type='hidden' id='btn_type' name='btn_type' value={props.ReportFields.btn_type} />
                                                <Button aria-controls="simple-menu" aria-haspopup="true" className={'mr-1'} variant="contained" size="small" color="primary" onClick={handleClick} >Export</Button>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleExportClose}
                                                >
                                                    <MenuItem onClick={() => { handleExportClose('csv') }} > CSV </MenuItem>
                                                    <MenuItem onClick={() => { handleExportClose('excel') }}> Excel </MenuItem>
                                                </Menu>
                                            </div>                                           
                                            <div className={'ml-1'}>
                                                {(props.SavedSearchClickId) ?
                                                       <>
                                                        <Button aria-controls="simple-menu" aria-haspopup="true" className={'mr-1'} variant="contained" size="small" color="primary" onClick={handleSaveClick} disabled={!props.FieldChange ? true : false} >Save</Button>
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={saveAnchor}
                                                            keepMounted
                                                            open={Boolean(saveAnchor)}
                                                            onClose={handleSaveClose}
                                                        >
                                                            {props.SavedSearchCreatedBy == props.LoggedUser &&
                                                                <MenuItem onClick={() => { handleSaveClose('save') }} > Save </MenuItem>
                                                            }
                                                            <MenuItem onClick={() => { handleSaveClose('saveas') }} > Save as </MenuItem>
                                                        </Menu>
                                                        </>
                                                       
                                                    :
                                                    <Button aria-controls="simple-menu" aria-haspopup="true" className={'mr-1'} variant="contained" size="small" color="primary" onClick={() => { handleSaveClose('save') }}>Save</Button>
                                                }
                                            </div>
                                            <div className={'ml-1'}>
                                                <Button size="small" color="primary" variant="outlined" onClick={props.Reset}>Reset</Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                            <CustomMaterialTable
                                title=""
                                columns={[
                                    { title: 'Title', field: 'title', render: rowData => ( (rowData.public) ? <>{rowData.title} <span className='tasktype-badge wh'>public</span></> : <>{rowData.title}</>)},
                                    { title: 'Description', field: 'description'},
                                    { title: 'Owner', field: 'cby_fullname' },
                                ]}
                                data={props.SavedFilters}
                                options={{
                                    actionsColumnIndex: -1,
                                    paging: false,
                                    search: true,
                                }}
                                actions={[
                                    {
                                        icon: 'search',
                                        tooltip: 'Search',
                                        onClick: (event, rowData) => {
                                            props.HandleSearchClick(rowData.id, rowData.created_by);
                                        },
                                    },
                                    rowData => ({
                                        icon: 'share',
                                        tooltip: 'Share',
                                        onClick: (event, rowData) => {
                                            props.HandleShareClick(rowData.id);
                                        },                                        
                                        hidden :  (rowData.public == 1 || rowData.created_by != props.LoggedUser) ? true : false
                                    }),
                                    rowData => ({
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => {
                                            props.EditClick(rowData.id);
                                        },
                                        hidden :  (rowData.created_by != props.LoggedUser) ? true : false
                                    }),
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => {
                                            props.HandleDeleteClick(rowData.id);
                                        },
                                        hidden :  (rowData.created_by != props.LoggedUser) ? true : false
                                    }),
                                ]}
                            />
                    </TabPanel>
                    </div>
            </Paper>
            { tabIndex == 0 && 
              <Paper className={'mb-1'}>
               {(props.isSearch) &&
               <CustomMaterialTable
                    title= ""
                    icons={tableIcons}
                    columns={columns}
                    data={dataArr}
                    actions={[
                        {icon: tableIcons.View, tooltip: 'Details',onClick: (event, rowData) => {props.HandleViewDetailsClick(rowData.timeslice_id)}},
                         rowData => ({icon: () =>  parseInt(rowData.notes) ? <Badge color="secondary" variant="dot" className={'action_icon visible'}><AssignmentOutlinedIcon/></Badge> : <AssignmentOutlinedIcon/>, tooltip: 'Review Note',onClick: (event, rowData) => {props.HandleViewReviewNotes(rowData.timeslice_id,"review_button")}}),

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        columnsButton:true,
                        toolbar:true,
                        exportButton: false,
                        pageSize:10,
                        pageSizeOptions: [10,20,30],
                        thirdSortClick: false,
                        search: true,
                    }}
                     components={{
                     Toolbar: (mprops) => (
                       <div className={'mTable-toolbar-custom'}>
                       <CustomMTableToolbar {...mprops} />
                       <div className={'timeSheet-list_header d-flex justify-between align-stretch'}>
                                <div className={'col-main d-flex justify-between align-stretch d-felx_grow1'}></div>
                                <div className={'col-action col-summary'}>
                                    <div className={'col-time'}>
                                        {props.TotalHours}
                                        <span className={'info'}>Hours Total</span>
                                    </div>
                                </div>
                            </div>                     
                     </div>
                    )
                   }}
                   />
               }
              </Paper>
            }
       
       {props.PopupType === 'details' &&
                <DialogBoxLarge
                    Open= { props.Open }
                    Cancel = { props.OnClose }
                    HideSaveButton = {true}
                    CancelLabel = {"Close"}
                    Title = {'Time entry - Details'}
                    Content = { <DialogContent FormProps = { props }/> }
                />
        }
        {props.PopupType === 'review' &&
                <DialogBoxMedium
                    Open= { props.Open }
                    HideSaveButton = {true}
                    Cancel = { props.OnClose }
                    Title = {'Review Notes'}
                    Content = { <DialogReview FormProps = { props }/> }
                />
        }
        {props.PopupType === 'save_filters' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={props.EditId ? 'Edit Search' : 'Save Search'}
                OnSubmit={props.SaveFilter}
                Content={<SaveFilterForm FormProps={props} />}
            />
        }
         {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.OnClose}
                OnSubmit = {props.DeleteFilter}
                Title = 'Confirm Delete'
                ButtonName = 'Ok'
                Content = {
                    <DialogWithConfirmMessage msg="Do you want to delete this Search ?" />
                }
            />
        }
        {props.PopupType === 'share_filter' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                Title={'Share Search - ' + props.SharedSearchName}
                OnSubmit={props.ShareFilter}
                Content={<ShareFilterForm FormProps={props} />}
            />
        }
        <TimeEntryEdit
            timeSliceId={props.ClickedTimeSliceId}
            Open= {props.TimeSliceEditDialogOpen}
            Cancel = {props.TimeSliceEditDialogClose}
            timeSheetId = {props.timeSheetId}
            getTimesheetDetails = {props.GetSavedTimeSlices}
            showEditSuccess = {props.ShowEditSuccess}
            randomNumber = {props.randomNumber}
            UpdateTimeEntryOnEdit = {props.FormSubmit}
        />
        <CustomizedSnackbars
            open={props.snackbarOpen}
            variant='success'
            message={props.Message}
            handleClose={props.SnackbarClose}
        />
         <BackDrop className={'backdrop'} open={props.loaderOpen}>
           <CircularProgress color="inherit" />
         </BackDrop>
    </React.Fragment>
        )
}
const DialogReview = (props) => {
    return (
        <TimeSliceReviewNotes Comments = {_R.type(props.FormProps.ReviewNotes) !== 'Undefined' ? props.FormProps.ReviewNotes : []} Button = {props.FormProps.Button}/>
    )
}
const DialogContent = (props) => {
    return (
        <TimeSliceView id = {_R.type(props.FormProps.ClickedTimeSliceId) !== 'Undefined' ? props.FormProps.ClickedTimeSliceId : 0} />
       
    )
}
const SaveFilterForm = (props) => {
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="title"
                                name="title"
                                label="Title"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                value={props.FormProps.ReportFields.title}
                                onChange={props.FormProps.HandleOnChange}
                                error={( typeof props.FormProps.ErrorMessage !== 'undefined') && props.FormProps.ErrorMessage !== ""}
                                helperText={props.FormProps.ErrorMessage}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField
                                id="note"
                                name="description"
                                label="Description"
                                type="text"
                                multiline={true}
                                variant="outlined"
                                value={props.FormProps.ReportFields.description}
                                onChange={props.FormProps.HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(props.FormProps.ReportFields && typeof props.FormProps.ReportFields.public !== 'undefined') ? props.FormProps.ReportFields.public : false}
                                            value={ props.FormProps.ReportFields.public }
                                            inputProps={{
                                                id : "public",
                                                name : "public",
                                            }}
                                            onChange={props.FormProps.HandleOnChange}
                                            color="primary"
                                        />
                                    }
                                    label="Public"
                                />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}
const ShareFilterForm = (props) => {
    let values = props.FormProps.ReportFields;
    let input = props.FormProps.formInputs;    
    let users = (_R.type(input) !== 'Undefined' && _R.type(input.user_list) !== 'Undefined') ? input.user_list : [];
    if (users.length) {
        _ts.findStaffKey(users, ((_R.type(values) !== 'Undefined' && values.users.length > 0) ? values.users : [0]));
    }
    return (
        <React.Fragment>
            <form>
                <Grid style={{height : '250px'}}>
                    <input type='hidden' name='users' value={(typeof values.users !== 'undefined') ? values.users : null} />
                        <FormControl>
                            <TreeSelectComponent
                                data={typeof input.user_list != 'undefined' && input.user_list ? input.user_list : []}
                                placeholderText={"User Name"}
                                type='users'
                                HandleTreeChange={props.FormProps.HandleTreeChange}
                            />
                        </ FormControl>
                </Grid>
            </form>
        </React.Fragment>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}





