import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormHelperText from '@mui/material/FormHelperText';

import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

export const PoliciesComponent = (props) => {
    const policies = typeof props.policies !== 'undefined' ? props.policies : [];
    return (
        <>
            <div className={'listSubHeader pr-2'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                {props.EditPermission &&
                <Tooltip title="Add Policy" aria-label="add">
                    <Button variant="contained" color="primary" size="small" onClick={props.AddPolicyClick}>Add</Button>
                </Tooltip>
                }
            </div>
            
            <TableContainer component={Paper}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Rule Frequency</TableCell>
                            <TableCell>Status</TableCell>
                            {props.EditPermission && 
                            <TableCell>Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(policies.length === 0) &&
                            <TableRow >
                                <TableCell>No policies found.</TableCell>
                            </TableRow>
                        }
                        {policies.map((item, index) =>
                        <TableRow hover>
                            <TableCell>{item.work_policy_name}</TableCell>
                            <TableCell>{item.frequency.toLowerCase()}</TableCell>
                            <TableCell>{(item.status == 1) ? 'Active' : 'Inactive'}</TableCell>
                            {props.EditPermission &&
                            <TableCell>
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Edit">
                                        <EditIcon fontSize="small" onClick={()=>props.EditClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                                {/*<Tooltip title="Delete" aria-label="Delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Delete">
                                        <DeleteOutlinedIcon fontSize="small" onClick={()=>props.HandleDeleteClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>*/}
                                <Tooltip title="View" aria-label="view">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Comments">
                                    <VisibilityIcon fontSize="small" onClick={()=>props.HandleViewClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            }
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.PopupType === 'add' || props.PopupType === 'edit') &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.PopupType === 'add' ? 'Add Policy' : 'Edit Policy' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogAdd FormProps = { props }/> }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                OnSubmit={props.DeletePolicy}
                Title='Confirm Delete' 
                ButtonName='Ok'                
                Content={
                    <DialogWithConfirmMessage msg="Do you want to delete this Policy" />
                }
            />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "policy"
                            name = "policy"
                            label = "Policy"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.policy : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.policy !== 'undefined' && Errors.policy)&& true }
                            helperText = { typeof Errors.policy !== 'undefined' && Errors.policy }
                        />
                        </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="status" required >Status</InputLabel>
                        <Select
                            value={parseInt(FormFields.status)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.frequency && true}>Rule Frequency</InputLabel>
                        <Select
                            value={FormFields.frequency}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'frequency',
                                id: 'frequency'
                            }}
                            className={'input-item'}
                        >
                            {typeof props.FormProps.FrequencyArray !== 'undefined' && props.FormProps.FrequencyArray.map((frequency) =>
                                <MenuItem value={frequency.value} >{frequency.name}</MenuItem>
                            )}
                        </Select>
                        {Errors.frequency && <FormHelperText className="error">{Errors.frequency}</FormHelperText>}
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
