import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { DialogBoxMedium } from '../../common/dialog.medium';
import Box from '@mui/material/Box';

const API_URL = process.env.REACT_APP_API_URL;
export const ListExpenseComponent = (props) => {
    return (
        <React.Fragment>
            <div className={'expense-margin-top'}>
                <Paper>
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Typography component="h2" className={'section-title mt-2 expense-list-head'} variant="button" display="block" gutterBottom color="primary">
                                Saved Expenses
                            </Typography>
                        </div>
                    </div>
                    <div className={'tab-panelContainer'}>
                    {props.expenseFrom && <Grid container justify="center"><h3>{props.expenseFrom + ' To ' + props.expenseTo}</h3></Grid>}
                        <Table aria-label="simple table" className={'list-table_basic steps-table'} size="small">
                            <TableHead className={'thead'}>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Expense Type</TableCell>
                                    <TableCell>Expense Category</TableCell>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Expense Code</TableCell>
                                    <TableCell style={{width:"20%"}}>Description/Attachments</TableCell>
                                    <TableCell>Mileage</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody className={'tbody'}>
                                {props.reportId && props.expenseData && props.expenseData.map(({ id, date, type, category_name, project_name, exp_code_name, description, mileage, amount, attachments, exp_project_name, exp_project_code, exp_code }, i) =>
                                    <TableRow>
                                        <TableCell>{date}</TableCell>
                                        <TableCell>{type}</TableCell>
                                        <TableCell>{category_name}</TableCell>
                                        <TableCell>{(project_name) ? project_name : ((exp_project_code && exp_project_name)?(exp_project_code + ': ' + exp_project_name): '')}</TableCell>
                                        <TableCell>{(exp_code && exp_code_name)?(exp_code + ': ' + exp_code_name):((exp_code)?exp_code:'')}</TableCell>
                                        <TableCell>{description} <div>
                                        {
                                        attachments.map(attachment =>
                                        <span  className={"btn-attachment"} >
                                            {attachment.attachment_name.length>10 ? attachment.attachment_name.substring(0, 3) + "..." + attachment.attachment_name.substr(attachment.attachment_name.length-5) : attachment.attachment_name }
                                            <a href={API_URL + 'api/expenses/downloadImage?id=' + attachment.file_id}><GetAppIcon fontSize="small" /></a>
                                        </span>
                                        )} </div>
                                        </TableCell>
                                        <TableCell>{(mileage && parseInt(mileage) > 0)?mileage:''}</TableCell>
                                        <TableCell>{props.currencySymbol+parseFloat(amount).toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Tooltip title="View" aria-label="view">
                                                <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                    <VisibilityIcon fontSize="small" onClick={() => props.handleViewClick(id)} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit" aria-label="edit">
                                                <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                    <EditIcon fontSize="small" onClick={() => props.handleEditClick(id)} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" aria-label="delete">
                                                <IconButton className={'action-btn'} edge="end" aria-label="Comments">
                                                    <DeleteIcon fontSize="small" onClick={() => props.DeleteExpense(id,date)} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )} 
                                {!props.reportId && <TableRow>
                                    <TableCell colSpan={10}>No Expense Items</TableCell>
                                </TableRow>}
                                {props.reportId && props.expenseData && 
                                    <TableRow>
                                        <TableCell colSpan={6}></TableCell>
                                        <TableCell><b>Total</b></TableCell>
                                        <TableCell colSpan={3}><b>{props.currencySymbol+(props.totalAmount && parseFloat(props.totalAmount).toFixed(2))}</b></TableCell>
                                    </TableRow>}
                                {(props.report.status && !props.editableStatus.includes(parseInt(props.report.status))) && 
                                   <TableRow>
                                   <TableCell colSpan={10} align={'center'}>
                                    {((props.uneditableStatus.includes(parseInt(props.report.status)) && parseInt(props.report.status) === 7) && <p>Expense for this Period is <b>{props.report.status_name}</b> <a href={'submit/' + props.reportId}>Click</a> here to View details</p> )}
                                    {((props.uneditableStatus.includes(parseInt(props.report.status)) && parseInt(props.report.status) !== 7 ) && <p>Expense for this Period is already <b>{props.report.status_name}</b> <a href={'submit/' + props.reportId}>Click</a> here to View details</p> )}
                                    {((!props.uneditableStatus.includes(parseInt(props.report.status))) && <p>Expense for this Period is already <b>{props.report.status_name}</b> <a href={'submit/' + props.reportId}>Click</a> here to Unsubmit </p>)}
                                   </TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
            </div>
            <DialogBoxMedium
                Open={props.ExpenseDeleteOpen}
                Cancel={props.CloseExpense}
                Title={'Confirm Delete'}
                ButtonName ={'Delete'}
                OnSubmit={props.handleDeleteClick}
                Content={<DialogDeleteExpense FormProps={props} />}
            />
        </React.Fragment>
    )
};
const DialogDeleteExpense = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you really want to delete this expense item?
                </Typography>
            </Box>
        </div>
    )
}

