import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { formatName } from '../../common/common';

export const Comment = (props) => {
    let exp = 'panel5';
    if (props.Expense === true) {
      exp = false;
    }
    const [expanded, setExpanded] = React.useState(exp);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            >
           {props.Expense === true ?
            <Typography variant="subtitle1" gutterBottom>Notes</Typography>
            : <Typography variant="subtitle1" gutterBottom>Comments</Typography>
           }
            </AccordionSummary>
            <AccordionDetails>
                {props.Comments.length > 0 ?
                    <ul className="list-standard list-ul-shrink">
                        {(props.Comments).map((item) =>
                            <>
                            <li className="list-item">
                                <Typography variant="body1" gutterBottom>{item.comment}</Typography>
                            </li>
                            <li className="list-item">
                                <Typography  variant="body2" display="block"><i>Posted by {formatName(item.cby_first_name,null,item.cby_last_name)} On {item.created_on}</i></Typography>
                            </li>
                            </>
                        )}
                    </ul>
                    :
                    props.Expense === true ?
                    <Typography variant="body2" gutterBottom align='center'>No notes for this expense</Typography>
                    : <Typography variant="body2" gutterBottom align='center'>No comments for this request</Typography>
                    
                }
            </AccordionDetails>
        </Accordion>
    );
}
