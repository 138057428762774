import React from 'react';
import { AnnouncementsComponent } from './Announcements.Component';
import { httpServices } from '../../services/httpServices';
import { ErrorPage } from '../common/error';
const BASE_PATH = process.env.REACT_APP_BASE_PATH;

const formFields = {
    'id': 0,
    'announcement_title': '',
    'message': '',
    'announcement_status': 1,
    'target_users': 1,
    'email_notification' : 0
}

const filterFields = {
    'start_year': '',
    'selected_year': 0,
    'end_year': '',
    'search_status': 0,
    'years': Array()
}

const viewPageFields = {
    'id': 0,
    'announcement_title': '',
    'message': '',
    'announcement_status': 1,
    'target_users': 1,
    'created_on': '',
    'email_notification' : 0
}

export class Announcements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formFields: formFields,
            filterFields: filterFields,
            hasPermission: true,
            allAnnouncements: [],
            dialogOpen: false,
            viewPageFields: viewPageFields,
            open: false,
            addDialogOpen: false,
            editPermision: false,
            snackOpen: false,
            snackVarient: '',
            snackMessage: '',
            right: false,
            showLoader: true,
            disableSubmit: false,
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnFilterChange = this.handleOnFilterChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.handleDialogCancel = this.handleDialogCancel.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.handleOnHtmlChange = this.handleOnHtmlChange.bind(this);
        this.handleAddDialogue = this.handleAddDialogue.bind(this);
        this.snakbarClose = this.snakbarClose.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }
    componentDidMount = () => {
        this.getAnnouncements();
    }
    getAnnouncements = () => {
        this.setState({
            showLoader: true
        });
        httpServices.get('api/system/announcement/list')
            .then(response => {
                if (response.data.permission) {
                    let data = response.data;
                    const s = this.state.filterFields;
                    s['start_year'] = data.start_year;
                    s['end_year'] = data.end_year;
                    s['years'] = data.years;
                    this.setState({
                        allAnnouncements: data.all_announcements,
                        filterFields: s,
                        editPermision: data.edit_prmision,
                        right: false,
                        showLoader: false,
                    });
                }
                else {
                    this.setState({
                        hasPermission: false,
                        showLoader: false
                    });
                }
            });
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        if (evt.target.type && evt.target.type === 'checkbox') {
            ff[field] = (evt.target.checked) ? 1 : 0;
        } else {
            ff[field] = evt.target.value;
        }
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({ errors: err });
        }
    }
    handleDialogCancel = () => {
        this.setState({
            dialogOpen: false,
        });
    }

    handleOnFilterChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.filterFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            filterFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({ errors: err });
        }
    }
    snakbarClose = (id) => {
        this.setState({
            snackOpen: false
        });
    }
    handleOnHtmlChange = (evt) => { 
        const ff = this.state.formFields;
        ff['id'] = this.state.formFields.id;
        ff['announcement_title'] = this.state.formFields.announcement_title;
        ff['announcement_status'] = this.state.formFields.announcement_status;
        ff['target_users'] = this.state.formFields.target_users;
        ff['email_notification'] = this.state.formFields.email_notification;
        ff['message'] = evt.editor.getData();
        this.setState({
            formFields: ff
        });
    }
    resetFilter = () => {
        const ff = this.state.filterFields;
        ff['selected_year'] = 0;
        ff['search_status'] = 0;
        this.setState({
            addDialogOpen: false,
            right: false,
            filterFields: ff,
            showLoader: false,
        });
        this.getAnnouncements();        
    }
    handleSubmit = (props) => {
        if (this.handleValidation()) {
            var data = this.state.formFields;
            this.setState({
                disableSubmit: true,
                showLoader: true
            });            
            let value = { 'id': data.id, 'title': data.announcement_title, 'message': data.message, 'status': data.announcement_status, 'user': data.target_users, 'email_notification' : data.email_notification}
            httpServices.post('api/system/announcement/create', value)
                .then(response => {
                    if (response.data.status) {
                        let snackVarient = 'success';
                        let snackMessage = '';
                        if (data.id > 0) {
                            snackMessage = 'Announcement edited successfully';
                        }
                        else {
                            snackMessage = 'Announcement added successfully';
                        }
                        const f = this.state.formFields;
                        f['id'] = 0;
                        f['announcement_title'] = '';
                        f['message'] = '';
                        f['announcement_status'] = 1;
                        f['target_users'] = 1;
                        f['email_notification'] = 0;
                        this.setState({
                            formFields: f,
                            addDialogOpen: false,
                            right: false,
                            snackOpen: true,
                            snackVarient: snackVarient,
                            snackMessage: snackMessage,
                            showLoader: false,
                            disableSubmit: false,
                            showLoader: false                            
                        });
                        this.getAnnouncements();
                    } else {
                        this.setState({
                            disableSubmit: false,
                            showLoader: false,
                            errors : typeof response.data.errors !== 'undefined' ? response.data.errors : {}, 
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        props.preventDefault();
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let msg = (this.state.formFields.message) ? this.state.formFields.message.trim() : ''; 
        msg = msg.replace(/&nbsp;/g, '').replace(/ /g,'');
        if (!this.state.formFields.announcement_title) {
            errors["announcement_title"] = "Title cannot be empty";
            formIsValid = false;
        } 
        if ( msg == '<p></p>' || msg == '<p><br></p>' || msg == '') { 
            errors["message"] = "Message cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.announcement_status) {
            errors["announcement_status"] = "Status cannot be empty";
            formIsValid = false;
        }

        if (!this.state.formFields.target_users) {
            errors["target_users"] = "Target users cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    onClose = () => {
        this.setState({
            open: false,

        });
    }
    handleAddDialogue = () => {
        const f = this.state.formFields;
        f['id'] = 0;
        f['announcement_title'] = '';
        f['message'] = '';
        f['announcement_status'] = 1;
        f['target_users'] = 1;
        f['email_notification'] = 0;
        const addDialogOpen = (this.state.addDialogOpen) ? false : true;
        this.setState({
            addDialogOpen: addDialogOpen,
            formFields: f,
            errors: {}
        });
    }

    cancelEdit = () => {
        const f = this.state.formFields;
        f['id'] = 0;
        f['announcement_title'] = '';
        f['message'] = '';
        f['announcement_status'] = 1;
        f['target_users'] = 1;
        f['email_notification'] = 0;
        this.setState({
            formFields: f,
            addDialogOpen: false,
        });
    }
    handleEditClick = (id, props) => {
        if (id) {
            let value = { 'id': id }
            return httpServices.post('api/system/announcement/getdetails', value)
                .then(response => {
                    const f = this.state.formFields;
                    f['id'] = response.data.id;
                    f['announcement_title'] = response.data.title;
                    f['message'] = response.data.message;
                    f['announcement_status'] = response.data.status;
                    f['target_users'] = response.data.target_users;
                    f['email_notification'] = 0;
                    this.setState({
                        formFields: f,
                        addDialogOpen: true,
                    });

                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });

        }
    }
    handleSearch = (props) => {
        var data = this.state.filterFields;
        let selected_year = data.selected_year;
        let selected_status = data.search_status; 
        let value = { selected_year: selected_year, selected_status: selected_status, submit_type: 'search' }        
        this.setState({
            showLoader: true,
        });    
        httpServices.post('api/system/announcement/list', value)
            .then(response => {
                let data = response.data;
                const s = this.state.filterFields;
                s['start_year'] = data.start_year;
                s['end_year'] = data.end_year;
                s['years'] = data.years;
                this.setState({
                    allAnnouncements: data.all_announcements,
                    filterFields: s,
                    right: false,
                    showLoader: false
                });
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        props.preventDefault();
    }

    handleViewClick = (id) => {
        if (id) {
            window.location.href = BASE_PATH+'/system/announcements/view/'+id;
        }
    }
    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }
    render() {
        return (
            (this.state.hasPermission)
                ?
                <AnnouncementsComponent
                    FormFields={this.state.formFields}
                    Errors={this.state.errors}
                    FilterFields={this.state.filterFields}
                    ViewPageFields={this.state.viewPageFields}
                    DialogOpen={this.state.dialogOpen}
                    AddDialogOpen={this.state.addDialogOpen}
                    Open={this.state.open}
                    AllAnnouncements={this.state.allAnnouncements}
                    EditPermision={this.state.editPermision}
                    SnackMessage={this.state.snackMessage}
                    SnackOpen={this.state.snackOpen}
                    SnackVarient={this.state.snackVarient}
                    Right={this.state.right}
                    ShowLoader={this.state.showLoader}
                    DisableSubmit={this.state.disableSubmit}
                    HandleOnChange={this.handleOnChange}
                    HandleOnFilterChange={this.handleOnFilterChange}
                    HandleDialogCancel={this.handleDialogCancel}
                    HandleSubmit={this.handleSubmit}
                    Close={this.onClose}
                    HandleEditClick={this.handleEditClick}
                    HandleSearch={this.handleSearch}
                    HandleViewClick={this.handleViewClick}
                    cancelEdit={this.cancelEdit}
                    HandleOnHtmlChange={this.handleOnHtmlChange}
                    HandleAddDialogue={this.handleAddDialogue}
                    SnakbarClose={this.snakbarClose}
                    ToggleDrawer={this.toggleDrawer}
                    ResetFilter={this.resetFilter}
                />
                : <ErrorPage />
        )
    }
}
