import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export const UserSettingsComponent = (props) => {
    let PInfo = props.PersonalInfo;
    let Profile = props.Profile;
    return (
        <>
        <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Email Settings</Typography>
        </div>
        <div className={'section-main'}>
         <div className={'section-content'}>
         <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width='30%'>Receive email for own actions</TableCell>
                                <TableCell>{parseInt(PInfo.email_own_action) === 0 ? "No" : "Yes"}</TableCell>
                                {props.EditPermission &&
                                <TableCell><Button variant="contained" size="small" onClick={()=>props.OnEmailSettingsEdit('email_own_action')}>Edit</Button> </TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>Receive secondary email notifications</TableCell>
                                <TableCell>{parseInt(PInfo.secondary_email_notification) === 0 ? "No" : "Yes"}</TableCell>
                                <TableCell></TableCell>                             
                            </TableRow>
                             <TableRow>
                                <TableCell>Email Notification</TableCell>
                                <TableCell>{parseInt(Profile.email_notification) === 1 ? "Yes" : "No"}</TableCell>
                                <TableCell></TableCell>                                
                            </TableRow>
                        </TableBody>
                    </Table>
                   </div>
                    </div>
        </>
    )
};


