import React from 'react';
import { history } from './../../helpers/history';
import { httpServices } from '../../services/httpServices';
import { SystemComponent } from './System.Component';
import { ErrorPage } from '../common/error';
import MixpanelManager from '../../services/Analytics';

export class System extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            systemcomponents: [],
            hasPermission: true,
        };
        this.assignLink = this.assignLink.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount = () => {
        this.getAllComponents();
        window.scrollTo(0, 0);
    };
    getAllComponents = () => {
        let apiEndpoint = 'api/system/get-system-config-components';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    if (response.data.menus && response.data.menus.length) {
                        this.setState({
                            systemcomponents: response.data.menus,
                        });
                    }
                }
                else {
                    this.setState({
                        hasPermission: response.data.permission,
                    });
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    assignLink = (link) => () => {
        if (link) {
            history.push(link);
            this.MixpanelManager.trackEvent('System', { 'module': 'System', 'feature': link, 'action': 'clicked' });
        }
    };

    render() {
        return (
            (this.state.hasPermission)
                ?
                <SystemComponent
                    assignLink={this.assignLink}
                    SystemComponents={this.state.systemcomponents}
                />
                :
                <ErrorPage />
        )
    }
}