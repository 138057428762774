import React from 'react';
import { getDate,endOfMonth } from 'date-fns';
import moment from 'moment';
import {PayperiodStatusReportComponent} from './PayperiodStatusReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { formatName } from '../../common/common';
import { history } from './../../../helpers/history';
import * as _ts from '../../common/treeSelect';
import { connect } from "react-redux";
import { setSavedFilters, updateSavedFilterAction } from '../../../redux/store/reducers/savedFilters';
import { getRelativeUrl } from '../../common/common';

const filterValues = {
    'start_date': null,
    'end_date': null,
    'year' : new Date().getFullYear(),
    'month': 0,
    'group': 0,
    'staffType': 0,
    'projectIds': [0],
    'onlySubordinates': false,
    'inactiveResources': false,
}
const formFields = {
    'year' : new Date().getFullYear(),
    'month': 0,
    'group' : 0,
    'staffType' : 0,
    'projectIds' : [0],
    'onlySubordinates' : false,
    'inactiveResources' : false,
}
let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects,result);
        }
    });
}
  class PayperiodStatusReportContainer extends React.Component{
    constructor(props) {
        super(props);
        let filter = filterValues;
        this.state = {
            startYear : null,
            groups : null,
            staffTypes : null,
            formFields : filter,
            permission : true,
            loader: true,
            resultSet : [],
            errors :{},
            months :[],
            post : false,
            isPayperiod : true,
            payPeriodType : '',
            displayExport : false,
            projects : [],
            permissionType : '',
            viewTimesheetDetails: false,
            viewProjects : false,
            isSupervisor : false,
            counter : 0
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.saveFilterToStore = this.saveFilterToStore.bind(this);
        this.filterSubmit = this.filterSubmit.bind(this);
    }
    
    componentDidMount(){
        let year = this.state.formFields.year;
        const f = this.state.formFields;
        httpServices.post('api/report/payperiodStatus', {'year' : year})
            .then(response => {
                if (response.data.prev_pay_period) {
                    f['start_date'] = response.data.prev_pay_period.sdate;
                    f['end_date'] = response.data.prev_pay_period.edate;
                }
                if(response.data.permission){
                    this.setState({
                        permission: response.data.permission,
                        groups: response.data.groups,
                        staffTypes: response.data.staff_types,
                        payPeriodType: response.data.pay_period_type,
                        loader: false,
                        formFields: f,
                        permissionType: response.data.permission_type,
                        viewProjects: response.data.view_project_list,
                        isSupervisor: response.data.is_supervisor,
                        viewTimesheetDetails: response.data.view_timesheet_details,
                    }, () => {
                        if (response.data.view_project_list) {
                            this.getProjects();
                        }
                    });
                    this.props.setSavedFilterVal();
                }
                else {
                    this.setState({ loader: false,
                                    permission : response.data.permission });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });       
    }

    componentDidUpdate = (prevProps) => { 
        if (prevProps.savedFilterValues.length > 0 && this.state.counter === 0) {
            this.onSubmitClick();
            this.setState({
                counter: 1
            });
        }
    }
    filterSubmit = async () => {
        if (this.handleValidation()) {
            await this.saveFilterToStore();
            this.onSubmitClick();
        }
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name  === 'onlySubordinates') {
            f[name] = evt.target.checked;
        }
        else if(name ==='inactiveResources') {
            f[name] = evt.target.checked;
        }
        else {
            f[name] = evt.target.value;
        }
        this.setState({
            formFields: f
        });
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        else {
            let dateOBJ = moment(this.state.formFields.start_date);
            let selectedDay = parseInt(dateOBJ.format('DD'));
            if (this.state.payPeriodType === "semi-monthly") {
                if (selectedDay !== 1 && selectedDay !== 16) {
                    errors['start_date'] = "Please select valid payperiod start date";
                    formIsValid = false;
                }
            }
            else {
                if (selectedDay !== 1) {
                    errors['start_date'] = "Please select valid payperiod start date";
                    formIsValid = false;
                }
            }
        }
        if (!this.state.formFields.end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        else {
            let dateOBJ = moment(this.state.formFields.end_date);
            let selectedDay = parseInt(dateOBJ.format('DD'));
            let endDateOfSelectedMonth = parseInt(dateOBJ.endOf('month').format('DD'));
            if (this.state.payPeriodType === "semi-monthly") {
                if (selectedDay !== 15 && selectedDay !== endDateOfSelectedMonth) {
                    errors['end_date'] = "Please select valid payperiod end date";
                    formIsValid = false;
                }
            }
            else {
                if (selectedDay !== endDateOfSelectedMonth) {
                    errors['end_date'] = "Please select valid payperiod end date";
                    formIsValid = false;
                }
            }
        }
        if (this.state.formFields.start_date > this.state.formFields.end_date) {
            errors['end_date'] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    handleStartDateChange = (date) => {
        let errors = this.state.errors;
        const f = this.state.formFields;
        const field = 'start_date';
        f[field] = date;
        let dateOBJ = moment(date);
        let selectedDay = parseInt(dateOBJ.format('DD'));
        errors['start_date'] = "";
        if (this.state.payPeriodType === "semi-monthly") {
            if (selectedDay !== 1 && selectedDay !== 16) {
                errors['start_date'] = "Please select valid payperiod start date";
            }
        }
        else {
            if (selectedDay !== 1) {
                errors['start_date'] = "Please select valid payperiod start date";
            }
        }
        this.setState({
            formFields: f,
            errors : errors,
            displayExport: false,
        },()=> {
            if (this.state.viewProjects) {
                this.getProjects();
            }
        });
    };
    handleEndDateChange = (date) => {
        let errors = this.state.errors;
        const f = this.state.formFields;
        const field = 'end_date';
        f[field] = date;
        let dateOBJ = moment(date);
        let selectedDay = parseInt(dateOBJ.format('DD'));
        errors['end_date'] = "";
        let endDateOfSelectedMonth = parseInt(dateOBJ.endOf('month').format('DD'));
        if (this.state.payPeriodType === "semi-monthly") {
            if (selectedDay !== 15 && selectedDay !== endDateOfSelectedMonth) {
                errors['end_date'] = "Please select valid payperiod end date";
            }
        }
        else {
            if (selectedDay !== endDateOfSelectedMonth) {
                errors['end_date'] = "Please select valid payperiod end date";
            }
        }
        this.setState({
            formFields: f,
            errors : errors,
            displayExport: false,
        },()=> {
            if (this.state.viewProjects) {
                this.getProjects();
            }
        });
    };
    formatReviewerName = (data) => {
          if (data) {
              data.forEach(addReviewerName);
              function addReviewerName(item) {
                  item['reviewer_name'] = formatName(item.rby_first_name, null, item.rby_last_name)
              }
          }
          return data;
    }
    saveFilterToStore = () => {
          let relativeUrl = getRelativeUrl();
          if (relativeUrl !== '' && this.state.formFields) {
              relativeUrl = relativeUrl;
              let obj = { relative_url: relativeUrl, filter_options: this.state.formFields }
              this.props.updateSavedFilterVal({ obj });
          }
    }
    onSubmitClick = () => {
        let filter = filterValues;
        let savedFilterValues = this.props.savedFilterValues;
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl;
            let indexValue = savedFilterValues.findIndex((element) => element.relative_url === relativeUrl);
            if (indexValue !== -1) {
                let savedFilter = savedFilterValues[indexValue]['filter_options'];
                filter = Object.assign(filter, savedFilter);
            }
        }
        let data = {
            'start_date': filter.start_date,
            'end_date': filter.end_date,
            'group': filter.group,
            'inactiveResources': filter.inactiveResources,
            'month': filter.month,
            'onlySubordinates': filter.onlySubordinates,
            'projectIds': filter.projectIds,
            'staffType': filter.staffType,
            'year': filter.year
        };
        httpServices.post('api/report/payperiodStatus', data)
                .then(response => {
                    if (response.data.errors) {
                        this.setState({
                            loader: false,
                            errors: response.data.errors,
                        });
                    }
                    else if (response.data.permission && !response.data.errors) {
                        let result = this.formatReviewerName(response.data.result);
                        this.setState({
                            isPayperiod: response.data.valid,
                            resultSet: result,
                            post: true,
                            loader: false,
                            displayExport: true,
                        });
                    }
                    else {
                        this.setState({ loader: false });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
    }

    handleViewClick=(id)=>{
         window.scrollTo(0, 0);
         history.push('payperiod/' + id);
    };
    getProjects = () => {
        let data = this.state.formFields;
        let supervisor = this.state.isSupervisor;
        httpServices.post('api/report/get-projects', { permission : this.state.permissionType, project_sdate : data.start_date, project_edate : data.end_date, list_subordinate_projects : supervisor ? 1 : 0})
            .then(response => {
                if(response.data){
                    let projects = _ts.prepareTreeData(response.data,'projects', true);
                    this.setState({projects : projects});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleTreeChange = (evt, cNode, sNodes) => {
        let f = this.state.formFields;
        let project_lists = this.state.projects;
        let projects = [];
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                projects.push(0);
            }
        }
         else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if(el.project_id) {
                    getProjectTree(project_lists, el.project_id, projects);
                }
            });
        }
        f['projectIds'] = projects;
        this.setState({formFields : f});
    }

    render(){
        return(
                (this.state.permission)?
                        <PayperiodStatusReportComponent
                            StartYear = {this.state.startYear}
                            Loader = {this.state.loader}
                            Payperiods = {this.state.payperiods}
                            Groups = {this.state.groups}
                            FormFields = {this.state.formFields}
                            StaffTypes = {this.state.staffTypes}
                            HandleOnChange = {this.handleOnChange}
                            OnSubmitClick = {this.onSubmitClick}
                            Errors = {this.state.errors}
                            ResultSet = {this.state.resultSet}
                            HandleViewClick = {this.handleViewClick}
                            OnDetailsClick = {this.onDetailsClick}
                            HandleStartDateChange = {this.handleStartDateChange}
                            HandleEndDateChange = {this.handleEndDateChange}
                            Months = {this.state.months}
                            IsPayPeriod = {this.state.isPayperiod}
                            PayPeriodType = {this.state.payPeriodType}
                            Post = {this.state.post}
                            DisplayExport = {this.state.displayExport}
                            Projects = {this.state.projects}
                            HandleTreeChange = {this.handleTreeChange}
                            ViewProjects = {this.state.viewProjects}
                            FilterSubmit = {this.filterSubmit}
                            ViewTimesheetDetails = {this.state.viewTimesheetDetails}
                       />
                :
                <ErrorPage/>
        )
    }
}
    const mapStateToProps = (state) => {
        const savedFilterValues = state.savedFilters.savedFilterValues ? state.savedFilters.savedFilterValues : {};
        return {
            savedFilterValues: savedFilterValues,
        }
    }
    const mapDispatchToProps = dispatch => {
        return {
            setSavedFilterVal: () => dispatch(setSavedFilters()),
            updateSavedFilterVal: ({ obj }) => dispatch(updateSavedFilterAction({ obj }))
        }
    }
const connectedPayperiodStatusReportContainer = connect(mapStateToProps, mapDispatchToProps)(PayperiodStatusReportContainer);
export { connectedPayperiodStatusReportContainer as PayperiodStatusReportContainer };
