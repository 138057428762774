import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import * as _R from 'ramda';  // used 0.04kb
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';


const formFields = {
    'id': 0,
    'employer_name': null,
    'job_title': null,
    'annual_salary' : null,
    'location' : null,
    's_year':null,
    's_month': null,
    's_date':null,
    'e_year':null,
    'e_month':null,
    'e_date':null
}
const DialogContent = (props) => {
    const {FormFields, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                            Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                            End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {FormFields.s_year}
                                id="s_year"
                                name="s_year"
                                label="Year"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder = "YYYY"
                                error = {props.Errors.s_year && true}
                                helperText = {props.Errors.s_year}
                                onChange={props.GetDaysInMonth}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="s_month">Month</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.s_month}
                                onChange={props.GetDaysInMonth}
                                inputProps={{
                                    name: 's_month',
                                    id: 's_month',
                                }}>
                                <MenuItem value={null} key={'m0'}>Select</MenuItem>
                                <MenuItem value={'01'} key={'m1'}>01</MenuItem>
                                <MenuItem value={'02'} key={'m2'}>02</MenuItem>
                                <MenuItem value={'03'} key={'m3'}>03</MenuItem>
                                <MenuItem value={'04'} key={'m4'}>04</MenuItem>
                                <MenuItem value={'05'} key={'m5'}>05</MenuItem>
                                <MenuItem value={'06'} key={'m6'}>06</MenuItem>
                                <MenuItem value={'07'} key={'m7'}>07</MenuItem>
                                <MenuItem value={'08'} key={'m8'}>08</MenuItem>
                                <MenuItem value={'09'} key={'m9'}>09</MenuItem>
                                <MenuItem value={'10'} key={'m10'}>10</MenuItem>
                                <MenuItem value={'11'} key={'m11'}>11</MenuItem>
                                <MenuItem value={'12'} key={'m12'}>12</MenuItem>
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl variant="standard">
                        <InputLabel htmlFor="s_date">Day</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.s_date}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 's_date',
                                    id: 's_date',
                                }}>
                                <MenuItem value={null} key={'s-date-0'}>Select</MenuItem>
                                {_R.type(props.StartDates) !== 'Undefined' && props.StartDates.map((t, i) =>                                        
                                    <MenuItem key={'s-'+i} value={t}>{t}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {_R.type(FormFields) !== 'Undefined' && FormFields.e_year}
                                id="e_year"
                                name="e_year"
                                type="text"
                                label="Year"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder = "YYYY"
                                error = {props.Errors.e_year && true}
                                helperText = {props.Errors.e_year}
                                onChange={props.GetDaysInMonth}
                            /> 
                        </ FormControl>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="e_month">Month</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.e_month}
                                onChange={props.GetDaysInMonth}
                                inputProps={{
                                    name: 'e_month',
                                    id: 'e_month',
                                }}>
                                <MenuItem value={null} key={'m0'}>Select</MenuItem>
                                <MenuItem value={'01'} key={'m1'}>01</MenuItem>
                                <MenuItem value={'02'} key={'m2'}>02</MenuItem>
                                <MenuItem value={'03'} key={'m3'}>03</MenuItem>
                                <MenuItem value={'04'} key={'m4'}>04</MenuItem>
                                <MenuItem value={'05'} key={'m5'}>05</MenuItem>
                                <MenuItem value={'06'} key={'m6'}>06</MenuItem>
                                <MenuItem value={'07'} key={'m7'}>07</MenuItem>
                                <MenuItem value={'08'} key={'m8'}>08</MenuItem>
                                <MenuItem value={'09'} key={'m9'}>09</MenuItem>
                                <MenuItem value={'10'} key={'m10'}>10</MenuItem>
                                <MenuItem value={'11'} key={'m11'}>11</MenuItem>
                                <MenuItem value={'12'} key={'m12'}>12</MenuItem>
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="e_date">Day</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.e_date}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'e_date',
                                    id: 'e_date',
                                }}>
                                <MenuItem value={null} key={'e-date-0'}>Select</MenuItem>
                                {_R.type(props.EndDates) !== 'Undefined' && props.EndDates.map((t, i) =>            
                                    <MenuItem key={'e-'+i} value={t}>{t}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                value = {FormFields.employer_name}
                                id="employer_name"
                                name="employer_name"
                                label="Employer Name"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                error={props.Errors.employer_name && true}
                                helperText={props.Errors.employer_name}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {FormFields.job_title}
                                id="job_title"
                                name="job_title"
                                label="Job Title"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                error={props.Errors.job_title && true}
                                helperText={props.Errors.job_title}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {FormFields.annual_salary}
                                id="annual_salary"
                                name="annual_salary"
                                label="Annual Salary"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                error={props.Errors.annual_salary && true}
                                helperText={props.Errors.annual_salary}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                value = {FormFields.location}
                                id="location"
                                name="location"
                                label="Location"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                error={props.Errors.location && true}
                                helperText={props.Errors.location}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

class PreviousEmploymentListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            errors: {},
            errorMessage: false,
            sDays: [],
            eDays: [],
            previousEmployment: this.props.PreviousEmployment, 
            edit: this.props.Edit, 
            deletion: this.props.Delete,
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.OnClose = this.OnClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
    }
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.id = this.state.id;
            data.staff_id = this.props.StaffID;
            let tab = StaffConstants.STAFF_TABS[2];
            let module = 'previous_employment';
            let apiEndpoint = 'api/staff/work/save-previous-employment';
            if (data) {
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    previousEmployment: response.data.previous_employment,
                                    open:false,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.msg,
                                });
                            }
                        }
                        else {
                             this.setState({ errors: response.data.errors });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }
    onUpdate = (id = 0) => () => {
        let apiEndpoint = 'api/staff/work/get-previous-employment';
        let editable = (id) ? true : false;
        this.setState({id:id});
        return httpServices.post(apiEndpoint, {
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    console.log(response.data);
                    this.setState({
                        open:true,
                        formFields: (id) ? response.data.previous_employment: formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        sDays: response.data.smonth_days,
                        eDays: response.data.emonth_days
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    OnClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        let annual_salary = this.state.formFields.annual_salary;
        let start_date = this.state.formFields.start_date;
        let end_date = this.state.formFields.end_date;
        if (!this.state.formFields.employer_name) {
            errors["employer_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (annual_salary && !annual_salary.match(/^[\d]+$/)) {
            errors["annual_salary"] = "Annual Salary is invalid";
            formIsValid = false;
        }
        if (this.state.formFields.s_year && !this.state.formFields.s_year.match(/[\d]{4}/)) {
            errors["s_year"] = "Invalid format";
            formIsValid = false;
        }
        if (this.state.formFields.e_year && !this.state.formFields.e_year.match(/[\d]{4}/)) {
            errors["e_year"] = "Invalid format";
            formIsValid = false;
        }
        if (start_date && end_date) {
            let d1 = new Date(start_date);
            let d2 = new Date(end_date);
            if (d1.getTime() > d2.getTime()) {
                errors["start_date"] = 'Start Date cannot be greater than End Date.'
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/work/delete-previous-employment';
        return httpServices.post(apiEndpoint, {
            'staff_id': StaffID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[2];
                    let module = 'previous_employment';
                    this.setState({
                        previousEmployment: response.data.previous_employment,
                        open:false,
                    });
                    this.clearForm();
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }

    getDaysInMonth = (evt, value) => {

        let year, month;
        let name = evt.target.name;
        if (name === 's_month' || name === 'e_month') {
            year = (name === 's_month')?parseInt(this.state.formFields.s_year): parseInt(this.state.formFields.e_year);
            month = parseInt(evt.target.value-1);
        }
        else {
            year = parseInt(evt.target.value);
            month = (name === 's_year')?parseInt(this.state.formFields.s_month): parseInt(this.state.formFields.e_month);
        }
        var days = [];
        if (!isNaN(year) && !isNaN(month)) {
            var date = new Date(Date.UTC(year, month, 1));
            while (date.getMonth() === month) {
                days.push(date.getDate());
                date.setDate(date.getDate() + 1);
            }
        }
        if (name === 's_month' || name === 's_year') {
            this.setState({sDays: days});
        }
        else {
            this.setState({eDays: days});
        }
        this.HandleOnChange(evt, value);
    }

    render(){
        const {previousEmployment, edit, deletion} = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Previous Employment</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Previous Employment" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            { _R.isEmpty(previousEmployment) 
            ?   
                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
            : 
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Employer Name</TableCell>
                            <TableCell>Job Title</TableCell>
                            <TableCell>Annual Salary</TableCell>
                            <TableCell>Location</TableCell>
                            {_R.or(edit, deletion) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof previousEmployment != 'undefined' && previousEmployment.map((p, i)  =>
                        <TableRow hover key={i}>
                            <TableCell>{p.start_date}</TableCell>
                            <TableCell>{p.end_date}</TableCell>
                            <TableCell>{p.employer_name}</TableCell>
                            <TableCell>{p.job_title}</TableCell>
                            <TableCell>{p.annual_salary}</TableCell>
                            <TableCell>{p.location}</TableCell>
                            {_R.or(edit, deletion) &&
                            <TableCell align="right">
                            {_R.equals(edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onUpdate(p.id)}>
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            {_R.equals(deletion, true) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onDelete(p.id)}>
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(previousEmployment) &&
                        <TableRow>
                            <TableCell colSpan={7} align="center">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>            
            </div>
            }
            <DialogBoxMedium 
                    Open={this.state.open} 
                    Cancel={this.OnClose}
                    Title={this.state.isDelete ?  'Delete Previous Employment' : (this.state.isEditable ? 'Edit Previous Employment' : 'Add Previous Employment')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Previous Employment?'
                        :
                        <DialogContent
                            FormInputs={this.state.formInputs}
                            FormFields={this.state.formFields}
                            OnSubmit={this.onSubmit}
                            HandleOnChange = {this.HandleOnChange}
                            Errors = {this.state.errors}
                            GetDaysInMonth={this.getDaysInMonth.bind(this)}
                            StartDates={this.state.sDays}
                            EndDates={this.state.eDays}
                        />
                    }
                />
            </>
        )
    }
}
export { PreviousEmploymentListContainer as PreviousEmploymentList };
