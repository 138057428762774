import React from 'react';
import {SubmitListComponent} from './SubmitList.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import { history } from './../../../helpers/history';
import { CustomProgressBar } from '../../common/CustomProgressBar';

let filterFields = {
    'year' :  0,
    'month' : '0',
    'status' : '-1',
}

export class SubmitListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            list : [],
            hasPermission : true,
            filterInputs : [],
            filterFields : filterFields,
            right : false,
            loader : true,
        }
    }
    componentDidMount = () => {
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;
        if (fromDashboard) {
            let filterFields = this.state.filterFields;
            filterFields['year'] = '0';
            filterFields['month'] = '0';
            filterFields['status'] = '-1';
            this.setState({
                filterFields: filterFields
            });
        }
        this.getSubmitList();
    };
    getSubmitList = () => {
        let data = this.state.filterFields;
        return httpServices.post('api/timesheet/list', data)
            .then(response => {
                if (response.data) {
                    this.setState({
                        list : response.data.list,
                        filterInputs : response.data.form_inputs,
                        hasPermission : response.data.permission,
                        loader : false,
                    });
               }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    viewSubmitPage  = (id) =>{
        history.push('submit/' + id);
    }
    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }
    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
    }
    handleFilter = () => {
        const data = this.state.filterFields;
        if (data) {
            this.getSubmitList();
            this.setState({right: false});
        }
    }
    resetFilter = () => {
        let ff = {
            'year' :  0,
            'month' : '0',
            'status' : '-1',
        }
        filterFields = ff;
        this.setState({
            filterFields: ff,
            right: false
        },()=>{
            this.getSubmitList();}
        );
    }
    render(){
        return(
            (this.state.loader)?
                <CustomProgressBar 
                    loaderOpen={this.state.loader}
                />
                :
                (this.state.hasPermission)
                ?
                <SubmitListComponent
                    HandleSubmitPage = {this.viewSubmitPage.bind(this)}
                    ToggleDrawer = {this.toggleDrawer.bind(this)}
                    HandleFilterChange={this.handleFilterChange.bind(this)}
                    HandleFilter = {this.handleFilter.bind(this)}
                    ResetFilter ={this.resetFilter.bind(this)}
                    {...this.state}
                />
                :<ErrorPage/>
        )
    }
}
