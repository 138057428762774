import React from 'react';

import { httpServices } from '../../../../services/httpServices';
import { MaritalStatusComponent } from "./MaritalStatus.Component";

const formFields = {
    'id':0,
    'status': null,
    'start_date': null,
    'end_date': null,
};

export class MaritalStatus extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            maritalDetails : [],
            open : false,
            formFields : formFields,
            errors : {},
            IsEditable : false,
        };
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    onAdd = () => {        
        if(this.state.maritalDetails.length < 1 && this.props.maritalDetails){
            this.setState({maritalDetails : this.props.maritalDetails});
        }
        let formFields = {
            'id':0,
            'status': null,
            'start_date': null,
            'end_date': null,
        };
        this.setState({
            open : true,
            IsEditable: false,
            formFields : formFields,
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
   
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.staffId; 
                httpServices.post('api/staff/personal/saveMaritalDetails', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.status) {
                                if(response.data.start_date || response.data.end_date){
                                    data['start_date'] = response.data.start_date;
                                    data['end_date'] = response.data.end_date;
                                }
                                let marital_status_text  = this.getMaritalStatusById(data.status);
                                if(marital_status_text){
                                    data['title'] = marital_status_text;
                                }
                                if(data.id){
                                    const index = this.state.maritalDetails.findIndex(dd => dd.id === data.id);
                                    let marital_details = this.state.maritalDetails;
                                    marital_details[index] = data;
                                    this.setState({maritalDetails : marital_details,open: false});
                                    alert("Marital details updated successfully");
                                } else { 
                                    let marital_details = this.state.maritalDetails;
                                    data['id'] = response.data.id;
                                    marital_details.push(data);
                                    this.setState({
                                        maritalDetails : marital_details,
                                        open : false,
                                    });
                                    alert("Marital details saved successfully");
                                }        
                            } else {
                                alert(response.data.err_msg);
                            }
                        }
                        else {
                            this.setState({errors : response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    onUpdate = (id) => () => {
        if(this.state.maritalDetails.length < 1 && this.props.maritalDetails){
            this.setState({maritalDetails : this.props.maritalDetails});
        }
        if(id){
            httpServices.post('api/staff/personal/getMaritalDetailsById', { id : id })
                .then(response => {
                    let marital_details = response.data.marital_details;
                    if(marital_details){
                        this.setState({
                            open : true,
                            formFields : marital_details,
                            IsEditable : true,
                        });
                     }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };

    onDeleteSubmit = (id) => () => {
        if (id) {
            let confirm_delete = window.confirm("Do you want to delete this entry");
            if(confirm_delete){
                if(this.state.maritalDetails.length < 1 && this.props.maritalDetails){
                    this.setState({maritalDetails : this.props.maritalDetails});
                }
                let data = {
                    id : id,
                    staff_id : this.props.StaffID
                };
                httpServices.post('api/staff/personal/deleteMaritalDetails', { data })
                    .then(response => {
                        const index = this.state.maritalDetails.findIndex(dd => dd.id === data.id);
                        let marital_details = this.state.maritalDetails;
                        marital_details.splice(index,1);
                        this.setState({maritalDetails : marital_details,open: false});
                        alert("Marital details deleted successfully");
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.status) {
            errors["marital_status"] = "Cannot be empty";
            formIsValid = false;
        }
        let start_date = this.state.formFields.start_date ? new Date(this.state.formFields.start_date) : this.state.formFields.start_date;
        let end_date = this.state.formFields.end_date ? new Date(this.state.formFields.end_date) : this.state.formFields.end_date;
        if ( start_date && end_date ) {
            if(end_date < start_date){
                errors["end_date"] = "Please enter a valid End Date";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    getMaritalStatusById = (id) => {
        if(this.props.formInputs.marital_statuses){
            let title = '';
            let marital_statuses = this.props.formInputs.marital_statuses;
            marital_statuses.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };

    handleDateChange = (value, name) => {
        const ff = this.state.formFields;
        ff[name] = value;
        this.setState({
            formFields : ff
        });
    };
        
    render(){
        return (
            <MaritalStatusComponent 
                maritalDetails = { this.state.maritalDetails.length > 0 ? this.state.maritalDetails : this.props.maritalDetails }
                open = { this.state.open }
                onAdd = { this.onAdd }
                FormFields = { this.state.formFields } 
                Errors = { this.state.errors }
                HandleOnChange = { this.handleOnChange }
                onClose = { this.onClose }
                FormInputs = { this.props.formInputs }
                OnSubmit = { this.handleSubmit }
                staffId = { this.props.staffId }
                onUpdate = { this.onUpdate }
                onDeleteSubmit = { this.onDeleteSubmit }
                EditPermission = { this.props.Edit }
                DeletePermission = { this.props.Delete }
                IsEditable = { this.state.IsEditable }
                HandleDateChange = {this.handleDateChange}
            />
        )
    }
}
