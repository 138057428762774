import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';
import TableContainer from '@mui/material/TableContainer';
import { formatName } from '../common/common';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer) (({ theme }) => ({
  '& .container': {
    maxHeight: 440,
  },
}));
export const EdenredDetailsComponent = (props) => {
    return (
             <form className="form-fullwidth">
             <Paper>
                <StyledTableContainer className={'container'}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Benefit Year</TableCell>
                                <TableCell>Benefit Month</TableCell>
                                <TableCell>Transaction Type</TableCell>
                                <TableCell>Pre-Tax Amount</TableCell>
                                <TableCell>Post-Tax Amount</TableCell>
                            </TableRow>
                        </TableHead>
            {_R.type(props.MonthlyData) !== 'Undefined' && (props.MonthlyData).map((item) =>
                        <TableBody>
                            <TableRow>
                                <TableCell> {formatName(item.user_first_name, null, item.user_last_name)} </TableCell>
                                <TableCell> {item.benefit_year} </TableCell>
                                <TableCell> {item.benefit_month} </TableCell>
                                <TableCell> {item.txn_type} </TableCell>
                                <TableCell> {item.amount} </TableCell>
                                <TableCell> {item.posttax_amount} </TableCell>
                            </TableRow>
                        </TableBody> )}
                        </Table>
                </StyledTableContainer>
             </Paper>
             </form>
    )
};
