import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { formatName } from '../common/common';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomizedSnackbars } from '../common/snackbars';
import {DialogBoxMedium} from '../common/dialog.medium';

export const SystemRoleComponent = (props) => {   
        return (
            <>
                <div className={'listSubHeader'}>
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Roles</Typography>
                    <Tooltip title="Add Role" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} >
                            <AddIcon onClick={props.OnAddRoleClick}/>
                        </Fab>
                    </Tooltip>
                </div>
                <div className={'section-content roles-table'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Role Name</TableCell>
                                <TableCell>Role Type</TableCell>
                                <TableCell>Slug</TableCell>
                                <TableCell>Is Default</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Modified By</TableCell>
                                <TableCell>Modified On</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.Roles.map((r,i) =>    
                            <TableRow hover>
                                <TableCell><a className={'item-link'} onClick={props.ViewRoleDetails(r.id)}>{r.name}</a></TableCell>
                                <TableCell>{r.role_type}</TableCell>
                                <TableCell>{r.role_slug}</TableCell>
                                <TableCell>{r.is_default == 1 ? "Yes" : "No"}</TableCell>
                                <TableCell>{formatName(r.cby_first_name, null, r.cby_last_name)}</TableCell>
                                <TableCell>{r.created_on}</TableCell>
                                <TableCell>{formatName(r.mby_first_name, null, r.mby_last_name)}</TableCell>
                                <TableCell>{r.modified_on}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="View" aria-label="view">
                                        <IconButton className={'action-btn'} edge="end" aria-label="View">
                                            <VisibilityIcon fontSize="small" onClick={props.ViewRoleDetails(r.id)} />
                                        </IconButton>
                                    </Tooltip>
                                    {r.role_type !== "system_defined" &&
                                        <>
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton className={'action-btn'} edge="end" aria-label="Edit">
                                            <EditIcon fontSize="small" onClick={props.EditRoleClick(r.id)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton  className={'action-btn'} edge="end" aria-label="Delete" >
                                            <DeleteIcon fontSize="small" onClick={props.DeleteRoleClick(r.id)}/>
                                        </IconButton>
                                    </Tooltip>
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                {props.PopupType === 'add' &&
                <DialogBoxMedium 
                    Open= { props.Open }  
                    Cancel = { props.OnClose }
                    Title = { props.IsEditable ? 'Edit Role' : 'Add Role' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogContent FormProps = { props }/> }
                />
                }
                {props.PopupType === 'delete' &&
                <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.OnClose}
                    OnSubmit={props.DeleteRole}
                    Title='Confirm Delete'
                    Content={
                        <DialogWithConfirmMessage msg="Do you really want to delete this role ?" />
                    }
                />
                }
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarType}
                    message={props.Message}
                    handleClose={props.SnackbarClose}
                />
            </>
        )
}

const DialogContent = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { Errors.name && true }
                            helperText = { Errors.name }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            id = "description"
                            name = "description"
                            label = "Description"
                            required
                            InputLabelProps =  {{
                                shrink: true,
                            }}
                            value = { FormFields.description }
                            onChange = { HandleOnChange }
                            error = { Errors.description && true }
                            helperText = { Errors.description }
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
