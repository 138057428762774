import React from 'react';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { formatName } from '../../common/common';
import Paper from "@mui/material/Paper";

export const ChangeLogComponent = (props) => {
    const allEntities = ["Project", "Reviewer", "Labor Category", "Resource", "Resource Rate", "Task"];
    const gridStyle = {
        display: "block",
        padding: "24px",
        position: "relative",
        background: "#fff",
    };
    function sort(date1, date2) {
        if (date1 < date2) return -1;
        if (date1 > date2) return 1;
        return 0;
    }
    let project_data = props.Project;
    return (        
        <>
            <Paper className={"mb-1"} style={gridStyle}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Created By</label>
                                <span className={"value"}>
                                    {formatName(project_data.createdby_first_name, null, project_data.createdby_last_name)}
                                </span>
                            </div>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Modified By</label>
                                <span className={"value"}>
                                    {formatName(project_data.modifiedby_first_name, null, project_data.modifiedby_last_name)}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={"profile-view__basics_default"}>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Created On</label>
                                <span className={"value"}>
                                    {project_data.project_cdate}
                                </span>
                            </div>
                            <div className={"profile-view__basics_default-item item-role"}>
                                <label>Modified On</label>
                                <span className={"value"}>
                                    {project_data.project_mdate}
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container spacing={3} >
                    <Grid item xs={12} sm={12}>
                        <form className="form-fullwidth">
                                <Grid item xs={4} md={2}>
                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="age-simple">Entity Name</InputLabel>
                                        <Select
                                        value = {_R.type(props.selectedEntity) !== 'Undefined' ? props.selectedEntity: ''}
                                        onChange = {props.handleOnChange}
                                        inputProps = {{
                                        name: 'selectedEntity',
                                        id: 'selectedEntity',
                                        }}>
                                        {allEntities.map((name, i) =>
                                        <MenuItem value = {name} > {name} </MenuItem>
                                        )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                        </form>
                    </Grid>
            </Grid>
        <React.Fragment>
	        <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title=""
                        columns={[
                            {title: 'Modified On', field: 'created_on',  customSort:(a,b) => sort(new Date(a.created_on),new Date(b.created_on))},
                            {title: 'Modified By',field: 'user_fullname' },
                            {title: 'Name',field:'name'},
                            {title: 'Field',field: 'display_name'},
                            {title: 'Previous Value',field: 'prev'},
                            {title: 'Current Value',field: 'new'},
                        ]}
                        data={props.ChangeLogs}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                                            
                    />
	        </div>
        </React.Fragment>
        </>
    )
};
