import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialog =(props)=>  {
  return (
    <div>
      <Dialog fullScreen open={props.Open}  TransitionComponent={Transition}>
      <DialogTitle id="scroll-dialog-title">{props.Title}</DialogTitle>  
      <DialogContent>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                    {props.Content}
      </DialogContent>
        <DialogActions>
                    <Button color="primary" size="small" onClick={props.Cancel}>{props.CancelButtonName ? props.CancelButtonName : 'Cancel'}</Button>
                    {props.Disabled ?
                        <Button variant="contained" size="small" color="primary" disabled onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                    :
                        <Button variant="contained" size="small" color="primary" onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                    }
                    {props.DisplayNoButton &&
                        <Button variant="contained" size="small" color="primary" onClick={props.OnNoClick}>No</Button>
                    }
                </DialogActions>
      </Dialog>
    </div>
  );
}

