import { UserConstants } from '../../constants';
import { httpServices } from '../../services/httpServices';
import Cookies from 'universal-cookie';
import MixpanelManager from  '../../services/Analytics';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
//Function to handle logout action
export const logout = (status=200) => {
    const cookies = new Cookies();
    let user = {
    };
    let apiEndpoint = UserConstants.LOGOUT_ROUTE;
    let mx = new MixpanelManager();
    mx.trackEvent('Log out',{'message': "Log out clicked "});
    return httpServices.post(apiEndpoint, user)
        .then(response => {
            if (response.data) {
                var myLength = response.data.instance_id.length;
                if (status !== parseInt(401)) {
                    Object.keys(window.localStorage)
                        .forEach(function(key){
                            if (key.substring(0,myLength) === response.data.instance_id) {
                                localStorage.removeItem(key);
                            }
                            if (key === 'server-error') {
                                localStorage.removeItem(key);
                            }
                        });
                    cookies.remove('tab', { path: '/' });
                }
                //cookies.remove('filter', { path: '/' });
                window.location = BASE_PATH+'/login';
            }
            else {
                alert("logout fail");
            }
        })
        .catch(function(error) {
        });
};