import React from 'react';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import { formatName } from '../../common/common';
const API_URL = process.env.REACT_APP_API_URL;

export const PolicyComponent = (props) => {
    const policyUnit = typeof props.PolicyUnit !== 'undefined' ? props.PolicyUnit : 'year';
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={"Policy"}
                        columns={[
                            {
                                title: 'Eligibility Criteria Group',
                                field: 'name',
                                render: rowData => (
                                    <a href={API_URL + 'system/functional/eligibilitycriteria'} onClick={() => localStorage.setItem('clicked_group', rowData.id)}> {rowData.name} </a>
                                  )
                            },
                            {
                                title: 'Created By',
                                render: rowData => formatName(rowData.cby_first_name,null,rowData.cby_last_name)
                            },
                            {
                                title: 'Created On',
                                render: rowData => rowData.created_on
                            },
                            {
                                title: 'Modified By',
                                render: rowData => formatName(rowData.mbyFirstName,null,rowData.mbyLastName)
                            },
                            {
                                title: 'Modified On',
                                render: rowData => rowData.modified_on
                            }
                        ]}
                        data={props.Policy}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            paging: false,
                            sorting: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
        </>
    )
}

