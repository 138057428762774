import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import * as R from 'ramda';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {DialogBoxMedium} from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import { Comment } from '../Common/Comment';
import { ChangeHistory } from '../Common/ChangeHistory';
import { RelatedTimeEntries } from '../Common/RelatedTimeEntries';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AttachmentIcon from '@mui/icons-material/Attachment';
import * as _cs from '../../common/common';
import { AddLocation } from './LocationAdd.Container';
import { formatName } from '../../common/common';
import Tooltip from '@mui/material/Tooltip';

const API_URL = process.env.REACT_APP_API_URL;
const StyledHistory = styled('div')(({ theme }) => ({    
    '& .root': {
    width: '100%',
  }, 
}));

export const LocationViewComponent = (props) => {
if (props.Request.recipients) {
         var primary_arr = [];
         var secondary_arr = [];
         var reviewer_arr = [];
         var rec_arr = [];
         Object.keys(props.Request.recipients).forEach(myFunction);
         function myFunction(item, index) {
             if (props.Request.reviewers.includes(props.Request.recipients[item].user_id)) {
              if ( R.type(props.Request.primary)  !== "Undefined") {
              if (props.Request.primary.includes(props.Request.recipients[item].user_id)) {
                   props.Request.recipients[item].rev = " (Primary Reviewer)";                 
                   primary_arr.push(props.Request.recipients[item]);
              }
             else
              {
                 props.Request.recipients[item].rev = " (Secondary Reviewer)"; 
                 secondary_arr.push(props.Request.recipients[item]);
              }
            }
            else
            {
                 props.Request.recipients[item].rev = " (Reviewer)";                
                 reviewer_arr.push( props.Request.recipients[item]);
            }
        }
        else
             {
                 props.Request.recipients[item].rev = '';
                 rec_arr.push( props.Request.recipients[item]);
             }
         }
        props.Request.recipients = primary_arr.concat(secondary_arr, reviewer_arr, rec_arr);
     }
    let image_url = API_URL+'api/request/timeoff/download?id='+ (R.type(props.Request.request) !== 'Undefined' ? props.Request.request.attachment_id : []);    
    let height = props.Height + 25 + 'px';
    return (

<div className="section-main section-main-header-fixed">
    <div className={'header-fixed'} id = "fixed-header-id">
        <div className="section-header">
            <div className="col-left">
                <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={  <Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                <div className="section-title-wrap">
                    <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                    Work Location Change Requests
                    </Typography>
                    { R.type(props.Request.request) !== 'Undefined'  &&
                         <Typography>ID: {props.Request.request.id}</Typography>
                    }
                </div>
            </div>
            <div className="action">
                { props.SelfEdit &&
                    <Button variant="contained" size="small"  color="primary" onClick={props.EditClick}>Edit</Button>
                }<i> </i>
                { (props.IsReviewer !== false)  && (parseInt(props.Request.request.status) === 1) &&
                    <Button variant="contained" size="small" color="primary" onClick = {() => props.AddCommentClick("approve")}>Approve</Button>
                }<i> </i>
                { (props.IsReviewer !== false)  && (parseInt(props.Request.request.status) === 1) &&
                    <Button variant="contained" size="small" color="secondary" onClick = {() => props.AddCommentClick("reject")}>Reject</Button>
                }<i> </i>
                { (props.IsReviewer !== false)  && (parseInt(props.Request.request.status) === 2) && ((props.Request.timesheetsubmit.length > 0)) &&
                    <Button variant="contained" size="small" color="secondary" onClick = {() => props.AddCommentClick("unapprove")}>UnApprove</Button>
                }<i> </i>
                { (props.Request.is_cancel !== false) &&
                    <Button variant="outlined" size="small" color="primary" onClick = {() => props.AddCommentClick("cancel")}>Cancel</Button>
                }<i> </i>

                { (props.Request.comment !== false)  &&
                    <Button variant="outlined" size="small"  onClick={() => props.AddCommentClick('addcomment')}>Comment</Button>
                }<i> </i>
                { (props.IsReviewer === false)  && (props.ShowCor === true) &&
                    <Button variant="outlined" size="small" color="primary"  onClick={() => props.RequestAction(props.ID,'token_generate')}>Send Review Reminder</Button>
                }
            </div>
        </div>
    </div>
    <div className="section-body" style={{top : height}}>
        <Paper>
            <div className="timesheet_list-summary">
                <div className="p-1rem summary box-details">
                    <ul className="list-standard list-ul-shrink">
                            <li className="list-item">
                            <label>Staff Name</label>
                             { R.type(props.Request) !== 'Undefined'  &&
                                <span className="value" class='worklocationLabel'>{props.Request.creator}</span>
                             }
                            </li>
                            <li className="list-item">
                            <label>Project Name</label>
                             { R.type(props.Request.request) !== 'Undefined'  && (props.Request.request.project_name) &&
                                <span className="value">{props.Request.request.client_name}/{props.Request.request.project_name}</span>
                             }
                             { R.type(props.Request.request) !== 'Undefined'  && !(props.Request.request.project_name) &&
                                <span className="value">All Projects</span>
                             }
                            </li>
                            <li className="list-item">
                            <label>Location</label>
                               <span className="value">
                                 { R.type(props.Request.request) !== 'Undefined' &&
                                            <span className="pill">{props.Request.location}</span>
                                 }
                              </span>
                           </li>
                            <li className="list-item">
                            <label>Reference Number</label>
                             { R.type(props.Request.request) !== 'Undefined'  &&
                                <span className="value">{props.Request.request.reference_no}</span>
                             }
                            </li>
                            <li className="list-item">
                            <label>Date</label>
                             { R.type(props.Request.request) !== 'Undefined'  && (props.Request.request.start_date !== props.Request.request.end_date) &&
                                <span className="value">{props.Request.request.start_date} - {props.Request.request.end_date}</span>
                             }
                             { R.type(props.Request.request) !== 'Undefined'  && (props.Request.request.start_date === props.Request.request.end_date) &&
                                <span className="value">{props.Request.request.start_date}</span>
                             }

                            </li>
                            <li className="list-item">
                            <label>Hours</label>
                             { R.type(props.Request.request) !== 'Undefined'  &&
                                <span className="value">{props.Request.request.hours}</span>
                             }
                            </li>
                             <li className="list-item">
                            <label>Status</label>
                             { R.type(props.Request.request) !== 'Undefined'  &&
                                <span className="value" class='worklocationLabel'>{props.Request.request.request_status_name}</span>
                             }
                            </li>

                          { R.type(props.Request.request) !== 'Undefined' && props.Request.request.attachment_id &&
                             <li className="list-item">
                                <label><AttachmentIcon /></label>
                                 <a href = {image_url} > {_cs.formatFileName(props.Request.file_name, 19)} </a>
                              </li>
                         }
                    </ul>
                    <ul className="list-standard list-ul-grow list-ul-border-l">
                        <li className="list-item list-item-block">
                            <label>Description</label>
                            { R.type(props.Request.request) !== 'Undefined'  &&
                                <span className="value">{props.Request.request.description}</span>
                             }
                        </li>
                         { R.type(props.Request.recipients) !== 'Undefined'  && (props.Request.recipients != null) &&
                                    <li className="list-item list-item-block">
                                        <label>Email Recipients </label>
                                        <span className="value">
                                          { R.type(props.Request.recipients) !== 'Undefined'  && (props.Request.recipients != null) && Object.keys(props.Request.recipients).map((ry,i) =>
                                         <><span className="pill">{formatName(props.Request.recipients[ry].user_first_name, null, props.Request.recipients[ry].user_last_name)}{props.Request.recipients[ry].rev}</span></>

                                         )}
                                        </span>
                                    </li>
                                    }
                        <li className="list-item list-item-bottom-info">
                            <div>
                                <i><strong>Submitted by: </strong></i>
                                { R.type(props.Request.creator) !== 'Undefined'  &&
                                 <i>{props.Request.creator}</i>
                                }
                                { R.type(props.Request.submitted_date) !== 'Undefined'  &&
                                <i> on {props.Request.submitted_date} </i>
                                }
                                { R.type(props.Request.modifier) !== 'Undefined'  && (props.Request.modifier) &&
                                    <>
                                    |
                                    <i><strong> Modified by: </strong></i>
                                    <i>{props.Request.modifier}</i>
                                    <i> on {props.Request.modified_on} </i>
                                    </>
                                }
                                
                                { R.type(props.Request.approver_name) !== 'Undefined'  && (props.Request.approver_name) &&
                                    <>
                                    |
                                   <i><strong> Reviewed by: </strong></i>
                                   <i>{props.Request.approver_name}</i>
                                   <i> on {props.Request.request.reviewed_on} </i>
                                    </>
                                }
                            </div>
                        </li> 
                    </ul>
                </div>
            </div>
        </Paper>
        <Paper>
        <StyledHistory>
        <div className={'root'}>
                        <Comment Comments={R.type(props.CommentsHistory) !== 'Undefined' ? props.CommentsHistory : []} />
                        <RelatedTimeEntries
                            TimeEntries = {R.type(props.Timesheet) !== 'Undefined' ? props.Timesheet : []}
                            Total = {R.type(props.Request.timesheet_total) !== 'Undefined' ? props.Request.timesheet_total : []}
                        />
                        <ChangeHistory History = {R.type(props.ChangeLog) !== 'Undefined' ? props.ChangeLog :[]} />
        </div>
        </StyledHistory>
        </Paper>

    </div>
        <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid?'success':'error'}
                message={props.Valid? props.SuccessMessage:props.ErrorMessage}
                handleClose={props.SnackbarClose}
            />

         {(props.Popup === 'edit') &&
            <AddLocation
                Open = {props.DialogOpen}
                UpdateParent = {props.UpdateParentOnEditSuccess}
                EditId = {props.ID}
            />
         }
        <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.Action === 'addcomment' ? 'Add Comment' : props.Action === 'approve' ? 'Approve Request' : props.Action === 'unapprove' ? 'UnApprove Request' : props.Action === 'cancel' ? 'Cancel Request' : props.Action === 'token_generate' ? 'Generate Token' :'Reject Request'}
                ButtonName = { props.Action === 'addcomment' ? 'Add' : props.Action === 'approve' ? 'Approve' : props.Action === 'unapprove' ? 'UnApprove' : props.Action === 'cancel' ? 'Yes' : props.Action === 'token_generate' ? 'Generate' :'Reject'}
                OnSubmit = { props.HandleSubmit }
                Loading = {props.Loading}
                Content = { <DialogContent FormProps = { props }/> }
        />

</div>


    )
};
const DialogContent = (props) => {
    return (
        <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                    <FormControl>
                        <TextField
                            id = "comment"
                            name = "comment"
                            label = {(props.FormProps.Action === 'approve') ?  "Comments (optional)" : "Comments"}
                            type = "text"
                            required = {(props.FormProps.Action === 'unapprove') ? true :false}
                            multiline={true}
                            variant="outlined"
                            value = {(typeof props.FormProps.FormFields.comment !== 'undefined') ? props.FormProps.FormFields.comment  : null}
                            onChange = {props.FormProps.HandleOnChange}
                            error = {typeof props.FormProps.Errors != 'undefined' &&  props.FormProps.Errors.comment }
                            helperText = { typeof props.FormProps.Errors != 'undefined' && props.FormProps.Errors.comment}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
