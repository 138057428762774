import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Paper from '@mui/material/Paper';
import * as _R from 'ramda';  // used 0.04kb

export const CompanyDetailsComponent = (props) => {
    return (
        <React.Fragment>
            <>
            <div className = {'listSubHeader'}>
                <Typography component = "h2" className = {'section-title'} variant = "button" display = "block" gutterBottom color = "primary"> Company Details </Typography>
                {props.EditPermission &&
                    <div className = {'profile-view__action-right'}>
                        <Button variant="contained" size = "small"  color = "primary" onClick = {props.EditCompanyDetails} > Edit </Button>
                    </div>
                }
                </div>
                <div className = {'section-body'}>
                    <Paper className = {'mb-1'}>
                            <div className = {'p-1rem box-details'}>
                                <ul className = {'list-standard'}>
                                    <li className = {'list-item list-item-status'}>
                                        <label>Company Name	</label>
                                        <span className = {'value'}> {(props.CompanyDetails && typeof props.CompanyDetails.company_name !== 'undefined') ? props.CompanyDetails.company_name : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Short Name</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.short_name !== 'undefined') ? props.CompanyDetails.short_name : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Street</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.street !== 'undefined') ? props.CompanyDetails.street : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>State</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.state !== 'undefined') ? props.CompanyDetails.state : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Zip</label>
                                        <span className = {'value'}>{( props.CompanyDetails && typeof props.CompanyDetails.zip !== 'undefined') ? props.CompanyDetails.zip : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Fax</label>
                                        <span className = {'value'}>{( props.CompanyDetails && typeof props.CompanyDetails.fax !== 'undefined') ? props.CompanyDetails.fax : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Website</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.website !== 'undefined') ? props.CompanyDetails.website : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label> Company Information</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.company_info !== 'undefined') ? props.CompanyDetails.company_info : []}</span>
                                    </li> 
                                </ul>
                                <ul className = {'list-standard list-item-right'}>
                                    <li className = {'list-item'}>
                                        <label>Address</label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.addr1 !== 'undefined') ? props.CompanyDetails.addr1 : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label></label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.addr2 !== 'undefined') ? props.CompanyDetails.addr2 : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>City</label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.city !== 'undefined') ? props.CompanyDetails.city : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Country</label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.country !== 'undefined') ? props.CompanyDetails.country : []}</span>
                                    </li>
                                    <li className = {'list-item'}>
                                        <label>Telephone</label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.telephone !== 'undefined') ? props.CompanyDetails.telephone : []}</span>
                                    </li>
                                    <>
                                    <li className = {'list-item'}>
                                        <label>Email</label>
                                        <span className = {'value left'}>{(props.CompanyDetails && typeof props.CompanyDetails.email !== 'undefined') ? props.CompanyDetails.email : []}</span>
                                    </li>
                                    </>
                                    <li className = {'list-item'}>
                                        <label>Support Email</label>
                                        <span className = {'value'}>{(props.CompanyDetails && typeof props.CompanyDetails.support_email !== 'undefined') ? props.CompanyDetails.support_email : []}</span>
                                    </li> 
                                </ul>
                            </div>
                    </Paper>   
            </div>
            <DialogBoxMedium
                    Open = { props.Open }
                    Cancel = { props.OnClose }
                    Title = { props.FormFields && props.FormFields.company_id  ? 'Edit Company Details' : 'Add Company Details' }
                    OnSubmit = { props.HandleSubmit }
                    Content = { <DialogAdd FormProps = { props }/> }
                />
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant = {props.SnackbarVariant} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }    
            </>
        </React.Fragment>
    )   
}

const DialogAdd = (props) => {
const { FormFields, HandleOnChange, Errors } = props.FormProps; 
return (
    <form className = "form-fullwidth">
            <Grid container spacing = {3}>
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            required
                            id = "company_name"
                            name = "company_name"
                            label = "Company Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.company_name ))? FormFields.company_name : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.company_name !== 'undefined' && Errors.company_name)&& true }
                            helperText = { typeof Errors.company_name !== 'undefined' && Errors.company_name }
                        />
                    </ FormControl>
                </Grid>        
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            required
                            id = "short_name"
                            name = "short_name"
                            label = "Short Name"
                            type = "text"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.short_name ))? FormFields.short_name : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.short_name !== 'undefined' && Errors.short_name) && true }
                            helperText = { typeof Errors.short_name !== 'undefined' && Errors.short_name }
                        />
                    </ FormControl>
                </Grid>                       
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "street"
                            name = "street"
                            label = "Street"
                            type = "text"
                            fullWidth
                            onChange = {HandleOnChange}
                            InputLabelProps = {{ shrink: true }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.street ))? FormFields.street : '' }
                            error = { (typeof Errors.street !== 'undefined' && Errors.street) && true }
                            helperText = { typeof Errors.street !== 'undefined' && Errors.street }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "state"
                            name = "state"
                            label = "State"
                            type = "text"
                            fullWidth
                            onChange = {HandleOnChange}
                            InputLabelProps = {{ shrink: true }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.state ))? FormFields.state : '' }
                            error = { (typeof Errors.state !== 'undefined' && Errors.state) && true }
                            helperText = { typeof Errors.state !== 'undefined' && Errors.state }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField                            
                            id = "zip"
                            name = "zip"
                            label = "Zip"
                            type = "text"
                            fullWidth
                            onChange = {HandleOnChange}
                            InputLabelProps = {{ shrink: true }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.zip ))? FormFields.zip : '' }
                            error = { (typeof Errors.zip !== 'undefined' && Errors.zip) && true }
                            helperText = { typeof Errors.zip !== 'undefined' && Errors.zip }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "fax"
                            name = "fax"
                            label = "Fax"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.fax ))? FormFields.fax : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.fax !== 'undefined' && Errors.fax) && true }
                            helperText = { typeof Errors.fax !== 'undefined' && Errors.fax }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "website"
                            name = "website"
                            label = "Website"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.website ))? FormFields.website : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.website !== 'undefined' && Errors.website) && true }
                            helperText = { typeof Errors.website !== 'undefined' && Errors.website }
                        />
                    </ FormControl>
                </Grid>                               
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "company_info"
                            name = "company_info"
                            label = "Company Info"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.company_info ))? FormFields.company_info : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.company_info !== 'undefined' && Errors.company_info) && true }
                            helperText = { typeof Errors.company_info !== 'undefined' && Errors.company_info }
                        />
                    </ FormControl>
                </Grid>   
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "addr1"
                            name = "addr1"
                            label = "Address 1"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.addr1 ))? FormFields.addr1 : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.addr1 !== 'undefined' && Errors.addr1) && true }
                            helperText = { typeof Errors.addr1 !== 'undefined' && Errors.addr1 }
                        />
                    </ FormControl>
                </Grid>          
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "addr2"
                            name = "addr2"
                            label = " "
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.addr2 ))? FormFields.addr2 : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.addr2 !== 'undefined' && Errors.addr2) && true }
                            helperText = { typeof Errors.addr2 !== 'undefined' && Errors.addr2 }
                        />
                    </ FormControl>
                </Grid>          
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "city"
                            name = "city"
                            label = "City"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.city ))? FormFields.city : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.city !== 'undefined' && Errors.city) && true }
                            helperText = { typeof Errors.city !== 'undefined' && Errors.city }
                        />
                    </ FormControl>
                </Grid>          
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "country"
                            name = "country"
                            label = "Country"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.country ))? FormFields.country : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.country !== 'undefined' && Errors.country) && true }
                            helperText = { typeof Errors.country !== 'undefined' && Errors.country }
                        />
                    </ FormControl>
                </Grid>          
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "telephone"
                            name = "telephone"
                            label = "Telephone"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.telephone ))? FormFields.telephone : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.telephone !== 'undefined' && Errors.telephone) && true }
                            helperText = { typeof Errors.telephone !== 'undefined' && Errors.telephone }
                        />
                    </ FormControl>
                </Grid>          
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            id = "email"
                            name = "email"
                            label = "Email"
                            required
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.email ))? FormFields.email : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.email !== 'undefined' && Errors.email)&& true }
                            helperText = { typeof Errors.email !== 'undefined' && Errors.email }
                        />
                    </ FormControl>
                </Grid>     
                <Grid item xs = {12} md = {6}>
                    <FormControl>
                        <TextField
                            required
                            id = "support_email"
                            name = "support_email"
                            label = "Support Email"
                            type = "text"
                            fullWidth
                            InputLabelProps = {{ shrink: true }}
                            value = {( _R.type(FormFields) !== 'Undefined' && _R.type (FormFields.support_email ))? FormFields.support_email : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.support_email !== 'undefined' && Errors.support_email)&& true }
                            helperText = { typeof Errors.support_email !== 'undefined' && Errors.support_email }
                        />
                    </FormControl>
                </Grid>                                 
            </Grid>
        </form>
    )
}