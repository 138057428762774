import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { formatName } from '../../common/common';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

const API_URL = process.env.REACT_APP_API_URL;
export const ChargeabilityReportComponent = (props) =>{

     const gridStyle = {
        margin: '15px 0px'
    };
        return(
            <React.Fragment>
            <Paper md={12}>
            <div class="p1">
            <Typography variant="h6" component="h6" className={'section-title'}>Resource Chargeability Report</Typography>
                <form className="form-fullwidth" action={API_URL+'api/report/export-employee-chargeability-report'} method="post" id = "ChargeabilityReportForm">
                    <Grid container spacing={3} style={gridStyle}>               
                        <Grid item  md={2} className='justify-between'>
                            <FormControl error = {props.Errors.year && true} variant="standard">
                                <InputLabel htmlFor="year">Year</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.year !== 'undefined') ? props.ReportFields.year : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'year',
                                        id:   'year',
                                    }}>
                            
               { typeof props.ReportFields.year != 'undefined' && props.ReportInputs.years !=='' && props.ReportInputs.years.map((t, i) =>
                                     <MenuItem value={t} key={'cst-'+i}>{t}</MenuItem>
                                )} 
                                </Select>
                                <FormHelperText>{props.Errors.year}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={2}>
                            <FormControl error = {props.Errors.month && true} variant="standard">
                                <InputLabel htmlFor="month" required>Month</InputLabel>
                                <Select
                                   value={(typeof props.ReportFields.month !== 'undefined') ? props.ReportFields.month : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'month',
                                        id: 'month',
                                    }}>
                                 {  typeof props.ReportInputs.months != 'undefined' && props.ReportInputs.months !== '' && Object.keys(props.ReportInputs.months).map((t, i) =>
                                        <MenuItem value={i+1} key={'month-'+i}>{props.ReportInputs.months[t]}</MenuItem>
                                    )} 
                                </Select>
                                <FormHelperText>{props.Errors.month}</FormHelperText>
                            </ FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl error = {props.Errors.name_format && true} variant="standard">
                                <InputLabel htmlFor="name_format">Report Name Format</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.name_format !== 'undefined') ? props.ReportFields.name_format : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'name_format',
                                        id: 'name_format',
                                    }}>
                                    {typeof props.ReportInputs.name_formats!= 'undefined' && props.ReportInputs.name_formats !== '' && props.ReportInputs.name_formats.map((k,i) =>
                                    <MenuItem value={k.key}>{k.value}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>{props.Errors.name_format}</FormHelperText>
                            </ FormControl>
                        </Grid>
                         <Grid item  md={4}>
                                <FormControl variant="standard">
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked = {(typeof props.ReportFields.include_inactive_employees !== 'undefined') ? props.ReportFields.include_inactive_employees : false}  
                                                value = {(typeof props.ReportFields.include_inactive_employees !== 'undefined') ? props.ReportFields.include_inactive_employees : null}
                                                id = "include_inactive_employees" 
                                                name = "include_inactive_employees"
                                                onChange = {props.HandleOnChange}
                                                color="primary"
                                            />
                                        }
                                        label="Include inactive resources"
                                    />
                                     </ FormControl>
                            </Grid>
                         <input type='hidden' name='excluded_resources' value={props.ReportFields.excluded_resources}/>
                         <Grid item  md={8}>
                            <FormControl>
                              <Autocomplete
                                    value = {(typeof props.ReportFields.excluded_staff !== 'undefined' && props.ReportFields.excluded_staff) ? props.ReportFields.excluded_staff  : []}
                                    onChange={props.handleRecipientChange}
                                    multiple
                                    id="tags-outlined"
                                    options={ (typeof props.ReportInputs.staff_list !== 'undefined') ? props.ReportInputs.staff_list  : []}
                                    getOptionLabel={(option) => formatName(option.first_name, null, option.last_name)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Exclude Resources"
                                    placeholder="Exclude Resources"
                                    />
                                    )}
                                /> 
                              {typeof props.ReportFields.excluded_staff !== 'undefined' && props.ReportFields.excluded_staff.length > 0 &&
                                 <div>{props.ReportFields.excluded_staff.length} resource excluded</div>
                              }
                            </FormControl>
                            </Grid>
                        <Grid item >
                            <Button variant="contained" size="small" color="primary" name="export" onClick = {props.FormSubmit} >Export</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            </Paper>


            </React.Fragment>


            )
    
}

