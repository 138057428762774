import React from "react";
import { SubProjectComponent } from "./SubProject.Component";
import { httpServices } from "../../../services/httpServices";
import { ErrorPage } from "../../common/error";
import { CustomProgressBar } from "../../common/CustomProgressBar";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class SubProjectContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: true,
            loaderOpen: false,
            projectId: 0,
            parent : 0,
            subprojects: [],
            dialogOpen: false,
            snackbarOpen : false,
            projectAllEditPermission : false,
            snackbarMsg : '',
            popupType : '',
            nameFormat : 'FIRSTLAST',
            filterViewState : false,
            projectStatus : 1,
            projectStatuses : []
        };
        this.getSubProjects = this.getSubProjects.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onView = this.onView.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.updateParentOnAddSuccess = this.updateParentOnAddSuccess.bind(this);
        this.updateParentOnEditSuccess = this.updateParentOnEditSuccess.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleFilterOnChange = this.handleFilterOnChange.bind(this);
        this.handleFilterOnSubmit = this.handleFilterOnSubmit.bind(this);
    }
    componentDidMount() {
        this.getSubProjects();
    }
     snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onUpdate = (id) => {
        this.setState({
            dialogOpen : true,
            popupType : 'edit_project',
            projectId : id,
        });
    };
    onAdd = () => {
        this.setState({
            dialogOpen : true,
            popupType : 'add_project',
            parent : this.props.ProjectId,
        });
    };
    onView = (id) => {
        window.location.assign(BASE_PATH+'/projects/view/'+id); 
   };
    filterView = (anchor, open) => (event) => {
        this.setState({ filterViewState: open });
    };
    handleFilterOnSubmit = () =>{
        this.setState({filterViewState : false});
        this.getSubProjects();
    }
    handleFilterOnChange = (evt, value) => {       
        this.setState({
            projectStatus: evt.target.value
        });
    }
    updateParentOnEditSuccess = (status) => {
        if (status) {
         this.setState({
            dialogOpen : false,
            snackbarOpen : true,
            snackbarMsg : 'Project updated successfully',
        }, function () {
            this.getSubProjects();
        });
      }
        else
        {
             this.setState({
            dialogOpen : false,
        });

        }
    }
    updateParentOnAddSuccess = (status) => {
        if (status) {
            this.setState({
                dialogOpen: false,
                snackbarOpen: true,
                snackbarMsg: 'Project added successfully',
            }, function () {
                this.getSubProjects();
            });
        }
        else {
            this.setState({
                dialogOpen: false,
            });

        }
    }
    getSubProjects = () => {
        this.setState({ loaderOpen: true });
        return httpServices
            .post("api/project/getSubProjects", {
                projectid: this.props.ProjectId, 
                status: this.state.projectStatus
            })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        subprojects: response.data.projects,
                        permission: response.data.permission,
                        projectAllEditPermission: response.data.projectAllEditPermission,
                        loaderOpen: false,
                        projectStatuses : response.data.statuses
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    render() {
        return (
             <>
            <CustomProgressBar
                loaderOpen={this.state.loaderOpen}
            />
            {(this.state.permission) ?
            <SubProjectComponent
                SubProjects={this.state.subprojects}
                ProjectAllEditPermission = {this.state.projectAllEditPermission}
                OnUpdate = {this.onUpdate}
                LoaderOpen={this.state.loaderOpen}
                Parent={this.state.parent}
                OnAdd = {this.onAdd}
                OnView = {this.onView}
                UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
                ProjectId = {this.state.projectId}
                DialogOpen = {this.state.dialogOpen}
                UpdateParentOnEditSuccess = {this.updateParentOnEditSuccess}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                PopupType = {this.state.popupType}
                SnackbarClose = {this.snackbarClose}
                NameFormat = {this.state.nameFormat}
                FilterView = {this.filterView}
                FilterViewState = {this.state.filterViewState}
                ProjectStatuses = {this.state.projectStatuses}
                ProjectStatus = {this.state.projectStatus}
                HandleFilterOnSubmit = {this.handleFilterOnSubmit}
                HandleFilterOnChange ={this.handleFilterOnChange}

                />
           : <ErrorPage/>
                 }
           </>
        );

    }
}

