import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as _R from 'ramda';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const DialogBox = styled(Dialog)(({ theme }) => ({
    '& .root': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .wrapper': {
    margin: theme.spacing(1),
    position: 'relative',
  },  
  '& .buttonProgress': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  '& .dialogTitle': {
    color: '#333',
    fontSize: '.9rem', 
    fontWeight: '700', 
    letterSpacing: '.005em', 
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

export const DialogBoxLarge = (props) => {
    return (
        <DialogBox
                className={'modal_default large'}
                open={props.Open}
                aria-labelledby="scroll-dialog-title"
            >
            {props.HideCancel ?
                <DialogTitle id="scroll-dialog-title" className='dialogTitle'>{props.Title}<IconButton onClick={props.Cancel}>
                    <CloseIcon />
                </IconButton></DialogTitle>
                : <DialogTitle id="scroll-dialog-title" className='dialogTitle'>{props.Title}</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText className ={props.ErrorMessage && 'error'}>{props.ErrorMessage && props.ErrorMessage}</DialogContentText>
                    {props.Content}
                </DialogContent>
                {props.HideSaveButton ? props.HideCancel ? <></> : <DialogActions>
                <Button color="primary" size="small" tabindex={_R.type(props.ButtonTabIndex) !== 'Undefined' ? props.ButtonTabIndex : "0"} onClick={props.Cancel}>{props.CancelLabel ? props.CancelLabel : "Cancel"}</Button>
                </DialogActions> 
                :
                <DialogActions>
                    {(props.DisplayCheckBox ) &&
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(props.FormValues && typeof props.FormValues.sent_notification !== 'undefined') ? props.FormValues.sent_notification : true}
                                        value={ props.FormValues.sent_notification }
                                        inputProps={{
                                            id : "sent_notification",
                                            name : "sent_notification",
                                        }}
                                        onChange={props.HandleOnChange}
                                        color="primary"
                                    />
                                }
                                label="Send email notification to task assignees"
                            />
                        </FormControl>
                    }
                    <Button color="primary" size="small" tabindex={_R.type(props.ButtonTabIndex) !== 'Undefined' ? props.ButtonTabIndex : "0"}  onClick={props.Cancel}>Cancel</Button>
                    {props.Disabled ?
                        <Button tabindex={_R.type(props.ButtonTabIndex) !== 'Undefined' ? props.ButtonTabIndex:"0"} variant="contained" size="small" color="primary" disabled onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                    :   
                        <div className={'root'}>
                            <div className={'wrapper'}>
                                <Button tabindex={_R.type(props.ButtonTabIndex) !== 'Undefined' ? props.ButtonTabIndex : "0"}  disabled = {_R.type(props.Loading) !== 'Undefined' && props.Loading} variant="contained" size="small" color="primary" onClick={props.OnSubmit}>{props.ButtonName ? props.ButtonName : 'Save'}</Button>
                                { _R.type(props.Loading) !== 'Undefined' && props.Loading && <CircularProgress size={24} className={'buttonProgress'} />}
                            </div>
                        </div>
                    }
                    {props.DisplayNoButton && 
                        <Button variant="contained" size="small" tabindex={_R.type(props.ButtonTabIndex) !== 'Undefined' ? props.ButtonTabIndex : "0"}  color="primary" onClick={props.OnNoClick}>No</Button>
                    }
                </DialogActions>}
            </DialogBox> 
    )
}

