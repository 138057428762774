import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as _R from 'ramda'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import FormHelperText from '@mui/material/FormHelperText';
const API_URL = process.env.REACT_APP_API_URL;
export const StaffDetailsReportComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    let columns = (_R.type(props.FormInputs.reportColumns) != 'Undefined' && props.FormInputs.reportColumns) ? props.FormInputs.reportColumns : [];
    let selectedColumnsArr = (typeof props.SelectedColumns !== 'undefined' && props.SelectedColumns !== '') ? JSON.parse(props.SelectedColumns) : [];
    if (columns.length) {
        _ts.findStaffDetailsReportColumns(columns, selectedColumnsArr);
    }
    return (
        <Paper>
            <div className={'p1'}>
                <Typography variant="h6" component="h6" className={'section-title'}>Staff Details Report</Typography>
                <form id="export-form" className="form-fullwidth" action={API_URL+'api/staff/export'} method="post">
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item  md={3} className='justify-between'>
                            <FormControl error={props.Errors.staff_status && true} variant="standard">
                                <InputLabel htmlFor="emp_category">Staff Status</InputLabel>
                                    <Select
                                        value = {_R.type(props.FormValues.staff_status) !== 'Undefined' ? props.FormValues.staff_status : '0'}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'staff_status',
                                            id:   'staff_status',
                                        }}>
                                        <MenuItem value={"0"}>All</MenuItem>
                                        {_R.type(props.FormInputs.staffStatues) != 'Undefined' && props.FormInputs.staffStatues.map((ss, i) =>
                                        <MenuItem value={ss.user_status_id}>{ss.user_status_name}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText >{props.Errors.staff_status}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={3} className='justify-between'>
                            <FormControl error={props.Errors.staff_type && true} variant="standard">
                                <InputLabel htmlFor="emp_category">Staff Type</InputLabel>
                                    <Select
                                        value = {_R.type(props.FormValues.staff_type) !== 'Undefined' ? props.FormValues.staff_type : '0'}
                                        onChange={props.HandleOnChange}
                                        inputProps={{
                                            name: 'staff_type',
                                            id:   'staff_type',
                                        }}>
                                        <MenuItem value={'0'}>All</MenuItem>
                                        {_R.type(props.FormInputs.staffTypes) != 'Undefined' && props.FormInputs.staffTypes.map((st, i) =>
                                        <MenuItem value={st.id}>{st.type}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText >{props.Errors.staff_type}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item  md={3} className='justify-between'>
                            <FormControl>
                                <TreeSelectComponent
                                    dropdown_mode={false}
                                    value={selectedColumnsArr}
                                    data={columns}
                                    placeholderText={"Select Columns"}
                                    HandleTreeChange={props.HandleTreeChange}
                                    keepOpenOnSelect = {true}
                                />
                            </FormControl>
                            <input type='hidden' name='selected_columns' value={props.SelectedColumns}/>
                        </Grid>
                        <Grid item md={3}>
                            <Grid container spacing={2} style={gridStyle}>
                                <Button variant="contained" size="small" color="primary" onClick={props.ExportReports} >Export</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Paper>
    )
}
