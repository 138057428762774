import React from 'react';

export const ApiErrorPage = (props) => {
    let error = localStorage.getItem('server-error');
    let error_obj = {};
    if (error) {
        error_obj = JSON.parse(error);
    }
    return (
        <>
            <p className='error' align='center'>
                {error_obj.server_response ? (error_obj.server_response.name +': '+ error_obj.server_response.message ) : ''} 
            </p>
            { error_obj.api_endpoint ?
            <p className='error' align='center'>
                Requested API url {error_obj.api_endpoint ? error_obj.api_endpoint : ''}
            </p>
            : 
            <p className='error' align='center'>
                Something went wrong please try again later
            </p>
            }
        </>
    )
}

