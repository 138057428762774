import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { LocationAddComponent } from './LocationAdd.Component';
import * as _ts from '../../common/treeSelect';

const formFields = {
    'id': 0,
    'start_date': null,
    'start_date_obj': null,
    'end_date': null,
    'end_date_obj': null,
    'projects': '',
    'hours': null,
    'duration': 'full',
    'file': '',
    'file_name': '',
    'reference_no': '',
    'description': '',
    'remove_file': false,
    'custom_recipient': [],
};
const filters = {
    'reviewers': '',
    'custom_recipients': '',
    'joined_date': null,
    'recipients': '',
};
let getProjectTree = function (dataObj, selected_projects, result) {
    dataObj.forEach(function (e) {
        if ((selected_projects && selected_projects === e.project_id) || !selected_projects) {
            if (e.lcr_required && !e.default ) {
                result.push(e.project_id);
            }
            if (e.children) {
                getProjectTree(e.children, 0, result);
            }
        } else if (selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects, result);
        }
    });

}
export class AddLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateData: {},
            filters: filters,
            errors: {},
            open: false,
            formFields: formFields,
            isEdit: true,
            snackbar: false,
            valid: false,
            success_message: null,
            error_message: null,
            flag: false,
            file: {},
            approvedTimesheetErr : '',
            loading : false,
            timesheet_location : 0
        }
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleRecipientChange = this.handleRecipientChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    };
    componentDidMount = () => {
        if (this.props.StartDate) {
            let formFields = this.state.formFields;
            formFields['start_date'] = this.props.StartDate;
            let sDateArr = this.props.StartDate.split('-');
            formFields['start_date_obj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
            if (!formFields['end_date']) {
                formFields['end_date'] = this.props.StartDate;
                formFields['end_date_obj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
            }
            this.setState({
                formFields: formFields,
            });
        }
        this.getFormInputs(this.props.EditId);
        this.getCustomRecipients();
    };
    handleTreeChange = (evt, cNode, sNodes, action) => {
        if (typeof sNodes[0] !== 'undefined') {
            this.getLocationApprovers(sNodes[0].project_id);
            this.getFormInputs(0, sNodes[0].project_id);
        }
        else {
            this.getLocationApprovers(-1);
        }
        //this.getCustomRecipients(cNode.project_id);
        let projects = [];
        let tasks = [];
        let filters = this.state.filters;
        if (cNode.value === 'selectAll') {
            if (cNode.checked) {
                if (action === 'project') {
                    projects.push(0)
                }
                else if (action === 'tasks') {
                    tasks.push(0);
                }
            }
        }
        else {
            if (sNodes && sNodes.length >= 0) {
                sNodes.forEach(function (el) {
                    if (el.project_id || el.task_id || el.user_id || el.client_id) {
                        if (action === 'project') {
                            getProjectTree(filters.projects, el.project_id, projects);
                        } else if (action === 'tasks') {
                            tasks.push(el.task_id);
                        }

                    }
                });
            }
        }
        if (action === 'project') {
            formFields['projects'] = projects;
        }
        this.setState({ formFields: formFields }, function () {
            //if (action === 'project') {
            //  this.getTasksByProject(projects);
            // }
        });
    }

    getCustomRecipients = (project_id = 0) => {
        let apiEndpoint = 'api/location/getCustomRecipients';
        let action = '';
        if (this.props.EditId) {
            action = "request_edit";
        }
        else {
            action = "request_create";
        }
        return httpServices.post(apiEndpoint, { 'project': project_id, 'action': action })
            .then(response => {
                if (response.data) {
                    filters.custom_recipients = response.data.customrecipients;
                    //filters.primary = response.data.primary;
                    this.setState({
                        filters: filters,
                    });
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });

    };


    getLocationApprovers = (project_id) => {
        let apiEndpoint = 'api/location/getLocationApprovers';
        let action = '';
        if (this.props.EditId) {
            action = "request_edit";
        }
        else {
            action = "request_create";
        }
        let formFields = this.state.formFields;
        return httpServices.post(apiEndpoint, { 'id': project_id, 'action': action, start_date: formFields.start_date, end_date: formFields.end_date })
            .then(response => {
                if (response.data) {
                    filters.reviewers = response.data.reviewer;
                    filters.primary = response.data.primary;
                    filters.recipients = response.data.recipients.concat(response.data.reviewer);
                    this.setState({
                        filters: filters,
                    });
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });

    };

    handleDateChange = (value, name) => {
        const form_field = this.state.formFields;
        form_field[name] = value;
        if (name == 'start_date') {
            if (form_field['start_date'] > form_field['end_date']) {
                form_field['end_date'] = form_field['start_date'];
            }
        }
        this.setState({
            formFields: form_field
        }, function () {
            this.getFormInputs();
            let project_id = this.state.formFields.projects.length ? this.state.formFields.projects[0] : -1;
            this.getLocationApprovers(project_id);
        })
    };

    getStandardDates = (date) => {
        let resObj = {
            modified_date: '',
            display_date: '',
        }
        let dateobj = new Date(date);
        let year = dateobj.getFullYear();
        let month = dateobj.getMonth() + 1;
        let tempMonth = dateobj.getMonth();
        if (month < 10) {
            month = '0' + month;
        }
        let cdate = dateobj.getDate();
        let tempDate = dateobj.getDate();
        if (cdate < 10) {
            cdate = '0' + cdate;
        }
        resObj['modified_date'] = year + '-' + month + '-' + cdate;
        resObj['display_date'] = new Date(year,tempMonth,tempDate); // Creating standard date object
        return resObj;
    }

    getFormInputs = (id, project = 0) => {
        if (id) {
            this.onEditRequest(id);
        }
        else {
            let apiEndpoint = 'api/location/getFormInputs';
            return httpServices.post(apiEndpoint, { 'sdate': this.state.formFields.start_date, 'edate': this.state.formFields.end_date, 'location': this.state.formFields.location, 'project' : project })
                .then(response => {
                    let projects = response.data.projects;
                    formFields.location = response.data.location;
                    formFields.locations = response.data.locations;
                    let ids = response.data.locations.map( el => el.id );
                    if (!(ids.includes(parseInt(response.data.location)))) {
                        formFields.location = '';
                    }
                    if (response.data.location_multiple_projects === "yes") {
                        filters.projects = _ts.prepareTreeData(projects, 'projects', false, true);
                    }
                    else {
                        filters.projects = _ts.prepareTreeData(projects, 'projects', false);
                    }
                    filters.relieved_date = response.data.relieved_date;
                    filters.joined_date = response.data.joined_date;
                    this.setState({
                        filters: filters,
                        open: true,
                        flag: true,
                        formFields: formFields,
                        timesheet_location : response.data.timesheet_location
                    });
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    };

    onClose = () => {
        const f = this.state.formFields;
        f['start_date'] = '';
        f['end_date'] = '';
        f['projects'] = '';
        f['hours'] = '';
        f['duration'] = 'full';
        f['location'] = '';
        f['file'] = '';
        f['file_name'] = '';
        f['id'] = 0;
        f['description'] = '';
        f['reference_no'] = '';
        f['custom_recipient'] = [];
        f['remove_file'] = false;
        f['start_date_obj'] = null;
        f['end_date_obj'] = null;
        this.setState({
            open: false,
            errors: {},
            flag: true,
            formFields: f,
        });
        this.props.UpdateParent(false);
    }
    handleEditSubmit = (event) => {
        if (this.handleValidation()) {
            this.setState({ loading : true });
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/location/edit', { data, 'doc': this.state.file, 'custom_users': JSON.stringify(Object.values(this.state.formFields.custom_recipient)) })
                    .then(response => {
                        if (response.data.success) {
                            let attachment_msg = '';
                            if (response.data.attachment_details && response.data.attachment_details.status === false) {
                                 attachment_msg = 'Failed file attachment.';
                            }
                            this.setState({
                                snackbar: true,
                                valid: true,
                                success_message: "Updated Succesfully",
                                flag: true,
                                open: false,
                            });
                            this.onClose();
                            this.props.UpdateParent(true, attachment_msg);
                        } else {
                            let error_msg = response.data.error_messages;
                            let error_val = '';
                            if (typeof error_msg === 'object' && error_msg !== null) {
                                Object.values(error_msg).forEach(val => {
                                    error_val += val;
                                    error_val += ',';
                                });
                            }
                            else {
                                error_val = error_msg;
                            }                            
                            let snackbar = false;
                            if (error_val) {
                                snackbar = true;
                            }
                            let errors = response.data.errors ? response.data.errors : {};
                            this.setState({
                                flag: true,
                                open: true,
                                snackbar: snackbar,
                                valid: false,
                                error_message: error_val,
                                loading : false,
                                errors : errors,
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };


    handleSubmit = (event) => {
        if (this.state.formFields.id) {
            this.handleEditSubmit();
        }
        else if (this.handleValidation()) {
            this.setState({ loading : true });
            let data = this.state.formFields;
            if (data) {
                httpServices.post('api/location/create', { data, 'doc': this.state.file, 'custom_users': JSON.stringify(Object.values(this.state.formFields.custom_recipient)) })
                    .then(response => {
                        if (response.data.success) {
                            let attachment_msg = '';
                            if (response.data.attachment_details && response.data.attachment_details.status === false) {
                                 attachment_msg = 'Failed file attachment.';
                            }
                            //this.props.getOffsiteDetails("my");
                            this.setState({
                                snackbar: true,
                                valid: true,
                                //success_message: "Request submitted successfully",
                                //flag :true,
                                //open :false,
                            });
                            this.onClose();
                            this.props.UpdateParent(true, attachment_msg);
                            //this.props.DisplayRequestSuccess(true, "Request submitted successfully");

                        } else {
                            let error_msg = response.data.error_messages;
                            let error_val = '';
                            if (typeof error_msg === 'object' && error_msg !== null) {
                                Object.values(error_msg).forEach(val => {
                                    error_val += val;
                                    error_val += ',';
                                });
                            }
                            else {
                                error_val = error_msg;
                            }
                            error_val = error_val.replace(/,\s*$/, "");
                            let snackbar = false;                            
                            if (error_val) {
                                snackbar = true;
                            }
                            let errors = response.data.errors ? response.data.errors : {};
                            this.setState({
                                open: true,
                                snackbar: snackbar,
                                valid: false,
                                error_message: error_val,
                                loading : false,
                                errors: errors,
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const files = this.state.file;
        const name = evt.target.name;
        f[name] = evt.target.value;
        if (name === 'remove_file') {
            f[name] = evt.target.checked;
        }
        if (name === "file") {
            files['file'] = evt.target.files[0];
            this.setState({
                file: files,
            });
        }
        if (name === "start_date" || name === "end_date") {
            f['projects'] = '';
            this.getFormInputs();
        }
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({ errors: {} });
        }
    };
    onEditRequest = (id) => {
        return httpServices.post('api/location/getRequestDetails', { 'id': id })
            .then(response => {
                if (response.data.request_details && response.data.status) {
                    formFields.location = response.data.request_details.location_id;
                    formFields.start_date = response.data.request_details.start_date;
                    if (response.data.request_details.start_date) {
                        let sDateArr = response.data.request_details.start_date.split('-');
                        formFields['start_date_obj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
                    }
                    formFields.end_date = response.data.request_details.end_date;
                    if (response.data.request_details.end_date) {
                        let eDateArr = response.data.request_details.end_date.split('-');
                        formFields['end_date_obj'] = new Date(eDateArr[0],eDateArr[1]-1,eDateArr[2]);
                    }
                    formFields.hours = response.data.request_details.hours;
                    formFields.file_name = response.data.request_details.name;
                    formFields.attachment_id = response.data.request_details.attachment_id;
                    if (parseInt(response.data.request_details.hours) === parseInt(response.data.daily_min_hours)) {
                        formFields.duration = "full";
                    }
                    else if (parseInt(response.data.request_details.hours) === parseInt(response.data.daily_min_hours / 2)) {
                        formFields.duration = "half";
                    }
                    else if (parseInt(response.data.request_details.hours) === 0) {
                        formFields.duration = "no_limit";
                    }
                    else {
                        formFields.duration = "hour";
                    }
                    formFields.description = response.data.request_details.description;
                    formFields.reference_no = response.data.request_details.reference_no;
                    formFields.id = response.data.request_details.id;
                    formFields.projects = [response.data.request_details.project_id];
                    this.setState({
                        flag: true,
                        open: true,
                        formFields: formFields,
                        EditId: id,
                        approvedTimesheetErr : '',
                        timesheet_location : response.data.timesheet_location

                    }, function () {
                        this.getFormInputs(0, response.data.request_details.project_id);
                    });
                    this.getLocationApprovers(response.data.request_details.project_id);
                    this.getCustomRecipients(response.data.request_details.project_id);
                    formFields.custom_recipient = response.data.custom_users;
                    /*this.setState({
                        formFields: formFields,
                    });*/

                } else {
                    formFields.id = id;
                    if (response.data.error_msg) {
                        this.setState({
                            approvedTimesheetErr : response.data.error_msg,
                            formFields : formFields,
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    handleRecipientChange = (evt, value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') {
                //ff['county'] = 'new_'+value;
                //ff['county_name'] = value;
            } else if (typeof value === 'object' && value) {
                ff['custom_recipient'] = value;

            }
        } else {
            //ff['county'] = '';
            //ff['county_name'] = '';
        }
        this.setState({
            formFields: ff
        });
    };


    snackbarClose = () => {
        this.setState({
            snackbar: false,
            valid: false,
        });
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.location) {
            errors["location"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.end_date && this.state.formFields.start_date) {
            if (this.state.formFields.end_date < this.state.formFields.start_date) {
                errors["end_date"] = "End date should be greater than start date";
                formIsValid = false;
            }
        }

        if (!this.state.formFields.description.trim()) {
            errors["description"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.duration === "hour") {
            if (!this.state.formFields.hours) {
                errors["hours"] = "Cannot be empty";
                formIsValid = false;
            }

        }
        if (this.state.formFields.projects.length === 0) {
            errors["projects"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        return (
            <LocationAddComponent
                Open={this.state.flag ? this.state.open : this.props.Open}
                HandleOnChange={this.handleOnChange.bind(this)}
                FormFields={this.state.formFields}
                FormInputs={this.state.formInputs}
                Filters={this.state.filters}
                OnClose={this.onClose}
                HandleTreeChange={this.handleTreeChange}
                HandleSubmit={this.handleSubmit}
                Errors={this.state.errors}
                Snackbar={this.state.snackbar}
                Valid={this.state.valid}
                ErrorMessage={this.state.error_message}
                SuccessMessage={this.state.success_message}
                SnackbarClose={this.snackbarClose}
                handleRecipientChange={this.handleRecipientChange}
                HandleDateChange={this.handleDateChange}
                ApprovedTimesheetErr={this.state.approvedTimesheetErr}
                Loading = {this.state.loading}
                TimesheetLocation = {this.state.timesheet_location}

            />
        )
    }
};

