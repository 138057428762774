import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import parse from 'html-react-parser';

import * as _R from 'ramda';  // used 0.04kb
import { DialogBoxMedium } from '../../common/dialog.medium';
import { formatName } from '../../common/common';
import { HoursByProject } from '../details/HoursByProject';
import { TimeSheetView } from '../details/TimeSheetView';
import { StatusChangeLog } from '../details/StatusChangeLog';
import { CustomizedSnackbars } from '../../common/snackbars';
import { ProjectReviewStatus } from '../details/ProjectReviewStatus';
import { LateSubmission } from '../details/LateSubmission';
import { TimeOffRequests } from '../details/TimeOffRequests';
import { LocationChangeRequest } from '../details/LocationChangeRequest';
import { TimeOffLog } from '../details/TimeOffLog';
import { ClearanceConfirmation } from '../details/ClearanceConfirmation';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import RadioGroup from '@mui/material/RadioGroup';
import { Grid } from '@mui/material';

const DialogMissedDates = (props) => {
    return (
        <div>
            {(!_R.isEmpty(props.Validation) &&
                parse(props.Validation.dates)
            )}
        </div>
    )
}
function nl2br(str){
 return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

const DialogSubmitValidation = (props) => {
    return (
        <div>
            {(!_R.isEmpty(props.Validation) &&
                <>
                {Object.values(props.Validation.messages).map((msg) => 
                    <p>{ parse(msg) }</p>
                )}
                </>
            )}
        </div>
    )
}
const DialogAdjustHours = (props) => {
    return (
        <div>
            {(!_R.isEmpty(props.Validation) &&
                <div>
                    <p>You have saved <b>{props.Validation.hours_saved - props.Validation.min_hours} hours</b> more than the Minimum Pay Period Hours {props.Validation.min_hours} for this pay period. So the system would like to suggest the following changes to your Time Off hours to preserve your UTO/PTO/FTO credits.</p>

                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Time Off Type</TableCell>
                                <TableCell align="right">Saved Hours</TableCell>
                                <TableCell align="right">Suggested Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.Validation.adjusted_timeslices.map((t, index) =>
                                <TableRow key='timeslice-{index}'>
                                    <TableCell component="th" scope="row">{t.formatted_date}</TableCell>
                                    <TableCell component="th" scope="row">{t.to_type}</TableCell>
                                    <TableCell component="th" scope="row" align="right">{t.prv_hours}</TableCell>
                                    <TableCell component="th" scope="row" align="right">{t.adjusted_hours}</TableCell>
                                </TableRow>
                            )
                            }
                            <TableRow>
                                <TableCell component="th" scope="row" colspan={2}> Total Hours</TableCell>
                                <TableCell component="th" scope="row" align="right">{props.Validation.saved_hours_total}</TableCell>
                                <TableCell component="th" scope="row" align="right">{props.Validation.suggested_hours_total}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <p>Do you want to continue with the above suggestion?</p>
                </div>
            )
            }
        </div>
    )
}

const DialogSubmit = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
            <form className="form-fullwidth">
                {(props.LateSubmitValidationReq) &&
                            <Paper className={'mb-1'} style={{ padding: '16px', backgroundColor: '#fff3de' }}>
                    <div>
                        <div className={'pb-1'}>
                            <b>
                                {"We noticed a delay in your timesheet submission. Please provide a reason for the late submission. Your specified reason will be used to justify any waivers for this late submission"}
                            </b>
                        </div>
                        <div>
                            <TextField
                                id="late_submission_reason"
                                name="late_submission_reason"
                                label="Reason"
                                required
                                type="text"
                                multiline={true}
                                variant="outlined"
                                rows={4}
                                value={typeof props.FormFields.late_submission_reason !== 'undefined' ? props.FormFields.late_submission_reason : ''}
                                onChange={props.HandleOnChange}
                                error={props.Errors.late_submission_reason_error && true}
                                helperText={typeof props.Errors.late_submission_reason_error !== 'undefined' ? props.Errors.late_submission_reason_error : ''}
                            />
                        </div>
                    </div>
                    </Paper>
                }
                <Paper className={'mb-1'} style={{ padding: '16px', backgroundColor: '#e8ede1' }}>
                <div>
                    I <b>certify</b> that this is a true and correct report of the actual hours I worked during the period
                    <b> {props.Timesheet['payperiod_sdate']} to {props.Timesheet['payperiod_edate']}</b>
                    <br />
                </div>
                <FormControl required error={props.Errors.agree && true} component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(typeof props.FormFields.agree !== 'undefined') ? props.FormFields.agree : false}
                                value={(typeof props.FormFields.agree !== 'undefined') ? props.FormFields.agree : null}
                                id="agree"
                                name="agree"
                                onChange={props.HandleOnChange}
                            />
                        }
                        label="I agree"
                    />
                </FormControl>

                {(typeof props.FormFields.agree !== 'undefined' && props.FormFields.agree) &&
                    <TextField
                        required
                        id="initials"
                        name="initials"
                        label="Initials"
                        value={props.FormFields.initials}
                        variant="outlined"
                        onChange={props.HandleOnChange}
                        error={props.Errors.initials && true}
                        helperText={props.Errors.initials}
                    />
                }
                </Paper>
                {props.UserClearanceLevel && props.ClearanceLevelOptions && Object.keys(props.ClearanceLevelOptions).length > 0 &&
                    <Paper className={'mb-1'} style={{ padding: '16px', backgroundColor: '#d9eeff' }}>
                        <div className={'mb-1'}>
                            <b>To comply with the reporting requirements for cleared personnel, please check the appropriate box(es) below and, if applicable, follow the link to complete the required forms</b>
                        </div>
                        {props.UserClearanceLevel && Object.keys(props.ClearanceGroup).map((index,option) =>
                        <>
                            <Paper className={'mb-1'} style={{ padding: '16px', backgroundColor: '#d9eeff' }}>
                                {Object.keys(props.ClearanceGroup[index]).map((k, v) =>
                                    <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={props.SelectedClearanceLevels.includes(k)}
                                                    onChange={() => props.HandleClearanceChange(k, index)}
                                                />
                                            }
                                            label={parse(props.ClearanceGroup[index][k])}
                                        />
                                    </Grid>
                                )}
                            </Paper>    
                        </>
                    )}
                    <span className='error'>{props.Errors.clearance_error}</span>                   
                    </Paper>
                }
                
            </form>
        </React.Fragment>
    )
}
const DialogUnSubmitValidation = (props) => {
    let timesheet_id = props.Validation.timesheet_id;
    let link = timesheet_id;
    return (
        <React.Fragment>
            <p>{props.Validation.message}</p>
            {props.Validation.timesheet_id !== 0 &&
                <ul>
                    <li>Click <a href={link}>here</a> to unsubmit the timesheet.</li>
                </ul>
            }

        </React.Fragment>
    );
}
const DialogUnSubmitConfirm = (props) => {
    return (
        <React.Fragment>
            <p>Provide a reason for Unsubmitting your timesheet:</p>
            <TextField
                required
                id="reason"
                name="reason"
                label="Reason"
                multiline
                variant="outlined"
                fullWidth
                onChange={props.HandleOnChange}
                error={props.Errors.reason && true}
                helperText = {props.Errors.reason}
            />

        </React.Fragment>
    );
}


export const SubmitComponent = (props) => {
    //Tabs
    const [tabValue, setTabValue] =  React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    let fwsWarning = '';
    let fwsVariance = '';
    if (props.FormData.fws_var_info && typeof props.FormData.fws_var_info === 'object') {
        if (props.FormData.fws_var_info.fws_warning && props.FormData.fws_var_info.fws_warning !== '') {
            fwsWarning = props.FormData.fws_var_info.fws_warning;
        } else if (props.FormData.fws_var_info.fws_variance) {
            fwsVariance = props.FormData.fws_var_info.fws_variance;
        }
    }
    return (
        <div>
            <div className={'section-main section-main-header-fixed'}><div className={'header-fixed'} >
                <div className={'section-header'}>
                    <div className={'col-left'}>
                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                        <Typography variant="h6" component="h6" className={'section-title'}>
                            {(_R.type(props.FormData.timesheet) !== 'Undefined') &&
                            <>
                            {(props.FormData.can_submit) ? 'Submit Timesheet' : 'Unsubmit Timesheet'}
                            &nbsp;for {props.FormData.timesheet.payperiod_sdate} to {props.FormData.timesheet.payperiod_edate}
                            </>
                            }
                        </Typography>
                    </div>
                    <div className={'actions'}>
                        {props.FormData.can_submit &&
                            <Button variant="contained" color="secondary" onClick={props.OnSubmitClick}>Submit</Button>
                        }
                        {props.FormData.can_unsubmit &&
                            <Button variant="contained" color="secondary" onClick={props.OnUnSubmitClick}>UnSubmit</Button>
                        }
                    </div>
                </div></div>
                <div className={'section-body'}>
                    <Paper className={'mb-1'}>
                        {!_R.isEmpty(props.FormData) &&
                        <div className={'timesheet_list-summary'}>
                            <div className={'p-1rem summary box-details'}>
                                <ul className={'list-standard'}>
                                    <li className={'list-item list-item-status'}>
                                        <label>Status</label>
                                        <span className={'value'}>{props.FormData.timesheet.status_name}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Submitted On</label>
                                        <span className={'value'}>{props.FormData.timesheet.submitted_time}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Last Reviewed By</label>
                                        <span className={'value'}>{formatName(props.FormData.timesheet.rby_first_name,null,props.FormData.timesheet.rby_last_name)}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Last Reviewed On</label>
                                        <span className={'value'}>{props.FormData.timesheet.reviewed_time}</span>
                                    </li>
                                </ul>
                                <ul className={'list-standard list-item-right'}>
                                    {(_R.type(props.FormData.total_hours) !== 'Undefined' && props.FormData.total_hours.length > 0) && props.FormData.total_hours.map((t_hrs,index)=>
                                        <>{(parseInt(t_hrs.task_type) !== 0 && t_hrs.code === "WH") ?
                                            props.TimesheetLocation ?
                                            <>
                                                {(_R.type(props.FormData.ts_summary) !== 'Undefined' && typeof props.FormData.ts_summary.location_hours !== 'undefined' && props.FormData.ts_summary.location_hours.length > 0) && props.FormData.ts_summary.location_hours.map((l_hrs) =>
                                                    <li className={'list-item list-items-onsite-hours'}>
                                                        <label>Hours ({l_hrs.location})</label>
                                                        <span className={'value right'}>{parseFloat(l_hrs.location_hours).toFixed(2)}</span>
                                                    </li>
                                                )}
                                            </>
                                            :
                                            <></>
                                            : <>
                                                <li className={'list-item list-items-hours'}>
                                                    <label>{t_hrs.name}</label>
                                                    <span className={'value right'}>{t_hrs.hours}</span>
                                                </li>
                                            </>}
                                        </>
                                    )}
                                    <li className={'list-item list-items-hours'}>
                                        <label>Total Hours</label>
                                        <span className={'value right'}>{_R.type(props.FormData.ts_summary) !== 'Undefined' ? props.FormData.ts_summary.hours_saved : ''}</span>
                                    </li>
                                    <li className={'list-item list-items-min-hours'}>
                                        <label>Min. Hours Required</label>
                                        <span className={'value right'}>{_R.type(props.FormData.min_hours) !== 'Undefined' ? parseFloat(props.FormData.min_hours).toFixed(2) : ''}</span>
                                    </li>
                                    <>
                                    {(fwsVariance !== '') &&
                                    <li className={'list-item list-items-min-hours'}>
                                        <label>Standard Hours Variance </label>
                                        <span className={'value right'}>{fwsVariance}</span>
                                    </li>
                                    }
                                    </>
                                    {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                                    <>
                                    <li className={'list-item'}>
                                        <label>Delayed By</label>
                                        <span className={'value'}>{(typeof props.FormData.late_submission_details.delayed_by !== 'undefined') ? props.FormData.late_submission_details.delayed_by : ""}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Reason For Late Submission</label>
                                        <span className={'value'}>{(typeof props.FormData.late_submission_details.late_submission_reason !== 'undefined' &&  props.FormData.late_submission_details.late_submission_reason) ? parse(nl2br(props.FormData.late_submission_details.late_submission_reason)) : ""}</span>
                                    </li>
                                    <li className={'list-item'}>
                                        <label>Late Submission Review Comment</label>
                                        <span className={'value'}>{(typeof props.FormData.late_submission_details.waiver_justification !== 'undefined'&& props.FormData.late_submission_details.waiver_justification) ? parse(nl2br(props.FormData.late_submission_details.waiver_justification)) : ""}</span>
                                    </li>
                                    </>
                                    }
                                </ul>
                            </div>
                            {/*<div className={'chart'}>
                                <Chart data={props.donutData} className={'chart-view'}>
                                    <PieSeries
                                        valueField="val"
                                        argumentField="region"
                                        innerRadius={0.6}
                                    />
                                    <Animation />
                                </Chart>
                            </div>
                            */}
                        </div>
                        }
                        <>
                        {(fwsWarning !== '') &&
                        <div className={'fws-warning'}>{fwsWarning}</div>
                        }
                        </>
                    </Paper>
                    <div className={'timesheet_list'}>
                        <TimeSheetView
                            UserTimeIn = {props.FormData.user_timein}
                            UserTimeInReq = {props.FormData.user_timein_required}
                            UserTimeInDetails = {props.FormData.timeinout_rows}
                            Timesheet={props.FormData.timesheet}
                            TimeinTotal = {props.FormData.timein_total}
                            TotalHours = {props.FormData.total_hours}
                            TsSummary = {props.FormData.ts_summary}
                            MinHours = {props.FormData.min_hours}
                            DayOff = {props.FormData.day_off ? props.FormData.day_off.split(',') : []}
                            FwsVarianceInfo = {props.FormData.fws_var_info ? props.FormData.fws_var_info : {}}
                            listReviewNotes = {props.listReviewNotes}
                            ClientsCount = {props.FormData.clients_count ? parseInt(props.FormData.clients_count) : 0}
                            onTimeEntryEditClick = {props.onTimeEntryEditClick}
                            listActionMenuClick = {props.listActionMenuClick}
                            clickedTimeSliceId = {props.clickedTimeSliceId}
                            timeSliceEditDialogOpen = {props.timeSliceEditDialogOpen}
                            timeSliceEditDialogClose = {props.timeSliceEditDialogClose}
                            PopupType = {props.PopupType}
                            ButtonLoader = {props.ButtonLoader}
                            DialogOpen = {props.DialogOpen}
                            OnClose = {props.OnClose}
                            getTimesheetDetails = {props.getTimesheetDetails}
                            timeSheetId = {props.timeSheetId}
                            randomNumber = {props.randomNumber}
                            ViewType = {props.ViewType}
                            ReviewNotes = {props.ReviewNotes}
                            onTimeEntryDeleteClick = {props.onTimeEntryDeleteClick}
                            HandleTimeEntryDeleteReason = {props.HandleTimeEntryDeleteReason}
                            TimeEntryDeleteReason = {props.TimeEntryDeleteReason}
                            TimesliceDelete = {props.TimesliceDelete}
                            TimeEntryDeleteError = {props.TimeEntryDeleteError}
                            TimesheetId = {props.timeSheetId}
                            RequestedPage = {'submit-details'}
                            ForceRerender = {props.ForceRerender}
                            ShowEditSuccess = {props.ShowEditSuccess}
                            GetLockedDetails = {props.GetLockedDetails}
                            PopupMsg = {props.PopupMsg}
                            LockOpen = {props.LockOpen}
                        />
                    </div>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="Timesheet List View Tabs"
                            >
                                <Tab label="Hours By Project"/>
                                <Tab label="Status Change Log"/>
                                <Tab label="Time Off Request"/>
                                {(props.FormData.multiple_review === 1) &&
                                    <Tab label=" Project Review Status"/>
                                }
                                {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                                   <Tab label="Late Submission Status"/>
                                }
                                {props.TimesheetLocation &&
                                    <Tab label="Work Location Change Request"/>
                                }
                                <Tab label="Time Off Log"/>
                                {(props.FormData.user_clearence_level) &&
                                    <Tab label="Clearance Confirmation"/>
                                }
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabValue} index={0}>
                                <HoursByProject
                                    HoursByProject={props.FormData.chargecode_summary}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <StatusChangeLog
                                    StatusChangeLog={props.FormData.history}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <TimeOffRequests
                                    TimeOffRequests={props.FormData.time_off}
                                />
                            </TabPanel>
                            {(props.FormData.multiple_review === 1) &&
                                <TabPanel value={tabValue} index={3}>
                                    <ProjectReviewStatus
                                        ProjectReviewStatus={props.FormData.project_review}
                                        MultipleReview = {props.FormData.multiple_review}
                                    />
                                </TabPanel>
                            }
                            {(typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details) &&
                            <TabPanel value={tabValue} index={(props.FormData.multiple_review === 1) ? 4 : 3}>
                                <LateSubmission
                                    LateSubmissionDetails = {props.FormData.late_submission_details}
                                />
                            </TabPanel>
                            }
                            <TabPanel value={tabValue} index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 5 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 4 : 3}>
                                <LocationChangeRequest
                                    OffsiteRequest = {props.FormData.offsites}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue}  index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 6 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 5 : 4}>
                                <TimeOffLog
                                    TimeOffLog = {props.FormData.timeofflog}
                                />
                            </TabPanel>
                            {(props.FormData.user_clearence_level) &&
                                <TabPanel value={tabValue}  index={(props.FormData.multiple_review === 1 && (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 7 : (props.FormData.multiple_review === 1 || (typeof props.FormData.late_submission_details !== 'undefined' && props.FormData.late_submission_details)) ? 6 : 5}>
                                    <ClearanceConfirmation
                                        ClearanceConfirmation = {props.FormData.selectedClearanceOptions}                                    
                                    />                            
                                </TabPanel>
                            }   
                        </div>
                    </Paper>
                </div>
            </div>
            {(props.PopupType === 'missed-dates') &&
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.OnMissedDatesConfirm}
                    Title='Submit Timesheet'
                    ButtonName={props.DialogButton}
                    Content={
                        <DialogMissedDates
                            Validation={props.Validation}
                        />
                    }
                />
            }
            {(props.PopupType === 'submit-validate' || props.PopupType === 'submit-confirmation') &&
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={(props.PopupType === 'submit-validate') ? props.OnClose : props.ConfirmSubmitTimesheet}
                    Title={(props.PopupType === 'submit-validate') ? 'Submit Timesheet failed' : 'Confirm Timesheet Submission'}
                    ButtonName={(props.PopupType === 'submit-validate') ? props.DialogButton : 'Confirm'}
                    Content={
                        <DialogSubmitValidation
                            Validation={props.Validation}
                        />
                    }
                />
            }

            {props.PopupType === 'submit' &&                      
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SubmitTimesheet}
                    Title='Submit Timesheet'
                    ButtonName={props.DialogButton}
                    Disabled={props.SubmitDisabled}
                    Loading = {props.ButtonLoader}
                    Content={
                        <DialogSubmit
                            FormFields={props.FormFields}
                            Validation={props.Validation}
                            PopupType={props.PopupType}
                            Timesheet={props.FormData.timesheet}
                            ClearanceLevelOptions={props.FormData.clearence_level_options}
                            UserClearanceLevel={props.FormData.user_clearence_level}
                            HandleOnChange={props.HandleOnChange}
                            HandleClearanceChange={props.HandleClearanceChange}
                            SelectedClearanceLevels={props.SelectedClearanceLevels}
                            Errors={props.Errors}
                            LateSubmitValidationReq = {props.LateSubmitValidationReq}
                            ClearanceGroup = {props.clearanceByGroup}
                        />
                    }
                />
            }

            {(props.PopupType === 'adjusttimesheethours') &&
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    OnSubmit={props.SetAdjustHours(1)}
                    Title='System Suggestion: Adjust Time Off Hours'
                    DisplayNoButton={true}
                    OnNoClick={props.SetAdjustHours(0)}
                    ButtonName={props.DialogButton}
                    Content={
                        <DialogAdjustHours
                            Validation={props.Validation}
                        />
                    }
                />
            }

            {props.PopupType === 'unsubmit_validation' &&
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    ButtonName={props.DialogButton}
                    OnSubmit={props.OnClose}
                    Title='Timesheet Unsubmission failed'
                    Content={
                        <DialogUnSubmitValidation
                            Validation={props.Validation}
                            PopupType={props.PopupType}
                        />
                    }
                />
            }
            {props.PopupType === 'unsubmit_confirm' &&
                <DialogBoxMedium
                    Open={props.DialogOpen}
                    Cancel={props.OnClose}
                    ButtonName={props.DialogButton}
                    OnSubmit={props.UnSubmitTimesheet}
                    Title='Confirm Unsubmit'
                    Loading = {props.ButtonLoader}
                    Content={
                        <DialogUnSubmitConfirm
                            Validation={props.Validation}
                            HandleOnChange={props.HandleOnChange}
                            Errors={props.Errors}
                        />
                    }
                />
            }
            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant='success'
                message={props.SnackbarMsg}
                handleClose={props.SnackbarClose}
            />

        </div>
    );
}


