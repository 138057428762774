import React from 'react';

import {httpServices}  from '../../services/httpServices';
import {RoleDetailsComponent} from './RoleDetailsComponent';
import { CustomProgressBar } from '../common/CustomProgressBar';
import * as _ts from '../common/treeSelect';


export class RoleDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            role_id : 0,
            all_actions : [],
            selected_role_details : {},
            snackbarOpen : false,
            snackbarMsg : '',
            users : [],
            initial_users : [],
            all_roles : [],
            all_modules : [],
            filter_role : -1,
            filter_module : '',
            filter_module_add_action : '',
            user_search_key : '',
            open : false,
            popup_type : '',
            all_users : [],
            selectedUserList : [],
            selectedUserIds : [],
            temp_deleted_user_id : 0,
            temp_deleted_action_id : 0,
            loader : true,
            action_list : [],
            actions : [],
            action_name : '',
        };
    }
    componentDidMount = () => {
        let id = this.props.match.params.id;
        if (id) {
            this.setState({role_id : id});
            this.getRoleDetails(id);
        }
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.filter = this.filter.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.search = this.search.bind(this);
        this.addUserClick = this.addUserClick.bind(this);
        this.addAction = this.addAction.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSelectUser = this.onSelectUser.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUserRoleClick = this.deleteUserRoleClick.bind(this);
        this.deleteUserRole = this.deleteUserRole.bind(this);
        this.handleAddActionFilterChange = this.handleAddActionFilterChange.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.addActionToRole = this.addActionToRole.bind(this);
        this.getModuleActions = this.getModuleActions.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.deleteRoleActionClick = this.deleteRoleActionClick.bind(this);
        this.deleteRoleAction = this.deleteRoleAction.bind(this);
    };
    getRoleDetails = (id) => {
        let apiEndpoint = 'api/system/role/get-role-permissions';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                    if (response.data) {
                        this.setState({
                            all_actions : response.data.all_actions,
                            selected_role_details : response.data.selected_role_details,
                            users : response.data.users,
                            initial_users : response.data.users,
                            all_roles : response.data.all_roles,
                            all_modules : response.data.all_modules,
                            all_users : response.data.all_users,
                            loader : false,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    getModuleActions = (module) => {
        let apiEndpoint = 'api/system/role/get-module-actions';
        return httpServices.post(apiEndpoint,{module : module, role_id : this.state.role_id})
            .then(response => {
                    if (response.data) {
                        this.setState({                           
                            loader : false,
                            action_list : _ts.prepareTreeData(response.data.actions, 'actions')
                        });
                    }                
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let values = this.state.formInputs;
        let actions = [];     
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                if (action === 'actions') {
                    actions.push(0);
                }               
            }
        }
        else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.action_id) {
                    if (action === 'actions') {
                        actions.push(el.action_id);
                    }                               
                }
            });
        }            
        this.setState({actions : actions});
    }    
    handlePermissionChange = (action_id,index) => (evt) => {
        let permission = evt.target.checked;
        if (action_id) {
            let data = {
                action_id : action_id,
                role_id : this.state.role_id,
                permission : permission ? '1' : '0',
            };
            let apiEndpoint = 'api/system/role/update-role-permissions';
            return httpServices.post(apiEndpoint,data)
                .then(response => {
                    if (response.data) {
                        if (response.data) {
                            let all_actions = this.state.all_actions;
                            all_actions[index]['selected'] = permission;
                            this.setState({ 
                                all_actions : all_actions,
                                snackbarOpen : true,
                                snackbarMsg : 'Permission changed successfully',
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    handleFilterChange = (evt, value) => {
        let val = evt.target.value;
        this.setState({
            filter_module : val
        });
    };
    handleAddActionFilterChange = (evt) => {
        let val = evt.target.value;
        this.setState({
            filter_module_add_action : val
        }, function(){
            this.getModuleActions(this.state.filter_module_add_action);
        });
    };
    handleSearch = (evt,value) => {
        let val = evt.target.value;
        this.setState({
            user_search_key : val
        });
    };
    filter = () => {
        let data = {
            filter_module : this.state.filter_module,
            id : this.state.role_id,
        }
        let apiEndpoint = 'api/system/role/get-role-permissions';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data) {
                        this.setState({
                            all_actions : response.data.all_actions,
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    search = () => {
        let search_key = this.state.user_search_key.toUpperCase();
        let users = this.state.initial_users;
        let search_result = [];
        users.forEach((us,i)=>{
            if (us.first_name.toUpperCase().indexOf(search_key)>-1 || us.last_name.toUpperCase().indexOf(search_key)>-1 ){
                search_result.push(us);
            }
        }) 
        this.setState({users : search_result});
    }
    addUserClick = () => {
        this.setState({
            open : true,
            popup_type : 'add',
            selectedUserIds : [],
        });
    };
    addAction = () => {
        this.setState({
            open : true,
            popup_type : 'add_action',
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            actions : [],
            filter_module_add_action : ''
        });
    };

    onSelectUser = (evt,value) => {
        const val = evt.target.value;
        this.setState({
            selectedUserIds : val,
        }
        );
    }
    onRemove = (selectedList, selectedItem) => {
        let selected = [];
        selectedList.forEach((p, v) =>{
            selected.push(p.user_id);
        })
        this.setState({
            selectedUserIds : selected,
        }
        );
    }
    addUser = () => {
        let data = {
            user_ids : JSON.stringify(this.state.selectedUserIds),
            id : this.state.role_id,
        }
        let apiEndpoint = 'api/system/role/assign-user-to-role';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data) {
                        this.setState({
                            open:false,
                            snackbarOpen : true,
                            snackbarMsg : 'Added new users to this role'
                        },function(){
                            this.getRoleDetails(data['id']);
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    deleteRoleActionClick = (action_id, action)  => () => {        
        this.setState({
            popup_type : 'delete_role_action',
            open : true,
            temp_deleted_action_id : action_id,
            action_name : action
        });       
    }
    deleteRoleAction = () => {
        let data = {
            action_id : this.state.temp_deleted_action_id,
            role_id : this.state.role_id,
        };
        let apiEndpoint = 'api/system/role/delete-role-permission';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data.status) {                            
                        this.setState({ 
                            snackbarOpen : true,
                            open:false,
                            snackbarMsg : 'Action removed successfully',
                            temp_deleted_action_id : 0
                        });
                        if (this.state.filter_module) {
                            this.filter();
                        }
                        else {
                            this.getRoleDetails(this.state.role_id);
                        }
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    deleteUserRoleClick = (user_id,index) => () => {
        this.setState({
            popup_type : 'delete',
            open : true,
            temp_deleted_user_id : user_id,
        });
    }
    addActionToRole = () => {
        if (this.handleValidation()) {          
                httpServices.post('api/system/role/saveRolePermission', { role_id :this.state.role_id, actions : JSON.stringify(this.state.actions) })
                    .then(response => {
                        if (response.data.status) {                               
                                this.setState({
                                    open: false,
                                    snackbarMsg: "Role permission added successfully",
                                    snackbarOpen: true,
                                    snackbarType: "success",
                                    actions : [],
                                    filter_module_add_action : ''
                                });                             
                            this.getRoleDetails(this.state.role_id);
                        } 
                    })
                    .catch(function (error) {
                        console.log("error" + error);
                    });
            
        }
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.actions.length === 0) {
            errors["add_action"] = "Cannot be empty";
            formIsValid = false;
        }       
        this.setState({errors : errors});
        return formIsValid;
    }
    deleteUserRole = () => {
        let data = {
            user_id : this.state.temp_deleted_user_id,
            role_id : this.state.role_id,
        }
        let apiEndpoint = 'api/system/role/delete-user-from-role';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data) {
                        this.getRoleDetails(this.state.role_id);
                        this.setState({
                            open:false,
                            snackbarOpen : true,
                            snackbarMsg : 'User role deleted successfully',                          
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    enterPressed(event) {
        event.preventDefault();
        let code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.search();
        } 
    }    
    render(){
        return(
           <>
                
                    <CustomProgressBar 
                        loaderOpen={this.state.loader}
                    />
                   
                <RoleDetailsComponent 
                    AllActions = {this.state.all_actions}
                    SelectedRoleDetails = {this.state.selected_role_details}
                    HandlePermissionChange = {this.handlePermissionChange}
                    SnackbarOpen = {this.state.snackbarOpen}
                    SnackbarMsg = {this.state.snackbarMsg}
                    SnackbarClose = {this.snackbarClose.bind(this)}
                    Users = {this.state.users}
                    AllRoles = {this.state.all_roles}
                    AllModules = {this.state.all_modules}
                    HandleFilterChange = {this.handleFilterChange}
                    Filter = {this.filter}
                    FilterModule = {this.state.filter_module}
                    AddActionFilterModule = {this.state.filter_module_add_action}
                    AddActionHandleFilterChange = {this.handleAddActionFilterChange}
                    AddActionToRole = {this.addActionToRole}
                    UserSearchKey = {this.state.user_search_key}
                    HandleSearch = {this.handleSearch}
                    Search = {this.search}
                    AddUserClick = {this.addUserClick}
                    AddAction = {this.addAction}
                    Open = {this.state.open}
                    OnClose = {this.onClose}
                    AllUsers = {this.state.all_users}
                    PopupType = {this.state.popup_type}
                    OnSelectUser = {this.onSelectUser}
                    SelectedUserList = {this.state.selectedUserList}
                    OnRemove = {this.onRemove}
                    AddUser = {this.addUser}
                    DeleteUserRoleClick = {this.deleteUserRoleClick}
                    DeleteUserRole = {this.deleteUserRole}
                    EnterPressed = {this.enterPressed}
                    SelectedUserIds = {this.state.selectedUserIds}
                    ActionList = {this.state.action_list}
                    HandleTreeChange = {this.handleTreeChange}
                    Actions = {this.state.actions}
                    Errors = {this.state.errors}
                    DeleteRoleActionClick = {this.deleteRoleActionClick}
                    DeleteRoleAction = {this.deleteRoleAction}
                    ActionName = {this.state.action_name}
                />    
                </>  
        )
    }
}

