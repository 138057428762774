import React from 'react';
import {ProjectCodeReport} from './ProjectCodeReport'
import {httpServices}  from '../../services/httpServices'
import { ErrorPage } from '../common/error';
import * as validations from '../common/validate';
import * as _ts from '../common/treeSelect';

const API_URL = process.env.REACT_APP_API_URL;
const ReportFields = {
    'staff_status' : '',
    'staff_type' : '',
    'start_date' : '',
    'end_date' : '',
    'client_status' : '',
    'staff_name' : '',
    'client' : '',
    'report_type' : '',
    'staff_name_format' : '',
    'project_status' : '1',
    'projects' : [],
    'include_hours' : '',
}

const ReportInputs = {
    'staff_list' : [],
    'client_statuses' : [],
    'staff_statuses' : [],
    'staff_type' : [],
    'project_status' : [],
    'name_format':[],
    'project_list' : [],
    'exclude_project_list' : []
}
const ReportTable = {
    'header' : '',
    'rows' : ''
}
let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects,result);
        }
    });
}
const getAllProjectIds = function(projectTree,pIds) {
    projectTree.forEach(function(e) {
        if(e.project_id){
            pIds.push(e.project_id);
        }
        if(e.children instanceof Object) {
            getAllProjectIds(e.children, pIds);
        }
    });
} 

export class ProjectCodeReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            ReportTable : ReportTable,
            ReportInputs: ReportInputs,
            client_list: '',
            dialogOpen : false,
            popupType : '',
            selectedDate :'',
            random_number : 0,
            hasPermission : true,
            user : 0,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
        ff[field] = evt.target.value;
        if(field === 'client_status'){
             this.getClientByStatus(evt.target.value);
             ff['client'] = [];
             ff['projects']=[];
        }
        if(field=== 'project_status')
        {
            this.getProjectBystatus(evt.target.value);
            ff['projects'] = [];
        }
        if(field=== 'client')
        {
            ff['client'] = this.state.ReportFields.client;
            ff['projects']=[0];
            this.getProjectBystatus(this.state.ReportFields.project_status);
        }
        if(field === 'staff_status'){
             this.getStaffByStatus(evt.target.value,this.state.ReportFields.start_date,this.state.ReportFields.end_date,this.state.ReportFields.staff_type);
             //ff['staff_name'] = [];
        }
        if(field === 'staff_type'){
             this.getStaffByStatus(this.state.ReportFields.staff_status,this.state.ReportFields.start_date,this.state.ReportFields.end_date,evt.target.value);
             //ff['staff_type'] = 0;
        }
        if (field === 'include_hours') {
            ff['include_hours'] = evt.target.checked;
        }
         
        this.setState({
            ReportFields: ff
        });

    };
    exportReports = () => {
        let valid = this.handleValidation();
        if (valid) {
            document.getElementById('export').value = true;
            document.getElementById('labor-distribution').submit();
        }
    };

     handleValidation() {
        let formIsValid = true;
         let validationData = [
             { name: 'staff_type', value: this.state.ReportFields.staff_type, type: 'integer', otherOptions: { required: false } },
             { name: 'staff_name_format', value: this.state.ReportFields.staff_name_format, type: 'text', otherOptions: { required: false } },
             { name: 'start_date', value: this.state.ReportFields.start_date, type: 'date', otherOptions: { required: true } },
             { name: 'end_date', value: this.state.ReportFields.end_date, type: 'date', otherOptions: { required: true } },
             { name: 'report_type', value: this.state.ReportFields.report_type, type: 'text', otherOptions: { required: false } },
             { name: 'client_status', value: this.state.ReportFields.client_status, type: 'integer', otherOptions: { required: false } },
             { name: 'project_status', value: this.state.ReportFields.project_status, type: 'integer', otherOptions: { required: false } },
             { name: 'staff_status', value: this.state.ReportFields.staff_status, type: 'integer', otherOptions: { required: false } },
             { name: 'staff_name', value: this.state.ReportFields.staff_name, type: 'integer', otherOptions: { required: false } },
             { name: 'client', value: this.state.ReportFields.client, type: 'integer', otherOptions: { required: false } }
         ]
         let validateResponse = validations.validateFormElements(validationData);
         let validateErrors = validateResponse.errors;
         let isValid = validateResponse.valid;
         if (isValid) {
             let errors = {};
        let start_date = this.state.ReportFields.start_date ? new Date(this.state.ReportFields.start_date) : this.state.ReportFields.start_date;
        let end_date = this.state.ReportFields.end_date ? new Date(this.state.ReportFields.end_date) : this.state.ReportFields.end_date;
        if ( start_date && end_date ) {
            if(end_date < start_date){
                errors["end_date"] = "Please enter a valid End Date";
                formIsValid = false;
                 }
             }
             this.setState({ errors: errors });
         } else {
             formIsValid = false;
             this.setState({ errors: validateErrors });
         }
        return formIsValid;
    }

    componentDidMount() {
        httpServices.post('api/report/project-code-details')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        ReportInputs : {
                            staff_statuses : inputs.employee_statuses,
                            staff_type : inputs.staff_types,
                            start_date : inputs.start_date,
                            end_date :inputs.end_date,
                            client_statuses : inputs.client_statuses,
                            name_format :inputs.name_formats
                        },

                        ReportFields : {
                            'client_status' : 0,
                            'staff_status' : 0,
                            'project_status' : 1,
                            'project_statuses' : inputs.projectStatus,
                            'staff_type' : 0,
                            'staff_name' : 0,
                            'client' : 0,
                            'start_date' : inputs.start_date,
                            'end_date' : inputs.end_date,
                            'report_type' : 'PROJECTCODE',
                            'staff_name_format' : inputs.name_format,
                            'include_hours' : false,
                            'projects' : [0],
                        }
                    });
                    this.getStaffByStatus(0,inputs.start_date,inputs.end_date,0);
                    this.getClientByStatus(0);
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
    }

    getStaffByStatus(status_id,start_date,end_date,staff_type){
        httpServices.post('api/report/getStaffList',{ 'status_id' : status_id,'start_date' : start_date,'end_date' : end_date,'staff_type' : staff_type })
            .then(response => {
                if (response.data) {
                    let staff_list = response.data;
                    let RepInputs = this.state.ReportInputs;
                    let reportFields = this.state.ReportFields;
                    reportFields['staff_name'] = '0';
                    RepInputs['staff_list'] = staff_list;
                    this.setState({
                        ReportInputs : RepInputs,
                        ReportFields: reportFields,
                    }); 
                }
            })
    }
    getClientByStatus(status_id){
        httpServices.post('api/report/getClientList',{ 'status_id' : status_id})
            .then(response => {
                if (response.data) {
                    let client_list = response.data;
                    let RepInputs = this.state.ReportInputs;
                    RepInputs['client_list'] = client_list;
                    this.setState({                        
                             ReportInputs : RepInputs         

                    });
                    this.getProjectBystatus(this.state.ReportFields.project_status);
                }
            })
    }
    getProjectBystatus(project_status){
        let client=JSON.parse(JSON.stringify(this.state.ReportFields.client));
        httpServices.post('api/project/get-projects-by-status',{ 'status' : project_status , 'clients' : client, 'all_permission' : this.state.allPermission})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.ReportInputs;
                    finputs['project_list'] =  _ts.prepareTreeData(response.data, 'projects');
                    this.setState({
                        ReportInputs:finputs,
                        loaderOpen : false,
                    });
                }
            })
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.ReportFields;
        let values = this.state.ReportInputs;
        let clients=[];
        let projects = [];
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                if (action === 'project') {
                    projects.push(0);
                }
            }
        }
        else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id || el.client_id || el.user_id) {
                    if (action === 'clients') {
                        clients.push(el.client_id);
                    }
                    else if (action === 'project') {
                        getProjectTree(values.project_list, el.project_id, projects);
                    }
                }
            });
        }
        else if(action === 'clients'){
            f['clients'] = clients;
            f['projects']=[0];
            this.getProjectBystatus(this.state.ReportFields.project_status);
        }
        f['projects'] = projects;
        this.setState({ReportFields : f});
    }
    OnSubmitClick = (action) => () =>{
        let start_date = this.state.ReportFields.start_date;
        let end_date = this.state.ReportFields.end_date;
        let client_status = this.state.ReportFields.client_status;
        let project_status = this.state.ReportFields.project_status;
        let staff_status = this.state.ReportFields.staff_status;
        let staff_name = this.state.ReportFields.staff_name;
        let client = this.state.ReportFields.client;
        let staff_type = this.state.ReportFields.staff_type;
        let staff_name_format = this.state.ReportFields.staff_name_format;
        let report_type = this.state.ReportFields.report_type;
        let include_hours = this.state.ReportFields.include_hours;
        let projects = this.state.ReportFields.projects;

        let data = {staff_type : staff_type, staff_name_format : staff_name_format, report_type : report_type ,client_status : client_status, project_status : project_status, projects : projects, start_date : start_date, end_date : end_date, staff_status : staff_status,staff_name : staff_name,client : client,include_hours : include_hours }
        if(this.handleValidation()){
            httpServices.post('api/report/generate-pc-report', data)
                .then(response => {
                    if (response.data.permission && !response.data.errors) { 
                         this.setState({
                            ReportTable:{
                                  header : response.data.header,
                                  rows : response.data.row_values,                                 
                                
                         }
                        }); 
                    } else if (response.data.errors) {
                        this.setState({
                            errors: response.data.errors
                        })
                    }
                 else {
                    this.setState({
                        hasPermission : false,
                    });
                 }

            });
         }
    }
    editTimeInOutClick = (date) => ()=>{
        let rand = Math.random();
        this.setState({
            selectedDate : date,
            dialogOpen : true,
            popupType : 'timein_out',
            random_number : rand,
        });
    }
    onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    }
    exportPCReport = () => {
       let url = API_URL+'api/report/export-project-code-report/'+this.state.ReportFields.start_date+"/"+this.state.ReportFields.end_date+"/"+this.state.ReportFields.client;
        window.location.assign(url);

    }
    render(){
       return(
           (this.state.hasPermission)
            ?
           <ProjectCodeReport
                ReportInputs = { this.state.ReportInputs }
                HandleTreeChange = {this.handleTreeChange}
                Errors = { this.state.errors }
                HandleOnChange = { this.HandleOnChange }
                ReportFields = { this.state.ReportFields }
                ReportTable = { this.state.ReportTable}
                ClientList = { this.state.client_list}
                OnSubmitClick = { this.OnSubmitClick.bind(this) }
                ExportReports = {this.exportReports}
            />
           :<ErrorPage/>
       )
    }
}
