export * from './alert.constants';
export * from './user.constants';
export * from './staff.constants';
export * from './settings.constants';
export * from './table.constants';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
// SSO BASE PATH
export const SSO_AUTH_URL = BASE_PATH +  '/api/user/login?authtype=sso';
// GOOGLE AUTH
export const GOOGLE_AUTH_URL = BASE_PATH +  '/api/user/login?authtype=google';
// // MICROSOFT AUTH
export const MICROSOFT_AUTH_URL = BASE_PATH +  '/api/user/login?authtype=microsoft';


