import React from 'react';
import { ErrorPage } from '../../common/error';
import {httpServices}  from '../../../services/httpServices';
import { TimeOffSettingsComponent }  from './TimeOffSettings.Component.js';
import { CustomProgressBar } from '../../common/CustomProgressBar';

export class TimeOffSettingsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            TimeOffTypeView : false,
            permission : false,
            showLoader : true,
            dataLoaded : false,
        }
    }

    componentDidMount = () => {
        let apiEndpoint = 'api/settings/timeoff';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        timeOffTypeView : response.data.time_off_type_view_permission,
                        permission : true,
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
                else
                {
                   this.setState({
                       permission : false,
                       showLoader : false,
                       dataLoaded : true,
                    });
                }
            })
    };

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission)
                        ?
                        <TimeOffSettingsComponent
                            TimeOffTypeView = {this.state.timeOffTypeView}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
