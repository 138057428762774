import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { ProjectViewComponent } from './ProjectView.Component';
import { history } from '../../../helpers/history';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export class ProjectViewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            project : [],
            showLoader : true,
            projectId :0,
            parentProject : [],
            otherPermissions : [],
            projectParentPermission : false,
            inheritResourceFlag : false,
            snackbarOpen : false,
            snackbarMsg : '',
            editPermission : false,
            selfEditPermission : false,
            taskCount : 0,
            reviewerEditPermission : false,
            reviewerDeletePermission : false,
            reviewerViewPermission : false,
            dialogOpen : false,
            dialogCopy : false,
            resourceView : false,
            subprojects : false,
            addSubProjectPopup : false,
            addLocationPopUp : false,
            formValues : {},
            workLocations  : [],
            project_location : '',
            default : 0,
            timesheetLocation : 0,
            project_location_error : '',
	        system_timesheet_location : false,
            ts_location_error : ''
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.viewLink = this.viewLink.bind(this);
        this.updateParentOnEditSuccess = this.updateParentOnEditSuccess.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.updateParentOnAddSuccess = this.updateParentOnAddSuccess.bind(this);
        this.onAddWorkLocationClick = this.onAddWorkLocationClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDefaultChange = this.handleDefaultChange.bind(this);
        this.handleTimesheetLocationChange = this.handleTimesheetLocationChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.updateParentOnCopySuccess = this.updateParentOnCopySuccess.bind(this);
    }

    componentDidMount() {
        let id = (this.props.match.params.id) ? this.props.match.params.id : 0;
        this.setState({ projectId: id });
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.state.projectId !== prevStates.projectId) {
            this.getProjectDetails(this.state.projectId);
        }
        let id = (this.props.match.params.id) ? this.props.match.params.id: false;
        // Code for handling the back button click
        if (id && (typeof prevProps.match.params !== 'undefined' && prevProps.match.params.id)) {
            if (id !== prevProps.match.params.id) {
                this.setState({ projectId : id });
                this.getProjectDetails(id);
            }
        }
    }

    onUpdate = () => {
        this.setState({
            dialogOpen : true,
        });
    };
    onCopy = () => {
        this.setState({
            dialogCopy : true,
        });
    };

    getProjectDetails = (projectId) => {
        let apiEndpoint = 'api/project/details';
        this.setState({
            showLoader : true,
        });
        return httpServices.post(apiEndpoint,{project_id : projectId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            project : response.data.project,
                            parentProject : response.data.parentProject,
                            showLoader : false,
                            permission : response.data.permission,
                            otherPermissions : response.data.otherPermissions,
                            projectParentPermission : response.data.projectParentPermission,
                            inheritResourceFlag :response.data.inherit_resource_flag,
                            editPermission : response.data.edit_permission,
                            selfEditPermission : response.data.self_edit_permission,
                            reviewerViewPermission : response.data.reviewerView,
                            reviewerEditPermission : response.data.reviewerEdit,
                            reviewerDeletePermission : response.data.reviewerDelete,
                            taskCount : response.data.task_count,
                            resourceView : response.data.resourceView,
                            subprojects : response.data.subprojects,
                            workLocations: response.data.work_locations,
			    system_timesheet_location :  response.data.system_timesheet_location
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    viewLink = (link, id)=> () => {
        history.push(BASE_PATH+link);
        this.setState({
            projectId : id,
        }, function () {
            this.getProjectDetails(id);        
        });
    };
    
    updateParentOnEditSuccess = (status) => {
         this.setState({
                dialogOpen : false});
        if (status) {
            this.setState({
                snackbarOpen : true,
                snackbarMsg : 'Project updated successfully',
            }, function() {
                this.getProjectDetails(this.state.projectId);
            });
        }
    }

    updateParentOnAddSuccess = (status) => {
        if (status) {
            this.setState({
                addSubProjectPopup: false,
                snackbarOpen: true,
                snackbarMsg: 'Project added successfully',
            }, function () {
            });
        }
        else {
            this.setState({
                addSubProjectPopup: false,
            });

        }
    }

    updateParentOnCopySuccess = (status) => {
        if (status) {
            this.setState({
                dialogCopy : false,
                snackbarOpen: true,
                snackbarMsg: 'Project copied successfully',
            }, function () {
            });
        }
        else {
            this.setState({
                dialogCopy : false,
            });

        }
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    handleOnChange = (evt) => {        
        this.setState({
            project_location : evt.target.value
        });
    };

    handleDefaultChange = (evt) => {      
        this.setState({
            default : evt.target.checked  ? 1 : 0
        });
    };

    handleTimesheetLocationChange = (evt) => {     
        this.setState({
            timesheetLocation : evt.target.checked  ? 1 : 0
        });
    };

    OnAddSubProjectClick = () => {
        this.setState({
            addSubProjectPopup : true,
        });
    }

    onAddWorkLocationClick = () => {
        this.setState({
            addLocationPopUp: true,
            project_location_error: '',
            ts_location_error: ''
        });
    }

    onClose = () => {
        this.setState({
            addLocationPopUp: false,
            project_location_error: '',
            ts_location_error : ''
        });
    }

    validateFormData = () => {
        let valid = true;
        if (this.state.project_location === 'undefined' || !this.state.project_location) {
            this.setState({
                project_location_error : 'Location cannot be empty'
            });
            valid = false;
        }
        if (this.state.default === 1) 
        {
            if (this.state.timesheetLocation == 0)
            {
                this.setState({
                    ts_location_error : 'Timesheet should be enabled for default location'
                });
                valid = false;
            }            
        }       
        return valid;
    }

    onConfirm = () => {
        let projectId = this.state.projectId;
        let locationId = this.state.project_location;
            let apiEndpoint = 'api/project/saveProjectLocation';
            if (this.validateFormData()){
                return httpServices.post(apiEndpoint, { project_id: projectId, location_id: locationId, set_default: this.state.default, action: "add", timesheet:this.state.timesheetLocation })
                    .then(response => {
                        if (response.data) {
                            if (response.data.permission) {
                                this.setState({
                                    addLocationPopUp: false,
                                    project_location: ''
                                }, 
                                () => this.getProjectDetails(projectId)
                                );
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }        
    }

    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(! this.state.showLoader) ?
                    <>
                    {(this.state.permission) ?
                        <ProjectViewComponent
                            Project = {this.state.project}
                            ParentProject = {this.state.parentProject}
                            ProjectId = {this.state.projectId}
                            ViewLink = {this.viewLink}
                            OtherPermissions = {this.state.otherPermissions}
                            ProjectParentPermission = {this.state.projectParentPermission}
                            TaskCount = {this.state.taskCount}
                            InheritResourceFlag = {this.state.inheritResourceFlag}
                            UpdateParentOnEditSuccess = {this.updateParentOnEditSuccess}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            EditPermission = {this.state.editPermission}
                            SelfEditPermission = {this.state.selfEditPermission}
                            ReviewerEditPermission = {this.state.reviewerEditPermission}
                            ReviewerViewPermission = {this.state.reviewerViewPermission}
                            ReviewerDeletePermission = {this.state.reviewerDeletePermission}
                            SnackbarClose = {this.snackbarClose}
                            DialogOpen = {this.state.dialogOpen}
                            DialogCopy = {this.state.dialogCopy}
                            OnCopy = {this.onCopy}
                            OnUpdate = {this.onUpdate} 
                            ResourceView = {this.state.resourceView}
                            SubProjects = {this.state.subprojects}
                            OnAddSubProjectClick = {this.OnAddSubProjectClick}
                            AddSubProjectPopup = {this.state.addSubProjectPopup}
                            UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
                            OnAddWorkLocationClick = {this.onAddWorkLocationClick}
                            AddLocationPopUp = {this.state.addLocationPopUp}
                            FormValues={this.state.formValues}
                            WorkLocations = {this.state.workLocations}
                            HandleOnChange = {this.handleOnChange}
                            ProjectLocation = {this.state.project_location}
                            HandleDefaultChange = {this.handleDefaultChange}
                            Default = {this.state.default}
                            TimesheetLocation = {this.state.timesheetLocation}
                            HandleTimesheetLocationChange = {this.handleTimesheetLocationChange}
                            OnClose = {this.onClose}
                            OnConfirm = {this.onConfirm}
                            Error = {this.state.project_location_error}
			                SystemLocation = {this.state.system_timesheet_location}
                            TimesheetError = {this.state.ts_location_error}
                            UpdateParentOnCopySuccess = {this.updateParentOnCopySuccess}
                        />
                        : <ErrorPage />
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}
