import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';

export const ExpenseCodeComponent = (props) => {
    const codes = typeof props.ExpenseCodes !== 'undefined' ? props.ExpenseCodes : [];
    return (
        <>
            <div className={'listSubHeader pr-2'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                {props.EditPermission &&
                <Tooltip title="Add Expense Code" aria-label="add">
                    <Button variant="contained" color="primary" size="small" onClick={props.AddExpenseCodeClick}>Add</Button>
                </Tooltip>
                }
            </div>
            
            <TableContainer component={Paper}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell>Expense Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            {props.EditPermission && 
                            <TableCell>Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(codes.length === 0) &&
                            <TableRow >
                                <TableCell>No expense codes found.</TableCell>
                            </TableRow>
                        }
                        {codes.map((item, index) =>
                        <TableRow hover>
                            <TableCell>{item.code}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.start_date}</TableCell>
                            <TableCell>{item.end_date}</TableCell>
                            {props.EditPermission &&
                            <TableCell>
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Edit">
                                        <EditIcon fontSize="small" onClick={()=>props.EditClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" aria-label="Delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Delete">
                                        <DeleteOutlinedIcon fontSize="small" onClick={()=>props.HandleDeleteClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            }
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.PopupType === 'add' || props.PopupType === 'edit') &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.PopupType === 'add' ? 'Add Expense Code' : 'Edit Expense Code' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogAdd FormProps = { props }/> }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                OnSubmit={props.DeleteExpCode}
                Title='Confirm Delete' 
                ButtonName='Ok'                
                Content={
                    <DialogWithConfirmMessage msg="Do you want to delete this Expense Code" />
                }
            />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                    <TextField variant="standard"
                            required
                            id = "startDate"
                            name = "startDate"
                            label = "Start Date"
                            type = "date"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.startDate : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.startDate !== 'undefined' && Errors.startDate)&& true }
                            helperText = { typeof Errors.startDate !== 'undefined' && Errors.startDate }
                        />
                        </ FormControl>
                </Grid>
                        <Grid item xs={12} md={6}>
                    <FormControl>
                         <TextField variant="standard"
                            id = "end-date"
                            name = "endDate"
                            label = "End Date"
                            type = "date"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.endDate : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.endDate !== 'undefined' && Errors.endDate)&& true }
                            helperText = { typeof Errors.endDate !== 'undefined' && Errors.endDate }
                        />
                        </ FormControl>
                </Grid>
                        <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "exp_code"
                            name = "expenseCode"
                            label = "Expense Code"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.expenseCode : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.expenseCode !== 'undefined' && Errors.expenseCode)&& true }
                            helperText = { typeof Errors.expenseCode !== 'undefined' && Errors.expenseCode }
                        />
                        </ FormControl>
                </Grid>
                        <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "name"
                            name = "name"
                            label = "Name"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.name : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.name !== 'undefined' && Errors.name)&& true }
                            helperText = { typeof Errors.name !== 'undefined' && Errors.name }
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
