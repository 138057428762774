import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { formatAndSortNames } from '../../common/common';
import { CustomDatePicker } from '../../common/CustomDatePicker';

const styles = theme => ({
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    }
});
export const TimeoffFilterComponent = (props) => {
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'user_first_name', middle_name: 'user_middle_name', last_name: 'user_last_name'};
    let users = typeof props.users !== 'undefined' ? props.users : [];
    if (users && users.length) {
        users = formatAndSortNames(users, [format, columnsToBeFormated], 'ASC');
    }
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl>
                    <CustomDatePicker
                                id = "startDate"
                                name = "startDate"
                                selectedDate = {props.formFields.startDate}
                                handleDateChange = {props.handleStartDateChange}
                                label = {'Start Date'}
                            />
                    </ FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl>
                        <CustomDatePicker
                            id="endDate"
                            name="endDate"
                            selectedDate={props.formFields.endDate}
                            handleDateChange={props.handleEndDateChange}
                            label={'End Date'}
                        />
                        <span class="error">{props.errors.endDate}</span>
                    </FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Request Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'requestStatus',
                            id: 'requestStatus',
                        }}
                        value = {!_R.isNil(props.formFields.requestStatus) && props.formFields.requestStatus }
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.requestStatus) !== 'undefined' && props.requestStatus.map((t, i) =>
                            <MenuItem value={t.request_status_id} key={'c-'+i}>{t.request_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                {props.tab === 'all' &&
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'employeeStatus',
                            id: 'employeeStatus',
                        }}
                        value = {!_R.isNil(props.formFields.employeeStatus) && props.formFields.employeeStatus}
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.employeeStatus) !== 'undefined' && props.employeeStatus.map((n, i) =>
                            <MenuItem value={n.user_status_id} key={'n-'+i}>{n.user_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
                {props.tab !== 'my' && 
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Name</InputLabel>
                        <Select
                            inputProps={{
                            name: 'userId',
                            id: 'userId',
                        }}
                        value = {!_R.isNil(props.formFields.userId) && props.formFields.userId}
                        onChange = {props.handleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {users.map((n, i) =>
                            <MenuItem value={n.user_id} key={'n-'+i}>{n.formatted_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant = 'standard'>
                            <InputLabel htmlFor="age-simple">Task</InputLabel>
                            <Select
                                inputProps={{
                                    name: 'task',
                                    id: 'task',
                                }}
                                value = {!_R.isNil(props.formFields.task) && props.formFields.task}
                                onChange = {props.handleOnChange}
                            >
                                <MenuItem value="0" >All</MenuItem>
                                {typeof(props.filterTOTasks) !== 'undefined' && props.filterTOTasks.map((to, i) =>
                                <MenuItem value={to.task_id} key={'n-'+i}>{to.task_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div className={'d-flex justify-end mb-1'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(typeof props.saveFilter !== 'undefined') ? props.saveFilter : false}
                                value={(typeof props.saveFilter !== 'undefined') ? props.saveFilter : false}
                                id = "saveFilter"
                                name ="saveFilter"
                                onChange={props.handleOnChange}
                                color={'primary'}
                            />
                        }
                        label="Save Filter"
                    />
                </div>
                <div className={'d-flex justify-end'}>
                    <Button variant = "outlined" color = "primary"   className = {'mr-1 filterSubmitButton'} onClick = {()=> props.ResetFilter (props.tab)} > Reset</Button>
                    <Button variant = "contained" color = "primary"  className = {' filterSubmitButton'} onClick = {()=> props.handleOnSubmit (props.tab)}> Submit </Button>
                </div>
        </form>
    )
}
