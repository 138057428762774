import React from 'react';

import { withStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { ucfirst, ucwords } from 'bellajs';
import * as _R from 'ramda';
import * as _cs from '../../common/common';
import { formatName } from '../../common/common';

const styles = theme => ({
    chip: {
        height:'18px',
        fontSize:'0.7rem'
    },
    statusIndicator:{
        fontSize:'0.8rem'
    },
    statusIndicatorSuccess:{
        color:'green',
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        border: '1px solid green',
        padding: '0.1rem 0.2rem',
        borderRadius: '2px',
    },
    statusIndicatorError:{
        color: '#ed143d',
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        border: '1px solid #ed143d',
        padding: '0.1rem 0.2rem',
        borderRadius: '2px',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
    },
});
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const StaffBasic = withStyles(styles)(props => {
    const { classes, staffDetails } = props;
    //const isMenuOpen = Boolean(props.anchorEl);
    var staffData = {};
    var thumb_url = '';
    var image_url = '';
    if (_R.type(staffDetails) !== 'Undefined') {
        staffData = staffDetails;
        if (_R.type(staffDetails.photos) !== 'Undefined') {
            let photos = staffDetails.photos;
            let result = photos.find(i => i.default_photo === 1);
            if (result) {
                 thumb_url = result['thumb_url'];
                 image_url = result['aws_url'];
            }
        }
    }   
    let full_name = formatName(staffData.first_name,null,staffData.last_name);
    /*const staffMoreMenu = (
        <Menu
            anchorEl = {props.anchorEl}
            anchorOrigin = {{ vertical: 'top',horizontal: 'right'}}
            transformOrigin = {{vertical: 'top', horizontal: 'right'}}
            open = {isMenuOpen}
            onClose = {props.handleMenuClose}>
            <MenuItem onClick = {props.handleOpenModal} >Preferences< /MenuItem>
            <MenuItem onClick = {props.handleOpenModal} >User Configuration< /MenuItem>
            <MenuItem onClick = {props.handleOpenModal} >Change Log< /MenuItem>
        </Menu>
    );
    /*const preferencesModal = (
        <Dialog fullScreen
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={props.handleCloseModal}
            >
            <AppBar color="default" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>Preferences</Typography>
                    <IconButton edge="start" color="inherit" onClick={props.handleCloseModal} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
        </AppBar>
           <Preferences />
        /*</Dialog>
    );*/
    return (
        <React.Fragment>
            <div class="profile-view-aside">
                <div class="pb pb-main">
                    <div class="pbi-item pbi-name">{_R.isNil(staffData.salutation)?full_name:staffData.salutation+' '+full_name}</div>
                    <div class="pbi-item pbi-status-group">
                        <div data-icon="mail_outline" className={'profile-view__basics-item item-email pbi-email'}>{staffData.user_email}</div>
                        <div data-icon="phone" className={'profile-view__basics-item item-phone pbi-phone'}>{_cs.formatPhone(staffData.user_mobile)}</div>
                    </div>
                    <div class="pbi-item pbi-status-group">
                        <div class="pbi-type">{staffData.staff_type ? ucwords(staffData.staff_type) : null }</div>                       
                        {(typeof staffData.status_text !== 'undefined' && staffData.status_text.toLowerCase() === 'active') ?
                            <div class="pbi-status">
                                <span class="status-view status-active">{(typeof staffData.status_text !== 'undefined' && staffData.status_text.toUpperCase())}</span>
                            </div>
                            : <div class="pbi-status">
                                <span class="status-view status-inactive">{(typeof staffData.status_text !== 'undefined' && staffData.status_text.toUpperCase())}</span>
                            </div>
                        }
                    </div>
                </div>
                <div class="pb pb-highlight">
                    <div class="pb-image">
                        <div class="helper" style={{cursor:'pointer'}}>
                            {(thumb_url !== '') ? <img src = {thumb_url} alt="" onClick={()=>props.handlePhotoClick(image_url)}/>
                                : <img src={BASE_PATH + "/noimage.jpg"}  alt="" />
                            }
                        </div>
                    </div>
                    <div class="pb-info-sub">
                        <ul class="pbi-info-list">                          
                            <li>
                                <span class="label">Staff ID:</span>
                                <span class="value">{staffData.staff_id}</span>
                            </li>
                            {staffData.display_employee_code && staffData.employee_id &&
                                <li>
                                    <span class="label">Employee ID:</span>
                                    <span class="value">{staffData.employee_id}</span>
                                </li>
                            }
                            <li>
                                <span class="label">Joined:</span>
                                <span class="value">{staffData.date_joined}</span>
                            </li>
                            {staffData.relieved_date &&
                                <li>
                                    <span class="label">Relieved:</span>
                                    <span class="value">{staffData.relieved_date}</span>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                <div class="pb pb-info">
                    <ul class="pbi-info-list">
                        <li>
                            <span class="label">Category</span>
                            <span class="value">{staffData.emp_category != null ? ucfirst(staffData.emp_category): ''}</span>
                        </li>
                        <li>
                            <span class="label">Overtime</span>
                            <span class="value">{_R.type(staffData.overtime)!== 'Undefined'?ucfirst(staffData.overtime):null}</span>
                        </li>
                        <li>
                            <span class="label">Organization</span>
                            <span class="value">{staffData.organization}</span>
                        </li>
                        <li>
                            <span class="label">Titled employee</span>
                            <span class="value">{parseInt(staffData.titled) ? 'Yes': 'No'}</span>
                        </li>
                        <li>
                            <span class="label">Job Title</span>
                            <span class="value">{staffData.job_title ? (staffData.job_title) : null }</span>
                        </li>
                        <li>
                            <span class="label">Main Project</span>
                            <span class="value">{staffData.project_path} {staffData.project_status_name? '('+staffData.project_status_name+')' : null}</span>
                        </li>
                        <li>
                            <span class="label">Project Role</span>
                            <span class="value">{staffData.project_title ? ucwords(staffData.project_title) : null }</span>
                        </li>
                        <li>
                            <span class="label">Supervisor</span>
                            <span class="value">{staffData.supervisor ? formatName(staffData.supervisor.first_name,staffData.supervisor.middle_name,staffData.supervisor.last_name) : null }</span>
                        </li>
                    </ul>
                </div>
            </div>
            
    </React.Fragment>
    )
});

