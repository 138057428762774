import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export class PreferencesForm extends React.Component {
    render() {
        return (
            <>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        typeof this.props.FormFields
                                            .email_notification !== "undefined"
                                            ? this.props.FormFields
                                                  .email_notification
                                            : false
                                    }
                                    value={
                                        typeof this.props.FormFields
                                            .email_notification !== "undefined"
                                            ? this.props.FormFields
                                                  .email_notification
                                            : null
                                    }
                                    id="email_notification"
                                    name="email_notification"
                                    onChange={this.props.HandleOnChange}
                                />
                            }
                            label="Email Notification"
                        />
                    </Grid>
                    {typeof this.props.FormFields.email_notification !==
                        "undefined" &&
                    this.props.FormFields.email_notification ? (
                        <>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                typeof this.props.FormFields
                                                    .email_own_action !==
                                                "undefined"
                                                    ? this.props.FormFields
                                                          .email_own_action
                                                    : false
                                            }
                                            value={
                                                typeof this.props.FormFields
                                                    .email_own_action !==
                                                "undefined"
                                                    ? this.props.FormFields
                                                          .email_own_action
                                                    : null
                                            }
                                            id="email_own_action"
                                            name="email_own_action"
                                            onChange={this.props.HandleOnChange}
                                        />
                                    }
                                    label="Receive Email For Own Actions"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                typeof this.props.FormFields
                                                    .secondary_email_notification !==
                                                "undefined"
                                                    ? this.props.FormFields
                                                          .secondary_email_notification
                                                    : false
                                            }
                                            value={
                                                typeof this.props.FormFields
                                                    .secondary_email_notification !==
                                                "undefined"
                                                    ? this.props.FormFields
                                                          .secondary_email_notification
                                                    : null
                                            }
                                            id="secondary_email_notification"
                                            name="secondary_email_notification"
                                            onChange={this.props.HandleOnChange}
                                        />
                                    }
                                    label="Secondary Email Notification"
                                />
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </>
        );
    }
}
