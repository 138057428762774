import React from 'react';
import Button from '@mui/material/Button';
import { DialogBoxMedium } from "../../common/dialog.medium";
import { MTableToolbar } from "@material-table/core";
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';
import SaveAlt from '@mui/icons-material/SaveAlt';
import * as _cs from '../../common/common';

import { CustomizedSnackbars } from '../../common/snackbars';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import { withStyles } from '@mui/styles';

const API_URL = process.env.REACT_APP_API_URL;

function sort(date1,date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
const UserListStyle = theme => ({
     drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});
const StyledFilterButton = styled(IconButton)(() => ({
    position:'absolute',
    top:"0.5rem",
    right:"0.5rem"
}));
const customButton = (props) => {
    return (<Button variant="contained" color="primary" size='small'>Add</Button>);
}
export const UserListComponent = withStyles(UserListStyle) (props => {
    const { classes, toggleDrawer} = props;
    const columns = [
        { title: 'Name', field: 'full_name', removable: false, defaultSort: 'asc'},
        { title: 'Email', field: 'user_email', searchable:false },
        { title: 'Phone Number', field: 'phone_number', render: rowData => ( _cs.formatPhone(rowData.user_mobile)), sorting:false, searchable:false},
        { title: 'User Type', field: 'user_type_name', searchable:false },
        { title: 'Organization', field: 'client_name', searchable:false },
        { title: 'Status', field: 'user_status_name', searchable:false },
        { title: 'Modified By', field: 'modified_by', searchable:false  },
        { title: 'Modified On', field: 'user_mdate',customSort:(a,b) => sort(new Date(a.user_mdate),new Date(b.user_mdate)), searchable:false },
    ];
    return (
        <React.Fragment>
            <CustomMaterialTable
                title= "Users"
                data = {props.Users}
                columns={columns}
                actions={[
                    {
                        icon: customButton,
                        tooltip: 'Add User',
                        isFreeAction: true,
                        onClick: () => { props.HandleAddUser() },
                    },
                    {
                        icon: SaveAlt,
                        tooltip: 'Export',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.ExportUser()},
                    },
                    rowData => ({
                        icon: Edit,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => { props.HandleEditClick(rowData.user_id)},
                        hidden: props.EditPermission ? false : true,
                    }),
                    rowData => (
                    {icon: DeleteOutline, tooltip: 'Delete', onClick: (event, rowData) => { props.HandleDeleteClick(rowData.user_id)},hidden: true}),
                ]}
                options={{
                    actionsColumnIndex: -1,
                    thirdSortClick: false,
                    search: true,
                }}
                freezeHeader = {true}
                components={{
                    Toolbar: props => (
                        <div style={{ position: 'relative', paddingRight:'50px' }}>
                                <MTableToolbar {...props} />
                            <Tooltip title="Filter" aria-label="Filter">
                                <StyledFilterButton onClick={toggleDrawer('right', true)} className={props.filterButton}><FilterList /></StyledFilterButton>
                            </Tooltip>
                        </div>
                        )
                    }}
                onRowClick={(event, rowData) => {props.HandleViewDetails(rowData.user_id)}}
            />
              
              <Drawer
                anchor="right"
                open={props.Right}
                onOpen={props.toggleDrawer('right', true)}
                onClose={props.toggleDrawer('right', false)}>
                <SideList
                    Right={'right'}
                    FilterValues = {props.FilterFields}
                    FilterInputs={props.Filters}
                    HandleFilterChange={props.HandleFilterChange}
                    SubmitFilter={props.SubmitFilter}
                    ResetFilter={props.ResetFilter}
                />
            </Drawer>
            {/*Form for submitting filter values for export user list*/}
            <form action={API_URL+'api/user/list'} method={"post"} id={"exportUserList"}>
                <input type="hidden" name="action" value={"export"} />
                <input type="hidden" name="status" value={props.FilterFields.status} />
                <input type="hidden" name="user_type" value={props.FilterFields.user_type} />
                <input type="hidden" name="group" value={props.FilterFields.group} />
            </form>
            {(props.PopupType === 'delete' &&  props.Open) &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { 'Confirm Delete' }
                OnSubmit = { props.DeleteClick} ButtonName={'Yes'}
                Content = {"Do you really want to delete this user?"}
            />}

            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>
    );
});

const SideList = withStyles(UserListStyle) (p => {
      return (
        < React.Fragment>
            <div role="presentation" className={p.classes.drawerList}>
            <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}
                            value = {!_R.isNil(p.FilterValues.status) && p.FilterValues.status }
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {_R.type(p.FilterInputs.status !== 'Undefined') && p.FilterInputs.status.map((s, i) =>
                            <MenuItem value={s.user_status_id} key={'st-'+i}>{s.user_status_name}</MenuItem>
                        )}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="userTypes">User Type</InputLabel>
                        <Select
                            inputProps={{
                                name: 'user_type',
                                id: 'user_type',
                            }}
                            value = {!_R.isNil(p.FilterValues.user_type) && p.FilterValues.user_type }
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {_R.type(p.FilterInputs.userTypes !== 'Undefined') && p.FilterInputs.userTypes.map((s, i) =>
                            (s.type != "TBD") ?
                            <MenuItem value={s.type} key={'st-'+i}>{s.type}</MenuItem>
                            :
                            <></>
                        )}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="groups">Groups</InputLabel>
                        <Select
                            inputProps={{
                                name: 'group',
                                id: 'group',
                            }}
                            value = {!_R.isNil(p.FilterValues.group) && p.FilterValues.group }
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {_R.type(p.FilterInputs.groups !== 'Undefined') && p.FilterInputs.groups.map((s, i) =>
                            <MenuItem value={s.id} key={'st-'+i}>{s.name}</MenuItem>
                        )}
                        </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined"  color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
});
