import React from 'react';

export class CompanyInfo extends React.Component {
    render() {
        const { info } = this.props;
        return (
            <div>
                        <div className={'company-logo'}>
                            <img src={'company_logo.png'} alt="Company Logo" />
                        </div>
                        <div className={'support'}>
                            <div>
                                <p>
                                    {info.company_name}<br />
                                    {info.addr1}<br />
                                    {info.addr2}<br />
                                </p>
                                <p>
                                    {info.city}, {info.zip}<br />{info.country}
                                </p>
                            </div>
                            <div className={'title'}>Support</div>
                            <div>
                                <p>
                                    Tel: {info.telephone}<br />
                                    Support: {info.support_email}
                                </p>
                            </div>
                        </div>
                    </div>

        );

    }
}
