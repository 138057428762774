import React from 'react';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';

import { LocationsContainer }  from './Locations.Container.js';
import { RulesContainer }  from './Rules.Container.js';
import { PoliciesContainer }  from './Policies.Container.js';
import { SystemPolicyContainer }  from './SystemPolicy.Container.js';
import { GetSelectedTab } from '../../../redux/middleware/tabMiddleware';


export const WorkpolicySettingsComponent = (props) => {
    //Tabs
    const tabValue = GetSelectedTab("policy-settings");
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-body'}>
                    <Paper>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabValue}
                                onChange={props.handleChange}
                                indicatorColor="primary"
                                aria-label="Work Policy"
                            >                             
                                <Tab label="Locations"/>
                                <Tab label="Rules"/>
                                <Tab label="Policies"/>
                                <Tab label="System Policy"/>
                            </Tabs>
                        </div>
                        <div className={'tab-panelContainer'}>
                            <TabPanel value={tabValue} index={0}>
                                {tabValue === 0 &&
                                <LocationsContainer/>
                                }                                
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                {tabValue === 1 &&
                                <RulesContainer
                                FrequencyArray = {props.FrequencyArray}
                                />
                                }
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                {tabValue === 2 &&
                                    <PoliciesContainer
                                        FrequencyArray = {props.FrequencyArray}
                                    />
                                }
                                
                            </TabPanel><TabPanel value={tabValue} index={3}>
                                {tabValue === 3 &&
                                    <SystemPolicyContainer />
                                }
                            </TabPanel>
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>    
    )
};

