import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { formatName } from '../../../common/common';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { CustomDatePicker } from '../../../common/CustomDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label" htmlFor="timeoff_type_id" required  error={props.Errors.timeoff_type_id && true}>Time off Type</InputLabel>
                            <Select
		                value={props.FormFields.timeoff_type_id}
                                onChange={props.HandleOnChange}
                                displayEmpty
                                inputProps={{
                                    name: 'timeoff_type_id',
                                    id: 'timeoff_type_id',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                {typeof(props.FormInputs) !== undefined && props.FormInputs.event_based_timeoffs.map((timeoff, i) =>
                                    <MenuItem value={timeoff.id} key={'timeoff-'+i}>{timeoff.to_type}</MenuItem>
                                )}
                            </Select>
		            <small className='error'>{props.Errors.timeoff_type_id}</small>
                        </ FormControl>
                    </Grid>
			 <Grid item xs={12} md={6}>
                                <FormControl>
                                    <CustomDatePicker
                                        id = "from_date"
                                        name = "from_date"
                                        selectedDate={props.FormFields.from_date}
                                        handleDateChange={props.HandleDateChange}
                                        required={true}
                                        label={'From Date'}
                                    />
		                    <small className='error'>{props.Errors.from_date}</small>
                                </ FormControl>
                            </Grid>

		 <Grid item xs={12} md={6}>
                                <FormControl>
                                    <CustomDatePicker
                                        id = "to_date"
                                        name = "to_date"
                                        selectedDate={props.FormFields.to_date}
                                        handleDateChange={props.HandleDateChange}
                                        required={true}
                                        label={'To Date'}
                                    />
				    <small className='error'>{props.Errors.to_date}</small>
                                </ FormControl>
                            </Grid>
		<Grid item xs={12} md={6}>
		    <Tooltip title="Default eligibility">
                        <FormControl>
			    <InputLabel shrink >Default eligibility</InputLabel>
                            <TextField variant="standard"
                                id="eligible_days"
                                name="eligible_days"
                                type="text"
                                fullWidth
				disabled
                                value={props.FormFields.default_eligibility ? props.FormFields.default_eligibility : ''  }
                            />
                        </ FormControl>
		    </Tooltip>
                </Grid>

		<Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="eligible_days"
                                name="eligible_days"
                                label="Staff Eligibility"
                                type="text"
                                fullWidth
                                required
				InputLabelProps = {{
                                	shrink: true,
                            	}}
                                onChange={props.HandleOnChange}
                                value={props.FormFields.eligible_days ? props.FormFields.eligible_days : props.FormFields.default_eligibility}
		                error={props.Errors.eligible_days && true}
                                helperText={props.Errors.eligible_days}
                            />
                        </ FormControl>
                    </Grid>

		</Grid>
            </form>
        )
};

export const StaffEventsConfigComponent = (props) => {
	const columns = [
        { title: 'Time off type', field: 'timeoff_type_name'},
        { title: 'From date', field: 'from_date'},
   	{ title: 'To date', field: 'to_date'},
	{ title: 'Default Eligibility', field: 'default_eligibility'},
	{ title: 'Staff Eligibility', field: 'eligible_days'},
	{ title: 'Created', field: 'created_by', render: rowData => <>{formatName(rowData.cby_first_name,null,rowData.cby_last_name)} {rowData.created_on}</>},
	{ title: 'Modified', field: 'modified_by', render: rowData => <>{formatName(rowData.mby_first_name,null,rowData.mby_last_name)} {rowData.modified_on}</>},
	];

    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
	     <CustomMaterialTable
                    title="Time off events"
                    columns={columns}
                    data={props.StaffEventsList}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging:false,
                    }}
	    	    actions={[
                        {   
                            icon: 'add',
                            tooltip: 'Add timeoff events config',
                            isFreeAction: true,
                            onClick: (event, newData) => { 
                                props.OnAdd();
                            },
                            /*hidden: (Edit) ? false : true,*/
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                props.HandleUpdate(rowData.id);
                            },
                            /*hidden: (Edit) ? false : true,*/
                        },

                    ]}
	    	 editable={{
                        onRowDelete: rowData =>
                            new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                props.OnDelete(rowData.id);
                            }
                            resolve()
                            }, 500)
                        }),
                    }}
		/>
            </div>
	    <DialogBoxMedium
               Open={props.Open}
               Cancel={props.Close}
               Title= {props.IsEdit ? 'Edit Time off Events' : 'Add Time off Events'}
               OnSubmit={props.SaveStaffEvent}
	       Loading ={props.ButtonLoader}
               Content={<DialogComponent FormProps={props}/>} />
            <CustomizedSnackbars
               open={props.Snackbar}
               variant={props.Variant}
               message={props.Message}
               handleClose={props.SnackbarClose}
	    />
        </React.Fragment>
    );
}
