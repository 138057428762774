import React from 'react';
import {httpServices}  from '../../../services/httpServices';
import { OvertimeAddComponent } from './OvertimeAdd.Component';
import moment from 'moment';

const formFields = {
    'id':0,
    'startDate' : '',
    'hours' : '',
    'description' : '',
    'refNo' : '',
    'file' : null,
    'file_name' : null,
    'attachment_id' : '',
    'custom_recipient' : [],
    'remove_file' : false,
}
export class AddOvertime  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields : formFields,
            stateData : {},
            open : false,
            errors :{},
            snackbar : false,
            valid: false,
            success_message:null,
            error_message :null,
            week: null,
            removeFile : false,
            removeFileId :0,
            file: {},
            relievedDate: '',
        }
    };

    componentDidMount() {
        if(this.props.EditId ) {
            this.onList(this.props.EditId);
        }
        else {
            this.clearForm();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.Open !== this.state.open) {
            this.setState({open : this.props.Open});
        }
    }


    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const files = this.state.file;
        const name = evt.target.name;
        f[name] = evt.target.value;
        if (name === 'remove_file') { // Check box
            f[name] = evt.target.checked;
            this.setState({removeFileId :f['attachment_id']});
            f['attachment_id'] = null;
            this.setState({formfields : f,
                removeFile: true,
            });
        } else {
            f[name] = evt.target.value;
        }
        if (name === "file") {
            files[name] = evt.target.files[0];
            this.setState({
              file: files,
             });
        }
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
        if( evt.target.name === 'startDate') {
            let week = null;
            let day = moment(evt.target.value,'YYYY-MM-DD');
            if (day.isValid())  {
                let weekStart = day.startOf('week').format("MM/DD/YYYY");
                let weekEnd = day.endOf('week').format("MM/DD/YYYY");
                week = weekStart +" - "+weekEnd;
            }
            this.setState({week: week});
            this.getOverTimeReceipients();
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.startDate) {
            errors["startDate"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.hours) {
            errors["hours"] = "Cannot be empty";
            formIsValid = false;
        }
        else {
            let reg = /^([0-9.:]*$)/
            const validHour = reg.test(this.state.formFields.hours);
            if ((this.state.formFields.hours > (7 * 24)) || this.state.formFields.hours <= 0 || !validHour) {
                errors["hours"] = "Invalid hours";
                formIsValid = false;
            }
        }
        if (!(this.state.formFields.description !== null &&  this.state.formFields.description.replace(/\s+/g, ''))) {
            errors['description'] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    onAddRequest = (id) => {
        if(id && parseInt(id) !== 0) {
            this.onEditRequest(id);
        }
        else {
            this.onSaveRequest();
        }
    }

    onSaveRequest = () => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            data.type = 1;
            data.doc = this.state.file;
            if (this.state.formFields.custom_recipient) {
                let c_recipients = this.arrayColumn(this.state.formFields.custom_recipient,'user_id');
                data['custom_recipients'] = JSON.stringify(c_recipients);
            }
            if (this.props.Recipients) {
                let stateDefaultRecipients = this.props.Recipients;
                let defaultRecipients = [];
                Object.keys(stateDefaultRecipients).map(function(key,p_index) {
                    let obj = stateDefaultRecipients[key];
                    if (obj.user_id) {
                        defaultRecipients.push(obj.user_id);
                    }
                    return true;
                });
                if (defaultRecipients.length) {
                    data['recipients'] = JSON.stringify(defaultRecipients);
                }
            }
            return httpServices.post('api/overtime/create', data)
            .then(response => {
                if (response.data.valid) {
                    let attachment_msg = '';
                    if (response.data.attachment_details && response.data.attachment_details.status === false) {
                        attachment_msg = 'Failed file attachment.';
                    }
                    this.setState({ 
                                    valid: true,
                                });
                    this.props.UpdateParent(this.state.valid, attachment_msg);
                }
                else {
                    let snackbar = true;
                    if (typeof response.data.errors !== 'undefined' && response.data.errors) {
                        snackbar = false;
                    }
                    this.setState({ 
                        snackbar : snackbar,
                        valid:false,
                        error_message :  response.data.message,
                        errors : response.data.errors ? response.data.errors : {},
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }
    clearForm = () => {
        let ff = this.state.formFields;
        for (let key in ff) {
            if(key === 'id') {
                ff[key] = 0;
            } 
            else if(key === 'custom_recipient') {
                ff[key] = [];
            }else if(key === 'remove_file') {
                ff[key] = false;
            }
            else if (key === 'file' || key === 'file_name') {
                ff[key] = null;
            }
            else {
                ff[key] = '';
            }
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };

    arrayColumn = (array, columnName) => {
        return array.map(function(value,index) {
            return value[columnName] ? value[columnName] : "0";
        })
    }

    onEditRequest = (id) => {
        let data = this.state.formFields;
        if(id) {
            data.id = id;
        }
        data.type = 1;
        data.doc = this.state.file;
        data.removeFile = this.state.removeFile;
        data.removeFileId = this.state.removeFileId;
        if (this.state.formFields.custom_recipient) {
            let c_recipients = this.arrayColumn(this.state.formFields.custom_recipient,'user_id');
            data['custom_recipients'] = JSON.stringify(c_recipients);
        }
        if (this.handleValidation()) {
            return httpServices.post('api/overtime/edit',data)
            .then(response => {
                if (response.data.edit_success) {
                    let attachment_msg = '';
                    if (response.data.attachment_details && response.data.attachment_details.status === false) {
                        attachment_msg = 'Failed file attachment.';
                    }
                   this.setState({ 
                                    valid: true,
                                    });
                    formFields.remove_file = false;
                    this.props.UpdateParent(this.state.valid, attachment_msg);
                } else {
                    let snackbar = true;
                    let errors = {};
                    if (typeof response.data.errors !== 'undefined' && response.data.errors) {
                        snackbar = false;
                        errors = response.data.errors;
                    }
                    this.setState({ 
                        open : true,
                        snackbar : snackbar,
                        valid : false,
                        error_message : response.data.message ? response.data.message : '',
                        errors : errors,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }

    onList = (id) => {
        return httpServices.post('api/overtime/list', {'id': id})
            .then(response => {
                if (response.data.requests) {
                    formFields.startDate = response.data.requests[0].start_date;
                    formFields.hours = response.data.requests[0].hours;
                    formFields.description = response.data.requests[0].description;
                    formFields.refNo = response.data.requests[0].reference_no;
                    formFields.id = response.data.requests[0].id;
                    formFields.attachment_id = response.data.requests[0].attachment_id;
                    formFields.file_name = response.data.requests[0].name;
                    formFields.custom_recipient = response.data.other_custom_users;
                    formFields.file = '';
                    this.setState({
                        open: true,
                        formFields: formFields,
                        relievedDate: response.data.relieved_date,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
        });
    }

    onClose = () => {
        this.setState({open : false});
        this.props.UpdateParentOnAddSuccess(false);
    }
    snackbarClose = () => {
        this.setState({
            snackbar : false,
            valid: false,
        });
    }

     handleRecipientChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'object' && value)
            {
                ff['custom_recipient'] = value;
            }
        } 
        this.setState({
            formFields: ff
        });
    };
    getOverTimeReceipients = () => {
        let sdate = this.state.formFields.startDate ? this.state.formFields.startDate : '';
        let apiEndpoint = 'api/overtime/getOvertimeReceipients';
        return httpServices.post(apiEndpoint, { sdate: sdate})
            .then(response => {
                if (response.data) {
                    this.setState({
                        recipients : response.data.recipients,
                        approvers : response.data.approvers
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    
    render(){
        return(
            <OvertimeAddComponent
                Open = {this.state.open}
                Close = {this.onClose.bind(this)}
                AddRequest={this.onAddRequest.bind(this)}
                HandleOnChange={this.handleOnChange.bind(this)}
                Errors={this.state.errors}
                FormFields={this.state.formFields}
                Snackbar = {this.state.snackbar}
                Valid = {this.state.valid}
                ErrorMessage = {this.state.error_message}
                SuccessMessage = {this.state.success_message}
                SnackbarClose = {this.snackbarClose.bind(this)}
                Week = {this.state.week !== 'Undefined' ?  this.state.week : 'Undefined' }
                HandleRecipientChange = {this.handleRecipientChange.bind(this)}
                Recipients={this.state.recipients !== 'undefined' ? this.state.recipients : this.props.Recipients}
                Approvers={this.state.approvers !== 'undefined' ? this.state.approvers : this.props.Approvers}
                Custom_recipients = {this.props.Custom_recipients}
                RemoveFile = {this.props.removeFile}
                UpdateParent  = {this.props.updateParent}
                UpdateParentOnAddSuccess = {this.props.updateParentOnAddSuccess}
                Doj = {this.props.Doj}
                RelievedDate={this.props.RelievedDate? this.props.RelievedDate : this.state.relievedDate}
            />
        )
    }
};
