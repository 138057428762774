import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import {DialogBoxMedium} from '../common/dialog.medium';
import { CustomizedSnackbars } from '../common/snackbars';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const EmailNotificationComponent = (props) => {
    let dataArr = props.EmailNotifications;
    let Open = props.Open;
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={props.Title}
                        columns={[
                            {
                                title: 'Category',
                                field: 'category',
                            },
                            {
                                title: 'Event',
                                field: 'name',
                            },
                           
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Modified By',
                                field: 'modified_by',
                            },
                            {
                                title: 'Modified Date',
                                field: 'modified_on',
                                render: rowData => <>{rowData.modified_on != 0 ? rowData.modified_on : ''} </>
                            },
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            paging: false,
                            sorting: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: '550px',
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                            },
                            {
                                icon: 'visibility',
                                tooltip: 'View',
                                onClick: (event, rowData) => {
                                    props.ViewNotificationDetails(rowData.id);
                                },
                            },
                        ]}                    

                    />
                </Grid>
            </Grid>
            
            <DialogBoxMedium
                    Open = {Open}
                    Cancel = {props.OnClose}
                    Title = {"Edit Email Notifications"}
                    OnSubmit = {props.OnSubmit}
                    Content = {<DialogContent FormProps={props}/>}
                    Loading = {props.ButtonLoader}
           />
            <CustomizedSnackbars
                open={props.SnackbarOpen}
                variant={props.SnackbarType}
                message={props.Message}
                handleClose={props.SnackbarClose}
            />

        </React.Fragment>
        </>
    )
};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="category"
                                name="category"
                                label="Category"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.category}
                                onChange={HandleOnChange}
                                disabled={true}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </ FormControl>
                    </Grid>        
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="name"
                                name="name"
                                label="Event"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.name}
                                disabled={true}
                                onChange={HandleOnChange}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField 
                                required
                                id="description"
                                name="description"
                                label="Description"
                                multiline={true}
                                variant="outlined"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.description}
                                onChange={HandleOnChange}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="subject"
                                name="subject"
                                label="Subject"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.subject}
                                onChange={HandleOnChange}
                                error={props.Errors.subject && true}
                                helperText={props.Errors.subject}
                            />
                        </ FormControl>
                    </Grid>   
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="user_status">
                                Status
                            </InputLabel>
                            <Select
                                value={typeof FormFields.user_status !== "undefined" ? FormFields.user_status : 1 }
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: "user_status",
                                    id: "user_status",
                                }}
                            >
                                {typeof FormFields.user_statuses != "undefined" && FormFields.user_statuses !== "" &&
                                    FormFields.user_statuses.map((t, i) => (
                                        <MenuItem
                                            value={t.user_status_id}
                                            key={"stst-" + i}
                                        >
                                            {t.user_status_name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <small className='error'>{(typeof props.Errors.user_status !== 'undefined' && props.Errors.user_status) ? props.Errors.user_status : ''}</small>
                        </ FormControl>
                    </Grid>                  
        
                </Grid>
            </form>
)
}
