import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

const headCells = [
  { id: 'title', numeric: false, disablePadding: true, label: 'Title' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
];


const EnhancedTableToolbar = props => {
const CompensationComponentStyle  = styled(React.Fragment)(({ theme }) => ({
        '& .root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        '& .highlight':
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
        '& .title': {
            flex: '1 1 100%',
        },
    }));
  const { numSelected } = props;
  return (
    <CompensationComponentStyle>
    <Toolbar
      className={clsx('root', {
        ['highlight']: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={'title'} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={'title'} variant="h6" id="tableTitle">
          Components 
        </Typography>
      )}
    </Toolbar>
    </CompensationComponentStyle>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CompensationComponentStyle = styled(React.Fragment)(({ theme }) => ({
  '& .root': {
    width: '100%',
  },
  '& .paper': {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  '& .table': {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export class Compensation extends React.Component{
    render(){
        return(
            <CompensationComponents 
                SystemComponents = {this.props.SystemComponents}
                HandleComponentCheckBoxClick = {this.props.HandleComponentCheckBoxClick}
                HandleSelectAllClick = {this.props.HandleSelectAllClick}
                SelectAllComponentsFlag = {this.props.SelectAllComponentsFlag}
                SelectedComponentsLength = {this.props.SelectedComponentsLength}
            />
        )
    }
}

const CompensationComponents = (props) => {

  return (
    <CompensationComponentStyle>
    <div className={'root'}>
      <Paper className={'paper'}>
        <EnhancedTableToolbar numSelected={props.SelectedComponentsLength ? props.SelectedComponentsLength : null} />
        <TableContainer>
          <Table
            className={'table'}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onClick={props.HandleSelectAllClick}
                            checked={props.SelectAllComponentsFlag}
                            indeterminate
                        />
                    </TableCell>
                    {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                    >
                    {headCell.label}
                    </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.SystemComponents.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={event => props.HandleComponentCheckBoxClick(event, row.id)}
                      role="checkbox"
                      aria-checked={row.isSelected}
                      tabIndex={-1}
                      key={row.title}
                      selected={row.isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={row.isSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.title}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
    </div>
    </CompensationComponentStyle>
  );
};

