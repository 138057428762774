import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ucwords } from 'bellajs';
import { formatName } from '../../common/common';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { ErrorPage } from '../../common/error';
import Alert from '@mui/material/Alert';
import * as _R from 'ramda';

const Styles = styled(React.Fragment)(({ theme }) => ({
    '& .table': {
        width: '100%',
    },
    '& .tooltip': {
        maxWidth: 'none',backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    }
}));
const API_URL = process.env.REACT_APP_API_URL;
export const TimeOffDetailsComponent = (props) => {
    const { classes, staffDetails, listView} = props;
    return (
        <Styles>
            <form action={API_URL+'api/report/timeoffConsolidatedlog'} method="post">
                <input type = "hidden" name='user_id' value={staffDetails.user_id}></input>
                <div className={'section-main section-main-header-fixed'}>
                    <>
                        <div className={'header-fixed'}>
                            <div className={'section-header'}>
                                <div className={'col-left stretched'}>
                                    {(props.listView)?
                                        <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                                    :<></>
                                    }
                                    <div className={'steps-boxed-input st-HSpacingL_1'}>
                                        <Grid container className={'timeoff-filter'}>
                                            <Grid item md={6} >
                                                <Select
                                                    value={props.selectedYear}
                                                    inputProps={{
                                                        name: 'year',
                                                        id: 'year',
                                                    }}
                                                    onChange = {props.HandleOnChange}>
                                                    {props.years.map((year, i) =>
                                                        <MenuItem value={year} key={'year'+i}>{year}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button variant="contained" color="primary" size="small" onClick={props.getTimeOffDetails}>Filter</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = {'section-body'}>
                            <>
                                <div className = {'page-details-header profile-view__basics_default'}>
                                    <div className = {'title-box profile-view__basics__main'}>
                                    {(typeof(staffDetails) != 'undefined' && staffDetails) ?
                                    <>
                                        <h1 className = {'profile-view__basics-item item-title'}>
                                        {_R.isNil(staffDetails.salutation)?formatName(staffDetails.first_name,null,staffDetails.last_name):staffDetails.salutation+' '+formatName(staffDetails.first_name,null,staffDetails.last_name)}
                                        </h1>
                                        <div className = {'status'}>
                                        {staffDetails.staff_type && <span className = {'staff-type'}>
                                            { ucwords(staffDetails.staff_type) }
                                        </span>}
                                        {staffDetails.emp_category && <span className = {'emp-category'}>
                                            { ucwords(staffDetails.emp_category) }
                                        </span>}
                                        {staffDetails.overtime && <span className = {'overtime'}>
                                        { ucwords(staffDetails.overtime) }
                                        </span>}
                                        </div>
                                    </>
                                    :
                                        <> </>
                                    }
                                    </div>
                                    <div className={'profile-view__basics_default-item'}>
                                        <label>Staff ID</label><span className={"value"}>{staffDetails.staff_id}</span>
                                    </div>
                                    <div className={'profile-view__basics_default-item item-designation'}>
                                        <label>Date Joined</label>
                                        <span className={"value"}>{staffDetails.date_joined}</span>
                                    </div>
                                    { staffDetails.status_text.toLowerCase() === 'active'?
                                        <div className={'profile-view__basics_default-item item-status status-active'}>
                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span>
                                        </div>
                                    :
                                        <div className={'profile-view__basics_default-item item-status status-inactive'}>
                                            <label>Status</label><span className={"value"} >{staffDetails.status_text.toUpperCase()}</span>
                                        </div>
                                    }
                                    {(staffDetails.timeoff_eligibility === 'custom_start_date') &&
                                        <div className={'profile-view__basics_default-item item-mainproject'}>
                                            <label> Time Off Eligibility Start Date </label>
                                            <span className={"value"}>{staffDetails.doj_override_date}</span>
                                        </div>
                                    }
                                    <div className={'profile-view__basics_default-item item-mainproject'}>
                                        <label>Time Off Eligible Experience as on {props.onDate}</label>
                                        <span className={"value"}>{props.experience.years + ' Years ' + props.experience.months + ' months ' + props.experience.days + ' days '}</span>
                                    </div>

                                </div>
                                { props.ErrorMsg ?
                                    <ErrorPage Message ={props.ErrorMsg} />
                                    :<></>
                                }
                                <div className={'page-details-content'}>
                                    <div className={'content-sidebar'}>
                                        <div className={'timeoff-items'}>
                                            {_R.type(props.timeoffTypes) !== undefined && Object.keys(props.timeoffTypes).map((k) =>
                                            <>
                                            <div style={{ cursor: 'pointer'}} onClick={props.ChangeTimeoffType(props.timeoffTypes[k]['id'])} className={`item ${(props.SelectedId != '' && props.SelectedId == props.timeoffTypes[k]['id']) ? 'item-selected' : ''}`}>
                                                <div className={'title'}>{ props.timeoffTypes[k]['to_type']}</div>
                                                <div className={'value-container'}>
                                                    <>
                                                    <div className={'value'}>
                                                        <div className={'label'}> ELIGIBLE </div>
                                                        <span className="days"> {props.timeoffTypes[k]['details']['eligible_days']} Days </span>
                                                        <span className="hours"> {props.timeoffTypes[k]['details']['eligible_hours']} Hours </span>
                                                    </div>
                                                    <div className={'value'}>
                                                        <div className={'label'}> USED </div>
                                                        <span className="days"> {props.timeoffTypes[k]['details']['used_days']} Days </span>
                                                        <span className="hours"> {props.timeoffTypes[k]['details']['used_hours']} Hours </span>
                                                    </div>
						    {(props.selectedYear < props.CutoffYear || ( props.selectedYear >= props.CutoffYear && props.timeoffTypes[k]['event_based'] == 0)) &&
                                                    <div className={'value'}>
                                                        <div className={'label'}> BALANCE </div>
                                                        <span className="days"> {props.timeoffTypes[k]['details']['balance_days']} Days </span>
                                                        <span className="hours"> {props.timeoffTypes[k]['details']['balance_hours']} Hours </span>
                                                    </div>
					            }
                                                    </>
                                                </div>
                                            </div>
                                            </>
                                            )}
                                            {_R.type(props.holidayLog) !== undefined && Object.keys(props.holidayLog).map((k) =>
                                            <>
                                            <div style={{ cursor: 'pointer'}} onClick={props.ChangeHolidayType(props.holidayLog[k]['id'])} className={`item ${(props.SelectedId != '' && props.SelectedId == props.holidayLog[k]['id']) ? 'item-selected' : ''}`}>
                                                <div className={'title'}>{ props.holidayLog[k]['to_type']}</div>
                                                <div className={'value-container'}>
                                                    <>
                                                    <div className={'value'}>
                                                        <div className={'label'}> ELIGIBLE </div>
                                                        <span className="days"> {props.holidayLog[k]['details']['eligible_days']} Days </span>
                                                        <span className="hours"> {props.holidayLog[k]['details']['eligible_hours']} Hours </span>
                                                    </div>
                                                    <div className={'value'}>
                                                        <div className={'label'}> USED </div>
                                                        <span className="days"> {props.holidayLog[k]['details']['used_days']} Days </span>
                                                        <span className="hours"> {props.holidayLog[k]['details']['used_hours']} Hours </span>
                                                    </div>
                                                    <div className={'value'}>
                                                        <div className={'label'}> BALANCE </div>
                                                        <span className="days"> {props.holidayLog[k]['details']['balance_days']} Days </span>
                                                        <span className="hours"> {props.holidayLog[k]['details']['balance_hours']} Hours </span>
                                                    </div>
                                                    </>
                                                </div>
                                            </div>
                                            </>
                                            )}
                                        </div> 
                                    </div>
                                    <div className={'content-main'}>
                                        {( _R.type(props.SelectedType['full_details']['to_type'] !== undefined ) &&  props.SelectedType['full_details']['to_type']) &&
                                            <>
						<Paper  className={"mt-1"}>
                                                <Table className={'table'} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{'width':'400px'}} component="th" scope="row" className={'section-title title-main'}> {props.SelectedType['full_details']['to_type']} </TableCell>
                                                            {(props.SelectedType['full_details']['to_type'] !== 'Holidays') ? <TableCell component="te" scope="row" align="right" width="150">Hours</TableCell> : ''}
                                                            {(props.SelectedType['full_details']['to_type'] !== 'Holidays') ? <TableCell component="th" scope="row" align="right" width="150" >Days</TableCell> : ''}
                                                        </TableRow>
                                                    </TableHead>
                                                {(props.SelectedType['full_details']['view_object']) && <TableBody>
                                                    {props.SelectedType['full_details']['view_object'].map((value, index) => <TableRow>
							{((props.selectedYear <  props.CutoffYear|| (props.selectedYear >= props.CutoffYear && props.SelectedType['event_based'] && !value['label'].includes('Unused'))) || !props.SelectedType['event_based']) ?
							<>
							<TableCell component="td" scope="row">{value['label']}
                                                            {(props.SelectedType['full_details']['additional_info']) && (index === 0) &&
                                                                <Tooltip
                                                                    classes={{ tooltip: 'tooltip' }}
                                                                    title={props.SelectedType['full_details']['additional_info'].map((k, v) => <p>{
                                                                        (k.eligible_hours).toFixed(2) + " (Hours)" + " * " + (('months' in k) ? k.months + "(months)" : ('pay_periods' in k) ? k.pay_periods + "(pay periods)" : ('days' in k) ? k.days + " (days)" : "") + " = " + (k.total_hours).toFixed(2) + " (Hours) "
                                                                    }
                                                                    </p>)
                                                                    }
                                                                    arrow
                                                                >
                                                                    <InfoOutlinedIcon color="primary" fontSize='small' />
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={(value['hours'] < 0) ? 'error' : ''}>{parseFloat(value['hours']).toFixed(2)}</TableCell>
                                                        <TableCell component="td" scope="row" align="right" className={(value['days'] < 0) ? 'error' : ''}>{parseFloat(value['days']).toFixed(2)}</TableCell>
						      </> : <> </>
							}

					             </TableRow>)}
                                                </TableBody>
                                                }
                                                </Table>
						</Paper>
						<Paper  className={"mt-1"}>
						{(props.selectedYear >=  props.CutoffYear && props.SelectedType['event_based'] && props.SelectedType['timeoff_events'] && props.SelectedType['timeoff_events'].length > 0 ) ?
						<div >
						     <Grid item xs={12} md={6}>
                                                   <div className={"ml-1"}>
                                                        <h6 class="MuiTypography-root section-title MuiTypography-h6"> {props.SelectedType['full_details']['to_type']} events </h6>
                                                    </div>
                                                </Grid>
                                                    <Table className="userTimeoffList" size="small" aria-label="a dense table">
                                                        <TableHead className='header'>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">Start Date</TableCell>
                                                                <TableCell component="th" scope="row">End Date</TableCell>
								 <TableCell component="th" scope="row">Eligible Days</TableCell>
                                                                <TableCell component="th" scope="row">Eligible Hours</TableCell>
                                                                <TableCell component="th" scope="row">Used Days</TableCell>
							        <TableCell component="th" scope="row">Used Hours</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(props.SelectedType['timeoff_events']).map((row) =>
                                                                <TableRow>
                                                                    <TableCell component="td" scope="row">{row.from_date}</TableCell>
                                                                    <TableCell component="td" scope="row">{row.to_date}</TableCell>
                                                                    <TableCell component="td" scope="row">{parseFloat(row.event_eligible_days).toFixed(2)}</TableCell>
								    <TableCell component="td" scope="row">{parseFloat(row.event_eligible_hours).toFixed(2)}</TableCell>
                                                                    <TableCell component="td" scope="row">{parseFloat(row.event_used_days).toFixed(2)}</TableCell>
                                                                    <TableCell component="td" scope="row">{parseFloat(row.event_used_hours).toFixed(2)}</TableCell>
								</TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </div>
						: <></>}
						{(props.selectedYear < props.CutoffYear && props.SelectedType['event_based']) ?
							<Alert severity="info"> There are no events for the selected time off type </Alert>
							: <></>
						}
						</Paper>
						<Paper  className={"mt-1"}>
                                                {(props.SelectedType['adjustments'] && props.SelectedType['adjustments'].length > 0 ) && <div className={"mt-1"}>
                                                    <div className={"mt-1 ml-1"}>
                                                        <Typography variant="button" display="block" gutterBottom style={{fontWeight: 'bold', color: '#000', textTransform: 'capitalize'}}>{props.SelectedType['full_details']['to_type']} Adjustment/Carry forward</Typography>
                                                    </div>
                                                    <Table className="userTimeoffList" size="small" aria-label="a dense table">
                                                        <TableHead className='header'>
                                                            <TableRow>
                                                                {(props.SelectedType['adjustment_period'] === 'payperiod') &&
                                                                    <TableCell component="th" scope="row">Pay Period</TableCell>
                                                                }
                                                                <TableCell component="th" scope="row" >Category</TableCell>
                                                                <TableCell component="th" scope="row">Note</TableCell>
                                                                <TableCell component="th" scope="row" align="right" width="150">Hours</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(props.SelectedType['adjustments']).map((row) =>
                                                                <TableRow>
                                                                    {(props.SelectedType['adjustment_period'] === 'payperiod') &&
                                                                        <TableCell component="td" scope="row">{row.payperiod_sdate + ' - ' + row.payperiod_edate}</TableCell>
                                                                    }
                                                                    <TableCell component="td" scope="row">{row.category}</TableCell>
                                                                    <TableCell component="td" scope="row">{row.note} </TableCell>
                                                                    <TableCell component="td" scope="row" align="right">{parseFloat(row.hours).toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            <TableRow>
                                                                {props.SelectedType['adjustment_period'] === 'payperiod' ?
                                                                    <TableCell component="td" scope="row" colSpan={3}><b>Total</b> </TableCell>
                                                                    : <TableCell component="td" scope="row" colSpan={2}><b>Total</b> </TableCell>
                                                                }
                                                                <TableCell component="td" scope="row" align="right">{parseFloat(props.SelectedType['adjustmentshours']).toFixed(2)}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>}
						</Paper>
						<Paper  className={"mt-1"}>
                                                {(props.SelectedType['timeoff_type_user_policies'] && props.SelectedType['timeoff_type_user_policies'].length > 0 ) && <div className={"mt-1"}>
                                                    <div className={"mt-1 ml-1"}>
                                                        <Typography variant="button" display="block" gutterBottom style={{fontWeight: 'bold', color: '#000', textTransform: 'capitalize'}}>Eligibility Criteria Override</Typography>
                                                    </div>
                                                    <Table className= "userTimeoffList" size="small" aria-label="a dense table">
                                                        <TableHead className='header'>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" >Experience from</TableCell>
                                                                <TableCell component="th" scope="row" >Experience to</TableCell>
                                                                <TableCell component="th" scope="row" >Unit</TableCell>
                                                                <TableCell component="th" scope="row" >Effective Date</TableCell>
                                                                <TableCell component="th" scope="row" >Days</TableCell>
                                                                <TableCell component="th" scope="row" >Note</TableCell>
                                                                <TableCell component="th" scope="row" >Created by</TableCell>
                                                                <TableCell component="th" scope="row" >Created on</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            { Object.keys(props.SelectedType['timeoff_type_user_policies']).map((key) =>
                                                            <TableRow>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['lower_limit']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['upper_limit']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['eligibility_criteria_unit']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['effective_date']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['no_days']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['note']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['cby_first_name']+' '+props.SelectedType['timeoff_type_user_policies'][key]['cby_last_name']}</TableCell>
                                                                <TableCell component="td" scope="row">{props.SelectedType['timeoff_type_user_policies'][key]['created_on']}</TableCell>
                                                            </TableRow> )}
                                                        </TableBody>
                                                    </Table>
                                                </div> }
						</Paper>
						<Paper  className={"mt-1"}>
                                                {(props.SelectedType['display_labels'] != undefined) && <Grid container  justify="space-between" alignItems="center" spacing={0} >
                                                   <Grid item xs={12} md={6}>
						   <div className={"ml-1"}>
                                                        <h6 class="MuiTypography-root section-title MuiTypography-h6">Log</h6>
                                                    </div>
						</Grid><Grid item xs={12} md={6}>
                                                    {(listView == true) && <div className={"ml-1"} style = {{display : 'flex'}}>
                                                        <Button variant="contained" style ={{marginLeft: 'auto', marginRight : '1rem'}} color="primary" size="small" type='submit' name='export' value="export">Consolidated Log</Button>
                                                        </div>
                                                    }
						    </Grid>
                                                    <Table className="userTimeoffList" size="small">
                                                        <TableHead className='header'>
                                                            <TableRow>
                                                                <TableCell>Pay Period</TableCell>
                                                                <TableCell> Timesheet Status </TableCell>
                                                                {(props.SelectedType['display_labels']).map((key, value)=> <>
                                                                        <TableCell align="right"> {key}</TableCell>
                                                                    </> )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            { props.SelectedType['accruals'] && Object.keys(props.SelectedType['accruals']).map((key) =>
                                                            <TableRow >
                                                                <TableCell> 
                                                                    <Link onClick={props.ViewLink(props.SelectedType['accruals'][key].timesheet_id)} className={'link'} >
                                                                    {props.SelectedType['accruals'][key].payperiod_sdate+' - '+props.SelectedType['accruals'][key].payperiod_edate}
                                                                    </Link>
                                                                    </TableCell>
                                                                <TableCell>{props.SelectedType['accruals'][key].status}</TableCell>
                                                                {(props.SelectedType['accruals'][key].eligible_hours !== undefined) && <TableCell align="right">{(props.SelectedType['accruals'][key].eligible_hours != 'N/A') ? parseFloat(props.SelectedType['accruals'][key].eligible_hours).toFixed(2) : ''}</TableCell>}
                                                                <TableCell  align="right">{(props.SelectedType['accruals'][key].taken_hours != 'N/A') ? parseFloat(props.SelectedType['accruals'][key].taken_hours).toFixed(2) : '' }</TableCell>
                                                            </TableRow>
                                                            )}
                                                            { (props.SelectedType['accruals'] && (Object.keys(props.SelectedType['accruals']).length>0)) &&
                                                            <TableRow>
                                                                <TableCell  align="right" colSpan={2}><b>Total</b></TableCell>
                                                                    {(props.SelectedType['accruals'][0].eligible_hours !== undefined) &&
                                                                        <TableCell  align="right">
                                                                            {Object.keys(props.SelectedType['accruals']).map((key, value) =>
                                                                                { return  props.SelectedType['accruals'][key].eligible_hours?parseFloat(props.SelectedType['accruals'][key].eligible_hours):0;}).reduce((a,b) => { return  a+b;}).toFixed(2)}
                                                                        </TableCell>
                                                                    }
                                                                        <TableCell  align="right">
                                                                            {Object.keys(props.SelectedType['accruals']).map((key) =>  { return props.SelectedType['accruals'][key].taken_hours?parseFloat(props.SelectedType['accruals'][key].taken_hours):0;}).reduce((a,b) => { return a+b;}).toFixed(2)
                                                                            }
                                                                        </TableCell>
                                                            </TableRow>
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </Grid>}
                                        </Paper>
	    				</>}
                                    </div>
                                </div>
                            </>
                        </div>
                    </>
                </div>
            </form>
        </Styles>
    )
};









