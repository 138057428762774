import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { formatName } from '../common/common';
import { CustomDatePicker } from '../common/CustomDatePicker';


export const AuditLogComponent = (props) => {
    const gridStyle = {
        margin: '15px 0px'
    };
    return (
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Audit Log</Typography>
            </div>
            <Paper md={12}>
                <form className="form-fullwidth">
                    <Grid container spacing={3} style={gridStyle}>
                        <Grid item  md={2}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="staff">User Name</InputLabel>
                                <Select
                                    value={(typeof props.formFields.staff !== 'undefined') ? props.formFields.staff : null}
                                    onChange={props.HandleOnChange}
                                    error = { props.Errors.staff && true}
                                    inputProps={{
                                        name: 'staff',
                                        id: 'staff',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                    {  typeof props.formInputs.staff_list != 'undefined' && props.formInputs.staff_list.map((t, i) =>
                                        <MenuItem value={t.user_id}>{formatName(t.first_name, null, t.last_name)}</MenuItem>
                                    )}
                                </Select>
                                { props.Errors.staff && <FormHelperText error>{props.Errors.staff}</FormHelperText> }
                            </ FormControl>
                        </Grid>
                        <Grid item  md={2}>
                            <FormControl variant="standard">
                                <InputLabel htmlFor="modules">Module</InputLabel>
                                <Select
                                    value={(typeof props.formFields.module !== 'undefined') ? props.formFields.module : '0'}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'module',
                                        id: 'module',
                                    }}>
                                    <MenuItem value='0'>All</MenuItem>
                                    {  typeof props.Modules != 'undefined' && props.Modules.map((t, i) =>
                                        <MenuItem value={t.module}>{t.module}</MenuItem>
                                    )}
                                </Select>
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <CustomDatePicker
                                    name = "start_date"
                                    id = "start_date"
                                    selectedDate = {props.formFields.start_date}
                                    handleDateChange = {props.HandleDateChange}
                                    error = {props.Errors.start_date}
                                    required = {true}
                                    label = "From"
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <CustomDatePicker
                                    name = "end_date"
                                    id = "end_date"
                                    selectedDate = {props.formFields.end_date}
                                    handleDateChange = {props.HandleDateChange}
                                    error = {props.Errors.end_date}
                                    required = {true}
                                    label = "To"
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="small" color="primary" onClick={ props.OnSearchClick }>Show Log</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            {(props.formInputs.auditlog_list)?
                <CustomMaterialTable
                        title={"Audit Log"}
                        columns={[
                            {title: 'User Name',field: 'user_fullName'},
                            {title: 'Date and Time',field: 'created_on'},
                            {title: 'IP Address',field: 'ipaddress'},
                            {title: 'User Agent',field: 'useragent'},
                            {title: 'Version',field: 'app_version'},
                            {title: 'Module',field: 'module'},
                            {title: 'Controller',field: 'controller'},
                            {title: 'Action',field: 'action'},
                            {title: 'Summary',field: 'summary'},
                        ]}
                        data={props.formInputs.auditlog_list}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}
                        actions={[
                            rowData => ({
                                icon: 'visibility',
                                tooltip: 'Change History',
                                onClick: (event, rowData) => {
                                    props.ShowChangeHistory(rowData.id);
                                },
                                hidden : (rowData.change_history == 0 ) ? true : false, 
                            }),                          
                        ]}
                                            
                    />
                :<></>
            }
            {props.Open &&
            <DialogBoxLarge
                Open={props.Open}
                Cancel={props.Close}
                Title={'Change History'}
                History={props.ChangeHistory}
            />
            }
        </>
    )
}

export const DialogBoxLarge = (props) => {
    return (
        <Dialog
                className={'modal_default large'}
                open={props.Open}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">{props.Title}</DialogTitle>
                <DialogContent>
                    {typeof props.History !== 'undefined' && typeof props.History.changelog_arr !== 'undefined' && props.History.changelog_arr.length > 0 ?
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Entity Field</TableCell>
                                <TableCell>Previous Value</TableCell>
                                <TableCell>Current Value</TableCell>
                                <TableCell>Modified By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(props.History.changelog_arr).map((item) =>
                            <TableRow key = {item.id}>
                                <TableCell component="th" scope="row"> {props.History.created_on} </TableCell>
                                <TableCell component="th" scope="row"> {item.display_name} </TableCell>
                                <TableCell component="th" scope="row"> {item.prev} </TableCell>
                                <TableCell component="th" scope="row"> {item.new}</TableCell>
                                <TableCell component="th" scope="row"> {formatName(props.History.user_first_name,null,props.History.user_last_name)} </TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                    :
                    <Typography variant="body2" gutterBottom align='center'>No changes for this log.</Typography>
                    }
                    </DialogContent>
                <DialogActions>
                    <Button color="primary" size="small" onClick={props.Cancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
    )
}

