import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';

const API_URL = process.env.REACT_APP_API_URL;

export const TimesheetExportComponent = (props) => { console.log(props);
    let clients = (_R.type(props.Filters) !== 'Undefined' && _R.type(props.Filters.clients) !== 'Undefined') ? props.Filters.clients : [];
    let projects = ( _R.type(props.Filters) !== 'Undefined' && _R.type(props.Filters.projects) !== 'Undefined') ? props.Filters.projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.FilterFields) !== 'Undefined') ? props.FilterFields.projects : []));
    }
    const mstyle={ chips: { background: "#f4f4f4", color: 'black' }}
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Timesheet Export for CTMS Reconciliation</h6>
                </Grid>
                <Grid item>
                    <form className="form-fullwidth" action={API_URL+'api/report/reconcile-timesheet-export'} method="post" id="timesheetExport">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.FilterFields.start_date}
                                        id="start_date"
                                        name="start_date"
                                        label="Start Date"
                                        type="date"
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.Errors.start_date && true}
                                        helperText={props.Errors.start_date}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <FormControl>
                                    <TextField variant="standard"
                                        value = {props.FilterFields.end_date}
                                        id="end_date"
                                        name="end_date"
                                        required
                                        label="End Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={props.HandleFilterChange}
                                        error={props.Errors.end_date && true}
                                        helperText={props.Errors.end_date}
                                    />
                                </ FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <FormControl error={props.Errors.staffStatus && true} variant="standard">
                                    <InputLabel required htmlFor="staffStatus">Staff Status</InputLabel>
                                    <Select
                                        required
                                        value={props.FilterFields.staffStatus}
                                        inputProps={{
                                            name: 'staffStatus',
                                            id: 'staffStatus',
                                        }}
                                        onChange={props.HandleFilterChange}
                                    >
                                        <MenuItem value={'0'}>{"All"}</MenuItem>
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.staffStatuses) !== 'Undefined' && props.Filters.staffStatuses.map((s, i) =>
                                            <MenuItem key={'staff-'+i} value={s.user_status_id}>{s.user_status_name}</MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText >{props.Errors.staffStatus}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Staff Name Option</FormLabel>
                                        <RadioGroup aria-label="name_option" name="name_option" value={props.FilterFields.name_option} onChange={props.HandleFilterChange}>
                                            <FormControlLabel value="alias_name" control={<Radio />} label="Alias Name" />
                                            <FormControlLabel value="staff_name" control={<Radio />} label="Staff Name" />
                                        </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2} >
                            { props.FilterFields.name_option === 'staff_name' ?
                                <FormControl error={_R.type(props.Errors.staff_name_format) !== 'Undefined'?true:false} variant="standard">
                                    <InputLabel required htmlFor="staff_name_format">Staff Name Format</InputLabel>
                                    <Select
                                        required
                                        value={props.FilterFields.staff_name_format}
                                        inputProps={{
                                            name: 'staff_name_format',
                                            id: 'staff_name_format',
                                        }}
                                        onChange={props.HandleFilterChange}
                                    >
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.name_formats) !== 'Undefined' && props.Filters.name_formats.map((rtype, i) =>
                                            <MenuItem key={'project-'+i} value={rtype.key}>{rtype.value}</MenuItem>
                                        )}
                                    
                                    </Select>
                                    {_R.type(props.Errors.staff_name_format) !== 'Undefined' && <FormHelperText>{props.Errors.staff_name_format}</FormHelperText>}
                                </FormControl>:
                                <FormControl error={_R.type(props.Errors.key_identifier) !== 'Undefined'?true:false} variant="standard">
                                    <InputLabel required htmlFor="key_identifier">Key Identifier</InputLabel>
                                    <Select
                                        required
                                        value={props.FilterFields.key_identifier}
                                        inputProps={{
                                            name: 'key_identifier',
                                            id: 'key_identifier',
                                        }}
                                        onChange={props.HandleFilterChange}
                                    >
                                        {_R.type(props.Filters) === 'Object' && _R.type(props.Filters.key_identifiers) !== 'Undefined' && props.Filters.key_identifiers.map((rtype, i) =>
                                            <MenuItem key={'project-'+i} value={rtype}>{rtype}</MenuItem>
                                        )}
                                    </Select>
                                    {_R.type(props.Errors.key_identifier) !== 'Undefined' && <FormHelperText>{props.Errors.key_identifier}</FormHelperText>}
                                </FormControl>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div class="filter-column">
                                    <div class="filter-column-title">Client</div>
                                    <div class="filter-column-content">
                                        <FormControl variant="standard">
                                            <InputLabel>Client Status</InputLabel>
                                            <Select
                                                value={(typeof props.FilterFields.clientStatus !== 'undefined') ? props.FilterFields.clientStatus : ''}
                                                onChange={props.HandleFilterChange}
                                                inputProps={{
                                                    name: 'clientStatus',
                                                    id: 'clientStatus',
                                                }}
                                            >
                                            <MenuItem value={'0'}>{"All"}</MenuItem>
                                            { _R.type(props.Filters) === 'Object' && _R.type(props.Filters.clientStatuses) !== 'Undefined' && props.Filters.clientStatuses.map((c, i) =>
                                                <MenuItem value={c.client_status_id}>{c.client_status_name}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl>
                                        <FormControl error={_R.type(props.Errors.client) !== 'Undefined'?true:false} variant="standard">
                                        <input type='hidden' name='client' value={props.FilterFields.client}/>
                                            <Multiselect
                                                options={clients}
                                                displayValue="client_name"
                                                placeholder="Search Client"
                                                selectedValues={props.selectedClientList}
                                                style = {mstyle}
                                                onSelect={props.OnSelect}
                                                onRemove={props.OnRemove}
                                                avoidHighlightFirstOption = {true}
                                                closeIcon = "cancel"
                                            />
                                            {_R.type(props.Errors.client) !== 'Undefined' && <FormHelperText>{props.Errors.client}</FormHelperText>}
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div class="filter-column">
                                    <div class="filter-column-title">Project</div>
                                    <div class="filter-column-content">
                                        <FormControl variant="standard">
                                            <InputLabel>Project Status</InputLabel>
                                            <Select
                                                value={(typeof props.FilterFields.projectStatus !== 'undefined') ? props.FilterFields.projectStatus : ''}
                                                onChange={props.HandleFilterChange}
                                                inputProps={{
                                                    name: 'projectStatus',
                                                    id: 'projectStatus',
                                                }}
                                            >
                                                <MenuItem value={'0'}>{"All"}</MenuItem>
                                            {_R.type(props.Filters) === 'Object' &&  _R.type(props.Filters.projectStatuses) !== 'Undefined' && props.Filters.projectStatuses.map((p, i) =>
                                                <MenuItem value={p.project_status_id}>{p.project_status_name}</MenuItem>
                                            )}
                                            </Select>
                                        </FormControl>
                                        <FormControl error={_R.type(props.Errors.projects) !== 'Undefined'?true:false}>
                                            <input type='hidden' name='projects' value={props.FilterFields.projects}/>
                                            <div>
                                                <TreeSelectComponent
                                                    data={projects}
                                                    placeholderText={"Search Projects"}
                                                    type='project'
                                                    HandleTreeChange={props.HandleTreeChange}
                                                />
                                            </div>
                                            {_R.type(props.Errors.projects) !== 'Undefined' && <FormHelperText>{props.Errors.projects}</FormHelperText>}
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} class='filter-action'>
                                <div class="filter-column">
                                    <div class="d-flex align-center justify-end">
                                        <Button variant="contained" size="small" color="primary" onClick={props.ValidateFilter}> Export</Button>
                                    </div>                                   
                                </div>
                            </Grid> 
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper>
        </>
    )
};
