import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';

const API_URL = process.env.REACT_APP_API_URL;
export const Attachments = (props) => {
    let exp = 'panel5';
    if (props.Expense === true) {
      exp = false;
    }
    const [expanded, setExpanded] = React.useState(exp);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            >
                <Typography variant="subtitle1" gutterBottom>Attachments</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {typeof props.Attachments !== 'undefined' && props.Attachments.length > 0 ?
                    <ul className="list-standard list-ul-shrink">
                        {props.Attachments.map((row, i) =>
                        <li className="list-item">
                            {row.attachment_name}<a href={API_URL + 'api/expenses/downloadImage?id=' + row.file_id}><GetAppIcon fontSize="small" /></a>
                        </li>
                        )}

                    </ul>
                    :
                    <Typography variant="body2" gutterBottom align='center'>No attachments added</Typography>
                }
            </AccordionDetails>
        </Accordion>
    );
}
