import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; 
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as R from 'ramda';
import timeGridPlugin from '@fullcalendar/timegrid';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import StopIcon from '@mui/icons-material/Stop';
import { withStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as _ts from '../../common/treeSelect';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import { DialogBoxMedium } from '../../common/dialog.medium';

const requestTypeColors = {timeoff : '#e3b1f1', offsite : '#fae4ad', overtime : '#92f4ff'};
export const CalendarComponent = (props) => {
    return (
        <React.Fragment>
            <Grid container spacing={3} className={'request-calendar'}>
                <Grid item md={3} xs={12}>
                    <div className={'calendar-filter'}>
                        <FilterComponent
                            HandleEventChange={props.HandleEventChange}
                            Filter={props.Filter}
                            RequestConfig={props.RequestConfig}
                            HandleOnChange={props.HandleOnChange}
                            HandleStatusChange={props.HandleStatusChange}
                            Clients={props.Clients}
                            HandleClientChange={props.HandleClientChange}
                            Projects={props.Projects}
                            HandleTreeChange={props.HandleTreeChange}
                            ProjectResources={props.ProjectResources}
                            HandleEmployeeChange={props.HandleEmployeeChange}
                            HandleTypeChange={props.HandleTypeChange}
                            ShowClientProjectFilter={props.ShowClientProjectFilter}
                            ShowStaffFilter={props.ShowStaffFilter}
                        />
                    </div>
                </Grid>
                <Grid item md={9} xs={12} className={'req-calendar'}>
                    <FullCalendar
                        plugins={[ dayGridPlugin,timeGridPlugin, interactionPlugin ]}
                        buttonText={{today:'Today',week:'Week',month:'Month',day:'Day'}}
                        initialView={"dayGridMonth"}
                        events={props.Events}
                        datesSet = {(arg)=>{props.HandleDateChange(arg.view.activeStart,arg.view.activeEnd);}}
                        eventClick = {(arg)=> { 
                                if(R.type(arg.event) !== 'Undefined') {
                                    props.ViewEvent(arg.event);
                                }
                            }
                        }
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        dayCellClassNames = {(arg) => {
                                let classArray =[];
                                classArray.push('link');
                                return classArray;
                            }
                        }
                        eventContent={renderEventContent}
                        dayMaxEvents = {3}
                    />
                </Grid>
            </Grid>
            {(props.PopupType==='event_view' && props.Open) &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                ButtonName='More'
                OnSubmit={props.ViewEventDetails}
                Title={props.EventData.title}
                Content={
                    <DialogViewEvent 
                        Data={typeof props.EventData !== 'undefined' ? props.EventData : {}}
                    />
                }
            />
            }
        </React.Fragment>
    )
};

const FilterComponent = (props) => {
    // Request events filter values
    const allRequest = (props.Filter && props.Filter.request_event_type) ? (props.Filter.request_event_type.findIndex(x => x === 'all') !== -1) : false;
    const myRequest = (props.Filter && props.Filter.request_event_type) ? (props.Filter.request_event_type.findIndex(x => x === 'my') !== -1) : false;
    const reviewRequest = (props.Filter && props.Filter.request_event_type) ? (props.Filter.request_event_type.findIndex(x => x === 'review') !== -1) : false;
    // Request status filter values
    const statusAll = (props.Filter && props.Filter.status) ? (props.Filter.status.findIndex(x => x === '0') !== -1) : false;
    const statusApproved = (props.Filter && props.Filter.status) ? (props.Filter.status.findIndex(x => x === '2') !== -1) : false;
    const statusSubmitted = (props.Filter && props.Filter.status) ? (props.Filter.status.findIndex(x => x === '1') !== -1) : false;
    const statusRejected = (props.Filter && props.Filter.status) ? (props.Filter.status.findIndex(x => x === '3') !== -1) : false;
    const statusCancelled = (props.Filter && props.Filter.status) ? (props.Filter.status.findIndex(x => x === '4') !== -1) : false;
    const requestTypeAll = (props.Filter && props.Filter.request_type) ? (props.Filter.request_type.findIndex(x => x === 'all') !== -1) : false;

    let projects = typeof props.Projects !== 'undefined' ? props.Projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof props.Filter.projects !== 'undefined') ? props.Filter.projects : []));
    }
    const [expanded, setExpanded] = React.useState('calendar-events');
    const accordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <form className="form-fullwidth" >
                <div>
                    <Accordion expanded={expanded === 'calendar-events'} onChange={accordionChange('calendar-events')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="calendar-events"
                            id="calendar-events"
                        >
                            <span>Calendar Events</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup> 
                                <FormControlLabel
                                    control={<CalendarEventsCheckbox checked={allRequest} onChange={props.HandleEventChange} name="all" />}
                                    label="All Requests"
                                />
                                <FormControlLabel
                                    control={<CalendarEventsCheckbox checked={myRequest} name="my" onChange={props.HandleEventChange}/>}
                                    label="My Requests"
                                />
                                <FormControlLabel
                                    control={<CalendarEventsCheckbox checked={reviewRequest} name="review" onChange={props.HandleEventChange}/>}
                                    label="My Action Items"
                                />
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'req-type'} onChange={accordionChange('req-type')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="req-type"
                            id="req-type"
                        >
                            <span>Request Type</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={requestTypeAll} color={'primary'} onChange={props.HandleTypeChange} name="all" />}
                                    label="All"
                                />
                                {typeof props.RequestConfig !== 'undefined' && props.RequestConfig.map((rc, i) =>
                                    getRequestTypeCheckBox({type : rc.code, name : rc.name, onChange : props.HandleTypeChange, selectedReqTypes : props.Filter.request_type})
                                )
                                }
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'req-status'} onChange={accordionChange('req-status')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="req-status"
                            id="req-status"
                        >
                            <span>Request Status</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={statusAll} color={'primary'} onChange={props.HandleStatusChange} name="0" />}
                                    label="All"
                                />
                                <FormControlLabel
                                    control={<DarkcyanCheckbox checked={statusSubmitted} name="1" onChange={props.HandleStatusChange}/>}
                                    label="Submitted"
                                />
                                <FormControlLabel
                                    control={<GreenCheckbox checked={statusApproved} name="2" onChange={props.HandleStatusChange}/>}
                                    label="Approved"
                                />
                                <FormControlLabel
                                    control={<RedCheckbox checked={statusRejected} name="3" onChange={props.HandleStatusChange}/>}
                                    label="Rejected"
                                />
                                <FormControlLabel
                                    control={<CancelledCheckbox checked={statusCancelled} name="4" onChange={props.HandleStatusChange}/>}
                                    label="Cancelled"
                                />
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    {props.ShowClientProjectFilter &&
                    <>
                    <FormGroup className={'pt-1'}>
                        <Autocomplete
                            value={props.Filter.client_obj}
                            onChange={props.HandleClientChange}
                            options={(typeof props.Clients !== 'undefined') ? props.Clients : []}
                            getOptionLabel={(option) => option.client_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Client"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TreeSelectComponent
                            data = {projects}
                            placeholderText = { "Search Projects"}
                            type = 'project'
                            HandleTreeChange = {props.HandleTreeChange}
                        />
                    </FormGroup>
                    </>
                    }
                    {(props.ShowStaffFilter || props.ShowClientProjectFilter) &&
                    <FormGroup className={'pt-1 pb-1'}>
                        <Autocomplete
                            multiple
                            value={props.Filter.employee_obj}
                            onChange={props.HandleEmployeeChange}
                            options={(typeof props.ProjectResources !== 'undefined') ? props.ProjectResources : []}
                            getOptionLabel={(option) => option.user_fullname}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Staff"
                                    placeholder="Select Staff"
                                />
                            )}
                        />
                    </FormGroup>                    
                    }
                </div>
        </form>
    )
};

const DialogViewEvent = (props) => {
    return (
        <form className="form-fullwidth" >
            <ul className={'list-standard'}>
                <li className={'list-item list-item-status'}>
                    <label>Employee</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.user_fullname : ''}</span>
                </li>                
                {props.Data.title.split("-")[1].includes("Work Location") ?
                    <> <li className={'list-item'}>
                        <label>Type</label>
                        <span className={'value'}>{"Work Location Change"}</span>
                    </li>
                        <li className={'list-item'}>
                            <label>Location</label>
                            <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.request_type : ''}</span>
                        </li></>
                    :
                    <li className={'list-item'}>
                        <label>Type</label>
                        <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.request_type : ''}</span>                    </li>
                }               
                <li className={'list-item'}>
                    <label>Date</label>
                    <span className={'value'}>
                        {typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.start_date+' - '+props.Data.extendedProps.end_date : ''}
                    </span>
                </li>
                <li className={'list-item'}>
                    <label>Reason</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.description : ''}</span>
                </li>
                <li className={'list-item'}>
                    <label>Duration</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.custom_duration : ''}</span>
                </li>
                <li className={'list-item'}>
                    <label>Status</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.status_name : ''}</span>
                </li>
                <li className={'list-item'}>
                    <label>Created On</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.created_on : ''}</span>
                </li>
                {(typeof props.Data.extendedProps !== 'undefined' && typeof props.Data.extendedProps.reviewed_on !== 'undefined' &&props.Data.extendedProps.reviewed_on)&&
                <>
                <li className={'list-item'}>
                    <label>Reviewed On</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.reviewed_on : ''}</span>
                </li>
                <li className={'list-item'}>
                    <label>Reviewed By</label>
                    <span className={'value'}>{typeof props.Data.extendedProps !== 'undefined' ? props.Data.extendedProps.reviewed_by : ''}</span>
                </li>
                </>
                }
            </ul>
        </form>
    )
}
// Custom function for rendering calender event content
function renderEventContent(eventInfo) {
    let status = eventInfo.event.extendedProps.status ? parseInt(eventInfo.event.extendedProps.status) : 0;
    return (
        <div className={'fc-event-main'}>
            <div className={'fc-event-main-frame'}>
                <div className={'fc-event-title-container'}>
                    <div className={'fc-event-title fc-sticky'}>
                        {(eventInfo.event.extendedProps.action_required) &&
                            <TouchAppIcon fontSize="small" />
                        }
                        {(status === 1) &&
                            <StopIcon className={'statusIcon-submitted'} fontSize="small"/>
                        }
                        {(status === 2) &&
                            <StopIcon className={'statusIcon-approved'} fontSize="small" />
                        }
                        {(status === 3) &&
                            <StopIcon className={'statusIcon-rejected'} fontSize="small" /> 
                        }
                        {(status === 4) &&
                            <StopIcon className={'statusIcon-cancelled'} fontSize="small" />
                        }
                        {eventInfo.event.title}                
                    </div>
                </div> 
            </div>
        </div>
    )
}

const GreenCheckbox = withStyles({
  root: {
    color: 'green',
    '&$checked': {
      color: 'green',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
  root: {
    color: 'red',
    '&$checked': {
      color: 'red',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const DarkcyanCheckbox = withStyles({
  root: {
    color: '#bb00ff',
    '&$checked': {
      color: '#bb00ff',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CancelledCheckbox = withStyles({
  root: {
    color: '#aaa',
    '&$checked': {
      color: '#aaa',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CalendarEventsCheckbox = withStyles({
  root: {
    color: '#aaaaaa',
    '&$checked': {
      color: '#aaaaaa',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/* Generic functional component 
 * for returning the request type check box 
 */
function getRequestTypeCheckBox (props) {
    let selectedReqestTypes = props.selectedReqTypes;
    let idx = selectedReqestTypes.findIndex(x => x === props.type);
    let color = requestTypeColors[props.type] ? requestTypeColors[props.type] : '';
    const Checkbox2 = withStyles({
        root: {
            color: color,
            '&$checked': {
                color: color,
            },
        },
        checked: {},
    })((props: CheckboxProps) => <Checkbox {...props} />);

    return <FormControlLabel
        control = {<Checkbox2 name = {props.type} />}
        label = {props.name}
        onChange = {props.onChange}
        checked = {idx !== -1 ? true : false}
    />
}
