import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { formatName } from '../common/common';
import FormHelperText from '@mui/material/FormHelperText';

const API_URL = process.env.REACT_APP_API_URL;
export const ReimbursementReportComponent = (props) =>{
    console.log(props);

    const gridStyle = {
        margin: '15px 0px'
    };
    
    let minPeriodFrom = (typeof props.FormFields.periodFrom !== 'undefined') ? props.FormFields.periodFrom : null;
    return(
        <React.Fragment>
            <Paper md={12}>
                <div class="p1">
                <Typography variant="h6" component="h6" className={'section-title'}>Reimbursement Report</Typography>
                <form id="export-form" className="form-fullwidth" action={API_URL+'api/report/reimbursement-report'} method="post">
                    <Grid container spacing={3} style={gridStyle}>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField variant="standard" 
                                    id = "periodFrom"
                                    name = "periodFrom"
                                    label = "Period From"
                                    type = "date"
                                    required
                                    onChange = {props.HandleOnChange}
                                    error = { props.Errors.periodFrom && true }
                                    helperText = { props.Errors.periodFrom }
                                    value={(typeof props.FormFields.periodFrom !== 'undefined') ? props.FormFields.periodFrom : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "periodTo"
                                    name = "periodTo"
                                    label = "Period To"
                                    type = "date"
                                    required
                                    value={(typeof props.FormFields.periodTo !== 'undefined') ? props.FormFields.periodTo : null}
                                    onChange = {props.HandleOnChange}
                                    error = { props.Errors.periodTo && true }
                                    helperText = { props.Errors.periodTo }
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: minPeriodFrom, 
                                    }}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item  md={2}>
                            <FormControl error={props.Errors.employeeName && true} variant="standard">
                                <InputLabel htmlFor="employeeName">Employee Name</InputLabel>
                                <Select
                                    value={(typeof props.FormFields.employeeName !== 'undefined') ? props.FormFields.employeeName : null}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'employeeName',
                                        id: 'employeeName',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
                                    {typeof props.EmployeeList != 'undefined' &&  props.EmployeeList.map((t, i) =>
                                        <MenuItem value={t.user_id} key={'sn-'+i}>{formatName(t.user_first_name,null,t.user_last_name,props.NameFormat)}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.employeeName}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" size="small" onClick={props.ExportReports} name="export"> Export </Button>
                        </Grid>
                    </Grid>
                </form>
                </div>
            </Paper>
        </React.Fragment>
    )

}

