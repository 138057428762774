import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { OfficeComponent }  from './Office.Component.js';

let formFields = {
    name : '',
    id : 0
}
export class OfficeContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            editPermission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant: '',
            buttonLoader : false,
            offices : []
        }
        this.addOfficeClick = this.addOfficeClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editClick = this.editClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteOfficeSubmit = this.deleteOfficeSubmit.bind(this);
    }

    componentDidMount = () => {
        this.getOfficeLocations();
    };

    getOfficeLocations = () => {
        let apiEndpoint = 'api/settings/getoffices';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        offices : response.data.offices,
                        editPermission : response.data.edit_permission,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;       
        this.setState({
            formFields : ff,
        });
    };


    addOfficeClick = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    handleSubmit = () => {
        this.setState({buttonLoader : true });
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/addOffice', { data })
                    .then(response => {
                        if (response.data.permission) {
                            this.getOfficeLocations();
                            let successMsg = ''
                            if(data.id){
                                successMsg = "Office location updated successfully";
                            } else {
                                successMsg = "Office location added successfully";
                            }
                            if (response.data.status) {
                                this.setState({
                                    snackbarOpen : true,
                                    snackbarMsg : successMsg,
                                    snackbarVariant: 'success',
                                    open : false,
                                    popupType : '',
                                    formFields : {},
                                    errors : {},
                                    buttonLoader : false,
                                });
                            }
                            else
                            {   let errors = this.state.errors;
                                errors['name'] = response.data.error;
                                this.setState({
                                    errors : errors,
                                    buttonLoader : false,
                                });   
                            }
                        }
                        else {
                            this.setState({
                                buttonLoader : false
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
        else {
            this.setState({ buttonLoader : false });
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.name) {
            errors['name'] = "Cannot be empty";
            formIsValid = false;
        }
        else if(!this.state.formFields.name.trim())
        {
            errors['name'] = "Cannot be empty";
            formIsValid = false; 
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editClick = (id) => {
        let apiEndpoint = 'api/settings/get-office';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.office) {
                       let formFields = {
                           id : response.data.office.id,
                           name : response.data.office.name,                          
                        }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };    
    deleteOffice = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };
    deleteOfficeSubmit = () => {
        let del_id = this.state.deleteId;
        if (del_id) {
            let apiEndpoint = 'api/settings/deleteOffice';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    if (response.data.status) {
                        this.getOfficeLocations();
                        this.setState({
                            open : false
                        });
                        alert("Office deleted successfully");
                    } else {
                        alert(response.data.err_msg);
                        this.setState({
                            open : false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };

    render(){
        return(
            <OfficeComponent
                Offices = {this.state.offices}
                EditPermission = {this.state.editPermission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddOfficeClick = {this.addOfficeClick}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                OnSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                DeleteOffice = {this.deleteOffice}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditClick = {this.editClick}
                OnClose = {this.onClose}
                SnackbarVariant = {this.state.snackbarVariant}
                ButtonLoader = {this.state.buttonLoader}
                DeleteOfficeSubmit = {this.deleteOfficeSubmit}
            />
        )
    }
}
