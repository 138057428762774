import React from 'react';
import {PayrollGenerateComponent} from './PayrollGenerate.Component'
import {httpServices}  from '../../services/httpServices'
import {history} from './../../helpers/history'

const formFields = {
    'year': null,
    'pay_period': null,
    'sdate': null,
    'edate': null,
}

const getYear = () => {
    return new Date().getFullYear();
}

export class PayrollGenerateContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formFields: formFields,
            intervals:[],
            users: [],
            hasPermission: '',
            permision_err_msg: '',
            loaderOpen: false,
            selectedDate: null,
            name_format : '',
        }
        this.HandleOnChange = this.handleOnChange.bind(this);
        this.HandleOnYearChange = this.handleOnYearChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generatePayroll = this.generatePayroll.bind(this);
    }

    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'pay_period' && evt.currentTarget.dataset.sdate) {
            f.sdate = evt.currentTarget.dataset.sdate;
        }
        if (name === 'pay_period' && evt.currentTarget.dataset.edate) {
            f.edate = evt.currentTarget.dataset.edate;
        }
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
    };

    handleOnYearChange = (evt, value) => {
        const fieldValue = evt.target.value;
        this.getPayPeriods(fieldValue);
        this.handleOnChange(evt, value);
    };

    getPayPeriods = (year) => {
        if (year) {
            return httpServices.post('api/payroll', {year: year})
                .then(response => {
                    if (response.data.intervals) {
                        this.setState({intervals:response.data.intervals,
                           name_format : response.data.name_format
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
            });
        }
    }

    handleSubmit = (event) => {
        return httpServices.post('api/payroll', { 'start_date': this.state.formFields.sdate, 'end_date': this.state.formFields.edate})
            .then(response => {
                if (response.data) {
                    this.setState({users: response.data.users, hasPermission:response.data.permission, permision_err_msg: response.data.permision_err_msg});
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    generatePayroll = (rows) => {

        if (this.state.selectedDate) {
            let dataRows = [];
            let payPeriodID = null;

            var dateobj = new Date(this.state.selectedDate);
            let year = dateobj.getFullYear();
            let month = dateobj.getMonth() + 1;
            if (month < 10) {
                month = '0'+month;
            }
            let cdate = dateobj.getDate();
            if (cdate < 10) {
                cdate = '0'+cdate;
            }
            let payDate = year+'-'+month+'-'+cdate;

            rows.map(function(val, index){
                if (index === 0 && val['pay_period_id'] != null) payPeriodID = val['pay_period_id']; 
                //var dataObj = new Object();
                let dataObj = {};
                dataObj.staff_id = val.id;
                dataObj.desg = val.designation;
                dataObj.date_joined = val.date_joined;
                dataObj.date_relieved = val.relieved_date;
                dataObj.payroll_id = val.payroll_id;
                dataObj.user_id = val.user_id;
                dataObj.emp_category = val.emp_category;
                dataObj.pay_date = payDate;
                dataRows.push(dataObj);
                return dataRows;
            });

            let postData = {
                'data_rows': JSON.stringify(dataRows),
                'payperiod_id': payPeriodID,
                'start_date': this.state.formFields.sdate,
                'end_date': this.state.formFields.edate,
            };
            this.setState({loaderOpen:true});

            return httpServices.post('api/payroll/generate', postData)
                .then(response => {
                    if (response.data) {
                        this.setState({loaderOpen:false});
                        history.push('review/' + response.data.payperiod_id +'/new');
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
        else{
            alert("Please select pay check date");
        }
    }

    onReset = () => {
        const f = this.state.formFields;
        f['year'] = getYear();
        this.setState({
            formFields: f,
            users: []
        });
        this.getPayPeriods(getYear());
    }

    componentDidMount() {
        if (getYear()) {
            this.getPayPeriods(getYear());
        }
    }

    /* Handle Time entry date change*/
    handleTimeEntryDateChange = (date) => {
        this.setState({
            selectedDate : date,
        });
    };

    render(){
        return(
            <PayrollGenerateComponent
                handleTimeEntryDateChange = {this.handleTimeEntryDateChange.bind(this)}
                FormFields={this.state.formFields} 
                HandleOnChange={this.HandleOnChange}
                HandleOnYearChange={this.HandleOnYearChange}
                OnSubmit={this.handleSubmit}
                Intervals={this.state.intervals}
                NameFormat = {this.state.name_format}
                Users={this.state.users}
                GeneratePayroll={this.generatePayroll}
                HasPermission={this.state.hasPermission}
                PermissionMsg={this.state.permision_err_msg}
                OnReset={this.onReset.bind(this)}
                LoaderOpen={this.state.loaderOpen}
                SelectedDate={this.state.selectedDate}
            />
        )
    }
}
