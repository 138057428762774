import React from 'react';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
export const ReportComponent = (props) => { 
    return (
        <>
            <div className="box-list">
                {props.Reports.map((r,i) =>
                <>
                {r.permission &&
                <div className="item">
                    <a href onClick={props.assignLink(r.code)} style={{ cursor: 'pointer'}}>
                        <table>
                            <tr>
                                <td>
                                    <div className="icon"><BarChartOutlinedIcon style={{ fontSize: 60 }} /></div>
                                </td>
                                <td>
                                    <div className="content">
                                        <div className="title">{r.name}</div>
                                        <span className="info">{r.description}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>              
                    </a>  
                </div>
                }
                </>
                )}
            </div>
        </>
    )
}
