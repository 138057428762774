import React from 'react';
import {EdenredDetailsComponent} from './EdenredDetails.Component'
import {httpServices}  from '../../services/httpServices'

export class EdenredDetailsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            Open: false,
            monthlyData:[],
            name_format : '',
        }
    }
     componentDidMount = () => {
        var edenred_monthYear = (this.props.match.params.month) ? this.props.match.params.month: false;
        this.displayEdenredDetails(edenred_monthYear);
    };
     displayEdenredDetails = (monthYear) => {
        return httpServices.post('api/payroll/edenredDetails', {'monthYear': monthYear})
            .then(response => {
                if (response.data) {
                        this.setState({ monthlyData: response.data.monthlyData,
                           name_format : response.data.name_format
                        });
                }
            })
      }
    render(){
        return(
            <EdenredDetailsComponent
                MonthlyData={this.state.monthlyData}
                NameFormat = {this.state.name_format}
            />
        )
    }
}
