import React from 'react';
import {TimeofftypePolicyComponent} from './TimeofftypePolicy.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb

const formFields = {
    'id' : 0,
    'user_id': null,
    'lower_limit': null,
    'upper_limit': null,
    'no_days': null,
    'update_method': null,
    'note': '',
    'effective_date': null,
    'timeoff_type_id': null,
    'unit': null
}

export class TimeofftypePolicyDetails extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            errors: {},
            pto_policy: [],
            open:false,
            isEdit: false,
            variant : 'success',
            snackbar : false,
            eligibilityUnit : '',
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
        if(name == "timeoff_type_id") {
          let unit = this.props.policyEnabledTimeofftypes[this.props.policyEnabledTimeofftypes.findIndex(x => x.id == evt.target.value)]['eligibility_criteria_unit'];
          this.setState({
            eligibilityUnit: unit
        });
        }        
    };

    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }

    handleValidation() {
        let formIsValid = false;
        let errors = {};
        if (!this.state.formFields.group_id) {
            errors["group_id"] = "Cannot be empty";
        }
        if (!this.state.formFields.note) {
            errors["note"] = "Cannot be empty";
        }
        if (Object.keys(errors).length === 0) {
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    savePTOPolicy =() => {
        var len = this.state.pto_policy.length;
        if (len === 0) {
            if (_R.type(this.props.PTOPolicy) !== 'Undefined') {
                this.setState({pto_policy: this.props.PTOPolicy});
            }
        }
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            const id = (this.state.id) ? this.state.id : 0
            data.staff_id = staffID;
            data.user_id = userID;
            return httpServices.post('api/timeoff/save-user-policy', {data})
                .then(response => {
                    if (!response.data.errors) {
                        if (!response.data.status_flag) {
                            this.setState({
                                snackbar: true,
                                variant: 'error',
                                message: "Duplicate entry not allowed",
                            });
                        }
                        if (response.data.valid) {
                            let row = response.data.timeoff_policy;
                            if (!id ) {
                                //Append data
                                this.setState({
                                    pto_policy: [...this.state.pto_policy, row],
                                    snackbar : true,
                                    variant : 'success',
                                    message : "Staff eligibility criteria override added successfully",
                                });
                            }
                            else {
                                //Update Data
                                let idx = this.state.pto_policy.findIndex(x => x.id === id);
                                let pto_policy = [...this.state.pto_policy];
                                pto_policy[idx] = row;                           
                                this.setState({ pto_policy: pto_policy,
                                    snackbar : true,
                                    variant : 'success',
                                    message : "Staff eligibility criteria override updated successfully",
                                });                      
                            }
                            this.setState({open:false});
                            this.clearForm();
                        }
                    }
                    else {
                        this.setState({errors : response.data.errors});
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }

    onAdd = () => {
        this.setState({
            id: 0,
            open: true,
            isEdit: false,
        });
    }

    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            payperiod_id: null,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
        });
    };

    onUpdate = (id) => {
        return httpServices.post('api/timeoff/get-user-policy', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({
                        formFields: response.data.timeoff_policy, 
                        open: true, 
                        id: id,
                        isEdit: true,
                        eligibilityUnit: response.data.timeoff_policy['eligibility_criteria_unit'],
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    render(){
        return(
            <TimeofftypePolicyComponent
            PTOPolicy={this.state.pto_policy.length > 0 ? this.state.pto_policy : _R.type(this.props.PTOPolicy) !== 'Undefined' ? this.props.PTOPolicy: undefined}
            SavePTOPolicy={this.savePTOPolicy.bind(this)}
            HandleOnChange={this.handleOnChange}
            FormFields={this.state.formFields} 
            Open={this.state.open}
            Close={this.onClose.bind(this)}
            OnAdd={this.onAdd.bind(this)}
            HandleUpdate={this.onUpdate.bind(this)}
            EligibilityUnit={this.state.eligibilityUnit}
            Errors={this.state.errors}
            PolicyEnabledTimeofftypes = {this.props.policyEnabledTimeofftypes}
            IsEdit = {this.state.isEdit}
            Edit = {this.props.Edit}
            Variant = {this.state.variant}
            Message = {this.state.message}
            Snackbar = {this.state.snackbar}
            SnackbarClose = {this.snackbarClose}
            EditligibilityCriteriaGroups = {this.props.editligibilityCriteriaGroups}
            EligibilityCriteriaGroups = {this.props.eligibilityCriteriaGroups}
            />
        )
    }
}
