import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

export const ProjectCodeFilterComponent = (props) => {
    return (
        <form className="form-fullwidth">
            <Grid container spacing={2}>
                <Grid item xs={9}> 
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Expense Pay Type</InputLabel>
                        <Select
                            inputProps={{
                                name: 'pay_type',
                                id: 'pay_type',
                            }}
                            value={(typeof props.SelectedPayType !== 'undefined') ? parseInt(props.SelectedPayType) : 0}
                            onChange={props.HandleOnChange}
                        >
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Direct</MenuItem>
                            <MenuItem value="2">Indirect</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={props.HandleOnSubmit}> Submit </Button>
        </form>
    )
}
