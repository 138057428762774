import React from 'react';
import { ComponentUI } from './Components.Component';
import { SettingsConstants } from '../../constants/';
import { httpServices }  from '../../services/httpServices';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const formFields = {
    'id':0,
    'title': '',
    'type': null,
    'sub_type': null,
    'tab': null,
    'start_date': null,
    'end_date': null,
    'lower_limit': null,
    'upper_limit': null,
    'unit': null,
    'based_on': null,
    'amount': '',
    'payroll_count': 0,
    'pay_recurrence': null,
    'pay_frequency': null,
    'status': '1',
    'desc': null,
    'config_id': 0,
    'cap_unit' : null,
    'cap_figure':'',
    'staff_types': [],
}
const stafftype_field = [];
class ComponentsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formInputs: [],
            formFields: formFields,
            open: false,
            isEditable: false,
            isConfigEdit: false,
            config_id: false,
            errors: {},
            hasPermission: false,
            permision_err_msg: '',
            editPermission: false,
            deletePermission: false,
            confirmPopup: false,
            snackbarOpen : false,
            showLoader : false,
            message: '',
            stafftypeField : stafftype_field,
            dataLoaded : false,
            snackVariant : 'success',
            editId : 0,
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.confirmClicked = this.confirmClicked.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.OnUpdate = this.onUpdate.bind(this);
        this.HandleConfigUpdate = this.onConfigUpdate.bind(this);
        this.HandleConfigDelete = this.onConfigDelete.bind(this);
    }

    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        if (field === 'title' && evt.target.value !== ''){
            this.setState({
                formFields: ff,
                errors: {}
            });
        }
        else{
            this.setState({
                formFields: ff
            });
        }
    };
    componentDidMount = () => {
        this.setState({showLoader : true});
        let apiEndpoint = SettingsConstants.API_COMPONENTS;
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    this.setState({
                        formInputs: response.data,
                        hasPermission: response.data.permission,
                        permision_err_msg: response.data.permision_err_msg,
                        editPermission: response.data.edit_permission,
                        deletePermission: response.data.delete_permission,
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
                else {
                     this.setState({showLoader : false,
                                    dataLoaded : true,
                     });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };

    validate = () => {
        let formIsValid = false;
        let errors = {};
        if (!this.state.formFields.title || this.state.formFields.title.replace(/\s/g,'') === '') {
            errors["title"] = "Title cannot be empty";
        }
        if (!this.state.isEditable && this.state.formFields.start_date === null) {
            errors["start_date"] = "Start date cannot be empty";
        }
        var lowerLimit = this.state.formFields.lower_limit;
        var upperLimit = this.state.formFields.upper_limit;
        if (lowerLimit && !String(lowerLimit).match(/^[0-9]+$/)) {
            errors["lower_limit"] = "Lower limit is invalid";
        }
        if (upperLimit && !String(upperLimit).match(/^[0-9]+$/)) {
            errors["upper_limit"] = "Upper limit is invalid";
        }
        if (this.state.formFields.amount !== undefined &&  this.state.formFields.amount  && !String(this.state.formFields.amount).match(/^\d+(\.\d+)?$/)) {
            errors["amount"] = "Amount is invalid";
        }
        if (this.state.formFields.cap_figure && !String(this.state.formFields.cap_figure).match(/^\d+(\.\d+)?$/)) {
            errors["cap_figure"] = "Cap Figure value is invalid";
        }
        if(this.state.formFields.start_date && this.state.formFields.end_date){
            let start_date = new Date(this.state.formFields.start_date);
            let end_date = new Date(this.state.formFields.end_date);
            if (start_date > end_date) {
                errors["end_date"] = "End date should be greater than Start date";
                formIsValid = false;
            }
        }
        if (!Object.keys(errors).length) {
            errors = {};
            formIsValid = true;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    handleSubmit = async(event) => {
        if (this.validate()) {
            const data = this.state.formFields;
            let updateFlag = false;
            if (this.state.config_id){
                data.config_id = this.state.config_id;
            }
            /*if ( !this.state.isEditable && window.confirm("Do you want to update existing compensation record?")) {
                updateFlag = true;
            }*/
            //Display config update popup, for component Add
            if (!this.state.isEditable) {
                 this.setState({confirmPopup: true});
            }
            else if (data) {
                data.update_to_existing = updateFlag;
                let validTitle = await this.addComponent(data);
                if (validTitle) {
                    this.setState({ 
                        open: false,
                        snackbarOpen: true,
                        snackVariant: 'success',
                        message: 'Component updated successfully',
                     });
                }
                else {
                    if (Object.keys(this.state.errors).length === 0) {
                        this.setState({
                            open: false,
                            snackbarOpen: true,
                            snackVariant: 'error',
                            message: 'Component update failed.Component with same name already exist',
                        });
                    }
                }
            }
        }
    }    
    deleteComponent = (id, isConfig, componentId) => {
          return httpServices.post('api/settings/deleteComponent', {'id': id, 'is_config': isConfig, 'component_id': componentId})
          .then(response => {
              if (response.data) {
                  if (response.data.error) {
                      this.setState({
                      snackbarOpen: true,
                      message: response.data.error,
                      snackVariant: 'error',
                      });
                  }
                  else {
                    this.setState({formInputs: response.data});
                  }
              }
          })
          .catch(function(error) {
            console.log("Error: "+error);
          });
  };

    addComponent = (data) => {
        return httpServices.post(SettingsConstants.API_COMPONENTS, { data })
            .then(response => {
                if (response.data.valid) {
                    this.setState({ 
                        formInputs: response.data,
                     });
                     return response.data.valid_title;
                }
                else
                {
                    this.setState({
                        errors: response.data.errors,
                    }, () => {
                        return false;
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };

    onDelete = (id, index) => {
        if(id) {
            this.deleteComponent(id, false);
        }
    }

    onAdd = (id) => {
        this.clearForm();
        this.setState({
            open: true,
            isEditable: false,
            formFields: formFields,
            config_id: false,
            editId : 0
        });
    }

    onClose = () =>{
        this.setState({
            open: false
        });
        this.clearForm();
    }

    onUpdate = (id) => {
        this.setState({
            open: true,
            isEditable: true,
            config_id: false,
            editId : id
        });
        return httpServices.post('api/settings/getComponent', {'id': id})
            .then(response => {
                if (response.data) {
                    this.setState({formFields: response.data.components, errors:{}});
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    onConfigUpdate = (evt) => {
        var componentID = evt.currentTarget.dataset.parent_id;
        var configID = evt.currentTarget.dataset.row_id;

        this.setState({
            open: true,
            isEditable: true,
            isConfigEdit: true,
            config_id: configID
        });
        return httpServices.post('api/settings/getComponent', {'id': componentID})
            .then(response => {
                if (response.data) {
                    this.setState({formFields: response.data.components});
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onConfigDelete = (evt) => {
        var id = evt.currentTarget.dataset.row_id;
        var component_id = evt.currentTarget.dataset.component_id;
        if(id) {
            this.deleteComponent(id, true, component_id);
        }
    }

     clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = (key === 'id')?0:null;
            ff['status'] = 1;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            isEditable: false,
            config_id: false,
            isConfigEdit: false,
            stafftypeField: [],
        });
     };
    onSelect = (evt, value) => {
        const val = evt.target.value;
        this.setState({
            stafftypeField: val,
        });
    }
    confirmClicked = () => {
        let flag = true;
        let staff_types = this.state.stafftypeField;
        let errors = {};
        if (staff_types.length === 0) {
            flag = false;
            errors["staff_types"] = "Staff type is required";
        }
        if (flag) {
            this.setState({
                errors: {},
                updateFlag: true,
                showLoader: true,
            }, function() {
                this.submitConfirm();
            });
        }
        else {
            this.setState({
                errors: errors,
            });
        }
    }
    cancelClicked = () => {
        this.setState({
            errors: {},
            updateFlag: false,
            showLoader: true,
        }, function() {
            this.submitConfirm();
        });
    }
    submitConfirm = async() => {
        const data = this.state.formFields;
        const staff_types = this.state.stafftypeField;
        let updateFlag = this.state.updateFlag;
        if (this.state.config_id){
            data.config_id = this.state.config_id;
        }
        if (data) {
            data.update_to_existing = updateFlag;
            if (typeof staff_types != 'undefined' && staff_types.length > 0) {
                let stypes = staff_types.toString();
                 data.staff_types = stypes;
            }
            let validTitle = await this.addComponent(data);
            if (validTitle) {
                    this.setState({
                        confirmPopup: false,
                        open: false,
                        showLoader: false,
                        snackbarOpen: true,
                        snackVariant: 'success',
                        message: 'Component added successfully',
                    });                
            }
            else if (Object.keys(this.state.errors).length === 0) {
                this.setState({
                    confirmPopup: false,
                    showLoader: false,
                    open: false,
                    snackbarOpen: true,
                    snackVariant: 'error',
                    message: 'Component add failed.Component with same name already exist',
                });
            }
            else {
                this.setState({
                    confirmPopup: false,
                    showLoader: false,
                });
            }

        }
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    render(){
        return(
            <>
            <CustomProgressBar
            loaderOpen={this.state.showLoader}
            />
            {this.state.dataLoaded ?
                <>
                {(this.state.hasPermission)?
                    <ComponentUI 
                    FormFields={this.state.formFields} 
                    FormInputs={this.state.formInputs} 
                    HandleOnChange={this.HandleOnChange}
                    OnDelete={this.onDelete}
                    OnSubmit={this.handleSubmit}
                    FF={this.props.components}
                    OnAdd={this.onAdd}
                    Open={this.state.open}
                    SnackVariant = {this.state.snackVariant}
                    OnClose={this.onClose}
                    OnUpdate={this.onUpdate}
                    IsEditable={this.state.isEditable}
                    HandleConfigUpdate={this.onConfigUpdate}
                    HandleConfigDelete={this.onConfigDelete}
                    ConfigEditFlag={this.state.isConfigEdit}
                    Errors={this.state.errors}
                    Edit={this.state.editPermission}
                    Delete={this.state.deletePermission}
                    ConfirmPopup = {this.state.confirmPopup}
                    OnSelect = {this.onSelect}
                    ConfirmClicked = {this.confirmClicked}
                    CancelClicked = {this.cancelClicked}
                    SnackbarOpen = {this.state.snackbarOpen}
                    SnackbarClose = {this.snackbarClose}
                    Message = {this.state.message}
                    SelectedStaffType={this.state.stafftypeField}
                    />
                    :<ErrorPage Message ={this.state.permision_err_msg}/>}
                </>
            : <></>}
            </>
        )
    }
}
export { ComponentsContainer as ComponentsView };
