import React from 'react';

import { httpServices } from '../../../../services/httpServices';
import { StaffPersonalDetailsComponent } from "./StaffPersonalDetails.Component";

const personalInputs = {
    blood_groups : [
        { id : 0, value : "A+ve", title : "A+ve"},
        { id : 1, value : "A-ve", title : "A-ve"},
        { id : 2, value : "B+ve", title : "B+ve"},
        { id : 3, value : "B-ve", title : "B-ve"},
        { id : 4, value : "AB+ve", title : "AB+ve"},
        { id : 5, value : "AB-ve", title : "AB-ve"},
        { id : 6, value : "O+ve", title : "O+ve"},
        { id : 7, value : "O-ve", title : "O-ve"},
    ],
};
export class StaffPersonalDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            open: false,
            errors: {},
            formValues : {},
            basicDetails: {},
            ssn_flag:0,
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleSubmit =  this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            basicDetails: Object.assign({}, this.props.staffDetails),
        });
    }
    componentDidUpdate(prevProps) {
        if ((Object.keys(prevProps.staffDetails).length !== Object.keys(this.props.staffDetails).length) ) {
            this.setState({
                basicDetails: Object.assign({}, this.props.staffDetails),
            });
        }
    }

    onUpdate = () => {
        this.setState({
            open : true,
            formValues: Object.assign({}, this.props.staffDetails),
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            errors : {},
        });
    };
   
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formValues;
        ff[field] = evt.target.value;
        this.setState({
            formValues: ff
        });
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formValues;
            let veteranCategoryIds = data.veteran_category_ids;
            if (veteranCategoryIds) {
                data.veteran_category = JSON.stringify(veteranCategoryIds);
            }
            if (data) {
                httpServices.post('api/staff/personal/editStaffPersonalDetails', { data })
                    .then(response => {
                        if (response.data) {
                            if (!response.data.errors) {
                                if(response.data.dob){
                                    data['dob'] = response.data.dob;
                                }
                                let gender_title = this.getGenderTitleById(data.staff_gender);
                                let race_ethnicity = this.getRaceEthnicityById(data.staff_race_ethnicity);
                                let protected_veteran = this.getProtectedVeteranById(data.protected_veteran_id);
                                let vateran_categories = this.getVeteranCategories(data.veteran_category);
                                if(data.staff_salutation){
                                    let salutation = this.getSalutationById(data.staff_salutation);
                                    if(salutation){
                                        data.salutation = salutation;
                                    }
                                }
                                if(gender_title){
                                    data['gender'] = gender_title;
                                }
                                if(race_ethnicity){
                                    data['race_ethnicity'] = race_ethnicity;
                                }
                                if(protected_veteran){
                                    data['protected_veteran'] = protected_veteran;
                                }
                                if(vateran_categories){
                                    data['veteran_category_details'] = vateran_categories;
                                }
                                this.setState({
                                    basicDetails : data,
                                    open: false
                                }, () => {this.props.updateChange()});
                                alert("Personal details updated successfully");
                            }
                            else {
                                this.setState({errors : response.data.errors});
                            }
                        }
                    })
                    .catch(function(error) {
                        alert("Update failed");
                        console.log("error" + error);
                    });
            }
        }
    };

    getGenderTitleById = (id) => {
       if(this.props.formInputs.staff_gender_opts){
            let title = '';
            let gender_options = this.props.formInputs.staff_gender_opts;
            gender_options.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };

    getRaceEthnicityById = (id) => {
       if(this.props.formInputs.staff_race_ethinicity_opts){
            let title = '';
            let race_ethinicity_opts = this.props.formInputs.staff_race_ethinicity_opts;
            race_ethinicity_opts.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };

    getProtectedVeteranById = (id) => {
       if(this.props.formInputs.staff_veteran_opts){
            let title = '';
            let staff_veteran_opts = this.props.formInputs.staff_veteran_opts;
            staff_veteran_opts.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };

    getVeteranCategories = (ids) => {
        if(this.props.formInputs.staff_veteran_category_opts){
            let categories = [];
            let staff_veteran_categories = this.props.formInputs.staff_veteran_category_opts;
            for(var i = 0; i < ids.length; i++){ 
                // eslint-disable-next-line
                staff_veteran_categories.forEach(function(category){
                    if(category.id === parseInt(ids[i])){
                        categories.push(category);
                    }
                });
            }
            return categories;
        }
    };

    getSalutationById = (id) => {
       if(this.props.formInputs.salutations){
            let title = '';
            let salutations = this.props.formInputs.salutations;
            salutations.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formValues.first_name) {
            errors["first_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formValues.last_name) {
            errors["last_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formValues.staff_dob) {
            errors["dob"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formValues.staff_gender) {
            errors["gender"] = "Cannot be empty";
            formIsValid = false;
        }
       /* if (!this.state.basic_details.staff_race_ethnicity) {
            errors["race_ethnicity"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.basic_details.protected_veteran_id) {
            errors["protected_veteran"] = "Cannot be empty";
            formIsValid = false;
        }*/
        this.setState({errors: errors});
        return formIsValid;
    } 
    getSSN =() =>{
        let ssn_flag=this.state.ssn_flag?0:1;
        httpServices.post('api/staff/get-ssn-by-staff-id', { 'id':this.props.staffDetails.staff_id })
            .then(response => {
                if (response.data) {
                    this.props.staffDetails.ssn=ssn_flag?response.data.ssn:'**** ** '+response.data.ssn.substr(response.data.ssn.length - 4);
                    this.setState({
                        ssn_flag: ssn_flag
                    });
                }
            });
    }
    render(){
        return (
            <StaffPersonalDetailsComponent 
                staffDetails = { this.state.basicDetails }                
                open = { this.state.open }
                onUpdate = { this.onUpdate }
                getSSN={this.getSSN}
                ssn_flag={ this.state.ssn_flag }
                Errors = { this.state.errors }
                onClose = { this.onClose }
                FormInputs = { this.props.formInputs }
                FormData = { this.state.formValues }
                handleOnChange = { this.HandleOnChange }
                OnSubmit = { this.handleSubmit }
                EditPermission = { this.props.Edit }
                PersonalInputs = { personalInputs}
            />
        )
    }
}
