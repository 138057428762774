import React, {useState, useEffect} from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FilterListIcon from '@mui/icons-material/FilterList';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TableConstants } from '../../../constants';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import InputAdornment from '@mui/material/InputAdornment';
import { Search, Clear } from "@mui/icons-material";


const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});

const API_URL = process.env.REACT_APP_API_URL;
var rows_per_page = TableConstants.ROWS_PER_PAGE;
// Filter form preparations
const SideList = withStyles(styles)(p => {
    return (
        <>
            <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl className={p.classes.drawerFormControl} variant='standard'>
                        <InputLabel >Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}
                            value = {!_R.isNil(p.FilterFields.status) && p.FilterFields.status }
                            onChange = {p.HandleFilterChange} >
                            <MenuItem value={0} key={'st-0'}>All</MenuItem>
                            {_R.type(p.FilterInputs.status) !== 'Undefined' && p.FilterInputs.status.map((s, i) =>
                                <MenuItem value={s.user_status_id} key={'st-'+i}>{s.user_status_name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <FormControl className={p.classes.drawerFormControl} variant='standard'>
                        <InputLabel >Year</InputLabel>
                        <Select
                            inputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                            value = {!_R.isNil(p.FilterFields.year) && p.FilterFields.year}
                            onChange = {p.HandleFilterChange} >
                            {_R.type(p.FilterInputs.years) !== 'Undefined' && p.FilterInputs.years.map((y) =>
                                <MenuItem value={y}>{y}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <FormControl className={p.classes.drawerFormControl} variant='standard'>
                        <InputLabel >Staff Name Format</InputLabel>
                        <Select
                            inputProps={{
                                name: 'name_format',
                                id: 'name_format',
                            }}
                            value = {!_R.isNil(p.FilterFields.name_format) && p.FilterFields.name_format}
                            onChange = {p.HandleFilterChange} >
                            {_R.type(p.FilterInputs.name_formats) !== 'Undefined' && p.FilterInputs.name_formats.map((s, i) =>
                                <MenuItem value={s.key}>{s.value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color="primary"  className={p.classes.filterSubmitButton} onClick={p.GetTimeOffList} > Filter </Button>
            <Button variant="outlined"  color="primary"  className={p.classes.filterResetButton} onClick={p.ResetFilter}>Reset</Button>
        </>
    );
});

export const TimeOffListComponent  = withStyles(styles)(props => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rows_per_page);
    const initialState = props.TimeOffListDetails;
    const [filterFn, setFilterFn] = useState(initialState);
    const [value, setValue] = useState('');
    useEffect(() => {
        setFilterFn(initialState);
    }, [initialState]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSearch = e => {
        let target = e.target;
        setValue(target.value);
        let items = props.TimeOffListDetails;
        let filteredValues = items.filter(x => x.full_name.toLowerCase().includes(target.value) || x.full_name.includes(target.value) || x.full_name.toUpperCase().includes(target.value));
        setFilterFn(filteredValues);
        setPage(0);
    }
    const handleClear = () => {
        setValue('');
        setFilterFn(initialState); 
    }
    return (
        <React.Fragment>
            <form action={API_URL+'api/timeoff/list'} method="post">
                <input type = "hidden" name='status' value={props.FilterFields.status}></input>
                <input type = "hidden" name='year' value={props.FilterFields.year}></input>
                <input type = "hidden" name='name_format' value={props.FilterFields.name_format}></input>
                <div className={'section-main'}>
                    <div className={'section-header'}>
                        <div className={'col-left'}>
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary" ref={element => { if (element) element.style.setProperty('text-transform', 'capitalize', 'important');
                            }}>
                                Time Off Details
                            </Typography>
                            <Grid item xs={12} sm={6} md={4}>
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            onChange={props.HandleOnChange}
                                            value={props.FormFields.day_radio}
                                            checked={props.FormFields.day_radio}
                                            inputProps={{
                                                name: 'day_radio',
                                                id: 'day_radio',
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Days"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            onChange={props.HandleOnChange}
                                            value={props.FormFields.hour_radio}
                                            checked={props.FormFields.hour_radio}
                                            inputProps={{
                                                name: 'hour_radio',
                                                id: 'hour_radio',
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Hours"
                                />
                            </Grid>
                        </div>
                        <div className={'action'}>
                            <FormControl>
                                <TextField
                                    id="search"
                                    name="search"
                                    placeholder="Search Employee"
                                    type="text"
                                    value={value}
                                    onChange={handleSearch}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant='standard'
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>),
                                        endAdornment: (<InputAdornment position="start">
                                        <Clear onClick={handleClear} style={{cursor : 'default'}}/>
                                        </InputAdornment>),
                                    }}
                                />
                            </ FormControl>{" "}
                            <Button variant="contained" color="primary" size="small" type='submit' name='export' value="export">Export</Button>
                            <Tooltip title="Filter">
                                <IconButton onClick={props.FilterView('right', true)}>
                                    <FilterListIcon color="action" />
                                </IconButton>
                            </Tooltip>
                            <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                                <div className={'tree-view-drawer'}>
                                    <SideList
                                        FilterInputs={props.FilterInputs}
                                        FilterFields={props.FilterFields}
                                        HandleFilterChange={props.HandleFilterChange}
                                        GetTimeOffList={props.HandleFilter}
                                        ResetFilter={props.ResetFilter}
                                    />
                                </div>
                            </Drawer>
                        </div> {/* --- section action --- */}
                    </div> {/* --- section header --- */}
                    <div className={'section-body'}>
                        <Paper>
                            <TableContainer style={{height: '60vh', overflowX : 'auto'}}>
                                <Table aria-label="simple table" className="userTimeoffList" border = "1" style ={{'borderCollapse': 'collapse'}}>
                                    <TableHead className="header" style={{position: "sticky", top: "0px" }}>
                                        <TableRow>
                                            <TableCell  rowSpan="2" width="70%" style={{position: "sticky", left: "0px" , background: "#eee", zIndex: 1 }} > Employee Name </TableCell>
                                            {_R.type(props.Columns) !== 'Undefined'  && Object.keys(props.Columns).map((item,k) =><TableCell align ="center" style={{ position: "sticky", top: "0px"}} colSpan={props.Columns[k]['colspan']} >{props.Columns[k]['main_header']}</TableCell>)}
                                        </TableRow>
                                        <TableRow>
                                        {_R.type(props.Columns) !== 'Undefined'  && Object.keys(props.Columns).map((item,k) =>
                                        <>
                                            {(props.Columns[k]['eligible_hours']) && <TableCell style={{position: "sticky", top: "0px"}}> Eligible </TableCell>}
                                            {(props.Columns[k]['used_hours']) && <TableCell style={{position: "sticky", top: "0px"}} > Used </TableCell>}
                                            {(props.Columns[k]['balance_hours']) && <TableCell style={{position: "sticky", top: "0px"}} > Balance </TableCell>}
                                        </>
                                        )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_R.type(filterFn) !== 'Undefined' && Object.keys(filterFn).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((employee, k) =>
                                            <TableRow>
                                                <TableCell style={{ position: "sticky", left: "0", backgroundColor: "white" }}><a onClick={() => props.HandleViewDetailsPage(filterFn[employee]['user_id'])} className='link'> {filterFn[employee]['full_name']} </a> </TableCell>
                                                {_R.type(filterFn) !== 'Undefined' && Object.keys(filterFn[employee]['hours']).map((i, j) =>
                                                    <>
                                                        {(props.Columns[j]) && (props.Columns[j]['eligible_hours']) &&
                                                            <>
                                                                {(props.FormFields.day_radio) ?
                                                                    <TableCell>
                                                                        {filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['eligible_days'] !== 'N/A') ? filterFn[employee]['hours'][i]['eligible_days'] : 0}</span>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell>
                                                                        {filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['eligible_hours'] !== 'N/A') ? filterFn[employee]['hours'][i]['eligible_hours'] : 0}</span>
                                                                    </TableCell>
                                                                }
                                                            </>
                                                        }
                                                        {(props.Columns[j]) && (props.Columns[j]['used_hours']) &&
                                                            <>
                                                                {(props.FormFields.day_radio) ?
                                                                    <TableCell>
                                                                        {filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['used_days'] !== 'N/A') ? filterFn[employee]['hours'][i]['used_days'] : 'N/A'}</span>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell>
                                                                        {filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['used_hours'] !== 'N/A') ? filterFn[employee]['hours'][i]['used_hours'] : 0}</span>
                                                                    </TableCell>}
                                                            </>
                                                        }
                                                        {(props.Columns[j]) && (props.Columns[j]['balance_hours']) &&
                                                            <>
                                                                {(props.FormFields.day_radio) ?
                                                                    <TableCell>{filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['balance_days'] !== 'N/A') ? filterFn[employee]['hours'][i]['balance_days'] : 0}</span>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell>{filterFn[employee][i]}
                                                                        <span> {(filterFn[employee]['hours'][i]['balance_hours'] !== 'N/A') ? filterFn[employee]['hours'][i]['balance_hours'] : 0}</span>
                                                                    </TableCell>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )}
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={(filterFn !== undefined) ? Object.keys(filterFn).length : 15}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div> {/*--- section body ----*/}
                </div> {/*--- section main ---- */}
            </form>
        </React.Fragment>
    )
});

