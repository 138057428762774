import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
const authKey = cookie.load(AUTH_KEY);
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
            authKey
            ? (<Component {...props} />)
            : (<Redirect to={{ pathname: BASE_PATH + '/login'}} />)
    )} />
)
