import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import * as _R from 'ramda';  // used 0.04kb
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import FormHelperText from '@mui/material/FormHelperText';
import { CustomizedSnackbars } from '../../../common/snackbars';
import { CustomDatePicker } from '../../../common/CustomDatePicker';




const formFields = {
    work_policy_id : null,
    start_date : null,
    end_date : null,
    id : 0
}
const DialogContent = (props) => {
    const { FormFields, HandleOnChange, Errors, AllPolicies, HandleDateChange } = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel required error={Errors.work_policy_id && true}>Policy</InputLabel>
                        <Select
                            value={parseInt(FormFields.work_policy_id)}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'work_policy_id',
                                id: 'work_policy_id'
                            }}
                            className={'input-item'}
                        >
                            {typeof AllPolicies !== 'undefined' && AllPolicies.map((policy) =>
                                <MenuItem value={policy.id} >{policy.work_policy_name}</MenuItem>
                            )}
                        </Select>
                        {Errors.work_policy_id && <FormHelperText className="error">{Errors.work_policy_id}</FormHelperText>}
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id="start_date"
                            name="start_date"
                            selectedDate={FormFields.start_date}
                            handleDateChange={HandleDateChange}
                            shouldDisableDate={(date) => { return date.day() !== 0; }}
                            required={true}
                            label={'Start Date'}
                        />
                        <small className='error'>{Errors.start_date && Errors.start_date}</small>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <CustomDatePicker
                            id="end_date"
                            name="end_date"
                            selectedDate={FormFields.end_date}
                            handleDateChange={HandleDateChange}
                            label={'End Date'}
                        />
                        <small className='error'>{Errors.end_date && Errors.end_date}</small>
                        {props.ErrorMsg && <FormHelperText className="error">{props.ErrorMsg}</FormHelperText>}
                    </ FormControl>
                </Grid>
            </Grid>
            </form>
    )
}

class WorkPolicyListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            deleteId : 0,
            open: false,
            isEditable: false,
            isDelete: false,
            formFields: [],
            allPolicies : [],
            editPermission : false,
            permission : true,
            errors : {},
            workPolicy: this.props.WorkPolicy, 
            edit: this.props.Edit,
            deletion: this.props.Delete,
            userId: 0,
            errorMsg : '',
            snackbarOpen: false,
            snackbarVariant: '',
            snackbarMsg: '',
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
  

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onDelete = (id) => () => {
        this.setState({
            deleteId:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    }
    onDeleteSubmit = () => {
        const {UserID, StaffID } = this.props;
        let id = this.state.deleteId;
        let apiEndpoint = 'api/staff/work/delete-policy';
        return httpServices.post(apiEndpoint, {
            'staff_id': StaffID,
            'id': id,
        })
        .then(response => {
            this.onUpdate();
            if (response.data) {
                this.setState({
                    open:false,
                    workPolicy: response.data.work_policy
                }, () => this.displaySnackBar(response.data.success, response.data.msg));
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });

    }
    onUpdate = (id) => () => {
        const { UserID } = this.props;
        let apiEndpoint = 'api/staff/work/workpolicy';
        return httpServices.post(apiEndpoint, {'userID':UserID, 'id': id })
            .then(response => {
                if (response.data) {
                    if (id) {
                        this.setState({
                            id:id,
                            isEditable: true,
                            open:true,
                            allPolicies: response.data.all_policies,
                            formFields: response.data.work_policy,
                            isDelete: false,
                            userId: UserID
                        });
                    }
                    else {
                        this.setState({
                            id: 0,
                            open:true,
                            isDelete: false,
                            allPolicies: response.data.all_policies,
                            isEditable: false,
                            userId: UserID
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    onSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            let userId = this.state.userId;
            if (data) { 
                 let apiEndpoint = 'api/staff/work/save-policy';
                 let id = (data.id) ? data.id : 0;
                 let work_policy_id = data.work_policy_id;
                 let start_date = data.start_date;
                 let end_date = (data.end_date) ? data.end_date : '';
                 return httpServices.post(apiEndpoint,{'id' : id, 'work_policy_id' : work_policy_id, 'start_date' : start_date, 'end_date' : end_date, 'user_id' : userId })
                    .then(response => {
                        this.onUpdate();
                        if (response.data.valid) {
                            this.setState({
                                open: false,
                                formFields: {},
                                errors: {},
                                errorMsg: '',
                                workPolicy: response.data.work_policy
                            }, () => this.displaySnackBar(response.data.valid, response.data.msg));
                        }
                        else {
                            if (response.data.errors) {
                                this.setState({
                                    errors: response.data.errors,
                                    errorMsg: ''
                                });
                            }
                            else {
                                this.displaySnackBar(response.data.valid, response.data.error_msg)
                            }
                        }                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.work_policy_id) {
            errors["work_policy_id"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if ((this.state.formFields.end_date && this.state.formFields.start_date && this.state.formFields.end_date !== '0000-00-00') &&  (this.state.formFields.end_date < this.state.formFields.start_date)) {
            errors["end_date"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors, errorMsg : ''});
        return formIsValid;
    }

    displaySnackBar = (status, msg) => {
        if (status) {
            this.setState({
                snackbarOpen: true,
                snackbarVariant: 'success',
                snackbarMsg: msg,
            });
        } else {
            this.setState({
                snackbarOpen: true,
                snackbarVariant: 'error',
                snackbarMsg: msg,
            });
        }
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    handleDateChange = (value, name) => {
        const form_field = this.state.formFields;
        form_field[name] = value;
        this.setState({
            formFields: form_field
        });
    };


    render(){
        const {workPolicy, edit, deletion} = this.state;
        return (
            <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Work Policy</Typography>
                {_R.equals(edit, true) &&
                    <Tooltip title="Add Policy" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            </div>
            { _R.isEmpty(workPolicy) 
            ?   
                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
            : 
            <div className={'section-content'}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Policy</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            {_R.or(edit, deletion) &&
                                <TableCell></TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { typeof workPolicy != 'undefined' && workPolicy.map((p, i)  =>
                        <TableRow hover key={i}>
                            <TableCell>{p.work_policy_name}</TableCell>
                            <TableCell>{p.start_date}</TableCell>
                            <TableCell>{p.end_date}</TableCell>
                            {_R.or(edit, deletion) &&
                            <TableCell align="right">
                            {_R.equals(edit, true) &&
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onUpdate(p.id)}>
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            {_R.equals(deletion, true) &&
                                <Tooltip title="Delete" aria-label="delete">
                                    <IconButton className={'action-btn'} edge="end" aria-label="Comments" onClick={this.onDelete(p.id)}>
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            </TableCell>
                            }
                        </TableRow>
                    )}
                    { _R.isEmpty(workPolicy) &&
                        <TableRow>
                            <TableCell colSpan={7} align="center">
                                <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>            
            </div>
            }
            <DialogBoxMedium 
                    Open={this.state.open} 
                    Cancel={this.onClose}
                    Title={this.state.isDelete ?  'Delete Policy' : (this.state.isEditable ? 'Edit Policy' : 'Add Policy')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Policy?'
                        :
                        <DialogContent
                            EditPermission = {this.state.editPermission}
                            Permission = {this.state.permission}
                            Open = {this.state.open}
                            FormFields = {this.state.formFields}
                            Errors = {this.state.errors}
                            HandleOnChange = {this.handleOnChange}
                            HandleDateChange = {this.handleDateChange}
                            OnSubmit = {this.onSubmit}
                            WorkPolicy = {this.state.workPolicy}
                            AllPolicies = {this.state.allPolicies}
                            ErrorMsg = {this.state.errorMsg}
                        />
                    }
                />
                {this.state.snackbarOpen &&
                    <CustomizedSnackbars
                        open={this.state.snackbarOpen}
                        variant={this.state.snackbarVariant}
                        message={this.state.snackbarMsg}
                        handleClose={this.snackbarClose}
                    />
                }
            </>
        )
    }
}
export { WorkPolicyListContainer as WorkPolicyList };
