import React from 'react';
import {CustomReportComponent} from './customReport.Component'
import {httpServices}  from '../../../services/httpServices'
import { ErrorPage } from '../../common/error';
import * as _ts from '../../common/treeSelect';
import * as validations from '../../common/validate';
import MixpanelManager from '../../../services/Analytics';


const ReportFields = {
    'date_from': '',
    'date_to': '',
    'user_status' : '',
    'groups': '',
    'clients' : [],
    'tasks' : [],
    'staffs' : [],
    'projects' : [],
    'project_status' : '1',
    'staff_type' : [],
    'btn_type': '',
    'users' : [],
    'public' : false,
    'labels': [],
}
const formInputs = {
    staff_status : [],
    staff_type : [],
    staff_type_ids : [],
    client_status : [],
    project_status : [],
    staff_list : [],
    user_list : [],
    task_list : [],
    client_list : [],
    project_list : [],
    exclude_project_list : [],
}
let getProjectTree = function(dataObj, selected_projects, result) {
    dataObj.forEach(function(e) {
        if((selected_projects && selected_projects===e.project_id) || !selected_projects){
            result.push(e.project_id);
            if (e.children) {
                getProjectTree(e.children,0,result);
            }
        }else if(selected_projects && e.children instanceof Object) {
            getProjectTree(e.children, selected_projects,result);
        }
    });
}
const getAllProjectIds = function(projectTree,pIds) {
    projectTree.forEach(function(e) {
        if(e.project_id){
            pIds.push(e.project_id);
        }
        if(e.children instanceof Object) {
            getAllProjectIds(e.children, pIds);
        }
    });
}
let successMsg = '';
let dates_in_page = [];
export class CustomReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            ReportFields: ReportFields,
            formInputs: formInputs,
            random_number : 0,
            clickedTimeSliceId : 0,
            hasPermission : true,
            reportData : {},
            current : '',
            comments : '',
            popupType : '',
            button : '',
            open : false,
            reviewNotes : '',
            changeLogs : '',
            timeSliceEditDialogOpen : false,
            snackbarOpen : false,
            forseRerender : false,
            total_hours : 0,
            isSearch : false,
            loaderOpen : false,
            allPermission : "No",
            savedFilters : [],
            savedSearchClickId : 0,
            tabIndex : 0,
            error_msg : '',
            deleteId : 0,
            editId : 0,
            logged_user : 0,
            shareFilterId : 0,
            fieldChange : false,
            saveAction : '',
            savedSearchCreatedBy : 0,
            sharedSearchName : ''
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.editSavedTimeEntry = this.editSavedTimeEntry.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.handleViewDetailsClick = this.handleViewDetailsClick.bind(this);
        this.timeSliceEditDialogClose = this.timeSliceEditDialogClose.bind(this);
        this.showEditSuccess = this.showEditSuccess.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.getSavedTimeSlices = this.getSavedTimeSlices.bind(this);
        this.getTimeEntrySummary = this.getTimeEntrySummary.bind(this);
        this.exportReports = this.exportReports.bind(this);
        this.reset = this.reset.bind(this);
        this.handlesSaveFilterClick = this.handlesSaveFilterClick.bind(this);
        this.handleUpdateFilterClick = this.handleUpdateFilterClick.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.editClick = this.editClick.bind(this);
        this.handleShareClick = this.handleShareClick.bind(this);
        this.shareFilter = this.shareFilter.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    reset = () => {
        const ff = this.state.ReportFields;
        ff['clients'] = [0];
        ff['projects'] = [0];
        ff['client_status'] = 1;
        ff['project_status'] = 1;
        ff['task_status'] = 0;
        ff['tasks'] = [0];
        ff['type'] = 0;
        ff['user_groups'] = 0;
        ff['user_status'] = 1;
        ff['staffs'] = [0];
        ff['users'] = [0];
        ff['emp_category'] = 0;
        ff['pay_type'] = 0;
        ff['location'] = 0;
        ff['status'] = 0;
        ff['date_from'] = ff.pay_period_first;
        ff['date_to'] = ff.pay_period_last;
        ff['staff_type'] =  [ff.staff_types[0]];
        ff['staff_type_ids'] = [];
        ff['timeslice_log'] = '';
	ff['selected_label'] = 0;
	ff['label_type'] = -1;
        this.setState({
                 ReportFields: ff,
            });
        this.setState({loaderOpen:true, isSearch : false, fieldChange : false, savedSearchClickId : 0});
        this.getClientBystatus(this.state.ReportFields.client_status);
        this.getStaffBystatus(this.state.ReportFields.user_status);
        this.formSubmit();
    };
    editSavedTimeEntry = (id) => {
        let timeslice_id = parseInt(id);
        let rand = Math.random();
        if (timeslice_id > 0) {
            this.setState({
                clickedTimeSliceId : timeslice_id,
                timeSliceEditDialogOpen : true,
                random_number : rand,
            });
        }
    };
    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.ReportFields;
        ff[field] = evt.target.value;
        if(field === 'client_status')
        {
            this.getClientBystatus(evt.target.value);
            ff['clients']=[];
            ff['projects']=[];
        }
        if(field=== 'project_status')
        {
            this.getProjectBystatus(evt.target.value);
            ff['projects'] = [];
        }
        if(field=== 'task_status')
        {
            this.getTaskBystatus(evt.target.value);
            ff['tasks'] = [];
        }
        if(field === 'user_status')
        {
          this.getStaffBystatus(evt.target.value);
          ff['staffs'] = [];
        }
        if(field === 'user_groups')
        {
          this.getStaffBystatus(this.state.ReportFields.user_status);
          ff['staffs'] = [];
        }
        if (evt.target.type && evt.target.type === 'checkbox') {
            ff[field] = evt.target.checked;
	}
        if(field ==='date_to' || field ==='date_from'){
            this.getStaffBystatus(this.state.ReportFields.user_status);
        }
        this.setState({
                ReportFields: ff,
                fieldChange: true
                });           
    };
    handleDeleteClick = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };
    shareFilter = () => {
        let ff = this.state.ReportFields;
        this.MixpanelManager.trackEvent('Reports', { 'module': 'Reports', 'feature': 'custom-report-save-filters', 'action': 'filter-shared' });
        return httpServices.post('api/report/shareFilter', {'users': ff.users, 'id' : this.state.shareFilterId})
                .then(response => {
                    if (response.data && response.data.permission) {
                        successMsg =  "Search shared successfully";                       
                        this.setState({
                            loaderOpen: false,
                            popupType: '',
                            open: false,
                            snackbarOpen : true,
                        })
                    }
                   
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
    }
    editClick = (id) => {
        let ff = this.state.ReportFields;
        if (id) {
            return httpServices.post('api/report/get-saved-filter', {'id' : id})
            .then(response => {
                if (response.data) {
                    let filter = response.data.filter;
                    ff['title'] = filter.title;
                    ff['description'] = filter.description;
                    ff['public'] = filter.public;                  
                    this.setState({
                        ReportFields: ff,
                        open : true,
                        popupType : 'save_filters',
                        editId : id,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
        }
    };
    formSubmit = () => {
        let filterFields = this.state.ReportFields;
        if(this.handleValidation()) {
         this.setState({loaderOpen:true});
        return httpServices.post('api/report/search', {'staffs' : filterFields.staffs, 'projects' : filterFields.projects,'clients' : filterFields.clients, 'search' : true, 'tasks' : filterFields.tasks, 'date_from' : filterFields.date_from, 'date_to' : filterFields.date_to, 'status' : filterFields.status, 'emp_category' : filterFields.emp_category,'location' : filterFields.location,'pay_type' : filterFields.pay_type,'user_groups' : filterFields.user_groups, 'user_status' : filterFields.user_status, 'staff_type' : this.state.ReportFields.staff_type_ids, 'client_status' : filterFields.client_status, 'project_status' : filterFields.project_status, 'task_status' : filterFields.task_status, 'type' : filterFields.type,'timeslice_log' : filterFields.timeslice_log, 'label_type': filterFields.label_type, 'selected_label': filterFields.selected_label})
            .then(response => {
                if (response.data && !response.data.errors) {
                    this.setState({
                        isSearch: true,
                        reportData: response.data.report_data,
                        total_hours: response.data.total_hours,
                        loaderOpen: false,
                    })
                } else if (response.data.errors) {
                    this.setState({
                        errors: response.data.errors,
                        loaderOpen: false
                    })
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    }
    handleViewDetailsClick = (id) => {                
          this.setState({
            clickedTimeSliceId : id,
            popupType : 'details',
            open : true,
            }); 
    }
    handleViewReviewNotes = (id, button = '') => {
        let apiEndpoint = 'api/timesheet/listreviewcomments';
        return httpServices.post(apiEndpoint,{timeslice_id : id})
            .then(response => {
                if (response.data) {
                        this.setState({
                            reviewNotes : response.data.comments,
                            button : button,
                            popupType : 'review',
                            open : true,
                        });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    getClientBystatus(client_status){
        httpServices.post('api/report/get-clients',{'status' : client_status,'permission' : (this.state.allPermission === "Yes") ? "all" : "self"})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['client_list'] = _ts.prepareTreeData(response.data, 'clients');
                    this.setState({
                        formInputs:finputs
                    });
                    this.getProjectBystatus(this.state.ReportFields.project_status);
                }
            })
    }
    getUsers() {
        httpServices.post('api/report/get-all-users')
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['user_list'] = _ts.prepareTreeData(response.data, 'users', false);
                    this.setState({
                        formInputs: finputs
                    });
                }
            })
    }
     getStaffBystatus(staff_status) {
        httpServices.post('api/report/get-users',{ 'staff_type' : this.state.ReportFields.staff_type_ids, 'status_id' : staff_status, 'user_group' : this.state.ReportFields.user_groups, 'sdate' : this.state.ReportFields.date_from, 'edate' : this.state.ReportFields.date_to})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['staff_list'] = _ts.prepareTreeData(response.data, 'staff');
                    this.setState({
                        formInputs:finputs
                    });
                }
            })
    }
    getProjectBystatus(project_status){
        let client=JSON.parse(JSON.stringify(this.state.ReportFields.clients));
        if( !(client.length && parseInt(client))){
            client=[];
            this.state.formInputs.client_list.map((t, i) =>
                client.push(t.client_id)
                );
        }
        httpServices.post('api/project/get-projects-by-status',{ 'status' : project_status , 'clients' : client, 'all_permission' : this.state.allPermission})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    finputs['project_list'] =  _ts.prepareTreeData(response.data, 'projects');
                    this.setState({
                        formInputs:finputs,
                        loaderOpen : false,
                    });
                    this.getTaskBystatus(this.state.ReportFields.task_status);
                }
            })
    }
    getTaskBystatus(task_status){
        let project = JSON.parse(JSON.stringify(this.state.ReportFields.projects));
        if( !(project.length && parseInt(project))){
            project=[];
            if (this.state.formInputs.project_list && this.state.formInputs.project_list.length) {
                getAllProjectIds(this.state.formInputs.project_list,project);
            }
        }
        httpServices.post('api/report/get-tasks-by-projects',{ 'task_status' : task_status , 'projects' : project})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
                    let tasks = response.data.tasks;
                     // Sort the tast based on the selected project tree
                    let sorted_tasks = [];
                    project.forEach((p) =>{
                        if (p) {
                            if (tasks && tasks.length) {
                                tasks.forEach((task) => {
                                    if (task.task_project === p) {
                                        let json_task = JSON.stringify(task);
                                        sorted_tasks.push(json_task);
                                    }
                                });
                            }
                        }
                    });
                    finputs['task_list'] =  _ts.prepareTreeData(sorted_tasks, 'tasks');
                    this.setState({
                        formInputs:finputs
                    });
                }
            })
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let f = this.state.ReportFields;
        let values = this.state.formInputs;
        let staffs = [];
        let clients=[];
        let tasks = [];
        let projects = [];
        let users = [];
        if (cNode.value === 'selectAll'){
            if( cNode.checked) {
                if (action === 'clients') {
                    clients.push(0);
                }
                else if (action === 'staffs') {
                    staffs.push(0);
                }
                else if (action === 'users') {
                    users.push(0);
                }
                else if (action === 'project') {
                    projects.push(0);
                }
                else if (action === 'tasks') {
                    tasks.push(0);
                }
            }
        }
        else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id || el.client_id || el.user_id || el.task_id) {
                    if (action === 'staffs') {
                        staffs.push(el.user_id);
                    }
                    else if (action === 'clients') {
                        clients.push(el.client_id);
                    }
                    else if (action === 'users') {
                        users.push(el.user_id);
                    }
                    else if (action === 'tasks') {
                        tasks.push(el.task_id);
                    }
                    else if (action === 'project') {
                        getProjectTree(values.project_list, el.project_id, projects);
                    }
                }
            });
        }
        if (action === 'staffs') {
            f['staffs'] = staffs;
        }
        else if(action === 'clients'){
            f['clients'] = clients;
            f['projects']=[0];
            this.getProjectBystatus(this.state.ReportFields.project_status);
        }
        else if (action === 'project') {
            f['projects'] = projects;
            this.getTaskBystatus(this.state.ReportFields.task_status);
        }
        else if (action === 'tasks') {
            f['tasks'] = tasks;
        }
        else if (action === 'users') {
            f['users'] = users;
        }
        this.setState({ReportFields : f, fieldChange : true});
    }
    handleStaffTypeChange = (evt, value) => {
        let ff = this.state.ReportFields;
        let staff_type_ids = [];
        let new_value = [];
        if (value) {
            if (typeof value === 'string') {
                //ff['county'] = 'new_'+value;
            } else if (typeof value === 'object' && value) {
                 let value_len = value.length;
                 value.forEach(eachStaffTypeFunction);
                 function eachStaffTypeFunction(eachStaff, index1) {
                 staff_type_ids.push(parseInt(eachStaff.id));
                 if (value_len > 1) {
                    if (eachStaff.type !== "All Staff Types") {
                    new_value.push(eachStaff);
                    }
                 }
                else{
                    new_value.push(eachStaff);
                }
            }
                if (value_len === 0) {
                    new_value = [ff.staff_types[0]];
                }
                ff['staff_type'] = new_value;
                ff['staff_type_ids'] = staff_type_ids;
            }
        }
        this.getStaffBystatus(this.state.ReportFields.user_status);
        this.setState({
            ReportFields: ff,
            fieldChange : evt == "search" ? false : true
        });
        
    };
    onClose = () => {
        let ff = this.state.ReportFields;
        ff['title'] = '';
        ff['description'] = '';
        ff['public'] = false;
        ff['users'] = [];
         this.setState({
            open: false,
            error_msg : '',
            editId : 0,
            ReportFields: ff,
        });
    }
    handleStaffChange = (evt, value) => {
        let ff = this.state.ReportFields;
        if (value) {
            if (typeof value === 'string') {
                //ff['county'] = 'new_'+value;
            } else if (typeof value === 'object' && value) {
                 ff['employee'] = value;
            }
        }
        this.setState({
            ReportFields: ff
        });
    };
    timeSliceEditDialogClose = () => {
        this.setState({timeSliceEditDialogOpen:false});
    };
     showEditSuccess = () => {
        successMsg = "Time entry updated";
        this.setState({
            snackbarOpen : true,
        });
    };

    exportReports = (export_option) => {
        let valid = this.handleValidation();
        if (valid) {
            let fields = this.state.ReportFields;
            fields.btn_type = export_option;
            this.setState({ReportFields : fields}, function() {
                document.getElementById('export-form').submit();
            });
        }
    };

    getSavedFilters = () => {
        return httpServices.post('api/report/getSavedFilters')
        .then(response => {
            if (response.data && response.data.permission) {
                this.setState({
                    savedFilters: response.data.saved_filters,
                })
            }
        })
        .catch(function (error) {
            console.log("Error: " + error);
        });
    }
    
    handlesSaveFilterClick = (action) => {
        this.setState({
            popupType: 'save_filters',
            open: true,
            saveAction : action
        });
    }

    handleUpdateFilterClick = (action) => {
        if (action == "save" && this.state.savedSearchClickId) {
            this.setState({               
                saveAction : action
            }, (() => this.saveFilter()));
        }
        else
        {
            this.handlesSaveFilterClick("saveas");
        }
    }

    handleShareClick = (id) => {
        let ff = this.state.ReportFields;
         return httpServices.post('api/report/get-filter-users', {'id' : id})
            .then(response => {
                if (response.data.permission) {
                    let users = response.data.users;
                    if (users) {
                        ff['users'] = users; 
                    }
                    this.setState({
                        popupType: 'share_filter',
                        open: true,
                        shareFilterId : id,
                        ReportFields: ff,
                        sharedSearchName: response.data.filter_title
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
       
    }

    handleSearchClick = (id, cby) => {
        let ff = this.state.ReportFields;
        this.setState({
            savedSearchClickId: id,
            savedSearchCreatedBy: cby,
            tabIndex : 0            
        });
        return httpServices.post('api/report/get-saved-filter', {'id' : id})
            .then(response => {
                if (response.data) {
                    let filterOptions = response.data.filter_options;
                    Object.keys(filterOptions).forEach(key => {
                        if (key == "staff_type") {
                            let staff_type_ids = [];
                            let selected_types = [];
                            Object.keys(filterOptions[key]).forEach(each => {
                                staff_type_ids.push(parseInt(filterOptions[key][each]));
                                selected_types.push(ff.staff_types[parseInt(filterOptions[key][each])]);
                            });
                            this.handleStaffTypeChange("search", selected_types);
                            ff['staff_type_ids'] = staff_type_ids; 
                        }
                        else if (key == "timeslice_type") {
                            ff['type'] = filterOptions[key];
                        }
                        else if (key == "timeslice_project") {                        
                            ff['projects'] = filterOptions[key].map(Number);
                        }
                        else if (key == "timeslice_client") {                        
                            ff['clients'] = filterOptions[key].map(Number);
                        }
                        else if (key == "timeslice_createdby") {                        
                            ff['staffs'] = filterOptions[key].map(Number);
                        }
                        else if (key == "timeslice_task") {                        
                            ff['tasks'] = filterOptions[key].map(Number);
                        }
                        else
                        {
                            ff[key] = filterOptions[key];
                        }
                      });
                      this.setState({
                        ReportFields: ff
                    }, (() => this.submitSavedSearch()));
                    this.MixpanelManager.trackEvent('Reports', { 'module': 'Reports', 'feature': 'custom-report-save-filters', 'action': 'search-saved-filter' });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    
    submitSavedSearch = () => {
        this.setState({
            fieldChange: false
        }, (() => this.formSubmit()));
    }

    updateFilter = () => {
        let filterFields = this.state.ReportFields;
        this.MixpanelManager.trackEvent('Reports', { 'module': 'Reports', 'feature': 'custom-report-save-filters', 'action': 'updated-saved-filter-details' });
        return httpServices.post('api/report/updateSavedFilter', {'title': filterFields.title, 'description': filterFields.description, 'id': this.state.editId, 'public': filterFields.public})
            .then(response => {
                if (response.data && response.data.status) {
                    successMsg = "Saved Search updated successfully";
                    filterFields['title'] = '';
                    filterFields['description'] = '';
                    filterFields['public'] = false;
                    this.setState({
                        loaderOpen: false,
                        popupType: '',
                        open: false,
                        snackbarOpen: true,
                        editId: 0,
                        ReportFields: filterFields
                    })
                    this.getSavedFilters();
                    this.handleTabChange(1);
                }
                else {
                    this.setState({
                        error_msg: response.data.error_msg,
                        loaderOpen: false,
                    })
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });    
    }

    saveFilter = () => {
        if ((!this.state.ReportFields.title || this.state.ReportFields.title.trim() == "") && this.state.saveAction == "saveas") {
            this.setState({
                error_msg: "Title cannot be empty",
            })
        }
        else {
            if (this.state.editId) {
                this.updateFilter();
            }
            else {
                let filterFields = this.state.ReportFields;
                return httpServices.post('api/report/saveFilter', { 'staffs': filterFields.staffs, 'projects': filterFields.projects, 'clients': filterFields.clients, 'save': true, 'tasks': filterFields.tasks, 'date_from': filterFields.date_from, 'date_to': filterFields.date_to, 'status': filterFields.status, 'emp_category': filterFields.emp_category, 'location': filterFields.location, 'pay_type': filterFields.pay_type, 'user_groups': filterFields.user_groups, 'user_status': filterFields.user_status, 'staff_type': this.state.ReportFields.staff_type_ids, 'client_status': filterFields.client_status, 'project_status': filterFields.project_status, 'task_status': filterFields.task_status, 'type': filterFields.type, 'timeslice_log': filterFields.timeslice_log, 'title': filterFields.title, 'description': filterFields.description, 'id': this.state.editId, 'public': filterFields.public, 'update_id': this.state.savedSearchClickId, 'save_action': this.state.saveAction,'label_type': filterFields.label_type, 'selected_label':filterFields.selected_label })
                    .then(response => {
                        if (response.data && response.data.status) {
                            successMsg = this.state.saveAction == "save" ? "Saved Search updated successfully" : "Search saved successfully";
                            filterFields['title'] = '';
                            filterFields['description'] = '';
                            filterFields['public'] = false;
                            this.setState({
                                loaderOpen: false,
                                popupType: '',
                                open: false,
                                snackbarOpen: true,
                                editId: 0,
                                ReportFields: filterFields
                            })
                            this.getSavedFilters();
                            this.handleTabChange(1);
                            this.MixpanelManager.trackEvent('Reports', { 'module': 'Reports', 'feature': 'custom-report-save-filters', 'action': 'filter-saved' });
                        }
                        else {
                            this.setState({
                                error_msg: response.data.error_msg,
                                loaderOpen: false,
                            })
                        }

                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        }
    }

     handleValidation() {
        let formIsValid = true;
         let validationData = [
             { name: 'date_from', value: this.state.ReportFields.date_from, type: 'date', otherOptions: { required: true } },
             { name: 'date_to', value: this.state.ReportFields.date_to, type: 'date', otherOptions: { required: true } },
             { name: 'user_status', value: this.state.ReportFields.user_status, type: 'integer', otherOptions: { required: false } },
             { name: 'project_status', value: this.state.ReportFields.project_status, type: 'alphanumeric', otherOptions: { required: false } }
         ]
         let validateResponse = validations.validateFormElements(validationData);
         let validateErrors = validateResponse.errors;
         let isValid = validateResponse.valid;
         if (isValid) {
             let errors = {};
             let start_date = this.state.ReportFields.date_from ? new Date(this.state.ReportFields.date_from) : this.state.ReportFields.date_from;
        let end_date = this.state.ReportFields.date_to ? new Date(this.state.ReportFields.date_to) : this.state.ReportFields.date_to;
        if ( start_date && end_date ) {
            if(end_date < start_date){
                errors["date_to"] = "To Date should be greater than from date";
                formIsValid = false;
            }
             }
             this.setState({
                 errors: errors
             });
         } else {
             formIsValid = false;
             this.setState({
                 errors: validateErrors
             });
         }
        return formIsValid;
    }
    componentDidMount() {
        this.setState({loaderOpen:true});
        httpServices.get('api/report/search')
            .then(response => {
                if (response.data.permission) {
                    let inputs = response.data;
                    this.setState({
                        ReportFields : {
                            date_from : inputs.first_day,
                            date_to : inputs.last_day,
                            pay_period_first : inputs.first_day,
                            pay_period_last : inputs.last_day,
                            groups : inputs.groups,
                            user_statuses : inputs.employee_status,
                            task_statuses : inputs.taskStatus,
                            task_status : 0,
                            project_status : 1,
                            user_status : 1,
                            project_statuses : inputs.projectStatus,
                            client_statuses : inputs.clientStatus,
                            emp_categories : inputs.employee_category,
                            pay_types : inputs.pay_types,
                            job_types : inputs.job_types,
                            statuses : inputs.status,
                            staff_types : inputs.staff_types,
                            locations : inputs.locations,
                            'clients' : [0],
                            'projects' : [0],
                            'tasks' : [0],
                            'staffs' : [0],
                            'users' : [0],
			    labels : inputs.labels,
                        },
                        savedFilters : inputs.saved_filters,
                        allPermission : response.data.all_permission,
                        logged_user : inputs.logged_user,
                    });
                    this.getClientBystatus(1);
                    this.getUsers();
                }
                else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
            this.getStaffBystatus(1);
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    };
     getSavedTimeSlices = (date=false) => {
        let data = {
            date : date ? date : this.state.selectedDate,
            //mode : 'semi-monthly' : TBD
        };
        return httpServices.post('api/timesheet/timeslicesearch', {data : data})
            .then(response => {
                if (response.data) {
                    if (response.data.timeslices && response.data.timeslices.length) {
                        dates_in_page = this.arrayColumn(response.data.timeslices,'date');
                    }
                    this.setState({
                        timesheetList : response.data,
                        timesheetIsSubmitted : response.data.is_submitted,
                        payPeriodTxt: response.data.payperiod_sdate+' - '+response.data.payperiod_edate,
                    })
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };
    getTimeEntrySummary = () => {
        let postData = {
            date: this.state.selectedDate
        };
        return httpServices.post('api/timesheet/get-project-summary', postData)
            .then(response => {
                if (response.data) {
                    let data = response.data;
                    this.setState({
                        project_summary: data.project_summary,
                        daily_summary: data.daily_summary,
                        hours_summary: data.hours_summary,
                        formData : response.data,
                     });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    };
    deleteFilter = () => {
        let apiEndpoint = 'api/report/deleteFilter';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    successMsg = "Search deleted successfully";
                    this.setState({
                        snackbarOpen : true,
                        open : false,
                        popupType : '',
                        savedFilters : response.data.saved_filters
                    });
                    this.MixpanelManager.trackEvent('Reports', { 'module': 'Reports', 'feature': 'custom-report-save-filters', 'action': 'delete-saved-filter' });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleTabChange = (tab) => {
        this.setState({
            tabIndex : tab           
        });
    }
    render(){
        return(
            (this.state.hasPermission)
            ?
            <CustomReportComponent
            HandleOnChange = { this.HandleOnChange }
            Button = {this.state.button}
            ReportFields = { this.state.ReportFields }
            HandleStaffTypeChange = {this.handleStaffTypeChange}
            HandleStaffChange = {this.handleStaffChange}
            formInputs = { this.state.formInputs }
            HandleTreeChange = {this.handleTreeChange}
            FormSubmit = {this.formSubmit}
            ReportData = {this.state.reportData}
            HandleViewDetailsClick = {this.handleViewDetailsClick}
            Open = {this.state.open}
            Current = {this.state.current}
            Comments = {this.state.comments}
            ChangeLog = {this.state.changeLogs}
            PopupType = {this.state.popupType}
            OnClose = {this.onClose}
            HandleViewReviewNotes = {this.handleViewReviewNotes}
            ReviewNotes = {this.state.reviewNotes}
            TimeSliceEditDialogOpen = {this.state.timeSliceEditDialogOpen}
            TimeSliceEditDialogClose = {this.timeSliceEditDialogClose}
            ShowEditSuccess = {this.showEditSuccess}
            snackbarOpen = {this.state.snackbarOpen}
            Message = {successMsg}
            SnackbarClose = {this.snackbarClose}
            randomNumber = {this.state.random_number}
            EditSavedTimeEntry = {this.editSavedTimeEntry}
            ClickedTimeSliceId = {this.state.clickedTimeSliceId}
            GetSavedTimeSlices = {this.getSavedTimeSlices}
            TotalHours = {this.state.total_hours}
            isSearch = {this.state.isSearch}
            loaderOpen = {this.state.loaderOpen}
            Errors = { this.state.errors }
            ExportReports = {this.exportReports}
            Reset = {this.reset}
            HandlesSaveFilterClick={this.handlesSaveFilterClick}
            SaveFilter={this.saveFilter}
            SavedFilters = {this.state.savedFilters}
            HandleSearchClick = {this.handleSearchClick}
            SavedSearchClickId = {this.state.savedSearchClickId}
            TabIndex = {this.state.tabIndex}
            HandleTabChange = {this.handleTabChange}
            ErrorMessage = {this.state.error_msg}
            HandleDeleteClick = {this.handleDeleteClick}
            DeleteFilter = {this.deleteFilter}
            EditClick = {this.editClick}
            EditId = {this.state.editId}
            LoggedUser = {this.state.logged_user}
            HandleShareClick = {this.handleShareClick}
            ShareFilter = {this.shareFilter}
            HandleUpdateFilterClick = {this.handleUpdateFilterClick}
            FieldChange = {this.state.fieldChange}
            SavedSearchCreatedBy = {this.state.savedSearchCreatedBy}
            SharedSearchName = {this.state.sharedSearchName}
         />
            :<ErrorPage/>
        )
    }

}

