import React from 'react';
import { httpServices } from '../../services/httpServices';
import ReactHtmlParser from 'html-react-parser';
import { ErrorPage } from '../common/error';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

export class AnnouncementView extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            announcement : {},
            permission : true,
            id : 0
        }

        this.getAnnouncement = this.getAnnouncement.bind(this);
    }

    componentDidMount = () => {
        this.getAnnouncement(this.props.match.params.id);
    };
    componentDidUpdate= ()=>{
        if(this.state.id !== this.props.match.params.id ) {
            this.getAnnouncement(this.props.match.params.id);
        }
    };

    getAnnouncement = (id) =>{
        let apiEndpoint = 'api/system/announcement/view';
        let data = {
            'id' : id,
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data) { 
                    if (response.data.permission) {
                        this.setState({
                            announcement : response.data.announcements,
                            id :id,
                            permission : response.data.permission
                        });
                    }else {
                        this.setState({
                            id : id
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <>
            {(this.state.permission)?
                <AnnouncementViewComponent 
                    Announcement= {this.state.announcement}
                />
                : <ErrorPage/>
            }</>	
        )
    }
}
const AnnouncementViewComponent = (props) =>{
    return(
        <>
            <div className={'section-main section-main-header-fixed'}>
                <div className={'header-fixed'} >
                    <div className={'section-header'}>
                        <div className={'col-left mt-1'}>
                            <Button size="small" variant="outlined" className="startIcon-noMargin" startIcon={<Tooltip title="Back" aria-label="back"><ChevronLeftIcon /></Tooltip>} onClick={() => { window.history.back(); }} ></Button>
                            <Typography variant="h6" component="h6" className={'section-title'}><span className={'text-transform-none'}>{props.Announcement.title}</span></Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'section-body'}>
                <Paper className={'mb-1'}>
                    <div className={'p-1rem'}>
                        {(props.Announcement.message) ?
                            <>{ReactHtmlParser(props.Announcement.message)}</>
                            : <></>
                        }
                    </div>
                </Paper>
            </div>
        </>
    )
}

