import React from 'react';
import { InstanceDetailsComponent}  from './InstanceDetails.Component';
import { httpServices }  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const formFields = {
    src_user: '',
    dest_user: '',
    src_task: '',
    dest_task: '',
    src_location: 0,
    dest_location: 0,
    status : 1,
    editId : '',
};
export class InstanceDetailsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            hasPermission: false,
            staffView: false,
            staffEdit: false,
            staffTaskDelete : false,
            locationView: false,
            locationEdit: false,
            dataLoaded: false,
            cancel : false,
            save :false,
            source_staff_list: [],
            source_user_list: [],
            destination_user_list: [],
            destination_staff_list: [],
            formFields : formFields,
            errors : {},
            destination_instance: '',
            staff_mapped : [],
            user_mapped : [],
            src_task_list :[],
            dest_task_list : [],
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant : '',
            editSrcTask :"",
            editDestTask:'',
            editSrcTaskList :[],
            editDestTaskList :[],
            editStatus:'',
            editId:'',
            src_location_list : [],
            dest_location_list : [],
            showLoader: false,
            open : false,
            deleteId : 0,
            mapOption : '',
            srcFullName : '',
            sourceLocationName : ''
        };
        this.getList = this.getList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.delete = this.delete.bind(this);
        this.edit = this.edit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleStatusChange =this.handleStatusChange.bind(this);
        this.resetFields = this.resetFields.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteLocation = this.onDeleteLocation.bind(this);
    }
    componentDidMount = () => {
        this.setState({destination_instance : this.props.location.state.instanceName});
        this.getList(this.props.location.state.instanceName);
    }; 
    getList = (destination_instance) => {
        this.setState({
            showLoader: true
        });
        let apiEndpoint = 'api/settings/syncinstance/list';
            return httpServices.post(apiEndpoint, {destination_instance: destination_instance})
                .then(response => {
                    if (response.data.permission) {
                        let staffView = response.data.staff_view;
                        let staffEdit = response.data.staff_edit;
                        let staffTaskDelete = response.data.staffTaskDelete;
                        let locationView = response.data.location_view;
                        let locationEdit = response.data.location_edit;
                        let source_staff_list = staffView ? response.data.source_staff_list : [];
                        let destination_staff_list = staffView ? response.data.destination_staff_list : [];                        
                        let source_user_list = staffView ? response.data.source_user_list : [];
                        let destination_user_list = staffView ? response.data.destination_user_list : [];
                        let staff_mapped = staffView ? response.data.staff_mapped : [];
                        let user_mapped = staffView ? response.data.user_mapped : [];
                        let src_task_list = staffView ? response.data.src_task_list : [];
                        let dest_task_list = staffView ? response.data.dest_task_list : [];
                        let task_mapped = staffView ? response.data.task_mapped : [];
                        let src_location_list = locationView ? response.data.locations : [];
                        let dest_location_list = locationView ? response.data.destination_location_data : [];
                        let location_mapped = locationView ? response.data.location_mapped : [];
                        this.setState({
                            hasPermission: true,
                            dataLoaded: true,
                            staffView: staffView,
                            staffEdit: staffEdit,
                            staffTaskDelete : staffTaskDelete,
                            locationView: locationView,
                            locationEdit: locationEdit,
                            source_staff_list: source_staff_list,
                            destination_user_list: destination_user_list,                            
                            source_user_list: source_user_list,
                            destination_staff_list: destination_staff_list,
                            staff_mapped: staff_mapped,
                            user_mapped: user_mapped,
                            src_task_list: src_task_list,
                            dest_task_list: dest_task_list,
                            task_mapped: task_mapped,
                            src_location_list: src_location_list,
                            dest_location_list: dest_location_list,
                            location_mapped: location_mapped,
                            showLoader: false
                        });
                    } else{
                        this.setState({
                            dataLoaded: true,
                            showLoader: false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
    };
    handleEditClick = (id, user_map_id,status, src_id, dest_id, src_path, dest_path) => {
        let userMapEdit =  this.state.user_mapped.filter(mapping => mapping.id === user_map_id);
        let fields = this.state.formFields;
        fields['editUserMapId'] = user_map_id;
        fields['editSrcTask'] = src_id;
        fields['editDestTask'] = dest_id;
        fields['editSrcPath'] = src_path; 
        fields['editDestPath'] = dest_path;         
        this.setState({
            open : true,
            editId : id,
            editStatus : status ? 1 : 0,
            editSrcTaskList : userMapEdit[0].edit_src_task_list,
            editDestTaskList : userMapEdit[0].edit_dest_task_list,
            formFields: fields,
        });
    }
    onClose = () =>{
        let ff = this.state.formFields;
        ff['status'] = null;

        this.setState({
            open: false,
            staffDelete: false,
            locationDelete: false,
            errors:{},
            deleteConfirm : false,
            formFields : ff 
        });
        this.clearForm();
    }

    clearForm = () => {
        let ff = this.state.formFields;
        ff['status'] = 1;
        this.setState({
            errors: {},
            errorMessage: false,
            formFields : {} 
        });
    };
    handleSubmit = (map_option, map_id = null) => {
        if (this.handleValidation(map_option)) {
            let data = this.state.formFields;
            data.destination = this.state.destination_instance;
            data.map_option = map_option;
            let success_message ='';
            let apiEndpoint = '';
            if (map_option == 'user') {
                success_message = "User added successfully";
                apiEndpoint = 'api/settings/syncinstance/saveUserMapping';
            }
            if (map_option == 'task') {
                success_message = "Task added successfully";
                apiEndpoint = 'api/settings/syncinstance/saveTaskMapping';
            }
            if (map_option == 'location') {
                success_message = "Location added successfully";
                apiEndpoint = 'api/settings/syncinstance/saveLocationMapping';
            }
            if(map_id) {
                data.user_map_id = map_id;
            }
            if (data) {
                if (map_option == 'task') {
                    let userMapEdit =  this.state.user_mapped.filter(mapping => mapping.id === data.user_map_id);
                    let srcTaskList = userMapEdit[0].edit_src_task_list;
                    data.srcTaskPath =  srcTaskList.filter(mapping => mapping.task_id === data.src_task)[0]['task_path'];
                    let destTaskList = userMapEdit[0].edit_dest_task_list;
                    data.destTaskPath =  destTaskList.filter(mapping => mapping.task_id === data.dest_task)[0]['task_path'];
                }
                return httpServices.post(apiEndpoint, data)
                    .then(response => {
                        if (response.data.status) {
                            this.getList(this.state.destination_instance);
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: success_message,
                                snackbarVariant: 'success'
                            });
                        }
                        else {
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: response.data.error,
                                snackbarVariant: 'error'
                            });
                        }
                        this.resetFields();
                    })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
            }
        }
    }
    resetFields = () => {
        let fields = this.state.formFields;
        fields['src_user'] = '';
        fields['dest_user'] = '';
        fields['src_user'] = '';
        fields['dest_user'] = '';
        fields['src_task'] = '';
        fields['dest_task'] = '';
        fields['src_location'] = 0;
        fields['dest_location'] = 0;
        fields['status'] = 1;
        fields['editDestTask'] = '';
        fields['editSrcTask'] = '';
        fields['editSrcPath'] = '';
        fields['editDestPath'] = '';        
        this.setState({ formFields: fields});
    } 
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    handleValidation(map_option) {
        let formIsValid = true;
        let errors = {};
        if(map_option == 'staff'){
            if (!this.state.formFields.src_staff) {
                errors["src_staff"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.formFields.dest_staff) {
                errors["dest_staff"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        else if (map_option == 'user') {
            if (!this.state.formFields.src_user) {
                errors["src_user"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.formFields.dest_user) {
                errors["dest_user"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        else if(map_option == 'task'){
            if (!this.state.formFields.src_task) {
                errors["src_task"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.formFields.dest_task) {
                errors["dest_task"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        else if (map_option == 'location') {
            if (!this.state.formFields.src_location) {
                errors["src_location"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.formFields.dest_location) {
                errors["dest_location"] = "Cannot be empty";
                formIsValid = false;
            }
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onDelete = (delete_id, src_full_name, map_option) => {
        this.setState({staffDelete : true, deleteId : delete_id, srcFullName : src_full_name, mapOption : map_option});
    }
    onDeleteLocation = (delete_id, source_location_name, map_option) => {
        this.setState({locationDelete : true, deleteId : delete_id, sourceLocationName : source_location_name, mapOption : map_option});
    }
    delete = () =>{
        this.setState({staffDelete : false, locationDelete : false});
        let delete_id = this.state.deleteId;
        let map_option = this.state.mapOption;
        if (delete_id) {
            let success_message = '';
            if (map_option == 'staff') {
                success_message = "User deleted successfully";
            }
            else if (map_option == 'task') {
                success_message = "Task deleted successfully";
            }
            else if (map_option == 'location') {
                success_message = "Location deleted successfully";
            }
            let apiEndpoint = 'api/settings/syncinstance/delete';
            return httpServices.post(apiEndpoint,{delete_id, map_option})
                .then(response => {
                    if (response.data.status) {
                        this.getList(this.state.destination_instance);
                        this.setState({
                            snackbarOpen: true,
                            snackbarMsg: success_message,
                            snackbarVariant: 'success'
                        });
                    } else {
                        this.setState({
                            snackbarOpen: true,
                            snackbarMsg: "Delete failed!",
                            snackbarVariant: 'error'
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    edit = (editId, status) => {
        if (editId) {
            let ff = this.state.formFields;
            let formIsValid = true;
            let errors = {};
            let apiEndpoint = 'api/settings/syncinstance/edit';
            if (!this.state.formFields.editSrcTask) {
                errors["edit_src_task"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.formFields.editDestTask) {
                errors["edit_dest_task"] = "Cannot be empty";
                formIsValid = false;
            }
            if (formIsValid) {
                let userMapEdit =  this.state.user_mapped.filter(mapping => mapping.id === ff.editUserMapId);
                let srcTaskList = userMapEdit[0].edit_src_task_list;
                let newSrcTaskPath =  srcTaskList.filter(mapping => mapping.task_id === ff.editSrcTask)[0]['task_path'];
                let destTaskList = userMapEdit[0].edit_dest_task_list;
                let newDestTaskPath =  destTaskList.filter(mapping => mapping.task_id === ff.editDestTask)[0]['task_path'];
                return httpServices.post(apiEndpoint, 
                    { editId: editId, status: status, src_task: ff.editSrcTask, dest_task: ff.editDestTask, user_map_id: ff.editUserMapId, src_task_path: ff.editSrcPath, dest_task_path: ff.editDestPath, newSrcTaskPath : newSrcTaskPath, newDestTaskPath : newDestTaskPath })
                    .then(response => {
                        if (response.data.status) {
                            this.getList(this.state.destination_instance);
                            this.setState({
                                open: false,
                                snackbarOpen: true,
                                snackbarMsg: "Updated Task successfuly!",
                                snackbarVariant: 'success'
                            });
                            this.resetFields();
                        } else {
                            this.setState({
                                snackbarOpen: true,
                                snackbarMsg: "Duplicate entry.Failed to update the value",
                                snackbarVariant: 'error'
                            });
                        }
                        
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
            else {
                this.setState({ errors: errors });
            }
        }
    }
    handleStatusChange = (evt, value) => {
        const field = evt.target.name;
        this.setState({
            editStatus : evt.target.value
        });
    };
    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.dataLoaded) ?
                (this.state.hasPermission)
                ?
                <InstanceDetailsComponent
                    SourceStaffList = {this.state.source_staff_list}
                    SourceUserList = {this.state.source_user_list}
                    DestinationUserList = {this.state.destination_user_list}
                    FormFields = {this.state.formFields}
                    HandleOnChange = {this.handleOnChange}
                    HandleEditClick = {this.handleEditClick}
                    Errors = {this.state.errors}
                    HandleSubmit ={this.handleSubmit}
                    DestinationStaffList = {this.state.destination_staff_list}
                    DestinationInstance = {this.state.destination_instance}
                    StaffMapped = {this.state.staff_mapped}
                    UserMapped = {this.state.user_mapped}
                    SourceTaskList = {this.state.src_task_list}
                    DestinationTaskList = {this.state.dest_task_list}
                    SnackbarOpen = {this.state.snackbarOpen}
                    Open = {this.state.open}
                    SnackbarMsg = {this.state.snackbarMsg}
                    SnackbarVariant = {this.state.snackbarVariant}
                    SnackbarClose = {this.snackbarClose}
                    Delete = {this.delete}
                    OnSave = {this.edit}
                    SourceLocationList = {this.state.src_location_list}
                    DestinationLocationList = {this.state.dest_location_list}
                    LocationMapped = {this.state.location_mapped}
                    StaffView = {this.state.staffView}
                    StaffEdit = {this.state.staffEdit}
                    StaffTaskDelete ={this.state.staffTaskDelete}
                    LocationView = {this.state.locationView}
                    LocationEdit = {this.state.locationEdit}
                    EditId = {this.state.editId}
                    EditSrcTask = {this.state.editSrcTask}
                    EditDestTask = {this.state.editDestTask}
                    EditStatus = {this.state.editStatus}
                    EditDestTaskList = {this.state.editDestTaskList}
                    EditSrcTaskList = {this.state.editSrcTaskList}
                    Close = {this.onClose}
                    HandleStatusChange ={this.handleStatusChange}
                    StaffDelete = {this.state.staffDelete}
                    OnDeleteStaff = {this.onDelete}
                    LocationDelete = {this.state.locationDelete}
                    OnDeleteLocation = {this.onDeleteLocation}
                    SrcFullName = {this.state.srcFullName}
                    SourceLocationName = {this.state.sourceLocationName}

                />
                : <ErrorPage Message={'You are not authorized to view this page.'} />
            :<></>
            }
            </>
        )
    }
}
