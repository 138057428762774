import React from 'react';
import {CompensationComponent} from './Compensation.Component'
import {httpServices}  from '../../../../services/httpServices'
import update from 'immutability-helper';
import { ErrorPage } from '../../../common/error';

export class Compensation extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            txt_salary: 0,
            effective_date: null,
            end_date : null,
            pay_type: null,
            components:[],
            open: false,
	        openNote: false,
	        note: [],
            result : null,
            closeNote:false,
            errors: {},
            addComponent: false,
            component_id:null,
            salaryID: null,
            scheduledComponents: [],
            componentAdjustments : [],
            configComponents : [],
            validation_error: null,
            isCompensationEdit : false,
            editCompensationId : 0,
            compen_component_id:null,
            Variant : 'success',
            amountMessage : null,
            snackbar : false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddSalary = this.onAddSalary.bind(this);
        this.onAddComponent = this.onAddComponent.bind(this);
	    this.onAddComponentNote = this.onAddComponentNote.bind(this);
        this.onSaveComponent = this.onSaveComponent.bind(this);
        this.SalaryUpdateClick = this.salaryUpdateClick.bind(this);
        this.SalaryDeleteClick = this.SalaryDeleteClick.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleOnChange = (evt) => {
        const { name, value } = evt.target;
        this.setState({ [name]: value }, () => {
            if (value) {
                this.setState({errors: {}});
            }
        });
    };

    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let salary = this.state.txt_salary;
        if (!this.state.effective_date) {
            errors["effective_date"] = "Cannot be empty";
        }else if(this.state.end_date && new Date(this.state.end_date)<new Date(this.state.effective_date)){
            errors["end_date"] = "Should be greater than effective date";   
        }
        if (!this.state.pay_type) {
            errors["pay_type"] = "Cannot be empty";
        }
        if (!salary) {
            errors["salary"] = "Cannot be empty";
        }
        else if (salary && !salary.toString().match(/^[0-9]|(\.[0-9]+)?$/)) {
            errors["salary"] = "Salary input is not valid";
        }
        this.setState({errors: errors});
        let error_count = Object.keys(errors).length;
        if (error_count > 0) {
            formIsValid = false;
        } 
        return formIsValid;
    }

    onSubmit = () =>{
        if (this.handleValidation()) {
            const {StaffID, UserID} = this.props;
            let data = {
                'staff_id': StaffID, 
                'user_id': UserID, 
                'salary': this.state.txt_salary,
                'effective_date': this.state.effective_date,
                'pay_type': this.state.pay_type,
                'end_date': this.state.end_date,
            }
            if (this.state.isCompensationEdit && this.state.editCompensationId) {
                data['id'] = this.state.editCompensationId;
            }
            if (StaffID) {
                return httpServices.post('api/compensation/saveCompensation', data)
                    .then(response => {
                        if (response.data) {
                            if (!response.data.input_errors) {
                                if (response.data.error) {
                                    /*var errors ={};
                                errors['server'] = response.data.error;
                                this.setState({errors: errors});*/
                                    this.setState({validation_error: response.data.error});
                                }
                                else{
                                    this.setState({
                                        open: false,
                                        components: response.data.salary_components,
                                        txt_salary: 0,
                                        effective_date: null,
                                        pay_type: null,
                                        isCompensationEdit : false,
                                        editCompensationId : 0,
                                    });
                                }
                            }
                            else {
                                this.setState({errors: response.data.input_errors});
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    }

    onAddSalary = () => {
        this.setState({
            addComponent: false,
            open: true,
        });
    }

    onClose = () =>{
        this.setState({
            open: false,
            errors:{},
            validation_error:null,
            pay_type: null,
            effective_date:null,
            txt_salary: 0,
            isCompensationEdit : false,
        });
    }
    onCloseNote = () =>{
        this.setState({
            openNote: false,
            errors:{}
        });
    }
    handleDateChange = (date, name) => {
        if (name == "effective_date") {
            this.setState({
                effective_date: date,
            });
        }
        else
        {
            this.setState({
                end_date: date,
            });
        }        
    };
    onUpdate = (components, data, index) => {
       let msg = '';
       let postData = {
            'id': data[index].id,
            'amount': data[index].pref_amount,
            'salary_id': data[index].staff_salary_id,
            'staff_id': this.props.StaffID
        }
        return httpServices.post('api/compensation/saveComponent', postData)
            .then(response => {
                if (response.data) {
                    if (!response.data.errors) {
                        let sdata = response.data.component;
                        let idx = components.findIndex(x => x.id === data[index].staff_salary_id);
                        components[idx]['components'][index] = sdata;
                        this.setState({ components: components, result: true });
                        //TODO Add New salary component
                    } else {
                        msg = 'Invalid entry at amount field'
                        this.setState({ result: false, dialogOpen: true });
                    }
                    this.setState({
                        amountMessage: msg
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
        }
    onAddComponentNote = (compen_component_id)=>{
        return httpServices.post('api/compensation/getCompensationComponentNote', {component_note_id:compen_component_id})
            .then(response => {
                if (response.data.permission) {
                    this.setState({openNote: true,
                        note: response.data.note,
                        compen_component_id: response.data.compen_component_id
                    });
                }
                else {
                    alert("You are not authorized to do this action");
                }
            })
    }
    onAddComponent = (salaryID,salaryComponents) => {
        let sal_components = [];
        if (salaryComponents) {
            if (salaryComponents.length > 0) {
                sal_components = salaryComponents;
            }
        }
        this.setState({
            open: true,
            addComponent: true,
            salaryID: salaryID,
            component_id: null,
            configComponents : sal_components
        });
    }

    onSaveComponent = () => {
        var components = (this.state.components.length>0)?this.state.components:(this.props.CompensationData.salary_details?this.props.CompensationData.salary_details:[]);
        this.setState({components: components});
        const data = {
            'staff_id': this.props.StaffID,
            'salary_id': this.state.salaryID,
            'component_id': this.state.component_id,
        }
        if (this.props.StaffID) {
            return httpServices.post('api/compensation/addNewComponent', data)
                .then(response => {
                    if (response.data.status) {
                        let idx = components.findIndex(x => x.id === this.state.salaryID);
                        var s = update(this.state.components, {
                            [idx]: {
                                'components': {
                                    $push: [response.data]
                                }
                            }
                        });
                        this.setState({'components': s, open: false});
                    }
                    else
                    {
                       var errors ={};
                       errors['duplicate_entry'] = response.data.error_msg;
                       this.setState({errors: errors});                        
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    salaryUpdateClick = (evt,salary_id) => {
        evt.stopPropagation();
        if (salary_id) {
            return httpServices.post('api/compensation/getSalary', {id : salary_id})
                .then(response => {
                    if (response.data.salary) {
                        let salary = response.data.salary;
                        this.setState({
                            txt_salary : salary.amount,
                            effective_date : salary.start_date,
                            pay_type : salary.pay_frequency,
                            end_date : salary.end_date,
                            isCompensationEdit : true,
                            open : true,
                            editCompensationId : salary_id,
                        });        
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };
    SalaryDeleteClick = (evt,salary_id) => {
        evt.stopPropagation();
        if (salary_id) {
            return httpServices.post('api/compensation/deleteSalary', {id : salary_id,'staff_id': this.props.StaffID})
                .then(response => {
                    if (response.data.error) {
                        this.setState({
                            snackbar : true,
                            variant : 'error',
                            message : response.data.message,
                            components: response.data.salary_components,
                        });
                    }
                    else{
                        this.setState({
                            snackbar : true,
                            variant : 'success',
                             message : response.data.message,
                             components: response.data.salary_components,

                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };
    render(){
        let Edit, Delete;
        if (typeof this.props.CompensationData != 'undefined') {
            Edit = this.props.CompensationData.edit_permission
            Delete = this.props.CompensationData.delete_permission
        }  
        return(
            (this.props.CompensationData.permission)
            ?
            <CompensationComponent
                HandleDateChange = {this.handleDateChange} 
                HandleOnChange={this.handleOnChange}
                GenerateSalary = {this.onSubmit}
                Components={this.state.components}
                AddNewSalary={this.onAddSalary}
                Open={this.state.open}
		 OpenNote ={this.state.openNote}
                OnCloseNote ={this.onCloseNote}
		 note={this.state.note}
		 Compen_component_id= {this.state.compen_component_id}
                Close={this.onClose.bind(this)}
                HandleUpdate={this.onUpdate.bind(this)}
                Errors={this.state.errors}
		 AddComponentNote={this.onAddComponentNote}
                AddComponent={this.onAddComponent}
                AddComponentFlag={this.state.addComponent}
                SaveComponent={this.onSaveComponent}
                PayTypes={this.props.CompensationData && this.props.CompensationData.pay_types}
                SalaryDetails={ (  this.state.components && this.state.components.length>0) ? this.state.components: (this.props.CompensationData && this.props.CompensationData.salary_details)}
                SelPayType={this.state.pay_type}
                ConfigComponents={this.state.configComponents.length>0 ? this.state.configComponents : (this.props.CompensationData && this.props.CompensationData.config_components)}
                SelComponent={this.state.component_id}
                ScheduledComponents={this.state.scheduledComponents.length >0 ? this.state.scheduledComponents: (this.props.CompensationData && this.props.CompensationData.scheduled_components)}
                ComponentAdjustments={this.state.componentAdjustments.length >0 ? this.state.componentAdjustments: (this.props.CompensationData && this.props.CompensationData.component_adjustments)}
                StaffID={this.props.StaffID}
                BankAccountType={this.props.CompensationData.account_type}
                Edit={Edit}
                Delete={Delete}
                ValidationError={this.state.validation_error}
                BankInfo={this.props.CompensationData && this.props.CompensationData.bank_info? this.props.CompensationData.bank_info:[]}
                BonusInfo={this.props.CompensationData && this.props.CompensationData.referral_bonus? this.props.CompensationData.referral_bonus:[]}
                Data={this.props.CompensationData.fed_tax_details}
                StateTaxData = {this.props.CompensationData.state_tax_details}
                AllStates = {this.props.CompensationData.all_states}
                MaritalStatuses = {this.props.CompensationData.marital_statuses}
                FederalFilingStatuses = {this.props.CompensationData.federal_filing_status}
                Users = {this.props.CompensationData.users}
                SalaryUpdateClick = {this.SalaryUpdateClick}
                SalaryDeleteClick = {this.SalaryDeleteClick}
                EffectiveDate = {this.state.effective_date}
                TxtSalary = {this.state.txt_salary}
                EndDate = {this.state.end_date}
                IsCompensationEdit = {this.state.isCompensationEdit}
                ViewAdjustments = {this.props.CompensationData.view_adjustments}
                Variant = {this.state.variant}
                Message = {this.state.message}
                Snackbar = {this.state.snackbar}
                AmountMessage = {this.state.amountMessage}
                Result = {this.state.result}
                SnackbarClose = {this.snackbarClose}
            />
            :
            <ErrorPage/>
        )
    }
}
