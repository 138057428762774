import React from 'react';
import { GroupsComponent} from './Groups.Component';
import { httpServices } from '../../../../services/httpServices';
import { CustomProgressBar } from '../../../common/CustomProgressBar';

export class GroupsContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            title : 'Group',
            changeLog  : [],
            groups : [],
            formData:[],
            snackbarOpen : false,
            snackbarVarient : '',
            snackbarMsg : '',
            showLoader : true,
            dataLoaded : false,
            buttonLoader : false,
            type : '',
            id : 0,
            groupId:0,
            errors : {},
            editPermission : false
        }
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
    }

    componentDidMount(){
        this.listUserGroups(this.props.UserId);
    }
    listUserGroups = (UserId) => {
        let apiEndpoint = 'api/user/list-user-groups';
        return httpServices.post(apiEndpoint,{user_id : UserId})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            groups : response.data.groups,
                            showLoader : false,
                            permission : response.data.permission,
                            groupList : response.data.groupList,
                            editPermission : response.data.edit_permission
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleClickOpen = (id) => {
        this.setState({open:true, type:'add'});
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formData;
        var val = evt.target.value;
        ff[field] = val;
        this.setState({
            formData: ff
        });
    };
    
    onClose = () => {
        this.setState({
            open : false,
            formData : [],
            errors : {}
        });
    }
    onDelete = (id) => {
        this.setState({
            groupId:id,
            open:true,
            type:'delete'
        });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };
    
    onDeleteSubmit = () => {
        const userID = this.props.UserId;
        this.setState({buttonLoader:true});
        let apiEndpoint = 'api/user/delete-group-members';
        return httpServices.post(apiEndpoint, {
            'users': [userID],'id' :this.state.groupId
        })
            .then(response => {
                this.setState({
                    open:false,
                    buttonLoader : false,
                    snackbarOpen : true,
                    snackbarVarient : 'success',
                    snackbarMsg : 'Group deleted successfully',
                });
                this.listUserGroups(this.props.UserId);
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }
    onSubmit = ()  => {
        const userID = this.props.UserId;
        let apiEndpoint = 'api/user/add-group-member';
        if (this.state.formData.group) {
            this.setState({buttonLoader:true});
            return httpServices.post(apiEndpoint, {'id': this.state.formData.group , user : userID} )
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            buttonLoader:false,
                            open:false,
                            snackbarOpen : true,
                            snackbarVarient : 'success',
                            snackbarMsg : 'Group added successfully',
                            formData : [],
                            errors : {}
                        });
                        this.listUserGroups(this.props.UserId);
                    } else {
                        this.setState({ errors: response.data.errors });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        } else
        {
            let errors =[];
            errors.group = 'Cannot be empty';
            this.setState({errors:errors});
        }
    };

    render(){
        return (
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                <GroupsComponent 
                    Groups = {this.state.groups}
                    HandleClickOpen = {this.handleClickOpen}
                    OnClose = {this.onClose}
                    Open={this.state.open}
                    GroupList = {this.state.groupList}
                    FormData = {this.state.formData}
                    OnDelete = {this.onDelete}
                    onDeleteSubmit = {this.onDeleteSubmit}
                    onSubmit = {this.onSubmit}
                    SnackbarOpen={this.state.snackbarOpen}
                    SnackbarVarient={this.state.snackbarVarient}
                    SnackbarMsg={this.state.snackbarMsg}
                    SnackbarClose={this.snackbarClose}
                    ButtonLoader={this.state.buttonLoader}
                    HandleOnChange = {this.handleOnChange}
                    Type = { this.state.type }
                    Errors = {this.state.errors}
                    EditPermission = {this.state.editPermission}
            />
            </>
        )
    }
}
