import React from 'react';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import {StaffDetailsReportComponent} from './StaffDetailsReportComponent';
import * as validations from '../../common/validate';

function getSelectedColumnValues(sNodes, resArr) {
    if (sNodes) {
        sNodes.forEach(function(node){
            if (node.value) {
                resArr.push(node.value);
            }
            if (node.children) {
                node.child = node.children;
            }
            if (node.child) {
                getSelectedColumnValues(node.child, resArr);
            }
        })
    } 
    return resArr;
}
let allColumns = [];
const formValues = {
    'staff_type':'0',
    'staff_status':'0'
}
export class StaffDetailsReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            hasPermission : true,
            formInputs: [],
            selectedColumns: '', 
            formValues: formValues,
            errors: {}
        }
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.exportReports = this.exportReports.bind(this);
    }
    componentDidMount() {
        httpServices.get('api/report/staff-details-report')
            .then(response => {
                if (response.data.permission){
                    let formInputs = this.state.formInputs;
                    formInputs.staffStatues = response.data.staff_statuses;
                    formInputs.staffTypes = response.data.staff_types;
                    formInputs.reportColumns = response.data.report_columns;
                    let selectedColumns = [];
                    if (response.data.report_columns) {
                        selectedColumns = getSelectedColumnValues(response.data.report_columns,[]);
                        allColumns = selectedColumns;
                        selectedColumns = JSON.stringify(selectedColumns);
                    }
                    this.setState ({
                        hasPermission : response.data.permission,
                        selectedColumns: selectedColumns,
                    });
                } else {
                    this.setState ({
                        hasPermission: false,
                    });
                }
            });
        window.scrollTo(0, 0);
    }
    componentWillUnmount () { 
        formValues['staff_type'] = '0';
        formValues['staff_status'] = '0';
    }
    handleTreeChange = (evt, cNode, sNodes, action) => {
        let selectedColumns = getSelectedColumnValues(sNodes,[]);
        let jsonStr = "";
        if (selectedColumns) {
            jsonStr = JSON.stringify(selectedColumns);
        } 
        /*else {
            jsonStr = JSON.stringify(allColumns);
        }*/
        this.setState({
            selectedColumns: jsonStr,
        });
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formValues;
        ff[field] = evt.target.value;
        this.setState({
            formValues: ff
        });
    }
    exportReports = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById('export-form').submit();
        }
    };

    validateRequest() {
        let validationData = [
            { name: 'staff_status', value: this.state.formValues.staff_status, type: 'alphanumeric', otherOptions: { required: false } },
            { name: 'staff_type', value: this.state.formValues.staff_type, type: 'alphanumeric', otherOptions: { required: false } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        this.setState({ errors: validateErrors });
        return isValid;
    }

    render(){
       return(
           (this.state.hasPermission)
            ?
            <StaffDetailsReportComponent 
                FormInputs = {this.state.formInputs}
                HandleTreeChange = {this.handleTreeChange}
                SelectedColumns = {this.state.selectedColumns}
                HandleOnChange= {this.handleOnChange}
                FormValues={this.state.formValues}
                Errors = {this.state.errors}
                ExportReports = {this.exportReports}
            />
           :<ErrorPage/>
       )
    }
}

