import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const API_URL = process.env.REACT_APP_API_URL;
export const BonusReportComponent = (props) =>{
     const gridStyle = {
        margin: '15px 0px'
    };
        return(
            <React.Fragment>
            <Paper md={12}>
            <div class="p1">
            <Typography variant="h6" component="h6" className={'section-title'}>Ascella Recommended Salary and Proposed Bonus Report</Typography>
                <form id="export-form" className="form-fullwidth" action={API_URL+'api/report/export-consolidated-salary-bonus-report'} method="post">
                    <Grid container spacing={3} style={gridStyle}>               
                        <Grid item  md={2} className='justify-between'>
                            <FormControl>
                                <TextField variant="standard"
                                    value = {props.ReportFields.on_date}
                                    id="on_date"
                                    name="on_date"
                                    label="Data as on Paycheck"
                                    type="date"
                                    InputProps={{inputProps: { min: "2020-01-01", max: props.ReportFields.max_date } }}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={props.Errors.on_date && true}
                                    helperText={props.Errors.on_date}
                                    onChange={props.HandleOnChange}
                                />
                            </FormControl>
                        </Grid>              
                     

                    
                        <Grid item >
                            <Button variant="contained" size="small" color="primary" onClick={props.ExportReports} name="export" disabled={props.DisableButton?true:false}>Export</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>    
            </Paper>


            </React.Fragment>


            )
    
}

