import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatName } from '../../../common/common';

import * as _R from 'ramda';  // used 0.04kb

const ListSubordinateStyle = styled(React.Fragment)(({ theme }) => ({}));

export const SubordinateList = (props) => {
    const { Subordinates } = props;
    return (
        <ListSubordinateStyle>
        <div className={'listSubHeader'}>
            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Subordinates</Typography>
        </div>
            { _R.isEmpty(Subordinates) ? <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography> :
           
           <div className={'section-content'}>
                <Table className={'table'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { typeof Subordinates != 'undefined' && Subordinates.map((d, id) =>
                        <TableRow key={id}>
                            <TableCell>{formatName(d.first_name,null,d.last_name)}</TableCell>
                            <TableCell>{d.user_status === 1 ? 'Active': 'Inactive'}</TableCell>
                        </TableRow>
                        )}
                        { _R.isEmpty(Subordinates) &&
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                </TableCell>
                            </TableRow>
                        }   
                    </TableBody>
                </Table>
            </div>
            }
        </ListSubordinateStyle>
    );
};
