import React from 'react';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import {DialogBoxMedium} from '../../../common/dialog.medium';

const DialogComponent = (p) => {
    const {FormProps:props} = p;
        return (
            <form className="form-fullwidth">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="family_type" required  error={props.Errors.family_type && true}>Relation</InputLabel>
                            <Select
                                value={props.FormFields.family_type}
                                onChange={props.HandleOnChange}
                                error={props.Errors.family_type && true}
                                inputProps={{
                                    name: 'family_type',
                                    id: 'family_type',
                                }}>
                                {typeof(props.FamilyTypeOpts) !== 'undefined' && props.FamilyTypeOpts.map((t, i) =>
                                    <MenuItem value={t.id} key={'c-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText className='error'>{props.Errors.family_type}</FormHelperText>
                        </ FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                required
                                value={props.FormFields.name}
                                onChange={props.HandleOnChange}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl error={props.Errors.gender && true} variant="standard">
                            <InputLabel htmlFor="gender" required>Gender</InputLabel>
                            <Select
                                value={(typeof props.FormFields.gender !== 'undefined') ? props.FormFields.gender : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'gender',
                                    id: 'gender',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof props.StaffGenderOpts != 'undefined' && props.StaffGenderOpts.map((g, i) =>
                                    <MenuItem value={g.id} key='gender-{g.id}'>{g.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{props.Errors.gender}</FormHelperText>
                        </ FormControl>
                    </Grid>
                   <Grid item xs={12} md={6}>
                        <FormControl error={props.Errors.idcard_type && true} variant="standard">
                            <InputLabel htmlFor="idcard_type" required>ID Proof Type</InputLabel>
                            <Select
                                value={(typeof props.FormFields.idcard_type !== 'undefined') ? props.FormFields.idcard_type : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'idcard_type',
                                    id: 'idcard_type',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof props.IdCardTypes != 'undefined' && props.IdCardTypes.map((idt, i) =>
                                    <MenuItem value={idt.id} key={'idtypes-'+i}>{idt.title}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{props.Errors.idcard_type}</FormHelperText>
                        </ FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="idcard_value"
                                name="idcard_value"
                                label="ID Proof Number"
                                type="text"
                                required
                                value={(typeof props.FormFields.idcard_value !== 'undefined') ? props.FormFields.idcard_value : null}
                                onChange={props.HandleOnChange}
                                error={props.Errors.idcard_value && true}
                                helperText={props.Errors.idcard_value}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="dob"
                                name="dob"
                                label="DOB"
                                type="date"
                                value={typeof props.FormFields!= 'undefined' && props.FormFields.dob}
                                onChange={props.HandleOnChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={props.Errors.dob && true}
                                helperText={props.Errors.dob}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="sort_order"
                                name="sort_order"
                                label="Order"
                                type="text"
                                value={props.FormFields.sort_order}
                                onChange={props.HandleOnChange}
                                error={props.Errors.sort_order && true}
                                helperText={props.Errors.sort_order}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
        )
};

export const FamilyComponent = (props) => {
    const { Edit } = props;
    let table_size = 10; // Default
    if (props.Family) {
        let data_arr_length = props.Family.length ;
        if (data_arr_length < 10) {
            table_size = data_arr_length < 3 ? 2 : data_arr_length;
        }
    }

    return (
        <React.Fragment>
            <div className={'mtable-basic'}>
                <CustomMaterialTable
                    title="Family Details"
                    columns={[
                        { title: 'Name', field: 'name'},
                        { title: 'Gender', field: 'gender_title'},
                        { title: 'ID Proof Type', field:'idcard_title'},
                        { title: 'ID Proof Number', field:'idcard_value'},
                        { title: 'DOB', field: 'dob'},
                        { title: 'Relation', field: 'relation' },
                    ]}
                    data={props.Family}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize:table_size,
                        pageSizeOptions: [10,20,30],
                        search: false,
                        thirdSortClick: false
                    }}
                    actions={[
                        {   
                            icon: 'add',
                            tooltip: 'Add Family Details',
                            isFreeAction: true,
                            onClick: (event, newData) => { 
                                props.AddComponent();
                            },
                            hidden: (Edit) ? false : true,
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                props.HandleUpdate(rowData.id);
                            },
                            hidden: (Edit) ? false : true,
                        }
                    ]}
                    editable={{
                        onRowDelete: rowData =>
                            new Promise((resolve, reject) => {
                            setTimeout(() => {
                            {
                                const index = props.Family.indexOf(rowData);
                                props.OnDelete(index, rowData.id);
                            }
                            resolve()
                            }, 500)
                        }),
                    }}
                />
            </div>
            <DialogBoxMedium
                    Open={props.Open}
                    Cancel={props.Close}
                    Title= {props.IsDelete ?  'Delete Family Details' : (props.IsEdit ? 'Edit Family Details' : 'Add Family Details')}
                    OnSubmit={props.SaveFamily}
                    Content={<DialogComponent FormProps={props}/>}/>
        </React.Fragment>
    );
}

