import React from 'react';
import {TimeoffLogReport} from './TimeoffLogReport'
import {httpServices}  from '../../services/httpServices'
import { ErrorPage } from '../common/error';
import * as validations from '../common/validate';
const API_URL = process.env.REACT_APP_API_URL;
const FilterFields = {
    'user_id' : 0,
    'year' : 0,
    'name_format' : 'FIRSTLAST',
    'start_date' : '',
    'end_date' : '',
    'date_joined' : '',
    'relieved_date' : '',
    'staff_status' : 1,
    'staff_name_format' : '',
    'staff_list' : [],
    'staff_statuses' : [],
    'staff_type' : [],
}
const ReportTable = {
    'header' : '',
    'rows' : ''
}
const filters = {
}
export class TimeoffLogReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : true,
            data : [],
            open: false,
            FilterFields: FilterFields,
            filters: filters,
            errors: {},
            loaderOpen: false,
            type:'',
            timeoffData : [],
            ReportTable : ReportTable,
            dialogOpen : false,
            popupType : '',
            hasPermission : true,
            open: false,
            staffDetails:{},
            permission:false,
            timeoffTypes:{},
            years:{},
            showLoader : true,
            dataLoaded : false,
            selectedType : Array(),
            selectedId : '',
        }
        this.exportReports = this.exportReports.bind(this);
    }
    handleFilterChange = (evt, value) => {
        let start_year = '';
        let end_year = '';
        let f = this.state.FilterFields;
        let filters = this.state.filters;
        let name = evt.target.name;
        let val = evt.target.value;
        f[name] = val;
        for (var i=0; i < filters['staff_list'].length; i++) {
            if(filters['staff_list'][i].user_id == val) {
                start_year = filters['staff_list'][i].date_joined;
                end_year = filters['staff_list'][i].relieved_date;
                break;
            }
        }
        this.setState({
            FilterFields: f,
            filters: filters,
        });
        if (name === 'staff_status') {
            FilterFields['start_date'] = '';
            FilterFields['end_date'] = '';
            FilterFields['user_id'] = 0;
            this.getStaffByStatus(val,FilterFields.start_date,FilterFields.end_date,FilterFields.start_date,FilterFields.end_date,0);
        }
        if (name === 'user_id') {
            this.getYearsByUser(start_year,end_year);
        }
    }
    getYearsByUser = (start_date, end_date) => {
        let end_year = '';
        var list = [];
        const d = new Date();
        let current_year = d.getFullYear();
        if (end_date !== null) {
            var [relieved_year] = end_date.split('-');
            if (current_year >= Number(relieved_year)) {
                end_year = Number(relieved_year);
            }
            else {
                end_year = current_year + 2;
            }
        }
        else {
            end_year = current_year + 2;
        }
        var [start_year] = start_date.split('-');
        for (var i = Number(start_year); i <= end_year; i++) {
            if(i !== 0) {
                list.push(i);
            }
        }
        let FilterFields = this.state.FilterFields;
        FilterFields.year = 0;
        let filters = this.state.filters;
        filters.year = list;           
        this.setState({
            filters : filters,
            FilterFields : FilterFields
        });
    }
    exportReports = () => {
        let valid = this.handleValidation();
        if (valid) {
            document.getElementById('export').value = true;
            document.getElementById('time-off').submit();
        }
    }
    handleValidation() {
        let formIsValid = true;
        let validationData = [
            { name: 'user_id', value: this.state.FilterFields.user_id, type: 'integer', otherOptions: { required: true } },
            { name: 'year', value: this.state.FilterFields.year, type: 'integer', otherOptions: { required: true } },
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        if (isValid) {
            let errors = {};
            this.setState({ errors: errors });
        } else {
            formIsValid = false;
            this.setState({ errors: validateErrors });
        }
        return formIsValid;
    }
    componentDidMount = () => {
        this.setState({
            loaderOpen:true
        });
        let FilterFields = this.state.FilterFields;
        return httpServices.get('api/report/project-code-details')
            .then(response => {
                if (response.data.permission) {
                    FilterFields.start_date=response.data.start_date;
                    FilterFields.end_date=response.data.end_date;
                    FilterFields.name_format=response.data.name_format;
                    FilterFields.staff_statuses=response.data.employee_statuses;
                    FilterFields.staff_type=response.data.staff_type;
                    FilterFields.date_joined=response.data.date_joined;
                    FilterFields.relieved_date=response.data.relieved_date;
                    this.setState({
                        
                        FilterFields : FilterFields,
                        permission: response.data.permission,
                        loaderOpen: false                                               
                    });
                    this.getStaffByStatus(FilterFields.staff_status,FilterFields.start_date,FilterFields.end_date,0);
                }
                else {
                    this.setState({
                        permission: response.data.permission,
                        loaderOpen: false
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getStaffByStatus(status_id,start_date,end_date,staff_type){
        httpServices.post('api/report/getStaffList',{ 'status_id' : status_id,'start_date' : start_date,'end_date' : end_date,'staff_type' : staff_type })
            .then(response => {
                if (response.data) {
                    this.setState({
                        filters : {'staff_list' : response.data}
                    }); 
                }
            })
    }
    validateFilter = () => {
        let valid = this.validate();
        let f = this.state.FilterFields;
        this.setState({FilterFields: f},
        );
    }
    validate = () => {
        let formIsValid = true;
        let errors = {};
       
        if (!this.state.FilterFields.user_id) {
            errors["user_id"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.FilterFields.year) {
            errors["year"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    OnSubmitClick = (action) => () =>{
        let start_date = this.state.FilterFields.start_date;
        let year = this.state.FilterFields.year;
        let end_date = this.state.FilterFields.end_date;
        let staff_status = this.state.FilterFields.staff_statuses;
        let user_id = this.state.FilterFields.user_id;
        let staff_type = this.state.FilterFields.staff_type;
        let staff_name_format = this.state.FilterFields.name_format;
        let data = {staff_type : staff_type, staff_name_format : staff_name_format, start_date : start_date, end_date : end_date, staff_status : staff_status,user_id : user_id, year : year }
        if(this.handleValidation()){
            httpServices.post('api/report/timeoffConsolidatedlog', data)
                .then(response => {
                    if (response.data.permission) {
                        let inputs = response.data;
                        this.setState({
                            permission : true,
                            staffDetails : inputs.staff_details,
                            experience : inputs.experience,
                            timeOffListDetails : inputs.timeoff_details,
                            holidayListDetails : inputs.holidays_datas,
                            headersList : inputs.headers,
                            years : inputs.years,
                            showLoader : false,
                            dataLoaded : true,
                        });
                    } else if (response.data.errors) {
                        this.setState({
                            errors: response.data.errors
                        })
                    }
                 else {
                    this.setState({
                        hasPermission : false,
                    });
                 }
            }); 
        }
    }
   
    onClose = () => {
        this.setState({
            dialogOpen : false,
        });
    }
    render(){
       return(
           (this.state.hasPermission)
            ?
           <TimeoffLogReport
                HandleFilterChange = {this.handleFilterChange.bind(this)}
                ValidateFilter = {this.validateFilter.bind(this)}
                Data = {this.state.data}
                FilterFields = {this.state.FilterFields}
                Filters = {this.state.filters}
                Type = {this.state.type}
                TimeoffData  = {this.state.timeoffData}
                OnClose={this.onClose.bind(this)}
                Errors = { this.state.errors }
                ReportTable = { this.state.ReportTable}
                OnSubmitClick = { this.OnSubmitClick.bind(this) }
                ExportReports = {this.exportReports}
                staffDetails = {this.state.staffDetails}
                experience = {this.state.experience}
                TimeOffListDetails = {this.state.timeOffListDetails}
                HolidayListDetails = {this.state.holidayListDetails}
                HeadersList = {this.state.headersList}
                years = {this.state.years}
            />
           :<ErrorPage/>
       )
    }
}
