import React from 'react';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {DialogBoxMedium} from '../../common/dialog.medium';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CustomizedSnackbars } from '../../common/snackbars';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';


const gridStyle = {
    margin: '15px 0px'
};
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            className="tab-panel"
            hidden={value !== index}
            {...other}
        >
            {children}
        </Typography>
    );
}
export const InstanceDetailsComponent = (props) => {
    const [tabValue, setTabValue] = React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <>
            <Paper md={12}>
                <div class="p1">
                    <div className={'section-main'}>
                        <div className={'listSubHeader'}>
                            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">{props.DestinationInstance} Instance </Typography>
                        </div>
                        <div className={'tab-navContainer d-flex justify-between steps-tab'}>
                            <Tabs
                                value={tabValue}
                                onChange={tabChange}
                                indicatorColor="primary"
                                aria-label="Instance sync Tabs"
                            >
                                {props.StaffView && <Tab label="Users" />}
                                {props.LocationView && <Tab label="Location" />}

                            </Tabs>
                        </div>
                        {props.StaffView &&
                            <TabPanel value={tabValue} index={0}>
                                <form className="form-fullwidth" method="post">
                                    {props.StaffEdit &&
                                    <Grid container spacing={3} style={gridStyle}>
                                        <Grid item xs={12} sm={6} md={3} lg={2} className='justify-between'>
                                            <FormControl variant="standard">
                                                <InputLabel htmlFor="group">Source User</InputLabel>
                                                <Select
                                                    value={props.FormFields.src_user}
                                                    onChange={props.HandleOnChange}
                                                    inputProps={{
                                                        name: 'src_user',
                                                        id: 'src_user',
                                                    }}>
                                                    <MenuItem value='0'>-Select user-</MenuItem>
                                                    {typeof props.SourceUserList != 'undefined' && props.SourceUserList !== null && props.SourceUserList.map((t, i) =>
                                                        <MenuItem value={t.user_id} key={'st-' + i}>{t.full_name}</MenuItem>
                                                    )}
                                                </Select>
                                                {props.Errors.src_user && <FormHelperText className='error' >{props.Errors.src_user}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={2} className='justify-between'>
                                            <FormControl variant="standard">
                                                <InputLabel htmlFor="group">Destination User</InputLabel>
                                                <Select
                                                    value={props.FormFields.dest_user}
                                                    onChange={props.HandleOnChange}
                                                    inputProps={{
                                                        name: 'dest_user',
                                                        id: 'dest_user',
                                                    }}>
                                                    <MenuItem value='0'>-Select user-</MenuItem>
                                                    {typeof props.DestinationUserList != 'undefined' && props.DestinationUserList !== null && props.DestinationUserList.map((t, i) =>
                                                        <MenuItem value={t.user_id} key={'st-' + i}>{t.user_fullname}</MenuItem>
                                                    )}
                                                </Select>
                                                {props.Errors.dest_user && <FormHelperText className='error' >{props.Errors.dest_user}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" size="small" color="primary" onClick={() => { props.HandleSubmit('user') }}> Add </Button>
                                        </Grid>
                                    </Grid>
                                    }
                                    <React.Fragment>
                                        <div className={'mtable-basic'}>
                                            <CustomMaterialTable
                                                title=" "
                                                columns={[
                                                    {
                                                        title: 'Source User',
                                                        field: 'src_full_name',
                                                    },
                                                    {
                                                        title: 'Destination User',
                                                        field: 'dest_full_name',
                                                    },
                                                ]}
                                                data={props.UserMapped}
                                                options={{
                                                    search: false,
                                                    actionsColumnIndex: -1,
                                                    toolbar: true,
                                                    pageSizeOptions: [10, 20, 30],
                                                    rowStyle: rowData => ({
                                                        color: (typeof rowData.isDataChanged !== 'undefined' && rowData.isDataChanged) ? '#DC143C' : '',
                                                    })
                                                }}
                                                actions={props.StaffEdit ? [
                                                    {
                                                        icon: 'delete',
                                                        tooltip: 'Delete',
                                                        onClick: (event, rowData) => {
                                                            props.OnDeleteStaff(rowData['id'], rowData['src_full_name'], 'staff');
                                                        },
                                                    }

                                                ]: []}

                                                detailPanel={[
                                                    {
                                                        render: ({ rowData }) => {
                                                            return (
                                                        <div className={'project-task-detail-panel'}>
                                                            {props.StaffEdit &&
                                                            <Grid container spacing={3} style={gridStyle}>
                                                                <Grid item xs={6} md={3} className='justify-between'>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel htmlFor="group">Source Task </InputLabel>
                                                                        <Select
                                                                            value={props.FormFields.src_task}
                                                                            onChange={props.HandleOnChange}
                                                                            inputProps={{
                                                                                name: 'src_task',
                                                                                id: 'src_task',
                                                                            }}>
                                                                            <MenuItem value='0'>-Select task-</MenuItem>
                                                                            {typeof rowData.src_task_list != 'undefined' && rowData.src_task_list !== null && rowData.src_task_list.map((t, i) =>
                                                                                <MenuItem value={t.task_id} key={'st-' + i} disabled={t.task_status_name != 'Active'}>{t.task_path} ({t.task_status_name})</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                        {props.Errors.src_task && <FormHelperText className='error' >{props.Errors.src_task}</FormHelperText>}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} md={3} className='justify-between'>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel htmlFor="group">Destination Task</InputLabel>
                                                                        <Select
                                                                            value={props.FormFields.dest_task}
                                                                            onChange={props.HandleOnChange}
                                                                            inputProps={{
                                                                                name: 'dest_task',
                                                                                id: 'dest_task',
                                                                            }}>
                                                                            <MenuItem value='0'>-Select task-</MenuItem>
                                                                            {typeof rowData.dest_task_list != 'undefined' && rowData.dest_task_list !== null && rowData.dest_task_list.map((t, i) =>
                                                                                <MenuItem value={t.task_id} key={'st-' + i} disabled={t.task_status_name != 'Active'}>{t.task_path} ({t.task_status_name})</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                        {props.Errors.dest_task && <FormHelperText className='error' >{props.Errors.dest_task}</FormHelperText>}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} md={3} className='justify-between'>
                                                                <FormControl variant="standard">
                                                                    <InputLabel htmlFor="status">Status</InputLabel>
                                                                    <Select
                                                                        defaultValue={1}
                                                                        value={props.FormFields.status}
                                                                        onChange={props.HandleOnChange}
                                                                        inputProps={{
                                                                            name: 'status',
                                                                            id: 'status',
                                                                        }}>                                                                       
                                                                        <MenuItem value={1}>Active</MenuItem>
                                                                        <MenuItem value={0}>Inactive</MenuItem>
                                                                    </Select>
                                                                    {props.Errors.status && <FormHelperText className='error'>{props.Errors.status}</FormHelperText>}
                                                                </FormControl>
                                                            </Grid>
                                                                <Grid item>
                                                                    <Button variant="contained" size="small" color="primary" onClick={() => { props.HandleSubmit('task', rowData.id) }}> Add </Button>
                                                                </Grid>
                                                            </Grid>
                                                            }
                                                            {(typeof rowData.synced_task_list !== 'undefined' && rowData.synced_task_list.length > 0) &&
                                                                <div className={'section-content roles-table'}>
                                                                    <Table size="small" >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Source Task </TableCell>
                                                                                <TableCell>Destination Task </TableCell>
                                                                                <TableCell> Status </TableCell>
                                                                                {props.StaffEdit &&
                                                                                    <TableCell>  </TableCell>
                                                                                }
                                                                                {props.StaffTaskDelete &&
                                                                                    <TableCell>  </TableCell>
                                                                                }
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {rowData.synced_task_list.map((ct, i) =>
                                                                                <TableRow>
                                                                                    <TableCell>{ct.src_task_path} ({ct.src_task_status_name})</TableCell>
                                                                                    <TableCell>{ct.dest_task_path} ({ct.dest_task_status_name})</TableCell>
                                                                                    <TableCell>{ct.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                                                                                    {props.StaffEdit &&
                                                                                    <TableCell>
                                                                                        <Tooltip title="Edit" aria-label="edit">
                                                                                            <IconButton className={'action-btn'} aria-label="Edit" >
                                                                                                <EditIcon fontSize="small" onClick={() => { props.HandleEditClick(ct.id, ct.user_map_id, ct.status, ct.src_task_id, ct.dest_task_id, ct.src_task_path, ct.dest_task_path) }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </TableCell>
                                                                                    }               
                                                                                    {props.StaffTaskDelete   &&
                                                                                    <TableCell>                                      
                                                                                    <Tooltip title="Delete" aria-label="delete">
                                                                                        <IconButton className={'action-btn'} aria-label="Delete" >
                                                                                            <DeleteIcon fontSize="small" onClick={() => { props.Delete(ct.id, 'task') }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    </TableCell>
                                                                                    }
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }}]}
                                            />
                                        </div>
                                    </React.Fragment>
                                </form>
                            </TabPanel>
                        }
                        {props.LocationView &&
                            <TabPanel value={tabValue} index={props.StaffView ? 1 : 0}>
                                <form className="form-fullwidth" method="post">
                                    {props.LocationEdit &&
                                    <Grid container spacing={3} style={gridStyle}>
                                        <Grid item xs={12} sm={6} md={3} lg={2} className='justify-between'>
                                            <FormControl variant="standard">
                                                <InputLabel htmlFor="source_location">Source Location</InputLabel>
                                                <Select
                                                value={props.FormFields.src_location}
                                                    onChange={props.HandleOnChange}
                                                    inputProps={{
                                                        name: 'src_location',
                                                        id: 'src_location',
                                                    }}>
                                                    <MenuItem value='0'>-Select Location-</MenuItem>
                                                {typeof props.SourceLocationList != 'undefined' && props.SourceLocationList !== null && props.SourceLocationList.map((t, i) =>
                                                        <MenuItem value={t.id} key={'st-' + i}>{t.location}</MenuItem>
                                                    )}
                                                </Select>
                                            {props.Errors.src_location && <FormHelperText className='error' >{props.Errors.src_location}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={2} className='justify-between'>
                                            <FormControl variant="standard">
                                                <InputLabel htmlFor="group">Destination Location</InputLabel>
                                                <Select
                                                    value={props.FormFields.dest_location}
                                                    onChange={props.HandleOnChange}
                                                    inputProps={{
                                                        name: 'dest_location',
                                                        id: 'dest_location',
                                                    }}>
                                                    <MenuItem value='0'>-Select Location-</MenuItem>
                                                {typeof props.DestinationLocationList != 'undefined' && props.DestinationLocationList !== null && props.DestinationLocationList.map((t, i) =>
                                                        <MenuItem value={t.id} key={'st-' + i}>{t.location}</MenuItem>
                                                    )}
                                                </Select>
                                                {props.Errors.dest_location && <FormHelperText className='error' >{props.Errors.dest_location}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" size="small" color="primary" onClick={() => { props.HandleSubmit('location') }}> Add </Button>
                                        </Grid>
                                    </Grid>
                                    }
                                </form>
                            <div className={'mtable-basic'}>
                                <CustomMaterialTable
                                    title=" "
                                    columns={[
                                        {
                                            title: 'Source Location',
                                            field: 'source_location_name',
                                        },
                                        {
                                            title: 'Destination Location',
                                            field: 'destination_location_name',
                                        },

                                    ]}
                                    data={props.LocationMapped}
                                    options={{
                                        search: false,
                                        actionsColumnIndex: -1,
                                        toolbar: true,
                                        pageSizeOptions: [10, 20, 30],
                                        rowStyle: rowData => ({
                                            color: (typeof rowData.isDataChanged !== 'undefined' && rowData.isDataChanged) ? '#DC143C' : '',
                                        })
                                    }}
                                    actions = { props.LocationEdit ? [
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => {
                                                props.OnDeleteLocation(rowData['id'], rowData['source_location_name'], 'location');
                                            },
                                        }
                                    ]:
                                    []
                                }
                                />
                            </div>
                            </TabPanel>
                        }                        
                    </div>
                </div>
            </Paper>
            {props.SnackbarOpen &&
                <CustomizedSnackbars
                    open={props.SnackbarOpen}
                    variant={props.SnackbarVariant}
                    message={props.SnackbarMsg}
                    handleClose={props.SnackbarClose}
                />
            }
            {props.Open &&
                <DialogBoxMedium
                    Open = {props.Open}
                    Cancel = {props.Close}
                    Title = { "Edit Task"}
                    OnSubmit={() => props.OnSave(props.EditId, props.EditStatus)}
                    Content = {<DialogContent FormProps={props}/>}
                />
            }
            {props.StaffDelete &&
                <DialogBoxMedium
                    Open = {props.StaffDelete}
                    Cancel = {props.Close}
                    Title = { "Delete User"}
                    OnSubmit={() => props.Delete()}
                    ButtonName={'Delete'}
                    Content={"Do you want to delete the user mapping for " +(props.SrcFullName) +" ?"}
                />
            }
            {props.LocationDelete &&
                <DialogBoxMedium
                    Open = {props.LocationDelete}
                    Cancel = {props.Close}
                    Title = { "Delete Location"}
                    OnSubmit={() => props.Delete()}
                    ButtonName={'Delete'}
                    Content={"Do you want to delete the location mapping for " +(props.SourceLocationName) +" ?"}
                />
            }
        </>
    )
}
const DialogContent = (p) => {
    const {FormProps:props} = p;
    return (
        <form className="form-fullwidth">
                <Grid item xs={12} md={12} className='justify-between'>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="group">Source Task </InputLabel>
                        <Select
                            value={props.FormFields.editSrcTask}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'editSrcTask',
                                id: 'editSrcTask',
                            }}>
                            <MenuItem value='0'>-Select task-</MenuItem>
                            {typeof props.EditSrcTaskList != 'undefined' && props.EditSrcTaskList !== null && props.EditSrcTaskList.map((t, i) =>
                                <MenuItem value={t.task_id} key={'st-' + i} disabled={t.task_status_name != 'Active'}>{t.task_path} ({t.task_status_name})</MenuItem>
                            )}
                        </Select>
                        {/* {props.Errors.src_task && <FormHelperText className='error' >{props.Errors.src_task}</FormHelperText>} */}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} className='justify-between'>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="group">Destination Task </InputLabel>
                        <Select
                            value={props.FormFields.editDestTask}
                            onChange={props.HandleOnChange}
                            inputProps={{
                                name: 'editDestTask',
                                id: 'editDestTask',
                            }}>
                            <MenuItem value='0'>-Select task-</MenuItem>
                            {typeof props.EditDestTaskList != 'undefined' && props.EditDestTaskList !== null && props.EditDestTaskList.map((t, i) =>
                                <MenuItem value={t.task_id} key={'st-' + i} disabled={t.task_status_name != 'Active'}>{t.task_path} ({t.task_status_name})</MenuItem>
                            )}
                        </Select>
                        {/* {props.Errors.src_task && <FormHelperText className='error' >{props.Errors.src_task}</FormHelperText>} */}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                <FormControl variant="standard">
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                    value={props.EditStatus}onChange={props.HandleStatusChange}
                    inputProps={{
                        name: 'status',
                        id: 'status',
                    }}>
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                    </FormControl>
                    </Grid>
        </form>
    )
}