/*
 * Local storage related functions
 *
 */

/*
 * Set localStorage item for populating user assigned client, project, tasks
 * In Timesheet module
 */
/*
 * Timesheet specific js global object
 */
import {httpServices}  from '../../services/httpServices'
import * as _R from 'ramda';
var timesheetObj = {
    instance_id:null,
    setInstanceId: function(instance_id) {
        this.instance_id = instance_id;
    },
    getInstanceId: function() {
        return this.instance_id;
    },
}

export const is_ls_enabled = () => {
    if (!window.localStorage) {
        return false;
    }
    return true;
};

export const ls_get_item_chargecodes = () => {
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.user_chargecodes';
    let ls_chargecodes = localStorage.getItem(keyName);
    let data = JSON.parse(ls_chargecodes);
    return data;
}

export const ls_set_item_chargecodes = (value) => {
    let instanceID = timesheetObj.getInstanceId();
    let key = instanceID+'.user_chargecodes';
    localStorage.setItem(key, JSON.stringify(value));
}
export const  ls_set_instance_id= (instanceID) => {
    timesheetObj.setInstanceId(instanceID);
}
export const ls_init_user_chargecodes = (url, instanceID, reload, date) => {
    timesheetObj.setInstanceId(instanceID);   
    //ls_clear_usertasks();
    if (ls_get_item_chargecodes()===null || reload) {
        (async () => {
            const response = await httpServices.post(url, {});
            if (response.data) {
                let dataObj = response.data;
                ls_set_item_chargecodes(dataObj);
                return true;
            }
        })()
        return true;
    }
    return true;
}

const ls_set_valid_projects = (pID) => {
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.valid_projects';
    let validProjects = JSON.parse(localStorage.getItem(keyName));
    if (validProjects == null) {
        validProjects = [];
    }
    if (!validProjects.some(t => t == pID)) {
        validProjects.push(pID);
    }
    localStorage.setItem(keyName, JSON.stringify(validProjects));
};
export const ls_get_valid_project = () =>{
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.valid_projects';
    let validProjects = localStorage.getItem(keyName);
    let data = JSON.parse(validProjects);
    return data;
};

export const ls_get_projects = (clientID) => {
    let chargeCodes =  ls_get_item_chargecodes();
    let allProjects  = chargeCodes.projects;
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.valid_projects';
    let validProjects = JSON.parse(localStorage.getItem(keyName));
    let projects = [];
    allProjects.forEach(function(value, key){
        if (validProjects && validProjects.some(t => t == value.project_id)) {
            projects.push(value);
        }
    });
    /*if (_R.type(clientID) !== 'Undefined') {
        if (_R.type(allProjects[clientID]) !== 'Undefined') {
            projects = allProjects[clientID];
        }
        return projects;
    }*/
    return projects;
}

export const ls_get_tasks = (projectID, date) => {
    var tasks = [];
    var chargeCodes =  ls_get_item_chargecodes();
    if (chargeCodes) {
    var allTasks  = chargeCodes.tasks;
    var dString = null;
    let temp_date = date.split('-');
    //let dateStr = new Date(date);
    let dateStr = new Date(temp_date[0], temp_date[1]-1, temp_date[2], 0, 0, 0, 0 );
    if (_R.type(allTasks[projectID]) !== 'Undefined') {
        let temp = allTasks[projectID];
        temp.forEach(function(value, key){
            let validProjects = ls_get_valid_project();
            if (validProjects && validProjects.some(t => t == value.task_project)) {
                dString = value.task_sdate.split('-');
                let taskSdate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
                let taskEdate;
                if(value.task_edate!=null) {
                    dString = value.task_edate.split('-');
                    taskEdate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
                }
                else {
                    taskEdate = new Date();
                }
                if(dateStr>=taskSdate && dateStr<=taskEdate) {
                    tasks.push(value);
                }
            }
        });
    }
    }
    return tasks;
}

export const ls_clear = () => {
    if (window.localStorage) {
        localStorage.clear();
    }
}

/*
 * Validate project start date and end date
 */
export const ls_validate_project = (obj, date) => {
    let subProjValidation = false;
    let dString = obj.start_date.split('-');
    let selDateStr = date.split('-');
    let dateStr = new Date(selDateStr[0], selDateStr[1]-1, selDateStr[2], 0, 0, 0, 0);
    let sDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
    let eDate;
    if (obj.project_edate===null) { // Project end date
        if (obj.end_date===null) {  // Resource end date
            eDate = new Date();
        }
        else {
            dString = obj.end_date.split('-');
            eDate = new Date(dString[0], dString[1]-1, dString[2]);
        }
    } else {
        if (obj.end_date) {
            let temp_proj_edate = new Date(obj.project_edate);
            let temp_resourse_edate = new Date(obj.end_date);
            // setting parent project end date into child data.
	    let child = obj.children;
            child.forEach(function(child_value, child_key){
               child[child_key].project_edate = obj.project_edate;
            })
	    if (temp_resourse_edate <= temp_proj_edate) {
                subProjValidation = true;
                dString = obj.end_date.split('-');
                eDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
            } else {
                dString = obj.project_edate.split('-');
                eDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
            }
        }
        else {
            dString = obj.project_edate.split('-');
            eDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
        }
    }
    if (parseInt(obj.project_status) == 1) { // Client side project status validation : 1 - Active
        if(dateStr>=sDate && dateStr<=eDate) {
            ls_set_valid_projects(obj.project_id);
            return true;
        }
        else {
            if (subProjValidation && obj.children){
                let f = ls_validate_subproject(obj.children, dateStr);
                if (f){
                    return true;
                }
            }
            ls_remove_valid_projects(obj.project_id);
        }
    } else {
        ls_remove_valid_projects(obj.project_id);
    }
}

export const ls_set_time_entries = (data, userID) => {
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.unsaved_timeentries_'+userID;
    localStorage.setItem(keyName, JSON.stringify(data));
}

export const ls_get_time_entries = (userID) => {
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.unsaved_timeentries_'+userID;
    let timeEntries = localStorage.getItem(keyName);
    let data = JSON.parse(timeEntries);
    return data;
}

const ls_validate_subproject = (dataObj, date) => {
    let flag = false;
    let dateStr = new Date(date);
    dataObj.forEach(function(child, key){
        if (child.start_date) {
            let dString = child.start_date.split('-');
            var sDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
            let eDate;
            if (child.end_date===null) {
                if (child.project_edate===null) {
                    eDate = new Date();
                }
                else {
                    dString = child.project_edate.split('-');
                    eDate = new Date(dString[0], dString[1]-1, dString[2]);
                }
            }
            else {
                dString = child.end_date.split('-');
                eDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
            }
            if (dateStr>=sDate && dateStr<=eDate) {
                flag = true;
            }
        }
    });
    return flag;
}

export const ls_get_tags = (tagIDs) => {
    let chargeCodes =  ls_get_item_chargecodes();
    let allTags  = chargeCodes.tags;
    let tags = [];
    if (_R.type(tagIDs)!=='Undefined'){
        if (tagIDs.indexOf(',') > -1) {
            let tagTypes = tagIDs.split(',');
            tags = allTags.reduce(function (r, a) {
                if (tagTypes.some(t => t == a.tag_type)) {
                    r[a.tag_type_name] = r[a.tag_type_name] || [];
                    r[a.tag_type_name].push(a);
                }
                return r;
            }, Object.create(null));
        }
        else {
            tags = allTags.reduce(function (r, a) {
                if (a.tag_type == tagIDs) {
                    r[a.tag_type_name] = r[a.tag_type_name] || [];
                    r[a.tag_type_name].push(a);
                }
                return r;
            }, Object.create(null));
        }
    }
    return tags;
}

//TODO: Refactoring
const ls_addProjectOptions1 = (dataObj, date, p, pp) => {
    if (!dataObj['task_id']) {
        if (ls_validate_project(dataObj, date)) {
            if (dataObj.project_parent == 0) {
                let tempDataObj = JSON.parse(JSON.stringify(dataObj)); 
                p.push(tempDataObj);
            }
            if (dataObj.children instanceof Object) {
                if (dataObj.children.length) {
                    dataObj.children.forEach(function(obj, idx){
                            ls_addProjectOptions1(obj, date, p);
                    });
                }
            }
        }
        else {
            p.forEach(function(val,idx){
                let new_obj = removeFromTree(val, dataObj.id);
                p[idx] = new_obj;
            });
        }
    }
    else {
        if (!ls_validate_task(dataObj, date)) {
            p.forEach(function(val,idx){
                let new_obj = removeTaskFromTree(val, dataObj.id);
                p[idx] = new_obj;
            });
        }
    }
}

const removeFromTree = (root, idToDelete, parent, idx) => {
    if (root.id == idToDelete) {
        if (parent) {
            parent.children.splice(idx, 1);
        }
        else return null;
    }

    if (_R.type(root.children) !== 'Undefined' && root.children.length && _R.type(root.children.entries()) !== 'Undefined') {
        for (const [i, e] of root.children.entries()) {
            removeFromTree(e, idToDelete, root, i);
        }
    }
    return root;
};

export const validateProjects = (date,action_from = '',projects=[]) =>{
    if (action_from === 'edit') {
        let p = [];
        let allProjects  = projects;
        Object.values(allProjects).forEach(function(obj, idx){
            ls_addProjectOptions1(obj, date, p, allProjects);
        });
        return makeOptGroups(p);
    } else {
        let chargeCodes =  ls_get_item_chargecodes();
        let p = [];
        //let allProjects  = (lsFirst)?lsProjects:chargeCodes.projects;
        if (chargeCodes) {
            let allProjects  = chargeCodes.projects;
            Object.values(allProjects).forEach(function(obj, idx){
                ls_addProjectOptions1(obj, date, p, allProjects);
            });
        }
        return makeOptGroups(p);
        //return p;
    }
}

export const makeOptGroups = (options) => {
    let dataArr = [];
    options.sort((a, b) => a.client_code.localeCompare(b.client_code));
    if (options.length) {
        let temp_client_name = '';
        let temp_id = 999; // Need unique id for each node of the material tree
        Object.values(options).forEach(function(obj, idx){
            if (temp_client_name !== obj.client_name){
                temp_id += 1; 
                let t = {};
                t['id'] = temp_id;
                t['label'] = obj.client_name;
                t['className'] = 'no-radio';
                //t['project_id'] = false;
                //t['disabled'] = true;
                t['client_id'] = obj.project_client;
                t['project_id'] = 0;
                t['expanded'] = true;
                t['children'] =[];
                t['children'].push(obj);
                dataArr.push(t);
                temp_client_name = obj.client_name;
            } else {
                let project_client = obj.project_client;
                let index = dataArr.findIndex(dd => dd.client_id == project_client);
                dataArr[index]['children'].push(obj);
            }
            //dataArr.push(obj);
        });
    }
    return dataArr;
}
const ls_remove_valid_projects = (pid) => {
    let instanceID = timesheetObj.getInstanceId();
    let keyName = instanceID+'.valid_projects';
    let validProjects = JSON.parse(localStorage.getItem(keyName));
    if (validProjects == null) {
        validProjects = [];
    }
    if (validProjects.some(t => t == pid)) {
        let index = validProjects.indexOf(pid);
        validProjects.splice(index, 1);
    }
    localStorage.setItem(keyName, JSON.stringify(validProjects));
}
export const makeOptGroupsForReport = (options) => {
    let dataArr = [];
    options.sort((a, b) => a.client_name.localeCompare(b.client_name));
    if (options.length) {
        let temp_client_name = '';
        Object.values(options).forEach(function(obj, idx){
            if (temp_client_name !== obj.client_name){
                let t = {};
                t['label'] = obj.client_name;
                t['className'] = 'no-radio';
                t['project_id'] = false;
                t['disabled'] = true;
                dataArr.push(t);
                temp_client_name = obj.client_name;
            } 
            dataArr.push(obj);
        });
    }
    return dataArr;
}
const ls_validate_task = (dataObj, date) => {
    let flag = false;
    let dateArray = date.split('-');
    let dateStr = new Date(dateArray[0], dateArray[1]-1, dateArray[2], 0 , 0, 0, 0);
    let dString = dataObj.start_date.split('-');
    var sDate = new Date(dString[0], dString[1]-1, dString[2]);
    let eDate;
    if (dataObj.end_date===null) {
        eDate = new Date();
    }
    else {
        dString = dataObj.end_date.split('-');
        eDate = new Date(dString[0], dString[1]-1, dString[2], 0, 0, 0, 0);
    }
    if (dateStr>=sDate && dateStr<=eDate) {
        flag = true;
    }
    return flag;
}
const removeTaskFromTree = (root, idToDelete, parent, idx) => {
    if (root.task_id == idToDelete) {
        return null;
    }
    if (_R.type(root.children) !== 'Undefined' && root.children.length && _R.type(root.children.entries()) !== 'Undefined') {
        for (const [i, e] of root.children.entries()) {
            removeFromTree(e, idToDelete, root, i);
        }
    }
    return root;
};

