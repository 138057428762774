import React from 'react';
import Paper from '@mui/material/Paper';
import { TimeOffTypesContainer }  from './TimeOffTypes.Container';

export const TimeOffSettingsComponent = (props) => {
    
    return (
        <React.Fragment>
            <div className={'section-main'}>
                <div className={'section-body'}>
                    <Paper>
                        <div>
                                    <TimeOffTypesContainer />
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>
    )
};