import React from 'react';
import { connect } from "react-redux";
import { setSelectedTabIndex } from '../../../redux/store/reducers/tabs';
import {OvertimeComponent} from './Overtime.Component';
import {httpServices}  from '../../../services/httpServices'
import { ViewOvertimeDetails } from './ViewOvertimeDetails.Container';
import { history } from './../../../helpers/history';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import MixpanelManager from  '../../../services/Analytics';
import { getRelativeUrl } from '../../common/common';
import { setSavedFilters, updateSavedFilterAction } from '../../../redux/store/reducers/savedFilters';


const formFields = {
    'my': {
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 0,
    } ,
    'review': {
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 1,
        'employeeName' : 0,
    } ,
    'all':{
        'startDate' : null,
        'endDate' : null,
        'requestStatus' : 0,
        'employeeName' : 0,
        'employeeStatus' : 1,
    }
}
class OvertimeContainer extends React.Component {
    constructor(props) {
        super(props);
        const overtimeTabSelected = this.props.selectedOvertimeTab;
        let filter = formFields[overtimeTabSelected];
        this.state ={
            formFields : filter,
            requests : {},
            reviewRequestsCount : 0,
            open: false,
            tabName:overtimeTabSelected,
            editId :null,
            userType:true,
            viewId : null,
            viewClick : false,
            filterViewState : false,
            requestType :'Overtime',
            requestStatus :[],
            listViewPermission : false,
            notEligibleMessage : null,
            recipients :{},
            approvers :{},
            custom_recipients:{},
            logged_user:null,
            allRequestView: false,
            snackbarOpen : false,
            snackbarMsg : '',
            doj: null,
            relievedDate: null,
            requestView: false,
            showLoader: true,
            dataLoaded: false,
            periodStartDate: new Date (),
            periodEndDate: new Date (),
            errors :{},
            saveFilter : false,
            counter : 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.filterView = this.filterView.bind(this);
        this.filterSubmit = this.filterSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.updateParent = this.updateParent.bind(this);
        this.updateParentOnAddSuccess = this.updateParentOnAddSuccess.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
        this.saveFilterValues = this.saveFilterValues.bind(this);
        this.saveFilterToStore = this.saveFilterToStore.bind(this);
    };
    componentDidUpdate = (prevProps) => { 
        // Fetching overtime details based on the redux store values after store values are set initially
        if (prevProps.savedFilterValues.length > 0 && this.state.counter === 0) {
            this.getIndex(this.state.tabName);
            this.setState({
                counter: 1
            });
        }
    }
    updateParentOnAddSuccess = (successFlag) => {
        this.setState({open : successFlag,
                        editId : null,
                        });
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        if (name === 'saveFilter') {
            this.setState({
                saveFilter: value
            });
        }
        else {
            f[name] = evt.target.value;
            this.setState({
                formFields: f
            }, function () {
                this.getEmployees();
            });
        }
    }
    handleChange = (event, value) => {
        let tab = '';
        switch (value) {
            case 0:
                tab = 'my';
                break;
            case 1:
                tab = 'review';
                break;
            case 2:
                tab = 'all';
                break;
            default:
                tab = 'my';
        }
        let obj = { page: "overtime", selected: tab }
        this.props.setTabIndexValue(obj);
        this.setState({ tabName: tab, });
    }
 
    handleStartDateChange = (date) => {
        const f = this.state.formFields;
        const field = 'startDate';
        f[field] = date;
        this.setState({
            formFields: f
        });
        let reg = /^\d{4}-\d{2}-\d{2}$/;
        if (reg.test(this.state.formFields.startDate)) {
            this.getEmployees();
        }
    };
    handleEndDateChange = (date) => {
        const f = this.state.formFields;
        const field = 'endDate';
        f[field] = date;
        this.setState({
            formFields: f
        });
        let reg = /^\d{4}-\d{2}-\d{2}$/;
        if (reg.test(this.state.formFields.endDate)) {
            this.getEmployees();
        }
    };
    getEmployees = (tabValue) => {
        let employeeStatus = this.state.formFields.employeeStatus;
        let tab = this.state.tabName;
        let startDate = this.state.formFields.startDate;
        let endDate = this.state.formFields.endDate;
          return httpServices.post('api/overtime/listovertimeusers', {tab, employeeStatus, startDate, endDate})
            .then(response => {
                if (response.data) {
                   this.setState({
                    employeeList : response.data,
                });
                }
            })
    };
    saveFilterValues = (tab) => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl+'#'+tab;
            let apiEndpoint = 'api/user/save-filter';
            let data = {
                relative_url : relativeUrl,
                filter_options : this.state.formFields,
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    saveFilterToStore = (tab) => {
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '' && this.state.formFields) {
            relativeUrl = relativeUrl + '#' + tab;
            let obj = {relative_url : relativeUrl, filter_options : this.state.formFields}
            this.props.updateSavedFilterVal({obj});
        }
    }    
    filterSubmit = (tab) => {
        if (this.handleValidation()) {
            this.saveFilterToStore(tab);
            if (this.state.saveFilter) {
                this.saveFilterValues(tab);
            }
            this.setState({
                filterViewState: false,
            }, function () {
                this.getIndex(tab);
            });

        }
    }

    componentDidMount = () => {
        var fromDashboard = (this.props.location.state && this.props.location.state.fromDashboard) ? this.props.location.state.fromDashboard : false;        
        if (fromDashboard) {
            var dashboardFilter = (this.props.location.state && this.props.location.state.dashboardFilter) ? this.props.location.state.dashboardFilter : [];
            let startDate = (dashboardFilter.overtimeRequestsStartDate && dashboardFilter.overtimeRequestsStartDate != '') ? dashboardFilter.overtimeRequestsStartDate : null;
            let endDate = (dashboardFilter.overtimeRequestsEndDate && dashboardFilter.overtimeRequestsEndDate != '') ? dashboardFilter.overtimeRequestsEndDate : null; 
            let formFields = this.state.formFields;
            formFields['startDate'] = startDate;
            formFields['endDate'] = endDate;
            formFields['requestStatus'] = 1;
            formFields['employeeName'] = 0;
            this.setState({
                formFields: formFields
            });
        }
        let tab = this.state.tabName;
        this.props.setSavedFilterVal();
        this.getIndex(tab);
    };
    
    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open,
            errors : {},      
        });
    };
    
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            formFields: ff,
        });
    };

    getIndex = (tabValue) => {
        if (this.state.tabName !== tabValue) {
            this.setState({
                saveFilter: false,
            });
        }
        let filter = formFields[tabValue];
        let savedFilterValues = this.props.savedFilterValues;
        let relativeUrl = getRelativeUrl();
        if (relativeUrl !== '') {
            relativeUrl = relativeUrl + '#' + tabValue;
            let indexValue = savedFilterValues.findIndex((element) => element.relative_url === relativeUrl);
            if (indexValue !== -1) {
                let savedFilter = savedFilterValues[indexValue]['filter_options'];
                filter = Object.assign(filter, savedFilter);
            }
        }
        let status = filter.requestStatus;
        let data = {
            'tab' : tabValue ? tabValue : this.state.tabName,
            'employeeStatus' : filter.employeeStatus,
            'requestStatus' : status,
            'startDate' : filter.startDate,
            'endDate' : filter.endDate,
            'employeeName' : filter.employeeName,
        };
        let mixPanelAction = '';
        if (tabValue === 'my') {
            mixPanelAction = 'my-requests-tab-clicked';
        } else if (tabValue === 'review') {
            mixPanelAction = 'review-requests-tab-clicked';
        } else if (tabValue === 'all') {
            mixPanelAction = 'all-requests-tab-clicked';
        }
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'overtime', 'action' : mixPanelAction});
        this.setState({showLoader : true});
        return httpServices.post('api/overtime/list',data)
            .then(response => {
                if (response.data.permission) {
                    this.setState({overtime : response.data.overtime,
                        requests : response.data.requests,
                        reviewRequestsCount : response.data.reviewRequestsCount,
                        userType : response.data.userType,
                        requestStatus : response.data.requestStatus,
                        employeeList : response.data.overtime_eligible_users,
                        listViewPermission : response.data.permission,
                        employeeStatus : response.data.employeeStatus,
                        notEligibleMessage : response.data.notEligible,
                        recipients : response.data.recipients,
                        approvers : response.data.approvers,
                        custom_recipients : response.data.custom_recipients, 
                        logged_user : response.data.logged_user,
                        allRequestView : response.data.allrequest_view,
                        doj : response.data.doj,
                        relievedDate: response.data.relieved_date,
                        tabName: response.data.tabName,
                        requestView : response.data.request_view,
                        showLoader : false,
                        dataLoaded : true,
                    });                    
                    let overtimetab = this.state.tabName;
                    let filter = formFields[overtimetab];
                    let filterFields = filter;
                    if (response.data.payperiod_startDate && !filterFields.startDate) {
                        filterFields.startDate = response.data.payperiod_startDate;
                        filterFields.endDate = response.data.payperiod_endDate;
                    }
                    filterFields.requestStatus = status;
                    let startDateArr = filterFields.startDate.split('-');
                    let startDateObj = new Date(startDateArr[0], startDateArr[1]-1, startDateArr[2]);
                    let endDateArr = filterFields.endDate.split('-');
                    let endDateObj = new Date(endDateArr[0], endDateArr[1]-1, endDateArr[2]);
                    if (response.data.requests) {
                        if (data['tab'] === 'my') {
                            this.setState({
                                formFields : filterFields,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                            });
                        } else if (data['tab'] === 'all') {
                            this.setState({
                                formFields : filterFields,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                            });
                        }
                        else if (data['tab'] === 'review') {
                            this.setState({
                                formFields : filterFields,
                                periodStartDate :startDateObj,
                                periodEndDate :endDateObj,
                            });
                        }
                    }
                    if(data.tab !== this.state.tabName) {
                        this.filterSubmit(this.state.tabName);
                    }
                }
                else {
                    this.setState({showLoader : false,
                        dataLoaded : true});
                }
            })
    }
    handleOnClick = () => {
        this.setState({open:true});
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'overtime', 'action' : 'create-overtime-request-clicked'});
    }
    handleEditClick = (id) => {
        if(id) {
            this.setState({
                editId :id,
                open:true 
            });
        }
    }
    handleViewClick = (id) => {
        if(id) {
            this.setState({viewId :id, viewClick : true, });
            history.push('overtime/view/' + id);
        }
    }
    updateParent = (valid, attachmentMsg = '') => {
        this.setState({open:false});
        if(valid) {
            let EditId = this.state.editId;
            let msg ='';
            if(EditId) {
                msg = 'Request updated successfully';
                EditId = 0; 
            }
            else {
                msg = 'Request submitted successfully';
            }
            if (attachmentMsg != '') {
                msg = msg+". "+ attachmentMsg;
            }
            this.getIndex(this.state.tabName);
            this.setState({
                snackbarOpen : true,
                snackbarMsg : msg,
                editId : EditId,
            });
        }
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    resetFilter = (tab) => {
        let ff = this.state.formFields; 
        ff['startDate'] = null;
        ff['endDate'] = null;
        ff['requestStatus'] = tab === 'review' ? 1:0;
        ff['employeeStatus'] = tab === 'all' ? 1:0;
        ff['employeeName'] = '0' ;
        ff['userId'] = '0';
        ff['task'] = '0';
        this.setState({
            formFields: ff,
            right: false
        },()=>{
            this.filterSubmit(tab);}
        );
    }


    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (this.state.formFields.startDate > this.state.formFields.endDate) {
            errors["endDate"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    render(){
        return(
            (this.state.viewClick) ?
             <ViewOvertimeDetails
                ViewId = {this.state.viewId}
                Custom_recipients = {this.state.custom_recipients}
                UpdateParent  = {this.updateParent}
                UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
            />
            :
            <>
                <CustomProgressBar loaderOpen={this.state.showLoader}/>
                    {(this.state.dataLoaded) ?
                        <>
                        {(this.state.listViewPermission) ?
                        <OvertimeComponent
                            HandleChange={this.handleChange}
                            Requests = {this.state.requests}
                            GetIndex = {this.getIndex.bind(this)}
                            HandleOnClick = {this.handleOnClick}
                            Open = {this.state.open}
                            TabName = {this.state.tabName}
                            EditId = {this.state.editId}
                            HandleEditClick = {this.handleEditClick}
                            ReviewRequestsCount = {this.state.reviewRequestsCount}
                            UserType = {this.state.userType}
                            HandleViewClick = {this.handleViewClick}
                            FilterView = {this.filterView}
                            FilterViewState = {this.state.filterViewState}
                            RequestType = {this.state.requestType}
                            RequestStatus = {this.state.requestStatus}
                            FilterSubmit = {this.filterSubmit}
                            FormFields = {this.state.formFields}
                            HandleOnChange ={this.handleOnChange}
                            EmployeeList = {this.state.employeeList}
                            ListViewPermission = {this.state.listViewPermission}
                            EmployeeStatus = {this.state.employeeStatus}
                            NotEligibleMessage = {this.state.notEligibleMessage}
                            ClearForm ={this.clearForm.bind(this)}
                            Recipients ={this.state.recipients}
                            Approvers = {this.state.approvers}
                            Custom_recipients = {this.state.custom_recipients}
                            UpdateParent  = {this.updateParent}
                            Logged_user = {this.state.logged_user}
                            UpdateParentOnAddSuccess = {this.updateParentOnAddSuccess}
                            AllRequestView = {this.state.allRequestView}
                            SnackbarOpen = {this.state.snackbarOpen}
                            SnackbarMsg = {this.state.snackbarMsg}
                            SnackbarClose = {this.snackbarClose}
                            Doj = {this.state.doj}
                            RelievedDate= {this.state.relievedDate}
                            RequestView = {this.state.requestView}
                            handleStartDateChange={this.handleStartDateChange}
                            handleEndDateChange={this.handleEndDateChange}
                            PeriodStartDate = {this.state.periodStartDate}
                            PeriodEndDate = {this.state.periodEndDate}
                            ResetFilter = {this.resetFilter.bind(this)}
                            errors = {this.state.errors}
                            SaveFilter = {this.state.saveFilter}
                        />
                        : <ErrorPage/>}
                        </>
                    :<></>}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const savedFilterValues =  state.savedFilters.savedFilterValues ? state.savedFilters.savedFilterValues : {};
    const selectedOvertimeTab = state.Tabs.allTabs['overtime'] ? state.Tabs.allTabs['overtime'] : 'my';
    return {
        savedFilterValues: savedFilterValues,
        selectedOvertimeTab: selectedOvertimeTab
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setSavedFilterVal: () => dispatch(setSavedFilters()),
        setTabIndexValue: (obj) => dispatch(setSelectedTabIndex(obj)),
        updateSavedFilterVal: ({obj}) => dispatch(updateSavedFilterAction({obj}))
    }
}
const connectedOvertimeContainer = connect(mapStateToProps, mapDispatchToProps)(OvertimeContainer);
export { connectedOvertimeContainer as OvertimeContainer };
