import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as _R from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomDatePicker } from '../../common/CustomDatePicker';
import { formatAndSortNames } from '../../common/common';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';

const styles = theme => ({
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    }
});
export const LocationFilterComponent = (props) => {
    const format = {format : props.NameFormat};
    const columnsToBeFormated = {first_name: 'user_first_name', middle_name: 'user_middle_name', last_name: 'user_last_name'};
    let employee = (typeof props.props.Employee !== 'undefined' && props.props.Employee !== null) ? props.props.Employee : [];
    if (employee && employee.length) {
        employee = formatAndSortNames(employee, [format, columnsToBeFormated], 'ASC');
    }
    let projects = (typeof props.props.Projects !== 'undefined') ? props.props.Projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((typeof props.props.FormFields.project_id !== 'undefined') ? [props.props.FormFields.project_id] : []));
    }
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Location</InputLabel>
                        <Select
                            inputProps={{
                                name: 'location',
                                id: 'location',
                            }}
                            value = {(typeof props.props.FormFields.location !== 'undefined') ? props.props.FormFields.location : null}
                            onChange = {props.HandleOnChange}
                        >   
                            <MenuItem value="0">All</MenuItem>
                            {typeof(props.props.FormFields.locations) !== 'undefined' && (props.props.FormFields.locations != null)  && props.props.FormFields.locations.map((loc, key) =>
                                <MenuItem value={loc.id} >{loc.location}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl>
                        <CustomDatePicker
                            id="startDate"
                            name="start_date"
                            selectedDate={props.props.FormFields.start_date}
                            handleDateChange={props.handleStartDateChange}
                            label={'Start Date'}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl>
                        <CustomDatePicker
                            id="endDate"
                            name="end_date"
                            selectedDate={props.props.FormFields.end_date}
                            handleDateChange={props.handleEndDateChange}
                            label={'End Date'}
                        />
                        <span class="error">{props.props.Errors.endDate}</span>
                    </ FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl variant = 'standard'>
            <InputLabel htmlFor="age-simple">Status</InputLabel>
            <Select
                inputProps={{
                    name: 'requestStatus',
                    id: 'request_status',
                }}
               value = {(typeof props.props.FormFields.requestStatus !== 'undefined') ? props.props.FormFields.requestStatus  : null}
               onChange = {props.HandleOnChange}
            >
                <MenuItem value="0">All</MenuItem>
                {typeof(props.props.RequestStatus) !== 'undefined' && (props.props.RequestStatus != null)  && props.props.RequestStatus.map((t, i) =>
                    <MenuItem value={t.request_status_id} key={'c-'+i}>{t.request_status_name}</MenuItem>
                )}
            </Select>
        </FormControl>
        </Grid>
        </Grid>
         {props.props.Tab === 'all' &&
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'emp_status',
                            id: 'emp_status',
                        }}
                        value = {!_R.isNil(props.props.FormFields.emp_status) && props.props.FormFields.emp_status}
                        onChange = {props.HandleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.props.EmployeeStatus) !== 'undefined' && props.props.EmployeeStatus.map((n, i) =>
                            <MenuItem value={n.user_status_id} key={'n-'+i}>{n.user_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
         {props.props.Tab !== 'my' &&
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="age-simple">Staff Name</InputLabel>
                        <Select
                            inputProps={{
                            name: 'user_id',
                            id: 'user_id',
                        }}
                        value = {!_R.isNil(props.props.FormFields.user_id) && props.props.FormFields.user_id}
                        onChange = {props.HandleOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {employee.map((n, i) =>
                            <MenuItem value={n.user_id} key={'n-'+i}>{n.formatted_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl>
                            <TreeSelectComponent
                                dropdown_mode={'true'}
                                data={projects}
                                placeholderText={"Projects"}
                                type='project'
                                HandleTreeChange={props.HandleTreeChange}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
        <div className={'d-flex justify-end mb-1'}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={(typeof props.saveFilter !== 'undefined') ? props.saveFilter : false}
                        value={(typeof props.saveFilter !== 'undefined') ? props.saveFilter : false}
                        id="saveFilter"
                        name="saveFilter"
                        onChange={props.HandleOnChange}
                        color={'primary'}
                    />
                }
                label="Save Filter"
            />
        </div>
        <Button variant = "contained" color = "primary" className = {'filterSubmitButton'} onClick = {props.handleOnSubmit}> Submit </Button>
        <Button variant = "outlined" color = "primary"  className = {'ml-1 filterSubmitButton'} onClick = {()=> props.ResetFilter(props.Tab)} > Reset</Button>
        </form>
    )
}
