import React from 'react';
import { LabelsComponent } from './Labels.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const formFields = {
    'id': 0,
    'name': null,
    'tags': []
};

export class LabelsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: false,
            editPermission : false,
            projectLabels: [],
            formFields: formFields,
            open: false,
            errors: {},
            snackbar: false,
            id: 0,
            showLoader: true,
            dataLoaded: false,
            buttonLoader: false,
            deleteId: 0,
            message: null,
	    tags:[],
	    filterInputs :[]
        };
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        this.getProjectLabels(this.props.ProjectId);
    }   

    snackbarClose = () => {
        this.setState({
            snackbar: false,
        });
    }

    getProjectLabels = (projectId) => {
        let apiEndpoint = 'api/project/listProjectLabels';
        return httpServices.post(apiEndpoint, { 'project_id': projectId })
            .then(response => {
                if (response.data.permission) {
		    let filterInputs = this.state.filterInputs;
                    filterInputs.all_labels = response.data.all_labels;
                    this.setState({
                        projectLabels: response.data.labels,
                        showLoader: false,
                        permission: response.data.permission,
                        editPermission : response.data.edit_permission,
                        dataLoaded: true,
			filterInputs : filterInputs
                    });
                }
                else {
                    this.setState({
                        showLoader: false,
                        dataLoaded: true,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    onAdd = () => {
        let fields = this.state.formFields;
        this.setState({
            open: true,
            formFields: fields,
            id: 0,
        });
    };

    onClose = () => {
        this.setState({
            open: false,
            errors: {},
            deleteId: 0,
            showLoader: false,
            dataLoaded: true,
        });
        this.clearForm();
    };

    clearForm = () => {
        let ff = this.state.formFields;
        for (let key in ff) {
            if (key === 'id') {
                ff[key] = 0;
            }
            else {
                ff[key] = null;
            }
        }
        this.setState({
            formFields: ff
        });
    };

    handleOnChange = (evt, value) => {
	let ff = this.state.formFields;
	ff['tags'] = value;
        this.setState({
            formFields: ff
            });
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        this.setState({ errors: errors });
        return formIsValid;
    }

    confirmDelete = (id) => {
        this.setState({ deleteId: id });
    }

    handleSubmit = () => {
        this.setState({ buttonLoader: true });
        if (this.handleValidation()) {
            let project_id = this.props.ProjectId;
            let data = this.state.formFields;
            data.project_id = project_id;
	    data.tags_value = JSON.stringify(this.state.formFields.tags);
            if (data) {
                let apiEndpoint = 'api/project/saveProjectLabel';
                return httpServices.post(apiEndpoint, data)
                    .then(response => {
                        if (typeof response.data.valid === 'undefined') {
                            if (response.data.permission) {
                                this.setState({
                                    open: false,
                                    snackbar: true,
                                    variant: 'success',
                                    message: "Project label added successfully",
                                    buttonLoader: false,
                                    permission: response.data.permission,
                                    id: 0,
                                });
                                this.clearForm();
                                this.getProjectLabels(this.props.ProjectId);
                            }
                            else {
                                this.setState({
                                    buttonLoader: false
                                });
                            }
                        }                        
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        } else {
            this.setState({ buttonLoader: false });
        }
    };

    onDelete = () => {
        let apiEndpoint = 'api/project/deleteProjectLabel';
        return httpServices.post(apiEndpoint, { 'id': this.state.deleteId })
            .then(response => {
                if (response.data.permission) {
                    this.setState({
                        open: false,
                        snackbar: true,
                        variant: 'success',
                        message: "Project label deleted successfully",
                        buttonLoader: false,
                        permission: response.data.permission,
                        deleteId: 0,
                    });
                    this.getProjectLabels(this.props.ProjectId);                    
                }
            });
    }

    render() {
        return (
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                        {(this.state.permission) ?
                            <LabelsComponent
                                ProjectLabels={this.state.projectLabels}
                                DeleteId={this.state.deleteId}
                                OnAdd={this.onAdd}
                                Open={this.state.open}
                                OnClose={this.onClose}
                                HandleOnChange={this.handleOnChange}
                                HandleSubmit={this.handleSubmit}
                                Errors={this.state.errors}
                                FormFields={this.state.formFields}
                                Variant={this.state.variant}
                                Message={this.state.message}
                                Snackbar={this.state.snackbar}
                                SnackbarClose={this.snackbarClose}
                                ProjectId={this.props.ProjectId}
                                ButtonLoader={this.state.buttonLoader}
                                ConfirmDelete={this.confirmDelete}
                                DeleteLabel = {this.onDelete}
                                EditPermission = {this.state.editPermission}
				FormInputs = {this.state.filterInputs}
                            />
                            :
                            <ErrorPage />
                        }
                    </>
                    : <></>
                }
            </>
        )
    }
}
