import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {CustomMaterialTable, CustomMTableToolbar} from '../common/CustomMaterialTable';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import * as _R from 'ramda';
import { withStyles } from '@mui/styles';

const customButton = (props) => {
    return (<Button variant="contained" color="primary" size='small'>Add</Button>);
}

function sort(date1, date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
}
const styles = theme => ({
    drawerList: {
        width: 250,
        padding: '20px'
    },
    drawerFormControl: {
        width: '100%'
    },
    filterSubmitButton: {
        marginTop: '20px'
    },
    filterButton: {
        position: 'absolute',
        top: "0.5rem",
        right: "0.5rem",
    },
    filterResetButton: {
        marginTop: '20px',
        marginLeft: '10px'
    },
});
const columns = [
    { title: 'Title', field: 'title',removable: false,cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } }
    },
    { title: 'Created By', field: 'created_by_name', removable: false,cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } } },
    { title: 'Created On', field: 'created_on', removable: false, customSort: (a, b) => sort(new Date(a.created_on), new Date(b.created_on)), render: rowData => ((rowData.created_on !== null && rowData.created_on !== '0') ? rowData.created_on : ''),cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } }  },
    { title: 'Modified By', field: 'modified_by_name', removable: false,cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } } },

    { title: 'Modified On', field: 'modified_on', removable: false, customSort: (a, b) => sort(new Date(a.modified_on), new Date(b.modified_on)), render: rowData => ((rowData.modified_on !== null && rowData.modified_on != 0) ? rowData.modified_on : 'Never'),cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } } },
    { title: 'Status', field: 'status_value', removable: false,cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } } },
    { title: 'Target Users', field: 'target_users_value', removable: false,cellStyle: (e, rowData) => {if (!rowData.read == 1) {return { fontWeight: 'bold' }; } } },
];

const SideList = withStyles(styles)(p => {
    return (<div role="presentation" className={p.classes.drawerList}>
        <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <FormControl className={p.classes.drawerFormControl} variant="standard">
                    <InputLabel htmlFor="selected_year">Year</InputLabel>
                    <Select
                        inputProps={{
                            name: 'selected_year',
                            id: 'selected_year',
                        }}
                        value={!_R.isNil(p.FilterValues.selected_year) && p.FilterValues.selected_year}
                        onChange={p.HandleFilterChange}
                    >
                        <MenuItem value="0">All</MenuItem>
                        {p.FilterValues.years.map((t, i) =>
                            <MenuItem value={t} >{t}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <FormControl className={p.classes.drawerFormControl} variant="standard">
                    <InputLabel htmlFor="search_status">Status</InputLabel>
                    <Select
                        inputProps={{
                            name: 'search_status',
                            id: 'search_status',
                        }}
                        value={!_R.isNil(p.FilterValues.search_status) && p.FilterValues.search_status}
                        onChange={p.HandleFilterChange}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>Draft</MenuItem>
                        <MenuItem value={2}>Published</MenuItem>
                        <MenuItem value={3}>Archived</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
	    <Grid item xs={4}>
            <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
	    </Grid>
	    <Grid item>
            <Button variant="outlined" color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
	    </Grid>
        </Grid>
    </div>
    );
});
export const ListAnnouncementsComponent = withStyles(styles)(props => {
    const { classes, ToggleDrawer } = props;
    return (
        <React.Fragment>

            <CustomMaterialTable
                title="Announcements"
                data={props.AllAnnouncements}
                columns={columns}
                actions={[
                    {
                        icon: customButton,
                        tooltip: 'Add Announcement',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.HandleAddDialogue() },
                        hidden: (props.EditPermision) ? false : true,
                    },
                    rowData => ({
                        icon: Edit,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => { props.HandleEditClick(rowData.id) },
                        hidden: (props.EditPermision) ? false : true,
                    }),
                ]}
                options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 15,
                    maxBodyHeight: '550px',
                    search: true,
                }}
                components={{
                    Toolbar: props => (
                        <div style={{ position: 'relative', paddingRight: '50px' }}>
                            <CustomMTableToolbar {...props} />
                            <Tooltip title="Filter" aria-label="Filter">
                                <IconButton onClick={ToggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                            </Tooltip>
                        </div>
                    )
                }}
                onRowClick={(event, rowData) => props.HandleViewClick(rowData.id)}
            />
            <Drawer
                anchor="right"
                open={props.Right}
                onOpen={props.ToggleDrawer('right', true)}
                onClose={props.ToggleDrawer('right', false)}>
                <SideList
                    Right={'right'}
                    FilterValues={props.FilterFields}
                    HandleFilterChange={props.HandleOnFilterChange}
                    SubmitFilter={props.HandleSearch}
                    ResetFilter={props.ResetFilter}
                />
            </Drawer>
        </React.Fragment>
    )
});

