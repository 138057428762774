import React from 'react';

// Dowjones tree select
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css'

export const TreeSelectComponent = (props) => {
    return (
        <DropdownTreeSelect
            className="mdl-demo"
            disabled={props.disabled ? props.disabled : false}
            data={props.data}
            onChange={(currentNode, selectedNodes, event) => props.HandleTreeChange(event, currentNode, selectedNodes, props.type)}
            keepTreeOnSearch="true"
            clearSearchOnChange="true"
            keepChildrenOnSearch="true"
            keepOpenOnSelect={false}
            mode ={props.dropdown_mode ? "radioSelect" : "multiSelect"}
            texts={{
                'placeholder': props.placeholderText
            }}
        />
    )
}
