import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import { formatName } from "../common/common";
import * as _R from 'ramda';
import Typography from '@mui/material/Typography';

export const SubordinateComponent = (props) => {
    let Subordinates = props.Subordinates;
    return (
        <>
            <div className={'listSubHeader'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">Subordinates (Active)</Typography>
            </div>
            {_R.isEmpty(Subordinates) ? <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography> :

                <div className={'section-content'}>
                    <Table className={'table'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {typeof Subordinates != 'undefined' && Subordinates.map((d, id) =>
                                <TableRow key={id}>
                                    <TableCell>{formatName(d.user_first_name, null, d.user_last_name)}</TableCell>
                                </TableRow>
                            )}
                            {_R.isEmpty(Subordinates) &&
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <Typography variant="body2" color="textSecondary" gutterBottom>No Records Found.</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            }
        </>
    );
};

