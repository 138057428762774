import React from 'react';

import { AppraisalComponent } from './Appraisal.Component';
import { httpServices } from '../../../../services/httpServices';
import { ErrorPage } from '../../../common/error';

const formFields = {
    'id':0,
    'appraisal_type': null,
    'start_date': null,
    'end_date': null,
    'rating': null,
    'evaluator' : null,
    'approver' : null,
    'scale' : null,
    'comments' : null,
};

export class AppraisalDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            //AppraisalData:[],
            formInputs: [],
            formFields: formFields,
            open: false,
            isEditable: false,
            isViewable: false,
            errors: {},
        }
        this.HandleOnChange = this.HandleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        this.getAppraisalTypes();
        this.setState({AppraisalData:this.props.AppraisalData});        
    };

    getAppraisalTypes = () =>{
        let apiEndpoint = 'api/staff/appraisal/getAllAppraisalTypes';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    this.setState({formInputs: response.data});                    
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    getAppraisalTypeById = (id) => {
       if(this.state.formInputs.appraisalTypes){
            let title = '';
            let appraisal_types = this.state.formInputs.appraisalTypes;
            appraisal_types.forEach(function(item){
                if(item.id === id){
                    title = item.title;                              
                }
            });
            return title;
        } 
    };

    HandleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/staff/appraisal/addAppraisalDetails', { data })
                    .then(response => {                      
                        if (!response.data.errors) {
                            if (response.data.status_flag) {
                                if(data.id){
                                    let appraisal_details = this.state.AppraisalData;
                                    appraisal_details.appraisalInfo = response.data.record;
                                    this.setState({AppraisalData:appraisal_details,open: false});
                                    alert("Appraisal details updated successfully");
                                } else { 
                                    let appraisal_details = this.state.AppraisalData;
                                    data = response.data.record;
                                    appraisal_details.appraisalInfo = data;
                                    this.setState({
                                        AppraisalData:appraisal_details,
                                        open: false,
                                    });
                                    alert("Appraisal details saved successfully");
                                }                                                     
                            } else {
                                alert("Duplicate entry not allowed");
                            }
                        }
                        else {
                            this.setState({errors : response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};        
        if (!this.state.formFields.rating) {
            errors["rating"] = "Cannot be empty";
            formIsValid = false;
        }     

        if (!this.state.formFields.appraisal_type) {
            errors["appraisal_type"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }        
        if(this.state.formFields.start_date && this.state.formFields.end_date){
            let start_date = new Date(this.state.formFields.start_date);
            let end_date = new Date(this.state.formFields.end_date);
            if(start_date > end_date){
                errors["end_date"] = "Please enter valid dates";
                formIsValid = false;
            }
        }
        if (!this.state.formFields.evaluator) {
            errors["evaluator"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.approver) {
            errors["approver"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    
    onAdd = () => {
        if(!this.state.AppraisalData && this.props.AppraisalData){
            this.setState({AppraisalData : this.props.AppraisalData});
        }
        let formFields = {
            'id' : 0,
            'appraisal_type' : null,
            'start_date' : null,
            'end_date' : null,
            'rating' : null,
        } 
        this.setState({
            open : true,
            isEditable : false,
            isViewable: false,
            formFields : formFields,            
            errors : {}
        });
    };

    onUpdate = (id) => {
        if(!this.state.AppraisalData && this.props.AppraisalData){
            this.setState({AppraisalData : this.props.AppraisalData});
        }
        if(id){
            let data = {};
            data['id'] = id;
            httpServices.post('api/staff/appraisal/getAppraisalDetailsById', { data })
                .then(response => {
                    let appraisal_details = response.data.apprailsal_details;                    
                    if(appraisal_details){
                        this.setState({
                            open: true,
                            isEditable: true,
                            isViewable: false,
                            formFields:appraisal_details,
                        });
                     }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };
     onView = (id) => {
        if(!this.state.AppraisalData && this.props.AppraisalData){
            this.setState({AppraisalData : this.props.AppraisalData});
        }
        if(id){
            let data = {};
            data['id'] = id;
            httpServices.post('api/staff/appraisal/getAppraisalDetailsById', { data })
                .then(response => {
                    let appraisal_details = response.data.apprailsal_details;
                    if(appraisal_details){
                        this.setState({
                            open: true,
                            isEditable: true,
                            isViewable: true,
                            formFields:appraisal_details,
                        });
                     }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };


    onDelete = (id) => {                
        if (id) {
            if(!this.state.AppraisalData && this.props.AppraisalData){
                this.setState({AppraisalData : this.props.AppraisalData});
            }
            let data = {};
            data['id'] = id;
            data['staff_id'] = this.props.StaffID;
            httpServices.post('api/staff/appraisal/deleteAppraisalDetails', { data })
                .then(response => {
                    this.setState({open: false});
                    let appraisal_details = this.state.AppraisalData;                    
                    let updated_appraisal_details_arr = {appraisalInfo:[]};
                    appraisal_details.appraisalInfo.forEach(function(item){
                        if(!(item.id === id)){
                            updated_appraisal_details_arr.appraisalInfo.push(item);
                        }
                    });
                    this.setState({AppraisalData:updated_appraisal_details_arr});
                    alert("Appraisal details deleted successfully");
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };

    onClose = () =>{
        this.setState({
            open: false
        });
    };

    render(){
        return(
            (this.props.AppraisalData.permission)
            ?
            <AppraisalComponent 
                Data={this.state.AppraisalData ? this.state.AppraisalData : this.props.AppraisalData}
                FormFields={this.state.formFields} 
                FormInputs={this.state.formInputs} 
                OnAdd={this.onAdd.bind(this)}
                onClose={this.onClose.bind(this)}
                OnDelete={this.onDelete.bind(this)}
                open={this.state.open}
                HandleOnChange={this.HandleOnChange}
                OnSubmit={this.handleSubmit}
                StaffId = {this.props.StaffID}
                Errors={this.state.errors}
                OnUpdate={this.onUpdate.bind(this)}
                OnView={this.onView.bind(this)}
                Edit={this.props.AppraisalData.edit_permission}
                Delete={this.props.AppraisalData.delete_permission}
                ViewScaleComments={this.props.AppraisalData.view_scale_comments}
                isEditable = { this.state.isEditable }
                isViewable = { this.state.isViewable }
            />
            :
            <ErrorPage/>
        )
    }
}
