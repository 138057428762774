import React from 'react';
import {ReviewComponent} from './Review.Component';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _R from 'ramda';  // used 0.04kb

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const formFields = {
    'projects' : [],
    'initials' : '',
    'reason' : '',
    'nconfirmity' : [],
    'agree' : '',
    'waiver_status' : '',
    'justification' : '',
    'late_submission_reason' : '',
}

export class TimesheetReviewContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            errors: {},
            list : [],
            hasPermission : true,
            formData : {},
            formFields : formFields,
            id : 0,
            validation : [],
            dialogOpen : false,
            popupType : '',
            dialogButton : null,
            snackbarOpen : false,
            buttonDisabled : true,
            view_type : '',
            review_comments : [],
            reviewNote : '',
            review_note_timeslice_id : 0,
            nconfirmity : [],
            reviewNoteError : false,
            rerenderSavedTimesheet : false,
            loading : false,
            loader : true,
            disableApproveBtn :false,
            showWaiverStatusUpdateBtn : false,
            waiverDetails : {},
            timesheetExist : true,
            lateSubmission : false,
            timesheetLocation : false
        }
        this.listReviewNotes = this.listReviewNotes.bind(this);
        this.handleReviewNoteChange = this.handleReviewNoteChange.bind(this);
        this.addReviewNote = this.addReviewNote.bind(this);
        this.handleNonConfirmityChange = this.handleNonConfirmityChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.approvebtnClick = this.approvebtnClick.bind(this);
        this.reviewbtnClick = this.reviewbtnClick.bind(this);
        this.submitbtnClick = this.submitbtnClick.bind(this);
        this.submitReviewTimesheet = this.submitReviewTimesheet.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.goToReviewList = this.goToReviewList.bind(this);
        this.updateWaiverStatusClick = this.updateWaiverStatusClick.bind(this);
        this.handleWaiverChange = this.handleWaiverChange.bind(this);
        this.updateWaiverStatus = this.updateWaiverStatus.bind(this);
    }
    componentDidMount = () => {
        var id = (this.props.match.params.id) ? this.props.match.params.id: false;
        this.setState({
            id : id
        });
        this.getTimesheetDetails(id);
        window.scrollTo(0, 0);
    };
    getTimesheetDetails = (id) => {
        if (id == "null") {
            this.setState({timesheetExist : false,
                loader : false,
                hasPermission : true
            });
        }
        else {
            return httpServices.post('api/timesheet/review', {id : id})
                .then(response => {
                    if (response.data) {
                        let view_type = '';
                        if (response.data.viewtype) {
                            view_type = response.data.viewtype;
                        }
                        let showWaiverStatusUpdateBtn = this.state.showWaiverStatusUpdateBtn;
                        if (response.data.edit_waiver_status_permission) {
                            showWaiverStatusUpdateBtn = true;
                        }
                        let buttonDisabled = true;
                        if (typeof response.data.late_submission_review !== 'undefined' && response.data.late_submission_review && typeof response.data.supervisor_review !== 'undefined' && !response.data.supervisor_review) {
                            buttonDisabled = false;
                        }
                        this.setState({
                            loader : false,
                            formData : response.data,
                            hasPermission : response.data.permission,
                            view_type : view_type,
                            buttonDisabled : buttonDisabled,
                            showWaiverStatusUpdateBtn : showWaiverStatusUpdateBtn,
                            timesheetLocation : response.data.timesheet_location
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    }
    onClose = () => {
        this.setState({
            dialogOpen : false,
            disableApproveBtn : false,
            buttonDisabled : true,
        }, function(){
            this.clearForm();
        });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        var projects = f.projects;
        let buttonDisabled = true;
        if (name === 'project') {
            if (evt.target.checked) {
                projects.push(parseInt(val));
            }
            else {
                projects = projects.filter(function(value, index, arr){
                    return value !== parseInt(val);
                });
            }
            f['projects'] = projects;
            
        }
        else if (name === 'agree') {
            f[name] = evt.target.checked;
            if (!evt.target.checked) {
                f['initials'] = '';
                buttonDisabled = true;
            }
            else {
                buttonDisabled = false;
            }
            this.setState({
                buttonDisabled : buttonDisabled,
            });
        }
        else if (name === 'waiver_status'){
            if (evt.target.checked) {
                f[name] = parseInt(val);
            }
        }
        else {
            f[name] = val;
        }
        this.setState({
            formFields: f,
        });
        if (val) {
            var err = this.state.errors;
            if (name === 'project') {
                err['projects'] = '';
            }
            else {
                err[name] = '';
            }
            this.setState({errors: err});
        }
    };
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            if (key === 'projects') {
                ff['projects'] = [];
            }
            else if(key === 'nconfirmity') {
                ff[key] = [];   
            }
            else {
                ff[key] = null;
            }
        }
        // Reseting global variable 'formFields' 
        for (let globalFormKey in ff) {
            if (globalFormKey === 'projects') {
                ff[globalFormKey] = [];
            }
            else if(globalFormKey === 'nconfirmity') {
                ff[globalFormKey] = [];
            }
            else {
                ff[globalFormKey] = '';
            }
        }
        this.setState({
            errors: {},
            formFields: ff,
        });
    };
    approvebtnClick = () => {
        let id  =this.state.id;
        return httpServices.post('api/timesheet/approvevalidation', {id : id})
            .then(response => {
                if (response.data.valid) {
                    //Display Approve popup
                    this.setState({
                        popup : 'approve',
                        dialogOpen : true,
                        dialogButton : 'Confirm',
                        disableApproveBtn : true,
                        validation : response.data,
                    });
                }
                else {
                    //Display validation failed popup
                    this.setState({
                        popup : 'approve-validation',
                        dialogOpen : true,
                        validation : response.data,
                        disableApproveBtn : true,
                    });
                }

        })
        .catch(function(error) {
            console.log("Error: "+error);
        });


    }
    reviewbtnClick = (action) => () => {
        let id  =this.state.id;
        return httpServices.post('api/timesheet/unapprovevalidation', {id : id, action: action})
            .then(response => {
                if (response.data.valid) {
                    let popup = action;
                    let btn = (action === 'reject') ? 'Reject' : 'Un-approve';
                    this.setState({
                        popup : popup,
                        dialogOpen : true,
                        dialogButton : btn,
                    });
                }
                else {
                    //Display validation failed popup
                    this.setState({
                        popup : 'unapprove-validation',
                        dialogOpen : true,
                        validation : response.data,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    submitbtnClick = () => {
        let id  =this.state.id;
        return httpServices.post('api/timesheet/validate-submit', {id : id})
            .then(response => { 
                if (response.data) {
                    //Display Approve popup
                    this.setState({
                        validation : response.data,
                        popup : 'submit',
                        dialogOpen : true,
                        dialogButton : 'Submit Anyway',
                        lateSubmission : response.data.late_submission
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }
    submitReviewTimesheet = () => {
        let id  =this.state.id;
        let action = this.state.popup;
        this.setState({
            loading : true,
        });
        let data = this.state.formFields; 
        data.id = id;
        data.action = action;
        data.nconfirmity = JSON.stringify(this.state.nconfirmity);
        let valid = this.validateSubmit(action);
        if (valid) {
        return httpServices.post('api/timesheet/review', data)
            .then(response => {
                if (response.data.valid) {
                    this.setState({
                        formData : response.data,
                        dialogOpen : false,
                        snackbarOpen : true,
                        loading : false,
                    }, function() {
                        this.clearForm();
                        let _this = this;
                        setTimeout(function(){ _this.goToReviewList(); }, 3000);
                    });
                } else {
                    this.setState({
                        loading : false,
                        errors : response.data.errors,
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
        } else {
            this.setState({
                loading : false,
            });
        }
    }
    validateSubmit = (action) => {
        let valid = true;
        let errors = {};
        let projects = this.state.formFields.projects;
        let multipleReview = this.state.formData.multiple_review;
        let reviewProjects = typeof this.state.formData.review_projects !== 'undefined' ? this.state.formData.review_projects : [];
        if (multipleReview === 1 && action !== 'submit') {
            if (_R.length(projects) === 0 && reviewProjects.length) {
                valid = false;
                errors['projects'] = "Please select a project";
            } 
        }
        if (action === 'reject') {
            let reason = this.state.formFields.reason;
            if (_R.length(reason.trim()) === 0) {
                valid = false;
                errors['reason'] = "Comment cannot be empty";
            }
        }
        if (action === 'approve') {
            let skipInitialsValidation = false;
            if (typeof this.state.formData.late_submission_review !== 'undefined' && this.state.formData.late_submission_review && typeof this.state.formData.supervisor_review !== 'undefined' && !this.state.formData.supervisor_review) {
                skipInitialsValidation = true;
            }
            if (!skipInitialsValidation) {
                if (_R.length(this.state.formFields.initials.trim()) === 0) {
                    valid = false;
                    errors['initials'] = "Cannot be empty";
                }
            }
            if ((typeof this.state.formData.late_submission_review !== 'undefined' && this.state.formData.late_submission_review) && _R.length(this.state.formFields.justification.trim()) === 0) {
                valid = false;
                errors['justification_error'] = "Cannot be empty";
            }
            if ((typeof this.state.formData.late_submission_review !== 'undefined' && this.state.formData.late_submission_review) && _R.length(this.state.formFields.waiver_status) === 0) {
                valid = false;
                errors['waiver_status'] = "Cannot be empty";
            }
        }
        if (action === 'submit') {
            let reason = this.state.formFields.reason;
            let late_submission_reason = this.state.formFields.late_submission_reason;
            if (_R.length(reason.trim()) === 0) {
                valid = false;
                errors['reason'] = "Reason cannot be empty";
            }
            if (this.state.lateSubmission && _R.length(late_submission_reason.trim()) === 0) {
                valid = false;
                errors['late_submission_reason'] = "Late submission reason cannot be empty";
            }
        }
        this.setState({errors: errors});
        return valid;
    }
    listReviewNotes = (id) => {
        if (id) {
            httpServices.post('api/timesheet/listreviewcomments', { timeslice_id : id })
                .then(response => {
                    if(response.data){
                        if (response.data) {
                            this.setState({
                                popup : 'show-review-list',
                                dialogOpen : true,
                                review_comments : response.data.comments,
                                review_note_timeslice_id : id,
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };
    handleReviewNoteChange = (evt) => {
        let val = evt.target.value;
        this.setState({
            reviewNote : val,
        });
    }
    handleNonConfirmityChange = (index,timeslice_id) => (evt) => {
        let nconfirmity = this.state.nconfirmity;
        if (timeslice_id) {
            if (evt.target.checked) {
                nconfirmity.push(timeslice_id);
                this.listReviewNotes(timeslice_id);
            } else {
                let index = nconfirmity.indexOf(timeslice_id);
                if (index > -1) {
                    nconfirmity.splice(index, 1);
                }
            }        
        }
        this.setState({
            nconfirmity : nconfirmity,
        });
    }
    addReviewNote = () => {
        let id = parseInt(this.state.review_note_timeslice_id);
        let review_comment = this.state.reviewNote;
        if (id > 0 && review_comment !== '') {
            return httpServices.post('api/timesheet/addcomment', {id : id, comment : review_comment})
                .then(response => {
                    if (response.data.status && response.data.comment) {
                        let review_comments = this.state.review_comments;
                        review_comments.push(response.data.comment);
                        let rerenderSavedTimesheet = this.state.rerenderSavedTimesheet;
                        this.setState({
                            review_comments : review_comments,
                            reviewNote : '',
                            reviewNoteError : false,
                            dialogOpen : false,
                            rerenderSavedTimesheet : !rerenderSavedTimesheet, // Forcefully rerendering the saved timesheet section for reflecting the add note change dynamically.
                        });
                        this.getTimesheetDetails(this.state.id);
                    } else {
                        if (response.data.errors && response.data.errors['comment']) {
                            this.setState({
                                reviewNoteError : response.data.errors['comment']
                            });
                        }
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        } else {
            this.setState({
                reviewNoteError : true,
            },function(){
                console.log("Invalid data");
            });
        }
    }
    goToReviewList = () => {
        this.props.history.push(BASE_PATH+"/timesheet/review");
    }
    updateWaiverStatusClick = () => {
        let id = this.state.id;
        httpServices.post('api/timesheet/update-waiver-status', { timesheet_id : id })
            .then(response => {
                if(response.data.permission && response.data.waiver_details){
                    this.setState({
                        popup : 'update_waiver_status',
                        dialogOpen : true,
                        dialogButton : 'Save',
                        waiverDetails : response.data.waiver_details
                    });
                }
            })
            .catch(function(error) {
                console.log("error" + error);
            });
    }
    handleWaiverChange = (evt, value) => {
        const f = this.state.waiverDetails;
        const name = evt.target.name;
        const val = evt.target.value;
        f[name] = val;
        this.setState({
            waiverDetails : f,
        });
    }
    updateWaiverStatus = () => {
        let data = this.state.waiverDetails;
        if (data.waiver_justification && data.waiver_justification.trim() !== '') {
            this.setState({
                loading : true,
            });
            data.action = 'update';
            httpServices.post('api/timesheet/update-waiver-status', data)
                .then(response => {
                    if(response.data.permission){
                        let formData = this.state.formData;
                        formData.message = 'Waiver status updated';
                        if (formData.late_submission_details && response.data.waiver_details) {
                            formData.late_submission_details.status_name = response.data.waiver_details.status_name;
                            formData.late_submission_details.waiver_justification = response.data.waiver_details.waiver_justification;
                        }
                        this.setState({
                            popup : '',
                            dialogOpen : false,
                            waiverDetails : {},
                            snackbarOpen : true,
                            formData : formData,
                            loading : false,
                        });
                    } else {
                        this.setState({
                            loading : false,
                        });
                    }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        } else {
            let errors = {};
            errors['justification_error'] = 'Cannot be empty';
            this.setState({
                errors : errors,
            });
        }
    }
    render(){
        return(
             (this.state.loader)
            ?
            <CustomProgressBar
                loaderOpen={this.state.loader}
            />
            :
            (this.state.hasPermission)
            ?
                (this.state.timesheetExist) ?
                <ReviewComponent
                OnClose = {this.onClose}
                SnackbarClose = {this.snackbarClose}
                ApprovebtnClick = {this.approvebtnClick}
                ReviewbtnClick = {this.reviewbtnClick}
                SubmitbtnClick = {this.submitbtnClick}
                SubmitReviewTimesheet = {this.submitReviewTimesheet}
                HandleOnChange = {this.handleOnChange}
                ViewType = {this.state.view_type}
                listReviewNotes = {this.listReviewNotes}
                HandleReviewNoteChange = {this.handleReviewNoteChange}
                AddReviewNote = {this.addReviewNote}
                HandleNonConfirmityChange = {this.handleNonConfirmityChange}
                {...this.state}
                TimesheetId = {this.state.id}
                RerenderSavedTimesheet = {this.state.rerenderSavedTimesheet}
                Loading= {this.state.loading}
                ShowWaiverStatusUpdateBtn = {this.state.showWaiverStatusUpdateBtn}
                UpdateWaiverStatusClick = {this.updateWaiverStatusClick}
                WaiverDetails = {this.state.waiverDetails}
                HandleWaiverChange = {this.handleWaiverChange}
                UpdateWaiverStatus = {this.updateWaiverStatus}
                TimesheetLocation = {this.state.timesheetLocation}
                />
                : <ErrorPage Message="Timesheet does not exist" />
            :<ErrorPage/>
        )
    }
}
