import React from 'react';
import {ImportEdenRedStatementComponent} from './ImportEdenRedStatement.Component'
import {httpServices}  from '../../services/httpServices'
import { history } from './../../helpers/history';
import { ErrorPage } from '../common/error';

export class ImportEdenRedStatementContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            file: null,
            hasPermission: true,
            errors: {},
            valid : false,
            snackbarOpen: false,
            message: null,
            error_message: null,
            commutedData: [],
            uploadedData: [],
            component_data:[],
            total_pretax_amount:null,
            total_posttax_amount:null,
            grand_total:null,
            Open: false,
            disable:false
        }
        this.HandleOnChange = this.handleOnChange.bind(this);
    }
    handleOnChange = (evt, value) => {
        let sfile = evt.target.files[0];
        this.setState({file: sfile});
    };
    onSubmit = () => {
        if (this.handleValidation()) {
            this.importEdenred();
        }
        document.getElementById('fileToUpload').value = null;

    };
    componentDidMount = () => {
        this.importEdenred();
    };
    importEdenred = () => {
        let file= this.state.file;
        let postData = {
            file: file
        };
        return httpServices.post('api/payroll/importEdenredStatement', postData)
            .then(response => {
                if(response.data.valid){
                    if (response.data.uploaded_data[0]) {
                        this.setState({uploadedData:response.data.uploaded_data,
                            commutedData: response.data.commutedData,
                            component_data: response.data.component_data,
                            total_pretax_amount: response.data.total_pretax_amount,
                            total_posttax_amount: response.data.total_posttax_amount,
                            grand_total: response.data.grand_total,
                            hasPermission:response.data.permission,
                            Open: true});
                    }
                }
                else {
                    this.setState({commutedData: response.data.commutedData,
                     hasPermission:response.data.permission});
                    if(response.data.message) {
                         this.setState({snackbarOpen: true,
                         error_message : response.data.message,
                        });
                    }
                }
            })
    }
    saveEdenred = () => {
        this.setState({disable:true});
        let postData = JSON.stringify(this.state.uploadedData);
        return httpServices.post('api/payroll/saveEdenredStatement', {data:postData})
            .then(response => {
                if (response.data) {
                    let data = response.data;
                    let valid = response.data.valid;
                    if (valid) {
                        this.setState({
                            commutedData: data.commutedData,
                            hasPermission:response.data.permission,
                            message:response.data.message,
                            snackbarOpen: true,
                            valid: true,
                            Open:false,
                        });

                    }
                    else {
                        let error_message = response.data.message;
                        this.setState({
                            commutedData: data.commutedData,
                            hasPermission:response.data.permission,
                            error_message : error_message,
                        });
                        if(response.data.message) {
                            this.setState({snackbarOpen: true});
                        }
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleValidation() {
        let valid = true;
        let errors = {};
        let file = this.state.file;

        if (!file) {
            errors["file"] = "Please upload a file";
            valid = false;
        }
        else {
            let file_name = file.name.trim();
            file_name = file_name.replace(/[()]/g,'');
            let allowed_files = [".xlsx"];
            var regex = new RegExp("([a-zA-Z0-9_\\.:])+(" + allowed_files.join('|') + ")$");
            if (!regex.test(file_name.toLowerCase())) {
                errors["file"] =  "Please upload files having extensions: " + allowed_files.join(', ') + " only."
                valid = false;
            }
        }
        this.setState({errors: errors, error_message: null});
        return valid;
    };
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
            valid: false,
        });
    }
    handleEdenredDetailsPage = (month,year)=> {
        let monthYear =month+" "+year;
        history.push('importedenredstatement/edenreddetails/'+monthYear);
    }
    onClose = () =>{
        this.setState({
            Open: false,
            errors:{}
        });
        this.clearForm();
    }
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    render(){
        return(
            (this.state.hasPermission) ?
            <ImportEdenRedStatementComponent
                HandleOnChange={this.handleOnChange}
                OnSubmit={this.onSubmit.bind(this)}
                SaveEdenred={this.saveEdenred.bind(this)}
                SnackbarClose ={this.snackbarClose.bind(this)}
                HandleEdenredDetailsPage={this.handleEdenredDetailsPage.bind(this)}
                Close={this.onClose.bind(this)}
                {...this.state}
            />
            :
            <ErrorPage/>
        )
    }
}
