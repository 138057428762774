import React from 'react';
import { connect } from "react-redux";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete,{ createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';

import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';
import { CustomizedSnackbars } from '../../../common/snackbars';

const DialogContent = (props) => {
    const {FormFields, FormInputs, HandleOnChange} = props;
    //const [value, setValue] = React.useState(null);
    const filter = createFilterOptions();
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="type" error={props.Errors.type && true} required>Address Type</InputLabel>
                            <Select
                                    required
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.type}
                                    error={props.Errors.type && true}
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'type',
                                        id: 'type',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.address_type_opts) !== 'Undefined' && FormInputs.address_type_opts.map((t, i) =>
                                        <MenuItem value={t.id} key={'address-type' + i}>{t.title}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className='error'>{props.Errors.type}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="country" error={props.Errors.country && true} required>Country</InputLabel>
                            <Select
                                    required
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.country}
                                    error={props.Errors.country && true} 
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'country',
                                        id: 'country',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.countries) !== 'Undefined' && FormInputs.countries.map((c, i) =>
                                        <MenuItem value={c.id} key={'country' + i}>{c.name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className='error'>{props.Errors.country}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="state" error={props.Errors.state && true} required>State</InputLabel>
                            <Select
                                    required
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.state}
                                    error={props.Errors.state && true}
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'state',
                                        id: 'state',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.states) !== 'Undefined' && FormInputs.states.map((s, i) =>
                                        <MenuItem value={s.id} key={'state' + i}>{s.short_code}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className='error'>{props.Errors.state}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                                <Autocomplete
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.county_name}
                                    onChange={props.HandleCountyChange}
                                    id="free-solo-with-text-demo"
                                    options={typeof FormInputs.counties !== 'undefined' ? FormInputs.counties : []}
                                    getOptionLabel={option => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.name;
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                name: `Add "${params.inputValue}"`,
                                                id : 0,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    //style={{ width: 300 }}
                                    freeSolo
                                     error={props.Errors.county_name && true}
                                    renderInput={params => (
                                        <TextField {...params} label="County" variant="outlined"
                                        error={props.Errors.county_name && true}
                                        helperText = {props.Errors.county_name}
                                        />
                                    )}
                                />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="city" error={props.Errors.city && true} required>City</InputLabel>
                            <Select
                                    required
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.city}
                                    error={props.Errors.city && true}
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'city',
                                        id: 'city',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.cities) !== 'Undefined' && FormInputs.cities.map((city, i) =>
                                        <MenuItem value={city.id} key={'city' + i}>{city.name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className='error'>{props.Errors.city}</FormHelperText>
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="street"
                                name="street"
                                label="Street Address"
                                type="text"
                                multiline
                                rows="2"
                                fullWidth
                                required
                                onChange={HandleOnChange}
                                value={FormFields.street}
                                error={props.Errors.street && true}
                                helperText = {props.Errors.street}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="zipcode"
                                name="zipcode"
                                label="Zip Code"
                                type="text"
                                fullWidth
                                required
                                onChange={HandleOnChange}
                                value={FormFields.zipcode}
                                error={props.Errors.zipcode && true}
                                helperText = {props.Errors.zipcode}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}

const formFields = {
    'id': 0,
    'country': '',
    'state': '',
    'county': '',
    'city': '',
    'street': '',
    'type': '',
    'zipcode': '',
}
class AddressListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            primary: false,
            errors: {},
            snackbarOpen : false,
            variant : 'success',
            address : []
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.loadFormInputs = this.loadFormInputs.bind(this);
        this.handleCountyChange = this.handleCountyChange.bind(this);
    }    
    componentDidMount() {
        this.setState({
            address : this.props.Address,
        });
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onUpdate = (id = 0) => () => {
        const {UserID, StaffID} = this.props;
        let apiEndpoint = 'api/staff/contact/get-address';
        let editable = (id) ? true : false;
        return httpServices.post(apiEndpoint, {
                        'user_id':UserID,
                        'staff_id':StaffID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        id: id,
                        open:true,
                        formFields: (id) ? response.data.selected_address: formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        primary: false,
                    });
                    this.loadFormInputs();
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.id = this.state.id;
            data.user_id = userID;
            data.staff_id = staffID;
            let tab = StaffConstants.STAFF_TABS[1];
            let module = 'address';
            if (data) {
                let apiEndpoint = 'api/staff/contact/save-address';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    open:false,
                                    snackbarOpen : true,
                                    varient: 'success',
                                    message : response.data.message,
                                    address : response.data.address,
                                });
                                this.clearForm();
                            }
                            else {
                                let input_errors = response.data.input_errors;
                                if (Object.keys(input_errors).length) {
                                    this.setState({
                                        errors:response.data.input_errors,
                                    });
                                }
                                else {
                                    alert(response.data.msg);
                                }
                            }
                        }
                        else {
                            this.setState({
                                errors:response.data.errors,
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    };
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/contact/delete-address';
        return httpServices.post(apiEndpoint, {
            'staff_id': StaffID,
            'user_id': UserID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[1];
                    let module = 'address';
                    this.setState({
                        open:false,
                        snackbarOpen : true,
                        varient: 'success',
                        message : response.data.message,
                        address : response.data.address,
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.country) {
            errors["country"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.state) {
            errors["state"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.city) {
            errors["city"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.street) {
            errors["street"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.zipcode) {
            errors["zipcode"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.type) {
            errors["type"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const val = evt.target.value;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
        if (field === 'country') {
            this.onCountryChange(val);
            let ffield = this.state.formFields;
            ffield['state'] = '';
            ffield['county'] = '';
            ffield['county_name'] = '';
            ffield['city'] = '';
            this.setState({
            formFields: ffield
        });

        }
        if (field === 'state') {
            this.onStateChange(val);
            let ffield = this.state.formFields;
            ffield['county'] = '';
            ffield['county_name'] = '';
            ffield['city'] = '';
            this.setState({
                formFields: ffield
            });
        }
    };
    handleCountyChange = (evt,value) => {
        let ff = this.state.formFields;
        if (value) {
            if (typeof value === 'string') { 
                ff['county'] = 'new_'+value;
                ff['county_name'] = value;
            } else if (typeof value === 'object' && value) {
                if (value.inputValue) {
                    ff['county_name'] = value.inputValue;
                }
                else if (value.name) {
                    ff['county_name'] = value.name;
                }
                if (value.id === 0) {
                    ff['county'] = 'new_'+value.inputValue;
                } else {
                    ff['county'] = parseInt(value.id);
                }
            }
        } else {
            ff['county'] = '';
            ff['county_name'] = '';
        }
        this.setState({
            formFields: ff
        });
    };
    onCountryChange = (id) => {
        let apiEndpoint = 'api/staff/contact/get-states';
        return httpServices.post(apiEndpoint, { 'country_id': id })
            .then(response => {
                if (!response.data.error) {
                    let finputs = this.state.formInputs;
                    finputs['states'] = response.data.states;
                    //finputs['counties'] = [];
                    this.setState({
                        formInputs: finputs,
                    });
                }
                else {
                    alert(response.data.msg);
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onStateChange = (id) => {
        let apiEndpoint = 'api/staff/contact/get-cities';
        return httpServices.post(apiEndpoint, { 'state_id': id })
            .then(response => {
                if (!response.data.error) {
                    let finputs = this.state.formInputs;
                    finputs['cities'] = response.data.cities;
                    finputs['counties'] = response.data.counties;
                    this.setState({
                        formInputs: finputs,
                    });
                }
                else {
                    alert(response.data.msg);
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    loadFormInputs = () => {
        let country_id = this.state.formFields.country;
        let state_id = this.state.formFields.state;
        this.onCountryChange(country_id);
        this.onStateChange(state_id);
    };

    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    };
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
            this.setState({formFields: ff});
        }
        this.setState({errors: {}});
    };
    render(){
        const { address } = this.state;
        return (
            <React.Fragment>
                <div className={'contact-group_head'}>
                    <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                        <LocationIcon className={'title-icon'} fontSize="small"/> 
                        Address
                    </Typography>
                    <div className={'action'}>
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
                <ul className={'contact-list'}>
                    {address && address.map( (a, index ) =>
                        <li>
                            <div className={'contact-detils'}>
                                <div className={'contact-type'}>{a.title}</div>
                                <div className={'contact-title'}>{a.street}</div>
                                <div className={'contact-content'}>
                                    {a.city}, {(a.county)?a.county+',':''} {(a.short_code)?a.short_code:a.state} {a.zipcode} 
                                    
                                    <div>{a.country}</div>
                                </div>
                            </div>
                            <div className={'contact-action'}>
                                <IconButton aria-label="edit">
                                    <EditIcon fontSize="small" onClick={this.onUpdate(a.id)}/>
                                </IconButton>
                                <IconButton aria-label="edit">
                                    <DeleteIcon fontSize="small" onClick={this.onDelete(a.id)}/>
                                </IconButton>
                            </div>
                        </li>
                    )}
                </ul>
                <DialogBoxMedium
                    Open={this.state.open}
                    Cancel={this.onClose}
                    Title={this.state.isDelete ?  'Delete Address' : (this.state.isEditable ? 'Edit Address' : 'Add Address')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : this.onSubmit}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Address?'
                        :
                        <DialogContent
                            FormInputs={this.state.formInputs}
                        FormFields={this.state.formFields}
                        OnSubmit={this.onSubmit}
                        HandleOnChange = {this.onHandleChange}
                        Errors = {this.state.errors}
                        HandleCountyChange = {this.handleCountyChange}
                        />
                    }
                />
                <CustomizedSnackbars
                open={this.state.snackbarOpen}
                variant={this.state.variant}
                message={this.state.message}
                handleClose ={this.snackbarClose}
                />
            </React.Fragment>
        )
    }
}
export { AddressListContainer as AddressList };
