import React, { Component } from 'react';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
class TsLogoTitle extends Component{
    render(){
        return(
            <div className={'appBarLogo'}>
                <div className={'helper'}>
                   <a href={BASE_PATH}><img src={BASE_PATH+"/company_logo.png"} alt={"this.props.title"} /></a>
                </div>
            </div>
        )
    }
}

export default TsLogoTitle;
