import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

export const DisabilityComponent = (props) => {
    let dataArr = props.disability_benefits;
    let table_size = 10; // Default
    if (dataArr) {
        let data_arr_length = dataArr.length ;
        if (data_arr_length < 10) {
            table_size = data_arr_length < 3 ? 2 : data_arr_length;
        }
    }
    let Open = props.Open;
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={props.Title}
                        columns={[
                            {
                                title: 'Disability Type',
                                field: 'disability_type',
                            },
                            {
                                title: 'Start Date',
                                field: 'start_date',
                            },
                            {
                                title: 'End Date',
                                field: 'end_date',
                            },
                            {
                                title: 'Paycheck Start Date',
                                field: 'paycheck_start_date',
                            },
                            {
                                title: 'Paycheck End Date',
                                field: 'paycheck_end_date',
                            },
                            {
                                title:'Monthly Premium Employee',
                                field:'monthly_premium_employee',
                            },
                            {
                                title:'Monthly Premium Family',
                                field:'monthly_premium_family',
                            },
                            {
                                title:'Employer Contribution Employee(%)',
                                field:'employer_contrib_employee',
                                hidden:true,
                            },
                            {
                                title:'Employer Contribution Family(%)',
                                field:'employer_contrib_family',
                                hidden:true,
                            },
                            {
                                title:'Employee Contribution Employee(%)',
                                field:'employee_contrib_employee',
                                hidden:true,
                            },
                            {
                                title:'Employee Contribution Family(%)',
                                field:'employee_contrib_family',
                                hidden:true,
                            },
                            {
                                title:'Deduction Component',
                                field:'deduction_component',
                                hidden:true,
                            },
                            {
                                title:'CTC Component',
                                field:'ctc_component',
                                hidden:true,
                            },
                        ]}
                        data={dataArr}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            pageSize:table_size,
                            pageSizeOptions: [10,20,30],
                            columnsButton:true,
                        }}
                        actions={[
                             {
                                icon: 'add',
                                tooltip: 'Add Disability Details',
                                isFreeAction: true,
                                onClick: (event) => { props.OnAdd() }, 
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                            },
                        ]}
                        editable={{
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                setTimeout(() => {
                                {
                                    props.OnDelete(rowData.id);
                                }
                                resolve()
                                }, 500)
                            }),
                        }}
                    />
                </Grid>
            </Grid>
            
            <DialogBoxMedium
                    Open = {Open}
                    Cancel = {props.OnClose}
                    Title = {props.IsEditBasic ? "Edit Disability Details" : "Add Disability Details"}
                    OnSubmit = {props.OnSubmit}
                    Content = {<DialogContent FormProps={props}/>}
           />

        </React.Fragment>
        </>
    )
};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange,disability_type,disability_deduction_components,disability_ctc_components} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
            <div className = "error"> {props.Errors.disability_overlap}</div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.start_date && true}
                                helperText={props.Errors.start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.end_date}
                                onChange={HandleOnChange}
                                error={props.Errors.end_date && true}
                                helperText={props.Errors.end_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="paycheck_start_date"
                                name="paycheck_start_date"
                                label="Paycheck Start Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.paycheck_start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.paycheck_start_date && true}
                                helperText={props.Errors.paycheck_start_date}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="paycheck_end_date"
                                name="paycheck_end_date"
                                label="Paycheck End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.paycheck_end_date}
                                onChange={HandleOnChange}
                                error={props.Errors.paycheck_end_date && true}
                                helperText={props.Errors.paycheck_end_date}
                            />
                        </ FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="disability_type" required  error={props.Errors.disability_type && true}>Disability Type</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.disability_type}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'disability_type',
                                    id: 'disability_type',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(disability_type) !== 'Undefined' && disability_type.map((t, i) =>
                                    <MenuItem value={t.id} key={'i-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.disability_type && <FormHelperText className='error'>{props.Errors.disability_type}</FormHelperText> }
                        </ FormControl>
                    </Grid>
            <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="monthly_prem_employee"
                                name="monthly_prem_employee"
                                label="Monthly Premium  Employee"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.monthly_prem_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
            <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="monthly_prem_family"
                                name="monthly_prem_family"
                                label="Monthly Premium  Family"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.monthly_prem_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
          <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employer_contrib_employee"
                                name="employer_contrib_employee"
                                label="Employer Contribution Employee Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employer_contrib_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
                <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employer_contrib_family"
                                name="employer_contrib_family"
                                label="Employer Contribution Family Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employer_contrib_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
               <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employee_contrib_employee"
                                name="employee_contrib_employee"
                                label="Employee Contribution Employee Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employee_contrib_employee}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>

        <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="employee_contrib_family"
                                name="employee_contrib_family"
                                label="Employee Contribution Family Percentage"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.employee_contrib_family}
                                onChange={HandleOnChange}
                            />
                        </ FormControl>
                    </Grid>
         <Grid item xs={12} md={6}>

                        <FormControl variant="standard">
                           <InputLabel htmlFor="deduction_components"  error={props.Errors.deduction_components && true}>Disability Deduction Component</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.deduction_components}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'deduction_components',
                                    id: 'deduction_components',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(disability_deduction_components) !== 'Undefined' && disability_deduction_components.map((t, i) =>
                                    <MenuItem value={t.id} key={'disability_deduction_components-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                           {props.Errors.deduction_components && <FormHelperText>{props.Errors.deduction_components}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="ctc_components" error={props.Errors.ctc_components && true}>Disability CTC Component</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.ctc_components}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'ctc_components',
                                    id: 'ctc_components',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                {_R.type(disability_ctc_components) !== 'Undefined' && disability_ctc_components.map((t, i) =>
                                    <MenuItem value={t.id} key={'disability_ctc_components-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            {props.Errors.ctc_components && <FormHelperText>{props.Errors.ctc_components}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
)
}
