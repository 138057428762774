import React from 'react';
import { BeneficiaryComponent } from './Beneficiary.Component';
import { httpServices } from '../../../../services/httpServices';

const formFields = {
    'id': 0,
    'beneficiary_name': null,
    'beneficiary_types': null,
    'beneficiary_relationships': null,
    'percentage': null,
};

export class Beneficiary extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            beneficiary_details: [],
            title : 'Beneficiary Details',
            formFields: formFields,
            open: false,
            isEditable: false,
            errors: {},
            Open: false,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleOnChange = (evt, value) => {
        const field = evt.target.name;     
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
            this.setState({ 
                formFields: ff
            });        
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/staff/benefit/add-beneficiary', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data) {
                                if(data.id){
                                    let details = response.data.beneficiary_details;
                                    let ben_relationships = response.data.beneficiary_relationships;
                                    this.setState({beneficiary_details:details, Open: false,beneficiary_relationships : ben_relationships});
                                    alert("Beneficiary details updated successfully");
                                } else {
                                    let details = response.data.beneficiary_details;
                                    let ben_relationships = response.data.beneficiary_relationships;
                                    this.setState({
                                        beneficiary_details:details,
                                        Open: false,
                                        beneficiary_relationships : ben_relationships
                                    });
                                    alert("Beneficiary details saved successfully");
                                }
                            }
                        }
                        else {
                            this.setState({errors: response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        
        }
    };

    onAdd = () => {
        this.clearForm();
      
        this.setState({
            Open: true,
            isEditable: false,
        });
    };

    clearForm = () => {
        const ff = {
             'id':0,
             'beneficiary_name': null,
             'beneficiary_types': null,
             'beneficiary_relationships': null,
             'percentage': null,
          };

        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff,
            isEditable: false,
        });
    };
    
    onClose = () =>{
        this.clearForm();
        this.setState({
            Open: false
        });
    };
    
    onUpdate = (id) => {
        return httpServices.post('api/staff/benefit/get-beneficiary-details-by-id', {'id': id})
            .then(response => {

                if (response.data) {
                    let data = response.data;
                    if (Object.keys(data).length){
                        const inputs = {
                            'id': data.id,
                            'beneficiary_name': data.beneficiary_name,
                            'beneficiary_types': data.beneficiary_type_id,
                            'beneficiary_relationships': data.relationship_id,
                            'effective_date': data.effective_date,
                            'percentage':data.percentage,                       
                        };
                        this.setState({
                            formFields: inputs,
                            isEditable: true,
                            Open: true,
                            id: data.id,
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

     onDelete = (rowID) => {
        return httpServices.post('api/staff/benefit/delete-beneficiary-details', {'id': rowID, 'staff_id': this.props.StaffID})
            .then(response => {
                if (response.data.valid) {
                    this.setState({beneficiary_details:response.data.beneficiary_details});
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
 
    handleValidation() {
        let formIsValid = true;
        let errors = {};       
     
        if (!this.state.formFields.beneficiary_types) {
            errors["beneficiary_types"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.beneficiary_relationships) {
            errors["beneficiary_relationships"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.percentage) {
            errors["percentage"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.percentage > 100) {
            errors["percentage"] = "Cannot be greater than 100";
            formIsValid = false;
        }

        if (!this.state.formFields.beneficiary_name) {
            errors["beneficiary_name"] = "Cannot be empty";
            formIsValid = false;
        }
       
        this.setState({errors: errors});
        return formIsValid;
    }

    render(){
        return(
            <BeneficiaryComponent
               Title={this.state.title}
               beneficiary_types = {this.props.beneficiary_types}
               beneficiary_details = {(this.state.beneficiary_details) && (this.state.beneficiary_details.length > 0)  ? this.state.beneficiary_details:this.props.beneficiary_details}
               beneficiary_relationships = {(this.state.beneficiary_relationships) && (this.state.beneficiary_relationships.length > 0)  ? this.state.beneficiary_relationships:this.props.beneficiary_relationships}
               FormFields={this.state.formFields}
               isEditable = {this.state.isEditable}
               StaffID = {this.props.StaffID} 
               OnSubmit={this.handleSubmit} 
               HandleOnChange={this.handleOnChange}
               OnAdd={this.onAdd.bind(this)}
               OnDelete={this.onDelete.bind(this)}
               Open= {this.state.Open} 
               OnClose={this.onClose.bind(this)}
               OnUpdate={this.onUpdate.bind(this)} 
               Errors={this.state.errors}
            />
        )
    }
}
