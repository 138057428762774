import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { RulesComponent }  from './Rules.Component.js';
import { ErrorPage } from '../../common/error';

let formFields = {
    location : null,
    status : 'Active',
    id : 0,
    name : '',
    reccur_frequency : '',
    selectedDays : [],
    repeat : 1,
}
export class RulesContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            rules : [],
            locations : [],
            editPermission : false,
            permission : true,
            open : false,
            popupType : '',
            formFields : Object.assign({}, formFields),
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            deleteId : 0,
            snackbarVariant: '',
            error_msg : true,
        }
        this.addRules = this.addRules.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editRule = this.editRule.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onSelectDays = this.onSelectDays.bind(this);
    }

    componentDidMount = () => {
        this.getAllRules();
    };

    getAllRules = () => {
        let apiEndpoint = 'api/settings/workpolicy/listrules';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    this.setState({
                        rules : response.data.rules,
                        locations : response.data.locations,
                        editPermission : response.data.edit_permission,
                        permission : response.data.permission,
                        error_msg : response.data.permision_err_msg,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };


    addRules = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : Object.assign({}, formFields),
            errors : {},
        })
    }

    handleDelete = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = Object.assign({}, this.state.formFields);
            data.selectedDays = JSON.stringify(this.state.formFields['selectedDays']);
            if (data) {
                 httpServices.post('api/settings/workpolicy/saveRule', { data })
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.valid) {
                                this.getAllRules();
                                let successMsg = ''
                                if (data.id) {
                                    successMsg = 'Rule updated successfully';
                                } else {
                                    successMsg = 'Rule added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                if (response.data.errors) {
                                    this.setState({
                                        errors: response.data.errors,
                                    });
                                }
                                else {
                                    let errorMsg = response.data.err_msg;
                                    this.setState({
                                        snackbarOpen: true,
                                        snackbarMsg: errorMsg,
                                        snackbarVariant: 'error'
                                    });
                                }
                            }
                        }
                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.location) {
            errors["location"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.name || !this.state.formFields.name.trim()) {
            errors["name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.reccur_frequency) {
            errors["reccur_frequency"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.repeat) {
            errors["repeat"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editRule = (id) => {
        let apiEndpoint = 'api/settings/workpolicy/get-rule-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.rule) {
                    var selected = [];
                       if (response.data.rule.reccur_by_days) {
                           let reccur_days = response.data.rule.reccur_by_days.split(", ");
                           reccur_days.forEach(element => {
                               selected.push(element);
                           });
                       }
                       let formFields = {
                           id : response.data.rule.id,
                           location : response.data.rule.location_id,
                           name : response.data.rule.rule_name,
                           status : response.data.rule.status,
                           reccur_frequency : response.data.rule.reccur_frequency,
                           repeat : response.data.rule.reccur_interval,
                           selectedDays : selected
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    onSelectDays = (event) => {
        let formFields = this.state.formFields;
        if (!formFields['selectedDays']) {
            formFields['selectedDays'] = [];
        }
        if (event.target.checked) {
           formFields['selectedDays'].push(event.target.value) ;
        }
        else
        {
            let dayArray = formFields['selectedDays'];
            let newArray = dayArray.filter(each => each != event.target.value);
            formFields['selectedDays'] = newArray;
        }
        this.setState({
            formFields : formFields,
        });
    }

    deleteRule = () => {
        let apiEndpoint = 'api/settings/workpolicy/deleteRule';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    if (response.data.valid) {
                        this.getAllRules();
                        this.setState({
                            snackbarOpen : true,
                            snackbarMsg : "Rule deleted successfully",
                            open : false,
                            popupType : '',
                            snackbarVariant: 'success'
                        });
                    } else {
                        alert(response.data.err_msg);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            (this.state.permission)?
            <RulesComponent
                rules = {this.state.rules}
                EditPermission = {this.state.editPermission}
                Permission = {this.state.permission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddRules = {this.addRules}
                FormFields = {this.state.formFields}
                OnSelectDays = {this.onSelectDays}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditRule = {this.editRule}
                OnClose = {this.onClose}
                DeleteRule = {this.deleteRule}
                HandleDelete = {this.handleDelete}
                SnackbarVariant = {this.state.snackbarVariant}
                Locations = {this.state.locations}
                FrequencyArray = {this.props.FrequencyArray}
            />
            :
            <ErrorPage Message = {this.state.error_msg} />
        )
    }
}
