import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import * as _R from 'ramda';
import * as _ts from '../../common/treeSelect';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import { formatName } from '../../common/common';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

const API_URL = process.env.REACT_APP_API_URL;
const BackDrop = styled(Backdrop) (({ theme }) => ({
        '& .backdrop': {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        },
}));

export const IifReportComponent = (props) => {
    let laborCategoryHide = props.HideLaborCategory;
    let clinHide = props.HideClin;
    const columns = [
        { title: 'Date', field: 'timeslice_date', },
        { title: 'Staff', field: 'user_fullname',render: rowData => <>{formatName(rowData.first_name,null,rowData.last_name,props.name_format)} </>},
        { title: 'Staff Type', field: 'staff_type' },
        { title: 'Projects', field: 'last_entry' ,render: rowData => <>{rowData.client_code+'/'+rowData.project_path} </>},
        { title: 'Charge Code', field: 'chargecode' },
        { title: 'Hours', field: 'hours' },
        { title: 'Labor Category', field: 'gsa_lcat', hidden: laborCategoryHide },
        { title: 'CLIN', field: 'clins', hidden: clinHide },
    ];
    const gridStyle = {
        margin: '15px 0px'
    };
    const input = props.formInputs;
    const values = props.formFields;
    let staffs = (_R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.staff_list) !== 'Undefined') ? props.formInputs.staff_list : [];
    if (staffs.length) {
        _ts.findStaffKey(staffs, ((_R.type(props.formFields) !== 'Undefined') ? props.formFields.staffs : []));
    }
    let clients = (_R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.client_list) !== 'Undefined') ? props.formInputs.client_list : [];
    if (clients.length) {
        _ts.findClientKey(clients, ((_R.type(props.formFields) !== 'Undefined') ? props.formFields.clients : []));
    }
    let projects = ( _R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.project_list) !== 'Undefined') ? props.formInputs.project_list : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(props.formFields) !== 'Undefined') ? props.formFields.projects : []));
    }
    let exclude_projects = ( _R.type(props.formInputs) !== 'Undefined' && _R.type(props.formInputs.exclude_project_list) !== 'Undefined') ? props.formInputs.exclude_project_list : [];
    if (exclude_projects.length) {
        _ts.findProjectKey(exclude_projects, ((_R.type(props.formFields.exclude_projects) !== 'Undefined') ? props.formFields.exclude_projects : []));
    }

     return (
         <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 class="MuiTypography-root section-title MuiTypography-h6">Timesheet IIF Report</h6>
                </Grid>
                <Grid item>
                <form id="export-form" className="form-fullwidth" action={API_URL+'api/report/exportTimesheetIifReport'} method="post">
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "start_date"
                                    name = "start_date"
                                    label = "Date From"
                                    type = "date"
                                    required
                                    onChange = {props.HandleOnChange}
                                    value={(typeof values.start_date !== 'undefined') ? values.start_date : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    error={props.Errors.start_date && true}
                                    helperText={props.Errors.start_date}
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "end_date"
                                    name = "end_date"
                                    label = "Date To"
                                    type = "date"
                                    required
                                    value={(typeof values.end_date !== 'undefined') ? values.end_date : null}
                                    onChange = {props.HandleOnChange}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    error={props.Errors.end_date && true}
                                    helperText={props.Errors.end_date}
                                />
                            </ FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={4}>
                            <div class="filter-column">
                                <div class="filter-column-title">Staff</div>
                                <div class="filter-column-content">
                                    <Grid>
                                        <FormControl error={props.Errors.staff_status && true} variant="standard">
                                            <InputLabel htmlFor="staff_status">Staff Status</InputLabel>
                                            <Select
                                                value={(typeof values.staff_status !== 'undefined') ? values.staff_status : null}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'staff_status',
                                                    id: 'staff_status',
                                                    }}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                            { typeof input.staff_status != 'undefined' && input.staff_status.map((t, i) =>
                                                <MenuItem value={t.user_status_id}>{t.user_status_name}</MenuItem>
                                            )}
                                            </Select>
                                            <FormHelperText>{props.Errors.staff_status}</FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid>
                                        <FormControl error={props.Errors.staff_type && true} variant="standard">
                                        <InputLabel htmlFor="staff_type">Staff Type</InputLabel>
                                            <Select  
                                                value={(typeof values.staff_type !== 'undefined') ? values.staff_type : null}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'staff_type',
                                                    id: 'staff_type',
                                                }}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                            { typeof input.staff_type != 'undefined' && input.staff_type.map((t, i) =>
                                                <MenuItem value={t.id}>{t.type}</MenuItem>
                                            )}
                                            </Select>
                                            <FormHelperText>{props.Errors.staff_type}</FormHelperText>
                                    </FormControl>
                                    </Grid> 
                                    <Grid>
                                        <input type='hidden' name='staffs' value={ (typeof values.staffs !== 'undefined') ? values.staffs : null }/>
                                        <FormControl>
                                            <TreeSelectComponent
                                                data = {typeof input.staff_list != 'undefined' && input.staff_list?input.staff_list:[]}
                                                placeholderText = { "Search Staff"}
                                                type = 'staffs'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                        </ FormControl>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-title">Client</div>
                                <div class="filter-column-content">
                                     <Grid className='justify-between'>
                                        <FormControl error={props.Errors.client_status && true} variant="standard">
                                            <InputLabel htmlFor="client_status">Client status</InputLabel>
                                            <Select  
                                                value={(typeof values.client_status !== 'undefined') ? values.client_status : null}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'client_status',
                                                    id: 'client_status',
                                                }}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                            { typeof input.client_status != 'undefined' && input.client_status.map((t, i) =>
                                                <MenuItem value={t.client_status_id}>{t.client_status_name}</MenuItem>
                                            )}
                                            </Select>
                                            <FormHelperText>{props.Errors.client_status}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid>
                                        <input type='hidden' name='clients' value={ (typeof values.staffs !== 'undefined') ? values.clients : null }/>
                                        <FormControl>
                                            <TreeSelectComponent
                                                data = {typeof input.client_list != 'undefined' && input.client_list?input.client_list:[]}
                                                placeholderText = { "Search Clients"}
                                                type = 'clients'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                        </ FormControl>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={gridStyle}>
                            <div class="filter-column">
                                <div class="filter-column-title">Project</div>
                                <div class="filter-column-content">
                                    <Grid>
                                        <FormControl error={props.Errors.project_status && true} variant="standard">
                                            <InputLabel htmlFor="project_status">Project status</InputLabel>
                                            <Select             
                                                value={(typeof values.project_status !== 'undefined') ? values.project_status : null}
                                                onChange={props.HandleOnChange}
                                                inputProps={{
                                                    name: 'project_status',
                                                    id: 'project_status',
                                                }}
                                            >
                                                <MenuItem value="0">All</MenuItem>
                                            { typeof input.project_status != 'undefined' && input.project_status.map((t, i) =>
                                                <MenuItem value={t.project_status_id}>{t.project_status_name}</MenuItem>
                                            )}
                                            </Select>
                                            <FormHelperText>{props.Errors.project_status}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid>
                                        <FormControl>
                                            <input type='hidden' name='projects' value={props.formFields.projects}/>
                                            <TreeSelectComponent
                                                data = {projects}
                                                placeholderText = { "Search Projects"}
                                                type = 'project'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {(input.exclude_project_list &&  !(values.projects.length && parseInt(values.projects)) && Object.keys(input.exclude_project_list).length>0)?
                                    <Grid>
                                        <FormControl>
                                            <input type='hidden' name='exclude_projects' value={values.exclude_projects}/>
                                            <TreeSelectComponent
                                                data = {exclude_projects}
                                                placeholderText = { "Exclude Projects"}
                                                type = 'excluded_project'
                                                HandleTreeChange = {props.HandleTreeChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    :<></>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked = { props.formFields.labor_category }
                                        value = { props.formFields.labor_category }
                                        id = "labor_category"
                                        name = "labor_category"
                                        onChange = {props.HandleOnChange}
                                        color="primary"
                                    />
                                    }
                                    label="Include Labor Category"
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked = { props.formFields.clin }
                                        value = { props.formFields.clin }
                                        id = "clin"
                                        name = "clin"
                                        onChange = {props.HandleOnChange}
                                        color="primary"
                                    />
                                    }
                                    label="Include CLIN"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div class="filter-column">
                                <div class="d-flex align-center justify-end pr-2">
                                    <Button size="small" color="primary" variant="contained" onClick={ props.OnSearchClick }>Show</Button>
                                    <div className={'ml-1'}>
                                    <Button variant="contained" size="small" color="primary" onClick={props.ExportReports} >Export</Button>
                                    </div>
                                </div>
                            </div>
                        </Grid> 
                    </Grid>
                </form>
                </Grid>
            </Grid>
         </Paper>
                <Paper>
                    <>
                    {   (input.timesheet_list && Object.keys(input.timesheet_list).length>0)?
                         <CustomMaterialTable
                            title= "Timesheet List"
                            columns={columns}
                            data={input.timesheet_list}
                            options={{
                                search:false,
                                actionsColumnIndex: 0,
                                pageSize:10,
                                pageSizeOptions: [10,20,30]
                            }}
                        />
                        :
                        (props.OnSearch == true) &&
                            <div> No records found </div>
                    }
                    </>
                </Paper>
             <BackDrop className={'backdrop'} open={props.LoaderOpen}>
                <CircularProgress color="inherit" />
            </BackDrop>
         </>
     )
}
