import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { CompanyDetailsComponent } from './CompanyDetails.Component';

let formFields = {
    company_name : null,
    short_name : null,
    street : null,
    state : null,
    zip: null,
    fax : null,
    website : null,
    company_info : null,
    addr1: null,
    addr2: null,
    city: null,
    country: null,
    telephone: null,
    email: null,
    support_email: null,
    company_id : 0,
}
export class CompanyDetailsContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            companyDetails: [],
            hasPermission : true,
            edit_permission : false,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            snackbarVariant: ''

        }
        this.editCompanyDetails = this.editCompanyDetails.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onClose = this.onClose.bind(this);

    }
    componentDidMount(){
        this.getCompanyDetails();
    }
    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.company_name.trim()) {
            errors["company_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.short_name.trim()) {
            errors["short_name"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.support_email.trim()) {
            errors["support_email"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.formFields.email) {
            let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let ok = reg.exec(this.state.formFields.email);
            if (!ok) {
                errors["email"] = "Email isn't valid";
                formIsValid = false;
            }
        }
        if (this.state.formFields.support_email.trim()) {
            let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let ok = reg.exec(this.state.formFields.support_email);
            if (!ok) {
                errors["support_email"] = "Email isn't valid";
                formIsValid = false;
            }
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    editCompanyDetails = () => {
        let companyDetails = this.state.companyDetails ? this.state.companyDetails : formFields;
         companyDetails = JSON.stringify(companyDetails);
        this.setState({
            formFields : JSON.parse(companyDetails),
            open : true,
            popupType : 'edit',
        });
    };

    handleSubmit = (event) => {
        if (this.handleValidation()) {
            let data = this.state.formFields;
            if (data) {
                let apiEndpoint = 'api/system/updateCompanyDetails';
                return httpServices.post(apiEndpoint, {data})
                    .then(response => {
                        if (response.data.status && response.data.edit_permission) {
                                let successMsg = ''
                                if(data.company_id){
                                    successMsg = 'Company details updated successfully';
                                } else {
                                    successMsg = 'Company details  added successfully';
                                }
                                this.setState({
                                    companyDetails : response.data.companyDetails,
                                    snackbarOpen : true,
                                    snackbarMsg : successMsg,
                                    snackbarVariant: 'success',
                                    open : false,
                                    popupType : '',
                                    formFields : {},
                                    errors : {},
                                });                           
                        } else if (typeof response.data.errors !== 'undefined' && response.data.errors) {
                            this.setState({
                                errors : response.data.errors,
                            });
                        }                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };

    getCompanyDetails = () => {
        let apiEndpoint = 'api/system/companydetails';
        return httpServices.post(apiEndpoint)
            .then(response => {
                    if (response.data.permission) {
                        this.setState({
                            companyDetails : response.data.companyDetails,
                            permission : response.data.permission,
                            edit_permission : response.data.edit_permission,
                        });
                    }
                    else {
                        this.setState({
                            hasPermission : false,
                            edit_permission :false,
                        });
                    }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    render(){
        return(
            <CompanyDetailsComponent
            CompanyDetails = {this.state.companyDetails }
            PopupType = {this.state.popupType}
            Open = {this.state.open}
            EditCompanyDetails = {this.editCompanyDetails}
            FormFields = {this.state.formFields}
            Errors = {this.state.errors}
            HandleSubmit = {this.handleSubmit}
            SnackbarClose = {this.snackbarClose}
            SnackbarOpen = {this.state.snackbarOpen}
            SnackbarMsg = {this.state.snackbarMsg}
            SnackbarVariant = {this.state.snackbarVariant}
            OnClose = {this.onClose}
            HandleOnChange = {this.handleOnChange}
            EditPermission = {this.state.edit_permission}
        />  
        )
    }

}
