import React from 'react';
import { UserProjectsComponent } from './UserProjects.Component';
import { httpServices } from '../../services/httpServices';
import { history } from '../../helpers/history';
import { ErrorPage } from '../common/error';
import { CustomProgressBar } from '../common/CustomProgressBar';

const filterValues = {
    status : 1
};

export class UserProjectsContainer extends React.Component{
    constructor(props) {
        let filters = filterValues;
        super(props);
        this.state = {
            permission : false,
            filterInputs : [],
            filterValues : filters,
            showLoader : true,
            projects :[],
            right: false,
            deletePermission : false,
            dataLoaded : false,
        };
        this.handleViewDetails =  this.handleViewDetails.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
    }
   
    componentDidMount() {
        this.getProjectList();
    }    

    toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ ...this.state, [side]: open });
    }

    handleViewDetails = (id) => {
        if(id) {
            history.push('projects/view/' + id);
        }
    }

    handleFilterChange = (evt, value) => {
        let f = this.state.filterValues;
        let name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterValues: f
        });
    }

    getProjectList = () => {
        this.setState({
            right: false,
            showLoader : true,
        });
        const data = this.state.filterValues;
        let apiEndpoint = 'api/user/my-project';
        return httpServices.post(apiEndpoint,data)
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            projects : response.data.projects,
                            filterInputs : response.data.filters,
                            showLoader : false,
                            permission : response.data.permission,
                            dataLoaded:true,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false,
                            dataLoaded:true
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    resetFilter = () => {
        let f = {
            status : 1,
        };
        this.setState({
            filterValues: f
        },() => {this.getProjectList();});
    }
    render(){
        return(
            <>
                <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
                        <UserProjectsComponent
                            Projects = {this.state.projects}
                            HandleViewDetails = {this.handleViewDetails}
                            FilterValues = {this.state.filterValues}
                            FilterInputs={this.state.filterInputs}
                            DeletePermission={this.state.deletePermission}
                            toggleDrawer={this.toggleDrawer}
                            Right={this.state.right}
                            HandleFilterChange={this.handleFilterChange.bind(this)}
                            SubmitFilter={this.getProjectList}
                            ResetFilter={this.resetFilter.bind(this)}
                        />
                        :
                        <ErrorPage/>
                    }
                    </>
                    :<></>
                }
            </>
        )
    }
}