import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import * as _R from 'ramda';

export const TimeOffLog = (props) => {
  return (
    <div className={'pt-1 pb-1'} style={{ minHeight: '200px' }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Pay Period</TableCell>
            <TableCell> Accrued (In Hours) </TableCell>
            <TableCell> Used (In Hours)</TableCell>
          </TableRow>
        </TableHead>
        {_R.type(props.TimeOffLog) !== 'Undefined' && (props.TimeOffLog).map((item) =>
          <>

            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row"> {item.name}</TableCell>
                <TableCell component="th" scope="row"> {item.payperiod_sdate} - {item.payperiod_edate}</TableCell>
                <TableCell component="th" scope="row"> {parseFloat(item.hours).toFixed(2)} </TableCell>
                <TableCell component="th" scope="row"> {parseFloat(item.taken).toFixed(2)} </TableCell>
              </TableRow>
            </TableBody>
          </>
        )}


      </Table>
    </div>
  );
}
