import React from 'react';
import { ExpenseVoucherComponent } from './ExpenseVoucher.Component';
import { httpServices } from '../../../services/httpServices';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { ErrorPage } from '../../common/error';

const filterFields = {
    'year': new Date().getFullYear(),
    'month': 0,
    'report_status': 0,
    'employee': 0,
    'name_format': 'FIRSTLAST'
};
export class ExpenseVoucherContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasPermission: true,
            permisionError: '',
            VoucherList: [],
            SubmitAmount: 0,
            ActualAmount: 0,
            filterViewState: false,
            filterFields: filterFields,
            startYear: '',
            endYear: '',
            employees: [],
            expenseStatus: [],
            years: [],
            months: [],
            currencySymbol :'',
            showLoader : false
        }
        this.getVoucherList = this.getVoucherList.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    componentDidMount = () => {
        this.getVoucherList();

    };

    filterView = (anchor, open) => (event) => {
        this.setState({ filterViewState: open });
    };
    filterSubmit = () => {
        this.getVoucherList();
    }
    handleOnChange = (evt, value) => {
        const f = this.state.filterFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
    }


    getVoucherList = (sdate, props) => {
        this.setState({showLoader :true});
        let apiEndpoint = 'api/expenses/expensesheets';
        let data = {
            'year': this.state.filterFields.year,
            'month': this.state.filterFields.month,
            'status': this.state.filterFields.report_status,
            'user_id': this.state.filterFields.employee,
            'name_format': this.state.filterFields.name_format
        }
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                let response_data = response.data;
                if (response_data.permission === false) {
                    this.setState({
                        hasPermission : false,
                        permisionError: "You are not authorized to do this action",
                        showLoader : false
                    });
                }
                else {
                    this.setState({
                        VoucherList: response_data.voucher_list,
                        submitAmount: response_data.submitted_amount_total,
                        actualAmount: response_data.actual_payment_total,
                        startYear: response_data.start_year,
                        endYear: response_data.end_year,
                        employees: response_data.employees,
                        expenseStatus: response_data.status,
                        years: response_data.years,
                        months: response_data.months,
                        filterViewState: false,
                        currencySymbol: response_data.currency_symbol,
                        showLoader : false,
                        hasPermission : true,
                    });
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }



    render() {
        return (
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {this.state.hasPermission ?
                <ExpenseVoucherComponent
                    VoucherList={this.state.VoucherList}
                    SubmitAmount={this.state.submitAmount}
                    ActualAmount={this.state.actualAmount}
                    FilterView={this.filterView}
                    FilterViewState={this.state.filterViewState}
                    FilterSubmit={this.filterSubmit}
                    FilterFields={this.state.filterFields}
                    ExpenseStatus={this.state.expenseStatus}
                    StartYear={this.state.startYear}
                    EndYear={this.state.endYear}
                    Employees={this.state.employees}
                    Years={this.state.years}
                    Months={this.state.months}
                    HandleOnChange={this.handleOnChange}
                    GetVoucherList={this.getVoucherList}
                    CurrencySymbol={this.state.currencySymbol}
                />:
                <ErrorPage/>
            }
            </>
        )
    }
}
