import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import * as _R from 'ramda';  // used 0.04kb
import {DialogBoxMedium} from '../common/dialog.medium';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomizedSnackbars } from '../common/snackbars';

// dialog content
const DialogContent = (p) => {
    const {Components:base_components} = p;
    const {FormProps:props} = p;
    const {FormFields, FormInputs, HandleOnChange, ConfigEditFlag} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="title"
                                name="title"
                                label="Title"
                                type="text"
                                fullWidth
                                required
                                onChange={HandleOnChange}
                                value={FormFields.title}
                                InputProps={{
                                    readOnly: ConfigEditFlag?true:false,
                                }}
                                InputLabelProps={{ shrink: true }}
                                error={props.Errors.title && true}
                                helperText={props.Errors.title}
                            />
                        </ FormControl>
                    </Grid>
                    {!ConfigEditFlag?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="type">Type</InputLabel>
                            <Select
                                value={FormFields.type ? FormFields.type : ''}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}>
                                <MenuItem value={null} key={'type-0'}>Select</MenuItem>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.types) !== 'Undefined' && FormInputs.types.map((t, i) =>            
                                    <MenuItem key={'type-'+i} value={t.id}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.type && props.Errors.type}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {!ConfigEditFlag?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="sub_type">Sub-Type</InputLabel>
                            <Select
                                value={FormFields.sub_type ? FormFields.sub_type : ''}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'sub_type',
                                    id: 'sub_type',
                                }}>
                                <MenuItem value={null} key={'sub-type-0'}>Select</MenuItem>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.sub_types) !== 'Undefined' && FormInputs.sub_types.map((t, i) =>            
                                    <MenuItem value={t.id} key={'sub-type-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.sub_type && props.Errors.sub_type}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {!ConfigEditFlag?
                    <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="tab">Tab</InputLabel>
                            <Select
                                value={FormFields.tab ? FormFields.tab : ''}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'tab',
                                    id: 'tab',
                                }}>
                                <MenuItem value={null} key={'tab-0'}>Select</MenuItem>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.tabs) !== 'Undefined' && FormInputs.tabs.map((t, i) =>            
                                    <MenuItem value={t.id} key={'tab-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.tab && props.Errors.tab}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {!ConfigEditFlag?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="status">Status</InputLabel>
                            <Select
                                onChange={HandleOnChange}
                                value={_R.type(FormFields.status) !== 'Undefined' ? FormFields.status: 1}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}>
                                <MenuItem value={1} key={'status-1'}>Active</MenuItem>
                                <MenuItem value={0} key={'status-0'}>Inactive</MenuItem>
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.status && props.Errors.status}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.start_date}
                                onChange={HandleOnChange}
                                error={props.Errors.start_date && true}
                                helperText={props.Errors.start_date}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={HandleOnChange}
                                value={FormFields.end_date}
                                error={props.Errors.end_date && true}
                                helperText={props.Errors.end_date}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="unit">Unit</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.unit)}
                                onChange={HandleOnChange}
                                required
                                inputProps={{
                                    name: 'unit',
                                    id: 'unit',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.units) !== 'Undefined' && FormInputs.units.map((t, i) =>
                                    <MenuItem value={t.id} key={'unit-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.unit && props.Errors.unit}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="amount"
                                name="amount"
                                label="Amount"
                                type="text"
                                fullWidth
                                value={FormFields.amount}
                                onChange={HandleOnChange}
                                error={props.Errors.amount && true}
                                helperText={props.Errors.amount}
                                InputLabelProps={{ shrink: true }}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="payroll_count"
                                name="payroll_count"
                                label="Payroll Count"
                                type="text"
                                fullWidth
                                value={FormFields.payroll_count}
                                onChange={HandleOnChange}
                                error={props.Errors.payroll_count && true}
                                helperText={props.Errors.payroll_count}
                                InputLabelProps={{ shrink: true }}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="based_on">Based On</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.based_on)}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'based_on',
                                    id: 'based_on',
                                }}>
                                {_R.type(base_components) !== 'Undefined' && _R.length(base_components) > 0 && base_components.map((b, i) =>
                                    <MenuItem value={b.id} key={'based-on-'+i}>{b.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.based_on && props.Errors.based_on}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {_R.type(FormFields.based_on) !== 'Null' &&
                    <>
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                           <TextField variant="standard"
                                id="lower_limit"
                                name="lower_limit"
                                label="Lower Limit"
                                type="text"
                                fullWidth
                                value={FormFields.lower_limit}
                                onChange={HandleOnChange}
                                error={props.Errors.lower_limit && true}
                                helperText={props.Errors.lower_limit}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="upper_limit"
                                name="upper_limit"
                                label="Upper Limit"
                                type="text"
                                fullWidth
                                value={FormFields.upper_limit}
                                onChange={HandleOnChange}
                                error={props.Errors.upper_limit && true}
                                helperText={props.Errors.upper_limit}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    </> }
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="pay_recurrence">Pay Recurrence</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.pay_recurrence)}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'pay_recurrence',
                                    id: 'pay_recurrence',
                                }}>
                                <MenuItem value={null} key={'pay-recurrence-0'}>Select</MenuItem>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.frequency_opt) !== 'Undefined' && FormInputs.frequency_opt.map((t, i) =>
                                    <MenuItem value={t.id} key={'pay-recurrence-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.pay_recurrence && props.Errors.pay_recurrence}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item  xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="pay_frequency">Pay Frequency</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.pay_frequency)}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'pay_frequency',
                                    id: 'pay_frequency',
                                }}>
                                <MenuItem value={null} key={'pay_frequency-0'}>Select</MenuItem>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.frequency_opt) !== 'Undefined' && FormInputs.frequency_opt.map((t, i) =>
                                    <MenuItem value={t.id} key={'pay_frequency-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.pay_frequency && props.Errors.pay_frequency}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="cap_unit">Cap Unit</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && parseInt(FormFields.cap_unit)}
                                onChange={HandleOnChange}
                                required
                                inputProps={{
                                    name: 'cap_unit',
                                    id: 'cap_unit',
                                }}>
                                {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.units) !== 'Undefined' && FormInputs.units.map((t, i) =>
                                    <MenuItem value={t.id} key={'unit-'+i}>{t.title}</MenuItem>
                                )}
                            </Select>
                            <small className='error'>{typeof props.Errors !== 'undefined' && props.Errors.cap_unit && props.Errors.cap_unit}</small>
                        </ FormControl>
                    </Grid>
                    : <></>}
                    {props.FormFields.id === 0 || ( props.FormFields.id && ConfigEditFlag )?
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                id="cap_figure"
                                name="cap_figure"
                                label="Cap Figure"
                                type="text"
                                fullWidth
                                value={FormFields.cap_figure}
                                onChange={HandleOnChange}
                                error={props.Errors.cap_figure && true}
                                helperText={props.Errors.cap_figure}
                                InputLabelProps={{ shrink: true }}
                            />
                        </ FormControl>
                    </Grid>
                    : <></>}
                    <Grid item xs={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="desc"
                                name="desc"
                                label="Description"
                                type="text"
                                fullWidth
                                value={FormFields.desc}
                                onChange={HandleOnChange}
                                multiline
                                rowsMax="4"
                                InputLabelProps={{ shrink: true }}
                                error={props.Errors.desc && true}
                                helperText={props.Errors.desc}
                            />
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
    )
}
const DialogConfirm = (props) => {

    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you want to add this component to staff config? It will also reflect in the latest compensation of all members in selected Staff Type.
                 </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                <FormControl className={"form-fullwidth"} variant="standard">
                    <InputLabel htmlFor="staff_type">Select Staff Type</InputLabel>
                    <Select
                        value={ props.SelectedStaffType ? props.SelectedStaffType : [] }
                        onChange={props.OnSelect}
                        multiple={true}
                    >
                    {_R.type(props.StaffTypes) !== 'Undefined' && props.StaffTypes.map((u, i) =>
                        <MenuItem value={u.id}>{u.type}</MenuItem>
                    )}
                    </Select>
                    <small className='error'>{props.Errors.staff_types && props.Errors.staff_types}</small>
                </ FormControl>
                </Grid>
            </Grid>
        </div>
    )
}

export const ComponentUI = (props) => {
    const {FormInputs, FF, OnDelete, OnUpdate} = props;
    const dataArr = (_R.type(FF) !== 'Undefined' && _R.length(FF) !== 0)?FF:FormInputs.components;
    const staff_types = (_R.type(FormInputs.staff_types) !== 'Undefined' && _R.length(FormInputs.staff_types) !== 0)?FormInputs.staff_types:[];
    /*const [formValues, setValues] = React.useState({
        'title': ''
    });

    const handleChange = name => event => {
        setValues({ ...formValues, [name]: event.target.value });
    };*/
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title="Components"
                        columns={[
                            { 
                                title: 'Title', 
                                field: 'title',  
                            },
                            { title: 'Type', field: 'type' },
                            { title: 'Sub-Type', field: 'sub_type'},
                            { title: 'Tab', field: 'tab'},
                            { title: 'Status', field: 'status', lookup: { 1: 'Active', 0: 'Inactive' } },
                            { title: 'Description', field: 'description', hidden: true},
                            { title: 'Created By', field: 'created_by', },
                            { title: 'Created On', field: 'created_on'},
                        ]}
                        data={dataArr}
                        options={{
                            actionsColumnIndex: -1,
                            columnsButton:true,
                            toolbar:true,
                            pageSize:10,
                            pageSizeOptions: [10,20,30],
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Component',
                                isFreeAction: true,
                                hidden: (props.Edit) ? false : true,
                                onClick: (event) => { props.OnAdd() }
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                hidden: (props.Edit) ? false : true,
                                onClick: (event, rowData) => {
                                    OnUpdate(rowData.id);
                                }
                            }
                        ]}
                        editable={{
                            isDeletable: rowData => (props.Delete) ? true : false,
                            onRowDelete: rowData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                    {
                                        const index = props.FormInputs.components.indexOf(rowData);
                                        OnDelete(rowData.id, index);
                                    }
                                    resolve()
                                    }, 500)
                            }),
                        }}
                        detailPanel={[
                            {
                                render: ({ rowData }) => {
                                    return (
                                        <div class="staff_tabel-detail-panel">
                                            <div class="staff_tabel-detail-item"><label>Date:</label><span class="value">{rowData.start_date} - {rowData.end_date}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Based On:</label><span class="value">{rowData.based_on}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Lower Limit:</label><span class="value">{rowData.lower_limit}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Upper Limit:</label><span class="value">{rowData.upper_limit}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Unit:</label><span class="value">{rowData.unit}</span></div>
                                            <div class="staff_tabel-detail-item">
                                                <label>Amount:</label>
                                                <span class="value">{(!_R.isNil(rowData.unit) && _R.toLower(rowData.unit) === 'fixed') ? rowData.figure : ((!_R.isNil(rowData.unit) && !_R.empty(rowData.unit)) ? rowData.figure + '%' : rowData.figure)}</span>
                                            </div>
                                            <div class="staff_tabel-detail-item"><label>Payroll Count:</label><span class="value">{rowData.payroll_count}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Pay Recurrence:</label><span class="value">{rowData.pay_recurrence_title}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Pay Frequency:</label><span class="value">{rowData.pay_frequency_title}</span></div>
                                            <div class="staff_tabel-detail-item"><label>Cap Unit:</label><span class="value">{rowData.cap_unit_title}</span></div>
                                            <div class="staff_tabel-detail-item">
                                                <label>Cap Amount:</label>
                                                <span class="value">{(!_R.isNil(rowData.cap_unit_title) && _R.toLower(rowData.cap_unit_title) === 'fixed') ? rowData.cap_figure : ((!_R.isNil(rowData.cap_unit_title) && !_R.empty(rowData.cap_uniti_title)) ? rowData.cap_figure + '%' : rowData.cap_figure)}</span>
                                            </div>

                                            <div> </div>
                                            <div class="">
                                                <span class="value">
                                                    <IconButton aria-label="edit">
                                                        <EditIcon data-row_id={rowData.config_id} data-parent_id={rowData.id} onClick={props.HandleConfigUpdate} />
                                                    </IconButton>
                                                </span>
                                            </div>
                                            <div class="">
                                                <span class="value">
                                                    <IconButton aria-label="delete">
                                                        <DeleteIcon data-row_id={rowData.config_id} data-component_id={rowData.id} onClick={props.HandleConfigDelete} />
                                                    </IconButton>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        ]}
                    />
                </Grid>
            </Grid>
            <DialogBoxMedium 
                    Open={props.Open} 
                    Cancel={props.OnClose}
                    Title={props.IsEditable?'Edit Component':'Add Component'}
                    OnSubmit={props.OnSubmit}
                    Content={<DialogContent FormProps={props} Components={dataArr}/>}/>
            <DialogBoxMedium
                Open={props.ConfirmPopup}
                Cancel={props.CancelClicked}
                OnSubmit={props.ConfirmClicked}
                ButtonName = 'Yes'
                CancelButtonName = 'No'
                Title='Confirm'
                Content={
                    <DialogConfirm
                        Errors = {props.Errors}
                        StaffTypes = {staff_types}
                        OnSelect = {props.OnSelect}
                        SelectedStaffType={props.SelectedStaffType}
                    />
                }
            />
            <CustomizedSnackbars
            open={props.SnackbarOpen}
            variant={props.SnackVariant}
            message={props.Message}
            handleClose={props.SnackbarClose}
        />
        </React.Fragment>
    )
};
