import React from 'react';

//import {history} from './../../helpers/history';
import {httpServices}  from '../../services/httpServices';
import {JobTitleComponent}  from './JobTitle.Component';

const formFields = {
    'id':0,
    'title': '',
    'level': 'null',
};

export class JobTitleView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            jobtitle : [],
            formFields : formFields,
            open : false,
            isEditable : false,
            errors : {},
            popupType : 'add',
            hasPermission : true,
            editPermission : false,
            deletePermission : false,
        };
         this.editJobTitleClick = this.editJobTitleClick.bind(this);
         this.handleOnChange = this.handleOnChange.bind(this);
         this.onClose = this.onClose.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
         this.onAddJobTite = this.onAddJobTitle.bind(this);
         this.deleteJobTitleClick = this.deleteJobTitleClick.bind(this);     
         this.viewDetail = this.viewDetail.bind(this);
    }
    componentDidMount = () => {
        this.getAllJobTitle();
        this.getAllLevels();
    };
    getAllJobTitle = () => {
        let apiEndpoint = 'api/settings/getAllJobTitle';
        return httpServices.get(apiEndpoint)
        .then(response => {
            if (response.data.permission) {
                if (response.data.jobtitle && response.data.jobtitle.length) {
                    this.setState({
                        jobtitle : response.data.jobtitle,
                        editPermission : response.data.edit_permission,
                        deletePermission : response.data.delete_permission,
                        hasPermission : true,
                    });
            } else {
                this.setState({
                    hasPermission : false,
                });
            }
        }
        else {
            this.setState({
                hasPermission : false,
            });
        }
    })          
        .catch(function(error) {
           console.log("Error: "+error);
         });
    };   
    getAllLevels = () => {
        let apiEndpoint = 'api/settings/getAllLevels';
        return httpServices.get(apiEndpoint)
        .then(response => {
            if (response.data.permission) {
                if (response.data.levels && response.data.levels.length) {
                    this.setState({levellist : response.data.levels});
            } else {
                this.setState({
                    hasPermission : false,
                });
            }
        }
    })          
        .catch(function(error) {
           console.log("Error: "+error);
         });
    };
    viewDetail  = (id) =>{
        this.setState({
            id: id
     });
       // history.push('api/settings/getJobTitleDetailsByLevelId' + '/' + id);
    }  
    editJobTitleClick = (id) => {
        let apiEndpoint = 'api/settings/get-job-title-details-by-id';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.job_details) {
                        this.setState({
                            formFields : response.data.job_details,
                            open : true,
                            isEditable : true,
                            popupType : 'add',
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };
    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    onAddJobTitle = () => {
        if(this.state.jobtitle.length < 1 && this.props.jobtitle){
            this.setState({jobtitle : this.props.jobtitle});
        }
      
        let formFields = {
            'id':0,            
            'title': '',
            'level':'',           
          };          
        this.setState({
            open : true,
            formFields : formFields,
            isEditable : false,
            popupType : 'add',
        });        
    };
    getJobLevelById = (id) => {
        if(this.state.levellist){
            let jtitle = '';
            let levels = this.state.levellist;
            levels.forEach(function(item){
                if(item.id === id){
                    jtitle = item.name;
                }
            });
            return jtitle;
        }
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;            
            if (data) {
                 httpServices.post('api/settings/saveJobTitle', { data })
                    .then(response => {
                        if (response.data.status) {
                            let level  = this.getJobLevelById(data.level);
                            if(level){
                                data['name'] = level;
                            }
                            if(data.id){
                                // Update DOM
                                const index = this.state.jobtitle.findIndex(rr => rr.id === data.id);
                                let jobtitle = this.state.jobtitle;
                                jobtitle[index] = data;
                                this.setState({
                                    jobtitle : jobtitle,
                                    open : false
                                });
                                alert("Job Title updated successfully");
                            } else {
                                let jobtitle = this.state.jobtitle;
                                data['id'] = response.data.id;
                                jobtitle.push(data);
                                this.setState({
                                    jobtitle : jobtitle,
                                    open : false,
                                });
                                alert("Job Title saved successfully");
                            }
                        } else {
                            if (response.data.validation_errors) {
                                this.setState({ errors: response.data.validation_errors });
                            }
                            else
                                alert(response.data.error);
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.level) {
            errors["level"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.title.trim()) {
            errors["title"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }
    deleteJobTitleClick = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteJobId : id,
            });
        }
    };
    deleteJobTitle = () => {
        let del_id = this.state.deleteJobId;
        if (del_id) {
            let apiEndpoint = 'api/settings/deleteJobTitle';
            return httpServices.post(apiEndpoint,{id : del_id})
                .then(response => {
                    if (response.data.status) {
                        const index = this.state.jobtitle.findIndex(rr => rr.id === del_id);
                        let jobtitle = this.state.jobtitle;
                        jobtitle.splice(index, 1);
                        this.setState({
                            jobtitle : jobtitle,
                            open : false
                        });
                        alert("Job Title deleted successfully");
                    } else {
                        alert(response.data.err_msg);
                        this.setState({
                            open : false
                        });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    };
 
    render(){
        return (
            <JobTitleComponent
                JobTitle = {this.state.jobtitle}  
                handleViewDetail={this.viewDetail}
                EditJobTitleClick = {this.editJobTitleClick}
                Open = {this.state.open}  
                FormInputs = { this.props.formInputs }
                FormFields = {this.state.formFields}
                ListFields = {this.state.levellist}
                HandleOnChange = {this.handleOnChange}
                OnClose = {this.onClose}
                HandleSubmit = {this.handleSubmit}
                Errors = {this.state.errors}
                OnAddJobTitleClick = {this.onAddJobTitle}                
                PopupType = {this.state.popupType}
                DeleteJobTitleClick = {this.deleteJobTitleClick}      
                DeleteJobTitle = {this.deleteJobTitle}  
                EditPermission = {this.state.editPermission}
                DeletePermission = {this.state.deletePermission}   
                HasPermission = {this.state.hasPermission}               
            />
        )
    }
}
