import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

export const LocationsComponent = (props) => {
    const locatons = typeof props.locations !== 'undefined' ? props.locations : [];
     return (
        <>
            <div className={'listSubHeader pr-2'}>
                <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                {props.EditPermission &&
                <Tooltip title="Add Location" aria-label="add">
                    <Button variant="contained" color="primary" size="small" onClick={props.AddLocationClick}>Add</Button>
                </Tooltip>
                }
            </div>
            
            <TableContainer component={Paper}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Color Code</TableCell>
                            {props.EditPermission && 
                            <TableCell>Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(locatons.length === 0) &&
                            <TableRow >
                                <TableCell>No locatons found.</TableCell>
                            </TableRow>
                        }
                        {locatons.map((item, index) =>
                        <TableRow hover>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.color_code}</TableCell>
                            {props.EditPermission &&
                            <TableCell>
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Edit">
                                        <EditIcon fontSize="small" onClick={()=>props.EditClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                                {/*<Tooltip title="Delete" aria-label="Delete">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Delete">
                                        <DeleteOutlinedIcon fontSize="small" onClick={()=>props.HandleDeleteClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>*/}
                            </TableCell>
                            }
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.PopupType === 'add' || props.PopupType === 'edit') &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.PopupType === 'add' ? 'Add Location' : 'Edit Location' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogAdd FormProps = { props }/> }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVariant} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            {props.PopupType === 'delete' &&
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.OnClose}
                OnSubmit={props.DeleteLocation}
                Title='Confirm Delete' 
                ButtonName='Ok'                
                Content={
                    <DialogWithConfirmMessage msg="Do you want to delete this Location" />
                }
            />
            }
        </>
    )
}

const DialogAdd = (props) => {
    console.log(props.FormProps);
    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "location"
                            name = "location"
                            label = "Work Location"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.location : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.location !== 'undefined' && Errors.location)&& true }
                            helperText = { typeof Errors.location !== 'undefined' && Errors.location }
                        />
                        </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="type"  required >Type</InputLabel>
                        <Select
                            value={FormFields.type}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'type',
                                id: 'type'
                            }}
                            className={'input-item'}
                        >
                                { typeof props.FormProps.location_type != 'undefined' && props.FormProps.location_type.map((t,i) =>
                                    <MenuItem value={t}>{t}</MenuItem>
                                    
                                )}
                            
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "description"
                            name = "description"
                            label = "Description"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.description : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.description !== 'undefined' && Errors.description)&& true }
                            helperText = { typeof Errors.description !== 'undefined' && Errors.description }
                        />
                        </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="status"  required >Status</InputLabel>
                        <Select
                            value={FormFields.status}
                            onChange={HandleOnChange}
                            inputProps={{
                                name: 'status',
                                id: 'status'
                            }}
                            className={'input-item'}
                        >
                            <MenuItem value='ACTIVE'>ACTIVE</MenuItem>
                            <MenuItem value='INACTIVE'>INACTIVE</MenuItem>
                        </Select>
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField variant="standard"
                            id="color_code"
                            name="color_code"
                            label="Color Code"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={_R.type(FormFields) !== 'Undefined' ? FormFields.color_code : ''}
                            onChange={HandleOnChange}
                            error={(typeof Errors.color_code !== 'undefined' && Errors.color_code) && true}
                            helperText={typeof Errors.color_code !== 'undefined' && Errors.color_code}
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}

const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}
