import 'date-fns';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import * as _R from 'ramda';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { CustomizedSnackbars } from '../../common/snackbars';
import { DialogBoxMedium } from '../../common/dialog.medium';


export const AdditionalptoAddComponent = props => {
    return (
        <React.Fragment>
            <DialogBoxMedium
                Open={props.Open}
                Cancel={props.Close}
                ButtonName='Submit' 
                OnSubmit={props.FormFields.id !== 0 ? ()=>props.AddRequest(props.FormFields.id) : ()=>props.AddRequest(0)}
                Title={props.Title+' Additional pto Request'}
                Loading = {props.ButtonLoader}
                Content={
                  <DialogAddAdditionalpto FormProps={props}  />
                }
            />
             <CustomizedSnackbars
                open={props.Snackbar}
                variant={props.Valid?'success':'error'}
                message={props.Valid? props.SuccessMessage:props.ErrorMessage}
                handleClose={props.SnackbarClose}
            />
        </React.Fragment>
    )
}

const DialogAddAdditionalpto = (props) => {
    const {FormProps} = props;
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id = "hours"
                            name = "hours"
                            label = "Hours"
                            variant='standard'
                            type = "hours"
                            placeholder ="[Format: nn.nn or HH:MM ]"
                            required
                            value = {FormProps.FormFields.hours}
                            onChange = {FormProps.HandleOnChange}
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            error = { typeof(FormProps.Errors) !== 'undefined' && !_R.isNil(FormProps.Errors.hours) && FormProps.Errors.hours }
                            helperText = { typeof(FormProps.Errors) !== 'undefined' && !_R.isNil(FormProps.Errors.hours) && FormProps.Errors.hours }
                        />
                    </ FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl variant = 'standard'>
                        <InputLabel htmlFor="years" required error={ FormProps.Errors.year && true}>Year</InputLabel>
                        <Select
                            value = { FormProps.FormFields.year }
                            onChange = { FormProps.HandleOnChange }
                            inputProps = {{
                                name: 'year',
                                id: 'year',
                            }}>
                            { FormProps.years.map((c) =>
                                <MenuItem value={c} >{c}</MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{FormProps.Errors.year}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl>
			<TextField
                            id = "description"
                            name = "description"
                            label = "Reason"
                            required
                            type = "text"
                            multiline={true}
                            variant="outlined"
                            value = {FormProps.FormFields.description}
                            onChange = {FormProps.HandleOnChange}
                            error = { FormProps.Errors.description && true }
                            helperText = { FormProps.Errors.description }
                        />
                    </ FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    {(FormProps.PtoDetails)? 
                         <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colspan='3'>PTO Information</TableCell>
                                </TableRow>
                            </TableHead>
                            { _R.type(FormProps.PtoDetails) !== 'Undefined' && FormProps.PtoDetails.map((value, index) =>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row"> {value['label']} </TableCell>
                                        <TableCell component="th" scope="row"> {value['hours']} Hours </TableCell>
                                        <TableCell component="th" scope="row"> {value['days']} Days </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                        </>
                        :<></>}
                </Grid>
            </Grid>                        
        </form> 
    )
}

