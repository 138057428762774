import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatName } from "../../common/common";
import IconButton from "@mui/material/IconButton";
import FormHelperText from '@mui/material/FormHelperText';
import EditIcon from "@mui/icons-material/Edit";
import { DialogBoxMedium } from '../../common/dialog.medium';
import TextField from '@mui/material/TextField';
import { CustomizedSnackbars } from '../../common/snackbars';
import * as _R from 'ramda';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import TablePagination from '@mui/material/TablePagination';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import { CustomMaterialTable } from '../../common/CustomMaterialTable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const FilterButtonStyles = styled('div')(({ theme }) => ({
    '& .filterSubmitButton':{
        marginTop:'20px'
    },
    '& .filterResetButton':{
        marginTop:'20px',
        marginLeft:'10px'
    },
})); 
const DialogStyles = styled(Dialog)(({ theme }) => ({   
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
    
    closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const ResourcesComponent = (props => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
        '& .tooltip': {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 500,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid black',
        },
      }));

    return (
        <React.Fragment>
        {(props.OtherPermissions.allView || props.OtherPermissions.pmView || props.OtherPermissions.resourceView) &&
            <div className={'section-main'}>
                <div className={'listSubHeader'}>
                    <div className={'col-left'}>
                        <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary">
                        </Typography>
                        {props.InheritResourceFlag && 
                        <p className="error">
                            Resources are inherited from the parent project and can only be managed from there.
                        </p>}
                    </div>
                    <div className={'action'}>
                    {(props.EditPermission) && (!props.InheritResourceFlag) &&
                        <Tooltip title="Add Project Resource" aria-label="add">
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={props.OnAdd}>
                            <AddIcon />
                        </Fab>
                        </Tooltip>
                    }
                    <Tooltip title="Filter">
                    <IconButton onClick={props.FilterView('right', true)}>
                        <FilterListIcon color="action" />
                    </IconButton>
                    </Tooltip>
                    <Drawer open={props.FilterViewState} anchor={'right'} onClose={props.FilterView('right', false)}>
                    { <div className={'tree-view-drawer'}>
                        <div className={'drawer-title'}>Filter</div>
                        <ResourceFilterComponent
                            FilterFields = {props.FilterFields}
                            ResetFilter = {props.ResetFilter}
                            AssinedResourceList = {props.AssinedResourceList}
                            Lcat = {props.Lcat}
                            ContractVehicles = {props.ContractVehicles}
                            GsaLcats = {props.GsaLcats}          
                            EmployeeStatus = {props.EmployeeStatus}
                            HandleFilterOnSubmit = {props.HandleFilterOnSubmit}
                            HandleFilterOnChange = {props.HandleFilterOnChange}
                            NameFormat = {props.NameFormat}
                        />
                    </div>
                    }
                    </Drawer>
                </div>
            </div>
            <>
            <TableContainer >
            <Table aria-label="simple table"  >
                <TableHead>
                <TableRow>
                    <TableCell> Resource Name </TableCell>
                    <TableCell> Labor Category </TableCell>
                    {(typeof props.ContractVehicles !== 'undefined'  && props.ContractVehicles.length > 0) &&
                        <TableCell> GSA Labor Category </TableCell>
                    }
                    <TableCell> Start Date </TableCell>
                    <TableCell> End Date </TableCell>
                    <TableCell> Staff Type </TableCell>
                    <TableCell> Employment Status</TableCell>
                    {!props.InheritResourceFlag && <TableCell width="150"></TableCell>}
                </TableRow>
                </TableHead>
            {(props.ProjectResources.length === 0) &&
                <TableBody>
                <TableRow>
                <TableCell width ="200">No resource found</TableCell>
                </TableRow>
                </TableBody>
            }
            <TableBody>
            {Object.keys(props.ProjectResources).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                <>
                <TableRow className={(props.ProjectResources[row].task_count === null || props.ProjectResources[row].task_count === "0") ? 'warning-bg' : ''}>
                <TableCell> {formatName(props.ProjectResources[row].first_name, null, props.ProjectResources[row].last_name)}</TableCell>
                {(props.ProjectResources[row].lcat_info_data.length > 1) ?
                    <TableCell>
                    <HtmlTooltip placement="bottom"
                        title={
                        <React.Fragment>
                    <TableContainer >
                    <Table aria-label="simple table" >
                    <TableHead>
                    <TableRow>
                    <TableCell> Labor Category </TableCell>
                    <TableCell> Start Date </TableCell>
                    <TableCell> End Date </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {(props.ProjectResources[row].lcat_info_data).length > 1 && props.ProjectResources[row].lcat_info_data.map((r, i) =>
                        <TableRow  >
                        <TableCell>{r.lcat_name} {r.is_excluded == 1?<Chip style={{backgroundColor:'white', color:'red',height:'15px',fontSize:'0.6rem', width:'70px'}} label="Excluded!" variant="outlined" />: '' }</TableCell>
                        <TableCell>{r.start_date}</TableCell>
                        <TableCell>{r.end_date}</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    </React.Fragment>
                        }>
                    <span className={'MuiTableCell-tooltip-container'}><InfoIcon/>
                    {props.ProjectResources[row].labour_category }</span>
                    </HtmlTooltip>
                
                    </TableCell>

                    :
                    <TableCell> {props.ProjectResources[row].labour_category }</TableCell>
                }
                {(typeof props.ContractVehicles !== 'undefined'  && props.ContractVehicles.length > 0) &&
                    <TableCell>{props.ProjectResources[row].gsa_lcat_name }</TableCell>
                }
                <TableCell>{props.ProjectResources[row].start_date}</TableCell>
                <TableCell>{props.ProjectResources[row].end_date}</TableCell>
                <TableCell>{props.ProjectResources[row].staff_type_name}</TableCell>
                <TableCell>{props.ProjectResources[row].user_status_name }</TableCell>

                {!props.InheritResourceFlag && <TableCell align="right">
                {(props.EditPermission === true) &&
                    <Tooltip title="Edit" aria-label="edit">
                        <IconButton   className={'action-btn'} edge="end" aria-label="Edit">
                            <EditIcon fontSize="small" onClick={()=>props.OnUpdate(props.ProjectId, props.ProjectResources[row].id)}/>
                        </IconButton>
                    </Tooltip>
                }
                {(props.EditPermission === true &&  props.DeletePermission === true) &&
                    <Tooltip title="Delete" aria-label="delete">
                        <IconButton   className={'action-btn'} edge="end" aria-label="Delete">
                            <DeleteIcon fontSize="small" onClick={()=>props.DeleteResource(props.ProjectResources[row].id)}/>
                        </IconButton>
                    </Tooltip>
                }
                {((props.Project['project_paytype'] === 1 || props.Project['project_paytype'] === 2) && props.ProjectResources[row].override_rate_eligible === true) &&
                   <Tooltip title="LCR Override" aria-label="lcrOverride">
                   {(typeof props.ProjectResources[row].resource_rate_count !== 'undefined'  && props.ProjectResources[row].resource_rate_count > 0) ?
                   <IconButton   className={'action-btn'} edge="end" aria-label="lceOverride">
                       <AttachMoneyIcon color="primary" fontSize="small" onClick={()=>props.OnUpdateLcrRate(props.ProjectId, props.ProjectResources[row].id)}/>
                   </IconButton>:
                   <IconButton   className={'action-btn'} edge="end" aria-label="lceOverride">
                       <AttachMoneyIcon fontSize="small" onClick={()=>props.OnUpdateLcrRate(props.ProjectId, props.ProjectResources[row].id)}/>
                   </IconButton> }
                   </Tooltip>
                }
                </TableCell>
                }
                </TableRow> 
              </>
            )}
            </TableBody>
            </Table>
            </TableContainer>
            {<TablePagination
                rowsPerPageOptions={[15, 30, 50]}
                component="div"
                count={props.ProjectResources.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
		onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
            </>
            <div className={'warning-bg info-iconbox'}> No task assigned </div>
            </div>
        }
        <DialogBoxMedium
        Open={props.Open}
        Cancel={props.OnClose}
        Title={props.IsEditable? "Edit Project Resource" : "Add Project Resource"}
        OnSubmit={props.IsEditable ? ()=>props.HandleResourceConfirm(props.Id) : ()=>props.HandleSubmit(props.Id) }
        Content={<DialogContentEdit FormProps={props} />}
        Loading = {props.ButtonLoader}
        />
        {(props.OpenRate === true) &&
            <DialogContentRate
                Open={props.OpenRate}
                Cancel={props.OnClose}
                FormValues ={props.FormValues}
                HandleRateSubmit = {props.HandleRateSubmit}
                HandleOnRateChange = {props.HandleOnRateChange}
                Result = {props.Result}
                RateMessage = {props.RateMessage}
                ResourceStartDate = {props.ResourceStartDate}
                ResourceEndDate = {props.ResourceEndDate}
                ResourceInfo = {props.ResourceInfo}
                NameFormat = {props.NameFormat}
            />
        }
        <CustomizedSnackbars
        open={props.Snackbar}
        variant={props.Variant}
        message={props.Message}
        handleClose={props.SnackbarClose}
        />
        <DialogBoxMedium
            Open={props.ResourceConfirm}
            Title={'Confirm Update'}
            ButtonName ={'Yes'}
            DisplayNoButton={true}
            HideCancel={true}
            OnNoClick={()=>props.HandleResourceConfirm(props.Id,"No")}
            OnSubmit={()=>props.HandleResourceConfirm(props.Id,"yes")}
            Content={<DialogResourceConfirm FormProps={props} />}
        />
        <DialogBoxMedium
            Open = {props.ConfirmDelete}
            Cancel = {props.OnClose}
            ButtonName = {"Delete"}
            OnSubmit = {()=>props.DeleteResource(props.Id,"yes")}
            Title = {"Confirmation"}
            Content = {
                <DialogWithConfirmMessage
                    msg = {"Forecast Allocation exists for this resource. Allocation will be removed for the resource, Do you want to delete this resource?"}
                />
            }
        />
        </React.Fragment>
    );
});
const DialogWithConfirmMessage = (props) => {
    return (
        <>
            <div>{props.msg}</div>
        </>
    )
}

const DialogResourceConfirm = (props) => {
    return (
        <div>
            <Box className={'mt-1'}>
                 <Typography variant="subtitle2" gutterBottom>
                    Do you want to update project labor category resource end date?
                </Typography>
            </Box>
        </div>
    )
}

const ResourceFilterComponent = (props) => {
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={3}>
                 <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status"> Resource Name </InputLabel>
                        <Select
                            inputProps={{
                            name: 'resourceName',
                            id: 'resourceName',
                        }}
                        value = {!_R.isNil(props.FilterFields.resourceName) && props.FilterFields.resourceName}
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.AssinedResourceList ) !== 'undefined' && props.AssinedResourceList.map((n, i) =>
                            <MenuItem value={n.user_id} key={'c-'+i}>{formatName(n.first_name, null, n.last_name)}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status"> Labor Category  </InputLabel>
                        <Select
                            inputProps={{
                            name: 'laborCategory',
                            id: 'laborCategory',
                        }}
                        value = {!_R.isNil(props.FilterFields.laborCategory) && props.FilterFields.laborCategory}
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.Lcat) !== 'undefined' && props.Lcat.map((t, i) =>
                            <MenuItem value={t.id} key={'c-'+i}>{t.name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                {(typeof props.ContractVehicles !== 'undefined'  && props.ContractVehicles.length > 0) &&
                <>
                 <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status"> Contract Vehicle  </InputLabel>
                        <Select
                            inputProps={{
                            name: 'contractVehicle',
                            id: 'contractVehicle',
                        }}
                        value = {!_R.isNil(props.FilterFields.contractVehicle ) && props.FilterFields.contractVehicle }
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.ContractVehicles) !== 'undefined' && props.ContractVehicles.map((t, i) =>
                            <MenuItem value={t.contract_vehicle_id } key={'c-'+i}>{t.name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status"> GSA Labor Category  </InputLabel>
                        <Select
                            inputProps={{
                            name: 'gsaLaborCategory',
                            id: 'gsaLaborCategory',
                        }}
                        value = {!_R.isNil(props.FilterFields.gsaLaborCategory) && props.FilterFields.gsaLaborCategory}
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.GsaLcats) !== 'undefined' && props.GsaLcats.map((t, i) =>
                            <MenuItem value={t.id} key={'c-'+i}>{t.name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
                </>
                }
                 <Grid item xs={9}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="emp-status">Employment Status</InputLabel>
                        <Select
                            inputProps={{
                            name: 'employmentStatus',
                            id: 'employmentStatus',
                        }}
                        value = {!_R.isNil(props.FilterFields.employmentStatus) && props.FilterFields.employmentStatus}
                        onChange = {props.HandleFilterOnChange}
                        >
                        <MenuItem value="0" >All</MenuItem>
                        {typeof(props.EmployeeStatus) !== 'undefined' && props.EmployeeStatus.map((t, i) =>
                            <MenuItem value={t.user_status_id} key={'c-'+i}>{t.user_status_name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <FilterButtonStyles>
                        <Button variant="contained" color="primary" onClick={props.HandleFilterOnSubmit} className={'filterSubmitButton'} > Submit </Button>
                    </FilterButtonStyles>
                </Grid>
                <Grid item>
                    <FilterButtonStyles>
                        <Button variant="outlined" color="primary" onClick={props.ResetFilter} className={'filterResetButton'} > Reset </Button>
                    </FilterButtonStyles>
                </Grid>
            </Grid>
        </form>
    )
};

const DialogContentRate = (props) => {
    const [resourceStart, setResourceStart] = React.useState([props.ResourceStartDate]);
    const [resourceEnd, setResourceEnd] = React.useState([props.ResourceEndDate]);
    const [columns, setColumns] = React.useState([
       { title: 'Start Date', field: 'start_date', type :'date', validate: rowData => (rowData.start_date === '' || typeof rowData.start_date === 'undefined' || !rowData.start_date) ? false : true,
          editComponent: (props) =>  {
              return (
                (resourceEnd == '')?
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  autoOk
                  disableToolbar
                  margin="normal"
                  id="start_date"
                  format="MM/dd/yyyy"
                  clearable
                  minDate={resourceStart}
                  value = {(typeof props.rowData.start_date !== 'undefined' && props.rowData.start_date) ? props.rowData.start_date : null}
                  error={(typeof props.rowData.start_date === 'undefined' || props.rowData.start_date === null) && true}
                  onChange={(e) =>
                      props.onChange(e)
                  }
                  InputProps={{
                    style: {
                        fontSize: 14,
                        height: 22,
                        width : 246,
                    }
                  }}
                  />
                  </LocalizationProvider>
                :
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  autoOk
                  disableToolbar
                  margin="normal"
                  id="start_date"
                  format="MM/dd/yyyy"
                  clearable
                  minDate={resourceStart}
                  maxDate = {resourceEnd}
                  value = {(typeof props.rowData.start_date !== 'undefined' && props.rowData.start_date) ? props.rowData.start_date : null}
                  error={(typeof props.rowData.start_date === 'undefined' || props.rowData.start_date === null) && true}
                  onChange={(e) =>
                      props.onChange(e)
                  }
                  InputProps={{
                    style: {
                        fontSize: 14,
                        height: 22,
                        width : 246,
                    }
                  }}
                  />
                  </LocalizationProvider>
              )},
      },
      { title: 'End Date', field: 'end_date', type: 'date', 
              editComponent: (props) =>  {
                  return (
                    (resourceEnd == '')?
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                      autoOk
                      disableToolbar
                      margin="normal"
                      id="end_date"
                      format="MM/dd/yyyy"
                      clearable
                      value = {(typeof props.rowData.end_date !== 'undefined' && props.rowData.end_date) ? props.rowData.end_date : null}
                      onChange={(e) => 
                          props.onChange(e)
                      }
                      minDate={resourceStart}
                      InputProps={{
                        style: {
                            fontSize: 14,
                            height: 22,
                            width : 246, 
                        }
                      }}
                      />
                      </LocalizationProvider>
                      :
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                      autoOk
                      disableToolbar
                      margin="normal"
                      id="end_date"
                      format="MM/dd/yyyy"
                      clearable
                      value = {(typeof props.rowData.end_date !== 'undefined' && props.rowData.end_date) ? props.rowData.end_date : null}
                      onChange={(e) =>
                          props.onChange(e)
                      }
                      minDate={resourceStart}
                      maxDate={resourceEnd}
                      InputProps={{
                        style: {
                            fontSize: 14,
                            height: 22,
                            width : 246,
                        }
                      }}
                      />
                      </LocalizationProvider>
                  )},
      },
      { title: 'CTC Type', field: 'type', lookup: {'hourly': 'Hourly', 'monthly': 'Monthly'}, initialEditValue: 'hourly' },
      { title: 'Rate', field: 'CTC_rate', validate: rowData => (rowData.CTC_rate === '' || typeof rowData.CTC_rate === 'undefined' || !rowData.CTC_rate) ? false : true,},

  ]);
    return (
         <DialogStyles  className={'modal_default large'} open={props.Open} aria-labelledby="scroll-dialog-title" >
            <DialogTitle id="scroll-dialog-title">{"LCR Override Rate :- "+ formatName(props.ResourceInfo.first_name, null, props.ResourceInfo.last_name)}
                <IconButton aria-label="close" className={'closeButton'} onClick={props.Cancel}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
        {(props.Result === true) &&
            <div className={'root'}>
             <Alert severity="success"> {props.RateMessage} </Alert>
            </div>
        }
        {(props.Result === false) &&
            <div className={'root'}>
                <Alert severity="error"> {props.RateMessage} </Alert>
            </div>
        }
                <CustomMaterialTable
                    columns={columns}
                    data = {props.FormValues} 
                    options={{
                        actionsColumnIndex : -1,
                        search : false,
                        paging : false,
                        toolbar : true,
                        showTitle : false,
                    }}
                    localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this LCR Override Rate?' } } }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                        props.HandleRateSubmit('add', newData);
                                resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                             new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    props.HandleRateSubmit('update', newData);
                                resolve();
                                }, 1000)
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    props.HandleRateSubmit('delete', oldData);
                                    resolve();
                                }, 1000)
                            }),
                    }}
                />
            </DialogContent>
        <DialogActions>
        </DialogActions>
    </DialogStyles>
    )
}

const DialogContentEdit = (p) => {
    const {FormProps:props} = p;
    return (
        <form className="form-fullwidth" >
            <Grid container spacing={4}>
                 <Grid item xs={12} md={12}>
                        {props.IsEditable ?
                            <div className="form-group-readonly"> 
                                <label> Resource Name  </label>
                                <span className={"value"}>  {(typeof props.FormFields.resourceName !== 'undefined') ? props.FormFields.resourceName : null} </span>
                            </div>

                            :
                        <FormControl error={props.Errors.resourceName && true} variant="standard">
                            <InputLabel htmlFor="resourceName" required> Resource Name </InputLabel>
                            <Select
                                value={(typeof props.FormFields.resourceName !== 'undefined') ? props.FormFields.resourceName : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'resourceName',
                                    id: 'resourceName',
                                }}>
                                <MenuItem value=""><em>-Select-</em></MenuItem>
                                { typeof props.Users != 'undefined' && props.Users.map((idt, i) =>
                                    <MenuItem value={idt.user_id} key={'idtypes-'+i}>{formatName(idt.first_name, null, idt.last_name)}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{props.Errors.resourceName}</FormHelperText>
                        </ FormControl>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            required
                            id = "start_date"
                            name = "start_date"
                            label = "Start Date"
                            type = "date"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { props.FormFields.start_date }
                            onChange = { props.HandleOnChange }
                            error = { props.Errors.start_date }
                            helperText = { props.Errors.start_date }
                            variant="standard"
                        />
                    </ FormControl>
                </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                        <TextField
                            id = "end_date"
                            name = "end_date"
                            label = "End Date"
                            type = "date"
                            InputLabelProps =  {{
                                shrink: true,
                            }}
                            value = { props.FormFields.end_date }
                            onChange = { props.HandleOnChange }
                            variant="standard"
                        />
                    </ FormControl>
                </Grid>
                </Grid>
                <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                        {(props.Project.labor_category === 1 )?

                        <FormControl error={props.Errors.lbCategoryName && true} variant="standard">
                            <InputLabel shrink htmlFor="lbCategoryName" required>Labor Category Name</InputLabel>
                            <Select
                                value={(typeof props.FormFields.lbCategoryName !== 'undefined') ? props.FormFields.lbCategoryName : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'lbCategoryName',
                                    id: 'lbCategoryName',
                                }}>
                                <MenuItem value=""><em>-Select-</em></MenuItem>
                                { typeof props.Lcat != 'undefined' && props.Lcat.map((idt, i) =>
                                    <MenuItem value={idt.id} key={'idtypes-'+i}>{idt.name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{props.Errors.lbCategoryName}</FormHelperText>
                        </ FormControl>
                            :
                              <FormControl variant="standard">
                            <InputLabel shrink htmlFor="lbCategoryName" >Labor Category Name</InputLabel>
                            <Select
                                value={(typeof props.FormFields.lbCategoryName !== 'undefined') ? props.FormFields.lbCategoryName : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'lbCategoryName',
                                    id: 'lbCategoryName',
                                }}>
                                <MenuItem value=""><em>-Select-</em></MenuItem>
                                { typeof props.Lcat != 'undefined' && props.Lcat.map((idt, i) =>
                                    <MenuItem value={idt.id} key={'idtypes-'+i}>{idt.name}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                        }
                    </Grid>
                </Grid>
                 {(typeof props.ContractVehicles !== 'undefined'  && props.ContractVehicles.length > 0) &&
                <>
                    <Grid container spacing={6}>
                     <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="contractVehicle" >Contract Vehicle</InputLabel>
                            <Select
                                value={(typeof props.FormFields.contractVehicle !== 'undefined') ? props.FormFields.contractVehicle : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'contractVehicle',
                                    id: 'contractVehicle',
                                }}>
                                <MenuItem value=""><em>-Select-</em></MenuItem>
                                { typeof props.ContractVehicles != 'undefined' && props.ContractVehicles.map((idt, i) =>
                                    <MenuItem value={idt.contract_vehicle_id} key={'idtypes-'+i}>{idt.name}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="gsaLaborCategory" >GSA Labor Category</InputLabel>
                            <Select
                                value={(typeof props.FormFields.gsaLaborCategory !== 'undefined') ? props.FormFields.gsaLaborCategory : ''}
                                onChange={props.HandleOnChange}
                                inputProps={{
                                    name: 'gsaLaborCategory',
                                    id: 'gsaLaborCategory',
                                }}>
                                <MenuItem value=""><em>Select</em></MenuItem>
                                { typeof props.GsaLcats != 'undefined' && props.GsaLcats.map((idt, i) =>
                                    <MenuItem value={idt.id} key={'idtypes-'+i}>{idt.name}</MenuItem>
                                )}
                            </Select>
                        </ FormControl>
                    </Grid>
                     </Grid>
                     </>
                }
        </form>
    )
}

