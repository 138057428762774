import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const API_URL = process.env.REACT_APP_API_URL;
export const WorkHoursReportComponent = (props) =>{
     const gridStyle = {
        margin: '15px 0px'
    };
        return(
            <React.Fragment>
            <Paper md={12}>
            <div class="p1">
            <Typography variant="h6" component="h6" className={'section-title'}>Work Hours Report</Typography>
                <form id="export-form" className="form-fullwidth" action={API_URL+'api/report/generate-work-hours-report'} method="post">
                    <Grid container spacing={2} style={gridStyle}>
                       <Grid item  md={2} className='justify-between'>
                            <FormControl variant="standard">
                                <InputLabel>Staff status</InputLabel>
                                <Select
                                    value={(typeof props.ReportFields.staff_status !== 'undefined') ? props.ReportFields.staff_status : 0}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff_status',
                                        id:   'staff_status',
                                    }}>
                                    <MenuItem value='0'>-All-</MenuItem>
              { typeof props.ReportFields.staff_statuses != 'undefined' && props.ReportFields.staff_statuses !=='' && props.ReportFields.staff_statuses.map((t, i) =>
                                    <MenuItem value={t.user_status_id} key={'stst-'+i}>{t.user_status_name}</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                        </Grid>
                      <Grid item  md={2} className='justify-between'>
                            <FormControl>
                                <TextField variant="standard"
                                    value = {props.ReportFields.on_date}
                                    id="on_date"
                                    name="on_date"
                                    label="Date"
                                    type="date"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
				    onChange={props.HandleOnChange}
                                    error = { typeof props.Errors.on_date !== 'undefined' && true}
                                    helperText = { typeof props.Errors.on_date !== 'undefined' && props.Errors.on_date }
                                />
                            </FormControl>
                        </Grid>



                        <Grid item >
                            <Button size="small" color="primary" onClick={props.ExportReports} name="export" variant="contained">Export</Button>
                        </Grid>
                    </Grid>
                </form>
                </div>
            </Paper>
        </React.Fragment>
        )
}
