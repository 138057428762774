import cookie from 'react-cookies';
import axios from 'axios';
import { logout } from '../modules/common/logout';

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const httpServices = {
    get,
    post,
    put,
    deleteDetail
};

//Request options
export function getOptions(){
    let options = {};
    // `headers` are custom headers to be sent

    if(cookie.load(AUTH_KEY)){
        options.headers = { 
            'x-apikey': cookie.load(AUTH_KEY)
        };
    }
    return options;
};
//GET Request
function get(apiEndpoint) {
    return axios.get(API_URL+apiEndpoint, getOptions()).then((response)=>{
        return response;
    }).catch((err)=>{
        let errStatus = err.response.status;
        if (errStatus === 401) { // Unautorized
            logout(401);
        } else if (errStatus === 503) {
            window.location = BASE_PATH + "/under-maintenance";
        } else {
            let error_obj = {
                server_response : err,
                api_endpoint : apiEndpoint,
            }
            localStorage.setItem('server-error', JSON.stringify(error_obj));
            window.location = BASE_PATH + "/server-error";
        }
    })
}

//POST Request
function post(apiEndpoint, payload){
    return axios({
        method: 'post',
        url: API_URL+apiEndpoint,
        transformRequest: [function (data, headers) {
            // we need to set Content-Type to undefined,
            // to make the browser set it to multipart/form-data
            // and fill in the correct *boundary*.
            // setting Content-Type to multipart/form-data manually
            // will fail to fill in the boundary parameter of the request.
            headers['Content-Type'] = undefined;
            return objectToFormData(data);
        }],
        data: payload,
        config: getOptions()
    })
    .then(function (response) {
        //handle success
        return response;

    })
    .catch(function (err) {
        //handle error
        let errStatus = err.response.status;
        if (errStatus === 401) { // Unautorized
            logout(401);
        } else if (errStatus === 503){
            window.location = BASE_PATH + "/under-maintenance";
        } else {
            let error_obj = {
                server_response : err,
                api_endpoint : apiEndpoint,
            }
            localStorage.setItem('server-error', JSON.stringify(error_obj));
            window.location = BASE_PATH + "/server-error";
        }
    });
}

//PUT Request
function put(apiEndpoint, payload){
    return axios.put(API_URL+apiEndpoint, payload, getOptions()).then((response)=>{
        return response;
    }).catch((err)=>{
        console.log(err);
    })
}

// DELETE Request
function deleteDetail(apiEndpoint){
    return axios.delete(API_URL+apiEndpoint, getOptions()).then((response)=>{
        return response;
    }).catch((err)=>{
        console.log(err);
    })
}

//
function objectToFormData (obj, form, namespace) {
    var fd = form || new FormData();
    var formKey;
    for(var key in obj) {
        if(obj.hasOwnProperty(key)) {
            if(namespace) {
                formKey = namespace + '[' + key + ']';
            } else {
                formKey = key;
            }

            // if the key is an object, but not a File,
            // use recursivity.
            if(typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
                objectToFormData(obj[key], fd, key);
            } else if(obj[key]) {
                // if it's a string or a File object
                fd.append(formKey, obj[key]);
            }
        }
    }
    return fd;
}
