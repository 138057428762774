import React from 'react';
import { ChangeLogComponent} from './ChangeLog.Component';
import { httpServices } from '../../../services/httpServices';

export class ChangeLogContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            permission : false,
            showLoader : true,
            title : 'Change Log',
            changeLog  : [],
            project : [],
            selectedEntity : 'Project' ,
        }
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange = (evt, value) => {
        this.setState({
            selectedEntity : evt.target.value
        },
        function(){
            this.getChangeLogDetails(this.props.ProjectId)
        });
    };
    componentDidMount(){
        this.getChangeLogDetails(this.props.ProjectId);
    }
    getChangeLogDetails = (projectId) => {
        let apiEndpoint = 'api/project/ChangeLog';
        let selectedEntity =this.state.selectedEntity;
        return httpServices.post(apiEndpoint,{project_id : projectId,entity:selectedEntity})
            .then(response => {
                if (response.data) {
                    if (response.data.permission) {
                        this.setState({
                            project : response.data.project,
                            changeLog : response.data.changeLog,
                            showLoader : false,
                            permission : response.data.permission,
                        });
                    }
                    else {
                        this.setState({
                            showLoader : false
                        });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    render(){
        return (
            <ChangeLogComponent 
            ChangeLogs = {this.state.changeLog}
            Project = {this.state.project}
            selectedEntity ={this.state.selectedEntity}
            handleOnChange = {this.handleOnChange}
            />
        )
    }
}
