import React from 'react';

import { httpServices } from '../../../../services/httpServices';
import { IdProofDetailsComponent } from "./IdProof.Component";

const formFields = {
    'id':0,
    'idcard_id': null,
    'id_number': null,
};

export class IdProofDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            idProofDetails : [],
            open : false,
            formFields : formFields,
            errors : {},
            IsEditable : false,
            idProof_flag :0,
        };
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
    }
    componentDidMount = () => {
        let idProofDetails=this.props.idProofDetails?this.props.idProofDetails:[];
        let idProofDisplay={};
        idProofDetails.map((t, i) =>{
                idProofDisplay[t.idcard_id]=1;
            });
         this.setState({
             idProofDetails : idProofDetails,
             idProofDisplay : idProofDisplay,   
         });
        
    }

    onAdd = () => {        
        if(this.state.idProofDetails.length < 1 && this.props.idProofDetails){
            this.setState({idProofDetails : this.props.idProofDetails});
        }
        let formFields = {
            'id':0,
            'idproof_types': null,
            'idproof_number': null,
        };
        this.setState({
            open : true,
            formFields : formFields,
        });
    };

    onClose = () => {
        this.setState({
            open : false,
            errors : {}
        });
    };
    handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        f[name] = evt.target.value;
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.staffId; 
                httpServices.post('api/staff/personal/saveIdProofDetails', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (!response.data.exist) {
                                if (response.data.idProofDetails) {
                                    let idProofDisplay =this.changeIdProofDisplay();
                                    idProofDisplay[data.idproof_types]=1;
                                    this.setState({idProofDetails:response.data.idProofDetails,idProofDisplay:idProofDisplay});
                                    this.setState({open:false});
                                    alert("Saved Successfully");
                                }
                            }
                            else
                            {
                                alert("Already Exist");
                            }
                        }
                        else {
                            this.setState({errors : response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    onUpdate = (id) => () => {
        if(this.state.idProofDetails.length < 1 && this.props.idProofDetails){
            this.setState({idProofDetails : this.props.idProofDetails});
        }
        if(id){
            httpServices.post('api/staff/personal/getIdProofDetailsById', { id : id })
                .then(response => {
                    let idProofDetails = response.data.idProofDetails;
                    if(idProofDetails){
                        this.setState({
                            open : true,
                            formFields : idProofDetails,
                            IsEditable : true,
                        });
                     }
                })
                .catch(function(error) {
                    console.log("error" + error);
                });
        }
    };

    onDeleteSubmit = (id) => () => {
        if (id) {
            let confirm_delete = window.confirm("Do you want to delete this entry");
            if(confirm_delete){
                if(this.state.idProofDetails.length < 1 && this.props.idProofDetails){
                    this.setState({idProofDetails : this.props.idProofDetails});
                }
                let data = {
                    id : id,
                    staff_id : this.props.staffId
                };
                httpServices.post('api/staff/personal/deleteIdProofDetails', { data })
                    .then(response => {
                        this.setState({idProofDetails : response.data.idProofDetails,idProofDisplay:this.changeIdProofDisplay(),open: false});
                        alert("Deleted successfully");
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.idcard_id) {
            errors["idcard_id"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.id_number) {
            errors["id_number"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    getDisabilityTypeById = (id) => {
        if(this.props.formInputs.disability_types){
            let title = '';
            let disability_types = this.props.formInputs.disability_types;
            disability_types.forEach(function(item){
                if(item.id === id){
                    title = item.title;
                }
            });
            return title;
        }
    };
    getIdProof_number =(staff_id,idcard_id,index) =>{
        let idProofDisplay=this.changeIdProofDisplay(idcard_id);
        index=idProofDisplay[idcard_id]? -1 :index;console.log(index);
        httpServices.post('api/staff/get-ssn-by-staff-id', {staff_id,idcard_id,index})
            .then(response => {
                if (response.data) {
                    this.setState({
                        idProofDetails :response.data.idProofDetails,
                        idProofDisplay:idProofDisplay,
                    });
                }
            });
    }
    changeIdProofDisplay = (index) =>{
        let idProofDisplay=this.state.idProofDisplay;
        let idProof_flag=(index && idProofDisplay[index])?0:1;
        Object.keys(idProofDisplay).forEach(key => idProofDisplay[key] = 1);  
        if(index){
            idProofDisplay[index]=idProof_flag;
        }
        return idProofDisplay;
    }
    render(){
        return (
            <IdProofDetailsComponent 
                IdProofDetails = {this.state.idProofDetails.length > 0 ? this.state.idProofDetails : this.props.idProofDetails}
                open = { this.state.open }
                onAdd = { this.onAdd }
                FormFields = { this.state.formFields } 
                Errors = { this.state.errors }
                HandleOnChange = { this.handleOnChange }
                onClose = { this.onClose }
                FormInputs = { this.props.formInputs }
                OnSubmit = { this.handleSubmit }
                staffId = { this.props.staffId }
                onUpdate = { this.onUpdate }
                getIdProof_number = { this.getIdProof_number }
                onDeleteSubmit = { this.onDeleteSubmit }
                IsEditable = { this.state.IsEditable }
                EditPermission = { this.props.Edit }
                DeletePermission = { this.props.Delete }
                idProofnumber_view = {this.props.idProofnumber_view}
                idProofnumber_edit = {this.props.idProofnumber_edit}
                idProofnumber_delete = {this.props.idProofnumber_delete}
                idProofnumber_fullview = {this.props.idProofnumber_fullview}
		        idProof_flag={ this.state.idProofDisplay }
            />
        )
    }
}
