import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { formatName } from '../../common/common';
import { TreeSelectComponent } from '../../common/TreeSelectComponent';
import * as _ts from '../../common/treeSelect';
import * as _R from 'ramda';
import { styled } from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;

export const TimesheetSummaryReportComponent = (props) => {
     const gridStyle = {
        margin: '15px 0px'
    };
    const formValues = props.FormFields ? props.FormFields : {};
    const formInputs = props.FormInputs ? props.FormInputs : {};
    let projects = (_R.type(formInputs.projects) !== 'Undefined') ? formInputs.projects : [];
    if (projects.length) {
        _ts.findProjectKey(projects, ((_R.type(formValues.project) !== 'Undefined') ? [formValues.project] : []));
    }
    const headers = typeof props.ReportHeaders !== 'undefined' ? props.ReportHeaders : {};
    const data = typeof props.ReportData !== 'undefined' ? props.ReportData : {};
    const StyledPaper = styled(Paper) (({ theme }) => ({
        '& .clientBg': {
            backgroundColor: '#A3D1FF !important',
        },
        '& .projectBg': {
            backgroundColor: '#E0FFD6 !important',
        },
        '& .etotal': {
            backgroundColor: '#FFD699 !important',
        },
        '& .totalWorking': {
            backgroundColor: '#000 !important',
            Color: '#fff !important'
        },
        '& .tableWrapper': {
            height: '500px',
            overflow: 'auto',
            width: '100%'
        }
    }));
    
    return (
        <>
        <Paper>
            <Grid container class="p1">
                <Grid item>
                    <h6 className="MuiTypography-root section-title MuiTypography-h6">Timesheet Summary Report</h6>
                </Grid>
                <Grid item>
                <form className="form-fullwidth" action={API_URL+'api/report/hrreport'} method="post" id="hrreport">
                <input type="hidden" id="export_btn" name="export_btn" value="export" /> 
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "startDate"
                                    name = "startDate"
                                    label = "Start Date"
                                    type = "date"
                                    required
                                    onChange = {props.HandleOnChange}
                                    value={(typeof formValues.startDate !== 'undefined') ? formValues.startDate : null}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { max: (typeof formValues.startDateMax !== 'undefined') ? formValues.startDateMax : null} 
                                    }}
                                    error = { props.Errors.startDate && true }
                                    helperText = { props.Errors.startDate }
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl>
                                <TextField variant="standard"
                                    id = "endDate"
                                    name = "endDate"
                                    label = "End Date"
                                    required
                                    type = "date"
                                    value={(typeof formValues.endDate !== 'undefined') ? formValues.endDate : null}
                                    onChange = {props.HandleOnChange}
                                    InputLabelProps = {{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: { min: (typeof formValues.minEndDate !== 'undefined') ? formValues.minEndDate : null} 
                                    }}
                                    error = { props.Errors.endDate && true }
                                    helperText = { props.Errors.endDate }
                                />
                            </ FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <FormControl error={props.Errors.clientStatus && true} variant="standard">
                                <InputLabel>Client Status</InputLabel>
                                <Select
                                    value={(typeof formValues.clientStatus !== 'undefined') ? formValues.clientStatus : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'clientStatus',
                                        id: 'clientStatus',
                                    }}
                                >
                                    <MenuItem value={'0'}>{"All"}</MenuItem>
                                    { typeof formInputs.clientStatuses != 'undefined' && formInputs.clientStatuses.map((c, i) =>
                                    <MenuItem value={c.client_status_id}>{c.client_status_name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.clientStatus}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={props.Errors.client && true} variant="standard">
                                <InputLabel>Client</InputLabel>
                                <Select
                                    value={(typeof formValues.client !== 'undefined') ? formValues.client : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'client',
                                        id: 'client',
                                    }}
                                >
                                    <MenuItem value={'0'}>{"All"}</MenuItem>
                                    { typeof formInputs.clients != 'undefined' && formInputs.clients.map((cl, i) =>
                                    <MenuItem value={cl.client_id}>{cl.client_name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.client}</FormHelperText>
                         </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={props.Errors.projectStatus && true} variant="standard">
                                <InputLabel>Project Status</InputLabel>
                                <Select
                                    value={(typeof formValues.projectStatus !== 'undefined') ? formValues.projectStatus : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'projectStatus',
                                        id: 'projectStatus',
                                    }}
                                >
                                    <MenuItem value={'0'}>{"All"}</MenuItem>
                                    { typeof formInputs.projectStatuses != 'undefined' && formInputs.projectStatuses.map((p, i) =>
                                    <MenuItem value={p.project_status_id}>{p.project_status_name}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.projectStatus}</FormHelperText>
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl>
                                <TreeSelectComponent
                                    dropdown_mode={'true'}
                                    data={formInputs.projects}
                                    placeholderText={"Search Projects"}
                                    type='project'
                                    HandleTreeChange={props.HandleTreeChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item  xs={12} sm={6} md={4}>
                         <input type='hidden' name='staff_status_ids' value={(typeof formValues.staff_status_ids !== 'undefined' && formValues.staff_status_ids) ? formValues.staff_status_ids : []}/>
                        <Autocomplete
                            value={(!_R.isEmpty(formValues.staffStatus) && formValues.staffStatus) ? formValues.staffStatus : (typeof formValues.staffStatusList !== 'undefined' ? [formValues.staffStatusList[0]] :[])}
                            onChange={props.HandleStaffStatusChange}
                            multiple
                            id="tags-outlined"
                            options={(typeof formValues.staffStatusList !== 'undefined') ? formValues.staffStatusList : []}
                            getOptionLabel={(option) => option.user_status_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Staff Status"
                                    placeholder="Select Staff Status"
                                />
                            )}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={props.Errors.staff && true} variant="standard">
                                <InputLabel>Staff Name</InputLabel>
                                <Select
                                    value={(typeof formValues.staff !== 'undefined') ? formValues.staff : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'staff',
                                        id: 'staff',
                                    }}
                                >
                                    <MenuItem value={'0'}>{"All"}</MenuItem>
                                    { typeof formInputs.staffs !== 'undefined' && Object.keys(formInputs.staffs).map((key) =>
                                    <MenuItem value={formInputs.staffs[key].user_id}>{formatName(formInputs.staffs[key].first_name,null,formInputs.staffs[key].last_name)}</MenuItem>
                                    )}

                                </Select>
                                <FormHelperText >{props.Errors.staff}</FormHelperText>
                              </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={props.Errors.nameFormat && true} variant="standard">
                                <InputLabel>Staff Name Format</InputLabel>
                                <Select
                                    value={(typeof formValues.nameFormat !== 'undefined') ? formValues.nameFormat : ''}
                                    onChange={props.HandleOnChange}
                                    inputProps={{
                                        name: 'nameFormat',
                                        id: 'nameFormat',
                                    }}
                                >
                                    { typeof formInputs.nameFormats != 'undefined' && formInputs.nameFormats.map((nm, i) =>
                                    <MenuItem value={nm.key}>{nm.value}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText >{props.Errors.nameFormat}</FormHelperText>
                           </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={gridStyle}>
                        <Grid item xs={12} md={8}>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className="d-flex align-center justify-end">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={props.HandleOnChange}
                                            value={(typeof formValues.includeTask !== 'undefined') ? formValues.includeTask : false}
                                            checked={(typeof formValues.includeTask !== 'undefined') ? formValues.includeTask : false}
                                            inputProps={{
                                                name: 'includeTask',
                                                id: 'includeTask',
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Include Task"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2} className = {'filter-action'}>
                            <div className="filter-column">
                                <div className="d-flex align-center justify-end pr-2">
                                    <input type = 'hidden' name='user_status' value={(typeof formValues.staffStatus !== 'undefined') ? JSON.stringify(formValues.staffStatus) : []}/>
                                    <input type='hidden' name='project' value={typeof formValues.project !== 'undefined' ? formValues.project : 0} />
                                    <Button variant="contained" size="small" color="primary" onClick={props.FormSubmit} >Submit</Button>
                                    <Button className={'ml-1'} size="small" color="primary" variant="contained" onClick={props.ExportReports} value="export" name="export_btn">Export</Button>
                                </div>
                            </div>
                        </Grid> 
                    </Grid>
                </form>
                </Grid>
            </Grid>
        </Paper>
        <div className={'ReportList  justify-between align-stretch'}>
            <StyledPaper>
               <TableContainer>
                    <>
                    {(data && Object.keys(data).length > 0 && Object.keys(headers).length > 0) && 
                    <Grid container>
                        <div className={'tableWrapper'}>
                        <Table  stickyHeader aria-label="Report List" className={'list-table_basic-year table-light-bordered'} border={1}>
                            <TableHead className={'thead'}>
                                <TableRow>
                                 { Object.keys(headers).map((key) =>
                                    <TableCell>{headers[key]}</TableCell>
                                 )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {  Object.keys(data).map((key) =>
                                <>
                                   {(data[key]['type'] === 'client') &&
                                        <>
                                            <TableRow className={'clientBg'}>
                                                {Object.keys(headers).map((tempHead,i) =>
                                                    <>
                                                        {data[key][i] ?
                                                        <TableCell className={i !== 0 ? 'text-right' : ''}>
                                                            {data[key][i]}
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                        </TableCell>
                                                        }
                                                    </>
                                                )}
                                            </TableRow>
                                        </>
                                    }
                                    {(data[key]['type'] === 'project') &&
                                        <>
                                            <TableRow className={'projectBg'}>
                                                {Object.keys(headers).map((tempHead,i) =>
                                                    <>
                                                        {data[key][i] ?
                                                        <TableCell className={i !== 0 ? 'text-right' : ''}>
                                                            {data[key][i]}
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                        </TableCell>
                                                        }
                                                    </>
                                                )
                                                }
                                            </TableRow>
                                        </>
                                    }
                                    {(data[key]['type'] === 'etotal') &&
                                        <>
                                            <TableRow className={'etotal'}>
                                                {Object.keys(headers).map((tempHead,i) =>
                                                    <>
                                                        {data[key][i] ?
                                                        <TableCell className={i !== 0 ? 'text-right' : ''}>
                                                            {data[key][i]}
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                        </TableCell>
                                                        }
                                                    </>
                                                )
                                                }
                                            </TableRow>
                                        </>
                                    }
                                    {(data[key]['type'] === 'total_working') &&
                                        <>
                                            <TableRow className={'bg-black'}>
                                                {Object.keys(headers).map((tempHead,i) =>
                                                    <>
                                                        {data[key][i] ?
                                                        <TableCell className={i !== 0 ? 'text-right' : ''}>
                                                            {data[key][i]}
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                        </TableCell>
                                                        }
                                                    </>
                                                )
                                                }
                                            </TableRow>
                                        </>
                                    }
                                    {(data[key]['type'] !== 'total_working' && data[key]['type'] !== 'client' && data[key]['type'] !== 'project' && data[key]['type'] !== 'etotal') &&
                                        <>
                                            <TableRow>
                                                {Object.keys(headers).map((tempHead,i) =>
                                                    <>
                                                        {data[key][i] ?
                                                        <TableCell className={i !== 0 ? 'text-right' : ''}>
                                                            {data[key][i]}
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                        </TableCell>
                                                        }
                                                    </>
                                                )}
                                            </TableRow>
                                        </>
                                    }
                                </>
                            )}
                            </TableBody>
                        </Table>
                        </div>
                    </Grid>
                    }
                    </>
                </TableContainer>
                </StyledPaper>
            </div>
        </>
     )
}
