import React from 'react';
import {StaffCountReportComponent} from './StaffCountReportComponent';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import * as _ms from '../../common/multiSelect';
import * as validations from '../../common/validate';

const formFields = {
    'start_date' : '',
    'end_date' : '',
    'report_type' : 'pay-period',
    'staff_type' : [0],
    'work_locations' : [0],
    'gender' : [0],
    'genderwise_report_flag' : false,
    'exclude_work_location_flag' : false,
}

const formInputs = {
    staff_types : [],
    work_locations : [],
    report_types : [
        {name:'Pay Period',value:'pay-period'},
        {name:'Monthly',value:'monthly'}
    ],
    gender_opts : [],
}

export class StaffCountReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            formFields: formFields,
            formInputs: formInputs,
            hasPermission : true,
            errors : {},
            selectedWorkLocations: [],
            selectedStaffTypes: [],
            selectedGenderOpts : [],
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.OnSelect = this.onSelect.bind(this);
        this.OnRemove = this.onRemove.bind(this);
    }
    componentDidMount() {
        this.index();
        window.scrollTo(0, 0);
    }
    index = () => {
        httpServices.get('api/report/staffCountReport',{})
            .then(response => {
                let selectedWorkLocations = [] ;
                if (response.data.permission) {
                    selectedWorkLocations = this.state.selectedWorkLocations;
                    let selectedStaffTypes = this.state.selectedStaffTypes;
                    let finputs = this.state.formInputs;
                    let locations = _ms.prepareData(response.data.work_locations);
                    finputs.work_locations = locations.list;
                    let staff_types = _ms.prepareData(response.data.staff_types);
                    finputs.staff_types = staff_types.list;
                    selectedWorkLocations = locations.selected;
                    selectedStaffTypes = staff_types.selected;
                    let gender_options =  _ms.prepareData(response.data.gender_opts);
                    finputs.gender_opts = gender_options.list;
                    this.setState({
                        formInputs: finputs,
                        selectedWorkLocations : selectedWorkLocations,
                        selectedStaffTypes : selectedStaffTypes,
                        selectedGenderOpts : gender_options.selected,
                    });
                } else {
                    this.setState({
                        hasPermission : false,
                    });
                }
            })
    }
    onSelect = (selectedList, selectedItem) => {
    let selected = [];
    let f = this.state.formFields;
    let selectedWorkLocations = this.state.selectedWorkLocations;
    let selectedStaffTypes = this.state.selectedStaffTypes;
    let selectedGenderOpts = this.state.selectedGenderOpts; 
    if (typeof selectedItem.id !== 'undefined' && typeof selectedItem.name !== 'undefined') {
        if (selectedItem.id !== 0) {
            if(selectedList[0].id === 0) {
                selectedList.splice(0,1);
            }
        }
        else {
            selectedList = [{id:0,name:"All Locations",short_code:'All'}]
        }
        selectedList.forEach((p, v) =>{
            selected.push(p.id);
        })
        selectedWorkLocations = selectedList;
        f.work_locations = selected;
    }
    if (typeof selectedItem.id !== 'undefined' && typeof selectedItem.type !== 'undefined') {
        if (selectedItem.id !== 0) {
            if(selectedList[0].id === 0) {
                selectedList.splice(0,1);
            }
        }
        else {
            selectedList = [{id:0,type:"All"}]
        }
        selectedList.forEach((p, v) =>{
            selected.push(p.id);
        })
        selectedStaffTypes = selectedList;
        f.staff_type = selected;
    }
    if (typeof selectedItem.id !== 'undefined' && typeof selectedItem.title !== 'undefined') {
        if (selectedItem.id !== 0) {
            if(selectedList[0].id === 0) {
                selectedList.splice(0,1);
            }
        }
        else {
            selectedList = [{id:0,title:"All"}]
        }
        selectedList.forEach((p, v) =>{
            selected.push(p.id);
        })
        f.gender = selected;
        selectedGenderOpts = selectedList;
    }

    this.setState({
            formFields: f,
            selectedWorkLocations: selectedWorkLocations,
            selectedStaffTypes: selectedStaffTypes,
            selectedGenderOpts : selectedGenderOpts,
        });
    }


    onRemove = (selectedList, removedItem) => {
        let selected = [];
        let f = this.state.formFields;
        if (typeof removedItem.id !== 'undefined' && typeof removedItem.name !== 'undefined') {
           if((selectedList[0] == null )) {
               selected.push(0);
               selectedList = [{id:0, name:"All Locations", short_code:'All'}]
           }
           else {
                selectedList.forEach((p, v) =>{
                    selected.push(p.id);
                })
           }
        this.setState({selectedWorkLocations : selectedList});
        f.work_locations = selected;
        }
        if (typeof removedItem.id !== 'undefined' && typeof removedItem.type !== 'undefined') {
           if((selectedList[0] == null )) {
               selected.push(0);
               selectedList = [{id:0, type:"All"}]
           }
           else {
            selectedList.forEach((p, v) =>{
                selected.push(p.id);
            })
           }
        this.setState({selectedStaffTypes : selectedList});
        f.staff_type = selected;
        }
        this.setState({
            formFields: f,
        });
    }


    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        let value = evt.target.value;
        if (field === 'staff_type') {
            if (value.includes('All')) {
                value = ['all'];
            }
        }
        if (field === 'work_locations') {
            if (value.includes('All')) {
                value = ['all'];
            } else if (value.includes('other')) {
                value = ['other'];
            }
        }
        if (field === 'genderwise_report_flag') {
            value = evt.target.checked;
        }
        if (field === 'exclude_work_location_flag') {
            value = evt.target.checked;
        }
        ff[field] = value;
        this.setState({
            formFields: ff,
        });
    }
    validateRequest() {
        let validationData = [
            { name: 'start_date', value: this.state.formFields.start_date, type: 'date', otherOptions: { required: true } },
            { name: 'end_date', value: this.state.formFields.end_date, type: 'date', otherOptions: { required: true } },
            { name: 'report_type', value: this.state.formFields.report_type, type: 'text', otherOptions: { required: false } }
        ]
        let validateResponse = validations.validateFormElements(validationData);
        let validateErrors = validateResponse.errors;
        let isValid = validateResponse.valid;
        let formIsValid = true;
        if (isValid) {
            let errors = {};
            let start_date = this.state.formFields.start_date;
            let end_date = this.state.formFields.end_date;
            if (new Date(end_date) <= new Date(start_date)) {
                errors['end_date'] = 'Please select valid dates';
                formIsValid = false;
            }
            this.setState({ errors: errors });
        } else {
            formIsValid = false;
            this.setState({ errors: validateErrors });
        }
        return formIsValid;
    }
    formSubmit = () => {
        let valid = this.validateRequest();
        if (valid) {
            document.getElementById("staffCountForm").submit();
        }
    }
    render(){
        return(
            (this.state.hasPermission)
            ?
            <StaffCountReportComponent 
                FormInputs = { this.state.formInputs }
                FormFields = { this.state.formFields }
                HandleOnChange = { this.handleOnChange }
                FormSubmit = {this.formSubmit}
                Errors = {this.state.errors}
                OnSelect = {this.onSelect}
                OnRemove = {this.onRemove}
                SelectedWorkLocations = {this.state.selectedWorkLocations}
                SelectedStaffTypes = {this.state.selectedStaffTypes}
                SelectedGenderOpts = {this.state.selectedGenderOpts}
                DisplayLocation = {this.state.formFields.exclude_work_location_flag ? false : true }
            />
            :<ErrorPage/>
        )
    }
}
