import React from 'react';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import {ImportExpenseComponent} from './ImportExpense.Component';
import { httpServices } from '../../../services/httpServices';

const formFields = {
    'voucherStatus' :-1, 
    'year': new Date().getFullYear(),
    'month': 0,
    'employee' :0,
    'expPayDate' :null,
};
export class ImportExpenseContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            formFields : formFields,
            hasPermission : true,
            permisionError: '',
            file: {},
            successMessage: '',
            errorMessage: '',
            dialogOpen: false,
            dialogVarient: '',
            dialogMessage: '',
            showLoader: true,
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPermision = this.checkPermision.bind(this);
        this.snakbarClose = this.snakbarClose.bind(this);        
    }

    componentDidMount = () => {
        this.checkPermision();
    };
    
    onFileChange = (evt, value) => {
        const files = this.state.file;
        const name = evt.target.name;
        if (name === "file") { 
            files['file'] = evt.target.files[0];
            this.setState({
              file: files,
             });
         }
    }

    snakbarClose = (props) => {
        this.setState({
            dialogOpen: false,
        });

    }
    
    checkPermision = (props) => { 
        let value = {'action' : 'Expense.expense.entry.edit'}
        httpServices.post('api/expenses/checkpermision', value)
            .then(response => { 
                if (response.data) {
                    if (response.data.permission === false) {
                        this.setState({
                            permisionError: "You are not authorized to do this action"
                        });
                    }
                    this.setState({ showLoader: false });
                }

            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    handleSubmit = (props) => { 
        props.preventDefault();
        if(!this.state.file['file']){
            this.setState({
                successMessage: '',
                errorMessage: 'Please select a file',
                permisionError: '',
                dialogOpen: true,
                dialogVarient: 'error',
                dialogMessage: "Please select a file"
            });  
        }
        else
        {

            let value = {'doc' : this.state.file}
            httpServices.post('api/expenses/import', value)
                .then(response => { 
                    if(response.data.permission===false)
                    { 
                        this.setState({
                            successMessage: '',
                            errorMessage: '',
                            permisionError: "You are not authorized to do this action"
                        });
                        document.getElementById("file").value = null;
                    }
                    else
                    { 
                        if(response.data.status === 0)
                        {   
                            this.setState({
                                successMessage: '',
                                errorMessage: response.data.upload_msg,
                                permisionError: '',
                                dialogOpen: true,
                                dialogVarient: 'error',
                                dialogMessage: response.data.upload_msg
                            });
                            document.getElementById("file").value = null;
                        }
                        else
                        {
                            this.setState({
                                successMessage: response.data.upload_msg,
                                errorMessage: '',
                                permisionError: '',
                                dialogOpen: true,
                                dialogVarient: 'success',
                                dialogMessage: response.data.upload_msg,
                            });  
                            window.location.href = "submit";
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
        props.preventDefault();
    }

    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
          <ImportExpenseComponent 
                FormFields = {this.state.formFields}
                onFileChange={this.onFileChange}
                handleSubmit={this.handleSubmit}
                checkPermision={this.checkPermision}
                permisionError={this.state.permisionError}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
                dialogOpen = {this.state.dialogOpen}
                snakbarClose={this.snakbarClose}
                dialogVarient={this.state.dialogVarient}
                dialogMessage={this.state.dialogMessage}
            />
            </>
        )
    }
}
