import React from 'react';
import {CustomMaterialTable, CustomMTableToolbar} from '../../common/CustomMaterialTable';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material//Edit';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as _R from 'ramda';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import { nl2br } from '../../common/common';
import { CustomizedSnackbars } from '../../common/snackbars';
import { ClientEditContainer } from './ClientEdit.Container';
 


const styles = theme => ({
    drawerList: {
        width: 250,
        padding:'20px'
    },
    drawerFormControl:{
        width:'100%'
    },
    filterSubmitButton:{
        marginTop:'20px'
    },
    filterButton:{
        position:'absolute',
        top:"0.5rem",
        right:"0.5rem",
    },
    filterResetButton:{
        marginTop:'20px',
        marginLeft:'10px'
    },
});

const customButton = (props) => {
    return (<Button variant="contained" color="primary" size='small'>Add Client</Button>);
}

const SideList = withStyles(styles)(p => {
      return (<div role="presentation" className={p.classes.drawerList}>
            <Typography component="div" variant="button" display="block" gutterBottom color="primary">Filter</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}
                            value = {!_R.isNil(p.FilterValues.status) && p.FilterValues.status }
                            onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {_R.type(p.FilterInputs.status !== 'Undefined') && p.FilterInputs.status.map((s, i) =>
                            <MenuItem value={s.client_status_id} key={'st-'+i}>{s.client_status_name}</MenuItem>
                        )}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl className={p.classes.drawerFormControl} variant="standard">
                        <InputLabel htmlFor="client">Type</InputLabel>
                        <Select
                            inputProps={{
                                name: 'type',
                                id: 'type',
                            }}
                        value = {!_R.isNil(p.FilterValues.type) && p.FilterValues.type}
                        onChange = {p.HandleFilterChange}
                        >
                        <MenuItem value={0} key={'st-0'}>All</MenuItem>
                        {_R.type(p.FilterInputs.client_types !== 'Undefined') && p.FilterInputs.client_types.map((s, i) =>
                            <MenuItem value={s.id} key={'st-'+i}>{s.name}</MenuItem>
                        )}
                        </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Button variant="contained" color="primary" onClick={p.SubmitFilter} className={p.classes.filterSubmitButton}>Filter</Button>
                    <Button variant="outlined"  color="primary" onClick={p.ResetFilter} className={p.classes.filterResetButton}>Reset</Button>
                </Grid>
        </div>
    );
});

export const ClientListComponent = withStyles(styles)(props => {
    const columns = [
        { title: 'Name', field: 'client_name', removable: false, width :"20%" },
        { title: 'Code', field: 'client_code', removable: false, width:"10%" },
        { title: 'Description', field: 'client_description', removable: false, width:"25%", render: rowData =><span>{parse(nl2br(rowData.client_description))}</span>},
        { title: 'Type', field: 'type_name', removable: false, width:"10%" },
        { title: 'Status', field: 'client_status', removable: false, width:"10%" },
    ];
    const { classes, toggleDrawer, EditPermission } = props;
    return (
        <React.Fragment>
            <CustomMaterialTable
                title= "Clients"
                data = {props.Clients}
                columns={columns}
                actions={[
                    {
                        icon: customButton,
                        tooltip: 'Add Client',
                        isFreeAction: true,
                        onClick: (event, newData) => { props.AddClient() },
                        hidden: (EditPermission) ? false : true, 
                    },
                    rowData => ({
                        icon: Edit,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => { props.HandleEditClick(rowData.client_id)},
                        hidden : (EditPermission ) ? false : true, 
                    }),
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize:10,
                    search:true,
                    pageSizeOptions: [10,20,30],
                    thirdSortClick: false,
                    tableLayout: "fixed"
                }}
                components={{
                    Toolbar: props => (
                        <div style={{ position: 'relative', paddingRight:'50px' }}>
                                <CustomMTableToolbar {...props} />
                            <Tooltip title="Filter" aria-label="Filter">
                                <IconButton onClick={toggleDrawer('right', true)} className={classes.filterButton}><FilterList /></IconButton>
                            </Tooltip>
                        </div>
                        )
                    }}
                onRowClick={(event, rowData) => props.HandleViewDetails(rowData.client_id)}
            />
              <Drawer
                anchor="right"
                open={props.Right}
                onOpen={props.toggleDrawer('right', true)}
                onClose={props.toggleDrawer('right', false)}>
                <SideList
                    Right={'right'}
                    FilterValues = {props.FilterValues}
                    FilterInputs={props.FilterInputs}
                    HandleFilterChange={props.HandleFilterChange}
                    SubmitFilter={props.SubmitFilter}
                    ResetFilter={props.ResetFilter}
                />
            </Drawer>

            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
            <ClientEditContainer
                Page = {"ClientList"}
                Open = {props.Open}
                FormFields = {props.FormFields}
                PopupType = {props.PopupType}
                EditId = {props.EditId}
                Status = {props.Status}
                ClientTypes = {props.ClientTypes}
                UpdateParentOnSuccess = {props.UpdateParentOnSuccess}
            />
        </React.Fragment>
    );
});
