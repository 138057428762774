import React from 'react';
//import * as _r from 'ramda';

import { FedtaxComponent } from './Fedtax.Component';
import { httpServices } from '../../../../services/httpServices';
import { ErrorPage } from '../../../common/error';

const formFields = {
    'id':0,
    'start_date': null,
    'end_date': null,
    'fed_tax_allowances':null,
    'fed_tax_exempt_withholding' : null,
    'fed_tax_extra_withholding' : null,
    'fed_filing_status' : null,
    'multiple_jobs' :null,
    'claim_dependents' :null,
    'other_income' :null,
    'deductions' :null,
};

export class TaxDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            TaxData:[],
            formFields: formFields,
            open: false,
            isEditable: false,
            errors: {},
            fed_tax_id: null,
            state_tax_id: null
        }
        this.handleOnChange2 = this.handleOnChange2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOnChange2 = (evt, value) => {
        const field = evt.target.name;
            const ff = this.state.formFields;
            ff[field] = evt.target.value;
            this.setState({
                formFields: ff
            });
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }       

        if (!this.state.formFields.fed_filing_status) {
            errors["fed_filing_status"] = "Cannot be empty";
            formIsValid = false;
        }
        
        if (!this.state.formFields.fed_tax_exempt_withholding && this.state.formFields.fed_tax_exempt_withholding !== 0) {
            errors["fed_tax_exempt_withholding"] = "Cannot be empty";
            formIsValid = false;
        }
         if (!this.state.formFields.multiple_jobs && this.state.formFields.multiple_jobs !== 0) {
            errors["multiple_jobs"] = "Cannot be empty";
            formIsValid = false;
        }
        if(this.state.formFields.start_date && this.state.formFields.end_date){
            let start_date = new Date(this.state.formFields.start_date);
            let end_date = new Date(this.state.formFields.end_date);
            if(start_date > end_date){
                errors["end_date"] = "End date cannot be before start date";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }


    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                data.staff_id = this.props.StaffID;
                httpServices.post('api/compensation/add-fedtax', { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data) {
                                if(data.id){
                                    let taxdata = this.state.TaxData;
                                    const index = taxdata.findIndex(a => a.id === data.id);
                                    data['marital_status'] = response.data.fedtax_details.marital_status;
                                    data['start_date'] = response.data.fedtax_details.start_date;
                                    data['end_date'] = response.data.fedtax_details.end_date;
                                    data['exempt_label'] = response.data.fedtax_details.exempt_label;
                                    data['job_label'] = response.data.fedtax_details.job_label;
                                    data['fed_tax_extra_withholding'] = response.data.fedtax_details.fed_tax_extra_withholding;
                                    data['claim_dependents'] = response.data.fedtax_details.claim_dependents;
                                    data['other_income'] = response.data.fedtax_details.other_income;
                                    data['deductions'] = response.data.fedtax_details.deductions;
                                    taxdata[index] = data;
                                    this.setState({TaxData:taxdata, open: false});
                                    alert("Federal Tax  details updated successfully");
                                } else {
                                    let taxdata = this.state.TaxData;
                                    data['id'] = response.data.fedtax_details.id;
                                    data['marital_status'] = response.data.fedtax_details.marital_status;
                                    data['start_date'] = response.data.fedtax_details.start_date;
                                    data['end_date'] = response.data.fedtax_details.end_date;
                                    data['exempt_label'] = response.data.fedtax_details.exempt_label;
                                    data['job_label'] = response.data.fedtax_details.job_label;
                                    data['fed_tax_extra_withholding'] = response.data.fedtax_details.fed_tax_extra_withholding;
                                    data['claim_dependents'] = response.data.fedtax_details.claim_dependents;
                                    data['other_income'] = response.data.fedtax_details.other_income;
                                    data['deductions'] = response.data.fedtax_details.deductions;
                                    taxdata.push(data);
                                    this.setState({
                                        TaxData:taxdata,
                                        open: false,
                                    });
                                    alert("Federal Tax details saved successfully");
                                }
                            }
                            else
                            {   
                                const ff = this.state.errors;
                                ff['fedtax_overlap'] = "Federal Tax already exist for this period";
                                this.setState({open: true,errors:ff});
                            }
                        }
                        else {
                            this.setState({errors: response.data.errors});
                        }
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    onAdd = () => {
        this.clearForm();
        if (this.state.TaxData.length===0){
            this.setState({TaxData : this.props.TaxInfo});
        }
        this.setState({
            open: true,
            isEditable: false,
        });
    };
    onUpdate = (id) => {
        if (this.state.TaxData.length === 0){
            this.setState({
                TaxData : this.props.TaxInfo,
                isEditable: true,
            });
        }
        else {
            this.setState({
                isEditable: true,
            });

        }
       return httpServices.post('api/compensation/get-fed-tax-details-by-id', {'id': id})
            .then(response => {

                if (response.data) {
                    let data = response.data;
                    if (Object.keys(data).length){
                        const inputs = {
                            'id': data.id,
                            'start_date': data.start_date,
                            'end_date': data.end_date,
                            'fed_tax_allowances':data.fed_tax_allowances,
                            'fed_tax_exempt_withholding':data.fed_tax_exempt_withholding,
                            'fed_tax_extra_withholding':data.fed_tax_extra_withholding,
                            'fed_filing_status' :data.fed_filing_status,
                            'multiple_jobs' :data.multiple_jobs,
                            'claim_dependents' :data.claim_dependents,
                            'other_income' :data.other_income,
                            'deductions' :data.deductions,
                        };
                        this.setState({
                            formFields: inputs,
                            open: true,
                            id: data.id,
                        });
                    }
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    onDelete = (index, rowID) => {
       var len = this.state.TaxData.length;
        if (len === 0) {
            this.setState({TaxData:this.props.TaxInfo}, function(){
                let b = this.state.TaxData;
                b.splice(index, 1);
                this.setState({TaxData: b});
            });
        }
        else {
            let b = this.state.TaxData;
            b.splice(index, 1);
            this.setState({TaxData: b});

        }
        return httpServices.post('api/compensation/delete-fed-tax', {'id': rowID, 'staff_id': this.props.StaffID})
        .then(response => {
            if (!response.data.error) {
                alert("Federal Tax details deleted successfully");
            }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });

    }

    /*  componentDidMount = () => {
        let dataArr = this.state.TaxData.length>0?this.state.TaxData:this.props.Tax;
        if(_r.type(dataArr) !== 'Undefined' && 'benefit_config' in dataArr && !_r.isNil(dataArr.benefit_config)) {
            let formData = {
                'fsa_effective_date': _r.type(dataArr['benefit_config']['fsa_effective_date'] !== 'Undefined')?dataArr['benefit_config']['fsa_effective_date']:null,
                'hsa_effective_date': _r.type(dataArr['benefit_config']['hsa_effective_date'] !== 'Undefined')?dataArr['benefit_config']['hsa_effective_date']:null,
                'disability_type': dataArr['benefit_config']['disability_id'],
                'safeharbor_contrib': dataArr['benefit_config']['enable_safeharbor_contrib']
            };
            this.setState({formFields: formData});
        }
    }; */

    clearForm = () => {
        let formFields = {
          'id':0,
           'start_date': null,
           'end_date': null,
           'fed_tax_allowances':null,
           'fed_tax_exempt_withholding' : null,
           'fed_tax_extra_withholding' : null,
           'fed_filing_status' : null
         };       
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: formFields,
            isEditable: false,
        });
    };
    onClose = () =>{
        this.clearForm();
        this.setState({
           open: false
        });
    };


    render(){
         return(
            (this.props.TaxViewPermission) ?
            <FedtaxComponent
                Data={this.props.TaxInfo}
                StateTaxData = {this.props.StateTaxInfo}
                AllStates = {this.props.AllStates}
                TaxViewPermission = {this.props.TaxViewPermission}
                TaxEditPermission = {this.props.TaxEditPermission}
                TaxDeletePermission = {this.props.TaxDeletePermission}
                MaritalStatuses = {this.props.MaritalStatuses}
                FederalFilingStatuses = {this.props.FederalFilingStatuses}
                OnAdd={this.onAdd.bind(this)}
                FormFields={this.state.formFields} 
                Errors={this.state.errors}
                Open={this.state.open}
                OnClose={this.onClose.bind(this)}
                OnSubmit={this.handleSubmit}
                OnDelete={this.onDelete.bind(this)}               
                OnUpdate={this.onUpdate.bind(this)}
                StaffID={this.props.StaffID}
                isEditable = {this.state.isEditable}
                HandleOnChange2={this.handleOnChange2}
            />
            :
            <ErrorPage/>
        )
    }
}
