import React from 'react';
import { connect } from "react-redux";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import AddIcon from '@mui/icons-material/Add';

import * as _R from 'ramda';  // used 0.04kb
import * as _cs from '../../../common/common';

import {DialogBoxMedium} from '../../../common/dialog.medium';
import { httpServices } from '../../../../services/httpServices';
import { StaffConstants } from '../../../../constants';
import { CustomizedSnackbars } from '../../../common/snackbars';

const DialogContent = (props) => {
    const {FormFields, FormInputs, HandleOnChange, Primary} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl>
                            <TextField variant="standard"
                                id="phone_number"
                                name="phone_number"
                                label="Phone Number"
                                type="text"
                                fullWidth
                                required = 'true'
                                onChange={HandleOnChange}
                                value={FormFields.phone_number}
                                error={props.Errors.phone_number && true}
                            />
                            <small className='error'>{props.Errors.phone_number && props.Errors.phone_number}</small>
                        </ FormControl>
                    </Grid>
                    {_R.not(Primary)  &&
                    <Grid item xs={12} md={12}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="type" error={props.Errors.type && true} required>Type</InputLabel>
                            <Select
                                    value={_R.type(FormFields) !== 'Undefined' && FormFields.type}
                                    error={props.Errors.type && true}
                                    onChange={HandleOnChange}
                                    inputProps={{
                                        name: 'type',
                                        id: 'type',
                                    }}>
                                    {_R.type(FormInputs) === 'Object' && _R.type(FormInputs.phone_type_opts) !== 'Undefined' && FormInputs.phone_type_opts.map((t, i) =>
                                        <MenuItem value={t.id}>{t.title}</MenuItem>
                                    )}
                            </Select>
                            <small className='error'>{props.Errors.type && props.Errors.type}</small>    
                        </ FormControl>
                    </Grid>
                    }
                </Grid>
            </form>
    )
}

const formFields = {
    'id': 0,
    'type': null,
    'phone_number': null,
}
class PhoneListContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: 0,
            isEditable: false,
            isDelete: false,
            formFields: formFields,
            formInputs: [],
            primary: false,
            errors: {},
            snackbarOpen : false,
            variant : 'success',
            phone : this.props.Phone
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteSubmit = this.onDeleteSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.onUpdatePrimary = this.onUpdatePrimary.bind(this);
        this.onSubmitPrimary = this.onSubmitPrimary.bind(this);
    }
    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }
    onUpdate = (id = 0) => () => {
        const {UserID, StaffID} = this.props;
        let apiEndpoint = 'api/staff/contact/get-phone';
        let editable = (id) ? true : false;
        this.setState({
            id : id
        })
        return httpServices.post(apiEndpoint, {
                        'user_id':UserID,
                        'staff_id':StaffID,
                        'id': id,
            })
            .then(response => {
                if (response.data) {
                    this.setState({
                        open:true,
                        formFields: (id) ? response.data.selected_phone : formFields,
                        formInputs: response.data,
                        isDelete: false,
                        isEditable: editable,
                        primary: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    onSubmit = ()  => {
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.phone_number = this.state.formFields.phone_number ? this.state.formFields.phone_number.replace( /[\s- .)(]/g, '') : null;
            data.id = this.state.id;
            data.staff_id = staffID;
            data.user_id = userID;
            let tab = StaffConstants.STAFF_TABS[1];
            let module = 'phone';
            if (data) {
                let apiEndpoint = 'api/staff/contact/save-phone';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    phone:response.data.phone,
                                    open:false,
                                    snackbarOpen : true,
                                    varient: 'success',
                                    message : response.data.message,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({ errorMessage: response.data.msg});
                            }
                        }
                        else {
                            this.setState({ errors:response.data.errors });
                        }

                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        }
    };
    onDelete = (id) => () => {
        this.setState({
            id:id,
            open:true,
            isDelete: true,
            isEditable: false,
        });
    };
    onDeleteSubmit = () => {
        const {UserID, StaffID} = this.props;
        const {id} = this.state;
        let apiEndpoint = 'api/staff/contact/delete-phone';
        return httpServices.post(apiEndpoint, {
            'user_id': UserID,
            'staff_id': StaffID,
            'id': id,
        })
            .then(response => {
                if (response.data) {
                    let tab = StaffConstants.STAFF_TABS[1];
                    let module = 'phone';
                    this.setState({
                        phone:response.data.phone,
                        open:false,
                        snackbarOpen : true,
                        varient: 'success',
                        message : response.data.message,
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    };
    onUpdatePrimary = () => {
        formFields['phone_number'] = this.props.Phone.primary;
        this.setState({
                        open:true,
                        formFields: formFields,
                        isDelete: false,
                        isEditable: true,
                        primary: true,
                    });

    };
    onSubmitPrimary = () => {
        if (this.handleValidation()) {
            const staffID = this.props.StaffID;
            const userID = this.props.UserID;
            const data = this.state.formFields;
            data.phone_number = this.state.formFields.phone_number.replace( /[\s-. )(]/g, '');
            data.staff_id = staffID;
            data.user_id = userID;
            let tab = StaffConstants.STAFF_TABS[1];
            let module = 'phone';
            if (data) {
                let apiEndpoint = 'api/staff/contact/save-phone-primary';
                return httpServices.post(apiEndpoint, { data })
                    .then(response => {
                        if (!response.data.errors) {
                            if (response.data.valid) {
                                this.setState({
                                    phone:response.data.phone,
                                    open:false,
                                    snackbarOpen : true,
                                    varient: 'success',
                                    message : response.data.message,
                                });
                                this.clearForm();
                            }
                            else {
                                this.setState({errorMessage: response.data.msg });
                            }
                        }
                        else {
                            this.setState({ errors:response.data.errors });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }

        }
    };
    handleValidation() {
        let formIsValid = true;
        let errors = {};
        let phone_number = this.state.formFields.phone_number;
        let primary = this.state.primary;
        if (!phone_number) {
            errors["phone_number"] = "Cannot be empty";
            formIsValid = false;
        }
        else if(phone_number) {
            if(this.state.formFields.type && parseInt(this.state.formFields.type) === 4){
                let reg = /^\d{4}$/;
                let ok = reg.exec(phone_number);
                if (!ok) {
                    errors["phone_number"] = "Extension number isn't valid.Use only numbers(max length:4)";
                    formIsValid = false;
                }
            }
            else{
                let reg = /^(\+\d{1,2}[\s.-]?)\(?\d{3}\)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{4}$/;
                let ok = reg.exec(phone_number);
                if (!ok) {
                    errors["phone_number"] = "Invalid Phone Number. Valid format is +number";
                    formIsValid = false;
                }
            }
        }
        if (!this.state.formFields.type && !primary) {
            errors["type"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    };
    onHandleChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        const val = evt.target.value;
        ff[field] = val;
        this.setState({
            formFields: ff
        });
        if (val) {
            var err = this.state.errors;
            err[field] = '';
            this.setState({errors: err});
        }
    };
    onClose = () => {
        this.setState({
            open: false
        });
        this.clearForm();
    };
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            errorMessage: false,
            formFields: ff
        });
    };
    render(){
        const { phone } = this.state;
        return (
            <React.Fragment>
                <div className={'contact-group_head'}>
                    <Typography className={'title'} variant="button" color="primary" display="block" gutterBottom>
                        <PhoneIcon className={'title-icon'} fontSize="small"/> 
                        Phone
                    </Typography>
                    <div className={'action'}>
                        <Fab size="small" color="default" aria-label="add" className={'fab-outline'} onClick={this.onUpdate()}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
                <ul className={'contact-list'}>
                    <li>
                        <div className={'contact-detils'}>
                            <div className={'contact-type primary'}>Primary (Personal)</div>
                            <div className={'contact-title'}>{_cs.formatPhone(phone.primary)}</div>
                            <div className={'contact-content'}></div>
                        </div>
                        <div className={'contact-action'}>
                            <IconButton aria-label="edit">
                                <EditIcon fontSize="small" onClick={this.onUpdatePrimary}/>
                            </IconButton>
                        </div>
                    </li>
                    {phone.other.map( (ph, index ) =>
                        <li>
                            <div className={'contact-detils'}>
                                <div className={'contact-type'}>{ph.title}</div>
                                <div className={'contact-title'}>{_cs.formatPhone(ph.phone_number)}</div>
                            </div>
                            <div className={'contact-action'}>
                                <IconButton aria-label="edit">
                                    <EditIcon fontSize="small" onClick={this.onUpdate(ph.id)}/>
                                </IconButton>
                                <IconButton aria-label="delete">
                                    <DeleteIcon fontSize="small" onClick={this.onDelete(ph.id)}/>
                                </IconButton>
                            </div>
                        </li>
                    )}
                </ul>
                <DialogBoxMedium
                    Open={this.state.open}
                    Cancel={this.onClose}
                    Title={this.state.isDelete ?  'Delete Phone' : (this.state.isEditable ? 'Edit Phone' : 'Add Phone')}
                    OnSubmit={this.state.isDelete ? this.onDeleteSubmit : (this.state.primary ? this.onSubmitPrimary : this.onSubmit)}
                    ButtonName={this.state.isDelete ? 'Ok' : 'Save'}
                    ErrorMessage={this.state.errorMessage}
                    Content={
                        this.state.isDelete ?
                        'Do you want to delete this Phone Number?'
                        :
                        <DialogContent
                            FormInputs={this.state.formInputs}
                            FormFields={this.state.formFields}
                            OnSubmit={this.onSubmit}
                            HandleOnChange = {this.onHandleChange}
                            Primary = {this.state.primary}
                            Errors = {this.state.errors}
                        />
                    }
                />
                <CustomizedSnackbars
                open={this.state.snackbarOpen}
                variant={this.state.variant}
                message={this.state.message}
                handleClose ={this.snackbarClose}
                />
            </React.Fragment>
        )
    }
}
export { PhoneListContainer as PhoneList };
