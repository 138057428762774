import React from 'react';
import { httpServices }  from '../../../services/httpServices';
import { SystemPolicyComponent }  from './SystemPolicy.Component.js';
import { ErrorPage } from '../../common/error';

let formFields = {
    policy : null,
    start_date : null,
    end_date : null,
    id : 0
}
export class SystemPolicyContainer extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            systemPolicies : [],
            allPolicies : [],
            editPermission : false,
            permission : true,
            open : false,
            popupType : '',
            formFields : formFields,
            errors : {},
            snackbarOpen : false,
            snackbarMsg : '',
            deleteId : 0,
            snackbarVariant: '',
            error_msg : true,
        }
        this.addSystemPolicy = this.addSystemPolicy.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.editSystemPolicy = this.editSystemPolicy.bind(this);
        this.onClose = this.onClose.bind(this);
        this.deleteSystemPolicy = this.deleteSystemPolicy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount = () => {
        this.getAllSystemPolicies();
    };

    getAllSystemPolicies = () => {
        let apiEndpoint = 'api/settings/workpolicy/listSystemPolicies';
        return httpServices.get(apiEndpoint)
            .then(response => {
                if (response.data) {
                    this.setState({
                        systemPolicies : response.data.system_policies,
                        allPolicies : response.data.all_policies,
                        editPermission : response.data.edit_permission,
                        permission : response.data.permission,
                        error_msg : response.data.permision_err_msg,
                    });
                }
            })
    }

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };

    handleDateChange = (value, name) => {
        const form_field = this.state.formFields;
        form_field[name] = value;
        if (name == 'start_date') {
            if (form_field['end_date'] != '' && form_field['start_date'] > form_field['end_date']) {
                form_field['end_date'] = form_field['start_date'];
            }
        }
        this.setState({
            formFields: form_field
        });
    };

    addSystemPolicy = () => {
        this.setState({
            popupType : 'add',
            open : true,
        });
    };

    onClose = () =>{
        this.setState({
            open : false,
            formFields : {},
            errors : {},
        })
    }

    handleDelete = (id) => {
        if (id) {
            this.setState({
                open : true,
                popupType : 'delete',
                deleteId : id,
            });
        }
    };

    handleSubmit = (event) => {
        if(this.handleValidation()){
            let data = this.state.formFields;
            if (data) {
                 httpServices.post('api/settings/workpolicy/saveSystemPolicy', { data })
                    .then(response => {
                        if (response.data.permission) {
                            if (response.data.valid) {
                                this.getAllSystemPolicies();
                                let successMsg = ''
                                if (data.id) {
                                    successMsg = 'System policy updated successfully';
                                } else {
                                    successMsg = 'System policy added successfully';
                                }
                                this.setState({
                                    snackbarOpen: true,
                                    snackbarMsg: successMsg,
                                    snackbarVariant: 'success',
                                    open: false,
                                    popupType: '',
                                    formFields: {},
                                    errors: {},
                                });
                            }
                            else {
                                if (response.data.errors) {
                                    this.setState({
                                        errors: response.data.errors,
                                    });
                                }
                                else {
                                    let errorMsg = 'System policy already exists';
                                    this.setState({
                                        snackbarOpen: true,
                                        snackbarMsg: errorMsg,
                                        snackbarVariant: 'error'
                                    });
                                }
                            }
                        }
                      
                    })
                    .catch(function(error) {
                        console.log("error" + error);
                    });
            }
        }
    };

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.policy) {
            errors["policy"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if ((this.state.formFields.end_date && this.state.formFields.start_date) &&  (this.state.formFields.end_date < this.state.formFields.start_date)) {
            errors["end_date"] = "End date should be greater than start date";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    snackbarClose = () => {
        this.setState({
            snackbarOpen : false,
        });
    }

    editSystemPolicy = (id) => { console.log('dddd')
        let apiEndpoint = 'api/settings/workpolicy/getSystemPolicyById';
        return httpServices.post(apiEndpoint,{id : id})
            .then(response => {
                if (response.data) {
                   if (response.data.system_policies) {
                    var selected = [];
                        let formFields = {
                           id : response.data.system_policies.id,
                           policy : response.data.system_policies.work_policy_id,
                           start_date : response.data.system_policies.start_date,
                           end_date : (response.data.system_policies.end_date != '' && response.data.system_policies.end_date != '0000-00-00') ? response.data.system_policies.end_date : ''
                       }
                       this.setState({
                           formFields : formFields,
                           open : true,
                           popupType : 'edit',
                       });
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    deleteSystemPolicy = () => {
        let apiEndpoint = 'api/settings/workpolicy/deleteSystemPolicy';
        return httpServices.post(apiEndpoint,{id : this.state.deleteId})
            .then(response => {
                if (response.data) {
                    if (response.data.valid) {
                        this.getAllSystemPolicies();
                        this.setState({
                            snackbarOpen : true,
                            snackbarMsg : "System policy deleted successfully",
                            open : false,
                            popupType : '',
                            snackbarVariant: 'success'
                        });
                    } else {
                        alert(response.data.err_msg);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            (this.state.permission)?
            <SystemPolicyComponent
                EditPermission = {this.state.editPermission}
                Permission = {this.state.permission}
                PopupType = {this.state.popupType}
                Open = {this.state.open}
                AddSystemPolicy = {this.addSystemPolicy}
                FormFields = {this.state.formFields}
                Errors = {this.state.errors}
                HandleOnChange = {this.handleOnChange}
                HandleSubmit = {this.handleSubmit}
                SnackbarClose = {this.snackbarClose}
                SnackbarOpen = {this.state.snackbarOpen}
                SnackbarMsg = {this.state.snackbarMsg}
                EditSystemPolicy = {this.editSystemPolicy}
                OnClose = {this.onClose}
                DeleteSystemPolicy = {this.deleteSystemPolicy}
                HandleDelete = {this.handleDelete}
                SnackbarVariant = {this.state.snackbarVariant}
                SystemPolicies = {this.state.systemPolicies}
                AllPolicies = {this.state.allPolicies}
                HandleDateChange = {this.handleDateChange}
            />
            :
            <ErrorPage Message = {this.state.error_msg} />
        )
    }
}
