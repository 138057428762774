import React from 'react';
import {ProjectStatusReportComponent} from './ProjectStatusReport.Component';
import {httpServices}  from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const filterFields = {
    'report_type' : 'task',
    'client' : 0,
    'projects' : 0,
    'name_format' : 'LASTFIRST',
    'include_employee' : true,
    'include_timeoff' : false,
    'start_date' : '',
    'end_date' : '',
    'timeoff_start_date' : '',
    'timeoff_end_date' : '',
}

const filters = {
}

export class ProjectStatusReportContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : true,
            data : [],
            open: false,
            filterFields: filterFields,
            filters: filters,
            errors: {},
            loaderOpen: false,
            type:'',
            timeoffData : []
        }
       
    }

    componentDidMount = () => {
        this.setState({
            loaderOpen:true
        });
        let filterFields = this.state.filterFields;
        return httpServices.get('api/report/project-status-report')
            .then(response => {
                if (response.data.permission) {
                    let filters = response.data.filters;
                    filterFields.start_date=response.data.start_date;
                    filterFields.end_date=response.data.end_date;
                    filterFields.name_format=response.data.name_format;
                    filterFields.timeoff_start_date=response.data.timeoff_start_date;
                    filterFields.timeoff_end_date=response.data.timeoff_end_date;
                    this.setState({
                        filters: filters,
                        filterFields : filterFields,
                        permission: response.data.permission,
                        loaderOpen: false                                               
                    });
                }
                else {
                    this.setState({
                        permission: response.data.permission,
                        loaderOpen: false
                    });

                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    getProjetStatusReport = () => {
        this.setState({
            loaderOpen:true
        });
        let filterFields = this.state.filterFields;
        return httpServices.post('api/report/project-status-report', filterFields)
            .then(response => {
                if (response.data && !response.data.errors) {                    
                    this.setState({
                        data : response.data.data,
                        timeoffData : response.data.timeoff_data,
                        type :  response.data.type,
                        permission: response.data.permission,                                           
                        loaderOpen: false,                                               
                    });
                } else if (response.data.errors) {
                    this.setState({
                        errors: response.data.errors,
                        loaderOpen: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleFilterChange = (evt, value) => {
        let f = this.state.filterFields;
        let filters = this.state.filters;
        let name = evt.target.name;
        let val = evt.target.value;
        if (name === 'nbillable' || name === 'include_employee' || name === 'include_timeoff') {
            f[name] = evt.target.checked;
        }
        else {
            f[name] = val;
        }
        this.setState({
            filterFields: f,
            filters: filters,
        });
        if (name === 'client') {
            this.getProjectsByClient(evt.target.value);
        }
    }
   
    getProjectsByClient = (client) => {
        return httpServices.post('api/report/get-project', {client : client})
            .then(response => {
                if (response.data) {
                    let filterFields = this.state.filterFields;
                    filterFields.project = 0;
                    let filters = this.state.filters;
                    filters.projects = response.data.projects;                    
                    this.setState({
                        filters : filters,
                        filterFields : filterFields
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    
    validateFilter = () => {
        let valid = this.validate();
        let f = this.state.filterFields;
        this.setState({filterFields: f},
            function() {
                if (valid) {
                    this.getProjetStatusReport();
                }

            }
        );
    }
    
    validate = () => {
        let formIsValid = true;
        let errors = {};
        if (!this.state.filterFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }

        if (!this.state.filterFields.end_date) {
            errors["end_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (this.state.filterFields.start_date && this.state.filterFields.end_date) {
            if (new Date(this.state.filterFields.start_date) > new Date(this.state.filterFields.end_date)) {
                errors["start_date"] = "Start Date should be less than End Date";
                formIsValid = false;
            }
        }
        if(this.state.filterFields.include_timeoff){
            if (!this.state.filterFields.timeoff_start_date) {
                errors["timeoff_start_date"] = "Cannot be empty";
                formIsValid = false;
            }
            if (!this.state.filterFields.timeoff_end_date) {
                errors["timeoff_end_date"] = "Cannot be empty";
                formIsValid = false;
            }
            if (this.state.filterFields.timeoff_start_date && this.state.filterFields.timeoff_end_date) {
                if (new Date(this.state.filterFields.timeoff_start_date) > new Date(this.state.filterFields.timeoff_end_date)) {
                    errors["timeoff_start_date"] = "Start Date should be less than End Date";
                    formIsValid = false;
                }
            }
        }
        if (!this.state.filterFields.client) {
            errors["client"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.filterFields.project) {
            errors["project"] = "Cannot be empty";
            formIsValid = false;
        }
        

        this.setState({errors: errors});
        return formIsValid;
    }
    onClose = () =>{
        this.setState({
            open: false,
        });
    };

    render(){
        return(
            <>
            { (this.state.permission) ? 
                <ProjectStatusReportComponent
                    HandleFilterChange = {this.handleFilterChange.bind(this)}
                    ValidateFilter = {this.validateFilter.bind(this)}
                    GetProjetStatusReport = {this.getProjetStatusReport.bind(this)}
                    Data = {this.state.data}
                    FilterFields = {this.state.filterFields}
                    Filters = {this.state.filters}
                    Errors = {this.state.errors}
                    Type = {this.state.type}
                    TimeoffData  = {this.state.timeoffData}
                    OnClose={this.onClose.bind(this)}
                />
                :
                <ErrorPage/>
            }
            <CustomProgressBar
                loaderOpen={this.state.loaderOpen}
            />
            </>            
        )
    }
}
