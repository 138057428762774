import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomMaterialTable } from '../common/CustomMaterialTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import * as _R from 'ramda';
import {DialogBoxMedium} from '../common/dialog.medium';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { CustomizedSnackbars } from '../common/snackbars';

export const RequestConfigComponent = (props) => {
    return (
        <>
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomMaterialTable
                        title={props.Title}
                        columns={[
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Code',
                                field: 'code',
                            },
                            {
                                title: 'Description',
                                field: 'description',
                            },
                            {
                                title: 'Status',
                                render: rowData => rowData.status === 1 ? <>Enabled</> : <>Disabled</>
                            },
                            {
                                title: 'Auto Approval',
                                render: rowData => rowData.auto_approval === 1 ? <>Enabled</> : <>Disabled</>
                            },
                        ]}
                        data={props.RequestConfig}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            pageSize:10,
                            pageSizeOptions: [10,20,30],
                        }}
                        actions={[                            
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => {
                                    props.OnUpdate(rowData.id);
                                },
                            },                          

                        ]}
                    />
                </Grid>
            </Grid>
            
            <DialogBoxMedium
                Open = {props.Open}
                Cancel = {props.OnClose}
                Title = {"Edit Request Config"}
                OnSubmit = {props.OnSubmit}
                Content = {<DialogContent FormProps={props}/>}
           />
           {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = 'true'
                variant= 'success'
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </React.Fragment>
    </>
)};
const DialogContent = (p) => {
    const {FormProps:props} = p;
    const {FormFields, HandleOnChange} = props;
    return (
            <form className="form-fullwidth" onSubmit={props.OnSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField variant="standard"
                                required
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.name}
                                onChange={HandleOnChange}
                                error={props.Errors.name && true}
                                helperText={props.Errors.name}
                            />
                        </ FormControl>
                    </Grid>              
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="status" required  error={props.Errors.status && true}>Status</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.status}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={1} key={'i-type-1'}>Enabled</MenuItem>
                                <MenuItem value={0} key={'i-type-2'}>Disabled</MenuItem>
                            </Select>
                            {props.Errors.status && <FormHelperText className='error'>{props.Errors.status}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <TextField 
                                required
                                id="description"
                                name="description"
                                label="Description"
                                multiline={true}
                                variant="outlined"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={FormFields.description}
                                onChange={HandleOnChange}
                                error={props.Errors.description && true}
                                helperText={props.Errors.description}
                            />
                        </ FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard">
                           <InputLabel htmlFor="auto_approval" required  error={props.Errors.auto_approval && true}>Auto Approval</InputLabel>
                            <Select
                                value={_R.type(FormFields) !== 'Undefined' && FormFields.auto_approval}
                                onChange={HandleOnChange}
                                inputProps={{
                                    name: 'auto_approval',
                                    id: 'auto_approval',
                                }}>
                                <MenuItem value={null} key={'i-type-0'}>Select</MenuItem>
                                <MenuItem value={1} key={'i-type-1'}>Enabled</MenuItem>
                                <MenuItem value={0} key={'i-type-2'}>Disabled</MenuItem>
                            </Select>
                            {props.Errors.auto_approval && <FormHelperText className='error'>{props.Errors.auto_approval}</FormHelperText> }
                        </ FormControl>
                    </Grid>
                </Grid>
            </form>
)
}
