import React from 'react';
import { HistoryComponent } from './History.Component';
import * as _R from 'ramda';


export class HistoryDetails extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            title : 'Change History',
            history  : [],
        }
    }
 
       render(){
        return(
            <HistoryComponent
               Tiitle={this.state.title}
               History={this.state.history.length>0 ? this.state.history :(_R.type(this.props.HistoryData) !== 'Undefined') ? this.props.HistoryData.history: undefined}
               StaffDetails = {(_R.type(this.props.HistoryData) !== 'Undefined') ? this.props.HistoryData.staff_details: undefined}  
            />
        )
    }
}
