import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import * as _R from 'ramda';  // used 0.04kb
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { formatName } from '../../common/common';
import { DialogBoxMedium } from '../../common/dialog.medium';
import { CustomizedSnackbars } from '../../common/snackbars';

export const ExpenseLimitComponent = (props) => {
    const limits = typeof props.ExpenseLimits !== 'undefined' ? props.ExpenseLimits : [];
    return (
        <>
            <div className={'listSubHeader pr-2'}>
            <Typography component="h2" className={'section-title'} variant="button" display="block" gutterBottom color="primary"></Typography>
                {props.EditPermission &&
                <Tooltip title="Add Expense Limit" aria-label="add">
                    <Button variant="contained" color="primary" size="small" onClick={props.AddExpenseLimitClick}>Add</Button>
                </Tooltip>
                }
            </div>
            
            <TableContainer component={Paper}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell> Year </TableCell>
                            <TableCell> Percentage </TableCell>
                            <TableCell> Created By </TableCell>
                            <TableCell> Created On </TableCell>
                            <TableCell> Modified By </TableCell>
                            <TableCell> Modified On </TableCell>
                            {props.EditPermission &&
                            <TableCell>Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={'tbody'}>
                        {(limits.length === 0) &&
                            <TableRow >
                                <TableCell>No records found.</TableCell>
                            </TableRow>
                        }
                        {limits.map((item, index) =>
                        <TableRow hover>
                            <TableCell>{item.year}</TableCell>
                            <TableCell>{item.percentage}%</TableCell>
                            <TableCell>{formatName(item.cby_first_name,null,item.cby_last_name)}</TableCell>
                            <TableCell>{item.created_on}</TableCell>
                            <TableCell>{formatName(item.mby_first_name,null,item.mby_last_name)}</TableCell>
                            <TableCell>{item.modified_on}</TableCell>
                            {props.EditPermission &&
                            <TableCell>
                                <Tooltip title="Edit" aria-label="edit">
                                    <IconButton  className={'action-btn'} edge="end" aria-label="Edit">
                                        <EditIcon fontSize="small" onClick={()=>props.EditClick(item.id)}/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            }
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.PopupType === 'add' || props.PopupType === 'edit') &&
            <DialogBoxMedium
                Open= { props.Open }
                Cancel = { props.OnClose }
                Title = { props.PopupType === 'add' ? 'Add Expense Limit' : 'Edit Expense Limit' }
                OnSubmit = { props.HandleSubmit }
                Content = { <DialogAdd FormProps = { props }/> }
            />
            }
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarType} 
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </>
    )
}

const DialogAdd = (props) => {
    const getYear = () => {
        return new Date().getFullYear();
    }
    const currentYear = getYear();
    const years = [];
    for(var i = 2017; i <=currentYear+1; i++){
        years.push(i);
    }

    const { FormFields,HandleOnChange,Errors } = props.FormProps;
    return (
        <form className="form-fullwidth">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="age-simple">Year</InputLabel>
                        <Select
                            inputProps={{
                            name: 'year',
                            id: 'year',
                        }}
                        value = {(FormFields.year)? FormFields.year: currentYear }
                        onChange = {HandleOnChange}
                        >
                        { years.map((y) =>
                            <MenuItem value={y}>{y}</MenuItem>
                        )}
                        </Select>
                        <small className='error'>{Errors.year && Errors.year}</small>
                    </FormControl>
                </Grid>
                <Grid  item xs={12} md={12}>
                    <FormControl>
                        <TextField variant="standard"
                            required
                            id = "percentage"
                            name = "percentage"
                            label = "Percentage"
                            InputLabelProps = {{
                                shrink: true,
                            }}
                            value = { _R.type(FormFields) !== 'Undefined' ? FormFields.percentage : '' }
                            onChange = { HandleOnChange }
                            error = { (typeof Errors.percentage !== 'undefined' && Errors.percentage)&& true }
                            helperText = { typeof Errors.percentage !== 'undefined' && Errors.percentage }
                        />
                    </ FormControl>
                </Grid>
            </Grid>
        </form>
    )
}
