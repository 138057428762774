import React from 'react';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { CustomMaterialTable } from '../../../common/CustomMaterialTable';
import {DialogBoxMedium} from '../../../common/dialog.medium';
import { ServicePeriodEdit } from "../../../staff/view/employment/EditServicePeriod";
import { CustomizedSnackbars } from '../../../common/snackbars';

export const ServicePeriodComponent = (props) => {
    return(
        <>
        <div className={'mtable-basic'}>
                    <CustomMaterialTable
                        title={"Service Period"}
                        columns={[
                            {title: 'Date',field: 'date',render:rowData=>(rowData.end_date ? rowData.start_date+' - '+rowData.end_date : rowData.start_date)},
                            {title: 'Staff Type',field: 'type'},
                            {title: 'Employment Type',field: 'emp_type'},
                            {title: 'Organization',field: 'client_name'},
                            {title: 'Pay Type',field: 'category'},
                            {title: 'Overtime',field: 'overtime'},
                            {title: 'Daily Min.',field: 'daily_minimum'},
                            {title: 'Daily Max.',field: 'daily_max_limit'},
                            {title: 'Weekly Limit',field: 'weekly_limit', render:rowData=>((rowData.weekly_limit ? rowData.weekly_limit : 0) + ' ('+(rowData.include_holidays=='Yes' ? 'Include Holidays' : 'Exclude Holidays')+')')},
                            {title: 'Duration', field: 'duration'}
                        ]}
                        actions={[
                            {
                            icon: 'add',
                            tooltip: 'Add Service Period',
                            isFreeAction: true,
                            onClick: (event, newData) => {props.HandleClickOpen()},
                            hidden: props.EditPermission ? false : true,
                        },
                        rowData => ({
                            icon: Edit,
                            tooltip: 'Edit',
                            onClick: (event, rowData) => { props.HandleClickOpen(rowData.id)},
                            hidden: props.EditPermission ? false : true,
                        }),
                        rowData => ({
                            icon: DeleteOutline, 
                            tooltip: 'Delete',
                            onClick: (event, rowData) => { props.OnDelete(rowData.id)},
                            hidden: props.EditPermission ? false : true,
                        }),
                        ]}
                        data={props.ServicePeriods}
                        options={{
                            search: false,
                            actionsColumnIndex: -1,
                            toolbar:true,
                            thirdSortClick:false,
                        }}

                    />
                    {(props.ServicePeriods.length !== 0) &&
                    <div className = "text-bold" align="center" >
                        <div> Total : {props.TotalExperience}</div>
                    </div>
                    }
	    </div>
        <DialogBoxMedium
            Open={props.Open}
            Cancel={props.OnClose}
            Title={props.isDelete ?  'Delete Service Period' : (props.isEditable ? 'Edit Service Period' : 'Add Service Period')}
            OnSubmit={props.isDelete ? props.OnDeleteSubmit : props.OnSubmit}
            ButtonName={props.isDelete ? 'Yes' : 'Save'}
            ErrorMessage={props.errorMessage}
            Loading = {props.ButtonLoader}
            Content={
                props.isDelete ?
                'Do you want to delete this Service Period?'
                :
                <ServicePeriodEdit 
                    OnSubmit={props.OnSubmit}
                    spInputs={props.sp_inputs} 
                    HandleOnChange={props.HandleOnChange} 
                    spData={props.formFields}
                    Errors = {props.errors}
                />
            }
            />
            {props.SnackbarOpen &&
            <CustomizedSnackbars
                open = {props.SnackbarOpen}
                variant= {props.SnackbarVarient}
                message = {props.SnackbarMsg}
                handleClose = {props.SnackbarClose}
            />
            }
        </>

    )
}

