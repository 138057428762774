import * as _R from 'ramda';
/****
 * Validation function used in time entry page
 */
export const validateHour = (params) => {
    let retObj = {};
    let isValid = true;
    let msg = '';
    let val = params.val;
    let defaultInc = params.minimum_inc;
    let projectMinimumInc = params.project_minimum_inc;
    let project_id = params.project_id;
    let re = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
    let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
    let re2 = new RegExp("^(\\.)[0-9]{1,2}");
    if(re2.test(val)) {
        val = '0'+val;
    }
    if(re1.test(val)) {
        let hm = val.split(':');
        let fr = hm[1]/60;
        val = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
    }
    if(!(re.test(val) || re2.test(val))) {
        isValid = false;
        msg = 'Hours is invalid';
    }
    else {
        let minimumInc = null;
        if (project_id!==0) {
            minimumInc = projectMinimumInc;
        }
        if (minimumInc===null) {
            minimumInc = defaultInc;
        }
        if (minimumInc !== 0) {
            let min = minimumInc*100;
            let frp = parseFloat(val);
            frp = frp.toFixed(2)*100;
            if(!(frp%min===0)) {
                isValid = false;
                msg = 'Hours should be entered as a multiple of '+minimumInc+' hours';
            }
        }
        if(!(val>=0 && val<=24)) {
            msg = 'Hours should not exceed 24.00';
            isValid = false;
        }
    }
    return retObj = {
        status : isValid,
        value : val,
        msg: msg
    };
};

export const calculateSum = (arr) => {
    let sum = 0;
    let re = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
    let re1 = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
    let re2 = new RegExp("^(\\.)[0-9]{1,2}");
    let hr;
    arr.forEach(function(obj) {
        if(re1.test(obj)) {
            let hm = obj.split(':');
            let fr = hm[1]/60;
            hr = parseFloat(hm[0])+parseFloat(fr.toFixed(2));
            sum += parseFloat(hr);
        }
        else {
            if(re2.test(obj)) {
                hr = '0'+obj;
                sum += parseFloat(hr);  
            }
            if(re.test(obj)) {
                sum += parseFloat(obj);
            }
        }
    });
    let msg = false;
    if(!(sum>=0 && sum<=24)) {
        msg = 'Invalid hours';
    }
    let retObj = {'sum': sum.toFixed(2), 'msg': msg};
    return retObj;
}

function getTaskIdsWithLocation(array) {
    return array.map(function(value,index) {
        return value['task_'+index] ? value['task_'+index]+'-'+value['location_'+index] : "";
    })
}

export const validate = (dataObj, err) => {
    let valid = true;
    let msg = 'Required field/s are empty or invalid';
    let non_confirmity_found = false;
    let errorsObj = {};
    if (dataObj.length) {
        let taskIdsWithLocation = getTaskIdsWithLocation(dataObj);
        dataObj.forEach(function(obj, idx) {
            // Non confirmity validation
            if (parseInt(obj.non_confirmity)) {
                if (obj.hasOwnProperty('resolve')) {
                    if (!obj.resolve) {
                        non_confirmity_found = true;
                    }
                } else {
                    non_confirmity_found = true;
                }
            }
            let key = (Object.keys(obj).filter(function (propertyName) {
                return propertyName.indexOf("rowKey_") === 0;
            }));
            if (key) {
                let rowIndex = obj[key];
                let taskAndLocationString = obj['task_'+rowIndex]+'-'+obj['location_'+rowIndex];
                let taskCount = taskIdsWithLocation.reduce((count, item, ind) => item === taskAndLocationString ? count + 1 : count, 0);
                if (taskCount > 1) { // Duplicate task found.
                    errorsObj['task_duplicate_'+rowIndex] = 1;
                    valid = false;
                    msg = 'You cannot enter duplicate entry for the same day. Please edit existing one';
                } else {
                    errorsObj['task_duplicate_'+rowIndex] = 0;
                }

                //valdiate project
                if (_R.isNil(obj['project_'+rowIndex]) || obj['project_'+rowIndex] === '') {
                    errorsObj['project_'+rowIndex] = 'Project cannot be empty';
                    valid = false;
                }

                //validate task
                if (_R.isNil(obj['task_'+rowIndex]) || obj['task_'+rowIndex] === '') {
                    errorsObj['task_'+rowIndex] = 'Task cannot be empty';
                    valid = false;
                }
               
                //validate location
                if (obj['location_'+rowIndex] === '' && obj['wlc_allowed'+rowIndex] == true) {
                    errorsObj['location_'+rowIndex] = 'Location cannot be empty';
                    valid = false;
                }

                //validate description
                if (_R.isNil(obj['desc_'+rowIndex]) || obj['desc_'+rowIndex].trim() === '') {
                    errorsObj['desc_'+rowIndex] = 'Description cannot be empty';
                    valid = false;
                }

                //validate hours
                if (_R.isNil(obj['hour_'+rowIndex]) || obj['hour_'+rowIndex] === '') {
                    errorsObj['hour_'+rowIndex] = 'Hour cannot be empty';
                    valid = false;
                }
                if (parseInt(obj['enable_location_' + rowIndex])  && ( _R.isNil(obj['location_'+rowIndex]) || obj['location_'+rowIndex] === '')) {
                    errorsObj['location_'+rowIndex] = 'Location cannot be empty';
                    valid = false;
                }
            } 
        });
    }
    if (valid) {
        if (Object.keys(err).length){
            valid = false;
            if (typeof err.daytotal !== 'undefined') {
                msg = err.daytotal;
            }
        }
    }
    let retObj = {'valid': valid, 'msg': msg, 'errors': errorsObj, 'non_confirmity_found': non_confirmity_found};
    return retObj;
}
