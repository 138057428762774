import React from 'react';
import {PayrollComponent} from './Payroll.Component'
import {httpServices}  from '../../services/httpServices'
import { history } from './../../helpers/history';
import { ErrorPage } from '../common/error';

export class PayrollContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            year: null,
            payperiod: null,
            intervals: [],
            hasPermission: true,
            permision_err_msg: '',
            loaderOpen: true,
            name_format : '',
        }
        this.HandleOnYearChange = this.handleOnYearChange.bind(this);
    }

    handleOnYearChange = (evt, value) => {
        let yearVal = evt.target.value;
        if (yearVal) {
            this.setState({year: yearVal});
            this.getPayperiods(yearVal);
        }
    };

    getPayperiods = (yearVal) => {
        return httpServices.post('api/payroll', {year: yearVal})
            .then(response => {
                if (response.data) {
                    this.setState({
                        loaderOpen:false,
                        intervals:response.data.intervals, 
                        hasPermission:response.data.permission, 
                        permision_err_msg: response.data.permision_err_msg,
                        name_format : response.data.name_format
                    });
                }
        })
        .catch(function(error) {
            console.log("Error: "+error);
        });
    }

    componentDidMount = () => {
        let year = new Date().getFullYear();
        this.getPayperiods(year);
    };

    viewReviewPage  = (payperiodID) =>{
        history.push('payroll/review/' + payperiodID);
    }

    render(){
        return(
            (this.state.hasPermission)
            ?<PayrollComponent
                Year={this.state.year} 
                HandleOnYearChange={this.handleOnYearChange.bind(this)}
                Intervals={this.state.intervals}
                HandleReviewPage={this.viewReviewPage.bind(this)}
                LoaderOpen={this.state.loaderOpen}
            />
            :<ErrorPage Message ={this.state.permision_err_msg}/>
        )
    }
}
