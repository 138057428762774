import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as _R from 'ramda';

export const TimeEntrySummary  = props => {
    return (
        <React.Fragment>
            <div className={'timeEntry-summary_table'}>
                <div className={'summary-item'}>
                    <Typography variant="h6" className={'section-title'}>Project Effort <span className={'sub'}>Pay Period</span></Typography>
                    <div>
                        <ul className={'summary-list'}>
                            {_R.type(props.project_summary) !== 'Undefined' && (props.project_summary).map((item ,i) => 
                            <li>
                                <label>{item.project_code}</label> 
                                <span className={'value'}>{item.hours}</span>
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={'summary-item'}>
                    <Typography variant="h6" className={'section-title'}>
                        Project Effort 
                        <span className={'sub'}>Daily</span>
                        <div className={'summary-date'}>Date : {props.DisplayDate}</div>
                    </Typography>
                    <div>
                        <ul className={'summary-list'}>
                            {_R.type(props.daily_summary) !== 'Undefined' && (props.daily_summary).map((item ,i) => 
                            <li>
                                <label>{item.project_code}</label> 
                                <span className={'value'}>{item.hours}</span>
                            </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={'summary-item'}>
                    <Typography variant="h6" className={'section-title'}>Hours summary</Typography>
                    {_R.type(props.hours_summary) !== 'Undefined' && (props.hours_summary.limit === 'daily_minimum') &&
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell> Till {props.hours_summary.sdate} </TableCell>
                                <TableCell> Pay period </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow> 
                                <TableCell>Minimum hours required</TableCell>
                                <TableCell> {props.hours_summary.min_hours_till}  </TableCell>
                                <TableCell> {props.hours_summary.min_hours} </TableCell> 
                             </TableRow>
                            <TableRow>
                                <TableCell> Hours saved</TableCell>
                                <TableCell> {props.hours_summary.total_hours_till} </TableCell>
                                <TableCell> {props.hours_summary.total_hours} </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell> Variance</TableCell>
                                <TableCell>{props.hours_summary.total_hours_till - props.hours_summary.min_hours_till}</TableCell>
                                <TableCell>{props.hours_summary.total_hours - props.hours_summary.min_hours}</TableCell>
                             </TableRow>
                         </TableBody>
                    </Table>
                    }
                    {_R.type(props.hours_summary) !== 'Undefined' && (props.hours_summary.limit === 'weekly_limit') &&
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell> Week </TableCell>
                                <TableCell> Limit  </TableCell>
                                <TableCell> Hours  </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_R.type(props.hours_summary) !== 'Undefined' && (props.hours_summary.week_summary).map((weekly, i) =>
                            <TableRow>
                                <TableCell> {props.hours_summary.week_summary[i].from_date} to {props.hours_summary.week_summary[i].to_date} </TableCell> 
                                <TableCell> {props.hours_summary.week_summary[i].weekly_limit} </TableCell>
                                <TableCell> {props.hours_summary.week_summary[i].total_hours} </TableCell>     
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    }
                </div>
            </div>
        </React.Fragment>
    )
};
