import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';
import { CalendarComponent } from './Calendar.Component';
import { history } from './../../../helpers/history';
import * as _ts from '../../common/treeSelect';
import MixpanelManager from  '../../../services/Analytics';

// Hard coded all object for showing 'all' option in the filter dropdown
const clientAllOption = {client_id: '0', client_name: 'All'};
const employeeAllOption = {value: '0', user_fullname: 'All' , user_id: '0'};
const filter = {
    start_date: '',
    end_date: '',
    request_event_type: ['all','my','review'],
    request_type: ['timeoff'], 
    status: ['1','2'],
    client: '0',
    employee: ['0'],
    client_obj : clientAllOption,
    employee_obj : [employeeAllOption],
    projects : [0],
};

export class RequestCalendar extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            showLoader : false,
            hasPermission : '',
            filter : filter,
            events : [],
            requestConfig : [],
            open : false,
            popupType : '',
            eventData : {},
            clients : [],
            projects : [],
            projectResources : [],
            showClientProjectFilter : false,
            showStaffFilter : false,
        };
        this.getCalendarData = this.getCalendarData.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.viewEvent = this.viewEvent.bind(this);
        this.onClose = this.onClose.bind(this);
        this.viewEventDetails=this.viewEventDetails.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.MixpanelManager = new MixpanelManager();
    }
    componentDidMount = () => {
        this.getCalendarData();
        let callFrom = 'didMount';
        this.getProjects(callFrom);
    };
    getCalendarData = () => {
        this.setState({
            showLoader : true,
        });
        let apiEndpoint = 'api/request/calendar';
        let filter = this.state.filter;
        let data = {
            start_date : filter.start_date,
            end_date : filter.end_date,
            request_event_type : filter.request_event_type,
            request_type : filter.request_type,
            status : filter.status,
        }
        data.selected_resources = [];
        let indexOfAllEmployeeItem = filter.employee.findIndex(emp => emp === '0'); // Checking if the employee all option is selected.
        let indexOfAllProjectItem = filter.projects.findIndex(ipr => ipr === 0);
        if (filter.employee.length && indexOfAllEmployeeItem === -1) { 
            data.selected_resources = filter.employee;
        }
        else if (indexOfAllEmployeeItem !== -1 && (filter.client !== '0' || (filter.projects.length && indexOfAllProjectItem))) {
            let projectResources = this.state.projectResources;
            let tempResources = [];
            projectResources.forEach(function(pRes) {
                if (pRes.user_id !== "0") {
                    tempResources.push(pRes.user_id);
                }
            })
            data.selected_resources = tempResources;
        }
        if (filter.projects && filter.projects.length) {
            data.selected_projects = filter.projects;
        }
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.permission) {
                    let reqConfig = [];
                    if (response.data.request_config && response.data.request_config.length) {
                        reqConfig = response.data.request_config;
                        let additionalPtoIndex = reqConfig.findIndex(rc => rc.code === 'additionalpto');
                        if (additionalPtoIndex !== -1) {
                            reqConfig.splice(additionalPtoIndex, 1);
                        }
                    }
                    let clients = response.data.clients;
                    let filter = this.state.filter;
                    if (clients && clients.length) {
                        clients.unshift(clientAllOption);                        
                    } else {
                        filter.client_obj = {};
                    }                    
                    this.setState({
                        hasPermission : true,
                        showLoader : false,
                        events : response.data.events,
                        requestConfig : reqConfig,
                        clients : clients,
                        filter : filter,
                    });
                }
                else {
                    this.setState({
                        hasPermission : false,
                        showLoader : false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };
    getProjects = (callFrom='') => {
        let apiEndpoint = 'api/request/get-projects-and-resources';
        let filter = this.state.filter;
        let data = {
            client : filter.client,
            projects : filter.projects,
            start_date : filter.start_date,
            end_date : filter.end_date,
        };
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data && response.data.projects) {
                    let projects = _ts.prepareTreeData(response.data.projects, 'projects');                     
                    let projectResources = response.data.resources ? response.data.resources : [];
                    if (!projects.length) {
                        filter.projects = [0];
                    }
                    if (projectResources.length) {
                        projectResources.unshift(employeeAllOption);
                    } else {
                        filter['employee'] = ['0'];
                        filter['employee_obj'] = [];
                    }
                    this.setState({
                        filter : filter,
                        projects : projects,
                        projectResources : projectResources,
                        showClientProjectFilter : response.data.show_client_project_filter,
                        showStaffFilter : response.data.show_staff_filter,
                    }, function () {
                        if (callFrom !== 'didMount') {
                            this.getCalendarData();
                        }
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    handleDateChange = (start, end) => {
        let filter = this.state.filter;
        filter['start_date'] = start.getFullYear()+'-'+(start.getMonth()+1)+'-'+start.getDate();
        filter['end_date'] = end.getFullYear()+'-'+(end.getMonth()+1)+'-'+end.getDate();
        this.setState({
            filter: filter,
        }, function(){
            this.getProjects('onDateChange');
        });
    }
    handleEventChange = (evt, val) => {
        const field = evt.target.name;
        const value = evt.target.checked;
        let stateFilter = this.state.filter;
        let request_event_type = stateFilter.request_event_type;
        if (value) {
            request_event_type.push(field);
        } else {
            let idx = request_event_type.findIndex(x => x === field);
            if (idx !== -1) {
                request_event_type.splice(idx, 1);
            }
        }
        request_event_type = [...new Set(request_event_type)];
        if (field === 'all' && !value) {
            request_event_type = [];
        }
        else if ((request_event_type.findIndex(x => x === 'my') !== -1 && request_event_type.findIndex(x => x === 'review') !== -1) || (field === 'all' && value)) {
            request_event_type = ['all','my','review'];
        } else {
            let idx = request_event_type.findIndex(x => x === 'all');
            if (idx !== -1) {
                request_event_type.splice(idx, 1);
            }
        }
        filter['request_event_type'] = request_event_type;
        stateFilter['request_event_type'] = request_event_type;
        this.setState({
            filter: stateFilter,
        }, function(){
            this.getCalendarData();
        })
    }
    handleOnChange = (evt, val) => {
        const field = evt.target.name;
        const value = evt.target.value;
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({
            filter: filter,
        }, function(){
            this.getCalendarData();
        });
    }
    /* Note: Not able to reuse the generic onchange function 'handleOnChange'
     * because the autocomple component not returning the name atribute
     */
    handleClientChange = (evt, val) => {
        let filter = this.state.filter;
        filter['client'] = val ? val.client_id : 0;
        filter['client_obj'] = val ? val : {};
        filter['projects'] = [0];
        filter['employee'] = ['0'];
        filter['employee_obj'] = [employeeAllOption];
        this.setState({
            filter: filter,
        }, function(){
            if (val) {
                this.getProjects('clients');
            } 
        });
    }
    handleEmployeeChange = (evt, value) => {
        let filter = this.state.filter;
        if (value) {
            if (value.length) {
                let indexOfAll = value.findIndex(i => i.user_id === "0");
                if (indexOfAll !== -1 && indexOfAll === value.length-1) {
                    filter.employee_obj = [employeeAllOption]; 
                    filter.employee = ['0']; 
                } else {
                    let tempNewEmployeeObjArr = [];
                    let tempEmpIds = [];
                    value.forEach(function(item) {
                        if (item.user_id !== "0") {
                            tempNewEmployeeObjArr.push(item);
                            tempEmpIds.push(item.user_id);
                        }
                    })
                    filter.employee_obj = tempNewEmployeeObjArr;
                    filter.employee = tempEmpIds;
                }
            }
            else {
                filter.employee_obj = [];
                filter.employee = ['0'];
            }
        }
        this.setState({
            filter: filter,
        }, function(){
            this.getCalendarData(); 
        });
    }
    handleTreeChange = (evt, cNode, sNodes) => {
        let filter = this.state.filter;
        let projectTree = this.state.projects;
        let projects = [];
        if (cNode.project_id === 0){
            if( cNode.checked) {
                projects.push(0);
            }
        }
        else if (sNodes && sNodes.length >= 0) {
            sNodes.forEach(function(el){
                if (el.project_id && el.project_id !== 0) {
                    getSelectedProjectIdsFromTree(projectTree, el.project_id, projects);
                }
            });
        }
        filter.projects = projects;
        filter['employee'] = ['0'];
        filter['employee_obj'] = [employeeAllOption];
        this.setState({
            filter : filter,
        }, function () {
            this.getProjects('project');
        });
    }
    viewEvent = (evt) => {
        let data = {};
        data['extendedProps'] = evt.extendedProps;
        data['title'] = evt.title;
        this.setState({
            eventData: data,
            popupType: 'event_view',
            open: true,
        });
        this.MixpanelManager.trackEvent('Request', {'module' : 'Request', 'feature' : 'calendar', 'action' : 'calendar-event-clicked'});
    }
    onClose = () => {
        this.setState({
            eventData: {},
            open: false,
            popupType: '',
        });
    }
    viewEventDetails = () => {
       if (this.state.eventData && this.state.eventData.extendedProps.view_url) {
            history.push(this.state.eventData.extendedProps.view_url);
       } 
    }
    handleStatusChange = (evt, val) => {
        const field = evt.target.name;
        const value = evt.target.checked;
        let stateFilter = this.state.filter;
        let allStatuses = ["1","2","3","4"];
        let status = stateFilter.status;
        if (value) {
            status.push(field);
        } else {
            let idx = status.findIndex(x => x === field);
            if (idx !== -1) {
                status.splice(idx, 1);
            }
        }
        status = [...new Set(status)];
        if (field === '0' && !value) {
            status = [];
        }
        else if ((field === '0' && value) || (JSON.stringify(allStatuses.sort()) === JSON.stringify(status.sort()))) {
            status = ['0','1','2','3','4'];
        } else {
            let idx = status.findIndex(x => x === '0');
            if (idx !== -1) {
                status.splice(idx, 1);
            }
        }
        filter['status'] = status;
        stateFilter['status'] = status;
        this.setState({
            filter: stateFilter,
        }, function(){
            this.getCalendarData();
        })
    }

    handleTypeChange = (evt, val) => {
        const field = evt.target.name;
        const value = evt.target.checked;
        let stateFilter = this.state.filter;
        let allRequestTypes = ["timeoff","offsite","overtime"];
        let requestType = stateFilter.request_type;
        if (value) {
            requestType.push(field);
        } else {
            let idx = requestType.findIndex(x => x === field);
            if (idx !== -1) {
                requestType.splice(idx, 1);
            }
        }
        requestType = [...new Set(requestType)];
        if (field === 'all' && !value) {
            requestType = [];
        }
        else if ((field === 'all' && value) || (JSON.stringify(allRequestTypes.sort()) === JSON.stringify(requestType.sort()))) {
            requestType = allRequestTypes;
            requestType.push('all');
        } else {
            let idx = requestType.findIndex(x => x === 'all');
            if (idx !== -1) {
                requestType.splice(idx, 1);
            }
        }
        filter['request_type'] = requestType;
        stateFilter['request_type'] = requestType;
        this.setState({
            filter: stateFilter,
        }, function(){
            this.getCalendarData();
        })
    }

    render(){
        return(
            <>
            <CustomProgressBar
                loaderOpen={this.state.showLoader}
            />
            {(this.state.hasPermission)
            ?
            <CalendarComponent
                Events = {this.state.events}
                HandleDateChange= {this.handleDateChange}
                HandleOnChange={this.handleOnChange}
                HandleEventChange={this.handleEventChange}
                Filter={this.state.filter}
                RequestConfig={this.state.requestConfig}
                ViewEvent={this.viewEvent}
                Open={this.state.open}
                PopupType={this.state.popupType}
                EventData={this.state.eventData}
                OnClose={this.onClose}
                ViewEventDetails={this.viewEventDetails}
                HandleStatusChange={this.handleStatusChange}
                Clients={this.state.clients}
                HandleClientChange={this.handleClientChange}
                Projects={this.state.projects}
                HandleTreeChange={this.handleTreeChange}
                ProjectResources={this.state.projectResources}
                HandleEmployeeChange={this.handleEmployeeChange}
                HandleTypeChange={this.handleTypeChange}
                ShowClientProjectFilter={this.state.showClientProjectFilter}
                ShowStaffFilter={this.state.showStaffFilter}
            />
            :
            (this.state.hasPermission === false) ?
            <ErrorPage 
                Message={'You have no permission to view request dashboard'} 
            />
            :
            <></>
            }
            </>
        )
    }
}

let getSelectedProjectIdsFromTree = function(treeData, selectedProjectId, resultArr) {
    treeData.forEach(function(e) {
        if((selectedProjectId && selectedProjectId === e.project_id) || !selectedProjectId){
            resultArr.push(e.project_id);
            if (e.children) {
                getSelectedProjectIdsFromTree(e.children, 0, resultArr);
            }
        }else if(selectedProjectId && e.children instanceof Object) {
            getSelectedProjectIdsFromTree(e.children, selectedProjectId, resultArr);
        }
    });
}

