import 'date-fns';
import React from 'react';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';

import { TimesheetList } from './timeEntry/TimesheetList';
import { DialogBoxMedium } from '../common/dialog.medium';
import { TimeEntrySummary } from './timeEntry/TimeEntrySummary';
import { TimeEntryRow } from './timeEntry/TimeEntryRow/TimeEntryRowContainer';
import { CustomizedSnackbars } from '../common/snackbars';
import { TimeEntryEdit } from './timeEntry/TimeEntryEdit';
import { TimeInOutList } from './timeEntry/TimeInOutList';
import { TimeInOut} from './timeEntry/TimeInOut';
import { AddTimeOff } from '../requests/TimeOff/TimeOffAdd.Container';
import { AddLocation } from '../requests/Location/LocationAdd.Container';
import { SplitButton } from '../common/splitButton';
import { CustomDatePicker } from '../common/CustomDatePicker';


const DialogWithConfirmMessage = (props) => {
    return (
        <>{props.msg}</>
    )
}

export const TimeEntryComponent = props => {
    //Tabs
    const [tabValue, setTabValue] =  React.useState(0);
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="tab-panel"
                hidden={value !== index}
                {...other}
            >
                {children}
            </Typography>
        );
    }
    
    const [summaryView, setSummaryView] = React.useState(null)
    const closeSummary = () => {
        setSummaryView(null)
    }
    /*const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 80)   */
    const myRef = React.useRef(null)
    /*const executeScroll = () => scrollToRef(myRef)*/
    return (
        <React.Fragment>
            <div className={'st-timeEntry_wrapper'}>
                <div className={'st-action_bar st-action_bar-top'}>
                    <div className={'st-action_item st-timeEntry_date'} ref={myRef}>
                        <CustomDatePicker
                            datepickerType = 'keyboard'
                            id = "timeEntry_date"
                            name = "timeEntry_date"
                            className = {'timeEntry-dateInput'}
                            selectedDate = {props.selectedDate}
                            handleDateChange = {props.handleTimeEntryDateChange}
                            maxDate = {props.maxDate}
                            minDate = {props.minDate}
                            minDateMessage = {"Error: Timesheet date should not be prior to Date of Joining"}
                            shouldDisableDate = {(date) => {
                                let timeentry_date = true;
                                props.TimeentryPeriods.forEach(period => {
                                    if (moment(date).isBetween(period.start_date, period.end_date, 'date','[]')) {
                                        timeentry_date = false;
                                    }
                                });
                                return timeentry_date;
                            }}
                        />
                    </div>
                    <div className={'st-action_item st-timeEntry_inout'}>
                        {props.TimeInOut ? 
                        <div className={'st-HSpacingL_4'}>
                            <Button 
                                variant="contained" 
                                size="small" 
                                color="primary" 
                                startIcon={<AlarmAddIcon />}
                                onClick={()=>props.addTimeInOutClick(false)}
                            >
                                <span className={'label'}>Time In/Out</span>
                                <span className={'value ml10'}>{(props.TimeInOutTotal).toFixed(2)} <span className={'info'}>Hours</span></span>
                            </Button>
                        </div> 
                        :<></>} 
                    </div>
                    <div className={'st-action_item st-timeEntry_payperiod'}>
                        <div className={'st-timeEntry_payperiod-title st-HSpacingL_2'}>
                            <span className={'label'}>Pay Period</span>
                            {props.PayPeriodText}
                        </div>
                    </div>
                    {(props.TimeoffReqPermission || props.OffsiteReqPermission) &&
                    <div className={'st-action_item st-timeEntry_request justify-self-end'}>
                        <div className={'st-HSpacingL_4'}>
                           <SplitButton
                                Options = {props.RequestOptions}
                           />
                        </div>
                    </div>
                    }
                </div>
                <div className={'st-timeEntry'}>
                    <TimeEntryRow
                        SelectedDate = {props.selectedDate}
                        InstanceID = {props.InstanceID}
                        UpdateParentContainer = {props.UpdateParentContainer}
                        TimesheetIsSubmitted = {props.TimesheetIsSubmitted}
                        MinimumInc = {props.MinimumInc}
                        DeletedTsIdFromParent = {props.DeletedTsIdFromParent}
                        ForceRerender = {props.ForceRerender}
                        UpdateRenderFlag = {props.UpdateTimeEntryOnEdit}
                        CopyTimeEntryFlag = {props.CopyTimeEntryFlag}
                        CopiedDate = {props.CopiedDate}
                        UpdateCopyTimeEntryFlag = {props.UpdateCopyTimeEntryFlag}
                        SelectedLocation = {props.SelectedLocation}
                        AddLunchBreak = {props.AddLunchBreak}
                        TimeInoutDetails = {props.time_inout_details} 
                        InOutDate = {props.InOutDate}
                        DisableSaveButton = {props.DisableSaveButton}
                        getTimeInOutDetails = {props.getTimeInOutDetails}
                    />
                </div>
            </div>
            <div className={'timesheet_list xs-hidden '+ summaryView}>
                <div className={'timesheet_list-header'}>
                    <div className={'title'}>Timesheet Summary</div>
                    <IconButton aria-label="close" onClick={closeSummary}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
                <Paper>
                    <div className={'tab-navContainer d-flex justify-between'}>
                        <Tabs
                            value={tabValue}
                            onChange={tabChange}
                            indicatorColor="primary"
                            aria-label="Timesheet List View Tabs"
                        >
                            <Tab label="Saved Timesheet"/>
                            {props.TimeInOut && 
                            <Tab label="Time In/Out" onClick={props.getTimeInOutDetails}/>
                            }
                            <Tab label="Summary"/>
                        </Tabs>
                    </div>
                    <div className={'tab-panelContainer'}>
                        <TabPanel value={tabValue} index={0}>
                            <TimesheetList 
                                TimesheetList = {props.timesheetList}
                                Index            = {props.RowIndex}
                                GetSavedTimeSlices = {props.GetSavedTimeSlices}
                                GetTimesheetByDate = {props.GetTimeSlicesByDate}
                                EditTimeEntry = {props.EditSavedTimeEntry}
                                ListReviewNotes = {props.ListReviewNotes}
                                PopupType = {props.PopupType}
                                Open = {props.DialogOpen}
                                Close = {props.OnClose}
                                ReviewNotes = {props.ReviewNotes}
                                OnDelete = {props.DeleteSavedTimeEntry}
                                HoursSummary = {props.hours_summary}
                                CopyTimeEntries = {props.CopyTimeEntries}
                                selectedDate = {props.selectedDate}
                                TimesheetId = {props.TimesheetId}
                                GetLockedDetails = {props.GetLockedDetails}
                                PopupMsg = {props.PopupMsg}
                            />
                        </TabPanel>
                        {props.TimeInOut === 1 && 
                        <TabPanel value={tabValue} index={1}>
                            <TimeInOutList 
                                TimeInOut = {props.time_inout}
                                time_inout_details = {props.time_inout_details}               
                                EditTimeInout = {props.addTimeInOutClick}
                            />
                        </TabPanel>
                        }
                        <TabPanel value={tabValue} index={props.TimeInOut ? 2 : 1 }>
                            <TimeEntrySummary 
                                  project_summary = {props.project_summary} 
                                  daily_summary = {props.daily_summary} 
                                  DisplayDate = {props.DisplayDate}       
                                  hours_summary = {props.hours_summary}
                            />
                        </TabPanel>
                    </div>
                </Paper>
            </div>

       <CustomizedSnackbars
            open={props.snackbarOpen}
            variant='success'
            message={props.Message}
            handleClose={props.SnackbarClose}
        />
        <TimeEntryEdit
            timeSliceId={props.ClickedTimeSliceId}
            Open= {props.TimeSliceEditDialogOpen}
            Cancel = {props.TimeSliceEditDialogClose}
            timeSheetId = {props.timeSheetId}
            getTimesheetDetails = {props.GetSavedTimeSlices}
            showEditSuccess = {props.ShowEditSuccess}
            randomNumber = {props.randomNumber}
            UpdateTimeEntryOnEdit = {props.UpdateTimeEntryOnEdit}
        />
        {(props.PopupType === 'timein_out' && props.DialogOpen) &&
            <TimeInOut
                SelectedDate = {props.TimeInoutDate}
                Open = {props.DialogOpen}
                Cancel = {props.OnClose}
                RandomNumber = {props.randomNumber}
                TimeInOutSaveSuccess = {props.TimeInOutSaveSuccess}
            />
        }
        {props.PopupType === 'non_confirmity_user_confirmation' &&
            <DialogBoxMedium
                Open={props.DialogOpen}
                Cancel={props.OnClose}
                ButtonName={props.DialogButton}
                OnSubmit={props.SkipNonConfirmity}
                Title='Confirm'
                Content={
                    <DialogWithConfirmMessage msg="Do you want to save without resolving issues ?" />
                }
            />
        }
        {(props.PopupType === 'request_timeoff' && props.DialogOpen) &&
            <AddTimeOff
                Open = {props.DialogOpen}
                UpdateParentOnAddSuccess = {props.UpdateParentContainer}
                DisplayRequestSuccess = {props.DisplayRequestSuccess}
                EditId = {0}
                Title = {"ADD"}
                StartDate = {props.selectedDate}
                CloseLoader = {props.CloseLoader}
            />
        }
        {(props.PopupType === 'request_offsite' && props.DialogOpen) &&
            <AddLocation
                Open = {props.DialogOpen}
                UpdateParent = {props.UpdateParentContainer}
                DisplayRequestSuccess = {props.DisplayRequestSuccess}
                EditId = {0}
                Title = {"ADD"}
                StartDate = {props.selectedDate}
            />
        }
        </React.Fragment>
    )
}
