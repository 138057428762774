import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';

export const LabelsComponent = (props => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <React.Fragment>
                <TableContainer >
                    <Table aria-label="simple table"  >
                        <TableHead>
                            <TableRow>
                                <TableCell><Checkbox
                                    onChange={props.HandleCheckboxChange}
                                    inputProps={{
                                        name: 'select_all_labels',
                                        id: 'select_all_labels',
                                    }}
                                    checked={props.SelectAllLabels}
                                    color="primary"
                                />
                                </TableCell>
                                <TableCell> Label Name </TableCell>
                                <TableCell> Created By </TableCell>
                                <TableCell> Created On </TableCell>
                                </TableRow>
                        </TableHead>
                        {(props.ProjectLabels.length === 0) &&
                            <TableBody>
                                <TableRow>
                                    <TableCell width="200">No label found</TableCell>
                                </TableRow>
                            </TableBody>
                        }
                        <TableBody>
                            {Object.keys(props.ProjectLabels).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                                <>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                onChange={(e) => props.HandleCheckboxChange(e, props.ProjectLabels[row].id)}
                                                inputProps={{
                                                    name: 'label_row_select',
                                                    id: 'label_row_select',
                                                }}
                                                checked={(props.SelectedLabels.includes(props.ProjectLabels[row].id)) ? true : false}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell> {props.ProjectLabels[row].name}</TableCell>
                                        <TableCell>{props.ProjectLabels[row].cby_name}</TableCell>
                                        <TableCell>{props.ProjectLabels[row].created_on}</TableCell>
                                        </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={props.ProjectLabels.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                
            </React.Fragment>
        </>
    )
});